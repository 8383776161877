export const SORT_RELEVANCE = 'sort.relevance';
export const SORT_PRICE_HIGH2LOW = 'sort.price.high2low';
export const SORT_PRICE_LOW2HIGH = 'sort.price.low2high';
export const FILTER_DRAWER_TITLE_MOBILE = 'filter_drawer.title';
export const FILTERS_APPLY = 'filters.apply';
export const FILTERS_FILTER = 'filters.filter';
export const FILTERS_FILTERS = 'filters.filters';
export const FILTERS_CLEAR_ALL = 'filters.clear_all';
export const PLP_FILTER_ERROR_FILTER_IS_NOT_AVAILABLE =
  'plp.filter.error.filters_not_available';
