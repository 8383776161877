import { checkStoreIdIsValid } from 'components/Session/feature/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectors } from 'redux/factories/create-selectors';
import { isNotEmpty } from 'utils/array/size';
import {
  selectArrangementFulfilled,
  selectProductsIds,
} from 'redux/PDP/arrangement/arrangement.selectors';
import {
  selectIsSessionBlocked,
  selectIsSessionFulfilled,
} from 'redux/session/availability/availability.selectors';

export const { selectData: selectProductOptionsAvailability, selectIsBlocked } =
  createSelectors((state) => state.productOptionsAvailability);

export const selectShouldCheckAvailability = createSelector(
  [
    selectIsSessionFulfilled,
    checkStoreIdIsValid,
    selectProductsIds,
    selectArrangementFulfilled,
  ],
  (
    isAvailabilitySessionFulfilled,
    isStoreIdValid,
    productsIds,
    isArrangementFulfilled,
  ) =>
    isNotEmpty(productsIds) &&
    isAvailabilitySessionFulfilled &&
    isStoreIdValid &&
    isArrangementFulfilled,
);

export const selectIsLoading = createSelector(
  [selectShouldCheckAvailability, selectIsBlocked, selectIsSessionBlocked],
  (shouldCheckAvailability, isAvailabilityBlocked, isSessionBlocked) =>
    isSessionBlocked || (shouldCheckAvailability && isAvailabilityBlocked),
);
