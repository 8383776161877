/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@mui/material';
import {
  DescriptionWrapper,
  ViewButtonWrapper,
  link,
} from 'components/PDP/Details/components/Marketplace/components/ProductDescription/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { FC, useState } from 'react';

interface Props {
  description: string;
}

export const ProductDescription: FC<Props> = ({ description }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <DescriptionWrapper
        sx={{
          WebkitLineClamp: expanded ? 'unset' : 3,
        }}
      >
        {description}
      </DescriptionWrapper>
      {description.length > 200 && (
        <ViewButtonWrapper>
          <Button
            sx={link}
            variant="text"
            endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'View Less' : 'View More'}
          </Button>
        </ViewButtonWrapper>
      )}
    </>
  );
};
