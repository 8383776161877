import * as TK from 'components/AccountAccess/translations/locale.constant';

export const contactUsAccountAccessLocale = {
  [TK.TITLE]: 'log in or create an account',
  [TK.BODY]: 'With your account, you can',
  [TK.EARN_TXT]: ' earn and use rewards ',
  [TK.ORDER_TXT]: 'on this order.',
  [TK.CONTACTUS_TXT]:
    'Manage your orders, earn rewards, and get exclusive offers with your account.',
  [TK.REWARDS_TXT]: ' exclusive rewards. ',
  [TK.LOGIN]: 'Log In',
  [TK.CREATE_ACCOUNT]: 'Create Account',
  [TK.WELCOME_BACK]: 'welcome back!',
  [TK.APPLY_REWARDS]:
    'By logging in, you can now view and manage your orders on your Account page.',
  [TK.ACCOUNT_TXT]: 'My Account',
  [TK.REWARD_TOOLTIP]: 'You can use only one Reward or Promo code per order. ',
  [TK.ALLERGY_TEXT]: 'Allergy & Safety Information',
  [TK.FAQ_TEXT]: 'FAQs',
  [TK.NO_REWARDS]: 'You currently have no rewards available.',
  [TK.NO_REWARDS_MESSAGE]:
    'With your account, every order you place gets you closer to your next reward! Checkout now to earn more.',
};
