export const snackbar = {
  width: ['96%', '90%', '100%'],

  '& p': {
    fontWeight: 500,
  },

  '& .MuiPaper-root.MuiPaper-elevation': {
    color: 'warning.contrastText',
  },
};
