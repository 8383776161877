import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';

export const getAvailabilityByType = (
  { pickup, delivery, shipment }: AvailabilityOnDate,
  type: FulfillmentOptionType,
): OrderTypeAvailability =>
  ({
    [OrderType.Pickup]: pickup,
    [OrderType.Delivery]: delivery,
    [OrderType.Shipment]: shipment,
  }[type]);
