import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.locale.constants';
import * as styles from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/styles';
import { Area } from 'api/area/types/area.interface';
import { AvailableDate } from 'api/availability-on-date/types/available-date.interface';
import { Box, RadioGroup, Typography } from '@mui/material';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { RadioButton } from 'components/common/Buttons/RadioButton/RadioButton';
import { analytics } from 'service/adobe-analytics/analytics';
import { dateToSQLDateString, parseSqlDate } from 'utils/date';
import { format } from 'date-fns';
import { selectCatalogNumber } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC, MutableRefObject } from 'react';
import i18next from 'i18next';

type Props = {
  dateSelected: Date | null;
  availableDates: AvailableDate[];
  setNewDate: (date: Date) => void;
  isFulfillmentAvailable: boolean;
  area: Area | null;
  product: Product | null;
  orderType: string;
  collectionPromptRef?: MutableRefObject<HTMLInputElement | null>;
};
export const PickupDates: FC<Props> = ({
  dateSelected,
  availableDates,
  setNewDate,
  isFulfillmentAvailable,
  area,
  product,
  orderType,
  collectionPromptRef,
}) => {
  const catalogNumber = useSelector(selectCatalogNumber);
  const onPickupDateChange = (newSuggestedDate: Date) => {
    setNewDate(newSuggestedDate);
    if (dateSelected && area && product) {
      const productSKU = `${catalogNumber}-${product.name}`;
      analytics.SuggestedDateClickEvent(
        orderType,
        dateSelected,
        area,
        productSKU,
        newSuggestedDate,
      );
    }
  };

  return (
    <>
      <Box ref={collectionPromptRef}>
        {availableDates.length > 0 && isFulfillmentAvailable ? (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              {i18next.t(TK.CHOOSE_ANOTHER_DATE)}
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ mt: 3, mb: 2 }} variant="subtitle1">
            {i18next.t(TK.CHOOSE_ANOTHER_DATE)}
          </Typography>
        )}
        <RadioGroup
          value={dateSelected && dateToSQLDateString(dateSelected)}
          onChange={(_, value) => {
            onPickupDateChange(parseSqlDate(value));
          }}
        >
          {availableDates.map(({ date: suggestedDate, available }) => {
            return (
              <RadioButton
                key={suggestedDate}
                labelPosition="start"
                disabled={!available}
                sx={styles.suggestedDate}
                value={suggestedDate}
              >
                <Box display="flex" justifyContent="space-between">
                  {format(parseSqlDate(suggestedDate), 'eeee, d LLL')}
                </Box>
              </RadioButton>
            );
          })}
        </RadioGroup>
      </Box>
    </>
  );
};
