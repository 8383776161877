import { colors } from 'utils/theme/colors';
import { styled } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';

export const Container = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(11),
  },
}));

export const SocialSection = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(6),
  },
}));

export const SectionWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.breakpoints.values.md,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 8.75),
  },
}));

export const SubscriptionSectionWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  border: `${colors.primaryRed} 16px solid`,
}));

export const InfoSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 2.5),
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6, 5),
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(6, 0),
    textAlign: 'left',
  },
}));

export const SocialAndPaymentsSection = styled('section', {
  shouldForwardProp: isPropValid,
})<{
  displayUpMdRow?: boolean;
}>(
  ({ theme, displayUpMdRow }) =>
    displayUpMdRow && {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
);
