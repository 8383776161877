import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { StoreDetailsResponse } from 'interfaces/store-details-page.interfaces';
import { createSlice } from '@reduxjs/toolkit';
import {
  getStoreRanking,
  retrieveStoreDetails,
  saveRanking,
} from 'components/StoreDetails/feature/actions';

export type StoreDetailState = {
  status: Status;
  data: StoreDetailsResponse;
  storeRanking: number;
  rankingStatus: Status;
  StoreRankingUpdate: string;
  updateRankingStatus: Status;
  error: ResponseError | null;
};
const initialState: StoreDetailState = {
  data: {},
  storeRanking: 0,
  rankingStatus: Status.INIT,
  StoreRankingUpdate: '',
  updateRankingStatus: Status.INIT,
  status: Status.INIT,
  error: null,
};

export const slice = createSlice({
  name: 'stores/details',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(retrieveStoreDetails.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(
        retrieveStoreDetails.fulfilled,
        (state, { payload }: PayloadAction<StoreDetailsResponse>) => {
          state.data = payload;
          state.status = Status.FULFILLED;
        },
      )
      .addCase(retrieveStoreDetails.rejected, (state) => {
        state.status = Status.ERROR;
      })

      .addCase(getStoreRanking.fulfilled, (state, { payload }) => {
        state.storeRanking = payload;
        state.rankingStatus = Status.FULFILLED;
      })
      .addCase(getStoreRanking.pending, (state) => {
        state.rankingStatus = Status.PENDING;
      })
      .addCase(getStoreRanking.rejected, (state) => {
        state.rankingStatus = Status.ERROR;
      })
      .addCase(saveRanking.pending, (state) => {
        state.updateRankingStatus = Status.PENDING;
      })
      .addCase(saveRanking.rejected, (state, { payload }) => {
        state.updateRankingStatus = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addCase(saveRanking.fulfilled, (state, { payload }) => {
        state.updateRankingStatus = Status.FULFILLED;
        state.StoreRankingUpdate = payload.data;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
  },
});

export const storeDetailsReducer = slice.reducer;
export const { reset } = slice.actions;
