import { AxiosError } from 'axios';
import { SeverityLevel } from 'utils/telemetry/severity-level.enum';
import { appInsights } from 'utils/telemetry/app-insights';

const isNotHtmlError = (error: AxiosError<string>) =>
  typeof error?.response?.data !== 'string' ||
  !error?.response?.headers ||
  typeof error.response.headers !== 'object' ||
  !Object.hasOwnProperty.call(error.response.headers, 'content-type') ||
  typeof (error.response.headers as Record<string, string>)['content-type'] !==
    'string' ||
  !(error.response.headers as { 'content-type': string })[
    'content-type'
  ].startsWith('text/html');

export const htmlErrorInterceptor = (
  error: AxiosError,
): Promise<AxiosError> => {
  if (isNotHtmlError(error)) {
    return Promise.reject(error);
  }
  appInsights.trackException({
    exception: new Error(
      `Got HTML error: ${(error?.response?.data as string).slice(500)}...`,
    ),
    severityLevel: SeverityLevel.Critical,
  });

  const reason = {
    ...error,
    response: {
      ...error.response,
      data: 'pdp.general.error.something_went_wrong',
    },
  };

  return Promise.reject(reason);
};
