import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { StoresState } from 'redux/types/store.types';
import { createSlice } from '@reduxjs/toolkit';
import { getAvailableStoresForPickup } from 'redux/PDP/availability/for-pickup/pickup.actions';

const initialState: StoresState = {
  data: [],
  status: Status.INIT,
  error: null,
};

const pickupSlice = createSlice({
  name: 'pickup',
  initialState,
  reducers: {
    resetPickupStoresState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableStoresForPickup.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(getAvailableStoresForPickup.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.data = payload;
      })
      .addCase(getAvailableStoresForPickup.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: pickupReducer, actions: pickupActions } = pickupSlice;

export const { resetPickupStoresState } = pickupSlice.actions;
