import { Box, Card, Divider, Skeleton, SxProps } from '@mui/material';
import { colors } from 'utils/theme/colors';
import React, { FC } from 'react';

export const PasControlSkeleton: FC = () => {
  const baseCardSxStyles: SxProps = {
    border: ['none', '1px solid'],
    borderColor: ['transparent', colors.borderBlack],
    boxShadow: 'none',
    display: ['flex'],
    px: ['10px', '25px'],
    py: ['15px', '35px', '35px'],
    flexDirection: ['column'],
    marginBottom: ['30px', '20px'],
  };

  const stepNumberStyles: SxProps = {
    width: ['45px', '35px', '35px'],
    height: ['45px', '35px', '35px'],
  };

  const stepDescriptorStyles: SxProps = {
    marginLeft: ['15px'],
    height: stepNumberStyles.height,
  };

  const displayFlex: SxProps = {
    display: ['flex'],
  };

  return (
    <>
      <Divider />
      <Box
        sx={{
          ...displayFlex,
          flexDirection: ['column'],
          marginTop: ['30px'],
          padding: ['20px', '0px'],
          marginBottom: ['0px', '20px'],
        }}
      >
        <Skeleton
          sx={{
            marginBottom: ['40px', '25px'],
            height: ['50px'],
            width: ['35%', '40%'],
          }}
        />
        <Card sx={baseCardSxStyles}>
          <Box sx={displayFlex}>
            <Skeleton sx={stepNumberStyles} />
            <Skeleton
              sx={{
                ...stepDescriptorStyles,
                width: ['40%'],
              }}
            />
          </Box>
          <Skeleton
            sx={{
              height: ['65px', '45px', '45px'],
              width: ['100%'],
              marginTop: ['20px'],
            }}
          />
        </Card>
        <Card sx={baseCardSxStyles}>
          <Box sx={displayFlex}>
            <Skeleton sx={stepNumberStyles} />
            <Skeleton
              sx={{
                ...stepDescriptorStyles,
                width: ['20%'],
              }}
            />
          </Box>
        </Card>
        <Card sx={baseCardSxStyles}>
          <Box sx={displayFlex}>
            <Skeleton sx={stepNumberStyles} />
            <Skeleton
              sx={{
                ...stepDescriptorStyles,
                width: ['50%'],
              }}
            />
          </Box>
        </Card>
      </Box>
    </>
  );
};
