import { OrderTrackResponse } from 'api/order/types';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  trackEnterpriseOrder,
  trackOrder,
} from 'components/CustomerService/OrderTracking/feature/actions';

type State = {
  status: Status;
  data: OrderTrackResponse;
  enterpriseOrder: OrderTrackResponse;
  isEnterpriseOrder: boolean;
  error: ResponseError | null;
};
const initialState: State = {
  data: {
    order: {},
    parentProducts: [],
    childProducts: [],
    status: [],
    tracking: {},
    driverTracking: undefined,
  },
  enterpriseOrder: {
    order: {},
    parentProducts: [],
    childProducts: [],
    status: [],
    tracking: {},
    driverTracking: undefined,
  },
  isEnterpriseOrder: false,
  status: Status.INIT,
  error: null,
};

export const slice = createSlice({
  name: 'customer-service/order-tracking',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(trackOrder.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(
        trackOrder.fulfilled,
        (state, { payload }: PayloadAction<OrderTrackResponse | undefined>) => {
          if (payload) {
            state.data = payload;
            state.status = Status.FULFILLED;
          } else {
            state.status = Status.NOT_FOUND;
          }
        },
      )
      .addCase(trackOrder.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(trackEnterpriseOrder.pending, (state) => {
        state.status = Status.PENDING;
        state.isEnterpriseOrder = false;
      })
      .addCase(
        trackEnterpriseOrder.fulfilled,
        (state, { payload }: PayloadAction<OrderTrackResponse | undefined>) => {
          if (payload) {
            state.enterpriseOrder = payload;
            state.status = Status.FULFILLED;
            state.isEnterpriseOrder = true;
          } else {
            state.status = Status.NOT_FOUND;
          }
        },
      )
      .addCase(trackEnterpriseOrder.rejected, (state) => {
        state.status = Status.ERROR;
        state.isEnterpriseOrder = false;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
  },
});

export const orderTrackingReducer = slice.reducer;
export const { reset } = slice.actions;
