import {
  OwnBoxAddon,
  OwnBoxOptions,
} from 'redux/PDP/arrangement/types/own-box.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { range } from 'utils/range';

const createHandlerKey = (
  cells: number,
  rows: number,
  quantity: number,
): string => `${cells}_${rows}_${quantity}`;

const duplicateItems = <T extends { quantity?: number }>(
  items: T[],
  amount = 1,
): T[] => {
  return items.reduce<T[]>(
    (acc, item) => [
      ...acc,
      ...range(Math.round(item.quantity || amount)).map<T>(() => item),
    ],
    [],
  );
};

const duplicateItemRows = <T>(items: T[], amount = 1): T[] =>
  range(amount)
    .map<T[]>(() => items)
    .flat();

const extendHandlers: Record<string, (addons: UpsellItem[]) => UpsellItem[]> = {
  [createHandlerKey(6, 2, 2)]: (addons) => duplicateItemRows(addons, 2),
  [createHandlerKey(12, 2, 4)]: (addons) =>
    duplicateItemRows(duplicateItems(addons, 2), 2),
  [createHandlerKey(12, 3, 6)]: (addons) => duplicateItems(addons, 2),
  [createHandlerKey(12, 3, 3)]: (addons) => duplicateItemRows(addons, 3),
  default: (addons) => duplicateItems(addons, addons[0]?.quantity),
};

export const getDessertBoardExtendAddonsByQuantity = (
  addons: UpsellItem[],
  options: OwnBoxOptions,
  clickOwnBoxAddon?: OwnBoxAddon[],
  addOnIds?: number[],
): UpsellItem[] => {
  let arr: UpsellItem[] = [];
  clickOwnBoxAddon?.map((item) => {
    const clickedItem = addons.filter(
      (x) => x.option.addonId === item?.addonId,
    );
    const res = (
      extendHandlers[
        createHandlerKey(
          options?.cells,
          options.rows,
          options?.cells / addons.length,
        )
      ] || extendHandlers.default
    )(clickedItem);
    arr = arr.concat(res);
    if (addOnIds) {
      arr = arr.sort(
        (a, b) =>
          addOnIds.indexOf(a.option.addonId) -
          addOnIds.indexOf(b.option.addonId),
      );
    }
    return arr;
  });
  return arr;
};

export const getCookiesBoardExtendAddonsByQuantity = (
  addons: UpsellItem[],
  options: OwnBoxOptions,
): UpsellItem[] => {
  const cookieLimitedName = 'Cookie Box - LTO';
  const limitedCookies = addons.filter((addon) =>
    addon.option.optionName?.includes(cookieLimitedName),
  );
  const everyDayCookie = addons.filter(
    (addon) => !addon.option.optionName?.includes(cookieLimitedName),
  );
  let cookieItems: UpsellItem[] = [];
  /** bottom stack */
  const handlerKey = createHandlerKey(
    options?.cells,
    options.rows,
    options?.cells / everyDayCookie.length,
  );
  const handler = extendHandlers[handlerKey] || extendHandlers.default;
  cookieItems = cookieItems.concat(handler(everyDayCookie));

  /** top stack */
  const handlerKeylimited = createHandlerKey(
    options?.cells,
    options.rows,
    options?.cells / limitedCookies.length,
  );
  const handlerLimited =
    extendHandlers[handlerKeylimited] || extendHandlers.default;
  cookieItems = cookieItems.concat(handlerLimited(limitedCookies));

  if (limitedCookies.length <= 0) {
    cookieItems = cookieItems.concat(handler(everyDayCookie));
  }
  if (everyDayCookie.length <= 0) {
    cookieItems = cookieItems.concat(handlerLimited(limitedCookies));
  }
  return cookieItems;
};

export const extendAddonsByQuantity = (
  addons: UpsellItem[],
  options: OwnBoxOptions,
  clickOwnBoxAddon?: OwnBoxAddon[],
  addOnIds?: number[],
  isCookie12?: boolean,
): UpsellItem[] => {
  /** Dessert Board */
  if (clickOwnBoxAddon && clickOwnBoxAddon?.length > 0) {
    return getDessertBoardExtendAddonsByQuantity(
      addons,
      options,
      clickOwnBoxAddon,
      addOnIds,
    );
  }
  /** Cookies Board */
  if (isCookie12) {
    return getCookiesBoardExtendAddonsByQuantity(addons, options);
  }
  /** Default OwnBox */
  return (
    extendHandlers[
      createHandlerKey(
        options?.cells,
        options.rows,
        options?.cells / addons.length,
      )
    ] || extendHandlers.default
  )(addons);
};
