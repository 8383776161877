import * as styles from 'components/Home/CarouselSimplified/components/CarouselItem/styles';
import { Box, Link } from '@mui/material';
import { CarouselItemImageSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton/CarouselItemImageSkeleton';
import { CarouselSimplifiedItem } from 'components/Home/CarouselSimplified/types';
import { Image } from 'components/common/Image/Image';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { checkForFrench } from 'components/Home/utils';
import { isDefined } from 'utils/is-defined';
import { isIE } from 'utils/isIE';
import { noImageAvailable } from 'utils/cloudinaryAssets';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useIsUpMd } from 'utils/hidden/hide-up-md';
import { useSelector } from 'react-redux';
import { webkitLineClamp } from 'webkit-line-clamp';
import React, { FC, useRef, useState } from 'react';

interface Props {
  item: CarouselSimplifiedItem;
  sequence: number;
  analyticsClickHandler?: () => void;
}

export const CarouselItem: FC<Props> = ({
  item,
  sequence,
  analyticsClickHandler,
}) => {
  const [imgSrc, setImgSrc] = useState<string>(item.src);
  const [frImgSrc, setFrImgSrc] = useState<string>(item.frSrc);
  const isUpMd = useIsUpMd();
  const titleRef = useRef<HTMLDivElement | null>(null);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const handleLoadImageFail = () => {
    setImgSrc(noImageAvailable);
    setFrImgSrc(noImageAvailable);
  };

  /**
   *   @notice "webkitLineClamp" is used as polyfill
   *   for css "line-clamp" property for IE browser
   */

  if (isIE && isDefined(titleRef.current)) {
    webkitLineClamp(titleRef.current, 3);
  }

  return (
    <Link
      href={item.url}
      sx={styles.container}
      key={item.id}
      data-test="carousel-simplified-item"
      color="secondary"
      underline="none"
      onClick={() => {
        segment.occasionSelected(
          item.name,
          item.url,
          sequence,
          'Home Page',
          userSession,
          userAccountInformation,
        );
        if (analyticsClickHandler) analyticsClickHandler();
      }}
    >
      <Box overflow="hidden" borderRadius="50%" sx={styles.carouselItem}>
        <Image
          role="presentation"
          src={checkForFrench(imgSrc, frImgSrc)}
          loading={<CarouselItemImageSkeleton />}
          onError={handleLoadImageFail}
          alt={item.name}
          observerMargins="0px 0px 400px"
          data-test="carousel-simplified-item-image"
        />
      </Box>
      <Box ref={titleRef} width={1}>
        <PureHtmlTypography
          sx={styles.title}
          variant={isUpMd ? 'subtitle1' : 'subtitle2'}
          component="p"
          data-test="carousel-simplified-title"
        >
          {checkForFrench(item.name, item.frName)}
        </PureHtmlTypography>
      </Box>
    </Link>
  );
};
