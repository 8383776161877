import * as styles from 'components/Home/NotificationBanner/styles';
import { Box, Button } from '@mui/material';
import {
  CloseIconContainer,
  CloseIconWrapper,
  NotificationBannerItemContainer,
} from 'components/Home/NotificationBanner/styles';
import { ComponentProps } from 'components/Home/types';
import { DottedSlider } from 'components/common/DottedSlider';
import { EASTERN_TIME } from 'utils/date';
import { NotificationBannerContent } from 'components/Home/NotificationBanner/types';
import { NotificationBannerItem } from 'components/Home/NotificationBanner/NotificationBannerItem';
import {
  analyticInitialnotificationBannerLoad,
  notificationBannerClosed,
  notificationBannerNextMessage,
} from 'components/Home/NotificationBanner/utils/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getComponent } from 'components/Home/feature/selectors';
import {
  getData,
  getSameDayDeliveryCutOff,
} from 'components/Home/NearestStore/feature/selectors';
import { getRemainingTime } from 'components/Home/NotificationBanner/utils/getRemainingTime';
import { intervalToDuration, set } from 'date-fns';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import { utcToZonedTime } from 'date-fns-tz';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, useEffect, useState } from 'react';

export const NotificationBanner: FC<ComponentProps> = ({ type, id }) => {
  const { storeOpen } = useSelector(getData);
  const getCutOffTime = useSelector(getSameDayDeliveryCutOff);
  const componentData = useSelector(getComponent(type, id));
  const content = useResponsiveContent<NotificationBannerContent>(
    componentData,
    'md',
  );
  const [hoursRemaining, setHoursRemaining] = useState<number>(0);
  const [minutesRemaining, setMinutesRemaining] = useState<number>(0);
  const [isBannerCloseButtonPressed, setBannerCloseButtonPressed] =
    useState<boolean>(false);
  const [cutOffTimeHasBeenReached, setCutOffTimeHasBeenReached] =
    useState<boolean>(true);

  const { available, autoSlideInterval, items } = content;

  const currentDateTime = new Date();
  const activeFrom = utcToZonedTime(
    new Date(componentData.activeFrom),
    EASTERN_TIME,
  );

  const showBanner = (
    isDeliveryCutoffTime: boolean,
    isPromoMessage: boolean,
    promoEndTime: string,
    cutoffTime: number,
  ): boolean => {
    if (isDeliveryCutoffTime) {
      return (
        !isBannerCloseButtonPressed &&
        !!storeOpen &&
        !cutOffTimeHasBeenReached &&
        (hoursRemaining < cutoffTime ||
          (hoursRemaining === cutoffTime && !minutesRemaining))
      );
    }
    if (isPromoMessage) {
      const endDate = utcToZonedTime(new Date(promoEndTime), EASTERN_TIME);
      return activeFrom <= endDate && currentDateTime <= endDate;
    }
    return true;
  };

  const displayedBanner = items.filter((item) =>
    showBanner(
      item.isDeliveryCutoffTime,
      item.isPromoMessage,
      item.promoEndTime,
      item.cutoffTime,
    ),
  );

  const notificationBannerText: string[] = displayedBanner.map((item) =>
    checkForFrench(item.bannerText, item.frBannerText),
  );
  useEffect(() => {
    analyticInitialnotificationBannerLoad(notificationBannerText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getCutOffTime) {
      const date = new Date(getCutOffTime);
      const cutOffDateTime = utcToZonedTime(date, EASTERN_TIME);
      const cutOffTime = set(cutOffDateTime, {
        year: currentDateTime.getFullYear(),
        month: currentDateTime.getMonth(),
        date: currentDateTime.getDate(),
      });
      if (currentDateTime <= cutOffTime) {
        const duration = intervalToDuration({
          start: currentDateTime,
          end: cutOffTime,
        });

        if (duration.hours || duration.minutes || duration.seconds) {
          const minutes = duration.minutes || 0;
          const hours = duration.hours || 0;

          setMinutesRemaining(minutes);
          setHoursRemaining(hours);
          if (minutes && duration.seconds) {
            setMinutesRemaining(minutes + 1);
          }
          if (minutes === 59 && duration.seconds) {
            setMinutesRemaining(0);
            setHoursRemaining(hours + 1);
          }
          if (minutes === 0 && duration.seconds) {
            setMinutesRemaining(minutes + 1);
          }
          setCutOffTimeHasBeenReached(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCutOffTime, minutesRemaining, hoursRemaining]);

  if (!available) {
    return null;
  }

  const getPromotionalBannerTextBasedOnRemainingTime = (end: Date): string => {
    let messgae = '';
    const start = utcToZonedTime(new Date(currentDateTime), EASTERN_TIME);
    if (start <= end) {
      const duration = intervalToDuration({
        start,
        end,
      });
      if (
        duration.years ||
        duration.months ||
        duration.days ||
        duration.hours ||
        duration.minutes ||
        duration.seconds
      ) {
        const years = duration.years || 0;
        const months = duration.months || 0;
        const days = duration.days || 0;
        let minutes = duration.minutes || 0;
        let hours = duration.hours || 0;

        if (minutes && duration.seconds) {
          minutes += 1;
        }
        if (minutes === 59 && duration.seconds) {
          minutes = 0;
          hours += 1;
        }
        if (minutes === 0 && duration.seconds) {
          minutes += 1;
        }
        messgae = getRemainingTime(minutes, hours, days, months, years);
      }
    }
    return messgae;
  };

  const getSliderAppendDots = (dots: React.ReactNode) => {
    return (
      <ul>
        {Array.isArray(dots) &&
          dots.map((dot, key: number) => (
            <Button
              sx={{
                p: 0,
                m: 0,
                background: 'none',
                minWidth: 'auto',
                ':hover': { backgroundColor: 'transparent' },
              }}
              key={displayedBanner[key].sequence}
              onClick={() =>
                notificationBannerNextMessage(
                  checkForFrench(
                    displayedBanner[key].bannerText,
                    displayedBanner[key].frBannerText,
                  ),
                )
              }
            >
              {dot}
            </Button>
          ))}
      </ul>
    );
  };

  const settings = {
    slidesToScroll: 1,
    variableWidth: false,
    initialSlide: 0,
    infinite: true,
    swipe: true,
    autoplay: true,
    adaptiveHeight: true,
    appendDots: (dots: React.ReactNode) => getSliderAppendDots(dots),
  };

  return (
    <Box data-test="notification-banners" sx={styles.sameDayBannerStyles}>
      {!isBannerCloseButtonPressed && !!displayedBanner.length && (
        <DottedSlider {...settings} autoplaySpeed={autoSlideInterval}>
          {displayedBanner.map((item) => {
            let message = '';
            if (item.isDeliveryCutoffTime) {
              message = getRemainingTime(minutesRemaining, hoursRemaining);
            }
            if (item.isPromoMessage) {
              const endDate = utcToZonedTime(
                new Date(item.promoEndTime),
                EASTERN_TIME,
              );
              message = getPromotionalBannerTextBasedOnRemainingTime(endDate);
            }
            const tempButtonWrapper = document.createElement('div');
            tempButtonWrapper.innerHTML = checkForFrench(
              item.buttonText,
              item.frButtonText,
            );
            const buttonTextHtml = tempButtonWrapper.firstChild;
            const isButtonText = !!buttonTextHtml?.textContent;
            return (
              <NotificationBannerItemContainer
                key={item.sequence}
                bgColor={item.bannerBGColor}
                isButtonText={isButtonText}
              >
                <CloseIconWrapper isButtonText={isButtonText}>
                  <CloseIconContainer
                    onClick={() => {
                      setBannerCloseButtonPressed(true);
                      notificationBannerClosed(notificationBannerText);
                    }}
                    isButtonText={isButtonText}
                  >
                    <CloseIcon sx={{ width: 'auto', height: 'auto' }} />
                  </CloseIconContainer>
                </CloseIconWrapper>
                <NotificationBannerItem
                  key={item.sequence}
                  sequence={item.sequence}
                  bannerBGColor={item.bannerBGColor}
                  bannerText={checkForFrench(
                    item.bannerText,
                    item.frBannerText,
                  )}
                  buttonText={checkForFrench(
                    item.buttonText,
                    item.frButtonText,
                  )}
                  buttonCTALink={item.buttonCTALink}
                  buttonBGColor={item.buttonBGColor}
                  message={message}
                  isButtonText={isButtonText}
                />
              </NotificationBannerItemContainer>
            );
          })}
        </DottedSlider>
      )}
    </Box>
  );
};
