import { Alert, Link, SxProps, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { storeWidgetSequenceBackground } from 'utils/cloudinaryAssets';

export const StoreInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRight: `1px solid ${colors.dividerBlack}`,
  paddingRight: '12px',
  [theme.breakpoints.down(520)]: {
    border: 'none',
    paddingRight: '0px',
  },
}));

export const highlightedStore: SxProps<Theme> = (theme) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  textDecoration: 'none',
  '& h6:first-of-type': {
    color: theme.palette.primary.main,
  },
});

export const StoreWidgetWrapper = styled(Link)(({ theme }) => ({
  border: `1px solid ${colors.borderBlack}`,
  display: 'flex',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  width: '100%',
  cursor: 'pointer',
  padding: '16px 20px',
  marginBottom: 16,
  textDecoration: 'none',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    textDecoration: 'none',
    '& h6:first-of-type': {
      color: theme.palette.primary.main,
    },
  },
  '& svg': {
    height: 20,
    width: 20,
    color: theme.palette.primary.main,
    marginRight: 12,
  },
  '& h6': {
    color: theme.palette.text.primary,
  },
  'p:first-of-type': {
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down(520)]: {
    flexDirection: 'column',
  },
}));

export const StoreInfoAndSpecialStoreHoursAndTagIconWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down(520)]: {
      border: 'none',
      flexDirection: 'column',
    },
  }),
);

export const SequenceAndDistance = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: colors.systemBackground,
  justifyContent: 'space-evenly',
  width: 'max-content',
  paddingRight: '24px',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '4px',
  marginBottom: '12px',
  [theme.breakpoints.down(520)]: {
    width: '100%',
    padding: '4px 8px',
    justifyContent: 'flex-start',
  },
}));

export const sequence: ThemedSxProps = (theme) => ({
  borderRadius: '50%',
  height: '32px',
  width: '32px',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${storeWidgetSequenceBackground})`,
  backgroundSize: 'contain',
  'p:first-of-type': {
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
    WebkitTextStroke: `thin ${theme.palette.primary.main}`,
    WebkitTextFillColor: theme.palette.primary.contrastText,
    height: '16px',
    fontWeight: 900,
  },
});

export const distance: ThemedSxProps = {
  ml: '10px',
  'p:first-of-type': {
    margin: '0px',
    padding: '0px',
    fontWeight: 600,
    fontSize: '16px',
    pt: '4px',
    color: 'black',
  },
};

export const DirectionsAndPhoneNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.MuiSvgIcon-root': {
    margin: '0px',
  },
  'p:first-of-type': {
    padding: '0px',
    margin: '0px',
    width: 'max-content',
    paddingLeft: '12px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(375)]: {
      paddingLeft: '6px',
    },
  },
  '.MuiTypography-root': {
    fontWeight: 600,
    [theme.breakpoints.down(520)]: {
      fontSize: '13px',
    },
  },
  [theme.breakpoints.down(520)]: {
    flexDirection: 'row',
    padding: '20px 0px 16px',
    justifyContent: 'space-between',
  },
}));

export const getDirectionsButton: ThemedSxProps = (theme) => ({
  marginBottom: '24px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
  marginLeft: '8px',
  textWrap: 'nowrap',
  paddingTop: '8px',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: `${colors.redButtonHover} 8%`,
  },
  [theme.breakpoints.down(520)]: {
    margin: '0px',
  },
});

export const storePhoneNumber: SxProps<Theme> = (theme) => ({
  display: 'flex',
  paddingLeft: '8px',
  marginLeft: '8px',
  justifyContent: 'start',
  marginBottom: '24px',
  paddingTop: '8px',
  [theme.breakpoints.down(520)]: {
    margin: '0px',
  },
  '.MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    letterSpacing: 0.4,
    lineHeight: 1.6,
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: `${colors.redButtonHover} 8%`,
  },
});

export const StoreHours = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '225px',
  height: '24px',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '4px',
  [theme.breakpoints.down(520)]: {
    width: '100%',
  },
  'p:first-of-type': {
    marginBottom: '0px',
  },
  p: {
    marginBottom: '0px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    color: colors.activeBlack,
  },
}));

export const SpecialStoreHoursAndTags = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const SpecialStoreHoursAlert = styled(Alert)({
  marginRight: '16px',
  marginTop: '16px',
  '& .MuiSvgIcon-root': {
    color: '#FF9900',
    marginRight: '0px',
  },
  '& .MuiAlert-message': {
    display: 'flex',
    flexDirection: 'column',
    color: '#00000099',
  },
});
export const topSpecialStoreHoursText: ThemedSxProps = {
  paddingBottom: '8px',
};

export const TagRow = styled('div')({
  marginTop: '8px',
  display: 'flex',
  'div:not(:last-child)': {
    marginRight: '12px',
  },
});

export const TagIcon = styled('div')({
  border: `1px solid ${colors.borderBlack}`,
  width: 'max-content',
  height: '32px',
  padding: '6px 8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  marginTop: 1.5,
  '& span': {
    color: colors.activeBlack,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '164%',
  },
});
