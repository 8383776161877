import * as TK from 'translations/locales/translation-keys.constant';
import {
  ENTER_VALID_ZIP,
  ZIP_SHOULD_BE_VALID,
} from 'utils/pas-control.constants';
import { ResourceLanguage } from 'i18next';
import { common } from 'translations/locales/common';
import { pluralize } from 'translations/utils';

export const locale: ResourceLanguage = {
  translations: {
    ...common,
    [TK.WE_GOT_AN_ERROR]: 'We got an error. Please try again in a few minutes',
    'pdp.general.error.something_went_wrong':
      'Something went wrong. Please try again in few moments',
    'pdp.main.error.smth_went_wrong':
      'Something went wrong while getting available dates for shipment. Please try again in few moments',
    'pdp.main.error.ship_on_given_date': 'Select another date for delivery',
    'pdp.main.error.store_not_found_for_mode': 'Store is not found for mode',
    'pdp.main.error.no_argument_available_for_catalog':
      'No Arrangement available for catalog: {{catalogNumber}}',
    'pdp.main.error.no_catalog_number_available_for_url':
      'No catalog number available for page friend url: {{pageFriendlyUrl}}',
    'pdp.main.error.no_ingredient_available_for_arrangement':
      'No Ingredients available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_products_available_for_arrangement':
      'No Products available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_reviews_available_for_arrangement':
      'No Reviews available for arrangementId: {{arrangementId}}',
    'pdp.main.error.cannot_find_store': 'Cannot find store to process order',
    'pdp.main.error.city_not_found': 'City not found',
    'pdp.main.error.cannot_parse_response': "Can't parse response",
    [ZIP_SHOULD_BE_VALID]: 'Enter a valid zip code',
    [ENTER_VALID_ZIP]: 'Enter a valid zip code to continue',
    'pdp.main.error.provided_zip': 'Zip should be provided',
    'pdp.main.error.provided_date': 'Date should be provided',
    'pdp.main.error.enter_zip_and_date':
      'Enter a zip code and choose a date to continue',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL]: 'Entrez le modèle de voiture',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR]:
      'Entrez la couleur de la voiture',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL]:
      'Entrez le modèle du véhicule',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR]:
      'Entrez la couleur du véhicule',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS]:
      'Entrez un modèle de voiture valide',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS]:
      'Entrez une couleur de voiture valide',
    [TK.NUMBER_OF_SIZES_ONE]: 'Taille unique',
    [TK.NUMBER_OF_SIZES_TWO]: 'Deux tailles',
    [TK.NUMBER_OF_SIZES_THREE]: 'Trois tailles',
    [TK.NUMBER_OF_SIZES_FOUR]: 'Quatre tailles',
    [TK.NUMBER_OF_SIZES_FIVE]: 'Cinq tailles',
    [TK.NUMBER_OF_SIZES_SIX]: 'Six tailles',
    [TK.NUMBER_OF_SIZES_SEVEN]: 'Sept tailles',
    [TK.NUMBER_OF_SIZES_EIGHT]: 'Huit tailles',
    [TK.NUMBER_OF_SIZES_NINE]: 'Neuf tailles',
    [TK.NUMBER_OF_SIZES_TEN]: 'Dix tailles',
    [TK.NUMBER_OF_SIZES_STARTING_AT]: 'À partir de',
    [TK.NUMBER_OF_SIZES_NUMERIC_QUANTITY]: 'Tailles commençant à',
    [TK.NUMBER_OF_SIZES_NO_SIZES]: 'Il n’y a pas de tailles disponibles',
    [TK.IMAGE_NOT_AVAILABLE]: 'Non disponible',
    [TK.CLOSE]: 'Fermer',
    [TK.DAY_OF_WEEK_SUNDAY_LONG]: 'Dimanche',
    [TK.DAY_OF_WEEK_MONDAY_LONG]: 'Lundi',
    [TK.DAY_OF_WEEK_TUESDAY_LONG]: 'Mardi',
    [TK.DAY_OF_WEEK_WEDNESDAY_LONG]: 'Mercredi',
    [TK.DAY_OF_WEEK_THURSDAY_LONG]: 'Jeudi',
    [TK.DAY_OF_WEEK_FRIDAY_LONG]: 'Vendredi',
    [TK.DAY_OF_WEEK_SATURDAY_LONG]: 'Samedi',
    [TK.DAY_OF_WEEK_SUNDAY_SHORT]: 'Sun',
    [TK.DAY_OF_WEEK_MONDAY_SHORT]: 'Mon',
    [TK.DAY_OF_WEEK_TUESDAY_SHORT]: 'Tue',
    [TK.DAY_OF_WEEK_WEDNESDAY_SHORT]: 'Wed',
    [TK.DAY_OF_WEEK_THURSDAY_SHORT]: 'Thu',
    [TK.DAY_OF_WEEK_FRIDAY_SHORT]: 'Fri',
    [TK.DAY_OF_WEEK_SATURDAY_SHORT]: 'Sat',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_UP]: 'Inscrivez-vous',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_OUT]: 'Se déconnecter',
    [TK.HEADER_USER_PROFILE_ACCOUNT]: 'Compte',
    [TK.HEADER_USER_PROFILE_MY_REWARDS]: 'Mes récompenses',
    [TK.HEADER_USER_PROFILE_FAQ]: 'FAQs & Avantages',
    [TK.HEADER_USER_PROFILE_MY_INFORMATION]: 'Mes informations',
    [TK.HEADER_USER_PROFILE_ORDER_HISTORY]: 'Historique des commandes',
    [TK.HEADER_USER_PROFILE_GIFT_REMINDERS]: 'Rappels de cadeaux',
    [TK.HEADER_USER_PROFILE_ADDRESS_BOOK]: 'Carnet d’adresses',
    [TK.HEADER_HELP]: 'Aider',
    [TK.HEADER_FIND_STORE]: 'Trouver un magasin',
    [TK.HEADER_EDIBLE_REWARDS]: 'Récompenses Edible',
    [TK.HEADER_SHOPPING_CART]: 'Caddie',
    [TK.SUBSCRIPTION_FOOTER_TITLE]:
      'Inscrivez-vous aux promotions et offres spéciales.',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL]: 'Adresse Courriel',
    [TK.SUBSCRIPTION_FOOTER_FORM_SUBMIT]: 'Soumettre',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR]:
      'Veuillez saisir une adresse e-mail valide',
    [TK.SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR]:
      'Saisir une e-mail valide',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Acheter maintenant',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE]: 'Voir ',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND]: 'et',
    [TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS]:
      'les Conditions Générales',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER]: ' pour plus de détails.',
    [TK.SUBSCRIPTION_EMAIL_FORM_LABEL]: 'Adresse e-mail*',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Acheter maintenant',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Découvrez votre réduction',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT]:
      'Rejoignez notre liste d’abonnés pour des économies immédiates sur votre prochaine commande de ramassage.',
    [TK.NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT]:
      'Rejoignez notre liste de diffusion par e-mail ou SMS pour ',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT]:
      'économies instantanées',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT]: 'instantané',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT]: 'économies',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT]:
      'sur votre prochaine commande de ramassage',
    [TK.SUBSCRIPTION_FIRST_POPUP_TITLE]:
      'Quelque chose que vous allez adorer !',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_VAL]: '15',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_PER]: '%',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT]: 'de rabais',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_STAR]: '*',
    [TK.SUBSCRIPTION_FIRST_POPUP_DESCRIPTION]:
      'sur votre prochaine commande de ramassage lorsque vous vous inscrivez pour recevoir nos courriels dès aujourd’hui !',
    [TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE]: 'Super !',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST]:
      'Merci de vous être inscrit ! Vérifiez votre boîte de réception',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST]:
      'pour obtenir 15% de rabais sur votre prochain achat en ramassage.',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE]:
      'Connectez-vous avec nous sur les réseaux sociaux :',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT]:
      'Commandez un cadeau fraîchement préparé pour toutes les occasions !',
    [TK.SUBSCRIPTION_IMAGE_TITLE]:
      'Délicieuses Fraises enrobées de Chocolat design®',
    [TK.ERROR_PHONE]: 'Numéro de téléphone invalide',
    [TK.ERROR_EMAIL]: 'Adresse courriel invalide',
    [TK.REQUIRED_EMAIL]: 'L’adresse courriel est obligatoire',
    [TK.ERROR_AGREE]: 'Vous devez accepter la politique',
    [TK.HOME_PAGE_VIEW_ALL]: 'Voir tout',
    [TK.HOME_PAGE_CAROUSEL_FORWARD_CONTROL]:
      'Faire avancer le carrousel pour révéler plus de produits',
    [TK.HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL]:
      'Faire reculer le carrousel pour afficher les produits précédents',
    [TK.NEAREST_STORE_WEEKLY_HOURS]:
      'Afficher les heures hebdomadaires du magasin le plus proche',
    [TK.NEAREST_STORE_PHONE_CONTACT]:
      'Appeler le magasin Edible le plus proche de votre emplacement',
    [TK.NEAREST_STORE_DIRECTIONS]:
      'Obtenir les indications pour le magasin Edible le plus proche de votre emplacement',
    [TK.HOME_PAGE_H1_HEADING]:
      'Bouquets de fruits Edible Arrangements, Fraises enrobées de chocolat et Livraison de cadeaux',
    [TK.FRANCHISE_BANNER_TITLE]: 'la meilleure opportunité de franchise',
    [TK.FRANCHISE_BANNER_BODY]:
      'Ouvrez votre propre magasin Edible® et rejoignez notre communauté passionnée et mondiale de franchises',
    [TK.FRANCHISE_BANNER_BUTTON]: 'En savoir plus',
    [TK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL]: 'Inscrivez-vous',
    [TK.POPULAR_CATEGORIES]: 'Catégories populaires',
    [TK.LOG_IN]: 'S’identifier',
    [TK.SUBSCRIPTION_TERMS_OF_USE]: 'Voir les conditions d’utilisation',
    [TK.SUBSCRIPTION_FORM_PHONE_LABEL]: 'Numéro de Téléphone',
    [TK.SUBSCRIPTION_FORM_PHONE_ERROR]:
      'Veuillez saisir un numéro de téléphone valide',
    [TK.SUBSCRIPTION_FORM_SIGNUP_PHONE_ERROR]:
      'Entrez un numéro de téléphone valide',
    [TK.SUBSCRIPTION_FORM_POLICY_LABEL]: 'J’accepte les',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP]:
      'J’accepte de recevoir des messages texte automatisés périodiques de ou au nom de Edible Arrangements, LLC edible.com des promotions, des offres spéciales et des réductions au numéro de téléphone fourni ci-dessus. Je comprends que mon consentement n’est pas requis et ne constitue pas une condition de tout achat. Les frais standard de messagerie texte et de données s’appliquent. Tous les transporteurs ne sont pas couverts. Je comprends que le fait de cocher cette case constitue ma signature. Pour vous désinscrire, répondez à un SMS EA avec STOP to stop et HELP for Help.',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL]: 'de Communication.',
    [TK.SUBSCRIPTION_FORM_POLICY_ERROR]: 'Veuillez vérifier la politique',
    [TK.SUBSCRIPTION_SIGNUP_POLICY_ERROR]: 'Veuillez accepter pour continuer',
    [TK.SOCIAL_LINKS_TITLE]: 'Suivez-nous',
    [TK.PAYMENT_OPTIONS_TITLE]: 'Paiements Sécurisés Par',
    [TK.NEAREST_STORE_TITLE]: 'Votre plus Proche Edible Boutique',
    [TK.NEAREST_STORE_TODAY_LABEL]: 'Aujourd’hui',
    [TK.NEAREST_STORE_DIRECTIONS_LABEL]: 'Obtenir L’itinéraire',
    [TK.TERMS_OF_USE_LABEL]: 'Conditions d’Utilisation',
    [TK.PRIVACY_POLICY_LABEL]: 'Politique de Confidentialité',
    [TK.CALIFORNIA_PRIVACY_RIGHTS_LABEL]: 'California Droit à la vie Privée',
    [TK.CORPORATE_PROMOTION_LABEL]: 'Conditions Générales de la Promotion',
    [TK.COOKIE_SETTINGS_LABEL]: 'Paramètres des Cookies',
    [TK.CORPORATE_COPYRIGHT_LABEL]:
      'Edible Arrangements, LLC. Tous droits réservés.',
    [TK.DESKTOP_SEARCH_PLACEHOLDER]: 'Rechercher',
    [TK.SEARCH_SUGGESTED_RESULTS]: 'Résultats Suggérés:',
    [TK.SEARCH_NO_SUGGESTIONS]: 'Aucune suggestion trouvée',
    [TK.SEARCHING_TEXT]: 'Recherche de suggestions',
    [TK.ALL_RESULTS]: 'Tous les Résultats',
    [TK.RESULT]: 'Résultat',
    [pluralize(TK.RESULT)]: 'Résultats',
    [TK.HEADER_NAV_MORE_LINKS]: 'Plus de Liens',
    [TK.HEADER_NAV_LANGUAGE]: 'Langue',
    [TK.HEADER_NAV_CUSTOMER_SERVICE]: 'Service Client',
    [TK.HEADER_NAV_STORES]: 'Magasins',
    [TK.HEADER_NAV_ABOUT]: 'Environ',
    [TK.HEADER_NAV_ABOUT_EDIBLE]: 'Environ Edible Arrangements',

    [TK.HEADER_HELP_TRACK_YOUR_ORDER]: 'Suivez Votre Commande',
    [TK.HEADER_HELP_CONTACT_US]: 'Contactez-nous',
    [TK.HEADER_HELP_ALLERGY_SAFETY_INFORMATION]:
      'Informations sur les Allergies et la Sécurité',
    [TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS]:
      'Conditions Générales de la Promotion',
    [TK.HEADER_HELP_CERTIFIED_HAPPINESS]: 'Bonheur Certifié',
    [TK.HEADER_HELP_FAQS]: 'FAQs',
    [TK.HEADER_HELP_PRODUCTS_POLICY]: 'Politique Relative aux Produits',

    [TK.HEADER_STORES_FRANCHISE_OPPORTUNITIES]: 'Opportunités de Franchise',
    [TK.HEADER_STORES_EDIBLE_TREATS]: 'Edible® Traite',
    [TK.HEADER_ABOUT_WHO_WE_ARE]: 'Qui Sommes-nous',
    [TK.HEADER_ABOUT_OUR_MISSION]: 'Notre mission: to wow you®',
    [TK.HEADER_ABOUT_OUR_HISTORY]: 'Notre histoire',
    [TK.HEADER_ABOUT_FOUNDER]: 'Fondateur, Tariq Farid',
    [TK.HEADER_ABOUT_CONTACT_US]: 'Contactez-nous',
    [TK.HEADER_OTHER_CAREERS]: 'Carrières',
    [TK.HEADER_OTHER_PRESS]: 'Presser',
    [TK.HEADER_OTHER_BLOG]: 'Blog',
    [TK.HEADER_NAV_OTHER]: 'Autre',
    [TK.CHOOSE_VARIANT]: 'Choisissez une Option:',
    [TK.SINGLE_VARIANT]: 'Taille Unique:',
    [TK.VIEW_DETAILS]: 'Voir les Détails',
    [TK.HIDE_DETAILS]: 'Masquer les Détails',
    [TK.HEADER_GET_HELP]: `Obtenir de l'aide`,
    [TK.EDIBLE_GUARANTEE_DESCRIPTION]: `Nous sommes là pour vous. Pour les grands moments de la vie, les petits, et même lorsque les choses ne se passent pas comme prévu. Vous nous avez choisis pour célébrer tous vos moments, et c’est de tout cœur que nous le faisons. C'est pour cela que nous vous offrons la Garantie Edible – notre promesse de donner le meilleur de nous-mêmes.`,
    [TK.EDIBLE_GUARANTEE_POLICY]:
      'Votre bonheur est notre bonheur, et il est garanti à 100%.',
    [TK.EDIBLE_GUARANTEE_HASSLEFREE]: 'satisfaction garantie, sans tracas',
    [TK.EDIBLE_GUARANTEE_SATISFACTION_HEADER]: 'satisfaction',
    [TK.EDIBLE_GUARANTEE_SUPPORT_HEADER]: 'garantie',
    [TK.EDIBLE_GUARANTEE_STREAMLINED_HEADER]: 'simplifié',
    [TK.EDIBLE_GUARANTEE_SATISFACTION]: `Nous remplacerons ou rembourserons votre commande si vous n’êtes pas 100% satisfait(e).`,
    [TK.EDIBLE_GUARANTEE_SUPPORT]:
      'Assistance sans tracas par email ou par téléphone.',
    [TK.EDIBLE_GUARANTEE_STREAMLINED]:
      'Nous prenons votre satisfaction au sérieux!',
    [TK.ENTER_ONE_PHONE_NUMBER]:
      'Veuillez entrer au moins un numéro de téléphone.',
    [TK.ENTER_ONE_VALID_PHONE_NUMBER]:
      'Veuillez entrer un numéro de téléphone domicile/travail/portable valide.',
    [TK.CANT_BEGIN_WITH_O_OR_1]:
      "Le numéro ne peut pas commencer par '0' ou '1'. Veuillez fournir un numéro de téléphone à 10 chiffres, en commençant par le code régional.",
    [TK.ALERT_OWN_BOX_ADDONS]: 'Veuillez sélectionner au moins 1 élément',
    [TK.ALERT_PRODUCT_OPTION]: 'Veuillez sélectionner une option',
    [TK.ALERT_AVAILABILITY_ZIP]:
      'Veuillez entrer le code postal pour continuer',
    [TK.ALERT_AVAILABILITY_DATE]:
      'Veuillez sélectionner une date pour continuer',
    [TK.ALERT_AVAILABILITY_DELIVERY_METHOD]:
      'Veuillez sélectionner le type de livraison pour continuer',
    [TK.ALERT_AVAILABILITY_STORE]:
      'Veuillez sélectionner le magasin pour continuer',
    [TK.ALERT_SUGGESTED_DATE]:
      'Veuillez sélectionner une date suggérée pour continuer votre commande',
    [TK.ALERT_SUGGESTED_PRODUCT]:
      'Veuillez choisir parmi les produits suggérés pour continuer vos achats',
    [TK.ALERT_DESSERT_OWN_BOX_ADDONS]:
      'Veuillez sélectionner au moins 1 élément parmi les 3 options ci-dessous pour continuer.',
    [TK.ALERT_6_COOKIE_BOX_ADDONS]:
      'Veuillez sélectionner au moins 1 cookie pour continuer.',
    [TK.ALERT_12_COOKIE_BOX_ADDONS]:
      'Veuillez sélectionner au moins 1 cookie dans la première liste ci-dessous pour continuer.',
    [TK.PDP_TITLE]: 'Faire une sélection',
    [TK.PICKUP]: 'Ramassage pour {{name}} le {{date}}',
    [TK.MENU_TITLE]: 'Sélectionner le destinataire',
    [TK.GUIDE]:
      'Vous pouvez ajouter un nouveau destinataire ci-dessous ou continuer vos achats pour {{name}}',
    [TK.NEW_RECIPIENT_SUBTITLE]: 'Adresse ou date différente',
    [TK.SWITCH_RECIPIENT]: 'Changer de destinataire',
    [TK.DELIVERY_FROM]:
      'Votre commande sera traitée à partir de {{store}} le {{date}}',
    [TK.DELIVERY_FOR]: 'Livraison à {{name}} le {{date}}',
    [TK.PICKUP_FROM]:
      'Votre commande sera prête à être récupérée à partir de {{store}} le {{date}} à {{time}}',
    [TK.PICKUP_FOR]: 'Récupération pour {{name}} le {{date}}',
    [TK.SHIPMENT_SERVICED_ON]: 'Votre commande sera traitée le {{date}}',
    [TK.NEW_RECIPIENT]: 'Nouveau destinataire',
    [TK.DELIVERY]: 'Livraison',
    [TK.PICK_UP]: 'Ramassage',
    [TK.DELIVERY_DESCRIPTION]: `Quoi de plus doux que la livraison à domicile ? Des friandises qui arrivent fraîches avec une présentation parfaite, bien sûr. Avec la livraison Edible®, c'est la norme.`,
    [TK.AVAILABLE_FOR_OTHER_DATES]: "Disponible pour d'autres dates",
    [TK.CHOOSE_ANOTHER_DATE]: 'Sélectionnez une autre date pour le ramassage',
    [TK.AVAILABLE_FOR_OTHER_PRODUCTS]: "Disponible pour d'autres produits",
    [TK.OTHER_PRODUCTS_AVAILABLE]:
      "Nous sommes désolés, mais ce produit n'est pas disponible à la date que vous avez sélectionnée. Veuillez choisir un autre produit disponible à la date sélectionnée.",
    [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
      "La livraison n'est pas disponible pour la date sélectionnée. Cliquez pour voir les dates disponibles.",
    [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
      "Désolé ! La livraison n'est pas disponible pour le produit sélectionné. Cliquez pour voir les autres produits disponibles pour la date sélectionnée.",
    [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
      "Désolé ! Le ramassage n'est pas disponible pour la date sélectionnée. Cliquez pour voir les dates disponibles.",
    [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
      "Désolé ! Le produit que vous avez sélectionné n'est pas disponible en magasin. Cliquez si vous préférez quelque chose de sucré à emporter !",
    [TK.PICKUP_IN_STORE]: 'Ramassage en magasin',
    [TK.PICKUP_DESCRIPTION]:
      'Dans les magasins du pays pour un ramassage rapide et pratique qui est toujours',
    [TK.FREE]: 'GRATUIT',
    [TK.NO_APPROPRIATE_DATE]: 'Vous ne voyez pas la date que vous souhaitez ?',
    [TK.EXPLORE_AVAILABLE_PRODUCTS]:
      'Explorons les produits disponibles à la date sélectionnée.',
    [TK.SEE_AVAILABLE_PRODUCTS]: 'Voir les produits disponibles',
    [TK.FLEX_FREE]: 'GRATUIT*',
    [TK.FLEX_NEXT_DAY_PRICE]: '5,99 $*',
    [TK.FLEX_DATES_AVAILABLE]: 'Dates flexibles disponibles',
    [TK.FLEX_DELIVERY_DATES]: 'Flexible avec la date de livraison ?',
    [TK.FLEX_DELIVERY_DATES_SUBTITLE]:
      'Nous offrons la livraison gratuite pour la plupart des commandes de livraison le lendemain et ultérieures.',
    [TK.FLEX_DATES_AVAILABLE_SUBTITLE]:
      'Nous avons trouvé des dates de livraison supplémentaires qui pourraient vous intéresser. Êtes-vous satisfait de la date sélectionnée ? Passez simplement cette sélection.',
    [TK.CHOOSE_ANOTHER_DATE]: 'Sélectionnez une autre date de livraison',
    [TK.DISCLAIMER_MESSAGE]: `*Des frais supplémentaires peuvent s'appliquer pour une livraison de plus de 15 miles et/ou pour les commandes de livraison le jour même ou en 1 heure en fonction du fuseau horaire du magasin qui reçoit la commande. Voir les conditions d'utilisation pour plus de détails`,
    [TK.DISCLAIMER_MESSAGE_KMS]: `*Des frais supplémentaires peuvent s'appliquer pour une livraison de plus de 24 kilomètres et/ou pour les commandes de livraison le jour même ou en 1 heure en fonction du fuseau horaire du magasin qui reçoit la commande. Voir les conditions d'utilisation pour plus de détails`,
    [TK.CURBSIDE]: 'Bord de rue',
    [TK.DISTANCE]: 'distance',
    [TK.KOSHER]: 'Casher',
    [TK.MILES]: '~{{miles}} miles',
    [TK.KILOMETERS]: '~{{km}} km',
    [TK.KILOMETERS_TEXT]: 'kilomètres',
    [TK.SMOOTHIE]: 'Smoothie',
    [TK.TITLE_PICKUP]: 'Sélectionnez un magasin de ramassage local',
    [TK.TITLE_DELIVERY]: 'Sélectionnez un magasin pour la livraison',
    [pluralize(TK.SHOW_MORE)]: 'Afficher {{count}} de plus',
    [TK.MONDAY_FULL]: 'Lundi',
    [TK.TUESDAY_FULL]: 'Mardi',
    [TK.WEDNESDAY_FULL]: 'Mercredi',
    [TK.THURSDAY_FULL]: 'Jeudi',
    [TK.FRIDAY_FULL]: 'Vendredi',
    [TK.SATURDAY_FULL]: 'Samedi',
    [TK.SUNDAY_FULL]: 'Dimanche',
    [TK.MONDAY_SHORT]: 'Lun',
    [TK.TUESDAY_SHORT]: 'Mar',
    [TK.WEDNESDAY_SHORT]: 'Mer',
    [TK.THURSDAY_SHORT]: 'Jeu',
    [TK.FRIDAY_SHORT]: 'Ven',
    [TK.SATURDAY_SHORT]: 'Sam',
    [TK.SUNDAY_SHORT]: 'Dim',
    [TK.ERROR_NO_STORES]:
      'Oops, nous ne pouvons pas vous suggérer de magasin pour le moment. Veuillez sélectionner une autre date ou changer la méthode de livraison.',
    [TK.ERROR_DELIVERY_CUTOFF_PASSED]:
      "Oops, l'heure limite de livraison est passée pour aujourd'hui. Veuillez sélectionner une autre date.",
    [TK.ERROR_PICKUP_CUTOFF_PASSED]:
      "Oops, l'heure limite de ramassage est passée pour aujourd'hui. Veuillez sélectionner une autre date.",
    [TK.ERROR_PICKUP_DATE_IN_PAST]:
      'Oops, la date de ramassage ne peut pas être dans le passé. Veuillez sélectionner une autre date.',
    [TK.ERROR_SAME_DAY_SHIPMENT]:
      "Oops, nous n'offrons actuellement pas d'expédition le jour même, veuillez sélectionner une autre date.",
    [TK.ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED]:
      "Oops, l'expédition du lendemain n'est plus disponible aujourd'hui, veuillez sélectionner une autre date.",
    [TK.ERROR_SIZE_IS_NOT_AVAILABLE]:
      "Nous sommes désolés, l'option que vous avez sélectionnée n'est pas disponible dans ce magasin. Veuillez sélectionner une taille ou une date différente.",
    [TK.ERROR_STORE_IS_CLOSED]:
      'Nous sommes désolés, ce magasin est actuellement fermé. Veuillez sélectionner un autre code postal ou une autre date.',
    [TK.ERROR_PICKUP_CUTOFF_DATE]:
      "Nous sommes désolés, mais le magasin que vous avez sélectionné n'accepte pas les commandes de ramassage le jour même après {{time}}. Veuillez sélectionner une autre date ou un autre magasin.",
    [TK.ERROR_BEFORE_MINIMUM_PICKUP_TIME]:
      "Oops, l'heure de ramassage sélectionnée n'est pas disponible. Veuillez choisir une heure après {{time}} ou contacter le magasin.",
    [TK.ERROR_SERVICING_STORE_CLOSED]:
      'Nous sommes désolés, mais le magasin que vous avez sélectionné est fermé le {{date}}. Veuillez sélectionner une autre date.',
    [TK.ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER]:
      'Nous sommes désolés, mais le magasin que vous avez sélectionné ne peut pas compléter la commande pour le {{date}}. Veuillez sélectionner une autre date.',
    [TK.ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "Nous sommes désolés, la livraison n'est actuellement pas disponible le {{date}} depuis le magasin que vous avez sélectionné {{store}}. Veuillez sélectionner une autre date.",
    [TK.ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
      "Nous sommes désolés, le ramassage n'est actuellement pas disponible le {{date}} depuis le magasin que vous avez sélectionné {{store}}. Veuillez sélectionner une autre date.",
    [TK.SHOW_MORE_REVIEW]: 'Afficher {{count}} autre avis',
    [pluralize(TK.SHOW_MORE_REVIEW)]: 'Afficher {{count}} autres avis',
    [TK.PRODUCT_NO_REVIEW]: 'Aucun avis',
    [TK.PRODUCT_REVIEW]: 'Avis : {{count}}',
    [pluralize(TK.PRODUCT_REVIEW)]: 'Avis : {{count}}',
    [TK.PRODUCT_REVIEWS]: 'Avis',
    [TK.PRODUCT_DESCRIPTION]: 'À propos',
    [TK.PRODUCT_INGREDIENTS]: 'Ingrédients',
    [TK.QUESTIONS]: 'Des questions ? Demandez !',
    [TK.COUPONS_APPLICABLE_TITLE]: 'Coupons non applicables',
    [TK.ALLERGY_WARNING_TITLE]: `Avertissement d'allergie : `,
    [TK.DISCLAIMER_TITLE]: 'Avis de non-responsabilité : ',
    [TK.SHOW_MORE_DELIVERY_STORES]: 'Afficher plus de magasins de livraison',
    [TK.WHEN]: 'Quand?',
    [TK.TODAY]: `Aujourd'hui ({{date}})`,
    [TK.TOMORROW]: 'Demain ({{date}})',
    [TK.DATE_PICKER]: 'Sélectionner une date',
    [TK.UPSELLS_DECREASE_QUANTITY]: 'Decrease quantity',
    [TK.UPSELLS_INCREASE_QUANTITY]: 'Augmenter la quantité',
    [TK.UPSELLS_REMOVE]: 'Supprimer',
    [TK.UPSELLS_ADD]: 'Ajouter',
    [TK.UPSELLS_CONTINUE]: 'Continuer',
    [TK.UPSELLS_SAVE_SELECTION]: 'Enregistrer les sélections ({{count}})',
    [TK.UPSELLS_TITLE]: 'améliorez votre cadeau',
    [TK.UPSELLS_OPTIONS_SINGLE]: 'Unique',
    [TK.UPSELLS_OPTIONS_OPTION]: 'Option',
    [TK.UPSELL_OPTIONS_STARTING_AT]: 'Options à partir de',
    [TK.UPSELL_OPTIONS_SHOW_ALL]: 'Afficher tout',
    [TK.UPSELL_OPTIONS_EXPANDED_SINGLE]: 'Option unique',
    [TK.UPSELL_OPTIONS_EXPANDED_SELECT_ONE]: 'Sélectionnez une seule option',
    [TK.UPSELL_OPTIONS_EXPANDED_SELECT_MULTIPLE]:
      'Sélectionnez 1–{{count}} options',
    [TK.UPSELL_OPTIONS_EXPANDED_SHOW_LESS]: 'Afficher moins',
    [TK.UPSELL_OPTIONS_WARNING]:
      'Avertissement : Nous ne pouvons pas récupérer les upsells, allez simplement au formulaire du destinataire',
    [TK.UPSELL_OPTIONS_WARNING_DESIGN_VARY]: 'Le design peut varier',
    [TK.UPSELL_OPTIONS_NOTICE]:
      "Remarque : Il n'y a pas d'upsells ici. Vous devriez aller au formulaire du destinataire",
    [TK.UPSELL_SHOW_MORE]: 'Afficher plus',
    [TK.UPSELL_SHOWING]: 'Affichage de {{shown}} sur {{count}}.',
    [TK.UPSELL_SHOW]: 'Afficher {{shownCount}} de plus',
    [TK.UPSELL_OPTIONS_PRODUCT_BADGE_LABEL]: 'Votre cadeau',
    [TK.SAVE]: 'Enregistrer',
    [TK.ADD]: 'Ajouter',
    [TK.CANCEL]: 'Annuler',
    [TK.LETTERS_OPTION_TITLE]: 'Lettre (1-5 caractères)',
    [TK.LETTERS_OPTION_MODAL_TITLE]: 'Lettre',
    [TK.LETTERS_OPTION_EXAMPLE]: 'Exemple : Anniversaire - ajoutez leur nom',
    [TK.LETTERS_OPTION_WARN]: 'Seules les lettres majuscules sont autorisées',
    [TK.LETTERS_INPUT_LABEL]: 'Entrez les lettres',
    [TK.NUMBERS_OPTION_TITLE]: 'Nombre (1-3 caractères)',
    [TK.NUMBERS_OPTION_MODAL_TITLE]: 'Nombre',
    [TK.NUMBERS_OPTION_EXAMPLE]: "Exemple : Sweet Sixteen - ajoutez un '16'",
    [TK.NUMBERS_OPTION_WARN]: 'Seuls les nombres sont autorisés',
    [TK.NUMBERS_INPUT_LABEL]: 'Entrez votre nombre',
    [TK.YEAR_OPTION_TITLE]: 'Année (4 caractères)',
    [TK.YEAR_OPTION_MODAL_TITLE]: 'Année',
    [TK.YEAR_OPTION_EXAMPLE]:
      "Exemple : Réunion de classe - tapez l'année de graduation (AAAA)",
    [TK.YEAR_OPTION_WARN]: 'Seuls les nombres sont autorisés',
    [TK.YEAR_INPUT_LABEL]: "Entrez l'année",
    [TK.EMPTY_VALUE_VALIDATION_ERROR_MESSAGE]:
      'Entrez au moins un caractère pour continuer',
    [TK.CENSORED_WORD_VALIDATION_ERROR_MESSAGE]:
      'Cette combinaison de lettres est interdite. Veuillez essayer une autre combinaison',
    [TK.SWITCH_TO_THIS_OPTION]: 'Passer à cette option',
    [TK.MAXIMUM_3_CHARACTERS]: 'Maximum 3 caractères.',
    [TK.MAXIMUM_5_CHARACTERS]: 'Maximum 5 caractères.',
    [TK.ONLY_NUMBERS_ALLOWED]: 'Seuls les nombres sont autorisés.',
    [TK.MUST_BE_4_CHARACTERS_LONG]: 'Doit comporter 4 caractères.',
    [TK.ONLY_UPPERCASE_LETTERS_ALLOWED]:
      'Seules les lettres majuscules sont autorisées.',
    [TK.PDP_REQUIRED_FIELD]: '* Champ obligatoire',
    [TK.PDP_FIRST_NAME_TEXT]:
      'Le destinataire est celui qui REÇOIT cet article',
    [TK.PDP_FIRST_NAME_TEXT_PICKUP]:
      'Le destinataire est celui qui VIENT CHERCHER',
    [TK.PDP_ADDRESS_SUBTITLE]:
      "Sélectionnez Résidentiel ou Professionnel pour le type d'adresse, puis saisissez les détails.",
    [TK.PDP_ERROR_ALERT_ADD_TO_CART_HEADING]: 'Oups !',
    [TK.PDP_ERROR_ALERT_ADD_TO_CART_BODY]:
      'Nous avons des difficultés à ajouter cet article au panier. Veuillez réessayer dans quelques minutes.',
    [TK.PDP_ERROR_PICKUP_IN_PAST]:
      'Oops, la date de ramassage ne peut pas être dans le passé. Veuillez sélectionner une autre date.',
    [TK.PDP_ERROR_CHOOSE_SAME_DAY_PICKUP_TIME_AFTER]:
      "Nous sommes désolés, mais le magasin {{store}} n'accepte pas les commandes de ramassage le jour même après {{time}}. Veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_CHOOSE_PICKUP_TIME_AFTER]:
      "Oops, l'heure de ramassage sélectionnée n'est pas disponible. Veuillez choisir une heure après {{time}} ou contacter le magasin.",
    [TK.ERROR_SAME_DAY_SHIPMENT]:
      "Oops, nous n'offrons actuellement pas d'expédition le jour même, veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_NEXT_DAY_SHIPMENT_CUTOFF]:
      "Oops, l'expédition du lendemain n'est plus disponible aujourd'hui, veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_NO_SERVICE_OPTION]:
      'Oops, nous ne pouvons pas traiter votre sélection. Veuillez rafraîchir la page.',
    [TK.PDP_ERROR_SERVICE_OPTION_STORE]:
      "Nous sommes désolés, la taille de produit que vous avez sélectionnée n'est pas disponible dans ce magasin. Veuillez sélectionner une autre taille ou un autre magasin.",
    [TK.PDP_ERROR_ORDER_DATE_IN_PAST]:
      "La date de commande est passée et n'est plus disponible. Veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_STORE_IS_CLOSED_ON_DATE]:
      'Nous sommes désolés, mais le magasin que vous avez sélectionné est fermé ce jour-là. Veuillez sélectionner une autre date.',
    [TK.PDP_ERROR_STORE_CANNOT_FULFILL_ON_DATE]:
      'Nous sommes désolés, mais le magasin que vous avez sélectionné ne peut pas compléter la commande le {{date}}. Veuillez sélectionner une autre date.',
    [TK.ERROR_STORE_IS_CLOSED]:
      'Nous sommes désolés, mais le magasin que vous avez sélectionné est fermé ce jour-là. Veuillez sélectionner une autre date.',
    [TK.PDP_ERROR_DELIVERY_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
      "Nous sommes désolés, la livraison n'est actuellement pas disponible le {{date}} depuis le magasin que vous avez sélectionné {{store}}. Veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_PICKUP_NOT_AVAILABLE_ON_DATE_FOR_STORE]:
      "Nous sommes désolés, le ramassage n'est actuellement pas disponible le {{date}} depuis le magasin que vous avez sélectionné {{store}}. Veuillez sélectionner une autre date.",
    [TK.PDP_ERROR_UNCOMPLETED_REQUIRED_FIELDS]:
      'Veuillez compléter tous les champs obligatoires : {{fields}}',
    [TK.PDP_ERROR_FIRST_NAME_SPL_CHARS]: 'Entrez un prénom valide',
    [TK.PDP_ERROR_LAST_NAME_SPL_CHARS]: 'Entrez un nom de famille valide',
    [TK.PDP_ERROR_STREET_ADDRESS_SPL_CHARS]: 'Entrez une adresse valide.',
    [TK.PDP_ERROR_REQUIRED]: 'obligatoire',
    [TK.PDP_ERROR_NAME_SPL_CHARS]:
      'Entrez un nom valide ou envoyez de manière anonyme',
    [TK.PDP_ERROR_OCCASION_ID]: 'Sélectionnez une occasion',
    [TK.PICKUP_PHONE]: 'Téléphone du destinataire*',
    [TK.PICKUP_FORM_CURBSIDE_SWITCH_LABEL]: 'Ramassage en bordure de rue',
    [TK.PICKUP_CURBSIDE_MODEL_LABEL]: 'Modèle de véhicule*',
    [TK.PICKUP_CURBSIDE_COLOR_LABEL]: 'Couleur du véhicule*',
    [TK.PICKUP_DETAILS]: 'Détails de ramassage',
    [TK.PICKUP_CONTACT_DETAILS]: `Nous ne vous contacterons que s'il y a des complications`,
    [TK.DELIVERY_INSTRUCTIONS_PLACEHOLDER]: 'Instructions de livraison',
    [TK.PICKUP_INSTRUCTIONS]: 'Instructions de ramassage',
    [TK.PICKUP_INSTRUCTIONS_PLACEHOLDER]: `Fournissez tous les détails qui peuvent faciliter votre ramassage. Incluez la plage horaire de ramassage, les exigences d'accessibilité, etc.`,
    [TK.COMPLIMENTARY_GIFT_MESSAGE]: 'Ajouter un message cadeau gratuit',
    [TK.COMPLIMENTARY_MESSAGE_TITLE]: 'Message cadeau gratuit',
    [TK.OR]: 'Ou',
    [TK.SELECT_NEW_CARD]: 'Sélectionner une nouvelle carte',
    [TK.PRINTIBLE_OLD_PRICE_TEXT]: 'Était ',
    [TK.PRINTIBLE_PRICE_SUBTEXT]: 'Seulement ',
    // eslint-disable-next-line no-template-curly-in-string
    [TK.PRINTIBLE_PRICE_TEXT]: '${{price}}',
    [TK.PRINTIBLE_CARDS]: 'Cartes imprimables',
    [TK.GIFT_CARD_MODAL_TITLE]: 'Laissez-leur un message sincère',
    [TK.GIFT_CARD_MODAL_GIFT_MESSAGE]: 'Rendez-le mémorable',
    [TK.GIFT_CARD_MODAL_KEEPSAKE_CHERISH]:
      'envoyer une carte de vœux personnalisée',
    [TK.GIFT_CARD_MODAL_YEAR_COME]: 'pendant des années à venir',
    [TK.WRITE_FOR_ME_NAME]: 'Votre nom*',
    [TK.ANANYMOUSLY_SWITCH_LABEL]: 'Envoyer de manière anonyme',
    [TK.WRITE_FOR_ME_NAME_NOTE]:
      'Informez le destinataire de qui a envoyé son cadeau',
    [TK.WRITE_FOR_ME_CREATE_BUTTON]: 'Créer mon message cadeau',
    [TK.WRITE_FOR_ME_START_NEW_BUTTON]: 'Commencer un nouveau message cadeau',
    [TK.GIFT_MESSAGE_TABS_LABEL]: 'Onglets de saisie du message de la carte',
    [TK.GIFT_MESSAGE_FIRST_TAB_LABEL]: 'Écrire le mien',
    [TK.GIFT_MESSAGE_SECOND_TAB_LABEL]: 'Écrire pour moi',
    [TK.TITLE]: 'Faire une sélection',
    [TK.AREA]: 'Code postal',
    [TK.POSTAL_CODE]: 'Code postal',
    [TK.STATE]: 'Province',
    [TK.PROVINCE]: 'Province',
    [TK.CITY]: 'Ville',
    [TK.CHANGE_AREA]: 'Besoin de changer le code postal ?',
    [TK.CHANGE_POSTAL_CODE]: 'Besoin de changer le code postal ?',
    [TK.CHANGE_AREA_INFO]:
      'Votre code postal de livraison et votre province sont verrouillés selon votre sélection. Vous pouvez modifier le code postal de livraison en sélectionnant l\'option "Besoin de changer le code postal".',
    [TK.CHANGE_POSTAL_CODE_INFO]:
      'Votre code postal de livraison et votre province sont verrouillés selon votre sélection. Vous pouvez modifier le code postal de livraison en sélectionnant l\'option "Besoin de changer le code postal".',
    [TK.CART_POSTAL_CODE_INFO]:
      'Chaque magasin propose des friandises différentes. Pour changer le code postal, vous devez commencer une nouvelle commande. Cela garantit que vos friandises sont toujours disponibles.',
    [TK.CHANGE_ZIP_TITLE]: 'Changer le code postal',
    [TK.CHANGE_ZIP_DESCRIPTION]:
      'Chaque magasin propose des friandises différentes. Changer le code postal redémarrera le processus à partir de la page du produit afin que nous puissions vérifier que votre sélection est toujours disponible.',
    [TK.CHANGE_BACK_BUTTON]: 'Retour',
    [TK.CHANGE_ZIP_BUTTON]: 'Changer le code postal',
    [TK.CHANGE_POSTAL_TITLE]: 'modifier le code postal',
    [TK.CHANGE_POSTAL_DESCRIPTION]:
      'Chaque magasin propose des friandises différentes. Changer le code postal redémarrera le processus à partir de la page du produit afin que nous puissions vérifier que votre sélection est toujours disponible.',
    [TK.CHANGE_POSTAL_BUTTON]: 'Changer le code postal',
    [TK.STORES_NEAR_YOU]: '{{amountOfStores}} Magasins près de chez vous',
    [TK.STORE_NEAR_YOU]: '{{amountOfStores}} Magasin près de chez vous',
    [TK.LOAD_MORE_STORES_BUTTON_TEXT]: 'Charger plus',
    [TK.PRIVACY_POLICY]: 'Politique de confidentialité',
    [TK.TERMS_OF_USE]: `Conditions d'utilisation`,
    [TK.CONCERNED_ABOUT_PRIVACY]:
      'Préoccupé par la confidentialité ? Consultez nos politiques ci-dessous.',
    [TK.LOCATION_SERVICES_OFF]:
      'Il semble que les services de localisation soient désactivés.',
    [TK.TURN_ON_LOCATION_SERVICES]:
      'Pour trouver un magasin Edible près de chez vous, utilisez la fonction de recherche ou la carte interactive ci-dessus. Ou activez les services de localisation sur votre appareil.',
    [TK.STORE_SHOWING_AMOUNT_DISPLAY_TEXT]:
      'Affichage de {{amountOfStoresCurrentlyDisplayed}} sur {{amountOfStoresTotal}}, Charger 8 de plus',
    [TK.SMOOTHIE]: 'Smoothie',
    [TK.CURBSIDE]: 'Bordure de trottoir',
    [TK.KOSHER]: 'Casher',
    [TK.APPLY]: 'Appliquer',
    [TK.CLEAR_ALL_FILTERS]: 'Effacer tous les filtres',
    [TK.ERROR_MESSAGE_OPEN_YOUR_OWN_STORE]: 'Ouvrez votre propre magasin',
    [TK.ERROR_MESSAGE_WE_SHIP_TEXT]:
      "Nous sommes désolés, nous n'avons trouvé aucun magasin à proximité. Mais nous pouvons peut-être expédier certaines de nos friandises gastronomiques chez vous ou chez votre proche !",
    [TK.ERROR_MESSAGE_OH_NO]: 'Oh non !',
    [TK.ERROR_MESSAGE_DIFFERENT_SEARCH]:
      'Essayez une autre ville, un autre état ou un autre code postal pour voir les résultats',
    [TK.ERROR_MESSAGE_DIFFERENT_SEARCH_AND_FILTER]:
      'Essayez une autre ville, un autre état ou un autre code postal, ou modifiez vos filtres pour voir les résultats.',
    [TK.ERROR_MESSAGE_SEARCH_AND_FILTER_CRITERIA]:
      "Nous sommes désolés, nous n'avons trouvé aucun magasin correspondant à vos critères.",
    [TK.ERROR_MESSAGE_OPEN_STORE_TEXT]:
      'Vous voulez être le premier à ouvrir un magasin Edible Arrangements près de chez vous ? Vous pouvez posséder votre propre franchise pour livrer des friandises délicieuses à votre quartier.',
    [TK.ERROR_MESSAGE_NEW_STORES_TEXT]:
      'De nouveaux emplacements ouvrent tout le temps, alors revenez bientôt !',
    [TK.DISTANCE_FILTER_OPTION]:
      'Dans un rayon de {{distance}} {{distanceMetric}}',
    [TK.DISTANCE_TEXT]: 'Distance',
    [TK.SERVICES_OFFERED_TEXT]: 'Services offerts',
    [TK.FILTERS_TEXT]: 'Filtres',
    [TK.FIND_A_STORE]: 'Trouver un magasin',
    [TK.MOBILE_FILTER_TEXT]: 'Filtrer les résultats',
    [TK.CUSTOM_GREETING_CARDS]: 'Cartes de vœux personnalisées',
    [TK.STORE_FINDER_HOME]: 'Accueil',
    [TK.STORE_FINDER_TITLE]: 'Localisateur de magasins Edible',
    [TK.STORE_FINDER_DESCRIPTION]:
      "Visitez l'un des plus de 900 emplacements dans le monde pour un échantillon gratuit, des offres exclusives et des promotions en magasin !",
    [TK.STORE_FINDER_PLACEHOLDER]:
      'Entrez votre ville et votre état ou votre code postal',
    [TK.STORE_FINDER_PLACEHOLDER_CA]:
      'Entrez la ville et la province ou le code postal',
    [TK.STORE_FINDER_LOCATION]: 'Emplacement',
    [TK.STORE_FINDER_CHOOSE_FROM_LIST]: 'Choisissez dans la liste ci-dessous',
    [TK.STORE_FINDER_VALIDATION_ERROR]:
      'Entrez une ville et une province ou un code postal valides',
    [TK.STORE_FINDER_VALIDATION_ERROR_CA]:
      'Entrez une ville et un état ou un code postal valide',
    [TK.STORE_FINDER_BUTTON]: 'Trouver un magasin',
    [TK.STORE_FINDER_BUTTON_LOADING_TEXT]: 'Récupération du/des magasin(s)…',
    [TK.INPUT_ADORNMENT]: 'Entrez une ville, un état ou un code postal',
    [TK.INPUT_ADORNMENT_CA]: 'Entrez la ville, la province ou le code postal',
    [TK.STORE_FINDER_FRANCHISE_HEAD]: 'Vivez la belle vie !',
    [TK.STORE_FINDER_FRANCHISE_SUBTITLE]: 'Possédez une franchise',
    [TK.STORE_FINDER_FRANCHISE_DESCRIPTION]:
      'Découvrez comment ouvrir votre propre magasin Edible et rejoignez notre communauté passionnée de propriétaires de franchises à travers le monde.',
    [TK.LEARN_MORE_STORES]: 'En savoir plus',
    [TK.LOAD_MORE_STORES_ARIA_TEXT]: 'Charger plus de magasins',
    [TK.OPEN_FRANCHISE_LEARN_MORE_BUTTON]:
      'Ouvrez votre propre franchise En savoir plus',
    [TK.OPEN_FRANCHISE_BANNER]: 'Bannière Ouvrez votre propre franchise',
    [TK.INVALID_SEARCH_TEXT]:
      'Entrez une ville et un état ou un code postal valide dans le champ ci-dessus pour voir les résultats',
    [TK.COMING_SOON_STORE_TEXT]: 'Nouveaux magasins à venir !',
    [TK.CALL_EDIBLE_STORE]: 'Appelez ce magasin Edible',
    [TK.CONTACT_US]: 'Contactez-nous',
    [TK.EMAIL_EDIBLE_STORE]: 'Envoyez un courriel à ce magasin Edible',
    [TK.GET_DIRECTIONS]: 'Obtenir les indications pour le magasin Edible',
    [TK.STORE_FINDER_PAGE_META_TAG_TITLE]:
      'Emplacements et magasins Edible Arrangements près de chez vous',
    [TK.STORE_FINDER_PAGE_META_TAG_DESCRIPTION]:
      'Trouvez un emplacement Edible Arrangements près de chez vous. Votre guichet unique pour la livraison de cadeaux, la cueillette, les offres exclusives en magasin et plus encore. Explorez les magasins près de chez vous ici !',
    [TK.STORE_ADDRESS1]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_ADDRESS2]: 'Edible® {{storeNumber}}, {{address1}}, {{address2}}',
    [TK.STORE_BANNER_BODY]: `Achetez des cadeaux et des friandises pour quelqu'un d'autre (ou pour vous-même !) chez Edible Arrangements {{city}}, {{state}} - {{address1}}.`,
    [TK.STORE_BANNER_BUTTON]: 'Acheter maintenant',
    [TK.FRANCHISE_BANNER_BUTTON_LABEL]:
      'Texte du bouton de la bannière Ouvrez votre propre franchise',
    [TK.STORE_BANNER_TITLE]: 'Venez nous voir !',
    [TK.STORE_BANNER_ALT_TEXT]:
      'Visitez et faites vos achats dans notre magasin Edible',
    [TK.FRANCHISE_BANNER_ALT_TEXT]:
      "En savoir plus sur la possession d'une franchise Edible",
    [TK.FRANCHISE_BANNER_TITLE]: 'Vivez la vie douce !',
    [TK.FRANCHISE_BANNER_SUB_TITLE]: 'Possédez une franchise',
    [TK.FRANCHISE_BANNER_SUB_TITLE_LABEL]:
      'Sous-titre de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_TEXT_CONTAINER]:
      'Conteneur de texte de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_LABEL]: 'Bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_TITLE_LABEL]:
      'Titre de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_BODY_LABEL]:
      'Corps de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_BODY]:
      'Découvrez comment ouvrir votre propre magasin Edible et rejoignez notre communauté passionnée de propriétaires de franchises à travers le monde.',
    [TK.FRANCHISE_BANNER_BUTTON]: 'En savoir plus',
    [TK.SERVICED_AREAS_TITLE]: 'Zone de service',
    [TK.ABOUT_STORE_TITLE]: 'À propos de notre magasin',
    [TK.ABOUT_STORE_ONE]:
      "Edible Arrangements® {{storeNumber}} à {{city}}, {{state}} a ouvert ses portes pour la première fois en {{opening}}. Depuis lors, nous aidons les gens de notre communauté locale à célébrer toutes sortes d'occasions - grandes et petites. Nos arrangements de fruits et nos cadeaux sont toujours préparés avec des fruits frais cultivés et sélectionnés selon nos normes d'expertise en fruits. Et nous avons aussi la meilleure variété de fruits enrobés de chocolat gourmet ! Venez nous rendre visite à tout moment pour un échantillon GRATUIT !",
    [TK.ABOUT_STORE_TWO]:
      "Saviez-vous que nous avons également des friandises aux fruits frais que vous pouvez déguster en déplacement ? Passez nous voir aujourd'hui pour prendre une salade de fruits frais, un donut Edible®, un cornet de fruits enrobés de chocolat™ ou l'une de nos autres délicieuses collations aux fruits frais ! Elles sont parfaites pour les pique-niques, les collations pendant la pause déjeuner et même pour vous remonter le moral l'après-midi !",
    [TK.ABOUT_STORE_THREE]:
      "Si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les fêtes ou même d'un petit quelque chose juste parce que, appelez-nous au <phone>{{phoneNumber}}</phone>. Nous avons hâte de vous rencontrer !",
    [TK.SOCIAL_MEDIA_TITLE]: 'Connectons-nous !',
    [TK.SOCIAL_MEDIA_BODY]:
      'Suivez ce magasin sur les réseaux sociaux pour des promotions exclusives !',
    [TK.SOCIAL_FACEBOOK_ARIA_LABEL]: 'Suivez le magasin Edible sur Facebook',
    [TK.SOCIAL_INSTAGRAM_ARIA_LABEL]: 'Suivez le magasin Edible sur Facebook',
    [TK.SOCIAL_PINTEREST_ARIA_LABEL]: 'Suivez le magasin Edible sur Pinterest',
    [TK.SOCIAL_TWITTER_ARIA_LABEL]: 'Suivez le magasin Edible sur Twitter',
    [TK.SOCIAL_YOUTUBE_ARIA_LABEL]: 'Suivez le magasin Edible sur YouTube',
    [TK.CATEGORIES_TITLE]:
      'Nous pensons que vous aimerez ces cadeaux populaires',
    [TK.DISTANCE_AWAY]: '~{{distance}} {{distanceMetric}} de distance',
    [TK.POPULAR_GIFT]: 'Section des cadeaux populaires',
    [TK.BLOG_CAROUSEL]: 'Affichage du carrousel de blog',
    [TK.STORE_DETAILS_ADDRESS1]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}}',
    [TK.STORE_DETAILS_ADDRESS2]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_TITLE]:
      'Edible Arrangements {{city}}, {{state}} : {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_SCHEMA_DESCRIPTION]:
      "Edible Arrangements® {{storeNumber}} à {{city}}, {{state}} a ouvert ses portes pour la première fois en {{openingDate}}. Depuis lors, nous aidons les gens de notre communauté locale à célébrer toutes sortes d'occasions - grandes et petites. Nos arrangements de fruits et nos cadeaux sont toujours préparés avec des fruits frais cultivés et sélectionnés selon nos normes d'expertise en fruits. Et nous avons aussi la meilleure variété de fruits enrobés de chocolat gourmet ! (Venez nous rendre visite à tout moment pour un échantillon GRATUIT !) Saviez-vous que nous avons également des friandises aux fruits frais que vous pouvez déguster en déplacement ? Passez nous voir aujourd'hui pour prendre un smoothie aux fruits frais, un milkshake Froyo Fruit™, un donut Edible®, un cornet de fruits enrobés de chocolat™ ou l'une de nos autres délicieuses collations aux fruits frais ! Elles sont parfaites pour les pique-niques, les collations pendant la pause déjeuner et même pour vous remonter le moral l'après-midi ! Si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les fêtes ou même d'un petit quelque chose juste parce que, appelez-nous au {{storePhoneNumber}}. Nous avons hâte de vous rencontrer !",
    [TK.STORE_DETAILS_META_DESCRIPTION]:
      "Prenez un délicieux smoothie aux fruits frais, une friandise aux fruits enrobés de chocolat, un donut ou même une salade de fruits frais dans le magasin Edible Arrangements à {{city}} aujourd'hui !",
    [TK.STORE_ADDRESS1]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_ADDRESS2]: 'Edible® {{storeNumber}}, {{address1}}, {{address2}}',
    [TK.STORE_BANNER_BODY]:
      "Achetez des cadeaux et des friandises pour quelqu'un d'autre (ou pour vous-même !) chez Edible Arrangements {{city}}, {{state}} - {{address1}}.",
    [TK.STORE_BANNER_BUTTON]: 'Acheter maintenant',
    [TK.FRANCHISE_BANNER_BUTTON_LABEL]:
      'Texte du bouton de la bannière Ouvrez votre propre franchise',
    [TK.STORE_BANNER_TITLE]: 'Venez nous voir !',
    [TK.STORE_BANNER_ALT_TEXT]:
      'Visitez et faites vos achats dans notre magasin Edible',
    [TK.FRANCHISE_BANNER_ALT_TEXT]:
      "En savoir plus sur la possession d'une franchise Edible",
    [TK.FRANCHISE_BANNER_SUB_TITLE]: 'Possédez une franchise',
    [TK.FRANCHISE_BANNER_SUB_TITLE_LABEL]:
      'Sous-titre de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_TEXT_CONTAINER]:
      'Conteneur de texte de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_LABEL]: 'Bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_TITLE_LABEL]:
      'Titre de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_ARIA_BODY_LABEL]:
      'Corps de la bannière Ouvrez votre propre franchise',
    [TK.FRANCHISE_BANNER_BODY]:
      'Découvrez comment ouvrir votre propre magasin Edible et rejoignez notre communauté passionnée de propriétaires de franchises à travers le monde.',
    [TK.FRANCHISE_BANNER_BUTTON]: 'En savoir plus',
    [TK.SERVICED_AREAS_TITLE]: 'Zone de service',
    [TK.ABOUT_STORE_TITLE]: 'À propos de notre magasin',
    [TK.ABOUT_STORE_ONE]:
      "Edible Arrangements® {{storeNumber}} à {{city}}, {{state}} a ouvert ses portes pour la première fois en {{opening}}. Depuis lors, nous aidons les gens de notre communauté locale à célébrer toutes sortes d'occasions - grandes et petites. Nos arrangements de fruits et nos cadeaux sont toujours préparés avec des fruits frais cultivés et sélectionnés selon nos normes d'expertise en fruits. Et nous avons aussi la meilleure variété de fruits enrobés de chocolat gourmet ! Venez nous rendre visite à tout moment pour un échantillon GRATUIT !",
    [TK.ABOUT_STORE_TWO]:
      "Saviez-vous que nous avons également des friandises aux fruits frais que vous pouvez déguster en déplacement ? Passez nous voir aujourd'hui pour prendre une salade de fruits frais, un donut Edible®, un cornet de fruits enrobés de chocolat™ ou l'une de nos autres délicieuses collations aux fruits frais ! Elles sont parfaites pour les pique-niques, les collations pendant la pause déjeuner et même pour vous remonter le moral l'après-midi !",
    [TK.ABOUT_STORE_THREE]:
      "Si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les fêtes ou même d'un petit quelque chose juste parce que, appelez-nous au <phone>{{phoneNumber}}</phone>. Nous avons hâte de vous rencontrer !",
    [TK.SOCIAL_MEDIA_TITLE]: 'Connectons-nous !',
    [TK.SOCIAL_MEDIA_BODY]:
      'Suivez ce magasin sur les réseaux sociaux pour des promotions exclusives !',
    [TK.SOCIAL_FACEBOOK_ARIA_LABEL]: 'Suivez le magasin Edible sur Facebook',
    [TK.SOCIAL_INSTAGRAM_ARIA_LABEL]: 'Suivez le magasin Edible sur Facebook',
    [TK.SOCIAL_PINTEREST_ARIA_LABEL]: 'Suivez le magasin Edible sur Pinterest',
    [TK.SOCIAL_TWITTER_ARIA_LABEL]: 'Suivez le magasin Edible sur Twitter',
    [TK.SOCIAL_YOUTUBE_ARIA_LABEL]: 'Suivez le magasin Edible sur YouTube',
    [TK.CATEGORIES_TITLE]:
      'Nous pensons que vous aimerez ces cadeaux populaires',
    [TK.DISTANCE_METRIC]: '~{{distance}} {{distanceMetric}}',
    [TK.POPULAR_GIFT]: 'Section des cadeaux populaires',
    [TK.BLOG_CAROUSEL]: 'Affichage du carrousel de blog',
    [TK.STORE_DETAILS_ADDRESS1]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}}',
    [TK.STORE_DETAILS_ADDRESS2]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_TITLE]:
      'Edible Arrangements {{city}}, {{state}} : {{address1}} {{address2}}',
    [TK.STORE_DETAILS_META_SCHEMA_DESCRIPTION]:
      "Edible Arrangements® {{storeNumber}} à {{city}}, {{state}} a ouvert ses portes pour la première fois en {{openingDate}}. Depuis lors, nous aidons les gens de notre communauté locale à célébrer toutes sortes d'occasions - grandes et petites. Nos arrangements de fruits et nos cadeaux sont toujours préparés avec des fruits frais cultivés et sélectionnés selon nos normes d'expertise en fruits. Et nous avons aussi la meilleure variété de fruits enrobés de chocolat gourmet ! (Venez nous rendre visite à tout moment pour un échantillon GRATUIT !) Saviez-vous que nous avons également des friandises aux fruits frais que vous pouvez déguster en déplacement ? Passez nous voir aujourd'hui pour prendre un smoothie aux fruits frais, un milkshake Froyo Fruit™, un donut Edible®, un cornet de fruits enrobés de chocolat™ ou l'une de nos autres délicieuses collations aux fruits frais ! Elles sont parfaites pour les pique-niques, les collations pendant la pause déjeuner et même pour vous remonter le moral l'après-midi ! Si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les fêtes ou même d'un petit quelque chose juste parce que, appelez-nous au {{storePhoneNumber}}. Nous avons hâte de vous rencontrer !",
    [TK.STORE_DETAILS_META_DESCRIPTION]: `Obtenez un délicieux smoothie aux fruits sains, une friandise aux fruits trempés dans le chocolat, un beignet ou même une salade de fruits frais au magasin Edible Arrangements de {{city}} aujourd'hui!`,
    [TK.DIRECTIONS]: `Obtenir l'itinéraire`,
    [TK.STORE_COMING_SOON_ADDRESS]: 'Edible® {{storeNumber}}, {{address1}}',
    [TK.STORE_BANNER_COMING_SOON_BODY]:
      'Suivez Edible Arrangements {{city}}, {{state}} - {{address1}} pour un accès anticipé à des offres excitantes et des friandises exclusives.',
    [TK.STORE_BANNER_COMING_SOON_BUTTON]: 'Acheter maintenant',
    [TK.STORE_BANNER_COMING_SOON_TITLE]: 'Ouverture prochaine !',
    [TK.STORE_BANNER_COMING_SOON_ALT_TEXT]:
      'Bannière de magasin prochainement ouvert.',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT]:
      'En savoir plus sur la franchise Edible',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_TITLE]: 'Vivez la vie SwLet!',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE]: 'Possédez une franchise',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_BODY]:
      'Découvrez comment ouvrir votre propre magasin Edible et rejoignez notre communauté passionnée de franchisés.',
    [TK.FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON]: 'En savoir plus',
    [TK.COMING_SOON_TEXT]: 'Ce magasin ouvrira bientôt !',
    [TK.STORE_COMING_SOON_ABOUT_STORE_TITLE]: 'À propos de notre magasin',
    [TK.STORE_COMING_SOON_ABOUT_STORE_ONE]: `Edible Arrangements® {{storeNum}} à {{city}}, {{state}} ouvrira bientôt pour rendre notre communauté plus douce !`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_TWO]: `Nos arrangements de fruits et cadeaux sont toujours préparés quotidiennement avec des fruits cultivés et sélectionnés selon nos normes d'experts en fruits. Et nous avons également la meilleure variété de fruits trempés dans du chocolat gourmet ! Essayez nos légendaires fraises, pommes, ananas, bananes, oranges et poires trempées dans du chocolat.`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_THREE]: `Ici, vous trouverez uniquement les cadeaux de fruits les plus frais. Lorsque nous ouvrirons, passez nous voir et découvrez pourquoi les nôtres sont considérés comme les meilleurs fruits trempés. Offrez-vous un bouquet de fruits parfait ou une boîte de fruits trempés pour une occasion spéciale ! Alors passez bientôt si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les vacances ou même d'un petit quelque chose juste parce que.`,
    [TK.STORE_COMING_SOON_ABOUT_STORE_FOUR]: `bientôt. ered The Best Dipped Fto h, Period®fect fruit bouquet or dipped fruit box for your special occasion! So stop by soon if you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because.`,
    [TK.SOCIAL_MEDIA_TITLE]: 'Connectons-nous !',
    [TK.SOCIAL_MEDIA_BODY]:
      'Suivez ce magasin sur les réseaux sociaux pour des promotions exclusives !',
    [TK.STORE_COMING_SOON_SOCIAL_FACEBOOK_ARIA_LABEL]:
      'Suivez Edible Store sur Facebook',
    [TK.STORE_COMING_SOON_SOCIAL_INSTAGRAM_ARIA_LABEL]:
      'Suivez Edible Store sur Instagram',
    [TK.STORE_COMING_SOON_SOCIAL_PINTEREST_ARIA_LABEL]:
      'Suivez Edible Store sur Pinterest',
    [TK.STORE_COMING_SOON_SOCIAL_TWITTER_ARIA_LABEL]:
      'Suivez Edible Store sur Twitter',
    [TK.STORE_COMING_SOON_SOCIAL_YOUTUBE_ARIA_LABEL]:
      'Suivez Edible Store sur YouTube',
    [TK.STORE_COMING_SOON_CATEGORIES_TITLE]:
      'Nous pensons que vous aimerez ces cadeaux populaires',
    [TK.STORE_COMING_SOON_DISTANCE_AWAY]:
      '~{{distance}} {{distanceMetric}} de distance',
    [TK.STORE_COMING_SOON_DETAILS_NO_ADDRESS]:
      'Edible Arrangements {{city}}, {{state}}',
    [TK.STORE_COMING_SOON_DETAILS_ADDRESS1]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}}',
    [TK.STORE_COMING_SOON_DETAILS_ADDRESS2]:
      'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
    [TK.STORE_COMING_SOON_DETAILS_META_TITLE]:
      'Edible Arrangements {{city}}, {{state}}: {{address1}} {{address2}}',
    [TK.STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION]:
      "Edible Arrangements® {{storeNumber}} à {{city}}, {{state}} a ouvert ses portes pour la première fois le {{openingDate}}. Depuis lors, nous aidons les gens de notre communauté locale à célébrer toutes sortes d'occasions - grandes et petites. Nos arrangements de fruits et nos cadeaux sont toujours préparés avec des fruits frais cultivés selon nos normes d'expertise en fruits. Et nous avons également la meilleure variété de chocolat gourmet Dipped Fruit™ ! (Venez nous rendre visite à tout moment pour un échantillon GRATUIT !) Saviez-vous que nous avons aussi des friandises aux fruits frais que vous pouvez déguster en déplacement ? Passez nous voir aujourd'hui pour prendre un smoothie aux fruits frais, un Froyo Fruit Shake™, un Edible® Donut, un cône de fruits trempés au chocolat ou l'une de nos autres délicieuses collations aux fruits frais ! Ils sont parfaits pour les pique-niques, les collations pendant la pause déjeuner et même pour vous remonter le moral l'après-midi ! Si vous avez besoin d'un cadeau d'anniversaire, de cadeaux de mariage, d'un centre de table pour les fêtes ou même d'un petit quelque chose juste parce que, appelez-nous au {{storePhoneNumber}}. Nous avons hâte de vous rencontrer !",
    [TK.STORE_COMING_SOON_DETAILS_META_DESCRIPTION]:
      "Prenez un délicieux smoothie aux fruits sains, une friandise aux fruits trempés au chocolat, un donut ou même une salade de fruits frais dans le magasin Edible Arrangements de {{city}} aujourd'hui !",
    [TK.SUBSCRIPTION_TITLE]: "Soyez informé de l'ouverture de ce magasin !",
    [TK.SUBSCRIPTION_SUBTITLE]:
      'Indiquez votre adresse e-mail pour les mises à jour du magasin et les promotions spéciales.',
    [TK.REDIRECT_SECTION_TITLE]:
      "Besoin de commander vos friandises dès aujourd'hui ?",
    [TK.REDIRECT_SECTION_SUBTITLE]:
      'Recherchez un magasin disponible dans votre région et commandez en ligne pour le retrait ou la livraison.',
    [TK.FIND_STORE_BUTTON]: 'Trouver un magasin',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_TITLE]: 'Super !',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_SUBTITLE]: 'Merci de vous être inscrit.',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_EMAIL]:
      'Vérifiez votre boîte de réception pour notre premier courriel bientôt !',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_TEXT]:
      'Vérifiez vos messages pour notre premier texto bientôt !',
    [TK.SUBSCRIPTION_FOOTER_DIALOG_SOCIAL_LINKS_TITLE]:
      'Assurez-vous de vous connecter avec nous ici aussi :',
    [TK.SUBSCRIPTION_EMAIL_DIALOG_FORM_STEP_BACKGROUND_ALT]:
      "Arrière-plan de l'étape du formulaire d'abonnement par courriel",
    [TK.SUBSCRIPTION_EMAIL_DIALOG_SUBMIT_SUCCESS_STEP_BACKGROUND_ALT]:
      "Arrière-plan de l'étape de soumission réussie de l'abonnement par courriel",
    [TK.SUBSCRIPTION_FACEBOOK]: 'facebook',
    [TK.SUBSCRIPTION_TWITTER]: 'twitter',
    [TK.SUBSCRIPTION_INSTAGRAM]: 'instagram',
    [TK.SUBSCRIPTION_LINKEDIN]: 'linkedin',
    [TK.SUBSCRIPTION_YOUTUBE]: 'youtube',
    [TK.SUBSCRIPTION_EMAIL_FORM_ERROR]: 'Entrez une adresse courriel valide',
    [TK.SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER]: 'votrenom@courriel.com',
    [TK.POPULAR_GIFTS]: 'Cadeaux populaires',
    [TK.TODAYS_STORE_HOURS]: `Horaires d'ouverture du magasin aujourd'hui`,
    [TK.HOURS]: 'Heures',
    [TK.LIMITED_LINES_HELPER_TEXT]: `Nous avons un espace limité pour l'impression, nous devons donc également limiter le nombre de lignes disponibles. Les lignes ici ne représentent pas la quantité réelle de lignes, faites attention aux indicateurs.`,
    [TK.FIELD_REQUIRED_HELPER_TEXT]: 'Ce champ est obligatoire',
    [TK.LINES_LIMIT_EXCEEDED]: 'Limite de lignes atteinte',
    [TK.TYPING_LAST_LINE]: 'Vous êtes dans la dernière ligne',
    [TK.REACHING_LAST_LINE]: 'Atteinte de la limite',
    [TK.SPL_CHARS_ERROR]: 'Les caractères (<>) ne sont pas autorisés',
    [TK.EMOJIS_ENTERED]: 'Les emojis ne sont pas autorisés',
    [TK.REDUCE_MESSAGE_LENGTH_HELPER_TEXT]:
      'Réduisez la longueur de votre message',
    'pdp.product_code': 'Code de Produit',
    'pdp.choose_recipient': 'Choisir le destinataire',
    'pdp.availability.where_is_it_going': 'Où va cette gâterie?',
    'pdp.availability.postal_code_label': 'Entrez le Code Postal',
    'pdp.availability.postal_code_validate_error':
      'Veuillez entrer un Code Postal valide',
    'pas_control.title': 'Livraison & Décroche',
    'pdp.availability.zip_unfortunately_does_not_service':
      'Pardon! Ce produit n’est pas disponible dans la zone que vous avez sélectionnée.',
    'pdp.date_selection.when': 'Quand?',
    'pdp.date_selection.today': 'Aujourd’hui',
    'pdp.date_selection.tomorrow': 'Demain',
    'pdp.date_selection.date_picker': 'Sélectionnez la Date',
    'filters.filters': 'Filtres',
    'filters.clear_all': 'Effacer Tous les Filtres',
    'plp.filter_chip.clear_filters': 'Effacer Tous les Filtres',
    'sort.sort_by': 'Trier Par',
    'sort_select.price': 'Prix',
    'sort_select.relevance': 'Pertinence',
    'sort_select.price_desktop_asc': 'Prix — Faible à Élevé',
    'sort_select.price_desktop_desc': 'Prix — Élevé à Faible',
    'pdp.delivery_method.question': 'Comment aimeriez-vous l’obtenir?',
    'pdp.accordion.description': 'Environ',
    'pdp.accordion.ingredients': 'Description du Produit',
    'pdp.accordion.questions': 'Questionne? Demandez-le!',
    'reviews.review': 'Critiques',
    'reviews.reviews': 'Critiques',
    'pdp.accordion.faq': 'Produit FAQs',
    'plp.load_more_button': 'Charger Plus',
    'pdp.button.continue': 'Continuer',
    'upsell.continue': 'Continuer',
    'plp.call-center-welcome-modal.continue': 'Continuer',
    'signup_back.text': 'Retour',
    'common.back': 'Retour',
    'auth.dialog.modal.header': "S'identifier",
    'customer_service_page.track_order_title': 'Suivez Votre Commande',
    'customer_service_page.track_order_helper_text':
      'Pour suivre l’état actuel de votre commande, veuillez entrer votre numéro de commande',
    'customer_service_page.track_order_order_number_label_text':
      'Numéro de Commande',
    'customer_service_page.track_order_tooltip_text':
      'Votre numéro de commande se trouve dans l’e-mail de confirmation de commande que vous avez reçu de notre part.',
    'customer_service_page.track_order_btn_text': 'Suivre la Commande',
    'customer_service_page.edible_home_page': 'Domicile',
    'customer_service_page.track_order_order_number_valid_error':
      'Le numéro de commande que vous avez entré n’est pas valide',
    'customer_service_page.track_order_order_number_required_error':
      'Numéro de Commande Requis',
    'customer_service_page.track_order_order_number_over_error':
      'Le numéro de commande doit comporter au maximum 12 caractères',
    'customer_service_page.track_order_order_number_under_error':
      'Le numéro de commande doit comporter au moins 10 caractères',
    'customer_service_page.track_order_order_not_found_title_error':
      'Commande Introuvable',
    'customer_service_page.track_order_order_not_found_error_message':
      'Aucun enregistrement de ce numéro de suivi ne peut être trouvé pour le moment, veuillez vérifier le numéro et réessayer plus tard. Pour obtenir de l’aide, veuillez communiquer avec',
    'customer_service_page.track_order_customer_service': 'Service Client',
    'contact_us.text': 'Contactez-nous',
    'contact_us_form.header':
      'Nous promettons de rendre les moments WOW possibles pour chaque invité, c’est vous! S’il y a un problème avec votre commande, l’aide est à portée de clic.',
    'contact_us_form.reason': 'En quoi pouvons-nous vous aider?',
    'contact_us_form.order': 'Numéro de Commande',
    'contact_us_form.user_type':
      'Avez-vous passé la commande (Client) ou avez-vous reçu la commande en cadeau (Destinataire)?',
    'contact_us_form.first_name': 'Prénom',
    'contact_us_form.last_name': 'Nom',
    'contact_us_form.email': 'Messagerie électronique',
    'contact_us_form.phone': 'Numéro de téléphone',
    'contact_us_form.message': 'Message',
    'contact_us_form.first_name_spl_chars': 'Entrez un prénom valide',
    'contact_us_form.last_name_spl_chars': 'Entrez un nom de famille valide',
    'contact_us_form.send_request': 'Envoyer une Demande',
    'contact_us_form.best_time_to_call': 'Meilleur moment pour appeler',
    'contact_us_form.provide_information':
      'Veuillez fournir autant d’informations que possible. Un membre de l’équipe vous contactera dès que possible.',
    'pdp.delivery_method.pickup_in_store': 'Cueillette en Magasin',
    'pdp.delivery_method.free': 'Gratuit',
    'pdp.delivery_method.hand_delivery': 'Livraison',
    'store_selector.title.pickup': 'Sélectionnez un magasin de ramassage local',
    'upsell.title': 'Améliorez Votre Cadeau',
    'upsell.subtitle':
      'Ajoutez une touche personnelle à votre arrangement avec des ballons, un ours en peluche en peluche, des fruits gastronomiques trempés dans du chocolat et plus encore!',
    'upsell.options.show_all': 'Afficher tout',
    show_more: 'Afficher plus',
    show_less: 'Afficher moins',
    'upsell.options.show_more': 'Afficher Plus',
    'session.lock.reset': 'Réinitialisation',
    'order.header.pickup': 'Informations sur le Ramassage',
    'order.header.shipment': 'Informations sur L’expédition',
    'order.header.delivery': 'Informations de Livraison',
    'recipient_form.recipient_name': 'Nom du Destinataire',

    'recipient_form.first_name_placeholder': 'Prénom',
    'recipient_form.last_name_placeholder': 'Nom',
    'recipient_form.address_title': 'Adresse',
    'recipient_form.address_type_label': 'Type D’adresse',

    'recipient_form.business_name_label': '{{type}} nom',
    'recipient_form.city_label': 'Ville / Village',
    'recipient_form.po_box_note':
      'Veuillez noter que nous ne pouvons pas livrer à des boîtes postales.',
    'recipient_form.street_address': 'Adresse Municipale',

    'recipient_form.apt_floor_suite_button': 'Apt/Étage/Suite',
    'recipient_form.apt_floor_suite_label': 'Apt/Étage/Suite (facultatif)',
    'recipient_form.business_warning':
      'Nous ne conseillons pas les livraisons le jour même après 15h00 car de nombreuses entreprises ferment à 17h00. Votre magasin local peut vous contacter si vous passez une commande le jour même pour la livraison afin de reporter votre commande à un autre jour ouvrable.',
    'recipient_form.choose_option': 'Veuillez Choisir',
    'recipient_form.pickup_time': 'Heure de Ramassage',
    'recipient_form.enter_first_name': 'Entrez le prénom.',
    'recipient_form.enter_last_name': 'Entrez le nom de famille.',
    'login_or_address_book.login_text':
      'Connectez-vous au carnet d’adresses Access',
    'pas_control.select_local_pickup_shop':
      'Sélectionnez un magasin de ramassage local',
    'pickup_form.identification': 'Identification',
    'pickup_form.phone.label': 'Numéro de Téléphone de Ramassage',
    'pickup_form.date_time.header': 'Date & Heure',
    'pickup_form.date_time.label': 'Heure de Ramassage',
    'pickup_form.date_time.alert.on': 'Ramassage le:',
    'pickup_form.instruction.button': 'Instructions de Ramassage',
    'pickup_form.instruction.header': 'Instructions de Ramassage',
    'pickup_form.instruction.input.label':
      'Instructions de Ramassage (facultatif)',
    'instructions.delivery_instructions_hint':
      'Carrefours, points de repère, informations pour les communautés fermées, etc.',
    'order.occasion.header': 'Quelle est L’occasion?',
    'order.occasion.alert':
      'Aidez-nous à mieux comprendre les occasions et les événements tendance afin que nous puissions améliorer nos produits',
    'order.occasion.select.label': 'Occasion ou Événement (facultatif)',
    'order.occasion.option_title': 'Veuillez Choisir',
    'greetings_cards.heartfelt_message': 'Laissez-leur un message sincère!',
    'greetings_cards.gift_message_free_hint':
      'Nos friandises feront bonne impression, mais votre carte peut être un souvenir qu’ils chériront pour les années à venir.',
    'greetings_cards.gift_message_free_explain':
      'Ce message apparaîtra sur l’étiquette volante de votre cadeau. Il ne sera pas enfermé dans une enveloppe.',
    'greetings_cards.enter_card_message': 'Entrez un Message Cadeau Gratuit',
    'greetings_cards.remove_card_message': 'Supprimer le Message Cadeau',
    'greetings_cards.optional': 'facultatif',
    'greetings_cards.top_pick': 'PREMIER CHOIX',
    'greetings_cards.premium_card': 'Ajouter une carte Premium',
    'greetings_cards.remove_card': 'Retirer la Carte',
    'greetings_cards.card_preview': 'Aperçu de la carte',
    'greetings_cards.printible_iframe_error':
      'Erreur de prise en charge du navigateur: Le navigateur ne prend pas en charge iframe.',
    'greetings_cards.printible_subtitle':
      'Téléchargez votre <strong>propre photo</strong> ou utilisez une carte préconçue avec votre <strong>message personnalisé</strong>',
    'greetings_cards.complimentary_card': 'Carte Gratuite',
    'pdp.button.add_to_cart': 'Ajouter au Panier',
    'pdp.button.redicting': 'Redirection...',
    'pdp.button.submitting': 'Soumettre...',
    'session.lock.more_info': 'Plus d’infos',
    'session.lock.less_info': 'Moins d’infos',
    'store_selector.show_more_delivery_stores':
      'Afficher Plus de Magasins de Livraison',
    'order.contacts.alert':
      'Veuillez fournir au moins un numéro de téléphone du destinataire, nous utiliserons ce numéro en cas de complications à l’accouchement',
    'order.contacts.header': 'Coordonnées du Bénéficiaire',
    'order.contacts.add.button': 'Téléphone Cellulaire et/ou Professionnel',
    'order.contacts.phone': 'Téléphone Résidentiel',
    'order.contacts.cell_phone': 'Téléphone Portable',
    'order.contacts.work_phone': 'Téléphone Professionnel',
    'order.contacts.email.label': 'E-mail du Destinataire (facultatif)',
    'instructions.delivery_instructions': 'Instructions de Livraison',
    'instructions.delivery_instructions_label':
      'Instructions de Livraison (facultatif)',
    'instructions.read_our_delivery_policies':
      'Lisez nos Politiques de Livraison',
    'instructions.please_dont_enter_time':
      'Veuillez ne pas saisir de demande de délai de livraison ici.',
    'auth.log_in.error.header': 'Votre compte est verrouillé',
    'auth.log_in.error.locked':
      'Vous avez eu trop de tentatives de connexion infructueuses. Veuillez réessayer plus tard',
    'auth.log_in.error.try_again': 'Veuillez réessayer après un certain temps',
    'auth.log_in.error.invalid_credentials':
      'Veuillez entrer votre adresse e-mail et votre mot de passe',
    'auth.log_in.error.not_exist':
      'Nous n’avons pas pu trouver d’utilisateur avec cet e-mail et ce mot de passe. Veuillez réessayer',
    'auth.log_in.error._exception':
      'Nous avons un problème pour vous inscrire, désolé pour la gêne occasionnée. Veuillez réessayer plus tard',
    'auth.dialog.modal.registration.tip': 'Vous n’avez pas de compte?',
    'auth.dialog.modal.registration': 'Créer un Compte',
    'auth.log_in.form.email.label': 'Courriel',
    'auth.log_in.form.password.label': 'Mot de Passe',
    'auth.log_in.form.password.placeholder': 'au moins 8 caractères',
    'auth.log_in.form.reset.label': 'Vous avez oublié votre mot de passe?',
    'auth.log_in.form.signing.in': 'Connexion...',
    'auth.log_in.form.success': 'Succès',
    'auth.log_in.form.sign.in': 'Connexion',
    'auth.err.email.invalid': 'Veuillez saisir une adresse e-mail valide',
    'auth.log_in.err.password.required': 'Le mot de passe est requis',
    'auth.log_in.err.email.required': 'L’adresse e-mail est requise',
    'auth.log_in.dialog.reset.header': 'Mot de passe oublié',
    'auth.reset.tip':
      'Entrez votre adresse e-mail ci-dessous et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    'auth.reset.email.label': 'Courriel',
    'auth.reset.submitting': 'Connexion',
    'auth.reset.submit': 'Connexion',
    'auth.reset.sent.1': 'L’aide est en route!',
    'auth.reset.sent.2':
      'Nous vous avons envoyé un e-mail avec des instructions sur la façon de réinitialiser votre mot de passe.',
    'auth.reset.sent.3': 'Soyez à l’affût bientôt!',
    'auth.reset.status.not_found':
      'Désolé, nous n’avons pas pu trouver cette adresse e-mail. Veuillez réessayer ou créer un nouveau compte',
    'signup_email_already_exist.text':
      'Vous êtes déjà membre Edible Rewards. Connectez-vous à votre compte pour voir vos avantages.',

    'signup.text': 'Créer un Nouveau Compte',
    'signup_account_infromation.text': 'Informations sur le Compte',
    'signup_your_name.text': 'Votre Nom',
    'signup_birthday.text': 'Anniversaire',
    'signup_email_address.text': 'Adresse Courriel',
    'signup_email_not_valid_error.text':
      'Veuillez saisir une adresse e-mail valide',
    'signup_password.text': 'Mot de Passe',
    'signup_password.strength': 'Force du mot de Passe',
    'signup_password.invalid':
      'Le mot de passe doit avoir au moins 1 caractère spécial (!@#$%^&*)',
    'signup_password.minimum':
      'Le mot de passe doit contenir au moins 8 caractères',
    'signup.verify_password_error': 'Vérifier le mot de Passe',
    'signup.verify_password_match': 'Les mots de passe doivent correspondre',
    'signup_first_name.text': 'Prénom',
    'signup_phone_number.text': 'Numéro de Téléphone',
    'signup_last_name.text': 'Nom',
    'signup_birthday.tooltip':
      'Dites-nous votre date de naissance et votre mois et nous vous enverrons un cadeau d’anniversaire gratuit!',
    'signup_birth_date.text': 'Jour',
    'signup_birth_day.text': 'Jour',
    'signup_birth_day.error': 'Entrez le jour de naissance',
    'signup_birth_month.text': 'Mois',
    'signup_birth_month.error': 'Entrez le mois de naissance',
    'signup_birth_year.text': 'Année (facultatif)',
    'signup_birth_year.placeholder': 'Année',
    'signup_password.requirements':
      'Votre mot de passe doit comporter au moins 8 caractères et contenir au moins 1 caractère spécial (!@#$%^&*).',
    'signup_first_name.required': 'Entrez le prénom',
    'signup_last_name.required': 'Entrez le nom de famille',
    'signup_first_name.error': 'Entrez un prénom valide',
    'signup_last_name.error': 'Entrez un nom de famille valide',
    'signup_phone_number.error': 'Entrez un numéro de téléphone valide',
    'signup_field.required': 'Ce champ est obligatoire',
    'signup_form.choose_option': 'Veuillez Choisir',
    'signup_create_account_button.text': 'Créer un Compte',
    'signup_success_title.text': 'Félicitations!',
    'signup_success_content.text':
      'Visitez Mon compte pour voir vos récompenses disponibles.',
    'signup_my_account_button.text': 'Mon Compte',
    'signup_shop_now_button.text': 'Acheter Maintenant',
    'signup_link_terms_of_use.text': 'Conditions d’utilisation',
    'signup_link_privacy_policy.text': 'Notre politique de confidentialité',

    'signup_link_products_policy.text': 'Politique Relative aux Produits',
    'signup_link_edible_rewards.text': 'Edible Rewards® Termes & conditions',
    'signup_link_click_here.text': 'cliquez ici',
    'signup_terms_and_conditions.text':
      'En cliquant sur le bouton marqué CRÉER UN COMPTE ou par tout autre acte de votre part d’utiliser le site Edible Arrangements® ou nos services de quelque manière que ce soit, vous reconnaissez avoir lu et compris et accepté d’être légalement lié par tous les termes et conditions de notre site Web {{termsOfUse}}, {{privacyPolicy}}, {{productsPolicy}}, {{edibleRewards}} et toutes les conditions d’utilisation supplémentaires ou n’acceptent pas d’être liés par toutes les conditions d’utilisation ou conditions supplémentaires, {{clickHere}} pour plus d’informations concernant les Edible Rewards®.',
    'signup_contact_information.text': 'Coordonnées',
    'search.helper.text': 'Search for products, stores and information...',
  },
};
