import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const continueButtonWrapper: SxProps<Theme> = (theme) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 5,
  width: 1,
  py: 2,
  px: 2.5,
  background: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    background: 'transparent',
    zIndex: 0,
    p: 0,
    borderTop: 'none',
  },
});

export const counterButton: SxProps<Theme> = (theme) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
});
