import { Typography } from '@mui/material';
import { theme } from 'utils/theme/theme';
import styled from '@emotion/styled/macro';

export const ScheduleWeekdays = styled(Typography)({
  width: [1, 'auto'],
  [theme.breakpoints.up('sm')]: {
    width: 84,
  },
});
