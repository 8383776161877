function checkIsHomePage(pathname: string): boolean {
  return ['/home', '/home/'].includes(pathname);
}

export function checkIsPdp(pathname: string): boolean {
  return Boolean(/\/fruit-gifts\/.+/.exec(pathname));
}

function checkIsLandingPage(pathname: string): boolean {
  return Boolean(/\/l\/.+/.exec(pathname));
}

export function checkIsPlpPath(pathname: string): boolean {
  return (
    !checkIsHomePage(pathname) &&
    !checkIsPdp(pathname) &&
    !checkIsLandingPage(pathname)
  );
}

export const isUrlToAnotherResource = (menuUrl: string): boolean =>
  ['http://', 'https://', '.com'].some(
    (searchString) => menuUrl.lastIndexOf(searchString, 0) === 0,
  );
