import * as styles from 'components/GiftFinder/Form/styles';
import { BaseExpandedSelector } from 'components/GiftFinder/Form/base-expanded-selector';
import { Box, Slider, Typography } from '@mui/material';
import { Filter, FilterCategory } from 'api/filter-categories/types';
import { marks, valuetext } from 'components/GiftFinder/utils/cost-utils';
import React, { FC } from 'react';

interface ExpandedCostSelectorProps {
  setFilterData: (filterData: Filter[]) => void;
  selectedFilterData: Filter[];
  category: FilterCategory[];
  selectedCostRange: number[];
  setSelectedCostRange: (costRange: number[]) => void;
}

export const ExpandedCostSelector: FC<ExpandedCostSelectorProps> = (props) => {
  const handleChange = (event: Event | null, newValue: number | number[]) => {
    const range = newValue as number[];

    const minIndex = Math.min(...range);
    const maxIndex = Math.max(...range);
    const minValue = marks[minIndex].price;
    const maxValue = marks[maxIndex].price;

    const filteredItems = props.category[0].Items.filter((item) => {
      const itemValue = parseFloat(item.Value.split('-')[0]);

      return itemValue >= minValue && itemValue <= maxValue;
    });

    props.setSelectedCostRange(range);
    props.setFilterData(filteredItems);
  };

  return (
    <BaseExpandedSelector
      label="Cost"
      subHeading="I'm on a budget."
      imageSrc={props?.category[0]?.FilterCategoryImageURL}
      isSelected={props.selectedFilterData.length > 0}
    >
      <Box width="100%" sx={styles.expandedCostSelectorContainer}>
        <Slider
          value={props.selectedCostRange}
          onChange={handleChange}
          valueLabelDisplay="off"
          getAriaValueText={valuetext}
          marks={marks}
          step={null}
          min={0}
          max={6}
          sx={styles.costSlider}
        />
        <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
          <Typography variant="body2">{'<$30'}</Typography>
          <Typography variant="body2">$500+</Typography>
        </Box>
      </Box>
    </BaseExpandedSelector>
  );
};
