import { dataLayer } from 'hooks/google/data-layer';

export const analyticInitialnotificationBannerLoad = (
  messages: string[],
): void => {
  dataLayer.push({
    event: 'notificationBannerLoad',
    notificationBanner: {
      pageName: 'Home Page',
      pageType: 'Home Page - Notification Banner',
      siteVersion: 'v1.0',
      techStack: 'platform',
      messages,
    },
  });
};

export const notificationBannerCTAClicked = (
  buttonText: string,
  buttonLink: string,
): void => {
  dataLayer.push({
    event: 'notificationBannerCTAClicked',
    notificationBanner: {
      pageName: 'Home Page',
      pageType: 'Home Page - Notification Banner',
      siteVersion: 'v1.0',
      techStack: 'platform',
      buttonText,
      buttonLink,
    },
  });
};

export const notificationBannerNextMessage = (messages: string): void => {
  dataLayer.push({
    event: 'notificationBannerNextMessage',
    notificationBanner: {
      pageName: 'Home Page',
      pageType: 'Home Page - Notification Banner',
      siteVersion: 'v1.0',
      techStack: 'platform',
      messages,
    },
  });
};

export const notificationBannerClosed = (messages: string[]): void => {
  dataLayer.push({
    event: 'notificationBannerClosed',
    notificationBanner: {
      pageName: 'Home Page',
      pageType: 'Home Page - Notification Banner',
      siteVersion: 'v1.0',
      techStack: 'platform',
      messages,
    },
  });
};
