import { SxProps } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const alert: ThemedSxProps = (theme) => ({
  width: 1,
  backgroundColor: theme.palette.custom.bodyBg,
  px: 2,
  py: [2, 2, 1.5],
  textAlign: 'initial',
  justifyContent: 'space-between',
});

export const lockIcon: SxProps = {
  width: 24,
  height: 24,
  mr: 0.5,
  display: 'inline',
  position: 'relative',
  top: 2,
};

export const lockDetails: SxProps = {
  width: [0.6, 0.7, 0.7, 0.7],
  p: [0.4, 0.6, 0.8, 1],
  pl: [0.2, 0.3, 0.4, 0.5],
  pr: [0.5, 1, 1.5, 2],
  fontWeight: 500,
  fontSize: ['12px', '14px', '14px', '15px'],
};

export const recipientChange: SxProps = {
  textDecoration: 'underline',
  fontWeight: 400,
  textAlign: 'right',
  fontSize: ['12px', '14px', '14px', '15px'],
};
