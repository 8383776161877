import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import * as styles from 'components/LocalSeoPage/States/styles';
import { CanonicalUrlProvider } from 'providers/CanonicalUrlProvider';
import { Grid, Typography } from '@mui/material';
import { HeadMetaStates } from 'components/LocalSeoPage/HeadMeta/HeadMetaStates';
import { LOCAL_SEO_ALIAS } from 'containers/MainContainer/constants/path';
import { RedirectUrl } from 'components/LocalSeoPage/RedirectUrl/RedirectUrl';
import { StateLink } from 'components/LocalSeoPage/States/styles';
import { setSeoUrlParam } from 'utils/set-seo-url-param';
import { statesUS } from 'utils/country';
import { trackStateClicked } from 'components/LocalSeoPage/analytics/track-local-seo-click.aa';
import React, { FC } from 'react';
import i18next from 'i18next';

export const States: FC = () => (
  <>
    <RedirectUrl />
    <CanonicalUrlProvider />
    <HeadMetaStates />
    <Typography variant="h1" sx={styles.stateTitle}>
      {i18next.t(TK.STATES_SEO_PAGE_TITLE)}
    </Typography>
    <Typography sx={styles.stateDescription}>
      {i18next.t(TK.STATES_SEO_PAGE_DESCRIPTION)}
    </Typography>
    <Grid
      container
      mb={5}
      spacing={{ xs: 1, sm: 3, md: 4, lg: 5 }}
      columns={{ xs: 1, sm: 10, md: 14, lg: 18 }}
    >
      {statesUS.map((stateUS) => (
        <Grid item xs={1} sm={3} md={4} key={stateUS}>
          <StateLink
            to={`/${LOCAL_SEO_ALIAS}/${setSeoUrlParam(stateUS)}`}
            onClick={() => trackStateClicked(stateUS)}
          >
            {stateUS}
          </StateLink>
        </Grid>
      ))}
    </Grid>
  </>
);
