import { IconButton, styled } from '@mui/material';
import { theme } from 'utils/theme/theme';

export const CustomizedControl = styled(IconButton)({
  '&.slick-arrow': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.other.outlineBorder}`,
    transition: '.2s',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
    '&:before': {
      content: 'none',
    },
    '&.slick-disabled': {
      color: theme.palette.action.disabled,
      '&:hover, &:focus': {
        color: theme.palette.action.disabled,
      },
    },
    '&.slick-prev': {
      left: '-8px',
    },
    '&.slick-next': {
      right: '-4px',
    },
  },
});
