import { RootState } from 'redux/store';
import { resetPrintibleOrderToInitialState } from 'components/PDP/Order/GreetingsCards/feature/greetings-cards.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useResetPrintibleOnAreaChange = (): void => {
  const areaName = useSelector(({ area: { data } }: RootState) => data.name);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPrintibleOrderToInitialState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaName]);
};
