import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getIsRecipientIdExist } from 'redux/cart/cart.selectors';
import { getIsStatusPending } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { isPending } from 'utils/status.comparer';

export const getIsAddToCartBlocked = createSelector(
  getIsStatusPending,
  getIsRecipientIdExist,
  ({ cart: { addingStatus } }: RootState) => addingStatus,
  (addressValidationPending, recipientExist, cartAddingStatus) =>
    addressValidationPending || isPending(cartAddingStatus) || recipientExist,
);
