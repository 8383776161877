import { SxProps } from '@mui/system';
import {
  careerCorporateImage,
  careerLaborSection,
  careerLaborSectionHover,
  careerMainBanner,
  careerStoreImage,
} from 'utils/cloudinaryAssets';
import { theme } from 'utils/theme/theme';

export const careerContainer: SxProps = {
  margin: ['0', '0 auto;', '0 auto'],
  width: ['100%', '688px', '1060px'],
};

export const bannerMainContainer: SxProps = {
  backgroundImage: `url(${careerMainBanner})`,
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: ['473px', '415px', '350px'],
  padding: '100px 0',
  textAlign: 'center',
};

export const bannerHeaderStyle: SxProps = {
  color: 'white',
  fontSize: ['3em', '3em', '3.5em'],
  padding: '0',
  wordSpacing: '-3px',
  fontFamily: 'poetsenone !important',
};

export const bannerSubHeaderStyle: SxProps = {
  color: 'white',
  fontFamily: '16px',
};

export const bannerButtonStyle: SxProps = {
  backgroundColor: '#E93B3B',
  color: 'white',
  padding: '5px 18px',
  borderRadius: '20px',
  fontSize: '16px',
  display: 'inline-block',
  fontWeight: '400',
};

export const aboutUsConatiner: SxProps = {
  alignItems: 'center',
  flexDirection: ['column', 'column', 'row'],
};

export const careerFlowerImageStyle: SxProps = {
  width: ['200px', '200px', '300px'],
};

export const aboutTextStyle: SxProps = {
  fontFamily: 'poetsenone !important',
  fontSize: '30px',
  color: '#111',
};

export const careerFruitLabour: SxProps = {
  padding: ['35px 20px 55px', '35px 25px 55px', '35px 60px 55px'],
  backgroundColor: '#002577',
  marginBottom: '50px',
  color: '#fff',
};

export const careerCorporateBack: SxProps = {
  background: `url(${careerCorporateImage})`,
  height: '400px',
  marginRight: '5px',
  width: ['100%', '690px', '527px'],
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
};

export const careerStoresBack: SxProps = {
  background: `url(${careerStoreImage})`,
  height: '400px',
  width: ['100%', '690px', '527px'],
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
};

export const careerCorporateInner: SxProps = {
  textAlign: 'center',
  cursor: 'pointer',
  fontFamily: 'poetsenone !important',
};

export const jobListingText: SxProps = {
  lineHeight: '30px',
  transform: 'translateY(-50%)',
  color: 'white',
  letterSpacing: '1px',
  fontFamily: 'poetsenone !important',
  fontSize: '30px',
  marginTop: '-200px',
};

export const fruitTextStyle: SxProps = {
  fontFamily: 'poetsenone !important',
  fontSize: ['24px', '30px', '30px'],
  padding: '20px 0px 20px 0px',
};

export const linkTextStyle: SxProps = {
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
};

export const careerJobReviews: SxProps = {
  padding: ['10px 0 0', '5px 60px 10px 55px', '5px 60px 10px 55px'],
  backgroundColor: 'rgb(237, 37, 36)',
  height: '414px',
  marginBottom: '50px',
  color: '#fff',
};

export const careerProperties: SxProps = {
  padding: ['10px 0 0', '5px 15px 10px 10px', '5px 40px 10px 35px'],
  height: ['814px', '334px', '434px'],
  marginBottom: '50px',
  color: '#fff',
};

export const propertiesTextStyle: SxProps = {
  fontFamily: 'poetsenone !important',
  fontSize: ['16px', '16px', '30px'],
  padding: '0px',
  color: '#E93B3B',
  textTransform: ['uppercase', 'uppercase', 'capitalize'],
};

export const careerPropertiesItems: SxProps = {
  display: ['block !important', 'flex !important', 'flex !important'],
  pt: [2.5, 0, 0],
  flexDirection: 'row',
  border: '1px solid lightgray',
  borderRadius: '18px',
  alignItems: 'center',
  textAlign: 'center',
};

export const careerPropertiesItemsSerial: SxProps = {
  width: '32px',
  height: '32px',
  display: 'inline-block',
  background: '#E93B3B',
  borderRadius: '100%',
  color: 'white',
  fontFamily: 'Oswald',
};

export const careerPropertiesItemsTitle: SxProps = {
  fontFamily: 'poetsenone !important',
  fontSize: ['24px', '24px', '30px'],
  padding: '0px',
  color: '#111',
};

export const careerPropertiesItemsDesc: SxProps = {
  fontSize: ['15px', '15px', '16px'],
  color: '#111',
  height: ['150px', '145px', '145px'],
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  margin: '0 0 5px',
};

export const careerJobReviewsItems: SxProps = {
  '& img': {
    margin: '0 auto',
    border: '5px solid white',
    borderRadius: '50%',
  },
  '& p': {
    textAlign: 'center',
    fontSize: ['12px', '14px', '19px'],
  },
  '& .reviews': {
    height: ['205px', '205px', '212px'],
    margin: '0 auto',
    width: ['348px', '530px', '700px'],
  },
};

export const laborContainer: SxProps = {
  width: ['100%', '100%', '1060px'],
  margin: '0 auto',
  marginTop: '35px',
};

export const laborSubSection: SxProps = {
  background: `url(${careerLaborSection})`,
  flex: 1,
  textAlign: 'center',
  padding: '30px 0',
  margin: '4px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundPosition: 'center',
  paddingTop: '30px',
  height: '205px',
  '&:hover': {
    background: `url(${careerLaborSectionHover})`,
    backgroundPosition: 'center',
    paddingTop: '0',
    '& .laborSubSection1': {
      display: 'none',
    },
    '& .laborSubSection1Hover': {
      display: 'block',
    },
    '& .laborSubSection2': {
      display: 'none',
    },
    '& .laborSubSection2Hover': {
      display: 'block',
    },
    '& .laborSubSection3': {
      display: 'none',
    },
    '& .laborSubSection3Hover': {
      display: 'block',
    },
    '& .laborSubSection4': {
      display: 'none',
    },
    '& .laborSubSection4Hover': {
      display: 'block',
    },
    '& .laborSubSection5': {
      display: 'none',
    },
    '& .laborSubSection5Hover': {
      display: 'block',
    },
    '& .laborSubSection6': {
      display: 'none',
    },
    '& .laborSubSection6Hover': {
      display: 'block',
    },
  },
};

export const laborImageStyle: SxProps = {
  maxWidth: ['45px', '45px', '70px'],
  display: 'inline',
};

export const laborImage3Style: SxProps = {
  maxWidth: '145px',
  display: 'inline',
};

export const hoverTextStyle: SxProps = {
  padding: ['0', '0', '0 20px'],
  color: 'white',
  fontSize: '16px',
};

export const hoverTextSection3Style: SxProps = {
  padding: ['25px 27px 0 26px', '25px 27px 0 26px', '25px 27px 0 26px'],
  color: 'white',
  fontSize: '16px',
};

export const socialLinkContainer: SxProps = {
  margin: '50px 0',
  '& .socialIcon': {
    verticalAlign: 'bottom',
    border: '1px solid',
    padding: '5px',
    borderRadius: '100%',
    width: '27px',
    height: '27px',
    display: 'table-cell',
    fontSize: '15px',
    marginRight: '5px',
  },
};

export const followText: SxProps = {
  color: '#E93B3B',
  textAlign: 'center',
  fontFamily: 'poetsenone !important',
  fontSize: '30px',
};

export const jobListingContainer: SxProps = {
  display: ['grid', 'grid', 'flex'],
  height: ['770px', '770px', '500px'],
  marginBottom: ['150px', '150px', 0],
};

export const propertiesImage: SxProps = {
  height: ['378px', '241px', '315px'],
  width: ['342px', '260px', '340px'],
  marginLeft: [0, '-19px', 0],
  [theme.breakpoints.between(400, 435)]: {
    width: '405px',
  },
};
