import { analytics } from 'service/adobe-analytics/analytics';
import { formatToLegacyDateString } from 'utils/date';
import { getSelectedServiceType } from 'service/adobe-analytics/utils';
import { useEffect } from 'react';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useSelector } from 'react-redux';

import { DEFAULT_PARTNER_PROGRAM_SHIPMENT_STORE } from 'redux/cart/cart.constants';
import { selectIsStoreOrRecipientLock } from 'redux/session/availability/availability.selectors';

export function useAAStoreSelectedEvent(): void {
  const pasData = usePasData();
  const isStoreOrRecipientLock = useSelector(selectIsStoreOrRecipientLock);

  useEffect(() => {
    if (
      !isStoreOrRecipientLock &&
      pasData.area?.name &&
      pasData.date &&
      pasData.store &&
      pasData.orderType
    ) {
      analytics.storeSelected({
        enteredZipCode: pasData.area?.name,
        selectedZipCode: pasData.store.areaName || '',
        selectedServiceType: getSelectedServiceType(pasData.orderType),
        selectedStoreDistance: pasData.store.distance || 0,
        selectedStoreNumber:
          pasData.store.number || DEFAULT_PARTNER_PROGRAM_SHIPMENT_STORE,
        selectedDate: formatToLegacyDateString(pasData.date),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasData.store]);
}
