import * as TK from 'translations/locales/translation-keys.constant';
import { Badge, IconButton, Link } from '@mui/material';
import { CartStoreNumberSetting } from 'types/cart.types';
import { Links } from 'enums/common-links';
import { RootState } from 'redux/store';
import { analytics } from 'service/adobe-analytics/analytics';
import { cartLink } from 'components/Header/styles';
import { getCartDetail, selectTitle } from 'redux/cart/cart.selectors';
import { nxtGenCartStoreNumberSetting } from 'redux/settings/settings.selectors';
import { push } from 'components/BreadcrumbBack/feature/slice';
import { segment } from 'service/segment/segment';
import { selectCartCount } from 'components/Cart/feature/selectors';
import { selectRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import i18next from 'i18next';

export const DesktopCartLink: FC = () => {
  const dispatch = useDispatch();
  const [newCartLoad, setNewCartLoad] = useState(false);
  const totalProductCount = useSelector(selectCartCount);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  let cartRecipientId = useSelector(
    ({ availabilitySession }: RootState) =>
      availabilitySession.data.currentRecipientId,
  );
  const title = i18next.t(TK.HEADER_SHOPPING_CART);
  const { pathname, search } = useLocation();
  const pageName = useSelector(selectTitle);
  const onClick = () => {
    dispatch(
      push({
        href: `${pathname}${search}`,
        title: pageName,
      }),
    );
    analytics.headerMenuItemClicked(title);
    segment.menuItemClicked(
      title,
      `/${pathname}${search}`,
      userSession,
      userAccountInformation,
    );
  };
  const cartDetail = useSelector(getCartDetail);
  const storeSettings = useSelector(nxtGenCartStoreNumberSetting);
  const availableRecipients = useSelector(selectRecipients);
  let isNewCartEnabled = true;
  let cartStoreNumberSettingParsed: CartStoreNumberSetting = {
    AllStoreEnabled: false,
    EnabledStoreList: '',
  };
  const callCenterMode = useSelector(({ callCenterMode: { data } }) => data);

  if (cartRecipientId < 0 && availableRecipients.length) {
    cartRecipientId = availableRecipients[0].id;
  }
  if (storeSettings && storeSettings.length) {
    cartStoreNumberSettingParsed = JSON.parse(
      storeSettings,
    ) as CartStoreNumberSetting;

    const newCartEnabledStoreIdArray =
      cartStoreNumberSettingParsed.EnabledStoreList?.split(',') || [];

    if (
      isNewCartEnabled &&
      newCartEnabledStoreIdArray &&
      cartDetail?.length > 0
    ) {
      isNewCartEnabled = !cartDetail.some(
        (item) => !newCartEnabledStoreIdArray.includes(`${item.storeID}`),
      );
    }
    if (callCenterMode && callCenterMode.id > 0) {
      isNewCartEnabled = false;
    } else {
      isNewCartEnabled =
        cartStoreNumberSettingParsed.AllStoreEnabled || isNewCartEnabled;
    }
  }

  useEffect(() => {
    if (window.NEW_CART_ENABLED && isNewCartEnabled) {
      setNewCartLoad(true);
    } else {
      setNewCartLoad(false);
    }
  }, [
    setNewCartLoad,
    cartStoreNumberSettingParsed.AllStoreEnabled,
    isNewCartEnabled,
  ]);

  return (
    <>
      {newCartLoad ? (
        <IconButton
          size="medium"
          sx={cartLink}
          color="secondary"
          title={title}
          aria-label={title}
          component={Link}
          href={
            cartRecipientId
              ? `${Links.NewFruitCart}?CRID=${cartRecipientId}`
              : Links.NewFruitCart
          }
          onClick={onClick}
        >
          <Badge
            badgeContent={totalProductCount}
            showZero
            max={999}
            color="primary"
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>
      ) : (
        <IconButton
          size="medium"
          sx={cartLink}
          color="secondary"
          title={title}
          aria-label={title}
          component={Link}
          href={Links.FruitCart}
        >
          <Badge
            badgeContent={totalProductCount}
            showZero
            max={999}
            color="primary"
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>
      )}
    </>
  );
};
