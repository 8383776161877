import { Account, EditAccount } from 'components/SignUp/account.types';
import {
  LegacySignUpStatus,
  ParsedErrorResponse,
  ParsedSignUpResponse,
} from 'api/account-legacy/sign-up.misc';
import { accountLegacyApiService } from 'api/account-legacy/signup-legacy.api.service';
import { appInsights } from 'utils/telemetry/app-insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fillLegacyAccountDefaultParams } from 'utils/fill-legacy-account-default-params';

export const SignUp = createAsyncThunk(
  'account/sign-up',
  async (accountDetails: Account, { rejectWithValue }) => {
    try {
      const { data } = await accountLegacyApiService.createAccount(
        JSON.stringify(fillLegacyAccountDefaultParams(accountDetails)),
      );
      const res = JSON.parse(data.d) as ParsedSignUpResponse;
      res.email = accountDetails.email;
      res.firstName = accountDetails.firstName;
      res.lastName = accountDetails.lastName;

      if (res._Status === LegacySignUpStatus.ERROR) {
        return rejectWithValue(res as ParsedErrorResponse);
      }
      appInsights.trackEvent({ name: 'Signed up' });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const update = createAsyncThunk(
  'account/update',
  async (accountDetails: EditAccount, { rejectWithValue }) => {
    try {
      const { data } = await accountLegacyApiService.createAccount(
        JSON.stringify(fillLegacyAccountDefaultParams(accountDetails)),
      );
      const res = JSON.parse(data.d) as ParsedSignUpResponse;
      res.email = accountDetails.email;
      res.firstName = accountDetails.firstName;
      res.lastName = accountDetails.lastName;

      if (res._Status === LegacySignUpStatus.ERROR) {
        return rejectWithValue(res as ParsedErrorResponse);
      }
      appInsights.trackEvent({ name: 'Updated up' });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
