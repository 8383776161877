import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

export const QuantityButtonSkeleton: FC = () => (
  <Skeleton
    sx={{
      height: ['50px'],
      width: ['100%'],
      marginRight: ['10px'],
    }}
  />
);
