import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import * as styles from 'components/LocalSeoPage/States/styles';
import { BlogCarousel } from 'components/LocalSeoPage/BlogCarousel/BlogCarousel';
import { Box, Typography } from '@mui/material';
import { CanonicalUrlProvider } from 'providers/CanonicalUrlProvider';
import { LoadingSpinner } from 'components/LocalSeoPage/LoadingSpinner/LoadingSpinner';
import { RedirectUrl } from 'components/LocalSeoPage/RedirectUrl/RedirectUrl';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { StoreFinderWidget } from 'components/StoreFinder/interfaces/store-finder-page.interfaces';
import { StoreWidget } from 'components/common/StoreWidget/StoreWidget';
import { loadLocale } from 'redux/locale/utils';
import { storesApiService } from 'api/stores.api.service';
import { useScrollToTopOnMount } from 'hooks/scroll/use-scroll-to-top-on-mount';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const StoreListing: FC = () => {
  useScrollToTopOnMount();
  const [storeDataLoading, setStoreDataLoading] = useState<boolean>(true);
  const [storeData, setStoreData] = useState<StoreFinderWidget[]>();
  const [storeWidgetsToShow, setStoreWidgetsToShow] = useState<number>(16);
  const locale = loadLocale();

  useEffect(() => {
    const fetchStoreData = async () => {
      const response = await storesApiService.getStoreFinderStores({
        latitude: 0,
        longitude: 0,
      });
      setStoreData(response.storeData);
      setStoreDataLoading(false);
    };
    fetchStoreData();
  }, []);

  useEffect(() => {
    if (!storeDataLoading && storeData) {
      setStoreWidgetsToShow(storeData.length > 16 ? 16 : storeData.length);
    }
  }, [storeDataLoading, storeData?.length]);

  if (storeDataLoading) {
    return <LoadingSpinner />;
  }

  const displayStoreWidgets = () => {
    const widgets = [];

    if (!storeData) return null;

    storeData.sort((a, b) => {
      if (a.stateName < b.stateName) {
        return -1;
      }
      if (a.stateName > b.stateName) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < storeData.length; i += 1) {
      widgets.push(
        <Box
          key={storeData[i].storeId}
          display={i >= storeWidgetsToShow ? 'none' : 'inline'}
        >
          <StoreWidget
            storeName={storeData[i].modifiedName}
            storeAddress={storeData[i].address}
            storeHours={storeData[i].timingsShort}
            storePhoneNumber={storeData[i].phoneNumber}
            isSmoothieStore={storeData[i].isSmoothieStore}
            pageFriendlyUrl={storeData[i].pageFriendlyUrl}
            locale={locale}
          />
        </Box>,
      );
    }
    return widgets;
  };

  if (!storeData) return null;

  return (
    <>
      <RedirectUrl />
      <CanonicalUrlProvider />
      <Typography variant="h1" sx={styles.stateTitle}>
        {i18next.t(TK.STATES_SEO_PAGE_TITLE)}
      </Typography>
      <Typography sx={styles.stateDescription}>
        {i18next.t(TK.STORE_LISTING_PAGE_DESCRIPTION)}
      </Typography>
      <Typography variant="h2" sx={styles.stateStoreTitle}>
        {i18next.t(TK.STORE_COUNT, {
          replace: {
            StoreCount: storeData.length,
          },
        })}
      </Typography>
      <Box>
        {displayStoreWidgets()}
        <Box sx={styles.showMore}>
          <Typography
            variant="button"
            display={
              storeData.length - storeWidgetsToShow > 0 ? 'inline' : 'none'
            }
            onClick={() => {
              setStoreWidgetsToShow(
                storeData.length - storeWidgetsToShow > 16
                  ? storeWidgetsToShow + 16
                  : storeWidgetsToShow +
                      (storeData.length - storeWidgetsToShow),
              );
            }}
          >
            {i18next.t(TK.SHOW_MORE_WIDGETS, {
              replace: {
                Amount:
                  storeData.length - storeWidgetsToShow > 16
                    ? 16
                    : storeData.length - storeWidgetsToShow,
              },
            })}
          </Typography>
          <Typography variant="body3">
            {i18next.t(TK.TOTAL_WIDGETS_SHOWN, {
              replace: {
                Amount: storeWidgetsToShow,
                TotalAmount: storeData.length,
              },
            })}
          </Typography>
        </Box>
      </Box>
      <RenderWhenInView>
        <BlogCarousel />
      </RenderWhenInView>
    </>
  );
};
