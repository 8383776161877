import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsOnlyXs = (): boolean =>
  useMediaQuery(useTheme().breakpoints.only('xs'));

export const HideOnXs: FC = ({ children }) => {
  return useIsOnlyXs() ? null : <>{children}</>;
};
