import {
  Mode,
  reset,
  setMode,
} from 'components/Authentication/LogIn/feature/slice';
import { batch, useDispatch } from 'react-redux';

export const useOpenLogInDialog = (): (() => void) => {
  const dispatch = useDispatch();
  return (): void => {
    dispatch(setMode(Mode.LogIn));
  };
};

export const useOpenResetPasswordDialog = (): (() => void) => {
  const dispatch = useDispatch();
  return (): void => {
    dispatch(setMode(Mode.Reset));
  };
};

export const useCloseDialog = (): (() => void) => {
  const dispatch = useDispatch();
  return (): void => {
    batch(() => {
      dispatch(setMode(Mode.None));
      dispatch(reset());
    });
  };
};
