import { dynamicEvents } from 'service/dynamic-yield';
import { fetchUserSession } from 'providers/Session/feature/user.action';
import { reset } from 'components/Authentication/LogIn/feature/slice';
import { segment } from 'service/segment/segment';
import {
  selectIsLoggedIn,
  selectUser,
  selectUserAccountInformation,
  selectUserEmail,
} from 'providers/Session/feature/user.selectors';
import {
  selectIsNoneMode,
  selectLogInIsFulfilled,
} from 'components/Authentication/LogIn/feature/selectors';
import { useCloseDialog } from 'components/Authentication/hooks/control';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useLoginSuccess = (): void => {
  const dispatch = useDispatch();
  const close = useCloseDialog();
  const success = useSelector(selectLogInIsFulfilled);
  const userEmail = useSelector(selectUserEmail);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isNone = useSelector(selectIsNoneMode);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    if (success) {
      if (userEmail.length > 0) {
        segment.userLoginSuccess(
          userEmail,
          userSession,
          userAccountInformation,
        );
        dynamicEvents.loginSuccess(userEmail);
      }
      dispatch(fetchUserSession());
    }
  }, [success, dispatch, userEmail]);

  useEffect(() => {
    if (isLoggedIn && !isNone) {
      close();
      dispatch(reset());
    }
  }, [isLoggedIn, isNone, close, dispatch]);
};
