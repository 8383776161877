import { RootState } from 'redux/store';
import { SessionLock } from 'enums/session-lock';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isError, isPending } from 'utils/status.comparer';
import { isPositive } from 'utils/numbers';
import { selectExistsRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import { selectSessionLockType } from 'redux/session/availability/availability.selectors';

export const getLockedOrderData = createSelector(
  ({ availabilitySession: a }: RootState) => a.data,
  ({ storeName, fulfillmentDate }) => ({
    store: storeName,
    date: fulfillmentDate,
  }),
);
export const getIsRecipientExist = createSelector(
  ({ availabilitySession: a }: RootState) => a.data.currentRecipientId,
  (id) => Boolean(id && id > 0),
);
export const checkStoreIdIsValid = createSelector(
  ({ availabilitySession: a }: RootState) => a.data.storeId,
  (id) => Boolean(id && isPositive(id)),
);
export const selectFulfillmentDate = createSelector(
  ({ availabilitySession: { data } }: RootState) => data.fulfillmentDate,
  (date) => (date ? new Date(date) : null),
);
export const selectOrderType = createSelector(
  ({ availabilitySession: { data } }: RootState) => data.serviceOption,
  (serviceOption) => serviceOption ?? null,
);
export const selectRecipientId = ({ availabilitySession: a }: RootState) =>
  a.data.currentRecipientId;

export const selectIsSetSession = ({ availabilitySession: a }: RootState) =>
  a.data.isSet;

export const getIsPending = createSelector(
  ({ availabilitySession: a }: RootState) => a.status,
  (s) => isPending(s),
);

export const getIsError = createSelector(
  ({ availabilitySession: a }: RootState) => a.status,
  (s) => isError(s),
);

export const checkIsScheduleBlocked = createSelector(
  ({ sessionStoreSchedule: { status } }: RootState) => status,
  (status) => isBlocked(status),
);
export const getSchedule = createSelector(
  ({ sessionStoreSchedule: { schedule } }: RootState) => schedule,
  (schedule) => schedule,
);

export const selectIsNewRecipientSelected = createSelector(
  selectSessionLockType,
  selectExistsRecipients,
  (lockType, existsRecipients) =>
    lockType === SessionLock.None && existsRecipients,
);
