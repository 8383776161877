import { isNotNull } from 'utils/null.utils';
import { useEffect, useState } from 'react';

export function useCloseOnTimeout({
  isOpened,
  timeout,
  close,
}: {
  isOpened: boolean;
  timeout: number;
  close: () => void;
}): void {
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  useEffect(() => {
    if (isOpened) {
      setTimeoutId(window.setTimeout(close, timeout));
      return;
    }
    if (isNotNull(timeoutId)) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);
}
