import * as TK from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/translations/constants';

export const multipleRecipientLockCommonLocale = {
  [TK.PICKUP]: 'Pickup for {{name}} on {{date}}',
  [TK.DELIVERY]: 'Delivery to {{name}} on {{date}}',
  [TK.MENU_TITLE]: 'Select Recipient',
  [TK.GUIDE]:
    'You can add a new recipient below or continue shopping for {{name}}',
  [TK.NEW_RECIPIENT_SUBTITLE]: 'Different address or date',
  [TK.SWITCH_RECIPIENT]: 'Switch recipient',
  [TK.CLOSE]: 'Close',
};
