import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { dataTestAvailabilityStepName } from 'components/PDP/Details/components/PasControl/contants/data-test-availability-step-name';

export const getPasStepDataTestValue = (
  step: AvailabilityStep,
  ending: string,
): string => {
  const stepName = dataTestAvailabilityStepName[step];
  return `pdp-pas-${stepName}-${ending}`;
};
