import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/translations/suggested-dates.locale.constants';
import * as styles from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/styles';
import { AvailableDate } from 'api/availability-on-date/types/available-date.interface';
import { Box, RadioGroup, Typography } from '@mui/material';
import { FlexFee } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/Fees/FlexFee';
import { RadioButton } from 'components/common/Buttons/RadioButton/RadioButton';
import { dateToSQLDateString, parseSqlDate } from 'utils/date';
import { format, isEqual } from 'date-fns';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, MutableRefObject } from 'react';
import i18next from 'i18next';

type Props = {
  dateSelected: Date | null;
  availableDates: AvailableDate[];
  setNewDate: (date: Date) => void;
  isFulfillmentAvailable: boolean;
  isDeliverySelected: boolean;
  isOnlyShipmentAvailable: boolean;
  collectionPromptRef?: MutableRefObject<HTMLInputElement | null>;
};
export const FlexFeeDates: FC<Props> = ({
  dateSelected,
  availableDates,
  setNewDate,
  isFulfillmentAvailable,
  isDeliverySelected,
  isOnlyShipmentAvailable,
  collectionPromptRef,
}) => {
  const todayDate = new Date().getDate();
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  return (
    <>
      <Box ref={collectionPromptRef}>
        {availableDates.length > 0 && isFulfillmentAvailable ? (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              {i18next.t(TK.FLEX_DATES_AVAILABLE)}
            </Typography>
            <Typography variant="body2">
              {i18next.t(TK.FLEX_DATES_AVAILABLE_SUBTITLE)}
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ mt: 3, mb: 2 }} variant="subtitle1">
            {i18next.t(TK.CHOOSE_ANOTHER_DATE)}
          </Typography>
        )}
        <RadioGroup
          value={dateSelected && dateToSQLDateString(dateSelected)}
          onChange={(_, value) => {
            setNewDate(parseSqlDate(value));
            segment.deliveryTypeSelected(
              'Same Day Delivery',
              dateSelected,
              userSession,
              userAccountInformation,
            );
          }}
        >
          {availableDates.map(({ date: suggestedDate, available, fee }) => {
            return (
              <RadioButton
                key={suggestedDate}
                labelPosition="start"
                disabled={!available}
                sx={styles.suggestedDate}
                value={suggestedDate}
              >
                <Box display="flex" justifyContent="space-between">
                  {format(parseSqlDate(suggestedDate), 'eeee, d LLL')}
                  {available && (
                    <FlexFee
                      isOnlyShipmentAvailable={isOnlyShipmentAvailable}
                      fee={isDeliverySelected && available ? fee || 0 : 0}
                      isDelivery={isDeliverySelected}
                      isSameDay={isEqual(
                        todayDate,
                        new Date(parseSqlDate(suggestedDate)).getDate(),
                      )}
                    />
                  )}
                </Box>
              </RadioButton>
            );
          })}
        </RadioGroup>
        <Box>
          <Typography sx={styles.disclaimerMessage}>
            {i18next.t(TK.DISCLAIMER_MESSAGE)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
