import { RootState } from 'redux/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useUpdatedCartRedirect = (cartRecipientId: number): void => {
  const cartUpdated = useSelector(({ cart }: RootState) => cart.cartUpdated);
  useEffect(() => {
    if (cartUpdated) {
      let url = window.NEW_CART_ENABLED
        ? `${window.location.origin}/fruit-cart`
        : `${window.location.origin}/fruit-cart.aspx`;
      if (cartRecipientId) {
        url += `?CRID=${cartRecipientId}`;
      }
      window.location.href = url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartUpdated]);
};
