const StoreSkipped = 'StoreSkipped';

type StoreSkippedType = {
  storeId: number;
  isSkipped: boolean;
  rankedStoreId: number;
};

type PlpCMSClickEvent = {
  zone: string;
  module_type: string;
  module_name?: string;
  position: string;
  link?: string;
};

export const AddSessionStoreRanking = (
  storeId: number,
  rankedStoreId: number,
  isSkipped?: boolean,
): void => {
  const storeSkippedArray = JSON.parse(
    sessionStorage.getItem(StoreSkipped) || '[]',
  ) as StoreSkippedType[];
  const index = storeSkippedArray.findIndex((i) => i.storeId === storeId);
  const isStoreSkipped = isSkipped || localStorage.getItem('store');
  const storeData: StoreSkippedType = {
    storeId,
    isSkipped: !!isStoreSkipped || index !== -1,
    rankedStoreId,
  };
  if (index === -1) {
    storeSkippedArray.push(storeData);
    sessionStorage.setItem(StoreSkipped, JSON.stringify(storeSkippedArray));
  }
};

export const getRankingFromSession = (storeId?: number): StoreSkippedType => {
  const storeSkippedArray = JSON.parse(
    sessionStorage.getItem(StoreSkipped) || '[]',
  ) as StoreSkippedType[];
  const result = storeSkippedArray.find(
    (i) => i.storeId === storeId && i.rankedStoreId > 0,
  ) || {
    storeId: -1,
    isSkipped: false,
    rankedStoreId: -1,
  };
  return result;
};

export const addCMSClickEventToSession = (
  clickEvent: PlpCMSClickEvent,
): void => {
  sessionStorage.setItem('PLPClickObject', JSON.stringify(clickEvent));
};
