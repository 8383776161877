import { Breakpoints } from '@mui/system';
import { SrcSet } from 'components/common/Image/Image';
import { filterFalsy } from 'utils/array/filter-falsy';

type SrcSetProps = {
  srcSet?: string;
  sizes?: string;
};

export const getSrcSet = (
  srcSet: SrcSet | undefined,
  breakpoints: Breakpoints,
): SrcSetProps => {
  if (!srcSet) {
    return {};
  }

  const { xs, sm, md } = srcSet;

  return {
    srcSet: filterFalsy([
      xs ? `${xs.url} ${xs.width}w` : '',
      sm ? `${sm.url} ${sm.width}w` : '',
      md ? `${md.url} ${md.width}w` : '',
    ]).join(', '),
    sizes: filterFalsy([
      md ? `(min-width: ${breakpoints.values.md}px) ${md.width}px` : '',
      sm ? `(min-width: ${breakpoints.values.sm}px) ${sm.width}px` : '',
      xs ? `(min-width: ${breakpoints.values.xs}px) ${xs.width}px` : '',
    ]).join(', '),
  };
};
