import * as styles from 'components/Header/MobileMenus/styles';
import { Divider, ListItem, Typography } from '@mui/material';
import { TFunctionResult } from 'i18next';
import React, { FC } from 'react';

interface Props {
  children: TFunctionResult | React.ReactChild;
}

export const MainMenuSubtitle: FC<Props> = ({ children }) => (
  <>
    <Divider sx={styles.divider} />
    <ListItem sx={styles.subTitle}>
      <Typography variant="h6" color="secondary">
        {children}
      </Typography>
    </ListItem>
  </>
);
