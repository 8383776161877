import { ApiBaseService } from 'api/apibase.service';
import { AxiosResponse } from 'axios';

export type ML = {
  footerDetail: string;
  footerDetailMobile: string;
  pageTitle: string;
  description: string;
  giftGuideContentDescription: string;
  metaTagDescription: string;
  metaTagKeyword: string;
  giftGuideContentHeader: string;
  giftGuideContentTagLine: string;
  name: string;
};

export type Category = {
  id: number;
  image: string;
  alt: string;
  url: string;
  newPLP: boolean;
};

export type ArrangementGroup = {
  id: number;
  inactive: boolean;
  pageFriendlyURL: string;
  showOnOccasionDropdown: boolean;
  mls: ML[];
  giftGuideBannerImage: string;
  giftGuideMobileBannerImage: string;
  /** TODO: remove "?" after release 38 */
  marketplaceCategories?: Category[];
};

class ArrangementGroupsApiService extends ApiBaseService {
  findOne(id: number): Promise<AxiosResponse<ArrangementGroup>> {
    return this.apibase.get(`/arrangement-groups/${id}`);
  }
}

const arrangementGroupsApiService = new ArrangementGroupsApiService();

export { arrangementGroupsApiService };
