import {
  CITY_META_DESCRIPTION_TAG,
  CITY_META_TITLE_TAG,
  CITY_PAGE_DESCRIPTION,
  CITY_PAGE_TITLE,
  CITY_STORE_COUNT,
  HOME,
  SHOW_MORE_WIDGETS,
  STATES,
  STATES_META_DESCRIPTION_TAG,
  STATES_SEO_META_TITLE,
  STATES_SEO_PAGE_DESCRIPTION,
  STATES_SEO_PAGE_TITLE,
  STATE_META_TITLE_TAG,
  STATE_SEO_STATE_PAGE_DESCRIPTION,
  STATE_SEO_STATE_PAGE_TITLE,
  STATE_STORE_COUNT,
  STORES_IN_CITIES_OF_STATE,
  STORE_COUNT,
  STORE_LIST,
  STORE_LISTING_PAGE_DESCRIPTION,
  TOTAL_WIDGETS_SHOWN,
} from 'components/LocalSeoPage/translations/locale.constants';

export const localSeoPageCommonLocale = {
  [STATES_SEO_META_TITLE]: 'Edible Arrangements Store Location Directory',
  [STATES_SEO_PAGE_TITLE]: 'Edible Arrangements Store Directory',
  [STATES_SEO_PAGE_DESCRIPTION]: `Searching for your nearest Edible Arrangements? With over 1,000 locations nationwide, there is sure to be one near your neighborhood. Offering delivery or convenient pick-up, find an Edible store near you here using our store directory below!`,
  [STATE_SEO_STATE_PAGE_TITLE]: 'Edible Arrangements in {{State}}',
  [STORE_LISTING_PAGE_DESCRIPTION]:
    'Looking for an Edible Arrangements near you? With over 1,000 locations nationwide, there is sure to be an Edible store close to your neighborhood. From birthday and anniversary gifts to unique business gifts or just because presents, our {{State}} Edible Arrangements’ have the perfect gift for every occasion, or to treat yourself, including fruit arrangements, chocolate covered strawberries, decadent platters to please the whole family and more. See all Edible Arrangement locations in {{State}} below!',
  [STATE_SEO_STATE_PAGE_DESCRIPTION]:
    'Looking for an Edible Arrangements near you in {{State}}? With over 1,000 locations nationwide, there is sure to be an Edible store close to your neighborhood. From birthday and anniversary gifts to unique business gifts or just because presents, our {{State}} Edible Arrangements’ have the perfect gift for every occasion, or to treat yourself, including fruit arrangements, chocolate covered strawberries, decadent platters to please the whole family and more. See all Edible Arrangement locations in {{State}} below!',
  [CITY_PAGE_TITLE]: 'Edible Arrangements in {{City}}, {{State}}',
  [CITY_PAGE_DESCRIPTION]:
    'Looking for an Edible Arrangements near you in {{City}}, {{State}}? With over 1,000 locations nationwide, there is sure to be an Edible store close to your neighborhood. From birthday and anniversary gifts to unique business gifts or just because presents, our {{City}}, {{State}} Edible Arrangements’ have the perfect gift for every occasion, or to treat yourself, including fruit arrangements, chocolate covered strawberries, decadent platters to please the whole family and more. See all Edible Arrangement locations in {{City}}, {{State}} below!',
  [STORES_IN_CITIES_OF_STATE]: 'Find Edible Stores in {{State}}',
  [STATE_STORE_COUNT]: '{{StoreCount}} Edible Store(s) in {{State}}',
  [STORE_COUNT]: '{{StoreCount}} Edible Store(s)',
  [CITY_STORE_COUNT]: '{{StoreCount}} Edible Store(s) in {{City}}, {{State}}',
  [HOME]: 'Home',
  [STATES]: 'States',
  [STATE_META_TITLE_TAG]: 'Edible Arrangements {{State}} Store Locations',
  [STATES_META_DESCRIPTION_TAG]:
    'Searching for your nearest Edible Arrangements? With over 1,000 locations nationwide, there is sure to be one nearby. Find a store near you using our directory here!',
  [CITY_META_TITLE_TAG]:
    'Edible Arrangements {{City}}, {{State}} Store Locations',
  [CITY_META_DESCRIPTION_TAG]:
    '{{City}}, {{State}} Edible Arrangements offer fruit bouquets, chocolate covered strawberries, gifts for all occasions and more. Find a {{City}} store near you here!',
  [SHOW_MORE_WIDGETS]: 'Show {{Amount}} More',
  [TOTAL_WIDGETS_SHOWN]: 'Showing {{Amount}} of {{TotalAmount}}',
  [STORE_LIST]: 'Store List',
};
