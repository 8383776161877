import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { CreateParams } from 'api/event-info-legacy/types';
import { DResponse } from 'types/legacy/response.types';

class EventInfoLegacyApiService extends ApiLegacyBaseService {
  createConciergeInfo({
    email,
    message,
    name,
  }: CreateParams): AxiosPromise<DResponse> {
    return this.apibase.post<DResponse>(
      '/business-gifting/default.aspx/AddBusinessConceirgeInfo',
      {
        ContactName: name,
        Email: email,
        ShortMessage: message,
      },
    );
  }
}

export const eventInfoLegacyApiService = new EventInfoLegacyApiService();
