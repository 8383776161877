import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';
import { AddressValidationDecision } from 'service/adobe-analytics/enums';
import { ArrowDownward } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dialog } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styled';
import { OriginalAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/OriginalAddress';
import { Props } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/props';
import { SuggestedAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/SuggestedAddress';
import {
  actions,
  confirmSuggestedAddressBtn,
  icon,
  invalidDialogWrapper,
  modifyOriginalAddressBtn,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styles';
import { analytics } from 'service/adobe-analytics/analytics';
import { defaultProps } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/default.prop';
import React, { FC } from 'react';
import i18next from 'i18next';

export const InvalidDialogPart: FC<Props> = ({
  open = false,
  onConfirm,
  onReject,
}) => {
  const handleModifyAddress = () => {
    onReject();
    analytics.addressValidationSelection(
      AddressValidationDecision.ModifyOriginalAddress,
    );
  };

  const handleConfirmAddress = () => {
    if (onConfirm) onConfirm();
    analytics.addressValidationSelection(
      AddressValidationDecision.ConfirmSuggestedAddress,
    );
  };

  return (
    <Dialog sx={invalidDialogWrapper} onClose={handleModifyAddress} open={open}>
      <DialogTitle>{i18next.t(TK.SA_INVALID_TITLE)}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={3}>
          {i18next.t(TK.SA_INVALID_CONTENT)}
        </Typography>
        <Box
          display="flex"
          flexDirection={['column', 'column', 'row']}
          alignItems="center"
          justifyContent="center"
        >
          <OriginalAddress />
          <ArrowDownward sx={icon} />
          <SuggestedAddress />
        </Box>
      </DialogContent>
      <DialogActions sx={actions}>
        <Button
          onClick={handleModifyAddress}
          sx={modifyOriginalAddressBtn}
          variant="outlined"
          size="large"
          color="primary"
          data-test="pdp-recipient-suggested-address-modify-address-button"
        >
          {i18next.t(TK.SA_INVALID_REJECT)}
        </Button>
        <Button
          onClick={handleConfirmAddress}
          sx={confirmSuggestedAddressBtn}
          variant="contained"
          size="large"
          color="primary"
          data-test="pdp-recipient-suggested-address-confirm-address-button"
        >
          {i18next.t(TK.SA_INVALID_CONFIRM)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
InvalidDialogPart.defaultProps = defaultProps;
