import {
  mpGoodyBrandValuesAAPIFounded,
  mpGoodyBrandValuesBIPOCFounded,
  mpGoodyBrandValuesBlackFounded,
  mpGoodyBrandValuesFemaleFounded,
  mpGoodyBrandValuesGlutenFree,
  mpGoodyBrandValuesHispanicFounded,
  mpGoodyBrandValuesKosherCertified,
  mpGoodyBrandValuesLGBTQFounded,
  mpGoodyBrandValuesSocialImpactDriven,
  mpGoodyBrandValuesSustainable,
  mpGoodyBrandValuesUSAMade,
  mpGoodyBrandValuesVegan,
} from 'utils/cloudinaryAssets';

const brandValueImages = [
  {
    value: 'Gluten Free',
    img: mpGoodyBrandValuesGlutenFree,
  },
  {
    value: 'Sustainable',
    img: mpGoodyBrandValuesSustainable,
  },
  {
    value: 'Black Founded',
    img: mpGoodyBrandValuesBlackFounded,
  },
  {
    value: 'Vegan',
    img: mpGoodyBrandValuesVegan,
  },
  {
    value: 'Female Founded',
    img: mpGoodyBrandValuesFemaleFounded,
  },
  {
    value: 'USA Made',
    img: mpGoodyBrandValuesUSAMade,
  },
  {
    value: 'Kosher Certified',
    img: mpGoodyBrandValuesKosherCertified,
  },
  {
    value: 'AAPI Founded',
    img: mpGoodyBrandValuesAAPIFounded,
  },
  {
    value: 'LGBTQ+ Founded',
    img: mpGoodyBrandValuesLGBTQFounded,
  },
  {
    value: 'Social Impact Driven',
    img: mpGoodyBrandValuesSocialImpactDriven,
  },
  {
    value: 'Hispanic Founded',
    img: mpGoodyBrandValuesHispanicFounded,
  },
  {
    value: 'BIPOC Founded',
    img: mpGoodyBrandValuesBIPOCFounded,
  },
];

export { brandValueImages };
