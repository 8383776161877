import { ComponentType } from 'components/Home/types';
import { PlpWidgetState } from 'components/PLP/feature/slice';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const getContent = (state: RootState): PlpWidgetState =>
  state.plpWidgetContent;

export const getStatus = (state: RootState): Status => getContent(state).status;

export const plpGetComponents = (state: RootState): ComponentType[] =>
  getContent(state).data.content;

export const plpGetComponent =
  (type: string, id: string) =>
  (state: RootState): ComponentType =>
    plpGetComponents(state).find(
      (component) => component.type === type && component.id === id,
    )!;
