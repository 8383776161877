import { Platform } from 'service/adobe-analytics/enums';
import { analytics } from 'service/adobe-analytics/analytics';
import { appInsights } from 'utils/telemetry/app-insights';
import {
  checkIsBlocked,
  selectArrangement,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { dataLayer } from 'hooks/google/data-layer';
import { epsilon } from 'service/epsilon/epsilon';
import { checkIsBlocked as goBackBlocked } from 'components/PDP/GoBack/feature/selectors';
import { isNotEmpty } from 'utils/array/size';
import { isNull } from 'utils/null.utils';
import { isPositive } from 'utils/numbers';
import {
  selectUser,
  selectUserEmail,
} from 'providers/Session/feature/user.selectors';
import { useBreadcrumbProps } from 'components/BreadcrumbBack/hooks/use-breadcrumb-props';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRelatedCategoryName } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/use-related-category-name';
import { useSelector } from 'react-redux';

export const useTrackArrangement = (): void => {
  const category = useRelatedCategoryName();
  const { pathname } = useLocation();
  const isBlocked = useSelector(goBackBlocked);
  const { title } = useBreadcrumbProps(isBlocked);
  const userSession = useSelector(selectUser);

  const arrangement = useSelector(selectArrangement);
  const userEmail = useSelector(selectUserEmail);
  const arrangementPending = useSelector(checkIsBlocked);

  useEffect(() => {
    if (isPositive(arrangement.id) && isNotEmpty(arrangement.products)) {
      const ids = arrangement.products.map(
        (product) =>
          `${arrangement.catalogNumber}-${product.name.replace(/\s/g, '-')}`,
      );
      const price = arrangement.products[0].price.toFixed(2).toString();
      const productId = ids[0];

      /* Initial GTM call that provide productId to other GTM scripts */
      dataLayer.push({
        productId,
        pageType: 'productDetail',
        totalValue: arrangement.products[0].price.toFixed(2),
      });
      /* Criteo call */
      dataLayer.push({
        event: 'productDetailPage',
        productDetailPage: {
          userEmail,
          products: ids,
          productId,
          arrangementId: arrangement.catalogNumber,
          pageType: 'productDetail',
          totalValue: arrangement.products[0].price.toFixed(2),
          path: pathname,
          productSKU: arrangement.id.toString(),
          category: title || '',
          name: arrangement.name,
          salePrice: arrangement.products[0].regularPrice.toFixed(2).toString(),
          price,
        },
      });
      /* GA4 */
      gtag('event', 'product_view', {
        currency: 'USD',
        id: productId,
        name: arrangement.name,
        productSKU: arrangement.id.toString(),
        price,
        quantity: 1,
      });
      gtag('event', 'view_item', {
        currency: 'USD',
        value: '',
        items: [
          {
            item_id: productId,
            item_name: arrangement.name,
            index: 0,
            item_category: title || '',
            price,
            quantity: 1,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrangement, userEmail]);

  useEffect(
    () => () => {
      dataLayer.push({
        productId: 0,
        pageType: window.location.pathname,
        totalValue: 0,
      });
    },
    [],
  );

  useEffect(() => {
    if (
      !arrangement ||
      !(arrangement.catalogNumber && arrangement.id) ||
      arrangementPending ||
      isNull(category)
    ) {
      return;
    }
    appInsights.trackEvent({
      name: 'PDP visited',
      properties: {
        catalogNumber: arrangement.catalogNumber,
        pas: 'inline',
      },
    });

    analytics.productsDetailPage(arrangement, category, Platform.ReactNew);
    /* Ignore arrangement loading */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [arrangement, category]);

  useEffect(() => {
    if (userSession.userId > 0 || arrangement.id > 0) {
      epsilon.pageView(
        3,
        userSession,
        '',
        arrangement.name,
        arrangement.catalogNumber,
      );
    }
  }, [userSession, arrangement]);
};
