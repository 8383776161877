import * as styles from 'lib/bazaarvoice/components/StarRating/styles';
import { Box } from '@mui/material';
import { ROOT_CONTAINER_ID } from 'utils/root';
import { isNotUndefined } from 'utils/is-not-undefined';
import { scroller } from 'react-scroll';
import { useBazaarvoice } from 'lib/bazaarvoice/hooks/use-bazaarvoice';
import React, { memo } from 'react';

interface Props {
  productId: number;
  onGoToReview?: () => void;
  hideButton?: boolean;
}

/**
 * @see {@link https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Display/display_integration.html#rating-summary}
 */
export const StarRating = memo<Props>(
  ({ productId, onGoToReview, hideButton = false }) => {
    useBazaarvoice({
      callback: (bv) => {
        if (isNotUndefined(onGoToReview)) {
          bv.swat_reviews.on('show', () => {
            scroller.scrollTo('reviews', {
              containerId: ROOT_CONTAINER_ID,
              smooth: 'true',
            });
            onGoToReview();
          });
        }
      },
    });

    return (
      <Box
        sx={styles.rating({ hideButton })}
        data-bv-show="rating_summary"
        data-bv-product-id={productId}
      />
    );
  },
);
