import { ArrangementPreview } from 'api/arrangements/types';
import Cookies from 'js-cookie';

const date = new Date();
date.setTime(date.getTime() + 5 * 60 * 1000);
const PLP_CLICK_COOKIE_KEY = 'PLPClickObject';
export const HOME_PAGE_CLICK_COOKIE_KEY = 'HomePageClickObject';
const PLP_INTERRUPTER_COOKIE_KEY = 'PLPInterrupterData';
const GIFT_FINDER_CLICK_COOKIE_KEY = 'GiftFinderClickObject';

type PlpWidgetClickEvent = {
  zone: string;
  module_type: string;
  module_name?: string;
  position: string;
  link?: string;
};

export const addWidgetClickEventToCookies = (
  clickEvent: PlpWidgetClickEvent,
): void => {
  Cookies.set(PLP_CLICK_COOKIE_KEY, JSON.stringify(clickEvent), {
    expires: date,
  });
};

export const addHomePageWidgetClickEventToCookies = (
  clickEvent: PlpWidgetClickEvent,
): void => {
  Cookies.set(HOME_PAGE_CLICK_COOKIE_KEY, JSON.stringify(clickEvent), {
    expires: date,
  });
};

export const addPlpInterrupterDataToCookies = (
  PLPInterrupterData: ArrangementPreview,
): void => {
  Cookies.set(PLP_INTERRUPTER_COOKIE_KEY, JSON.stringify(PLPInterrupterData), {
    expires: date,
  });
};

export const addGiftFinderWidgetClickEventToCookies = (
  clickEvent: PlpWidgetClickEvent,
): void => {
  Cookies.set(GIFT_FINDER_CLICK_COOKIE_KEY, JSON.stringify(clickEvent), {
    expires: date,
  });
};
