/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import {
  NullableState,
  StateWithoutData,
  Status,
} from 'redux/types/state.interface';
import { ResponseError } from 'types/error.types';

type ExtraReducers<Data> = (builder: ActionReducerMapBuilder<Data>) => void;
type States<Data, Error = ResponseError> =
  | NullableState<Data, Error>
  | StateWithoutData<Error>;

export function createExtraReducers<
  Data,
  ThunkArg,
  ThunkApiConfig,
  Error = ResponseError,
>(
  initialState: States<Data, Error>,
  action: AsyncThunk<Data, ThunkArg, ThunkApiConfig>,
): ExtraReducers<States<Data, Error>> {
  return (builder) => {
    builder
      .addCase(action.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(action.fulfilled, (state, { payload }) => ({
        ...state,
        status: Status.FULFILLED,
        data: payload,
      }))
      .addCase(action.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as Draft<Error>;
      })
      .addDefaultCase((state) => state);
  };
}
