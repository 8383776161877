import * as styles from 'components/GiftFinder/Form/styles';
import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { FC } from 'react';

interface Props {
  label: string;
  subHeading: string;
  imageSrc: string;
  isSelected?: boolean;
  children: React.ReactNode;
}

export const BaseExpandedSelector: FC<Props> = ({
  label,
  subHeading,
  imageSrc,
  isSelected = false,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={styles.baseExpandedContainer(isSelected)}>
      <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
        <Avatar alt={label} src={imageSrc} sx={styles.baseExpandedMainIcon} />
        <Box>
          <Typography color="black" variant="h6" component="div">
            {label}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {subHeading}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.mainContainer(isMobile)}>{children}</Box>
      {isSelected && <CheckIcon sx={styles.checkIcon(isMobile)} />}
    </Box>
  );
};
