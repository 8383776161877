import * as styles from 'components/Home/ChipWidget/components/Skeleton/styles';
import * as widgetStyles from 'components/Home/ChipWidget/styles';
import { Box, Grid, Skeleton } from '@mui/material';
import { range } from 'utils/range';
import React, { FC } from 'react';

export const ChipWidgetSkeleton: FC = () => (
  <Box sx={widgetStyles.wrapper}>
    <Skeleton sx={styles.title} />
    <Grid
      container
      sx={widgetStyles.grid}
      rowSpacing={3}
      columnSpacing={{ xs: 2, md: 4 }}
    >
      {range(8).map((rangeItem) => (
        <Grid key={rangeItem} item>
          <Skeleton sx={styles.item} />
        </Grid>
      ))}
    </Grid>
  </Box>
);
