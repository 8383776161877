import { AddressInfo } from 'api/cart/types/cart-recipient.type';
import { Area, Country, State } from 'components/Payment/feature/types';
import { CallCenterSession } from 'api/session/types/call-center-session.interface';
import { City } from 'api/area/types/city.interface';
import { OrderType } from 'types/cart.types';
import { ProcessPaymentResponse } from 'api/payment/types/process-payment-response.type';
import { ResponseError } from 'types/error.types';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';

export const selectIsPaymentProcessing = createSelector(
  ({ paymentResponse: { status } }: RootState) => status,
  (status) => [Status.PENDING].includes(status),
);

export const selectIsPaymentIntialState = createSelector(
  ({ paymentResponse: { status } }: RootState) => status,
  (status) => [Status.INIT].includes(status),
);

export const selectIsPaymentProcessed = createSelector(
  ({ paymentResponse: { status } }: RootState) => status,
  (status) => [Status.FULFILLED].includes(status),
);

export const selectIsPaymentProcessingError = createSelector(
  ({ paymentResponse: { status } }: RootState) => status,
  (status) => [Status.ERROR].includes(status),
);

export const getCallCenterSession = ({
  callCenterMode,
}: RootState): CallCenterSession => {
  return callCenterMode.data;
};

export const getRecipientInfo = ({
  recipientCart: { recipientCart },
}: RootState): AddressInfo[] => {
  const recipientInfo = [];
  if (recipientCart?.recipient) {
    for (let i = 0; i < recipientCart.recipient.length; i += 1) {
      if (recipientCart.recipient[i].orderType !== OrderType.Pickup) {
        recipientInfo.push({
          name: recipientCart.recipient[i].recipientName,
          address1: recipientCart.recipient[i].recipientAddress1,
          address2: recipientCart.recipient[i].recipientAddress2,
          city: recipientCart.recipient[i].recipientCity,
          state: recipientCart.recipient[i].recipientState,
          zip: recipientCart.recipient[i].recipientArea,
          number:
            recipientCart.recipient[i].recipientCellPhone ||
            recipientCart.recipient[i].recipientHomePhone ||
            recipientCart.recipient[i].recipientWorkPhone,
          fulfillmentDate: new Date(recipientCart.recipient[i].fulfillmentDate),
        });
      }
    }
  }
  return recipientInfo;
};

export const getPaymentResponse = ({
  paymentResponse: { paymentResponse },
}: RootState): ProcessPaymentResponse => {
  return paymentResponse;
};

export const getPaymentErrorResponse = ({
  paymentResponse: { error },
}: RootState): ResponseError | null => {
  return error;
};

export const selectCountries = ({
  paymentResponse: { countries },
}: RootState): Country[] => {
  return countries;
};

export const selectStates = ({
  paymentResponse: { states },
}: RootState): State[] => {
  return states;
};

export const selectCities = ({ area: { data } }: RootState): City[] =>
  data.cities;

export const selectArea = ({ paymentResponse: { area } }: RootState): Area => {
  return area;
};

export const selectAreaStatus = ({
  paymentResponse: { areaStatus },
}: RootState): Status => {
  return areaStatus;
};

export const selectIsAreaPending = createSelector(
  ({ paymentResponse: { areaStatus } }: RootState) => areaStatus,
  (status) => [Status.PENDING].includes(status),
);

export const selectCurrentRecipientId = ({ availabilitySession }: RootState) =>
  availabilitySession.data.currentRecipientId;

export const selectEmailExist = ({
  paymentResponse: { isEmailExist },
}: RootState): boolean => isEmailExist;
