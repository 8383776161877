import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/PDP/Details/components/Marketplace/components/BrandValues/brandvalues.styles';
import { BrandValueImg } from 'components/PDP/Details/components/Marketplace/components/BrandValues/BrandValueImg';
import { Chip, Stack } from '@mui/material';
import { brandValueImages } from 'components/PDP/Details/components/Marketplace/components/BrandValues/utils';
import { selectBrandValues } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useMemo } from 'react';
import i18next from 'i18next';

interface BrandValueReturnType {
  data: {
    value: string;
    img: string;
  }[];
}

export const BrandValues: FC = () => {
  const brandValuesFromMP = useSelector(selectBrandValues);

  const memoizedBrandValues: BrandValueReturnType['data'] = useMemo(() => {
    if (brandValuesFromMP) {
      const uniqueBrandValue = brandValuesFromMP.split(',');
      const getImage = brandValueImages.filter((img) =>
        uniqueBrandValue.includes(img.value),
      );

      return getImage;
    }

    return [];
  }, [brandValuesFromMP]);

  return (
    <Stack mt={3} sx={styles.container}>
      {memoizedBrandValues &&
        memoizedBrandValues.map((brandValue) => {
          return (
            <Chip
              icon={<BrandValueImg url={brandValue.img} />}
              label={i18next.t(TK.BRAND_VALUE, {
                brand_value: brandValue.value,
              })}
              sx={styles.cardContainer}
              key={brandValue.value}
            />
          );
        })}
    </Stack>
  );
};
