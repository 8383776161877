import { AuthStatus, Event } from 'service/adobe-analytics/enums';
import { analytics } from 'service/adobe-analytics/analytics';
import { appInsights } from 'utils/telemetry/app-insights';
import {
  selectIsFinished,
  selectIsLoggedIn,
  selectUserId,
} from 'providers/Session/feature/user.selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useTrackAuthUser = (): void => {
  const isFinished = useSelector(selectIsFinished);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const id = useSelector(selectUserId);

  const [userId, setUserId] = useState(id || '');

  useEffect(() => {
    if (isFinished) {
      if (isLoggedIn) {
        appInsights.setAuthenticatedUserContext(id.toString(), undefined, true);
      } else {
        appInsights.clearAuthenticatedUserContext();
      }
      if (id !== userId) {
        setUserId(id);
        analytics.digitalDataPushList({
          event: Event.UserData,
          user: {
            authStatus: isLoggedIn ? AuthStatus.Auth : AuthStatus.NotAuth,
            id: isLoggedIn ? id.toString() : '',
          },
        });
      }
    }
    /*  eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isFinished]);
};
