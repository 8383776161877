import { ApiBaseService } from 'api/apibase.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CategoryPageItem } from 'redux/PLP/arrangements/types/category-page.types';

type CategoryPagesParam = {
  url: string;
};
class CategoryPagesApiService extends ApiBaseService {
  getCategoryPages(
    params: CategoryPagesParam,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<CategoryPageItem[]>> {
    return this.apibase.get('/category-pages', {
      ...config,
      params,
    });
  }
}

const categoryPagesApiService = new CategoryPagesApiService();

export { categoryPagesApiService };
