import { PLPState } from 'redux/PLP/plp.types';
import { Reducer, createSlice } from '@reduxjs/toolkit';
import {
  categoryPagesExtraReducers,
  categoryPagesInitialState,
} from 'redux/PLP/arrangements/slices/category-pages.slice-part';
import {
  filtersExtraReducers,
  filtersInitialState,
} from 'redux/PLP/filter/filters.slice-part';

const initialState: PLPState = {
  ...categoryPagesInitialState,
  ...filtersInitialState,
};

const slice = createSlice({
  name: 'plp',
  extraReducers: (builder) => {
    categoryPagesExtraReducers(builder);
    filtersExtraReducers(builder);
    builder.addDefaultCase((state) => state);
  },
  initialState,
  reducers: {
    setPlpInitialState: () => initialState,
  },
});

export const plpReducers: Reducer<typeof initialState> = slice.reducer;
export const { setPlpInitialState } = slice.actions;
