import {
  Container,
  Video,
  volumeContainer,
} from 'components/Home/CarouselBanners/components/CarouselVideo/styles';
import { IconButton } from '@mui/material';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import { videoImg } from 'utils/regex';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';

interface CarouselVideoProps {
  src: string;
  fallbackSrc: string;
  onError?: () => void;
}

export const CarouselVideo: FC<CarouselVideoProps> = ({
  src,
  fallbackSrc,
  onError,
}) => {
  const [isMuted, setIsMuted] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  const toggleIsMuted = (event: MouseEvent) => {
    event.preventDefault();
    setIsMuted((prevState) => !prevState);
  };

  return (
    <Container>
      <Video
        ref={videoRef}
        muted={isMuted}
        poster={videoImg(src)}
        autoPlay
        loop
      >
        <source onError={onError} src={src} />
        <source onError={onError} src={fallbackSrc} />
        <track kind="captions" />
      </Video>
      <IconButton
        aria-label="Mute"
        onClick={toggleIsMuted}
        sx={volumeContainer}
      >
        {isMuted ? <VolumeOff /> : <VolumeUp />}
      </IconButton>
    </Container>
  );
};
