export const roundNumberValue = (numValue: number): string => {
  const roundedNumber = Math.round((numValue + Number.EPSILON) * 100) / 100;
  const decimalPart = roundedNumber % 1;
  if (decimalPart === 0) {
    return roundedNumber.toFixed(2);
  }
  if (decimalPart < 0.1) {
    return `${roundedNumber.toFixed(1)}0`;
  }
  return roundedNumber.toFixed(2);
};

export const roundOffNumberValue = (numValue: number): string => {
  const roundedNumber = Math.round((numValue + Number.EPSILON) * 100) / 100;

  // Convert the rounded number to string with 2 decimal places
  const roundedString = roundedNumber.toFixed(2);

  // If the rounded number has no decimal part, returns xx.00
  if (Number(roundedString) === Math.floor(roundedNumber)) {
    return roundedString;
  }

  // If the second decimal place is zero, returns xx.x0
  if (roundedString.slice(-1) === '0') {
    return `${roundedNumber.toFixed(1)}0`;
  }

  return roundedString;
};
