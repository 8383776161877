import {
  ALL_ARRANGEMENTS_FILTER_VALUE,
  arrangementApiService,
} from 'api/arrangements/arrangement.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchArrangements = createAsyncThunk(
  'plp/all-arrangements',
  async () => {
    const { data } = await arrangementApiService.find({
      filterIds: ALL_ARRANGEMENTS_FILTER_VALUE,
    });

    return data;
  },
);
