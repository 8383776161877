import * as styles from 'components/Header/HeaderNavigation/styles';
import { Box, Drawer } from '@mui/material';
import { Item } from 'redux/header/header.types';
import { MegaMenuAssociations } from 'enums/mega-menu';
import { MenuBanner } from 'components/Header/HeaderNavigation/MenuBanner';
import { MenuButton } from 'components/Header/HeaderNavigation/MenuButton';
import { MenuCategories } from 'components/Header/HeaderNavigation/MenuCategories';
import { MenuSkeleton } from 'components/Header/HeaderNavigation/MenuSkeleton';
import { ViewAllButton } from 'components/Header/HeaderNavigation/ViewAllButton';
import {
  getForDesktop,
  getIsFetchError,
  getIsFetched,
  getIsFetching,
} from 'redux/header/header.selectors';
import { isNotEmpty } from 'utils/is-not-empty';
import { useScrollBlockHome } from 'hooks/use-scroll-block';
import { useSelector } from 'react-redux';
import React, { FC, useRef, useState } from 'react';

const REACTION_DELAY = 300;

export const HeaderNavigation: FC = () => {
  const menu = useSelector(getForDesktop);
  const isFetching = useSelector(getIsFetching);
  const isFetchError = useSelector(getIsFetchError);
  const isFetched = useSelector(getIsFetched);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [activeMenuItem, setActiveMenuItem] = useState<Item | null>(null);
  const [menuPosition, setMenuPosition] = useState(0);
  const [blockScrollHome, allowScrollHome] = useScrollBlockHome();
  const [menuShowTimeoutId, setMenuShowTimeoutId] = useState<null | number>(
    null,
  );
  const [closeTimeoutId, setCloseTimeoutId] = useState<null | number>(null);

  const initMenuShow = (menuItem: Item) => {
    setMenuShowTimeoutId(
      window.setTimeout(() => {
        if (anchorRef.current && isFetched) {
          setMenuPosition(anchorRef.current.getBoundingClientRect().bottom);
        }
        if (isNotEmpty(menuItem.categories)) {
          blockScrollHome();
        }
        setActiveMenuItem(menuItem);
      }, REACTION_DELAY),
    );
  };

  const initClose = () => {
    setCloseTimeoutId(
      window.setTimeout(() => {
        setActiveMenuItem(null);
        allowScrollHome();
      }, REACTION_DELAY),
    );
  };

  const cancelMenuShow = () => {
    if (menuShowTimeoutId) {
      window.clearTimeout(menuShowTimeoutId);
      setCloseTimeoutId(null);
    }
  };

  const cancelClose = () => {
    if (closeTimeoutId) {
      window.clearTimeout(closeTimeoutId);
      setCloseTimeoutId(null);
    }
  };

  if (isFetchError) {
    return <span>error</span>;
  }

  if (isFetching) {
    return <MenuSkeleton />;
  }

  return (
    <Box onMouseLeave={initClose} component="nav" sx={styles.navContainer}>
      <Box sx={styles.navItems} ref={anchorRef}>
        {menu.map((menuItem, i) => {
          return (
            <MenuButton
              index={i}
              key={menuItem.id}
              item={menuItem}
              activeMenuId={activeMenuItem && activeMenuItem.id}
              onMouseEnter={() => initMenuShow(menuItem)}
              onMouseLeave={cancelMenuShow}
            />
          );
        })}
      </Box>
      {menu.map((item) => {
        const isMenuVisible = activeMenuItem && item.id === activeMenuItem.id;

        return isNotEmpty(item.categories) ? (
          <Drawer
            key={item.id}
            ModalProps={{
              keepMounted: true,
              disableScrollLock: true,
            }}
            PaperProps={{
              onMouseLeave: initClose,
              onMouseEnter: cancelClose,
            }}
            onClose={initClose}
            anchor="top"
            transitionDuration={0}
            disablePortal
            open
            sx={styles.categoriesContainer(menuPosition, isMenuVisible)}
          >
            <Box sx={styles.categoriesWrapper}>
              <MenuCategories
                categories={item.categories}
                menuId={item.id}
                initClose={initClose}
              />
              {item.megaMenuAssociation === MegaMenuAssociations.Shop && (
                <MenuBanner />
              )}
            </Box>
            {item.megaMenuAssociation === MegaMenuAssociations.Shop && (
              <ViewAllButton />
            )}
          </Drawer>
        ) : null;
      })}
    </Box>
  );
};
