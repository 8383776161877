import { Breakpoint, useTheme } from '@mui/material/styles';
import { ComponentType, ContentType } from 'components/Home/types';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useResponsiveContent = <T extends ContentType>(
  component: ComponentType,
  breakpoint: Breakpoint,
): T => {
  const theme = useTheme();
  const isUpBreakpoint = useMediaQuery(theme.breakpoints.up(breakpoint));

  if (isUpBreakpoint) {
    return component.content.desktop as T;
  }
  return component.content.mobile as T;
};
