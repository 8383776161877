export const CONTENT = 'bgdf.normal.content';
export const TITLE = 'bgdf.normal.title';
export const EMAIL_LABEL = 'bgdf.email.label';
export const NAME_LABEL = 'bgdf.name.label';
export const MESSAGE_LABEL = 'bgdf.message.label';
export const MESSAGE_PLACEHOLDER = 'bgdf.message.placeholder';
export const ERROR_TRY_AGAIN = 'bgdf.error.try_again';
export const ERROR_CONTENT = 'bgdf.error.content';
export const ERROR_TITLE = 'bgdf.error.title';
export const SUBMIT = 'bgdf.submit';
export const CANCEL = 'bgdf.cancel';
export const SUCCESS_CONTENT = 'bgdf.success.content';
export const SUCCESS_TITLE = 'bgdf.success.title';
export const EMAIL_VALID_ERROR = 'bgdf.email.error.valid';
export const EMAIL_REQUIRED_ERROR = 'bgdf.email.error.required';
