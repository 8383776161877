import { AuthenticationDialog } from 'components/Authentication/AuthenticationDialog';
import { HeaderContainer } from 'containers/HeaderContainer';
import { Outlet } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { useLoginSuccess } from 'containers/MainContainer/use-login-success';
import React, { FC, RefObject, Suspense, lazy } from 'react';

const FooterContainer = lazy(() => import('containers/FooterContainer'));

interface Props {
  bottomContainer: RefObject<HTMLDivElement>;
}

export const Error404Layout: FC<Props> = ({ bottomContainer }) => {
  useLoginSuccess();

  return (
    <>
      <HeaderContainer />
      <Outlet />
      <Suspense fallback={<Skeleton />}>
        <FooterContainer />
      </Suspense>
      <AuthenticationDialog />
      <div ref={bottomContainer} />
    </>
  );
};
