import { Accessibility } from 'components/About/Accessibility/Accessibility';
import { Box, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import {
  CUSTOMER_SERVICE_ALIAS,
  LOCAL_LEGAL_ALIAS,
  LOCAL_SEO_ALIAS,
  ROOT_PATH_LIST,
  STORE_FINDER_PATH,
} from 'containers/MainContainer/constants/path';
import { Careers } from 'components/About/Careers/Careers';
import { CartError } from 'components/Error/CartError';
import { City } from 'components/LocalSeoPage/City/City';
import { Composer } from 'components/common/Composer/Composer';
import { DefaultSuspense } from 'components/common/DefaultSuspense/DefaultSuspense';
import { DynamicPage } from 'components/DynamicPages';
import { Error404Layout } from 'layouts/Error404Layout';
import { ErrorBoundary } from 'react-error-boundary';
import { FullPageErrorBoundaries } from 'components/Error/FullPageErrorBoundaries';
import { HomeSkeleton } from 'components/Home/Skeleton';
import { LazyBusinessGifting } from 'containers/BusinessGifting/LazyBusinessGifting';
import { Links } from 'enums/common-links';
import { MainLayout } from 'layouts/MainLayout';
import { MyAccountContainer } from 'containers/MyAccountsContainer/MyAccountContainer';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OrderConfirmationError } from 'components/Error/OrderConfirmationError';
import { OrderDetailsContainer } from 'containers/OrderDetailsContainer/OrderDetailsContainer';
import { OurMission } from 'components/About/OurMission/OurMission';
import { PaymentPageError } from 'components/Error/PaymentPageError';
import { PaypalProvider } from 'components/Paypal/PaypalProvider';
import { PlpContainerSkeleton } from 'containers/PLPContainer/PLPContainerSkeleton';
import { PressRoomPageError } from 'components/Error/PressRoomPageError';
import { ProductDisplayPageError } from 'components/Error/ProductDisplayPageError/index';
import { ProductListingPageError } from 'components/Error/ProductListingPageError';
import { RewardsPageError } from 'components/Error/RewardsPageError';
import { RewardsSignUpContainer } from 'containers/RewardsContainer/RewardsSignUpContainer';
import { SignUpContainer } from 'containers/SignupContainer/SignUpContainer';
import { State } from 'components/LocalSeoPage/State/State';
import { States } from 'components/LocalSeoPage/States/States';
import { StoreFinderError } from 'components/Error/StoreFinderError';
import { StoreListing } from 'components/LocalSeoPage/StoreListing/StoreListing';
import { SubscriptionSection } from 'components/Subscription/SubscriptionSection';
import { SubscriptionSectionWrapper } from 'components/Footer/BottomNavigation/styles';
import { TermsAndConditions } from 'components/About/TermsAndConditions/TermsAndConditions';
import { TermsAndConditionsExpired } from 'components/About/TermsAndConditions/TermsAndConditionsExpired';
import { TimeLine } from 'components/About/TimeLine/TimeLine';
import { TrackOrderError } from 'components/Error/TrackOrderError';
import { WhoWeAre } from 'components/About/WhoWeAre/WhoWeAre';
import {
  getAccessibilityPaths,
  getBusinessGiftingPaths,
  getCareerPaths,
  getHomePaths,
  getOrderConfirmationPaths,
  getOurMissionPaths,
  getPdpPath,
  getPressRoomPaths,
  getStoreComingSoonPaths,
  getTermsAndConditionsExpiredPaths,
  getTermsAndConditionsPaths,
  getTrackOrderEndpoints,
  getWhoWeArePaths,
} from 'containers/MainContainer/router.helper';
import { getTheme } from 'utils/theme/utils/get-theme';
import { root } from 'utils/root';
import { trackComponent } from 'utils/telemetry/app-insights';
import { useDoubleSlashRedirector } from 'containers/MainContainer/use-double-slash-redirector';
import { useHistoryScrollRestorationManual } from 'hooks/use-history-scroll-restoration-manual';
import { useLocale } from 'hooks/use-locale';
import { useSegment } from 'containers/MainContainer/hooks/use-segment';
import { useTranslation } from 'react-i18next';
import React, { FC, Suspense, lazy, useEffect, useRef } from 'react';

const ContactUsContainer = trackComponent(
  lazy(() => import('containers/ContactUsContainer/ContactUsContainer')),
  'Contact us',
);

const Error404 = lazy(() => import('../Error404Container/Error404Container'));

const PressRoom = lazy(
  () => import('../PressRoomContainer/PressRoomContainer'),
);

const HomePage = trackComponent(
  lazy(() => import('containers/HomePageContainer')),
  'Home',
);
const LandingPage = lazy(() => import('containers/LandingPageContainer'));
const Maintenance = lazy(
  () => import('../MaintenanceContainer/MaintenanceContainer'),
);
const PDPContainer = trackComponent(
  lazy(() => import('containers/PDPContainer/PDPContainer')),
  'PDP',
);
const PLPContainer = trackComponent(
  lazy(() => import('containers/PLPContainer/PLPContainer')),
  'PLP',
);
const LocalSeoContainer = trackComponent(
  lazy(() => import('containers/LocalSeoContainer/LocalSeoContainer')),
  'Local SEO',
);
const CartContainer = lazy(() =>
  import('containers/CartContainer/CartContainer').then((module) => ({
    default: module.CartContainer,
  })),
);
const PaymentContainer = lazy(() =>
  import('containers/PaymentContainer/PaymentContainer').then((module) => ({
    default: module.PaymentContainer,
  })),
);
const LegalContainer = trackComponent(
  lazy(() => import('containers/LegalContainer/LegalContainer')),
  'Legal Page',
);
const OrderConfirmationContainer = lazy(() =>
  import(
    'containers/OrderConfirmationContainer/OrderConfirmationContainer'
  ).then((module) => ({
    default: module.OrderConfirmationContainer,
  })),
);
const CustomerServiceContainer = trackComponent(
  lazy(
    () =>
      import('containers/CustomerServiceContainer/CustomerServiceContainer'),
  ),
  'Customer Service Page',
);
const StoreFinderContainer = trackComponent(
  lazy(() =>
    import('containers/StoreFinderContainer/StoreFinderContainer').then(
      (module) => ({ default: module.StoreFinderContainer }),
    ),
  ),
  'Store Finder Page',
);
const StoreDetailsContainer = trackComponent(
  lazy(() =>
    import('containers/StoreDetailsContainer/StoreDetailsContainer').then(
      (module) => ({ default: module.StoreDetailsContainer }),
    ),
  ),
  'Store Detail Page',
);
const StoresComingSoonContainer = trackComponent(
  lazy(() =>
    import(
      'containers/StoreComingSoonContainer/StoresComingSoonContainer'
    ).then((module) => ({ default: module.StoresComingSoonContainer })),
  ),
  'Store Coming Soon Page',
);

export const MainContainer: FC = () => {
  useDoubleSlashRedirector();
  useSegment();
  const bottomContainer = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();
  const { locale, isLocaleChanged } = useLocale();
  useHistoryScrollRestorationManual();

  useEffect(() => {
    i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLocaleChanged) {
      document.location.reload();
    }
  }, [isLocaleChanged]);

  useEffect(() => {
    const checkAndSetAriaHiddenValue = (): void => {
      if (root?.getAttribute('aria-hidden') !== 'false') {
        root?.setAttribute('aria-hidden', 'false');
        setTimeout(checkAndSetAriaHiddenValue, 2000);
      }
    };

    if (
      root &&
      (root.getAttribute('aria-hidden') === 'true' ||
        root.getAttribute('aria-hidden') === null ||
        root.getAttribute('aria-hidden') === undefined)
    ) {
      checkAndSetAriaHiddenValue();
    }
  }, []);

  return (
    <ThemeProvider theme={getTheme()}>
      <ErrorBoundary FallbackComponent={FullPageErrorBoundaries}>
        <Routes>
          <Route element={<Error404Layout bottomContainer={bottomContainer} />}>
            <Route
              path="/404"
              element={
                <DefaultSuspense>
                  <Error404 />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
          </Route>

          <Route
            path="/maintenance.html"
            element={
              <DefaultSuspense>
                <Maintenance />
              </DefaultSuspense>
            }
          />
          <Route element={<MainLayout bottomContainer={bottomContainer} />}>
            <Route
              path={getPdpPath(':pageFriendlyUrl')}
              element={
                <ErrorBoundary FallbackComponent={ProductDisplayPageError}>
                  <Composer components={[ScopedCssBaseline, PaypalProvider]}>
                    <Suspense fallback={<Box minHeight="100vh" />}>
                      <PDPContainer bottomContainer={bottomContainer} />
                    </Suspense>
                  </Composer>
                </ErrorBoundary>
              }
            />
            {window.NEW_CART_ENABLED && (
              <Route
                path={Links.NewFruitCart}
                element={
                  <ErrorBoundary FallbackComponent={CartError}>
                    <Composer components={[ScopedCssBaseline, PaypalProvider]}>
                      <Suspense fallback={<Box minHeight="100vh" />}>
                        <CartContainer />
                      </Suspense>
                    </Composer>
                  </ErrorBoundary>
                }
              />
            )}
            {getOrderConfirmationPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <ErrorBoundary FallbackComponent={OrderConfirmationError}>
                    <DefaultSuspense>
                      <OrderConfirmationContainer />
                      <SubscriptionSectionWrapper>
                        <SubscriptionSection />
                      </SubscriptionSectionWrapper>
                    </DefaultSuspense>
                  </ErrorBoundary>
                }
              />
            ))}
            {getPressRoomPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <ErrorBoundary FallbackComponent={PressRoomPageError}>
                    <DefaultSuspense>
                      <PressRoom />
                    </DefaultSuspense>
                  </ErrorBoundary>
                }
              />
            ))}
            {getWhoWeArePaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <WhoWeAre />
                  </DefaultSuspense>
                }
              />
            ))}
            {getOurMissionPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <OurMission />
                  </DefaultSuspense>
                }
              />
            ))}
            {getCareerPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <Careers />
                  </DefaultSuspense>
                }
              />
            ))}
            {getAccessibilityPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <Accessibility />
                  </DefaultSuspense>
                }
              />
            ))}
            {getTermsAndConditionsPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <TermsAndConditions />
                  </DefaultSuspense>
                }
              />
            ))}
            {getTermsAndConditionsExpiredPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <DefaultSuspense>
                    <TermsAndConditionsExpired />
                  </DefaultSuspense>
                }
              />
            ))}
            <Route
              path="/about/timeline"
              element={
                <DefaultSuspense>
                  <TimeLine />
                </DefaultSuspense>
              }
            />
            <Route
              path="/payment"
              element={
                <ErrorBoundary FallbackComponent={PaymentPageError}>
                  <Composer components={[ScopedCssBaseline, PaypalProvider]}>
                    <Suspense fallback={<Box minHeight="100vh" />}>
                      <PaymentContainer />
                    </Suspense>
                  </Composer>
                </ErrorBoundary>
              }
            />
            {getHomePaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <Composer components={[ScopedCssBaseline, DefaultSuspense]}>
                    <Suspense fallback={<HomeSkeleton />}>
                      <HomePage />
                      <SubscriptionSectionWrapper>
                        <SubscriptionSection />
                      </SubscriptionSectionWrapper>
                    </Suspense>
                  </Composer>
                }
              />
            ))}
            <Route
              path="/l/:customLandingPath"
              element={
                <DefaultSuspense>
                  <LandingPage />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
            <Route
              path="/customer-service/contact-us"
              element={
                <DefaultSuspense>
                  <ContactUsContainer />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
            <Route
              path="/account/sign-up"
              element={
                <DefaultSuspense>
                  <SignUpContainer />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />

            {ROOT_PATH_LIST.map((path) => (
              <Route
                key={path}
                path={`/${path}/*`}
                element={
                  <DefaultSuspense>
                    <DynamicPage />
                  </DefaultSuspense>
                }
              />
            ))}
            <Route
              path="/order-details/"
              element={
                <ErrorBoundary FallbackComponent={RewardsPageError}>
                  <DefaultSuspense>
                    <OrderDetailsContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </DefaultSuspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/rewards/"
              element={
                <ErrorBoundary FallbackComponent={RewardsPageError}>
                  <DefaultSuspense>
                    <RewardsSignUpContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </DefaultSuspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/my-account/"
              element={
                <ErrorBoundary FallbackComponent={RewardsPageError}>
                  <DefaultSuspense>
                    <MyAccountContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </DefaultSuspense>
                </ErrorBoundary>
              }
            />
            <Route
              path={`/${CUSTOMER_SERVICE_ALIAS}/:pageName`}
              element={
                <DefaultSuspense>
                  <CustomerServiceContainer />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
            {getTrackOrderEndpoints().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <ErrorBoundary FallbackComponent={TrackOrderError}>
                    <DefaultSuspense>
                      <CustomerServiceContainer />
                      <SubscriptionSectionWrapper>
                        <SubscriptionSection />
                      </SubscriptionSectionWrapper>
                    </DefaultSuspense>
                  </ErrorBoundary>
                }
              />
            ))}
            <Route
              path={`/${LOCAL_LEGAL_ALIAS}`}
              element={
                <DefaultSuspense>
                  <LegalContainer />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
            <Route
              path={`/${LOCAL_LEGAL_ALIAS}/:legal`}
              element={
                <DefaultSuspense>
                  <LegalContainer />
                  <SubscriptionSectionWrapper>
                    <SubscriptionSection />
                  </SubscriptionSectionWrapper>
                </DefaultSuspense>
              }
            />
            <Route
              path={`/${LOCAL_SEO_ALIAS}`}
              element={
                <Suspense fallback={<Box minHeight="100vh" />}>
                  <LocalSeoContainer>
                    <States />
                  </LocalSeoContainer>
                </Suspense>
              }
            />
            <Route
              path={`/${LOCAL_SEO_ALIAS}/:state`}
              element={
                <Suspense fallback={<Box minHeight="100vh" />}>
                  <LocalSeoContainer>
                    <State />
                  </LocalSeoContainer>
                </Suspense>
              }
            />
            <Route
              path="/store-listing/"
              element={
                <Suspense fallback={<Box minHeight="100vh" />}>
                  <LocalSeoContainer isStoreListing>
                    <StoreListing />
                  </LocalSeoContainer>
                </Suspense>
              }
            />
            <Route
              path={`/${LOCAL_SEO_ALIAS}/:state/:city`}
              element={
                <>
                  {window.location.pathname.endsWith('.aspx') ? (
                    <Navigate
                      to={`/states/${window.location.pathname.split('/')[2]}`}
                      replace
                    />
                  ) : (
                    <Suspense fallback={<Box minHeight="100vh" />}>
                      <LocalSeoContainer>
                        <City />
                      </LocalSeoContainer>
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              path="/fruit-gifts"
              element={<Navigate to="/fruit-arrangements" />}
            />
            {getBusinessGiftingPaths().map((path) => (
              <Route key={path} path={path} element={<LazyBusinessGifting />} />
            ))}
            <Route
              path="/:filterUrl"
              element={
                <ErrorBoundary FallbackComponent={ProductListingPageError}>
                  <Suspense fallback={<PlpContainerSkeleton />}>
                    <PLPContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path={`${STORE_FINDER_PATH}`}
              element={
                <ErrorBoundary FallbackComponent={StoreFinderError}>
                  <DefaultSuspense>
                    <StoreFinderContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </DefaultSuspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/stores/:friendlyUrl"
              element={
                <ErrorBoundary FallbackComponent={StoreFinderError}>
                  <DefaultSuspense>
                    <StoreDetailsContainer />
                    <SubscriptionSectionWrapper>
                      <SubscriptionSection />
                    </SubscriptionSectionWrapper>
                  </DefaultSuspense>
                </ErrorBoundary>
              }
            />
            {getStoreComingSoonPaths().map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <ErrorBoundary FallbackComponent={StoreFinderError}>
                    <DefaultSuspense>
                      <StoresComingSoonContainer />
                      <SubscriptionSectionWrapper>
                        <SubscriptionSection />
                      </SubscriptionSectionWrapper>
                    </DefaultSuspense>
                  </ErrorBoundary>
                }
              />
            ))}
          </Route>
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
