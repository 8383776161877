import { CarouselBannersItem } from 'components/Home/CarouselBanners/types';
import { CarouselImage } from 'components/Home/CarouselBanners/components/CarouselImage';
import { CarouselVideo } from 'components/Home/CarouselBanners/components/CarouselVideo';
import { Link } from '@mui/material';
import { checkForFrench } from 'components/Home/utils';
import { isVideo } from 'components/Home/utils/is-video';
import {
  noImageAvailableBanner,
  noImageAvailableBannerDesktop,
} from 'utils/cloudinaryAssets';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useIsUpMd } from 'utils/hidden/hide-up-md';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';

type CarouselItemProps = Pick<
  CarouselBannersItem,
  | 'url'
  | 'src'
  | 'frSrc'
  | 'fallbackSrc'
  | 'name'
  | 'altText'
  | 'frAltText'
  | 'analyticsClickHandler'
  | 'headerText'
  | 'frHeaderText'
  | 'contentText'
  | 'frContentText'
  | 'smallText'
  | 'frSmallText'
  | 'buttonText'
  | 'frButtonText'
  | 'buttonTextColor'
  | 'textColor'
  | 'textAlignment'
  | 'buttonAlignment'
>;

export const CarouselItem: FC<CarouselItemProps> = ({
  url,
  src: initialSrc,
  frSrc,
  fallbackSrc,
  name,
  altText,
  frAltText,
  analyticsClickHandler,
  headerText,
  frHeaderText,
  contentText,
  frContentText,
  smallText,
  frSmallText,
  buttonText,
  frButtonText,
  buttonTextColor,
  textColor,
  textAlignment,
  buttonAlignment,
}) => {
  const [src, setSrc] = useState(initialSrc);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    setSrc(initialSrc);
  }, [initialSrc]);

  const isUpMd = useIsUpMd();

  const handleError = () => {
    if (src === fallbackSrc) {
      setSrc(isUpMd ? noImageAvailableBannerDesktop : noImageAvailableBanner);
    } else {
      setSrc(fallbackSrc);
    }
  };
  return (
    <Link
      onClick={() => {
        segment.bannerClicked(
          url,
          name,
          'Home Page Banner',
          userSession,
          userAccountInformation,
        );
        if (analyticsClickHandler) analyticsClickHandler();
      }}
      href={url}
      display="block"
      sx={{
        cursor: 'pointer',
        width: '100%',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      {isVideo(src) ? (
        <CarouselVideo
          src={src}
          fallbackSrc={fallbackSrc}
          onError={handleError}
          key={name}
        />
      ) : (
        <CarouselImage
          src={checkForFrench(src, frSrc)}
          key={name}
          alt={checkForFrench(altText, frAltText)}
          headerText={checkForFrench(headerText, frHeaderText)}
          contentText={checkForFrench(contentText, frContentText)}
          smallText={checkForFrench(smallText, frSmallText)}
          buttonText={checkForFrench(buttonText, frButtonText)}
          buttonTextColor={buttonTextColor}
          textColor={textColor}
          onError={handleError}
          textAlignment={textAlignment}
          buttonAlignment={buttonAlignment}
        />
      )}
    </Link>
  );
};
