import * as TK from 'components/PDP/Details/components/PasControl/components/DateSelection/components/NotAvailableAlert/translations/constants';
import { Area } from 'api/area/types/area.interface';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { BaseAlert } from 'components/common/BaseAlert/styled';
import { Button, Typography } from '@mui/material';
import { Links } from 'enums/common-links';
import { getIsPending } from 'components/Session/feature/selectors';
import { getOrderType } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/NotAvailableAlert/utils/get-order-type';
import { useSelectSuggestedProduct } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/hooks/use-select-suggested-product';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  area: Area;
  date: Date;
}

export const NotAvailableAlert: FC<Props> = ({ area, date }) => {
  const isPending = useSelector(getIsPending);
  const onClick = useSelectSuggestedProduct(
    getOrderType(area.availability),
    date,
    area,
  );

  return (
    <BaseAlert data-test="pdp-pas-date-unavailable-error">
      <Backdrop opened={isPending} />
      <Typography variant="subtitle2" mb={1}>
        {i18next.t(TK.TITLE)}
      </Typography>
      <Typography variant="body2" mb={1}>
        {i18next.t(TK.BODY)}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        href={Links.FruitArrangements}
        onClick={onClick}
        fullWidth
        data-test="pdp-pas-date-see-available-products"
      >
        {i18next.t(TK.BUTTON)}
      </Button>
    </BaseAlert>
  );
};
