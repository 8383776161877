import {
  checkIsSignUpSuccess,
  getNewUserInfo,
} from 'components/SignUp/feature/selectors';
import { dataLayer } from 'hooks/google/data-layer';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useNewUserSignUp = (): void => {
  const signUpSuccessful = useSelector(checkIsSignUpSuccess);
  const newUserInfo = useSelector(getNewUserInfo);

  useEffect(() => {
    if (signUpSuccessful && newUserInfo) {
      dataLayer.push({
        event: 'newUserSignUp',
        userInfo: {
          firstName: newUserInfo.firstName,
          lastName: newUserInfo.lastName,
          email: newUserInfo.email,
        },
      });
    }
  }, [newUserInfo, signUpSuccessful]);
};
