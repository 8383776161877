import { ApiBaseService } from 'api/apibase.service';
import { AxiosResponse } from 'axios';
import { Item } from 'redux/header/header.types';

class HeaderApiService extends ApiBaseService {
  public all(query?: { target: number }): Promise<AxiosResponse<Item[]>> {
    return this.apibase.get('/top-nav/items', { params: query });
  }
}

export const headerApiService = new HeaderApiService();
