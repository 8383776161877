import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';
import { unlockDiscountSuccessImageDesktop } from 'utils/cloudinaryAssets';

export const containerWrapper: ThemedSxProps = {
  display: 'flex',
  padding: '0px !important',
  textAlign: { md: 'center', sm: 'center', xs: 'center' },
};

export const drawerBar: ThemedSxProps = (theme) => ({
  height: '4px',
  width: '120px',
  background: theme.palette.common.black,
  opacity: '54%',
  borderRadius: '4px',
  marginTop: '8px',
});

export const unlockOffer: ThemedSxProps = {
  minHeight: { md: '210px', sm: '210px', xs: '120px' },
  marginTop: { md: '10px', sm: '10px', xs: '0px' },
  marginBottom: { md: '0px', sm: '0px', xs: '20px' },
  backgroundImage: {
    md: `url(${unlockDiscountSuccessImageDesktop})`,
    xs: `url(${unlockDiscountSuccessImageDesktop})`,
    sm: `url(${unlockDiscountSuccessImageDesktop})`,
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: { md: 'block', sm: 'block', xs: 'block' },
};

export const imageName: ThemedSxProps = (theme) => ({
  paddingTop: { md: '330px', sm: '320px', xs: '320px' },
  marginLeft: '145px',
  fontSize: '10px',
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontWeight: '500',
});

export const rightWrapper: ThemedSxProps = {
  width: '100%',
  paddingTop: { md: '20px', sm: '20px', xs: '30px' },
};

export const SuccessContentTitle: ThemedSxProps = {
  fontWeight: 400,
  fontSize: '48px',
  letterSpacing: '0.15px',
  lineHeight: { md: '134%', sm: '134%', xs: '50%' },
  marginBottom: { md: '0', sm: '0', xs: '15px' },
  fontFamily: fonts.poppins,
};

export const successContentDescription: ThemedSxProps = {
  display: 'inline-flex',
  fontWeight: '500',
  fontSize: { md: '20px', sm: '20px', xs: '12px' },
  letterSpacing: '0.15px',
};

export const socialContainer: ThemedSxProps = {
  display: 'flex',
  flexDirection: { md: 'row', sm: 'row', xs: 'column' },
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  marginTop: '-65px',
  marginBottom: '10px',
};

export const termsMobileView: ThemedSxProps = {
  display: { md: 'inline', sm: 'inline', xs: 'none' },
};

export const socialContainerText: ThemedSxProps = {
  display: { md: 'flex', sm: 'flex', xs: 'contents' },
  textAlign: 'center',
  flexDirection: { md: 'column', sm: 'column', xs: 'row' },
  marginRight: '10px',
  width: { md: '22%', sm: '22%', xs: '61%' },
};

export const socialHeadText: ThemedSxProps = {
  display: 'inline-flex',
  textAlign: 'center',
  fontWeight: '500',
  fontSize: { md: '14px', sm: '14px', xs: '12px' },
  letterSpacing: { md: '0.1px', sm: '0.1px', xs: '0.1px' },
  lineHeight: { md: '157%', sm: '157%', xs: '157%' },
  fontFamily: fonts.poppins,
};

export const SocialIcons = styled('div')({
  textAlign: 'center',
  '& a:nth-child(n)': {
    marginLeft: '16px',
  },
  '& a:nth-child(1)': {
    marginLeft: '0px',
  },
  '& svg': {
    width: { md: '36px', sm: '36px', xs: '24px' },
    height: { md: '36px', sm: '36px', xs: '24px' },
  },
});

export const icon: ThemedSxProps = (theme) => ({
  ':not(:first-of-type)': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  padding: theme.spacing(1),
});

export const actions: ThemedSxProps = {
  padding: '0px !important',
  display: { md: 'flex', sm: 'flex', xs: 'block' },
  flexDirection: 'column',
};

export const button: ThemedSxProps = {
  width: { md: 'auto', sm: 'auto', xs: '90%' },
  padding: '15px 32px 15px 32px',
  height: { md: '54px', sm: '54px', xs: '44px' },
  fontSize: { md: '15px', sm: '15px', xs: '14px' },
  marginTop: { md: '12px', sm: '12px', xs: '12px' },
  borderRadius: '0px',
  zIndex: 1,
};

export const showBtnContainer: ThemedSxProps = {
  display: { md: 'flex', sm: 'flex', xs: 'block' },
  flexDirection: 'column',
  marginTop: { md: '20px', sm: '20px', xs: '8px' },
  marginLeft: '15px',
};
export const showBtnLeft: ThemedSxProps = (theme) => ({
  fontSize: { md: '16px', sm: '16px', xs: '12px' },
  fontWeight: '500',
  textAlign: 'center',
  color: theme.palette.common.black,
  lineHeight: { md: '150%', sm: '150%', xs: '143%' },
  width: { md: 'auto', sm: 'auto', xs: '100%' },
  marginRight: { md: '10px', sm: '10px', xs: '0px' },
  marginBottom: '0px',
});

export const termsContainer: ThemedSxProps = {
  marginTop: { md: '0px', sm: 'auto', xs: 'auto' },
  minHeight: { md: 'auto', sm: 'auto', xs: 'auto' },
  position: { md: 'static', sm: 'static', xs: 'static' },
};

export const TermsOfUse = styled('a')({
  textDecoration: 'underline',
  fontFamily: fonts.poppins,
  fontSize: 12,
  fontWeight: '500',
  lineHeight: '164%',
  color: colors.black,
});

export const termsofuse: ThemedSxProps = {
  marginLeft: '0px',
};

export const TermsAndConditions = styled('a')({
  textDecoration: 'underline',
});
export const termConBottom: ThemedSxProps = (theme) => ({
  paddingTop: '8px',
  paddingBottom: '20px',
  fontFamily: fonts.poppins,
  fontSize: 12,
  fontWeight: '500',
  lineHeight: '164%',
  textAlign: 'center',
  color: theme.palette.common.black,
});
