import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { colors } from 'utils/theme/colors';

export const upperHeaderPanel: SxProps<Theme> = (theme) => ({
  width: 1,
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0.625, 'auto', 0, 'auto'),
  maxWidth: 1060,
  padding: theme.spacing(2, 0),
});

export const webHeader: SxProps = {
  display: 'flex',
  flex: '1 0 auto',
  ml: 4,
};

export const controls: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const search: SxProps = {
  display: 'flex',
  flex: '1 1 auto',
  mr: 4,
};

export const divider: SxProps<Theme> = (theme) => ({
  height: '24px',
  padding: theme.spacing(0, 1),
  mr: 1,
});

export const buttonLink: SxProps<Theme> = (theme) => ({
  '&.MuiLink-root.MuiButton-sizeMedium': {
    lineHeight: '24px',
    fontWeight: 600,
    letterSpacing: '0.4px',
    padding: theme.spacing(1, 2),
    mr: 1,
    '&:hover': {
      color: colors.primaryBlack,
    },
  },
});

export const cartLink: SxProps = {
  padding: 1,
};

export const toolbar: SxProps = {
  py: 1.5,
  pl: 2.5,
  pr: 1,
  minHeight: 0,
};

export const toolbarButtons: SxProps = {
  ml: 'auto',
  display: 'flex',
  gap: 0.5,
};

export const drawer: SxProps = {
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: 320,
  },
};
