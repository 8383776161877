import * as styles from 'components/Home/GridWidget/styles';
import { CategoryWidgetSkeleton } from 'components/Home/GridWidget/components/Skeleton';
import { ComponentProps } from 'components/Home/types';
import { Grid } from '@mui/material';
import { GridItem } from 'components/Home/GridWidget/components/GridItem';
import {
  GridWidgetContent,
  GridWidgetItem,
} from 'components/Home/GridWidget/types';
import { Headline } from 'components/Home/GridWidget/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import {
  ViewAllButtonDesktop,
  ViewAllButtonMobile,
  WidgetWrapper,
} from 'components/Home/common/styled';
import { checkForFrench } from 'components/Home/utils';
import { getComponent } from 'components/Home/feature/selectors';
import { getId } from 'components/Home/utils/get-html-id';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const GridWidget: FC<ComponentProps> = ({ type, id, sequence }) => {
  const componentData = useSelector(getComponent(type, id));
  const content = useResponsiveContent<GridWidgetContent>(componentData, 'md');

  const { imagesPreloaded } = useImagePreloader(content.items);

  if (!imagesPreloaded) {
    return <CategoryWidgetSkeleton />;
  }

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <WidgetWrapper id={getId(type, id)} data-test="grid-widget">
        <Headline>
          <PureHtmlTypography variant="h6" data-test="grid-widget-title">
            {checkForFrench(content.title, content.frTitle)}
          </PureHtmlTypography>
          <ViewAllButtonDesktop
            size="small"
            href={checkForFrench(content?.url, content?.frUrl)}
            color="primary"
          >
            <PureHtmlTypography>
              {checkForFrench(
                content?.viewAllButtonText,
                content?.frViewAllButtonText,
              )}
            </PureHtmlTypography>
          </ViewAllButtonDesktop>
        </Headline>

        <Grid
          sx={styles.container}
          columnSpacing={2.5}
          rowSpacing={{ xs: 2.5, md: 6.25 }}
          container
          data-test="grid-widget-content"
        >
          {content.items.map((category: GridWidgetItem) => (
            <Grid key={category.id} xs={6} sm={3} item>
              <GridItem item={category} />
            </Grid>
          ))}
        </Grid>
        <ViewAllButtonMobile
          size="large"
          variant="outlined"
          href={checkForFrench(content?.url, content?.frUrl)}
          color="primary"
        >
          {checkForFrench(
            content?.viewAllButtonText,
            content?.frViewAllButtonText,
          )}
        </ViewAllButtonMobile>
      </WidgetWrapper>
    </RenderWhenInView>
  );
};
