import * as TK from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/translations/constants';
import * as styles from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/components/GuidePopover/styles';
import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { selectRecipientName } from 'redux/session/availability/availability.selectors';
import { useCloseOnTimeout } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/components/GuidePopover/hooks/use-close-on-timeout';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  anchorElement: HTMLDivElement;
  onClose: () => void;
  open?: boolean;
  timeout?: number;
}

export const GuidePopover: FC<Props> = ({
  anchorElement,
  open = false,
  onClose,
  timeout,
}) => {
  const recipientName = useSelector(selectRecipientName);

  useCloseOnTimeout({
    close: onClose,
    isOpened: Boolean(open),
    timeout: timeout ?? Infinity,
  });

  if (!anchorElement) {
    return null;
  }

  return (
    <Popper
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      placement="top"
      open={open}
      anchorEl={anchorElement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper elevation={13} sx={styles.paper(anchorElement.offsetWidth)}>
            <Typography variant="body2">
              {i18next.t(TK.GUIDE, { name: recipientName })}
            </Typography>
            <Box height={1} my="auto" ml={1.5}>
              <IconButton
                aria-label={i18next.t(TK.CLOSE)}
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box sx={styles.arrow} />
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
