import { EnsureAll } from 'types/utility.types';
import { FulfillmentOptionType } from 'types/cart.types';
import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { isFulfillType } from 'utils/is-order-type';
import { isNotNull } from 'utils/null.utils';

interface FilledPas extends EnsureAll<Pas> {
  orderType: FulfillmentOptionType;
}

export function isPasFilled(pas: Pas): pas is FilledPas {
  return Object.values(pas).every(isNotNull) && isFulfillType(pas.orderType);
}
