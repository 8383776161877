import { add } from 'date-fns';

export const PREVIEW_FORMAT = 'EEEE, LLL d';
export const DAY_TOGGLE_FORMAT = 'd MMM';

const newDateMidnight = new Date();
newDateMidnight.setHours(0, 0, 0, 0);

export const TODAY = newDateMidnight;
export const TOMORROW = add(newDateMidnight, { days: 1 });
