import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as styles from 'components/PDP/Details/components/Gallery/styles';
import { BigGallery } from 'components/PDP/Details/components/Gallery/components/BigGallery/BigGallery';
import { Box, Skeleton } from '@mui/material';
import { DottedSlider } from 'components/common/DottedSlider';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { Media } from 'components/PDP/Details/components/Gallery/components/Media';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { isString } from 'utils/is-string';
import { selectIsMarketplaceProduct } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC, Fragment, ReactElement, useState } from 'react';

export type GalleryImages =
  | string
  | ((isBottomCarousel?: boolean) => ReactElement);

type Props = {
  images?: GalleryImages[];
  imageAltTag: string;
  loading?: boolean;
  showHorizontalBorders: boolean;
};

export const Gallery: FC<Props> = ({
  images = [],
  imageAltTag,
  loading,
  showHorizontalBorders,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const isMarketplaceProduct = useSelector(selectIsMarketplaceProduct);

  if (loading) {
    return <Skeleton sx={styles.skeleton} />;
  }

  return (
    <Box
      sx={isMarketplaceProduct ? styles.galleryMpProduct : styles.gallery}
      className="galleryImg"
    >
      <Box sx={styles.slider(showHorizontalBorders)}>
        <HideUpSm>
          <DottedSlider afterChange={(index) => setActiveIndex(index)}>
            {images?.map((carouselImage, index) =>
              isString(carouselImage) ? (
                <Media
                  key={carouselImage}
                  src={carouselImage}
                  alt={imageAltTag}
                  forcePause={index !== activeIndex}
                />
              ) : (
                <Fragment key={carouselImage.toString()}>
                  {carouselImage()}
                </Fragment>
              ),
            )}
          </DottedSlider>
        </HideUpSm>
        <ShowUpSm>
          <BigGallery
            images={images}
            imageAlt={imageAltTag}
            showHorizontalBorders={showHorizontalBorders}
          />
        </ShowUpSm>
      </Box>
    </Box>
  );
};
