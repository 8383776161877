import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosResponse } from 'axios';
import { SectionType } from 'components/Subscription/EmailSubscriptionDialog/enums/email-subscription.enum';
import { SubscriptionFormData } from 'components/Subscription/types';

class SubscribeLegacyApiService extends ApiLegacyBaseService {
  subscribe({
    email,
    phone,
    section = SectionType.CouponSignUp,
  }: SubscriptionFormData): Promise<AxiosResponse<void>> {
    return this.apibase.get('/subscribe.aspx', {
      params: {
        EmailAddress: email,
        PhoneNumber: phone,
        Section: section,
      },
    });
  }
}

export const subscribeLegacyApiService = new SubscribeLegacyApiService();
