import { createAsyncThunk } from '@reduxjs/toolkit';
import { storesApiService } from 'api/stores.api.service';

export const fetchSchedule = createAsyncThunk(
  'session/store-schedule/fetch',
  async ({ storeId, date }: { storeId: number; date: Date }) => {
    const { data } = await storesApiService.getStoreSchedule(storeId, date);
    return data;
  },
);
