import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const accordion: SxProps = {
  height: 80,
  display: 'flex',
  alignItems: 'center',
};

export const summary: SxProps<Theme> = (theme) => ({
  p: 0,
  justifyContent: 'space-between',

  '&.Mui-disabled': {
    opacity: 1,
  },
  '& *': {
    color: theme.palette.text.primary,
  },
});

export const preview: SxProps = {
  textOverflow: 'ellipsis',
  maxWidth: [500, 500, 580],
  position: ['absolute', 'relative', 'relative'],
  mt: [11, 0, 0],
  width: 'auto',
  pl: 0,
  textAlign: 'right',
};
