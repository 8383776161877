export interface PaginationPayload<T> {
  items: T[];
  isAllFetched: boolean;
}

function isAllItemsFetched(itemsAmount: number, limit?: number): boolean {
  if (limit === undefined) {
    return true;
  }
  if (limit <= 0) {
    return true;
  }
  if (itemsAmount < limit) {
    return true;
  }

  return false;
}

export function createPaginationPayload<T>(
  items: T[],
  limit?: number,
): PaginationPayload<T> {
  return {
    items,
    isAllFetched: isAllItemsFetched(items.length, limit),
  };
}
