import { useEffect } from 'react';

export function useHistoryScrollRestorationManual(): void {
  useEffect(() => {
    if (
      'scrollRestoration' in window.history &&
      window.history.scrollRestoration === 'auto'
    ) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);
}
