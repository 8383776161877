import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFallbackResponse } from 'components/Home/feature/fallback';
import { plpWidgetApiService } from 'api/plp-widget.api.service';

export const getPlpWidgetContent = createAsyncThunk(
  'plp/get',
  async ({
    previewDate,
    showDraft,
    filterId,
  }: {
    previewDate: string | null;
    showDraft: string | null;
    filterId: number | null;
  }) => {
    let plpWidgetContent;
    const { data } = await plpWidgetApiService
      .getContent(previewDate, showDraft, filterId)
      .catch(() => ({
        data: getFallbackResponse(),
      }));

    if (!data) {
      plpWidgetContent = await plpWidgetApiService
        .getContent(previewDate, showDraft, 0)
        .catch(() => ({
          data: getFallbackResponse(),
        }));

      return plpWidgetContent.data;
    }
    return data;
  },
);
