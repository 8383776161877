import {
  ApiLegacyBaseService,
  LEGACY_JSON_HEADERS,
} from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';

const AUTH_ROOT = '/Ajax/LoginUser.aspx';

class AuthLegacyApiService extends ApiLegacyBaseService {
  private authPath(path: string): string {
    return `${AUTH_ROOT}/${path}`;
  }

  validate(email: string, password: string): AxiosPromise<DResponse> {
    return this.apibase.post<DResponse>(
      this.authPath('ValidateUserByEmailAndPassword'),
      { Email: email, Password: password },
    );
  }

  logInUser(email: string, password: string): AxiosPromise<DResponse> {
    const conditionalPayload = IS_MOBILE_HOST
      ? { FailedloginCount: '0' }
      : { FailedLoginCount: '0' };

    return this.apibase.post<DResponse>(this.authPath('LoginUser'), {
      Email: email,
      Password: password,
      chkRemeberPassword: 'false',
      IsPartialMemeber: 'false',
      CaptchaResponse: '',
      ...conditionalPayload,
    });
  }

  logInCallCenterAccount({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<DResponse> {
    return this.apibase
      .post<DResponse>('/Ajax/CSRAccountLogin.aspx/btnCSRLogin_Click', {
        CSRUserName: username,
        CSRUserPassword: password,
        SelectedCountry: 1,
      })
      .then(({ data }) => data);
  }

  resetPassword(email: string): AxiosPromise<DResponse> {
    return this.apibase.post(this.authPath('RecoverPassword'), {
      Email: email,
    });
  }

  logOut(): AxiosPromise<DResponse> {
    /** axios clears default content-type header */
    return this.apibase.post(
      IS_MOBILE_HOST
        ? '/default.aspx/AccountSignOut'
        : this.authPath('logoutuser'),
      null,
      { headers: LEGACY_JSON_HEADERS },
    );
  }
}

const authLegacyApiService = new AuthLegacyApiService();
export { authLegacyApiService };
