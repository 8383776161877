import { OrderType } from 'types/cart.types';
import {
  ShipmentStore,
  availabilityStoreService,
} from 'api/availability-store.api.service';
import { Store } from 'redux/types/store.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

// here
function mapShipmentStore(store: ShipmentStore): Store[] {
  return [
    {
      id: store.storeId,
      number: store.storeNumber,
      areaName: '',
      address: '',
      city: '',
      stateCode: '',
      phone: '',
      schedule: [],
      curbside: false,
      managedType: null,
      openingTime: null,
      closingTime: null,
    },
  ];
}

interface BaseParams {
  date: Date;
  arrangementId: number;
  productId: number;
  test: boolean;
}

interface PickupParams extends BaseParams {
  orderType: OrderType.Pickup;
  areaId: number;
}

interface DeliveryAndShipmentParams extends BaseParams {
  orderType: OrderType.Delivery | OrderType.Shipment;
  areaName: string;
}

export const fetchStores = createAsyncThunk(
  'availability/stores',
  (params: DeliveryAndShipmentParams | PickupParams, { rejectWithValue }) => {
    const commonPayload = {
      date: params.date,
      arrangementId: params.arrangementId,
      productId: params.productId,
      test: params.test || false,
    };

    let promise: Promise<Store[]>;

    switch (params.orderType) {
      case OrderType.Pickup: {
        promise = availabilityStoreService.getAvailableStoresForPickup({
          ...commonPayload,
          areaId: params.areaId,
        });
        break;
      }
      case OrderType.Delivery: {
        promise = availabilityStoreService.getAvailableStoresForDelivery({
          ...commonPayload,
          areaName: params.areaName,
        });
        break;
      }
      case OrderType.Shipment: {
        promise = availabilityStoreService
          .getAvailableStoreForShipment({
            ...commonPayload,
            areaName: params.areaName,
          })
          .then(({ data }) => mapShipmentStore(data));
        break;
      }
      default: {
        return rejectWithValue(null);
      }
    }

    return promise.catch((err) => rejectWithValue(parseAxiosError(err)));
  },
);
