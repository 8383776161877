import * as styles from 'components/Home/SecondaryBanner/components/styles';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { TextAndButtonAlignment } from 'components/Home/SecondaryBanner/types';
import { colors } from 'utils/theme/colors';
import {
  getAlignmentSecondaryBannerStyles,
  getHeaderTextSecondaryBannerStyles,
  getMobileAlignmentSecondaryBannerStyles,
  getMobileHeaderTextSecondaryBannerStyles,
} from 'components/Home/SecondaryBanner/components/styles';
import { useIsDownMd } from 'utils/hidden/use-is-down-md';
import React, { FC } from 'react';

interface Props {
  subTitleText?: string;
  titleText?: string;
  buttonText?: string;
  buttonTextColor?: string;
  textColor?: string;
  textAlignment: TextAndButtonAlignment;
  buttonAlignment: TextAndButtonAlignment;
  secondaryBannerLength: number;
}

export const SecondaryBannerContent: FC<Props> = ({
  subTitleText,
  titleText,
  buttonText,
  buttonTextColor,
  textColor,
  textAlignment,
  buttonAlignment,
  secondaryBannerLength,
}) => {
  const isDownMd = useIsDownMd();
  const TextColor = textColor === 'white' ? colors.white : colors.primaryRed;
  const ButtonTextColor =
    buttonTextColor === 'white' ? colors.white : colors.primaryRed;
  const ButtonBackGroundColor =
    buttonTextColor === 'white' ? colors.primaryRed : colors.white;
  const ButtonVariant = buttonTextColor === 'white' ? 'contained' : 'outlined';
  const {
    textContainerSecondaryBanner,
    titleTextSecondaryBanner,
    subTitleTextSecondaryBanner,
  } = styles;

  const headerTextStyle = isDownMd
    ? getMobileAlignmentSecondaryBannerStyles(
        textAlignment || TextAndButtonAlignment.MiddleLeft,
      )
    : getAlignmentSecondaryBannerStyles(
        textAlignment || TextAndButtonAlignment.MiddleLeft,
      );

  const buttonPositionStyle = isDownMd
    ? getMobileAlignmentSecondaryBannerStyles(
        buttonAlignment || TextAndButtonAlignment.MiddleLeft,
      )
    : getAlignmentSecondaryBannerStyles(
        buttonAlignment || TextAndButtonAlignment.MiddleLeft,
      );

  const headerTextStyleWidth = isDownMd
    ? getMobileHeaderTextSecondaryBannerStyles(secondaryBannerLength)
    : getHeaderTextSecondaryBannerStyles(secondaryBannerLength);

  const htmlTextContainer = () => (
    <>
      <Box
        sx={
          {
            ...textContainerSecondaryBanner,
            ...headerTextStyle,
            ...headerTextStyleWidth,
          } as SxProps<Theme>
        }
      >
        {titleText && (
          <PureHtmlTypography
            variant="h5"
            sx={{
              ...titleTextSecondaryBanner,
              color: `${TextColor}`,
            }}
          >
            {titleText}
          </PureHtmlTypography>
        )}
        {subTitleText && (
          <PureHtmlTypography
            variant="subtitle1"
            sx={{
              ...subTitleTextSecondaryBanner,
              color: `${TextColor}`,
            }}
          >
            {subTitleText}
          </PureHtmlTypography>
        )}
      </Box>
    </>
  );

  const htmlButtonContainer = () => (
    <>
      {buttonText && (
        <Box
          sx={
            {
              ...styles.buttonContainer,
              ...buttonPositionStyle,
            } as SxProps<Theme>
          }
        >
          <Button
            data-test="carousel-image-btn"
            variant={ButtonVariant}
            color="primary"
            sx={{
              ...styles.buttonStyle,
              backgroundColor: `${ButtonBackGroundColor}`,
              '&:hover': {
                backgroundColor: `${ButtonBackGroundColor}`,
                borderWidth: '4px',
              },
            }}
          >
            <PureHtmlTypography
              sx={{
                ...styles.buttonText,
                color: `${ButtonTextColor}`,
              }}
            >
              {buttonText}
            </PureHtmlTypography>
          </Button>
        </Box>
      )}
    </>
  );

  const { mainContainerSecondaryBanner } = styles;
  const { mainFrameDesktop } = styles;
  const { backGroundImageWrapperSecondaryBanner } = styles;

  return (
    <Box
      sx={{
        ...backGroundImageWrapperSecondaryBanner,
      }}
    >
      <Box sx={mainContainerSecondaryBanner}>
        <Box sx={mainFrameDesktop}>{htmlTextContainer()}</Box>
        <Box sx={mainFrameDesktop}>{htmlButtonContainer()}</Box>
        <Box sx={styles.spacer} />
      </Box>
    </Box>
  );
};
