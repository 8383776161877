import { SEARCH_QUERY_KEY } from 'containers/PLPContainer/query.constants';
import { useQueryParameter } from 'hooks/use-query-parameter';
import { useQueryParameterRemover } from 'containers/PLPContainer/hooks/query/use-query-parameter-remover';
import { useQueryParameterSetter } from 'containers/PLPContainer/hooks/query/use-query-parameter-setter';

export const usePlpSearchText = (): [string, (text?: string) => void] => {
  const text = useQueryParameter(SEARCH_QUERY_KEY);
  const set = useQueryParameterSetter();
  const remove = useQueryParameterRemover();

  const apply = (searchText?: string): void => {
    if (searchText && searchText.trim()) {
      set(SEARCH_QUERY_KEY, searchText.trim());
    } else {
      remove(SEARCH_QUERY_KEY);
    }
  };

  return [text?.trim() ?? '', apply];
};
