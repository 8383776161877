import { ALL_ARRANGEMENTS_FILTER_VALUE } from 'api/arrangements/arrangement.api.service';
import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { FilterTypes } from 'enums/filter-type';
import { Locale } from 'enums/locale';

export const DEFAULT_FILTER = {
  ID: 0,
  Name: '',
  Value: ALL_ARRANGEMENTS_FILTER_VALUE,
  FilterCategoryID: 0,
  Type: 0,
  Sequence: 0,
  PageFriendlyURL: '',
};

type ML = {
  id: number;
  culture: Locale;
  name: string;
};

export type FilterItem = {
  deleted: boolean;
  filterCategoryID: number;
  id: number;
  inactive: boolean;
  mls: ML[];
  sequence: number;
  type: FilterTypes;
  url: string;
  arrangementGroupId: number;
  value: string;
};

class FiltersApiService extends ApiBaseService {
  find(url: string): AxiosPromise<FilterItem[]> {
    return this.apibase.get('/filters', { params: { url } });
  }
}

const filterApiService = new FiltersApiService();

export { filterApiService };
