import * as TK from 'components/Session/Alert/StoreLockAlert/translations/locale.constants';
import { Skeleton } from '@mui/material';
import {
  checkIsScheduleBlocked,
  checkStoreIdIsValid,
  getSchedule,
} from 'components/Session/feature/selectors';
import { clearSchedule } from 'components/Session/feature/slice';
import { fetchSchedule } from 'components/Session/feature/actions';
import { format, parse } from 'date-fns';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { getLocalizedTime } from 'utils/date';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

const localizeTimeString = (time: string): string =>
  getLocalizedTime(parse(time, 'HH:mm:ss', new Date()));

interface Props {
  date: Date;
  storeId: number;
}

export const Schedule: FC<Props> = ({ date, storeId }) => {
  const dispatch = useDispatch();
  const isBlocked = useSelector(checkIsScheduleBlocked);
  const isValidStoreId = useSelector(checkStoreIdIsValid);
  const schedule = useSelector(getSchedule);

  useEffect(() => {
    if (isValidStoreId && date) {
      dispatch(fetchSchedule({ storeId, date }));
    } else {
      dispatch(clearSchedule());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [storeId, date ? formatSQLDate(date) : '', dispatch, isValidStoreId]);

  if (isBlocked || !schedule || !date) {
    return <Skeleton />;
  }

  return (
    <>
      {i18next.t(TK.SL_STORE_SCHEDULE, {
        day: format(date, 'EEEE'),
        from: localizeTimeString(schedule.PickupStartTime),
        to: localizeTimeString(schedule.PickupEndTime),
      })}
    </>
  );
};
