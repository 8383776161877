import { useLocation, useNavigate } from 'react-router-dom';

type Remover = (parameter: string) => void;
export const useQueryParameterRemover = (): Remover => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (param: string): void => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete(param);
    navigate({ search: urlSearchParams.toString() });
  };
};
