import { StructuredDataValues } from 'utils/seo/google/enums';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';
import { useEffect, useState } from 'react';

type Location = { host: string; protocol: string; pathname: string };

const generate = (
  { host, protocol, pathname }: Location,
  statesServed: string[],
): string => {
  return JSON.stringify(
    pickBy(
      {
        '@context': StructuredDataValues.SCHEMA,
        '@type': 'FoodEstablishment',
        name: 'Edible Arrangements',
        url: `${protocol}//${host}${pathname}`,
        logo: 'https://resources.ediblearrangements.com/resources/en-us/i/revamp_p/edible_arrangement_logo.png',
        areaServed: [
          statesServed.map((state) => {
            return {
              '@type': 'State',
              name: `${state}`,
              url: `${protocol}//${host}${pathname}/${state}`,
            };
          }),
        ],
      },
      isNotUndefined,
    ),
    null,
    2,
  );
};

export function useStructuredStatesData(states: string[]): string {
  const [structure, setStructure] = useState<string>(
    generate(window.location, states),
  );

  useEffect(() => {
    setStructure(generate(window.location, states));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return structure;
}
