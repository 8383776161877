import { ButtonBase, styled } from '@mui/material';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import { theme } from 'utils/theme/theme';

interface ButtonProps {
  boxtype: OwnBoxTypes;
}

interface Coordinate {
  top: number[];
  left: number[];
}

const IMAGE_ORIGINAL_SIZE = 360;
const coordinates: Record<OwnBoxTypes, Coordinate> = {
  [OwnBoxTypes.DIPPED_FRUIT]: {
    top: [164, 205, 247],
    left: [71, 133, 190, 251, 65, 129, 194, 257, 60, 126, 196, 261],
  },
  [OwnBoxTypes.FRUIT]: {
    top: [122, 122, 164, 164, 164, 206, 206, 206, 206, 248, 248, 248],
    left: [141, 183, 117, 159, 202, 97, 139, 181, 223, 118, 160, 202],
  },
  [OwnBoxTypes.DESSERT]: {
    top: [170, 248],
    left: [55, 152, 249, 45, 152, 259],
  },
  [OwnBoxTypes.DESSERT_BOARD]: {
    top: [90, 135, 185, 227, 180, 230, 88, 90, 136, 184, 230, 230, 136, 230],
    left: [252, 252, 252, 252, 70, 70, 96, 142, 96, 207, 207, 160, 142, 114],
  },
  [OwnBoxTypes.DESSERT_BOARD_DIPPED_BERRIES]: {
    top: [88, 90, 136, 184, 230, 230, 136, 230],
    left: [96, 142, 96, 207, 207, 160, 142, 114],
  },
  [OwnBoxTypes.DESSERT_BOARD_DIPPED_FRUIT]: {
    top: [136, 230],
    left: [142, 114],
  },
  [OwnBoxTypes.DESSERT_BOARD_BACKEDGOODS_DIPPEDFRUIT]: {
    top: [90, 135, 185, 227, 180, 230, 136, 230],
    left: [252, 252, 252, 252, 70, 70, 142, 114],
  },
  [OwnBoxTypes.COOKIE_BOX]: {
    top: [170, 248, 148, 220],
    left: [55, 152, 249, 45, 152, 259, 55, 152, 249, 45, 152, 259],
  },
  [OwnBoxTypes.COOKIE_BOX_LIMITED]: {
    top: [148, 220],
    left: [55, 152, 249, 45, 152, 259],
  },
  [OwnBoxTypes.NONE]: {
    top: [],
    left: [],
  },
};
const createRelativeCoordinate = (coordinate: number): string =>
  `calc(${coordinate} / ${IMAGE_ORIGINAL_SIZE} * 100%)`;

const getItemWidth = (box: OwnBoxTypes): string => {
  return createRelativeCoordinate(
    box === OwnBoxTypes.DESSERT || box === OwnBoxTypes.COOKIE_BOX ? 56 : 40,
  );
};

const getTopCoordinate = (index: number, boxtype: OwnBoxTypes): number =>
  coordinates[boxtype].top[
    Math.floor(
      (index * coordinates[boxtype].top.length) /
        coordinates[boxtype].left.length,
    )
  ];

const getNthOfTypeBoxCells = (boxtype: OwnBoxTypes) =>
  coordinates[boxtype]?.left.reduce(
    (cells, leftCoordinate, index) => ({
      ...cells,
      [`:nth-of-type(${index + 1})`]: {
        left: createRelativeCoordinate(leftCoordinate),
        top: createRelativeCoordinate(getTopCoordinate(index, boxtype)),
      },
    }),
    {},
  );

export const ImageButton = styled(ButtonBase)(({ boxtype }: ButtonProps) => ({
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  position: 'absolute',
  borderRadius: '40%',
  backgroundColor: theme.palette.common.white,
  width: getItemWidth(boxtype),
  ...getNthOfTypeBoxCells(boxtype),
  '& img': {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    borderRadius: '40%',
  },
}));
