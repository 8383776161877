import { Skeleton } from '@mui/material';
import { generate } from 'utils/generate';
import React, { FC } from 'react';

export const DescriptionSkeleton: FC = () => (
  <>
    {generate(<Skeleton width="100%" height={12} sx={{ mb: 0.5 }} />, 2)}
    <Skeleton width="50%" height={12} />
  </>
);
