import { CarouselItemImageSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton/CarouselItemImageSkeleton';
import { Skeleton } from '@mui/material';
import { titleSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton/styles';
import React, { FC } from 'react';

export const CarouselItemSkeleton: FC = () => (
  <div>
    <CarouselItemImageSkeleton />
    <Skeleton sx={titleSkeleton} variant="rectangular" />
  </div>
);
