import { Box, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

export const LoadingSpinner: FC = () => (
  <Box
    minHeight="100vh"
    display="flex"
    justifyContent="center"
    alignItems="flex-start"
    p={8}
  >
    <CircularProgress size={50} thickness={2} />
  </Box>
);
