import { FlexCartParams } from 'types/cart.types';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';

const DEFAULT_FLEX_CART_PARAMS: FlexCartParams = {
  FlexDiscount: 0,
  FlexFromDate: IS_MOBILE_HOST ? '1/1/0001 12:00:00 AM' : '01/01/0001',
  FlexOptionID: 0,
  FlexToDate: IS_MOBILE_HOST ? '1/1/0001 12:00:00 AM' : '01/01/0001',
};

export const getDefaultFlexCartParam = (): FlexCartParams =>
  DEFAULT_FLEX_CART_PARAMS;
