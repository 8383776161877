import { Box } from '@mui/material';
import { Props } from 'components/PDP/Upsells/props';
import { trackComponent } from 'utils/telemetry/app-insights';
import React, { Suspense, lazy } from 'react';

const Upsells = lazy(() =>
  import('./Upsells').then((module) => ({ default: module.Upsells })),
);

export const LazyUpsells = trackComponent(
  (props: Props) => (
    <Suspense fallback={<Box minHeight="100vh" />}>
      <Upsells {...props} />
    </Suspense>
  ),
  'PDP upsells',
);
