import * as TK from 'translations/locales/translation-keys.constant';
import { FulfillmentOptionType } from 'types/cart.types';
import { TFunctionKeys } from 'i18next';
import { isPickup } from 'utils/is-order-type';

export function getPreviewLocaleKey(
  orderType: FulfillmentOptionType,
): TFunctionKeys {
  return isPickup(orderType) ? TK.PICK_UP : TK.DELIVERY;
}
