import * as React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { filterRedundantItems } from 'utils/telemetry/filter-redundant-items.initializer';

export const plugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.APP_INSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    samplingPercentage: 50,
    extensions: [plugin],
  },
});

export const trackComponent = <P>(
  component: React.ComponentType<P>,
  componentName?: string,
  className?: string,
): React.ComponentClass<P> => {
  return withAITracking<P>(plugin, component, componentName, className);
};
appInsights.loadAppInsights().addTelemetryInitializer(filterRedundantItems);
