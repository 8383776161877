import { RecipientAvailability } from 'api/availability/recipient-availability.api.service';
import { RootState } from 'redux/store';

export const selectRecipientAvailability = (
  state: RootState,
): (number | undefined)[] =>
  state.recipientAvailability.data
    .filter((recipient) => recipient.isAvailable === true)
    .map((availableRecipient) => availableRecipient.recipientId);

export const selectRecipientsAvailable = (
  state: RootState,
): RecipientAvailability | undefined =>
  state.recipientAvailability.data.find(
    (recipient) => recipient.isAvailable === true,
  );
