import { Locales } from 'enums/locale';

export enum BannerAreaTitle {
  SkinnyBanner = 'Skinny Banner',
}

export type BannerAreaTitles =
  typeof BannerAreaTitle[keyof typeof BannerAreaTitle];

export type Targets = typeof Target[keyof typeof Target];

export type QueryParams = {
  countryId: number;
  bannerAreaTitle: BannerAreaTitles;
  target: Targets;
};

export type Banner = {
  id: number;
  image: string;
  desktopImage: string;
  desktopImageMap: string;
  mobileImage: string;
  mobileImageMap: string;
  alt: string;
  countryId: number;
  backgroundColor: string;
  alternateImage: string;
  culture: Locales;
  bannerAreaTitle: typeof BannerAreaTitle[keyof typeof BannerAreaTitle];
};

export enum Target {
  Edible = 1,
  DippedFruit = 2,
}
