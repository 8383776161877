export const OWN_BOX_SUBTITLE = 'own_box.subtitle';
export const OWN_BOX_DIPPED_FRUIT_TITLE = 'own_box.dipped_fruit.subtitle';
export const OWN_BOX_FRUIT_TITLE = 'own_box.fruit.subtitle';
export const OWN_BOX_DESSERT_TITLE = 'own_box.dessert.subtitle';
export const OWN_BOX_LIMIT_ALERT_TITLE = 'own_box.limit_alert.title';
export const OWN_BOX_LIMIT_ALERT_CONTENT = 'own_box.limit_alert.content';
export const OWN_BOX_DESSERT_BOARD_BAKESHOP_TITLE =
  'own_box.dessert_board_bakeshop.title';
export const OWN_BOX_DESSERT_BOARD_BERRIES_TITLE =
  'own_box.dessert_board_berries.title';
export const OWN_BOX_DESSERT_BOARD_FRUIT_TITLE =
  'own_box.dessert_board_fruit.title';
export const OWN_BOX_COOKIE_BOX_TITLE = 'own_box.cookie_box.title';
export const OWN_BOX_COOKIE_BOX_LIMITED_TITLE =
  'own_box.cookie_box_limited.title';
export const OWN_BOX_COOKIE_BOX_SUBTITLE = 'own_box.cookie_box.subtitle';
export const OWN_BOX_6_COOKIE_BOX_TITLE = 'own_box.6_cookie_box.title';
export const OWN_BOX_6_COOKIE_BOX_SUBTITLE = 'own_box.6_cookie_box.subtitle';
export const OWN_BOX_COOKIE_BOX_LIMITED_SUBTITLE =
  'own_box.cookie_box.limited.subtitle';
export const OWN_BOX_COOKIE_SIZE_12_COOKIES = 'own_box.cookie.size_12_cookies';
export const OWN_BOX_COOKIE_SIZE_6_COOKIES = 'own_box.cookie.size_6_cookies';
