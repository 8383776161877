import { CardOptionType } from 'components/PDP/Order/GreetingsCards/enums/card-option-type.enum';
import { GreetingsCardsErrorType } from 'components/PDP/Order/GreetingsCards/enums/greetings-cards-error-type.enum';
import {
  GreetingsCardsState,
  PrintibleState,
} from 'components/PDP/Order/GreetingsCards/feature/greetings-cards.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PrintibleActions } from 'components/PDP/Order/GreetingsCards/enums/printible-actions.enum';
import { isFreeCardMessageError } from 'components/PDP/Order/GreetingsCards/utils/is-free-card-message-error';

const initialPrintibleState = {
  origin: '',
  data: {
    action: PrintibleActions.NOT_SET,
    imageLink: '',
    mediumImageLink: '',
    printibleID: '',
  },
};

const initialState: GreetingsCardsState = {
  selected: CardOptionType.NOT_SELECTED,
  freeCardMessage: '',
  printible: initialPrintibleState,
  errors: [],
};

const greetingsCardsSlice = createSlice({
  name: 'recipient-info/greetings-cards',
  initialState,
  reducers: {
    updatePrintibleOrderState: (
      state,
      action: PayloadAction<PrintibleState>,
    ) => {
      state.printible = action.payload;
    },
    resetPrintibleOrderToInitialState: (state) => {
      state.printible = initialPrintibleState;
    },
    updateCardMessageValue: (state, action: PayloadAction<string>) => {
      if (
        action.payload !== '' &&
        state.errors.includes(GreetingsCardsErrorType.FIELD_EMPTY)
      ) {
        state.errors = state.errors.filter(
          (error) => error !== GreetingsCardsErrorType.FIELD_EMPTY,
        );
      }
      state.freeCardMessage = action.payload;
    },
    updateSelectedGreetingsCard: (
      state,
      action: PayloadAction<CardOptionType>,
    ) => {
      if (state.errors.some(isFreeCardMessageError)) {
        state.freeCardMessage = '';
        state.errors = state.errors.filter(
          (error) => !isFreeCardMessageError(error),
        );
      }
      state.selected = action.payload;
    },
    addGreetingsCardsError: (
      state,
      action: PayloadAction<GreetingsCardsErrorType>,
    ) => {
      if (state.errors.includes(action.payload)) {
        return;
      }
      const errors: GreetingsCardsErrorType[] = [...state.errors];
      errors.push(action.payload);
      state.errors = [...errors];
    },
    removeGreetingsCardsError: (
      state,
      action: PayloadAction<GreetingsCardsErrorType>,
    ) => {
      if (state.errors.includes(action.payload)) {
        state.errors = state.errors.filter((error) => error !== action.payload);
      }
    },
  },
});

export const { reducer: greetingsCardsReducer } = greetingsCardsSlice;

export const {
  updatePrintibleOrderState,
  resetPrintibleOrderToInitialState,
  updateCardMessageValue,
  updateSelectedGreetingsCard,
  addGreetingsCardsError,
  removeGreetingsCardsError,
} = greetingsCardsSlice.actions;
