export const SERVICED_AREAS_TITLE = 'store_details.serviced_areas_title';
export const STORE_DETAILS_BREADCRUMB = 'store_details.breadcrumb';
export const STORE_ADDRESS1 = 'store_details.store_address1';
export const STORE_ADDRESS2 = 'store_details.store_address2';
export const ABOUT_STORE_TITLE = 'store_details.about_store_title';
export const ABOUT_STORE_ONE = 'store_details.about_store_one';
export const ABOUT_STORE_TWO = 'store_details.about_store_two';
export const ABOUT_STORE_THREE = 'store_details.about_store_three';
export const FRANCHISE_BANNER_TITLE = 'franchise_banner.title';
export const FRANCHISE_BANNER_SUB_TITLE = 'franchise_banner.sub_title';
export const FRANCHISE_BANNER_TEXT_CONTAINER =
  'franchise_banner.text_container';
export const FRANCHISE_BANNER_ARIA_TITLE_LABEL = 'franchise_banner.title_label';
export const FRANCHISE_BANNER_ARIA_LABEL = 'franchise_banner.aria_label';
export const FRANCHISE_BANNER_BUTTON_LABEL =
  'franchise_banner.button_aria_label';
export const FRANCHISE_BANNER_SUB_TITLE_LABEL =
  'franchise_banner.sub_title_aria_label';
export const FRANCHISE_BANNER_BODY = 'franchise_banner.body';
export const FRANCHISE_BANNER_ARIA_BODY_LABEL =
  'franchise_banner.body_aria_label';
export const FRANCHISE_BANNER_BUTTON = 'franchise_banner.button';
export const FRANCHISE_BANNER_ALT_TEXT = 'franchise_banner.alt_text';
export const STORE_BANNER_ALT_TEXT = 'store_banner.alt_text';
export const STORE_BANNER_TITLE = 'store_banner.title';
export const STORE_BANNER_BODY = 'store_banner.body';
export const STORE_BANNER_BUTTON = 'store_banner.button';
export const SOCIAL_MEDIA_TITLE = 'social_media.title';
export const SOCIAL_MEDIA_BODY = 'social_media.body';
export const SOCIAL_INSTAGRAM_ARIA_LABEL = 'social.instagram.aria_label';
export const SOCIAL_FACEBOOK_ARIA_LABEL = 'social.facebook.aria_label';
export const SOCIAL_TWITTER_ARIA_LABEL = 'social.twitter.aria_label';
export const SOCIAL_PINTEREST_ARIA_LABEL = 'social.pinterest.aria_label';
export const SOCIAL_YOUTUBE_ARIA_LABEL = 'social.youtube.aria_label';
export const CATEGORIES_TITLE = 'categories.title';
export const DISTANCE_AWAY = 'store_widget.icon.distance_away';
export const POPULAR_GIFT = 'store_details.popular_gift';
export const BLOG_CAROUSEL = 'store_details.blog_carousel';
export const STORE_DETAILS_META_SCHEMA_DESCRIPTION =
  'store_details.meta.schema_description';
export const STORE_DETAILS_ADDRESS1 = 'store_details.address1';
export const STORE_DETAILS_ADDRESS2 = 'store_details.address1_and_address2';
export const STORE_DETAILS_META_TITLE = 'store_details.meta.title';
export const STORE_DETAILS_META_DESCRIPTION = 'store_details.meta.description';
