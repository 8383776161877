import { ToggleButton as MUIToggleButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { icon } from 'components/common/ToggleButton/toggle-button.styles';
import React, { ComponentProps, FC } from 'react';

type Icon = OverridableComponent<SvgIconTypeMap>;

interface Props extends ComponentProps<typeof MUIToggleButton> {
  LeftIcon?: Icon;
  RightIcon?: Icon;
}

export const ToggleButton: FC<Props> = ({
  children,
  LeftIcon,
  RightIcon,
  ...props
}) => (
  <MUIToggleButton {...props}>
    {LeftIcon && <LeftIcon sx={{ ...icon, mr: 1 }} />}
    {children}
    {RightIcon && <RightIcon sx={{ ...icon, ml: 1 }} />}
  </MUIToggleButton>
);
