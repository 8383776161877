import * as TRANSLATION_KEYS from 'components/PLP/FilterSortMenu/translations/translation-keys.constant';

export const filterSortCommonLocale = {
  [TRANSLATION_KEYS.SORT_RELEVANCE]: 'Relevance',
  [TRANSLATION_KEYS.SORT_PRICE_HIGH2LOW]: 'Price: High to Low',
  [TRANSLATION_KEYS.SORT_PRICE_LOW2HIGH]: 'Price: Low to High',
  [TRANSLATION_KEYS.FILTER_DRAWER_TITLE_MOBILE]: 'Filter By',
  [TRANSLATION_KEYS.FILTERS_APPLY]: 'Apply',
  [TRANSLATION_KEYS.FILTERS_FILTER]: 'Filter',
  [TRANSLATION_KEYS.FILTERS_FILTERS]: 'Filters',
  [TRANSLATION_KEYS.PLP_FILTER_ERROR_FILTER_IS_NOT_AVAILABLE]:
    'Filters is not available',
  [TRANSLATION_KEYS.FILTERS_CLEAR_ALL]: 'Clear all filters',
};
