import * as styles from 'components/common/ArrangementItem/styles';
import { ArrangementPreview } from 'api/arrangements/types';
import {
  ArrangementWrapper,
  ItemImageWrapper,
  Name,
  ProductTextWrapper,
} from 'components/common/ArrangementItem/styled';
import { Image } from 'components/common/Image/Image';
import { ImageSkeleton } from 'components/common/ArrangementItem/components/ImageSkeleton';
import { Typography } from '@mui/material';
import { addCurrency } from 'utils/add-currency';
import { getSizeDescription } from 'utils/get-size-description';
import { promo } from 'components/common/ArrangementItem/styles';
import { updateAssetUrls } from 'utils/assets-url-builder';
import React, { FC } from 'react';
import parse from 'html-react-parser';

type Props = {
  arrangement: ArrangementPreview;
};

export const ArrangementItem: FC<Props> = ({ arrangement }) => {
  const salepriceColorcheck =
    arrangement.salepriceColor.length < 4
      ? '#FF3300'
      : arrangement.salepriceColor;

  const updatedAssetUrl = updateAssetUrls(arrangement.image);
  const updatedAssetUrlThumbnail = updateAssetUrls(arrangement.thumbnail);

  return (
    <ArrangementWrapper>
      <ItemImageWrapper>
        <Image
          sx={styles.itemImage}
          src={updatedAssetUrl}
          srcSet={{
            xs: {
              url: updatedAssetUrl,
              width: 400,
            },
            sm: {
              url: updatedAssetUrl,
              width: 400,
            },
            md: {
              url: updatedAssetUrlThumbnail,
              width: 200,
            },
          }}
          errorFallback={arrangement.image}
          alt={arrangement.alt}
          animationDuration={250}
          lazy
          observerMargins="0px 0px 400px"
          loading={<ImageSkeleton />}
          data-test="plp-arrangement-item-image"
        />
        {arrangement.productImageTag && (
          <Typography
            sx={styles.productImageTagText}
            component="p"
            variant="body1"
          >
            {arrangement.productImageTag}
          </Typography>
        )}
      </ItemImageWrapper>
      <ProductTextWrapper>
        {arrangement.nonPromo && (
          <Typography
            sx={promo(arrangement.nonPromoColor)}
            component="p"
            variant="subtitle3"
          >
            {arrangement.nonPromo}
          </Typography>
        )}
        <Name
          variant="h2"
          sx={{ fontSize: 14, fontWeight: 600, lineHeight: '143%' }}
          data-test="plp-arrangement-item-title"
        >
          {parse(arrangement.name)}
        </Name>
        <Typography component="p" variant="body3" sx={styles.size}>
          {getSizeDescription(arrangement.sizeCount)}
        </Typography>
        <div style={{ display: 'flex' }}>
          {arrangement.isMinSizeOnSale &&
          arrangement.minsizeProductPrice > 1 ? (
            <Typography
              variant="subtitle2"
              component="p"
              mt={0.5}
              data-test="plp-arrangement-item-price"
              sx={styles.regularPrice}
            >
              {addCurrency(arrangement.minsizeProductPrice)}
            </Typography>
          ) : null}
          <Typography
            variant="subtitle2"
            mt={0.5}
            component="p"
            data-test="plp-arrangement-item-price"
            sx={styles.salePrice(
              arrangement.isMinSizeOnSale && arrangement.minsizeProductPrice > 1
                ? salepriceColorcheck
                : '',
            )}
          >
            {addCurrency(arrangement.minPrice.toFixed(2))}
          </Typography>
        </div>
        {arrangement.promo && (
          <Typography
            sx={promo(arrangement.promoColor)}
            component="p"
            variant="subtitle3"
          >
            {arrangement.promo}
          </Typography>
        )}
      </ProductTextWrapper>
    </ArrangementWrapper>
  );
};
