import { CaseOrderState } from 'redux/case/caseOrder/case-order.types';
import { ResponseError } from 'types/error.types';
import { createSlice } from '@reduxjs/toolkit';
import { find, save } from 'redux/case/caseOrder/case-order.action';

const initialState: CaseOrderState = {
  orderData: [],
  caseData: [],
  caseId: '',
  loading: false,
  error: null,
};

const caseOrderSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(find.pending, (state) => {
        state.loading = true;
      })
      .addCase(find.rejected, (state) => {
        state.loading = false;
      })
      .addCase(find.fulfilled, (state, { payload }) => {
        state.orderData = payload;
        state.loading = false;
      })
      .addCase(save.pending, (state) => {
        state.loading = true;
      })
      .addCase(save.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as ResponseError;
      })
      .addCase(save.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.caseData = payload.contactData;
        state.caseId = payload.data;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: caseOrderReducer } = caseOrderSlice;
