import { Button, alpha } from '@mui/material';
import { colors } from 'utils/theme/colors';
import { isIE } from 'utils/isIE';
import { theme } from 'utils/theme/theme';
import styled from '@emotion/styled/macro';

interface AddonItemProps {
  focused?: number | undefined;
  selected?: boolean;
}

export const AddonItem = styled(Button)<AddonItemProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.other.outlineBorder}`,
  boxShadow: 'none',
  cursor: 'pointer',
  boxSizing: 'border-box',
  width: 140,
  height: 138,
  [theme.breakpoints.up('xs')]: {
    borderColor: 'transparent',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 130,
    padding: theme.spacing(0.5),
    '& .MuiTypography-root': {
      width: isIE ? '100%' : 'auto',
      flex: '1 0 auto',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      fontSize: '12px',
      fontWeight: '400',
      textAlign: 'center',
    },
  },
  [theme.breakpoints.up('sm')]: {
    borderColor: 'transparent',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 140,
    padding: theme.spacing(0.5),
    '& .MuiTypography-root': {
      display: isIE ? 'block' : '-webkit-box',
      width: isIE ? '100%' : 'auto',
      flex: '1 0 auto',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },

  '&:hover': {
    borderColor: colors.greyBorder,
    backgroundColor: 'transparent',
    borderWidth: 1,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      '& .MuiTypography-root': {
        display: 'flex',
        overflow: 'visible',
        width: isIE ? '100%' : 'auto',
      },
    },
  },

  ...(props.selected && {
    padding: theme.spacing(0.5),
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    width: 140,
    height: 138,

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: 'transparent',
      overflow: 'hidden',
      display: 'inline-flex',
      [theme.breakpoints.up('sm')]: {
        '& .MuiTypography-root': {
          display: 'flex',
          overflow: 'visible',
          width: isIE ? '100%' : 'auto',
        },
      },
    },
    [theme.breakpoints.up('xs')]: {
      borderColor: theme.palette.primary.main,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: 130,
      '& .MuiTypography-root': {
        display: isIE ? 'block' : '-webkit-box',
        width: isIE ? '100%' : 'auto',
        flex: '1 0 auto',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'center',
      },
    },
    [theme.breakpoints.up('sm')]: {
      borderColor: theme.palette.primary.main,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: 140,
      '& .MuiTypography-root': {
        display: isIE ? 'block' : '-webkit-box',
        width: isIE ? '100%' : 'auto',
        flex: '1 0 auto',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
  }),

  ...(props.focused && {
    animation: `inViewElement 500ms ${theme.transitions.easing.easeInOut}`,
  }),

  '@keyframes inViewElement': {
    '0%': {
      backgroundColor: alpha(theme.palette.primary.main, 0.001),
    },
    '50%': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    '100%': {
      backgroundColor: alpha(theme.palette.primary.main, 0.001),
    },
  },
}));
