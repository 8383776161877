export const LOG_IN = 'log_in';
export const OR = 'or';
export const EMAIL = 'email';
export const MAIN_PAGE = 'main.page';
export const LOGO = 'logo';
export const ERROR = 'error';
export const RESULT = 'result';
export const SUBMITTING = 'submitting';
export const NO_RESULTS = 'no_results';
export const LOADING = 'loading';
export const WE_GOT_AN_ERROR = 'we_got_an_error';
export const DAY_OF_WEEK_MONDAY_LONG = 'day_of_week.monday_long';
export const DAY_OF_WEEK_TUESDAY_LONG = 'day_of_week.tuesday_long';
export const DAY_OF_WEEK_WEDNESDAY_LONG = 'day_of_week.wednesday_long';
export const DAY_OF_WEEK_THURSDAY_LONG = 'day_of_week.thursday_long';
export const DAY_OF_WEEK_FRIDAY_LONG = 'day_of_week.friday_long';
export const DAY_OF_WEEK_SATURDAY_LONG = 'day_of_week.saturday_long';
export const DAY_OF_WEEK_SUNDAY_LONG = 'day_of_week.sunday_long';
export const DAY_OF_WEEK_MONDAY_SHORT = 'day_of_week.monday_short';
export const DAY_OF_WEEK_TUESDAY_SHORT = 'day_of_week.tuesday_short';
export const DAY_OF_WEEK_WEDNESDAY_SHORT = 'day_of_week.wednesday_short';
export const DAY_OF_WEEK_THURSDAY_SHORT = 'day_of_week.thursday_short';
export const DAY_OF_WEEK_FRIDAY_SHORT = 'day_of_week.friday_short';
export const DAY_OF_WEEK_SATURDAY_SHORT = 'day_of_week.saturday_short';
export const DAY_OF_WEEK_SUNDAY_SHORT = 'day_of_week.sunday_short';
export const SUGGESTED_CALENDAR_TOP = 'pdp.suggested_calendar.top';
export const SUGGESTED_CALENDAR_LINK = 'pdp.suggested_calendar.link';
export const SUGGESTED_CALENDAR_BOTTOM = 'pdp.suggested_calendar.bottom';
export const STORE_TIMINGS = 'pas.store_timings';
export const STORE_IS_NOT_FOUND_FOR_ZIP =
  'pdp.main.error.store_not_found_for_zip';
export const STORE_IS_NOT_FOUND_FOR_ARRANGEMENT_FOR_ZIP =
  'pdp.main.error.store_not_found_for_arrangement_for_zip';
export const STORE_IS_NOT_AVAILABLE_FOR_DATE =
  'pdp.main.error.store_not_available_for_date';
export const CHOOSE_RECIPIENT = 'pdp.choose_recipient';
export const PDP_TITLE = 'pdp.title';
export const RECIPIENT_INFORMATION_ERROR_CAR_MODEL =
  'recipient_information.error_car_model';
export const RECIPIENT_INFORMATION_ERROR_CAR_COLOR =
  'recipient_information.error_car_color';
export const RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL =
  'recipient_information.error_car_model';
export const RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR =
  'recipient_information.error_car_color';
export const RECIPIENT_INFORMATION_ERROR_PICKUP_TIME =
  'recipient_information.error_pickup_time';
export const PICKUP_FORM_ERROR_PHONE =
  'recipient_information.error_pickup_phone';
export const NUMBER_OF_SIZES_ONE = 'arrangement_list_item.number_of_sizes.one';
export const NUMBER_OF_SIZES_TWO = 'arrangement_list_item.number_of_sizes.two';
export const NUMBER_OF_SIZES_THREE =
  'arrangement_list_item.number_of_sizes.three';
export const NUMBER_OF_SIZES_FOUR =
  'arrangement_list_item.number_of_sizes.four';
export const NUMBER_OF_SIZES_FIVE =
  'arrangement_list_item.number_of_sizes.five';
export const NUMBER_OF_SIZES_SIX = 'arrangement_list_item.number_of_sizes.six';
export const NUMBER_OF_SIZES_SEVEN =
  'arrangement_list_item.number_of_sizes.seven';
export const NUMBER_OF_SIZES_EIGHT =
  'arrangement_list_item.number_of_sizes.eight';
export const NUMBER_OF_SIZES_NINE =
  'arrangement_list_item.number_of_sizes.nine';
export const NUMBER_OF_SIZES_TEN = 'arrangement_list_item.number_of_sizes.ten';
export const NUMBER_OF_SIZES_STARTING_AT =
  'arrangement_list_item.number_of_sizes.starting_at';
export const NUMBER_OF_SIZES_NUMERIC_QUANTITY =
  'arrangement_list_item.number_of_sizes.sizes_starting_at';
export const NUMBER_OF_SIZES_NO_SIZES =
  'arrangement_list_item.number_of_sizes.no_sizes';
export const IMAGE_NOT_AVAILABLE = 'plp.image.not_available';
export const BACK = 'common.back';
export const CLOSE = 'close';
export const SUBMIT = 'submit';
export const SELECT_ADDRESS_TYPE =
  'recipient_information.validation.select_address_type';
export const ENTER_BUSINESS_NAME =
  'recipient_information.validation.enter_business_name';
export const ENTER_SCHOOL_NAME =
  'recipient_information.validation.enter_school_name';
export const ENTER_HOSPITAL_NAME =
  'recipient_information.validation.enter_hospital_name';
export const PAS_NETWORK_ERR = 'pas.network_err';
export const HEADER_USER_PROFILE_MENU_SIGN_OUT = 'header.user_profile.sign_out';
export const HEADER_USER_PROFILE_MENU_SIGN_UP = 'header.user_profile.sign_up';
export const HEADER_USER_PROFILE_ACCOUNT = 'header.account';
export const HEADER_USER_PROFILE_MY_REWARDS = 'header.user_profile.my_rewards';
export const HEADER_USER_PROFILE_FAQ = 'header.user_profile.faq_and_benefits';
export const HEADER_USER_PROFILE_MY_INFORMATION =
  'header.user_profile.my_information';
export const HEADER_USER_PROFILE_ORDER_HISTORY =
  'header.user_profile.order_history';
export const HEADER_USER_PROFILE_GIFT_REMINDERS =
  'header.user_profile.gift_reminders';
export const HEADER_USER_PROFILE_ADDRESS_BOOK =
  'header.user_profile.address_book';
export const HEADER_HELP = 'header.help';
export const HEADER_FIND_STORE = 'header.find_store';
export const HEADER_EDIBLE_REWARDS = 'header.edible_rewards';
export const HEADER_SHOPPING_CART = 'header.shopping_cart';
export const HEADER_HELP_TRACK_YOUR_ORDER = 'header.help_track_your_orders';
export const HEADER_HELP_CONTACT_US = 'header.help_contact_us';
export const HEADER_HELP_ALLERGY_SAFETY_INFORMATION =
  'header.help_allergy_safety_information';
export const HEADER_HELP_PROMOTION_TERMS_CONDITIONS =
  'header.help_promotion_terms_conditions';
export const HEADER_HELP_CERTIFIED_HAPPINESS =
  'header.help_certified_happiness';
export const HEADER_HELP_EDIBLE_GUARANTEE = 'header.help_edible_guarantee';
export const HEADER_GET_HELP = 'header.get_help';
export const HEADER_HELP_FAQS = 'header.help_faqs';
export const HEADER_HELP_PRODUCTS_POLICY = 'header.help_products_policy';
export const HEADER_STORES_FRANCHISE_OPPORTUNITIES =
  'header.stores.franchise_opportunities';
export const HEADER_STORES_EDIBLE_TREATS = 'header.stores.edible_treats';
export const HEADER_ABOUT_WHO_WE_ARE = 'header.about.who_we_are';
export const HEADER_ABOUT_OUR_MISSION = 'header.about.our_mission';
export const HEADER_ABOUT_OUR_HISTORY = 'header.about.our_history';
export const HEADER_ABOUT_FOUNDER = 'header.about.founder';
export const HEADER_ABOUT_CONTACT_US = 'header.about.contact_us';
export const HEADER_OTHER_CAREERS = 'header.other.careers';
export const HEADER_OTHER_PRESS = 'header.other.press';
export const HEADER_OTHER_BLOG = 'header.other.blog';
export const HEADER_NAV_CUSTOMER_SERVICE = 'header.nav.customer_service';
export const HEADER_NAV_STORES = 'header.nav.stores';
export const HEADER_NAV_ABOUT = 'header.nav.about';
export const HEADER_NAV_ABOUT_EDIBLE = 'header.nav.about_edible';
export const HEADER_NAV_OTHER = 'header.nav.other';
export const HEADER_NAV_MENU = 'header.nav.menu';
export const HEADER_NAV_MORE_LINKS = 'header.nav.more_links';
export const HEADER_NAV_LANGUAGE = 'header.nav.language';
export const SLIDER_NEXT = 'slider.next';
export const SLIDER_PREVIOUS = 'slider.previous';
export const SUBSCRIPTION_FOOTER_TITLE = 'subscription.title';
export const SUBSCRIPTION_SHOP_NOW = 'subscription.shop_now';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE =
  'subscription.terms_and_conditions.before';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND =
  'subscription.terms_and_conditions.between_and';
export const SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL =
  'subscription.email_form.button_label';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER =
  'subscription.terms_and_conditions.after';
export const SUBSCRIPTION_EMAIL_FORM_LABEL = 'subscription.email_form.label';
export const SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS =
  'subscription.email_form.terms_and_conditions';
export const NEW_SUBSCRIPTION_FIRST_POPUP_TITLE = 'subscription.popup_title';
export const NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT =
  'subscription.Join_our_email';
export const NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT =
  'subscription.Join_our_email_or_SMS';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT =
  'subscription.instant_savings';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT = 'subscription.instant';
export const NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT = 'subscription.savings';
export const NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT =
  'subscription.on_your_next_pickup';
export const SUBSCRIPTION_FIRST_POPUP_TITLE =
  'subscription.Something_You_ll_Love';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_VAL = 'subscription.15';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_PER = 'subscription.percent';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT = 'subscription.off';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_STAR = 'subscription.star';
export const SUBSCRIPTION_FIRST_POPUP_DESCRIPTION =
  'subscription.your_next_pickup_order';
export const SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE = 'subscription.sweet';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST =
  'subscription.thanks_for_signing_up';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST =
  'subscription.for_15_off';
export const SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE =
  'subscription.connect_with_us';
export const SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT =
  'subscription.order_a_freshly-crafted_gift';
export const SUBSCRIPTION_IMAGE_TITLE = 'subscription.delicious_fruit_design';
export const ERROR_PHONE = 'subscription.invalid_phone_number';
export const ERROR_EMAIL = 'subscription.invalid_email';
export const REQUIRED_EMAIL = 'subscription.email_is_required';
export const ERROR_AGREE = 'subscription.you_must_agree';
export const SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL =
  'subscription.email.address';
export const SUBSCRIPTION_FOOTER_FORM_SUBMIT = 'subscription.form.submit';
export const SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR =
  'subscription.form.email.error';
export const SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR =
  'subscription.signup.form.email.error';
export const SUBSCRIPTION_TERMS_OF_USE = 'subscription.form.terms.use';
export const SUBSCRIPTION_FORM_PHONE_LABEL = 'subscription.form.phone.label';
export const SUBSCRIPTION_FORM_PHONE_ERROR = 'subscription.form.phone.error';
export const SUBSCRIPTION_FORM_SIGNUP_PHONE_ERROR =
  'subscription.form.signup.phone.error';
export const SUBSCRIPTION_FORM_POLICY_LABEL = 'subscription.form.policy.label';
export const SUBSCRIPTION_FORM_POLICY_TOOLTIP =
  'subscription.form.policy.tooltip';
export const SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL =
  'subscription.form.policy.tooltip.label';
export const SUBSCRIPTION_FORM_POLICY_ERROR = 'subscription.form.policy.error';
export const SUBSCRIPTION_SIGNUP_POLICY_ERROR =
  'subscription.signup.form.policy.error';
export const ERROR_SPL_CHARS_BUSINESS_NAME =
  'recipient_information.validation.spl_chars_business_name';
export const ERROR_SPL_CHARS_SCHOOL_NAME =
  'recipient_information.validation.spl_chars_school_name';
export const ERROR_SPL_CHARS_HOSPITAL_NAME =
  'recipient_information.validation.spl_chars_hospital_name';
export const RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS =
  'recipient_information.error_car_model_spl_chars';
export const RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS =
  'recipient_information.error_car_color_spl_chars';
export const SOCIAL_LINKS_TITLE = 'social.links.title';
export const PAYMENT_OPTIONS_TITLE = 'payment.options.title';
export const NEAREST_STORE_TITLE = 'nearest.store.title';
export const NEAREST_STORE_TODAY_LABEL = 'nearest.store.today.label';
export const NEAREST_STORE_DIRECTIONS_LABEL = 'nearest.store.directions.label';
export const TERMS_OF_USE_LABEL = 'terms.of.use.label';
export const PRIVACY_POLICY_LABEL = 'privacy.policy.label';
export const CALIFORNIA_PRIVACY_RIGHTS_LABEL = 'california.privacy.label';
export const CORPORATE_PROMOTION_LABEL = 'corporate.promotion.label';
export const COOKIE_SETTINGS_LABEL = 'cookie.settings.label';
export const CORPORATE_COPYRIGHT_LABEL = 'corporate.copyright.label';
export const DESKTOP_SEARCH_PLACEHOLDER = 'desktop.search.placeholder';
export const SEARCH_HELPER_TEXT = 'search.helper.text';
export const SEARCH_SUGGESTED_RESULTS = 'suggested.results.text';
export const SEARCH_NO_SUGGESTIONS = 'search.no.suggestions.text';
export const SEARCHING_TEXT = 'searching.text';
export const ALL_RESULTS = 'all.results';
export const CHOOSE_VARIANT = 'choose.variant';
export const SINGLE_VARIANT = 'single.variant';
export const BLOG_CAROUSEL_TITLE = 'blog_carousel.title';
export const CANT_BEGIN_WITH_O_OR_1 = 'phone.cant_begin_with_0_or_1';
export const ENTER_ONE_PHONE_NUMBER = 'phone.enter_one_phone_number';
export const ENTER_ONE_VALID_PHONE_NUMBER =
  'phone.enter_one_valid_phone_number';
export const HOME_PAGE_VIEW_ALL = 'home_page.view_all';
export const HOME_PAGE_CAROUSEL_FORWARD_CONTROL =
  'home_page.carousel_forward_control';
export const HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL =
  'home_page.carousel_backward_control';
export const NEAREST_STORE_WEEKLY_HOURS = 'nearest_store.weekly_hours';
export const NEAREST_STORE_PHONE_CONTACT = 'nearest_store.phone_contact';
export const NEAREST_STORE_DIRECTIONS = 'nearest_store.directions';
export const HOME_PAGE_H1_HEADING = 'home_page.h1_heading';
export const FRANCHISE_BANNER_TITLE = 'home_page.franchise_banner.title';
export const FRANCHISE_BANNER_BODY = 'home_page.franchise_banner.body';
export const FRANCHISE_BANNER_BUTTON = 'home_page.franchise_banner.button';
export const POPULAR_CATEGORIES = 'home_page.popular_categories';
export const VIEW_DETAILS = 'product_listing.view_details';
export const HIDE_DETAILS = 'product_listing.hide_details';
export const EDIBLE_GUARANTEE_GET_HELP = 'edible_guarantee.get_help';
export const EDIBLE_GUARANTEE_TITLE = 'edible_guarantee.title';
export const EDIBLE_GUARANTEE_DESCRIPTION = 'edible_guarantee.description';
export const EDIBLE_GUARANTEE_POLICY = 'edible_guarantee.policy';
export const EDIBLE_GUARANTEE_HASSLEFREE = 'edible_guarantee.hassle_free';
export const EDIBLE_GUARANTEE_SATISFACTION_HEADER =
  'edible_guarantee.satisfaction_header';
export const EDIBLE_GUARANTEE_SUPPORT_HEADER =
  'edible_guarantee.support_header';
export const EDIBLE_GUARANTEE_STREAMLINED_HEADER =
  'edible_guarantee.streamlined_header';
export const EDIBLE_GUARANTEE_SATISFACTION = 'edible_guarantee.satisfaction';
export const EDIBLE_GUARANTEE_SUPPORT = 'edible_guarantee.support';
export const EDIBLE_GUARANTEE_STREAMLINED = 'edible_guarantee.streamlined';
export const ALERT_OWN_BOX_ADDONS = 'alert.own_box_addons';
export const ALERT_PRODUCT_OPTION = 'alert.product_option';
export const ALERT_AVAILABILITY_ZIP = 'alert.availability_zip';
export const ALERT_AVAILABILITY_DATE = 'alert.availability_date';
export const ALERT_AVAILABILITY_DELIVERY_METHOD =
  'alert.availability_delivery_method';
export const ALERT_AVAILABILITY_STORE = 'alert.availability_store';
export const ALERT_SUGGESTED_DATE = 'alert.suggested_date';
export const ALERT_SUGGESTED_PRODUCT = 'alert.suggested_product';
export const ALERT_DESSERT_OWN_BOX_ADDONS = 'alert.dessert_own_box_addons';
export const ALERT_6_COOKIE_BOX_ADDONS = 'alert.6_cookie_box_addons';
export const ALERT_12_COOKIE_BOX_ADDONS = 'alert.12_cookie_box_addons';
export const PICKUP = 'pdp.pickup';
export const DELIVERY = 'pdp.delivery';
export const MENU_TITLE = 'pdp.menu_title';
export const GUIDE = 'pdp.guide';
export const NEW_RECIPIENT_SUBTITLE = 'pdp.new_recipient_subtitle';
export const SWITCH_RECIPIENT = 'pdp.switch_recipient';
export const DELIVERY_FROM = 'pdp.delivery_from';
export const DELIVERY_FOR = 'pdp.delivery_for';
export const PICKUP_FROM = 'pdp.pickup_from';
export const PICKUP_FOR = 'pdp.pickup_for';
export const SHIPMENT_SERVICED_ON = 'pdp.shipment_serviced_on';
export const NEW_RECIPIENT = 'pdp.new_recipient';
export const PICK_UP = 'pdp.pick_up';
export const DELIVERY_DESCRIPTION = 'pdp.delivery_description';
export const AVAILABLE_FOR_OTHER_DATES = 'pdp.available_for_other_dates';
export const CHOOSE_ANOTHER_DATE = 'pdp.choose_another_date';
export const AVAILABLE_FOR_OTHER_PRODUCTS = 'pdp.available_for_other_products';
export const OTHER_PRODUCTS_AVAILABLE = 'pdp.other_products_available';
export const DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES =
  'pdp.delivery_not_available_for_date_suggest_dates';
export const DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS =
  'pdp.delivery_not_available_for_date_suggest_products';
export const PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES =
  'pdp.pickup_not_available_for_date_suggest_dates';
export const PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS =
  'pdp.pickup_not_available_for_date_suggest_products';
export const FREE = 'pdp.free';
export const PICKUP_IN_STORE = 'pdp.pickup_in_store';
export const PICKUP_DESCRIPTION = 'pdp.pickup_description';
export const NO_APPROPRIATE_DATE = 'pdp.no_appropriate_date';
export const EXPLORE_AVAILABLE_PRODUCTS = 'pdp.explore_available_products';
export const SEE_AVAILABLE_PRODUCTS = 'pdp.see_available_products';
export const FLEX_FREE = 'pdp.flex_free';
export const FLEX_NEXT_DAY_PRICE = 'pdp.flex_next_day_price';
export const FLEX_DATES_AVAILABLE = 'pdp.flex_dates_available';
export const FLEX_DELIVERY_DATES = 'pdp.flex_delivery_dates';
export const FLEX_DELIVERY_DATES_SUBTITLE = 'pdp.flex_delivery_dates_subtitle';
export const FLEX_DATES_AVAILABLE_SUBTITLE =
  'pdp.flex_dates_available_subtitle';
export const CHOOSE_ANOTHER_DATE_DELIVERY = 'pdp.choose_another_date_delivery';
export const DISCLAIMER_MESSAGE = 'pdp.disclaimer_message';
export const DISCLAIMER_MESSAGE_KMS = 'pdp.disclaimer_message_kms';
export const CURBSIDE = 'pdp.curbside';
export const DISTANCE = 'pdp.distance';
export const KOSHER = 'pdp.kosher';
export const MILES = 'pdp.miles';
export const KILOMETERS = 'pdp.kilometers';
export const KILOMETERS_TEXT = 'pdp.kilometers_text';
export const SMOOTHIE = 'pdp.smoothie';
export const TITLE_PICKUP = 'pdp.title.pickup';
export const TITLE_DELIVERY = 'pdp.title.delivery';
export const SHOW_MORE = 'pdp.show_more';
export const MONDAY_FULL = 'pdp.monday.full';
export const TUESDAY_FULL = 'pdp.tuesday.full';
export const WEDNESDAY_FULL = 'pdp.wednesday.full';
export const THURSDAY_FULL = 'pdp.thursday.full';
export const FRIDAY_FULL = 'pdp.friday.full';
export const SATURDAY_FULL = 'pdp.saturday.full';
export const SUNDAY_FULL = 'pdp.sunday.full';
export const MONDAY_SHORT = 'pdp.monday.short';
export const TUESDAY_SHORT = 'pdp.tuesday.short';
export const WEDNESDAY_SHORT = 'pdp.wednesday.short';
export const THURSDAY_SHORT = 'pdp.thursday.short';
export const FRIDAY_SHORT = 'pdp.friday.short';
export const SATURDAY_SHORT = 'pdp.saturday.short';
export const SUNDAY_SHORT = 'pdp.sunday.short';
export const ERROR_NO_STORES = 'pdp.error.no_stores';
export const ERROR_DELIVERY_CUTOFF_PASSED = 'pdp.error.delivery_cutoff_passed';
export const ERROR_PICKUP_CUTOFF_PASSED = 'pdp.error.pickup_cutoff_passed';
export const ERROR_PICKUP_DATE_IN_PAST = 'pdp.error.pickup_date_in_past';
export const ERROR_SAME_DAY_SHIPMENT = 'pdp.error.same_day_shipment';
export const ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED =
  'pdp.error.next_day_shipment_cutoff_passed';
export const ERROR_SIZE_IS_NOT_AVAILABLE = 'pdp.error.size_is_not_available';
export const ERROR_STORE_IS_CLOSED = 'pdp.error.store_is_closed';
export const ERROR_PICKUP_CUTOFF_DATE = 'pdp.error.pickup_cutoff_date';
export const ERROR_BEFORE_MINIMUM_PICKUP_TIME =
  'pdp.error.before_minimum_pickup_time';
export const ERROR_SERVICING_STORE_CLOSED = 'pdp.error.servicing_store_closed';
export const ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER =
  'pdp.error.servicing_store_cannot_fulfill_order';
export const ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE =
  'pdp.error.delivery_is_not_available_for_store_on_date';
export const ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE =
  'pdp.error.pickup_is_not_available_for_store_on_date';
export const SHOW_MORE_REVIEW = 'pdp.show_more_review';
export const SHOW_MORE_REVIEWS = 'pdp.show_more_reviews';
export const PRODUCT_NO_REVIEW = 'pdp.product_no_review';
export const PRODUCT_REVIEW = 'pdp.product_review';
export const PRODUCT_REVIEWS = 'pdp.product_reviews';
export const PRODUCT_DESCRIPTION = 'pdp.product_description';
export const PRODUCT_INGREDIENTS = 'pdp.product_ingredients';
export const QUESTIONS = 'pdp.questions';
export const COUPONS_APPLICABLE_TITLE = 'pdp.coupons_applicable_title';
export const ALLERGY_WARNING_TITLE = 'pdp.allergy_warning_title';
export const DISCLAIMER_TITLE = 'pdp.disclaimer_title';
export const SHOW_MORE_DELIVERY_STORES = 'pdp.show_more_delivery_stores';
export const WHEN = 'pdp.date_selection_when';
export const TODAY = 'pdp.date_selection_today';
export const TOMORROW = 'pdp.date_selection_tomorrow';
export const DATE_PICKER = 'pdp.date_selection_date_picker';
export const UPSELLS_DECREASE_QUANTITY = 'pdp.decrease_quantity';
export const UPSELLS_INCREASE_QUANTITY = 'pdp.increase_quantity';
export const UPSELLS_REMOVE = 'pdp.remove';
export const UPSELLS_ADD = 'pdp.add';
export const UPSELLS_CONTINUE = 'pdp.continue';
export const UPSELLS_SAVE_SELECTION = 'pdp.save_selections';
export const UPSELLS_TITLE = 'pdp.upgrade_your_gift';
export const UPSELLS_OPTIONS_SINGLE = 'pdp.single';
export const UPSELLS_OPTIONS_OPTION = 'pdp.option';
export const UPSELL_OPTIONS_STARTING_AT = 'pdp.options_starting_at';
export const UPSELL_OPTIONS_SHOW_ALL = 'pdp.show_all';
export const UPSELL_OPTIONS_EXPANDED_SINGLE = 'pdp.single_option';
export const UPSELL_OPTIONS_EXPANDED_SELECT_ONE = 'pdp.select_only_one_option';
export const UPSELL_OPTIONS_EXPANDED_SELECT_MULTIPLE = 'pdp.select_options';
export const UPSELL_OPTIONS_EXPANDED_SHOW_LESS = 'pdp.show_less';
export const UPSELL_OPTIONS_WARNING = 'pdp.upsell_warning';
export const UPSELL_OPTIONS_WARNING_DESIGN_VARY = 'pdp.design_may_vary';
export const UPSELL_OPTIONS_NOTICE = 'pdp.upsell_notice';
export const UPSELL_SHOW_MORE = 'pdp.show_more';
export const UPSELL_SHOWING = 'pdp.showing';
export const UPSELL_SHOW = 'pdp.show_more';
export const UPSELL_OPTIONS_PRODUCT_BADGE_LABEL = 'pdp.your_gift';
export const SAVE = 'pdp.save';
export const ADD = 'pdp.add';
export const CANCEL = 'pdp.cancel';
export const LETTERS_OPTION_TITLE = 'pdp.letters_option_title';
export const LETTERS_OPTION_MODAL_TITLE = 'pdp.letters_option_modal_title';
export const LETTERS_OPTION_EXAMPLE = 'pdp.letters_option_example';
export const LETTERS_OPTION_WARN = 'pdp.letters_option_warn';
export const LETTERS_INPUT_LABEL = 'pdp.letters_input_label';
export const NUMBERS_OPTION_TITLE = 'pdp.numbers_option_title';
export const NUMBERS_OPTION_MODAL_TITLE = 'pdp.numbers_option_modal_title';
export const NUMBERS_OPTION_EXAMPLE = 'pdp.numbers_option_example';
export const NUMBERS_OPTION_WARN = 'pdp.numbers_option_warn';
export const NUMBERS_INPUT_LABEL = 'pdp.numbers_input_label';
export const YEAR_OPTION_TITLE = 'pdp.year_option_title';
export const YEAR_OPTION_MODAL_TITLE = 'pdp.year_option_modal_title';
export const YEAR_OPTION_EXAMPLE = 'pdp.year_option_example';
export const YEAR_OPTION_WARN = 'pdp.year_option_warn';
export const YEAR_INPUT_LABEL = 'pdp.year_input_label';
export const EMPTY_VALUE_VALIDATION_ERROR_MESSAGE =
  'pdp.empty_value_validation_error_message';
export const CENSORED_WORD_VALIDATION_ERROR_MESSAGE =
  'pdp.censored_word_validation_error_message';
export const SWITCH_TO_THIS_OPTION = 'pdp.switch_to_this_option';
export const MAXIMUM_3_CHARACTERS = 'pdp.maximum_3_characters';
export const MAXIMUM_5_CHARACTERS = 'pdp.maximum_5_characters';
export const ONLY_NUMBERS_ALLOWED = 'pdp.only_numbers_allowed';
export const MUST_BE_4_CHARACTERS_LONG = 'pdp.must_be_4_characters_long';
export const ONLY_UPPERCASE_LETTERS_ALLOWED =
  'pdp.only_uppercase_letters_allowed';
export const PDP_RECIPIENT_NAME = 'recipient_form.recipient_name';
export const PDP_REQUIRED_FIELD = 'recipient_form.required_field';
export const PDP_FIRST_NAME_PLACEHOLDER =
  'recipient_form.first_name_placeholder';
export const PDP_FIRST_NAME_TEXT = 'recipient_form.first_name_text';
export const PDP_FIRST_NAME_TEXT_PICKUP =
  'recipient_form.first_name_text_pickup';
export const PDP_LAST_NAME_PLACEHOLDER = 'recipient_form.last_name_placeholder';
export const PDP_ADDRESS_TITLE = 'recipient_form.address_title';
export const PDP_ADDRESS_SUBTITLE = 'recipient_form.address_sub_title';
export const PDP_ADDRESS_TYPE_LABEL = 'recipient_form.address_type_label';
export const PDP_BUSINESS_NAME_LABEL = 'recipient_form.business_name_label';
export const PDP_CITY_LABEL = 'recipient_form.city_label';
export const PDP_PO_BOX_NOTE = 'recipient_form.po_box_note';
export const PDP_STREET_ADDRESS = 'recipient_form.street_address';
export const PDP_APT_FLOOR_SUITE_BUTTON =
  'recipient_form.apt_floor_suite_button';
export const PDP_APT_FLOOR_SUITE_LABEL = 'recipient_form.apt_floor_suite_label';
export const PDP_BUSINESS_WARNING = 'recipient_form.business_warning';
export const PDP_CHOOSE_OPTION = 'recipient_form.choose_option';
export const PDP_PICKUP_TIME = 'recipient_form.pickup_time';
export const PDP_ENTER_FIRST_NAME = 'recipient_form.enter_first_name';
export const PDP_ENTER_LAST_NAME = 'recipient_form.enter_last_name';
export const PDP_ERROR_ALERT_ADD_TO_CART_HEADING =
  'recipient_form.error_modal.add_to_cart.heading';
export const PDP_ERROR_ALERT_ADD_TO_CART_BODY =
  'recipient_form.error_modal.add_to_cart.body';
export const PDP_ERROR_PICKUP_IN_PAST =
  'recipient_form.error_modal.pickup_in_past';
export const PDP_ERROR_CHOOSE_SAME_DAY_PICKUP_TIME_AFTER =
  'recipient_form.error_modal.choose_same_day_pickup_time_after';
export const PDP_ERROR_CHOOSE_PICKUP_TIME_AFTER =
  'recipient_form.error_modal.choose_pickup_time_after';
export const PDP_ERROR_NEXT_DAY_SHIPMENT_CUTOFF =
  'recipient_form.error_modal.next_day_shipment_cutoff';
export const PDP_ERROR_NO_SERVICE_OPTION =
  'recipient_form.error_modal.no_service_option';
export const PDP_ERROR_SERVICE_OPTION_STORE =
  'recipient_form.error_modal.service_option_store';
export const PDP_ERROR_DELIVERY_NOT_AVAILABLE_ON_DATE_FOR_STORE =
  'recipient_form.error_modal.delivery_not_available_on_date_for_store';
export const PDP_ERROR_PICKUP_NOT_AVAILABLE_ON_DATE_FOR_STORE =
  'recipient_form.error_modal.pickup_not_available_on_date_for_store';
export const PDP_ERROR_ORDER_DATE_IN_PAST =
  'recipient_form.error_modal.order_date_in_past';
export const PDP_ERROR_STORE_CANNOT_FULFILL_ON_DATE =
  'recipient_form.error_modal.store_cannot_fulfill_on_date';
export const PDP_ERROR_STORE_IS_CLOSED_ON_DATE =
  'recipient_form.error_modal.store_closed_on_date';
export const PDP_ERROR_UNCOMPLETED_REQUIRED_FIELDS =
  'recipient_form.error_modal.uncompleted_required_fields';
export const PDP_ERROR_FIRST_NAME_SPL_CHARS =
  'recipient_form.error_first_name_spl_chars';
export const PDP_ERROR_LAST_NAME_SPL_CHARS =
  'recipient_form.error_last_name_spl_chars';
export const PDP_ERROR_STREET_ADDRESS_SPL_CHARS =
  'recipient_form.error_street_address_spl_chars';
export const PDP_ERROR_REQUIRED = 'recipient_form.error_required';
export const PDP_ERROR_NAME_SPL_CHARS = 'recipient_form.error_name_spl_chars';
export const PDP_ERROR_OCCASION_ID = 'recipient_form.error_occasion_id';
export const PICKUP_DATE_TIME_ALERT_ON = 'pickup_form.date_time.alert.on';
export const PICKUP_DATE_TIME_HEADER = 'pickup_form.date_time.header';
export const PICKUP_DATE_TIME_LABEL = 'pickup_form.date_time.label';
export const PICKUP_FORM_IDENTIFICATION = 'pickup_form.identification';
export const PICKUP_PHONE_LABEL = 'pickup_form.phone.label';
export const PICKUP_PHONE = 'pickup_form.phone';
export const PICKUP_FORM_CURBSIDE_SWITCH_LABEL =
  'pickup_form.curbside.switch.label';
export const PICKUP_CURBSIDE_MODEL_LABEL = 'pickup_form.curbside.model.label';
export const PICKUP_CURBSIDE_COLOR_LABEL = 'pickup_form.curbside.color.label';
export const PICKUP_INSTRUCTION_BUTTON = 'pickup_form.instruction.button';
export const PICKUP_INSTRUCTIONS_HEADER = 'pickup_form.instruction.header';
export const PICKUP_INSTRUCTIONS_LABEL = 'pickup_form.instruction.input.label';
export const PICKUP_DETAILS = 'pickup_form.pickup.details';
export const PICKUP_CONTACT_DETAILS = 'pickup_form.pickup.contact.details';
export const DELIVERY_INSTRUCTIONS = 'instructions.delivery_instructions';
export const READ_OUR_DELIVERY_POLICIES =
  'instructions.read_our_delivery_policies';
export const DELIVERY_INSTRUCTIONS_LABEL =
  'instructions.delivery_instructions_label';
export const DELIVERY_INSTRUCTIONS_PLACEHOLDER =
  'instructions.delivery_instructions_placeholder';
export const PICKUP_INSTRUCTIONS = 'instructions.pickup_instructions';
export const PICKUP_INSTRUCTIONS_PLACEHOLDER =
  'instructions.pickup_instructions_placeholder';
export const COMPLIMENTARY_GIFT_MESSAGE =
  'greetings_cards.complimentary_gift_message';
export const COMPLIMENTARY_MESSAGE_TITLE =
  'greetings_cards.complimentary_message.title';
export const REMOVE_GIFT_MESSAGE = 'greetings_cards.remove_card_message';
export const GIFT_MESSAGE_FREE_EXPLAIN =
  'greetings_cards.gift_message_free_explain';
export const HEARTFELT_MESSAGE = 'greetings_cards.heartfelt_message';
export const TOP_PICK = 'greetings_cards.top_pick';
export const OPTIONAL = 'greetings_cards.optional';
export const PRINTIBLE_SUBTITLE = 'greetings_cards.printible_subtitle';
export const PRINTIBLE_OLD_PRICE_TEXT =
  'greetings_cards.printible_old_price_text';
export const PRINTIBLE_PRICE_SUBTEXT =
  'greetings_cards.printible_price_subtext';
export const PREMIUM_CARD = 'greetings_cards.premium_card';
export const NO_GIFT_MESSAGE = 'greetings_cards.no_gift_message';
export const SELECT_NEW_CARD = 'greetings_cards.select_new_card';

export const REMOVE_CARD = 'greetings_cards.remove_card';
export const GIFT_MESSAGE_FREE_HINT = 'greetings_cards.gift_message_free_hint';
export const ENTER_CARD_MESSAGE = 'greetings_cards.enter_card_message';
export const PRINTIBLE_CARD_PREVIEW = 'greetings_cards.card_preview';
export const PRINTIBLE_APP = 'greetings_cards.printible_app';
export const PRINTIBLE_IFRAME_ERROR = 'greetings_cards.printible_iframe_error';
export const PRINTIBLE_PRICE_TEXT = 'greetings_cards.printible_price_text';
export const COMPLIMENTARY_CARD = 'greetings_cards.complimentary_card';
export const PRINTIBLE_CARDS = 'greetings_cards.printible_cards';
export const GIFT_CARD_MODAL_TITLE = 'gift_card_modal.title';
export const GIFT_CARD_MODAL_SUB_TITLE = 'gift_card_modal.sub_title';
export const GIFT_CARD_MODAL_GIFT_MESSAGE = 'gift_card_modal.gift_message';
export const GIFT_CARD_MODAL_KEEPSAKE_CHERISH =
  'gift_card_modal.keepsake_cherish';
export const GIFT_CARD_MODAL_YEAR_COME = 'gift_card_modal.year_come';
export const WRITE_FOR_ME_NAME = 'write_for_me.name';
export const ANANYMOUSLY_SWITCH_LABEL = 'write_for_me.ananymously.switch.label';
export const WRITE_FOR_ME_NAME_NOTE = 'write_for_me.name.note';
export const WRITE_FOR_ME_CREATE_BUTTON = 'write_for_me.create_button';
export const WRITE_FOR_ME_START_NEW_BUTTON = 'write_for_me.start_new_button';
export const GIFT_MESSAGE_TABS_LABEL = 'write_for_me.tabs_label';
export const GIFT_MESSAGE_FIRST_TAB_LABEL = 'write_for_me.first_tab_label';
export const GIFT_MESSAGE_SECOND_TAB_LABEL = 'write_for_me.second_tab_label';
export const OWN_DESSERT_BOARD_BAKED = 'pdp.alert.dessert_board_baked';
export const OWN_DESSERT_BOARD_DIPPED_BERRIES =
  'pdp.alert.dessert_board_dipped_berries';
export const OWN_DESSERT_BOARD_DIPPED_FRUIT =
  'pdp.alert.dessert_board_dipped_fruit';
export const TITLE = 'pdp.alert.title';
export const AREA = 'area.area';
export const POSTAL_CODE = 'area.postal_code';
export const CHANGE_POSTAL_CODE = 'area.change_postal_code';
export const STATE = 'area.state';
export const PROVINCE = 'area.province';
export const CITY = 'area.city';
export const CHANGE_AREA = 'area.change_area';
export const CHANGE_AREA_INFO = 'area.change_area_info';
export const CHANGE_POSTAL_CODE_INFO = 'area.change_postal_code_info';
export const CART_POSTAL_CODE_INFO = 'area.cart_postal_code_info';
export const CHANGE_ZIP_TITLE = 'area.change_zip_title';
export const CHANGE_ZIP_DESCRIPTION = 'area.change_zip_description';
export const CHANGE_BACK_BUTTON = 'area.change_back_button';
export const CHANGE_ZIP_BUTTON = 'area.change_zip_button';
export const CHANGE_POSTAL_BUTTON = 'area.change_zip_button';
export const CHANGE_POSTAL_TITLE = 'area.change_zip_title';
export const CHANGE_POSTAL_DESCRIPTION = 'area.change_zip_description';
export const PRODUCT_FAQ = 'pdp.accordion.faq';
export const BRAND_VALUE = 'pdp.brand_value';
export const STORES_NEAR_YOU = 'store_finder_page.stores_near_you';
export const STORE_NEAR_YOU = 'store_finder_page.store_near_you';
export const CONCERNED_ABOUT_PRIVACY =
  'store_finder_page.concerned_about_privacy';
export const LOAD_MORE_STORES_BUTTON_TEXT =
  'store_finder_page.load_more_button_text';
export const PRIVACY_POLICY = 'store_finder_page.privacy_policy';
export const TERMS_OF_USE = 'store_finder_page.terms_of_use';
export const LOCATION_SERVICES_OFF = 'store_finder_page.location_services_off';
export const TURN_ON_LOCATION_SERVICES =
  'store_finder_page.turn_on_location_services';
export const STORE_SHOWING_AMOUNT_DISPLAY_TEXT =
  'store_finder_page.stores_showing_amount_display_text';

export const CUSTOM_GREETING_CARDS = 'store_finder_page.custom_greeting_cards';
export const APPLY = 'store_finder_page.apply';
export const CLEAR_ALL_FILTERS = 'store_finder_page.clear_all_filters';
export const ERROR_MESSAGE_OPEN_YOUR_OWN_STORE =
  'store_finder_page.error_message.open_your_own_store';
export const ERROR_MESSAGE_OH_NO = 'store_finder_page.error_message.oh_no';
export const ERROR_MESSAGE_DIFFERENT_SEARCH_AND_FILTER =
  'store_finder_page.error_message.different_search_and_filter';
export const ERROR_MESSAGE_DIFFERENT_SEARCH =
  'store_finder_page.error_message.try_different_search_value';
export const ERROR_MESSAGE_WE_SHIP_TEXT =
  'store_finder_page.error_message.we_ship_text';
export const ERROR_MESSAGE_SEARCH_AND_FILTER_CRITERIA =
  'store_finder_page.error_message.no_criteria_match';
export const ERROR_MESSAGE_NEW_STORES_TEXT =
  'store_finder_page.error_message.new_stores_text';
export const ERROR_MESSAGE_OPEN_STORE_TEXT =
  'store_finder_page.error_message.open_stores_text';
export const DISTANCE_FILTER_OPTION = 'store_finder_page.distance_filter';
export const DISTANCE_TEXT = 'store_finder_page.distance_text';
export const SERVICES_OFFERED_TEXT = 'store_finder_page.service_offered_text';
export const FILTERS_TEXT = 'store_finder_page.filters_text';
export const MOBILE_FILTER_TEXT = 'store_finder_page.mobile_filters_text';
export const STORE_FINDER_HOME = 'store_finder_page.home';
export const FIND_A_STORE = 'store_finder_page.find_a_store';
export const STORE_FINDER_TITLE = 'store_finder_page.title';
export const STORE_FINDER_DESCRIPTION = 'store_finder_page.description';
export const STORE_FINDER_PLACEHOLDER = 'store_finder_page.placeholder';
export const STORE_FINDER_PLACEHOLDER_CA = 'store_finder_page.placeholder_ca';
export const STORE_FINDER_LOCATION = 'store_finder_page.location_text';
export const STORE_FINDER_CHOOSE_FROM_LIST =
  'store_finder_page.choose_from_list_text';
export const STORE_FINDER_VALIDATION_ERROR =
  'store_finder_page.validation_error';
export const STORE_FINDER_VALIDATION_ERROR_CA =
  'store_finder_page.validation_error_ca';
export const INPUT_ADORNMENT = 'store_finder_page.input_adornment';
export const INPUT_ADORNMENT_CA = 'store_finder_page.input_adornment_ca';
export const STORE_FINDER_BUTTON = 'store_finder_page.button';
export const STORE_FINDER_BUTTON_LOADING_TEXT =
  'store_finder_page.button_loading_text';
export const STORE_FINDER_FRANCHISE_HEAD = 'store_finder_page.franchise_head';
export const STORE_FINDER_FRANCHISE_SUBTITLE =
  'store_finder_page.franchise_sub_title';
export const STORE_FINDER_FRANCHISE_DESCRIPTION =
  'store_finder_page.franchise_description';
export const LEARN_MORE_STORES = 'store_finder_page.learn_more';
export const LOAD_MORE_STORES_ARIA_TEXT =
  'store_finder_page.load_more_stores_aria_text';
export const OPEN_FRANCHISE_LEARN_MORE_BUTTON =
  'store_finder_page.open_your_own_franchise_learn_more_button';
export const OPEN_FRANCHISE_BANNER =
  'store_finder_page.open_your_own_franchise_banner';
export const INVALID_SEARCH_TEXT = 'store_finder_page.invalid_search_text';
export const COMING_SOON_STORE_TEXT =
  'store_finder_page.coming_soon_store_text';
export const CALL_EDIBLE_STORE = 'store_finder_page.call_edible_store';
export const EMAIL_EDIBLE_STORE = 'store_finder_page.email_edible_store';
export const CONTACT_US = 'store_finder_page.email_contact_us';
export const GET_DIRECTIONS = 'store_finder_page.get_edible_store_directions';
export const STORE_FINDER_PAGE_META_TAG_TITLE =
  'store_finder_page.seo_page_title';
export const STORE_FINDER_PAGE_META_TAG_DESCRIPTION =
  'store_finder_page.seo_page_description';
export const MARKETPLACE_ZIP_CODE_LABEL =
  'recipient_form.marketplace_zip_code_label';
export const MARKETPLACE_STATE_LABEL = 'recipient_form.marketplace_state_label';
export const MARKETPLACE_RECIPIENT_STATE_OTHER =
  'recipient_form.marketplace_state_other';
export const MARKETPLACE_STATE_SELECT =
  'recipient_form.marketplace_state_select';
export const MARKETPLACE_UNAVAILABLE_ALERT_TITLE =
  'recipient_form.marketplace_unavailable_title';
export const MARKETPLACE_UNAVAILABLE_ALERT_BODY =
  'marketplace_unavailable_body';
export const MARKETPLACE_UNAVAILABLE_ALERT_LINK =
  'marketplace_unavailable_link';
export const SERVICED_AREAS_TITLE = 'store_details.serviced_areas_title';
export const STORE_DETAILS_BREADCRUMB = 'store_details.breadcrumb';
export const STORE_ADDRESS1 = 'store_details.store_address1';
export const STORE_ADDRESS2 = 'store_details.store_address2';
export const ABOUT_STORE_TITLE = 'store_details.about_store_title';
export const ABOUT_STORE_ONE = 'store_details.about_store_one';
export const ABOUT_STORE_TWO = 'store_details.about_store_two';
export const ABOUT_STORE_THREE = 'store_details.about_store_three';
export const FRANCHISE_BANNER_SUB_TITLE = 'franchise_banner.sub_title';
export const FRANCHISE_BANNER_TEXT_CONTAINER =
  'franchise_banner.text_container';
export const FRANCHISE_BANNER_ARIA_TITLE_LABEL = 'franchise_banner.title_label';
export const FRANCHISE_BANNER_ARIA_LABEL = 'franchise_banner.aria_label';
export const FRANCHISE_BANNER_BUTTON_LABEL =
  'franchise_banner.button_aria_label';
export const FRANCHISE_BANNER_SUB_TITLE_LABEL =
  'franchise_banner.sub_title_aria_label';
export const FRANCHISE_BANNER_ARIA_BODY_LABEL =
  'franchise_banner.body_aria_label';
export const FRANCHISE_BANNER_ALT_TEXT = 'franchise_banner.alt_text';
export const STORE_BANNER_ALT_TEXT = 'store_banner.alt_text';
export const STORE_BANNER_TITLE = 'store_banner.title';
export const STORE_BANNER_BODY = 'store_banner.body';
export const STORE_BANNER_BUTTON = 'store_banner.button';
export const SOCIAL_MEDIA_TITLE = 'social_media.title';
export const SOCIAL_MEDIA_BODY = 'social_media.body';
export const SOCIAL_INSTAGRAM_ARIA_LABEL = 'social.instagram.aria_label';
export const SOCIAL_FACEBOOK_ARIA_LABEL = 'social.facebook.aria_label';
export const SOCIAL_TWITTER_ARIA_LABEL = 'social.twitter.aria_label';
export const SOCIAL_PINTEREST_ARIA_LABEL = 'social.pinterest.aria_label';
export const SOCIAL_YOUTUBE_ARIA_LABEL = 'social.youtube.aria_label';
export const CATEGORIES_TITLE = 'categories.title';
export const DISTANCE_AWAY = 'store_widget.icon.distance_away';
export const DISTANCE_METRIC = 'store_widget.icon.distance_metric';
export const POPULAR_GIFT = 'store_details.popular_gift';
export const POPULAR_GIFTS = 'store_details.popular_gifts';
export const TODAYS_STORE_HOURS = 'store_details.todays_store_hours';
export const HOURS = 'store_details.hours';
export const BLOG_CAROUSEL = 'store_details.blog_carousel';
export const STORE_DETAILS_META_SCHEMA_DESCRIPTION =
  'store_details.meta.schema_description';
export const STORE_DETAILS_ADDRESS1 = 'store_details.address1';
export const STORE_DETAILS_ADDRESS2 = 'store_details.address1_and_address2';
export const STORE_DETAILS_META_TITLE = 'store_details.meta.title';
export const STORE_DETAILS_META_DESCRIPTION = 'store_details.meta.description';
export const STORE_DETAILS_FRANCHISE_BANNER_TITLE =
  'store_details.franchise_banner.title';
export const DIRECTIONS = 'store_details.directions';

export const STORE_COMING_SOON_ADDRESS =
  'store_coming_soon_details.store_address';
export const COMING_SOON_TEXT =
  'store_coming_soon_details.store_coming_soon_text';

export const FRANCHISE_BANNER_STORE_COMING_SOON_TITLE =
  'franchise_banner.title';
export const FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE =
  'franchise_banner.sub_title';
export const FRANCHISE_BANNER_STORE_COMING_SOON_BODY = 'franchise_banner.body';
export const FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON =
  'franchise_banner.button';
export const FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT =
  'franchise_banner.alt_text';
export const STORE_BANNER_COMING_SOON_ALT_TEXT = 'store_banner.alt_text';
export const STORE_BANNER_COMING_SOON_TITLE = 'store_coming_soon_banner.title';
export const STORE_BANNER_COMING_SOON_BODY = 'store_coming_soon_banner.body';
export const STORE_BANNER_COMING_SOON_BUTTON = 'store_banner.button';
export const STORE_COMING_SOON_ABOUT_STORE_TITLE =
  'store_coming_soon_details.about_store_title';
export const STORE_COMING_SOON_ABOUT_STORE_ONE =
  'store_coming_soon_details.about_store_one';
export const STORE_COMING_SOON_ABOUT_STORE_TWO =
  'store_coming_soon_details.about_store_two';
export const STORE_COMING_SOON_ABOUT_STORE_THREE =
  'store_coming_soon_details.about_store_three';
export const STORE_COMING_SOON_ABOUT_STORE_FOUR =
  'store_coming_soon_details.about_store_four';
export const STORE_COMING_SOON_SOCIAL_MEDIA_TITLE = 'social_media.title';
export const STORE_COMING_SOON_SOCIAL_MEDIA_BODY = 'social_media.body';
export const STORE_COMING_SOON_SOCIAL_INSTAGRAM_ARIA_LABEL =
  'social.instagram.aria_label';
export const STORE_COMING_SOON_SOCIAL_FACEBOOK_ARIA_LABEL =
  'social.facebook.aria_label';
export const STORE_COMING_SOON_SOCIAL_TWITTER_ARIA_LABEL =
  'social.twitter.aria_label';
export const STORE_COMING_SOON_SOCIAL_PINTEREST_ARIA_LABEL =
  'social.pinterest.aria_label';
export const STORE_COMING_SOON_SOCIAL_YOUTUBE_ARIA_LABEL =
  'social.youtube.aria_label';
export const STORE_COMING_SOON_CATEGORIES_TITLE = 'categories.title';
export const STORE_COMING_SOON_DISTANCE_AWAY =
  'store_widget.icon.distance_away';
export const STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION =
  'store_coming_soon_details.meta.schema_description';
export const STORE_COMING_SOON_DETAILS_NO_ADDRESS =
  'store_coming_soon_details.no_address';
export const STORE_COMING_SOON_DETAILS_ADDRESS1 =
  'store_coming_soon_details.title_address1';
export const STORE_COMING_SOON_DETAILS_ADDRESS2 =
  'store_coming_soon_details.title_address2';
export const STORE_COMING_SOON_DETAILS_META_TITLE =
  'store_coming_soon_details.meta.title';
export const STORE_COMING_SOON_DETAILS_META_DESCRIPTION =
  'store_coming_soon_details.meta.description';
export const SUBSCRIPTION_TITLE = 'store_coming_soon_subscription.title';
export const SUBSCRIPTION_SUBTITLE = 'store_coming_soon_subscription.subtitle';
export const REDIRECT_SECTION_TITLE =
  'store_coming_soon_subscription.redirect_title';
export const REDIRECT_SECTION_SUBTITLE = 'store_coming_soon.redirect_subtitle';
export const FIND_STORE_BUTTON = 'store_coming_soon.redirect_find_store';
export const SUBSCRIPTION_FOOTER_DIALOG_TITLE =
  'subscription.footer_dialog.title';
export const SUBSCRIPTION_FOOTER_DIALOG_SUBTITLE =
  'subscription.footer_dialog.subtitle';
export const SUBSCRIPTION_FOOTER_DIALOG_INFORM_EMAIL =
  'subscription.footer_dialog.inform_email';
export const SUBSCRIPTION_FOOTER_DIALOG_INFORM_TEXT =
  'subscription.footer_dialog.inform_text';
export const SUBSCRIPTION_FOOTER_DIALOG_SOCIAL_LINKS_TITLE =
  'subscription.footer_dialog.social_links_title';
export const SUBSCRIPTION_EMAIL_DIALOG_FORM_STEP_BACKGROUND_ALT =
  'subscription.email_dialog.form_step.background_alt';
export const SUBSCRIPTION_EMAIL_DIALOG_SUBMIT_SUCCESS_STEP_BACKGROUND_ALT =
  'subscription.email_dialog.submit_success_step.background_alt';
export const SUBSCRIPTION_FACEBOOK = 'subscription.facebook';
export const SUBSCRIPTION_TWITTER = 'subscription.twitter';
export const SUBSCRIPTION_INSTAGRAM = 'subscription.instagram';
export const SUBSCRIPTION_LINKEDIN = 'subscription.linkedin';
export const SUBSCRIPTION_YOUTUBE = 'subscription.youtube';
export const SUBSCRIPTION_EMAIL_FORM_ERROR = 'subscription.email_form.error';
export const SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER =
  'subscription.email_form.placeholder';
export const LIMITED_LINES_HELPER_TEXT =
  'limited_text_field.limited_lines_helper_text';
export const FIELD_REQUIRED_HELPER_TEXT =
  'limited_text_field.field_required_helper_text';
export const LINES_LIMIT_EXCEEDED = 'limited_text_field.lines_limit_exceeded';
export const TYPING_LAST_LINE = 'limited_text_field.typing_last_line';
export const SPL_CHARS_ERROR = 'limited_text_field.spl_chars_error';
export const EMOJIS_ENTERED = 'limited_text_field.emojis_error';
export const REDUCE_MESSAGE_LENGTH_HELPER_TEXT =
  'limited_text_field.reduce_message_length_text';
export const REACHING_LAST_LINE = 'limited_text_field.reaching_last_line';
