import { Dispatch, useEffect } from 'react';
import { ValidOrderTypes } from 'types/cart.types';
import { isFulfillType } from 'utils/is-order-type';
import {
  selectIsDateAvailabilityFulfilled,
  selectIsNotAvailable,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useSelector } from 'react-redux';

export function useRestoreOrderTypeIfDateAvailable({
  setOrderType,
}: {
  setOrderType: Dispatch<ValidOrderTypes>;
}): void {
  const { orderType } = usePasData();
  const isNotAvailable = useSelector(selectIsNotAvailable);
  const isDateAvailabilityFulfilled = useSelector(
    selectIsDateAvailabilityFulfilled,
  );

  useEffect(() => {
    if (
      isDateAvailabilityFulfilled &&
      isFulfillType(orderType) &&
      !isNotAvailable
    ) {
      setOrderType(orderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDateAvailabilityFulfilled]);
}
