import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';

class EmailLogLegacyApiService extends ApiBaseService {
  constructor() {
    super();
    this.apibase.defaults.baseURL = 'https://estore.ediblearrangements.com';
  }

  log(email: string): AxiosPromise {
    return this.apibase.get(`/logemail.aspx?email=${email}&Source=1&Type=1`);
  }
}

const emailLogLegacyApiService = new EmailLogLegacyApiService();
export { emailLogLegacyApiService };
