import * as TK from 'components/Cart/translations/locale.constant';

export const accountAccessLocale = {
  [TK.TITLE]: 'Log In or Create An Account',
  [TK.BODY]: 'With your account, you can',
  [TK.EARN_TXT]: ' earn and use rewards ',
  [TK.ORDER_TXT]: 'on this order.',
  [TK.ACCOUNT_TXT]: 'With your account, you’ll have access to',
  [TK.REWARDS_TXT]: ' exclusive rewards. ',
  [TK.LOGIN]: 'Log In',
  [TK.CREATE_ACCOUNT]: 'Create Account',
  [TK.WELCOME_BACK]: 'Welcome Back!',
  [TK.APPLY_REWARDS]:
    'By logging in, you can now apply one of your Edible Rewards® to this order.',
  [TK.REWARD_TOOLTIP]: 'You can use only one Reward or Promo code per order. ',
  [TK.ALLERGY_TEXT]: 'Allergy & Safety Information',
  [TK.FAQ_TEXT]: 'FAQs',
  [TK.NO_REWARDS]: 'You currently have no rewards available.',
  [TK.NO_REWARDS_MESSAGE]:
    'With your account, every order you place gets you closer to your next reward! Checkout now to earn more.',
};
