import * as TK from 'components/PDP/Details/components/OwnBox/translations/own-box.locale.constants';
import * as styles from 'components/PDP/Details/components/OwnBox/LimitAlert/styles';
import { Alert, IconButton, Snackbar, Typography } from '@mui/material';
import { CLOSE } from 'translations/locales/translation-keys.constant';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  isShown: boolean;
  hideAlert: () => void;
}

const HIDING_TIME_MILLIS = 10000;

export const LimitAlert: FC<Props> = ({ isShown, hideAlert }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={isShown}
    onClose={hideAlert}
    autoHideDuration={HIDING_TIME_MILLIS}
    sx={styles.snackbar}
    action={
      <IconButton
        color="inherit"
        aria-label={i18next.t(CLOSE)}
        onClick={hideAlert}
      >
        <CloseIcon />
      </IconButton>
    }
  >
    <Alert variant="filled" severity="warning" onClose={hideAlert}>
      <Typography variant="subtitle2" mb={0.5}>
        {i18next.t(TK.OWN_BOX_LIMIT_ALERT_TITLE)}
      </Typography>
      <Typography variant="body2">
        {i18next.t(TK.OWN_BOX_LIMIT_ALERT_CONTENT)}
      </Typography>
    </Alert>
  </Snackbar>
);
