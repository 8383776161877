import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/styles';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Button, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Image } from 'components/common/Image/Image';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { TimeLineBreadCrumb } from 'components/About/TimeLine/TimeLineBreadCrumb';
import { TimeLineResponse } from 'api/about/type/about';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { aboutApiService } from 'api/about/about.api.service';
import { timeLineUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { useIsDownSm } from 'utils/hidden/use-is-down-sm';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const TimeLine: FC = () => {
  const [timeLine, setTimeLine] = useState<TimeLineResponse[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(true);
  const fetchTimeInfo = async () => {
    const res = await aboutApiService.getTimeLineInfo();

    setTimeLine(res);
    setDataLoaded(false);
  };

  useEffect(() => {
    fetchTimeInfo();
  }, []);

  const [sortOrder, setSortOrder] = useState('desc');
  const sortTile = sortOrder === 'desc' ? 'NEWEST FIRST' : 'OLDEST FIRST';
  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const sortedItems = [...timeLine].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.sequence - b.sequence;
    }
    return b.sequence - a.sequence;
  });

  const isMobile = useIsDownSm();
  return (
    <>
      <Backdrop opened={dataLoaded} />
      <TimeLineBreadCrumb />
      <Box sx={styles.accountContainer}>
        <Box sx={styles.pressroomContainer}>
          <Box sx={styles.ContainerStyle}>
            <Typography
              variant="h4"
              fontWeight="500"
              style={{ marginBottom: '10px' }}
            >
              {i18next.t(TK.TIMELINE_TITLE)}
            </Typography>
            <Box style={{ display: 'block' }}>
              <PureHtmlTypography
                variant="body1"
                style={{ marginBottom: '10px' }}
              >
                {i18next.t(TK.TIMELINE_SUBTEXT)}
              </PureHtmlTypography>
            </Box>
          </Box>
          <Box>
            <Timeline
              position={isMobile ? 'right' : 'alternate'}
              sx={isMobile ? styles.timelineItem : null}
            >
              <Button sx={styles.sortButton} onClick={handleSort}>
                {sortTile}
                {sortOrder === 'desc' ? <ExpandMore /> : <ExpandLess />}
              </Button>
              {sortedItems.map((item) => (
                <TimelineItem key={item.id}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ color: 'black', borderRadius: '0' }}>
                      {item.linkTitle}
                    </TimelineDot>
                    <TimelineConnector sx={styles.connector} />
                  </TimelineSeparator>

                  <TimelineContent>
                    <Box sx={styles.altTimeLineBox}>
                      <Image
                        sx={styles.timeLineContent}
                        src={item.image}
                        errorFallback={timeLineUrl(`${item.image}`)}
                        alt={item.imageAltTag}
                      />
                      <Box sx={{ margin: '10px' }}>
                        <PureHtmlTypography
                          variant="h3"
                          fontWeight="500"
                          style={{ marginBottom: '10px' }}
                        >
                          {item.title}
                        </PureHtmlTypography>
                        <PureHtmlTypography
                          variant="body1"
                          style={{ marginBottom: '10px' }}
                        >
                          {item.detail}
                        </PureHtmlTypography>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
        </Box>
      </Box>
    </>
  );
};
