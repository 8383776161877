import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Subscription/EmailSubscriptionDialog/SubmitSuccessStep/styles';
import {
  Box,
  Button,
  Container,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import {
  SocialIcons,
  TermsAndConditions,
  TermsOfUse,
} from 'components/Subscription/EmailSubscriptionDialog/SubmitSuccessStep/styles';
import { TERMS_OF_USE_PATH } from 'containers/MainContainer/constants/path';
import { iconLinks } from 'components/Footer/SocialLinks/icon-links';
import { useIsUpSm } from 'utils/hidden/hide-up-sm';
import React, { FC } from 'react';
import i18next from 'i18next';

export const SubmitSuccessStep: FC = () => {
  const isUpSm = useIsUpSm();
  return (
    <Box>
      {!isUpSm && (
        <Box display="flex" justifyContent="center">
          <Box sx={styles.drawerBar} />
        </Box>
      )}
      <Container sx={styles.containerWrapper}>
        <Box sx={styles.rightWrapper}>
          <Typography sx={styles.SuccessContentTitle}>
            {i18next.t(TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE)}
          </Typography>
          <Typography sx={styles.successContentDescription}>
            {i18next.t(TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST)}
          </Typography>
          <Typography sx={styles.successContentDescription}>
            {i18next.t(TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST)}
          </Typography>
          <Box sx={styles.unlockOffer} />
          <Box sx={styles.socialContainer}>
            <Box sx={styles.socialContainerText}>
              <Typography sx={styles.socialHeadText}>
                {i18next.t(TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE)}
              </Typography>
            </Box>
            <SocialIcons>
              {iconLinks.map(({ icon: Icon, href }) => (
                <IconButton
                  key={href}
                  component="a"
                  href={href}
                  target="_blank"
                  size={isUpSm ? 'large' : 'medium'}
                  sx={styles.icon}
                  color="secondary"
                >
                  <Icon fontSize={isUpSm ? 'large' : 'medium'} />
                </IconButton>
              ))}
            </SocialIcons>
          </Box>
          <Box sx={styles.showBtnContainer}>
            <Typography sx={styles.showBtnLeft}>
              {i18next.t(TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT)}
            </Typography>
            <DialogActions sx={styles.actions}>
              <Button
                component="a"
                href="/all-fruit-arrangements"
                sx={styles.button}
                variant="contained"
                size="large"
                fullWidth
              >
                {i18next.t(TK.SUBSCRIPTION_SHOP_NOW)}
              </Button>
            </DialogActions>
          </Box>
          <Box sx={styles.termsContainer}>
            <Box sx={styles.termConBottom}>
              <Box sx={styles.termsMobileView}>
                {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE)}
              </Box>
              <TermsOfUse
                sx={styles.termsofuse}
                href={TERMS_OF_USE_PATH}
                target="_blank"
              >
                {i18next.t(TK.SUBSCRIPTION_TERMS_OF_USE)}
              </TermsOfUse>
              &nbsp;
              <span>
                {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND)}
              </span>
              &nbsp;
              <TermsAndConditions
                href={HeaderLinks.PROMOTION_TERMS_CONDITIONS}
                target="_blank"
              >
                <span>
                  {i18next.t(TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS)}
                </span>
              </TermsAndConditions>
              <Box sx={styles.termsMobileView}>
                {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
