import {
  Container,
  unlockOffer,
} from 'components/Subscription/SubscriptionSection/SubscriptionForm/styles';
import { Grid } from '@mui/material';
import { SignUpForm } from 'components/Subscription/SubscriptionSection/SubscriptionForm/SignupForm';
import { SubscriptionFormData } from 'components/Subscription/types';
import { SubscriptionSuccessDialog } from 'components/Subscription/SubscriptionSection/SubscriptionSuccessDialog';
import { subscribe } from 'components/Subscription/feature/actions';
import { useDispatch } from 'react-redux';
import React, { FC, useCallback, useState } from 'react';

export const SubscriptionForm: FC = () => {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialogOpenState = useCallback(
    () => setIsDialogOpen((prevState) => !prevState),
    [],
  );

  const handleSubmit = (data: SubscriptionFormData) => {
    toggleDialogOpenState();
    dispatch(subscribe(data));
  };

  return (
    <Container sx={unlockOffer}>
      <Grid xs={10} display="contents">
        <Grid
          xs={6}
          item
          sx={{ width: { md: '70%', sm: '150%', xs: '10%' } }}
        />
        <Grid xs={6} item flexDirection="column">
          <SignUpForm onSubmit={handleSubmit} />
        </Grid>
        <Grid />
        <SubscriptionSuccessDialog
          isOpen={isDialogOpen}
          toggleOpenState={toggleDialogOpenState}
        />
      </Grid>
    </Container>
  );
};
