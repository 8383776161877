import { Status } from 'redux/types/state.interface';
import { ValidatedAddress } from 'api/fulfillment/utils';
import { createSlice } from '@reduxjs/toolkit';
import { fetchSuggestedAddresses } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/action';

interface State {
  status: Status;
  validated: ValidatedAddress | null;
}

const initialState: State = {
  status: Status.INIT,
  validated: null,
};

const slice = createSlice({
  name: 'pdp/order/address-validation',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuggestedAddresses.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchSuggestedAddresses.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.validated = payload;
      })
      .addCase(fetchSuggestedAddresses.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
  },
});

export const pdpOrderAddressValidationReducer = slice.reducer;
export const { reset } = slice.actions;
