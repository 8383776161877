import { ArrangementGroup } from 'redux/PDP/arrangement/types/arrangement-group.types';
import { Status } from 'enums/status';
import { createSlice } from '@reduxjs/toolkit';
import { fetchArrangementGroup } from 'redux/PDP/arrangement/arrangement-group.actions';

export type ArrangementGroupState = {
  status: Status;
  error: string | null;
  arrangementGroup: ArrangementGroup;
};

const initialState: ArrangementGroupState = {
  status: Status.IDLE,
  error: null,
  arrangementGroup: { id: -1, name: '', showOnOccasionDropdown: false },
};

const slice = createSlice({
  name: 'arrangement-group',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArrangementGroup.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = (payload as Error)?.message;
      })
      .addCase(fetchArrangementGroup.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(fetchArrangementGroup.fulfilled, (state, { payload }) => {
        state.status = Status.SUCCESS;
        state.arrangementGroup = payload;
        state.error = null;
      })
      .addDefaultCase((state) => state);
  },
});

export const arrangementGroupReducer = slice.reducer;
