export type OneTrustLocation = { country: string; state: string };

export enum OneTrustCookiesCategories {
  NECESSARY = 'C0001',
  PERFORMANCE = 'C0002',
  FUNCTIONAL = 'C0003',
  TARGETING = 'C0004',
  SOCIAL_MEDIA = 'C0005',
}
export interface OneTrustConsentChangeEvent extends Event {
  detail: OneTrustCookiesCategories[];
  timeStamp: number;
}

export type OneTrustType = {
  getGeolocationData: () => OneTrustLocation;
  OnConsentChanged: (
    consentData: OneTrustConsentChangeEvent,
  ) => OneTrustConsentChangeEvent;
};
