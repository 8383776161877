import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isInit } from 'utils/status.comparer';
import { isEmpty } from 'utils/array/size';

export const checkIsEmptyResult = createSelector(
  ({ plpArrangements }: RootState) => ({
    arrangements: plpArrangements.arrangements,
    status: plpArrangements.status,
  }),
  ({ arrangements, status }) => !isBlocked(status) && isEmpty(arrangements),
);

export const getCount = createSelector(
  ({ plpArrangements: { arrangements } }: RootState) => arrangements,
  (arrangements) => arrangements.length,
);
export const selectArrangements = ({
  plpArrangements: { arrangements },
}: RootState) => arrangements;

export const checkIsBlocked = createSelector(
  ({ plpArrangements: { status } }: RootState) => status,
  (status) => isBlocked(status),
);
export const checkIsInit = createSelector(
  ({ plpArrangements: { status } }: RootState) => status,
  (status) => isInit(status),
);
