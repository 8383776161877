import * as TK from 'translations/locales/translation-keys.constant';
import { SQL_DATE_FORMAT } from 'api/api.constant';
import { format, parse } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

export const DATE_START_TIME_FORMAT = 'h:mm a';
export const MILLISECONDS_IN_MINUTE = 60000;
export const EASTERN_TIME = 'America/New_York';
export const HOURS_12 = 12;
export const MINUTES_10 = 10;

export function formatToAmPmTime(time: string): string {
  return format(new Date(time), DATE_START_TIME_FORMAT);
}

export function zonedTime(time: string): string {
  return formatInTimeZone(time, EASTERN_TIME, DATE_START_TIME_FORMAT);
}

/**
 * Extracts and formats a given date string into a readable string format showing the abbreviated month and full year.
 *
 * @param dateString - A string representation of the date, e.g., "2023-09-18T05:00:00.000Z"
 * @returns A formatted date string, e.g., "September, 2023"
 */
export const extractMonthYear = (dateString: Date): string => {
  return format(new Date(dateString), 'MMMM, yyyy');
};

/**
 * Return string of Shorten Month, Calendar Date, Calendar Year
 * @param date - Date string
 * @returns Sep 18, 2023
 */
export function formatMonthDateYear(date: Date): string {
  return format(date, 'PP');
}

/**
 * Return string of Shorten Day of week, shorten Month, Calendar Date, Calendar Year
 * @param dateToConvert - Date string
 * @returns Mon, Sep 18, 2023
 */
export function formatToReadableDateWithoutTime(dateToConvert: Date): string {
  return `${format(dateToConvert, 'eee')}, ${format(dateToConvert, 'PP')}`;
}
export function dateCheckIfIncludesTime(dateStr: string): Date {
  const isTimeNotInclude = dateStr.includes('T00:00');
  if (isTimeNotInclude) {
    return new Date(dateStr.replace('Z', ''));
  }
  return new Date(dateStr);
}
/**
 * Return string of Day of week, Shorten Month, Calendar Date, Calendar Year
 * @param dateToConvert - Date string
 * @returns Monday Sep 18th, 2023
 */
export function fullReadableDate(dateToConvert: Date): string {
  return `${format(dateToConvert, 'EEEE')} ${format(dateToConvert, 'PPP')}`;
}

/**
 * Return string of Shorten Day of week, Shorten Month, Calendar Date, Calendar Year, Time
 * @param dateToConvert - Date string
 * @returns Mon, Sep 18, 2023 at 1:00 AM
 */
export function formatToReadableDate(dateToConvert: Date): string {
  return `${format(new Date(dateToConvert), 'eee')}, ${format(
    new Date(dateToConvert),
    'PP',
  )} at ${format(new Date(dateToConvert), 'p')}`;
}

/**
 * Return string of Shorten Month, Calendar Date, Calendar Year, Time
 * @param dateToConvert - Date string
 * @returns Sep 18, 2023 1:00 AM
 */
export function orderStatusDate(dateToConvert: Date): string {
  return `${format(new Date(dateToConvert), 'PP')} ${format(
    new Date(dateToConvert),
    'p',
  )}`;
}

/**
 * Return timezone offset in minutes as number
 * @param timeZone - Timezone string
 * @returns minutes as number
 */
export function getTimezoneOffseMinutes(timeZone: string): number {
  return getTimezoneOffset(timeZone) / MILLISECONDS_IN_MINUTE;
}

export function isEqualDates(
  date: Date | null,
  dateToCompare: Date | null,
): boolean {
  return date?.getTime() === dateToCompare?.getTime();
}

function minutesToMilliseconds(minutes: number): number {
  return minutes * MILLISECONDS_IN_MINUTE;
}

export function subtractTimezoneOffset(date: Date): Date {
  return new Date(
    date.getTime() - minutesToMilliseconds(date.getTimezoneOffset()),
  );
}

export function addTimezoneOffset(date: Date): Date {
  return new Date(
    date.getTime() + minutesToMilliseconds(date.getTimezoneOffset()),
  );
}

/**
 * Returns translation key string for
 * long and short variants of days of the week.
 *
 * @param date - date from which weekday should be retrieved
 * @param isLong - long or shot translation key
 * @returns translation key string. This key can be used to get localized
 * version of day of the week.
 */
export function getDateWeekdayTranslationKey(
  date: Date | null,
  isLong = true,
): string {
  if (date === null) {
    return '';
  }
  const keys = isLong
    ? [
        TK.DAY_OF_WEEK_SUNDAY_LONG,
        TK.DAY_OF_WEEK_MONDAY_LONG,
        TK.DAY_OF_WEEK_TUESDAY_LONG,
        TK.DAY_OF_WEEK_WEDNESDAY_LONG,
        TK.DAY_OF_WEEK_THURSDAY_LONG,
        TK.DAY_OF_WEEK_FRIDAY_LONG,
        TK.DAY_OF_WEEK_SATURDAY_LONG,
      ]
    : [
        TK.DAY_OF_WEEK_SUNDAY_SHORT,
        TK.DAY_OF_WEEK_MONDAY_SHORT,
        TK.DAY_OF_WEEK_TUESDAY_SHORT,
        TK.DAY_OF_WEEK_WEDNESDAY_SHORT,
        TK.DAY_OF_WEEK_THURSDAY_SHORT,
        TK.DAY_OF_WEEK_FRIDAY_SHORT,
        TK.DAY_OF_WEEK_SATURDAY_SHORT,
      ];
  return keys[date.getDay()];
}

/**
 * Return hours from date ISO string as number
 * @param isoDateString - ISO date string
 * @returns hours as number which is between characters 11 and 13
 */
export const getHoursFromISODateString = (isoDateString: string): number =>
  Number(isoDateString.slice(0, 2));

/**
 * Return minutes from date ISO string as number
 * @param isoDateString - ISO date string
 * @returns hours as number which is between characters 14 and 16
 */
export const getMinutesFromISODateString = (isoDateString: string): number =>
  Number(isoDateString.slice(3, 5));

/**
 * Return list of dates
 * @param from - date
 * @param to - date
 * @param limit - max list length
 * @returns dates list by day between from and to dates
 */
export const getDatesList = (from: Date, to: Date, limit = 100): Date[] => {
  const result: Date[] = [];
  const start = new Date(from);
  let count = 0;

  while (start <= to && count <= limit) {
    result.push(new Date(start));
    start.setDate(start.getDate() + 1);
    count += 1;
  }

  return result;
};

/**
 * Converts date to sql date string format
 * @param date - date
 * @returns SQLDateString yyyy-mm-dd
 */
export const dateToSQLDateString = (date: Date): string =>
  format(date, 'yyyy-MM-dd');

/**
 * Converts date to sql date time string format
 * @param date - date
 * @returns SQLDateTimeString yyyy-mm-dd hh:mm:ss
 */
export const dateToSQLDateTimeString = (date: Date): string =>
  format(date, 'yyyy-MM-dd HH:mm:ss');

/**
 * Converts date to long localized time format
 * @param date - date
 * @returns string 02:00 PM
 */
export const getLocalizedTime = (date: Date): string => format(date, 'p');

/**
 * Converts hours string to date
 * @param s - string 13:00
 * @returns date
 */
export const hoursStringToDate = (s: string): Date =>
  parse(s, 'H:m', new Date());

/**
 * Converts date to legacy date format string
 * @param date - date
 * @returns string 04/14/2022
 */
export const formatToLegacyDateString = (date: Date): string =>
  format(date, 'MM/dd/yyyy');

/**
 * Converts sql date string to date
 * @param string 2022-09-06
 * @returns date
 */
export const parseSqlDate = (s: string): Date =>
  parse(s, SQL_DATE_FORMAT, new Date());

/**
 * Converts date to expiry date format string
 * @param date - date
 * @returns string 04/2022
 */
export const formatToExpiryDate = (date: Date): string =>
  format(new Date(date), 'MM/yyyy');

/**
 * Converts date to time format in AM/PM
 * @param date - Date
 * @returns string 07:30 PM
 */
export const formatAMPM = (date: Date): string => {
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= HOURS_12 ? 'PM' : 'AM';

  hours %= HOURS_12;
  hours = hours || HOURS_12;
  const convertedMinutes = minutes < MINUTES_10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${convertedMinutes} ${ampm}`;

  return strTime;
};

export function formatToReadableDateTimeFormatAMPM(
  dateToConvert: Date,
): string {
  return `${format(new Date(dateToConvert), 'eee')}, ${format(
    new Date(dateToConvert),
    'PP',
  )} at ${formatAMPM(dateToConvert)} `;
}

export function getNextDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

export function isNextDate(date: Date): boolean {
  const tommarow = getNextDay(new Date());
  const givenDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  return givenDate >= tommarow;
}
