import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/OurMission/styles';
import { Box, Link, Typography } from '@mui/material';
import { FACEBOOK_LINK } from 'utils/social-link.constants';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Image } from 'components/common/Image/Image';
import { OurMissionBreadcrumb } from 'components/About/OurMission/OurMissionBreadcrumb';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import {
  ourMissionDesktopBanner1,
  ourMissionDesktopBanner2,
  ourMissionDesktopBanner3,
  ourMissionLogo,
  ourMissionMobileBanner1,
  ourMissionMobileBanner2,
  ourMissionMobileBanner3,
  ourMissionMobileLikeImg,
} from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const OurMission: FC = () => {
  return (
    <>
      <OurMissionBreadcrumb />
      <Box sx={styles.ourMissionContainer}>
        <Typography variant="h1" sx={styles.ourMissionTitle}>
          {i18next.t(TK.OUR_MISSION_TITLE)}
        </Typography>
        <Box display="flex">
          <Box sx={styles.ourMissionSubTitleContainer}>
            <Typography sx={styles.ourMissionSubTitleStyle}>
              {i18next.t(TK.OUR_MISSION_SUB_TITLE)}{' '}
              <b> {i18next.t(TK.OUR_MISSION_SUB_TITLE1)} </b>{' '}
            </Typography>
            <Typography sx={styles.ourMissionSubTitleStyle}>
              {i18next.t(TK.OUR_MISSION_SUB_TITLE2)}
              <b> {i18next.t(TK.OUR_MISSION_SUB_TITLE1)} </b>{' '}
              {i18next.t(TK.OUR_MISSION_SUB_TITLE3)}
            </Typography>
            <Typography sx={styles.ourMissionSubTitleStyle}>
              {i18next.t(TK.OUR_MISSION_SUB_TITLE4)}
            </Typography>
          </Box>
          <ShowUpMd>
            <Box width="207px">
              <Box pt={5}>
                <Image src={ourMissionLogo} />
              </Box>
            </Box>
          </ShowUpMd>
        </Box>
        <ShowUpMd>
          <Box width="727px" mb={2.5}>
            <Link target="_parent" href="/store-finder">
              <Image src={ourMissionDesktopBanner1} />
            </Link>
          </Box>
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Box sx={styles.missionTabletContainer} pt={1}>
              <Box width="51%" mr={1}>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  textTransform="uppercase"
                  fontWeight="500"
                  mb={1}
                >
                  Fresh Wows DAILY
                </Typography>
                <Typography variant="body1" fontSize="16px">
                  Our stores are open 7 days a week, offering same-day delivery
                  every day!
                </Typography>
              </Box>
              <Box width="49%">
                <Link display="block" target="_parent" href="/store-finder">
                  <Image
                    width="335px"
                    height="204px"
                    src={ourMissionMobileBanner1}
                  />
                </Link>
              </Box>
            </Box>
          </ShowUpSm>
        </HideUpMd>
        <ShowUpXs>
          <Box sx={styles.missionMobileContainer} pt={1}>
            <Box width="100%" mr={1} mb={1} textAlign="center">
              <Typography
                variant="body1"
                fontSize="16px"
                textTransform="uppercase"
                fontWeight="500"
                mb={1}
              >
                Fresh Wows DAILY
              </Typography>
              <Typography variant="body1" fontSize="16px">
                Our stores are open 7 days a week, offering same-day delivery
                every day!
              </Typography>
            </Box>
            <Box width="100%">
              <Link display="block" target="_parent" href="/store-finder">
                <Image
                  width="100%"
                  height="250px"
                  src={ourMissionMobileBanner1}
                />
              </Link>
            </Box>
          </Box>
        </ShowUpXs>
        <ShowUpMd>
          <Box width="727px" mb={2.5}>
            <Link target="_blank" href={FACEBOOK_LINK}>
              <Image src={ourMissionDesktopBanner2} />
            </Link>
          </Box>
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Box sx={styles.missionTabletContainer} pt={1}>
              <Box width="51%" mr={1}>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  textTransform="uppercase"
                  fontWeight="500"
                  mb={1}
                >
                  Love fruit? Like Us!
                </Typography>
                <Typography variant="body1" fontSize="16px">
                  We share the love with our fruit fans on Facebook!
                </Typography>
                <Typography variant="body1" fontSize="16px">
                  Like our page today for surprise giveaways, sneak previews,
                  exclusive offers and so much more.
                </Typography>
                <Link target="_blank" href={FACEBOOK_LINK}>
                  <Image
                    width="94px"
                    height="42px"
                    src={ourMissionMobileLikeImg}
                  />
                </Link>
              </Box>
              <Box width="49%">
                <Link display="block" target="_blank" href={FACEBOOK_LINK}>
                  <Image
                    width="335px"
                    height="204px"
                    src={ourMissionMobileBanner2}
                  />
                </Link>
              </Box>
            </Box>
          </ShowUpSm>
        </HideUpMd>
        <ShowUpXs>
          <Box sx={styles.missionMobileContainer} pt={1}>
            <Box width="100%" mr={1} textAlign="center">
              <Typography
                variant="body1"
                fontSize="16px"
                textTransform="uppercase"
                fontWeight="500"
                mb={1}
              >
                Love fruit? Like Us!
              </Typography>
              <Typography variant="body1" fontSize="16px">
                We share the love with our fruit fans on Facebook!
              </Typography>
              <Typography variant="body1" fontSize="16px">
                Like our page today for surprise giveaways, sneak previews,
                exclusive offers and so much more.
              </Typography>
              <Link target="_blank" href={FACEBOOK_LINK}>
                <Image
                  width="94px"
                  height="42px"
                  src={ourMissionMobileLikeImg}
                />
              </Link>
            </Box>
            <Box width="100%">
              <Link display="block" target="_blank" href={FACEBOOK_LINK}>
                <Image
                  width="100%"
                  height="250px"
                  src={ourMissionMobileBanner2}
                />
              </Link>
            </Box>
          </Box>
        </ShowUpXs>
        <ShowUpMd>
          <Box width="727px" mb={2.5}>
            <Link target="_parent" href="/customer-service/contact-us">
              <Image src={ourMissionDesktopBanner3} />
            </Link>
          </Box>
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Box sx={styles.missionTabletContainer} pt={1}>
              <Box width="51%" mr={1}>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  textTransform="uppercase"
                  fontWeight="500"
                  mb={1}
                >
                  WOW-Worthy
                </Typography>
                <Typography variant="body1" fontSize="16px">
                  Tell us about your Edible Arrangements® experience!
                </Typography>
              </Box>
              <Box width="49%">
                <Link target="_parent" href="/customer-service/contact-us">
                  <Image
                    sx={styles.missionImage}
                    src={ourMissionMobileBanner3}
                  />
                </Link>
              </Box>
            </Box>
          </ShowUpSm>
        </HideUpMd>
        <ShowUpXs>
          <Box sx={styles.missionMobileContainer} pt={1}>
            <Box width="100%" mr={1} textAlign="center">
              <Typography
                variant="body1"
                fontSize="16px"
                textTransform="uppercase"
                fontWeight="500"
                mb={1}
              >
                WOW-Worthy
              </Typography>
              <Typography variant="body1" fontSize="16px">
                Tell us about your Edible Arrangements® experience!
              </Typography>
            </Box>
            <Box width="100%">
              <Link target="_parent" href="/customer-service/contact-us">
                <Image
                  sx={styles.missionMobileImage}
                  src={ourMissionMobileBanner3}
                />
              </Link>
            </Box>
          </Box>
        </ShowUpXs>
        <ShowUpMd>
          <Box sx={styles.doFruitContainer} width="727px" mb={2.5}>
            <Box sx={styles.doFruitButton}>
              <Typography sx={styles.doFruitTextStyle}>
                {i18next.t(TK.DO_FRUIT_TEXT)}
              </Typography>
            </Box>
          </Box>
        </ShowUpMd>
      </Box>
    </>
  );
};
