export enum DateSelector {
  TODAY,
  TOMORROW,
  DATE_PICKER,
}

export enum DateView {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}
