import { PaginationPayload, createPaginationPayload } from 'utils/pagination';
import { Review } from 'api/reviews/types/review.interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { reviewsApiService } from 'api/reviews/reviews.api.service';

export const fetchReviews = createAsyncThunk(
  'reviewsNew/fetch',
  ({
    productCode,
    offset,
    limit,
  }: {
    productCode: number;
    offset?: number;
    limit?: number;
  }): Promise<PaginationPayload<Review>> =>
    reviewsApiService
      .getReviews({ productCode, offset, limit })
      .then((reviews) => createPaginationPayload(reviews, limit)),
);
