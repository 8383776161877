import { ApiBaseService } from 'api/apibase.service';
import { Blog } from 'components/LocalSeoPage/BlogCarousel/types';

class BlogApiService extends ApiBaseService {
  async getBlogPosts(): Promise<Blog[]> {
    const { data } = await this.apibase.get<Blog[]>('blog');

    return data;
  }
}

const blogApiService = new BlogApiService();

export { blogApiService };
