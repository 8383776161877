import { UserSession } from 'providers/Session/feature/user.types';
import { dataLayer } from 'hooks/google/data-layer';
import { sha256 } from 'crypto-hash';

export class EpsilonAnalytics {
  public pageView = async (
    promoId: number,
    user: UserSession,
    department?: string,
    category?: string,
    productId?: number,
  ): Promise<void> => {
    const emailHash = await sha256(user.userEmail.toLowerCase());

    dataLayer.push({
      event: 'epsilonPageVisit',
      epsilonPageVisit: {
        promoId,
        url: encodeURIComponent(window.location.href),
        cacheBuster: Date.now(),
        userId: user.userId,
        emailHash,
        department,
        category,
        productId,
      },
    });
  };

  public conversion = async (
    user: UserSession,
    promoId: number,
    transactionId: string,
    conversionValue: number,
    conversionType: string,
    conversionStoreLocation: string,
    items: {
      product_id: string;
      item_amount: string;
      item_quantity: string;
      item_discount?: string;
    }[],
  ): Promise<void> => {
    const emailHash = await sha256(user.userEmail.toLowerCase());

    dataLayer.push({
      event: 'epsilonConversion',
      epsilonConversion: {
        promoId,
        url: encodeURIComponent(window.location.href),
        cacheBuster: Date.now(),
        emailHash,
        transactionId,
        conversionValue,
        conversionType,
        conversionStoreLocation,
        items,
      },
    });
  };

  public actionConversion = async (
    user: UserSession,
    promoId: number,
  ): Promise<void> => {
    const emailHash = await sha256(user.userEmail.toLowerCase());

    dataLayer.push({
      event: 'epsilonActionConversion',
      epsilonActionConversion: {
        promoId,
        url: encodeURIComponent(window.location.href),
        cacheBuster: Date.now(),
        emailHash,
      },
    });
  };
}

export const epsilon: EpsilonAnalytics = new EpsilonAnalytics();
