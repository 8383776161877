import { SxProps } from '@mui/system';
import { theme } from 'utils/theme/theme';

export const wrapper: SxProps = {
  mx: 0,
};

export const grid: SxProps = {
  px: 2.5,
  height: '145px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflow: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& .MuiGrid-item': {
    flex: '1 0 auto',
    flexBasis: 'initial',
    '&:last-child': {
      flex: '0',
      flexBasis: '50%',
    },
  },
  [theme.breakpoints.up('sm')]: {
    height: 'auto',
    flexDirection: 'row',
    px: 0,
    '& .MuiGrid-item': {
      flex: '0 0 initial',
    },
  },
};

export const description: SxProps = {
  ml: 1,
  mr: 2.5,
  [theme.breakpoints.up('sm')]: {
    mx: 0,
  },
};

export const borderStyle: SxProps = {
  border: [0, '1px solid #c9c9c9', '1px solid #c9c9c9'],
  pb: 1,
  pl: [0, 2, 2],
  pr: [0, 2, 2],
};

export const contentStyle: SxProps = {
  borderLeft: 0,
  pl: 0,
  pr: 0,
  ml: 0,
};

export const titleStyle: SxProps = {
  mb: 2,
  ml: [5, 4, 4],
  width: ['70%', '100%', '100%'],
  mt: [2, 0, 0],
};

export const carousel: SxProps = {
  '& .slick-slider': {
    position: 'relative',

    '& .slick-list': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '610px',
      },

      '& .slick-track': {
        display: 'flex',
      },

      '& .slick-slide': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        height: 'auto',
        '& > div:first-of-type': {
          mb: 1,
        },
        '& > div': {
          flex: '1 0 auto',
          '& .MuiGrid-root': {
            height: '100%',
          },
        },
        '&:last-child > div': {
          flex: 0,
          flexBasis: '50%',
        },
      },
    },
  },
};

export const cookieCarousel: SxProps = {
  '& .slick-slider': {
    position: 'relative',

    '& .slick-list': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '610px',
      },

      '& .slick-track': {
        display: ['inline-flex', 'ruby', 'ruby'],
      },

      '& .slick-slide': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        height: 'auto',
        width: '143px !important',
        '& > div:first-of-type': {
          mb: 1,
        },
        '& > div': {
          flex: '1 0 auto',
          '& .MuiGrid-root': {
            height: '100%',
          },
        },
        '&:last-child > div': {
          flex: 0,
          flexBasis: '50%',
        },
      },
    },
  },
};
export const dessertCarousel: SxProps = {
  '& .slick-slider': {
    position: 'relative',

    '& .slick-list': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '610px',
      },

      '& .slick-track': {
        display: 'flex',
      },

      '& .slick-slide': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        height: 'auto',
        width: '135px !important',
        '& > div:first-of-type': {
          mb: 1,
        },
        '& > div': {
          flex: '1 0 auto',
          '& .MuiGrid-root': {
            height: '100%',
          },
        },
        '&:last-child > div': {
          flex: 0,
          flexBasis: '50%',
        },
      },
    },
  },
};

export const noSpace: SxProps = {
  padding: 0,
  margin: 0,
};

export const accordionText: SxProps = {
  padding: 0,
  margin: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& svg': {
    color: '#000',
    width: '25px',
  },
};
export const subtitleStyle: SxProps = {
  position: 'absolute',
  marginTop: ['-20px', '-25px', '-25px'],
  marginLeft: ['40px', '30px', '30px'],
  [theme.breakpoints.down('sm')]: {
    width: '295px',
    position: 'relative',
    mb: 1,
  },
};

export const selectionText: SxProps = {
  mb: 2,
  textAlign: ['center', 'right', 'right'],
};
