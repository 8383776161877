import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const wrapper: ThemedSxProps = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'content-box',
  margin: '0 auto',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  padding: { xs: '56px 20px', sm: '40px' },
  marginBottom: { xs: '20px', sm: '40px' },
});

export const Headline = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 32,
});

export const title: ThemedSxProps = {
  textAlign: 'left',
  fontSize: 20,
  lineHeight: 1.6,
  fontWeight: '600',
  whiteSpace: 'wrap',
  maxWidth: 'calc(100% - 20px)',
  '& p': {
    whiteSpace: 'wrap',
  },
};

export const grid: ThemedSxProps = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: { xs: '100%', sm: '600px', md: '1100px' },
};
