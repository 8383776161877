import {
  STORE_IS_NOT_AVAILABLE_FOR_DATE,
  STORE_IS_NOT_FOUND_FOR_ARRANGEMENT_FOR_ZIP,
  STORE_IS_NOT_FOUND_FOR_ZIP,
} from 'translations/locales/translation-keys.constant';

export const ZIP_SHOULD_BE_PROVIDED = 'pdp.main.error.provided_zip';
export const ZIP_SHOULD_BE_VALID = 'pdp.main.error.enter_valid_zip';
export const DATE_SHOULD_BE_PROVIDED = 'pdp.main.error.provided_date';
export const ENTER_ZIP_AND_DATE_TO_CONTINUE =
  'pdp.main.error.enter_zip_and_date';
export const CANT_FULFILL_ON_DATE = 'pdp.main.error.cant_fulfill_on_date';
export const SOMETHING_WENT_WRONG = 'pdp.main.error.smth_went_wrong';
export const ENTER_VALID_ZIP = 'pdp.pas.error.enter_valid_zip';
export const SINGLE_DELIVERY_TYPE_COUNT = 1;
export const DEFAULT_START_PICKUP_TIME_HOUR = 9;
export const DEFAULT_END_PICKUP_TIME_HOUR = 17;
export const DEFAULT_PICKUP_TIME_INTERVAL = 30;

export const INPUT_VALIDATION_ERRORS = [
  ZIP_SHOULD_BE_PROVIDED,
  ZIP_SHOULD_BE_VALID,
  DATE_SHOULD_BE_PROVIDED,
  ENTER_ZIP_AND_DATE_TO_CONTINUE,
];
export const DATE_ERRORS = [
  CANT_FULFILL_ON_DATE,
  STORE_IS_NOT_AVAILABLE_FOR_DATE,
];
export const PAS_CONTROL_AREA_IS_NOT_AVAILABLE =
  'pas_control.area_is_not_available';
export const PAS_CONTROL_AREA_IS_NOT_AVAILABLE_FOR_PRODUCT =
  'pas_control.area_is_not_available_for_product';

export const ZIP_ERRORS = [
  STORE_IS_NOT_FOUND_FOR_ZIP,
  STORE_IS_NOT_FOUND_FOR_ARRANGEMENT_FOR_ZIP,
  PAS_CONTROL_AREA_IS_NOT_AVAILABLE_FOR_PRODUCT,
];
