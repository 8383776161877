import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { isNotEmpty } from 'utils/array/size';
import { pop } from 'components/BreadcrumbBack/feature/slice';
import { selectLink } from 'components/BreadcrumbBack/feature/selectors';
import { shouldUseReferrer } from 'components/PDP/GoBack/utils';
import { useDispatch, useSelector } from 'react-redux';

type Breadcrumb = {
  visible: boolean;
  linkProps: {
    component?: ForwardRefExoticComponent<
      LinkProps & RefAttributes<HTMLAnchorElement>
    >;
    to?: string;
    href?: string;
    onClick?: () => void;
  };
};

const { referrer } = document;
const isReferrerValid = shouldUseReferrer(referrer, window.origin);

export const useBreadcrumbProps = (): Breadcrumb => {
  const link = useSelector(selectLink);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(pop());
  };

  const linkProps = link
    ? { component: RouterLink, to: link.href, onClick }
    : { href: isReferrerValid ? referrer : undefined };

  const visible = isNotEmpty(Object.values(linkProps).filter(Boolean));

  return {
    linkProps,
    visible,
  };
};
