/* eslint-disable no-param-reassign */
import {
  AbortReason,
  getCategoryPage,
} from 'redux/PLP/arrangements/actions/category-pages.actions';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { CategoryPageState } from 'redux/PLP/arrangements/types/category-page.types';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';

export const categoryPagesInitialState: CategoryPageState = {
  categoryPage: null,
  categoryPageStatus: Status.INIT,
  categoryPageError: null,
};

export const categoryPagesExtraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<CategoryPageState>>,
): void => {
  builder
    .addCase(getCategoryPage.pending, (state) => {
      state.categoryPageStatus = Status.PENDING;
    })
    .addCase(getCategoryPage.fulfilled, (state, action) => {
      state.categoryPage = action.payload;
      state.categoryPageStatus = Status.FULFILLED;
    })
    .addCase(getCategoryPage.rejected, (state, { error, payload }) => {
      if (
        error.name === 'AbortError' &&
        error.message === AbortReason.NewSearch
      ) {
        return;
      }
      state.categoryPageStatus = Status.ERROR;
      state.categoryPageError = payload as ResponseError;
    });
};
