import { Box, SxProps, styled } from '@mui/material';

export const DescriptionWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1.75),
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2.5),
  },
}));

export const link: SxProps = {
  fontSize: '13px',
  fontWeight: 600,
  padding: '5px 16px',
  marginLeft: '-16px',
};

export const ViewButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2.5),
  },
}));
