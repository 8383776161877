import { Box } from '@mui/material';
import { LineItem } from 'components/OrderConfirmation/OrderSummary/LineItem';
import { Order } from 'redux/order-confirmation/types/types';
import { lineItems } from 'redux/order-confirmation/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface Props {
  order: Order;
}

export const OrderSummary: FC<Props> = ({ order }) => {
  const orderSummaryLineItems = useSelector((state) =>
    lineItems(state, order.orderID),
  );
  return (
    <Box mt={3}>
      {orderSummaryLineItems.map(
        ({ text, value, line, couponCodeStyle, orderType }) => (
          <LineItem
            key={text}
            text={text}
            value={value}
            line={line}
            couponCodeStyle={couponCodeStyle}
            orderType={orderType}
          />
        ),
      )}
    </Box>
  );
};
