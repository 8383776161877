import {
  ORDER_HEADER_DELIVERY,
  ORDER_HEADER_PICKUP,
  ORDER_HEADER_SHIPMENT,
} from 'components/PDP/Order/Header/translations/constants';

export const orderHeaderCommonLocale = {
  [ORDER_HEADER_PICKUP]: 'pickup information',
  [ORDER_HEADER_SHIPMENT]: 'shipment information',
  [ORDER_HEADER_DELIVERY]: 'delivery information',
};
