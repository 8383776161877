import { OrderExpressFulfillments } from 'redux/order-confirmation/types/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderExpressFulfillmentsApiService } from 'api/order-express-fulfillments.api.service';
import { parseAxiosError } from 'utils/errors';

export const fetchOrderFulfillmentByOrderNumber = createAsyncThunk(
  'express-fulfillments/order/fetch',
  async (orderNumber: string, { rejectWithValue }) => {
    try {
      const { data }: { data: OrderExpressFulfillments } =
        await orderExpressFulfillmentsApiService.findByOrderNumber(orderNumber);
      if (data) {
        return data;
      }
      return rejectWithValue('not_found');
    } catch (error) {
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);
