import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const contentInstantSavings: ThemedSxProps = {
  color: colors.primaryRed,
  fontWeight: '700',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const inlineContent: ThemedSxProps = {
  display: 'inline-flex',
  flexWrap: { xs: 'wrap', md: 'nowrap' },
  justifyContent: { xs: 'center', md: 'normal' },
  fontWeight: '500',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const pickupContent: ThemedSxProps = {
  fontWeight: '500',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const contentTitle: ThemedSxProps = {
  fontWeight: 400,
  fontSize: { md: '48px', sm: '48px', xs: '24px' },
  letterSpacing: '0.15px',
  lineHeight: { md: '134%', sm: '134%', xs: '160%' },
  fontFamily: fonts.poppins,
};

export const title: ThemedSxProps = (theme) => ({
  paddingBottom: theme.spacing(4),
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    flexBasis: 'calc(50% - 10px)',
    padding: theme.spacing(0, 12.5, 0, 0),
  },
});
