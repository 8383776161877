import { Dispatch, SetStateAction, useEffect } from 'react';
import { NULL_RECIPIENT } from 'containers/PDPContainer/PDPContainer';
import { Recipient } from 'redux/cart/cart.types';
import { isEmpty } from 'utils/is-empty';
import { isNotEmpty } from 'utils/is-not-empty';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { selectIsPickup } from 'containers/PDPContainer/feature/pas/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCartRecipientId } from 'containers/PDPContainer/hooks/use-get-cart-recipient-id';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';

export const useSyncRecipientCity = (
  cityMlId: number,
  setRecipient: Dispatch<SetStateAction<Recipient>>,
): void => {
  const dispatch = useDispatch();
  const cartRecipientId = useGetCartRecipientId();
  const { area, orderType } = usePasData();
  const { cities } = useSelector(({ area: { data } }) => data);
  const availabilitySession = useSelector(
    ({ availabilitySession: s }) => s.data,
  );
  const isPickup = useSelector(selectIsPickup);

  useEffect(() => {
    const isAreaCitiesExist = isNotEmpty(cities);
    const isSingleCityArea = cities.length === 1;
    const sessionCityMl =
      availabilitySession?.cartRecipient?.recipientContact.cityMlId;
    const isChangeArea = isAreaCitiesExist && cityMlId;

    if (isPickup || !isAreaCitiesExist) {
      return;
    }

    if (sessionCityMl && cartRecipientId) {
      const isSessionCityExistInNewArea = cities.find(
        ({ mlId }) => mlId === sessionCityMl,
      );
      if (isSessionCityExistInNewArea) {
        setRecipient((prev) => ({
          ...prev,
          cityMlId: sessionCityMl,
        }));
        return;
      }
      setRecipient((prev) => ({
        ...prev,
        cityMlId: NULL_RECIPIENT.cityMlId,
      }));
      return;
    }

    if (isChangeArea) {
      const isSelectedCityExistInNewArea = cities.find(
        ({ mlId }) => mlId === cityMlId,
      );
      if (!isSelectedCityExistInNewArea) {
        setRecipient((prev) => ({
          ...prev,
          cityMlId: cities[0].mlId,
        }));
        return;
      }
    }

    if (isSingleCityArea) {
      setRecipient((prev) => ({
        ...prev,
        cityMlId: cities[0].mlId,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (orderType && area && isEmpty(area?.cities)) {
      dispatch(
        pas.set({
          area: { ...area, cities },
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);
};
