import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { theme } from 'utils/theme/theme';

export const placeBtnStyle: SxProps = {
  width: '211px',
  height: '56px',
};

export const placeBtnMobileStyle: SxProps = {
  width: '320px',
  height: '40px',
  marginBottom: '64px',
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
  },
};

export const orderTitleStyle: SxProps = {
  width: ['475px', '475px', '530px'],
  fontWeight: '500',
  color: colors.primaryBlack,
  [theme.breakpoints.between(820, 1100)]: {
    width: '715px',
  },
};

export const orderTextStyle: SxProps = {
  fontSize: '16px',
  mt: 3,
  mb: 3,
};

export const selectBoxStyle: SxProps = {
  width: '184px',
  height: '40px',
  background: '#fff',
};

export const orderHeaderStyle: SxProps = {
  height: '86px',
  background: '#F7F7F7',
  p: 1,
  mb: 1.5,
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
  },
};

export const orderTableContainer: SxProps = {
  '& .MuiTableCell-root': {
    padding: '6px',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
    },
  },
  '& .MuiTableContainer-root': {
    boxShadow: 'none',
    overflowX: 'hidden',
  },
};

export const tableRowMobileStyle: SxProps = {
  display: 'flex',
  width: '310px',
  paddingBottom: '16px',
  marginBottom: '16px',
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
  },
};

export const tableMobileStyle: SxProps = {
  display: 'grid',
  width: '50%',
  '& .MuiTableCell-root': {
    textAlign: 'left',
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export const tableMobileContentStyle: SxProps = {
  display: 'grid',
  width: '50%',
  '& .MuiTableCell-root': {
    textAlign: 'right',
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: '400',
  },
};

export const mobileContainer: SxProps = {
  '& .lineStyle': {
    borderBottom: '1px solid #C9C9C9',
    mt: 2,
    mb: 2,
  },
};
