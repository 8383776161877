interface MonthDays {
  [month: number]: number;
}

const DAYS_IN_MONTH: MonthDays = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const isLeapYear = (year: number): boolean => {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
};

export const getDayDropdownSelectValues = (month = 0, year = 0): string[] => {
  let days = 31;

  if (month && year) {
    if (month === 2 && isLeapYear(year)) {
      days = 29;
    } else {
      days = DAYS_IN_MONTH[month];
    }
  } else if (month) {
    days = DAYS_IN_MONTH[month];
    if (month === 2 && !year) {
      days = 29;
    }
  } else if (year) {
    days = 31;
  }

  return Array.from({ length: days }, (_, i) => (i + 1).toString());
};

export const getMonthDropdownSelectValues = (): {
  key: number;
  value: string;
}[] => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthDropdownSelectValues = months.map((monthString, index) => ({
    key: index + 1,
    value: monthString,
  }));

  return monthDropdownSelectValues;
};

export const getYearDropdownSelectValues = (): string[] => {
  const yearStart = 1940;
  const currentYear = new Date().getFullYear();
  const yearList = Array.from(
    { length: currentYear - yearStart - 17 },
    (_, i) => (currentYear - i - 18).toString(),
  );
  return yearList;
};
