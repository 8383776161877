import * as styles from 'components/Header/MobileMenus/styles';
import { Box, Divider, IconButton, ListItem, Typography } from '@mui/material';
import { Category, Item } from 'redux/header/header.types';
import { MegaMenuAssociations } from 'enums/mega-menu';
import { Menu, NavigationGroups } from 'components/Header/MobileMenus/types';
import { MenuBanner } from 'components/Header/HeaderNavigation/MenuBanner';
import { RootState } from 'redux/store';
import { SubMenuSection } from 'components/Header/MobileMenus/SubMenuSection';
import { getForMobile } from 'redux/header/header.selectors';
import { menus } from 'components/Header/MobileMenus/menu-links';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  isVisible: boolean;
  menu: string | null;
  toggleMenuVisibility: () => void;
  onBackBtnClick: () => void;
}

export const SubMenu: FC<Props> = ({
  isVisible,
  menu,
  toggleMenuVisibility,
  onBackBtnClick,
}) => {
  const filtersMenu = useSelector<RootState, Item[]>(getForMobile);

  return (
    <Box sx={styles.menuList(isVisible)}>
      {menu && (
        <ListItem sx={styles.titleWrapper}>
          <IconButton
            color="secondary"
            component="button"
            onClick={onBackBtnClick}
            sx={styles.backButton}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" color="secondary" sx={styles.title}>
            {i18next.t(menu)}
          </Typography>
          <IconButton
            color="secondary"
            component="button"
            onClick={toggleMenuVisibility}
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
      )}
      {filtersMenu.map((item) => (
        <Box key={item.name} sx={styles.menuContent(item.name === menu)}>
          {item &&
            item.categories.map((category: Category) => (
              <SubMenuSection
                key={category.id}
                title={category.name}
                items={category.filters}
              />
            ))}
          {item && item.megaMenuAssociation === MegaMenuAssociations.Shop && (
            <>
              <Divider sx={styles.divider} />
              <MenuBanner />
            </>
          )}
        </Box>
      ))}
      {menus.map((item: Menu) =>
        item.groups?.map(({ name, navigations }: NavigationGroups) => (
          <Box key={name} sx={styles.menuContent(item.name === menu)}>
            <SubMenuSection title={name} items={navigations} />
          </Box>
        )),
      )}
    </Box>
  );
};
