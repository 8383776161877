import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const wrapper: ThemedSxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: [6, 8, 10],
  '& h2': {
    margin: '20px 0 20px 0',
    textAlign: 'center',
    fontSize: '1.75rem',
    fontWeight: '600',
  },
  '& p': {
    maxWidth: '800px',
    textAlign: 'center',
    fontWeight: '500',
  },
  '& Button': {
    marginTop: '40px',
  },
};

export const Shaky = styled('div')({
  ':hover': {
    animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
    transform: 'translate(0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000px',
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate(-1px, 0)',
    },
    '20%, 80%': {
      transform: 'translate(2px, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate(-4px, 0)',
    },
    '40%, 60%': {
      transform: 'translate(4px, 0)',
    },
  },
});
