import { Status } from 'redux/types/state.interface';
import { StoreSchedule } from 'redux/PDP/stores/schedule/stores-schedule.types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchSchedule } from 'components/Session/feature/actions';

interface State {
  status: Status;
  schedule: StoreSchedule | null;
}

const initialState: State = {
  status: Status.INIT,
  schedule: null,
};
const slice = createSlice({
  name: 'session/store/schedule',
  extraReducers: (builder) =>
    builder
      .addCase(fetchSchedule.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchSchedule.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.schedule = payload;
      })
      .addCase(fetchSchedule.rejected, (state) => {
        state.status = Status.ERROR;
        state.schedule = null;
      })
      .addDefaultCase((state) => state),
  initialState,
  reducers: {
    clearSchedule: (state) => {
      state.schedule = null;
    },
  },
});

export const sessionStoreScheduleReducer = slice.reducer;
export const { clearSchedule } = slice.actions;
