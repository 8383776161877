import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { DessertBoardType } from 'api/products/types';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';

export const isValidateDessertBoardItems = (
  selectedOwnBoxAddons: UpsellItem[],
  clickOwnBoxAddon: OwnBoxAddon[],
  onChangeStep: (step: AvailabilityStep) => void,
  pasData: Pas,
): boolean => {
  const backedAddons = selectedOwnBoxAddons.filter((x) =>
    clickOwnBoxAddon.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.BakedGoods,
    ),
  );
  const dippedBerriesAdon = selectedOwnBoxAddons.filter((x) =>
    clickOwnBoxAddon.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.DippedBerries,
    ),
  );
  const dippedFruitsAdon = selectedOwnBoxAddons.filter((x) =>
    clickOwnBoxAddon.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.DippedFruits,
    ),
  );

  if (
    backedAddons.length >= 1 &&
    dippedBerriesAdon.length >= 1 &&
    dippedFruitsAdon.length >= 1
  ) {
    if (pasData?.area?.name) {
      onChangeStep(AvailabilityStep.DATE_SELECTION);
    } else {
      onChangeStep(AvailabilityStep.ZIP_AVAILABILITY);
    }
    return false;
  }
  return true;
};
