import { OrderTypeLockAlert } from 'components/Session/Alert/OrderTypeLockAlert';
import { RecipientLockAlert } from 'components/Session/Alert/RecipientLockAlert/RecipientLockAlert';
import { SessionLock } from 'enums/session-lock';
import { StoreLockAlert } from 'components/Session/Alert/StoreLockAlert/StoreLockAlert';
import { selectSessionLockType } from 'redux/session/availability/availability.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface Props {
  slimStoreLock?: boolean;
  displayPickupStore?: boolean;
  shortDate?: boolean;
  displayRecipientName?: boolean;
  multipleRecipients?: boolean;
}

export const LockAlert: FC<Props> = ({
  slimStoreLock,
  displayPickupStore,
  shortDate,
  displayRecipientName,
  multipleRecipients,
}) => {
  const lockType = useSelector(selectSessionLockType);

  switch (lockType) {
    case SessionLock.Recipient:
    case SessionLock.None:
      return (
        <RecipientLockAlert
          displayRecipientName={displayRecipientName}
          multipleRecipients={multipleRecipients}
          shortDate={shortDate}
        />
      );
    case SessionLock.Store:
      return (
        <StoreLockAlert
          displayPickupStore={displayPickupStore}
          slim={slimStoreLock}
          shortDate={shortDate}
        />
      );
    case SessionLock.OrderType:
      return (
        <OrderTypeLockAlert
          displayPickupStore={displayPickupStore}
          shortDate={shortDate}
        />
      );
    default:
      return null;
  }
};
