import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';

class StoreLegacyApiService extends ApiBaseService {
  constructor() {
    super();
    this.apibase.defaults.baseURL = '/bananas_resources';
  }

  getStateStores(): AxiosPromise<string> {
    return this.apibase.get<string>('/statesstores.xml');
  }
}
export const storeLegacyApiService = new StoreLegacyApiService();
