import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { Key } from 'enums/key';
import { OrderHistoryResponse } from 'api/rewards/types/order-history-response.type';
import { cancelOrderLink } from 'components/CustomerService/OrderTracking/utils/utils';
import { useMenu } from 'hooks/useMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  orderDetails: OrderHistoryResponse;
}
export const ActionMenu: FC<Props> = ({ orderDetails }) => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const [expanded, setExpanded] = React.useState(false);
  const [, setAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  useEffect(() => {
    document.body.style.overflow = 'auto';
    setExpanded(open);
  }, [open]);
  const handleCloseAndUpdate = () => {
    handleClose();
  };
  useEffect(() => {
    const handleScroll = () => {
      if (anchorEl && open) {
        const rect = anchorEl.getBoundingClientRect();
        setAnchorPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    };
    if (open) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [anchorEl, open]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleCloseAndUpdate();
    }
  };
  const handleAccordionToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (anchorEl) {
      handleCloseAndUpdate();
    } else {
      document.body.style.overflow = 'scroll';
      handleClick(event);
    }
  };

  return (
    <Box mr={2}>
      <Button
        data-test="help-button"
        color="secondary"
        id="help-menu-button"
        aria-controls={open ? 'help-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleAccordionToggle}
        style={{ fontWeight: 600 }}
        endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={styles.actionColor}
      >
        <Typography sx={styles.actionLink}>
          {i18next.t(TK.ACTION_TEXT)}
        </Typography>
      </Button>
      <Menu
        data-test="help-div"
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'help-menu-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          component="a"
          href={`/customer-service/track-your-order.aspx?MPON=${orderDetails.number}`}
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          <Typography variant="body2">{i18next.t(TK.TRACK_TEXT)}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          component="a"
          href={`/order-details/?SaleID=${orderDetails.saleID}`}
          tabIndex={0}
          onKeyDown={onKeyDown}
          target="_blank"
        >
          <Typography variant="body2">{i18next.t(TK.VIEW_TEXT)}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          component="a"
          href={cancelOrderLink(orderDetails.number)}
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          <Typography variant="body2">{i18next.t(TK.CANCEL_TEXT)}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
