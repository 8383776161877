import { AddressTypes } from 'components/SignUp/signup.enum';

export const isUrlToAnotherResource = (menuUrl: string): boolean =>
  ['http://', 'https://'].some(
    (searchString) => menuUrl.lastIndexOf(searchString, 0) === 0,
  );

export const getNavigationUrl = (url: string): string => {
  if (url.startsWith('/')) {
    return url;
  }
  return isUrlToAnotherResource(url) ? url : `/${url}`;
};

export const getSearchItemUrl = (url: string): string =>
  url.replace(/^\//g, '').trim();

export const getCountryFullName = (countryId: number): string => {
  if (countryId === 1) return 'United States';
  if (countryId === 2) return 'Canada';
  return '';
};

export const getAddressType = (type: AddressTypes): string => {
  const typeName = Object.keys(AddressTypes).find(
    (key) => AddressTypes[key as keyof typeof AddressTypes] === type,
  );
  return typeName ?? 'NotSpecified';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateActiveAccountTab = (name: string) => {
  const url = new URL(window.location as unknown as string);
  url.searchParams.set('active', name);
  window.history.replaceState(null, '', url.toString());
};
