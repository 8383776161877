import { Box, Skeleton } from '@mui/material';
import { Skinny } from 'components/Header/Banner/Skinny';
import { getSkinny } from 'components/Header/Banner/feature/actions';
import {
  selectBanners,
  selectIsBlocked,
} from 'components/Header/Banner/feature/selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';

export const SkinnyBanners: FC = () => {
  const dispatch = useDispatch();
  const isBlocked = useSelector(selectIsBlocked);
  const banners = useSelector(selectBanners);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getSkinny());
    }, 200);
  }, [dispatch]);

  if (isBlocked) {
    return <Skeleton sx={{ height: [65, 110, 40], width: 1 }} />;
  }

  return (
    <Box position="relative" display="flex" flexDirection="column">
      {banners.map((b) => (
        <Skinny banner={b} key={b.id} />
      ))}
    </Box>
  );
};
