import { PRINTIBLE_ADDON_ID } from 'components/PDP/Upsells/constants';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isFulfilled } from 'utils/status.comparer';
import { isNotEmpty } from 'utils/array/size';

export const checkIsBlocked = createSelector(
  ({ pdpUpsells: { addonsStatus, upgradesStatus } }: RootState) => ({
    addonsStatus,
    upgradesStatus,
  }),
  ({ addonsStatus, upgradesStatus }) =>
    isBlocked(addonsStatus) || isBlocked(upgradesStatus),
);

export const checkIsFulfilled = createSelector(
  ({ pdpUpsells: { addonsStatus, upgradesStatus } }: RootState) => ({
    addonsStatus,
    upgradesStatus,
  }),
  ({ addonsStatus, upgradesStatus }) =>
    isFulfilled(addonsStatus) && isFulfilled(upgradesStatus),
);

export const checkIsError = createSelector(
  ({ pdpUpsells: { addonsStatus, upgradesStatus } }: RootState) => ({
    addonsStatus,
    upgradesStatus,
  }),
  ({ addonsStatus, upgradesStatus }) =>
    Status.ERROR === addonsStatus && Status.ERROR === upgradesStatus,
);

export const getRealUpsells = createSelector(
  ({ pdpUpsells: { addons, upgrades } }: RootState) => ({
    addons,
    upgrades,
  }),
  ({ addons, upgrades }) => [
    ...upgrades,
    ...addons.filter(({ id }) => id !== PRINTIBLE_ADDON_ID),
  ],
);

export const checkIsPrintibleExists = createSelector(
  ({ pdpUpsells: { addons } }: RootState) => addons,
  (addons) => addons.some(({ id }) => id === PRINTIBLE_ADDON_ID),
);

export const getPrintibleAddon = createSelector(
  ({ pdpUpsells: { addons } }: RootState) => addons,
  (addons) => addons.find((addon) => addon.id === +window.PRINTIBLE_ADDON_ID),
);

export const checkRealUpsellsExists = createSelector(
  getRealUpsells,
  (upsells) => isNotEmpty(upsells),
);
