import { SxProps } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const bannerLink: ThemedSxProps = {
  cursor: 'pointer',
  textDecoration: 'none',
};

export const defaultBannerContainerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'center',
  maxWidth: '1060px',
  width: '100%',
  px: ['20px', '24px', '0px'],
};
export const twoBannerContainer: SxProps = {
  ...defaultBannerContainerStyles,
  '& > .MuiLink-root:first-child': {
    marginRight: ['0px', '0px', '12px'],
  },
  '& > .MuiLink-root:last-child': {
    marginLeft: ['0px', '0px', '12px'],
  },
};
