import * as TK from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductShipping/translations/marketplace-product-shipping.locale.constants';
import * as styles from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductShipping/styles';
import { Box } from '@mui/system';
import { ErrorOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { addCurrency } from 'utils/add-currency';
import { colors } from 'utils/theme/colors';
import { formatRestrictedStates } from 'components/PDP/Details/components/Marketplace/components/utils/format-restricted-states';
import { selectArrangement } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const MarketplaceProductShipping: FC = () => {
  const arrangement = useSelector(selectArrangement);
  const productBrandName =
    arrangement.marketplaceArrangementAttributes?.subBrandName;

  const minimumShippingAmount =
    arrangement.marketplaceArrangementAttributes?.minimumShippingPrice;

  const restrictedStates = formatRestrictedStates(
    arrangement.marketplaceArrangementAttributes?.restrictedStates,
  );

  return (
    <Box>
      {productBrandName && typeof minimumShippingAmount === 'number' && (
        <Typography
          color={colors.freeDelivery}
          variant="subtitle2"
          sx={styles.infoLine}
        >
          <InfoOutlined sx={styles.infoIcon} />
          {i18next.t(TK.MARKETPLACE_FREE_SHIPPING, {
            productBrandName,
            minimumShippingAmount: addCurrency(minimumShippingAmount),
          })}
        </Typography>
      )}

      {restrictedStates && (
        <Typography
          color={colors.errorLight}
          variant="subtitle2"
          sx={styles.infoLine}
        >
          <ErrorOutlineOutlined sx={styles.dangerIcon} />
          {restrictedStates}
        </Typography>
      )}
    </Box>
  );
};
