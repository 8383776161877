import { Box } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  amount: number;
};

export const calculatePayLaterAmount = (
  price: number,
  quantity: number,
): number => +(price * quantity).toFixed(2) || 0;

export const PayLaterMessage: FC<Props> = ({ amount }) => (
  <Box
    minHeight={28}
    data-pp-amount={amount}
    data-pp-layout="text"
    data-pp-text-color="black"
    data-pp-logo-type="inline"
    data-test="paypal-paylater"
    data-pp-message
  />
);
