import { ArrangementGroupOccasion } from 'api/arrangement-group.api.service';

type SortFn = (
  a: Pick<ArrangementGroupOccasion, 'name'>,
  b: Pick<ArrangementGroupOccasion, 'name'>,
) => number;

export const sortOccasions: SortFn = ({ name: a }, { name: b }) => {
  const last = 'other';
  if (a.toLocaleLowerCase() === last) {
    return 1;
  }
  if (b.toLocaleLowerCase() === last) {
    return -1;
  }
  return a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1;
};
