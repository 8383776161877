import { AuthUserMenu } from 'components/Header/Menus/AccountMenu/AuthUserMenu';
import { Box } from '@mui/material';
import { GuestMenu } from 'components/Header/Menus/AccountMenu/GuestMenu';
import { selectIsLoggedIn } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, memo } from 'react';

export const AccountMenu: FC = memo(() => {
  const loggedIn = useSelector(selectIsLoggedIn);

  return <Box mr={1}>{loggedIn ? <AuthUserMenu /> : <GuestMenu />}</Box>;
});
