import { CustomArrowProps } from 'react-slick';
import { CustomizedControl } from 'components/PDP/Details/components/OwnBox/DessertBoardCarouselControl/styled';
import React, { FC, PropsWithChildren } from 'react';

type ControlProps = PropsWithChildren<CustomArrowProps> & {
  disabled: boolean;
};

export const DessertBoardCarouselControl: FC<ControlProps> = ({
  currentSlide,
  slideCount,
  children,
  disabled,
  ...props
}: ControlProps) => (
  <CustomizedControl disabled={disabled} {...props}>
    {children}
  </CustomizedControl>
);
