import * as TK from 'containers/SignupContainer/translations/locale.constants';
import * as styles from 'components/SignUp/Styles/sign-up-form.styles';
import * as yup from 'yup';
import { Account } from 'components/SignUp/account.types';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { BreadcrumbBack } from 'components/BreadcrumbBack/BreadcrumbBack';
import { Controller, useForm } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import { Option } from 'components/common/Select/Option';
import { PasswordStrengthChecker } from 'components/SignUp/password-checker';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { SignUp } from 'components/SignUp/feature/sign-up.action';
import { SignupData as SignUpFormData } from 'types/forms/signup-data-new';
import { SuccessDialog } from 'components/SignUp/SuccessDialog';
import { appInsights } from 'utils/telemetry/app-insights';
import {
  checkIsAlreadyExist,
  checkIsProcessing,
  checkIsSignUpSuccess,
} from 'components/SignUp/feature/selectors';
import { dynamicEvents } from 'service/dynamic-yield';
import {
  getDayDropdownSelectValues,
  getMonthDropdownSelectValues,
  getYearDropdownSelectValues,
} from 'utils/calculateBirthdayDropdownValues';
import { phoneModifier } from 'utils/phone-modifier';
import { signupFormSchema } from 'components/SignUp/signupFormSchema';
import { useBreadcrumbProps } from 'components/SignUp/use-breadcrumb-props';
import { useDispatch, useSelector } from 'react-redux';
import { useNewUserSignUp } from 'components/SignUp/hooks/use-new-user-sign-up';
import { useOpenLogInDialog } from 'components/Authentication/hooks/control';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { FC, useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';

const resolver = yupResolver<yup.SchemaOf<SignUpFormData>>(signupFormSchema);
const defaultYear = '1904';

export const AccountSignUp: FC = () => {
  useNewUserSignUp();
  const dispatch = useDispatch();
  const processing = useSelector(checkIsProcessing);
  const alreadyExist = useSelector(checkIsAlreadyExist);
  const signUpIsSuccess = useSelector(checkIsSignUpSuccess);
  const openLogInDialog = useOpenLogInDialog();
  const { visible, linkProps } = useBreadcrumbProps();

  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    firstName: '',
    lastName: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    phoneNumber: '',
    password: '',
    verifyPassword: '',
  });
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const signUpTermsAndConditionsText = i18next.t(
    TK.SIGNUP_TERMS_AND_CONDITIONS_TEXT,
    {
      termsOfUse: `<a href="/legal/terms-of-use">${i18next.t(
        TK.SIGNUP_LINK_TERMS_OF_USE,
      )}</a>`,
      privacyPolicy: `<a href="/legal/privacy-policy">${i18next.t(
        TK.SIGNUP_LINK_PRIVACY_POLICY,
      )}</a>`,
      productsPolicy: `<a href="/legal/products-policy">${i18next.t(
        TK.SIGNUP_LINK_PRODUCTS_POLICY,
      )}</a>`,
      edibleRewards: `<a href="/rewards/terms-and-conditions.aspx">${i18next.t(
        TK.SIGNUP_LINK_EDIBLE_REWARDS,
      )}</a>`,
      clickHere: `<a href="/about/faq.aspx">${i18next.t(
        TK.SIGNUP_LINK_CLICK_HERE,
      )}</a>`,
    },
  );

  const siteKey = window.RECAPTCHA_SITE_KEY;

  const defaultValues = useMemo(
    () => ({
      email: formData.email || '',
      firstName: formData.firstName || '',
      lastName: formData.lastName || '',
      birthDay: formData.birthDay || '',
      birthMonth: formData.birthMonth || '',
      birthYear: formData.birthYear || '',
      password: formData.password || '',
      verifyPassword: formData.verifyPassword || '',
      phoneNumber: formData.phoneNumber || '',
    }),
    [formData],
  );

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm<SignUpFormData>({
    defaultValues,
    resolver,
    shouldFocusError: true,
  });

  const daysDropdownSelectValues = getDayDropdownSelectValues(
    Number(formData.birthMonth),
    Number(formData.birthYear),
  );
  const monthsDropdownSelectValues = getMonthDropdownSelectValues();
  const yearsDropdownSelectValues = getYearDropdownSelectValues();

  useEffect(() => {
    if (alreadyExist) {
      openLogInDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyExist]);

  const onCaptchaChange = (value: string | null) => {
    setIsCaptchaValid(Boolean(value));
  };

  const onValidForm = (data: SignUpFormData) => {
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      birthDay,
      birthMonth,
      birthYear,
    } = data;
    const year = birthYear || defaultYear;
    const dob = new Date(`${birthMonth}/${birthDay}/${year}`);
    const postData: Account = {
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      birthday: dob,
      password,
      workPhone: phoneNumber || '',
    };

    dynamicEvents.signupSuccess(email);
    appInsights.trackEvent({ name: 'Create Account button clicked' });

    dispatch(SignUp(postData));
  };

  return (
    <Grid
      container
      item
      xs={12}
      md={10}
      lg={8}
      px={[3, 5, 0]}
      direction="column"
      flexWrap="nowrap"
    >
      {visible && (
        <BreadcrumbBack sx={{ mt: 2 }} {...linkProps}>
          {i18next.t(TK.SIGNUP_BACK_TEXT)}
        </BreadcrumbBack>
      )}
      <Grid item sx={styles.titleContainer} pt={4}>
        <span>{i18next.t(TK.SIGNUP_TEXT)}</span>
      </Grid>
      <Grid container direction="column" pt={6} pb={6}>
        <Grid item sx={styles.labelText}>
          <span>{i18next.t(TK.SIGNUP_ACCOUNT_INFORMATION_TEXT)}</span>
        </Grid>
        <Grid
          container
          flexDirection="row"
          flexWrap={['wrap', 'wrap', 'nowrap']}
        >
          <Grid
            container
            item
            direction="column"
            flexWrap={['wrap', 'nowrap', 'nowrap']}
            lg={8}
            md={10}
            xs={12}
            pt={1}
          >
            <Grid item sx={styles.formInputContainer}>
              <TextField
                sx={styles.formInput}
                {...register('email', { required: true })}
                variant="outlined"
                color={!errors?.email?.message ? 'secondary' : 'error'}
                autoComplete="off"
                name="email"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                data-test="sign-up-email-address"
                value={watch('email')}
                label={i18next.t(TK.SIGNUP_EMAIL_ADDRESS_TEXT)}
              />
            </Grid>
            <Grid item sx={styles.formInputContainer}>
              <PasswordStrengthChecker
                register={register}
                watch={watch}
                passwordValue={getValues().password}
                error={!!errors?.password?.message}
                errorText={errors?.password?.message || null}
              />
            </Grid>
            <Grid item sx={styles.formInputContainer}>
              <TextField
                sx={styles.formInput}
                {...register('verifyPassword', { required: true })}
                name="verifyPassword"
                variant="outlined"
                type="password"
                autoComplete="off"
                data-test="sign-up-verify-password"
                value={watch('verifyPassword')}
                error={!!errors?.verifyPassword?.message}
                helperText={errors?.verifyPassword?.message}
                label={i18next.t(TK.SIGNUP_VERIFY_PASSWORD_TEXT)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            lg={6}
            md={10}
            xs={12}
            sx={styles.formInputContainer}
          >
            <Grid item pl={[0, 0, 8]} sx={styles.formInput}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                flexWrap="nowrap"
                p={2}
                sx={styles.tooltipInfo}
              >
                <Grid
                  display="flex"
                  width="fit-content"
                  justifyContent="center"
                  alignItems="center"
                >
                  <InfoOutlined />
                </Grid>
                <Grid pl={1}>{i18next.t(TK.SIGNUP_PASSWORD_REQUIREMENTS)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ShowUpMd>
        <Divider sx={styles.dividerLine} />
      </ShowUpMd>
      <Grid
        container
        direction="row"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        pt={[2, 2, 6]}
      >
        <Grid container item direction="column" lg={8} md={10} xs={12}>
          <Grid item sx={styles.labelText}>
            {i18next.t(TK.SIGNUP_YOUR_NAME)}
          </Grid>
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              flexWrap={['wrap', 'nowrap', 'nowrap']}
            >
              <Grid
                item
                lg={6}
                md={10}
                xs={12}
                pr={[0, 2, 2]}
                sx={styles.formInputContainer}
              >
                <TextField
                  {...register('firstName', { required: true })}
                  sx={styles.formInput}
                  variant="outlined"
                  autoComplete="off"
                  data-test="sign-up-first-name"
                  label={i18next.t(TK.SIGNUP_FIRST_NAME)}
                  value={watch('firstName')}
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message}
                />
              </Grid>
              <Grid item lg={6} md={10} xs={12} sx={styles.formInputContainer}>
                <TextField
                  {...register('lastName', { required: true })}
                  sx={styles.formInput}
                  variant="outlined"
                  autoComplete="off"
                  data-test="sign-up-last-name"
                  label={i18next.t(TK.SIGNUP_LAST_NAME)}
                  value={watch('lastName')}
                  error={!!errors.lastName?.message}
                  helperText={errors?.lastName?.message}
                />
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection={['column', 'row', 'row']}
              flexWrap={['wrap', 'nowrap', 'nowrap']}
            >
              <Grid container direction="column" pt={2}>
                <Grid item sx={styles.labelText}>
                  {i18next.t(TK.SIGNUP_BIRTHDAY)}
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  flexWrap={['wrap', 'nowrap', 'nowrap']}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    pr={[0, 2, 2]}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel error={!!errors.birthMonth?.message}>
                        {i18next.t(TK.SIGNUP_BIRTH_MONTH)}
                      </InputLabel>
                      <Select
                        {...register('birthMonth', { required: true })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="sign-up-birth-month"
                        placeholder={i18next.t(TK.SIGNUP_BIRTH_MONTH)}
                        error={!!errors?.birthMonth?.message}
                        value={watch('birthMonth')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.SIGNUP_BIRTH_MONTH)}
                        </Option>
                        {monthsDropdownSelectValues.map((month) => {
                          return (
                            <Option
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  birthMonth: month.key.toString(),
                                }))
                              }
                              key={month.key}
                              value={month.key}
                            >
                              {month.value}
                            </Option>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        data-test="signup-birth-month-helper"
                        error
                      >
                        {errors?.birthMonth?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                    pr={[0, 2, 2]}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel error={!!errors.birthDay?.message}>
                        {i18next.t(TK.SIGNUP_BIRTH_DAY)}
                      </InputLabel>
                      <Select
                        {...register('birthDay', { required: true })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="signup-birth-date"
                        placeholder={i18next.t(TK.SIGNUP_BIRTH_DAY)}
                        error={!!errors?.birthDay?.message}
                        value={watch('birthDay')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.SIGNUP_BIRTH_DATE)}
                        </Option>
                        {daysDropdownSelectValues.map((day) => {
                          return (
                            <Option
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  birthDay: day,
                                }))
                              }
                              selected={
                                Number(day) === Number(formData.birthDay)
                              }
                              key={day}
                              value={day}
                            >
                              {day}
                            </Option>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        data-test="signup-birth-date-helper"
                        error
                      >
                        {errors?.birthDay?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel>{i18next.t(TK.SIGNUP_BIRTH_YEAR)}</InputLabel>
                      <Select
                        {...register('birthYear', { required: false })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="sign-up-birth-year"
                        placeholder={i18next.t(
                          TK.SIGNUP_BIRTH_YEAR_PLACEHOLDER,
                        )}
                        value={watch('birthYear')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.SIGNUP_BIRTH_YEAR)}
                        </Option>
                        {yearsDropdownSelectValues.map((year) => {
                          return (
                            <Option
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  birthYear: year,
                                }))
                              }
                              key={year}
                              value={year}
                            >
                              {year}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignContent="flex-end"
          xs={12}
          md={10}
          lg={6}
          sx={styles.formInputContainer}
        >
          <Grid item pl={[0, 0, 8]} sx={styles.formInput}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              flexWrap="nowrap"
              p={2}
              sx={styles.tooltipInfo}
            >
              <Grid
                display="flex"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
              >
                <InfoOutlined />
              </Grid>
              <Grid pl={1}>{i18next.t(TK.SIGNUP_BIRTHDAY_TOOLTIP)}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container pb={4.5} pt={2.5}>
        <Grid container item lg={6.9} md={6} xs={12}>
          <Grid item sx={styles.labelText}>
            {i18next.t(TK.SIGNUP_CONTACT_INFORMATION)}
          </Grid>
          <Grid container direction="column">
            <Grid container direction="row" sx={styles.formInputContainer}>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { ref, onChange, value } }) => (
                  <TextField
                    onChange={({
                      target: { value: v },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChange) {
                        onChange(phoneModifier(v));
                      }
                    }}
                    inputRef={ref}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    autoComplete="off"
                    value={value || ''}
                    label={i18next.t(TK.SIGNUP_PHONE_NUMBER)}
                    data-test="sign-up-form-work-phone"
                    error={!!errors.phoneNumber?.message}
                    helperText={errors?.phoneNumber?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ShowUpSm>
        <Divider sx={styles.dividerLine} />
      </ShowUpSm>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        md={6}
        lg={6}
        xl={6}
        py={[2, 4, 6]}
        width={[1, 1, 0.5]}
      >
        <Grid item sx={styles.termsAndConditions} width={[1, 0.75, 1]}>
          <Typography
            dangerouslySetInnerHTML={{ __html: signUpTermsAndConditionsText }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        lg={6}
        pb={[2, 3, 4]}
        justifyContent={['center', 'center', 'flex-start']}
      >
        <Grid item>
          <Box>
            <ReCAPTCHA
              sitekey={siteKey}
              data-size="compact"
              onChange={onCaptchaChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        lg={6}
        pb={[4.5, 0, 0]}
        justifyContent={['center', 'center', 'flex-start']}
      >
        <Grid item width={['320px', '225px']} height="50px">
          <Button
            variant="contained"
            type="submit"
            data-test="signup-submit-button"
            onClick={handleSubmit(onValidForm)}
            disabled={processing || !isCaptchaValid}
            sx={styles.createAccountButton}
            startIcon={
              processing ? (
                <CircularProgress size={20} color="inherit" />
              ) : undefined
            }
          >
            {i18next.t(TK.SIGNUP_CREATE_ACCOUNT_BUTTON_TEXT)}
          </Button>
        </Grid>
      </Grid>
      {signUpIsSuccess ? <SuccessDialog /> : null}
    </Grid>
  );
};
