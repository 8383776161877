import { Status } from 'redux/types/state.interface';
import { Upsell } from 'api/products/types';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAddons,
  fetchUpgrades,
} from 'components/Cart/Details/components/AddOnUpgradeCarousel/feature/actions';

export interface AddOnUpgradeCarouselState {
  data: {
    productNumber?: number;
    upgrades?: Upsell[];
    addons?: Upsell[];
  }[];
  upgradesStatus: Status;
  addonsStatus: Status;
}

const initialState: AddOnUpgradeCarouselState = {
  data: [],
  upgradesStatus: Status.INIT,
  addonsStatus: Status.INIT,
};

const slice = createSlice({
  name: 'carousel/addOnUpgrades',
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpgrades.pending, (state) => {
        state.upgradesStatus = Status.PENDING;
      })
      .addCase(fetchAddons.pending, (state) => {
        state.addonsStatus = Status.PENDING;
      })
      .addCase(fetchUpgrades.fulfilled, (state, { payload }) => {
        const productIndex = state.data.findIndex(
          (productData) => productData.productNumber === payload.productNumber,
        );

        if (productIndex !== -1 && !state.data[productIndex].upgrades) {
          const existingProduct = state.data[productIndex];
          const updatedProduct = {
            ...existingProduct,
            upgrades: [...(existingProduct.upgrades || []), ...payload.data],
          };

          state.data = [
            ...state.data.slice(0, productIndex),
            updatedProduct,
            ...state.data.slice(productIndex + 1),
          ];
        } else {
          const newProduct = {
            productNumber: payload.productNumber,
            upgrades: payload.data,
          };

          state.upgradesStatus = Status.FULFILLED;
          state.data = [...state.data, newProduct];
        }
      })
      .addCase(fetchAddons.fulfilled, (state, { payload }) => {
        const productIndex = state.data.findIndex(
          (productData) => productData.productNumber === payload.productNumber,
        );

        if (productIndex !== -1 && !state.data[productIndex].addons) {
          const existingProduct = state.data[productIndex];
          const updatedAddOns = {
            ...existingProduct,
            addons: [...(existingProduct.addons || []), ...payload.data],
          };

          state.addonsStatus = Status.FULFILLED;
          state.data = [
            ...state.data.slice(0, productIndex),
            updatedAddOns,
            ...state.data.slice(productIndex + 1),
          ];
        } else {
          const newProduct = {
            productNumber: payload.productNumber,
            addons: payload.data,
          };

          state.addonsStatus = Status.FULFILLED;
          state.data = [...state.data, newProduct];
        }
      })
      .addCase(fetchUpgrades.rejected, (state) => {
        state.upgradesStatus = Status.ERROR;
      })
      .addCase(fetchAddons.rejected, (state) => {
        state.addonsStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  initialState,
  reducers: {},
});

export const addOnUpgradeCarouselReducer = slice.reducer;
