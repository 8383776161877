import {
  checkIsBlocked,
  getName,
} from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/feature/selectors';
import { useRelatedCategoryProvider } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/use-related-category.provider';
import { useSelector } from 'react-redux';

export const useRelatedCategoryName = (): string | null => {
  useRelatedCategoryProvider();
  const isBlocked = useSelector(checkIsBlocked);
  const name = useSelector(getName);

  return isBlocked ? null : name;
};
