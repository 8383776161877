import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';

const AUTH_ROOT = '/my-account/create-profile.aspx';

class AccountLegacyApiService extends ApiLegacyBaseService {
  private authPath(path: string): string {
    return `${AUTH_ROOT}/${path}`;
  }

  createAccount(accountDetails: string): AxiosPromise<DResponse> {
    return this.apibase.post<DResponse>(this.authPath('CreateAccount'), {
      Account: accountDetails,
      PartialUserEmail: '',
      PartialAccountID: '',
      RewardTierID: 1,
    });
  }
}
const accountLegacyApiService = new AccountLegacyApiService();
export { accountLegacyApiService };
