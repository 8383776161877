import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/Careers/styles';
import { Box, Typography } from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Image } from 'components/common/Image/Image';
import { ShowOnlyXs } from 'utils/hidden/show-only-xs';
import {
  careerLaborSectionImg1,
  careerLaborSectionImg2,
  careerLaborSectionImg3,
  careerLaborSectionImg4,
  careerLaborSectionImg5,
  careerLaborSectionImg6,
} from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const FruitLabour: FC = () => {
  return (
    <Box sx={styles.careerFruitLabour}>
      <Box width={['100%', '65%', '65%']}>
        <Typography variant="h2" sx={styles.fruitTextStyle}>
          {i18next.t(TK.CAREER_FRUITS_TEXT)}
        </Typography>
        <Typography variant="body1" fontSize="16px">
          {i18next.t(TK.CAREER_FRUITS_SUB_TEXT)}
        </Typography>
      </Box>
      <HideOnXs>
        <Box display={['block', 'flex', 'flex']} sx={styles.laborContainer}>
          <Box display="flex" width={['35%', '35%', '44%']}>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection1" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg1}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION1_TITLE1)}
                  <br />
                  {i18next.t(TK.LABOR_SUB_SECTION1_TITLE2)}
                </Typography>
              </Box>
              <Box className="laborSubSection1Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle}>
                  {i18next.t(TK.LABOR_SUB_SECTION1_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection2" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg2}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION2_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection2Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle}>
                  {i18next.t(TK.LABOR_SUB_SECTION2_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection3" display="contents">
                <Image
                  sx={styles.laborImage3Style}
                  src={careerLaborSectionImg3}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION3_TITLE1)}
                  <br />
                  {i18next.t(TK.LABOR_SUB_SECTION3_TITLE2)}
                </Typography>
              </Box>
              <Box className="laborSubSection3Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextSection3Style}>
                  {i18next.t(TK.LABOR_SUB_SECTION3_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" width={['26%', '26%', '44%']}>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection4" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg4}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION4_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection4Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle} pt={4}>
                  {i18next.t(TK.LABOR_SUB_SECTION4_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection5" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg5}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION5_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection5Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle} pt={4}>
                  {i18next.t(TK.LABOR_SUB_SECTION5_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection6" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg6}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION6_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection5Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle} pt={4}>
                  {i18next.t(TK.LABOR_SUB_SECTION6_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </HideOnXs>
      <ShowOnlyXs>
        <Box display="flex" sx={styles.laborContainer}>
          <Box display="inline-table" width="87px">
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection1" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg1}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION1_TITLE1)}
                  <br />
                  {i18next.t(TK.LABOR_SUB_SECTION1_TITLE2)}
                </Typography>
              </Box>
              <Box className="laborSubSection1Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle}>
                  {i18next.t(TK.LABOR_SUB_SECTION1_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection2" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg2}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION2_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection2Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle}>
                  {i18next.t(TK.LABOR_SUB_SECTION2_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="inline-table" width="170px">
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection3" display="contents">
                <Image
                  sx={styles.laborImage3Style}
                  src={careerLaborSectionImg3}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION3_TITLE1)}
                  <br />
                  {i18next.t(TK.LABOR_SUB_SECTION3_TITLE2)}
                </Typography>
              </Box>
              <Box className="laborSubSection3Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextSection3Style}>
                  {i18next.t(TK.LABOR_SUB_SECTION3_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection4" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg4}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION4_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection4Hover" display="none">
                <Typography
                  variant="body1"
                  sx={styles.hoverTextSection3Style}
                  pt={4}
                >
                  {i18next.t(TK.LABOR_SUB_SECTION4_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="inline-table" width="87px">
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection5" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg5}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION5_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection5Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle} pt={4}>
                  {i18next.t(TK.LABOR_SUB_SECTION5_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.laborSubSection}>
              <Box className="laborSubSection6" display="contents">
                <Image
                  sx={styles.laborImageStyle}
                  src={careerLaborSectionImg6}
                />
                <Typography variant="body1" fontSize="16px">
                  {i18next.t(TK.LABOR_SUB_SECTION6_TITLE)}
                </Typography>
              </Box>
              <Box className="laborSubSection5Hover" display="none">
                <Typography variant="body1" sx={styles.hoverTextStyle} pt={4}>
                  {i18next.t(TK.LABOR_SUB_SECTION6_HOVER_TEXT)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ShowOnlyXs>
    </Box>
  );
};
