import { NearestStore } from 'components/Home/NearestStore/types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { getNearestStore } from 'components/Home/NearestStore/feature/actions';

export interface NearestStoreState {
  data: NearestStore | null;
  status: Status;
  error: ResponseError | null;
}

const initialState: NearestStoreState = {
  data: null,
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'nearestStore',
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(initialState, getNearestStore),
});

export const nearestStoreReducer = slice.reducer;
