import { Blog, Image } from 'components/LocalSeoPage/BlogWidget/styles';
import { Typography } from '@mui/material';
import { blogCarouselClassNames } from 'components/LocalSeoPage/BlogCarousel/blogCarouselClassNames';
import React, { FC } from 'react';

interface BlogWidgetProps {
  title: string;
  link: string;
  image: string;
}

export const BlogWidget: FC<BlogWidgetProps> = ({ title, link, image }) => {
  const classes = blogCarouselClassNames;
  return (
    <Blog href={link} target="_blank">
      <Image src={image} alt={title} sx={classes.blogImage} />
      <Typography variant="subtitle2" component="p" mt={2} textAlign="center">
        {title}
      </Typography>
    </Blog>
  );
};
