import { RootState } from 'redux/store';
import { fetchArea } from 'redux/PDP/area/area.actions';
import { selectName } from 'redux/PDP/area/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useSyncSessionArea = (): void => {
  const dispatch = useDispatch();
  const sessionAreaName = useSelector(
    ({ availabilitySession: { data } }: RootState) => data.areaName,
  );
  const name = useSelector(selectName);

  useEffect(() => {
    if (sessionAreaName && sessionAreaName !== name) {
      dispatch(fetchArea(sessionAreaName));
    }
    /* Ignore name change since session update is delayed */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, sessionAreaName]);
};
