import { SxProps, Theme } from '@mui/material/styles';

export const dialogWrapper: SxProps = {
  textAlign: 'center',
  '& .MuiPaper-root': {
    maxWidth: '100%',
    width: ['100%', '668px', '700px'],
    height: ['446px', '400px', '400px'],
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    px: ['12px', '12px', '18px'],
    paddingBottom: '0px',
  },
};

export const invalidDialogWrapper: SxProps = {
  textAlign: 'center',
  '& .MuiPaper-root': {
    maxWidth: '100%',
    width: ['100%', '668px', '700px'],
    height: ['630px', '600px', '400px'],
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    px: ['12px', '12px', '18px'],
    paddingBottom: '0px',
  },
};

export const saturdayDialogWrapper: SxProps = {
  textAlign: 'center',
  '& .MuiPaper-root': {
    maxWidth: '100%',
    width: ['100%', '668px', '700px'],
    height: ['500px', '400px', '400px'],
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    px: ['12px', '12px', '18px'],
    paddingBottom: '0px',
  },
};

const basicAddress: SxProps<Theme> = {
  p: ({ spacing }) => spacing(2, 1),
  width: 1,
  maxWidth: 320,
  borderWidth: 2,
};

export const address: SxProps<Theme> = {
  ...basicAddress,
  borderColor: ({ palette }) => palette.divider,
  borderStyle: 'solid',
  '& h6': {
    marginBottom: '16px',
  },
};

export const suggestedAddress: SxProps<Theme> = {
  ...basicAddress,
  borderColor: ({ palette }) => palette.primary.main,
  borderStyle: 'solid',
  '& h6': {
    marginBottom: '16px',
  },
};

export const actions: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: ['center', 'space-evenly', 'space-evenly'],
  alignItems: 'center',
  flexDirection: ['column', 'row', 'row'],
  px: ['18px', '18px !important', '32px'],
  '&.MuiDialogActions-root>:not(:first-of-type)': {
    mt: [2, 0, 0],
  },
};

export const keepOriginalAddressButton: SxProps = {
  width: ['100%', '294px', '300px'],
  marginLeft: '0px !important',
  height: ['40px', '48px', '48px'],
};

export const updateButton: SxProps = {
  width: ['100%', '294px', '300px'],
  height: ['40px', '48px', '48px'],
};

export const icon: SxProps<Theme> = {
  my: [0, 1, 0],
  mx: [0, 0, 2],
  width: 24,
  height: 24,
  color: ({ palette }) => palette.action.disabledBackground,
  transform: [undefined, undefined, 'rotate(-90deg)'],
};

export const addressStreet: SxProps = {
  wordBreak: 'break-word',
};

export const confirmSuggestedAddressBtn: SxProps = {
  mr: [0, 0, 0],
  width: '300px',
};

export const modifyOriginalAddressBtn: SxProps = {
  mt: [1, 2, 0],
  width: '300px',
};

export const shipmentConfirmBtn: SxProps = {
  mr: [0, 0, 0],
  width: '300px',
  height: ['40px', '48px', '48px'],
};

export const shipmentRejectBtn: SxProps = {
  mt: [1, 2, 0],
  width: '300px',
  height: ['40px', '48px', '48px'],
};
