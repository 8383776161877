export const ERROR_404_COPYRIGHT = 'error.404.copyright';
export const ERROR_404_OOPS = 'error.404.oops';
export const ERROR_404_ORDER_BY_PHONE = 'error.404.by_phone';
export const ERROR_404_RIGHTS_RESERVED = 'error.404.rights';
export const ERROR_404_TEXT_1 = 'error.404.text_1';
export const ERROR_404_TEXT_2 = 'error.404.text_2';
export const ERROR_404_TEXT_3 = 'error.404.text_3';
export const ERROR_404_TITLE = 'error.404.title';
export const ERROR_500_CALL_CUSTOM_CENTER = 'error.500.call_custom_center';
export const ERROR_500_CURRENT_LOCATION = 'error.500.current_loc';
export const ERROR_500_FIND_STORE = 'error.500.find_store';
export const ERROR_500_HEADING1 = 'error.500.heading1';
export const ERROR_500_HEADING2 = 'error.500.heading2';
export const ERROR_500_NEED_CALL = 'error.500.need_call';
export const ERROR_500_TITLE = 'error.500.title';
export const ERROR_500_TURN_GEOLOCATION = 'error.500.turn_geolocation';
export const ERROR_500_WE_CAN_EMAIL = 'error.500.we_can_email';
export const ERROR_500_YOUR_EMAIL_SUBMITTED = 'error.500.your_email_submit';
export const ERROR_500_ZIP_CODE = 'error.500.zip_code';
