import * as styles from 'components/Home/GridWidget/components/Skeleton/styles';
import { Grid, Skeleton } from '@mui/material';
import { Headline } from 'components/Home/GridWidget/styles';
import { ImageSkeleton } from 'components/Home/GridWidget/components/Skeleton/ImageSkeleton';
import { Item } from 'components/Home/GridWidget/components/Skeleton/styles';
import { WidgetWrapper } from 'components/Home/common/styled';
import { range } from 'utils/range';
import React, { FC, memo } from 'react';

export const CategoryWidgetSkeleton: FC = memo(() => (
  <WidgetWrapper>
    <Headline>
      <Skeleton sx={styles.widgetTitle} />
      <Skeleton width={75} height={16} />
    </Headline>

    <Grid sx={styles.container} spacing={{ xs: 1.25, md: 2.5 }} container>
      {range(10).map((item) => (
        <Grid key={item} xs={6} md={3} item>
          <Item>
            <ImageSkeleton />
            <Skeleton sx={styles.itemTitle} />
          </Item>
        </Grid>
      ))}
    </Grid>
  </WidgetWrapper>
));
