import { Box } from '@mui/material';
import { ProductOptionSkeleton } from 'components/PDP/Details/components/ProductOptions/components/ProductOption/ProductOptionSkeleton';
import { range } from 'utils/range';
import React, { FC } from 'react';

export const ProductOptionsSkeleton: FC = () => (
  <Box display="flex" flexDirection={['column', 'row']} gap={[4, 2]}>
    {range(2).map((index) => (
      <ProductOptionSkeleton key={index} />
    ))}
  </Box>
);
