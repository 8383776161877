import { Box, SxProps } from '@mui/material';
import { Color } from 'components/common/Buttons/enums/color.enum';
import {
  FormControlLabel,
  Radio,
  RadioIcon,
} from 'components/common/Buttons/RadioButton/styled';
import { Size } from 'components/common/Buttons/enums/size.enum';
import { isNotUndefined } from 'utils/is-not-undefined';
import React, { FC, KeyboardEvent, SyntheticEvent, useContext } from 'react';
// eslint-disable-next-line no-restricted-imports
import RadioGroupContext from '@mui/material/RadioGroup/RadioGroupContext';

interface Props {
  sx?: SxProps;
  value?: unknown;
  size?: Size;
  color?: Color;
  dataTest?: string;
  labelPosition?: 'start' | 'end';
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLLabelElement>, value: string) => void;
}

export const RadioButton: FC<Props> = ({
  sx,
  value,
  size = Size.MEDIUM,
  color = Color.PRIMARY,
  labelPosition = 'start',
  dataTest,
  checked,
  disabled,
  children,
  onChange,
  onKeyDown,
}) => {
  const context = useContext(RadioGroupContext);

  const checkIsChecked = () => {
    if (disabled) {
      return false;
    }
    if (isNotUndefined(checked)) {
      return checked;
    }
    if (isNotUndefined(context)) {
      return context.value === value;
    }
    return false;
  };

  return (
    <Box sx={sx} data-test={dataTest}>
      <FormControlLabel
        sx={sx}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (onKeyDown) onKeyDown(e, String(value));
        }}
        label={children}
        labelPlacement={labelPosition}
        checked={checkIsChecked()}
        disabled={disabled}
        color={color}
        tabIndex={0}
        control={
          <Radio
            size={size}
            color={color}
            icon={<RadioIcon size={size} />}
            checkedIcon={<RadioIcon size={size} />}
            disableRipple
            disableTouchRipple
            tabIndex={-1}
          />
        }
      />
    </Box>
  );
};
