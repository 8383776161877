import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Subscription/SubscriptionSection/styles';
import { Container } from 'components/Subscription/SubscriptionSection/styles';
import { Grid, Typography } from '@mui/material';
import { Languages } from 'redux/locale/locale.types';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { SubscriptionForm } from 'components/Subscription/SubscriptionSection/SubscriptionForm';
import { loadLocale } from 'redux/locale/utils';
import React, { FC } from 'react';
import i18next from 'i18next';

export const SubscriptionSection: FC = () => {
  return (
    <RenderWhenInView>
      <Container sx={{ width: { md: '1060px', sm: '688px', xs: '360px' } }}>
        <Grid
          width="100%"
          display="inline-grid"
          justifyContent="center"
          justifyItems="center"
        >
          <Typography sx={styles.contentTitle}>
            {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE)}
          </Typography>
          <Typography
            sx={{
              ...styles.inlineContent,
              fontSize:
                loadLocale() === Languages.FRENCH
                  ? { md: '11px', sm: '11px', xs: '6px' }
                  : { md: '20px', sm: '20px', xs: '16px' },
            }}
          >
            <span>
              {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT)}
            </span>
            &nbsp;
            <span>
              <Typography
                sx={{
                  ...styles.contentInstantSavings,
                  fontSize:
                    loadLocale() === Languages.FRENCH
                      ? { md: '11px', sm: '11px', xs: '6px' }
                      : { md: '20px', sm: '20px', xs: '16px' },
                }}
              >
                {i18next.t(
                  TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT,
                )}
              </Typography>
            </span>
            &nbsp;
            {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT)}
          </Typography>
        </Grid>
        <Grid width="100%" display="flex" justifyContent="flex-start">
          <SubscriptionForm />
        </Grid>
      </Container>
    </RenderWhenInView>
  );
};
