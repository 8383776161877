import { Filter, FruitFilter } from 'api/filter-categories/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type GiftFinderFilterState = {
  filters: Filter[];
  fruitFilters: FruitFilter[];
};

export const initialState: GiftFinderFilterState = {
  filters: [],
  fruitFilters: [],
};

export const giftFinderFilterSlice = createSlice({
  name: 'gift-finder/filters',
  initialState,
  reducers: {
    setGiftFinderFilters: (state, { payload }: PayloadAction<Filter[]>) => {
      state.filters = [...state.filters, ...payload];
    },
    setGiftFinderFruitFilters: (
      state,
      { payload }: PayloadAction<FruitFilter[]>,
    ) => {
      state.fruitFilters = [...state.fruitFilters, ...payload];
    },
    clearGiftFinderFilters: (state) => {
      state.filters = [];
    },
  },
});

export const { reducer: giftFinderFilterReducer } = giftFinderFilterSlice;

export const {
  setGiftFinderFilters,
  setGiftFinderFruitFilters,
  clearGiftFinderFilters,
} = giftFinderFilterSlice.actions;
