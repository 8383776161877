import * as styles from 'components/PDP/Details/components/PasControl/components/Stepper/styles';
import {
  Accordion,
  AccordionSummary,
  Box,
  StepContext,
  StepContextType,
  Typography,
} from '@mui/material';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { Element } from 'react-scroll';
import { StepLabel } from 'components/PDP/Details/components/PasControl/components/Stepper/styled';
import { getPasStepDataTestValue } from 'components/PDP/Details/components/PasControl/utils/get-pas-step-data-test-value';
import { steps } from 'components/PDP/Details/components/PasControl/contants/steps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useContext } from 'react';

interface Props {
  availabilityStep: AvailabilityStep;
  setActiveStep: (d: number) => void;
  clickable: boolean;
  preview?: string | JSX.Element;
}

export const StepSummary: FC<Props> = ({
  children,
  availabilityStep,
  setActiveStep,
  clickable,
  preview,
}) => {
  const { active, completed } =
    useContext<Partial<StepContextType>>(StepContext);

  const isStepAvailableForReturn = clickable && !active && !completed;
  const isStepClickable = clickable;

  return (
    <Element name={AvailabilityStep[availabilityStep]}>
      <Accordion sx={styles.accordion} expanded={active}>
        <StepLabel
          onClick={() =>
            isStepClickable && setActiveStep(steps[availabilityStep])
          }
          returnable={isStepAvailableForReturn}
          data-test={getPasStepDataTestValue(availabilityStep, 'summary')}
        >
          <AccordionSummary
            sx={styles.summary}
            expandIcon={<ExpandMoreIcon />}
            disabled={!completed && !active && !clickable}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={1}
              mr={1.5}
            >
              <Typography
                variant="subtitle3"
                sx={{ maxWidth: [240, 'unset'], fontSize: 16 }}
              >
                {children}
              </Typography>
              {preview && (
                <Typography
                  variant="body2"
                  sx={styles.preview}
                  data-test={getPasStepDataTestValue(
                    availabilityStep,
                    'preview',
                  )}
                >
                  {preview}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
        </StepLabel>
      </Accordion>
    </Element>
  );
};
