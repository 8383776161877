export const disableNextFiveDays = (calDate: Date): boolean => {
  const currentDay = new Date();

  // Check if calDate is Sunday or Monday
  if (calDate.getDay() === 0 || calDate.getDay() === 1) {
    return true;
  }

  // Check if calDate is today or within the next 4 days
  // 6 Days from Current Date will be disabled.e.g: Current Date is October 25th 2024 then Next Enabled Date will be October 31st 2024
  for (let i = 0; i <= 5; i += 1) {
    const nextDate = new Date(currentDay);
    nextDate.setDate(currentDay.getDate() + i);
    if (
      nextDate.getDate() === calDate.getDate() &&
      nextDate.getMonth() === calDate.getMonth() &&
      nextDate.getFullYear() === calDate.getFullYear()
    ) {
      return true;
    }
  }

  return false;
};
