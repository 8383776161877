import { segment } from 'service/segment/segment';
import {
  selectIsBlocked,
  selectIsLoggedIn,
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useSegment = (): void => {
  const sessionBlocked = useSelector(selectIsBlocked);
  const userLoggedIn = useSelector(selectIsLoggedIn);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    if (!sessionBlocked && userLoggedIn && userSession) {
      segment.identified(userSession, userAccountInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);
};
