import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isError, isInit } from 'utils/status.comparer';

export const checkIsInit = createSelector(
  ({ settings: { status } }: RootState) => status,
  (status) => isInit(status),
);

export const checkIsBlocked = createSelector(
  ({ settings: { status } }: RootState) => status,
  (status) => isBlocked(status),
);

export const selectIsRejected = createSelector(
  ({ settings: { status } }: RootState) => status,
  (status) => isError(status),
);

export const payPalEnabled = ({ settings: { data } }: RootState) =>
  data.enablePaymentMethodPayPal;

export const payPalVersion = ({ settings: { data } }: RootState) =>
  data.usePayPalVersion;
export const nxtGenCartStoreNumberSetting = ({
  settings: { data },
}: RootState) => data.enableNxtGenCartStoreNumberSetting;

export const printibleStrikeThrough = ({ settings: { data } }: RootState) =>
  data.enablePrintibleStrikeThrough;
