import { Reset } from 'components/Authentication/Reset/Reset';
import { ResetAlert } from 'components/Authentication/Reset/ResetAlert';
import { Slide } from '@mui/material';
import { selectIsResetMode } from 'components/Authentication/LogIn/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const ResetPasswordContent: FC = () => {
  const isReset = useSelector(selectIsResetMode);

  if (!isReset) {
    return null;
  }

  return (
    <Slide in={isReset} direction="right">
      <div>
        <ResetAlert />
        <Reset />
      </div>
    </Slide>
  );
};
