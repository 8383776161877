import * as LTK from 'components/Subscription/translations/locale.constants';
import * as React from 'react';
import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Subscription/SubscriptionSection/SubscriptionForm/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Container } from 'components/Subscription/SubscriptionSection/styles';
import { Controller, useForm } from 'react-hook-form';
import { HeaderLinks } from 'enums/header-links';
import { LoadingButton } from '@mui/lab';
import { Promo, PromoModule } from 'service/adobe-analytics/enums';
import { analytics } from 'service/adobe-analytics/analytics';
import { dataLayer } from 'hooks/google/data-layer';
import { dynamicEvents } from 'service/dynamic-yield';
import {
  getEmailSubscriptionStatus,
  getPhoneSubscriptionStatus,
} from 'components/Subscription/feature/selectors';
import { isFulfilled, isPending } from 'utils/status.comparer';
import { phoneModifier } from 'utils/phone-modifier';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { signUpSubscriptionFormSchema } from 'components/Subscription/schemas/subscription-form.schema';
import { useEffect } from 'react';
import { useIsDownSm } from 'utils/hidden/use-is-down-sm';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';

interface SubscriptionFormData {
  email: string;
  phone: string;
  isAgreeWithPolicy: boolean;
}

interface SubscriptionFormProps {
  onSubmit: (data: SubscriptionFormData) => void;
}

export const SignUpForm: React.FC<SubscriptionFormProps> = ({ onSubmit }) => {
  const resolver = yupResolver(signUpSubscriptionFormSchema);
  const isSmallScreen = useIsDownSm();
  const defaultValues: SubscriptionFormData = {
    email: '',
    phone: '',
    isAgreeWithPolicy: false,
  };

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm<SubscriptionFormData>({
    defaultValues,
    mode: 'onBlur', // Trigger validation on blur
    resolver,
  });

  const emailStatus = useSelector(getEmailSubscriptionStatus);
  const phoneStatus = useSelector(getPhoneSubscriptionStatus);

  const { pathname } = useLocation();
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    if (
      isSubmitSuccessful &&
      (isFulfilled(emailStatus) || isFulfilled(phoneStatus))
    ) {
      const promo = getValues('phone') ? Promo.EmailnPhone : Promo.Email;
      dataLayer.push({
        event: 'newSubscriptionSignUp',
        newSubscriptionSignUp: {
          email: getValues('email'),
          path: pathname,
        },
      });
      analytics.promoSignUp(promo, PromoModule.InPage);
      segment.marketingOptIn(
        getValues('email'),
        'Email',
        userSession,
        userAccountInformation,
      );
      segment.identify(getValues('email'));
      dynamicEvents.identifyUser(getValues('email'));
      if (getValues('phone')) {
        segment.marketingOptIn(
          getValues('phone'),
          'SMS',
          userSession,
          userAccountInformation,
        );
      }
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailStatus, phoneStatus, isSubmitSuccessful, reset]);

  return (
    <Container as="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid
        sx={{ width: { xs: '90%', sm: 'calc(100% + 16px)' } }}
        container
        direction={isSmallScreen ? 'column' : 'row'}
        spacing={2}
      >
        <Grid item xs={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={`${i18next.t(TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL)}*`}
                autoComplete="off"
                InputProps={{
                  sx: styles.inputWrapper,
                }}
                InputLabelProps={{ sx: styles.inputLabel }}
                FormHelperTextProps={{ sx: styles.helperText }}
                error={Boolean(errors.email)}
                helperText={
                  errors.email
                    ? i18next.t(TK.SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR)
                    : ''
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <TextField
                {...field}
                fullWidth
                type="tel"
                label={i18next.t(TK.SUBSCRIPTION_FORM_PHONE_LABEL)}
                InputProps={{
                  sx: styles.inputWrapper,
                }}
                InputLabelProps={{ sx: styles.inputLabel }}
                FormHelperTextProps={{ sx: styles.helperText }}
                error={Boolean(field.value && errors.phone)}
                helperText={
                  field.value && errors.phone
                    ? i18next.t(TK.SUBSCRIPTION_FORM_PHONE_ERROR)
                    : ''
                }
                autoComplete="off"
                onChange={(event) => {
                  onChange(phoneModifier(event.target.value));
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingTop:
              errors.email || errors.phone ? '0 !important' : 'inherit',
          }}
        >
          <FormControlLabel
            control={
              <Controller
                name="isAgreeWithPolicy"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    data-test="policy-cb"
                    {...field}
                    checked={!!field.value}
                    size="small"
                  />
                )}
              />
            }
            sx={styles.policy}
            label={
              <Box
                sx={{
                  display: {
                    xs: 'inline-flex',
                    md: 'inline-flex',
                    sm: 'inline-flex',
                  },
                  flexWrap: {
                    sm: 'wrap',
                    xs: 'wrap',
                  },
                }}
              >
                <Typography sx={styles.policyLabel} variant="body2">
                  {i18next.t(TK.SUBSCRIPTION_FORM_POLICY_LABEL)}
                </Typography>
                &nbsp;
                <Typography
                  component="a"
                  href={HeaderLinks.LEGAL_TERMS}
                  target="_blank"
                  sx={styles.termsOfUse}
                  variant="body2"
                  data-test="policy-tou"
                >
                  {i18next.t(TK.TERMS_OF_USE_LABEL)},
                </Typography>
                &nbsp;
                <Typography
                  component="a"
                  href={HeaderLinks.PROMOTION_TERMS_CONDITIONS}
                  target="_blank"
                  sx={styles.TermsAndConditions}
                  variant="body2"
                  data-test="policy-tac"
                >
                  {i18next.t(LTK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS)}
                </Typography>
                <Typography sx={styles.policyLabel} variant="body2">
                  &nbsp;
                  {i18next.t(LTK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND)}
                </Typography>
                &nbsp;
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={99999}
                  arrow
                  componentsProps={styles.tooltipStyle}
                  title={
                    <Typography sx={styles.tooltipTextStyle}>
                      {i18next.t(TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP)}
                    </Typography>
                  }
                >
                  <Typography
                    data-test="policy-cp"
                    sx={styles.policyTooltipLabel}
                    variant="body2"
                  >
                    {i18next.t(TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL)}
                  </Typography>
                </Tooltip>
              </Box>
            }
          />
          {errors.isAgreeWithPolicy && (
            <FormHelperText sx={styles.helperText} error>
              {i18next.t(TK.SUBSCRIPTION_SIGNUP_POLICY_ERROR)}
            </FormHelperText>
          )}
        </Grid>

        <Grid
          container
          item
          xs={12}
          columnSpacing={0}
          flexDirection={isSmallScreen ? 'row' : 'column'}
          justifyContent="center"
          sx={{ paddingBottom: { md: '16px', sm: '16px' } }}
        >
          {!isSmallScreen && <Grid item md={4} sm={2} width="33%" />}
          <Grid item md={4} sm={8} xs={12} width="100%">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ borderRadius: '0px' }}
              loading={isPending(emailStatus) || isPending(phoneStatus)}
              disabled={Object.keys(errors).length > 0}
              data-test="subscription-form-submit"
            >
              {i18next.t(LTK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL)}
            </LoadingButton>
          </Grid>
          {!isSmallScreen && <Grid item md={4} sm={2} width="33%" />}
        </Grid>
      </Grid>
    </Container>
  );
};
