import { ApiBaseService } from 'api/apibase.service';
import {
  AreaSearchResponse,
  StoreFinderRequest,
  StoreFinderResponse,
  StoresComingSoonResponse,
} from 'components/StoreFinder/interfaces/store-finder-page.interfaces';
import { AxiosPromise } from 'axios';
import { Coordinates, NearestStore } from 'components/Home/NearestStore/types';
import {
  ShipmentStoreTaxHistory,
  StoreRankingParams,
  StoreTaxDetails,
} from 'components/Cart/Details/components/OrderSummary/feature/types';
import { Store } from 'components/PDP/Order/RecipientForm/PickupForm/types/store.interface';
import { StoreDetailsResponse } from 'interfaces/store-details-page.interfaces';
import { StoreOccasionHoursAndAvailabilitiesResponse } from 'interfaces/store-occasion-hours-and-availabilities.interface';
import { StoreSchedule } from 'redux/PDP/stores/schedule/stores-schedule.types';
import { StoresByStateData } from 'components/LocalSeoPage/State/types';
import { formatSQLDate } from 'utils/get-sql-date-format';

class StoresApiService extends ApiBaseService {
  getStoreSchedule(id: number, date: Date): AxiosPromise<StoreSchedule> {
    return this.apibase.get<StoreSchedule>(`stores/${id}/schedule`, {
      params: { date: formatSQLDate(date) },
    });
  }

  async getShipmentStoreTaxHistory(
    filter: StoreTaxDetails,
  ): Promise<ShipmentStoreTaxHistory[]> {
    const { data } = await this.apibase.get<ShipmentStoreTaxHistory[]>(
      `stores/get-shipment-store-tax-history?date=${filter.date.toString()}&storeIdList=${
        filter.storeIdList
      }`,
    );

    return data;
  }

  async getStore(id: number): Promise<Store> {
    const { data } = await this.apibase.get<Store>(`stores/${id}`);
    return data;
  }

  async getNearestStore(coordinates: Coordinates): Promise<NearestStore> {
    const { data } = await this.apibase.get<NearestStore>(
      'stores/search/nearest-store',
      {
        params: {
          ...coordinates,
          day: new Date().getDay(),
        },
      },
    );

    return data;
  }

  async getStoresByState(state: string): Promise<StoresByStateData> {
    const { data } = await this.apibase.get<StoresByStateData>(
      `stores/by-state/${state}`,
    );

    return data;
  }

  async getStoreFinderStores(
    storeFinderRequest: StoreFinderRequest,
  ): Promise<StoreFinderResponse> {
    const { latitude, longitude, area } = storeFinderRequest;
    const { data } = await this.apibase.get<StoreFinderResponse>(
      `stores/store-finder/get-stores`,
      {
        params: { latitude, longitude, area },
      },
    );

    return data;
  }

  async getStoreOccasionHoursAndStoreAvailabilities(
    storeIds: string,
  ): Promise<StoreOccasionHoursAndAvailabilitiesResponse> {
    const { data } =
      await this.apibase.post<StoreOccasionHoursAndAvailabilitiesResponse>(
        `stores/store-finder/get-store-special-hours`,
        {
          storeIdsList: storeIds,
        },
      );

    return data;
  }

  async getStoresComingSoon(): Promise<StoresComingSoonResponse[]> {
    const { data } = await this.apibase.get<StoresComingSoonResponse[]>(
      `stores/coming-soon`,
    );
    return data;
  }

  async getAreasBasedOnSearch(
    searchText: string,
  ): Promise<AreaSearchResponse[]> {
    const { data } = await this.apibase.get<AreaSearchResponse[]>(
      `stores/by-area/${searchText}`,
    );

    return data;
  }

  async getStoreDetailByFriendlyUrl(
    friendlyUrl: string,
  ): Promise<StoreDetailsResponse> {
    const { data } = await this.apibase.post<StoreDetailsResponse>(
      `stores/details/${friendlyUrl}`,
    );

    return data;
  }

  async getStoreComingSoonDetails(
    storeId: string,
  ): Promise<StoreDetailsResponse> {
    const { data } = await this.apibase.post<StoreDetailsResponse>(
      `stores/details/store-coming-soon/${storeId}`,
    );

    return data;
  }

  async getStoreRanking(storeList: string): Promise<number> {
    const rankedStore = await this.apibase.get(
      `stores/getStoreRanking/${storeList}`,
    );
    return rankedStore.data as number;
  }

  updateStoreRankingDetails(data: StoreRankingParams[]): AxiosPromise<string> {
    return this.apibase.post<string>('stores/update-store-ranking', data);
  }
}

const storesApiService = new StoresApiService();

export { storesApiService };
