import { Banner } from 'api/banner/types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getSkinny } from 'components/Header/Banner/feature/actions';

export type BannersState = {
  skinny: Banner[];
  status: Status;
  error: ResponseError | null;
};

const initialState: BannersState = {
  skinny: [],
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'skinny',
  initialState,
  reducers: {
    setBannersInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkinny.pending, () => ({
        ...initialState,
        status: Status.PENDING,
        error: null,
      }))
      .addCase(getSkinny.rejected, (state, { payload }) => {
        state.skinny = [];
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addCase(getSkinny.fulfilled, (state, { payload }) => {
        state.skinny = payload;
        state.status = Status.FULFILLED;
        state.error = null;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: bannerReducer } = slice;
