import { getOrderConfirmation } from 'redux/order-confirmation/order-confirmation.action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useFetchOrderDetails = (): void => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const saleId = Number(params.get('SaleID'));

  useEffect(() => {
    if (saleId) {
      dispatch(getOrderConfirmation(saleId));
    }
  }, [saleId]);
};
