import * as TK from 'components/OrderConfirmation/translations/locale.constants';
import * as styles from 'components/OrderConfirmation/OrderType/style';
import { Box, Typography } from '@mui/material';
import { Order } from 'redux/order-confirmation/types/types';
import { formatToReadableDateTimeFormatAMPM } from 'utils/date';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  order: Order;
}

export const Pickup: FC<Props> = ({ order }) => {
  const curbPickUpInfo = order.CurbSideInstruction;
  const vehicleName = curbPickUpInfo
    ?.split('Car Model/Color: ')[1]
    .split('/')[0];
  const vehicleColor = curbPickUpInfo
    ?.split('Car Model/Color: ')[1]
    .split('/')[1];
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={styles.wrapper}
      key={order.orderID}
    >
      <Typography sx={styles.title}>
        {i18next.t(TK.PICKUP_INFO_TITLE)}
      </Typography>
      <Box mb={[3, 3, 0]} className="rightStyle">
        <Typography sx={styles.infoTitle}>
          {i18next.t(TK.PICKUP_INFO_METHOD)}
        </Typography>
        {order.isCurbSide === false && (
          <Typography sx={styles.infoText}>
            {i18next.t(TK.PICKUP_IN_STORE)}
          </Typography>
        )}
        {order.isCurbSide === true && (
          <Box>
            <Typography sx={styles.infoText}>
              {i18next.t(TK.CURB_PICKUP)}
            </Typography>
            <Typography sx={styles.infoText}>
              Vehicle Model - {vehicleName}
            </Typography>
            <Typography sx={styles.infoText}>
              Vehicle Color - {vehicleColor}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mb={[3, 3, 0]} className="leftStyle">
        <Typography sx={styles.infoTitle}>
          {i18next.t(TK.PICKUP_INFO_DATE)}
        </Typography>
        <Typography sx={styles.infoText}>
          {formatToReadableDateTimeFormatAMPM(new Date(order.fulfillmentDate))}
        </Typography>
      </Box>
      <Box mb={[3, 3, 0]} className="rightStyle">
        <Typography sx={styles.infoTitle}>
          {i18next.t(TK.PICKUP_INFO_INSTRUCTION)}
        </Typography>
        <Typography sx={styles.infoText}>
          {order.deliveryInstruction !== ''
            ? order.deliveryInstruction
            : i18next.t(TK.NO_INSTRUCTIONS)}
        </Typography>
      </Box>
      <Box mb={[3, 3, 0]} className="rightStyle">
        <Typography sx={styles.infoTitle}>
          {i18next.t(TK.PICKUP_INFO_NAME)}
        </Typography>
        <Typography sx={styles.infoText}>{order.recipientName}</Typography>
        <Typography sx={styles.infoText}>{order.workPhone}</Typography>
        <Typography sx={styles.infoText}>{order.homePhone}</Typography>
        <Typography sx={styles.infoText}>{order.cellPhone}</Typography>
      </Box>
      <Box mb={[3, 3, 0]} className="leftStyle">
        <Typography sx={styles.infoTitle}>
          {i18next.t(TK.PICKUP_INFO_FROM)}
        </Typography>
        <Typography sx={styles.infoText} className="infoCont">
          {order.formalName}
        </Typography>
        <Typography sx={styles.infoText} className="infoCont">
          {order.storeAddress1}
        </Typography>
        <Typography sx={styles.infoText} className="infoCont">
          {order.storeCity}, {order.storeStateAbbreviation} {order.storeArea}
        </Typography>
        <Typography sx={styles.infoText}>{order.storePhone}</Typography>
      </Box>
    </Box>
  );
};
