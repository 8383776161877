import { ApiBaseService } from 'api/apibase.service';
import { DynamicPageByURL } from 'api/dynamic/dynamic-page.type';

class DynamicApiService extends ApiBaseService {
  findByDynamicPageUrl(pageFriendlyUrl: string): Promise<DynamicPageByURL[]> {
    return this.apibase
      .get<DynamicPageByURL[]>(`/dynamic-page/${pageFriendlyUrl}`)
      .then(({ data }) => data);
  }

  findByDynamicPageUrlByRootPath(
    pageFriendlyUrl: string,
    rootPath: string,
  ): Promise<DynamicPageByURL[]> {
    return this.apibase
      .get<DynamicPageByURL[]>(`/dynamic-page/${pageFriendlyUrl}/${rootPath}`)
      .then(({ data }) => data);
  }
}

const dynamicApiService = new DynamicApiService();

export { dynamicApiService };
