import { IconButton, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { FC } from 'react';

interface Props {
  arrow: boolean;
  text: string;
  color?: string;
}

export const ListItemContent: FC<Props> = ({ arrow, text, color }) => (
  <>
    <Typography variant="subtitle2" color="secondary" sx={{ color }}>
      {text}
    </Typography>
    {arrow && (
      <IconButton color="secondary" component="button">
        <ArrowForwardIcon />
      </IconButton>
    )}
  </>
);
