import * as TK from 'components/Header/HeaderNavigation/translations/header-navigation.locale.constants';
import { Button, Divider } from '@mui/material';
import { Links } from 'enums/common-links';
import { viewAllButton } from 'components/Header/HeaderNavigation/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ViewAllButton: FC = () => (
  <>
    <Divider />
    <Button color="primary" href={Links.FruitArrangements} sx={viewAllButton}>
      {i18next.t(TK.VIEW_ALL_PRODUCTS)}
      <ArrowForwardIcon sx={{ ml: 1 }} />
    </Button>
  </>
);
