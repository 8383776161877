import { root } from 'utils/root';
import { useRef } from 'react';

export const useScrollBlock = (): [() => void, () => void] => {
  const scrollBlocked = useRef(false);

  const blockScroll = (): void => {
    if (!root || scrollBlocked.current) {
      return;
    }

    root.style.overflow = 'hidden';
    scrollBlocked.current = true;
  };

  const allowScroll = (): void => {
    if (!root || !scrollBlocked.current) {
      return;
    }

    root.style.overflow = '';
    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};

export const useScrollBlockHome = (): [() => void, () => void] => {
  const scrollBlocked = useRef(false);

  const blockScrollHome = (): void => {
    if (!root || scrollBlocked.current) {
      return;
    }

    root.style.overflow = '';
    scrollBlocked.current = true;
  };

  const allowScrollHome = (): void => {
    if (!root || !scrollBlocked.current) {
      return;
    }

    root.style.overflow = '';
    scrollBlocked.current = false;
  };

  return [blockScrollHome, allowScrollHome];
};
