import { IS_MOBILE_HOST } from 'utils/is-mobile-host';

export const getScript = (id: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'google-tag-manager-script';
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${id}');`;
  return script;
};

export const getNoScript = (id: string): HTMLElement => {
  const noscript = document.createElement('noscript');
  noscript.dataset.test = 'google-tag-manager-noscript';
  noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  return noscript;
};

export const getId = (): string | undefined =>
  IS_MOBILE_HOST
    ? window.GOOGLE_TAG_MANAGER_MOBILE_ID
    : window.GOOGLE_TAG_MANAGER_ID;
