import { Area } from 'api/area/types/area.interface';
import { ArrangementPreview } from 'api/arrangements/types';
import { Links } from 'enums/common-links';
import { OrderType } from 'types/cart.types';
import { appInsights } from 'utils/telemetry/app-insights';
import { emptyFunction } from 'utils/emptyFunction';
import { formatSQLDate } from 'utils/get-sql-date-format';
import {
  getIsError,
  selectOrderType,
} from 'components/Session/feature/selectors';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { selectIsSessionFinished } from 'redux/session/availability/availability.selectors';
import { updateAvailabilitySession } from 'redux/session/availability/availability.action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

type SuggestedProductClickHandler = (
  event: React.MouseEvent,
  arrangement?: ArrangementPreview,
) => void;

export const useSelectSuggestedProduct = (
  orderType: OrderType,
  date: Date | null,
  area: Area | null,
  onBeforeNavigation = emptyFunction,
): SuggestedProductClickHandler => {
  const dispatch = useDispatch();
  const [selectedSuggested, setSelectedSuggested] =
    useState<ArrangementPreview | null>(null);
  const navigate = useNavigate();
  const [navigateToList, setNavigateToList] = useState<boolean>(false);
  const sessionOrderType = useSelector(selectOrderType);
  const sessionError = useSelector(getIsError);
  const isFinished = useSelector(selectIsSessionFinished);

  const lockOrderType = () => {
    if (area) {
      dispatch(
        updateAvailabilitySession({
          selectedDate: date,
          areaId: area.id,
          areaName: area.name,
          serviceOption: orderType,
        }),
      );
    }
  };

  useEffect(() => {
    if (navigateToList && isFinished) {
      const dateGuard = date ? formatSQLDate(date) : '';
      if (dateGuard === '2024-05-11') {
        window.location.href = '/mothers-day-shipping-collection';
      } else {
        window.location.href =
          orderType === OrderType.Shipment
            ? Links.ShippingProducts
            : Links.FruitArrangements;
      }
    }
  }, [navigateToList, isFinished]);

  useEffect(() => {
    if (selectedSuggested && (sessionOrderType || sessionError)) {
      const url = getPdpPath(selectedSuggested?.url);
      appInsights.trackEvent({
        name: 'PAS suggested product selected',
        properties: { product: selectedSuggested.name },
      });
      if (selectedSuggested?.newPDP) {
        onBeforeNavigation();
        navigate(url);
      } else {
        window.location.href = url;
      }
    }
  }, [
    selectedSuggested,
    sessionOrderType,
    sessionError,
    navigate,
    onBeforeNavigation,
  ]);

  return (event, arrangement) => {
    event.preventDefault();
    lockOrderType();
    if (arrangement) {
      setSelectedSuggested(arrangement);
    } else {
      setNavigateToList(true);
    }
  };
};
