import * as TK from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductDetails/translations/marketplace-product-details.locale.constants';
import * as styles from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductDetails/styles';
import { ARRANGEMENT_TITLE_ELEMENT } from 'components/PDP/Details/constants';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { BrandValues } from 'components/PDP/Details/components/Marketplace/components/BrandValues/BrandValues';
import { Element } from 'react-scroll';
import { Image } from 'components/common/Image/Image';
import { LocalShipping } from '@mui/icons-material';
import { ProductCode } from 'components/PDP/Details/components/ProductCode/ProductCode';
import { ProductDescription } from 'components/PDP/Details/components/Marketplace/components/ProductDescription/ProductDescription';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { Title } from 'components/PDP/Details/components/Title/Title';
import { addCurrency } from 'utils/add-currency';
import {
  checkIsBlocked,
  selectArrangement,
  selectCatalogNumber,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { colors } from 'utils/theme/colors';
import { poweredByGoodyBadge } from 'utils/cloudinaryAssets';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import i18next from 'i18next';

interface MarketplaceProductDetailsProps {
  isLoading: boolean;
}

export const MarketplaceProductDetails: FC<MarketplaceProductDetailsProps> = ({
  isLoading,
}: MarketplaceProductDetailsProps) => {
  const theme = useTheme();
  const arrangement = useSelector(selectArrangement);
  const arrangementPending = useSelector(checkIsBlocked);
  const catalogNumber = useSelector(selectCatalogNumber);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Backdrop opened={isLoading} />
      <Box sx={styles.header}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ mb: 1 }}
            color={colors.primaryRed}
          >
            {arrangement.marketplaceArrangementAttributes?.subBrandName}
          </Typography>
          <Box sx={styles.marketplaceProductTitle}>
            <Element name={ARRANGEMENT_TITLE_ELEMENT}>
              <Title title={arrangement.name} loading={arrangementPending} />
            </Element>
          </Box>
          <ProductCode code={catalogNumber} loading={arrangementPending} />

          <Typography
            variant="h6"
            sx={styles.marketPlacePrice}
            color={colors.primaryRed}
          >
            {addCurrency(arrangement.products?.[0]?.price)}
          </Typography>

          <Box sx={styles.shippingRow}>
            <LocalShipping sx={styles.shippingIcon} />
            {typeof arrangement.marketplaceArrangementAttributes
              ?.shippingPrice === 'number' && (
              <Typography variant="subtitle2">
                {addCurrency(
                  arrangement.marketplaceArrangementAttributes.shippingPrice,
                )}{' '}
                {i18next.t(TK.MARKETPLACE_SHIPPING)}{' '}
              </Typography>
            )}
            <Typography variant="body2">
              {' '}
              {i18next.t(TK.MARKETPLACE_SHIPPING_TIME)}
            </Typography>
          </Box>
          <ShowUpMd>
            <ProductDescription description={arrangement.description} />
          </ShowUpMd>
        </Box>
        <Box sx={styles.badgeContainer}>
          <Image src={poweredByGoodyBadge} sx={styles.badge} />
        </Box>
      </Box>
      {isDesktop && <BrandValues />}
    </>
  );
};
