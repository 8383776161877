import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  existingEmail: string;
}

const initialState: State = { existingEmail: '' };

const slice = createSlice({
  name: 'options/billingForm',
  initialState,
  reducers: {
    checkExistingEmail: (state, { payload }: PayloadAction<State>) => {
      state.existingEmail = payload.existingEmail;
    },
  },
});

export const billingFormOptionsReducer = slice.reducer;
export const { checkExistingEmail } = slice.actions;
