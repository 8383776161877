import { IconButton, styled } from '@mui/material';
import { colors } from 'utils/theme/colors';

export const CustomizedControl = styled(IconButton)(({ theme }) => ({
  '&.slick-arrow': {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    transform: 'none',
    zIndex: 1,
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${colors.systemBorder}`,
    transition: '.2s',
    [theme.breakpoints.up('sm')]: {
      width: '50px',
      height: '50px',
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      opacity: 0.6,
    },
    '&:before': {
      content: 'none',
    },
  },
}));
