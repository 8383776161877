import {
  Dialog,
  INVALID_DIALOG,
  SATURDAY_SHIPMENT_DIALOG,
  UNKNOWN_DIALOG,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/utils';
import { getResult } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { isInUnknown, isInvalid } from 'api/fulfillment/utils';
import { useSelector } from 'react-redux';

export const useDeterminedDialog = (
  isSaturdayNonResidentialShipment = false,
): Dialog | undefined => {
  const selectedValidationStatus = useSelector(getResult);

  if (!selectedValidationStatus) {
    return undefined;
  }

  return (
    (isSaturdayNonResidentialShipment && SATURDAY_SHIPMENT_DIALOG) ||
    (isInvalid(selectedValidationStatus) && INVALID_DIALOG) ||
    (isInUnknown(selectedValidationStatus) && UNKNOWN_DIALOG) ||
    INVALID_DIALOG
  );
};
