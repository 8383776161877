import { AreaSearchResponse } from 'components/StoreFinder/interfaces/store-finder-page.interfaces';

export const getUniqueResults = (
  results: AreaSearchResponse[],
  areaValue: string,
): AreaSearchResponse[] => {
  const uniqueMap: Record<string, boolean> = {};
  const uniqueFiltered: AreaSearchResponse[] = [];
  const uniqueRemaining: AreaSearchResponse[] = [];

  results.forEach((result) => {
    const uniqueKey = `${result.cityName}${result.stateCode}`;
    if (!uniqueMap[uniqueKey]) {
      uniqueMap[uniqueKey] = true;
      if (result.stateName.toLowerCase() === areaValue.toLowerCase()) {
        uniqueFiltered.push(result);
      } else {
        uniqueRemaining.push(result);
      }
    }
  });

  return [...uniqueFiltered, ...uniqueRemaining];
};
