import { ALL_ARRANGEMENTS_FILTER_VALUE } from 'api/arrangements/arrangement.api.service';
import {
  FILTER_QUERY_KEY,
  FRUIT_QUERY_KEY,
} from 'containers/PLPContainer/query.constants';
import {
  checkIsBlocked,
  getFilters,
  getFruitFilters,
} from 'components/PLP/FilterCategories/feature/selectors';
import { selectFilter } from 'redux/PLP/filter/selectors';
import { useEffect } from 'react';
import { useQueryParameterSetter } from 'containers/PLPContainer/hooks/query/use-query-parameter-setter';
import { useQueryParameters } from 'hooks/use-query-parameter';
import { useSelector } from 'react-redux';

export const useBadFiltersSanitizer = (): void => {
  const setQueryParam = useQueryParameterSetter();
  const filterParam = useQueryParameters(FILTER_QUERY_KEY);
  const fruitParam = useQueryParameters(FRUIT_QUERY_KEY);
  const blocked = useSelector(checkIsBlocked);
  const pageFilter = useSelector(selectFilter);
  const filters = useSelector(getFilters);
  const fruitFilters = useSelector(getFruitFilters);

  useEffect(() => {
    if (blocked || !pageFilter?.value) {
      return;
    }

    if (pageFilter?.value) {
      const allowed = [
        ...filters.map(({ Value }) => Value),
        ALL_ARRANGEMENTS_FILTER_VALUE,
        pageFilter.value,
      ];
      const fruitAllowed = [
        ...fruitFilters.map(({ ProductGroupID }) => ProductGroupID.toString()),
      ];
      const real = filterParam.filter((param) => allowed.includes(param));
      const realFr = fruitParam.filter((frparam) =>
        fruitAllowed.includes(frparam),
      );
      if (real.length !== filterParam.length) {
        setQueryParam(FILTER_QUERY_KEY, real);
      }
      if (realFr.length !== fruitParam.length) {
        setQueryParam(FRUIT_QUERY_KEY, realFr);
      }
    }
  }, [
    blocked,
    filterParam,
    fruitParam,
    filters,
    pageFilter,
    setQueryParam,
    fruitFilters,
  ]);
};
