import { Decorator } from 'utils/decorator';
import { chunk } from 'utils/string/chunk';

export function breakLargeWords(maxWordLength: number): Decorator<string> {
  return (text) => {
    const sanitizedText = text.replace(/\r/g, '');

    return sanitizedText
      .split('\n')
      .map((line) =>
        line
          .split(' ')
          .map((word) => chunk(word, maxWordLength).join(' '))
          .join(' '),
      )
      .join('\n');
  };
}
