import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';

export const textFieldWrapper: SxProps = {
  maxWidth: 'auto',
  flexDirection: ['column', 'row', 'row'],
  width: 1,
  justifyContent: 'space-between',
  mt: 3,
};

export const passwordText: SxProps = {
  marginRight: '20px',
  width: ['320px', '358px', '358px'],
  mt: 2.5,
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
};

export const formInput: SxProps = {
  width: '100%',
};

export const tooltipInfo: SxProps = {
  backgroundColor: colors.toolTip,
  fontFamily: fonts.poppins,
  fontSize: '14px',
  fontWeight: '400',
  color: colors.black,
};
export const passwordBtnReject: ThemedSxProps = () => ({
  width: ['320px', '200px', '200px'],
  height: '56px',
  marginTop: ['20px', '0', '0'],
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
});

export const passwordBtnSelect: ThemedSxProps = () => ({
  width: ['320px', '200px', '200px'],
  height: '56px',
  marginTop: ['0', '2px', '0'],
  marginRight: '10px',
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
});

export const passwordWrapper: ThemedSxProps = () => ({
  display: ['block', 'flex', 'flex'],
  marginTop: '10px',
});
