import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SeoMetaData = {
  title: string;
  description: string;
  keywords: string;
};

const initialState: SeoMetaData = {
  title: '',
  description: '',
  keywords: '',
};

const slice = createSlice({
  name: 'local-seo',
  initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<Partial<SeoMetaData>>) => ({
      ...state,
      ...payload,
    }),
    clearAndSet: (state, { payload }: PayloadAction<Partial<SeoMetaData>>) => ({
      ...initialState,
      ...payload,
    }),
    reset: () => initialState,
  },
});

const { set, clearAndSet } = slice.actions;

export const seoActions = {
  ...slice.actions,
  set: (payload: Partial<SeoMetaData>): ReturnType<typeof set> => set(payload),
  clearAndSet: (payload: Partial<SeoMetaData>): ReturnType<typeof set> =>
    clearAndSet(payload),
};

export const localSeoReducer = slice.reducer;
