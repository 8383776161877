import { Settings } from 'react-slick';

export const settings: Settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  initialSlide: 0,
  infinite: true,
  responsive: [
    {
      breakpoint: 750,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
      },
    },
  ],
};
