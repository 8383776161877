import { OrderType } from 'types/cart.types';
import { isDelivery, isShipment } from 'utils/is-order-type';
import {
  selectIsFulfilled,
  selectStores,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useSelector } from 'react-redux';

export const useAutoSelect = (orderType: OrderType): boolean => {
  const isFulfilled = useSelector(selectIsFulfilled);
  const stores = useSelector(selectStores);
  const isSingleDeliveryStore = isDelivery(orderType) && stores.length === 1;
  const shouldAutoSelect =
    isFulfilled && (isShipment(orderType) || isSingleDeliveryStore);
  return shouldAutoSelect;
};

export const useAutoSelectDelivery = (orderType: OrderType): boolean => {
  const isFulfilled = useSelector(selectIsFulfilled);
  const stores = useSelector(selectStores);
  const isMoreDeliveryStore = isDelivery(orderType) && stores.length > 1;
  const shouldAutoSelectDelivery =
    isFulfilled && (isShipment(orderType) || isMoreDeliveryStore);
  return shouldAutoSelectDelivery;
};
