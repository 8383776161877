export type Address = {
  street1: string;
  street2?: string;
  city: string;
  state?: string;
  zip?: string;
  country?: string;
};

export enum ValidationResult {
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
  VALID = 'VALID',
}
type NotValidResult = ValidationResult.INVALID | ValidationResult.UNKNOWN;

export const isValid = <T extends ValidationResult>(
  result: T | ValidationResult,
): result is ValidationResult.VALID => result === ValidationResult.VALID;

export const isInvalid = <T extends ValidationResult>(
  result: T | ValidationResult,
): result is ValidationResult.INVALID => result === ValidationResult.INVALID;

export const isInUnknown = <T extends ValidationResult>(
  result: T | ValidationResult,
): result is ValidationResult.UNKNOWN => result === ValidationResult.UNKNOWN;

export function isNotValid<T extends ValidationResult>(
  result: T | ValidationResult,
): result is NotValidResult {
  return (
    result === ValidationResult.UNKNOWN || result === ValidationResult.INVALID
  );
}

export type ValidatedAddress = {
  addressToValidate: Address;
  suggestedAddresses: Address[];
  validationResults: ValidationResult | null;
  isResidential: boolean | null;
};
