export enum CreditCardTypesEnum {
  NotSpecified = 0,
  Master = 1,
  Visa = 2,
  AMEX = 3,
  Discover = 4,
  Maestro = 5,
  Solo = 6,
  Switch = 7,
  Delta = 8,
  DinersClub = 9,
  JCB = 10,
  PayPal = 11,
  BML = 12,
  AmazonPay = 13,
  ApplePay = 14,
  Others = 15,
  Venmo = 16,
}

export enum SaleDeclineReasonEnum {
  NotSpecified = 0,
  CVVMismatch = 1,
  AVSMismatch = 2,
  NotSufficientFund = 3,
  CVVANDAVSMismatch = 4,
  PayeezyFraudDetect = 5,
  ForterFraudDetect = 6,
  Other = 7,
}

export enum PaymentModeEnum {
  NotSpecified = 0,
  Cash = 1,
  CreditCard = 2,
  HouseCharge = 3,
  GiftCertificate = 4,
  DebitCard = 5,
  EAConnect = 6,
  EAEnterprise = 7,
  Complementary = 8,
  Check = 9,
  PayPal = 10,
  BML = 11,
  EFT = 12,
  BarterTrade = 13,
  AmazonPay = 14,
  AmazonMarketplace = 15,
  ApplePay = 16,
  Others = 17,
  Unpaid = 18,
  Venmo = 19,
  GooglePay = 20,
  Link = 21,
}
