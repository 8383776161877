import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { isSafari } from 'utils/is-safari';

const isSafariBrowser = isSafari();

export const container: ThemedSxProps = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  alignItems: 'center',
  textDecoration: 'none',
  '&.Mui-focusVisible': {
    border: `2px solid ${colors.black}`,
  },
  width: { xs: '150px' },
  '&:hover': {
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      '& img': {
        transform: 'scale(1.08)',
        transition: isSafariBrowser ? '' : 'all 0.3s ease',
      },
      '& .MuiTypography-subtitle1': {
        color: theme.palette.primary.main,
      },
    },
  },
});

export const carouselItem: ThemedSxProps = {
  display: 'flex',
  justifyContent: 'center',
  '.MuiBox-root': {
    overflow: 'hidden',
    '& img': {
      overflow: 'hidden',
      borderRadius: '50%',
      width: { xs: '150px' },
      height: { xs: '150px' },
    },
  },
};

export const title: ThemedSxProps = (theme) => ({
  margin: '20px auto 0',
  color: theme.palette.common.black,
  overflow: 'hidden',
  textAlign: 'center',
  maxWidth: 'calc(100% - 20px)',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    fontWeight: '500',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
});
