import { Status } from 'redux/types/state.interface';
import { fetchFilterName } from 'components/PDP/GoBack/feature/action';
import { getUrlAlias, shouldUseReferrer } from 'components/PDP/GoBack/utils';
import { setFulfilled, setState } from 'components/PDP/GoBack/feature/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const { referrer } = document;
const isReferrerValid = shouldUseReferrer(referrer, window.origin);
const pageFriendlyUrl =
  isReferrerValid && new URL(referrer).pathname.split('/')[1];

export const useRefererAlias = (): void => {
  const dispatch = useDispatch();
  const alias = referrer ? getUrlAlias(referrer) : null;

  useEffect(() => {
    if (alias) {
      dispatch(
        setState({
          status: Status.FULFILLED,
          filterName: alias,
        }),
      );
    } else {
      dispatch(
        isReferrerValid && pageFriendlyUrl
          ? fetchFilterName(pageFriendlyUrl)
          : setFulfilled(),
      );
    }
  }, [alias, dispatch]);
};
