import { HttpStatusCode } from 'enums/http-status-code';
import { appInsights } from 'utils/telemetry/app-insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { eventInfoLegacyApiService } from 'api/event-info-legacy/event-info.service';
import axios from 'axios';

type Params = { email: string; name?: string; message?: string };
export const createConciergeInfo = createAsyncThunk(
  'concierge-info/create',
  async ({ email, name = '', message = '' }: Params, { rejectWithValue }) => {
    try {
      const {
        data: { d },
      } = await eventInfoLegacyApiService.createConciergeInfo({
        email,
        name,
        message,
      });
      const response = JSON.parse(d) as { _Status?: 'OK' | 'Error' };
      if (response?._Status === 'OK') {
        appInsights.trackEvent({ name: 'Concierge info added' });
        return true;
      }
      appInsights.trackDependencyData({
        id: `${new Date().getTime()} + ${Math.random()}`,
        data: d,
        duration: 0,
        name: 'Legacy api',
        responseCode: 200,
        success: false,
        target: 'Legacy',
        type: 'Unexpected error',
      });
    } catch (error) {
      appInsights.trackDependencyData({
        id: `${new Date().getTime()} + ${Math.random()}`,
        data: JSON.stringify(error),
        duration: 0,
        name: 'Legacy api',
        responseCode:
          axios.isAxiosError(error) && error.response?.status
            ? error.response.status
            : HttpStatusCode.INTERNAL_SERVER_ERROR,
        success: false,
        target: 'Legacy',
        type: 'error',
      });
    }
    appInsights.trackEvent({ name: 'Concierge info error' });
    return rejectWithValue(false);
  },
);
