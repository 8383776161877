export const getCartNgUrl = (region: number): string => {
  let url = '';
  if (region) {
    const numericRegion = Number(region); // Convert to number if it's not already
    if (numericRegion === 0) {
      url = window.CARTNG_R0_URL;
    } else if (numericRegion === 1) {
      url = window.CARTNG_R1_URL;
    } else if (numericRegion === 2) {
      url = window.CARTNG_R2_URL;
    }
  }

  return `${url}/api`;
};
