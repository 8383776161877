import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';

export const selectIsBlocked = createSelector<RootState, Status, boolean>(
  ({ banners: { status } }) => status,
  (status) => [Status.INIT, Status.PENDING].includes(status),
);

export const selectBanners = ({ banners: { skinny } }: RootState) => skinny;
