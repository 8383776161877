import {
  LegacyAddToCartParams as CartParams,
  CartUpsell,
  FullLegacyAddToCartParams,
  ItemType,
} from 'types/cart.types';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { format } from 'date-fns';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';

export function fillLegacyCartDefaultParams(
  cart: CartParams,
): FullLegacyAddToCartParams {
  return {
    ...(pickBy(
      {
        OrderType: cart.OrderType,
        FreeProducts: IS_MOBILE_HOST
          ? undefined
          : [
              {
                Quantity: 0,
                ArrangementID: 0,
                ArrangementProductID: 0,
                ArrangementPromotionID: 0,
              },
            ],
        AddonItems: [
          pickBy(
            {
              AddOnType: IS_MOBILE_HOST ? 0 : undefined,
              CYOA: IS_MOBILE_HOST ? false : undefined,
              DefaultSleeve: IS_MOBILE_HOST ? false : undefined,
              ImagePrefixSleeve: IS_MOBILE_HOST ? '' : undefined,
              ItemType: ItemType.NotSpecified,
              Numbers: IS_MOBILE_HOST ? null : undefined,
              OptionText: '',
              ParentProductID: IS_MOBILE_HOST ? 0 : undefined,
              Price: '0',
              PrintibleCard: IS_MOBILE_HOST ? null : '',
              PrintibleCardResponse: IS_MOBILE_HOST ? null : '',
              ProductID: 0,
              ProductImagePath: IS_MOBILE_HOST ? '' : undefined,
              ProductName: IS_MOBILE_HOST ? '' : undefined,
              Quantity: IS_MOBILE_HOST ? 1 : 0,
              Sleeve: IS_MOBILE_HOST ? false : undefined,
              Years: IS_MOBILE_HOST ? null : undefined,
            },
            isNotUndefined,
          ) as CartUpsell,
        ],
        ArrangementOption: '',
        ArrangementOptionID: 0,
        CallRecipient: null,
        CarInfo: '',
        Catering: false,
        ConfigDeliveryDate: IS_MOBILE_HOST
          ? ''
          : format(new Date(), 'M/dd/yyyy'),
        ConfigDeliveryFeeCharges: '0',
        DeliveryFeeDetailID: IS_MOBILE_HOST ? -2147483648 : 0,
        DeliveryOrPickupInstructions: '',
        FlexDiscount: 0,
        FlexFromDate: IS_MOBILE_HOST ? '1/1/0001 12:00:00 AM' : '01/01/0001',
        FlexOptionID: 0,
        FlexToDate: IS_MOBILE_HOST ? '1/1/0001 12:00:00 AM' : '01/01/0001',
        FulfillmentTimeFrom: '1/1/0001 12:00:00 AM',
        FulfillmentTimeTo: '1/1/0001 12:00:00 AM',
        IsCurbSide: false,
        OccassionRec: false,
        OccassionRecDate: '1/1/0001 12:00:00 AM',
        Pickup7Minute: false,
        UpgradeItems: [
          pickBy(
            {
              AddOnType: IS_MOBILE_HOST ? 0 : undefined,
              CYOA: IS_MOBILE_HOST ? false : undefined,
              DefaultSleeve: IS_MOBILE_HOST ? false : undefined,
              ImagePrefixSleeve: IS_MOBILE_HOST ? '' : undefined,
              ItemType: ItemType.NotSpecified,
              Numbers: IS_MOBILE_HOST ? null : undefined,
              OptionText: '',
              ParentProductID: IS_MOBILE_HOST ? 0 : undefined,
              Price: '0',
              PrintibleCard: IS_MOBILE_HOST ? null : '',
              PrintibleCardResponse: IS_MOBILE_HOST ? null : '',
              ProductID: 0,
              ProductImagePath: IS_MOBILE_HOST ? '' : undefined,
              ProductName: IS_MOBILE_HOST ? '' : undefined,
              Quantity: IS_MOBILE_HOST ? 1 : 0,
              Sleeve: IS_MOBILE_HOST ? false : undefined,
              Years: IS_MOBILE_HOST ? null : undefined,
            },
            isNotUndefined,
          ) as CartUpsell,
        ],
        skipOnDelivery: '0',
      },
      isNotUndefined,
    ) as Required<FullLegacyAddToCartParams>),
    ...cart,
  };
}
