/* eslint-disable no-param-reassign */
import { ArrangementPreview } from 'api/arrangements/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchArrangements } from 'components/PLP/ArrangementsList/AllArrangements/feature/actions';

interface State {
  status: Status;
  arrangements: ArrangementPreview[];
}

const initialState: State = {
  status: Status.INIT,
  arrangements: [],
};
export const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(fetchArrangements.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchArrangements.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.arrangements = payload;
      })
      .addCase(fetchArrangements.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state),
  initialState,
  name: 'plp/empty-list',
  reducers: {},
});
export const plpAllArrangementsReducer = slice.reducer;
