import * as TK from 'components/Cart/Details/translations/locale.constant';
import { AddOn } from 'api/cart/types/cart-add-on.type';
import {
  AddressInfo,
  PickupInfo,
  Recipient,
  RecipientContact,
  StoreInfo,
} from 'api/cart/types/cart-recipient.type';
import { Cart } from 'api/cart/types/cart-retrieval.type';
import { CartProductForAnalytics } from 'api/cart/types/cart-item.type';
import { CartProducts } from 'components/Cart/hook/analytics/analytics';
import { ContactInfo } from 'redux/cart/cart.types';
import { ItemType, OrderType } from 'types/cart.types';
import { NULL_RECIPIENT } from 'containers/PDPContainer/PDPContainer';
import {
  OrderInfo,
  delivery,
  pickup,
  shipment,
} from 'components/Cart/hook/utils';
import { ProductInfo } from 'components/Cart/Details/components/Upsells/feature/types/productInfo';
import { ProductType, productTypeMap } from 'api/products/types';
import { ProductTypeAnalyticsPostfixes } from 'enums/product-types';
import { Reward } from 'providers/Session/feature/user.types';
import { RewardType } from 'components/Cart/feature/enums/reward-type.enum';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { UpsellItem } from 'components/Cart/Details/components/Upsells/feature/types/upsell-item';
import { colors } from 'utils/theme/colors';
import { createSelector } from '@reduxjs/toolkit';
import { dateCheckIfIncludesTime, isNextDate } from 'utils/date';
import { getByType } from 'utils/getProductPostfixByType';
import { isBlocked, isFinished } from 'utils/status.comparer';
import i18next from 'i18next';

export interface CouponCodeState {
  cart: Cart;
  strErrorMessage: string;
  crId: number;
  showCoupons: boolean;
}

export const cartChangesPending = ({ recipientCart }: RootState): boolean => {
  const pending =
    recipientCart.cardMessageStatus === 1 ||
    recipientCart.editQuantityStatus === 1 ||
    recipientCart.status === 1 ||
    recipientCart.oneHourDeliveryStatus === 1 ||
    recipientCart.addCouponStatus === 1 ||
    recipientCart.removeCouponStatus === 1;

  return pending;
};

export const shouldRetrieveCart = ({
  recipientCart: { retrieveCart },
}: RootState): boolean => {
  return retrieveCart;
};

export const shouldRetrieveRecipient = ({
  recipientCart: { retrieveRecipient },
}: RootState): boolean => {
  return retrieveRecipient;
};

export const selectIsCartLoading = createSelector(
  ({ recipientCart: { status } }: RootState) => status,
  (status) => [Status.FULFILLED, Status.INIT].includes(status),
);

export const selectIsCartStatus = createSelector(
  ({ recipientCart: { status } }: RootState) => status,
  (status) => status,
);

export const selectCartLoaded = createSelector(
  ({ recipientCart: { status } }: RootState) => status,
  (status) => [Status.FULFILLED].includes(status),
);

export const selectCartCount = ({
  recipientCart: { count },
}: RootState): number => {
  return count;
};

export const selectIsCartArrangementLoaded = createSelector(
  ({ plpArrangements: a }: RootState) => a.status,
  (status) => isFinished(status),
);

export const selectIsBlocked = createSelector(
  ({ recipientCart: { editQuantityStatus } }: RootState) => editQuantityStatus,
  (status) => isBlocked(status),
);

export const selectCouponBlocked = createSelector(
  ({ recipientCart: { addCouponStatus } }: RootState) => addCouponStatus,
  (status) => isBlocked(status),
);

export const selectIsRemoveCouponBlocked = createSelector(
  ({ recipientCart: { removeCouponStatus } }: RootState) => removeCouponStatus,
  (status) => isBlocked(status),
);

export const selectIsCardMessageUpdated = createSelector(
  ({ recipientCart: { cardMessageStatus } }: RootState) => cardMessageStatus,
  (status) => [Status.FULFILLED].includes(status),
);

export const selectIsCardMessagePending = createSelector(
  ({ recipientCart: { cardMessageStatus } }: RootState) => cardMessageStatus,
  (status) => [Status.PENDING].includes(status),
);

export const selectRecipientCart = ({ recipientCart }: RootState): Cart =>
  recipientCart.recipientCart;

export const selectRecipients = ({
  recipientCart,
}: RootState): Recipient[] | undefined => recipientCart.recipientCart.recipient;

export const selectRecipientCount = ({ recipientCart }: RootState): number =>
  recipientCart.recipientCart.recipient?.length || 0;

export const selectCouponCode = ({
  recipientCart,
}: RootState): CouponCodeState => ({
  cart: recipientCart.recipientCart,
  strErrorMessage: recipientCart.couponOptions.strErrorMessage,
  crId: recipientCart.couponOptions.crId,
  showCoupons: recipientCart.couponOptions.showCoupons,
});

export const selectArrangementsOneHourDelivery = ({
  recipientCart,
}: RootState): number[] => recipientCart.arrangementsOneHourDelivery;

export const selectOrderTotal = ({ recipientCart }: RootState): number => {
  const recipients = recipientCart.recipientCart.recipient;
  const total = recipients?.reduce(
    (sum, recipient) => sum + recipient.recipientMerchandiseTotal,
    0,
  );
  return total || 0;
};
export const isAnyDeliveryAndNextDay = ({
  recipientCart,
}: RootState): boolean => {
  const isNotMultiRecipient: boolean =
    recipientCart.recipientCart.recipient?.length === 1;

  const isDeliveryAndNextDayOrder = isNotMultiRecipient
    ? recipientCart.recipientCart.recipient?.[0].orderType ===
        OrderType.Delivery &&
      isNextDate(
        dateCheckIfIncludesTime(
          recipientCart.recipientCart.recipient?.[0].fulfillmentDate.toLocaleString(),
        ),
      )
    : false;
  return isDeliveryAndNextDayOrder;
};
export const selectFreeDeliveryDetails = ({ recipientCart }: RootState) => {
  const cartTotal = Number(sessionStorage.getItem('CartMerchandiseTotal'));
  const orderTotal = cartTotal || 0;
  const leftAmount = window.MIN_ORDER_TOTAL_THRESHOLD - orderTotal;
  const isNotMultiRecipient: boolean =
    recipientCart.recipientCart.recipient?.length === 1;

  const isAnyDeliveryAndNextDayOrder = isNotMultiRecipient
    ? recipientCart.recipientCart.recipient?.[0].orderType ===
        OrderType.Delivery &&
      isNextDate(
        dateCheckIfIncludesTime(
          recipientCart.recipientCart.recipient?.[0].fulfillmentDate.toLocaleString(),
        ),
      )
    : false;

  return {
    orderTotal,
    leftAmount,
    isAnyDeliveryAndNextDayOrder,
  };
};

export const selectIsCouponSuccessfullyApplied = ({
  recipientCart,
}: RootState): boolean => recipientCart.addCouponStatus === Status.FULFILLED;

export const selectShowCoupons = ({ recipientCart }: RootState): boolean =>
  recipientCart.couponOptions.showCoupons;

export const selectRewardApplied = ({
  recipientCart: { recipientCart },
}: RootState):
  | { rewardCode: string; amount: number; rewardType: RewardType }
  | undefined => {
  const recipient = recipientCart.recipient?.find(
    (r: Recipient) => !!r.couponCode || !!r.promotionCode,
  );

  if (!recipient) {
    return undefined;
  }

  const rewardType: RewardType = recipient.couponCode
    ? RewardType.COUPON
    : RewardType.PROMOTION;

  return {
    rewardCode: recipient.couponCode || recipient.promotionCode,
    amount: recipient.discountTotal,
    rewardType,
  };
};

export const getAllAddOns = (
  state: RootState,
  productID: number,
): AddOn[] | undefined =>
  state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.itemType !== ItemType.Package &&
      product.catalogCode !== 'Printible' &&
      product.productType === ProductType.AddOn &&
      product.parentCartRecipientItemId === productID,
  );

export const getAllUpgrades = (
  state: RootState,
  productID: number,
): AddOn[] | undefined =>
  state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.itemType !== ItemType.Package &&
      product.productType === ProductType.Upgrade &&
      product.parentCartRecipientItemId === productID,
  );

export const getAllUpgradesTotal = (
  productID: number,
  { recipientCart: { recipientCart } }: RootState,
): number => {
  const totalUpgradeCount =
    recipientCart.addOn
      ?.filter(
        (product) =>
          product.itemType !== ItemType.Package &&
          product.productType === ProductType.Upgrade &&
          product.parentCartRecipientItemId === productID,
      )
      .reduce((acc, { price }) => acc + price, 0) || 0;
  return totalUpgradeCount;
};

export const selectAllPackage = (
  state: RootState,
  productID: number,
): AddOn[] | undefined =>
  state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.itemType === ItemType.Package &&
      product.parentCartRecipientItemId === productID,
  );

export const selectPackageItemCount = (
  state: RootState,
  productID: number,
): number | undefined => {
  return state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.itemType === ItemType.Package &&
      product.parentCartRecipientItemId === productID,
  ).length;
};

export const selectCYOAtOrderSubItemCount = (
  state: RootState,
  productID: number,
): number | undefined => {
  return state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.parentCartRecipientItemId === productID &&
      product.name.includes('CYO'),
  ).length;
};

export const checkPrintableMessage = (
  state: RootState,
  cartRecipientId: number,
): AddOn[] | undefined =>
  state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.catalogCode === 'Printible' &&
      product.recipientId === cartRecipientId,
  );

export const selectTotalNumberOfRecipients = ({
  recipientCart: { recipientCart },
}: RootState): number => recipientCart.recipient?.length || 0;

export const selectLineItems = (state: RootState) => {
  const lineItemObj = [];
  const textProps = { fontSize: '16px', fontWeight: 600 };
  const valueProps = { fontSize: '16px' };
  const rewards =
    state.userSession.userAccountInformation?.rewards || ([] as Reward[]);

  const { recipientCart } = state.recipientCart;
  const { shipmentTaxHistory } = state.shipmentStoreTaxHistory;

  if (recipientCart?.recipient) {
    if (recipientCart.recipient.length === 1) {
      const rId = recipientCart.recipient[0].recipientId || 0;
      const distanceFee = state.recipientCart?.recipientCart?.distanceFee?.find(
        (fee) => fee.cartRecipientId === rId,
      );
      lineItemObj.push({
        text: i18next.t(TK.SUBTOTAL),
        value: recipientCart?.recipient[0].recipientMerchandiseTotal,
        textProps,
        valueProps,
      });
      if (recipientCart.recipient[0].discountTotal) {
        lineItemObj.push({
          text: i18next.t(TK.DISCOUNT_TOTAL),
          value: recipientCart.recipient[0].discountTotal,
          textProps,
          valueProps: { fontSize: '16px', color: colors.primaryRed },
        });
      }
      if (
        recipientCart?.recipient[0].tax1 > 0 &&
        recipientCart?.recipient[0].tax1Name
      ) {
        const storeid = recipientCart?.recipient[0].storeId;
        if (
          recipientCart?.recipient[0].orderType === OrderType.Shipment &&
          shipmentTaxHistory
        ) {
          const taxByStore = shipmentTaxHistory.find(
            (x) => x.storeId === storeid,
          );
          if (taxByStore) {
            lineItemObj.push({
              text: `${taxByStore?.taxName || i18next.t(TK.STATE_TAX)} (${
                shipmentTaxHistory[0].taxRate
              }%):`,
              value: recipientCart?.recipient[0].tax1,
              textProps,
              valueProps,
            });
          }
        } else
          lineItemObj.push({
            text: `${recipientCart?.recipient[0].tax1Name} (${recipientCart?.recipient[0].tax1Rate}%):`,
            value: recipientCart?.recipient[0].tax1,
            textProps,
            valueProps,
          });
      }
      if (
        recipientCart?.recipient[0].tax2 > 0 &&
        recipientCart?.recipient[0].tax2Name
      ) {
        lineItemObj.push({
          text: `${recipientCart?.recipient[0].tax2Name} (${recipientCart?.recipient[0].tax2Rate}%):`,
          value: recipientCart?.recipient[0].tax2,
          textProps,
          valueProps,
        });
      }
      if (
        recipientCart?.recipient[0].tax3 > 0 &&
        recipientCart?.recipient[0].tax3Name
      ) {
        lineItemObj.push({
          text: `${recipientCart?.recipient[0].tax3Name} (${recipientCart?.recipient[0].tax3Rate}%):`,
          value: recipientCart?.recipient[0].tax3,
          textProps,
          valueProps,
        });
      }
      if (
        recipientCart?.recipient[0].tax4 > 0 &&
        recipientCart?.recipient[0].tax4Name
      ) {
        lineItemObj.push({
          text: `${recipientCart?.recipient[0].tax4Name} (${recipientCart?.recipient[0].tax4Rate}%):`,
          value: recipientCart?.recipient[0].tax4,
          textProps,
          valueProps,
        });
      }
      if (
        recipientCart?.recipient[0].tax5 > 0 &&
        recipientCart?.recipient[0].tax5Name
      ) {
        lineItemObj.push({
          text: `${recipientCart?.recipient[0].tax5Name} (${recipientCart?.recipient[0].tax5Rate}%):`,
          value: recipientCart?.recipient[0].tax5,
          textProps,
          valueProps,
        });
      }

      if (recipientCart?.recipient[0].surcharge > 0) {
        lineItemObj.push({
          text: `${recipientCart?.recipient[0].surchargeName}:`,
          value: recipientCart?.recipient[0].surcharge,
          textProps,
          valueProps,
        });
      }
      if (recipientCart?.recipient[0].orderType === OrderType.Delivery) {
        let deliveryCharge =
          recipientCart?.recipient[0].deliveryOrShipmentCharge;
        if (distanceFee && distanceFee.fee > 0) {
          deliveryCharge -= distanceFee.fee;
          lineItemObj.push({
            text: i18next.t(TK.DISTANCE_FEE),
            value: distanceFee.fee,
            textProps,
            valueProps: { ...valueProps },
            distanceCaption: true,
          });
        }
        lineItemObj.push({
          text: recipientCart?.recipient[0].deliveryFeeCaption
            ? `${recipientCart?.recipient[0].deliveryFeeCaption}:`
            : i18next.t(TK.DELIVERY_FEE),
          value: deliveryCharge,
          textProps,
          valueProps: { ...valueProps },
          deliveryCaption: true,
        });
      }
      if (recipientCart?.recipient[0].orderType === OrderType.Shipment) {
        lineItemObj.push({
          text: i18next.t(TK.SHIPMENT_CHARGE),
          value: recipientCart?.recipient[0].deliveryOrShipmentCharge,
          textProps,
          valueProps,
        });
      }
      lineItemObj.push({
        text: i18next.t(TK.PROMO),
        value: 0,
        textProps,
        valueProps,
        divider: true,
        rewards,
        recipient: recipientCart?.recipient[0],
      });
      lineItemObj.push({
        text: i18next.t(TK.TOTAL),
        value: recipientCart?.recipient[0].cartTotal,
        textProps: { fontSize: '20px', fontWeight: 600 },
        valueProps: { fontSize: '20px', fontWeight: 600 },
        divider: true,
      });
    }
    if (recipientCart.recipient.length > 1) {
      recipientCart.recipient.map((rec, index) => {
        return lineItemObj.push({
          text: i18next.t(TK.RECIPIENT_TOTAL, {
            replace: { number: index + 1 },
          }),
          value: rec.recipientTotal,
          textProps,
          valueProps,
          recipient: rec,
        });
      });
      lineItemObj.push({
        text: i18next.t(TK.CART_TOTAL),
        value: recipientCart?.recipient[0].cartTotal,
        textProps: { fontSize: '20px', fontWeight: 600 },
        valueProps: { fontSize: '20px', fontWeight: 600 },
        divider: true,
      });
    }
  }
  return lineItemObj;
};

export const selectLineItemsTotal = ({
  recipientCart: { recipientCart },
}: RootState) => {
  const lineItemObjTotal = [];

  if (recipientCart?.recipient) {
    if (recipientCart.recipient.length === 1) {
      lineItemObjTotal.push({
        text: i18next.t(TK.TOTAL),
        value: recipientCart?.recipient[0].cartTotal,
      });
    }
    if (recipientCart.recipient.length > 1) {
      lineItemObjTotal.push({
        text: i18next.t(TK.CART_TOTAL),
        value: recipientCart?.recipient[0].cartTotal,
      });
    }
  }
  return lineItemObjTotal;
};

export const selectOrderType = ({
  recipientCart: { recipientCart },
}: RootState): OrderInfo[] | [] => {
  const orderTypes = [];
  if (recipientCart?.recipient) {
    for (let i = 0; i < recipientCart.recipient.length; i += 1) {
      const { orderType } = recipientCart.recipient[i];
      if (orderType === 1) orderTypes.push(delivery);
      if (orderType === 2) orderTypes.push(pickup);
      if (orderType === 3) orderTypes.push(shipment);
    }
  }
  return orderTypes;
};
export const selectCustomerInfo = ({
  recipientCart: { recipientCart },
}: RootState): AddressInfo[] => {
  const customerInfo = [];
  if (recipientCart?.recipient) {
    for (let i = 0; i < recipientCart.recipient.length; i += 1) {
      customerInfo.push({
        name: recipientCart.recipient[i].recipientName,
        address1: recipientCart.recipient[i].recipientAddress1,
        address2: recipientCart.recipient[i].recipientAddress2,
        city: recipientCart.recipient[i].recipientCity,
        state: recipientCart.recipient[i].recipientState,
        zip: recipientCart.recipient[i].recipientArea,
        number:
          recipientCart.recipient[i].recipientCellPhone ||
          recipientCart.recipient[i].recipientHomePhone ||
          recipientCart.recipient[i].recipientWorkPhone,
        fulfillmentDate: dateCheckIfIncludesTime(
          recipientCart.recipient[i]?.fulfillmentDate.toString(),
        ),
        fulfillmentInstructions:
          recipientCart.recipient[i].deliveryInstructions,
      });
    }
  }
  return customerInfo;
};

export const selectContactCustomerInfo = ({
  recipientCart: { recipientCart },
}: RootState): Partial<ContactInfo[]> => {
  const customerInfo: ContactInfo[] = [];
  if (recipientCart?.contact) {
    for (let i = 0; i < recipientCart.contact?.length; i += 1) {
      customerInfo.push({
        addressType: recipientCart.contact[i].addressType,
        address1: recipientCart.contact[i].address1,
        address2: recipientCart.contact[i].address2,
        company: recipientCart.contact[i].company || '',
        cellPhone: recipientCart.contact[i].cellPhone || '',
        phone: recipientCart.contact[i].homePhone || '',
        workPhone: recipientCart.contact[i].workPhone || '',
        cityMlId: recipientCart.contact[i].cityMlId ?? NULL_RECIPIENT.cityMlId,
        firstName: recipientCart.contact[i].firstName,
        lastName: recipientCart.contact[i].lastName,
        recipientEmail: recipientCart.contact[i].email,
      });
    }
  }
  return customerInfo;
};

export const selectPickupInfo = ({
  recipientCart: { recipientCart },
}: RootState): PickupInfo[] => {
  const pickupInfo: PickupInfo[] = [];
  if (recipientCart?.recipient) {
    for (let i = 0; i < recipientCart.recipient?.length; i += 1) {
      pickupInfo.push({
        isCurbSide: recipientCart.recipient[i].isCurbSide,
        carInfo: recipientCart.recipient[i].carInfo,
      });
    }
  }
  return pickupInfo;
};

export const IsFirstProduct = (
  state: RootState,
  parentCartRecipientItemId: number,
  recipientId: number,
): boolean => {
  let count = 0;
  let foundCount = 0;

  state.recipientCart.recipientCart?.cartItem?.forEach((item) => {
    count += 1;
    if (
      item.recipientId === recipientId &&
      item.id === parentCartRecipientItemId
    ) {
      foundCount = count;
    }
  });

  return foundCount === 1;
};
export const selectProductInfo = (
  state: RootState,
  parentCartRecipientItemId: number,
  recipientId: number,
  type: ProductType,
): ProductInfo | undefined => {
  const recipient = state.recipientCart.recipientCart?.recipient?.filter(
    (rec) => rec.recipientId === recipientId,
  );

  const cartItem = state.recipientCart.recipientCart?.cartItem?.filter(
    (cartItems) =>
      cartItems.recipientId === recipientId &&
      cartItems.id === parentCartRecipientItemId,
  );
  if (recipient?.length && cartItem?.length) {
    const addon = state.recipientCart.recipientCart?.addOn?.filter(
      (product) =>
        product.productType === type ||
        (product.recipientId === recipientId &&
          product.parentCartRecipientItemId === parentCartRecipientItemId),
    );

    if (addon?.length) {
      const Upsell: UpsellItem = {
        checked: true,
        quantity: addon[0].quantity,
        itemType: addon[0].productType,
        price: addon[0].price,
      };
      const productInfo: ProductInfo = {
        arrangementSizeId: cartItem[0].arrangementSizeId,
        id: cartItem[0].productId,
        name: addon[0].name,
        image: addon[0].image,
        price: addon[0].price,
        type: addon[0].productType,
        quantity: addon[0].quantity,
        orderType: recipient[0].orderType,
        storeId: recipient[0].storeId,
        fulfillmentDate: recipient[0].fulfillmentDate,
        upSells: Upsell,
        recipientId: cartItem[0].recipientId,
        parentCartRecipientItemId: cartItem[0].id,
        selectedProductID: cartItem[0].productId,
        selectedProductQuantity: cartItem[0].quantity,
        addonId: addon[0].id,
      };
      return productInfo;
    }

    const productInfo: ProductInfo = {
      arrangementSizeId: cartItem[0].arrangementSizeId,
      id: cartItem[0].productId,
      name: cartItem[0].name,
      image: cartItem[0].image,
      price: cartItem[0].price,
      type: cartItem[0].itemType,
      quantity: cartItem[0].quantity,
      orderType: recipient[0].orderType,
      storeId: recipient[0].storeId,
      fulfillmentDate: recipient[0].fulfillmentDate,
      recipientId: cartItem[0].recipientId,
      parentCartRecipientItemId: cartItem[0].id,
      selectedProductID: cartItem[0].productId,
      selectedProductQuantity: cartItem[0].quantity,
      addonId: 0,
    };
    return productInfo;
  }

  return undefined;
};

export const selectUpsellInfo = (
  state: RootState,
  parentCartRecipientItemId: number,
  recipientId: number,
): UpsellItem[] | undefined => {
  const addon = state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      (product.productType === ProductType.AddOn ||
        product.productType === ProductType.Upgrade) &&
      product.recipientId === recipientId &&
      product.parentCartRecipientItemId === parentCartRecipientItemId,
  );
  const upsellInfo: UpsellItem[] | undefined = [];
  if (addon?.length) {
    addon
      .filter((x) => x.itemType !== ItemType.Package)
      .map((item) => {
        return upsellInfo.push({
          productId: item.productId,
          price: item.price,
          quantity: item.quantity,
          itemType: item.productType,
          checked: true,
          text: item.option,
        });
      });
    return upsellInfo;
  }
  return undefined;
};

export const selectStoreInfo = ({
  recipientCart: { recipientCart },
}: RootState): StoreInfo[] => {
  const storeInfo = [];
  if (recipientCart?.recipient) {
    for (let i = 0; i < recipientCart.recipient.length; i += 1) {
      storeInfo.push({
        name: recipientCart.recipient[i].storeFormalName,
        address1: recipientCart.recipient[i].storeAddress1,
        address2: recipientCart.recipient[i].storeAddress2,
        city: recipientCart.recipient[i].storeCity,
        state: recipientCart.recipient[i].storeState,
        zip: recipientCart.recipient[i].storeArea,
        number: recipientCart.recipient[i].storePhone,
        storeId: recipientCart.recipient[i].storeId,
        timeZoneOffset: recipientCart.recipient[i].storeTimeZoneOffset,
        dayLightSavingOffset:
          recipientCart.recipient[i].storeDayLightSavingOffset,
      });
    }
  }
  return storeInfo;
};

export const selectCartProductsForAnalytics = ({
  recipientCart: { recipientCart },
}: RootState): CartProductForAnalytics[] => {
  const addOnItems = [];
  const cartItems = [];
  if (recipientCart?.cartItem) {
    for (let i = 0; i < recipientCart.cartItem.length; i += 1) {
      cartItems.push({
        id: `${recipientCart.cartItem[i].productId}${getByType(
          recipientCart.cartItem[i].itemType,
        )}`,
        price: recipientCart.cartItem[i].price,
        units: recipientCart.cartItem[i].quantity,
      });
    }
  }
  if (recipientCart?.addOn) {
    for (let i = 0; i < recipientCart.addOn.length; i += 1) {
      addOnItems.push({
        id: `${recipientCart.addOn[i].productId}${getByType(
          recipientCart.addOn[i].itemType,
        )}`,
        price: recipientCart.addOn[i].price,
        units: recipientCart.addOn[i].quantity,
      });
    }
  }
  return [...cartItems, ...addOnItems];
};
export const selectCartItemsForAnalytics = ({
  recipientCart: { recipientCart },
}: RootState) => {
  const cartItems = [];

  if (recipientCart?.cartItem) {
    for (let i = 0; i < recipientCart.cartItem.length; i += 1) {
      cartItems.push({
        productID: `${recipientCart.cartItem[i].productId}${getByType(
          recipientCart.cartItem[i].itemType,
        )}`,
        arrangementId: `${recipientCart.cartItem[i].arrangementId}`,
        productType: productTypeMap(recipientCart.cartItem[i].itemType),
        sku: `${recipientCart.cartItem[i].productId}`,
        category: 'fruit-gifts',
        name: recipientCart.cartItem[i].name,
        qty: recipientCart.cartItem[i].quantity,
        itemPrice: recipientCart.cartItem[i].price,
        url: `https://www.ediblearrangements.com/fruit-gifts/${recipientCart.cartItem[i].pageFriendlyURL}`,
      });
    }
  }
  return cartItems;
};

export const selectCartAddOnsForAnalytics = ({
  recipientCart: { recipientCart },
}: RootState) => {
  const addOnItems = [];

  if (recipientCart?.addOn) {
    for (let i = 0; i < recipientCart.addOn.length; i += 1) {
      const getProductType = getByType(recipientCart.addOn[i].itemType);
      if (ProductTypeAnalyticsPostfixes.Package !== getProductType) {
        addOnItems.push({
          productID: `${recipientCart.addOn[i].productId}${getByType(
            recipientCart.addOn[i].itemType,
          )}`,
          productType: productTypeMap(recipientCart.addOn[i].itemType),
          sku: `${recipientCart.addOn[i].productId}`,
          category: 'fruit-gifts',
          name: recipientCart.addOn[i].name,
          qty: recipientCart.addOn[i].quantity,
          itemPrice: recipientCart.addOn[i].price,
          url: 'https://www.ediblearrangements.com/fruit-gifts/',
        });
      }
    }
  }
  return addOnItems;
};

export const getCartTotal = ({
  recipientCart: { recipientCart },
}: RootState): number => {
  let total = 0;
  if (recipientCart.recipient) {
    recipientCart.recipient?.forEach((x) => {
      total += x.recipientTotal;
    });
  }

  return total;
};

export const getCartMerchandiseTotal = ({
  recipientCart: { recipientCart },
}: RootState): string => {
  let total = 0;
  if (recipientCart.recipient) {
    recipientCart.recipient?.forEach((x) => {
      total += x.recipientMerchandiseTotal;
    });
  }

  return total.toString();
};

export const checkFreeProduct = ({
  recipientCart: { recipientCart },
}: RootState): boolean => {
  let freeProduct = false;
  recipientCart.addOn?.forEach((x) => {
    if (x.name.toUpperCase().includes('FREE')) freeProduct = true;
  });

  return freeProduct;
};

export const selectCartItemsForGA4 = ({
  recipientCart: { recipientCart },
}: RootState): CartProducts[] => {
  const cartItems = [];
  if (recipientCart?.cartItem) {
    for (let i = 0; i < recipientCart.cartItem.length; i += 1) {
      cartItems.push({
        item_id: `${recipientCart.cartItem[i].productId}`,
        item_name: recipientCart.cartItem[i].name,
        index: 0,
        item_category: 'fruit-gifts',
        price: recipientCart.cartItem[i].price,
        quantity: recipientCart.cartItem[i].quantity,
      });
    }
  }
  return cartItems;
};

export const selectAllFreeAddOns = (
  state: RootState,
  productID: number,
): AddOn[] =>
  state.recipientCart.recipientCart?.addOn?.filter(
    (product) =>
      product.itemType === ItemType.Arrangement &&
      product.price === 0 &&
      product.parentCartRecipientItemId === productID &&
      !product.name.includes('CYO'),
  ) ?? [];

export const selectRecipientContact = ({
  recipientCart: { recipientCart },
}: RootState): RecipientContact[] => {
  if (!recipientCart?.recipient) {
    return [];
  }
  const deliveryRecipients = recipientCart.recipient.filter(
    (recipient) => recipient.orderType === OrderType.Delivery,
  );
  return deliveryRecipients.map((recipient) => {
    const [firstName, lastName] = recipient.recipientName.split(' ');
    return {
      name: recipient.recipientName,
      firstName,
      lastName,
      address1: recipient.recipientAddress1,
      address2: recipient.recipientAddress2,
      city: recipient.recipientCity,
      state: recipient.recipientState,
      zipCode: recipient.recipientArea,
      phone:
        recipient.recipientCellPhone ||
        recipient.recipientHomePhone ||
        recipient.recipientWorkPhone,
      email: recipient.email,
    };
  });
};
