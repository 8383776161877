import { ApiBaseService } from 'api/apibase.service';
import { AxiosResponse } from 'axios';
import { getWithExpiry, setWithExpiry } from 'utils/local-storage';

export interface SkinnyBannerItem {
  url: string;
  editorContent: string;
  frEditorContent?: string;
  sequence: number;
  bannerBackgroundColor: string;
}

export interface SkinnyBannerDeviceContent {
  available: boolean;
  autoSlideInterval: number;
  bannerBackgroundColor: string;
  items: SkinnyBannerItem[];
}

export interface OverrideTag {
  value: string;
  expiry: number;
}

export type WidgetContent = {
  widgetId: string;
  activeFrom: Date;
  activeTo: Date;
  createdAt: Date;
  eventName: string;
  type: string;
  content: {
    desktop: SkinnyBannerDeviceContent;
    mobile: SkinnyBannerDeviceContent;
  };
};

export type SkinnyBannerContentWidget = {
  id: string;
  createdAt: Date;
  type: string;
  isDraft: boolean;
  culture: string;
  newSkinnyBanner: boolean;
  content: [WidgetContent];
};

class SkinnyBannerApiService extends ApiBaseService {
  getSkinnyBanner(
    search: string,
  ): Promise<AxiosResponse<SkinnyBannerContentWidget>> {
    const params = new URLSearchParams(search);
    const previewDate = params.get('previewDate') || '';
    const overrideTag = params.get('skinnyBanner') || '';
    const cmsSourceParam = params.get('cms_source') || '';
    let customParam = '';
    const localStorageExpiryTime = 600000;

    const skinnyBannerLocalStorage = getWithExpiry('SKINNY_BANNER');
    const cmsSourceLocalStorage = getWithExpiry('CMS_SOURCE');

    if (previewDate !== '') {
      customParam = `?previewDate=${previewDate}`;
    }

    if (skinnyBannerLocalStorage !== null) {
      customParam = `?overrideTag=${skinnyBannerLocalStorage}`;
    }
    if (overrideTag !== '') {
      setWithExpiry('SKINNY_BANNER', overrideTag, localStorageExpiryTime);
      customParam = `?overrideTag=${overrideTag}`;
    }

    if (cmsSourceLocalStorage !== null) {
      customParam = `?source=${cmsSourceLocalStorage}`;
    }
    if (cmsSourceParam !== '') {
      setWithExpiry('CMS_SOURCE', cmsSourceParam, localStorageExpiryTime);
      customParam = `?source=${cmsSourceParam}`;
    }

    return this.apibase
      .get<SkinnyBannerContentWidget>(`/skinny${customParam}`)
      .then((res) => {
        return res;
      });
  }
}
const skinnyBannerApiService = new SkinnyBannerApiService();

export { skinnyBannerApiService };
