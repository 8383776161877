import { Box, CircularProgress } from '@mui/material';
import { HeaderSkeleton } from 'containers/PLPContainer/components/Header/HeaderSkeleton';
import { LOADING } from 'translations/locales/translation-keys.constant';
import { header } from 'containers/PLPContainer/styles';
import React, { FC } from 'react';
import i18next from 'i18next';

export const PlpContainerSkeleton: FC = () => (
  <Box
    width={1}
    maxWidth={1650}
    minHeight="100vh"
    mx="auto"
    display="flex"
    flexDirection="column"
  >
    <Box sx={header}>
      <HeaderSkeleton />
    </Box>
    <Box
      width={1}
      height={1}
      mt="20%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={50} thickness={5} title={i18next.t(LOADING)} />
    </Box>
  </Box>
);
