import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { storesApiService } from 'api/stores.api.service';

export const getStoreComingSoonDetails = createAsyncThunk(
  'stores/storeComingSoon',
  async ({ storeId }: { storeId: string }, { rejectWithValue }) => {
    try {
      const data = await storesApiService.getStoreComingSoonDetails(storeId);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
