import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Settings } from 'react-slick';
import React from 'react';

export const CarouselSettings: Settings = {
  lazyLoad: 'progressive',
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: false,
  initialSlide: 0,
  infinite: true,
  arrows: true,
  swipe: true,
  nextArrow: (
    <Box>
      <ArrowForwardIos fontSize="medium" />
    </Box>
  ),
  prevArrow: (
    <Box>
      <ArrowBackIosNew fontSize="medium" />
    </Box>
  ),
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
        arrows: true,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
        arrows: true,
      },
    },
  ],
};
