import { Status } from 'redux/types/state.interface';
import { checkArrangementGroupStatus } from 'components/common/ArrangementGroup/feature/selectors';
import { fetchArrangementGroup } from 'components/common/ArrangementGroup/feature/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useFetchOrderConfirmationArrangementGroup = (
  TOP_SALES_ID: number,
): void => {
  const dispatch = useDispatch();
  const arrangementGroupStatus = useSelector(checkArrangementGroupStatus);

  useEffect(() => {
    if (arrangementGroupStatus === Status.INIT) {
      dispatch(fetchArrangementGroup(TOP_SALES_ID));
    }
  }, []);
};
