export const ERROR_STRAWBERRY_MASCOT = 'error.strawberry_mascot';
export const ORDER_CONFIRMATION_ERROR_TITLE = 'order_confirmation.error_title';
export const ORDER_CONFIRMATION_ERROR_RELOAD =
  'order_confirmation.error_reload';
export const ORDER_CONFIRMATION_ERROR_SUMMARY =
  'order_confirmation.error_summary';
export const ORDER_CONFIRMATION_ERROR_CONTACT_US =
  'order_confirmation.error_contact_us';
export const PRODUCT_DISPLAY_PAGE_ERROR_TITLE = 'product_display.error_title';
export const PRODUCT_DISPLAY_PAGE_ERROR_RELOAD = 'product_display.error_reload';
export const PRODUCT_DISPLAY_PAGE_ERROR_SHOP_ALL =
  'product_display.error_shop_all';
export const PRODUCT_DISPLAY_PAGE_ERROR_SUMMARY =
  'product_display.error_summary';
export const PRODUCT_LISTING_PAGE_ERROR_TITLE = 'product_listing.error_title';
export const PRODUCT_LISTING_PAGE_ERROR_RELOAD = 'product_listing.error_reload';
export const PRODUCT_LISTING_PAGE_ERROR_SHOP_ALL =
  'product_listing.error_shop_all';
export const PRODUCT_LISTING_PAGE_ERROR_SUMMARY =
  'product_listing.error_summary';
export const TRACK_ORDER_ERROR_TITLE = 'track_order.error_title';
export const TRACK_ORDER_ERROR_RELOAD = 'track_order.error_reload';
export const TRACK_ORDER_ERROR_STORE_LOCATOR =
  'track_order.error_store_locator';
export const TRACK_ORDER_ERROR_SUMMARY = 'track_order.error_summary';
export const CART_ERROR_TITLE = 'cart.error_title';
export const CART_ERROR_RELOAD = 'cart.error_reload';
export const CART_ERROR_SUMMARY = 'cart.error_summary';
export const FULL_PAGE_ERROR_TITLE = 'full_page.error_title';
export const FULL_PAGE_ERROR_RELOAD = 'full_page.error_reload';
export const FULL_PAGE_ERROR_SUMMARY = 'full_page.error_summary';
export const PAYMENT_PAGE_ERROR_TITLE = 'payment_page.error_title';
export const PAYMENT_PAGE_ERROR_RELOAD = 'payment_page.error_reload';
export const PAYMENT_PAGE_ERROR_SUMMARY = 'payment_page.error_summary';
export const STORE_FINDER_ERROR_TITLE = 'store_finder.error_title';
export const STORE_FINDER_ERROR_RELOAD = 'store_finder.error_reload';
export const STORE_FINDER_ERROR_SUMMARY = 'store_finder.error_summary';
export const REWARDS_PAGE_ERROR_RELOAD = 'rewards_page.error_reload';
export const REWARDS_PAGE_ERROR_SUMMARY = 'rewards_page.error_summary';
export const REWARDS_PAGE_ERROR_TITLE = 'rewards_page.error_title';
export const PRESS_ROOM_ERROR_RELOAD = 'press_room.error_reload';
export const PRESS_ROOM_ERROR_SUMMARY = 'press_room.error_summary';
export const PRESS_ROOM_ERROR_TITLE = 'press_room.error_title';
