import { CustomArrowProps } from 'react-slick';
import { CustomizedControl } from 'components/Home/common/CarouselControl/style';
import React, { FC } from 'react';
import ReactDOM from 'react-dom';

interface ControlProps extends CustomArrowProps {
  children: React.ReactChild;
  portalContainerEl: Element | null;
}

export const CarouselControl: FC<ControlProps> = ({
  currentSlide,
  slideCount,
  children,
  portalContainerEl,
  ...props
}: ControlProps) =>
  portalContainerEl
    ? ReactDOM.createPortal(
        <CustomizedControl {...props}>{children}</CustomizedControl>,
        portalContainerEl,
      )
    : null;
