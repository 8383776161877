import { CartUpsell, ItemType } from 'types/cart.types';
import { ProductType } from 'api/products/types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { UpsellTypes } from 'enums/upsell-types.enum';
import { isNotUndefined } from 'utils/is-not-undefined';

export const extractUpsells = (
  upsellItems: UpsellItem[],
  upsellType: UpsellTypes,
): CartUpsell[] =>
  upsellItems
    .filter(
      ({ checked, quantity: quant, productType: prodType }) =>
        quant && checked && prodType === ProductType[upsellType],
    )
    .map(({ option: { price, productId }, quantity: quant, text }) => ({
      ProductID: productId,
      Price: price.toString(),
      Quantity: quant === undefined ? 0 : +quant,
      ItemType: ItemType[upsellType],
      OptionText: isNotUndefined(text) ? text : '',
      PrintibleCard: '',
      PrintibleCardResponse: '',
      Sleeve: false,
    }));
