import { StoreFinderRequest } from 'components/StoreFinder/interfaces/store-finder-page.interfaces';
import { canadaZipRegex } from 'utils/regex';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isCanada } from 'utils/country';
import { parseAxiosError } from 'utils/errors';
import { storesApiService } from 'api/stores.api.service';

export const getStoreFinderStores = createAsyncThunk(
  'storeFinder/getStores',
  async (storeFinderRequest: StoreFinderRequest, { rejectWithValue }) => {
    try {
      const data = await storesApiService.getStoreFinderStores(
        storeFinderRequest,
      );
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getStoreOccasionHoursAndAvailabilities = createAsyncThunk(
  'storeFinder/storeOccasionHoursAndAvailabilities',
  async (storeIdsList: string, { rejectWithValue }) => {
    try {
      const data =
        await storesApiService.getStoreOccasionHoursAndStoreAvailabilities(
          storeIdsList,
        );
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getStoresComingSoon = createAsyncThunk(
  'storeFinder/storesComingSoon',
  async (_, { rejectWithValue }) => {
    try {
      const data = await storesApiService.getStoresComingSoon();
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getStoreAreasBasedOnSearchInput = createAsyncThunk(
  'storeFinder/storeAreas',
  async (searchInput: string, { rejectWithValue }) => {
    try {
      const searchInputFormatted =
        isCanada && canadaZipRegex.test(searchInput)
          ? `${searchInput.toUpperCase().slice(0, 3)} ${searchInput
              .toUpperCase()
              .slice(-3)}`
          : searchInput;
      const data = await storesApiService.getAreasBasedOnSearch(
        searchInputFormatted,
      );
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
