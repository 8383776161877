import { BannerSkeleton } from 'components/Home/Banner/components/Skeleton';
import { ComponentProps } from 'components/Home/types';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { VideoTextContent } from 'components/Home/VideoText/types';
import { VideoWithText } from 'components/Home/VideoText/components/video-with-text';
import { WidgetWrapper } from 'components/Home/common/styled';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Errors {
  mobile: boolean;
  desktop: boolean;
}

const initialErrors: Errors = {
  mobile: false,
  desktop: false,
};

export const VideoText: FC<ComponentProps> = ({ type, id, sequence, name }) => {
  const [errors] = useState<Errors>(initialErrors);
  const componentData = useCmsWidgets(type, id);
  const videoTextContent = useResponsiveContent<VideoTextContent>(
    componentData,
    'sm',
  );
  const isPlpPageActive = useSelector(plpGetComponents);
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('sm'));

  const { imagesPreloaded } = useImagePreloader(videoTextContent.items);

  if (!imagesPreloaded) {
    return <BannerSkeleton />;
  }

  const isError = isUpMd ? errors.desktop : errors.mobile;

  if (!videoTextContent.available || isError) {
    return null;
  }

  const handleAnalyticsClick = () => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: '0',
      module_type: type,
      module_name: name,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <WidgetWrapper id={getId(type, id)} data-test="video-text-container">
        {videoTextContent.items.map((videoTextItem) => {
          return (
            <VideoWithText
              key={videoTextItem.src}
              videoTextItemSrc={videoTextItem.src}
              videoTextItemFrSrc={videoTextItem.frSrc}
              videoTextItemAltText={videoTextItem.altText}
              videoTextItemFrAltText={videoTextItem.frAltText}
              videoTextItemTitle={videoTextItem.title}
              videoTextItemFrTitle={videoTextItem.frTitle}
              videoTextItemDesc={videoTextItem.description}
              videoTextItemFrDesc={videoTextItem.frDescription}
              videoTextItemButtonText={videoTextItem.buttonText}
              videoTextItemFrButtonText={videoTextItem.frButtonText}
              videoTextItemButtonLink={videoTextItem.buttonLink}
              videoTextItemFrButtonLink={videoTextItem.frButtonLink}
              videoTextItemTextPosition={videoTextItem.textPosition}
              videoTextItemImageToggle={videoTextItem.hasImageToggle}
              handleAnalyticsClick={handleAnalyticsClick}
            />
          );
        })}
      </WidgetWrapper>
    </RenderWhenInView>
  );
};
