export const fonts = {
  default: 'Arial, sans-serif',
  geomanistbook: 'Geomanist-book, Arial, sans-serif',
  geomanistbold: 'Geomanist-bold, Arial, sans-serif',
  geomanistlight: 'Geomanist-light, Arial, sans-serif',
  geomanistmedium: 'Geomanist-medium, Arial, sans-serif',
  geomanistregular: 'Geomanist-regular, Arial, sans-serif',
  oswaldregular: 'Oswald-regular, Arial, sans-serif',
  ptsans: 'PT Sans Narrow, Helvetica Neue, Helvetica, Arial, sans-serif',
  ptsanscommon: 'PT Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  spartan: 'Spartan, Arial, sans-serif',
  poppins: 'Poppins, Arial, sans-serif',
};
