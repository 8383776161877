import { Settings } from 'react-slick';

export const settings: Settings = {
  lazyLoad: 'progressive',
  initialSlide: 0,
  infinite: false,
  slidesToShow: 4,
  arrows: true,
  swipe: true,
  speed: 500,
  rows: 2,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2.5,
      },
    },
  ],
};

export const cookieBoxSettings: Settings = {
  lazyLoad: 'progressive',
  initialSlide: 0,
  infinite: false,
  slidesToShow: 4,
  arrows: true,
  swipe: true,
  speed: 500,
  rows: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2.5,
      },
    },
  ],
};

export const dessertBoardSettings: Settings = {
  lazyLoad: 'progressive',
  initialSlide: 0,
  infinite: false,
  slidesToShow: 4,
  arrows: true,
  swipe: true,
  speed: 500,
  rows: 2,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2.5,
      },
    },
  ],
};
