import { ToggleButton, styled } from '@mui/material';

export const Toggle = styled(ToggleButton)(({ theme }) => ({
  textAlign: 'start',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  textTransform: 'none',
  color: theme.palette.text.primary,

  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.other.outlineBorder,

  '&.Mui-selected': {
    border: `2px solid ${theme.palette.primary.main}`,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  '&:hover': {
    background: 'none',
    borderColor: theme.palette.grey[500],
  },
}));
