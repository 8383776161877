export const DELIVERY_INSTRUCTIONS = 'instructions.delivery_instructions';
export const READ_OUR_DELIVERY_POLICIES =
  'instructions.read_our_delivery_policies';
export const DELIVERY_INSTRUCTIONS_LABEL =
  'instructions.delivery_instructions_label';
export const DELIVERY_INSTRUCTIONS_PLACEHOLDER =
  'instructions.delivery_instructions_placeholder';
export const PICKUP_INSTRUCTIONS = 'instructions.pickup_instructions';
export const PICKUP_INSTRUCTIONS_PLACEHOLDER =
  'instructions.pickup_instructions_placeholder';
