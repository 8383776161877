import * as styles from 'components/Home/ChipWidget/styles';
import { Box, Grid } from '@mui/material';
import { ChipItem } from 'components/Home/ChipWidget/components/ChipItem';
import { ChipWidgetContent } from 'components/Home/ChipWidget/types';
import { ComponentProps } from 'components/Home/types';
import { Headline } from 'components/Home/ChipWidget/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const ChipWidget: FC<ComponentProps> = ({
  type,
  id,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const content = useResponsiveContent<ChipWidgetContent>(componentData, 'md');
  const isPlpPageActive = useSelector(plpGetComponents);

  const handleAnalyticsClick = (position: number, linkName: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: position.toString(),
      module_type: type,
      module_name: name,
      link: linkName,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <Box
        id={getId(type, id)}
        sx={styles.wrapper}
        component="section"
        data-test="chip-widget"
      >
        <Headline>
          <PureHtmlTypography
            sx={styles.title}
            variant="h3"
            align="center"
            data-test="chip-widget-title"
          >
            {checkForFrench(content.title, content.frTitle)}
          </PureHtmlTypography>
        </Headline>
        <Grid
          container
          sx={styles.grid}
          rowSpacing={3}
          columnSpacing={{ xs: 2, md: 4 }}
          data-test="chip-widget-grid"
        >
          {content.items.map((item, index) => (
            <ChipItem
              key={item.title}
              item={item}
              analyticsClickHandler={() =>
                handleAnalyticsClick(index + 1, item.title)
              }
            />
          ))}
        </Grid>
      </Box>
    </RenderWhenInView>
  );
};
