import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useSearch = (): string => useLocation().search;

export const useQueryParameter = (param: string): string | null =>
  new URLSearchParams(useSearch()).get(param);

export const useQueryParameters = (param: string): string[] => {
  const search = useSearch();
  return useMemo(
    () => new URLSearchParams(search).getAll(param),
    [param, search],
  );
};

export const useQueryParametersObject = (): Record<string, string> => {
  const search = useSearch();
  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search).entries()),
    [search],
  );
};
