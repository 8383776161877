import { AxiosError, AxiosResponse } from 'axios';

export const rejectResponseByStatusCode =
  (code: number) =>
  (res: AxiosResponse): Promise<AxiosResponse> => {
    if (res.status === code) {
      const error = new Error() as AxiosError;
      error.code = `${res.status}`;
      error.response = res;
      return Promise.reject(error);
    }

    return Promise.resolve(res);
  };
