export const HOME_PAGE_VIEW_ALL = 'home_page.view_all';
export const HOME_PAGE_CAROUSEL_FORWARD_CONTROL =
  'home_page.carousel_forward_control';
export const HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL =
  'home_page.carousel_backward_control';
export const NEAREST_STORE_WEEKLY_HOURS = 'nearest_store.weekly_hours';
export const NEAREST_STORE_PHONE_CONTACT = 'nearest_store.phone_contact';
export const NEAREST_STORE_DIRECTIONS = 'nearest_store.directions';
export const HOME_PAGE_H1_HEADING = 'home_page.h1_heading';
export const FRANCHISE_BANNER_TITLE = 'home_page.franchise_banner.title';
export const FRANCHISE_BANNER_BODY = 'home_page.franchise_banner.body';
export const FRANCHISE_BANNER_BUTTON = 'home_page.franchise_banner.button';
export const POPULAR_CATEGORIES = 'home_page.popular_categories';
