import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { initDynamicCatalogCollection } from 'lib/bazaarvoice/services/dynamic-catalog-collection.service';
import { useBazaarvoice } from 'lib/bazaarvoice/hooks/use-bazaarvoice';
import React, { FC } from 'react';

const BRAND_NAME = 'Edible Arrangements';

interface Props {
  arrangement: Arrangement;
}

export const DccProvider: FC<Props> = ({ arrangement, children }) => {
  useBazaarvoice({
    callback: initDynamicCatalogCollection({
      locale: arrangement.culture,
      productId: arrangement.catalogNumber,
      productName: arrangement.name,
      productImageUrl: arrangement.products[0]?.image,
      productPageUrl: window.location.href,
      brandName: BRAND_NAME,
    }),
  });

  return <>{children}</>;
};
