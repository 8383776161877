import { SxProps } from '@mui/system';

export const snackbar: SxProps = {
  width: ['96%', '90%', '100%'],
  maxWidth: '320px',
  margin: '0 auto',
  '& .MuiPaper-root': {
    color: 'text.primary',
  },
};
