import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { getPreviewLocaleKey } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/utils/get-preview-locale-key';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  orderType: FulfillmentOptionType | OrderType.NotSpecified;
}

export const Preview: FC<Props> = ({ orderType }) => (
  <span>
    {orderType !== OrderType.NotSpecified && (
      <span>{i18next.t(getPreviewLocaleKey(orderType))} </span>
    )}
  </span>
);
