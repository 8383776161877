import * as styles from 'components/Home/GeneralText/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
} from '@mui/material';
import { ComponentProps } from 'components/Home/types';
import { Components } from 'enums/home-components';
import { Container } from 'components/Home/GeneralText//styles';
import { GeneralTextContent } from 'components/Home/GeneralText/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { SectionWrapper } from 'components/Footer/BottomNavigation/styles';
import { checkForFrench } from 'components/Home/utils';
import { colors } from 'utils/theme/colors';
import { getComponent, getComponents } from 'components/Home/feature/selectors';
import { getId } from 'components/Home/utils/get-html-id';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useState } from 'react';
import parse from 'html-react-parser';

export const GeneralText: FC<ComponentProps> = ({ type, id, sequence }) => {
  const componentData = useSelector(getComponent(type, id));
  const components = useSelector(getComponents);
  const content = useResponsiveContent<GeneralTextContent>(componentData, 'md');
  const item = content.items[0];
  const [expanded, setExpanded] = useState<boolean>(item.expandAccordian);
  const isAccordion = item.title && item.accordian;
  const widgetContent = checkForFrench(item.body, item.frBody);
  const parser = new DOMParser();
  const contentDoc = parser.parseFromString(widgetContent, 'text/html');
  const anchorTags: HTMLAnchorElement[] = Array.from(
    contentDoc.getElementsByTagName('a'),
  );
  const elements: HTMLElement[] = Array.from(contentDoc.querySelectorAll('*'));

  elements.map((element) => {
    const bodyElement = element;
    bodyElement.style.fontFamily = '';
    return element;
  });

  anchorTags.map((element) => {
    const anchorTagElement = element;
    anchorTagElement.style.color = colors.primaryRed;
    if (anchorTagElement.childNodes[0].nodeType === 1) {
      const spanElement = anchorTagElement.childNodes[0] as HTMLElement;
      spanElement.style.color = colors.primaryRed;
    }
    return element;
  });

  const updateContent = contentDoc.body.innerHTML;

  if (!content.available) {
    return null;
  }

  const handleChange = () => () => {
    setExpanded(!expanded);
  };

  const lastGeneralTextComponent = components
    ?.slice()
    .reverse()
    .find((component) => component.type === Components.generalText);

  const generalTextComponentTitleVariant =
    lastGeneralTextComponent?.sequence === componentData.sequence ? 'h1' : 'h2';

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <section id={getId(type, id)}>
        <Divider />
        <SectionWrapper>
          <Container>
            {isAccordion ? (
              <Accordion
                expanded={expanded}
                onChange={handleChange()}
                sx={styles.noSpace}
              >
                <AccordionSummary
                  sx={styles.accordianText}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <PureHtmlTypography
                    sx={styles.title}
                    variant={generalTextComponentTitleVariant}
                  >
                    {checkForFrench(item?.title, item?.frTitle)}
                  </PureHtmlTypography>
                </AccordionSummary>
                <AccordionDetails sx={styles.noSpace}>
                  <Box sx={{ mt: 5 }}>
                    <div> {parse(updateContent)}</div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Box>
                  <PureHtmlTypography
                    sx={styles.title}
                    variant={generalTextComponentTitleVariant}
                  >
                    {checkForFrench(item?.title, item?.frTitle)}
                  </PureHtmlTypography>
                </Box>
                <Box sx={{ pt: 5 }}>
                  <div> {parse(updateContent)}</div>
                </Box>
              </>
            )}
          </Container>
        </SectionWrapper>
      </section>
    </RenderWhenInView>
  );
};
