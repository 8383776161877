/* eslint-disable no-param-reassign */
import { OrderConfirmation } from 'redux/order-confirmation/types/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getOrderConfirmation } from 'redux/order-confirmation/order-confirmation.action';

export type OrderConfirmationState = {
  confirmation: {
    data: OrderConfirmation | null;
    status: Status;
  };
};

export const initialState: OrderConfirmationState = {
  confirmation: {
    data: null,
    status: Status.INIT,
  },
};

const orderConfirmationSlice = createSlice({
  name: 'orderConfirmation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderConfirmation.pending, (state) => {
        state.confirmation.status = Status.PENDING;
      })
      .addCase(getOrderConfirmation.rejected, (state) => {
        state.confirmation.status = Status.ERROR;
      })
      .addCase(getOrderConfirmation.fulfilled, (state, { payload }) => {
        state.confirmation.data = payload;
        state.confirmation.status = Status.FULFILLED;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: orderConfirmationReducer } = orderConfirmationSlice;
