import {
  OrderType,
  UpdateAddOnsParsedResponse,
  UpdateAddOnsStatus,
} from 'types/cart.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatToLegacyDateString } from 'utils/date';
import { parseAxiosError } from 'utils/errors';
import { productApiService } from 'api/products/product.api.service';
import { updateAddOnsLegacyApiService } from 'api/update-addons-upgrades.legacy.api.service';

type Params = {
  orderType: OrderType;
  date: Date;
  storeId: number;
  productId: number;
};

export const fetchUpgrades = createAsyncThunk(
  'pdp/fetch/upgrades',
  async ({ orderType, date, storeId, productId }: Params) => {
    const { data } = await productApiService.getUpgrades(productId, {
      store: storeId,
      date: formatToLegacyDateString(date),
      orderType,
    });
    return data;
  },
);

export const fetchAddons = createAsyncThunk(
  'pdp/fetch/addons',
  async ({ orderType, date, storeId, productId }: Params) => {
    const { data } = await productApiService.getAddons(productId, {
      store: storeId,
      date: formatToLegacyDateString(date),
      orderType,
    });
    return data;
  },
);

export const updateAddOnsUpgrades = createAsyncThunk(
  'pdp/fetch/updateaddonsupgrades',
  async (upsellItems: string, { rejectWithValue }) => {
    try {
      const { data } = await updateAddOnsLegacyApiService.UpdateAddOnsUpgrades(
        upsellItems,
      );
      const parsedResponse = JSON.parse(data.d) as UpdateAddOnsParsedResponse;
      if (parsedResponse._Status === UpdateAddOnsStatus.Error) {
        return rejectWithValue(parsedResponse);
      }
      return parsedResponse;
    } catch (error) {
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);
