import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addOnUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { sale } from 'components/OrderConfirmation/translations/locale.constants';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  product: OrderSubItem;
}

export const CombinationProduct: FC<Props> = ({ product }) => (
  <Box sx={styles.combinationProduct}>
    <Image src={addOnUrl(product.addOnOptionImage)} />
    <Box width="100%" ml={2}>
      {product.name.includes('CYODB') ||
      product.name.includes('CYO Cookie Box') ? (
        <Box className="productDescription" justifyContent="space-between">
          <PureHtmlTypography
            sx={styles.nameStyle}
            variant="subtitle3"
            className="fontStyle"
          >
            {product.sizeName}
          </PureHtmlTypography>
          {product.name.includes('CYO Cookie Box') && (
            <Typography className="fontStyle" variant="subtitle3">
              (x{product.quantity})
            </Typography>
          )}
        </Box>
      ) : (
        <Box className="productDescription" justifyContent="space-between">
          <PureHtmlTypography
            sx={styles.nameStyle}
            className="fontStyle"
            variant="subtitle3"
          >
            {product.name}
          </PureHtmlTypography>
          <Typography
            sx={styles.nameStyle}
            className="fontStyle"
            variant="subtitle3"
          >
            (
            {i18next.t(sale.ADDON_QTY, {
              '#': product.quantity,
            })}
            )
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);
