import { LegacyLogInValidationStatus } from 'api/auth-legacy/validate.misc';

export enum CartResponseStatus {
  Error = 'Error',
  Exception = 'Exception',
  OK = 'OK',
}

export enum LegacyResponseStatus {
  None = 'None',
  ERROR = 'ERROR',
  Error = 'Error',
  PARTIAL = 'PARTIAL',
  EXCEPTION = 'EXCEPTION',
  Exception = 'Exception',
  FULL = 'FULL',
  OK = 'OK',
}

export type DResponse = {
  d: string;
};

export type ValidationParsedResponse = {
  _Status: LegacyLogInValidationStatus;
  _Message: string;
  _Data: string;
};

export type CartParsedResponse = {
  _Status?: CartResponseStatus;
  _Message?: string;
  _Data?: number;
};

export type UpdateCartParsedResponse = {
  _Status?: CartResponseStatus;
  _Message?: string;
};

export type CardMessageParsedResponse = {
  _Status?: CartResponseStatus;
  _Data: string;
};
