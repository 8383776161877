import { Box } from '@mui/material';
import { PasswordField } from 'components/SignUp/password-field';
import { SignupData as SignupFormData } from 'types/forms/signup-data-new';
import { StrengthMeter } from 'components/SignUp/strength-meter';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { passwordRegexStrength } from 'components/SignUp/password-strength-regex';
import React, { FC, useEffect, useState } from 'react';

interface PasswordCheckerProps {
  register: UseFormRegister<SignupFormData>;
  watch: UseFormWatch<SignupFormData>;
  passwordValue: string;
  error: boolean;
  errorText: string | null;
}

export const PasswordStrengthChecker: FC<PasswordCheckerProps> = ({
  register,
  watch,
  passwordValue,
  error,
  errorText,
}) => {
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState<string>('');

  const checkPasswordStrength = (passwordChangeValue: string): void => {
    if (passwordChangeValue === '') {
      setPasswordStrength('Empty');
      setPasswordError('Empty');
      return;
    }
    let strength = '';
    passwordRegexStrength.forEach((reg) => {
      if (reg.regex.test(passwordChangeValue)) {
        strength = reg.label;
      }
    });
    setPasswordStrength(strength);
    setPasswordError(strength);
  };

  useEffect(() => {
    checkPasswordStrength(passwordValue);
  }, [passwordValue]);

  return (
    <Box className="row">
      <Box className="col-sm-4">
        <PasswordField
          error={error}
          errorText={errorText}
          watch={watch}
          register={register}
        />
        {passwordValue.length > 0 && (
          <StrengthMeter
            passwordStrength={passwordStrength}
            passwordError={passwordError}
          />
        )}
      </Box>
    </Box>
  );
};
