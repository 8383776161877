import {
  GetContactsParams,
  contactsApiService,
} from 'api/contacts.api.service';
import { RequestOptions } from 'redux/types/request-options.interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

const getFetchContactsAction = (prefix: string) =>
  createAsyncThunk(
    prefix,
    async (
      { cancelToken, ...params }: GetContactsParams & RequestOptions,
      { rejectWithValue },
    ) => {
      try {
        const { data } = await contactsApiService.get(params, { cancelToken });

        return data;
      } catch (err) {
        return rejectWithValue(parseAxiosError(err));
      }
    },
  );

export const fetchContacts = getFetchContactsAction('addressBook/fetch');

export const fetchMoreContacts = getFetchContactsAction(
  'addressBook/fetchMore',
);
