import * as TK from 'components/Authentication/translations/locale.constants';
import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .email(TK.AUTH_ERR_EMAIL_INVALID)
    .required(TK.AUTH_LOG_IN_ERR_EMAIL_REQUIRED),
  password: yup.string().required(TK.AUTH_LOG_IN_ERR_PASSWORD_REQUIRED),
});
