import * as TK from 'containers/AddressBook/translations/locale.constants';

export const addressBookCommonLocale = {
  [TK.ADDRESS_BOOK_BUTTON_TEXT]: 'Add Recipient From Address Book',
  [TK.ADDRESS_BOOK_SEARCH_INPUT_PLACEHOLDER]: 'Search Addresses...',
  [TK.ADDRESS_BOOK_TITLE]: 'address book',
  [TK.ADDRESS_BOOK_NO_RESULTS_FOUND]:
    'Unfortunately, you don’t have any saved addresses.',
  [TK.ADDRESS_BOOK_NO_RESULTS_FOUND_ON_SEARCH]: 'No addresses found',
  [TK.ADDRESS_BOOK_CAN_NOT_FETCH_CONTACTS]: "Can't fetch contacts",
  [TK.ADDRESS_BOOK_CLOSE_BUTTON]: 'Close',
};
