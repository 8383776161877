import { Theme, alpha, createTheme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { red } from '@mui/material/colors';

export const getTheme = (): Theme => {
  const initial = createTheme({
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: fonts.poppins,
            color: colors.primaryBlack,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: fonts.poppins,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            opacity: 1,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            lineHeight: '19px',
            padding: '2px 0 3px 0',
            '&.Mui-focused': {
              color: colors.primaryBlack,
              marginTop: 0,
            },
            '&.Mui-error': {
              color: red['500'],
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: red['500'],
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-shrink': {
              paddingRight: 8,
              paddingLeft: 6,
              backgroundColor: colors.white,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: 14,
            lineHeight: '19px',
            letterSpacing: '0.15px',
            color: alpha('#000', 0.6),
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiSkeleton: {
        defaultProps: {
          variant: 'rectangular',
        },
      },
    },
    breakpoints: {
      values: {
        xs: 360,
        sm: 768,
        md: 1200,
        lg: 1440,
        xl: 1920,
      },
    },
    spacing: 8,
    palette: {
      mode: 'light',
      common: {
        black: colors.primaryBlack,
      },
      primary: {
        main: '#E10600',
        dark: '#900400',
        contrastText: '#fff',
      },
      secondary: {
        main: '#252525',
      },
      warning: {
        main: '#ff9900',
        contrastText: alpha('#000', 0.87),
      },
      grey: {
        100: '#F8F8F8',
        200: '#DFDFDF',
        400: '#F4F6F9',
        500: '#555',
        600: '#757575',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      action: {
        hoverOpacity: 0.08,
      },
      other: {
        outlineBorder: alpha('#000', 0.23),
        standardInputLine: alpha('#000', 0.56),
      },
      divider: alpha('#000', 0.12),
      custom: {
        bodyBg: '#f2f3f5',
        contrastLightBg: 'rgba(255, 255, 255, 0.2)',
        whiteBg: alpha('#ffffff', 0.8),
      },
    },
    typography: {
      fontFamily: fonts.poppins,
      h3: {
        fontWeight: 600,
        fontSize: 17,
        lineHeight: '21px',
      },
      h4: {
        fontSize: 34,
        fontWeight: 600,
      },
      h5: {
        fontSize: 24,
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: '160%',
        letterSpacing: 0.15,
      },
      subtitle1: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '150%',
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '143%',
      },
      subtitle3: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '164%',
      },
      body1: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '143%',
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        fontStyle: 'normal',
        lineHeight: '143%',
      },
      body3: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '164%',
      },
    },
  });

  return createTheme(
    {
      ...initial,
      components: {
        ...initial.components,
        MuiDialog: {
          styleOverrides: {
            root: {
              '.MuiDialog-paper': {
                position: 'absolute',
                bottom: 0,
                maxHeight: `calc(100% - ${initial.spacing(5)})`,
                width: '100%',
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                marginTop: initial.spacing(5),
                display: 'flex',
                [initial.breakpoints.up('xs')]: {
                  maxWidth: '100%',
                },
                [initial.breakpoints.up('sm')]: {
                  position: 'initial',
                  height: 'auto',
                  maxWidth: 688,
                },
                [initial.breakpoints.up('md')]: {
                  maxWidth: 688,
                },
              },
              '.MuiDialogTitle-root': {
                padding: initial.spacing(4, 4, 3, 4),
              },
              '.MuiDialogActions-root': {
                padding: initial.spacing(3, 4, 4, 4),
              },
            },
          },
        },
        MuiBreadcrumbs: {
          styleOverrides: {
            root: {
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '164%',
              '.MuiLink-root': {
                color: initial.palette.text.primary,
                textDecoration: 'none',
              },
              '.MuiButton-text': {
                padding: 0,
                fontSize: 12,
                lineHeight: '164%',
                '.MuiButton-startIcon': {
                  marginRight: initial.spacing(0.5),
                },
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              alignItems: 'center',
              '&.MuiAlert-standardInfo': {
                color: initial.palette.common.black,
                backgroundColor: initial.palette.grey[100],
              },
              '&.MuiAlert-standardInfo > .MuiAlert-icon': {
                color: initial.palette.common.black,
              },
              '&.MuiAlert-filledError': {
                backgroundColor: red['500'],
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              borderWidth: '4px',
              borderRadius: 0,
              borderColor: colors.primaryRed,
              '&:hover': {
                boxShadow: 'none',
              },
            },
            sizeSmall: {
              fontSize: 13,
              lineHeight: '22px',
              letterSpacing: '0.46px',
              padding: '5.5px 16px 4.5px',
            },
            textSizeSmall: {
              padding: '5.5px 12px 4.5px',
            },
            sizeMedium: {
              fontSize: 14,
              lineHeight: '24px',
              letterSpacing: '0.4px',
              padding: '8.5px 24px 7.5px',
            },
            textSizeMedium: {
              padding: '8.5px 16px 7.5px',
            },
            sizeLarge: {
              fontSize: 15,
              lineHeight: '26px',
              letterSpacing: '0.4px',
              padding: '15.5px 32px 14.5px',
            },
            textSizeLarge: {
              padding: '15.5px 24px 14.5px',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
            outlined: {
              borderColor: initial.palette.other.outlineBorder,
              color: initial.palette.text.primary,
            },
            iconColorPrimary: {
              color: initial.palette.primary.dark,
            },
            deletableColorPrimary: {
              color: initial.palette.primary.main,
            },
            labelMedium: initial.typography.body2,
            labelSmall: initial.typography.body3,
            iconSmall: {
              fontSize: 16,
              marginLeft: initial.spacing(0.5),
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            list: {
              display: 'flex',
              flexDirection: 'column',
              gap: initial.spacing(0.5),
              padding: initial.spacing(1),
            },
          },
          defaultProps: {
            PaperProps: {
              elevation: 16,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              padding: initial.spacing(1, 2),
              minHeight: 'unset',

              '&:hover': {
                background: alpha(
                  initial.palette.primary.main,
                  initial.palette.action.hoverOpacity,
                ),
                '&, & *': {
                  color: initial.palette.primary.main,
                },
              },

              '&.Mui-selected': {
                border: `2px solid ${initial.palette.primary.main}`,
                color: initial.palette.primary.main,

                '&.Mui-disabled': {
                  border: 0,
                  opacity: 1,
                  color: initial.palette.text.disabled,
                  background: initial.palette.action.disabledBackground,
                },
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              ...initial.typography.body2,
              color: initial.palette.primary.main,

              '&:hover': {
                textDecoration: 'underline',
                color: initial.palette.primary.dark,
              },
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            colorSecondary: {
              color: initial.palette.text.secondary,
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: 'none',

              '&.Mui-expanded': {
                marginTop: 0,
                marginBottom: 0,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              '&.Mui-expanded': {
                minHeight: 'auto',
              },
              '& .MuiAccordionSummary-content, &.Mui-expanded .MuiAccordionSummary-content':
                {
                  margin: initial.spacing(1.75, 0),
                },
            },
          },
        },
        MuiRating: {
          styleOverrides: {
            root: {
              color: initial.palette.primary.main,

              '&.MuiRating-sizeSmall': {
                fontSize: 18,
              },

              '&.MuiRating-sizeMedium': {
                fontSize: 24,
              },
            },
          },
        },
        MuiStepLabel: {
          styleOverrides: {
            root: {
              '&.Mui-disabled *': {
                color: initial.palette.text.disabled,
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontWeight: 500,
              letterSpacing: 0.15,
            },
            outlined: {
              left: 8,
            },
            shrink: {
              '&.Mui-focused': {
                color: initial.palette.text.primary,
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              fontSize: 14,
              lineHeight: '19px',
              borderRadius: 0,
              '& fieldset': {
                padding: '0 16px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '17px 20px',
                '&.MuiInputBase-inputMultiline': {
                  padding: 0,
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: initial.palette.other.standardInputLine,
              },
              '&.Mui-focused': {
                color: initial.palette.text.primary,

                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: initial.palette.secondary.main,
                },
              },
              '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: red[500],
                },
              },
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              ...initial.typography.body2,
              textAlign: 'center',
              padding: '8px 16px',
              position: 'relative',
              color: initial.palette.text.primary,
              textTransform: 'none',
              borderRadius: 0,
              '&.Mui-selected': {
                background: '#fff',
                '&:hover': {
                  background: 'none',
                },
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: '-1px',
                  right: '-1px',
                  bottom: '-1px',
                  top: '-1px',
                  border: `2px solid ${initial.palette.primary.main}`,
                  display: 'block',
                },
              },
            },
          },
        },
      },
    },
    {
      [initial.breakpoints.up('sm')]: {
        fontSize: 25,
        lineHeight: '28px',
      },
    },
  );
};
