export enum FilterAction {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum PageType {
  ProductDetailedPage = 'product-detail-page',
  Cart = 'cart',
  MiniCart = 'mini-cart',
  ProductListPage = 'category-page',
  BusinessGifting = 'business-gifting',
  HomePage = 'home-page',
  Login = 'login-div',
  LocalSeoPage = 'local-seo-page',
  LocalSeoStatePage = 'local-seo-state-page',
  LocalSeoCityPage = 'local-seo-city-page',
  StoreFinderPage = 'store-finder',
  StoreFinderResults = 'store-finder-results',
  OrderTrackingPage = 'track-your-order',
  OrderTrackingResults = 'track-your-order-results',
  StoreDetailPage = 'store-detail-page',
  StoreComingSoonPage = 'store-coming-soon-page',
  ContactUsPage = 'contact-us-page',
  PlpInterrupterPage = 'plp-interrupter-page',
}

export enum Event {
  AddressValidationModule = 'address validation module',
  AddressValidationSelection = 'address validation module selection',
  PageLoadComplete = 'page load completed',
  PageData = 'page data',
  CartPageModalLoad = 'modal load',
  EditProductModalLoad = 'modal load',
  ArrangementClicked = 'arrangement clicked',
  BusinessGiftingContactAdded = 'business gifting contact added',
  SubscriptionSignup = 'email subscription',
  UserData = 'user data',
  PageInit = 'page init',
  ProductViewed = 'product viewed',
  FormComplete = 'form complete',
  ShoppingCart = 'shopping cart',
  AddToCart = 'add to cart',
  RemoveFromCart = 'remove from cart',
  FormStart = 'form start',
  FormRewards = 'form complete',
  OrderConfirmationPageView = 'order confirmation page view',
  CtaClicked = 'cta clicked',
  PhoneClicked = 'phone clicked',
  WidgetViewed = 'widget viewed',
  Error = 'error',
  AddonUpgrades = 'addon upgrades',
  ProductSizeSelected = 'arrangement selected',
  StoreSelected = 'store selected',
  ProductRefinement = 'product refinement',
  SiteInteraction = 'site interaction',
  PromoSignUp = 'promo sign up',
  RewardsRegistration = 'rewards registration',
  Login = 'login',
  ProductSearch = 'product search',
  StateClicked = 'state clicked',
  CityClicked = 'city clicked',
  GiftFinderClicked = 'gift finder clicked',
  GiftFinderFindGifts = 'gift finder find gifts clicked',
  PrintablePopupClicked = 'Printable Popup clicked',
  PrintablePopupCardClicked = 'Printable Popup Premium Card clicked',
  PrintablePopupMessageClicked = 'Printable Popup Complimentary Gift message clicked',
  PurchaseConfirmation = 'purchase confirmation',
  MenuNavClicked = 'Menu Item clicked',
  StartNewOrderClicked = 'start new order clicked',
  WantToKeepShoppingClicked = 'want to keep shopping product clicked',
  SignUpStoreComingSoon = 'sign up for new store coming soon',
  RewardApplied = 'reward discount',
  PromoCodeApplied = 'promo applied',
  ContinueShopping = 'continue shopping',
  StartShopping = 'start shopping',
  ProductCarouselClicked = 'merchandising click',
  OneHourDeliveryRemoved = 'one hour delivery removed',
  OneHourDeliverySelected = 'one hour delivery selected',
  AlternativePaymentMethodUsed = 'alternative payment method',
  HomePageClick = 'home page click',
  GiftFinderInteraction = 'gift finder interaction',
  ShopAvailableProducts = 'Shop Available Products',
  SkinnyBannerClick = 'skinny banner click',
  PlpInterrupterLoad = 'plp interrupter load',
  PlpInterrupterClicked = 'plp interrupter click',
  PDPAvailabilityHelperLoad = 'pdp availability helper loaded',
  ShopAvailableProductsModalLoad = 'pdp availability helper shop available products',
  SuggestedDateModalLoad = 'pdp availability helper change date',
  ComplimentaryGiftMessageAIClicked = 'interaction',
  CyoSelection = 'cyoSelection',
  EditProductInteraction = 'interaction',
}

export enum Forms {
  OrderForm = 'ordering form',
  LoginForm = 'login form',
  BusinessGiftingContactForm = 'business gifting contact form',
  DeliveryInfoForm = 'delivery info',
  EditDeliveryInfoForm = 'edit delivery info',
  EditPickupInfoForm = 'edit pickup info',
  BillingInfoForm = 'billing info',
  GiftFinderForm = 'gift finder',
  GiftReminder = 'gift reminder',
  AddressBook = 'address book',
}

export enum AddressValidationDecision {
  ContinueWithOriginalAddress = 'continue with original address',
  ConfirmSuggestedAddress = 'confirm suggested address',
  ModifyOriginalAddress = 'modify original address',
  UpdateDeliveryDate = 'update delivery date',
}

export enum AuthStatus {
  Auth = 'authenticated',
  NotAuth = 'not-authenticated',
}

export enum Interaction {
  GetStoreDirection = 'getStoreDirection',
  EditCardMessages = 'editCardMessages',
  IntlWebsites = 'intlWebsites',
  PickupInstructions = 'instructions',
  Default = 'default',
  SORT_BY_RELEVANCE = 'sort by: relevance',
  SORT_BY_PRICE_LOW_TO_HIGH = 'sort by: price low to high',
  SORT_BY_PRICE_HIGH_TO_LOW = 'sort by: price high to low',
}

export enum UserAgent {
  Mobile = 'Edible-Mobile',
  Desktop = 'edible-desktop',
}

export enum Platform {
  React = 'React',
  ReactNew = 'React (New Design)',
}

export enum PromoModule {
  Modal = 'modal',
  InPage = 'in-page',
}

export enum Promo {
  Email = 'email address',
  Phone = 'phone number',
  EmailnPhone = 'email and phone',
}

export enum CartRecipientAmountType {
  Single = 'single recipient',
  Multiple = 'multiple recipient',
}

export enum ButtonModulePosition {
  BODY = 'body',
  PRODUCT_CAROUSEL = 'product carousel',
}

export enum OneHourDeliveryEvent {
  Selected = 1,
  Removed = 0,
}

export enum EventLocation {
  EditProductModal = 'edit product modal',
  ShoppingCart = 'shopping cart',
  PDP = 'pdp',
  CartRewards = 'cart rewards',
  AddOnModal = 'add on modal',
  AddonCarouselModal = 'addon carousel',
}

export enum ModalCtaLocation {
  ShoppingCart = 'shopping cart',
  EditProductModal = 'edit addons',
  AddOnModal = 'shop popular addons',
  AddonCarouselModal = 'See All Add-ons',
}

export enum Errors {
  SaturdayNotAvailable = 'Saturday Shipment Not Available',
  AddressNotRecognized = 'Address Not Recognized',
  InvalidAddress = 'Invalid Address',
}

export enum PaymentMethod {
  NotSpecified = 'not specified',
  Cash = 'cash',
  CreditCard = 'credit card',
  HouseCharge = 'house charge',
  GiftCertificate = 'gift certificate',
  DebitCard = 'debit card',
  EAConnect = 'ea connect',
  EAEnterprise = 'ea enterprise',
  Complementary = 'complimentary',
  Check = 'check',
  PayPal = 'paypal',
  BML = 'bml',
  EFT = 'eft',
  BarterTrade = 'barter trade',
  AmazonPay = 'amazon pay',
  AmazonMarketplace = 'amazon marketplace',
  ApplePay = 'apple pay',
  Others = 'others',
  Unpaid = 'unpaid',
  Venmo = 'venmo',
  GooglePay = 'google pay',
  Link = 'link',
}

export enum PDPAvailabilityModal {
  ShopAvailableProductsModal = 'pdp availability helper',
  FulfillmentModal = 'fulfillment helper module',
  ProductCarouselModuleType = 'product carousel',
}
