import * as TK from 'components/AreaController/translations/locale.constants';

export const areaCommonLocale = {
  [TK.AREA]: 'Zip Code',
  [TK.POSTAL_CODE]: 'Postal Code',
  [TK.STATE]: 'State',
  [TK.CITY]: 'City/Town',
  [TK.CHANGE_AREA]: 'Need to change zip code?',
  [TK.CHANGE_POSTAL_CODE]: 'Need to change Postal Code?',
  [TK.CHANGE_AREA_INFO]:
    'Your delivery zip code and state are locked as per your selection. You can modify the delivery zip by selecting "Need to change zip code" option',
  [TK.CHANGE_POSTAL_CODE_INFO]:
    'Your delivery postal code and state are locked as per your selection. You can modify the delivery postal code by selecting "Need to change postal code" option',
  [TK.CART_POSTAL_CODE_INFO]:
    'Each store carries different treats. To change the zip code, you must start a new order. This ensures your treats are still available.',
  [TK.CHANGE_ZIP_TITLE]: 'change zip code',
  [TK.CHANGE_ZIP_DESCRIPTION]:
    'Each store carries different treats. Changing the zip code will restart the process from the product page so we can check that your selection is still available.',
  [TK.CHANGE_BACK_BUTTON]: 'Go Back',
  [TK.CHANGE_ZIP_BUTTON]: 'Change Zip Code',
};
