import { OrderType } from 'types/cart.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { productOptionsAvailabilityApiService } from 'api/availability/product-options-availability.api.service';

export const fetchProductsAvailability = createAsyncThunk(
  'product-options/availability',
  (
    params: {
      ids: number[];
      storeId: number;
      areaId: number;
      orderType: OrderType;
      fulfillmentDate: Date;
    },
    { rejectWithValue },
  ) =>
    productOptionsAvailabilityApiService
      .fetch(params)
      .catch((error) => rejectWithValue(parseAxiosError(error))),
);
