import { SxProps } from '@mui/material';
import { theme } from 'utils/theme/theme';

export const bottomContinuePanel: SxProps = {
  width: 1,
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 5,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    zIndex: 10,
  },
};

export const bottomContinueWrapper: SxProps = {
  mx: 'auto',
  px: 0,
  py: 1.5,
  maxWidth: [0, 688, 1060],
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
};

export const bottomContinueBtn: SxProps = {
  width: [0, 336, 297],
  height: 56,
};
