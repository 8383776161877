import * as TRANSLATION_KEYS from 'translations/locales/translation-keys.constant';
import { AxiosError } from 'axios';
import {
  CartParsedResponse,
  CartResponseStatus,
  UpdateCartParsedResponse,
} from 'types/legacy/response.types';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import {
  LegacyAddToCartParams,
  UpdatePickUpTimeParams,
} from 'types/cart.types';
import { LegacyAddedToCartResponse } from 'redux/cart/cart.types';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { appInsights } from 'utils/telemetry/app-insights';
import { cartApiService } from 'api/cart.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fillLegacyCartDefaultParams } from 'utils/fill-legacy-cart-default-params';
import { fillLegacyUpdateCartDefaultParams } from 'utils/fill-legacy-update-cart-default-params';
import { fulfillmentApiService } from 'api/fulfillment/fulfillment-api.service';
import { legacySessionApiService } from 'api/legacy-session';
import { parseAxiosError } from 'utils/errors';
import i18next from 'i18next';

function trackBadCart(cart: unknown) {
  appInsights.trackException({
    exception: new Error(
      `Bad cart response: \n${JSON.stringify(cart, null, 2)}`,
    ),
    severityLevel: SeverityLevel.Critical,
  });
}

/** Legacy escape function */
const escape = (string: string): string =>
  string
    .replace(/\\/g, '\\\\')
    .replace(/\\['\\]|'/g, (key: string) => (key === "'" ? "\\'" : key))
    .replace(/"/g, '\\"');

function parseResponse(cartResponse: LegacyAddedToCartResponse): number {
  if (!cartResponse || !cartResponse?.d) {
    trackBadCart(cartResponse);
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  let cart: CartParsedResponse;
  try {
    cart = JSON.parse(cartResponse.d) as CartParsedResponse;
  } catch (e) {
    trackBadCart(cartResponse);
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  if (cart?._Status !== CartResponseStatus.OK) {
    trackBadCart(cart);
    throw Error(cart._Message);
  }

  if (cart?._Data === undefined) {
    trackBadCart(cart);
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  return cart._Data;
}

function parseUpdateCardResponse(
  cartResponse: LegacyAddedToCartResponse,
): boolean {
  if (!cartResponse || !cartResponse?.d) {
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  let response: UpdateCartParsedResponse;
  try {
    response = JSON.parse(cartResponse.d) as CartParsedResponse;
  } catch (e) {
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  if (response?._Status !== CartResponseStatus.OK) {
    throw Error(response._Message);
  }

  if (response?._Message === undefined) {
    throw Error(`${i18next.t(TRANSLATION_KEYS.WE_GOT_AN_ERROR)}`);
  }

  return !!response._Message;
}
export const addToLegacyCart = createAsyncThunk(
  'cart/addLegacy',
  async (legacyCartData: LegacyAddToCartParams, { rejectWithValue }) => {
    if (IS_MOBILE_HOST) {
      await legacySessionApiService.update({
        areaId: legacyCartData.AreaID,
        areaName: legacyCartData.AreaName,
        serviceOption: legacyCartData.ServiceOption,
        selectedDate: new Date(legacyCartData.FulfillmentDate),
        arrangementId: legacyCartData.ArrangementID,
        arrangementGroupId: legacyCartData.ArrangementGroupID,
        arrangementSizeID: +legacyCartData.ArrangementSizeID,
        quantity: legacyCartData.Quantity,
        productId: +legacyCartData.ProductID,
        stateCode: legacyCartData.StateCode,
        currentRecipientId: legacyCartData.CurrentRecipientID,
      });
    }
    const { data } = await fulfillmentApiService.legacyAddToCart(
      `{'objJSON': '${escape(
        JSON.stringify(fillLegacyCartDefaultParams(legacyCartData)),
      )}','ToValidateAddress':'false',
      'IsFirstStoreSelected':${IS_MOBILE_HOST ? 'true' : 'false'},'AreaName':'${
        legacyCartData.AreaName
      }'}`,
    );
    try {
      return parseResponse(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getStoreDetailsById = createAsyncThunk(
  'cart/store-details',
  async (cartId: number, { rejectWithValue }) => {
    try {
      const result = await cartApiService.getStoreDetailsById(cartId);
      return result.data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const updateLegacyCart = createAsyncThunk(
  'cart/updatePickupTime',
  async (legacyCartData: UpdatePickUpTimeParams, { rejectWithValue }) => {
    const { data } = await fulfillmentApiService.legacyUpdatePickUpTimeToCart(
      JSON.stringify(fillLegacyUpdateCartDefaultParams(legacyCartData)),
    );
    try {
      return parseUpdateCardResponse(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
