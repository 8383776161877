import { useLocation, useNavigate } from 'react-router-dom';

type Setter = (
  parameter1: string,
  value1: string | string[],
  parameter2: string,
  value2: string | string[],
) => void;
export const useQueryParametersSetter = (): Setter => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    param1: string,
    value1: string | string[],
    param2: string,
    value2: string | string[],
  ): void => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete(param1);
    urlSearchParams.delete(param2);
    if (typeof value1 === 'string') {
      urlSearchParams.set(param1, value1);
    } else if (Array.isArray(value1)) {
      value1.forEach((v) => {
        urlSearchParams.append(param1, v);
      });
    }
    if (typeof value2 === 'string') {
      urlSearchParams.set(param2, value2);
    } else if (Array.isArray(value2)) {
      value2.forEach((v) => {
        urlSearchParams.append(param2, v);
      });
    }
    navigate({ search: urlSearchParams.toString() });
  };
};
