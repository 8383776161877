import {
  FullUpdatePickUpTimeParams,
  UpdatePickUpTimeParams,
} from 'types/cart.types';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';

export function fillLegacyUpdateCartDefaultParams(
  cart: UpdatePickUpTimeParams,
): FullUpdatePickUpTimeParams {
  return {
    ...(pickBy(
      {
        FulfillmentTimeFrom: '1/1/0001 12:00:00 AM',
        FulfillmentTimeTo: '1/1/0001 12:00:00 AM',
        OccasionRec: false,
        OccasionRecDate: '1/1/0001 12:00:00 AM',
        Pickup7Minute: false,
        SkipOnDelivery: 0,
        CallRecipient: null,
        ToValidateAddress: true,
      },
      isNotUndefined,
    ) as Required<FullUpdatePickUpTimeParams>),
    ...cart,
  };
}
