import { SxProps, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';

export const wrapper: SxProps<Theme> = (theme) => ({
  background: '#FCE4EC',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 20px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: '160px',
    justifyContent: 'space-around',
  },
});

export const textWrapper: SxProps<Theme> = (theme) => ({
  padding: '10px 0',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
});

export const btn: SxProps<Theme> = (theme) => ({
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
});

export const boxStyle: SxProps<Theme> = (theme) => ({
  marginTop: ['16px', '24px', '24px'],
  justifyContent: 'space-between',
  flexDirection: ['column', 'row', 'row'],
  '& div': {
    width: ['95%', '286px', '286px'],
    height: '64px',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    justifyContent: 'center',
    '& div': {
      margin: '0 auto',
    },
  },
});

export const subTitle: SxProps = {
  fontWeight: 500,
  fontSize: '14px',
};

export const bodyText: SxProps = {
  fontWeight: 400,
  fontSize: '12px',
};

export const shopButton: SxProps = {
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '0.46px',
  width: ['100%', '260px', '260px'],
  height: '56px',
  mt: ['16px', 0, 0],
};

export const CarouselContainer = styled('div')({
  '& .slick-slider': {
    '& button.slick-arrow': {
      display: 'none !important',
    },
    '& .slick-list': {
      '& .slick-track': {
        display: 'flex',
      },
    },
  },
});

export const carouselItem: SxProps = {
  maxWidth: '120px',
};

export const sliderContainer: SxProps = {
  maxWidth: ['100%', '100%', '615px'],
  background: '#FFF',
  mt: ['16px', '24px', '24px'],
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 0',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 14px 0px 0px',
          width: '100%',
          '& img': {
            border: `1px solid ${colors.dividerBlack}`,
            borderRadius: '0px',
          },
        },
      },
    },
    '& .slick-arrow': {
      border: `1px solid ${colors.dividerBlack}`,
      background: colors.white,
      color: colors.activeBlack,
      top: '90px',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
    },
    '& .slick-next': {
      right: '-25px',
    },
    '& .slick-prev': {
      left: '-25px',
    },
  },
};
