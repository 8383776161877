import { ArrangementGroupOccasion } from 'api/arrangement-group.api.service';

export const getOccasion = (
  occasions: ArrangementGroupOccasion[],
  occasionId: number | undefined,
): string => {
  let occassionName = '';
  if (occasionId) {
    // eslint-disable-next-line array-callback-return
    occasions.map((occasion) => {
      if (occasion.id === occasionId) {
        occassionName = occasion.name;
      }
    });
  }
  return occassionName;
};
