import { Status } from 'enums/status';
import { createSlice } from '@reduxjs/toolkit';
import { logOut } from 'redux/auth/log-out.action';

export type AuthState = {
  logOutStatus: Status;
};

const initialState: AuthState = {
  logOutStatus: Status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetLogOutStatus: (state) => {
      state.logOutStatus = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOut.fulfilled, (state) => {
        state.logOutStatus = Status.SUCCESS;
      })
      .addCase(logOut.rejected, (state) => {
        state.logOutStatus = Status.ERROR;
      })
      .addCase(logOut.pending, (state) => {
        state.logOutStatus = Status.IN_PROGRESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const authReducer = slice.reducer;
export const { resetLogOutStatus } = slice.actions;
