import {
  ABOUT_STORE_FOUR,
  ABOUT_STORE_ONE,
  ABOUT_STORE_THREE,
  ABOUT_STORE_TITLE,
  ABOUT_STORE_TWO,
  CATEGORIES_TITLE,
  COMING_SOON_TEXT,
  DISTANCE_AWAY,
  FIND_STORE_BUTTON,
  FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT,
  FRANCHISE_BANNER_STORE_COMING_SOON_BODY,
  FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON,
  FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE,
  FRANCHISE_BANNER_STORE_COMING_SOON_TITLE,
  REDIRECT_SECTION_SUBTITLE,
  REDIRECT_SECTION_TITLE,
  SERVICED_AREAS_TITLE,
  SOCIAL_FACEBOOK_ARIA_LABEL,
  SOCIAL_INSTAGRAM_ARIA_LABEL,
  SOCIAL_MEDIA_BODY,
  SOCIAL_MEDIA_TITLE,
  SOCIAL_PINTEREST_ARIA_LABEL,
  SOCIAL_TWITTER_ARIA_LABEL,
  SOCIAL_YOUTUBE_ARIA_LABEL,
  STORE_BANNER_COMING_SOON_ALT_TEXT,
  STORE_BANNER_COMING_SOON_BODY,
  STORE_BANNER_COMING_SOON_BUTTON,
  STORE_BANNER_COMING_SOON_TITLE,
  STORE_COMING_SOON_ADDRESS,
  STORE_COMING_SOON_DETAILS_ADDRESS1,
  STORE_COMING_SOON_DETAILS_ADDRESS2,
  STORE_COMING_SOON_DETAILS_META_DESCRIPTION,
  STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION,
  STORE_COMING_SOON_DETAILS_META_TITLE,
  STORE_COMING_SOON_DETAILS_NO_ADDRESS,
  SUBSCRIPTION_SUBTITLE,
  SUBSCRIPTION_TITLE,
} from 'components/StoreComingSoon/translations/locale.constants';

export const storeComingSoonDetailsCommonLocale = {
  [STORE_COMING_SOON_ADDRESS]: 'Edible® {{storeNumber}}, {{address1}}',
  [STORE_BANNER_COMING_SOON_BODY]:
    'Follow Edible Arrangements {{city}}, {{state}} - {{address1}} for early access to exciting offers and exclusive treats.',
  [STORE_BANNER_COMING_SOON_BUTTON]: 'Shop Now',
  [STORE_BANNER_COMING_SOON_TITLE]: 'Opening Soon!',
  [STORE_BANNER_COMING_SOON_ALT_TEXT]: 'Opening Soon Store Banner.',
  [FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT]:
    'Learn more about Owning an Edible Franchise',
  [FRANCHISE_BANNER_STORE_COMING_SOON_TITLE]: 'Live the Sweet Life!',
  [FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE]: 'Own a Franchise',
  [FRANCHISE_BANNER_STORE_COMING_SOON_BODY]:
    'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
  [FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON]: 'Learn More',
  [SERVICED_AREAS_TITLE]: 'Service Areas',
  [COMING_SOON_TEXT]: 'This Store is Opening Soon!',
  [ABOUT_STORE_TITLE]: 'About Our Store',
  [ABOUT_STORE_ONE]: `Edible Arrangements® {{storeNum}} in {{city}}, {{state}} will be opening soon to Make Life a Little Sweeter® for our local community!`,
  [ABOUT_STORE_TWO]: `Our fruit arrangements and gifts are always made fresh daily, using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate dipped fruit, too! Try our legendary chocolate dipped strawberries, apples, pineapple, bananas, oranges and pears`,
  [ABOUT_STORE_THREE]: `Here, you'll truly find oly the freshest fruit gifts. When we open, stop by and see why ours is considered The Best Dipped Fruit, Period®.`,
  [ABOUT_STORE_FOUR]: `We'll be opening our doors to the public, soon. Once we do, we can't wait to help you choose the perfect fruit bouquet or dipped fruit box for your special occasion! So stop by soon if you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because.`,
  [SOCIAL_MEDIA_TITLE]: "Let's Connect!",
  [SOCIAL_MEDIA_BODY]:
    'Follow this store on social media for exclusive promotions!',
  [SOCIAL_FACEBOOK_ARIA_LABEL]: 'Follow Edible Store on Facebook',
  [SOCIAL_INSTAGRAM_ARIA_LABEL]: 'Follow Edible Store on Facebook',
  [SOCIAL_PINTEREST_ARIA_LABEL]: 'Follow Edible Store on Pinterest',
  [SOCIAL_TWITTER_ARIA_LABEL]: 'Follow Edible Store on Twitter',
  [SOCIAL_YOUTUBE_ARIA_LABEL]: 'Follow Edible Store on YouTube',
  [CATEGORIES_TITLE]: "We Think You'll Like These Popular Gifts",
  [DISTANCE_AWAY]: '~{{distance}} {{distanceMetric}} Away',
  [STORE_COMING_SOON_DETAILS_NO_ADDRESS]:
    'Edible Arrangements {{city}}, {{state}}',
  [STORE_COMING_SOON_DETAILS_ADDRESS1]:
    'Edible Arrangements {{city}}, {{state}} - {{address1}}',
  [STORE_COMING_SOON_DETAILS_ADDRESS2]:
    'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
  [STORE_COMING_SOON_DETAILS_META_TITLE]:
    'Edible Arrangements {{city}}, {{state}}: {{address1}} {{address2}}',
  [STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION]:
    "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}}  first opened in  {{openingDate}}. Ever since, we've been helping people in our local community celebrate all kinds of occasions - big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! (Visit us any time for a FREE sample!)  Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit smoothie, Froyo Fruit Shake™, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They're perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at {{storePhoneNumber}}. We can't wait to meet you!",
  [STORE_COMING_SOON_DETAILS_META_DESCRIPTION]:
    'Grab a healthy delicious fruit smoothie, chocolate dipped fruit treat, donut or even fresh fruit salad at the Edible Arrangements store in {{city}} today!',
  [SUBSCRIPTION_TITLE]: 'Be Notified When This Store Opens!',
  [SUBSCRIPTION_SUBTITLE]:
    'Provide your email address for store updates and special promotions.',
  [REDIRECT_SECTION_TITLE]: 'Need to Order Your Treats Today?',
  [REDIRECT_SECTION_SUBTITLE]:
    'Search for an available store in your area and order online for pickup or delivery.',
  [FIND_STORE_BUTTON]: 'Find a Store',
};
