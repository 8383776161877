import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { SerializablePas } from 'containers/PDPContainer/feature/pas/types/serializable-pas.interface';
import { isUndefined } from 'utils/is-undefined';
import { removeUndefinedProperties } from 'utils/remove-undefined-properties';

export function serialize(pas: Partial<Pas>): Partial<SerializablePas> {
  const cleanPas = removeUndefinedProperties(pas);

  const { date, ...rest } = cleanPas;

  if (isUndefined(date)) {
    return rest;
  }

  return {
    ...rest,
    date: date?.toJSON() ?? null,
  };
}
