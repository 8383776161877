/**
 * @docs https://developers.google.com/analytics/devguides/collection/gtagjs
 * @param {string} measurementId 'G-XXXXXXXXXX'
 */
export const initialize = (measurementId: string): void => {
  /* eslint-disable */
  window.dataLayer = window.dataLayer || [];
  const gtag: Gtag.Gtag = function () {
    window.dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', measurementId);
  /* eslint-enable */
};

export const getScript = (measurementId: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'google-analytics-script-v4';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

  return script;
};
