import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchPreviousGroupName } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/feature/actions';

interface State {
  status: Status;
  name: string | null;
}

const initialState: State = {
  status: Status.INIT,
  name: null,
};

const slice = createSlice({
  name: 'pdp/prev-group-name',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreviousGroupName.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchPreviousGroupName.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.name = payload;
      })
      .addCase(fetchPreviousGroupName.rejected, (state) => {
        state.status = Status.ERROR;
        state.name = '';
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    setNoName: () => ({ status: Status.FULFILLED, name: '' }),
  },
});
export const pdpRelatedCategoryNameReducer = slice.reducer;
export const { setNoName } = slice.actions;
