import { Fallback } from 'components/PDP/Order/LoadingFallback';
import { Props } from 'components/PDP/Order/props';
import { trackComponent } from 'utils/telemetry/app-insights';
import React, { Suspense, lazy } from 'react';

const Order = lazy(() =>
  import('./Order').then((module) => ({ default: module.Order })),
);

export const LazyOrder = trackComponent(
  (props: Props) => (
    <Suspense fallback={<Fallback />}>
      <Order {...props} />
    </Suspense>
  ),
  'PDP recipient form',
);
