import * as styles from 'components/Header/HeaderNavigation/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Category } from 'redux/header/header.types';
import { MenuItemCategory } from 'components/Header/HeaderNavigation/MenuItemCategory';
import React, { FC, useMemo } from 'react';

interface Props {
  categories: Category[];
  menuId: number;
  initClose: () => void;
}

export const MenuCategories: FC<Props> = ({
  categories,
  menuId,
  initClose,
}) => {
  const menuRows = useMemo(
    () =>
      Array.from({ length: Math.ceil(categories.length / 2) }, (v, i) =>
        categories.slice(i * 2, i * 2 + 2),
      ),
    [categories],
  );

  return (
    <Box sx={styles.categories}>
      <TableContainer>
        <Table sx={styles.categoriesTable}>
          <TableBody>
            {menuRows.map((rowCats, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${menuId}${index}`}>
                {rowCats.map((category) => (
                  <TableCell key={category.id} sx={styles.categoryWrapper}>
                    <MenuItemCategory
                      category={category}
                      initClose={initClose}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
