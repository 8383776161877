import * as TK from 'components/Session/Alert/RecipientLockAlert/translations/constants';
import { DAY_OF_WEEK_MON_DATE_YEAR } from 'components/Session/date-format.constant';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { SHORT_DATE_FORMAT } from 'components/Session/Alert/constants';
import { format } from 'date-fns';
import { getLocalizedTime } from 'utils/date';
import {
  getLockedOrderData,
  selectIsNewRecipientSelected,
} from 'components/Session/feature/selectors';
import { isFulfillType, isPickup } from 'utils/is-order-type';
import {
  selectRecipientName,
  selectSessionOrderType,
} from 'redux/session/availability/availability.selectors';
import { useSelector } from 'react-redux';
import i18next, { TFunctionKeys } from 'i18next';

function getKeyByOrderType(
  orderType: FulfillmentOptionType,
  { displayRecipientName }: { displayRecipientName?: boolean },
): TFunctionKeys {
  if (displayRecipientName) {
    return isPickup(orderType) ? TK.PICKUP_FOR : TK.DELIVERY_FOR;
  }
  return {
    [OrderType.Delivery]: TK.DELIVERY_FROM,
    [OrderType.Pickup]: TK.PICKUP_FROM,
    [OrderType.Shipment]: TK.SHIPMENT_SERVICED_ON,
  }[orderType];
}

export const useTitle = (options?: {
  displayRecipientName?: boolean;
  shortDate?: boolean;
}): string => {
  const orderType = useSelector(selectSessionOrderType);
  const recipientName = useSelector(selectRecipientName);
  const isNewRecipientSelected = useSelector(selectIsNewRecipientSelected);
  const { date, store } = useSelector(getLockedOrderData);

  if (isNewRecipientSelected) {
    return i18next.t(TK.NEW_RECIPIENT);
  }

  if (!isFulfillType(orderType) || !date) {
    return '';
  }

  return i18next.t(
    getKeyByOrderType(orderType, {
      displayRecipientName: options?.displayRecipientName,
    }),
    {
      store,
      date: format(
        new Date(date),
        options?.shortDate ? SHORT_DATE_FORMAT : DAY_OF_WEEK_MON_DATE_YEAR,
      ),
      time: getLocalizedTime(new Date(date)),
      name: recipientName,
    },
  );
};
