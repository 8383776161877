import { CaseCategoryState } from 'redux/case/caseCategory/case.types';
import { createSlice } from '@reduxjs/toolkit';
import {
  getCaseCategory,
  getCaseCategoryType,
} from 'redux/case/caseCategory/case.action';

const initialState: CaseCategoryState = {
  category: [],
  categoryType: [],
  loading: false,
};

const caseCategorySlice = createSlice({
  name: 'case',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCaseCategory.pending, () => ({
        ...initialState,
        loading: true,
      }))
      .addCase(getCaseCategory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCaseCategory.fulfilled, (state, { payload }) => {
        state.category = payload;
        state.loading = false;
      })
      .addCase(getCaseCategoryType.pending, () => ({
        ...initialState,
        loading: true,
      }))
      .addCase(getCaseCategoryType.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCaseCategoryType.fulfilled, (state, { payload }) => {
        state.categoryType = payload;
        state.loading = false;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: caseCategoryReducer } = caseCategorySlice;
