import { OptionsAvailability } from 'components/PDP/Details/components/ProductOptions/hooks/use-product-options-availability';
import { selectIsLoading } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useIsAnyOptionAvailable(
  availability: OptionsAvailability,
): boolean {
  const isAvailabilityLoading = useSelector(selectIsLoading);
  const [isAnyOptionAvailable, setAnyOptionAvailable] = useState(true);

  useEffect(() => {
    if (isAvailabilityLoading) {
      return;
    }

    setAnyOptionAvailable(
      Object.values(availability).some((isAvailable) => isAvailable),
    );
  }, [availability, isAvailabilityLoading, setAnyOptionAvailable]);

  return isAnyOptionAvailable;
}
