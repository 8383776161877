import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/system';

export const sameDayBannerStyles: ThemedSxProps = (theme) => ({
  zIndex: 2,
  maxWidth: '432px',
  borderRadius: 3,
  position: 'fixed',
  bottom: 0,
  textAlign: 'center',
  '& .slick-dots': {
    top: '-25px',
    height: '20px',
    textAlign: 'left',
  },
  '& .slick-dots li': {
    borderRadius: '0 !important',
  },
  '& .slick-slider': {
    display: 'flex !important',
  },
  '& .slick-list': {
    width: '100%',
  },
  '& .slick-slide': {
    height: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    maxWidth: '276px',
    bottom: 0,
    mb: 0,
  },
});

export const NotificationBannerItemContainer = styled('div')<{
  bgColor: string;
  isButtonText: boolean;
}>(({ theme, isButtonText, bgColor }) => ({
  backgroundColor: bgColor,
  display: 'flex !important',
  width: 'fit-content !important',
  padding: isButtonText ? '16px 16px 16px 8px' : '0px 16px 0px 8px',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    gap: isButtonText ? 0 : '4px !important',
    padding: isButtonText ? 0 : '0px 8px 0px 8px !important',
  },
}));

export const CloseIconWrapper = styled('div')<{ isButtonText: boolean }>(
  ({ theme, isButtonText }) => ({
    float: 'left',
    display: 'flex',
    height: 'inherit',
    minHeight: 'fit-content',
    alignItems: 'center',
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      alignItems: isButtonText ? 'start' : 'center',
      marginTop: isButtonText ? '8px' : '0px',
      marginLeft: isButtonText ? '8px' : 0,
      marginRight: '0px',
      position: isButtonText ? 'absolute' : 'static',
    },
  }),
);

export const CloseIconContainer = styled('div')<{ isButtonText: boolean }>(
  ({ theme }) => ({
    width: '20px',
    height: '20px',
    borderRadius: '0px',
    fontWeight: '600',
    padding: '2px',
    float: 'left',
    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      m: 0,
    },
  }),
);

export const BoxContainer = styled('div')<{
  bannerbgcolor: string;
  isButtonText: boolean;
}>(({ theme, bannerbgcolor, isButtonText }) => ({
  backgroundColor: bannerbgcolor,
  minHeight: 'fit-content',
  display: 'flex !important',
  flexDirection: isButtonText ? 'row' : 'column',
  alignItems: 'center',
  gap: isButtonText ? '8px' : '16px',
  padding: isButtonText ? 0 : '16px 0px',
  justifyContent: 'center',
  cursor: 'pointer',
  width: 'fit-content',
  minWidth: 'fit-content',
  height: 'inherit',
  [theme.breakpoints.down('sm')]: {
    padding: isButtonText ? '8px 14px 12px 12px' : '8px 0px',
    flexDirection: isButtonText ? 'column' : 'row',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: isButtonText ? '8px' : '16px',
    flex: '1 0 0',
  },
}));

export const buttonWrapper: ThemedSxProps = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});
