import { FulfillmentOptionType } from 'types/cart.types';
import { dataTestOrderTypeName } from 'components/PDP/Details/components/PasControl/contants/data-test-order-type-name';

export const getOrderTypeDataTestValue = (
  orderType: FulfillmentOptionType,
  ending: string,
): string => {
  const orderTypeName = dataTestOrderTypeName[orderType];
  return `pdp-pas-${orderTypeName}-${ending}`;
};
