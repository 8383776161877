import { SxProps, SystemCssProperties } from '@mui/system';
import { Theme } from '@mui/material';

export const distance: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 64,
  maxHeight: 64,
  p: ({ spacing }) => spacing(2),
  background: ({ palette }) => palette.custom.bodyBg,
  mr: 1.5,
  [theme.breakpoints.up('sm')]: {
    maxHeight: 1,
  },
});

export const schedule: SxProps = {
  display: 'flex',
  justifyContent: ['space-between', 'flex-start'],
  alignItems: 'center',
  width: 1,
  mb: 0.5,
};

export const tag: SystemCssProperties<Theme> = {
  p: ({ spacing }) => spacing(0.66, 1),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: ({ palette }) => palette.other.outlineBorder,
};

export const phone: SxProps = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  width: '160px',
};
