import { getId, getNoScript, getScript } from 'hooks/google/tag-manager.utils';
import { useEffect } from 'react';

export const useTagManager = (): void => {
  useEffect(() => {
    const id = getId();
    if (!id) {
      return;
    }
    const script = getScript(id);
    const noScript = getNoScript(id);

    document.head.insertBefore(script, document.head.childNodes[1]);
    document.body.insertBefore(noScript, document.body.childNodes[0]);
  });
};
