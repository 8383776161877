import { BaseExpandedSelector } from 'components/GiftFinder/Form/base-expanded-selector';
import { Filter, FilterCategory } from 'api/filter-categories/types';
import { SpeedChip } from 'components/GiftFinder/chips/speed-chip';
import React, { FC } from 'react';

interface ExpandedSpeedSelectorProps {
  setFilterData: (filterData: Filter[]) => void;
  selectedFilterData: Filter[];
  category: FilterCategory[];
}

export const ExpandedSpeedSelector: FC<ExpandedSpeedSelectorProps> = (
  props,
) => {
  const speedList = props?.category[0]?.Items ?? [];
  const onSelectSpeed = (occasion: Filter) => {
    const newArray = props.selectedFilterData.includes(occasion)
      ? props.selectedFilterData.filter((item) => item.ID !== occasion.ID)
      : [occasion];
    props.setFilterData(newArray);
  };

  return (
    <BaseExpandedSelector
      label="Speed"
      subHeading="I need this fast!"
      imageSrc={props?.category[0]?.FilterCategoryImageURL}
      isSelected={props.selectedFilterData.length > 0}
    >
      {speedList.map((speed) => (
        <SpeedChip
          key={speed.ID}
          label={speed.Name}
          isSelected={props.selectedFilterData.some(
            (item) => item.ID === speed.ID,
          )}
          onClick={() => onSelectSpeed(speed)}
        />
      ))}
    </BaseExpandedSelector>
  );
};
