import { getScript, initialize } from 'hooks/google/analytics-v4.utils';
import { useEffect } from 'react';

export const useAnalyticsV4 = (): void => {
  useEffect(() => {
    const id = window.GOOGLE_ANALYTICS_ID_V4;

    if (!id) {
      return;
    }

    document.head.insertBefore(getScript(id), document.head.childNodes[1]);
    initialize(id);
  }, []);
};
