import { InstructionsErrorType } from 'components/PDP/Order/RecipientForm/components/DeliveryPickupInstructions/enums/instructions-error-type.enum';
import { InstructionsState } from 'components/PDP/Order/RecipientForm/components/DeliveryPickupInstructions/feature/delivery-pickup-instructions.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: InstructionsState = {
  errors: [],
};

const slice = createSlice({
  name: 'recipient-info/greetings-cards',
  initialState,
  reducers: {
    addInstructionsError: (
      state,
      action: PayloadAction<InstructionsErrorType>,
    ) => {
      if (state.errors.includes(action.payload)) {
        return;
      }
      const errors: InstructionsErrorType[] = [...state.errors];
      errors.push(action.payload);
      state.errors = [...errors];
    },
    removeInstructionsError: (
      state,
      action: PayloadAction<InstructionsErrorType>,
    ) => {
      if (state.errors.includes(action.payload)) {
        state.errors = state.errors.filter((error) => error !== action.payload);
      }
    },
  },
});

export const { reducer: deliveryPickupInstructionsReducer } = slice;

export const { addInstructionsError, removeInstructionsError } = slice.actions;
