import * as styles from 'components/DynamicPages/styles';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DynamicPageByURL } from 'api/dynamic/dynamic-page.type';
import { ROOT_PATH_LIST } from 'containers/MainContainer/constants/path';
import { dynamicApiService } from 'api/dynamic/dynamic.api.service';
import InnerHTML from 'dangerously-set-html-content';
import React, { useEffect, useState } from 'react';

export const DynamicPage = (): JSX.Element => {
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down('sm'));
  const [currentDetails, setCurrentDetails] = useState<DynamicPageByURL[]>();
  const url = window.location.href;

  const matchingPath = ROOT_PATH_LIST.find((path) => url.includes(`/${path}/`));
  const rootPath = ROOT_PATH_LIST.find((path) => url.includes(path));
  let pageEndpoint = '';

  useEffect(() => {
    const loadDynamicPageContent = async () => {
      if (matchingPath && rootPath) {
        pageEndpoint = url.substring(
          url.lastIndexOf(`/${matchingPath}/`) + `/${matchingPath}/`.length,
        );
        const response = await dynamicApiService.findByDynamicPageUrlByRootPath(
          pageEndpoint,
          rootPath,
        );
        setCurrentDetails(response);
      }
    };

    loadDynamicPageContent();
  }, []);
  return (
    <Box sx={styles.wrapper}>
      {currentDetails && currentDetails.length > 0 && (
        <InnerHTML
          className="dynamicContent"
          html={
            isMobile
              ? currentDetails[0]?.mobileContent
              : currentDetails[0]?.desktopContent
          }
        />
      )}
    </Box>
  );
};
