/* eslint-disable no-param-reassign */
import {
  AbortReason,
  fetchArrangements,
} from 'components/PLP/ArrangementsList/feature/actions';
import { ArrangementPreview } from 'api/arrangements/types';
import { ResponseError } from 'types/error.types';
import { SortParam, SortParams } from 'types/arrangement-service.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
  arrangements: ArrangementPreview[];
  status: Status;
  error: ResponseError | null;
  sort: SortParams;
};
const initialState: State = {
  arrangements: [],
  status: Status.INIT,
  error: null,
  sort: SortParam.Relevance,
};
const slice = createSlice({
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchArrangements.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchArrangements.fulfilled, (state, action) => {
        state.arrangements = action.payload;
        state.status = Status.FULFILLED;
        state.sort = action.meta.arg?.sort ?? SortParam.Relevance;
      })
      .addCase(fetchArrangements.rejected, (state, { error, payload }) => {
        if (
          error.name === 'AbortError' &&
          error.message === AbortReason.NewSearch
        ) {
          return;
        }
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
  name: 'plp/arrangements',
  reducers: {},
});

export const plpArrangementsReducer = slice.reducer;
