import { Button } from '@mui/material';
import { Item } from 'redux/header/header.types';
import { analytics } from 'service/adobe-analytics/analytics';
import { isNotEmpty } from 'utils/is-not-empty';
import { isUrlToAnotherResource } from 'utils/router';
import { navButton } from 'components/Header/HeaderNavigation/styles';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  item: Item;
  index: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  activeMenuId: number | null;
}

export const MenuButton: FC<Props> = ({
  item,
  index,
  onMouseEnter,
  activeMenuId,
  onMouseLeave,
}) => {
  const { name, url, color } = item;
  const theme = useTheme();
  const [isButtonActive, setIsButtonActive] = useState(false);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    setIsButtonActive(
      !!(
        activeMenuId &&
        isNotEmpty(item.categories) &&
        activeMenuId === item.id
      ),
    );
  }, [activeMenuId, item]);

  const navigationHandler = (urlToNavigate: string, textName: string) => {
    if (isUrlToAnotherResource(urlToNavigate)) {
      window.open(urlToNavigate, '_blank');
      analytics.headerMenuItemClicked(textName);
      segment.menuItemClicked(
        textName,
        urlToNavigate,
        userSession,
        userAccountInformation,
      );
    } else {
      analytics.headerMenuItemClicked(textName);
      segment.menuItemClicked(
        textName,
        urlToNavigate,
        userSession,
        userAccountInformation,
      );
    }
  };

  return (
    <>
      <Button
        color="secondary"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        href={isUrlToAnotherResource(url) ? undefined : `/${url}`}
        onClick={() => navigationHandler(url, name)}
        data-test={`navigation-btn-${index + 1}`}
        sx={
          color
            ? navButton(color, isButtonActive)
            : navButton(theme.palette.secondary.main, isButtonActive)
        }
      >
        {name}
      </Button>
    </>
  );
};
