import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const CarouselPropertiesWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    marginTop: '5px',
    width: '80%',
    margin: '0 auto',
    [breakpoints.up('xs')]: {
      padding: '0px',
      maxWidth: '100%',
      marginTop: '5px',
      width: '90%',
    },
    [breakpoints.up('sm')]: {
      padding: '0px',
      maxWidth: '100%',
      marginTop: '5px',
      width: '100%',
    },
    [breakpoints.up('md')]: {
      padding: '0px',
      maxWidth: '100%',
      marginTop: '5px',
    },
    '& .lineStyle': {
      width: '130%',
      margin: [0, 0, '20px 0 50px -15%'],
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  }),
);

export const CarouselWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  boxSizing: 'content-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  marginTop: '20px',
  width: '80%',
  margin: '0 auto',
  [breakpoints.up('xs')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '20px',
    width: '90%',
  },
  [breakpoints.up('sm')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '30px',
    width: '100%',
  },
  [breakpoints.up('md')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '30px',
  },
  '& .lineStyle': {
    width: '130%',
    margin: '20px 0 50px -15%',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));

export const CarouselContainer = styled('div')(
  ({ theme: { breakpoints } }) => ({
    '& .slick-slider': {
      '& .slick-list': {
        margin: '2px -10px',
        '& .slick-track': {
          display: 'flex',
          '& .slick-slide': {
            width: '80%',
            margin: [0, 0, '0px 10px'],
          },
        },
      },
      '& .slick-arrow': {
        display: 'none !important',
        '& svg': {
          display: 'none',
        },
      },
      '& .slick-arrow: before': {
        fontSize: '50px',
        content: 'none',
      },
      '&:hover': {
        '& .slick-arrow': {
          display: 'block !important',
        },
      },
      '& .slick-prev': {
        left: '80px',
        borderBottom: '2px solid',
        borderLeft: '2px solid',
        transform: 'rotate(45deg)',
        color: '#fff',
        width: '22px',
        height: '22px',
      },
      '& .slick-next': {
        right: '90px',
        borderBottom: '2px solid',
        borderLeft: '2px solid',
        transform: 'rotate(-135deg)',
        color: '#fff',
        width: '22px',
        height: '22px',
      },
    },
    [breakpoints.up('xs')]: {
      '& .slick-slider': {
        '& .slick-arrow': {
          top: '15%',
          display: 'block !important',
        },
        '&:hover': {
          '& .slick-arrow': {
            display: 'block !important',
          },
        },
        '& .slick-prev': {
          left: '24%',
        },
        '& .slick-next': {
          right: '30%',
        },
      },
    },
    [breakpoints.up('sm')]: {
      '& .slick-slider': {
        '& .slick-arrow': {
          top: '14%',
          display: 'block !important',
        },
        '&:hover': {
          '& .slick-arrow': {
            display: 'block !important',
          },
        },
        '& .slick-prev': {
          left: '26%',
        },
        '& .slick-next': {
          right: '31%',
        },
      },
    },
    [breakpoints.up('md')]: {
      '& .slick-slider': {
        '& .slick-arrow': {
          top: '50%',
          display: 'none !important',
        },
        '&:hover': {
          '& .slick-arrow': {
            display: 'block !important',
          },
        },
        '& .slick-prev': {
          left: '80px',
        },
        '& .slick-next': {
          right: '90px',
        },
      },
    },
  }),
);

export const arrowControls: ThemedSxProps = (theme) => ({
  display: 'flex',
  position: 'relative',
  '& button:first-of-type': {
    mr: 1.2,
    [theme.breakpoints.up('sm')]: {
      ml: 2.5,
      mr: 2.5,
    },
  },
});
