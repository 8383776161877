import * as TK from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductShipping/translations/marketplace-product-shipping.locale.constants';
import i18next from 'i18next';

export const formatRestrictedStates = (
  restrictedStates: string | undefined,
): string => {
  if (!restrictedStates) {
    return '';
  }

  const statesArray = restrictedStates.split(',').map((state) => state.trim());
  if (statesArray.length === 1) {
    const [state] = statesArray;
    return i18next.t(TK.MARKETPLACE_RESTRICTED_STATE, { state });
  }

  const allButLastStates = statesArray.slice(0, -1).join(', ');
  const lastState = statesArray[statesArray.length - 1];
  return i18next.t(TK.MARKETPLACE_MULTIPLE_RESTRICTED_STATES, {
    allButLastStates,
    lastState,
  });
};
