import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isError, isFulfilled } from 'utils/status.comparer';

export const selectClerkName = (state: RootState): string => {
  return state.callCenterMode.data.name;
};

export const selectIsModalOpen = (state: RootState): boolean => {
  return state.callCenterMode.isModalOpen;
};

const selectStatus = (state: RootState): Status => {
  return state.callCenterMode.status;
};

export const selectIsFulfilled = createSelector(selectStatus, isFulfilled);

export const selectIsError = createSelector(selectStatus, isError);

export const selectIsCallCenterModeDisabled = (state: RootState): boolean =>
  !state.callCenterMode.isEnabled;
