import { Box, Skeleton, Typography } from '@mui/material';
import { menuItem } from 'components/Header/Menus/LanguageMenu/styles';
import { useLocale } from 'hooks/use-locale';
import React, { FC } from 'react';

type Props = {
  value: string;
  icon?: string;
};

export const ButtonContent: FC<Props> = ({ icon, value }) => {
  const { isLocaleInit } = useLocale();
  return (
    <Box component="span" sx={menuItem}>
      {isLocaleInit ? (
        <>
          {icon && <img src={icon} alt={value} />}
          <Typography variant="subtitle2">{value}</Typography>
        </>
      ) : (
        <Skeleton width={55} height={20} />
      )}
    </Box>
  );
};
