import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Settings } from 'react-slick';
import React from 'react';

export const settings: Settings = {
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  initialSlide: 0,
  infinite: true,
  arrows: true,
  swipe: true,
  adaptiveHeight: true,
  nextArrow: (
    <Box>
      <ArrowRight fontSize="small" />
    </Box>
  ),
  prevArrow: (
    <Box>
      <ArrowLeft fontSize="small" />
    </Box>
  ),
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        speed: 500,
        autoplaySpeed: 8000,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: (
          <Box>
            <ArrowRight fontSize="small" />
          </Box>
        ),
        prevArrow: (
          <Box>
            <ArrowLeft fontSize="small" />
          </Box>
        ),
      },
    },
    {
      breakpoint: 550,
      settings: {
        speed: 500,
        autoplaySpeed: 8000,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: (
          <Box>
            <ArrowRight fontSize="small" />
          </Box>
        ),
        prevArrow: (
          <Box>
            <ArrowLeft fontSize="small" />
          </Box>
        ),
      },
    },
  ],
};
