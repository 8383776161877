import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { fonts } from 'utils/theme/fonts';

export const wrapper: ThemedSxProps = (theme) => ({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: fonts.poppins,
  width: '100%',
  maxWidth: '1060px',
  marginLeft: ['10px', '10px', '250px'],
  lineHeight: '143%',
  color: theme.palette.text.secondary,
  justifyContent: 'center',
});
