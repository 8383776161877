import { RequestOptions } from 'redux/types/request-options.interface';
import { availabilityStoreService } from 'api/availability-store.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const getAvailableStoresForDelivery = createAsyncThunk(
  'availability/for-delivery',
  (
    {
      date,
      arrangementId,
      productId,
      zip,
      options,
      test = false,
    }: {
      date: Date;
      arrangementId: number;
      productId: number;
      zip: string;
      options?: RequestOptions;
      test?: boolean;
    },
    { rejectWithValue },
  ) =>
    availabilityStoreService
      .getAvailableStoresForDelivery({
        areaName: zip,
        date,
        arrangementId,
        productId,
        test: test || false,
        options,
      })
      .catch((err) => rejectWithValue(parseAxiosError(err))),
);
