import { AxiosError } from 'axios';
import { CartState } from 'redux/cart/cart.types';
import { Status } from 'redux/types/state.interface';
import {
  addToLegacyCart,
  getStoreDetailsById,
  updateLegacyCart,
} from 'redux/cart/cart.actions';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CartState = {
  cartRecipientId: -1,
  addingStatus: Status.INIT,
  addingErrorMessage: null,
  count: 0,
  cartCountStatus: Status.INIT,
  cartUpdated: false,
  cartUpdatedStatus: Status.INIT,
  cartDetail: [],
  cartDetailStatus: Status.INIT,
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.addingErrorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToLegacyCart.fulfilled, (state, { payload }) => {
        state.cartRecipientId = payload;
        state.addingErrorMessage = null;
        state.addingStatus = Status.FULFILLED;
      })
      .addCase(addToLegacyCart.pending, (state) => {
        state.addingErrorMessage = null;
        state.addingStatus = Status.PENDING;
      })
      .addCase(addToLegacyCart.rejected, (state, { payload }) => {
        state.addingErrorMessage = payload as AxiosError;
        state.addingStatus = Status.ERROR;
      })
      .addCase(updateLegacyCart.fulfilled, (state, { payload }) => {
        state.cartUpdated = payload;
        state.cartUpdatedStatus = Status.FULFILLED;
      })
      .addCase(updateLegacyCart.pending, (state) => {
        state.cartUpdatedStatus = Status.PENDING;
      })
      .addCase(updateLegacyCart.rejected, (state) => {
        state.cartUpdatedStatus = Status.ERROR;
      })
      .addCase(getStoreDetailsById.fulfilled, (state, { payload }) => {
        state.cartDetail = payload;
        state.cartDetailStatus = Status.FULFILLED;
      })
      .addCase(getStoreDetailsById.pending, (state) => {
        state.cartDetailStatus = Status.PENDING;
      })
      .addCase(getStoreDetailsById.rejected, (state) => {
        state.cartDetailStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const cartReducer = slice.reducer;

export const { resetErrors } = slice.actions;
