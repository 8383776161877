import * as styles from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/SeeAvailableProducts/styles';
import { ArrangementPreview } from 'api/arrangements/types';
import { Box, Button, Typography } from '@mui/material';
import { Links } from 'enums/common-links';
import { SxProps } from '@mui/system';

import React, { FC } from 'react';

type Props = {
  title: string;
  description?: string;
  buttonText: string;
  onClick: (e: React.MouseEvent, arrangement?: ArrangementPreview) => void;
  sx?: SxProps;
  orderType: number;
};

export const SeeAvailableProducts: FC<Props> = ({
  title,
  description,
  buttonText,
  onClick,
  sx,
  orderType,
}) => (
  <Box sx={styles.wrapper(sx)}>
    <Typography sx={{ mb: 2 }} variant="subtitle1">
      {title}
    </Typography>
    {description && (
      <Typography sx={{ mb: 2 }} variant="body2">
        {description}
      </Typography>
    )}
    <Button
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => onClick(e)}
      href={orderType === 3 ? Links.ShippingProducts : Links.FruitArrangements}
      variant="contained"
      size="large"
      color="secondary"
      sx={{ width: 1 }}
      data-test="pdp-pas-see-available-products"
    >
      {buttonText}
    </Button>
  </Box>
);
