import * as styles from 'components/Home/GridWidget/components/GridItem/styles';
import { GridWidgetItem } from 'components/Home/GridWidget/types';
import { Image } from 'components/common/Image/Image';
import { ImageSkeleton } from 'components/Home/GridWidget/components/Skeleton/ImageSkeleton';
import {
  ImageWrapper,
  ListItem,
  ListItemLink,
} from 'components/Home/GridWidget/components/GridItem/styles';
import { Typography } from '@mui/material';
import { checkForFrench } from 'components/Home/utils';
import React, { FC } from 'react';
import parse from 'html-react-parser';

type Props = {
  item: GridWidgetItem;
};

export const GridItem: FC<Props> = ({ item }) => (
  <ListItemLink
    to={checkForFrench(item.url, item.frUrl)}
    data-test="grid-widget-item"
  >
    <ListItem>
      <ImageWrapper>
        <Image
          role="presentation"
          loading={<ImageSkeleton />}
          observerMargins="0px 0px 400px"
          src={checkForFrench(item.src, item.frSrc)}
          alt={checkForFrench(item.name, item.frName)}
          data-test="grid-widget-item-image"
        />
      </ImageWrapper>
      <Typography sx={styles.name} data-test="grid-widget-item-title">
        {parse(checkForFrench(item.name, item.frName))}
      </Typography>
    </ListItem>
  </ListItemLink>
);
