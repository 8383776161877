import * as TK from 'components/PDP/Order/common/components/LimitedTextField/translations/translation-keys.constants';

export const limitedTextFieldCommonLocale = {
  [TK.LIMITED_LINES_HELPER_TEXT]:
    'We have limited space for printing, so we have to limit the number of lines available as well. Lines here do not represent the real amount of lines, pay attention to the indicators.',
  [TK.FIELD_REQUIRED_HELPER_TEXT]: 'This is required field',
  [TK.LINES_LIMIT_EXCEEDED]: 'Line limit reached',
  [TK.TYPING_LAST_LINE]: 'You are in the last line',
  [TK.REACHING_LAST_LINE]: 'Reaching limit',
  [TK.SPL_CHARS_ERROR]: 'Characters (<>) are not allowed',
  [TK.EMOJIS_ENTERED]: 'Emojis are not allowed',
  [TK.REDUCE_MESSAGE_LENGTH_HELPER_TEXT]: 'Reduce the length of your message',
};
