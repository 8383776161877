import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { ParsedErrorResponse } from 'api/auth-legacy/log-in.misc';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { logIn } from 'components/PLP/CallCenter/components/Login/feature/actions';

const initialState = InitialStateFactory.create<
  { name: string },
  ParsedErrorResponse
>({
  name: '',
});

const slice = createSlice({
  initialState,
  name: 'callCenterLogin',
  reducers: {},
  extraReducers: createExtraReducers(initialState, logIn),
});

export const callCenterLoginReducer = slice.reducer;
