import {
  CartEvent,
  CartProductEventProps,
  DY,
  DynamicYieldEventName,
  DynamicYieldEventProperties,
} from 'service/dynamic-yield/dy-types';
import { Order, OrderItem, Sale } from 'redux/order-confirmation/types/types';
import { SortType } from 'components/PLP/FilterSortMenu/enums/sort-type.enum';
import {
  createProductFeedSku,
  getDynamicYieldSortData,
} from 'service/dynamic-yield/dy-utils';
import { sha256 } from 'crypto-hash';

export class DynamicEvents {
  private static instance: DynamicEvents;

  protected DY: DY;

  public static getInstance(): DynamicEvents {
    if (!DynamicEvents.instance) {
      DynamicEvents.instance = new DynamicEvents();
    }
    return DynamicEvents.instance;
  }

  constructor() {
    this.DY = window.DY;
  }

  private sendEvent(
    name: DynamicYieldEventName,
    properties: DynamicYieldEventProperties,
  ) {
    if (this.DY?.API)
      this.DY.API(CartEvent.EVENT, {
        name,
        properties,
      });
  }

  public async loginSuccess(email: string): Promise<void> {
    this.sendEvent('Login', {
      dyType: 'login-v1',
      hashedEmail: await sha256(email.toLowerCase()),
    });
  }

  public async signupSuccess(email: string): Promise<void> {
    this.sendEvent('Signup', {
      dyType: 'signup-v1',
      hashedEmail: await sha256(email.toLowerCase()),
    });
  }

  public async newsletterSubscription(email: string): Promise<void> {
    this.sendEvent('Newsletter Subscription', {
      dyType: 'newsletter-subscription-v1',
      hashedEmail: await sha256(email.toLowerCase()),
    });
  }

  public keywordSearch(keywords: string): void {
    this.sendEvent('Keyword Search', {
      dyType: 'keyword-search-v1',
      keywords,
    });
  }

  public promocodeEntered(code: string): void {
    this.sendEvent('Promo Code Entered', {
      dyType: 'enter-promo-code-v1',
      code,
    });
  }

  public addToCart(cartProperties: CartProductEventProps): void {
    const { currency, value, quantity, catalogNumber, sizeName, sku } =
      cartProperties;

    const productId = createProductFeedSku(
      catalogNumber !== undefined ? catalogNumber : '',
      sizeName !== undefined ? sizeName : '',
    );

    this.sendEvent('Add to cart', {
      dyType: 'add-to-cart-v1',
      value,
      currency,
      productId: sku ?? productId,
      quantity,
      cart: [
        {
          productId: sku ?? productId,
          quantity,
          itemPrice: value,
        },
      ],
    });
  }

  public async identifyUser(email: string): Promise<void> {
    this.sendEvent('Identify User', {
      dyType: 'identify-v1',
      hashedEmail: await sha256(email.toLowerCase()),
    });
  }

  public purchaseEvent(
    saleData: Sale,
    products: OrderItem[],
    orderData: Order[],
  ): void {
    for (let i = 0; i < orderData.length; i += 1) {
      this.sendEvent('Purchase', {
        dyType: 'purchase-v1',
        uniqueTransactionId: `${orderData[i].number}`,
        value: saleData.total,
        cart: products.map((product) => ({
          productId: createProductFeedSku(
            product.catalogNumber,
            product.sizeName,
          ),
          quantity: product.quantity,
          itemPrice: product.price,
        })),
      });
    }
  }

  public filterItems(
    filterType: string,
    filterStringValue: string,
    filterNumericValue?: number,
  ): void {
    this.sendEvent('Filter Items', {
      dyType: 'filter-items-v1',
      filterType,
      filterStringValue,
      filterNumericValue,
    });
  }

  public removeFromCart(productItem: CartProductEventProps): void {
    const { currency, sizeName, catalogNumber, quantity, value, sku } =
      productItem;

    const productId = createProductFeedSku(
      catalogNumber !== undefined ? catalogNumber : '',
      sizeName !== undefined ? sizeName : '',
    );

    this.sendEvent('Remove from cart', {
      dyType: 'remove-from-cart-v1',
      value,
      currency,
      productId: sku ?? productId,
      quantity,
      cart: [
        {
          productId: sku ?? productId,
          quantity,
          itemPrice: value,
        },
      ],
    });
  }

  public sortItemsEvent(selectedSortOption: SortType): void {
    const dynamicYieldSortData = getDynamicYieldSortData(selectedSortOption);
    this.sendEvent('Sort Items', {
      dyType: 'sort-items-v1',
      sortBy: dynamicYieldSortData.sortBy,
      sortOrder: dynamicYieldSortData.sortOrder,
    });
  }
}

export const dynamicEvents: DynamicEvents = DynamicEvents.getInstance();
