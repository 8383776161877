import { Banner } from 'api/banner/types';
import { Box } from '@mui/material';
import { Key } from 'enums/key';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

type Props = {
  banner: Banner;
};

const MAX_WIDTH_PX = 1920;
const calculateMargin = (): number => {
  if (document.body.clientWidth < MAX_WIDTH_PX) {
    return -Math.floor((MAX_WIDTH_PX - document.body.clientWidth) / 2);
  }
  return 0;
};

const trackItem = (bannerId: string): void => {
  const SKINNY_TRACK_ITEM_ID = '8';
  const query = new URLSearchParams({
    TrackingItemID: SKINNY_TRACK_ITEM_ID,
    BannerID: bannerId,
  }).toString();
  axios.get(`/WebsiteTracker.aspx?${query}`).then(
    () => {},
    () => {},
  );
};

export const SkinnyDesktop: FC<Props> = ({ banner }) => {
  const [marginLeft, setMarginLeft] = useState<number>(calculateMargin());
  const trackBanner = () => trackItem(banner.id.toString());
  const recalculateMarginLeft = (): void => setMarginLeft(calculateMargin());

  useEffect(() => {
    window.addEventListener('resize', recalculateMarginLeft);
    return () => {
      window.removeEventListener('resize', recalculateMarginLeft);
    };
  }, []);

  if (!banner.desktopImage) {
    return null;
  }

  const handleEnter = ({ key }: KeyboardEvent) => {
    if (key === Key.Enter) {
      trackBanner();
    }
  };

  return (
    <Box
      onClick={trackBanner}
      onKeyUp={handleEnter}
      style={{ backgroundColor: banner.backgroundColor || 'transparent' }}
      width={1}
      maxWidth={4000}
      height={40}
      minHeight={40}
      zIndex={8}
      tabIndex={0}
      border={0}
      p={0}
      mx="auto"
      component="button"
      role="button"
      overflow="hidden"
      data-test="header-banner"
    >
      <img
        src={banner.desktopImage}
        alt={banner.alt}
        useMap={`#SkinnyBannerMap${banner.id}`}
        style={{ marginLeft }}
      />
      {parse(banner.desktopImageMap)}
    </Box>
  );
};
