import { Environment, getCurrentEnvironment } from 'utils/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';

export const useDataDog = (): void => {
  useEffect(() => {
    if (getCurrentEnvironment() === Environment.PRODUCTION) {
      datadogLogs.init({
        clientToken: 'pub73e9d9e32788032d0503929452294f89',
        site: 'us3.datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
    }
  });
};
