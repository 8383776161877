import { DessertBoardType } from 'api/products/types';
import {
  OwnBoxAddon,
  OwnBoxOptions,
} from 'redux/PDP/arrangement/types/own-box.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';

const getUpdateBackedGoodQty = (index: number, itemsCount: number) => {
  if (itemsCount === 1) {
    switch (index) {
      case 0:
        return 6;
      default:
        return 6;
    }
  }
  if (itemsCount === 2) {
    switch (index) {
      case 0:
        return 4;
      case 1:
        return 2;
      default:
        return 6;
    }
  }
  if (itemsCount === 3) {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 2;
      case 2:
        return 2;
      default:
        return 6;
    }
  }
  return 6;
};

const getUpdateDippedBerriesQty = (index: number, itemsCount: number) => {
  if (itemsCount === 1) {
    switch (index) {
      case 0:
        return 6;
      default:
        return 6;
    }
  }
  if (itemsCount === 2) {
    switch (index) {
      case 0:
        return 3;
      case 1:
        return 3;
      default:
        return 6;
    }
  }
  if (itemsCount === 3) {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 2;
      case 2:
        return 2;
      default:
        return 6;
    }
  }
  if (itemsCount === 4) {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 2;
      case 2:
        return 1;
      case 3:
        return 1;
      default:
        return 6;
    }
  }
  if (itemsCount === 5) {
    switch (index) {
      case 0:
        return 2;
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 1;
      case 4:
        return 1;
      default:
        return 6;
    }
  }
  if (itemsCount === 6) {
    switch (index) {
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 1;
      case 4:
        return 1;
      case 5:
        return 1;
      default:
        return 6;
    }
  }
  return 6;
};

const getUpdateDippedFruitsQty = (index: number, itemsCount: number) => {
  if (itemsCount === 1) {
    switch (index) {
      case 0:
        return 2;
      default:
        return 2;
    }
  }
  if (itemsCount === 2) {
    switch (index) {
      case 0:
        return 1;
      case 1:
        return 1;
      default:
        return 2;
    }
  }
  return 2;
};

export const updateDessertBoardQuantity = (
  selected: UpsellItem[],
  options: OwnBoxOptions,
  clicked?: OwnBoxAddon,
  clickOwnBoxAddon?: OwnBoxAddon[],
): UpsellItem[] => {
  const backedAddons = selected.filter((x) =>
    clickOwnBoxAddon?.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.BakedGoods,
    ),
  );

  const dippedBerriesAdon = selected.filter((x) =>
    clickOwnBoxAddon?.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.DippedBerries,
    ),
  );

  const dippedFruitsAdon = selected.filter((x) =>
    clickOwnBoxAddon?.some(
      (addon) =>
        addon.addonId === x.option.addonId &&
        addon.dessertBoxType === DessertBoardType.DippedFruits,
    ),
  );

  let updatedQty = options.cells;
  let backedAddonsIndex = 0;
  let dippedBerriesAdonIndex = 0;
  let dippedFruitsAdonIndex = 0;
  const res = selected.map((selectedItem) => {
    if (
      backedAddons.some((x) => x.option.addonId === selectedItem.option.addonId)
    ) {
      if (backedAddons.length === 1) {
        updatedQty = getUpdateBackedGoodQty(backedAddonsIndex, 1);
      }
      if (backedAddons.length === 2) {
        updatedQty = getUpdateBackedGoodQty(backedAddonsIndex, 2);
      }
      if (backedAddons.length === 3) {
        updatedQty = getUpdateBackedGoodQty(backedAddonsIndex, 3);
      }
      backedAddonsIndex += 1;
    }

    if (
      dippedBerriesAdon.some(
        (x) => x.option.addonId === selectedItem.option.addonId,
      )
    ) {
      if (dippedBerriesAdon.length === 1) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 1);
      }
      if (dippedBerriesAdon.length === 2) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 2);
      }
      if (dippedBerriesAdon.length === 3) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 3);
      }
      if (dippedBerriesAdon.length === 4) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 4);
      }
      if (dippedBerriesAdon.length === 5) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 5);
      }
      if (dippedBerriesAdon.length === 6) {
        updatedQty = getUpdateDippedBerriesQty(dippedBerriesAdonIndex, 6);
      }
      dippedBerriesAdonIndex += 1;
    }

    if (
      dippedFruitsAdon.some(
        (x) => x.option.addonId === selectedItem.option.addonId,
      )
    ) {
      if (dippedFruitsAdon.length === 1) {
        updatedQty = getUpdateDippedFruitsQty(dippedFruitsAdonIndex, 1);
      }
      if (dippedFruitsAdon.length === 2) {
        updatedQty = getUpdateDippedFruitsQty(dippedFruitsAdonIndex, 2);
      }
      dippedFruitsAdonIndex += 1;
    }
    return {
      ...selectedItem,
      quantity:
        selectedItem.option.addonId === clicked?.addonId
          ? updatedQty
          : selectedItem.quantity,
    };
  });
  return res;
};
