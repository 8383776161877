import { Alert, Collapse, Typography } from '@mui/material';
import {
  checkIsPending,
  getResetState,
} from 'components/Authentication/Reset/feature/selectors';
import { getResetStatusMessage } from 'components/Authentication/translations/get-reset-status-message';
import { isError } from 'api/auth-legacy/reset-password.misc';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ResetAlert: FC = () => {
  const reset = useSelector(getResetState);
  const pending = useSelector(checkIsPending);

  return (
    <Collapse in={!pending && isError(reset)}>
      <Alert sx={{ mb: 3 }} severity="error">
        <Typography variant="body2">
          {i18next.t(getResetStatusMessage(reset))}
        </Typography>
      </Alert>
    </Collapse>
  );
};
