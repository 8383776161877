import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';
import { unlockDiscountBaseImageDesktop } from 'utils/cloudinaryAssets';

export const drawerBar: ThemedSxProps = (theme) => ({
  height: '4px',
  width: '120px',
  background: theme.palette.common.black,
  opacity: '54%',
  borderRadius: '4px',
  marginTop: '8px',
});
export const containerWrapper: ThemedSxProps = {
  display: 'flex',
  padding: '0px !important',
  textAlign: { md: 'center', sm: 'center', xs: 'center' },
};

export const wrapper: ThemedSxProps = {
  width: '100%',
  paddingTop: '20px',
};

export const unlockOffer: ThemedSxProps = {
  minHeight: { md: '220px', sm: '220px', xs: '120px' },
  marginTop: { md: '10px', sm: '10px', xs: '7px' },
  marginBottom: { md: '0px', sm: '0px', xs: '10px' },
  backgroundImage: {
    md: `url(${unlockDiscountBaseImageDesktop})`,
    xs: `url(${unlockDiscountBaseImageDesktop})`,
    sm: `url(${unlockDiscountBaseImageDesktop})`,
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'right -10px center',
  display: { md: 'block', sm: 'block', xs: 'block' },
};

export const contentTitle: ThemedSxProps = {
  fontWeight: 400,
  fontSize: { md: '48px', sm: '48px', xs: '24px' },
  letterSpacing: '0.15px',
  lineHeight: { md: '134%', sm: '134%', xs: '160%' },
  fontFamily: fonts.poppins,
};
export const contentOffer: ThemedSxProps = {
  width: '100%',
  fontSize: { md: '96px', sm: '96px', xs: '80px' },
  letterSpacing: '-1.5px',
  lineHeight: { md: '76px', sm: '76px', xs: '60px' },
  margin: '8px 0px 8px 0px',
  fontWeight: '600',
  fontFamily: fonts.poppins,
  '& span:nth-child(2)': {
    verticalAlign: 'text-top',
    fontWeight: '900',
    fontSize: { md: '60px', sm: '60px', xs: '50px' },
    letterSpacing: '-0.5px',
    lineHeight: { md: '120%', sm: '120%', xs: '120%' },
  },
  '& span:nth-child(4)': {
    verticalAlign: 'text-top',
    fontWeight: '900',
    paddingLeft: '10px',
    fontSize: { md: '34px', sm: '34px', xs: '24px' },
    letterSpacing: '0.25px',
    lineHeight: { md: '120%', sm: '120%', xs: '120%' },
  },
};
export const contentInstantSavings: ThemedSxProps = {
  color: colors.primaryRed,
  fontWeight: '700',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const inlineContent: ThemedSxProps = {
  display: 'inline-flex',
  fontWeight: '500',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const pickupContent: ThemedSxProps = {
  fontWeight: '500',
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  letterSpacing: '0.15px',
};

export const termsContainer: ThemedSxProps = {
  marginTop: { md: '0px', sm: 'auto', xs: 'auto' },
  minHeight: { md: 'auto', sm: 'auto', xs: 'auto' },
  position: { md: 'static', sm: 'static', xs: 'static' },
};

export const TermsOfUse = styled('a')({
  textDecoration: 'underline',
  fontFamily: fonts.poppins,
  fontSize: 12,
  fontWeight: '500',
  lineHeight: '164%',
  color: colors.black,
});

export const termsofuse: ThemedSxProps = {
  marginLeft: '0px',
};

export const TermsAndConditions = styled('a')({
  textDecoration: 'underline',
});
export const termConBottom: ThemedSxProps = (theme) => ({
  paddingTop: '16px',
  paddingBottom: '20px',
  fontFamily: fonts.poppins,
  fontSize: 12,
  fontWeight: '500',
  lineHeight: '164%',
  textAlign: 'center',
  color: theme.palette.common.black,
});
