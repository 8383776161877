import * as styles from 'containers/MyAccountsContainer/my-account-container.styles';
import { Grid } from '@mui/material';
import { MyAccountHome } from 'components/Rewards/RewardLanding/index';
import React, { FC } from 'react';

export const MyAccountContainer: FC = () => (
  <Grid sx={styles.containerWrapper}>
    <Grid sx={styles.main}>
      <MyAccountHome />
    </Grid>
  </Grid>
);
