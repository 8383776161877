import { AxiosError } from 'axios';
import { CONTACT_US_SAVE_ERROR } from 'containers/ContactUsContainer/translations/locale.constants';
import { ResponseError } from 'types/error.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { sessionApiService } from 'api/session/session.api.service';

export const fetchUserSession = createAsyncThunk(
  'session-user/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return await sessionApiService.getUser();
    } catch (err) {
      const returnError = parseAxiosError(err);
      return rejectWithValue(returnError.message);
    }
  },
);

export const fetchUserAccountInformation = createAsyncThunk(
  'user-account-information/fetch',
  async (userId: number, { rejectWithValue }) => {
    try {
      const data = await sessionApiService.getUserAccountInformation(userId);
      return data;
    } catch (err) {
      const returnError = parseAxiosError(err);
      return rejectWithValue(returnError.message);
    }
  },
);

export const saveStore = createAsyncThunk(
  'credited-store/store',
  async (storeNumber: string, { rejectWithValue }) => {
    try {
      let data = '';
      if (storeNumber) {
        const postData: string = storeNumber;
        const response = await sessionApiService.setCreditedStore(postData);
        data = response.data;
      }

      return { data };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after sometimes',
          data: { key: CONTACT_US_SAVE_ERROR },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateCartCount = createAsyncThunk(
  'cart-count/update',
  async (cartCount: number, { rejectWithValue }) => {
    try {
      let data;
      if (cartCount) {
        const response = await sessionApiService.setCartCount(cartCount);
        data = response.data;
      }
      return { data };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after sometimes',
          data: { key: CONTACT_US_SAVE_ERROR },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const saveOrderConfirmation = createAsyncThunk(
  'order-confirmation/save',
  async (status: boolean, { rejectWithValue }) => {
    try {
      let data = false;
      const postData: boolean = status;
      const response = await sessionApiService.setOrderConfirmationShown(
        postData,
      );
      data = response.data;

      return { data };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after sometimes',
          data: { key: CONTACT_US_SAVE_ERROR },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);

export const updateSaleId = createAsyncThunk(
  'update-saleId/update',
  async (saleId: number, { rejectWithValue }) => {
    try {
      let data = '';
      if (saleId) {
        const response = await sessionApiService.updateSaleId(saleId);
        data = response.data;
      }

      return { data };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after sometimes',
          data: { key: CONTACT_US_SAVE_ERROR },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
