import { SxProps, Theme } from '@mui/system';
import { TextAndButtonAlignment } from 'components/Home/CarouselBanners/components/CarouselImage/banner-text-type';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';
import React from 'react';

export const backGroundImageWrapper: SxProps = {
  display: 'flex !important',
  minHeight: ['400px', '400px', '720px'],
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
};

export const backGroundImageWrapperTop: SxProps = {
  display: 'flex !important',
  minHeight: ['400px', '400px', '720px'],
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
};

export const mainContainer: SxProps = {
  display: 'flex',
  maxWidth: '1650px',
  width: ['auto', 'auto', '1650px'],
};

export const mainContainerTop: SxProps = {
  display: 'flex',
  maxWidth: '1060px',
  padding: ['24px', '24px', '64px 0px'],
  alignItems: ['center', 'center', 'center'],
  gap: ['24px', '24px', '32px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  margin: '0 auto',
  flexDirection: ['column', 'column', 'column'],
  justifyContent: ['center', 'center', 'center'],
};

export const mainFrameDesktop: SxProps = {
  display: 'flex',
};

export const mainFrameDesktopTop: SxProps = {
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  alignItems: ['center', 'center', 'center'],
  gap: ['16px', '16px', '8px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const mainFrameDesktopTopWithoutSmallText: SxProps = {
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  alignItems: ['center', 'center', 'center'],
  gap: '24px',
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const mainFrameMobile: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  gap: ['16px', '16px', '80px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('sm')]: {
    maxWidth: ['480px'],
    alignItems: 'center !important',
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: ['480px'],
    alignItems: 'center !important',
  },
});

export const mainFrameMobileTop: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: ['column', 'column', 'column'],
  justifyContent: 'center',
  gap: ['16px', '24px', '80px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('sm')]: {
    maxWidth: ['480px'],
    alignItems: 'flex-start !important',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: ['480px'],
    alignItems: 'flex-start !important',
    flexDirection: 'column',
  },
});

export const spacer: SxProps<Theme> = (theme) => ({
  display: 'flex !important',
  flexDirection: 'column !important',
  alignItems: 'flex-start !important',
  gap: '10px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('md')]: {
    width: '514px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '300px',
  },
  [theme.breakpoints.up('xs')]: {
    width: '186px',
  },
});

export const textContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  gap: ['16px', '16px', '24px'],
  alignSelf: 'stretch',
  position: 'absolute',
};

export const textContainerTop: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  gap: ['4px', '4px', '8px'],
  alignSelf: 'stretch',
};

export const textContainerTopWithoutSmallText: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  gap: '16px',
  alignSelf: 'stretch',
};

export const headerText: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  fontSize: ['30px', '30px', '72px'],
  fontWeight: ['500', '500', '500'],
  lineHeight: ['123.5%', '123.5%', '120%'],
};

export const headerTextTop: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  fontSize: ['30px', '30px', '72px'],
  fontWeight: ['500', '500', '500'],
  lineHeight: ['123.5%', '123.5%', '120%'],
  textAlign: 'center',
};

export const midText: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontSize: ['14px', '14px', '24px'],
  alignSelf: 'stretch',
  fontWeight: 500,
  lineHeight: ['143%', '143%', '133.4%'],
};

export const midTextTop: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: ['14px', '14px', '24px'],
  alignSelf: 'stretch',
  fontWeight: 500,
  lineHeight: ['143%', '143%', '133.4%'],
  textAlign: 'center',
};

export const smallText: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontSize: ['10px', '10px', '12px'],
  alignSelf: 'stretch',
  fontWeight: ['400', '400', '500'],
};

export const smallTextTop: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: ['10px', '10px', '12px'],
  alignSelf: 'stretch',
  fontWeight: ['400', '400', '500'],
  textAlign: 'center',
};

export const buttonContainer: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'absolute',
};

export const buttonStyle: SxProps = {
  display: 'flex',
  padding: ['9px 24px', '9px 24px', '15px 32px'],
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
};

export const buttonText: SxProps = {
  fontFamily: fonts.poppins,
  fontWeight: '600',
  fontSize: ['13px', '13px', '15px'],
  lineHeight: ['22px', '22px', '26px'],
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
};

export const tabMobileTextContainer: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  width: '180px',
};

export const tabMobileButtonContainer: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  width: '155px',
};

export const tabMobileTextContainerTop: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  justifyContent: 'center',
  alignSelf: 'stretch',
  minHeight: '172px',
};

export const tabMobileTextContainerTopWithOutSmallText: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  justifyContent: 'center',
  alignSelf: 'stretch',
  minHeight: '172px',
};

export const tabTextBox: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const Image = styled('img')({
  display: 'block',
  width: '100%',
});

export const getAlignmentStyles = (
  alignment: TextAndButtonAlignment,
): React.CSSProperties => {
  switch (alignment) {
    case TextAndButtonAlignment.TopLeft:
      return { top: '64px', left: '64px' };
    case TextAndButtonAlignment.TopMiddle:
      return { top: '64px', left: '50%' };
    case TextAndButtonAlignment.TopRight:
      return { top: '64px', right: '64px' };
    case TextAndButtonAlignment.MiddleLeft:
      return { top: '50%', left: '64px' };
    case TextAndButtonAlignment.MiddleMiddle:
      return { top: '50%', left: '50%' };
    case TextAndButtonAlignment.MiddleRight:
      return { top: '50%', right: '64px' };
    case TextAndButtonAlignment.BottomLeft:
      return { bottom: '64px', left: '64px' };
    case TextAndButtonAlignment.BottomMiddle:
      return { bottom: '64px', left: '50%' };
    case TextAndButtonAlignment.BottomRight:
      return { bottom: '64px', right: '64px' };
    default:
      return {};
  }
};

export const getMobileAlignmentStyles = (
  alignment: TextAndButtonAlignment,
): React.CSSProperties => {
  switch (alignment) {
    case TextAndButtonAlignment.TopLeft:
      return { top: '24px', left: '20px' };
    case TextAndButtonAlignment.TopMiddle:
      return { top: '24px', left: '50%' };
    case TextAndButtonAlignment.TopRight:
      return { top: '24px', right: '64px' };
    case TextAndButtonAlignment.MiddleLeft:
      return { top: '50%', left: '20px' };
    case TextAndButtonAlignment.MiddleMiddle:
      return { top: '50%', left: '50%' };
    case TextAndButtonAlignment.MiddleRight:
      return { top: '50%', right: '64px' };
    case TextAndButtonAlignment.BottomLeft:
      return { bottom: '24px', left: '20px' };
    case TextAndButtonAlignment.BottomMiddle:
      return { bottom: '24px', left: '50%' };
    case TextAndButtonAlignment.BottomRight:
      return { bottom: '24px', right: '20px' };
    default:
      return {};
  }
};
