import { SxProps } from '@mui/system';

export const header: SxProps = {
  flexDirection: 'row',
  display: 'flex',
};

export const marketplaceProductTitle: SxProps = {
  marginBottom: 1,
};

export const shippingIcon: SxProps = {
  width: 22,
  height: 22,
  marginRight: '.4rem',
};

export const shippingRow: SxProps = {
  whiteSpace: 'pre',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'end',
};

export const badgeContainer: SxProps = {
  marginLeft: 'auto',
};

export const badge: SxProps = {
  width: { md: 85, sm: 85, xs: 70 },
  height: { md: 85, sm: 85, xs: 70 },
};

export const marketPlacePrice: SxProps = {
  mb: 1,
  mt: 1,
};
