import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { set } from 'date-fns';

export const getData = createSelector(
  ({ arrangement: { data } }: RootState) => data,
  (data) => data,
);

export const checkIsUnavailableBySchedule = createSelector(
  ({ arrangement: { data } }: RootState) => ({
    from: data.activeFromDate,
    to: data.activeToDate,
  }),
  ({ from, to }) => {
    if (!from && !to) {
      return false;
    }

    const now = new Date();

    const formattedToDate = set(new Date(to || new Date()), {
      year: new Date(to || new Date()).getFullYear(),
      month: new Date(to || new Date()).getMonth(),
      date: new Date(to || new Date()).getDate(),
      hours: 23,
      minutes: 59,
      seconds: 59,
    });

    const isAfter = !from || new Date(from) < now;
    const isBefore = !to || new Date(formattedToDate) > now;
    const betweenFromAndTo = isAfter && isBefore;
    return !betweenFromAndTo;
  },
);
