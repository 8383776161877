import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { Banner, QueryParams } from 'api/banner/types';

class BannerService extends ApiBaseService {
  find(query?: QueryParams): AxiosPromise<Banner[]> {
    let queryString = '';
    if (query) {
      const params = new URLSearchParams();
      Object.keys(query).forEach((key) => {
        params.set(key, query[key as keyof QueryParams].toString());
      });
      queryString = `?${params.toString()}`;
    }

    return this.apibase.get(`/banners${queryString}`);
  }
}

export const bannerService = new BannerService();
