import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import { Helmet } from 'react-helmet-async';
import { ogImage } from 'utils/cloudinaryAssets';
import { useStructuredStateData } from 'hooks/meta/use-state-structured-data';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  state: string;
}
export const HeadMetaState: FC<Props> = ({ state }) => {
  const structuredData = useStructuredStateData();

  return (
    <Helmet>
      <title>
        {i18next.t(TK.STATE_META_TITLE_TAG, {
          replace: {
            State: state,
          },
        })}
      </title>
      <meta name="keywords" content="" />
      <meta
        name="title"
        content={i18next.t(TK.STATE_META_TITLE_TAG, {
          replace: {
            State: state,
          },
        })}
      />
      <meta
        name="description"
        content={i18next.t(TK.STATES_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
          },
        })}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={i18next.t(TK.STATE_META_TITLE_TAG, {
          replace: {
            State: state,
          },
        })}
      />
      <meta
        property="og:description"
        content={i18next.t(TK.STATES_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
          },
        })}
      />
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};
