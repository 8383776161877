import { StrawberryImgMob, StrawberryImgTab } from 'utils/cloudinaryAssets';
import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const contactContainer: SxProps = {
  display: 'block',
  alignItems: 'center',
  mb: 1,
  p: '8px 0px 8px 8px',
};

export const textField: SxProps = {
  backgroundColor: colors.white,
  color: colors.black,
};

export const buttonContainer: SxProps = {
  display: 'flex',
  paddingTop: '20px',
};

export const valid: SxProps = {
  marginTop: '50%',
};

export const streetAddress: SxProps = {
  mt: 3,
  width: 1,
  mr: [0, 0, 1],
};

export const aptButtonWrapper: SxProps = {
  width: [1, 1, 0],
};

export const aptButton: SxProps = {
  mt: 1,
  textTransform: 'none',
  fontWeight: 600,
  minWidth: 140,
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
};

export const streetAdditionalWrapper: SxProps = {
  width: 1,
  mt: 3,
};

export const streetAdditional: SxProps = {
  width: [1, 1],
  mr: 0,
};

export const textFieldWrapper: SxProps = {
  display: 'flex',
  maxWidth: 'auto',
  flexDirection: ['column', 'row', 'row'],
  width: 1,
  justifyContent: 'space-between',
  mt: 3,
};

export const smsAlertContainer: SxProps = {
  display: 'flex',
  maxWidth: 'auto',
  flexDirection: 'row',
  width: 1,
  alignItems: 'center',
  mt: 3,
};

export const zipCodeField: SxProps = {
  mb: [3, 0, 0],
  width: 1,
  mr: [0, 1, 1],
};

export const cityField: SxProps = {
  width: 1,
  ml: [0, 1, 1],
};

export const formControl: SxProps = {
  width: '100%',
};

export const promoWrapper: SxProps = {
  maxWidth: 'auto',
  pb: 4,
};

export const promoContent: SxProps = {
  p: '32px 20px 32px 20px',
  backgroundColor: colors.systemBackground,
  borderRadius: '24px',
};

export const rewardContent: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  backgroundImage: [
    `url(${StrawberryImgMob})`,
    `url(${StrawberryImgTab})`,
    `url(${StrawberryImgTab})`,
  ],
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
};

export const rewardList: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'disc',
  ml: 3,
  '& .MuiListItem-root': {
    display: 'list-item',
  },
};

export const rewardListItem: SxProps = {
  flex: ['100%', '50%', '100%'],
  px: 0,
  pb: 0,
};

export const rewardMember: SxProps = {
  fontFamily: fonts.spartan,
  fontWeight: 500,
  fontSize: '10px',
  '& a': { color: colors.primaryRed },
};

export const payButton: SxProps = {
  width: ['100%', '430px', '430px'],
  height: '56px',
  fontFamily: fonts.spartan,
  fontSize: 15,
  lineHeight: '26px',
  fontWeight: 600,
  letterSpacing: 0.46,
  textTransform: 'none',
  p: '16px, 32px',
  color: colors.white,
  backgroundColor: colors.primaryRed,
  ':hover': {
    color: colors.white,
    backgroundColor: colors.primaryRed,
  },
};

export const termsConditions: SxProps = {
  fontFamily: fonts.spartan,
  fontWeight: '500',
  fontSize: '10px',
  mt: 3,
  ml: 1,
  '& a': { color: colors.primaryRed },
};

export const adornment: SxProps = {
  '&.MuiInputAdornment-root': {
    position: 'absolute',
    right: '14px',
  },
};

export const passwordStrength: SxProps = {
  width: '32px',
  height: '4px',
  borderRadius: '4px',
  border: `1px solid ${colors.borderBlack} `,
};

export const passwordStrengthWrapper: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: 1,
  ml: 3,
  mt: 1,
};
export const loginButtonStyle: SxProps = {
  p: 0,
  minWidth: 'auto',
  lineHeight: 'normal',
};
export const editEmailExitBtn: SxProps = {
  p: 0,
  minWidth: 'auto',
  lineHeight: 'normal',
  textDecoration: 'underline',
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
};
