import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  maxWidth: '1060px',
  alignItems: 'left',
  margin: '0 auto',
  boxSizing: 'content-box',
  padding: '0 20px',
  [theme.breakpoints.up('sm')]: {
    padding: '0 40px',
  },
}));

export const CarouselContainer = styled('div')({
  '& .slick-slider': {
    '& button.slick-arrow': {
      display: 'none !important',
    },
    '& .slick-list': {
      margin: '0 -10px',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
        },
      },
    },
  },
});

export const title: ThemedSxProps = (theme) => ({
  height: 20,
  width: '50%',
  margin: '1px 0',
  [theme.breakpoints.up('md')]: {
    height: 25,
    margin: '5px 0',
  },
});

export const name: ThemedSxProps = {
  height: 16,
  margin: '18px 0',
};

export const sizeCount: ThemedSxProps = {
  height: 12,
  width: '75%',
  marginBottom: 1.25,
};

export const price: ThemedSxProps = {
  height: 16,
  width: '35%',
  marginBottom: 2.5,
};

export const link: ThemedSxProps = {
  height: 30,
  marginBottom: 0.5,
};
