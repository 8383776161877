import {
  AUTH_RESET_SENT_1,
  AUTH_RESET_SENT_2,
  AUTH_RESET_SENT_3,
} from 'components/Authentication/translations/locale.constants';
import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ResetSent: FC = () => (
  <Box width={1} textAlign="center">
    <Typography variant="body2">{i18next.t(AUTH_RESET_SENT_1)}</Typography>
    <Typography variant="body2" mt={2}>
      {i18next.t(AUTH_RESET_SENT_2)}
    </Typography>
    <Typography variant="body2" mt={2}>
      {i18next.t(AUTH_RESET_SENT_3)}
    </Typography>
  </Box>
);
