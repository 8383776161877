import * as TK from 'components/Home/NotificationBanner/translations/locale.constants';

export const notificationBannerCommonLocale = {
  [TK.NOTIFICATION_BANNER_WITHIN_YEAR]: '{{years}} year ',
  [TK.NOTIFICATION_BANNER_WITHIN_YEARS]: '{{years}} years ',
  [TK.NOTIFICATION_BANNER_WITHIN_MONTH]: '{{months}} month ',
  [TK.NOTIFICATION_BANNER_WITHIN_MONTHS]: '{{months}} months ',
  [TK.NOTIFICATION_BANNER_WITHIN_DAY]: '{{days}} day ',
  [TK.NOTIFICATION_BANNER_WITHIN_DAYS]: '{{days}} days ',
  [TK.NOTIFICATION_BANNER_WITHIN_HOURS]: '{{hours}} hours ',
  [TK.NOTIFICATION_BANNER_WITHIN_HOUR]: '{{hours}} hour ',
  [TK.NOTIFICATION_BANNER_WITHIN_MINUTES]: '{{minutes}} minutes ',
  [TK.NOTIFICATION_BANNER_WITHIN_MINUTE]: '{{minutes}} minute',
};
