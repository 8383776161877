import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { HomePageContent } from 'components/Home/types';

class PlpWidgetApiService extends ApiBaseService {
  getContent(
    previewDate: string | null,
    showDraft: string | null,
    filterId: number | null,
  ): AxiosPromise<HomePageContent> {
    return this.apibase.get<HomePageContent>('/home', {
      params: {
        previewDate,
        showDraft,
        filterId,
      },
    });
  }
}

export const plpWidgetApiService = new PlpWidgetApiService();
