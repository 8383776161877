export enum Components {
  banner = 'banner',
  secondaryBanner = 'secondaryBanner',
  bannerWithProductList = 'bannerWithProductList',
  carouselSimplified = 'carouselSimplified',
  carouselDetailed = 'carouselDetailed',
  carouselBanners = 'carouselBanners',
  gridWidget = 'gridWidget',
  chipWidget = 'chipWidget',
  homepage = 'homepage',
  generalText = 'generalText',
  notificationBanner = 'notificationBanner',
  bvsocial = 'bvsocial',
  videoWithText = 'videoWithText',
}
