import { SubscriptionFormData } from 'components/Subscription/types';
import { appInsights } from 'utils/telemetry/app-insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { dynamicEvents } from 'service/dynamic-yield';
import { subscribeLegacyApiService } from 'api/subscribe-legacy.api.service';

export const subscribe = createAsyncThunk(
  'subscription/subscribe',
  async (data: SubscriptionFormData) => {
    appInsights.trackEvent({
      name: !data.phone
        ? 'Subscription form submitted with email'
        : 'Subscription form submitted with email and phone number',
    });
    const result = await subscribeLegacyApiService.subscribe(data);

    if (data.email) await dynamicEvents.newsletterSubscription(data.email);

    return result;
  },
);
