import { fetchRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/actions';
import { selectAreRecipientsInit } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import { selectIsRecipientOrNoLock } from 'redux/session/availability/availability.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useFetchRecipients(): void {
  const dispatch = useDispatch();
  const isRecipientOrNoLock = useSelector(selectIsRecipientOrNoLock);
  const areRecipientsInit = useSelector(selectAreRecipientsInit);

  useEffect(() => {
    if (isRecipientOrNoLock && areRecipientsInit) {
      dispatch(fetchRecipients());
    }
  }, [isRecipientOrNoLock, areRecipientsInit, dispatch]);
}
