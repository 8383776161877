import * as styles from 'components/Header/MobileMenus/styles';
import { Filter } from 'redux/header/header.types';
import { ListItemButton, ListSubheader, Typography } from '@mui/material';
import { Navigation } from 'components/Header/MobileMenus/types';
import { getNavigationUrl } from 'components/Header/utils';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  title: string;
  items: Filter[] | Navigation[];
}

export const SubMenuSection: FC<Props> = ({ title, items }) => (
  <div>
    <ListSubheader disableSticky sx={styles.listItem}>
      <Typography variant="subtitle2" color="disabled">
        {i18next.t(title)}
      </Typography>
    </ListSubheader>
    {items.map(({ name, url }) => (
      <ListItemButton
        key={name}
        sx={styles.listItem}
        component="a"
        href={getNavigationUrl(url)}
      >
        <Typography variant="subtitle2" color="secondary">
          {i18next.t(name)}
        </Typography>
      </ListItemButton>
    ))}
  </div>
);
