import { useLocation, useNavigate } from 'react-router-dom';

type Remover = (parameters: string[]) => void;
export const useQueryParametersRemover = (): Remover => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (params: string[]): void => {
    const urlSearchParams = new URLSearchParams(search);
    params.forEach((param) => {
      urlSearchParams.delete(param);
    });
    navigate({ search: urlSearchParams.toString() });
  };
};
