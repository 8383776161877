import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { RedirectUrl } from 'redux/PLP/redirect/types/types';

class RedirectApiService extends ApiBaseService {
  find(path: string): AxiosPromise<RedirectUrl[]> {
    return this.apibase.get<RedirectUrl[]>(
      `/arrangement-groups/getUrlRedirectionSetting/${path}`,
    );
  }
}
const redirectApiService = new RedirectApiService();

export { redirectApiService };
