import * as styles from 'components/Session/Alert/styles';
import { Box, Collapse } from '@mui/material';
import { FullStoreLock } from 'components/Session/Alert/StoreLockAlert/FullStoreLock';
import { SlimLockAlert } from 'components/Session/Alert/components/SlimLockAlert';
import { appInsights } from 'utils/telemetry/app-insights';
import { batch, useDispatch, useSelector } from 'react-redux';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { resetAvailabilitySession } from 'redux/session/availability/availability.action';
import { segment } from 'service/segment/segment';
import {
  selectIsFullLock,
  selectIsStoreLock,
} from 'redux/session/availability/availability.selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { storeSelector } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.slice';
import { useTitle } from 'components/Session/Alert/translations/use-title';
import { zipAvailability } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.slice';
import React, { FC, useCallback } from 'react';

interface Props {
  slim?: boolean;
  shortDate?: boolean;
  displayPickupStore?: boolean;
}

export const StoreLockAlert: FC<Props> = ({
  slim,
  shortDate,
  displayPickupStore,
}) => {
  const dispatch = useDispatch();
  const isStoreLock = useSelector(selectIsStoreLock);
  const isFullStoreLock = useSelector(selectIsFullLock);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const reset = useCallback(() => {
    batch(() => {
      dispatch(pas.reset());
      dispatch(storeSelector.reset());
      dispatch(zipAvailability.reset());
      dispatch(resetAvailabilitySession());
    });
    segment.storeLockResetClicked(userSession, userAccountInformation);
    appInsights.trackEvent({ name: 'Session lock reset' });
  }, [dispatch]);
  const title = useTitle({
    shortDate,
    displayPickupStore,
  });

  if (!isStoreLock) {
    return null;
  }

  return (
    <Collapse in={isStoreLock}>
      <Box sx={styles.alert} role="alert">
        {isFullStoreLock && !slim ? (
          <FullStoreLock title={title} reset={reset} />
        ) : (
          <SlimLockAlert
            dataTest="pdp-details-store-lock"
            title={title}
            reset={reset}
          />
        )}
      </Box>
    </Collapse>
  );
};
