import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';

export const carouselCon: ThemedSxProps = () => ({
  justifyContent: 'center',
});

export const carousel: ThemedSxProps = () => ({
  display: 'flex',
  maxWidth: '1060px',
  justifyContent: 'center',
  margin: '0 auto', // Center the grid
  width: '100%',
});

export const title: ThemedSxProps = (theme) => ({
  fontSize: '24px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  fontWeight: '500',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    fontWeight: '600',
  },
  whiteSpace: 'normal',
  textAlign: 'center',
  flex: '1 1 auto',
  pr: 1.2,
  '& p': {
    whiteSpace: 'wrap',
  },
});

export const arrowControls: ThemedSxProps = (theme) => ({
  display: 'flex',
  position: 'relative',
  '& button:first-of-type': {
    mr: 1.2,
    [theme.breakpoints.up('sm')]: {
      ml: 2.5,
      mr: 2.5,
    },
  },
});

export const Headline = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
});

export const GridContainer = styled(Grid)({
  flexWrap: 'wrap',
  width: '100%',
});

export const GridItem = styled(Grid)({
  boxSizing: 'border-box',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'center',
});
