import { CallCenterModeState } from 'components/PLP/CallCenter/feature/types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createCallCenterSession } from 'components/PLP/CallCenter/feature/action';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CallCenterModeState = {
  data: {
    name: '',
    id: 0,
    overNight: 0,
    source: 0,
  },
  isModalOpen: false,
  isEnabled: false,
  status: Status.INIT,
  error: null,
};

const callCenterModeSlice = createSlice({
  name: 'callCenterMode',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCallCenterSession.pending, (state) => {
        state.status = Status.PENDING;
        state.isEnabled = true;
      })
      .addCase(createCallCenterSession.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
        state.isModalOpen = true;
      })
      .addCase(createCallCenterSession.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.FULFILLED;
        state.isModalOpen = true;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: callCenterModeReducer, actions } = callCenterModeSlice;

export const callCenterModeActions = {
  ...actions,
  createCallCenterSession,
};
