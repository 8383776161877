import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';

class UpdateAddOnsLegacyApiService extends ApiLegacyBaseService {
  UpdateAddOnsUpgrades(upSellItems: string): AxiosPromise<DResponse> {
    return this.apibase.post(
      '/ajax/AddOnEdit.aspx/UpdateAddOns',
      upSellItems,
      {},
    );
  }
}

export const updateAddOnsLegacyApiService = new UpdateAddOnsLegacyApiService();
