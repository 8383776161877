export const SIGNUP_TEXT = 'signup.text';
export const SIGNUP_ACCOUNT_INFORMATION_TEXT =
  'signup_account_infromation.text';
export const SIGNUP_YOUR_NAME = 'signup_your_name.text';
export const SIGNUP_BIRTHDAY = 'signup_birthday.text';
export const SIGNUP_EMAIL_ADDRESS_TEXT = 'signup_email_address.text';
export const SIGNUP_EMAIL_NOT_VALID_ERROR_TEXT =
  'signup_email_not_valid_error.text';
export const SIGNUP_PASSWORD_TEXT = 'signup_password.text';
export const SIGNUP_PASSWORD_INVALID = 'signup_password.invalid';
export const SIGNUP_PASSWORD_MINIMUM = 'signup_password.minimum';
export const SIGNUP_PASSWORD_STRENGTH = 'signup_password.strength';
export const SIGNUP_VERIFY_PASSWORD_TEXT = 'signup.verify_password_error';
export const SIGNUP_VERIFY_PASSWORD_MATCH = 'signup.verify_password_match';
export const SIGNUP_FIRST_NAME = 'signup_first_name.text';
export const SIGNUP_PHONE_NUMBER = 'signup_phone_number.text';
export const SIGNUP_LAST_NAME = 'signup_last_name.text';
export const SIGNUP_BIRTHDAY_TOOLTIP = 'signup_birthday.tooltip';
export const SIGNUP_BIRTH_DATE = 'signup_birth_date.text';
export const SIGNUP_BIRTH_DAY = 'signup_birth_day.text';
export const SIGNUP_BIRTH_DAY_REQUIRED = 'signup_birth_day.error';
export const SIGNUP_BIRTH_MONTH = 'signup_birth_month.text';
export const SIGNUP_BIRTH_MONTH_REQUIRED = 'signup_birth_month.error';
export const SIGNUP_BIRTH_YEAR = 'signup_birth_year.text';
export const SIGNUP_BIRTH_YEAR_PLACEHOLDER = 'signup_birth_year.placeholder';
export const SIGNUP_PASSWORD_REQUIREMENTS = 'signup_password.requirements';
export const ENTER_FIRST_NAME = 'signup_first_name.required';
export const ENTER_LAST_NAME = 'signup_last_name.required';
export const ERROR_FIRST_NAME_SPL_CHARS = 'signup_first_name.error';
export const ERROR_LAST_NAME_SPL_CHARS = 'signup_last_name.error';
export const ERROR_PHONE_NUMBER_INVALID = 'signup_phone_number.error';
export const ERROR_REQUIRED = 'signup_field.required';
export const CHOOSE_OPTION = 'signup_form.choose_option';
export const SIGNUP_CREATE_ACCOUNT_BUTTON_TEXT =
  'signup_create_account_button.text';
export const SIGNUP_SUCCESS_TITLE = 'signup_success_title.text';
export const SIGNUP_SUCCESS_CONTENT = 'signup_success_content.text';
export const SIGNUP_MY_ACCOUNT_BUTTON_TEXT = 'signup_my_account_button.text';
export const SIGNUP_SHOP_NOW_BUTTON_TEXT = 'signup_shop_now_button.text';
export const SIGNUP_LINK_TERMS_OF_USE = 'signup_link_terms_of_use.text';
export const SIGNUP_LINK_PRIVACY_POLICY = 'signup_link_privacy_policy.text';
export const SIGNUP_LINK_PRODUCTS_POLICY = 'signup_link_products_policy.text';
export const SIGNUP_LINK_EDIBLE_REWARDS = 'signup_link_edible_rewards.text';
export const SIGNUP_LINK_CLICK_HERE = 'signup_link_click_here.text';
export const SIGNUP_TERMS_AND_CONDITIONS_TEXT =
  'signup_terms_and_conditions.text';
export const SIGNUP_BACK_TEXT = 'signup_back.text';
export const SIGNUP_CONTACT_INFORMATION = 'signup_contact_information.text';
