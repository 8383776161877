import { Environment, getCurrentEnvironment } from 'utils/environment';
import { HomePageContent } from 'components/Home/types';
import { fallbackResponseDev } from 'components/Home/feature/fallback/fallback-response-dev';
import { fallbackResponseLocal } from 'components/Home/feature/fallback/fallback-response-local';
import { fallbackResponseProd } from 'components/Home/feature/fallback/fallback-response-prod';
import { fallbackResponseQa } from 'components/Home/feature/fallback/fallback-response-qa';

const fallbackMap: Record<Environment, HomePageContent> = {
  [Environment.LOCAL]: fallbackResponseLocal,
  [Environment.DEVELOPMENT]: fallbackResponseDev,
  [Environment.QA]: fallbackResponseQa,
  [Environment.PRODUCTION]: fallbackResponseProd,
};

export const getFallbackResponse = (): HomePageContent =>
  fallbackMap[getCurrentEnvironment()];
