import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { Box } from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { Image } from 'components/common/Image/Image';
import { MyAccount } from 'components/Rewards/RewardLanding/Account/MyAccount';
import { RewardBreadcrumbBack } from 'components/Rewards/RewardLanding/MyRewards/RewardBreadcrumbBack';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import {
  rewardsBottomBannerDesktop,
  rewardsBottomBannerPhone,
  rewardsBottomBannerTablet,
  rewardsTopBannerDesktop,
  rewardsTopBannerPhone,
  rewardsTopBannerTablet,
} from 'utils/cloudinaryAssets';
import { useValidateRewardUserSession } from 'components/Rewards/hook/use-validate-reward-user-session';
import React, { FC } from 'react';
import i18next from 'i18next';

export const MyAccountHome: FC = () => {
  useValidateRewardUserSession();

  return (
    <Box>
      <RewardBreadcrumbBack />
      <HideUpSm>
        <HideOnXs>
          <Image
            alt={i18next.t(TK.REWARDS_TOP_BANNER)}
            src={rewardsTopBannerDesktop}
            sx={styles.bannerImg}
          />
        </HideOnXs>
      </HideUpSm>
      <HideOnXs>
        <ShowUpSm>
          <Image
            alt={i18next.t(TK.REWARDS_TOP_BANNER)}
            src={rewardsTopBannerTablet}
            sx={styles.bannerImg}
          />
        </ShowUpSm>
      </HideOnXs>
      <ShowUpXs>
        <Image
          alt={i18next.t(TK.REWARDS_TOP_BANNER)}
          src={rewardsTopBannerPhone}
          sx={styles.bannerImg}
        />
      </ShowUpXs>
      <MyAccount />
      <HideUpSm>
        <HideOnXs>
          <Image
            alt={i18next.t(TK.REWARDS_BOTTOM_BANNER)}
            src={rewardsBottomBannerDesktop}
            sx={styles.bannerImg}
          />
        </HideOnXs>
      </HideUpSm>
      <HideOnXs>
        <ShowUpSm>
          <Image
            alt={i18next.t(TK.REWARDS_BOTTOM_BANNER)}
            src={rewardsBottomBannerTablet}
            sx={styles.bannerImg}
          />
        </ShowUpSm>
      </HideOnXs>
      <ShowUpXs>
        <Image
          alt={i18next.t(TK.REWARDS_BOTTOM_BANNER)}
          src={rewardsBottomBannerPhone}
          sx={styles.bannerImg}
        />
      </ShowUpXs>
    </Box>
  );
};
