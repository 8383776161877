import {
  ApiLegacyBaseService,
  LEGACY_JSON_HEADERS,
} from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';

class LocaleLegacyApiService extends ApiLegacyBaseService {
  changeLocale(locale: string): AxiosPromise<DResponse> {
    return this.apibase.post(
      '/default.aspx/CultureChange',
      JSON.stringify({ culture: locale }),
      { headers: LEGACY_JSON_HEADERS },
    );
  }
}

export const localeLegacyApiService = new LocaleLegacyApiService();
