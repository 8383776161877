import { Type } from 'api/filter-categories/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { filterCategoriesApiService } from 'api/filter-categories/filter-categories.api.service';

export const fetchFilterCategories = createAsyncThunk(
  'filter-category/fetch',
  async () => {
    const { data } = await filterCategoriesApiService.getFilterCategories(
      Type.fruitBasketPage,
    );
    return data;
  },
);

export const fetchFruitFilters = createAsyncThunk(
  'fruit-filter/fetch',
  async () => {
    const { data } = await filterCategoriesApiService.getFruitFilters();
    return data;
  },
);
