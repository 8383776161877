import { Status } from 'redux/types/state.interface';
import { createConciergeInfo } from 'components/BusinessGifting/ContactDialog/feature/actions';
import { createSlice } from '@reduxjs/toolkit';

type State = { status: Status };
const initialState: State = {
  status: Status.INIT,
};
export const slice = createSlice({
  name: 'business-gifting/event-info',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createConciergeInfo.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(createConciergeInfo.fulfilled, (state) => {
        state.status = Status.FULFILLED;
      })
      .addCase(createConciergeInfo.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
  },
});

export const businessGiftingEventInfoReducer = slice.reducer;
export const { reset } = slice.actions;
