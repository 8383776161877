import * as TK from 'components/PDP/Details/components/PasControl/components/StoreSelector/translations/locale.constants';

export const storeListCommonLocale = {
  [TK.CURBSIDE]: 'Curbside',
  [TK.DISTANCE]: 'distance',
  [TK.KOSHER]: 'Kosher',
  [TK.MILES]: '~{{miles}} miles',
  [TK.SMOOTHIE]: 'Smoothie',
  [TK.TITLE_PICKUP]: 'Select local pickup shop',
  [TK.TITLE_DELIVERY]: 'Select shop for delivery',
  [TK.SHOW_MORE]: 'Show {{count}} More',
  [TK.MONDAY_FULL]: 'Monday',
  [TK.TUESDAY_FULL]: 'Tuesday',
  [TK.WEDNESDAY_FULL]: 'Wednesday',
  [TK.THURSDAY_FULL]: 'Thursday',
  [TK.FRIDAY_FULL]: 'Friday',
  [TK.SATURDAY_FULL]: 'Saturday',
  [TK.SUNDAY_FULL]: 'Sunday',
  [TK.MONDAY_SHORT]: 'Mon',
  [TK.TUESDAY_SHORT]: 'Tue',
  [TK.WEDNESDAY_SHORT]: 'Wed',
  [TK.THURSDAY_SHORT]: 'Thu',
  [TK.FRIDAY_SHORT]: 'Fri',
  [TK.SATURDAY_SHORT]: 'Sat',
  [TK.SUNDAY_SHORT]: 'Sun',
  [TK.ERROR_NO_STORES]:
    'Oops, we can not suggest you any of our store at the moment. Please select another date or change the fulfillment method.',
  [TK.ERROR_DELIVERY_CUTOFF_PASSED]:
    'Oops, the delivery cutoff time has passed for today. Please select another date.',
  [TK.ERROR_PICKUP_CUTOFF_PASSED]:
    'Oops, the pickup cutoff time has passed for today. Please select another date.',
  [TK.ERROR_PICKUP_DATE_IN_PAST]:
    'Oops, the pickup date may not be in the past. Please select another date.',
  [TK.ERROR_SAME_DAY_SHIPMENT]:
    'Oops, we currently do not offer same day shipping, please select another date.',
  [TK.ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED]:
    'Oops, Next Day shipment is no longer available today, please select another date.',
  [TK.ERROR_SIZE_IS_NOT_AVAILABLE]:
    "We're sorry, the option you selected is not available at this store. Please select a different size or date.",
  [TK.ERROR_STORE_IS_CLOSED]:
    "We're sorry, this store is currently closed. Please select another zip or date.",
  [TK.ERROR_PICKUP_CUTOFF_DATE]:
    "We're sorry but the store you’ve selected does not accept same day pickup orders after {{time}}. Please select a different date or another store.",
  [TK.ERROR_BEFORE_MINIMUM_PICKUP_TIME]:
    'Oops, the pickup time selected is not available. Please choose a time after {{time}} or contact the store.',
  [TK.ERROR_SERVICING_STORE_CLOSED]:
    "We're sorry but the store you’ve selected is closed on {{date}}. Please select another date.",
  [TK.ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER]:
    "We're sorry but the store you’ve selected cannot complete the order for {{date}}. Please select another date.",
  [TK.ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
    "We're sorry, delivery is currently not available on {{date}} from the store you selected {{store}}. Please select another date.",
  [TK.ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE]:
    "We're sorry, pick up is currently not available on {{date}} from the store you selected {{store}}. Please selected another date.",
};
