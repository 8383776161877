import { ArrangementGroupTarget } from 'enums/arrangement-group-targets';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupsApiService } from 'api/arrangement-group.api.service';

export const fetchOccasions = createAsyncThunk(
  'fetch/occasions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await groupsApiService.getOccasions(
        ArrangementGroupTarget.EA,
      );
      return data;
    } catch (e) {
      return rejectWithValue('');
    }
  },
);
