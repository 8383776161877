import { Event, PageType } from 'service/adobe-analytics/enums';
import { analytics } from 'service/adobe-analytics/analytics';

export const trackCityClicked = (text: string): void => {
  analytics.digitalDataPushList({
    event: Event.CityClicked,
    text,
  });

  analytics.localSeoPageData(
    `Local SEO City Page - ${text}`,
    PageType.LocalSeoStatePage,
  );
};

export const trackStateClicked = (text: string): void => {
  analytics.digitalDataPushList({
    event: Event.StateClicked,
    text,
  });

  analytics.localSeoPageData(
    `Local SEO State Page - ${text}`,
    PageType.LocalSeoStatePage,
  );
};
