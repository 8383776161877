import * as styles from 'components/Home/VideoText/styles';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { TextPosition } from 'components/Home/VideoText/types';
import { checkForFrench } from 'components/Home/utils';
import React, { FC } from 'react';

interface Props {
  videoTextItemSrc: string;
  videoTextItemFrSrc?: string;
  videoTextItemAltText?: string;
  videoTextItemFrAltText?: string;
  videoTextItemTitle?: string;
  videoTextItemFrTitle?: string;
  videoTextItemDesc?: string;
  videoTextItemFrDesc?: string;
  videoTextItemButtonText?: string;
  videoTextItemFrButtonText?: string;
  videoTextItemButtonLink?: string;
  videoTextItemFrButtonLink?: string;
  videoTextItemTextPosition?: string;
  videoTextItemImageToggle: boolean;
  handleAnalyticsClick?: () => void;
}

export const VideoWithText: FC<Props> = ({
  videoTextItemSrc = '',
  videoTextItemFrSrc = '',
  videoTextItemAltText = '',
  videoTextItemFrAltText = '',
  videoTextItemTitle = '',
  videoTextItemFrTitle = '',
  videoTextItemDesc = '',
  videoTextItemFrDesc = '',
  videoTextItemButtonText = '',
  videoTextItemFrButtonText = '',
  videoTextItemButtonLink = '',
  videoTextItemFrButtonLink = '',
  videoTextItemTextPosition,
  videoTextItemImageToggle,
  handleAnalyticsClick,
}) => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('sm'));

  let textAlignment;
  let videoAlignment;

  if (isUpMd) {
    const textContainerPosition = !!(
      videoTextItemTextPosition === TextPosition.CmsDesktopTextPosition
    );
    if (textContainerPosition) {
      textAlignment = { right: 0 };
      videoAlignment = { left: 0 };
    }

    if (!textContainerPosition) {
      textAlignment = { left: 0 };
      videoAlignment = { right: 0 };
    }
  }

  if (!isUpMd) {
    const textContainerPosition = !!(
      videoTextItemTextPosition === TextPosition.CmsMobileTextPosition
    );
    if (textContainerPosition) {
      textAlignment = { bottom: 0 };
      videoAlignment = { top: 0 };
    }

    if (!textContainerPosition) {
      textAlignment = { top: 0 };
      videoAlignment = { bottom: 0 };
    }
  }

  return (
    <>
      {videoTextItemTitle ? (
        <Box sx={styles.wrapper}>
          <Box sx={styles.videoTextContainer} style={textAlignment}>
            <Typography sx={styles.titleText} variant="h5">
              {checkForFrench(videoTextItemTitle, videoTextItemFrTitle)}
            </Typography>
            <Typography sx={styles.description} variant="body1">
              {checkForFrench(videoTextItemDesc, videoTextItemFrDesc)}
            </Typography>
            {checkForFrench(
              videoTextItemButtonText,
              videoTextItemFrButtonText,
            ) && (
              <Link
                href={checkForFrench(
                  videoTextItemButtonLink,
                  videoTextItemFrButtonLink,
                )}
                target="_blank"
                sx={styles.learnMoreButton}
                data-test="video-btn"
                onClick={handleAnalyticsClick}
              >
                <Typography>
                  {checkForFrench(
                    videoTextItemButtonText,
                    videoTextItemFrButtonText,
                  )}
                </Typography>
              </Link>
            )}
          </Box>
          <Box sx={styles.videoWrapper} style={videoAlignment}>
            <Box>
              {videoTextItemImageToggle ? (
                <Image
                  src={checkForFrench(videoTextItemSrc, videoTextItemFrSrc)}
                  alt={checkForFrench(
                    videoTextItemAltText,
                    videoTextItemFrAltText,
                  )}
                />
              ) : (
                <video
                  title={checkForFrench(
                    videoTextItemAltText,
                    videoTextItemFrAltText,
                  )}
                  width="100%"
                  height="100%"
                  muted
                  playsInline
                  loop
                  autoPlay
                  controls
                >
                  <source
                    src={checkForFrench(videoTextItemSrc, videoTextItemFrSrc)}
                    type="video/mp4"
                  />
                </video>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.wrapperWithoutText}>
          <Box sx={styles.videoContainerWitoutText}>
            <Box sx={styles.videoWrapper}>
              <Box>
                {videoTextItemImageToggle ? (
                  <Image
                    src={checkForFrench(videoTextItemSrc, videoTextItemFrSrc)}
                    alt={checkForFrench(
                      videoTextItemAltText,
                      videoTextItemFrAltText,
                    )}
                  />
                ) : (
                  <video
                    title={checkForFrench(
                      videoTextItemAltText,
                      videoTextItemFrAltText,
                    )}
                    width="100%"
                    height="100%"
                    muted
                    playsInline
                    loop
                    autoPlay
                    controls
                  >
                    <source
                      src={checkForFrench(videoTextItemSrc, videoTextItemFrSrc)}
                      type="video/mp4"
                    />
                  </video>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

VideoWithText.defaultProps = {
  videoTextItemTextPosition: '',
};
