import { BannerAreaTitle, Target } from 'api/banner/types';
import { bannerService } from 'api/banner/banner.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const getSkinny = createAsyncThunk(
  'banner/skinny',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await bannerService.find({
        countryId: 1,
        bannerAreaTitle: BannerAreaTitle.SkinnyBanner,
        target: Target.Edible,
      });
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
