import * as styles from 'components/Home/BannerProductCarousel/components/Skeleton/styles';
import {
  CarouselContainer,
  Container,
} from 'components/Home/BannerProductCarousel/components/Skeleton/styles';
import { CarouselItemSkeleton } from 'components/Home/BannerProductCarousel/components/Skeleton/CarouselItemSkeleton';
import { Headline } from 'components/Home/BannerProductCarousel/styles';
import { Skeleton } from '@mui/material';
import { range } from 'utils/range';
import { settings } from 'components/Home/BannerProductCarousel/settings';
import React, { FC } from 'react';
import Slider from 'react-slick';

export const BannerProductCarouselSkeleton: FC = () => (
  <>
    <Skeleton variant="rectangular" sx={styles.skeleton} />
    <Container>
      <Headline>
        <Skeleton sx={styles.title} variant="rectangular" />
      </Headline>
      <CarouselContainer data-test="carousel-detailed">
        <Slider {...settings}>
          {range(9).map((rangeItem) => (
            <CarouselItemSkeleton key={rangeItem} />
          ))}
        </Slider>
      </CarouselContainer>
    </Container>
  </>
);
