import {
  FOOTER_APP_STORES_APP_STORE_ALT,
  FOOTER_APP_STORES_APP_STORE_ARIA_LABEL,
  FOOTER_APP_STORES_GOOGLE_PLAY_ALT,
  FOOTER_APP_STORES_GOOGLE_PLAY_ARIA_LABEL,
  FOOTER_FACEBOOK_ARIA_LABEL,
  FOOTER_INSTAGRAM_ARIA_LABEL,
  FOOTER_PINTEREST_ARIA_LABEL,
  FOOTER_TWITTER_ARIA_LABEL,
  FOOTER_YOUTUBE_ARIA_LABEL,
} from 'components/Footer/translations/locale.constants';

export const footerCommonLocale = {
  [FOOTER_APP_STORES_GOOGLE_PLAY_ALT]: 'Google Play logo',
  [FOOTER_APP_STORES_APP_STORE_ALT]: 'App Store logo',
  [FOOTER_APP_STORES_GOOGLE_PLAY_ARIA_LABEL]: 'Get it on Google Play',
  [FOOTER_APP_STORES_APP_STORE_ARIA_LABEL]:
    'Download on the AppContainer Store',
  [FOOTER_FACEBOOK_ARIA_LABEL]: 'Follow Edible on Facebook',
  [FOOTER_INSTAGRAM_ARIA_LABEL]: 'Follow Edible on Facebook',
  [FOOTER_PINTEREST_ARIA_LABEL]: 'Follow Edible on Pinterest',
  [FOOTER_TWITTER_ARIA_LABEL]: 'Follow Edible on Twitter',
  [FOOTER_YOUTUBE_ARIA_LABEL]: 'Follow Edible on YouTube',
};
