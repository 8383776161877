import * as TK from 'components/PDP/Order/RecipientForm/components/Contacts/translations/constants';

export const orderContactsCommonLocale = {
  [TK.ORDER_CONTACTS_HEADER]: 'Recipient Contact Information',
  [TK.ORDER_CONTACTS_ALERT]:
    'Please provide at least one Recipient phone number, we will use this number in case of delivery complications',
  [TK.ORDER_CONTACTS_ADD_BUTTON]: 'Home And/Or Work Phone',
  [TK.ORDER_CONTACTS_HOME_PHONE]: 'Home Phone',
  [TK.ORDER_CONTACTS_CELL_PHONE]: 'Recipient Phone*',
  [TK.ORDER_CONTACTS_WORK_PHONE]: 'Work Phone',
  [TK.ORDER_CONTACTS_EMAIL_LABEL]: 'Recipient Email',
};
