import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { SubscriptionState } from 'components/Subscription/feature/slice';

export const getContent = (state: RootState): SubscriptionState =>
  state.subscription;

export const getEmailSubscriptionStatus = (state: RootState): Status =>
  getContent(state).emailStatus;

export const getPhoneSubscriptionStatus = (state: RootState): Status =>
  getContent(state).phoneStatus;
