import { Area } from 'api/area/types/area.interface';
import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { getArea } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.actions';

const initialState = InitialStateFactory.createNullable<Area>();

const slice = createSlice({
  name: 'pdp/area-availability',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: createExtraReducers(initialState, getArea),
});

export const { reducer: zipAvailabilityReducer } = slice;
export const zipAvailability = slice.actions;
