export const UPSELLS_DECREASE_QUANTITY = 'upsell.option.decrease_quantity';
export const UPSELLS_INCREASE_QUANTITY = 'upsell.option.increase_quantity';
export const UPSELLS_REMOVE = 'upsell.remove';
export const UPSELLS_ADD = 'upsell.add';
export const UPSELLS_CONTINUE = 'upsell.continue';
export const UPSELLS_SAVE_SELECTION = 'upsell.save_selection';
export const UPSELLS_TITLE = 'upsell.title';
export const UPSELLS_OPTIONS_SINGLE = 'upsell.options.single';
export const UPSELLS_OPTIONS_OPTION = 'upsell.options.option';
export const UPSELL_OPTIONS_STARTING_AT = 'upsell.options.starting_at';
export const UPSELL_OPTIONS_SHOW_ALL = 'upsell.options.show_all';
export const UPSELL_OPTIONS_EXPANDED_SINGLE = 'upsell.options.expanded.single';
export const UPSELL_OPTIONS_EXPANDED_SELECT_ONE =
  'upsell.options.expanded.select_one';
export const UPSELL_OPTIONS_EXPANDED_SELECT_MULTIPLE =
  'upsell.options.expanded.select_multiple';
export const UPSELL_OPTIONS_EXPANDED_SHOW_LESS =
  'upsell.options.expanded.show_less';
export const UPSELL_OPTIONS_WARNING = 'upsell.options.warning';
export const UPSELL_OPTIONS_NOTICE = 'upsell.options.notice';
export const UPSELL_SHOWING = 'upsell.options.showing';
export const UPSELL_SHOW = 'upsell.options.show';
export const UPSELL_SHOW_MORE = 'upsell.options.show_more';
export const UPSELL_OPTIONS_WARNING_DESIGN_VARY =
  'upsell.options.warning.design_vary';
export const UPSELL_OPTIONS_PRODUCT_BADGE_LABEL =
  'upsell.options.product_badge_label';
