import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import {
  CaseCategory,
  CaseCategoryParam,
} from 'redux/case/caseCategory/case.types';
import {
  CaseItem,
  CaseOrderParam,
  CaseParams,
} from 'redux/case/caseOrder/case-order.types';

class CaseApiService extends ApiBaseService {
  getCaseCategoryType({
    caseCategoryId,
    isRefund,
  }: CaseCategoryParam): AxiosPromise<CaseCategory[]> {
    return this.apibase.get<CaseCategory[]>('cases/category-types', {
      params: {
        caseCategoryId,
        inactive: false,
        isRefund,
      },
    });
  }

  save(data: CaseParams): AxiosPromise<string> {
    return this.apibase.post<string>('cases', data);
  }

  getDuplicateCase(data: CaseOrderParam): AxiosPromise<CaseItem[]> {
    return this.apibase.post<CaseItem[]>('cases/check-duplicates', data);
  }
}

const caseApiService = new CaseApiService();

export { caseApiService };
