export const COMPLIMENTARY_GIFT_MESSAGE =
  'greetings_cards.complimentary_gift_message';
export const COMPLIMENTARY_MESSAGE_TITLE =
  'greetings_cards.complimentary_message.title';
export const OR = 'greetings_cards.or';
export const REMOVE_GIFT_MESSAGE = 'greetings_cards.remove_card_message';
export const GIFT_MESSAGE_FREE_EXPLAIN =
  'greetings_cards.gift_message_free_explain';
export const HEARTFELT_MESSAGE = 'greetings_cards.heartfelt_message';
export const TOP_PICK = 'greetings_cards.top_pick';
export const OPTIONAL = 'greetings_cards.optional';
export const PRINTIBLE_SUBTITLE = 'greetings_cards.printible_subtitle';
export const PRINTIBLE_OLD_PRICE_TEXT =
  'greetings_cards.printible_old_price_text';
export const PRINTIBLE_PRICE_SUBTEXT =
  'greetings_cards.printible_price_subtext';
export const PREMIUM_CARD = 'greetings_cards.premium_card';
export const NO_GIFT_MESSAGE = 'greetings_cards.no_gift_message';
export const SELECT_NEW_CARD = 'greetings_cards.select_new_card';

export const REMOVE_CARD = 'greetings_cards.remove_card';
export const GIFT_MESSAGE_FREE_HINT = 'greetings_cards.gift_message_free_hint';
export const ENTER_CARD_MESSAGE = 'greetings_cards.enter_card_message';
export const PRINTIBLE_CARD_PREVIEW = 'greetings_cards.card_preview';
export const PRINTIBLE_APP = 'greetings_cards.printible_app';
export const PRINTIBLE_IFRAME_ERROR = 'greetings_cards.printible_iframe_error';
export const PRINTIBLE_PRICE_TEXT = 'greetings_cards.printible_price_text';
export const COMPLIMENTARY_CARD = 'greetings_cards.complimentary_card';
export const PRINTIBLE_CARDS = 'greetings_cards.printible_cards';
export const GIFT_CARD_MODAL_TITLE = 'gift_card_modal.title';
export const GIFT_CARD_MODAL_SUB_TITLE = 'gift_card_modal.sub_title';
export const GIFT_CARD_MODAL_GIFT_MESSAGE = 'gift_card_modal.gift_message';
export const GIFT_CARD_MODAL_KEEPSAKE_CHERISH =
  'gift_card_modal.keepsake_cherish';
export const GIFT_CARD_MODAL_YEAR_COME = 'gift_card_modal.year_come';
export const WRITE_FOR_ME_NAME = 'write_for_me.name';
export const ANANYMOUSLY_SWITCH_LABEL = 'write_for_me.ananymously.switch.label';
export const WRITE_FOR_ME_NAME_NOTE = 'write_for_me.name.note';
export const WRITE_FOR_ME_CREATE_BUTTON = 'write_for_me.create_button';
export const WRITE_FOR_ME_START_NEW_BUTTON = 'write_for_me.start_new_button';
export const GIFT_MESSAGE_TABS_LABEL = 'write_for_me.tabs_label';
export const GIFT_MESSAGE_FIRST_TAB_LABEL = 'write_for_me.first_tab_label';
export const GIFT_MESSAGE_SECOND_TAB_LABEL = 'write_for_me.second_tab_label';
