import { Link } from 'components/BreadcrumbBack/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: { history: Link[] } = {
  history: [],
};

export const slice = createSlice({
  name: 'link-history',
  initialState,
  reducers: {
    push: (state, { payload }: PayloadAction<Link>) => {
      state.history = [...state.history, payload];
    },
    pop: (state) => {
      state.history.pop();
    },
  },
});

export const breadcrumbHistoryReducer = slice.reducer;
export const { push, pop } = slice.actions;
