import { OrderType } from 'types/cart.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatToLegacyDateString } from 'utils/date';
import { productApiService } from 'api/products/product.api.service';

type Params = {
  orderType: OrderType;
  date: Date;
  storeId: number;
  productId: number;
};
export const fetchUpgrades = createAsyncThunk(
  'pdp/fetch/upgrades',
  async ({ orderType, date, storeId, productId }: Params) => {
    const { data } = await productApiService.getUpgrades(productId, {
      store: storeId,
      date: formatToLegacyDateString(date),
      orderType,
    });
    return data;
  },
);
export const fetchAddons = createAsyncThunk(
  'pdp/fetch/addons',
  async ({ orderType, date, storeId, productId }: Params) => {
    const { data } = await productApiService.getAddons(productId, {
      store: storeId,
      date: formatToLegacyDateString(date),
      orderType,
    });
    return data;
  },
);
