import { CalendarPicker } from '@mui/x-date-pickers';
import { SxProps } from '@mui/system';
import { Theme, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { theme } from 'utils/theme/theme';

export const StyledCalendar = styled(CalendarPicker)({
  margin: 0,
  width: '100%',
  maxHeight: 'unset',

  '& .PrivatePickersFadeTransitionGroup-root': {
    fontWeight: 600,
    fontSize: 14,
  },

  '& .PrivatePickersFadeTransitionGroup-root + button, .MuiIconButton-edgeStart, .MuiIconButton-edgeEnd':
    {
      color: theme.palette.secondary.main,

      '&.Mui-disabled': {
        color: theme.palette.action.disabled,
      },
    },

  '& .PrivatePickersSlideTransition-root': {
    minHeight: '254px',
  },
  '& [role="row"]': {
    justifyContent: 'space-between',
  },
  '& .MuiTypography-caption': {
    width: 45,

    '&:not(:last-child)': {
      marginRight: 'auto',
    },
  },
});
export const FloralSaturdayDayPrice = (isHovered: boolean): SxProps<Theme> => ({
  fontSize: '10px',
  fontWeight: 400,
  width: '32px',
  marginTop: '-4px',
  marginLeft: '-8px',
  borderRadius: '50px',
  border: isHovered ? '1px solid  #FFF' : '1px solid  rgba(225, 6, 0, 0.08)',
  backgroundColor: isHovered ? '#E10600' : '#FFE5E5',
  position: 'absolute',
});
export const pickersDay: SxProps<Theme> = () => ({
  height: 40,
  width: 40,
  borderRadius: '50%',
  lineHeight: '22px',
  margin: '3px',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  ...theme.typography.subtitle2,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  '&.MuiPickersDay-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },

  '&.MuiPickersDay-today': {
    ...theme.typography.subtitle2,
    position: 'relative',
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '::before': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&::before': {
      content: "''",
      display: 'block',
      width: 4,
      height: 4,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      bottom: 5,
      borderRadius: '100px',
    },
    '&.Mui-selected': {
      color: theme.palette.common.white,
      '::before': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },

  '&.MuiPickersDay-dayOutsideMonth': {
    '&, &.MuiPickersDay-today, &.MuiPickersDay-today::before, &.Mui-selected': {
      color: 'transparent',
      background: 'transparent',
      borderColor: 'transparent',
      transition: 'none',
    },
  },
  '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover': {
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,
  },
});

export const floralPartyBoxPickersDay: SxProps<Theme> = () => ({
  height: 40,
  width: 40,
  borderRadius: '50%',
  lineHeight: '22px',
  margin: '3px',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  ...theme.typography.subtitle2,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  '&.MuiPickersDay-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },

  '&.MuiPickersDay-today': {
    ...theme.typography.subtitle2,
    position: 'relative',
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '::before': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&::before': {
      content: "''",
      display: 'block',
      width: 4,
      height: 4,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      bottom: 5,
      borderRadius: '100px',
    },
    '&.Mui-selected': {
      color: theme.palette.common.white,
      '::before': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },

  '&.MuiPickersDay-dayOutsideMonth': {
    '&, &.MuiPickersDay-today, &.MuiPickersDay-today::before, &.Mui-selected': {
      color: 'transparent',
      background: 'transparent',
      borderColor: 'transparent',
      transition: 'none',
    },
  },
  '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover': {
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.main,
  },
});
