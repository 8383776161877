import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectors } from 'redux/factories/create-selectors';
import { isEmpty } from 'utils/is-empty';
import { isError, isFulfilled } from 'utils/status.comparer';

export const {
  selectData: selectStores,
  selectError,
  selectIsFulfilled,
  selectIsPending,
} = createSelectors(({ stores }) => stores);

export const selectAreStoresUnavailable = createSelector(
  (state: RootState) => state.stores,
  ({ data, status }) =>
    (isFulfilled(status) && isEmpty(data)) || isError(status),
);
