import { SxProps } from '@mui/material';

export const container: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  boxSizing: 'border-box',
  maxWidth: '100%',
  flexWrap: 'wrap',
};

export const cardContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #C9C9C9',
  borderTopLeftRadius: '1.25rem',
  borderBottomLeftRadius: '1.25rem',
  width: 'fit-content',
  height: '2.5rem',
  backgroundColor: 'white',
};

export const imageContainer: SxProps = {
  border: '1px solid #C9C9C9',
  borderRadius: '50%',
  backgroundColor: 'white',
  padding: '.5rem',
};

export const textContainer: SxProps = {
  paddingInlineStart: '0.375rem',
  paddingInlineEnd: '0.625rem',
  whiteSpace: 'nowrap',
};
