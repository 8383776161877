import { SxProps } from '@mui/system';

export const closeBtn: SxProps = {
  position: 'absolute',
  right: 25,
  top: 35,
  p: 0,
};

export const container: SxProps = {
  width: 1,
  maxWidth: [388, 388, 580],
  mx: 'auto',
  textAlign: 'center',
};

export const backButton: SxProps = {
  position: 'absolute',
  left: 25,
  top: 35,
  p: 0,
};
