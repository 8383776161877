import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Header/Menus/AccountMenu/styles';
import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { Logout } from '@mui/icons-material';
import { MenuButton } from 'components/Header/Menus/AccountMenu/MenuButton';
import { analytics } from 'service/adobe-analytics/analytics';
import { logOut } from 'redux/auth/log-out.action';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
  selectUserName,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useMenu } from 'hooks/useMenu';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { FC, memo, useEffect } from 'react';
import i18next from 'i18next';

const userMenuList = [
  {
    title: TK.HEADER_USER_PROFILE_MY_REWARDS,
    link: HeaderLinks.MY_ACCOUNT,
    divider: false,
  },
  {
    title: TK.HEADER_USER_PROFILE_FAQ,
    link: HeaderLinks.PROFILE_FAQ,
    divider: true,
  },
  {
    title: TK.HEADER_USER_PROFILE_MY_INFORMATION,
    link: HeaderLinks.PROFILE_MY_INFORMATION,
    divider: false,
  },
  {
    title: TK.HEADER_USER_PROFILE_ORDER_HISTORY,
    link: HeaderLinks.PROFILE_ORDER_HISTORY,
    divider: false,
  },
  {
    title: TK.HEADER_USER_PROFILE_GIFT_REMINDERS,
    link: HeaderLinks.PROFILE_GIFT_REMINDERS,
    divider: false,
  },
  {
    title: TK.HEADER_USER_PROFILE_ADDRESS_BOOK,
    link: HeaderLinks.PROFILE_ADDRESS_BOOK,
    divider: true,
  },
];

export const AuthUserMenu: FC = memo(() => {
  useTranslation();
  const { anchorEl, open, handleClick, handleClose } = useMenu();

  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);

  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const onLogoutClick = () => {
    handleClose();
    dispatch(logOut());
    analytics.headerMenuItemClicked(
      i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_OUT),
    );
    segment.menuItemClicked(
      i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_OUT),
      '',
      userSession,
      userAccountInformation,
    );
  };

  const getFormattedUserName = () => {
    const [firstName] = userName?.split(' ').filter(Boolean) || [];

    return firstName || userName || i18next.t(TK.HEADER_USER_PROFILE_ACCOUNT);
  };

  useEffect(() => {
    if (anchorEl) {
      analytics.headerMenuItemClicked(userName);
      segment.menuItemClicked(
        userName,
        '',
        userSession,
        userAccountInformation,
      );
    }
  }, [anchorEl, userName, handleClick]);

  return (
    <>
      <MenuButton
        onClick={handleClick}
        open={open}
        icon={<AccountCircleIcon />}
      >
        <Typography component="span" variant="subtitle2" sx={styles.userName}>
          {getFormattedUserName()}
        </Typography>
      </MenuButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'help-menu-button',
        }}
      >
        {userMenuList.map((listItem) => [
          <MenuItem
            key={listItem.link}
            onClick={() => {
              analytics.headerMenuItemClicked(i18next.t(listItem.title));
              segment.menuItemClicked(
                i18next.t(listItem.title),
                listItem.link,
                userSession,
                userAccountInformation,
              );
              handleClose();
            }}
            href={listItem.link}
            component="a"
          >
            <Typography variant="subtitle2">
              {i18next.t(listItem.title)}
            </Typography>
          </MenuItem>,
          listItem.divider && <Divider sx={styles.divider} />,
        ])}
        <MenuItem onClick={onLogoutClick}>
          <Typography variant="subtitle2" sx={styles.signOutButton}>
            <Logout sx={styles.signOutIcon} />
            {i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_OUT)}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
});
