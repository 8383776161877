/**
 * Value of this enum means the order in which steps should be enabled
 */
export enum AvailabilityStep {
  NOT_SELECTED = 0,
  OWN_BOX_ADDONS = 1,
  PRODUCT_OPTIONS = 2,
  ZIP_AVAILABILITY = 3,
  DATE_SELECTION = 4,
  DELIVERY_METHOD_SELECTION = 5,
  STORE_LIST_SELECTION = 6,
  CONTINUE = 7,
  SUGGESTED_DATE_SELECTION = 8,
  SUGGESTED_PRODUCT_SELECTION = 9,
  OWN_DESSERT_BOARD_BAKED = 10,
  OWN_DESSERT_BOARD_DIPPED_BERRIES = 11,
  OWN_DESSERT_BOARD_DIPPED_FRUIT = 12,
  DESSERT_OWN_BOX_ADDONS = 13,
  TITLE = 14,
  OWN_COOKIE_BOX_EVERYDAY = 15,
  OWN_COOKIE_BOX_LIMITED = 16,
  OWN_6_COOKIE_BOX = 17,
  OWN_12_COOKIE_BOX = 18,
}
