import * as TK from 'containers/PaymentContainer/translations/locale.constants';
import * as styles from 'components/Payment/BillingInformation/styles';
import { Box, Typography } from '@mui/material';
import { Props } from 'components/Payment/BillingInformation/props/strength-meter';
import {
  getPasswordStrength,
  getPasswordStrengthColor,
  getPasswordStrengthMeterColors,
} from 'components/Payment/BillingInformation/password-strength';
import React, { FC } from 'react';
import i18next from 'i18next';

export const StrengthMeter: FC<Props> = ({ password }) => {
  const passwordStrength = getPasswordStrength(password);
  const textColor = getPasswordStrengthColor(passwordStrength);
  const passwordStrengthColors =
    getPasswordStrengthMeterColors(passwordStrength);

  return (
    <Box sx={styles.passwordStrengthWrapper}>
      <Typography variant="body3" mr={1}>
        {i18next.t(TK.PASSWORD_STRENGTH)}
      </Typography>
      {passwordStrengthColors.map((color, i) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          bgcolor={color}
          sx={styles.passwordStrength}
        />
      ))}
      {password && (
        <Typography variant="body3" ml={1} color={textColor}>
          {passwordStrength}
        </Typography>
      )}
    </Box>
  );
};
