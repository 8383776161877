import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';

export const getOccasions = createSelector(
  ({ orderOccasions: { occasions } }: RootState) => occasions,
  (occasions) => occasions,
);
export const checkIsInitialized = createSelector(
  ({ orderOccasions: { status } }: RootState) => status,
  (status) => status !== Status.INIT,
);
export const checkIsBlocked = createSelector(
  ({ orderOccasions: { status } }: RootState) => status,
  (status) => isBlocked(status),
);
