import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { SessionLock } from 'enums/session-lock';
import { steps } from 'components/PDP/Details/components/PasControl/contants/steps';

export const lockStepMapper: Record<SessionLock, AvailabilityStep> = {
  [SessionLock.None]: steps[AvailabilityStep.NOT_SELECTED],
  [SessionLock.Recipient]: steps[AvailabilityStep.NOT_SELECTED],
  [SessionLock.Store]: steps[AvailabilityStep.NOT_SELECTED],
  [SessionLock.OrderType]: steps[AvailabilityStep.STORE_LIST_SELECTION],
  [SessionLock.ZipDate]: steps[AvailabilityStep.DELIVERY_METHOD_SELECTION],
};
