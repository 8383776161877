export const CONTACT_US_TEXT = 'contact_us.text';
export const CONTACT_US_SUCCESS_TEXT = 'contact_us_success.text';
export const CONTACT_US_ORDER_NUMBER_NOT_VALID_ERROR_TEXT =
  'contact_us.validation.ordernumber';
export const CONTACT_US_SAVE_ERROR =
  'contact_us.save.error.something_went_wrong';
export const CONTACT_US_FORM_LABEL_REASON = 'contact_us_form.reason';
export const CHOOSE_OPTION = 'contact_us_form.choose_option';
export const CONTACT_US_FORM_LABEL_REFUND_REASON =
  'contac_tus_form.refund_reason';
export const CONTACT_US_FORM_LABEL_ORDER = 'contact_us_form.order';
export const CONTACT_US_FORM_LABEL_FIRST_NAME = 'contact_us_form.first_name';
export const CONTACT_US_FORM_LABEL_LAST_NAME = 'contact_us_form.last_name';
export const CONTACT_US_FORM_LABEL_EMAIL = 'contact_us_form.email';
export const CONTACT_US_FORM_LABEL_PHONE = 'contact_us_form.phone';
export const CONTACT_US_FORM_LABEL_MESSAGE = 'contact_us_form.message';
export const CONTACT_US_FORM_LABEL_BEST_TIME_TO_CALL =
  'contact_us_form.best_time_to_call';
export const CONTACT_US_FORM_LABEL_PROVIDE_INFORMATION =
  'contact_us_form.provide_information';
export const CONTACT_US_GO_TO_FAQ_PAGE = 'contact_us.go_to_main_page';
export const CONTACT_US_SEND_REQUEST_BUTTON_TEXT = 'contact_us.send_request';
export const CONTACT_US_EMAIL_NOT_VALID_ERROR_TEXT =
  'contact_us.validation.email';
export const CONTACT_US_PHONE_NUMBER_NOT_VALID_ERROR_TEXT =
  'contact_us.validation.phone_number';
export const CONTACT_US_FORM_LABEL_HEADER_TEXT_LINE_01 =
  'contact_us_form.header_line01';
export const CONTACT_US_FORM_LABEL_HEADER_TEXT_LINE_02 =
  'contact_us_form.header_line02';
export const CONTACT_US_FORM_LABEL_HEADER_TEXT_REQUIRED =
  'contact_us_form.header_required';
export const CONTACT_US_FORM_LABEL_USER_TYPE_TEXT = 'contact_us_form.user_type';
export const CONTACT_US_FIRST_NAME_SPL_CHARS_ERROR_TEXT =
  'contact_us_form.first_name_spl_chars';
export const CONTACT_US_LAST_NAME_SPL_CHARS_ERROR_TEXT =
  'contact_us_form.last_name_spl_chars';
export const CONTACT_US_MSG_SPL_CHARS_ERROR_TEXT =
  'contact_us_form.msg_name_spl_chars';
export const CONTACT_US_REMOVE_PREFIX = 'contact_us_form.remove_prefix';
export const BACKTEXT = 'contact_us_form.back';
export const CONTINUESHOPPING = 'contact_us_form.continue_shopping';
export const CONTACT_US_MESSAGE_ERROR_TEXT =
  'contact_us_form.message_error_text';
export const CATEGORY_ERROR_TEXT = 'contact_us_form.category_error_text';
export const CATEGORY_TYPE_ERROR_TEXT =
  'contact_us_form.category_type_error_text';
export const REQUEST_BY_ERROR_TEXT = 'contact_us_form.request_by_error_text';
