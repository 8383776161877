import { ComponentType, HomePageContent } from 'components/Home/types';
import { HomePageState } from 'components/Home/feature/slice';
import { RootState } from 'redux/store';
import { Status } from 'enums/status';

export const getContent = (state: RootState): HomePageState =>
  state.homePageContent;

export const getStatus = (state: RootState): Status => getContent(state).status;

export const getHomePage = (state: RootState): HomePageContent =>
  getContent(state).data;

export const getComponents = (state: RootState): ComponentType[] =>
  getContent(state).data.content;

export const getComponent =
  (type: string, id: string) =>
  (state: RootState): ComponentType =>
    getComponents(state).find(
      (component) => component.type === type && component.id === id,
    )!;
