import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { Account } from 'components/Rewards/RewardLanding/Account/Account';
import { Faq } from 'components/Rewards/RewardLanding/Faq/Faq';
import { HideOnSm } from 'utils/hidden/hide-on-sm';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { MyRewards } from 'components/Rewards/RewardLanding/MyRewards/MyRewards';
import { OrderHistory } from 'components/Rewards/RewardLanding/OrderHistory/OrderHistory';
import { TabContext, TabList } from '@mui/lab';
import { Typography, useMediaQuery } from '@mui/material';
import { getSearchParams } from 'utils/getSearchParams';
import { theme } from 'utils/theme/theme';
import { updateActiveAccountTab } from 'components/Header/utils';
import Box from '@mui/material/Box';
import React, { FC, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import i18next from 'i18next';

export const MyAccount: FC = () => {
  const active = getSearchParams('active');
  const [value, setValue] = React.useState(active || '1');
  if (active === '') updateActiveAccountTab('1');
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (value) {
      updateActiveAccountTab(value);
    }
  }, [value]);
  return (
    <Box sx={styles.accountContainer}>
      <TabContext value={value}>
        <Box>
          <HideOnSm>
            <HideOnXs>
              <Typography variant="h5" mb={4} ml={2} mt={2}>
                {i18next.t(TK.MY_ACCOUNT)}
              </Typography>
            </HideOnXs>
          </HideOnSm>
          <TabList
            orientation={isMobileOrTablet ? 'horizontal' : 'vertical'}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={styles.tabListStyle}
            TabIndicatorProps={{
              style: {
                display: isMobileOrTablet ? 'block' : 'none',
                top: '40px',
              },
            }}
          >
            <Tab
              label={i18next.t(TK.MY_REWARDS)}
              sx={styles.linkStyle}
              value="1"
            />
            <Tab label={i18next.t(TK.FAQ)} sx={styles.linkStyle} value="2" />
            <Tab
              label={i18next.t(TK.ACCOUNT_PASSWORD)}
              sx={styles.linkStyle}
              value="3"
            />
            <Tab
              label={i18next.t(TK.ORDER_HISTORY)}
              sx={styles.linkStyle}
              value="4"
            />
          </TabList>
        </Box>
        <Box sx={styles.rewardContainer}>
          <TabPanel value="1">
            <MyRewards />
          </TabPanel>
          <TabPanel value="2">
            <Faq />
          </TabPanel>
          <TabPanel value="3">
            <Account />
          </TabPanel>
          <TabPanel value="4">
            <OrderHistory />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};
