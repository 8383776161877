import * as TRANSLATION_KEYS from 'translations/locales/translation-keys.constant';
import i18next from 'i18next';

const numberOfSizes = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  Ten: 10,
};

const TextSizeNumbers = {
  One: TRANSLATION_KEYS.NUMBER_OF_SIZES_ONE,
  Two: TRANSLATION_KEYS.NUMBER_OF_SIZES_TWO,
  Three: TRANSLATION_KEYS.NUMBER_OF_SIZES_THREE,
  Four: TRANSLATION_KEYS.NUMBER_OF_SIZES_FOUR,
  Five: TRANSLATION_KEYS.NUMBER_OF_SIZES_FIVE,
  Six: TRANSLATION_KEYS.NUMBER_OF_SIZES_SIX,
  Seven: TRANSLATION_KEYS.NUMBER_OF_SIZES_SEVEN,
  Eight: TRANSLATION_KEYS.NUMBER_OF_SIZES_EIGHT,
  Nine: TRANSLATION_KEYS.NUMBER_OF_SIZES_NINE,
  Ten: TRANSLATION_KEYS.NUMBER_OF_SIZES_TEN,
};

export type NumberOfSizesTypes =
  typeof numberOfSizes[keyof typeof numberOfSizes];

const getSizeNumbersByCount = (type: NumberOfSizesTypes): string =>
  ({
    [numberOfSizes.One]: TextSizeNumbers.One,
    [numberOfSizes.Two]: TextSizeNumbers.Two,
    [numberOfSizes.Three]: TextSizeNumbers.Three,
    [numberOfSizes.Four]: TextSizeNumbers.Four,
    [numberOfSizes.Five]: TextSizeNumbers.Five,
    [numberOfSizes.Six]: TextSizeNumbers.Six,
    [numberOfSizes.Seven]: TextSizeNumbers.Seven,
    [numberOfSizes.Eight]: TextSizeNumbers.Eight,
    [numberOfSizes.Nine]: TextSizeNumbers.Nine,
    [numberOfSizes.Ten]: TextSizeNumbers.Ten,
  }[type]);

export const getSizeDescription = (count: number): string => {
  if (count === 0) {
    return i18next.t(TRANSLATION_KEYS.NUMBER_OF_SIZES_NO_SIZES);
  }

  if (Object.values(numberOfSizes).includes(count)) {
    const sizeNumberKey = getSizeNumbersByCount(count);
    return count === numberOfSizes.One
      ? i18next.t(sizeNumberKey)
      : `${i18next.t(sizeNumberKey)} ${i18next.t(
          TRANSLATION_KEYS.NUMBER_OF_SIZES_STARTING_AT,
        )}`;
  }

  return `${count} ${i18next.t(
    TRANSLATION_KEYS.NUMBER_OF_SIZES_NUMERIC_QUANTITY,
  )}`;
};
