import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';

export const dialog: ThemedSxProps = {
  paddingLeft: '0px',
  borderRadius: '0px !important',
};
export const windowWrappercon: ThemedSxProps = {
  borderRadius: '0px !important',
  minWidth: { md: '700px', sm: '688px', xs: '360px' },
};

export const windowWrapper: ThemedSxProps = (theme) => ({
  borderRadius: theme.spacing(4, 4, 0, 0),
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(4),
    minWidth: '450px',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '700px',
  },
});

export const title: ThemedSxProps = {
  textAlign: 'right',
  padding: '0px !important',
};
export const closeBtn: ThemedSxProps = {
  position: 'absolute !important',
  marginLeft: '-50px',
  marginTop: { md: '20px', sm: '20px', xs: '15px' },
  height: { md: '24px', sm: '24px', xs: '18px' },
  width: { md: '24px', sm: '24px', xs: '18px' },
};

export const content: ThemedSxProps = (theme) => ({
  padding: theme.spacing(0, 0),
  textAlign: 'left',
  border: `${colors.primaryRed} 15px solid`,
});
export const icon: ThemedSxProps = (theme) => ({
  border: `1px solid ${theme.palette.divider}`,
  ':not(:first-of-type)': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
});

export const actions: ThemedSxProps = (theme) => ({
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2.5, 4, 4),
  },
});

export const button: ThemedSxProps = {
  borderRadius: 25,
  minHeight: '56px',
};

export const drawContent: ThemedSxProps = {
  border: `${colors.primaryRed} 15px solid`,
};
