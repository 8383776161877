import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { ProductType } from 'api/products/types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { isSelected } from 'components/PDP/Details/components/OwnBox/utils/is-selected';

export const toggleAddon = (
  selected: UpsellItem[],
  clicked: OwnBoxAddon,
): UpsellItem[] => {
  if (isSelected(selected, clicked)) {
    return selected.filter((item) => item.option.id !== clicked.id);
  }
  return [
    ...selected,
    {
      checked: true,
      option: clicked,
      productType: ProductType.AddOn,
      quantity: 0,
    },
  ];
};
