import { SxProps, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { theme } from 'utils/theme/theme';
import isPropValid from '@emotion/is-prop-valid';

export const MessageCounter = styled(Typography, {
  shouldForwardProp: isPropValid,
})(({ isError }: { isError: boolean }) => ({
  color: theme.palette.text.disabled,
  alignSelf: 'flex-end',
  ...(isError && {
    color: theme.palette.primary.main,
  }),
}));

export const textField: SxProps = {
  width: '100%',
  '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) > fieldset': {
    border: `2px solid ${theme.palette.secondary.main} `,
  },
  '& label.Mui-focused': {
    color: `${theme.palette.grey[500]}`,
  },
  '& > .MuiOutlinedInput-root': {
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '173px',
    p: [1.5, 2],
  },
  '& > .MuiOutlinedInput-root > textarea': {
    height: '120px',
    padding: 0,
    marginBottom: '25px',
  },
};

export const textFieldMessage: SxProps = {
  width: '100%',
  '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) > fieldset': {
    border: `2px solid ${theme.palette.secondary.main} `,
  },
  '& label.Mui-focused': {
    color: `${theme.palette.grey[500]}`,
  },
  '& > .MuiOutlinedInput-root': {
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '271px',
    p: [1.5, 2],
  },
  '& > .MuiOutlinedInput-root > textarea': {
    height: '214px',
    padding: 0,
    marginBottom: '25px',
  },
};

export const getInputAdornmentSx = (isWithAlerts: boolean): SxProps => ({
  height: '100%',
  position: 'absolute',
  left: 3,
  bottom: 10,
  display: 'flex',
  width: 'calc(100% - 20px)',
  justifyContent: isWithAlerts ? 'space-between' : 'flex-end',
  alignItems: 'flex-start',
});

export const inputAdornmentAlert: SxProps = {
  height: '30px',
};
