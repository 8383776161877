import { LegacyAreaClassificationType } from 'api/area/enums/legacy-area-classification-type.enum';
import { OrderType } from 'types/cart.types';
import { RootState } from 'redux/store';
import { SessionLock } from 'enums/session-lock';
import { createSelector } from '@reduxjs/toolkit';
import {
  isBlocked,
  isFinished,
  isFulfilled,
  isPending,
} from 'utils/status.comparer';
import { isPickup } from 'utils/is-order-type';
import { isPositive } from 'utils/numbers';

export const getIsPrintibleInOrder = ({ availabilitySession: a }: RootState) =>
  a.data.isPrintibleInOrder;

export const selectIsSessionFulfilled = createSelector(
  ({ availabilitySession: a }: RootState) => a.status,
  (s) => isFulfilled(s),
);

export const selectIsSessionFinished = createSelector(
  ({ availabilitySession: a }: RootState) => a.status,
  (s) => isFinished(s),
);

export const selectIsSessionBlocked = (state: RootState) =>
  isBlocked(state.availabilitySession.status);

export const selectIsSessionPending = (state: RootState) =>
  isPending(state.availabilitySession.status);

export const getPickupLockedFlags = createSelector(
  ({
    availabilitySession: {
      data: { cartRecipient },
    },
  }: RootState) => cartRecipient,
  (cartRecipient) => {
    const [color, model] = cartRecipient?.isCurbside
      ? cartRecipient?.recipientNote.carInfo.split('|')
      : [];

    return {
      firstName: Boolean(cartRecipient?.recipientContact.firstName),
      lastName: Boolean(cartRecipient?.recipientContact.lastName),
      phone: Boolean(cartRecipient?.recipientContact.phone),
      occasionId: Boolean(cartRecipient?.occasionId),
      note: Boolean(cartRecipient?.recipientNote.note),
      carColor: Boolean(color),
      carModel: Boolean(model),
    };
  },
);

export const getDeliveryLockedFlags = createSelector(
  ({
    availabilitySession: {
      data: { cartRecipient, currentRecipientId },
    },
  }: RootState) => ({ cartRecipient, currentRecipientId }),
  ({ cartRecipient, currentRecipientId }) => {
    const lock = Boolean(currentRecipientId && isPositive(currentRecipientId));

    return {
      firstName: lock && Boolean(cartRecipient?.recipientContact?.firstName),
      lastName: lock && Boolean(cartRecipient?.recipientContact?.lastName),
      address1: lock && Boolean(cartRecipient?.recipientContact?.address1),
      address2: lock && Boolean(cartRecipient?.recipientContact?.address2),
      cityId: lock && Boolean(cartRecipient?.recipientContact?.cityId),
      email: lock && Boolean(cartRecipient?.recipientContact?.recipientEmail),
      phone: lock && Boolean(cartRecipient?.recipientContact?.phone),
      cellPhone: lock && Boolean(cartRecipient?.recipientContact?.cellPhone),
      workPhone: lock && Boolean(cartRecipient?.recipientContact?.workPhone),
      occasionId: lock && Boolean(cartRecipient?.occasionId),
      note: lock && Boolean(cartRecipient?.recipientNote?.note),
      addressType:
        lock && Boolean(cartRecipient?.recipientContact?.addressType),
      company: lock && Boolean(cartRecipient?.recipientContact.company),
      instruction: lock && Boolean(cartRecipient?.recipientNote.note),
    };
  },
);

export const selectSessionArea = createSelector(
  (state: RootState) => state.availabilitySession.data,
  (session) => ({
    id: session.areaId,
    name: session.areaName,
    availability: {
      delivery: true,
      pickup: true,
      shipment: true,
    },
    cities: [],
    classification: LegacyAreaClassificationType.NotSpecified,
    countryCode: '',
    culture: '',
    state: '',
    stateCode: '',
  }),
);

export const selectSessionStore = createSelector(
  (state: RootState) => state.availabilitySession.data,
  (session) => ({
    id: session.storeId,
    number: session.storeNumber,
    address: session.storeAddress,
    areaName: session.areaName,
    phone: session.storePhone,
    city: '',
    stateCode: '',
    schedule: [],
    curbside: false,
    closingTime: null,
    openingTime: null,
    managedType: null,
  }),
);

export const selectSessionLockType = createSelector(
  (state: RootState) => state.availabilitySession.data,
  (session) => {
    return (
      (isPositive(session.currentRecipientId) && SessionLock.Recipient) ||
      (isPositive(session.storeId) && SessionLock.Store) ||
      (session.serviceOption && SessionLock.OrderType) ||
      (session.selectedDate && SessionLock.ZipDate) ||
      SessionLock.None
    );
  },
);

export const selectIsStoreOrRecipientLock = createSelector(
  selectSessionLockType,
  (type) => [SessionLock.Store, SessionLock.Recipient].includes(type),
);

export const selectSessionOrderType = (state: RootState) =>
  state.availabilitySession.data.serviceOption;

export const selectIsFullLock = createSelector(
  selectSessionOrderType,
  (orderType) => [OrderType.Delivery, OrderType.Pickup].includes(orderType),
);

export const selectIsLockExist = createSelector(
  selectSessionLockType,
  (type) => type !== SessionLock.None,
);

export const selectIsStoreLock = createSelector(
  selectSessionLockType,
  (type) => type === SessionLock.Store,
);

export const selectIsOrderTypeLock = createSelector(
  selectSessionLockType,
  (type) => type === SessionLock.OrderType,
);

export const selectIsRecipientLock = createSelector(
  selectSessionLockType,
  (type) => type === SessionLock.Recipient,
);

export const selectIsStoreLocked = createSelector(
  (state: RootState) => state.availabilitySession.data.storeId,
  (storeId) => isPositive(storeId),
);

export const selectIsPickupStoreLocked = createSelector(
  [selectIsStoreLocked, selectSessionOrderType],
  (isStoreLocked, serviceOption) => isStoreLocked && isPickup(serviceOption),
);

export const selectSessionStoreId = (state: RootState) =>
  state.availabilitySession.data.storeId;

export const selectSessionStoreName = (state: RootState) =>
  state.availabilitySession.data.storeName;

export const selectSessionAreaName = (state: RootState) =>
  state.availabilitySession.data.areaName;

export const selectSessionAreaId = (state: RootState) =>
  state.availabilitySession.data.areaId;

export const selectSessionSelectedDate = createSelector(
  (state: RootState) => state.availabilitySession.data.selectedDate,
  (date) => (date ? new Date(date) : null),
);

export const selectSessionFulfillmentDate = createSelector(
  (state: RootState) => state.availabilitySession.data.fulfillmentDate,
  (date) => (date ? new Date(date) : null),
);

export const selectRecipientName = createSelector(
  (state: RootState) =>
    state.availabilitySession.data.cartRecipient?.recipientContact.firstName,
  (state: RootState) =>
    state.availabilitySession.data.cartRecipient?.recipientContact.lastName,
  (firstName, lastName) =>
    `${firstName ? `${firstName} ` : ''}${lastName ?? ''}`,
);

export const selectAvailability = createSelector(
  (state: RootState) => state.availabilitySession.data,
  (availability) => availability,
);

export const selectIsAvailabilitySet = createSelector(
  selectAvailability,
  ({ isSet }) => isSet,
);

export const selectSessionDate = createSelector(
  selectIsRecipientLock,
  selectSessionFulfillmentDate,
  selectSessionSelectedDate,
  (isRecipientLock, fulfillmentDate, selectedDate) =>
    isRecipientLock ? fulfillmentDate : selectedDate,
);

export const selectIsRecipientOrNoLock = createSelector(
  selectSessionLockType,
  (type) => [SessionLock.None, SessionLock.Recipient].includes(type),
);

export const selectOccasionId = (state: RootState) =>
  state.availabilitySession.data.cartRecipient?.occasionId;

export const selectCartRecipientId = ({ availabilitySession }: RootState) =>
  availabilitySession.data.currentRecipientId;
