import { Order } from 'redux/order-confirmation/types/types';
import { ProductType, ProductTypeName } from 'api/products/types';
import { isUS } from 'utils/country';
import {
  selectFilterOrderItemData,
  selectFilterOrderSubItemData,
  selectSaleData,
} from 'redux/order-confirmation/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useGTagOrderConfirmationEvent(order: Order): void {
  const saleData = useSelector(selectSaleData);
  const products = useSelector((state) =>
    selectFilterOrderItemData(state, order.orderID),
  );
  const childProducts = useSelector((state) =>
    selectFilterOrderSubItemData(state, order.orderID),
  );

  useEffect(() => {
    if (saleData) {
      const currency = isUS ? 'USD' : 'CAD';
      const mainProducts = products?.map((element) => ({
        item_id: element.catalogNumber,
        item_name: element.name.split('-')[0].trim() || element.name,
        affiliation: order.storeNumber,
        item_category:
          element.type === ProductType.Package
            ? ProductTypeName.Package
            : ProductTypeName.Arrangement,
        price: element.price,
        quantity: element.quantity,
        discount: element.discount,
        currency,
        coupon: order.couponCode,
        item_variant: element.name,
        promotion_id: element.arrangementID,
      }));

      const addOns = childProducts
        ?.filter(
          (item) =>
            item.type === ProductType.AddOn &&
            item.itemType !== ProductType.Package,
        )
        .map((element) => ({
          item_id: element.catalogCode,
          item_name: element.name,
          affiliation: order.storeNumber,
          item_category: ProductTypeName.AddOn,
          price: element.price,
          quantity: element.quantity,
          discount: element.discount,
          currency,
          coupon: order.couponCode,
          promotion_id: element.productID,
        }));

      const upgrades = childProducts
        ?.filter((item) => item.type === ProductType.Upgrade)
        .map((element) => ({
          item_id: element.catalogCode,
          item_name: element.name,
          affiliation: order.storeNumber,
          item_category: ProductTypeName.Upgrade,
          price: element.price,
          quantity: element.quantity,
          discount: element.discount,
          currency,
          coupon: order.couponCode,
          promotion_id: element.productID,
        }));

      const gaPurchase = {
        transaction_id: order.orderID,
        affiliation: order.storeNumber,
        value: order.orderTotal,
        tax:
          order.orderTax1 +
          order.orderTax2 +
          order.orderTax3 +
          order.orderTax4 +
          order.orderTax5,
        shipping: order.deliveryOrShipmentCharge,
        currency,
        coupon: order.couponCode,
        items: [
          ...(mainProducts || []),
          ...(upgrades || []),
          ...(addOns || []),
        ],
      };

      gtag('event', 'purchase', gaPurchase);
      gtag('event', 'conversion', {
        send_to: window.GOOGLE_ADS_TAG_MANAGER_ID,
        value: saleData.total,
        currency,
        transaction_id: saleData.number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleData]);
}
