import { CLOSE } from 'translations/locales/translation-keys.constant';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { LogInContent } from 'components/Authentication/LogInContent';
import { LogInTitle } from 'components/Authentication/LogInTitle';
import { ResetPasswordContent } from 'components/Authentication/Reset/ResetPasswordContent';
import { ResetPasswordTitle } from 'components/Authentication/Reset/ResetPasswordTitle';
import { closeBtn, container } from 'components/Authentication/styles';
import { selectIsNoneMode } from 'components/Authentication/LogIn/feature/selectors';
import { useCloseDialog } from 'components/Authentication/hooks/control';
import { useSelector } from 'react-redux';
import { useTrackLogInForm } from 'components/Authentication/hooks/use-track-log-in-form';
import React, { FC } from 'react';
import i18next from 'i18next';

export const AuthenticationDialog: FC = () => {
  useTrackLogInForm();
  const closeDialog = useCloseDialog();
  const isNone = useSelector(selectIsNoneMode);

  return (
    <Dialog open={!isNone} onClose={closeDialog}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={closeDialog}
          sx={closeBtn}
          aria-label={i18next.t(CLOSE)}
          size="large"
          color="secondary"
          data-test="login-form-close"
        >
          <Close />
        </IconButton>
        <LogInTitle />
        <ResetPasswordTitle />
      </DialogTitle>
      <DialogContent sx={container}>
        <LogInContent />
        <ResetPasswordContent />
      </DialogContent>
    </Dialog>
  );
};
