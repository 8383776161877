export const LIMITED_LINES_HELPER_TEXT =
  'limited_text_field.limited_lines_helper_text';
export const FIELD_REQUIRED_HELPER_TEXT =
  'limited_text_field.field_required_helper_text';
export const LINES_LIMIT_EXCEEDED = 'limited_text_field.lines_limit_exceeded';
export const TYPING_LAST_LINE = 'limited_text_field.typing_last_line';
export const SPL_CHARS_ERROR = 'limited_text_field.spl_chars_error';
export const EMOJIS_ENTERED = 'limited_text_field.emojis_error';
export const REDUCE_MESSAGE_LENGTH_HELPER_TEXT =
  'limited_text_field.reduce_message_length_text';
export const REACHING_LAST_LINE = 'limited_text_field.reaching_last_line';
