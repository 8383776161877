import { OrderType } from 'types/cart.types';
import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { isNotSpecified } from 'utils/is-order-type';
import { isNull } from 'utils/null.utils';

interface EmptyPas extends Pas {
  area: null;
  date: null;
  orderType: OrderType.NotSpecified;
  store: null;
}

export function isPasEmpty(pas: Pas): pas is EmptyPas {
  const { area, date, orderType, store } = pas;

  return (
    isNull(area) && isNull(date) && isNull(store) && isNotSpecified(orderType)
  );
}
