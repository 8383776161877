import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isFulfilled, isInit } from 'utils/status.comparer';

export const selectIsLocaleFulfilled = createSelector(
  ({ locale }: RootState) => locale.status,
  (status) => isFulfilled(status),
);

export const selectIsLocaleInit = createSelector(
  ({ locale }: RootState) => locale.status,
  (status) => isInit(status),
);

export const selectLocale = ({ locale }: RootState): string => locale.data;
