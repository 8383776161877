import { ResponseError } from 'types/error.types';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { StoreScheduleState } from 'redux/PDP/stores/schedule/stores-schedule.types';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getStoresSchedule } from 'redux/PDP/stores/schedule/stores-schedule.actions';

export const initialState: StoreScheduleState = {
  data: {
    storeId: 0,
    PickupStartTime: '',
    PickupEndTime: '',
    PickupMargin: 0,
    SameDayPickupCutOffTime: '',
  },
  status: Status.INIT,
  error: null,
};

const storeScheduleSlice = createSlice({
  name: 'stores/schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoresSchedule.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(getStoresSchedule.fulfilled, (state, { payload }) => {
        state.data = { ...payload };
        state.data.storeId = payload.storeId;
        state.status = Status.FULFILLED;
      })
      .addCase(getStoresSchedule.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

const getCurrentStoreSchedule = ({ storeSchedule }: RootState) => storeSchedule;

export const getStoreSchedule = createSelector<
  RootState,
  StoreScheduleState,
  StoreScheduleState
>(getCurrentStoreSchedule, (storeSchedule) => storeSchedule);

export const { reducer: storeScheduleReducer } = storeScheduleSlice;
