export class CordialAnalytics {
  private static instance: CordialAnalytics;

  public static getInstance(): CordialAnalytics {
    if (!CordialAnalytics.instance) {
      CordialAnalytics.instance = new CordialAnalytics();
    }
    return CordialAnalytics.instance;
  }

  public addToCart = (
    productID: number,
    name: string,
    qty: number,
    itemPrice: number,
    size: string,
    category: string | null = '',
  ): void => {
    const cartData = [
      {
        productID,
        sku: productID,
        category,
        name,
        qty,
        itemPrice,
        url: window.location.href,
        attr: { size },
      },
    ];
    const properties = {
      productID,
      sku: productID,
      category,
      name,
      qty,
      itemPrice,
      size,
      url: window.location.href,
    };
    window.crdl('cartitem', 'add', cartData);
    window.crdl('event', 'cart_added', properties);
  };
}

export const cordialAnalytics: CordialAnalytics =
  CordialAnalytics.getInstance();
