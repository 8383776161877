import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/styles';
import { Box, Divider, Link, Typography } from '@mui/material';
import { Certification } from 'enums/store-params';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { ScheduleWeekdays } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/styled';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { StoreTags } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/components/StoreTags';
import { Store as StoreType } from 'redux/types/store.types';
import { TimingMessage } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/components/TimingMessage';
import { Toggle } from 'components/PDP/Details/components/PasControl/components/StoreSelector/styled';
import { isCanada } from 'utils/country';
import { isDefined } from 'utils/is-defined';
import { transformDaysToShortForm } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/utils';
import PhoneIcon from '@mui/icons-material/Phone';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  store: StoreType;
  selected?: boolean;
  setStore: (store: StoreType) => void;
  date: Date | null;
  dataTest: string;
};

export const Store: FC<Props> = ({
  store,
  selected = false,
  setStore,
  date,
  dataTest,
}) => {
  const distance = (
    <>
      {isDefined(store.distance) && (
        <Typography sx={styles.distance} variant="subtitle3" component="div">
          {isCanada
            ? i18next.t(TK.KILOMETERS, { km: store.distance })
            : i18next.t(TK.MILES, { miles: store.distance })}
        </Typography>
      )}
    </>
  );

  const phone = (
    <Link
      href={`tel:${store.phone}`}
      variant="subtitle2"
      underline="none"
      sx={styles.phone}
      onClick={(e) => e.stopPropagation()}
    >
      <PhoneIcon sx={{ mr: 1 }} />
      {store.phone}
    </Link>
  );

  const isStoreTagsShown =
    store.smoothie ||
    store.curbside ||
    Boolean(store.certifications === Certification.Kosher);

  return (
    <Toggle
      value={store.id}
      selected={selected}
      onChange={() => setStore(store)}
      sx={{ p: 2, mb: 1.5 }}
      data-test={dataTest}
    >
      <Box display="flex" width={1}>
        <ShowUpSm>{distance}</ShowUpSm>
        <Box flex={1}>
          <Box display="flex" flexWrap="wrap">
            <Box width={1} display="flex">
              <HideUpSm>{distance}</HideUpSm>
              <div>
                <Typography variant="subtitle2" component="div">
                  {store.address}
                </Typography>
                <Typography variant="body2" mt={0.5} component="div">
                  {`${store.city}, ${store.stateCode} ${store.areaName}`}
                </Typography>
              </div>
            </Box>
            <HideUpSm>
              <Box width={1} mt={2}>
                {phone}
              </Box>
            </HideUpSm>
          </Box>

          <Box display="flex" alignItems="center" mt={2}>
            <Box width={[1, 'auto']}>
              {store.schedule.map((timing) => (
                <Box key={timing.weekday} sx={styles.schedule}>
                  <ScheduleWeekdays sx={{ mr: 1.5 }} variant="body2">
                    {transformDaysToShortForm(timing.weekday)}
                    <span>:</span>
                  </ScheduleWeekdays>
                  <Typography variant="body2">{timing.hours}</Typography>
                </Box>
              ))}
            </Box>
            <ShowUpSm>
              <Divider orientation="vertical" flexItem sx={{ pl: 3 }} />
              <Box sx={{ pl: 3, ml: 'auto' }}>{phone}</Box>
            </ShowUpSm>
          </Box>
          <TimingMessage store={store} date={date} />
          {isStoreTagsShown && <StoreTags store={store} />}
        </Box>
      </Box>
    </Toggle>
  );
};
