import { AREA_NOT_FOUND } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-avialability.constants';
import { AxiosError } from 'axios';
import { HttpStatusCode } from 'enums/http-status-code';
import { ResponseError } from 'types/error.types';
import { areaApiService } from 'api/area/area.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { existsAnyAvailableFulfillmentMethodOnArea } from 'utils/any-available-fulfillment-method';
import { isEmpty } from 'utils/array/size';
import { parseAxiosError } from 'utils/errors';

export const getArea = createAsyncThunk(
  'area/fetch',
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await areaApiService.getAreas(name, 1);

      if (
        isEmpty(data) ||
        (data &&
          !existsAnyAvailableFulfillmentMethodOnArea(data[0].availability))
      ) {
        const error: ResponseError = {
          message: 'Area not found',
          data: { key: AREA_NOT_FOUND },
          status: HttpStatusCode.NOT_FOUND,
        };
        return rejectWithValue(error);
      }

      return data[0];
    } catch (err) {
      return rejectWithValue(parseAxiosError(err as AxiosError));
    }
  },
);
