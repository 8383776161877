import { Component, Content } from 'components/Home/types';
import { Components } from 'enums/home-components';

export enum TextAndButtonAlignment {
  TopLeft = '1',
  TopRight = '2',
  MiddleLeft = '3',
  MiddleRight = '4',
  BottomLeft = '5',
  BottomRight = '6',
}
export interface SecondaryBannerItem {
  bannerName: string;
  src: string;
  frSrc?: string;
  altText?: string;
  frAltText?: string;
  url: string;
  frUrl?: string;
  titleText?: string;
  frTitleText?: string;
  subTitleText?: string;
  frSubTitleText?: string;
  buttonText?: string;
  frButtonText?: string;
  buttonTextColor?: string;
  textColor?: string;
  textAlignment?: TextAndButtonAlignment;
  buttonAlignment?: TextAndButtonAlignment;
}

export interface SecondaryBannerContent extends Content {
  available: boolean;
  items: SecondaryBannerItem[];
}

export interface SecondaryBannerComponent extends Component {
  type: Components.secondaryBanner;
  content: {
    desktop: SecondaryBannerContent;
    mobile: SecondaryBannerContent;
  };
}
