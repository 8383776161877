import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { icon } from 'components/PDP/GoBack/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { FC } from 'react';

interface BreadcrumbBackProps {
  orderHistory: string;
}

export const wrapper: ThemedSxProps = () => ({
  display: 'flex',
  '& a': { fontSize: 12 },
});

export const link: ThemedSxProps = () => ({
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const BreadcrumbBack: FC<BreadcrumbBackProps> = ({ orderHistory }) => (
  <Box sx={wrapper}>
    <ArrowBackIosNewIcon sx={icon} role="presentation" />
    <Breadcrumbs>
      <Link href="/my-account/?active=4" className="link" sx={link}>
        {orderHistory && <Typography>{orderHistory}</Typography>}
      </Link>
    </Breadcrumbs>
  </Box>
);
