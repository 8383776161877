import { MediaPreview } from 'enums/media-preview.enum.';
import { isMp4 } from 'components/PDP/Details/components/Gallery/utils/is-mp4';

export const getDataTestAttribute = (
  preview: MediaPreview,
  src: string,
): string => {
  if (preview === MediaPreview.VIDEO) {
    return 'pdp-details-gallery-video-preview';
  }
  if (preview === MediaPreview.IMAGE) {
    return 'pdp-details-gallery-image-preview';
  }
  if (isMp4(src)) {
    return 'pdp-details-gallery-video';
  }
  return 'pdp-details-gallery-image';
};
