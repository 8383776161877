import * as TK from 'components/Cart/Details/components/FulfillmentInformation/Recipient/Contacts/translations/constants';

export const orderContactsCommonLocale = {
  [TK.ORDER_CONTACTS_HEADER]: 'Recipient Contact Information',
  [TK.ORDER_CONTACTS_ADD_BUTTON]: 'Home And/Or Work Phone',
  [TK.ORDER_CONTACTS_HOME_PHONE]: 'Home Phone',
  [TK.ORDER_CONTACTS_CELL_PHONE]: 'Cell Phone*',
  [TK.ORDER_CONTACTS_WORK_PHONE]: 'Work Phone',
  [TK.ORDER_CONTACTS_EMAIL_LABEL]: 'Recipient Email (optional)',
};
