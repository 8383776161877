import * as TK from 'components/Footer/translations/locale.constants';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  PINTEREST_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from 'utils/social-link.constants';
import {
  FacebookRounded,
  Instagram,
  Pinterest,
  SvgIconComponent,
  X,
  YouTube,
} from '@mui/icons-material';

import i18next from 'i18next';

interface IconLink {
  icon: SvgIconComponent;
  href: string;
  dataTest?: string;
  label: string;
}

export const iconLinks: IconLink[] = [
  {
    icon: Instagram,
    href: INSTAGRAM_LINK,
    dataTest: 'footer-social-inst',
    label: i18next.t(TK.FOOTER_INSTAGRAM_ARIA_LABEL),
  },
  {
    icon: FacebookRounded,
    href: FACEBOOK_LINK,
    dataTest: 'footer-social-facebook',
    label: i18next.t(TK.FOOTER_FACEBOOK_ARIA_LABEL),
  },
  {
    icon: X,
    href: TWITTER_LINK,
    dataTest: 'footer-social-twitter',
    label: i18next.t(TK.FOOTER_TWITTER_ARIA_LABEL),
  },
  {
    icon: Pinterest,
    href: PINTEREST_LINK,
    dataTest: 'footer-social-pinterest',
    label: i18next.t(TK.FOOTER_PINTEREST_ARIA_LABEL),
  },
  {
    icon: YouTube,
    href: YOUTUBE_LINK,
    dataTest: 'footer-social-youtube',
    label: i18next.t(TK.FOOTER_YOUTUBE_ARIA_LABEL),
  },
];
