import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { OrderExpressFulfillments } from 'redux/order-confirmation/types/types';

class OrderExpressFulfillmentsApiService extends ApiBaseService {
  findByOrderNumber(
    orderNumber: string,
  ): AxiosPromise<OrderExpressFulfillments> {
    return this.apibase.get<OrderExpressFulfillments>(
      `/express-fulfillments/order/${orderNumber}`,
    );
  }
}
const orderExpressFulfillmentsApiService =
  new OrderExpressFulfillmentsApiService();

export { orderExpressFulfillmentsApiService };
