import { LockGuideContext } from 'providers/LockGuideProvider';
import { useContext, useEffect, useState } from 'react';

export function useGuide({ isMenuOpened }: { isMenuOpened: boolean }): {
  isOpened: boolean;
  close: () => void;
} {
  const [isOpened, setIsOpened] = useState(true);
  const { isOpened: isOpenedByContext, close } = useContext(LockGuideContext);

  useEffect(() => {
    setIsOpened((prevState) => prevState && !isMenuOpened);
  }, [isMenuOpened]);

  return {
    isOpened: isOpened && isOpenedByContext,
    close,
  };
}
