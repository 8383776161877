import { ApiBaseService } from 'api/apibase.service';
import { Area } from 'api/area/types/area.interface';
import { AxiosResponse } from 'axios';
import { FulfillmentMethodsAvailability } from 'types/fulfillment-methods-availability.interface';
import { RequestOptions } from 'redux/types/request-options.interface';

class AreaApiService extends ApiBaseService {
  getAreas(areaName: string, limit: number): Promise<AxiosResponse<Area[]>> {
    return this.apibase.get<Area[]>('areas', {
      params: { name: areaName, limit },
    });
  }

  getFulfillmentMethodsAvailability(
    areaId: number,
    options?: RequestOptions,
  ): Promise<AxiosResponse<FulfillmentMethodsAvailability>> {
    return this.apibase.get<FulfillmentMethodsAvailability>(
      `areas/${areaId}/fulfillment-methods`,
      { cancelToken: options?.cancelToken },
    );
  }
}

const areaApiService = new AreaApiService();

export { areaApiService };
