import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';

export const accessibilityContainer: SxProps = {
  margin: ['0 auto', '0 auto;', '0 auto'],
  width: ['95%', '688px', '1060px'],
  paddingTop: '30px',
};

export const accessibilityMainHeader: SxProps = {
  color: '#0364A0',
  textTransform: ['uppercase', 'uppercase', 'capitalize'],
  textAlign: ['center', 'center', 'left'],
  fontSize: '30px',
  padding: '20px 0px',
  fontWeight: '400',
};

export const accessibilityHeader: SxProps = {
  color: '#0364A0',
  textTransform: 'capitalize',
  fontSize: ['24px', '30px', '30px'],
  padding: '20px 0px',
  fontWeight: ['600', '600', '400'],
};

export const phoneStyle: SxProps = {
  fontWeight: ['600', '600', '400'],
  fontSize: ['30px', '40px', '40px'],
};

export const accessibilityHeader1: SxProps = {
  color: '#111',
  textTransform: 'capitalize',
  fontSize: ['24px', '24px', '30px'],
  padding: '20px 0px',
  fontWeight: ['700', '700', '400'],
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  color: '#2c79d5',
};

export const activateBtnStyle: SxProps = {
  color: '#585858',
  height: '28px',
  fontWeight: '400',
  backgroundColor: 'rgb(240, 240, 240)',
  mb: 5,
  mt: 1,
  border: `solid 1px ${colors.grey}`,
};

export const adaEvolutionImg: SxProps = {
  width: '300px',
  height: '80px',
  margin: '0 auto',
};

export const adaEvolutionImgContainer: SxProps = {
  borderTop: `solid 1px ${colors.grey}`,
  borderBottom: `solid 1px ${colors.grey}`,
  py: 2,
};

export const graphicsTrustedImg: SxProps = {
  width: '133px',
  height: '50px',
  margin: '0 auto',
  mt: 3,
  mb: 2,
};

export const graphicsCtaImg: SxProps = {
  width: '155px',
  height: '80px',
  mb: 1.5,
};

export const graphicsTitle: SxProps = {
  color: '#0364A0',
  fontWeight: '600',
  fontSize: ['30px', '30px', '14px'],
};

export const graphicsLinkStyle: SxProps = {
  textDecoration: 'none',
  color: '#0364A0',
  fontWeight: '400',
  fontSize: ['30px', '30px', '14px'],
};

export const mobileLinkStyle: SxProps = {
  marginLeft: '20px',
  width: '96%',
  marginTop: '-24px',
};
