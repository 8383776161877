import { Image } from 'components/common/Image/Image';
import { MediaPreview } from 'enums/media-preview.enum.';
import { Video } from 'components/common/Video/Video';
import { getDataTestAttribute } from 'components/PDP/Details/components/Gallery/utils/get-data-test-attribute';
import { isMp4 } from 'components/PDP/Details/components/Gallery/utils/is-mp4';
import { updateAssetUrls } from 'utils/assets-url-builder';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import React, { FC } from 'react';

interface Props {
  src: string;
  alt: string;
  forcePause?: boolean;
  preview?: MediaPreview;
}

export const Media: FC<Props> = ({
  src,
  alt,
  forcePause = false,
  preview = MediaPreview.NONE,
}) =>
  src && isMp4(src) ? (
    <Video
      dataTest={getDataTestAttribute(preview, src)}
      forcePause={forcePause}
      src={src}
      errorFallback={src}
      PlayIcon={
        <PlayCircleOutlineIcon
          fontSize="large"
          sx={{ color: 'common.white' }}
        />
      }
    />
  ) : (
    <Image
      dataTest={getDataTestAttribute(preview, src)}
      key={src}
      src={updateAssetUrls(src)}
      errorFallback={src}
      alt={alt}
      animationDuration={250}
      square
    />
  );
