import { AxiosError } from 'axios';
import { CaseCategoryParam } from 'redux/case/caseCategory/case.types';
import { caseApiService } from 'api/case-api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const getCaseCategory = createAsyncThunk(
  'case-category/fetch',
  async (params: CaseCategoryParam, { rejectWithValue }) => {
    try {
      const { data } = await caseApiService.getCaseCategoryType(params);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const getCaseCategoryType = createAsyncThunk(
  'categoryType/fetch',
  async (params: CaseCategoryParam, { rejectWithValue }) => {
    try {
      const { data } = await caseApiService.getCaseCategoryType(params);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);
