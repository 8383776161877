import { Component, Content } from 'components/Home/types';
import { Components } from 'enums/home-components';

export enum TextPosition {
  Left = 'left',
  Right = 'right',
  CmsDesktopTextPosition = 'Right',
  CmsMobileTextPosition = 'Bottom',
}

export interface VideoTextItem {
  src: string;
  frSrc?: string;
  altText?: string;
  frAltText?: string;
  title?: string;
  frTitle?: string;
  description?: string;
  frDescription?: string;
  buttonText?: string;
  frButtonText?: string;
  buttonLink?: string;
  frButtonLink?: string;
  textPosition: string;
  hasImageToggle: boolean;
}

export interface VideoTextContent extends Content {
  available: boolean;
  items: VideoTextItem[];
}

export interface VideoTextComponent extends Component {
  type: Components.videoWithText;
  content: {
    desktop: VideoTextContent;
    mobile: VideoTextContent;
  };
}
