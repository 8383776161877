import * as styles from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/styles';
import { ArrowButton } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/components/ArrowButton/ArrowButton';
import { ArrowButtonType } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/components/ArrowButton/arrow-button-type.enum';
import { Box } from '@mui/system';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { DateView } from 'components/PDP/Details/components/PasControl/components/DateSelection/enums/date-selector.enum';
import { Key } from 'components/Paypal/enums/key.enum';
import { LocalizationProvider, PickersDay } from '@mui/lab';
import { StyledCalendar } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/styles';
import { Typography } from '@mui/material';
import { add, addMonths, format } from 'date-fns';
import { disableNextFiveDays } from 'components/PDP/Details/components/PasControl/components/DateSelection/utils/disable-next-five-days';
import { isDate } from 'utils/date/is-date.guard';
import { isDayView } from 'components/PDP/Details/components/PasControl/components/DateSelection/utils/is-day-view';
import { selectArrangementFloralPartyBoxBrand } from 'redux/PDP/arrangement/arrangement.selectors';
import { settingApiService } from 'api/settings.api.service';
import { useSelector } from 'react-redux';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { FC, useEffect, useState } from 'react';

const MONTH_BUTTON_FORMAT = 'MMMM';

const MIN_DATE = new Date();
const MAX_DATE = add(MIN_DATE, { years: 10 });

interface Props {
  date: Date | null;
  setDate(date: Date): void;
}

export const Calendar: FC<Props> = ({ date, setDate }) => {
  const [monthPage, setMonthPage] = useState(0);
  const [currentView, setCurrentView] = useState<CalendarPickerView>(
    DateView.DAY,
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const isFloralPartyBoxBrand = useSelector(
    selectArrangementFloralPartyBoxBrand,
  );
  const [floralBoxFeeForSaturday, setFloralBoxFeeForSaturday] =
    useState<string>('');

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const fetchShippingFee = async () => {
    const { data } = await settingApiService.getSetting(
      Key.FloralBoxFeeSetting,
    );
    const shippingFee = data.length > 0 ? `+$${Number(data[0].value)}` : '';
    setFloralBoxFeeForSaturday(shippingFee);
  };
  useEffect(() => {
    fetchShippingFee();
  }, []);

  const isDisableNextFiveDays = (calDate: Date) => {
    if (calDate && isFloralPartyBoxBrand) {
      return disableNextFiveDays(calDate);
    }
    return false;
  };
  const [hoveredIndex, setHoveredIndex] = useState('');
  const getFloralPartySaturdayShipping = (day: Date) => {
    const currentDay = new Date();

    if (
      !isDisableNextFiveDays(day) &&
      day.getMonth() === currentDay.getMonth() &&
      day.getFullYear() === currentDay.getFullYear()
    ) {
      return day.getDay() === 6 && day.getDate() >= currentDay.getDate() ? (
        <Typography
          key={`${day.getDate()}`}
          sx={styles.FloralSaturdayDayPrice(
            hoveredIndex === `${day.getDate()}`,
          )}
          onMouseEnter={() => setHoveredIndex(`${day.getDate()}`)}
          onMouseLeave={() => setHoveredIndex('')}
        >
          {floralBoxFeeForSaturday}
        </Typography>
      ) : (
        ''
      );
    }
    if (
      !isDisableNextFiveDays(day) &&
      (day.getMonth() > currentDay.getMonth() ||
        day.getFullYear() >= currentDay.getFullYear())
    ) {
      return day.getDay() === 6 ? (
        <Typography
          key={`${day.getDate()}`}
          sx={styles.FloralSaturdayDayPrice(
            hoveredIndex === `${day.getDate()}`,
          )}
          onMouseEnter={() => setHoveredIndex(`${day.getDate()}`)}
          onMouseLeave={() => setHoveredIndex('')}
        >
          {floralBoxFeeForSaturday}
        </Typography>
      ) : (
        ''
      );
    }
    return '';
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledCalendar
        components={{
          LeftArrowButton: ArrowButton,
          RightArrowButton: ArrowButton,
        }}
        componentsProps={{
          leftArrowButton: {
            type: ArrowButtonType.LEFT,
            text: format(
              addMonths(new Date(), monthPage - 1),
              MONTH_BUTTON_FORMAT,
            ),
            onClickAction: () => setMonthPage(monthPage - 1),
          },
          rightArrowButton: {
            type: ArrowButtonType.RIGHT,
            text: format(
              addMonths(new Date(), monthPage + 1),
              MONTH_BUTTON_FORMAT,
            ),
            onClickAction: () => setMonthPage(monthPage + 1),
          },
        }}
        date={selectedDate}
        onYearChange={() => {
          setCurrentView(DateView.MONTH);
        }}
        onMonthChange={() => {
          setCurrentView(DateView.DAY);
        }}
        view={currentView}
        onChange={(newDate) => {
          if (!isDate(newDate)) {
            return;
          }

          setSelectedDate(newDate);
          if (isDayView(currentView)) {
            setDate(newDate);
          }
        }}
        disablePast
        showDaysOutsideCurrentMonth
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: using ts-ignore here due to third party module
        shouldDisableDate={isDisableNextFiveDays}
        onViewChange={(view) => setCurrentView(view)}
        renderDay={(day, selectedDates, props) => (
          <PickersDay
            data-test={`pdp-pas-date-picker-day-${new Date(
              String(day),
            ).getDate()}`}
            {...props}
            disabled={props.disabled || props.outsideCurrentMonth}
            sx={
              isFloralPartyBoxBrand
                ? styles.floralPartyBoxPickersDay
                : styles.pickersDay
            }
            selected={
              date
                ? new Date(String(day)).toDateString() === date.toDateString()
                : false
            }
          >
            <Box>
              {(day as Date).getDate()}
              <br />
              {isFloralPartyBoxBrand && !props.outsideCurrentMonth
                ? getFloralPartySaturdayShipping(day as Date)
                : ''}
            </Box>
          </PickersDay>
        )}
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
      />
    </LocalizationProvider>
  );
};
