import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';

export const wrapper: SxProps = {
  padding: { md: '32px 20px', sm: 0, xs: 0 },
  position: 'relative',
  width: { md: '400px', sm: '88%', xs: '88%' },
  marginTop: { md: '0px', sm: '30px', xs: '30px' },
  marginLeft: { md: '24px', sm: '0', xs: '0' },
  height: 'fit-content',
  minHeight: { md: '519px', sm: 'auto', xs: '407px' },
  display: { md: 'flex', sm: 'inline-block', xs: 'block' },
  gap: '24px',
  border: { md: `1px solid ${colors.borderBlack}`, sm: 'none', xs: 'none' },
  borderRadius: '24px',
  marginBottom: { md: '0', sm: '0', xs: '40px' },
  '& .checkImg': {
    position: 'absolute',
  },
  '& .rightStyle': {
    width: { md: '100%', sm: '55%', xs: '100%' },
    float: { md: 'left', sm: '', xs: '' },
    display: 'inline-block',
  },
  '& .leftStyle': {
    width: { md: '100%', sm: '45%', xs: '100%' },
    float: { md: '', sm: 'right', xs: '' },
  },
  '& .shipmentInfo': {
    width: { md: '100%', sm: '80%', xs: '90%' },
  },
};
export const title: SxProps = {
  fontWeight: '600',
  fontSize: '20px',
};
export const infoTitle: SxProps = {
  fontWeight: '600',
  fontSize: '16px',
  marginTop: { md: '0', sm: '15px', xs: '15px' },
};
export const infoText: SxProps = {
  fontWeight: '400',
  fontSize: '16px',
  marginTop: '10px',
  '& .infoCont': {
    width: '70%',
  },
};
export const deliveryLink: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  marginLeft: '28px',
};

export const oneDeliveryText: SxProps = {
  fontWeight: '500',
  color: '#2E2E2E',
};
export const oneHrDeliveryImage: SxProps = {
  display: 'flex',
  alignItems: 'center',
  width: '20px',
  height: '20px',
};
export const OneHrDeliveryWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  padding: '2px 8px',
  gap: 1,
  backgroundColor: colors.lightRedBackground,
  borderRadius: 4,
  width: '156px',
  marginTop: '5px',
};
