import { Key } from 'components/Paypal/enums/key.enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { settingApiService } from 'api/settings.api.service';

export const getPaypalEnableSetting = createAsyncThunk(
  'paypalEnableSetting/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingApiService.getSetting(
        Key.EnablePaymentMethodPayPal,
      );
      if (data.length > 0) {
        return Boolean(Number(data[0].value));
      }
      return false;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getNxtGenCartStoreNumberSetting = createAsyncThunk(
  'NxtGenCartStoreNumberSetting/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingApiService.getSetting(
        'NxtGenCartStoreNumberSetting',
      );
      if (data.length > 0) {
        return data[0].value;
      }
      return '';
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getPaypalVersionSetting = createAsyncThunk(
  'paypalVersionSetting/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingApiService.getSetting(Key.UsePaypalVersion);

      if (data.length > 0) {
        return String(data[0].value);
      }
      return '';
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const getEnablePrintibleStrikeSetting = createAsyncThunk(
  'enablePrintibleStrike/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingApiService.getSetting(
        Key.EnablePrintibleStrikeThrough,
      );
      if (data.length > 0) {
        return Boolean(Number(data[0].value));
      }
      return false;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
