import { Area, Country, State } from 'components/Payment/feature/types';
import { ProcessPaymentResponse } from 'api/payment/types/process-payment-response.type';
import { ResponseError } from 'types/error.types';
import { SaleDeclineReasonEnum } from 'enums/payment-enum';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getArea,
  getCountries,
  getStates,
  isEmailAlreadyExist,
  processPayment,
} from 'components/Payment/feature/actions';

export interface ProcessPaymentState {
  paymentResponse: ProcessPaymentResponse;
  countries: Country[];
  states: State[];
  area: Area;
  isEmailExist: boolean;
  status: Status;
  areaStatus: Status;
  countriesStatus: Status;
  statesStatus: Status;
  emailExistStatus: Status;
  error: ResponseError | null;
}

const initialState: ProcessPaymentState = {
  paymentResponse: {
    saleId: 0,
    status: {
      statusCode: 0,
      message: '',
    },
    declinedSale: {
      saleId: 0,
      reason: SaleDeclineReasonEnum.NotSpecified,
    },
  },
  countries: [],
  states: [],
  area: {
    cities: [],
    state: '',
    country: '',
  },
  isEmailExist: false,
  status: Status.INIT,
  countriesStatus: Status.INIT,
  statesStatus: Status.INIT,
  areaStatus: Status.INIT,
  emailExistStatus: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'processPayment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processPayment.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(processPayment.fulfilled, (state, { payload }) => {
        state.paymentResponse = payload;
        state.status = Status.FULFILLED;
      })
      .addCase(processPayment.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(getCountries.pending, (state) => {
        state.countriesStatus = Status.PENDING;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.countries = payload;
        state.countriesStatus = Status.FULFILLED;
      })
      .addCase(getCountries.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.countriesStatus = Status.ERROR;
      })
      .addCase(getStates.pending, (state) => {
        state.statesStatus = Status.PENDING;
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.states = payload;
        state.statesStatus = Status.FULFILLED;
      })
      .addCase(getStates.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.statesStatus = Status.ERROR;
      })
      .addCase(getArea.pending, (state) => {
        state.areaStatus = Status.PENDING;
      })
      .addCase(getArea.fulfilled, (state, { payload }) => {
        state.area = payload;
        state.areaStatus = Status.FULFILLED;
      })
      .addCase(getArea.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.areaStatus = Status.ERROR;
      })
      .addCase(isEmailAlreadyExist.pending, (state) => {
        state.emailExistStatus = Status.PENDING;
      })
      .addCase(isEmailAlreadyExist.fulfilled, (state, { payload }) => {
        state.isEmailExist = payload;
        state.emailExistStatus = Status.FULFILLED;
      })
      .addCase(isEmailAlreadyExist.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.emailExistStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const processPaymentReducer = slice.reducer;
