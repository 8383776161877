import { Dialog as MuiDialog, styled } from '@mui/material';

export const Dialog = styled(MuiDialog)(({ theme }) => {
  const topSpacing = theme.spacing(5);
  return {
    '.MuiPaper-root': {
      maxHeight: `calc(100% - ${topSpacing})`,
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      marginTop: topSpacing,
      display: 'flex',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        height: 'auto',
        maxWidth: 452,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 700,
      },
    },
  };
});
