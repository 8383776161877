import { ResponseError } from 'types/error.types';
import { ReviewsState } from 'components/PDP/Details/components/Reviews/feature/reviews.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchReviews } from 'components/PDP/Details/components/Reviews/feature/reviews.actions';

const initialState: ReviewsState = {
  data: {
    items: [],
    isAllFetched: false,
  },
  status: Status.INIT,
  error: null,
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    resetReviewsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(fetchReviews.fulfilled, (state, { payload }) => {
        state.data.items = [...state.data.items, ...payload.items];
        state.data.isAllFetched = payload.isAllFetched;
        state.status = Status.FULFILLED;
      })
      .addCase(fetchReviews.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: reviewsReducer } = reviewsSlice;
export const { resetReviewsState } = reviewsSlice.actions;
