import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';
import {
  unlockDiscountSignUpBaseImageDesktop,
  unlockDiscountSignUpBaseImagePhone,
} from 'utils/cloudinaryAssets';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: theme.breakpoints.down('sm') ? 'row' : 'column',
  flexGrow: 1,
  rowGap: 1,
  zIndex: 1,
}));

export const unlockOffer: ThemedSxProps = {
  minHeight: { md: '220px', sm: '220px', xs: '120px' },
  marginTop: { md: '10px', sm: '10px', xs: '7px' },
  marginBottom: { md: '0px', sm: '0px', xs: '-84px' },
  marginRight: { xs: '-30px' },
  paddingRight: { xs: '30px' },
  flexDirection: { md: 'row', sm: 'row', xs: 'column' },
  rowGap: { xs: '104px' },
  backgroundImage: {
    md: `url(${unlockDiscountSignUpBaseImageDesktop})`,
    xs: `url(${unlockDiscountSignUpBaseImagePhone})`,
    sm: `url(${unlockDiscountSignUpBaseImageDesktop})`,
  },
  backgroundRepeat: 'no-repeat',
  backgroundSize: { md: 'auto 200px', sm: 'auto 200px', xs: 'auto 104px' },
  backgroundPosition: { md: 'left', sm: 'left', xs: 'top' },
};

export const inputWrapper: ThemedSxProps = () => ({
  borderRadius: '0px',
  width: '100%',
  background: 'white',
});

export const input: ThemedSxProps = (theme) => ({
  padding: theme.spacing(1.8125, 0, 1.8125, 2.5),
});

export const inputLabel: ThemedSxProps = (theme) => ({
  '&.MuiInputLabel-shrink': {
    backgroundColor: theme.palette.custom.bodyBg,
  },
  ':not(.MuiInputLabel-shrink)': {
    paddingLeft: theme.spacing(0.625),
  },
});

export const button: ThemedSxProps = {
  minWidth: '85px',
  borderRadius: 25,
};

export const helperText: ThemedSxProps = (theme) => ({
  margin: theme.spacing(0.375, 2.5, 0),
});

export const termHelperText: ThemedSxProps = (theme) => ({
  margin: theme.spacing(-2, 2.5, 0),
  fontWeight: '500',
});

export const termsOfUse: ThemedSxProps = (theme) => ({
  alignSelf: 'flex-start',
  margin: theme.spacing(1, 0, 0.5),
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
});

export const PolicyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2.5, 0),
}));

export const policy: ThemedSxProps = (theme) => ({
  marginRight: theme.spacing(0.5),
});

export const policyLabel: ThemedSxProps = (theme) => ({
  padding: theme.spacing(1, 0, 0.5),
});

export const tooltipStyle = {
  tooltip: {
    sx: {
      maxHeight: { md: '100px', sm: 'auto', xs: 'auto' },
      maxWidth: '900px',
    },
  },
};

export const tooltipTextStyle = { fontSize: '14px', lineHeight: '1.5' };

export const policyTooltipLabel: ThemedSxProps = (theme) => ({
  padding: theme.spacing(1, 0, 0.5),
  textDecoration: 'underline',
});

export const TermsAndConditions: ThemedSxProps = (theme) => ({
  alignSelf: 'flex-start',
  margin: theme.spacing(1, 0, 0.5),
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
});
