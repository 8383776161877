export const AUTH_RESET_STATUS_EXCEPTION = 'auth.reset.status.exception';
export const AUTH_RESET_STATUS_ERROR = 'auth.reset.status.error';
export const AUTH_RESET_STATUS_NOT_FOUND = 'auth.reset.status.not_found';
export const AUTH_RESET_SENT_1 = 'auth.reset.sent.1';
export const AUTH_RESET_SENT_2 = 'auth.reset.sent.2';
export const AUTH_RESET_SENT_3 = 'auth.reset.sent.3';
export const AUTH_RESET_SUBMIT = 'auth.reset.submit';
export const AUTH_RESET_SUBMITTING = 'auth.reset.submitting';
export const AUTH_RESET_EMAIL_LABEL = 'auth.reset.email.label';
export const AUTH_RESET_TIP = 'auth.reset.tip';
export const AUTH_DIALOG_RESET_HEADER = 'auth.log_in.dialog.reset.header';
export const AUTH_LOG_IN_ERR_EMAIL_REQUIRED = 'auth.log_in.err.email.required';
export const AUTH_LOG_IN_ERR_PASSWORD_REQUIRED =
  'auth.log_in.err.password.required';
export const AUTH_ERR_EMAIL_INVALID = 'auth.err.email.invalid';
export const AUTH_LOG_IN_FORM_SIGN_IN = 'auth.log_in.form.sign.in';
export const AUTH_LOG_IN_FORM_SUCCESS = 'auth.log_in.form.success';
export const AUTH_LOG_IN_FORM_SIGNING_IN = 'auth.log_in.form.signing.in';
export const AUTH_LOG_IN_FORM_RESET_LABEL = 'auth.log_in.form.reset.label';
export const AUTH_LOG_IN_FORM_PASSWORD_PLACEHOLDER =
  'auth.log_in.form.password.placeholder';
export const AUTH_LOG_IN_FORM_PASSWORD_LABEL =
  'auth.log_in.form.password.label';
export const AUTH_LOG_IN_FORM_EMAIL_LABEL = 'auth.log_in.form.email.label';
export const AUTH_DIALOG_MODAL_REGISTRATION = 'auth.dialog.modal.registration';
export const AUTH_DIALOG_MODAL_REGISTRATION_TIP =
  'auth.dialog.modal.registration.tip';
export const AUTH_DIALOG_LOG_IN_HEADER = 'auth.dialog.modal.header';
export const AUTH_LOGIN_ERR_LOCK_HEADER = 'auth.log_in.error.header';
export const AUTH_LOG_IN_ERR_LOCKED = 'auth.log_in.error.locked';
export const AUTH_LOG_IN_ERR_TRY_AGAIN = 'auth.log_in.error.try_again';
export const AUTH_LOG_IN_ERR_INVALID_CREDENTIALS =
  'auth.log_in.error.invalid_credentials';
export const AUTH_LOG_IN_ERR_NOT_EXIST = 'auth.log_in.error.not_exist';
export const AUTH_LOG_IN_ERR_EXCEPTION = 'auth.log_in.error._exception';
export const SIGNUP_EMAIL_ALREADY_EXIST = 'signup_email_already_exist.text';
