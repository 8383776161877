import * as styles from 'components/Subscription/EmailSubscriptionDialog/styles';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { CLOSE } from 'translations/locales/translation-keys.constant';
import { FormStep } from 'components/Subscription/EmailSubscriptionDialog/FormStep';
import { SubmitSuccessStep } from 'components/Subscription/EmailSubscriptionDialog/SubmitSuccessStep';
import { useIsUpSm } from 'utils/hidden/hide-up-sm';
import Close from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import React, { FC, useEffect, useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import i18next from 'i18next';

const COOKIE_EXPIRATION_DAYS = 3650;
const SUBSCRIPTION_COOKIE_KEY = 'showSignup';
const drawerBleeding = 56;

export const EmailSubscriptionDialog: FC = () => {
  const isSm = useIsUpSm();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  useEffect(() => {
    if (!Cookies.get(SUBSCRIPTION_COOKIE_KEY)) {
      Cookies.set(SUBSCRIPTION_COOKIE_KEY, 'y', {
        expires: COOKIE_EXPIRATION_DAYS,
      });
      setDrawerOpen(true);
      setIsOpen(true);
    }
  }, []);

  const formContent = () =>
    isSubmitted ? (
      <SubmitSuccessStep />
    ) : (
      <FormStep onSuccess={() => setIsSubmitted(true)} />
    );

  return (
    <>
      {!isSm ? (
        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: styles.drawContent,
          }}
        >
          <Box>{formContent()}</Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={isOpen}
          sx={styles.dialog}
          PaperProps={{ sx: styles.windowWrappercon }}
        >
          <DialogTitle sx={styles.title}>
            <IconButton
              sx={styles.closeBtn}
              onClick={() => setIsOpen(false)}
              color="secondary"
              size="small"
              aria-label={i18next.t(CLOSE)}
            >
              <Close fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={styles.content}>{formContent()}</DialogContent>
        </Dialog>
      )}
    </>
  );
};
