import { Typography, TypographyProps } from '@mui/material';
import { useSanitizedHtml } from 'components/common/PureHtml/hooks/use-sanitized-html';
import React, { FC } from 'react';

interface Props extends Omit<TypographyProps, 'dangerouslySetInnerHTML'> {
  component?: React.ElementType;
  children: string | Node;
}

export const PureHtmlTypography: FC<Props> = ({
  children,
  ...typographyProps
}) => {
  const html = useSanitizedHtml(children);

  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: html }}
      {...typographyProps}
    />
  );
};
