import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'components/Home/translations/locale.constants';
import * as styles from 'components/Home/CarouselSimplified/styles';

import { Box } from '@mui/material';
import { CarouselItem } from 'components/Home/CarouselSimplified/components/CarouselItem';
import {
  CarouselSimplifiedContent,
  CarouselSimplifiedItem,
} from 'components/Home/CarouselSimplified/types';
import { CarouselSimplifiedSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton';
import { ComponentProps } from 'components/Home/types';
import {
  GridContainer,
  GridItem,
  Headline,
} from 'components/Home/CarouselSimplified/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { WidgetWrapper } from 'components/Home/common/styled';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useIsBetweenMdnLg } from 'utils/hidden/use-is-between-md-lg';
import { useIsUpLg } from 'utils/hidden/use-is-up-lg';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const CarouselSimplified: FC<ComponentProps> = ({
  type,
  id,
  title,
  frTitle,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const content = useResponsiveContent<CarouselSimplifiedContent>(
    componentData,
    'md',
  );
  const isDesktop = useIsUpLg();
  const isTablet = useIsBetweenMdnLg();
  const isPlpPageActive = useSelector(plpGetComponents);
  let optionsPerRow;
  if (isDesktop) {
    optionsPerRow = 6;
  } else if (isTablet) {
    optionsPerRow = 3;
  } else {
    optionsPerRow = 2;
  }
  const initialSlides = content.items.slice(0, optionsPerRow);
  const is404Page = window.location.pathname.split('/').pop() === '404';

  const { imagesPreloaded } = useImagePreloader(initialSlides);

  const handleAnalyticsClick = (position: number, linkName: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: position.toString(),
      module_type: type,
      module_name: name,
      link: linkName,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  if (!imagesPreloaded) {
    return <CarouselSimplifiedSkeleton />;
  }

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <Box
        id="shopByOccasionWidget"
        style={{ backgroundColor: content.bgColor }}
      >
        <WidgetWrapper
          id={getId(type, id)}
          data-test="carousel-simplified-container"
        >
          <Headline>
            <PureHtmlTypography
              sx={styles.title}
              variant="h2"
              data-test="carousel-simplified-title"
            >
              {is404Page
                ? i18next.t(TK.POPULAR_CATEGORIES)
                : checkForFrench(title, frTitle) ||
                  checkForFrench(content.title, content.frTitle)}
            </PureHtmlTypography>
            <Box sx={styles.arrowControls} />
          </Headline>
          <GridContainer
            sx={styles.carouselCon}
            container
            spacing={1}
            data-test="carousel-simplified"
          >
            {content.items.map(
              (occasionItem: CarouselSimplifiedItem, index: number) => (
                <GridItem
                  key={occasionItem.id}
                  item
                  xs={6}
                  sm={4}
                  md={2}
                  lg={2}
                >
                  <CarouselItem
                    item={occasionItem}
                    sequence={index}
                    analyticsClickHandler={() =>
                      handleAnalyticsClick(index + 1, occasionItem.name)
                    }
                  />
                </GridItem>
              ),
            )}
          </GridContainer>
        </WidgetWrapper>
      </Box>
    </RenderWhenInView>
  );
};
