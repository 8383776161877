import { SxProps } from '@mui/system';

export const iconWrapper: SxProps = {
  display: 'flex',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  justifyContent: 'center',
};
