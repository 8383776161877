import { HomePageContent } from 'components/Home/types';
import { ResponseError } from 'types/error.types';
import { Status } from 'enums/status';
import { createSlice } from '@reduxjs/toolkit';
import { getHomePageContent } from 'components/Home/feature/actions';

export interface HomePageState {
  data: HomePageContent;
  status: Status;
  error: ResponseError | null;
}

const initialState: HomePageState = {
  data: {} as HomePageContent,
  status: Status.IDLE,
  error: null,
};

const slice = createSlice({
  name: 'homePageContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomePageContent.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(getHomePageContent.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getHomePageContent.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const homePageContentReducer = slice.reducer;
