import { Box, Skeleton } from '@mui/material';
import { continueButtonWrapper } from 'components/PDP/Details/components/ContinueButton/continue-button.styles';
import React, { forwardRef } from 'react';

export const ContinueButtonSkeleton = forwardRef<HTMLDivElement>(
  (props, ref) => (
    <div ref={ref}>
      <Box sx={continueButtonWrapper}>
        <Skeleton
          sx={{
            display: ['flex'],
            height: ['50px'],
            width: ['100%'],
            marginLeft: ['0px', '10px'],
          }}
        />
      </Box>
    </div>
  ),
);
