import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isError } from 'api/auth-legacy/reset-password.misc';
import { isFulfilled, isPending } from 'utils/status.comparer';

export const getResetState = createSelector(
  ({ authPasswordReset: { reset } }: RootState) => reset,
  (reset) => reset,
);

export const checkIsPending = createSelector(
  ({ authPasswordReset: { status } }: RootState) => status,
  (status) => isPending(status),
);

export const checkIsSuccess = createSelector(
  ({ authPasswordReset }: RootState) => authPasswordReset,
  ({ status, reset }) => isFulfilled(status) && !isError(reset),
);
