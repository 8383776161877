import * as TK from 'components/Home/SameDayDeliveryCutOffBanner/translations/locale.constants';

export const sameDayDeliveryCommonLocale = {
  [TK.SAME_DAY_DELIVERY_GET_IT_TODAY]: 'GET IT TODAY! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS_AND_MINUTES]:
    'Order within {{hours}} hours {{minutes}} minutes for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS_AND_MINUTE]:
    'Order within {{hours}} hours {{minutes}} minute for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR_AND_MINUTES]:
    'Order within {{hours}} hour {{minutes}} minutes for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR_AND_MINUTE]:
    'Order within {{hours}} hour {{minutes}} minute for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS]:
    'Order within {{hours}} hours for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR]:
    'Order within {{hours}} hour for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_MINUTES]:
    'Order within {{minutes}} minutes for Same Day Delivery! ',
  [TK.SAME_DAY_DELIVERY_ORDER_WITHIN_MINUTE]:
    'Order within {{minutes}} minute for Same Day Delivery! ',
};
