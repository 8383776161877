import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { AccountInfo, Reward } from 'providers/Session/feature/user.types';
import { Box, Typography } from '@mui/material';
import { NoReward } from 'components/Rewards/RewardLanding/MyRewards/NoReward';
import { RewardsContainer } from 'components/Rewards/RewardLanding/MyRewards/RewardsContainer';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const Rewards: FC = () => {
  const [pendingRewards, setPendingRewards] = useState<Reward[]>([]);
  const [historyRewards, setHistoryRewards] = useState<Reward[]>([]);
  const isRewardExpiryDate = true;
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId =
    accountInfo.length && accountInfo[0].id ? accountInfo[0].id : 0;
  const fetchRewardsByAccountID = async () => {
    const res = await rewardsApiService.getRewardsByAccountID(accountId);
    setPendingRewards(res.pendingRewards);
    setHistoryRewards(res.historyRewards);
  };

  useEffect(() => {
    if (accountId) {
      fetchRewardsByAccountID();
    }
  }, [accountId]);
  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" mb={2} mt={3}>
          {i18next.t(TK.REWARD_MESSAGE)}
        </Typography>
        {pendingRewards.length <= 0 && historyRewards.length <= 0 && (
          <NoReward />
        )}
      </Box>
      {pendingRewards.map((reward) => (
        <RewardsContainer
          key={reward.rewardId}
          reward={reward}
          historyReward={!isRewardExpiryDate}
        />
      ))}
      <Box sx={styles.historyRewardGrid}>
        {historyRewards.map((reward) => (
          <RewardsContainer
            key={reward.rewardId}
            reward={reward}
            historyReward={isRewardExpiryDate}
          />
        ))}
      </Box>
      <hr />
    </Box>
  );
};
