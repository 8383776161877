import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';

export type StoreDetailsByCartId = {
  cartRecipientId: number;
  cartId: number;
  storeID: number;
  storeNumber: string;
};
class CartApiService extends ApiBaseService {
  getCartCount(): Promise<number> {
    return this.apibase
      .get<number>('/carts/count', {
        headers: { 'Cache-Control': 'no-cache, no-store' },
      })
      .then(({ data }) => data);
  }

  getStoreDetailsById(cartId: number): AxiosPromise<StoreDetailsByCartId[]> {
    return this.apibase.get(`/carts/store-by-cart-id/${cartId}`);
  }
}

const cartApiService = new CartApiService();

export { cartApiService };
