import * as styles from 'components/Home/CarouselDetailed/components/Skeleton/styles';
import {
  CarouselContainer,
  Container,
} from 'components/Home/CarouselDetailed/components/Skeleton/styles';
import { CarouselItemSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton/CarouselItemSkeleton';
import { Headline } from 'components/Home/CarouselDetailed/styles';
import { Skeleton } from '@mui/material';
import { range } from 'utils/range';
import { settings } from 'components/Home/CarouselDetailed/settings';
import React, { FC } from 'react';
import Slider from 'react-slick';

export const CarouselDetailedSkeleton: FC = () => (
  <Container>
    <Headline>
      <Skeleton sx={styles.title} variant="rectangular" />
    </Headline>
    <CarouselContainer data-test="carousel-detailed">
      <Slider {...settings}>
        {range(9).map((rangeItem) => (
          <CarouselItemSkeleton key={rangeItem} />
        ))}
      </Slider>
    </CarouselContainer>
  </Container>
);
