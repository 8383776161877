import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  title: string;
  loading?: boolean;
}

export const Title: FC<Props> = ({ title, loading = false }) => {
  if (loading) {
    return <Skeleton height={32} />;
  }

  return (
    <PureHtmlTypography
      data-test="pdp-details-title"
      component="h1"
      variant="h6"
      fontWeight={600}
    >
      {title}
    </PureHtmlTypography>
  );
};
