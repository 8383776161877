export const CURBSIDE = 'pdp.tag.curbside';
export const DISTANCE = 'pdp.icon.distance';
export const KOSHER = 'pdp.tag.kosher';
export const MILES = 'pdp.title.miles';
export const SMOOTHIE = 'pdp.tag.smoothie';
export const TITLE_PICKUP = 'store_selector.title.pickup';
export const TITLE_DELIVERY = 'store_selector.title.delivery';
export const SHOW_MORE = 'store_selector.show_more';
export const MONDAY_FULL = 'store_selector.schedule.monday_full';
export const TUESDAY_FULL = 'store_selector.schedule.tuesday_full';
export const WEDNESDAY_FULL = 'store_selector.schedule.wednesday_full';
export const THURSDAY_FULL = 'store_selector.schedule.thursday_full';
export const FRIDAY_FULL = 'store_selector.schedule.friday_full';
export const SATURDAY_FULL = 'store_selector.schedule.saturday_full';
export const SUNDAY_FULL = 'store_selector.schedule.sunday_full';
export const MONDAY_SHORT = 'store_selector.schedule.monday_short';
export const TUESDAY_SHORT = 'store_selector.schedule.tuesday_short';
export const WEDNESDAY_SHORT = 'store_selector.schedule.wednesday_short';
export const THURSDAY_SHORT = 'store_selector.schedule.thursday_short';
export const FRIDAY_SHORT = 'store_selector.schedule.friday_short';
export const SATURDAY_SHORT = 'store_selector.schedule.saturday_short';
export const SUNDAY_SHORT = 'store_selector.schedule.sunday_short';
export const ERROR_NO_STORES = 'store_selector.errors.no_stores';
export const ERROR_SERVICING_STORE_CLOSED =
  'store_selector.servicing_store_closed';
export const ERROR_STORE_IS_CLOSED = 'store_selector.store_is_closed';
export const ERROR_SERVICING_STORE_CANNOT_FULFILL_ORDER =
  'store_selector.servicing_store_cannot_fulfill_order';
export const ERROR_DELIVERY_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE =
  'store_selector.delivery_is_not_available_for_store_on_date';
export const ERROR_DELIVERY_CUTOFF_PASSED =
  'availability.store.delivery_cutoff_passed';
export const ERROR_SIZE_IS_NOT_AVAILABLE =
  'availability.store.size_is_not_available';
export const ERROR_PICKUP_IS_NOT_AVAILABLE_FOR_STORE_ON_DATE =
  'store_selector.pickup_is_not_available_for_store_on_date';
export const ERROR_PICKUP_CUTOFF_PASSED =
  'availability.store.pickup_cutoff_passed';
export const ERROR_PICKUP_DATE_IN_PAST = 'store_selector.pickup_date_in_past';
export const ERROR_PICKUP_CUTOFF_DATE = 'store_selector.pickup_cutoff_date';
export const ERROR_BEFORE_MINIMUM_PICKUP_TIME =
  'store_selector.before_minimum_pickup_time';
export const ERROR_SAME_DAY_SHIPMENT = 'store_selector.same_day_shipment';
export const ERROR_NEXT_DAY_SHIPMENT_CUTOFF_PASSED =
  'store_selector.next_day_shipment_cutoff_passed';
export const ERROR_STORES_ARE_NOT_AVAILABLE =
  'store_selector.stores_are_not_available';
