import { Languages } from 'redux/locale/locale.types';
import Cookies from 'js-cookie';

export const loadLocale = (): string => {
  try {
    const locale = Cookies.get('locale') as Languages;
    if (!locale) {
      return Languages.ENGLISH;
    }
    if (!Object.values(Languages).includes(locale)) {
      return Languages.ENGLISH;
    }
    return locale;
  } catch (e) {
    return Languages.ENGLISH;
  }
};
