export const BILLING_INFORMATION_TITLE = 'payment.billing_information_title';
export const STREET_ADDRESS = 'payment.street_address';
export const APT_FLOOR_SUITE_BUTTON = 'payment.apt_floor_suite_button';
export const APT_FLOOR_SUITE_LABEL = 'payment.apt_floor_suite_label';
export const CHOOSE_OPTION = 'payment.choose_option';
export const COUNTRY_LABEL = 'payment.country';
export const CITY_LABEL = 'payment.city';
export const ZIP_LABEL = 'payment.zip';
export const STATE_LABEL = 'payment.state';
export const PHONE_NUMBER = 'payment.phone';
export const EMAIL_ADDRESS = 'payment.email_address';
export const PO_REFERENCE_LABEL = 'payment.po_reference';
export const REWARD_TITLE = 'payment.reward_title';
export const REWARD_SUBTITLE = 'payment.reward_subtitle';
export const REWARD_LIST1 = 'payment.reward_list1';
export const REWARD_LIST2 = 'payment.reward_list2';
export const REWARD_LIST3 = 'payment.reward_list3';
export const REWARD_LIST4 = 'payment.reward_list4';
export const REWARD_LIST5 = 'payment.reward_list5';
export const REWARD_LIST6 = 'payment.reward_list6';
export const REWARD_LIST7 = 'payment.reward_list7';
export const REWARD_BENEFITS = 'payment.reward_benefits';
export const REWARD_MEMBER = 'payment.reward_member';
export const REWARD_MEMBER_CHECKBOX = 'paymeny.reawrd_member_checkbox';
export const PAYMENT_TERMS_OF_USE = 'payment.reward_terms_of_use';
export const REWARD_SIGNUP_LABEL = 'payment.reward_signup_label';
export const PAYMENT_FOOTER_NOTE = 'payment.footer_note';
export const PAYMENT_DELIVERY_LABEL = 'payment.delivery_label';
export const PAYMENT_DELIVERY_LABEL_LINK = 'payment.delivery_label_link';
export const PLACE_ORDER = 'payment.place_order';
export const SUBMITTING = 'payment.submitting';
export const PAYMENT_PRODUCT_POLICY = 'payment.product_policy';
export const PLACE_ORDER_TERMS_CONDITIONS =
  'payment.place_order_terms_conditions';
export const ERROR_ENTER_FIRST_NAME = 'payment.billing_form.error_first_name';
export const ERROR_ENTER_LAST_NAME = 'payment.billing_form.error_last_name';
export const ERROR_ENTER_STREET = 'payment.billing_form.error_street_name';
export const ERROR_ENTER_ZIP_CODE = 'payment.billing_form.error_zip_code';
export const ERROR_ENTER_CITY = 'payment.billing_form.error_city_name';
export const ERROR_SELECT_STATE = 'payment.billing_form.error_select_state';
export const ERROR_ENTER_STATE = 'payment.billing_form.error_state_name';
export const ERROR_ENTER_COUNTRY = 'payment.billing_form.error_country_name';
export const ERROR_ENTER_PHONE = 'payment.billing_form.error_phone';
export const ERROR_ENTER_PASSWORD = 'payment.billing_form.error_password';
export const ERROR_PASSWORD_INVALID = 'signup_password.invalid';
export const ERROR_PASSWORD_MINIMUM = 'signup_password.minimum';
export const ERROR_VERIFY_PASSWORD_MATCH = 'signup.verify_password_match';
export const CANT_BEGIN_WITH_O_OR_1 =
  'payment.billing_form.phone_cant_begin_with_0_or_1';
export const ERROR_ENTER_EMAIL = 'payment.billing_form.error_email';
export const PAYMENT_DECLINED = 'payment.payment_declined';
export const PAYMENT_FRAUDSTER = 'payment.fraudster';
export const PAYMENT_ERROR = 'payment.error';
export const PAYMENT_RETRY = 'payment.retry';
export const PAYMENT_CANCELLED = 'payment.cancelled';
export const PAYMENT_BILLING_INFO_ERROR = 'payment.billing_info';
export const VENMO_CANCELLED = 'venmo.cancelled';
export const VENMO_APP_CANCELLED = 'venmo.app_cancelled';
export const CART_COUNT_SINGLE = 'heading.cart_count_single';
export const CART_COUNT_MULTI = 'heading.cart_count_multi';
export const RECIPIENT_COUNT_SINGLE = 'heading.recipient_count_single';
export const RECIPIENT_COUNT_MULTI = 'heading.recipient_count_multi';
export const RETURN_TO_CART = 'heading.return_to_cart';
export const PAYMENT_SMS_ALERT = 'payment.billing_form.sms_alert';
export const PAYMENT_METHOD = 'Payment Method';
export const CREDIT_CARD = 'Credit Card';
export const CONTINUE_WITH = 'or continue with';
export const VIEW_CART_SUMMARY = 'payment.view_cart_summary';
export const LOG_IN = 'payment.log_in';
export const ACCESS_ADDRESS_BOOK = 'payment.login_to_access';
export const SAME_AS = 'payment.same_as';
export const LOGIN_OR = 'payment.login_or';
export const ORDER_TOTAL = 'payment.order_total';
export const RECIPIENT = 'Recipient';
export const ITEMS = 'Item(s)';
export const CREATE_YOUR_PASSWORD = 'payment.create_your_password';
export const PASSWORD_LABEL = 'payment.password';
export const CONFIRM_PASSWORD_LABEL = 'payment.confirm_password';
export const EMAIL_ALREADY_EXIST = 'payment.email_already_exist';
export const PASSWORD_STRENGTH = 'payment.payment_strength';
export const EMAIL_ALREADY_EXIST_TITLE = 'payment.email_already_exist_title';
export const LOG_IN_BUTTON_BEFORE_TEXT = 'payment.login_in_button_before_text';
export const LOG_IN_BUTTON_TEXT = 'payment.login_in_button_text';
export const LOG_IN_BUTTON_AFTER_TEXT = 'payment.login_in_button_after_text';
export const APPROVE_WITH_ID = 'payment.approve_with_id';
export const CALL_ISSUER = 'payment.call_issuer';
export const CARD_NOT_SUPPORTED = 'payment.card_not_supported';
export const CARD_VELOCITY_EXCEEDED = 'payment.card_velocity_exceeded';
export const CURRENCY_NOT_SUPPORTED = 'payment.currency_not_supported';
export const DO_NOT_HONOR = 'payment.do_not_honor';
export const DO_NOT_TRY_AGAIN = 'payment.do_not_try_again';
export const DUPLICATE_TRANSACTION = 'payment.duplicate_transaction';
export const EXPIRED_CARD = 'payment.expired_card';
export const FRAUDULENT = 'payment.fraudulent';
export const GENERIC_DECLINE = 'payment.generic_decline';
export const INCORRECT_NUMBER = 'payment.incorrect_number';
export const INCORRECT_CVC = 'payment.incorrect_cvc';
export const INCORRECT_PIN = 'payment.incorrect_pin';
export const INCORRECT_ZIP = 'payment.incorrect_zip';
export const INSUFFICIENT_FUNDS = 'payment.insufficient_funds';
export const INVALID_ACCOUNT = 'payment.invalid_account';
export const INVALID_AMOUNT = 'payment.invalid_amount';
export const INVALID_CVC = 'payment.invalid_cvc';
export const INVALID_EXPIRY_MONTH = 'payment.invalid_expiry_month';
export const INVALID_EXPIRY_YEAR = 'payment.invalid_expiry_year';
export const INVALID_NUMBER = 'payment.invalid_number';
export const INVALID_PIN = 'payment.invalid_pin';
export const ISSUER_NOT_AVAILABLE = 'payment.issuer_not_available';
export const LOST_CARD = 'payment.lost_card';
export const MERCHANT_BLACKLIST = 'payment.merchant_blacklist';
export const NEW_ACCOUNT_INFORMATION_AVAILABLE =
  'payment.new_account_information_available';
export const NO_ACTION_TAKEN = 'payment.no_action_taken';
export const NOT_PERMITTED = 'payment.not_permitted';
export const OFFLINE_PIN_REQUIRED = 'payment.offline_pin_required';
export const ONLINE_OR_OFFLINE_PIN_REQUIRED =
  'payment.online_or_offline_pin_required';
export const PICKUP_CARD = 'payment.pickup_card';
export const PIN_TRY_EXCEEDED = 'payment.pin_try_exceeded';
export const PROCESSING_ERROR = 'payment.processing_error';
export const REENTER_TRANSACTION = 'payment.reenter_transaction';
export const RESTRICTED_CARD = 'payment.restricted_card';
export const REVOCATION_OF_ALL_AUTHORIZATIONS =
  'payment.revocation_of_all_authorizations';
export const REVOCATION_OF_AUTHORIZATION =
  'payment.revocation_of_authorization';
export const SECURITY_VIOLATION = 'payment.security_violation';
export const SERVICE_NOT_ALLOWED = 'payment.service_not_allowed';
export const STOLEN_CARD = 'payment.stolen_card';
export const STOP_PAYMENT_ORDER = 'payment.stop_payment_order';
export const TESTMODE_DECLINE = 'payment.testmode_decline';
export const TRANSACTION_NOT_ALLOWED = 'payment.transaction_not_allowed';
export const TRY_AGAIN_LATER = 'payment.try_again_later';
export const WITHDRAWAL_COUNT_LIMIT_EXCEEDED =
  'payment.withdrawal_count_limit_exceeded';
