import * as TK from 'components/PDP/Order/RecipientForm/PickupForm/translations/constants';

export const orderPickupFormCommonLocale = {
  [TK.PICKUP_FORM_IDENTIFICATION]: 'Identification',
  [TK.PICKUP_PHONE_LABEL]: 'Pickup Phone Number',
  [TK.PICKUP_PHONE]: 'Recipient Phone*',
  [TK.PICKUP_DATE_TIME_HEADER]: 'Date & Time',
  [TK.PICKUP_DATE_TIME_LABEL]: 'Pickup Time *',
  [TK.PICKUP_DATE_TIME_ALERT_ON]: 'Pickup On:',
  [TK.PICKUP_FORM_CURBSIDE_SWITCH_LABEL]: 'Curbside Pickup',
  [TK.PICKUP_CURBSIDE_MODEL_LABEL]: 'Vehicle Model*',
  [TK.PICKUP_CURBSIDE_COLOR_LABEL]: 'Vehicle Color*',
  [TK.PICKUP_INSTRUCTION_BUTTON]: 'Pickup Instructions',
  [TK.PICKUP_INSTRUCTIONS_HEADER]: 'Pickup Instructions',
  [TK.PICKUP_INSTRUCTIONS_LABEL]: 'Pickup Instructions (optional)',
  [TK.PICKUP_DETAILS]: 'Pickup Details',
  [TK.PICKUP_CONTACT_DETAILS]: 'We only reach out if there are complications',
};
