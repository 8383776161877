import { FulfillmentMethodsAvailability } from 'types/fulfillment-methods-availability.interface';
import { OrderType } from 'types/cart.types';

export function getOrderType({
  pickup,
  delivery,
  shipment,
}: FulfillmentMethodsAvailability): OrderType {
  if (pickup) {
    return OrderType.Pickup;
  }
  if (delivery) {
    return OrderType.Delivery;
  }
  if (shipment) {
    return OrderType.Shipment;
  }

  return OrderType.NotSpecified;
}
