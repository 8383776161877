import { ComponentItemType } from 'components/Home/types';
import { isDefined } from 'utils/is-defined';
import { useEffect, useState } from 'react';

type Fulfilled = {
  value: HTMLImageElement;
  status: string;
};

type Rejected = {
  reason: string;
  status: string;
};

function allSettled(promises: Promise<HTMLImageElement>[]) {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (val) => ({ status: 'fulfilled', value: val }),
      (err: string) => ({ status: 'rejected', reason: err }),
    ),
  );
  return Promise.all(wrappedPromises);
}

function preloadImages(
  imageSources: string[],
): Promise<(Fulfilled | Rejected)[]> {
  return allSettled(
    imageSources.map((src) => {
      const image = new Image();
      const promise = new Promise<HTMLImageElement>((resolve, reject) => {
        image.onload = () => resolve(image);
        image.onerror = () =>
          reject(new Error(`Preload image failed - ${src}`));
      });
      image.src = src;
      return promise;
    }),
  );
}

export function useImagePreloader(content: ComponentItemType[]): {
  imagesPreloaded: boolean;
} {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    if (!isDefined(content)) {
      setImagesPreloaded(true);
    }

    const images = content.map(({ src }) => src);

    preloadImages(images)
      .then(() => setImagesPreloaded(true))
      .catch(() => {});
  }, [content]);

  return { imagesPreloaded };
}
