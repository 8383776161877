import { callCenterModeActions } from 'components/PLP/CallCenter/feature/slice';
import { selectIsCallCenterModeDisabled } from 'components/PLP/CallCenter/feature/selectors';
import { selectIsSessionFinished } from 'redux/session/availability/availability.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCreateCallCenterSession = (): void => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callCenterMode = useSelector(({ callCenterMode: { data } }) => data);
  const clerkId = sessionStorage.getItem('CallCenterClerkUserID') || '';
  const overNight = sessionStorage.getItem('OverNightCall') || '';
  const isCallCenterModeDisabled = useSelector(selectIsCallCenterModeDisabled);
  const isSessionFinished = useSelector(selectIsSessionFinished);

  useEffect(() => {
    if (
      isSessionFinished &&
      clerkId &&
      isCallCenterModeDisabled &&
      callCenterMode &&
      callCenterMode.id === 0
    ) {
      dispatch(
        callCenterModeActions.createCallCenterSession({
          clerkId,
          overNight,
        }),
      );
      navigate({ search: '' });
    }
  }, [
    clerkId,
    overNight,
    isCallCenterModeDisabled,
    dispatch,
    navigate,
    isSessionFinished,
    callCenterMode,
  ]);
};
