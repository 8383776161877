export const REWARD_LOG_IN_FORM_SIGN_IN = 'reward.log_in.form.sign.in';
export const REWARD_LOG_IN_FORM_SUCCESS = 'reward.log_in.form.success';
export const REWARD_LOG_IN_FORM_SIGNING_IN = 'reward.log_in.form.signing.in';
export const REWARD_LOG_IN_FORM_RESET_LABEL = 'reward.log_in.form.reset.label';
export const REWARD_LOG_IN_FORM_PASSWORD_PLACEHOLDER =
  'reward.log_in.form.password.placeholder';
export const REWARD_LOG_IN_FORM_PASSWORD_LABEL =
  'reward.log_in.form.password.label';
export const REWARD_LOG_IN_FORM_EMAIL_LABEL = 'reward.log_in.form.email.label';
export const REWARD_LOG_IN_FORM_MY_ACCOUNT = 'reward.log_in.my.account';
export const REWARD_LOG_IN_FORM_EMAIL_MESSAGE =
  'reward.log_in.form.email.message';
export const REWARD_LOG_IN_FORM_REQUIRED_MESSAGE =
  'reward.log_in.form.required.message';
export const REWARD_DETAILED_MESSAGE = 'reward.detailed.message';
export const REWARD_ORDER_MESSAGE = 'reward.order.message';
export const REMINDER_DELETE_MSG = 'reminder.delete.msg';
export const ADDRESS_BOOK_DELETE_MSG = 'address.book.delete.msg';
export const MY_ACCOUNT = 'my.account';
export const MY_REWARDS = 'my.rewards';
export const ORDER_HISTORY = 'order.history';
export const FAQ = 'faq';
export const EDIBLE_REWARDS = 'edible.reward';
export const REWARD_MESSAGE = 'reward.message';
export const PROMO_MESSAGE = 'promo_message_text';
export const PROMO_EXPIRES = 'promo_expires_text';
export const PROMO_CODE = 'promo_code_text';
export const REWARD_GIFT_REMINDER = 'reward.gift.reminder';
export const REWARD_MY_LOCATIONS = 'reward.my.locations';
export const REWARD_REMINDER = 'reward.reminder';
export const NEW_CONTACT = 'new.contact';
export const NO_REMINDER_MSG = 'no.reminder.msg';
export const NO_ADDRESS_MSG = 'no.address.msg';
export const NO_LOCATION_MSG = 'no.location.msg';
export const HOME_LINK = 'home.link';
export const BACK_LINK = 'back.link';
export const CLOSE = 'close';
export const REWARDS_LINK = 'rewards.link';
export const REWARDS_SIGNUP_LINK = 'rewards.signup.link';
export const REWARDS_TOP_BANNER = 'rewards.top.banner';
export const REWARDS_BOTTOM_BANNER = 'rewards.bottom.banner';
export const GIFT_REMINDER = 'gift.reminder';
export const ADDRESS_BOOK = 'address.book';
export const PASSWORD_NOT_MATCHED_MSG = 'password.not.matched.msg';
export const CONFIRMED_PASSWORD_REQ_MSG = 'confirmed.password.req.msg';
export const PASSWORD_REQ_MSG = 'password.req.msg';
export const OLD_PASSWORD_REQ_MSG = 'old.password.req.msg';
export const OLD_PASSWORD_INCORRECT_MSG = 'old.password.incorrect.msg';
export const PASSWORD_FAILED_MSG = 'password.failed.msg';
export const PASSWORD_CHANGED_SUCCESS_MSG = 'password.changed.success.msg';
export const PASSWORD_HISTORY_VALIDATION_FAILURE =
  'password.history.validation.failure.msg';
export const CORRECT_PASSWORD_REQ_MSG = 'correct.password.req.msg';
export const ADDRESS_BOOK_GRID_ACTION = 'address.book.action';
export const ADDRESS_BOOK_GRID_CITY_STATE = 'address.book.city.state';
export const ADDRESS_BOOK_GRID_NAME = 'address.book.name';
export const GIFT_REMINDER_DELETE = 'gift.reminder.delete';
export const GIFT_ADDRESS_BOOK_DELETE = 'address.book.delete';
export const DELETE_CONFIRMATION_MSG = 'delete.confirmation.msg';
export const CHOOSE_OPTION = 'remider.choose_option';
export const REMINDER_TITLE = 'reminder.title';
export const REMINDER_TITLE_MSG = 'reminder.title.msg';
export const REMINDER_TITLE_SUB_MSG = 'reminder.title.sub.msg';
export const REMINDER_OCCASION = 'Occasion*';
export const REMINDER_OCCASION_ERROR = 'Choose your occasion';
export const REMINDER_OCCASION_DATE = 'Occasion Date*';
export const REMINDER_OCCASION_DATE_ERROR = 'Choose reminder date';
export const REMINDER_REMIND_ME = 'Remind Me*';
export const REMINDER_NOTE_ERROR_TEXT = 'reminder.note_error_text';
export const REMINDER_REMIND_ME_ERROR = 'Choose reminder period';
export const REWARD_DIALOG_SAVE = 'reward.dialog.save';
export const REWARD_DIALOG_OK = 'reward.dialog.ok';
export const REMINDER_COMMMENT = 'reminder.comment';
export const REMINDER_NOTES = 'reminder.notes';
export const REMINDER_TEXT = 'reminder.text';
export const REMINDER_WEEK_TEXT = 'reminder.week.text';
export const REWARDS_TEXT = 'rewards.text';

export const ACCOUNT_PASSWORD = 'account.password';
export const ACCOUNT_PASSWORD_TITLE = 'account_password_title';
export const ACCOUNT_SAVE = 'account_save';
export const CANCEL_TEXT = 'cancel_text';
export const PASSWORD_TITLE = 'password_title';
export const ACCOUNT_PASSWORD_SUBTITLE = 'account.password_subtitle';
export const REMINDER_SEARCH_TEXT = 'reminder.search.text';
export const ADDRESS_SEARCH_TEXT = 'address.search.text';
export const SEARCH_TEXT = 'search.text';
export const ORDER_BUTTON_TEXT = 'order.button.text';
export const NO_ORDERS_FOUND = 'order-history.no.orders.text';
export const ORDER_TEXT = 'order.text';
export const PASSWORD_REQUIREMENTS_MESSAGE =
  'account.change_password_requirements';
export const SIGNUP_FIRST_NAME = 'signup_first_name.text';
export const SIGNUP_LAST_NAME = 'signup_last_name.text';
export const PHONE_NUMBER = 'signup_phone_number';
export const PHONE = 'signup_phone';
export const CONTACT_DIALOG_TITLE = 'contact_dialog_title';
export const SIGNUP_BIRTHDAY = 'signup_birthday';
export const EMAIL_ADDRESS = 'email_address';
export const EMAIL_TEXT = 'email_text';
export const ACTION_ORDER = 'action_order';
export const ACTION_EDIT = 'action_edit';
export const ACTION_DELETE = 'action_delete';
export const ACTION_REMOVE = 'action_remove';
export const ACTION_TEXT = 'action_text';
export const OLD_PASSWORD = 'old_password';
export const NEW_PASSWORD = 'new_password';
export const CONFIRM_PASSWORD = 'confirm_password';
export const ACCOUNT_TITLE = 'account.title';
export const ACCOUNT_MY_ADDRESS = 'account.my_address';
export const ACCOUNT_ADDRESS_TYPE = 'account.address_type';
export const ACCOUNT_STREET_ADDRESS = 'account.street_address';
export const ACCOUNT_BUSINESS_NAME = 'reward.business_name';
export const ACCOUNT_APARTMENT = 'account_aprtment';
export const ACCOUNT_COUNTRY = 'account_country';
export const ACCOUNT_CITY = 'account_city';
export const ACCOUNT_STATE = 'account_state';
export const ACCOUNT_ZIP = 'account_zip';
export const ACCOUNT_CHANGE_PASSWORD = 'account.change_password';
export const ACCOUNT_ADDRESS = 'account_address';
export const OTHERS_TEXT = 'others_text';
export const EVENT_DATE = 'event_date';
export const EVENT = 'event';
export const EVENT_ACTION = 'event_action';
export const PRIOR_1_DAY = 'prior_1_day';
export const PRIOR_2_DAYS = 'prior_2_days';
export const PRIOR_5_DAYS = 'prior_5_days';
export const PRIOR_8_DAYS = 'prior_8_days';
export const PRIOR_10_DAYS = 'prior_10_days';
export const PRIOR_15_DAYS = 'prior_15_days';
export const TRACK_TEXT = 'track_text';
export const VIEW_TEXT = 'view_text';
export const ORDER_TYPE = 'order_type';
export const ORDER_NUMBER = 'order_number';
export const RECIPIENT_NAME = 'recipient_name';
export const FULFILLMENT_DATE = 'fulfillment_date';
export const ORDER_TOTAL = 'order_total';
export const ORDER_ACTIONS = 'order_actions';
export const ORDERS_UNTIL = 'ORDERS_UNTIL';
export const FREE_TXT = 'free_txt';
export const TITLE_TEXT1 = 'title_text1';
export const TITLE_TEXT2 = 'title_text2';
export const TITLE_TEXT3 = 'title_text3';
export const TITLE_TEXT4 = 'title_text4';
export const TITLE_TEXT5 = 'title_text4';
