import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';

export const RedirectUrl: FC = () => {
  const [redirectMeta, setRedirectMeta] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlData = new URL(window.location.href);
    let { pathname } = urlData;

    // Normalize pathname by removing trailing slash
    if (pathname.endsWith('/') && pathname.length > 1) {
      pathname = pathname.slice(0, -1);
      navigate(pathname, { replace: true });
    }

    const arrUrlPath = pathname.split('/').filter(Boolean);

    const hasUpperCase = (str: string) => /[A-Z]/.test(str);

    const shouldRedirect = arrUrlPath.some(
      (segment) => hasUpperCase(segment) || segment.includes('%20'),
    );

    if (shouldRedirect) {
      const normalizedPathname = pathname.replace('%20', '-').toLowerCase();
      const redirectUrl = urlData.origin + normalizedPathname;
      setRedirectMeta(`0; url=${redirectUrl}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!redirectMeta) return null;
  return (
    <Helmet>
      <meta httpEquiv="refresh" content={redirectMeta} />
    </Helmet>
  );
};
