import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const wrapper: ThemedSxProps = {
  minHeight: { md: '40px', sm: '40px', xs: '32px' },
  '& .slick-list': {
    pb: { md: 5, sm: 5, xs: 4 },
  },
  display: 'flex',
  justifyContent: 'center',
  '& .slick-slider': {
    width: '100%',
  },
  '& .slick-track img': {
    height: { md: '720px', sm: '560px', xs: '400px' },
    objectFit: 'cover',
    maxInlineSize: '100%',
    blockSize: 'auto',
  },
  '& .slick-slide': {
    width: '100% !important',
  },
  '& .slick-track': {
    width: '100% !important',
  },
};
