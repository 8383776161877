/* eslint-disable no-param-reassign */
import { OrderExpressFulfillments } from 'redux/order-confirmation/types/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchOrderFulfillmentByOrderNumber } from 'redux/order-confirmation/order-express-fulfillments.action';

export type OrderExpressFulfillmentsState = {
  orderExpressFulfillments: {
    data: OrderExpressFulfillments | null;
    status: Status;
  };
};

export const initialState: OrderExpressFulfillmentsState = {
  orderExpressFulfillments: {
    data: null,
    status: Status.INIT,
  },
};

const OrderExpressFulfillmentsSlice = createSlice({
  name: 'orderExpressFulfillments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderFulfillmentByOrderNumber.pending, (state) => {
        state.orderExpressFulfillments.status = Status.PENDING;
      })
      .addCase(fetchOrderFulfillmentByOrderNumber.rejected, (state) => {
        state.orderExpressFulfillments.status = Status.ERROR;
      })
      .addCase(
        fetchOrderFulfillmentByOrderNumber.fulfilled,
        (state, { payload }) => {
          state.orderExpressFulfillments.data = payload;
          state.orderExpressFulfillments.status = Status.FULFILLED;
        },
      )
      .addDefaultCase((state) => state);
  },
});

export const { reducer: orderExpressFulfillmentsReducer } =
  OrderExpressFulfillmentsSlice;
