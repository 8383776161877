import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';

export const steps = {
  [AvailabilityStep.NOT_SELECTED]: -1,
  [AvailabilityStep.OWN_BOX_ADDONS]: 0,
  [AvailabilityStep.PRODUCT_OPTIONS]: 0,
  [AvailabilityStep.ZIP_AVAILABILITY]: 0,
  [AvailabilityStep.DATE_SELECTION]: 1,
  [AvailabilityStep.DELIVERY_METHOD_SELECTION]: 2,
  [AvailabilityStep.SUGGESTED_DATE_SELECTION]: 2,
  [AvailabilityStep.SUGGESTED_PRODUCT_SELECTION]: 2,
  [AvailabilityStep.STORE_LIST_SELECTION]: 3,
  [AvailabilityStep.CONTINUE]: 3,
  [AvailabilityStep.OWN_DESSERT_BOARD_BAKED]: 0,
  [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_BERRIES]: 1,
  [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_FRUIT]: 2,
  [AvailabilityStep.DESSERT_OWN_BOX_ADDONS]: 0,
  [AvailabilityStep.TITLE]: 0,
  [AvailabilityStep.OWN_COOKIE_BOX_EVERYDAY]: 0,
  [AvailabilityStep.OWN_COOKIE_BOX_LIMITED]: 1,
  [AvailabilityStep.OWN_6_COOKIE_BOX]: 2,
  [AvailabilityStep.OWN_12_COOKIE_BOX]: 3,
};

export const notSelectedStep = steps[AvailabilityStep.NOT_SELECTED];
export const zipStep = steps[AvailabilityStep.ZIP_AVAILABILITY];
export const dateStep = steps[AvailabilityStep.DATE_SELECTION];
export const orderTypeStep = steps[AvailabilityStep.DELIVERY_METHOD_SELECTION];
export const storeListStep = steps[AvailabilityStep.STORE_LIST_SELECTION];
export const bakedStep = steps[AvailabilityStep.OWN_DESSERT_BOARD_BAKED];
export const dippedBerriesStep =
  steps[AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_BERRIES];
export const dippedFruitStep =
  steps[AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_FRUIT];
export const cookieEverydayStep =
  steps[AvailabilityStep.OWN_COOKIE_BOX_EVERYDAY];
export const cookieLimitedStep = steps[AvailabilityStep.OWN_COOKIE_BOX_LIMITED];
