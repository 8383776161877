import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const Container = styled('div')({
  position: 'relative',
});

export const Video = styled('video')({
  display: 'block',
  width: '100%',
});

export const volumeContainer: ThemedSxProps = (theme) => ({
  position: 'absolute',
  bottom: 10,
  right: 15,
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    bottom: 20,
    right: 25,
  },
});
