import {
  selectIsFulfilled,
  selectUserLocale,
} from 'providers/Session/feature/user.selectors';
import {
  selectIsLocaleFulfilled,
  selectIsLocaleInit,
  selectLocale,
} from 'redux/locale/locale.selectors';
import { useSelector } from 'react-redux';

export const useLocale = (): {
  locale: string;
  isLocaleChanged: boolean;
  isLocaleInit: boolean;
} => {
  const isFulfilled = useSelector(selectIsFulfilled);
  const isLocaleChanged = useSelector(selectIsLocaleFulfilled);
  const isLocaleInit = useSelector(selectIsLocaleInit);
  const userLocale = useSelector(selectUserLocale);
  const currentLocale = useSelector(selectLocale);

  return {
    locale: isFulfilled ? userLocale : currentLocale,
    isLocaleInit,
    isLocaleChanged,
  };
};
