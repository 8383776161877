import { HttpStatusCode } from 'enums/http-status-code';
import { RequestOptions } from 'redux/types/request-options.interface';
import { availabilityStoreService } from 'api/availability-store.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

type Props = {
  date: Date;
  arrangementId: number;
  productId: number;
  zip: string;
  options?: RequestOptions;
};

export const getAvailableStoreForShipment = createAsyncThunk(
  'availability/stores/for-shipment/fetch',
  async (
    { date, arrangementId, productId, zip, options }: Props,
    { rejectWithValue },
  ) => {
    try {
      const { data, status } =
        await availabilityStoreService.getAvailableStoreForShipment({
          areaName: zip,
          date,
          arrangementId,
          productId,
          options,
        });
      if (status === HttpStatusCode.GRACEFULLY_NOT_FOUND) {
        return rejectWithValue({ data, status });
      }
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
