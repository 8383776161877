type PasswordRegex = {
  regex: RegExp;
  label: 'Poor' | 'Fair' | 'Good' | 'Excellent';
};

export const passwordRegexStrength: PasswordRegex[] = [
  // Poor
  { regex: /^[a-z]{7,}$/, label: 'Poor' },
  { regex: /^[A-Z]{7,}$/, label: 'Poor' },
  { regex: /^\d{7,}$/, label: 'Poor' },
  {
    regex: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z]{2,}$/,
    label: 'Poor',
  },
  { regex: /^[#?!@$%^&*-,.]{1,}$/, label: 'Poor' },
  {
    regex: /^(?=.*[a-z])(?=.*[#?!@$%^&*\-,.])[a-z#?!@$%^&*\-,.]{2,}$/,
    label: 'Poor',
  },
  {
    regex: /^(?=.*[A-Z])(?=.*[#?!@$%^&*\-,.])[a-z#?!@$%^&*\-,.]{2,}$/,
    label: 'Poor',
  },
  { regex: /^(?=.*[A-Z])(?=.*\d).{4,}$/, label: 'Poor' },
  { regex: /^(?=.*[a-z])(?=.*\d).{4,}$/, label: 'Poor' },
  {
    regex: /^(?=.*[#?!@$%^&*\-,.])(?=.*\d).{2,}$/,
    label: 'Poor',
  },

  // Fair
  {
    regex: /^(?=.*[a-z])(?=.*[#?!@$%^&*\-,.])[a-z#?!@$%^&*\-,.]{7,}$/,
    label: 'Fair',
  },
  {
    regex: /^(?=.*[A-Z])(?=.*[#?!@$%^&*\-,.])[A-Z#?!@$%^&*\-,.].{4,}$/,
    label: 'Fair',
  },
  { regex: /^[#?!@$%^&*-,.]{7,}$/, label: 'Fair' },
  {
    regex: /^(?=.*[#?!@$%^&*\-,.])(?=.*\d).{4,}$/,
    label: 'Fair',
  },
  { regex: /^(?=.*[a-z])(?=.*\d).{8,}$/, label: 'Fair' },
  {
    regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[#?!@$%^&*\-,.]).{4,}$/,
    label: 'Fair',
  },
  {
    regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
    label: 'Fair',
  },

  // Good
  {
    regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[#?!@$%^&*\-,.]).{8,}$/,
    label: 'Good',
  },
  {
    regex: /^(?=.*[A-Z])(?=.*[#?!@$%^&*\-,.])(?=.*\d).{8,}$/,
    label: 'Good',
  },
  {
    regex: /^(?=.*[a-z])(?=.*[#?!@$%^&*\-,.])(?=.*\d).{8,}$/,
    label: 'Good',
  },

  // Excellent
  {
    regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&*\-,.]).{9,}$/,
    label: 'Excellent',
  },
];
