/* eslint-disable no-param-reassign */
import { ArrangementGroup } from 'api/arrangement-groups.api.service';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchArrangementGroup } from 'components/common/ArrangementGroup/feature/actions';

export type ArrangementGroupState = {
  status: Status;
  error: string | null;
  arrangementGroup: ArrangementGroup | null;
};

export const initialState: ArrangementGroupState = {
  status: Status.INIT,
  error: null,
  arrangementGroup: null,
};

const slice = createSlice({
  initialState,
  name: 'plp/arrangement-group',
  extraReducers: (builder) => {
    builder
      .addCase(fetchArrangementGroup.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchArrangementGroup.fulfilled, (state, action) => {
        state.status = Status.FULFILLED;
        state.arrangementGroup = action.payload;
        state.error = null;
      })
      .addCase(fetchArrangementGroup.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = (payload as Error)?.message;
      });
  },
  reducers: {},
});

export const plpArrangementGroupReducer = slice.reducer;
