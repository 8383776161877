import { useEffect, useState } from 'react';

/**
 * Hook that works like useEffect, but skips first execution.
 * Can work as regular useEffect if shouldSkipMount === false
 */
export const useSkipMountEffect = (
  callback: React.EffectCallback,
  dependencies: React.DependencyList,
  options?: { shouldSkipMount?: boolean },
): void => {
  const [shouldUpdate, setShouldUpdate] = useState(
    options?.shouldSkipMount ?? true,
  );

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
      return;
    }
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
