import { Box, Link, SxProps } from '@mui/material';
import { SecondaryBannerContent } from 'components/Home/SecondaryBanner/components/secondary-banner-content';
import { TextAndButtonAlignment } from 'components/Home/SecondaryBanner/types';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface Props {
  secondaryBannerItemUrl: string;
  secondaryBannerItemSrc: string;
  secondaryBannerItemName?: string;
  secondaryBannerItemAltText?: string;
  secondaryBannerItemTitleText?: string;
  secondaryBannerItemSubTitleText?: string;
  secondaryBannerItemButtonText?: string;
  secondaryBannerItemButtonTextColor?: string;
  secondaryBannerItemTextColor?: string;
  secondaryBannerItemTextAlignment: TextAndButtonAlignment;
  secondaryBannerItemButtonAlignment: TextAndButtonAlignment;
  secondaryBannerLength: number;
  analyticsClickHandler?: () => void;
}

export const SecondaryBannerLink: FC<Props> = ({
  secondaryBannerItemUrl = '',
  secondaryBannerItemSrc = '',
  secondaryBannerItemName = '',
  secondaryBannerItemAltText = '',
  secondaryBannerItemTitleText,
  secondaryBannerItemSubTitleText,
  secondaryBannerItemButtonText,
  secondaryBannerItemButtonTextColor,
  secondaryBannerItemTextColor,
  secondaryBannerItemTextAlignment,
  secondaryBannerItemButtonAlignment,
  secondaryBannerLength,
  analyticsClickHandler,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const handleClick = () => {
    segment.bannerClicked(
      secondaryBannerItemUrl,
      secondaryBannerItemName,
      'Home Page Secondary Banner',
      userSession,
      userAccountInformation,
    );
    if (analyticsClickHandler) analyticsClickHandler();
  };

  const imageContainer: SxProps = {
    width: '100%',
    height: ['264px', '264px', '320px'],
    maxWidth: ['100%', '768px', '100%'],
    backgroundImage: `url(${secondaryBannerItemSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: { xs: '20px', md: '40px', lg: '40px' },
    position: 'relative',
  };

  return (
    <Box
      sx={imageContainer}
      data-test="secondary-banner-widget-image"
      component={Link}
      href={secondaryBannerItemUrl}
      onClick={handleClick}
      aria-label={secondaryBannerItemAltText}
    >
      <SecondaryBannerContent
        titleText={secondaryBannerItemTitleText}
        subTitleText={secondaryBannerItemSubTitleText}
        buttonText={secondaryBannerItemButtonText}
        buttonTextColor={secondaryBannerItemButtonTextColor}
        textColor={secondaryBannerItemTextColor}
        textAlignment={secondaryBannerItemTextAlignment}
        buttonAlignment={secondaryBannerItemButtonAlignment}
        secondaryBannerLength={secondaryBannerLength}
      />
    </Box>
  );
};
