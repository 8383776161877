import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.locale.constants';

export const deliveryMethodsCommonLocale = {
  [TK.DELIVERY]: 'Delivery',
  [TK.PICK_UP]: 'Pick Up',
  [TK.DELIVERY_DESCRIPTION]:
    'What could be sweeter than hand delivery? Treats that arrive fresh with picture-perfect presentation, of course. With Edible® delivery, that’s just standard.',
  [TK.AVAILABLE_FOR_OTHER_DATES]: 'Available for other dates',
  [TK.CHOOSE_ANOTHER_DATE]: 'Select another date for pickup',
  [TK.AVAILABLE_FOR_OTHER_PRODUCTS]: 'Available for other products',
  [TK.OTHER_PRODUCTS_AVAILABLE]:
    'We’re sorry, but this product is not available on the date you selected. Please choose another product available on your selected date.',
  [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
    'Sorry! Delivery is unavailable for the selected date. Click to view available dates.',
  [TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
    'Sorry! Delivery is unavailable for the selected product. Click to see other products which are available for delivery for the selected date.',
  [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES]:
    'Sorry! Pick up is unavailable for the selected date. Click to view available dates.',
  [TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS]:
    'Sorry! The product you selected is not available for in-store pick up. See if there’s something sweet you’d rather grab!',
  [TK.PICKUP_IN_STORE]: 'Pick Up In Store',
  [TK.PICKUP_DESCRIPTION]:
    "Stores nationwide for fast, convenient pickup that's always",
  [TK.FREE]: 'FREE',
};
