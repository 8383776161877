export const PICKUP_DATE_TIME_ALERT_ON = 'pickup_form.date_time.alert.on';
export const PICKUP_DATE_TIME_HEADER = 'pickup_form.date_time.header';
export const PICKUP_DATE_TIME_LABEL = 'pickup_form.date_time.label';
export const PICKUP_FORM_IDENTIFICATION = 'pickup_form.identification';
export const PICKUP_PHONE_LABEL = 'pickup_form.phone.label';
export const PICKUP_PHONE = 'pickup_form.phone';
export const PICKUP_FORM_CURBSIDE_SWITCH_LABEL =
  'pickup_form.curbside.switch.label';
export const PICKUP_CURBSIDE_MODEL_LABEL = 'pickup_form.curbside.model.label';
export const PICKUP_CURBSIDE_COLOR_LABEL = 'pickup_form.curbside.color.label';
export const PICKUP_INSTRUCTION_BUTTON = 'pickup_form.instruction.button';
export const PICKUP_INSTRUCTIONS_HEADER = 'pickup_form.instruction.header';
export const PICKUP_INSTRUCTIONS_LABEL = 'pickup_form.instruction.input.label';
export const PICKUP_DETAILS = 'pickup_form.pickup.details';
export const PICKUP_CONTACT_DETAILS = 'pickup_form.pickup.contact.details';
