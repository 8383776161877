export enum StructuredDataValues {
  ITEM_LIST = 'ItemList',
  LIST_ITEM = 'ListItem',
  PRODUCT = 'Product',
  PERSON = 'Person',
  REVIEW = 'Review',
  RATING = 'Rating',
  ORGANIZATION = 'Organization',
  AGGREGATE_RATING = 'AggregateRating',
  AGGREGATE_OFFER = 'AggregateOffer',
  SCHEMA = 'https://schema.org/',
  USD = 'USD',
  OFFER = 'Offer',
  COLLECTION_PAGE = 'CollectionPage',
  BUSINESS_GIFTS_PAGE = 'BusinessGiftsPage',
}

export enum StructuredDataKeys {
  CONTEXT = '@context',
  TYPE = '@type',
}
