import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const desktopColumnWidth = ['100%', '100%', '1060px'];

export const header: SxProps = {
  my: 2,
  pl: [0.25, 0.25, 0],
  pr: 0.25,
  width: 1,
};

export const chipsContainer: SxProps = {
  mt: [2, 2, 0],
  mb: [0, 0, 2],
  '&:empty': { display: 'none' },
};

export const main: ThemedSxProps = (theme) => ({
  display: 'flex',
  width: 1,
  flexWrap: 'wrap',
  maxWidth: '1650px',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    padding: '20px',
  },
});

export const containerWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 1,
  mx: 'auto',
  fontSize: '16px',
};

export const arrangementsWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 1,
  px: ['20px', '20px', '0px'],
};

export const topComponentSection: SxProps = {
  height: '100%',
  width: '100%',
  section: {
    padding: ['10px 0px', '10px 30px', '10px 0px'],
    maxWidth: desktopColumnWidth,
    margin: '0 auto',
  },
};

export const bottomComponentSection: SxProps = {
  height: '100%',
  width: '100%',
  section: {
    padding: ['10px 0px', '10px 30px', '10px 0px'],
    maxWidth: desktopColumnWidth,
    margin: '0 auto',
  },
};

export const blogCarouselsection: SxProps = {
  maxWidth: desktopColumnWidth,
  margin: '0 auto',
};

export const footerDetailsSection: SxProps = {
  maxWidth: desktopColumnWidth,
  margin: '0 auto',
  padding: ['20px', '20px', '20px 0px'],
};
