import { FulfillmentOptionType } from 'types/cart.types';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isFulfilled, isPending } from 'utils/status.comparer';

export const selectSuggestedArrangements =
  (orderType: FulfillmentOptionType) => (state: RootState) =>
    state.suggestedArrangementsNew[orderType].data;

export const selectIsFulfilled = (orderType: FulfillmentOptionType) =>
  createSelector(
    (state: RootState) => state.suggestedArrangementsNew,
    (suggested) => isFulfilled(suggested[orderType].status),
  );

export const selectIsPending = (orderType: FulfillmentOptionType) =>
  createSelector(
    (state: RootState) => state.suggestedArrangementsNew,
    (suggested) => isPending(suggested[orderType].status),
  );
