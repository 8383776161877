import { ArrangementPreview } from 'api/arrangements/types';
import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { fetchArrangements } from 'containers/BusinessGifting/feature/actions';

const initialState = InitialStateFactory.create<ArrangementPreview[]>([]);

const slice = createSlice({
  name: 'business-gifting',
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(initialState, fetchArrangements),
});

export const businessGiftingReducer = slice.reducer;
