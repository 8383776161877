import {
  LoginStatus,
  ParsedCallCenterLoginResponse,
} from 'api/auth-legacy/log-in.misc';
import { appInsights } from 'utils/telemetry/app-insights';
import { authLegacyApiService } from 'api/auth-legacy/auth.legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNull } from 'utils/null.utils';
import { parseAxiosError } from 'utils/errors';

function isResponseValid(
  response: unknown,
): response is ParsedCallCenterLoginResponse {
  if (typeof response !== 'object' || isNull(response)) {
    return false;
  }
  if ('_Status' in response && '_Data' in response) {
    const typedResponse = response as Record<'_Status' | '_Data', unknown>;
    return (
      typeof typedResponse._Data === 'string' &&
      typeof typedResponse._Status === 'string'
    );
  }

  return false;
}

export const logIn = createAsyncThunk(
  'callCenterLogin/logIn',
  async (body: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const { d } = await authLegacyApiService.logInCallCenterAccount(body);
      const response: unknown = JSON.parse(d);

      if (!isResponseValid(response)) {
        appInsights.trackEvent({ name: 'Call center login bad response' });
        return rejectWithValue(response);
      }
      if (response._Status === LoginStatus.Error) {
        appInsights.trackEvent({ name: 'Call center login failed' });
        return rejectWithValue(response);
      }

      appInsights.trackEvent({ name: 'Call center logged in' });

      return { name: response._Data };
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
