import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const Image = styled('img')({
  display: 'block',
  width: '100%',
  minHeight: '62px',
});

export const wrapperWithoutText: ThemedSxProps = (theme) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: { xs: '180px', sm: '250px', md: '320px' },
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const videoContainerWitoutText: ThemedSxProps = {
  width: { xs: '100%', sm: '424px', md: '568px' },
  margin: '0 auto',
};

export const wrapper: ThemedSxProps = (theme) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  [theme.breakpoints.up(360)]: {
    height: '427px',
  },
  [theme.breakpoints.up(410)]: {
    height: '450px',
  },
  [theme.breakpoints.up(767)]: {
    height: '250px',
  },
  [theme.breakpoints.up(1023)]: {
    height: '320px',
  },
  [theme.breakpoints.up(1200)]: {
    height: '320px',
  },
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const videoTextContainer: ThemedSxProps = {
  position: 'absolute',
  width: { xs: '100%', sm: '240px', md: '460px' },
  height: { xs: '210px', sm: '250px', md: '320px' },
  textAlign: { md: 'left', sm: 'center', xs: 'center' },
  fontFamily: fonts.poppins,
  float: 'left',
};

export const videoWrapper: ThemedSxProps = (theme) => ({
  position: 'absolute',
  width: { xs: '100%', sm: '424px', md: '568px' },
  height: { xs: '180px', sm: '250px', md: '320px' },
  display: 'block',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
  float: 'left',
  '& img': {
    width: { xs: '100%', sm: '424px', md: '568px' },
    height: { xs: '180px', sm: '250px', md: '320px' },
    cursor: 'pointer',
  },
});

export const titleText: ThemedSxProps = (theme) => ({
  color: theme.palette.text.primary,
  fontFamily: fonts.poppins,
  fontSize: { md: '24px', sm: '20px', xs: '20px' },
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: { md: '32.02px', sm: '26.04px', xs: '26.4px' },
  letterSpacing: '0.15px',
  marginBottom: { md: '16px', sm: '12px', xs: '16px' },
  paddingTop: { xs: '0px', sm: '0px', md: '26px' },
});

export const description: ThemedSxProps = (theme) => ({
  color: theme.palette.text.primary,
  fontFamily: fonts.poppins,
  fontSize: { md: '16px', sm: '14px', xs: '14px' },
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: { md: '24px', sm: '20.02px', xs: '20.02px' },
  mb: { md: '16px', sm: '12px', xs: '24px' },
  whiteSpace: 'pre-wrap',
});

export const learnMoreButton: ThemedSxProps = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  textAlign: 'center',
  boxSizing: 'border-box',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    height: '40px',
    padding: '9px 24px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    height: '40px',
    padding: '9px 24px',
  },
  [theme.breakpoints.up('md')]: {
    width: '201px',
    height: '56px',
    padding: '15px 56px',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    textDecoration: 'none',
    boxShadow: 'none',
  },
  '.MuiTypography-root': {
    fontWeight: 600,
    fontSize: '15px',
    fontstyle: 'normal',
    color: colors.white,
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
});
