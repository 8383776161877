import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';
import { isPositive } from 'utils/numbers';
import { selectArrangementName } from 'redux/PDP/arrangement/arrangement.selectors';

export const selectIsCartLoading = createSelector(
  ({ cart: { addingStatus } }: RootState) => addingStatus,
  (status) => [Status.PENDING, Status.FULFILLED].includes(status),
);

export const getIsRecipientIdExist = createSelector(
  ({ cart: { cartRecipientId } }: RootState) => cartRecipientId,
  (id) => isPositive(id),
);

export const selectIsCartCountBlocked = createSelector(
  ({ cart: { cartCountStatus } }: RootState) => cartCountStatus,
  (status) => isBlocked(status),
);

export const selectTitle = createSelector(
  ({
    plp: {
      filter: { filterItem },
    },
  }: RootState) => filterItem?.mls[0]?.name,
  ({ plpArrangementGroup: a }: RootState) => a.arrangementGroup?.mls || [],
  selectArrangementName,
  (filterName, [info], name) =>
    name ||
    (info?.giftGuideContentTagLine &&
      info?.giftGuideContentHeader &&
      `${info?.giftGuideContentTagLine} ${info?.giftGuideContentHeader}`.trim()) ||
    filterName ||
    '',
);

export const getIsUpdateCartExist = createSelector(
  ({ cart: { cartUpdated } }: RootState) => cartUpdated,
  (cartUpdated) => cartUpdated,
);

export const getCartDetail = createSelector(
  ({ cart: { cartDetail } }: RootState) => cartDetail,
  (cartDetail) => cartDetail,
);

export const getCartRecipientId = createSelector(
  ({ cart: { cartDetail } }: RootState) => cartDetail,
  (cartDetail) => {
    if (cartDetail.length > 0) {
      return cartDetail[0].cartRecipientId;
    }
    return -1;
  },
);
