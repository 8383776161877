import * as TK from 'components/common/StickyAlert/translations/locale.constants';

export const stickyAlertCommonLocale = {
  [TK.ALERT_OWN_BOX_ADDONS]: 'Please select at least 1 item',
  [TK.ALERT_PRODUCT_OPTION]: 'Please, select an option',
  [TK.ALERT_AVAILABILITY_ZIP]: 'Please enter ZIP Code to Continue',
  [TK.ALERT_AVAILABILITY_DATE]: 'Please select Date to Continue',
  [TK.ALERT_AVAILABILITY_DELIVERY_METHOD]:
    'Please select Fullfilment Type to Continue',
  [TK.ALERT_AVAILABILITY_STORE]: 'Please select the Store to Continue',
  [TK.ALERT_SUGGESTED_DATE]:
    'Please select a suggested date to continue with your order',
  [TK.ALERT_SUGGESTED_PRODUCT]:
    'Please choose from suggested products to continue with shopping',
  [TK.ALERT_DESSERT_OWN_BOX_ADDONS]:
    'Please select at least 1 item from each of the 3 options below to continue.',
  [TK.ALERT_6_COOKIE_BOX_ADDONS]:
    'Please select at least 1 cookie to continue.',
  [TK.ALERT_12_COOKIE_BOX_ADDONS]:
    'Please select at least 1 cookie from the first list below to continue.',
  [TK.TITLE]: 'Make A Selection',
};
