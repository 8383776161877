import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addOnUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import React, { FC } from 'react';

interface Props {
  productUpgrade: OrderSubItem;
}

export const UpgradeProduct: FC<Props> = ({ productUpgrade }) => (
  <Box sx={styles.upgrade}>
    <Image src={addOnUrl(productUpgrade.addOnOptionImage)} />
    <Box width="100%">
      <div>
        <PureHtmlTypography
          sx={styles.nameStyle}
          variant="subtitle2"
          mb={1}
          mt={1}
        >
          {productUpgrade.name
            .replace(`- ${productUpgrade.sizeName}`, '')
            .replace(`: ${productUpgrade.sizeName}`, '')}
        </PureHtmlTypography>
        <PureHtmlTypography sx={styles.addonSizeTxt} variant="body2" mt={1}>
          {productUpgrade.sizeName}
        </PureHtmlTypography>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="end"
        >
          <Typography variant="body3" mt={-2}>
            {roundNumberValue(productUpgrade.price)}
          </Typography>
        </Box>
      </div>
    </Box>
  </Box>
);
