import React, { FC } from 'react';

type Props = {
  imgPreview: string[];
};

export const DessertBoardPreviewImage: FC<Props> = ({ imgPreview }) => {
  return (
    <>
      {imgPreview.map((url, index) => (
        <img
          key={url}
          src={url}
          alt={`Dessert Board Preview ${index}`}
          width={40}
          height={40}
          style={{ border: '1px solid #c9c9c9', marginRight: '3px' }}
        />
      ))}
    </>
  );
};
