import { AxiosError } from 'axios';
import { CONTACT_US_SAVE_ERROR } from 'containers/ContactUsContainer/translations/locale.constants';
import {
  CaseItem,
  CaseOrderParam,
  CaseParams,
} from 'redux/case/caseOrder/case-order.types';
import { ResponseError } from 'types/error.types';
import { caseApiService } from 'api/case-api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/array/size';
import { orderApiService } from 'api/order/order-api.service';
import { parseAxiosError } from 'utils/errors';
import { segment } from 'service/segment/segment';

export const find = createAsyncThunk(
  'order/fetch',
  async (orderNumber: string, { rejectWithValue }) => {
    try {
      const { data } = await orderApiService.find(orderNumber);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const save = createAsyncThunk(
  'save/case',
  async (dataParams: CaseParams, { rejectWithValue }) => {
    try {
      const { orderNumber } = dataParams.caseDetails;
      let contactData: CaseItem[] = [];
      let data = '';
      if (orderNumber) {
        const postData: CaseOrderParam = { orderNumber };
        const response = await caseApiService.getDuplicateCase(postData);
        contactData = response.data;
      }
      if (isEmpty(contactData)) {
        const response = await caseApiService.save(dataParams);
        data = response.data;
      }
      segment.contactUsFormSubmitted(contactData);
      return { contactData, data };
    } catch (ex) {
      const error = ex as AxiosError;
      if (error.response?.data) {
        const responseError: ResponseError = {
          message: 'Something went wrong. Please try after sometimes',
          data: { key: CONTACT_US_SAVE_ERROR },
          status: 404,
        };
        return rejectWithValue(responseError);
      }
      return rejectWithValue(parseAxiosError(error));
    }
  },
);
