import { Area } from 'api/area/types/area.interface';
import { ArrangementItem } from 'components/common/ArrangementItem/ArrangementItem';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Grid, Typography } from '@mui/material';
import { FulfillmentOptionType } from 'types/cart.types';
import {
  NO_SUGGESTIONS,
  TITLE,
  VIEW_ALL,
} from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/translations/locale.constants';
import { SeeAvailableProducts } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/SeeAvailableProducts/SeeAvailableProducts';
import { fetchArrangements } from 'components/PLP/ArrangementsList/feature/actions';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { getIsPending } from 'components/Session/feature/selectors';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { isEmpty } from 'utils/is-empty';
import { isNotEmpty } from 'utils/array/size';
import { selectArrangements } from 'components/PLP/ArrangementsList/feature/selectors';
import {
  selectIsFulfilled,
  selectIsPending,
  selectSuggestedArrangements,
} from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/hooks/use-fetch-suggested-arrangements';
import { useSelectSuggestedProduct } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/hooks/use-select-suggested-product';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

interface Props {
  orderType: FulfillmentOptionType;
  availability: AvailabilityOnDate | null;
  date: Date | null;
  area: Area | null;
  onBeforeNavigation: () => void;
}

// Here
export const SuggestedArrangements: FC<Props> = ({
  orderType,
  availability,
  date,
  area,
  onBeforeNavigation,
}) => {
  const dispatch = useDispatch();
  const suggestedArrangements = useSelector(
    selectSuggestedArrangements(orderType),
  );
  const isPending = useSelector(selectIsPending(orderType));
  const isSessionPending = useSelector(getIsPending);
  const isFulfilled = useSelector(selectIsFulfilled(orderType));
  const arrangements = useSelector(selectArrangements);
  const mDayArrangements = arrangements.slice(0, 4);

  const dateGuard = date ? formatSQLDate(date) : '';

  useEffect(() => {
    if (!arrangements.length) {
      dispatch(
        fetchArrangements({
          filterIds: [1753],
        }),
      );
    }
  }, []);

  const onSuggestedClick = useSelectSuggestedProduct(
    orderType,
    date,
    area,
    onBeforeNavigation,
  );
  useFetchSuggestedArrangements({
    availability,
    orderType,
    date,
    area,
  });

  return (
    <>
      <Backdrop opened={isPending || isSessionPending} />
      {isFulfilled && (
        <Box data-test="pdp-pas-suggested-products">
          {isNotEmpty(suggestedArrangements) && (
            <>
              <Typography sx={{ mt: 3, mb: 2 }} variant="subtitle1">
                {i18next.t(TITLE)}
              </Typography>
              <Grid container spacing={[1.5, 1]}>
                {dateGuard === '2024-05-11' &&
                  mDayArrangements &&
                  mDayArrangements.map((arrangement, index) => (
                    <Grid
                      data-test={`pdp-pas-suggested-product-item-${index + 1}`}
                      key={arrangement.id}
                      item
                      xs={6}
                      sm={3}
                    >
                      <a
                        href={getPdpPath(arrangement.url)}
                        onClick={(e) => onSuggestedClick(e, arrangement)}
                        data-test={
                          arrangement.newPDP
                            ? 'suggested-product'
                            : 'legacy-suggested-product'
                        }
                      >
                        <ArrangementItem arrangement={arrangement} />
                      </a>
                    </Grid>
                  ))}
                {suggestedArrangements &&
                  dateGuard !== '2024-05-11' &&
                  suggestedArrangements.map((arrangement, index) => (
                    <Grid
                      data-test={`pdp-pas-suggested-product-item-${index + 1}`}
                      key={arrangement.id}
                      item
                      xs={6}
                      sm={3}
                    >
                      <a
                        href={getPdpPath(arrangement.url)}
                        onClick={(e) => onSuggestedClick(e, arrangement)}
                        data-test={
                          arrangement.newPDP
                            ? 'suggested-product'
                            : 'legacy-suggested-product'
                        }
                      >
                        <ArrangementItem arrangement={arrangement} />
                      </a>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
          <SeeAvailableProducts
            title={
              isEmpty(suggestedArrangements) ? i18next.t(NO_SUGGESTIONS) : ''
            }
            buttonText={i18next.t(VIEW_ALL)}
            onClick={onSuggestedClick}
            sx={{
              mt: isEmpty(suggestedArrangements) ? 3 : 5,
            }}
            orderType={orderType}
          />
        </Box>
      )}
    </>
  );
};
