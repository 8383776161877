import { Button, styled } from '@mui/material';
import { colors } from 'utils/theme/colors';

export const WidgetWrapper = styled('section')(
  ({ theme: { breakpoints } }) => ({
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1060px',
    margin: '0 auto',
    padding: '20px',
    [breakpoints.up('sm')]: {
      padding: '40px',
    },
  }),
);

export const ViewAllButtonDesktop = styled(Button)(({ theme }) => ({
  display: 'none',
  minWidth: '225px',
  height: '50px',
  borderRadius: '0px',
  marginRight: '-10px',
  border: `4px solid ${colors.primaryRed}`,
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexShrink: 0,
  },
  '& p': {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 13,
  },
}));

export const ViewAllButtonMobile = styled(Button)(({ theme }) => ({
  display: 'flex',
  margin: '32px auto 0 auto',
  boxSizing: 'border-box',
  width: '320px',
  textTransform: 'none',
  overflow: 'hidden',
  borderRadius: '0px',
  border: `4px solid ${colors.primaryRed}`,
  height: '40px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
