import { ApiBaseService } from 'api/apibase.service';

/**
 * IMPORTANT: in order to send 'Content-Type' header
 * via methods that require payload (ex: POST, PUT, PATCH, etc.) second parameter is required
 */
export const LEGACY_JSON_HEADERS: Readonly<Record<string, string>> = {
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
  Accept: 'application/json, text/javascript, */*; q=0.01',
};

export class ApiLegacyBaseService extends ApiBaseService {
  constructor() {
    super();
    this.apibase.defaults.baseURL = '';
    this.apibase.defaults.headers = LEGACY_JSON_HEADERS;
  }
}
