import { Link } from 'react-router-dom';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const ListItemLink = styled(Link)(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingBottom: theme.spacing(0.5),
  [theme.breakpoints.up('md')]: {
    borderRadius: 8,
    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: theme.spacing(1),
    },
  },
}));

export const ListItem = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  color: theme.palette.common.black,
}));

export const name: ThemedSxProps = {
  display: 'block',
  paddingTop: 2,
  fontSize: 16,
  lineHeight: { xs: '18px', md: '22px' },
  fontWeight: 600,
  textAlign: 'center',
};

export const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${theme.palette.other.outlineBorder}`,
    borderRadius: 8,
  },
  '& img': {
    width: '100%',
  },
}));
