export const STORES_NEAR_YOU = 'store_finder_page.stores_near_you';
export const STORE_NEAR_YOU = 'store_finder_page.store_near_you';
export const CONCERNED_ABOUT_PRIVACY =
  'store_finder_page.concerned_about_privacy';
export const LOAD_MORE_STORES_BUTTON_TEXT =
  'store_finder_page.load_more_button_text';
export const PRIVACY_POLICY = 'store_finder_page.privacy_policy';
export const TERMS_OF_USE = 'store_finder_page.terms_of_use';
export const LOCATION_SERVICES_OFF = 'store_finder_page.location_services_off';
export const TURN_ON_LOCATION_SERVICES =
  'store_finder_page.turn_on_location_services';
export const STORE_SHOWING_AMOUNT_DISPLAY_TEXT =
  'store_finder_page.stores_showing_amount_display_text';

export const CUSTOM_GREETING_CARDS = 'store_finder_page.custom_greeting_cards';
export const KOSHER = 'store_finder_page.kosher';
export const APPLY = 'store_finder_page.apply';
export const CLEAR_ALL_FILTERS = 'store_finder_page.clear_all_filters';
export const ERROR_MESSAGE_OPEN_YOUR_OWN_STORE =
  'store_finder_page.error_message.open_your_own_store';
export const ERROR_MESSAGE_OH_NO = 'store_finder_page.error_message.oh_no';
export const ERROR_MESSAGE_DIFFERENT_SEARCH_AND_FILTER =
  'store_finder_page.error_message.different_search_and_filter';
export const ERROR_MESSAGE_DIFFERENT_SEARCH =
  'store_finder_page.error_message.try_different_search_value';
export const ERROR_MESSAGE_WE_SHIP_TEXT =
  'store_finder_page.error_message.we_ship_text';
export const ERROR_MESSAGE_SEARCH_AND_FILTER_CRITERIA =
  'store_finder_page.error_message.no_criteria_match';
export const ERROR_MESSAGE_NEW_STORES_TEXT =
  'store_finder_page.error_message.new_stores_text';
export const ERROR_MESSAGE_OPEN_STORE_TEXT =
  'store_finder_page.error_message.open_stores_text';
export const SMOOTHIE = 'store_finder_page.smoothie';
export const CURBSIDE = 'store_finder_page.curbside_pickup';
export const DISTANCE_FILTER_OPTION = 'store_finder_page.distance_filter';
export const DISTANCE_TEXT = 'store_finder_page.distance_text';
export const SERVICES_OFFERED_TEXT = 'store_finder_page.service_offered_text';
export const FILTERS_TEXT = 'store_finder_page.filters_text';
export const MOBILE_FILTER_TEXT = 'store_finder_page.mobile_filters_text';
export const STORE_FINDER_HOME = 'store_finder_page.home';
export const FIND_A_STORE = 'store_finder_page.find_a_store';
export const STORE_FINDER_TITLE = 'store_finder_page.title';
export const STORE_FINDER_DESCRIPTION = 'store_finder_page.description';
export const STORE_FINDER_PLACEHOLDER = 'store_finder_page.placeholder';
export const STORE_FINDER_LOCATION = 'store_finder_page.location_text';
export const STORE_FINDER_CHOOSE_FROM_LIST =
  'store_finder_page.choose_from_list_text';
export const STORE_FINDER_VALIDATION_ERROR =
  'store_finder_page.validation_error';
export const INPUT_ADORNMENT = 'store_finder_page.input_adornment';
export const STORE_FINDER_BUTTON = 'store_finder_page.button';
export const STORE_FINDER_BUTTON_LOADING_TEXT =
  'store_finder_page.button_loading_text';
export const STORE_FINDER_FRANCHISE_HEAD = 'store_finder_page.franchise_head';
export const STORE_FINDER_FRANCHISE_SUBTITLE =
  'store_finder_page.franchise_sub_title';
export const STORE_FINDER_FRANCHISE_DESCRIPTION =
  'store_finder_page.franchise_description';
export const LEARN_MORE_STORES = 'store_finder_page.learn_more';
export const LOAD_MORE_STORES_ARIA_TEXT =
  'store_finder_page.load_more_stores_aria_text';
export const OPEN_FRANCHISE_LEARN_MORE_BUTTON =
  'store_finder_page.open_your_own_franchise_learn_more_button';
export const OPEN_FRANCHISE_BANNER =
  'store_finder_page.open_your_own_franchise_banner';
export const INVALID_SEARCH_TEXT = 'store_finder_page.invalid_search_text';
export const COMING_SOON_STORE_TEXT =
  'store_finder_page.coming_soon_store_text';
export const CALL_EDIBLE_STORE = 'store_finder_page.call_edible_store';
export const EMAIL_EDIBLE_STORE = 'store_finder_page.email_edible_store';
export const CONTACT_US = 'store_finder_page.email_contact_us';
export const GET_DIRECTIONS = 'store_finder_page.get_edible_store_directions';
export const STORE_FINDER_PAGE_META_TAG_TITLE =
  'store_finder_page.seo_page_title';
export const STORE_FINDER_PAGE_META_TAG_DESCRIPTION =
  'store_finder_page.seo_page_description';
