import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const bullet: SxProps<Theme> = {
  m: 1.2,
  width: 6,
  height: 6,
  color: ({ palette }) => palette.primary.main,
};
export const icon: SxProps = {
  minWidth: 'unset',
  mr: 0.5,
};
