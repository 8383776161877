import { RootState } from 'redux/store';
import { createSelectors } from 'redux/factories/create-selectors';

export const selectAreaState = (state: RootState) => state.zipAvailability;

export const {
  selectData: selectAreaData,
  selectIsPending: selectIsAreaPending,
  selectIsError: selectIsAreaError,
  selectIsInit,
  selectIsFulfilled,
} = createSelectors(selectAreaState);
