export enum AddressTypes {
  NotSpecified = 0,
  Residential = 1,
  Business = 2,
  School = 3,
  Hospital = 4,
}

export enum RegistrationSource {
  Desktop = 1,
  Mobile = 2,
  Store = 3,
  DippedFruit = 4,
  FruitExpert = 5,
  App = 6,
  FreeTreatBoxLandingPage = 7,
}

export enum AccountMemberType {
  PartialMember = 1,
  FullMember = 2,
}

export enum GenderType {
  NotSpecified = 0,
  Male = 1,
  Female = 2,
}
