import { Area } from 'api/area/types/area.interface';
import { AvailableDate } from 'api/availability-on-date/types/available-date.interface';
import { Box, CircularProgress } from '@mui/material';
import { FlexFeeDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/DeliveryMethodDates/FlexFeeDates';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { NonFlexFeeDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/DeliveryMethodDates/NonFlexFeeDates';
import { PickupDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/PickupMethodDates/PickupDates';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { RootState } from 'redux/store';
import { Store } from 'redux/types/store.types';
import { isDelivery, isPickup } from 'utils/is-order-type';
import { isPending } from 'utils/status.comparer';
import {
  selectIsFulfilled,
  selectStores,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useSelector } from 'react-redux';
import React, { FC, MutableRefObject, useEffect, useState } from 'react';

type Props = {
  availableDates: AvailableDate[];
  setSelectedDate: (date: Date) => void;
  orderType: FulfillmentOptionType;
  dateSelected: Date | null;
  isAlwaysOnFlex: boolean;
  suggestedDatesRef?: MutableRefObject<HTMLInputElement | null>;
  collectionPromptRef?: MutableRefObject<HTMLInputElement | null>;
  area: Area | null;
  product: Product | null;
};

export const SuggestedDates: FC<Props> = ({
  availableDates,
  setSelectedDate,
  orderType,
  dateSelected,
  isAlwaysOnFlex,
  suggestedDatesRef,
  area,
  product,
  collectionPromptRef,
}) => {
  const todayDate = new Date();
  const orderIsDelivery =
    isDelivery(orderType) || orderType === OrderType.Shipment;
  const orderIsPickup = isPickup(orderType);

  const { availability, loading } = useSelector(
    ({ availabilityOnDate: { data, status } }: RootState) => ({
      availability: data,
      loading: isPending(status),
    }),
  );
  const isStoresFulfilled = useSelector(selectIsFulfilled);
  const [sameDayDeliveryFee, setSameDayDeliveryFee] = useState<number>(0);
  const stores: Store[] = useSelector(selectStores);
  useEffect(() => {
    if (isStoresFulfilled) {
      const fee = (stores && stores.length > 0 && stores[0].deliveryFee) || 0;
      setSameDayDeliveryFee(fee);
    }
  }, [isStoresFulfilled, stores]);

  const key = {
    [OrderType.Pickup]: 'pickup',
    [OrderType.Delivery]: 'delivery',
    [OrderType.Shipment]: 'shipment',
  }[orderType] as 'pickup' | 'delivery' | 'shipment';

  const isFulfillmentAvailable = availability[key].available;

  const isOnlyShipmentAvailable = key === 'shipment';

  if (loading) {
    return (
      <Box
        display="flex"
        flexWrap="nowrap"
        height="auto"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={28} thickness={2} />
      </Box>
    );
  }
  return (
    <Box ref={suggestedDatesRef}>
      {isAlwaysOnFlex && (
        <NonFlexFeeDates
          todaysDate={todayDate}
          dateSelected={dateSelected}
          daysToShow={3}
          sameDayDeliveryFee={sameDayDeliveryFee || availability.delivery.fee} // remove availability.delivery.fee in the future
          setNewDate={setSelectedDate}
          isFulfillmentAvailable={isFulfillmentAvailable}
          collectionPromptRef={collectionPromptRef}
          selectedProductPrice={product?.price}
        />
      )}
      {!isAlwaysOnFlex && orderIsDelivery && (
        <FlexFeeDates
          dateSelected={dateSelected}
          availableDates={availableDates}
          setNewDate={setSelectedDate}
          isFulfillmentAvailable={isFulfillmentAvailable}
          isOnlyShipmentAvailable={isOnlyShipmentAvailable}
          isDeliverySelected={orderIsDelivery}
          collectionPromptRef={collectionPromptRef}
        />
      )}
      {orderIsPickup && (
        <PickupDates
          dateSelected={dateSelected}
          availableDates={availableDates}
          setNewDate={setSelectedDate}
          isFulfillmentAvailable={isFulfillmentAvailable}
          area={area}
          product={product}
          orderType={key}
          collectionPromptRef={collectionPromptRef}
        />
      )}
    </Box>
  );
};
