export const ADDRESS_BOOK_BUTTON_TEXT = 'address_book.button_text';
export const ADDRESS_BOOK_SEARCH_INPUT_PLACEHOLDER =
  'address_book.search_input_placeholder';
export const ADDRESS_BOOK_TITLE = 'address_book.title';
export const ADDRESS_BOOK_NO_RESULTS_FOUND = 'address_book.no_results_found';
export const ADDRESS_BOOK_NO_RESULTS_FOUND_ON_SEARCH =
  'address_book.no_results_found_on_search';
export const ADDRESS_BOOK_CAN_NOT_FETCH_CONTACTS =
  'address_book.can_not_fetch_contacts';
export const ADDRESS_BOOK_CLOSE_BUTTON = 'address_book.close_button';
