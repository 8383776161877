import { Box } from '@mui/material';
import { CarouselBannerSkeleton } from 'components/Home/CarouselBanners/components/Skeleton';
import {
  CarouselBannersContent,
  CarouselBannersItem,
} from 'components/Home/CarouselBanners/types';
import { CarouselItem } from 'components/Home/CarouselBanners/components/CarouselItem';
import { ComponentProps } from 'components/Home/types';
import { DottedSlider } from 'components/common/DottedSlider';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { TextAndButtonAlignment } from 'components/Home/CarouselBanners/components/CarouselImage/banner-text-type';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import { wrapper } from 'components/Home/CarouselBanners/styles';
import React, { FC } from 'react';

const settings = {
  slidesToScroll: 1,
  variableWidth: false,
  initialSlide: 0,
  infinite: true,
  swipe: true,
  autoplay: true,
};

export const CarouselBanners: FC<ComponentProps> = ({
  type,
  id,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const content = useResponsiveContent<CarouselBannersContent>(
    componentData,
    'md',
  );
  const isPlpPageActive = useSelector(plpGetComponents);

  const handleAnalyticsClick = (position: number, linkName: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: position.toString(),
      module_type: type,
      module_name: name,
      link: linkName,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  const { imagesPreloaded } = useImagePreloader(content.items);
  if (!imagesPreloaded) {
    return <CarouselBannerSkeleton />;
  }

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <Box
        component="section"
        sx={wrapper}
        id={getId(type, id)}
        data-test="carousel-banners"
      >
        <DottedSlider {...settings} autoplaySpeed={content.autoSlideInterval}>
          {content.items.map((item: CarouselBannersItem, index) => (
            <CarouselItem
              altText={item.altText}
              frAltText={item?.frAltText}
              key={item.id}
              name={item.name}
              url={item.url}
              src={item.src}
              frSrc={item?.frSrc || ''}
              headerText={item?.headerText || ''}
              frHeaderText={item?.frHeaderText || ''}
              contentText={item?.contentText || ''}
              frContentText={item?.frContentText || ''}
              smallText={item?.smallText || ''}
              frSmallText={item?.frSmallText || ''}
              buttonText={item?.buttonText || ''}
              frButtonText={item?.frButtonText || ''}
              buttonTextColor={item?.buttonTextColor || ''}
              textColor={item?.textColor || ''}
              textAlignment={
                item?.textAlignment || TextAndButtonAlignment.MiddleLeft
              }
              buttonAlignment={
                item?.buttonAlignment || TextAndButtonAlignment.BottomLeft
              }
              fallbackSrc={item.fallbackSrc}
              data-test="carousel-bannsers-slide"
              analyticsClickHandler={() =>
                handleAnalyticsClick(index + 1, item.name)
              }
            />
          ))}
        </DottedSlider>
      </Box>
    </RenderWhenInView>
  );
};
