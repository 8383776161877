import * as TK from 'components/OrderConfirmation/translations/locale.constants';
import * as styles from 'components/OrderConfirmation/PaymentInfo/styles';
import {
  AmazonPayImg,
  ApplePayImg,
  GooglePayImg,
  LinkImg,
  PaypalLogo,
  VenmoLogo,
} from 'utils/cloudinaryAssets';
import { Box, Typography } from '@mui/material';
import { CreditCardTypeInfo } from 'components/OrderConfirmation/PaymentInfo/creditCardTypeInfo';
import { Image } from 'components/common/Image/Image';
import { PaymentMode, Sale } from 'redux/order-confirmation/types/types';
import { isEmpty } from 'utils/is-empty';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  saleData: Sale;
}

export const PaymentModeInfo: FC<Props> = ({ saleData }) => (
  <>
    {saleData.paymentMode === PaymentMode.CREDITCARD && (
      <CreditCardTypeInfo saleData={saleData} />
    )}
    {saleData.paymentMode === PaymentMode.APPLEPAY && (
      <Box sx={styles.cardTitle}>
        <Image
          alt={i18next.t(TK.APPLE_PAY_IMAGE)}
          width={49}
          height={20}
          className="mr5"
          src={ApplePayImg}
        />
        <Typography className="title">
          {!isEmpty(saleData.paymentTransactionID)
            ? i18next.t(TK.TRANSACTION_ID)
            : i18next.t(TK.PAID_BY)}
        </Typography>
        {!isEmpty(saleData.paymentTransactionID) && (
          <Typography sx={styles.cardNumber}>
            {saleData.paymentTransactionID}
          </Typography>
        )}
      </Box>
    )}
    {saleData.paymentMode === PaymentMode.AMAZONPAY && (
      <>
        <Box sx={styles.cardTitle}>
          <Image
            alt={i18next.t(TK.AMAZON_PAY_IMAGE)}
            width={94}
            height={24}
            className="mr5"
            src={AmazonPayImg}
          />
          <Typography className="title">{i18next.t(TK.PAYMENT_ID)}</Typography>
          <Typography sx={styles.cardNumber}>{saleData.referenceID}</Typography>
        </Box>
      </>
    )}
    {saleData.paymentMode === PaymentMode.VENMO && (
      <Box sx={styles.venmoPaymentWrapper}>
        <Box sx={styles.venmoPaymentContent}>
          <Image
            alt={i18next.t(TK.VENMO_IMAGE)}
            width={76}
            height={14}
            className="mt5"
            src={VenmoLogo}
          />
          <Typography variant="body1" sx={styles.venmoPaymentText}>
            {i18next.t(TK.sale.TRANSACTION_ID)}
          </Typography>
        </Box>
        <Typography variant="body1">
          {saleData.payPalOrderTransactionID}
        </Typography>
      </Box>
    )}
    {saleData.paymentMode === PaymentMode.PAYPAL && (
      <>
        <Box sx={styles.cardTitle}>
          <Image
            alt={i18next.t(TK.PAYPAL_IMAGE)}
            width={78}
            height={20}
            className="mr5"
            src={PaypalLogo}
          />
          <Typography className="title">
            {i18next.t(TK.TRANSACTION_ID)}
          </Typography>
          <Typography sx={styles.cardNumber}>
            {saleData.payPalOrderTransactionID}
          </Typography>
        </Box>
      </>
    )}
    {saleData.paymentMode === PaymentMode.GOOGLEPAY && (
      <Box sx={styles.cardTitle}>
        <Image
          alt={i18next.t(TK.GOOGLE_PAY_IMAGE)}
          width={49}
          height={20}
          className="mr5"
          src={GooglePayImg}
        />
        <Typography className="title">
          {!isEmpty(saleData.paymentTransactionID)
            ? i18next.t(TK.TRANSACTION_ID)
            : i18next.t(TK.PAID_BY_GPAY)}
        </Typography>
        {!isEmpty(saleData.paymentTransactionID) && (
          <Typography sx={styles.cardNumber}>
            {saleData.paymentTransactionID}
          </Typography>
        )}
      </Box>
    )}
    {saleData.paymentMode === PaymentMode.LINK && (
      <Box sx={styles.cardTitle}>
        <Image
          alt={i18next.t(TK.LINK_IMAGE)}
          width={49}
          height={20}
          className="mr5"
          src={LinkImg}
        />
        <Typography className="title">
          {!isEmpty(saleData.paymentTransactionID)
            ? i18next.t(TK.TRANSACTION_ID)
            : i18next.t(TK.PAID_BY_LINK)}
        </Typography>
        {!isEmpty(saleData.paymentTransactionID) && (
          <Typography sx={styles.cardNumber}>
            {saleData.paymentTransactionID}
          </Typography>
        )}
      </Box>
    )}
  </>
);
