import { ResponseError } from 'types/error.types';

export enum Status {
  INIT,
  PENDING,
  FULFILLED,
  ERROR,
  NOT_FOUND,
}

export interface StateWithoutData<Error = ResponseError> {
  status: Status;
  error: Error | null;
}

export interface State<T, Error = ResponseError>
  extends StateWithoutData<Error> {
  data: T;
}

export type NullableState<T, Error = ResponseError> = State<T | null, Error>;
