import * as TK from 'components/Header/HeaderNavigation/translations/header-navigation.locale.constants';
import { Box } from '@mui/material';
import { Links } from 'enums/common-links';
import { banner } from 'components/Header/HeaderNavigation/styles';
import { menuBanner } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const MenuBanner: FC = () => (
  <Box sx={banner}>
    <a href={Links.ShopMenuUnder50}>
      <img src={menuBanner} alt={i18next.t(TK.BANNER_ALT)} />
    </a>
  </Box>
);
