import { HttpStatusCode } from 'enums/http-status-code';
import { fetchArrangement } from 'redux/PDP/arrangement/arrangement.actions';
import { selectArrangementErrorStatus } from 'redux/PDP/arrangement/arrangement.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const usePageArrangement = (): void => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageFriendlyUrl } = useParams<{ pageFriendlyUrl: string }>();
  const arrangementStatus = useSelector(selectArrangementErrorStatus);

  useEffect(() => {
    if (pageFriendlyUrl) {
      dispatch(fetchArrangement(pageFriendlyUrl));
    }
  }, [pageFriendlyUrl, dispatch]);

  useEffect(() => {
    if (arrangementStatus === HttpStatusCode.NOT_FOUND) {
      navigate('/404');
    }

    if (
      arrangementStatus &&
      arrangementStatus >= HttpStatusCode.INTERNAL_SERVER_ERROR
    ) {
      navigate('/maintenance.html');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, arrangementStatus]);
};
