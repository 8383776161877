import { Review } from 'api/reviews/types/review.interface';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';
import { isPositive } from 'utils/numbers';

export const selectReviews = ({ reviews: { data } }: RootState) => data.items;

export const checkIsBlocked = createSelector(
  ({ reviews: reviewsNew }: RootState) => reviewsNew.status,
  (s) => isBlocked(s),
);

export const selectReviewsInfo = createSelector(selectReviews, (reviews) => {
  const ratingSum = reviews.reduce(
    (rating: number, review: Review) => rating + review.rating / reviews.length,
    0,
  );

  return {
    rating: Math.round(ratingSum),
    count: reviews.length,
  };
});

export const selectAreReviewsExist = createSelector(
  ({ reviews: reviewsNew }: RootState) => reviewsNew,
  ({ status, data: { items: reviews } }) =>
    !isBlocked(status) && isPositive(reviews.length),
);
