import { ApiBaseService } from 'api/apibase.service';
import { AxiosResponse } from 'axios';

export type Settings = {
  id: number;
  key: string;
  value?: string;
  cached: boolean;
};

class SettingApiService extends ApiBaseService {
  getSetting(settingKey: string): Promise<AxiosResponse<Settings[]>> {
    return this.apibase
      .get<Settings[]>(`/settings/${settingKey}`)
      .then((res) => {
        if (res.status === 200) {
          res.data.map((element) => {
            return element;
          });
        }
        return res;
      });
  }
}
const settingApiService = new SettingApiService();

export { settingApiService };
