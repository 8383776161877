import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';

class CartLegacyApiService extends ApiLegacyBaseService {
  updateComplimentaryMessage(
    cartRecipientId: number,
    message: string,
  ): AxiosPromise<DResponse> {
    return this.apibase.post(
      '/AJAX/EditCardMessageDeliveryInstructions.aspx/UpdateCardMessage',
      JSON.stringify({ Message: message, CRID: cartRecipientId }),
    );
  }
}

const cartLegacyApiService = new CartLegacyApiService();

export { cartLegacyApiService };
