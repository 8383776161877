export enum LegacyLogInValidationStatus {
  NONE = 'None',
  FULL = 'FULL',
  ERROR_DESKTOP = 'ERROR',
  ERROR = 'Error',
  PARTIAL = 'PARTIAL',
  EXCEPTION = 'EXCEPTION',
  INVALID_MAIL_PASSWORD = 'INVALIDEMAILPASSWORD',
  ACCOUNT_LOCK_DESKTOP = 'AccountLock',
  ACCOUNT_LOCK = 'ACCOUNTLOCK',
}

type TruthyStatus =
  | LegacyLogInValidationStatus.FULL
  | LegacyLogInValidationStatus.PARTIAL;

type LockStatus =
  | LegacyLogInValidationStatus.ACCOUNT_LOCK_DESKTOP
  | LegacyLogInValidationStatus.ACCOUNT_LOCK;

type ErrorStatus =
  | LegacyLogInValidationStatus.ERROR_DESKTOP
  | LegacyLogInValidationStatus.ERROR
  | LegacyLogInValidationStatus.EXCEPTION
  | LegacyLogInValidationStatus.INVALID_MAIL_PASSWORD
  | LegacyLogInValidationStatus.ACCOUNT_LOCK_DESKTOP
  | LegacyLogInValidationStatus.ACCOUNT_LOCK;

export const isTruthy = (
  status: LegacyLogInValidationStatus,
): status is TruthyStatus =>
  [
    LegacyLogInValidationStatus.FULL,
    LegacyLogInValidationStatus.PARTIAL,
  ].includes(status);

export const isError = (
  status: LegacyLogInValidationStatus,
): status is ErrorStatus =>
  [
    LegacyLogInValidationStatus.ERROR_DESKTOP,
    LegacyLogInValidationStatus.ERROR,
    LegacyLogInValidationStatus.EXCEPTION,
    LegacyLogInValidationStatus.INVALID_MAIL_PASSWORD,
    LegacyLogInValidationStatus.ACCOUNT_LOCK_DESKTOP,
    LegacyLogInValidationStatus.ACCOUNT_LOCK,
  ].includes(status);

export const isLocked = (
  status: LegacyLogInValidationStatus,
): status is LockStatus =>
  [
    LegacyLogInValidationStatus.ACCOUNT_LOCK_DESKTOP,
    LegacyLogInValidationStatus.ACCOUNT_LOCK,
  ].includes(status);
