import { Category } from 'redux/header/header.types';
import { Link, Typography } from '@mui/material';
import { analytics } from 'service/adobe-analytics/analytics';
import { categoryLink } from 'components/Header/HeaderNavigation/styles';
import { isUrlToAnotherResource } from 'utils/router';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

type Props = {
  category: Category;
  initClose: () => void;
};

export const MenuItemCategory: FC<Props> = ({
  category: { filters, name },
  initClose,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const menuItemHandler = (url: string, menuItem: string) => {
    if (isUrlToAnotherResource(url)) {
      analytics.headerMenuItemClicked(menuItem);
      segment.menuItemClicked(
        menuItem,
        url,
        userSession,
        userAccountInformation,
      );
      window.open(url, '_blank');
    } else {
      analytics.headerMenuItemClicked(menuItem);
      segment.menuItemClicked(
        menuItem,
        url,
        userSession,
        userAccountInformation,
      );
    }
    initClose();
  };

  return (
    <div>
      <Typography variant="subtitle2" sx={{ mb: 1.75 }}>
        {name}
      </Typography>
      {filters.map((filter) => (
        <Typography
          variant="body2"
          key={filter.id}
          sx={{ mb: 1 }}
          onClick={() => menuItemHandler(filter.url, filter.name)}
        >
          <Link sx={categoryLink} href={`/${filter.url}`}>
            {filter.name}
          </Link>
        </Typography>
      ))}
    </div>
  );
};
