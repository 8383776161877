import {
  fetchFilterCategories,
  fetchFruitFilters,
} from 'components/PLP/FilterCategories/feature/actions';
/* eslint-disable no-param-reassign */
import { FilterCategory, FruitFilter } from 'api/filter-categories/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';

type State = {
  categories: FilterCategory[];
  fruitFilters: FruitFilter[];
  status: Status;
  fruitFilterStatus: Status;
};
export const initialState: State = {
  categories: [],
  fruitFilters: [],
  status: Status.INIT,
  fruitFilterStatus: Status.INIT,
};

const slice = createSlice({
  name: 'plp/filter-categories',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterCategories.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchFilterCategories.fulfilled, (state, { payload }) => {
        state.categories = payload;
        state.status = Status.FULFILLED;
      })
      .addCase(fetchFilterCategories.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(fetchFruitFilters.pending, (state) => {
        state.fruitFilterStatus = Status.PENDING;
      })
      .addCase(fetchFruitFilters.fulfilled, (state, { payload }) => {
        state.fruitFilters = payload;
        state.fruitFilterStatus = Status.FULFILLED;
      })
      .addCase(fetchFruitFilters.rejected, (state) => {
        state.fruitFilterStatus = Status.ERROR;
      });
  },
  reducers: {},
});
export const plpFilterCategoriesReducer = slice.reducer;
