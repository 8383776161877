import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectors } from 'redux/factories/create-selectors';
import { isNotEmpty } from 'utils/array/size';

export const { selectData, selectIsInit: selectAreRecipientsInit } =
  createSelectors((state) => state.recipients);

export const selectExistsRecipients = (state: RootState) =>
  isNotEmpty(selectData(state));

export const selectRecipients = createSelector(selectData, (recipients) =>
  recipients.slice().reverse(),
);
