import { emptyFunction } from 'utils/emptyFunction';
import { hashObject } from 'utils/hash';
import { isNotNull } from 'utils/null.utils';
import { useEffect } from 'react';

function hashScript(script: Partial<HTMLScriptElement>): number {
  return hashObject({
    src: script.src,
    innerHTML: script.innerHTML,
  });
}

interface ScriptAttrs extends Partial<HTMLScriptElement> {
  keepOnUnmount?: boolean;
}

export const useScriptOnComplete = ({
  keepOnUnmount = false,
  ...scriptProps
}: ScriptAttrs): void => {
  const script = document.createElement('script');

  Object.assign(script, scriptProps);

  const id = `${hashScript(script)}_on-complete-script`;
  script.id = id;
  script.type = 'text/javascript';

  useEffect(() => {
    if (isNotNull(document.getElementById(id))) {
      return emptyFunction;
    }

    const append = () => document.body.appendChild(script);

    if (document.readyState === 'complete') {
      append();
    } else {
      window.addEventListener('load', append);
    }

    return () => {
      if (keepOnUnmount) {
        return;
      }

      document.getElementById(id)?.remove();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
};
