import * as styles from 'components/OrderConfirmation/TopSeller/components/CarouselItem/styles';
import { CarouselDetailedItem } from 'components/OrderConfirmation/TopSeller/type';
import { Image } from 'components/common/Image/Image';
import { ImageSkeleton } from 'components/common/ArrangementItem/components/ImageSkeleton';
import { ItemImageWrapper } from 'components/common/ArrangementItem/styled';
import { Link, Typography } from '@mui/material';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addCurrency } from 'utils/add-currency';
import { analytics } from 'service/adobe-analytics/analytics';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { getSizeDescription } from 'utils/get-size-description';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface CarouselItemProps {
  item: CarouselDetailedItem;
}

export const CarouselItem: FC<CarouselItemProps> = ({
  item: {
    id,
    alt,
    image,
    minPrice,
    name,
    productImageTag,
    nonPromo,
    nonPromoColor,
    promo,
    promoColor,
    sizeCount,
    url,
    isMinSizeOnSale,
    minsizeProductPrice,
  },
}) => {
  const user = useSelector(({ userSession: { data } }) => data);
  return (
    <Link
      underline="none"
      sx={styles.itemLink}
      href={getPdpPath(url)}
      data-test="carousel-detailed-item"
      color="secondary"
      onClick={() => {
        analytics.wantToKeepShoppingClicked(
          user?.userId > 0 ? user?.userId : 0,
          id,
          alt,
          name,
          minPrice,
        );
      }}
    >
      <ItemImageWrapper>
        <Image
          src={image}
          alt={alt}
          animationDuration={250}
          lazy
          observerMargins="0px 0px 400px"
          loading={<ImageSkeleton />}
          data-test="plp-arrangement-item-image"
        />
        {productImageTag && (
          <Typography
            sx={styles.productImageTagText}
            component="p"
            variant="subtitle3"
          >
            {productImageTag}
          </Typography>
        )}
      </ItemImageWrapper>
      {nonPromo && (
        <PureHtmlTypography
          sx={styles.promo(nonPromoColor)}
          variant="subtitle3"
        >
          {nonPromo}
        </PureHtmlTypography>
      )}
      <PureHtmlTypography variant="subtitle2" sx={styles.title}>
        {name}
      </PureHtmlTypography>
      <PureHtmlTypography variant="body3" sx={styles.size}>
        {getSizeDescription(sizeCount)}
      </PureHtmlTypography>
      <div style={{ display: 'flex' }}>
        {isMinSizeOnSale && minsizeProductPrice > 1 ? (
          <Typography
            variant="subtitle2"
            component="p"
            mt={0.5}
            data-test="plp-arrangement-item-price"
            sx={styles.regularPrice}
          >
            {addCurrency(minsizeProductPrice)}
          </Typography>
        ) : null}
        <Typography
          variant="subtitle2"
          mt={0.5}
          component="p"
          data-test="plp-arrangement-item-price"
          sx={styles.salePrice(
            isMinSizeOnSale && minsizeProductPrice > 1 ? '#FF3300' : '',
          )}
        >
          {addCurrency(minPrice.toFixed(2))}
        </Typography>
      </div>
      {promo && (
        <PureHtmlTypography sx={styles.promo(promoColor)} variant="subtitle3">
          {promo}
        </PureHtmlTypography>
      )}
    </Link>
  );
};
