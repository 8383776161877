import * as styles from 'components/Session/Alert/styles';
import { Box, Typography } from '@mui/material';
import Lock from '@mui/icons-material/Lock';
import React, { FC } from 'react';

export const SingleRecipientLock: FC = ({ children }) => (
  <Box sx={styles.alert} role="alert" data-test="pdp-details-order-lock">
    <Typography variant="subtitle2">
      <Lock sx={styles.lockIcon} />
      {children}
    </Typography>
  </Box>
);
