import * as productAccordionStyles from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { Box, Skeleton } from '@mui/material';
import { generate } from 'utils/generate';
import React, { FC } from 'react';

export const FaqSkeleton: FC = () => (
  <Box mt={5} mx={[2.5, 0]}>
    {generate(
      <Box display="flex" mb={4}>
        <Skeleton height={20} width="100%" sx={{ mr: 1 }} />
        <Skeleton sx={productAccordionStyles.expandIconSkeleton} />
      </Box>,
      3,
    )}
  </Box>
);
