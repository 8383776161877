import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { ProductOptionAvailability } from 'api/availability/types/product-option-availability.interface';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { fetchProductsAvailability } from 'components/PDP/Details/components/ProductOptions/feature/actions';

const initialState = InitialStateFactory.create<ProductOptionAvailability[]>(
  [],
);

const slice = createSlice({
  name: 'product-options/availability',
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(initialState, fetchProductsAvailability),
});

export const productOptionsAvailabilityReducer = slice.reducer;
