export const NO_APPROPRIATE_DATE = 'pdp.suggested_dates.no_appropriate_date';
export const EXPLORE_AVAILABLE_PRODUCTS =
  'pdp.suggested_dates.explore_available_products';
export const SEE_AVAILABLE_PRODUCTS =
  'pdp.suggested_dates.see_available_products';
export const FLEX_FREE = 'pdp.suggested_dates.flex_free';
export const FLEX_NEXT_DAY_PRICE = 'pdp.suggested_dates.flex_next_day_price';
export const FLEX_DATES_AVAILABLE = 'pdp.suggested_dates.dates_available';
export const FLEX_DELIVERY_DATES = 'pdp.suggested_dates.flex_dates';
export const FLEX_DELIVERY_DATES_SUBTITLE =
  'pdp.suggested_dates.flex_dates_subtitle';
export const FLEX_DATES_AVAILABLE_SUBTITLE =
  'pdp.suggested_dates.dates_available_subtitle';
export const CHOOSE_ANOTHER_DATE = 'pdp.suggested_dates.another_date';
export const DISCLAIMER_MESSAGE = 'pdp.suggested_dates.disclaimer_message';
