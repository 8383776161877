import { OrderType } from 'types/cart.types';
import { availabilityStoreService } from 'api/availability-store.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const checkAvailability = createAsyncThunk(
  'availability/check-availability',
  (
    {
      storeId,
      areaId,
      productId,
      orderType,
      fulfillmentDate,
    }: {
      storeId: number;
      areaId: number;
      productId: number;
      orderType: OrderType;
      fulfillmentDate: Date;
    },
    { rejectWithValue },
  ) =>
    availabilityStoreService
      .checkAvailability({
        storeId,
        areaId,
        productId,
        orderType,
        fulfillmentDate,
      })
      .catch((err) => rejectWithValue(parseAxiosError(err))),
);
