import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  checkoutBtnEnabled: boolean;
  deliveryInfoSectionOpen: boolean;
  giftMessageSectionOpen: boolean;
}

const initialState: State = {
  checkoutBtnEnabled: false,
  deliveryInfoSectionOpen: false,
  giftMessageSectionOpen: false,
};

const slice = createSlice({
  name: 'options/cart',
  initialState,
  reducers: {
    updateCheckoutBtnState: (
      state,
      { payload }: PayloadAction<Partial<State>>,
    ) => {
      state.checkoutBtnEnabled = payload.checkoutBtnEnabled!;
    },
    updateDeliveryInfoSectionState: (
      state,
      { payload }: PayloadAction<Partial<State>>,
    ) => {
      state.deliveryInfoSectionOpen = payload.deliveryInfoSectionOpen!;
    },
    updateGiftMessageSectionState: (
      state,
      { payload }: PayloadAction<Partial<State>>,
    ) => {
      state.giftMessageSectionOpen = payload.giftMessageSectionOpen!;
    },
  },
});

export const cartOptionsReducer = slice.reducer;
export const {
  updateCheckoutBtnState,
  updateDeliveryInfoSectionState,
  updateGiftMessageSectionState,
} = slice.actions;
