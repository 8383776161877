import { FulfillmentOptionType, OrderType } from 'types/cart.types';

export const getOrderTypeKey = (
  type: FulfillmentOptionType,
): 'pickup' | 'delivery' | 'shipment' =>
  ({
    [OrderType.Pickup]: 'pickup',
    [OrderType.Delivery]: 'delivery',
    [OrderType.Shipment]: 'shipment',
  }[type] as 'pickup' | 'delivery' | 'shipment');
