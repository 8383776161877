import { Errors } from 'service/adobe-analytics/enums';
import {
  INVALID_DIALOG,
  SATURDAY_SHIPMENT_DIALOG,
  UNKNOWN_DIALOG,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/utils';
import { InvalidDialogPart } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/InvalidDialogPart';
import { SaturdayShipmentDialogPart } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/SaturdayShipmentDialogPart';
import { UnknownDialogPart } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/UnknownDialogPart';
import { analytics } from 'service/adobe-analytics/analytics';
import { getSuggestedAddresses } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { useDeterminedDialog } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/use-determined-dialog';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

type Props = {
  open: boolean;
  onClose: () => void;
  onGoBack: () => void;
  onSelect: () => void;
  onSelectOriginalAddress: () => void;
  isShipmentOrder: boolean;
  isSaturdayNonResidentialShipment?: boolean;
};

export const AddressValidation: FC<Props> = ({
  open,
  onClose,
  onGoBack,
  onSelect,
  onSelectOriginalAddress,
  isShipmentOrder,
  isSaturdayNonResidentialShipment = false,
}) => {
  const dialog = useDeterminedDialog(isSaturdayNonResidentialShipment);
  const addresses = useSelector(getSuggestedAddresses);
  if (!dialog) {
    return null;
  }

  analytics.addressValidation(addresses.length > 0);

  if (dialog === SATURDAY_SHIPMENT_DIALOG) {
    analytics.pushErrors([Errors.SaturdayNotAvailable]);
    return (
      <SaturdayShipmentDialogPart
        open={open}
        onConfirm={onClose}
        onReject={onGoBack}
      />
    );
  }

  if (dialog === UNKNOWN_DIALOG) {
    analytics.pushErrors([Errors.AddressNotRecognized]);
    return (
      <UnknownDialogPart
        open={open}
        onSelectOriginalAddress={onSelectOriginalAddress}
        onReject={onClose}
        isShipmentOrder={isShipmentOrder}
      />
    );
  }

  if (dialog === INVALID_DIALOG) {
    analytics.pushErrors([Errors.InvalidAddress]);
    return (
      <InvalidDialogPart open={open} onConfirm={onSelect} onReject={onClose} />
    );
  }

  return null;
};

AddressValidation.defaultProps = {
  isSaturdayNonResidentialShipment: false,
};
