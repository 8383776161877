import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';

export const productSeparator: SxProps = {
  width: '100%',
  height: 0,
  border: `1px solid ${colors.dividerBlack}`,
  mt: 2,
  mb: 4,
};
export const title: SxProps = {
  fontWeight: '600',
  fontSize: { sm: '16px', xs: '14px' },
};

export const price: SxProps = {
  marginTop: '3px',
  width: { md: '17%', sm: '14%', xs: '31%' },
  textAlign: 'right',
  fontSize: { sm: '16px', xs: '14px' },
};

export const orderTotalLine: SxProps = {
  fontWeight: '600',
  fontSize: '20px',
  marginLeft: '10px',
};
