import * as TK from 'components/PDP/Details/components/Reviews/translations/reviews.locale.constants';
import { pluralize } from 'translations/utils';

export const reviewsCommonLocale = {
  [TK.SHOW_MORE]: 'Show {{count}} More Review',
  [pluralize(TK.SHOW_MORE)]: 'Show {{count}} More Reviews',
  [TK.PRODUCT_NO_REVIEW]: 'No Reviews',
  [TK.PRODUCT_REVIEW]: 'Review: {{count}}',
  [pluralize(TK.PRODUCT_REVIEW)]: 'Reviews: {{count}}',
  [TK.PRODUCT_REVIEWS]: 'Reviews',
};
