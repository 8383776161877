import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/Accessibility/styles';
import { AccessibilityBreadcrumb } from 'components/About/Accessibility/AccessibilityBreadcrumb';
import { Box, Button, Link, Typography } from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Image } from 'components/common/Image/Image';
import { ShowOnlyXs } from 'utils/hidden/show-only-xs';
import {
  accessibilityADAEvolution,
  accessibilityGraphicsCTA,
  accessibilityGraphicsImg1,
  accessibilityGraphicsImg2,
  accessibilityGraphicsImg3,
  accessibilityGraphicsImg4,
  accessibilityGraphicsImg5,
  accessibilityGraphicsImg6,
  accessibilityGraphicsTrusted,
} from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const Accessibility: FC = () => {
  return (
    <>
      <AccessibilityBreadcrumb />
      <Box sx={styles.accessibilityContainer}>
        <Typography>{i18next.t(TK.ACCESSIBILITY_TEXT1)}</Typography>
        <Typography variant="h2" mt={2} sx={styles.phoneStyle}>
          {i18next.t(TK.ACCESSIBILITY_PHONE)}
        </Typography>
        <Box mb={5}>
          <Box>
            <Typography variant="h2" sx={styles.accessibilityMainHeader}>
              {i18next.t(TK.ACCESSIBILITY_TITLE)}
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT)}
            </Typography>
            <Box sx={styles.adaEvolutionImgContainer} mb={2}>
              <Image
                sx={styles.adaEvolutionImg}
                src={accessibilityADAEvolution}
              />
            </Box>
          </Box>
          <Box mb={2}>
            <Typography variant="h2" sx={styles.accessibilityHeader}>
              {i18next.t(TK.ACCESSIBILITY_TITLE1)}
            </Typography>
            <Typography>{i18next.t(TK.ACCESSIBILITY_STATEMENT1)} </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT2)}
            </Typography>
            <hr />
          </Box>
          <Box mb={2}>
            <Typography variant="h2" sx={styles.accessibilityHeader}>
              {' '}
              {i18next.t(TK.ACCESSIBILITY_TITLE2)}{' '}
            </Typography>
            <Image
              sx={styles.graphicsTrustedImg}
              src={accessibilityGraphicsTrusted}
            />
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT3)}
            </Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT4)}
            </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT5)}
            </Typography>
            <hr />
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT6)}
            </Typography>
            <hr />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_STATEMENT7)} </b>
              <Link sx={styles.linkStyle} href="/customer-service/contact-us">
                <b> {i18next.t(TK.ACCESSIBILITY_LINK)} </b>
              </Link>
            </Typography>
            <hr />
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT8)}
            </Typography>
            <HideOnXs>
              <Typography mt={1}>
                <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE1)}</b>
                {i18next.t(TK.ACCESSIBILITY_USER_TEXT1)}
              </Typography>
            </HideOnXs>
            <ShowOnlyXs>
              <li>
                <Typography mt={1} sx={styles.mobileLinkStyle}>
                  <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE1)}</b>
                  {i18next.t(TK.ACCESSIBILITY_USER_TEXT1)}
                </Typography>
              </li>
            </ShowOnlyXs>
            <HideOnXs>
              <Typography mt={1}>
                <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE2)}</b>
                {i18next.t(TK.ACCESSIBILITY_USER_TEXT2)}
              </Typography>
            </HideOnXs>
            <ShowOnlyXs>
              <li>
                <Typography mt={1} sx={styles.mobileLinkStyle}>
                  <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE2)}</b>
                  {i18next.t(TK.ACCESSIBILITY_USER_TEXT2)}
                </Typography>
              </li>
            </ShowOnlyXs>
            <HideOnXs>
              <Typography my={1}>
                <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE3)}</b>
                {i18next.t(TK.ACCESSIBILITY_USER_TEXT3)}
              </Typography>
            </HideOnXs>
            <ShowOnlyXs>
              <li>
                <Typography my={1} sx={styles.mobileLinkStyle}>
                  <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE3)}</b>
                  {i18next.t(TK.ACCESSIBILITY_USER_TEXT3)}
                </Typography>
              </li>
            </ShowOnlyXs>
            <hr />
          </Box>
          <Box mb={2}>
            <Typography
              variant="h2"
              sx={styles.accessibilityHeader}
              textAlign="center"
            >
              {i18next.t(TK.ACCESSIBILITY_TITLE3)}
            </Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT9)} the{' '}
              <Link
                target="_blank"
                sx={styles.linkStyle}
                display="contents"
                href="https://www.audioeye.com/understanding-wcag-2-0/"
              >
                {i18next.t(TK.ACCESSIBILITY_WEB_LINK)}
              </Link>{' '}
              {i18next.t(TK.ACCESSIBILITY_STATEMENT10)}
            </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT11)}
            </Typography>
            <Typography mb={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE4)}</b>
            </Typography>
            <Link sx={styles.linkStyle} href="https://www.w3.org/WAI/">
              {i18next.t(TK.ACCESSIBILITY_LINK_TEXT1)}
            </Link>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_GUIDELINE)}
            </Typography>
            <hr />
          </Box>
          <Box mb={2}>
            <Typography
              variant="h2"
              sx={styles.accessibilityHeader}
              textAlign="center"
              mb={1}
            >
              {i18next.t(TK.ACCESSIBILITY_TITLE4)}
            </Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT9)} the{' '}
              <Link
                target="_blank"
                sx={styles.linkStyle}
                display="contents"
                href="https://www.audioeye.com/understanding-wcag-2-0/"
              >
                {i18next.t(TK.ACCESSIBILITY_WEB_LINK)}
              </Link>{' '}
              {i18next.t(TK.ACCESSIBILITY_STATEMENT10)}
            </Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT11)}
            </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT12)}
              <Link sx={styles.linkStyle} href="/customer-service/contact-us">
                {i18next.t(TK.ACCESSIBILITY_LINK_TEXT)}
              </Link>
            </Typography>
            <hr />
          </Box>
          <Box>
            <Typography variant="h2" sx={styles.accessibilityHeader} mb={2}>
              {i18next.t(TK.ACCESSIBILITY_TITLE5)}
            </Typography>
            <Typography>{i18next.t(TK.ACCESSIBILITY_STATEMENT13)}</Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT14)}
            </Typography>
            <Typography mt={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT15)}
            </Typography>
            <Button sx={styles.activateBtnStyle}>Activate Ally Toolbar</Button>
            <Typography mb={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_USER_TITLE5)}</b>
            </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT16)}
            </Typography>
            <Image sx={styles.graphicsCtaImg} src={accessibilityGraphicsCTA} />
            <Typography sx={styles.graphicsTitle}>
              {i18next.t(TK.ACCESSIBILITY_USER_TITLE6)}
            </Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT17)}
            </Typography>
            <Link
              target="_blank"
              sx={styles.graphicsLinkStyle}
              href="https://www.audioeye.com/technology/"
            >
              {i18next.t(TK.ACCESSIBILITY_LINK_TEXT2)}
            </Link>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT18)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg1} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT1)}</b>
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT1)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg2} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT2)}</b>
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT2)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg3} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT3)}</b>
              {i18next.t(TK.ACCESSIBILITY_EXP_TEXT3_LINK)}
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT3)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg4} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT4)}</b>
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT4)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg5} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT5)}</b>
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT5)}
            </Typography>
            <Image width="40px" height="40px" src={accessibilityGraphicsImg6} />
            <Typography my={1}>
              <b>{i18next.t(TK.ACCESSIBILITY_EXP_TEXT6)}</b>
            </Typography>
            <Typography mb={1}>
              {i18next.t(TK.ACCESSIBILITY_EXP_SUBTEXT6)}
            </Typography>
            {/* <Image></Image> */}
            <hr />
          </Box>
          <Box mt={2}>
            <Typography variant="h2" sx={styles.accessibilityHeader1}>
              {i18next.t(TK.ACCESSIBILITY_USER_TITLE7)}
            </Typography>
            <Typography>{i18next.t(TK.ACCESSIBILITY_STATEMENT19)}</Typography>
            <Typography my={1}>
              {i18next.t(TK.ACCESSIBILITY_STATEMENT20)}
            </Typography>
            <Link
              target="_blank"
              sx={styles.linkStyle}
              mb={1}
              href="https://www.facebook.com/help/141636465971794/"
            >
              {i18next.t(TK.ACCESSIBILITY_LINK_TEXT3)}
            </Link>
            <Link
              target="_blank"
              sx={styles.linkStyle}
              display="block"
              href="https://www.facebook.com/help/141636465971794/"
            >
              {i18next.t(TK.ACCESSIBILITY_LINK_TEXT4)}
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};
