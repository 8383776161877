import { Box, Skeleton } from '@mui/material';
import { CarouselItemSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton/CarouselItemSkeleton';
import { WidgetWrapper } from 'components/Home/common/styled';
import {
  carousel,
  widgetTitleSkeleton,
} from 'components/Home/CarouselSimplified/components/Skeleton/styles';
import { range } from 'utils/range';
import { settings } from 'components/Home/CarouselSimplified/settings';
import React, { ReactElement } from 'react';
import Slider from 'react-slick';

export const CarouselSimplifiedSkeleton = (): ReactElement => (
  <WidgetWrapper>
    <Skeleton sx={widgetTitleSkeleton} />
    <Box sx={carousel} data-test="carousel-simplified">
      <Slider {...settings}>
        {range(4).map((rangeItem) => (
          <CarouselItemSkeleton key={rangeItem} />
        ))}
      </Slider>
    </Box>
  </WidgetWrapper>
);
