import { CancelToken } from 'axios';
import { availabilityStoreService } from 'api/availability-store.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const getAvailableStoresForPickup = createAsyncThunk(
  'availability/for-pickup',
  (
    {
      date,
      arrangementId,
      productId,
      areaId,
      cancelToken,
      test,
    }: {
      date: Date;
      arrangementId: number;
      productId: number;
      areaId: number;
      cancelToken: CancelToken;
      test: boolean;
    },
    { rejectWithValue },
  ) =>
    availabilityStoreService
      .getAvailableStoresForPickup({
        date,
        arrangementId,
        productId,
        areaId,
        cancelToken,
        test,
      })
      .catch((err) => rejectWithValue(parseAxiosError(err))),
);
