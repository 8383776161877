export const BILLING_INFORMATION_TITLE = 'reward.billing_information_title';
export const STREET_ADDRESS = 'reward.street_address';
export const APT_FLOOR_SUITE_BUTTON = 'reward.apt_floor_suite_button';
export const APT_FLOOR_SUITE_LABEL = 'reward.apt_floor_suite_label';
export const CHOOSE_OPTION = 'reward.choose_option';
export const COUNTRY_LABEL = 'reward.country';
export const CITY_LABEL = 'reward.city';
export const CONTACT_DIALOG_TITLE = 'contact_dialog_title';
export const ADDRESS_BOOK_TITLE = 'contact_dialog_address_title';
export const ADDRESS_BOOK = 'address.book';
export const ADDRESS_TITLE = 'address-title';
export const ADDRESS_TYPE_LABEL = 'ADDRESS_TYPE_LABEL';
export const BUSINESS_NAME = 'account_business_name';
export const ZIP_LABEL = 'reward.zip';
export const STATE_LABEL = 'reward.state';
export const PHONE_NUMBER = 'reward.phone';
export const EMAIL_ADDRESS = 'reward.email_address';
export const REWARDS_BIRTHDAY = 'reward.signup_birthday';
export const REWARDS_BIRTH_DATE = 'signup_birth_date.text';
export const REWARDS_BIRTH_DAY = 'signup_birth_day.text';
export const REWARDS_BIRTH_DAY_REQUIRED = 'signup_birth_day.error';
export const REWARDS_BIRTH_MONTH = 'signup_birth_month.text';
export const REWARDS_BIRTH_MONTH_REQUIRED = 'signup_birth_month.error';
export const REWARDS_BIRTH_YEAR = 'signup_birth_year.text';
export const REWARDS_BIRTH_YEAR_PLACEHOLDER = 'signup_birth_year.placeholder';
export const PO_REFERENCE_LABEL = 'reward.po_reference';
export const REWARD_TITLE = 'reward.reward_title';
export const REWARD_SUBTITLE = 'reward.reward_subtitle';
export const REWARD_LIST1 = 'reward.reward_list1';
export const REWARD_LIST2 = 'reward.reward_list2';
export const REWARD_LIST3 = 'reward.reward_list3';
export const REWARD_LIST4 = 'reward.reward_list4';
export const REWARD_LIST5 = 'reward.reward_list5';
export const REWARD_LIST6 = 'reward.reward_list6';
export const REWARD_LIST7 = 'reward.reward_list7';
export const REWARD_BENEFITS = 'reward.reward_benefits';
export const REWARD_MEMBER = 'reward.reward_member';
export const REWARD_MEMBER_CHECKBOX = 'paymeny.reawrd_member_checkbox';
export const REWARD_SIGNUP_LABEL = 'reward.reward_signup_label';
export const PLACE_ORDER = 'reward.place_order';
export const SUBMITTING = 'reward.submitting';
export const PLACE_ORDER_TERMS_CONDITIONS =
  'reward.place_order_terms_conditions';
export const ERROR_ENTER_FIRST_NAME = 'reward.billing_form.error_first_name';
export const ERROR_ENTER_LAST_NAME = 'reward.billing_form.error_last_name';
export const ERROR_ENTER_STREET = 'reward.billing_form.error_street_name';
export const ERROR_ENTER_ADDRESS_TYPE =
  'reward.billing_form.error_address_type';
export const ERROR_ENTER_ZIP_CODE = 'reward.billing_form.error_zip_code';
export const ERROR_ENTER_CITY = 'reward.billing_form.error_city_name';
export const ERROR_SELECT_STATE = 'reward.billing_form.error_select_state';
export const ERROR_ENTER_STATE = 'reward.billing_form.error_state_name';
export const ERROR_ENTER_COUNTRY = 'reward.billing_form.error_country_name';
export const ERROR_ENTER_PHONE = 'reward.billing_form.error_phone';
export const ERROR_ENTER_PASSWORD = 'reward.billing_form.error_password';
export const ERROR_PASSWORD_INVALID = 'signup_password.invalid';
export const ERROR_PASSWORD_MINIMUM = 'signup_password.minimum';
export const ERROR_VERIFY_PASSWORD_MATCH = 'signup.verify_password_match';
export const CANT_BEGIN_WITH_O_OR_1 =
  'reward.billing_form.phone_cant_begin_with_0_or_1';
export const ERROR_ENTER_EMAIL = 'reward.billing_form.error_email';
export const VENMO_CANCELLED = 'venmo.cancelled';
export const VENMO_APP_CANCELLED = 'venmo.app_cancelled';
export const CART_COUNT_SINGLE = 'heading.cart_count_single';
export const CART_COUNT_MULTI = 'heading.cart_count_multi';
export const RECIPIENT_COUNT_SINGLE = 'heading.recipient_count_single';
export const RECIPIENT_COUNT_MULTI = 'heading.recipient_count_multi';
export const RETURN_TO_CART = 'heading.return_to_cart';
export const CREDIT_CARD = 'Credit Card';
export const CONTINUE_WITH = 'or continue with';
export const VIEW_CART_SUMMARY = 'reward.view_cart_summary';
export const LOG_IN = 'reward.log_in';
export const ACCESS_ADDRESS_BOOK = 'reward.login_to_access';
export const SAME_AS = 'reward.same_as';
export const LOGIN_OR = 'reward.login_or';
export const ORDER_TOTAL = 'reward.order_total';
export const RECIPIENT = 'Recipient';
export const ITEMS = 'Item(s)';
export const CREATE_YOUR_PASSWORD = 'reward.create_your_password';
export const PASSWORD_LABEL = 'reward.password';
export const CONFIRM_PASSWORD_LABEL = 'reward.confirm_password';
export const EMAIL_ALREADY_EXIST = 'reward.email_already_exist';
export const PASSWORD_STRENGTH = 'reward.reward_strength';
export const EMAIL_ALREADY_EXIST_TITLE = 'reward.email_already_exist_title';
export const LOG_IN_BUTTON_BEFORE_TEXT = 'reward.login_in_button_before_text';
export const LOG_IN_BUTTON_TEXT = 'reward.login_in_button_text';
export const LOG_IN_BUTTON_AFTER_TEXT = 'reward.login_in_button_after_text';
export const APPROVE_WITH_ID = 'reward.approve_with_id';
export const CALL_ISSUER = 'reward.call_issuer';
export const CARD_NOT_SUPPORTED = 'reward.card_not_supported';
export const CARD_VELOCITY_EXCEEDED = 'reward.card_velocity_exceeded';
export const CURRENCY_NOT_SUPPORTED = 'reward.currency_not_supported';
export const DO_NOT_HONOR = 'reward.do_not_honor';
export const DO_NOT_TRY_AGAIN = 'reward.do_not_try_again';
export const DUPLICATE_TRANSACTION = 'reward.duplicate_transaction';
export const EXPIRED_CARD = 'reward.expired_card';
export const FRAUDULENT = 'reward.fraudulent';
export const GENERIC_DECLINE = 'reward.generic_decline';
export const INCORRECT_NUMBER = 'reward.incorrect_number';
export const INCORRECT_CVC = 'reward.incorrect_cvc';
export const INCORRECT_PIN = 'reward.incorrect_pin';
export const INCORRECT_ZIP = 'reward.incorrect_zip';
export const INSUFFICIENT_FUNDS = 'reward.insufficient_funds';
export const INVALID_ACCOUNT = 'reward.invalid_account';
export const INVALID_AMOUNT = 'reward.invalid_amount';
export const INVALID_CVC = 'reward.invalid_cvc';
export const INVALID_EXPIRY_MONTH = 'reward.invalid_expiry_month';
export const INVALID_EXPIRY_YEAR = 'reward.invalid_expiry_year';
export const INVALID_NUMBER = 'reward.invalid_number';
export const INVALID_PIN = 'reward.invalid_pin';
export const ISSUER_NOT_AVAILABLE = 'reward.issuer_not_available';
export const LOST_CARD = 'reward.lost_card';
export const MERCHANT_BLACKLIST = 'reward.merchant_blacklist';
export const NEW_ACCOUNT_INFORMATION_AVAILABLE =
  'reward.new_account_information_available';
export const NO_ACTION_TAKEN = 'reward.no_action_taken';
export const NOT_PERMITTED = 'reward.not_permitted';
export const OFFLINE_PIN_REQUIRED = 'reward.offline_pin_required';
export const ONLINE_OR_OFFLINE_PIN_REQUIRED =
  'reward.online_or_offline_pin_required';
export const PICKUP_CARD = 'reward.pickup_card';
export const PIN_TRY_EXCEEDED = 'reward.pin_try_exceeded';
export const PROCESSING_ERROR = 'reward.processing_error';
export const REENTER_TRANSACTION = 'reward.reenter_transaction';
export const RESTRICTED_CARD = 'reward.restricted_card';
export const REVOCATION_OF_ALL_AUTHORIZATIONS =
  'reward.revocation_of_all_authorizations';
export const REVOCATION_OF_AUTHORIZATION = 'reward.revocation_of_authorization';
export const SECURITY_VIOLATION = 'reward.security_violation';
export const SERVICE_NOT_ALLOWED = 'reward.service_not_allowed';
export const STOLEN_CARD = 'reward.stolen_card';
export const TESTMODE_DECLINE = 'reward.testmode_decline';
export const TRANSACTION_NOT_ALLOWED = 'reward.transaction_not_allowed';
export const TRY_AGAIN_LATER = 'reward.try_again_later';
export const WITHDRAWAL_COUNT_LIMIT_EXCEEDED =
  'reward.withdrawal_count_limit_exceeded';
export const ACCOUNT_TITLE = 'account_title';
export const ACCOUNT_MY_ADDRESS = 'account_my_address';
