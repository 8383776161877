import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const rating =
  ({ hideButton }: { hideButton?: boolean }): ThemedSxProps =>
  (theme) => ({
    '.bv_avgRating_component_container, .bv_numReviews_text, .bv_button_buttonMinimalist ':
      {
        pt: '4px !important',
      },
    '.bv_button_buttonMinimalist': {
      display: hideButton
        ? 'none !important'
        : ['none !important', 'block !important'],
    },
    '&&& .bv_main_container_row_flex': {
      [theme.breakpoints.only('xs')]: {
        pr: '0 !important',
      },
    },
  });
