import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise, AxiosResponse } from 'axios';
import { Order } from 'redux/case/caseOrder/case-order.types';
import { OrderTrackResponse } from 'api/order/types';

class OrderApiService extends ApiBaseService {
  find(orderNumber: string): AxiosPromise<Order[]> {
    return this.apibase.get<Order[]>('orders/contact', {
      params: {
        orderNumber,
      },
    });
  }

  trackOrder(
    orderNumber: string,
  ): Promise<AxiosResponse<OrderTrackResponse | undefined>> {
    return this.apibase.get<OrderTrackResponse>('/orders/track-order', {
      params: { orderNumber },
    });
  }
}

const orderApiService = new OrderApiService();

export { orderApiService };
