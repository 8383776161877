import { Box } from '@mui/material';
import { trackComponent } from 'utils/telemetry/app-insights';
import React, { Suspense, lazy } from 'react';

const BusinessGifting = lazy(
  () => import('containers/BusinessGifting/BusinessGifting'),
);

export const LazyBusinessGifting = trackComponent(() => {
  return (
    <Suspense fallback={<Box height="100vh" />}>
      <BusinessGifting />
    </Suspense>
  );
}, 'Business Gifting');
