import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const accountCont: SxProps = {
  marginTop: { md: '-150px', sm: '460px', xs: '360px' },
  position: { md: 'absolute', sm: 'inherit', xs: 'relative' },
  left: { md: '60%', xs: '0%' },
};

export const accountPromo: SxProps = {
  position: { md: 'absolute', sm: 'inherit', xs: 'relative' },
  width: { md: '400px', sm: '90%', xs: '90%' },
  top: '245px',
  marginLeft: { md: '0', sm: '40px', xs: '20px' },
  background: colors.systemBackground,
  '& .strawberryImg': {
    position: 'absolute',
    marginTop: { md: '-90px', sm: '-85px', xs: '-74px' },
    width: { md: '210px', sm: '31%', xs: '60%' },
    height: { md: '370px', sm: '350px', xs: '330px' },
    marginLeft: { md: '169px', sm: '72%', xs: '46%' },
  },
  '& .strawberryImg1': {
    position: 'absolute',
    height: { md: '370px', sm: '360px', xs: '300px' },
    width: { md: '210px', sm: '48%', xs: '55%' },
    marginTop: { md: '-60px', sm: '-100px', xs: '-15px' },
    marginLeft: { md: '169px', sm: '55%', xs: '52%' },
  },
  '& .createAccountBtn': {
    width: { md: '360px', sm: '272px', xs: '100%' },
  },
  '& .rewardsBtn': {
    width: { md: '360px', sm: '360px', xs: '100%' },
  },
  '& .rewardText': {
    display: 'inline-block',
    width: '53%',
    textAlign: 'center',
    marginTop: '20px',
    '& p': {
      display: { md: 'block', sm: 'contents', xs: 'block' },
    },
  },
  '& .termsStyle': {
    margin: {
      md: '25px 16px 0 110px',
      sm: '25px 16px 0 35px',
      xs: '25px 16px 0 70px',
    },
  },
};

export const rewardContainer: SxProps = {
  padding: '32px 20px',
  height: { md: '444px', sm: '348px', xs: '462px' },
};

export const accountContainer: SxProps = {
  padding: '32px 20px',
  height: { md: '540px', sm: '336px', xs: '484px' },
};

export const accountHead: SxProps = {
  width: { md: '93%', sm: '49%', xs: '94%' },
  fontWeight: '600',
  fontSize: '20px',
  textAlign: 'center',
  marginLeft: { md: '25px', sm: '51%', xs: '0' },
};

export const rewardHead: SxProps = {
  fontWeight: '600',
  fontSize: '20px',
  width: { md: '100%', sm: '62%', xs: '84%' },
  marginLeft: { md: '0', sm: '-3%', xs: '8%' },
  textAlign: 'center',
};

export const accountText: SxProps = {
  fontWeight: '600',
  fontSize: { md: '16px', sm: '16px', xs: '14px' },
  margin: { md: '15px 0', sm: '15px 0', xs: '10px 0' },
  width: { md: '28%', sm: '62%', xs: '62%' },
  textAlign: { md: 'center', sm: 'right', xs: 'left' },
};

export const listText: SxProps = {
  '& li': {
    fontFamily: fonts.poppins,
    fontWeight: '400',
    fontSize: { md: '16px', sm: '16px', xs: '14px' },
    lineHeight: '170%',
    marginLeft: { md: '15px', sm: '310px', xs: '10px' },
  },
  '& p': {
    fontFamily: fonts.poppins,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    marginLeft: '15px',
    textAlign: 'center',
    width: '144px',
    marginTop: '10px',
  },
};

export const rewardText: SxProps<Theme> = (theme) => ({
  fontSize: { md: '16px', sm: '16px', xs: '14px' },
  textAlign: 'center',
  width: { md: '100%', sm: '38%', xs: '100%' },
  marginTop: { md: '25px', sm: '-190px', xs: '15px' },
  '& .fontWt': {
    fontWeight: '600',
  },
  '& .freetxt': {
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
});

export const redBtnStyle: SxProps<Theme> = (theme) => ({
  height: { md: '56px', sm: '56px', xs: '40px' },
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: '600',
  fontSize: '15px',
  marginRight: '15px',
  marginTop: '30px',
});

export const linkStyle: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  textAlign: 'center',
  textDecorationLine: 'underline',
  width: { md: '40%', sm: '40%', xs: '50%' },
  margin: {
    md: '25px 16px 0 100px',
    sm: '25px 16px 0 5px',
    xs: '15px 16px 0 70px',
  },
  display: { md: 'block', sm: 'block', xs: 'block' },
};

export const shopTitle: SxProps = {
  fontWeight: { md: '700', sm: '700', xs: '600' },
  fontSize: { md: '20px', sm: '20px', xs: '16px' },
  marginBottom: { md: '20px', sm: '20px', xs: '30px' },
};

export const shopHead: SxProps = {
  marginTop: { md: '95px', sm: '810px', xs: '1075px' },
  position: 'absolute',
  left: { md: '60%', sm: '6%', xs: '0%' },
  width: { md: '400px', sm: '100%', xs: '100%' },
  display: { md: 'block', sm: 'flex', xs: 'block' },
  textAlign: 'center',
};

export const orderDetailsHead: SxProps = {
  marginTop: { md: '95px', sm: '331px', xs: '809px' },
  position: 'absolute',
  left: { md: '60%', sm: '6%', xs: '0%' },
  width: { md: '400px', sm: '100%', xs: '100%' },
  display: { md: 'block', sm: 'flex', xs: 'block' },
  textAlign: 'center',
};

export const btnStyle: SxProps<Theme> = (theme) => ({
  width: { md: '360px', sm: '336px', xs: '320px' },
  height: { md: '56px', sm: '56px', xs: '40px' },
  marginBottom: { md: '0', sm: '0', xs: '25px' },
  marginTop: { md: '0', sm: '-15px', xs: '-15px' },
  marginLeft: { md: '0', sm: '30px', xs: '0' },
  background: theme.palette.primary.contrastText,
  border: `1px solid ${theme.palette.primary.main}`,
  fontWeight: '600',
  fontSize: '15px',
});
