import {
  ResetPasswordParsedResponse,
  ResetPasswordStatus,
  isError,
} from 'api/auth-legacy/reset-password.misc';
import { appInsights } from 'utils/telemetry/app-insights';
import { authLegacyApiService } from 'api/auth-legacy/auth.legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { segment } from 'service/segment/segment';

export const resetPassword = createAsyncThunk<
  ResetPasswordParsedResponse,
  { email: string },
  { rejectValue: { _Status: ResetPasswordStatus } }
>('auth/reset-password', async ({ email }, { rejectWithValue }) => {
  let data: { d: string };
  try {
    const response = await authLegacyApiService.resetPassword(email);
    data = response.data;
  } catch (e) {
    return rejectWithValue({ _Status: ResetPasswordStatus.Unexpected });
  }
  const res = JSON.parse(data.d) as ResetPasswordParsedResponse;
  if (!res || !res._Status) {
    return rejectWithValue({ _Status: ResetPasswordStatus.Unexpected });
  }
  if (isError(res._Status)) {
    return rejectWithValue(res);
  }
  segment.userForgotPassword(email);
  appInsights.trackEvent({ name: 'Password reset requested' });
  return res;
});
