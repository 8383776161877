import { Button, IconButton } from '@mui/material';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { SIGNUP_LOGIN_ARIA_LABEL } from 'components/Header/Menus/translations/header-menus.locale.constants';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import React, { FC, MouseEvent, ReactNode, TouchEvent } from 'react';
import i18next from 'i18next';

interface Props {
  open: boolean;
  onClick: (
    event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>,
  ) => void;
  children?: ReactNode;
  icon?: ReactNode;
}

export const MenuButton: FC<Props> = ({ onClick, open, icon, children }) => (
  <>
    <ShowUpMd>
      <Button
        color="secondary"
        id="account-menu-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        startIcon={icon}
        aria-label={i18next.t(SIGNUP_LOGIN_ARIA_LABEL)}
        data-test="account"
        style={{ fontWeight: 600 }}
      >
        {children}
      </Button>
    </ShowUpMd>
    <HideUpMd>
      <IconButton
        color="secondary"
        id="account-menu-button-mobile"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-label={i18next.t(SIGNUP_LOGIN_ARIA_LABEL)}
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        data-test="account-mobile"
      >
        <AccountCircleOutlinedIcon />
      </IconButton>
    </HideUpMd>
  </>
);
