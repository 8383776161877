import { AreaSearchResponse } from 'components/StoreFinder/interfaces/store-finder-page.interfaces';

export const filterOutCityAreas = (
  results: AreaSearchResponse[],
): AreaSearchResponse[] => {
  // Words to Filter by - sorted alphabetically.
  const filterWords = [
    '&',
    'Atlanta Ndc',
    'Bank America',
    'Bank of',
    'Depot',
    'Dept',
    'Department Of Revenue',
    'Departments',
    'Georgia Power',
    'Georgia Tech',
    'government',
    'state gov',
    'govt',
    'lottery',
    'Mississippi State',
    'Mississippi Valley State Col',
    'Naval Air Station',
    'North Georgia College',
    'Postal Credit Union',
    'Post Office',
    'printing',
    'State Penitentiary',
    'Univ',
    'University',
    'Wal-Mart Inc',
    'Wal Mart Inc',
  ].sort();

  const filteredResults = results.filter((result) => {
    const cityNameLowerCase = result.cityName.toLowerCase();

    return !filterWords.some((word) =>
      cityNameLowerCase.includes(word.toLowerCase()),
    );
  });

  return filteredResults;
};
