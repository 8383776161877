import * as styles from 'components/PDP/Details/components/Gallery/components/BigGallery/styles';
import {
  BottomGallery,
  GalleryItemWrapper,
} from 'components/PDP/Details/components/Gallery/components/BigGallery/styled';
import { Box } from '@mui/material';
import { Media } from 'components/PDP/Details/components/Gallery/components/Media';
import { MediaPreview } from 'enums/media-preview.enum.';
import { PREVIEW_SLIDERS_AMOUNT } from 'components/PDP/Details/components/Gallery/constants';
import { PreviewCarousel } from 'components/PDP/Details/components/Gallery/components/BigGallery/components/PreviewCarousel/PreviewCarousel';
import { Slider } from 'components/PDP/Details/components/Gallery/components/BigGallery/components/Slider';
import { isString } from 'utils/is-string';
import { isVideo } from 'components/Home/utils/is-video';
import { videoImg } from 'utils/regex';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import React, { FC, ReactElement, useState } from 'react';
import SlickSlider from 'react-slick';

interface Props {
  images: (string | ((isBottomCarousel?: boolean) => ReactElement))[];
  imageAlt: string;
  showHorizontalBorders: boolean;
}

export const BigGallery: FC<Props> = ({
  images = [],
  imageAlt,
  showHorizontalBorders,
}) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [sliderTop, setSliderTop] = useState<SlickSlider | null>(null);
  const [sliderBottom, setSliderBottom] = useState<SlickSlider | null>(null);

  const hasCarousel = images.length > 1;

  return (
    <>
      <Slider
        ref={(slider) => setSliderTop(slider)}
        asNavFor={sliderBottom ?? undefined}
        afterChange={(index) => {
          setSelectedImage(index);
        }}
      >
        {images.map((image, index) => (
          <Box
            key={`${image.toString()}_main`}
            role="button"
            sx={styles.mediaMask(showHorizontalBorders)}
          >
            {isString(image) ? (
              <Media
                src={image}
                alt={imageAlt}
                forcePause={index !== selectedImage}
              />
            ) : (
              image()
            )}
          </Box>
        ))}
      </Slider>

      {hasCarousel && (
        <BottomGallery withoutArrows={images.length <= PREVIEW_SLIDERS_AMOUNT}>
          <PreviewCarousel
            ref={(slider) => setSliderBottom(slider)}
            asNavFor={sliderTop ?? undefined}
          >
            {images.map((image, index) => (
              <GalleryItemWrapper
                key={image.toString()}
                role="button"
                active={index === selectedImage}
              >
                <div>
                  {isString(image) ? (
                    <>
                      <Media
                        src={isVideo(image) ? videoImg(image) : image}
                        alt={imageAlt}
                        preview={
                          isVideo(image)
                            ? MediaPreview.VIDEO
                            : MediaPreview.IMAGE
                        }
                      />
                      {isVideo(image) && (
                        <PlayCircleOutlineIcon sx={styles.videoIcon} />
                      )}
                    </>
                  ) : (
                    image(true)
                  )}
                </div>
              </GalleryItemWrapper>
            ))}
          </PreviewCarousel>
        </BottomGallery>
      )}
    </>
  );
};
