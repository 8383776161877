import { Links } from 'enums/common-links';
import { navigate } from 'utils/navigate';
import { reset } from 'components/Authentication/LogIn/feature/slice';
import {
  selectIsBlocked,
  selectIsFinished,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';

export const useValidateRewardUserSession = (): void => {
  const dispatch = useDispatch();
  const isUserSessionAvailable = useSelector(selectIsFinished);
  const isSelectIsBlocked = useSelector(selectIsBlocked);
  const user = useSelector(({ userSession: { data } }) => data);

  if (!isSelectIsBlocked && isUserSessionAvailable && user.userId < 0) {
    navigate(Links.EdibleRewardsLogin);
  } else {
    dispatch(reset());
  }
};
