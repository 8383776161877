import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { Box, Grid, Typography } from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Reward } from 'providers/Session/feature/user.types';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { colors } from 'utils/theme/colors';
import { formatToLegacyDateString } from 'utils/date';
import { strawberryBadge } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export type Props = {
  reward: Reward;
  historyReward: boolean;
};

export const RewardsContainer: FC<Props> = ({ reward, historyReward }) => {
  return (
    <>
      <Grid key={reward.rewardId} sx={styles.rewardGrid}>
        <Box sx={styles.imgStyle}>
          <img src={strawberryBadge} alt={reward.imageAltTag} height="48px" />
        </Box>
        <Box sx={styles.promoTextContents}>
          <HideOnXs>
            <Typography variant="subtitle3" fontWeight="500">
              {reward.title} -{' '}
            </Typography>
            <Typography variant="body3">
              {i18next.t(TK.PROMO_MESSAGE, {
                message: reward.message,
              })}
            </Typography>
          </HideOnXs>
          <ShowUpXs>
            <Box>
              <Typography variant="subtitle3" fontWeight="500">
                {reward.title} -{' '}
              </Typography>
              <Typography variant="body3">
                {i18next.t(TK.PROMO_MESSAGE, {
                  message: reward.message,
                })}
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography variant="subtitle3" fontWeight="500">
                {i18next.t(TK.PROMO_CODE)}
              </Typography>
              <Typography variant="body3">{reward.code}</Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="subtitle3"
                fontWeight="500"
                style={{ color: historyReward ? colors.primaryRed : '' }}
              >
                {i18next.t(TK.PROMO_EXPIRES)}
              </Typography>
              <Typography
                variant="body3"
                style={{ color: historyReward ? colors.primaryRed : '' }}
              >
                {formatToLegacyDateString(
                  new Date(reward.expireDate.toString().replace('Z', '')),
                )}
              </Typography>
            </Box>
          </ShowUpXs>
        </Box>
        <HideOnXs>
          <Box display="grid" width="22%">
            <Typography variant="subtitle3" fontWeight="500">
              Code: {reward.code}
            </Typography>
            <Typography
              variant="subtitle3"
              fontWeight="500"
              style={{ color: historyReward ? colors.primaryRed : '' }}
            >
              {i18next.t(TK.PROMO_EXPIRES)}
              {formatToLegacyDateString(
                new Date(reward.expireDate.toString().replace('Z', '')),
              )}
            </Typography>
          </Box>
        </HideOnXs>
      </Grid>
    </>
  );
};
