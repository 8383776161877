import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  CarouselContainer,
  Headline,
  title,
} from 'components/Home/CarouselDetailed/styles';
import {
  CarouselDetailedContent,
  CarouselDetailedItem,
} from 'components/Home/CarouselDetailed/types';
import { CarouselDetailedSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton';
import { CarouselItem } from 'components/Home/CarouselDetailed/components/CarouselItem';
import { ComponentProps } from 'components/Home/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import {
  ViewAllButtonDesktop,
  ViewAllButtonMobile,
  WidgetWrapper,
} from 'components/Home/common/styled';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { settings } from 'components/Home/CarouselDetailed/settings';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useIsBetweenMdnLg } from 'utils/hidden/use-is-between-md-lg';
import { useIsUpLg } from 'utils/hidden/use-is-up-lg';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import Slider from 'react-slick';

export const CarouselDetailed: FC<ComponentProps> = ({
  type,
  id,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const content = useResponsiveContent<CarouselDetailedContent>(
    componentData,
    'md',
  );
  const isPlpPageActive = useSelector(plpGetComponents);

  const isDesktop = useIsUpLg();
  const isTablet = useIsBetweenMdnLg();

  let slidesToShow;
  if (isDesktop) {
    slidesToShow = 5.5;
  } else if (isTablet) {
    slidesToShow = 4.5;
  } else {
    slidesToShow = 2.5;
  }
  const sliderSettings = {
    slidesToShow,
    ...settings,
  };
  const initialSlides = content.items.slice(0, sliderSettings.slidesToShow);

  const { imagesPreloaded } = useImagePreloader(initialSlides);

  const handleAnalyticsClick = (position: number, linkName: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: position.toString(),
      module_type: type,
      module_name: name,
      link: linkName,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  const handleViewAllButtonClick = () => {
    handleAnalyticsClick(0, content?.viewAllButtonText);
  };

  if (!imagesPreloaded) {
    return <CarouselDetailedSkeleton />;
  }

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <WidgetWrapper
        id={getId(type, id)}
        data-test="carousel-detailed-container"
      >
        <Headline>
          <PureHtmlTypography
            sx={title}
            variant="h2"
            data-test="carousel-detailed-title"
          >
            {checkForFrench(content?.title, content?.frTitle)}
          </PureHtmlTypography>
          <ViewAllButtonDesktop
            size="large"
            variant="outlined"
            href={content?.url}
            onClick={handleViewAllButtonClick}
            color="primary"
            data-test="view-all-button-big"
          >
            {checkForFrench(
              content?.viewAllButtonText,
              content?.frViewAllButtonText,
            )}
          </ViewAllButtonDesktop>
        </Headline>
        <CarouselContainer data-test="carousel-detailed">
          <Slider {...sliderSettings}>
            {content.items.map(
              (carouselItem: CarouselDetailedItem, index: number) => (
                <CarouselItem
                  key={carouselItem.id}
                  item={carouselItem}
                  data-test="carousel-detailed-slide"
                  sequence={index + 1}
                  analyticsClickHandler={() =>
                    handleAnalyticsClick(index + 1, carouselItem.name)
                  }
                />
              ),
            )}
          </Slider>
        </CarouselContainer>
        <ViewAllButtonMobile
          size="large"
          variant="outlined"
          href={content?.url}
          onClick={handleViewAllButtonClick}
          color="primary"
          data-test="view-all-button-big"
        >
          {checkForFrench(
            content?.viewAllButtonText,
            content?.frViewAllButtonText,
          )}
        </ViewAllButtonMobile>
      </WidgetWrapper>
    </RenderWhenInView>
  );
};
