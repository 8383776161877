import * as TK from 'components/Cart/Details/translations/locale.constant';
import * as select from 'components/Cart/feature/selectors';
import * as styles from 'components/Cart/Details/styles';
import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { Image } from 'components/common/Image/Image';
import { freeMessageLogo } from 'utils/cloudinaryAssets';
import { useSelector } from 'react-redux';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  isUnlockPdpMessage?: boolean;
}

export const FreeDeliveryMessage: FC<Props> = ({ isUnlockPdpMessage }) => {
  const orderTotalThreshold = window.MIN_ORDER_TOTAL_THRESHOLD;
  const { orderTotal, leftAmount, isAnyDeliveryAndNextDayOrder } = useSelector(
    select.selectFreeDeliveryDetails,
  );
  const percentage = Math.min((orderTotal / orderTotalThreshold) * 100, 100);
  const isDeliveryFeeDiscountApplied =
    orderTotal > 0 && isAnyDeliveryAndNextDayOrder;

  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down('sm'));
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  return (
    <>
      {isDeliveryFeeDiscountApplied ? (
        <Box sx={styles.messageWrapper}>
          <Box width="100%" display="flex">
            <Typography sx={styles.messageText}>
              {leftAmount > 0
                ? i18next.t(TK.FREE_DELIVERY_REMAINING, {
                    amount: leftAmount.toFixed(2),
                  })
                : i18next.t(
                    isMobile
                      ? TK.FREE_DELIVERY_COMPLETED_MOBILE
                      : TK.FREE_DELIVERY_COMPLETED,
                  )}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Image src={freeMessageLogo} width={24} height={24} />
            </Box>
          </Box>
          <BorderLinearProgress variant="determinate" value={percentage} />
        </Box>
      ) : (
        <></>
      )}
      {isUnlockPdpMessage && (
        <Box sx={styles.messageContainerPdp}>
          <>
            <Typography sx={styles.messageTextPdp}>
              {leftAmount > 0
                ? i18next.t(TK.FREE_DELIVERY_REMAINING, {
                    amount: leftAmount.toFixed(2),
                  })
                : i18next.t(TK.FREE_DELIVERY_COMPLETED)}
            </Typography>
            <Box display="flex">
              <Image src={freeMessageLogo} width={24} height={24} />
            </Box>
          </>
        </Box>
      )}
    </>
  );
};
