export const FOOTER_APP_STORES_GOOGLE_PLAY_ALT =
  'footer.app_stores.google_play_alt';
export const FOOTER_APP_STORES_APP_STORE_ALT =
  'footer.app_stores.app_store_alt';
export const FOOTER_APP_STORES_GOOGLE_PLAY_ARIA_LABEL =
  'footer.app_stores.google_play_aria_label';
export const FOOTER_APP_STORES_APP_STORE_ARIA_LABEL =
  'footer.app_stores.app_store_aria_label';
export const FOOTER_INSTAGRAM_ARIA_LABEL = 'footer.instagram.aria_label';
export const FOOTER_FACEBOOK_ARIA_LABEL = 'footer.facebook.aria_label';
export const FOOTER_TWITTER_ARIA_LABEL = 'footer.twitter.aria_label';
export const FOOTER_PINTEREST_ARIA_LABEL = 'footer.pinterest.aria_label';
export const FOOTER_YOUTUBE_ARIA_LABEL = 'footer.youtube.aria_label';
