import * as TK from 'components/Error/translations/constants';
import * as styles from 'components/Error/OrderConfirmationError/styles';
import { Box, Button, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { Shaky } from 'components/Error/OrderConfirmationError/styles';
import { appInsights } from 'utils/telemetry/app-insights';
import { sadStrawberry } from 'utils/cloudinaryAssets';
import React, { useEffect } from 'react';
import i18next from 'i18next';

export function CartError(props: FallbackProps): JSX.Element {
  useEffect(() => {
    appInsights.trackEvent({ name: 'Cart Page Error' }, props.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Shaky>
        <img
          src={sadStrawberry}
          width={200}
          height={200}
          alt={i18next.t(TK.ERROR_STRAWBERRY_MASCOT)}
        />
      </Shaky>
      <Typography variant="h2">{i18next.t(TK.CART_ERROR_TITLE)}</Typography>
      <Typography variant="body2">
        {i18next.t(TK.CART_ERROR_SUMMARY)}
      </Typography>
      <Box display="flex" sx={{ gap: '30px' }}>
        <Button variant="contained" onClick={props.resetErrorBoundary}>
          {i18next.t(TK.CART_ERROR_RELOAD)}
        </Button>
      </Box>
    </Box>
  );
}
