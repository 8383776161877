import { arrangementGroupsApiService } from 'api/arrangement-groups.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPreviousGroupName = createAsyncThunk(
  'pdp/fetch-prev-group-name',
  async (id: number) => {
    const {
      data: { mls },
    } = await arrangementGroupsApiService.findOne(id);
    return mls[0]?.name || '';
  },
);
