import { isNotNull } from 'utils/null.utils';
import { useQueryParameter } from 'hooks/use-query-parameter';

export function useIsBvEnabled(): boolean {
  const param = useQueryParameter('_bv_enabled');

  if (isNotNull(param)) {
    return param === 'true';
  }

  return window.BAZAARVOICE_ENABLED;
}

export function useIsC8Enabled(): boolean {
  const param = useQueryParameter('_c8_enabled');

  if (isNotNull(param)) {
    return param === 'true';
  }

  return window.CURALATE_ENABLED;
}
