import { SxProps } from '@mui/system';
import { theme } from 'utils/theme/theme';

export const wrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: [2, 0],
  width: '100%',
};

export const divider: SxProps = {
  width: 4,
  height: 4,
  background: theme.palette.text.disabled,
  mx: 1,
  borderRadius: '50%',
};
