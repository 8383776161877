import { ApiBaseService } from 'api/apibase.service';
import { Area } from 'api/area/types/area.interface';
import { AxiosPromise, CancelToken } from 'axios';
import { MarketplaceBrandsEnum } from 'enums/marketplace-brands.enum';
import { OrderType } from 'types/cart.types';
import { RequestOptions } from 'redux/types/request-options.interface';
import { Store } from 'redux/types/store.types';
import { formatSQLDate } from 'utils/get-sql-date-format';

export type ShipmentStore = {
  storeId: number;
  storeNumber: number;
};

type GetStoreByTypeParams = {
  date: Date;
  arrangementId: number;
  productId: number;
  areaName: string;
  options?: RequestOptions;
  test?: boolean;
};

interface GetMarketplaceStoreParams extends GetStoreByTypeParams {
  marketplaceBrandName?: MarketplaceBrandsEnum;
}

export interface MarketplaceStoreResponse {
  area: Area;
  store: Store;
}

class AvailabilityStoreApiService extends ApiBaseService {
  getAvailableStoreForShipment({
    date,
    arrangementId,
    productId,
    areaName,
    options,
  }: GetStoreByTypeParams): AxiosPromise<ShipmentStore> {
    return this.apibase.post(
      'availability/stores/shipment',
      {
        date: formatSQLDate(date),
        arrangementId,
        productId,
        areaName,
      },
      { cancelToken: options?.cancelToken },
    );
  }

  getAvailableStoresForDelivery({
    date,
    arrangementId,
    productId,
    areaName,
    options,
    test = false,
  }: GetStoreByTypeParams): Promise<Store[]> {
    return this.apibase
      .post<Store[]>(
        'availability/stores/delivery',
        {
          date: formatSQLDate(date),
          arrangementId,
          productId,
          areaName,
          test,
        },
        { cancelToken: options?.cancelToken },
      )
      .then(({ data }) => data);
  }

  getAvailableStoresForPickup({
    date,
    arrangementId,
    productId,
    areaId,
    cancelToken,
    test = false,
  }: {
    date: Date;
    arrangementId: number;
    productId: number;
    areaId: number;
    cancelToken?: CancelToken;
    test?: boolean;
  }): Promise<Store[]> {
    return this.apibase
      .get<Store[]>('availability/stores/pickup', {
        params: {
          date: formatSQLDate(date),
          arrangementId,
          productId,
          areaId,
          test: test ? 1 : 0,
        },
        cancelToken,
      })
      .then(({ data }) => data);
  }

  checkAvailability({
    storeId,
    areaId,
    productId,
    orderType,
    fulfillmentDate,
  }: {
    storeId: number;
    areaId: number;
    productId: number;
    orderType: OrderType;
    fulfillmentDate: Date;
  }): Promise<void> {
    return this.apibase
      .get<void>(`availability/stores/${storeId}`, {
        params: {
          areaId,
          productId,
          orderType,
          date: formatSQLDate(fulfillmentDate),
        },
      })
      .then(({ data }) => data);
  }

  getMarketplaceStore({
    date,
    arrangementId,
    productId,
    areaName,
    options,
    test = false,
    marketplaceBrandName,
  }: GetMarketplaceStoreParams): Promise<MarketplaceStoreResponse> {
    return this.apibase
      .post<MarketplaceStoreResponse>(
        'availability/stores/marketplace',
        {
          date: formatSQLDate(date),
          arrangementId,
          productId,
          areaName,
          test,
          marketplaceBrandName,
        },
        { cancelToken: options?.cancelToken },
      )
      .then(({ data }) => data);
  }
}

export const availabilityStoreService = new AvailabilityStoreApiService();
