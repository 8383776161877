import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';

export const boxStyle: SxProps = {
  display: 'flex',
  width: 1,
  justifyContent: 'space-between',
  '& .lineStyle': {
    width: ['100%', '100%', '92%'],
    marginBottom: '35px',
    marginTop: '20px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
};

export const giftCard: SxProps = {
  width: { md: '79%', sm: '82%', xs: '73%' },
};

export const orderSummaryWrapper: SxProps = {
  display: ['flex', 'flex', 'none'],
  flexDirection: [['column-reverse', 'row-reverse', 'column']],
};
export const messageWrapper: SxProps = {
  width: {
    xs: '100%',
    sm: '688PX',
    md: '610px',
  },
  padding: '16px 20px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #407FCB',
};
export const messageText: SxProps = {
  display: 'flex',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '0em',
  width: '96%',
  marginBottom: '9px',
};

export const messageContainerPdp: SxProps = {
  width: '100%',
  display: 'flex',
  paddingTop: '10px',
  color: colors.freeDelivery,
};

export const messageTextPdp: SxProps = {
  display: 'flex',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '150%',
  letterSpacing: '0em',
  paddingRight: '10px',
};

export const giftCardTitle: SxProps = {
  fontWeight: '600',
  fontSize: { md: '14px', sm: '14px', xs: '12px' },
  lineHeight: '143%',
};

export const giftCardMsg: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  color: colors.disabledBlack,
  marginBottom: '5px',
};

export const greetingStyle: SxProps = {
  '& .giftCardPrice': {
    marginTop: { md: '-55px', sm: '-55px', xs: '15px' },
  },
};

export const giftCardPrice: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  textAlign: 'right',
  marginTop: '-25px',
};

export const disclaimerText: SxProps = {
  letterSpacing: '0',
  textAlign: 'left',
  fontsize: '10px',
  width: { md: '75%', sm: '75%', xs: '91%' },
};

export const productSeparator: SxProps = {
  width: ['100%', '100%', '100%'],
  height: 0,
  border: `1px solid ${colors.dividerBlack}`,
  mt: 2,
  mb: 2,
};

export const rewardContainer: SxProps = {
  display: ['block', 'flex', ''],
};

export const printableEdit: SxProps<Theme> = (theme) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
  marginTop: '15px',
  cursor: 'pointer',
});

export const complimentaryWrapper: SxProps = {
  mt: 4,
  width: ['100%', '100%', '610px'],
};

export const MultiRecipientContainer: SxProps = {
  display: 'flex',
  width: { md: '610px', sm: '688px', xs: '320px' },
  height: '56px',
  padding: '0 0 24px 0',
  gap: '4px',
  marginTop: '24px',
  borderBottom: `solid 1px var(--other-outline-border, ${colors.borderBlack})`,
  marginBottom: '24px',
};

export const multiRecipientCount: SxProps = {
  textAlign: 'left',
  width: '40%',
  fontWeight: '600',
  fontSize: '14px',
  margin: '6px 0',
  float: 'left',
};

export const itemCountTxt: SxProps = {
  textAlign: 'right',
  width: '50%',
  fontWeight: '600',
  fontSize: '14px',
  margin: '6px 0',
  float: 'right',
  mb: 2,
};

export const viewEditBtn: SxProps = {
  width: ['133px'],
  height: ['34px'],
  marginLeft: ['20px'],
  padding: '10px',
};

export const recipientTotalWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  mb: [10, 10, 10],
  width: ['100%', '100%', '92%'],
};

export const detailsParentWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: ['column', 'row', 'row'],
  width: 1,
};

export const detailsWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: ['column', 'column', 'column'],
  width: 1,
};

export const noSpace: ThemedSxProps = {
  padding: 0,
  margin: 0,
};

export const accordianText: ThemedSxProps = {
  padding: 0,
  margin: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'relative',
    right: '56px',
    bottom: '127px',
  },
  '& svg': {
    color: colors.white,
  },
};

export const wrapper: SxProps = {
  width: ['100%', '100%', '610px'],
  height: ['auto'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  paddingBottom: 0,
  '& p': {
    fontSize: '16px',
  },
};

export const method: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: ['100%', '50%', '50%'],
  gap: '8px',
  '& p': {
    marginBottom: '8px',
  },
};

export const imageStyle: SxProps = {
  marginTop: '9px',
};

export const addressText: SxProps = {
  wordBreak: 'break-word',
};
