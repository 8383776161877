import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Header/MobileMenus/styles';
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { Item } from 'redux/header/header.types';
import { Language } from 'redux/locale/locale.types';
import { ListItemContent } from 'components/Header/MobileMenus/ListItemContent';
import { MainMenuSubtitle } from 'components/Header/MobileMenus/MainMenuSubtitle';
import { Menu, NavigationTypes } from 'components/Header/MobileMenus/types';
import { RootState } from 'redux/store';
import { changeLocale } from 'redux/locale/locale.actions';
import { getForMobile } from 'redux/header/header.selectors';
import { getNavigationUrl } from 'components/Header/utils';
import { isCanada } from 'utils/country';
import { isEmpty } from 'utils/array/size';
import { isUrlToAnotherResource } from 'utils/router';
import { languages } from 'redux/locale/locale.slice';
import { menus } from 'components/Header/MobileMenus/menu-links';
import { range } from 'utils/range';
import {
  selectIsLocaleInit,
  selectLocale,
} from 'redux/locale/locale.selectors';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  isVisible: boolean;
  handleSubMenu: (name: string) => void;
  toggleMenuVisibility: () => void;
}

export const MainMenu: FC<Props> = ({
  isVisible,
  handleSubMenu,
  toggleMenuVisibility,
}) => {
  const dispatch = useDispatch();
  const currentLocale = useSelector(selectLocale);
  const menu = useSelector<RootState, Item[]>(getForMobile);
  const activeLanguage: Language = languages[currentLocale];
  const isLocaleInit = useSelector(selectIsLocaleInit);

  function setLocaleCookie(locale: string) {
    Cookies.set('locale', locale);
  }

  const handleClickLanguage = (language: string) => {
    dispatch(changeLocale({ locale: language }));
    setLocaleCookie(language);
  };

  return (
    <Box sx={styles.menuList(isVisible)}>
      <ListItem sx={styles.listItem}>
        <Typography variant="h6" color="secondary">
          {i18next.t(TK.HEADER_NAV_MENU)}
        </Typography>
        <IconButton
          color="secondary"
          component="button"
          onClick={toggleMenuVisibility}
        >
          <CloseIcon />
        </IconButton>
      </ListItem>
      {menu.map((menuItem) => (
        <ListItemButton
          key={menuItem.id}
          component="a"
          sx={styles.listItem}
          onClick={() =>
            !isEmpty(menuItem.categories) && handleSubMenu(menuItem.name)
          }
          target={isUrlToAnotherResource(menuItem.url) ? '_blank' : undefined}
          href={
            isEmpty(menuItem.categories)
              ? getNavigationUrl(menuItem.url)
              : undefined
          }
        >
          <ListItemContent
            arrow={!isEmpty(menuItem.categories)}
            text={i18next.t(menuItem.name)}
            color={menuItem.color}
          />
        </ListItemButton>
      ))}
      <MainMenuSubtitle>{i18next.t(TK.HEADER_NAV_MORE_LINKS)}</MainMenuSubtitle>
      {menus.map((nav: Menu) => (
        <ListItemButton
          key={nav.name}
          sx={styles.listItem}
          onClick={() =>
            nav.type !== NavigationTypes.LINK && handleSubMenu(nav.name)
          }
          component="a"
          href={
            nav.type === NavigationTypes.LINK && nav.url
              ? getNavigationUrl(nav.url)
              : undefined
          }
        >
          <ListItemContent
            arrow={nav.type === NavigationTypes.MENUS}
            text={i18next.t(nav.name)}
          />
        </ListItemButton>
      ))}
      {isCanada && (
        <>
          <MainMenuSubtitle>
            {i18next.t(TK.HEADER_NAV_LANGUAGE)}
          </MainMenuSubtitle>
          {isLocaleInit
            ? Object.keys(languages).map((language) => {
                const { icon, definition } = languages[language];
                return (
                  <ListItemButton
                    key={definition}
                    onClick={() => handleClickLanguage(language)}
                    sx={styles.languageItem}
                  >
                    <img src={icon} alt={definition} />
                    <Typography variant="subtitle2" sx={styles.language}>
                      {definition}
                    </Typography>
                    {activeLanguage.definition === definition && (
                      <CheckIcon color="primary" />
                    )}
                  </ListItemButton>
                );
              })
            : range(0, 2).map((i) => (
                <Box key={i} sx={styles.languageSkeleton}>
                  <Skeleton width="100%" height={40} />
                </Box>
              ))}
        </>
      )}
    </Box>
  );
};
