import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';

export const pdpOrderSuggestedAddressCommonLocale = {
  [TK.SA_INVALID_TITLE]: 'Verify Your Delivery Address',
  [TK.SA_INVALID_CONTENT]:
    'Sorry, we don’t recognize the delivery address you entered. You can select the address below or go back and edit your entry.',
  [TK.SA_INVALID_CONFIRM]: 'Confirm Suggested Address',
  [TK.SA_INVALID_REJECT]: 'Modify Original Address',
  [TK.SA_UNKNOWN_CONFIRM]: 'Update Original Address',
  [TK.SA_UNKNOWN_CONTENT]: `Sorry, we don't recognize the delivery address you entered. Double check it for us below to ensure there are no delays in your delivery!`,
  [TK.SA_KEEP_ADDRESS]: 'Keep Original Address',
  [TK.SA_ORIGINAL_ADDRESS_DISCLAIMER]:
    'Keeping the original address may cause delay in delivery, if the address is found invalid.',
  [TK.SA_UNKNOWN_TITLE]: 'Verify Your Delivery Address',
  [TK.SA_SHIPMENT_REJECT]: 'Change Delivery Date',
  [TK.SA_SHIPMENT_CONFIRM]: 'Update Original Address',
  [TK.SA_ORIGINAL_ADDRESS]: 'Original Address',
  [TK.SA_SUGGESTED_ADDRESS]: 'Suggested Address',
  [TK.SA_SHIPMENT_TITLE]: 'Saturday Shipment Delivery Unavailable',
  [TK.SA_SHIPMENT_CONTENT]:
    'Saturday shipment delivery is not available to non-residential addresses. Please provide a residential address or select another delivery date.',
};
