import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';
import { AddressValidationDecision } from 'service/adobe-analytics/enums';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dialog } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styled';
import { OriginalAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/OriginalAddress';
import { Props } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/props';
import {
  actions,
  saturdayDialogWrapper,
  shipmentConfirmBtn,
  shipmentRejectBtn,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styles';
import { analytics } from 'service/adobe-analytics/analytics';
import { defaultProps } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/default.prop';
import React, { FC } from 'react';
import i18next from 'i18next';

export const SaturdayShipmentDialogPart: FC<Props> = ({
  open = false,
  onConfirm,
  onReject,
}) => {
  const handleConfirmAddress = () => {
    if (onConfirm) onConfirm();
    analytics.addressValidationSelection(
      AddressValidationDecision.ModifyOriginalAddress,
    );
  };

  const handleRejectAddress = () => {
    onReject();
    analytics.addressValidationSelection(
      AddressValidationDecision.UpdateDeliveryDate,
    );
  };

  return (
    <Dialog
      sx={saturdayDialogWrapper}
      onClose={handleRejectAddress}
      open={open}
    >
      <DialogTitle>{i18next.t(TK.SA_SHIPMENT_TITLE)}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={3}>
          {i18next.t(TK.SA_SHIPMENT_CONTENT)}
        </Typography>
        <Box width={1} display="flex" justifyContent="center">
          <OriginalAddress />
        </Box>
      </DialogContent>
      <DialogActions sx={actions}>
        <Button
          onClick={handleConfirmAddress}
          sx={shipmentConfirmBtn}
          variant="contained"
          size="large"
          color="primary"
        >
          {i18next.t(TK.SA_SHIPMENT_CONFIRM)}
        </Button>
        <Button
          onClick={handleRejectAddress}
          sx={shipmentRejectBtn}
          variant="outlined"
          size="large"
          color="primary"
        >
          {i18next.t(TK.SA_SHIPMENT_REJECT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
SaturdayShipmentDialogPart.defaultProps = defaultProps;
