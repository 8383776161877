import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import {
  FilterCategory,
  FruitFilter,
  Types,
} from 'api/filter-categories/types';

class FilterCategoriesApiService extends ApiBaseService {
  getFilterCategories(type?: Types): AxiosPromise<FilterCategory[]> {
    return this.apibase.get<FilterCategory[]>('/filter-category', {
      params: { type },
    });
  }

  getFruitFilters(): AxiosPromise<FruitFilter[]> {
    return this.apibase.get<FruitFilter[]>('/filter-category/fruits');
  }
}

const filterCategoriesApiService = new FilterCategoriesApiService();

export { filterCategoriesApiService };
