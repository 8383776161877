import * as styles from 'components/Home/CarouselBanners/components/CarouselImage/styles';
import { Box, Button, SxProps, Theme } from '@mui/material';
import {
  Image,
  getAlignmentStyles,
  getMobileAlignmentStyles,
  tabMobileButtonContainer,
} from 'components/Home/CarouselBanners/components/CarouselImage/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { TextAndButtonAlignment } from 'components/Home/CarouselBanners/components/CarouselImage/banner-text-type';
import { colors } from 'utils/theme/colors';
import { useIsDownMd } from 'utils/hidden/use-is-down-md';
import React, { FC } from 'react';

interface CarouselImageProps {
  src: string;
  alt: string;
  onError?: () => void;
  headerText?: string;
  contentText?: string;
  smallText?: string;
  buttonText?: string;
  buttonTextColor?: string;
  textColor?: string;
  textAlignment?: TextAndButtonAlignment;
  buttonAlignment?: TextAndButtonAlignment;
}

export const CarouselImage: FC<CarouselImageProps> = ({
  src,
  alt,
  onError,
  headerText,
  contentText,
  smallText,
  buttonText,
  buttonTextColor,
  textColor,
  textAlignment,
  buttonAlignment,
}) => {
  const isDownMd = useIsDownMd();
  const TextColor = textColor === 'white' ? colors.white : colors.primaryRed;
  const ButtonTextColor =
    buttonTextColor === 'white' ? colors.white : colors.primaryRed;
  const ButtonBackGroundColor =
    buttonTextColor === 'white' ? colors.primaryRed : colors.white;
  const ButtonVariant = buttonTextColor === 'white' ? 'contained' : 'outlined';
  const {
    textContainer,
    midText: midTextCss,
    smallText: smallTextCss,
    headerText: headerTextCss,
  } = styles;

  const headerTextStyle = isDownMd
    ? getMobileAlignmentStyles(
        textAlignment || TextAndButtonAlignment.MiddleLeft,
      )
    : getAlignmentStyles(textAlignment || TextAndButtonAlignment.MiddleLeft);

  const buttonPositionStyle = isDownMd
    ? getMobileAlignmentStyles(
        buttonAlignment || TextAndButtonAlignment.MiddleLeft,
      )
    : getAlignmentStyles(buttonAlignment || TextAndButtonAlignment.MiddleLeft);

  const htmlTextContainer = () => (
    <>
      <Box sx={{ ...textContainer, ...headerTextStyle } as SxProps<Theme>}>
        {headerText && (
          <PureHtmlTypography
            variant="h4"
            sx={{
              ...headerTextCss,
              color: `${TextColor}`,
            }}
          >
            {headerText}
          </PureHtmlTypography>
        )}
        {contentText && (
          <PureHtmlTypography
            variant="subtitle1"
            sx={{
              ...midTextCss,
              color: `${TextColor}`,
            }}
          >
            {contentText}
          </PureHtmlTypography>
        )}
        {smallText && (
          <PureHtmlTypography
            variant="subtitle1"
            sx={{
              ...smallTextCss,
              color: `${TextColor}`,
            }}
          >
            {smallText}
          </PureHtmlTypography>
        )}
      </Box>
    </>
  );

  const htmlButtonContainer = () => (
    <>
      {buttonText && (
        <Box
          sx={
            {
              ...styles.buttonContainer,
              ...buttonPositionStyle,
            } as SxProps<Theme>
          }
        >
          <Button
            data-test="carousel-image-btn"
            variant={ButtonVariant}
            color="primary"
            sx={{
              ...styles.buttonStyle,
              backgroundColor: `${ButtonBackGroundColor}`,
              '&:hover': {
                backgroundColor: `${ButtonBackGroundColor}`,
                borderWidth: '4px',
              },
            }}
          >
            <PureHtmlTypography
              sx={{
                ...styles.buttonText,
                color: `${ButtonTextColor}`,
              }}
            >
              {buttonText}
            </PureHtmlTypography>
          </Button>
        </Box>
      )}
    </>
  );

  if (headerText) {
    const { mainContainer } = styles;
    const { mainFrameMobile } = styles;
    const { tabMobileTextContainer } = styles;
    const { mainFrameDesktop } = styles;
    const { backGroundImageWrapper } = styles;

    return (
      <>
        <Box
          sx={{
            ...backGroundImageWrapper,
            backgroundImage: `url(${src})`,
          }}
        >
          {isDownMd ? (
            <Box sx={mainContainer}>
              <Box sx={mainFrameMobile}>
                <Box sx={tabMobileTextContainer}>{htmlTextContainer()}</Box>
                <Box sx={tabMobileButtonContainer}>{htmlButtonContainer()}</Box>
                <Box sx={styles.spacer} />
              </Box>
            </Box>
          ) : (
            <Box sx={mainContainer}>
              <Box sx={mainFrameDesktop}>{htmlTextContainer()}</Box>
              <Box sx={mainFrameDesktop}>{htmlButtonContainer()}</Box>
              <Box sx={styles.spacer} />
            </Box>
          )}
        </Box>
      </>
    );
  }
  return (
    <Image
      src={src}
      onError={onError}
      alt={alt}
      data-test="carousel-banners-item-image"
      loading="eager"
    />
  );
};
