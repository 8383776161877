import * as TK from 'translations/locales/translation-keys.constant';
import {
  checkIsBlocked,
  selectReviewsInfo,
} from 'components/PDP/Details/components/Reviews/feature/selectors';
import { useIsBvEnabled } from 'lib/bazaarvoice/hooks/use-is-bv-and-c8-enabled';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

export const useAccordionReviewsTitle = (): string => {
  const loading = useSelector(checkIsBlocked);
  const { count } = useSelector(selectReviewsInfo);
  const isBvEnabled = useIsBvEnabled();

  if (loading || isBvEnabled) {
    return i18next.t(TK.PRODUCT_REVIEWS);
  }

  if (count) {
    return i18next.t(TK.PRODUCT_REVIEW, { count });
  }

  return i18next.t(TK.PRODUCT_NO_REVIEW);
};
