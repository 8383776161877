import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { storesApiService } from 'api/stores.api.service';

export type GetStoreScheduleParams = {
  storeId: number;
  date: Date;
};

export const getStoresSchedule = createAsyncThunk(
  'stores/schedule/fetch',
  async ({ storeId, date }: GetStoreScheduleParams, { rejectWithValue }) => {
    try {
      const { data } = await storesApiService.getStoreSchedule(storeId, date);
      return { storeId, ...data };
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
