import { CurrencyCode } from 'enums/currency-code';
import { Locale } from 'enums/locale';

export class CurrencyProvider {
  static getCurrency(culture: string = Locale.EN_US): string {
    switch (culture) {
      case Locale.FR_CA:
        return CurrencyCode.CAD;
      default:
        return CurrencyCode.USD;
    }
  }
}
