import * as styles from 'components/SkinnyBannerV2/components/CarouselItem/style';
import { Box } from '@mui/material';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import React, { FC } from 'react';

interface SkinnyBannerProps {
  editorContent: string;
}

export const SkinnyMessage: FC<SkinnyBannerProps> = ({ editorContent }) => {
  return (
    <Box sx={styles.wrapper}>
      <PureHtmlTypography
        sx={styles.skinnyBannerText}
        data-test="Skinny-banner-widget-body"
      >
        {editorContent}
      </PureHtmlTypography>
    </Box>
  );
};
