import { createAsyncThunk } from '@reduxjs/toolkit';
import { emailLogLegacyApiService } from 'api/email-log.legacy.api.service';

export const logEmail = createAsyncThunk(
  'maintenance/log-email',
  async (email: string, { rejectWithValue }) => {
    try {
      await emailLogLegacyApiService.log(email);
      return true;
    } catch (e) {
      return rejectWithValue(false);
    }
  },
);
