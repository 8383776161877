export type UpdateAccountRequest = {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  addressType?: number;
  address1?: string;
  address2?: string;
  areaID?: number;
  areaOther?: string;
  areaExtended?: string;
  areaMLID?: number;
  cityID?: number;
  cityMLID?: number;
  cityOther?: string;
  stateID?: number;
  stateOther?: string;
  countryID?: number;
  workPhone?: string;
  homePhone: string;
  email: string;
  type?: number;
  status?: number;
  note?: string;
  deleted?: boolean;
  birthday?: string;
  updatedOnDate?: Date;
  gender?: number;
  memberType?: number;
  source?: number;
  rewardTierID?: number;
  company?: string;
};
export type ChangePasswordRequest = {
  id: number;
  password: string;
  oldPassword: string;
};
export enum ChangePasswordStatusEnum {
  SUCCESS = 'success',
  INCORRECT_OLD_PASSWORD = 'incorrect_old_password',
  PASSWORD_HISTORY_VALIDATION_FAILURE = 'password_history_validation_failure',
  FAILURE = 'failure',
}
