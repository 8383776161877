import { ProductOptionAvailability } from 'api/availability/types/product-option-availability.interface';
import {
  selectIsLoading,
  selectProductOptionsAvailability,
  selectShouldCheckAvailability,
} from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import { selectProductsIds } from 'redux/PDP/arrangement/arrangement.selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type OptionsAvailability = Record<number, boolean>;

function mapIdsToFullOptionsAvailability(ids: number[]): OptionsAvailability {
  return ids.reduce(
    (availability, id) => ({ ...availability, [id]: true }),
    {},
  );
}

function mapAvailabilitiesToOptionAvailability(
  availabilities: ProductOptionAvailability[],
): OptionsAvailability {
  return availabilities.reduce(
    (availability, { productId: optionId, isAvailable }) => ({
      ...availability,
      [optionId]: isAvailable,
    }),
    {},
  );
}

export function useProductOptionsAvailability(): OptionsAvailability {
  const optionsIds = useSelector(selectProductsIds);
  const availability = useSelector(selectProductOptionsAvailability);
  const shouldCheckAvailability = useSelector(selectShouldCheckAvailability);
  const isLoading = useSelector(selectIsLoading);

  return useMemo(
    () =>
      isLoading || !shouldCheckAvailability
        ? mapIdsToFullOptionsAvailability(optionsIds)
        : mapAvailabilitiesToOptionAvailability(availability),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, shouldCheckAvailability, optionsIds.join(',')],
  );
}
