import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderApiService } from 'api/order/order-api.service';
import { parseAxiosError } from 'utils/errors';

export const trackOrder = createAsyncThunk(
  'customer-service/track-order',
  async (orderNumber: string, { rejectWithValue }) => {
    try {
      const { data } = await orderApiService.trackOrder(orderNumber);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);

export const trackEnterpriseOrder = createAsyncThunk(
  'customer-service/track-enterprise-order',
  async (orderNumber: string, { rejectWithValue }) => {
    try {
      const { data } = await orderApiService.trackOrder(orderNumber);
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);
