import { Alert } from '@mui/material';
import { Store } from 'redux/types/store.types';
import { getTimingMessage } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/utils';
import { isBulkHoursAvailable } from 'utils/is-bulk-hours-available';
import React, { FC } from 'react';

type Props = {
  store: Store;
  date: Date | null;
};

export const TimingMessage: FC<Props> = ({
  store: { managedType, openingTime, closingTime },
  date,
}) => {
  if (
    !managedType ||
    !openingTime ||
    !closingTime ||
    !isBulkHoursAvailable(managedType) ||
    !date
  ) {
    return null;
  }

  return (
    <Alert variant="standard" severity="error" sx={{ mt: 1.5 }}>
      {getTimingMessage({
        openingTime,
        closingTime,
        date,
      })}
    </Alert>
  );
};
