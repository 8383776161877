import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { RecipientAvailability } from 'api/availability/recipient-availability.api.service';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { retrieveRecipientAvailability } from 'components/Session/Alert/feature/actions';

export type RecipientAvailabilityState = {
  status: Status;
  data: RecipientAvailability[];
  error: ResponseError | null;
};
const initialState: RecipientAvailabilityState = {
  data: [],
  status: Status.INIT,
  error: null,
};

export const slice = createSlice({
  name: 'recipient/availability',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(retrieveRecipientAvailability.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(
        retrieveRecipientAvailability.fulfilled,
        (state, { payload }: PayloadAction<RecipientAvailability[]>) => {
          state.data = [...state.data, ...payload];
          state.status = Status.FULFILLED;
        },
      )
      .addCase(retrieveRecipientAvailability.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
  },
});

export const recipientAvailabilityReducer = slice.reducer;
export const { reset } = slice.actions;
