import { colors } from 'utils/theme/colors';

export const COLORS = {
  NEUTRAL: colors.white,
  POOR: colors.lightRed,
  FAIR: colors.warningColor,
  STRONG: colors.successLightColor,
};

export enum PasswordStrength {
  NEUTRAL = 'Neutral',
  POOR = 'Poor',
  FAIR = 'Fair',
  STRONG = 'Strong',
}

export const getPasswordStrength = (password: string): string => {
  if (
    password &&
    new RegExp(/(?=.{8,})/).test(password) &&
    new RegExp(/(?=.*?[#!@$ %^&*])/).test(password)
  )
    return PasswordStrength.STRONG;
  if (password && new RegExp(/(?=.{8,})/).test(password))
    return PasswordStrength.FAIR;
  if (password) return PasswordStrength.POOR;
  return PasswordStrength.NEUTRAL;
};

export const getPasswordStrengthMeterColors = (strength: string): string[] => {
  let result;

  switch (strength) {
    case PasswordStrength.NEUTRAL:
      result = [COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL];
      break;
    case PasswordStrength.POOR:
      result = [COLORS.POOR, COLORS.NEUTRAL, COLORS.NEUTRAL];
      break;
    case PasswordStrength.FAIR:
      result = [COLORS.FAIR, COLORS.FAIR, COLORS.NEUTRAL];
      break;
    case PasswordStrength.STRONG:
      result = [COLORS.STRONG, COLORS.STRONG, COLORS.STRONG];
      break;
    default:
      result = [COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL];
  }
  return result;
};

export const getPasswordStrengthColor = (strength: string): string => {
  let color;
  switch (strength) {
    case PasswordStrength.POOR:
      color = colors.errorLightColor;
      break;
    case PasswordStrength.FAIR:
      color = colors.warningColor;
      break;
    case PasswordStrength.STRONG:
      color = colors.lightGreen;
      break;
    default:
      color = colors.errorLightColor;
  }
  return color;
};
