import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const emailBoxcon: ThemedSxProps = {
  padding: {
    md: '16px 0px 0px 0px !important',
    sm: '16px 0px 0px 0px !important',
    xs: '16px 0px 0px 0px !important',
  },
  display: { md: 'flex', sm: 'flex', xs: 'block' },
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '-65px',
};
export const textField: ThemedSxProps = {
  marginBottom: { md: '12px', sm: '12px', xs: '20px' },
  width: { md: '360px', sm: '360px', xs: '90%' },
  '& label': {
    fontSize: '14px',
    color: '#5C5C5C',
    lineHeight: { md: '16px', sm: '16px', xs: '19px' },
    paddingTop: '3px',
  },
  '& label.MuiInputLabel-shrink': {
    fontSize: '16px',
    paddingTop: '3px',
  },
  '& input': {
    borderRadius: '0px !important',
    padding: {
      md: '17px 24px !important',
      sm: '17px 24px !important',
      xs: '18px 24px !important',
    },
    fontWeight: '400',
  },
  '& p': {
    fontSize: '14px',
  },
};

export const button: ThemedSxProps = {
  width: { md: 'auto', sm: 'auto', xs: '90%' },
  padding: '15px 32px 15px 32px',
  height: { md: '54px', sm: '54px', xs: '44px' },
  fontSize: { md: '15px', sm: '15px', xs: '14px' },
  marginTop: { md: '12px', sm: '12px', xs: '12px' },
  borderRadius: '0px',
  zIndex: 1,
};
