export const SERVICED_AREAS_TITLE =
  'store_coming_soon_details.serviced_areas_title';
export const STORE_COMING_SOON_ADDRESS =
  'store_coming_soon_details.store_address';
export const COMING_SOON_TEXT =
  'store_coming_soon_details.store_coming_soon_text';
export const ABOUT_STORE_TITLE = 'store_coming_soon_details.about_store_title';
export const ABOUT_STORE_ONE = 'store_coming_soon_details.about_store_one';
export const ABOUT_STORE_TWO = 'store_coming_soon_details.about_store_two';
export const ABOUT_STORE_THREE = 'store_coming_soon_details.about_store_three';
export const ABOUT_STORE_FOUR = 'store_coming_soon_details.about_store_four';
export const FRANCHISE_BANNER_STORE_COMING_SOON_TITLE =
  'franchise_banner.title';
export const FRANCHISE_BANNER_STORE_COMING_SOON_SUB_TITLE =
  'franchise_banner.sub_title';
export const FRANCHISE_BANNER_STORE_COMING_SOON_BODY = 'franchise_banner.body';
export const FRANCHISE_BANNER_STORE_COMING_SOON_BUTTON =
  'franchise_banner.button';
export const FRANCHISE_BANNER_STORE_COMING_SOON_ALT_TEXT =
  'franchise_banner.alt_text';
export const STORE_BANNER_COMING_SOON_ALT_TEXT = 'store_banner.alt_text';
export const STORE_BANNER_COMING_SOON_TITLE = 'store_coming_soon_banner.title';
export const STORE_BANNER_COMING_SOON_BODY = 'store_coming_soon_banner.body';
export const STORE_BANNER_COMING_SOON_BUTTON = 'store_banner.button';
export const SOCIAL_MEDIA_TITLE = 'social_media.title';
export const SOCIAL_MEDIA_BODY = 'social_media.body';
export const SOCIAL_INSTAGRAM_ARIA_LABEL = 'social.instagram.aria_label';
export const SOCIAL_FACEBOOK_ARIA_LABEL = 'social.facebook.aria_label';
export const SOCIAL_TWITTER_ARIA_LABEL = 'social.twitter.aria_label';
export const SOCIAL_PINTEREST_ARIA_LABEL = 'social.pinterest.aria_label';
export const SOCIAL_YOUTUBE_ARIA_LABEL = 'social.youtube.aria_label';
export const CATEGORIES_TITLE = 'categories.title';
export const DISTANCE_AWAY = 'store_widget.icon.distance_away';
export const STORE_COMING_SOON_DETAILS_META_SCHEMA_DESCRIPTION =
  'store_coming_soon_details.meta.schema_description';
export const STORE_COMING_SOON_DETAILS_NO_ADDRESS =
  'store_coming_soon_details.no_address';
export const STORE_COMING_SOON_DETAILS_ADDRESS1 =
  'store_coming_soon_details.title_address1';
export const STORE_COMING_SOON_DETAILS_ADDRESS2 =
  'store_coming_soon_details.title_address2';
export const STORE_COMING_SOON_DETAILS_META_TITLE =
  'store_coming_soon_details.meta.title';
export const STORE_COMING_SOON_DETAILS_META_DESCRIPTION =
  'store_coming_soon_details.meta.description';
export const SUBSCRIPTION_TITLE = 'store_coming_soon_subscription.title';
export const SUBSCRIPTION_SUBTITLE = 'store_coming_soon_subscription.subtitle';
export const REDIRECT_SECTION_TITLE =
  'store_coming_soon_subscription.redirect_title';
export const REDIRECT_SECTION_SUBTITLE = 'store_coming_soon.redirect_subtitle';
export const FIND_STORE_BUTTON = 'store_coming_soon.redirect_find_store';
