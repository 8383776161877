import {
  NullableState,
  State,
  StateWithoutData,
  Status,
} from 'redux/types/state.interface';
import { ResponseError } from 'types/error.types';

export class InitialStateFactory {
  static createWithoutData = <
    Error = ResponseError,
  >(): StateWithoutData<Error> => ({
    status: Status.INIT,
    error: null,
  });

  static create = <T, Error = ResponseError>(data: T): State<T, Error> => ({
    ...InitialStateFactory.createWithoutData<Error>(),
    data,
  });

  static createNullable = <T, Error = ResponseError>(): NullableState<
    T,
    Error
  > => InitialStateFactory.create<T | null, Error>(null);
}
