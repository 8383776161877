export enum Links {
  FruitArrangements = '/all-fruit-arrangements',
  FruitCart = '/fruit-cart.aspx',
  NewFruitCart = '/fruit-cart',
  StoreLocator = '/store-finder',
  ContactUs = '/customer-service/contact-us',
  SignUp = '/account/sign-up',
  Payment = '/payment',
  Rewards = '/my-account',
  EdibleRewardsLogin = '/rewards',
  ShippingProducts = '/shipping-gifts',
  ShopMenuUnder50 = '/under-50',
  FreshFruit = 'https://www.freshfruit.com/',
}
