import * as TK from 'components/BusinessGifting/ContactDialog/translations/constants';

export const eventInfoDialogCommonLocale = {
  [TK.CONTENT]:
    'Want to start your order? Have questions? Our Gifting Concierge are happy to help.',
  [TK.TITLE]: 'Contact Your Gifting Concierge',
  [TK.EMAIL_LABEL]: 'Email',
  [TK.SUBMIT]: 'Submit',
  [TK.CANCEL]: 'Cancel',
  [TK.SUCCESS_CONTENT]: 'Your Gifting Concierge will be in touch soon',
  [TK.SUCCESS_TITLE]: ' thanks for reaching out!',
  [TK.EMAIL_VALID_ERROR]: 'Please enter a valid email address',
  [TK.EMAIL_REQUIRED_ERROR]: 'Enter your email',
  [TK.NAME_LABEL]: 'Name (Optional)',
  [TK.MESSAGE_LABEL]: 'Message (optional)',
  [TK.MESSAGE_PLACEHOLDER]: 'Enter any additional details or questions',
  [TK.ERROR_TRY_AGAIN]: 'Try again',
  [TK.ERROR_CONTENT]:
    'There seems to be an issue. Please try again or give us a call at ',
  [TK.ERROR_TITLE]: 'Sorry ):',
};
