import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { Box, RadioGroup } from '@mui/material';
import { DeliveryOption } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/DeliveryOption';
import {
  FulfillmentOptionType,
  OrderType,
  ValidOrderTypes,
} from 'types/cart.types';
import { Key } from 'enums/key';
import { appInsights } from 'utils/telemetry/app-insights';
import { getAvailabilityByType } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/utils/get-availability-by-type';
import { getAvailableMethods } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/utils/get-available-methods';
import { getOrderTypeKey } from 'components/PDP/Details/components/PasControl/utils/get-order-type-key';
import { selectAreStoresUnavailable } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useSelector } from 'react-redux';

import React, { FC, KeyboardEvent, MutableRefObject } from 'react';

const radioValues: Record<string, FulfillmentOptionType> = {
  [OrderType.Pickup.toString()]: OrderType.Pickup,
  [OrderType.Delivery.toString()]: OrderType.Delivery,
  [OrderType.Shipment.toString()]: OrderType.Shipment,
};

interface Props {
  orderType: ValidOrderTypes;
  setOrderType: (orderType: ValidOrderTypes) => void;
  availability: AvailabilityOnDate | null;
  hidden: boolean;
  fulfillmentRef?: MutableRefObject<HTMLInputElement | null>;
  collectionPromptRef?: MutableRefObject<HTMLInputElement | null>;
}

export const DeliveryMethods: FC<Props> = ({
  orderType,
  setOrderType,
  availability,
  hidden,
  fulfillmentRef,
  collectionPromptRef,
}) => {
  const areStoresUnavailable = useSelector(selectAreStoresUnavailable);
  const onChange = (_: React.ChangeEvent, value: string): void => {
    appInsights.trackEvent({
      name: 'PAS fulfillment method selected',
      properties: { method: getOrderTypeKey(radioValues[value]) },
    });
    setOrderType(radioValues[value]);
    setTimeout(() => {
      if (collectionPromptRef?.current)
        collectionPromptRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }, 500);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLLabelElement>, value: string) => {
    if (e.key === Key.Enter && value) {
      setOrderType(radioValues[value]);
    }
  };

  return (
    <Box data-test="pdp-pas-order-type-content" ref={fulfillmentRef}>
      {availability && !hidden && (
        <RadioGroup value={orderType} onChange={onChange}>
          {getAvailableMethods(availability).map((type) => {
            const typeAvailability = getAvailabilityByType(availability, type);

            return (
              <DeliveryOption
                key={type}
                type={type}
                availability={typeAvailability}
                onKeyDown={onKeyDown}
                disabled={
                  orderType === type &&
                  typeAvailability.available &&
                  areStoresUnavailable
                }
              />
            );
          })}
        </RadioGroup>
      )}
    </Box>
  );
};
