import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { styled } from '@mui/material';

export const CarouselWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  boxSizing: 'content-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  marginTop: '20px',
  [breakpoints.up('xs')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '50px',
  },
  [breakpoints.up('sm')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '30px',
  },
  [breakpoints.up('md')]: {
    padding: '0px',
    maxWidth: '100%',
    marginTop: '30px',
  },
  '& .lineStyle': {
    width: '130%',
    margin: '20px 0 50px -15%',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));

export const Headline = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
  },
}));

export const title: ThemedSxProps = {
  whiteSpace: 'wrap',
  fontWeight: '600',
  fontSize: '20px',
  flex: '1 1 auto',
  letterSpacing: '0.15px',
  lineHeight: '160%',
  pr: 1.2,
};

export const CarouselContainer = styled('div')({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 -10px',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
        },
      },
    },
  },
});

export const CarouselRewardsContainer = styled('div')(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 -10px',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
        },
      },
    },
    '& .slick-arrow': {
      border: `1px solid ${colors.dividerBlack}`,
      background: colors.white,
      color: colors.activeBlack,
      top: '90px',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        display: 'none !important',
      },
    },
    '& .slick-next': {
      right: '-35px',
    },
    '& .slick-prev': {
      left: '-35px',
    },
  },
}));

export const arrowControls: ThemedSxProps = (theme) => ({
  display: 'flex',
  position: 'relative',
  '& button:first-of-type': {
    mr: 1.2,
    [theme.breakpoints.up('sm')]: {
      ml: 2.5,
      mr: 2.5,
    },
  },
});
