import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const videoIcon: ThemedSxProps = (theme) => ({
  marginTop: -3.5,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.primary.contrastText,
});

export const mediaMask =
  (showHorizontalBorders: boolean): ThemedSxProps =>
  (theme) => ({
    border: '1px solid',
    borderColor: theme.palette.divider,
    width: [1, 410, 400],
    maxWidth: '100%',
    overflow: 'hidden',
    height: 0,
    paddingBottom: 'calc(100% - 2px)',
    '& div': {
      display: 'flex',
      width: '100%',
    },
    ...(showHorizontalBorders && {
      borderColor: ['transparent', 'transparent', theme.palette.divider],
    }),
  });
