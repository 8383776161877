import { RedirectUrl } from 'redux/PLP/redirect/types/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNotEmpty } from 'utils/array/size';
import { parseAxiosError } from 'utils/errors';
import { redirectApiService } from 'api/redirect-url.api.service';

export const getRedirectUrl = createAsyncThunk(
  'redirect/fetch',
  async (url: string, { rejectWithValue }) => {
    try {
      const { data }: { data: RedirectUrl[] } = await redirectApiService.find(
        url,
      );
      if (isNotEmpty(data)) {
        return data[0];
      }
      return rejectWithValue('not_found');
    } catch (error) {
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);
