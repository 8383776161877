import * as TK from 'components/Authentication/translations/locale.constants';
import { Slide } from '@mui/material';
import { selectIsLoginMode } from 'components/Authentication/LogIn/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const LogInTitle: FC = () => {
  const isLogin = useSelector(selectIsLoginMode);

  if (!isLogin) {
    return null;
  }

  return (
    <Slide in={isLogin} direction="left">
      <div>{i18next.t(TK.AUTH_DIALOG_LOG_IN_HEADER)}</div>
    </Slide>
  );
};
