import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { searchCatalogApiService } from 'api/search-catalog.api.service';

export const fetchSearchCatalog = createAsyncThunk(
  'searchCatalog/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await searchCatalogApiService.getSearchCatalog();
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const fetchProductsCatalog = createAsyncThunk(
  'productsCatalog/fetch',
  async ({ searchQuery }: { searchQuery: string }, { rejectWithValue }) => {
    try {
      const { data } = await searchCatalogApiService.getProductsCatalog(
        searchQuery,
      );
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
