import { AddonItem } from 'components/PDP/Details/components/OwnBox/OwnBoxAddonItem/styled';
import { Box, Grid, Typography } from '@mui/material';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { Image } from 'components/common/Image/Image';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import {
  getImageSx,
  limitedOnlySx,
} from 'components/PDP/Details/components/OwnBox/OwnBoxAddonItem/styles';
import { isDefined } from 'utils/is-defined';
import { isIE } from 'utils/isIE';
import { useInView } from 'react-intersection-observer';
import { useOnlyXs } from 'utils/hidden/use-only-xs';
import { webkitLineClamp } from 'webkit-line-clamp';
import React, { FC, useEffect, useRef, useState } from 'react';

type Props = {
  addon: OwnBoxAddon;
  onSelect(addon: OwnBoxAddon, event?: React.MouseEvent): void;
  onAnimationEnd(): void;
  selected?: boolean;
  disabled?: boolean;
  focused?: boolean;
  isContainerInView: boolean;
  limitedOnly?: boolean;
};

export const OwnBoxAddonItem: FC<Props> = ({
  addon,
  onSelect,
  onAnimationEnd,
  selected,
  disabled,
  focused,
  isContainerInView,
  limitedOnly,
}) => {
  const isMobile = useOnlyXs();
  const addonRef = useRef<HTMLButtonElement | null>(null);
  const addonNameRef = useRef<HTMLSpanElement | null>(null);
  const [isFocused, setIsFocused] = useState(focused);
  const { ref: addonWrapperRef, inView } = useInView({
    triggerOnce: false,
    threshold: 0.75,
    trackVisibility: true,
    delay: 1000,
  });

  useEffect(() => {
    if (focused) {
      setIsFocused(true);
    }
  }, [focused]);

  useEffect(() => {
    if (isMobile && focused) {
      addonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [isMobile, focused, addon.name, isContainerInView]);

  const handleAnimationEnd = () => {
    setIsFocused(false);
    onAnimationEnd();
  };

  /**
   *   @notice "webkitLineClamp" is used as polyfill
   *   for css "line-clamp" property for IE browser
   */

  if (isIE && isDefined(addonNameRef.current)) {
    webkitLineClamp(addonNameRef.current, 3);
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      ref={addonWrapperRef}
      data-test="pdp-details-own-box-item"
      mr={1.2}
      display="contents"
    >
      <AddonItem
        ref={addonRef}
        disableRipple
        tabIndex={0}
        focused={isFocused && inView ? 1 : undefined}
        selected={selected}
        aria-checked={selected}
        onClick={(event) => onSelect(addon, event)}
        onAnimationEnd={handleAnimationEnd}
      >
        <Image
          src={addon.image}
          alt={addon.imageAltText}
          sx={getImageSx({ disabled })}
        />

        <HideUpSm>
          <div style={{ display: 'contents' }}>
            {limitedOnly && (
              <Box sx={limitedOnlySx({ disabled })}>limited time only</Box>
            )}
            <Typography
              color={disabled ? 'textSecondary' : 'textPrimary'}
              variant="subtitle2"
              align="left"
              ref={addonNameRef}
            >
              {addon.name}
            </Typography>
          </div>
        </HideUpSm>
        <ShowUpSm>
          {limitedOnly && (
            <Box sx={limitedOnlySx({ disabled })}>limited time only</Box>
          )}
          <Typography
            color={disabled ? 'textSecondary' : 'textPrimary'}
            variant="body3"
            align="center"
            ref={addonNameRef}
          >
            {addon.name}
          </Typography>
        </ShowUpSm>
      </AddonItem>
    </Grid>
  );
};
