import {
  ApiLegacyBaseService,
  LEGACY_JSON_HEADERS,
} from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import { DResponse } from 'types/legacy/response.types';

class ConfirmationLegacyApiService extends ApiLegacyBaseService {
  resendConfirmation(MailTo: string, SaleID: number): AxiosPromise<DResponse> {
    return this.apibase.post(
      '/confirmation.aspx/SendEmail',
      JSON.stringify({ MailTo, SaleID }),
      { headers: LEGACY_JSON_HEADERS },
    );
  }

  sendSpecialProductsMail(SaleID: number): AxiosPromise<DResponse> {
    return this.apibase.post(
      '/confirmation.aspx/SendSpecialProductsMail',
      JSON.stringify({ SaleID }),
      { headers: LEGACY_JSON_HEADERS },
    );
  }
}

export const confirmationLegacyApiService = new ConfirmationLegacyApiService();
