const fileExtensionRegex =
  /\.(jpg|jpeg|png|gif|bmp|tiff|svg|mp4|avi|mov|wmv|flv)$/i;
const urlReplacement = 'https://rescloud.ediblearrangements.com/ea-assets';
const patternToCheckFor = '/resources/';

export const updateAssetUrls = (urlToCheck: string): string => {
  const patternIndex = urlToCheck.indexOf(patternToCheckFor);
  if (patternIndex === -1) {
    return urlToCheck; // Exit early if the pattern is not found
  }

  return (
    urlReplacement +
    urlToCheck.substring(patternIndex).replace(fileExtensionRegex, '.webp')
  );
};
