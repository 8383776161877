import { Platform } from 'service/adobe-analytics/enums';
import { Product } from 'components/PDP/Upsells/feature/types/product';
import { Step } from 'containers/PDPContainer/enums/step.enum';
import { StepType } from 'containers/PDPContainer/types/step.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { analytics } from 'service/adobe-analytics/analytics';
import { useEffect } from 'react';

export function useAAShipmentRecipientFormEvent(
  product: Product | null,
  selectedUpsellItems: UpsellItem[] | [],
  step: StepType,
  addonButtonPosition?: string,
): void {
  useEffect(() => {
    if (step === Step.FORM && product) {
      analytics.shipmentRecipientForm({
        product,
        upsellItems: selectedUpsellItems.filter(
          ({ checked, quantity: quant }) => checked && quant,
        ),
        platform: Platform.ReactNew,
        addonButtonPosition,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
}
