import * as styles from 'components/GiftFinder/styles';
import { Box } from '@mui/system';
import { FilterCategory } from 'api/filter-categories/types';
import { GiftFinderSelector } from 'components/GiftFinder/Form/gift-finder-selector';
import React, { FC } from 'react';

interface Props {
  label: string;
  category: FilterCategory[];
  subHeading: string;
  onClick?: () => void;
  isSelected?: boolean;
  imageSrc: string;
}

export const GiftFinderSelectorWrapper: FC<Props> = ({
  label,
  category,
  subHeading,
  onClick,
  isSelected,
  imageSrc,
}) => (
  <>
    {category.map((cat) => (
      <Box key={cat.ID} onClick={onClick} sx={styles.formControl}>
        <GiftFinderSelector
          label={label}
          subHeading={subHeading}
          isSelected={isSelected}
          imageSrc={imageSrc}
        />
      </Box>
    ))}
  </>
);
