import { AvailabilityOnDateState } from 'redux/PDP/availability/on-date/availability-on-date.types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getAvailabilityOnDate } from 'redux/PDP/availability/on-date/availability-on-date.actions';

const initialState: AvailabilityOnDateState = {
  data: {
    pickup: {
      available: false,
      dates: [],
      arrangementsAvailable: false,
      message: '',
    },
    shipment: {
      available: false,
      dates: [],
      arrangementsAvailable: false,
      message: '',
    },
    delivery: {
      available: false,
      dates: [],
      arrangementsAvailable: false,
      message: '',
      fee: 0,
      isFlex: false,
    },
    deliveryFee: 0,
  },
  status: Status.INIT,
  error: null,
};

const availabilityOnDateSlice = createSlice({
  name: 'availability-on-date',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailabilityOnDate.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(getAvailabilityOnDate.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.data = payload;
      })
      .addCase(getAvailabilityOnDate.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

export const { resetState } = availabilityOnDateSlice.actions;

export const {
  reducer: availabilityOnDateReducer,
  actions: availabilityOnDateActions,
} = availabilityOnDateSlice;
