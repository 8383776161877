import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupsApiService } from 'api/arrangement-group.api.service';

export const fetchArrangementGroup = createAsyncThunk(
  'arrangement-group/fetch',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await groupsApiService.findOne(id);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
