import { Area } from 'api/area/types/area.interface';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { FulfillmentOptionType } from 'types/cart.types';
import { getOrderTypeKey } from 'components/PDP/Details/components/PasControl/utils/get-order-type-key';
import { getSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.actions';
import { selectIsFulfilled } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

interface Props {
  availability: AvailabilityOnDate | null;
  orderType: FulfillmentOptionType;
  date: Date | null;
  area: Area | null;
}

const SUGGESTED_ARRANGEMENTS_COUNT = 4;

export const useFetchSuggestedArrangements = ({
  availability,
  orderType,
  date,
  area,
}: Props): void => {
  const dispatch = useDispatch();
  const orderTypeKey = getOrderTypeKey(orderType);
  const isSuggestedArrangementsFetched = useSelector(
    selectIsFulfilled(orderType),
  );

  useEffect(() => {
    const shouldFetchSuggestedArrangements =
      availability &&
      !isSuggestedArrangementsFetched &&
      availability[orderTypeKey].arrangementsAvailable &&
      !availability[orderTypeKey].available &&
      !availability[orderTypeKey].dates.length;

    if (shouldFetchSuggestedArrangements) {
      dispatch(
        getSuggestedArrangements({
          orderType,
          date: date ?? undefined,
          areaId: area?.id,
          limit: SUGGESTED_ARRANGEMENTS_COUNT,
        }),
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [availability, orderType]);
};
