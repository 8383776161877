// What about storing regex in one place? This one is from legacy site
export const priceRegex = /[^0-9.]/g;

export const regexEscape = (arg: string): string =>
  arg.replace(/[-/\\^$*+?.()=<>:;,|[\]{}]/g, '');

export const videoImg = (arg: string): string =>
  arg.replace('videos/', '').replace('mp4', 'jpg');

export const canadaZipRegex =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
