import {
  DESCRIPTION,
  META_KEYWORDS,
  TITLE,
} from 'components/Home/HeadMeta/translations/constants';

export const homeHeadMetaCommonLocale = {
  [META_KEYWORDS]:
    'Edible arrangements, fruit arrangements, fresh fruit arrangements, fruit bouquets, fruit baskets, fresh fruit, floral fruit, chocolate covered strawberries, dipped fruit, fruit delivery',
  [TITLE]: 'Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery',
  [DESCRIPTION]:
    'Our one-of-a-kind fresh fruit baskets, bouquets, arrangements & gourmet chocolate covered fruit gifts can be conveniently delivered nationwide. Shop today!',
};
