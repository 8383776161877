import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isError } from 'utils/status.comparer';

export const getCities = createSelector(
  ({
    area: {
      data: { cities },
    },
  }: RootState) => cities,
  (cities) => cities,
);

export const getAreaAvailability = createSelector(
  ({
    area: {
      data: { availability },
    },
  }: RootState) => availability,
  (availability) => availability,
);

export const checkIsBlocked = createSelector(
  ({ area: { status } }: RootState) => status,
  (status) => isBlocked(status),
);
export const selectIsRejected = createSelector(
  ({ area: { status } }: RootState) => status,
  (status) => isError(status),
);

export const selectName = ({ area: { data } }: RootState) => data.name;
