import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';

export const editAccountContainer: SxProps = {
  width: ['100%', '688px', '100%'],
  pb: 6,
};

export const contactContainer: SxProps = {
  display: ['block', 'flex', 'flex'],
  width: '100%',
  margin: '0 5px',
};

export const phoneBoxStyle: SxProps = {
  width: ['320px', '270px', '270px'],
  marginRight: [0, '20px', '20px'],
  mb: [2.5, 0, 0],
  mt: [2.5, 0, 0],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const emailBoxStyle: SxProps = {
  width: ['320px', '270px', '270px'],
  marginRight: [0, '20px', '20px'],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const countryComponent: SxProps = {
  width: ['320px', '174px', '174px'],
  marginRight: [0, '20px', '20px'],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const titleBoxStyle: SxProps = {
  width: ['320px', '160px', '160px'],
  mr: [0, 2.5, 2.5],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const addressTypeContainer: SxProps = {
  display: 'block',
  width: ['320px', '688px', '740px'],
  mb: 2.5,
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const buttonContainer: SxProps = {
  display: 'ruby',
  float: 'right',
  marginTop: [0, '-100px', '-70px'],
};

export const cancelButton: SxProps = {
  width: '112px',
  height: '56px',
};

export const saveEditAccount: SxProps = {
  marginLeft: '20px',
  width: '112px',
  height: '56px',
};

export const streetAddressContainer: SxProps = {
  width: ['320px', '560px', '560px'],
  mr: 2.5,
  mb: 2.5,
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const apatBoxStyle: SxProps = {
  width: ['320px', '160px', '160px'],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const bottomButtonStyle: SxProps = {
  width: '320px',
  mb: 2.5,
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const labelText: SxProps = {
  fontFamily: fonts.poppins,
  fontSize: '14px',
  fontWeight: '500',
  color: colors.black,
};

export const bithdayContainerStyle: SxProps = {
  width: ['320px', '688px', '740px'],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};
export const nameContainer: SxProps = {
  width: ['320px', '560px', '560px'],
  [theme.breakpoints.between(390, 435)]: {
    width: '360px',
  },
};

export const formInputContainer: SxProps = {
  width: '100%',
  paddingTop: '20px',
};

export const dobFieldDropdown: SxProps = {
  width: '100%',
};

export const formInput: SxProps = {
  width: '100%',
};
