import { createSelector } from '@reduxjs/toolkit';
import { getCities } from 'redux/PDP/area/selectors';
import { getSuggestedAddresses } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { isEqualIgnoringCase } from 'utils/string/is-equal-ignoring-case';

export const getSuggestedCity = createSelector(
  getSuggestedAddresses,
  getCities,
  ([suggestedAddress], cities) =>
    cities.find(
      ({ name }) =>
        suggestedAddress && isEqualIgnoringCase(name, suggestedAddress.city),
    ),
);
