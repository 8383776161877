import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import * as styles from 'components/LocalSeoPage/States/styles';
import { BlogCarousel } from 'components/LocalSeoPage/BlogCarousel/BlogCarousel';
import { Box, Typography } from '@mui/material';
import { CanonicalUrlProvider } from 'providers/CanonicalUrlProvider';
import { HeadMetaState } from 'components/LocalSeoPage/HeadMeta/HeadMetaState';
import { LoadingSpinner } from 'components/LocalSeoPage/LoadingSpinner/LoadingSpinner';
import { RedirectUrl } from 'components/LocalSeoPage/RedirectUrl/RedirectUrl';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { StoreWidget } from 'components/common/StoreWidget/StoreWidget';
import {
  getData,
  getLength,
  isBlocked,
  selectIsError,
} from 'components/LocalSeoPage/State/feature/selectors';
import { getSeoUrlParam } from 'utils/get-seo-url-param';
import { loadLocale } from 'redux/locale/utils';
import { seoActions } from 'containers/LocalSeoContainer/feature/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useScrollToTopOnMount } from 'hooks/scroll/use-scroll-to-top-on-mount';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const State: FC = () => {
  useScrollToTopOnMount();
  const dispatch = useDispatch();
  const isError = useSelector(selectIsError);
  const storeData = useSelector(getData);
  const storeCount = useSelector(getLength);
  const dataFetching = useSelector(isBlocked);
  const { state: stateParam } = useParams<{ state: string }>();
  const [storeWidgetsToShow, setStoreWidgetsToShow] = useState<number>(8);
  const state = stateParam && getSeoUrlParam(stateParam);
  const locale = loadLocale();

  useEffect(() => {
    if (!dataFetching) {
      setStoreWidgetsToShow(storeData.length > 8 ? 8 : storeData.length);
    }
  }, [dataFetching, storeData.length]);

  useEffect(() => {
    dispatch(
      seoActions.clearAndSet({
        title: i18next.t(TK.STATE_META_TITLE_TAG, {
          replace: {
            State: state,
          },
        }),
        description: i18next.t(TK.STATES_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
          },
        }),
      }),
    );
  });

  if (dataFetching) {
    return <LoadingSpinner />;
  }

  if (isError) {
    /** @notice it's made to trigger ErrorBoundaries and show UnexpectedError Page */
    throw new Error();
  }

  const displayStoreWidgets = () => {
    const widgets = [];

    for (let i = 0; i < storeData.length; i += 1) {
      widgets.push(
        <Box
          key={storeData[i].ID}
          display={i >= storeWidgetsToShow ? 'none' : 'inline'}
        >
          <StoreWidget
            storeName={storeData[i].ModifiedName}
            storeAddress={storeData[i].Address}
            storeHours={storeData[i].TimingsShort}
            storePhoneNumber={storeData[i].PhoneNumber}
            isSmoothieStore={storeData[i].IsLevel2Store}
            pageFriendlyUrl={storeData[i].PageFriendlyURL}
            locale={locale}
          />
        </Box>,
      );
    }
    return widgets;
  };

  return (
    <>
      <RedirectUrl />
      <CanonicalUrlProvider />
      {state && <HeadMetaState state={state} />}
      <Typography variant="h1" sx={styles.stateTitle}>
        {i18next.t(TK.STATE_SEO_STATE_PAGE_TITLE, {
          replace: {
            State: state,
          },
        })}
      </Typography>
      <Typography sx={styles.stateDescription}>
        {i18next.t(TK.STATE_SEO_STATE_PAGE_DESCRIPTION, {
          replace: {
            State: state,
          },
        })}
      </Typography>
      <Typography variant="h2" sx={styles.stateStoreTitle}>
        {i18next.t(TK.STATE_STORE_COUNT, {
          replace: {
            State: state,
            StoreCount: storeCount,
          },
        })}
      </Typography>
      <Box>
        {displayStoreWidgets()}
        <Box sx={styles.showMore}>
          <Typography
            variant="button"
            display={
              storeData.length - storeWidgetsToShow > 0 ? 'inline' : 'none'
            }
            onClick={() => {
              setStoreWidgetsToShow(
                storeData.length - storeWidgetsToShow > 8
                  ? storeWidgetsToShow + 8
                  : storeWidgetsToShow +
                      (storeData.length - storeWidgetsToShow),
              );
            }}
          >
            {i18next.t(TK.SHOW_MORE_WIDGETS, {
              replace: {
                Amount:
                  storeData.length - storeWidgetsToShow > 8
                    ? 8
                    : storeData.length - storeWidgetsToShow,
              },
            })}
          </Typography>
          <Typography variant="body3">
            {i18next.t(TK.TOTAL_WIDGETS_SHOWN, {
              replace: {
                Amount: storeWidgetsToShow,
                TotalAmount: storeData.length,
              },
            })}
          </Typography>
        </Box>
      </Box>
      <RenderWhenInView>
        <BlogCarousel />
      </RenderWhenInView>
    </>
  );
};
