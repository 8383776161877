import { Category, Item, Status } from 'redux/header/header.types';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';

export const getData = ({ header }: RootState): Item[] => header.data;

export const getForDesktop = createSelector<RootState, Item[], Item[]>(
  getData,
  (data) => data.filter((item) => item.forWeb),
);

export const getForMobile = createSelector<RootState, Item[], Item[]>(
  getData,
  (items) =>
    items
      .filter((item: Item) => item.forMobile)
      .map((item) => ({
        ...item,
        categories: item.categories.filter(
          (category: Category) => category.forMobile,
        ),
      }))
      .sort((itemA: Item, itemB: Item) => {
        if (itemA.mobileSequence && itemB.mobileSequence) {
          return itemA.mobileSequence - itemB.mobileSequence;
        }
        return Number(itemA.mobileSequence || itemB.mobileSequence);
      }),
);

export const getStatus = ({ header }: RootState): Status | null =>
  header.status;

export const getIsFetching = createSelector<RootState, Status | null, boolean>(
  getStatus,
  (status) => status === Status.FETCHING,
);

export const getIsFetched = createSelector<RootState, Status | null, boolean>(
  getStatus,
  (status) => status === Status.SUCCESS,
);

export const getIsFetchError = createSelector<
  RootState,
  Status | null,
  boolean
>(getStatus, (status) => status === Status.ERROR);
