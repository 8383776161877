import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/Careers/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import {
  FacebookRounded,
  LinkedIn,
  Pinterest,
  Twitter,
  YouTube,
} from '@mui/icons-material/';
import { FruitLabour } from 'components/About/Careers/components/FruitLabor';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Image } from 'components/common/Image/Image';
import { Properties } from 'components/About/Careers/components/Properties';
import { ShowOnlyXs } from 'utils/hidden/show-only-xs';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { Testimonials } from 'components/About/Careers/components/testimonials';
import { careerFlowerImage } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const Careers: FC = () => {
  return (
    <Box sx={styles.careerContainer}>
      <Box sx={styles.bannerMainContainer}>
        <ShowUpMd>
          <Typography variant="h1" sx={styles.bannerHeaderStyle} mb={1}>
            {i18next.t(TK.CAREER_BANNER_HEADER)}
          </Typography>
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Typography variant="h1" sx={styles.bannerHeaderStyle} mb={1}>
              {i18next.t(TK.CAREER_BANNER_HEADER1)} <br />{' '}
              {i18next.t(TK.CAREER_BANNER_HEADER2)}
            </Typography>
          </ShowUpSm>
        </HideUpMd>
        <HideUpMd>
          <ShowOnlyXs>
            <Typography variant="h1" sx={styles.bannerHeaderStyle} mb={1}>
              {i18next.t(TK.CAREER_BANNER_HEADER1)} <br />{' '}
              {i18next.t(TK.CAREER_BANNER_HEADER2)}
            </Typography>
          </ShowOnlyXs>
        </HideUpMd>
        <Typography variant="body1" sx={styles.bannerSubHeaderStyle} mb={2.5}>
          {i18next.t(TK.CAREER_BANNER_SUB_HEADER)}
        </Typography>
        <Button
          href="#job-listings"
          variant="contained"
          sx={styles.bannerButtonStyle}
        >
          {i18next.t(TK.CAREER_BANNER_BUTTON_TEXT)}
        </Button>
      </Box>
      <Box display="flex" sx={styles.aboutUsConatiner}>
        <Box p={4}>
          <Image src={careerFlowerImage} sx={styles.careerFlowerImageStyle} />
        </Box>
        <Box
          display="flex"
          pl={[2, 0, 0]}
          pr={2}
          alignItems="center"
          pb={[6, 6, 0]}
        >
          <Typography variant="h2" sx={styles.aboutTextStyle} pr={[2, 3, 3]}>
            {' '}
            {i18next.t(TK.CAREER_ABOUT)}
          </Typography>
          <Typography fontSize="16px">
            {' '}
            {i18next.t(TK.CAREER_ABOUT_TEXT)}
          </Typography>
        </Box>
      </Box>
      <FruitLabour />
      <Properties />
      <Testimonials />
      <Box sx={styles.socialLinkContainer}>
        <Typography variant="h2" sx={styles.followText}>
          {i18next.t(TK.FOLLOW_US_TEXT)}
        </Typography>
        <Box textAlign="center" pt={1.5}>
          <Box>
            <Link
              target="_blank"
              href="https://www.facebook.com/ediblearrangements"
            >
              <FacebookRounded className="socialIcon" />
            </Link>
            <Link target="_blank" href="https://twitter.com/edible">
              <Twitter className="socialIcon" />
            </Link>
            <Link target="_blank" href="https://pinterest.com/dofruit/">
              <Pinterest className="socialIcon" />
            </Link>
            <Link
              target="_blank"
              href="https://www.linkedin.com/company/edible-arrangements/"
            >
              <LinkedIn className="socialIcon" />
            </Link>
            <Link
              target="_blank"
              href="https://www.youtube.com/ediblearrangements"
            >
              <YouTube className="socialIcon" />
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.jobListingContainer} id="job-listings">
        <Link
          target="_blank"
          sx={styles.linkTextStyle}
          href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=3496665c-85f2-452a-8263-0aeb92099601&ccid=19000101_000001&lang=en_US"
        >
          <Box sx={styles.careerCorporateBack} />
          <Box sx={styles.careerCorporateInner}>
            <Typography variant="h2" sx={styles.jobListingText}>
              {i18next.t(TK.CAREER_CORPORATE_TEXT)} <br />{' '}
              {i18next.t(TK.CAREER_OPENING_TEXT)}
            </Typography>
          </Box>
        </Link>
        <Link
          target="_blank"
          sx={styles.linkTextStyle}
          href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=3496665c-85f2-452a-8263-0aeb92099601&ccId=9200107036002_2&lang=en_US"
        >
          <Box sx={styles.careerStoresBack} />
          <Box sx={styles.careerCorporateInner}>
            <Typography variant="h2" sx={styles.jobListingText}>
              {i18next.t(TK.CAREER_STORE_TEXT)}
            </Typography>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
