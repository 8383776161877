import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Settings } from 'react-slick';
import React from 'react';

export const settings: Settings = {
  slidesToShow: 5.5,
  slidesToScroll: 1,
  variableWidth: true,
  initialSlide: 0,
  infinite: true,
  arrows: true,
  swipe: true,
  nextArrow: (
    <Box>
      <ArrowForwardIos fontSize="small" />
    </Box>
  ),
  prevArrow: (
    <Box>
      <ArrowBackIosNew fontSize="small" />
    </Box>
  ),
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: true,
        slidesToShow: 4.5,
        nextArrow: undefined,
        prevArrow: undefined,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: true,
        slidesToShow: 2.5,
        nextArrow: undefined,
        prevArrow: undefined,
      },
    },
  ],
};
