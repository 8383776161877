import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isPending } from 'utils/status.comparer';

export const getResult = createSelector(
  ({ pdpOrderAddressValidation: { validated } }: RootState) =>
    validated?.validationResults,
  (validationResults) => validationResults,
);
export const getAddress = createSelector(
  ({ pdpOrderAddressValidation: { validated } }: RootState) =>
    validated?.addressToValidate,
  (address) => address,
);
export const getSuggestedAddresses = createSelector(
  ({ pdpOrderAddressValidation: { validated } }: RootState) =>
    validated?.suggestedAddresses,
  (addresses) => addresses || [],
);

export const selectIsResidential = createSelector(
  ({ pdpOrderAddressValidation: { validated } }: RootState) =>
    validated?.isResidential,
  (isResidential) => isResidential,
);
export const getIsStatusPending = createSelector(
  ({ pdpOrderAddressValidation: { status } }: RootState) => status,
  (status) => isPending(status),
);
