import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { FulfillType } from 'types/cart.types';
import { Upsell } from 'api/products/types';

export type UpsellsParams = {
  orderType: FulfillType;
  date: string;
  store: number;
};

class ProductApiService extends ApiBaseService {
  getAddons(id: number, params: UpsellsParams): AxiosPromise<Upsell[]> {
    return this.apibase.get(`/products/${id}/addons`, { params });
  }

  getUpgrades(id: number, params: UpsellsParams): AxiosPromise<Upsell[]> {
    return this.apibase.get(`/products/${id}/upgrades`, { params });
  }
}

export const productApiService = new ProductApiService();
