import * as TK from 'containers/SignupContainer/translations/locale.constants';

export const SignUpCommonLocale = {
  [TK.SIGNUP_TEXT]: 'Create New Account',
  [TK.SIGNUP_ACCOUNT_INFORMATION_TEXT]: 'Account Information',
  [TK.SIGNUP_YOUR_NAME]: 'Your Name',
  [TK.SIGNUP_BIRTHDAY]: 'Birthday',
  [TK.SIGNUP_EMAIL_ADDRESS_TEXT]: 'Email Address',
  [TK.SIGNUP_EMAIL_NOT_VALID_ERROR_TEXT]: 'Please enter a valid email address',
  [TK.SIGNUP_PASSWORD_TEXT]: 'Password',
  [TK.SIGNUP_PASSWORD_STRENGTH]: 'Password Strength',
  [TK.SIGNUP_PASSWORD_INVALID]:
    'Password Must Have At Least 1 Special Character(!@#$%^&*)',
  [TK.SIGNUP_PASSWORD_MINIMUM]: 'Password must contain at least 8 characters',
  [TK.SIGNUP_VERIFY_PASSWORD_TEXT]: 'Verify Password',
  [TK.SIGNUP_VERIFY_PASSWORD_MATCH]: 'Passwords must match',
  [TK.SIGNUP_FIRST_NAME]: 'First Name',
  [TK.SIGNUP_LAST_NAME]: 'Last Name',
  [TK.SIGNUP_PHONE_NUMBER]: 'Phone Number',
  [TK.SIGNUP_BIRTHDAY_TOOLTIP]:
    'Tell us your Birth date & month and we will send you a free birthday gift!',
  [TK.SIGNUP_BIRTH_DATE]: 'Day',
  [TK.SIGNUP_BIRTH_DAY]: 'Day',
  [TK.SIGNUP_BIRTH_DAY_REQUIRED]: 'Enter birth day',
  [TK.SIGNUP_BIRTH_MONTH]: 'Month',
  [TK.SIGNUP_BIRTH_MONTH_REQUIRED]: 'Enter birth month',
  [TK.SIGNUP_BIRTH_YEAR]: 'Year (Optional)',
  [TK.SIGNUP_BIRTH_YEAR_PLACEHOLDER]: 'Year',
  [TK.SIGNUP_PASSWORD_REQUIREMENTS]:
    'Your password must be at least 8 characters, and must contain at least 1 special character (!@#$%^&*).',
  [TK.ENTER_FIRST_NAME]: 'Enter first name.',
  [TK.ENTER_LAST_NAME]: 'Enter last name.',
  [TK.ERROR_FIRST_NAME_SPL_CHARS]: 'Enter a valid first name',
  [TK.ERROR_LAST_NAME_SPL_CHARS]: 'Enter a valid last name',
  [TK.ERROR_PHONE_NUMBER_INVALID]: 'Enter a valid phone number',
  [TK.ERROR_REQUIRED]: 'This is required field',
  [TK.CHOOSE_OPTION]: 'Please Choose',
  [TK.SIGNUP_CREATE_ACCOUNT_BUTTON_TEXT]: 'Create Account',
  [TK.SIGNUP_SUCCESS_TITLE]: 'Congratulations!',
  [TK.SIGNUP_SUCCESS_CONTENT]:
    'Visit My Account to see your available rewards.',
  [TK.SIGNUP_MY_ACCOUNT_BUTTON_TEXT]: 'My Account',
  [TK.SIGNUP_SHOP_NOW_BUTTON_TEXT]: 'Shop Now',
  [TK.SIGNUP_LINK_TERMS_OF_USE]: 'terms of use',
  [TK.SIGNUP_LINK_PRIVACY_POLICY]: 'our privacy policy',
  [TK.SIGNUP_LINK_PRODUCTS_POLICY]: 'products policy',
  [TK.SIGNUP_LINK_EDIBLE_REWARDS]: 'Edible Rewards® Terms & Conditions',
  [TK.SIGNUP_LINK_CLICK_HERE]: 'click here',
  [TK.SIGNUP_TERMS_AND_CONDITIONS_TEXT]:
    'By clicking the button marked CREATE ACCOUNT or by any other act on your part to use Edible Arrangements® site or our services in any way, you acknowledge that you have read and understand and agree to be legally bound by all of the terms and conditions of our website {{termsOfUse}}, {{privacyPolicy}}, {{productsPolicy}}, {{edibleRewards}} and all of the additional terms of use or do not agree to be bound by all of the terms of use or additional terms, {{clickHere}} for further information regarding Edible Rewards®.',
  [TK.SIGNUP_BACK_TEXT]: 'Back',
  [TK.SIGNUP_CONTACT_INFORMATION]: 'Contact Information',
};
