import { AxiosResponse } from 'axios';
import { HttpStatusCode } from 'enums/http-status-code';
import { SeverityLevel } from 'utils/telemetry/severity-level.enum';
import { appInsights } from 'utils/telemetry/app-insights';

export const happyNotFoundResponseInterceptor = (
  res: AxiosResponse,
): Promise<AxiosResponse> => {
  const isHappyNotFoundResponse =
    res.status === HttpStatusCode.OK &&
    typeof res.data === 'string' &&
    res.data.startsWith('Not found');

  if (isHappyNotFoundResponse) {
    const message = `Not found. Akamai responded with status code ${res.status} in a non-proper way.`;

    appInsights.trackException({
      exception: new Error(message),
      severityLevel: SeverityLevel.Critical,
    });

    return Promise.reject(new Error(message));
  }

  return Promise.resolve(res);
};
