export enum Sort {
  RELEVANCE = 'relevance',
  PRICE_ASC = 'price_low_to_high',
  PRICE_DESC = 'price_high_to_low',
}

const sorters: Sort[] = [Sort.RELEVANCE, Sort.PRICE_ASC, Sort.PRICE_DESC];

export const getSorters = (): Sort[] => sorters;

export const isSort = <T>(sort: Sort | T): sort is Sort =>
  getSorters().includes(sort as Sort);
