import { Ingredient } from 'components/PDP/Details/components/Ingredients/Ingredient';
import { List } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  ingredients: string[];
}

export const Ingredients: FC<Props> = ({ ingredients }) => (
  <List sx={{ px: 2, py: 0 }}>
    {ingredients.map((ingredient) => (
      <Ingredient key={ingredient} ingredient={ingredient} />
    ))}
  </List>
);
