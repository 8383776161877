import {
  ABOUT_STORE_ONE,
  ABOUT_STORE_THREE,
  ABOUT_STORE_TITLE,
  ABOUT_STORE_TWO,
  BLOG_CAROUSEL,
  CATEGORIES_TITLE,
  DISTANCE_AWAY,
  FRANCHISE_BANNER_ALT_TEXT,
  FRANCHISE_BANNER_ARIA_BODY_LABEL,
  FRANCHISE_BANNER_ARIA_LABEL,
  FRANCHISE_BANNER_ARIA_TITLE_LABEL,
  FRANCHISE_BANNER_BODY,
  FRANCHISE_BANNER_BUTTON,
  FRANCHISE_BANNER_BUTTON_LABEL,
  FRANCHISE_BANNER_SUB_TITLE,
  FRANCHISE_BANNER_SUB_TITLE_LABEL,
  FRANCHISE_BANNER_TEXT_CONTAINER,
  FRANCHISE_BANNER_TITLE,
  POPULAR_GIFT,
  SERVICED_AREAS_TITLE,
  SOCIAL_FACEBOOK_ARIA_LABEL,
  SOCIAL_INSTAGRAM_ARIA_LABEL,
  SOCIAL_MEDIA_BODY,
  SOCIAL_MEDIA_TITLE,
  SOCIAL_PINTEREST_ARIA_LABEL,
  SOCIAL_TWITTER_ARIA_LABEL,
  SOCIAL_YOUTUBE_ARIA_LABEL,
  STORE_ADDRESS1,
  STORE_ADDRESS2,
  STORE_BANNER_ALT_TEXT,
  STORE_BANNER_BODY,
  STORE_BANNER_BUTTON,
  STORE_BANNER_TITLE,
  STORE_DETAILS_ADDRESS1,
  STORE_DETAILS_ADDRESS2,
  STORE_DETAILS_BREADCRUMB,
  STORE_DETAILS_META_DESCRIPTION,
  STORE_DETAILS_META_SCHEMA_DESCRIPTION,
  STORE_DETAILS_META_TITLE,
} from 'components/StoreDetails/translations/locale.constants';

export const storeDetailsCommonLocale = {
  [STORE_DETAILS_BREADCRUMB]:
    'Edible Store {{storeNumber}} - {{city}}, {{state}}',
  [STORE_ADDRESS1]: 'Edible® {{storeNumber}}, {{address1}}',
  [STORE_ADDRESS2]: 'Edible® {{storeNumber}}, {{address1}}, {{address2}}',
  [STORE_BANNER_BODY]:
    'Shop gifts and treats for someone else (or yourself!) at Edible Arrangements {{city}}, {{state}} - {{address1}}.',
  [STORE_BANNER_BUTTON]: 'Shop Now',
  [FRANCHISE_BANNER_BUTTON_LABEL]: 'Open Your Own Franchise Banner Button Text',
  [STORE_BANNER_TITLE]: 'Come see us!',
  [STORE_BANNER_ALT_TEXT]: 'Visit and shop our Edible Store',
  [FRANCHISE_BANNER_ALT_TEXT]: 'Learn more about Owning an Edible Franchise',
  [FRANCHISE_BANNER_TITLE]: 'Live the Sweet Life!',
  [FRANCHISE_BANNER_SUB_TITLE]: 'Own a Franchise',
  [FRANCHISE_BANNER_SUB_TITLE_LABEL]: 'Open Your Own Franchise Banner Subtitle',
  [FRANCHISE_BANNER_TEXT_CONTAINER]:
    'Open Your Own Franchise Banner Text Container',
  [FRANCHISE_BANNER_ARIA_LABEL]: 'Open Your Own Franchise Banner',
  [FRANCHISE_BANNER_ARIA_TITLE_LABEL]: 'Open Your Own Franchise Banner Title',
  [FRANCHISE_BANNER_ARIA_BODY_LABEL]: 'Open Your Own Franchise Banner Body',
  [FRANCHISE_BANNER_BODY]:
    'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
  [FRANCHISE_BANNER_BUTTON]: 'Learn More',
  [SERVICED_AREAS_TITLE]: 'Service Areas',
  [ABOUT_STORE_TITLE]: 'About Our Store',
  [ABOUT_STORE_ONE]:
    "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}} first opened in {{opening}}. Ever since, we’ve been helping people in our local community celebrate all kinds of occasions – big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! Visit us any time for a FREE sample!",
  [ABOUT_STORE_TWO]:
    'Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit salad, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They’re perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!',
  [ABOUT_STORE_THREE]:
    'If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at <phone>{{phoneNumber}}</phone>. We can’t wait to meet you!',
  [SOCIAL_MEDIA_TITLE]: "Let's Connect!",
  [SOCIAL_MEDIA_BODY]:
    'Follow this store on social media for exclusive promotions!',
  [SOCIAL_FACEBOOK_ARIA_LABEL]: 'Follow Edible Store on Facebook',
  [SOCIAL_INSTAGRAM_ARIA_LABEL]: 'Follow Edible Store on Facebook',
  [SOCIAL_PINTEREST_ARIA_LABEL]: 'Follow Edible Store on Pinterest',
  [SOCIAL_TWITTER_ARIA_LABEL]: 'Follow Edible Store on Twitter',
  [SOCIAL_YOUTUBE_ARIA_LABEL]: 'Follow Edible Store on YouTube',
  [CATEGORIES_TITLE]: "We Think You'll Like These Popular Gifts",
  [DISTANCE_AWAY]: '~{{distance}} {{distanceMetric}} Away',
  [POPULAR_GIFT]: 'Popular Gift Section',
  [BLOG_CAROUSEL]: 'Blog Carousel Display',
  [STORE_DETAILS_ADDRESS1]:
    'Edible Arrangements {{city}}, {{state}} - {{address1}}',
  [STORE_DETAILS_ADDRESS2]:
    'Edible Arrangements {{city}}, {{state}} - {{address1}} {{address2}}',
  [STORE_DETAILS_META_TITLE]:
    'Edible Arrangements {{city}}, {{state}}: {{address1}} {{address2}}',
  [STORE_DETAILS_META_SCHEMA_DESCRIPTION]:
    "Edible Arrangements® {{storeNumber}} in {{city}}, {{state}}  first opened in  {{openingDate}}. Ever since, we've been helping people in our local community celebrate all kinds of occasions - big and small. Our fruit arrangements and gifts are always freshly-crafted using fruit that's grown and picked to our Fruit Expert® standards. And, we have the best variety of gourmet chocolate Dipped Fruit™, too! (Visit us any time for a FREE sample!)  Did you know we also have fresh fruit treats you can enjoy on the go? Stop by today to grab a fresh fruit smoothie, Froyo Fruit Shake™, Edible® Donut, chocolate Dipped Fruit™ Cone, or any one of our other delicious fresh fruit snacks! They're perfect for picnics, lunch break snacks, and even as an afternoon pick-me-up!If you need a birthday gift, wedding favors, a holiday centerpiece, or even a little something just because, give us a call at {{storePhoneNumber}}. We can't wait to meet you!",
  [STORE_DETAILS_META_DESCRIPTION]:
    'Grab a healthy delicious fruit smoothie, chocolate dipped fruit treat, donut or even fresh fruit salad at the Edible Arrangements store in {{city}} today!',
};
