import { Mode } from 'components/Authentication/LogIn/feature/slice';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isFulfilled, isPending } from 'utils/status.comparer';
import { isLocked, isTruthy } from 'api/auth-legacy/validate.misc';

export const checkIsProcessing = createSelector(
  ({ authentication: { validationStatus } }: RootState) => validationStatus,
  ({ authentication: { logInStatus } }: RootState) => logInStatus,
  (validationStatus, logInStatus) =>
    isPending(validationStatus) || isPending(logInStatus),
);
export const getValidationType = createSelector(
  ({ authentication: { validationType } }: RootState) => validationType,
  (type) => type,
);
export const selectLogInIsFulfilled = createSelector(
  ({ authentication: { logInStatus } }: RootState) => logInStatus,
  (status) => isFulfilled(status),
);
export const checkIsTruthyType = createSelector(
  ({ authentication: { validationType } }: RootState) => validationType,
  (type) => isTruthy(type),
);
export const checkIsLockedType = createSelector(
  ({ authentication: { validationType } }: RootState) => validationType,
  (type) => isLocked(type),
);
export const selectMode = ({ authentication: { mode } }: RootState) => mode;
export const selectIsNoneMode = createSelector(
  selectMode,
  (mode) => mode === Mode.None,
);
export const selectIsLoginMode = createSelector(
  selectMode,
  (mode) => mode === Mode.LogIn,
);
export const selectIsResetMode = createSelector(
  selectMode,
  (mode) => mode === Mode.Reset,
);
