import { ItemType, LegacyAddToCartParams } from 'types/cart.types';
import { PrintibleState } from 'components/PDP/Order/GreetingsCards/feature/greetings-cards.types';
import { Upsell } from 'api/products/types';

export const fillCartDataPrintible = (
  cart: LegacyAddToCartParams,
  printibleOrder: PrintibleState,
  printibleAddon?: Upsell,
): LegacyAddToCartParams => {
  if (!printibleOrder.data.printibleID || !printibleAddon?.options[0]) {
    return { ...cart };
  }

  const printibleAddonOption = printibleAddon.options[0];
  return {
    ...cart,
    AddonItems: [
      ...cart.AddonItems,
      {
        ProductID: printibleAddonOption.productId,
        Price: printibleAddonOption.price.toFixed(2).toString(),
        Quantity: 1,
        ItemType: ItemType.AddOn,
        OptionText: `:${printibleOrder.data.printibleID}:c`,
        PrintibleCard: printibleOrder.data.imageLink,
        PrintibleCardResponse: JSON.stringify({
          origin: printibleOrder.origin,
          data: printibleOrder.data,
        }),
        Sleeve: false,
      },
    ],
  };
};
