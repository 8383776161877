import { ResponseError } from 'types/error.types';
import { ShipmentStoreTaxHistory } from 'components/Cart/Details/components/OrderSummary/feature/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getShipmentStoreTaxHistory } from 'components/Cart/Details/components/OrderSummary/feature/action';

export interface ShipmentStoreTaxHistoryState {
  shipmentTaxHistory: ShipmentStoreTaxHistory[];
  status: Status;
  error: ResponseError | null;
}

const initialState: ShipmentStoreTaxHistoryState = {
  shipmentTaxHistory: [],
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'ShipmentStoreTaxHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShipmentStoreTaxHistory.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(getShipmentStoreTaxHistory.fulfilled, (state, { payload }) => ({
        ...state,
        status: Status.FULFILLED,
        shipmentTaxHistory: payload,
      }))
      .addCase(getShipmentStoreTaxHistory.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

export const ShipmentStoreTaxHistoryReducer = slice.reducer;
