import { SxProps } from '@mui/material/styles';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const textField: SxProps = {
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    pr: 0,
  },
};

export const adornment: SxProps = {
  '&.MuiInputAdornment-root': {
    position: 'absolute',
    right: '14px',
  },
};

export const wrapper: ThemedSxProps = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
});

export const errorText: SxProps = {
  position: 'absolute',
  top: '53px',
};

export const dividerText: ThemedSxProps = (theme) => ({
  ml: 2,
  mr: 2,
  [theme.breakpoints.down('sm')]: {
    mt: 2,
    mb: 2,
  },
});

export const flexBox: ThemedSxProps = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
});

export const dividerLine: ThemedSxProps = (theme) => ({
  display: 'none',
  height: '1px',
  width: '100%',
  background: 'var(--other-divider, rgba(0, 0, 0, 0.12))',
  [theme.breakpoints.down('sm')]: {
    display: 'inline',
  },
});

export const recipientLock: ThemedSxProps = (theme) => ({
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
});

export const alertText: SxProps = {
  mt: [2, 2, 2],
  maxWidth: ['auto', 'auto', 610],
};
