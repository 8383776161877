import * as TK from 'components/Authentication/translations/locale.constants';

export const authenticationCommonLocale = {
  [TK.AUTH_LOGIN_ERR_LOCK_HEADER]: 'Your account is locked',
  [TK.AUTH_LOG_IN_ERR_LOCKED]:
    'You had too many unsuccessful login attempts. Please try again later',
  [TK.AUTH_LOG_IN_ERR_TRY_AGAIN]: 'Please try again after sometime',
  [TK.AUTH_LOG_IN_ERR_INVALID_CREDENTIALS]:
    'Please enter your email and password',
  [TK.AUTH_LOG_IN_ERR_NOT_EXIST]:
    'We couldn’t find a user with this email and password. Please try again',
  [TK.AUTH_LOG_IN_ERR_EXCEPTION]:
    'We are having a problem to sign you in, sorry for the inconvenience. Please try again later',
  [TK.AUTH_DIALOG_LOG_IN_HEADER]: 'Log In',
  [TK.AUTH_DIALOG_MODAL_REGISTRATION_TIP]: 'Don’t have an account?',
  [TK.AUTH_DIALOG_MODAL_REGISTRATION]: 'Create an Account',
  [TK.AUTH_LOG_IN_FORM_EMAIL_LABEL]: 'Email Adress*',
  [TK.AUTH_LOG_IN_FORM_PASSWORD_LABEL]: 'Password*',
  [TK.AUTH_LOG_IN_FORM_PASSWORD_PLACEHOLDER]: 'at least 8 characters',
  [TK.AUTH_LOG_IN_FORM_RESET_LABEL]: 'Forgot Your Password?',
  [TK.AUTH_LOG_IN_FORM_SIGNING_IN]: 'Signing In…',
  [TK.AUTH_LOG_IN_FORM_SUCCESS]: 'Success',
  [TK.AUTH_LOG_IN_FORM_SIGN_IN]: 'Sign In',
  [TK.AUTH_ERR_EMAIL_INVALID]: 'Enter a valid email address',
  [TK.AUTH_LOG_IN_ERR_PASSWORD_REQUIRED]: 'Password is required',
  [TK.AUTH_LOG_IN_ERR_EMAIL_REQUIRED]: 'Email is required',
  [TK.AUTH_DIALOG_RESET_HEADER]: 'Forgot Password',
  [TK.AUTH_RESET_TIP]:
    'Enter your email address below and we’ll send you instructions to reset your password.',
  [TK.AUTH_RESET_EMAIL_LABEL]: 'Email Address',
  [TK.AUTH_RESET_SUBMITTING]: 'Submitting…',
  [TK.AUTH_RESET_SUBMIT]: 'Submit',
  [TK.AUTH_RESET_SENT_1]: 'Help is on the way!',
  [TK.AUTH_RESET_SENT_2]:
    'We’ve sent you an email with instructions on how to reset your password.',
  [TK.AUTH_RESET_SENT_3]: 'Be on the lookout soon!',
  [TK.AUTH_RESET_STATUS_NOT_FOUND]:
    'Sorry, we couldn’t find that email address. Please try again or create new account',
  [TK.AUTH_RESET_STATUS_ERROR]:
    'We are having a problem in recovering your password, sorry for the inconvenience. Please try again later',
  [TK.AUTH_RESET_STATUS_EXCEPTION]:
    'Sorry we have technical issues. Please try again in a few minutes',
  [TK.SIGNUP_EMAIL_ALREADY_EXIST]:
    'You are already an Edible Rewards Member. Login to your account to view your benefits.',
};
