import { MUIStyledCommonProps } from '@mui/system';
import { Theme } from '@mui/material';
import { theme } from 'utils/theme/theme';
import styled from '@emotion/styled/macro';

interface DesktopCarouselImageWrapperProps extends MUIStyledCommonProps<Theme> {
  active?: boolean;
}

export const GalleryItemWrapper = styled('div')(
  (props: DesktopCarouselImageWrapperProps) => ({
    width: 64,
    maxWidth: 64,
    height: 64,
    border: '2px solid transparent',
    padding: theme.spacing(0.25),

    '& img': {
      border: `1px solid ${theme.palette.divider}`,
      opacity: 0.64,
    },

    '&:hover': {
      cursor: 'pointer',
      '& img': {
        opacity: 1,
      },
    },

    ...(props.active && {
      border: `2px solid ${theme.palette.primary.main}`,
      '& img': {
        border: `1px solid ${theme.palette.divider}`,
        opacity: 1,
      },
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  }),
);

interface BottomGalleryProps extends MUIStyledCommonProps<Theme> {
  withoutArrows?: boolean;
}

export const BottomGallery = styled('div')((props: BottomGalleryProps) => ({
  display: 'inline-block',
  width: '300px',
  '& .slick-arrow:before': {
    content: 'none',
  },
  '& .slick-prev': {
    left: '-40px',
  },

  ...(props.withoutArrows && {
    display: 'inline-block',
    width: '300px',
    '& .slick-initialized .slick-cloned': {
      display: 'none',
    },
  }),
}));
