import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AxiosPromise } from 'axios';
import {
  ProductsCatalogLegacyResponse,
  SearchCatalogLegacyResponse,
} from 'components/Header/types';

class SearchCatalogApiService extends ApiLegacyBaseService {
  getSearchCatalog(): AxiosPromise<SearchCatalogLegacyResponse> {
    return this.apibase.get('/ajax/searchcatalog.aspx');
  }

  getProductsCatalog(
    searchQuery: string,
  ): AxiosPromise<ProductsCatalogLegacyResponse> {
    return this.apibase.get('/ajax/searchcatalog.aspx', {
      params: {
        s: searchQuery,
      },
    });
  }
}

export const searchCatalogApiService = new SearchCatalogApiService();
