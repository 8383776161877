import { ApiBaseService } from 'api/apibase.service';
import { Cart } from 'api/cart/types/cart-retrieval.type';
import { CartRecipientContact } from 'api/cart/types/cart-contact.type';
import {
  CouponCode,
  CouponCodeRemove,
} from 'api/cart/types/cart-coupon-code-type';
import { CouponCodeState } from 'components/Cart/feature/selectors';
import { getCartNgUrl } from 'utils/cart-validation/cart-region-validation';
import axios from 'axios';

class CartApiService extends ApiBaseService {
  cartRetrieval(
    cartrId: number,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .get<Cart>(`${url}/cart/retrieval/${cartrId}/${userId}`)
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartEditParentQuantity(
    cartItemId: number,
    quantity: number,
    callCenterUserId: number,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(
          `${url}/cart/edit/quantity/${cartItemId}/${quantity}/${callCenterUserId}/${userId}`,
        )
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartEditChildQuantity(
    cartItemId: number,
    quantity: number,
    callCenterUserId: number,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(
          `${url}/cart/edit/quantitySubItem/${cartItemId}/${quantity}/${callCenterUserId}/${userId}`,
        )
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartUpdateExpressCheckout(
    cartItemId: number,
    isSelected: boolean,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(
          `${url}/cart/edit/update-express-checkout/${cartItemId}/${String(
            isSelected,
          )}/${userId}`,
        )
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartRemove(
    cartItemId: number,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(`${url}/cart/edit/removeItem/${cartItemId}/${userId}`)
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartRemoveChild(
    cartItemId: number,
    userId: number,
    region: number,
  ): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(`${url}/cart/edit/removeSubItem/${cartItemId}/${userId}`)
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartRemoveRecipient(cartItemId: number, userId: number): Promise<Cart> {
    return this.apibase
      .post<Cart>(`/cart/edit/removeRecipient/${cartItemId}/${userId}`)
      .then(({ data }) => data);
  }

  cartEditRecipientContact(recipient: CartRecipientContact): Promise<Cart> {
    const url = getCartNgUrl(recipient.region);
    if (url)
      return axios
        .post<Cart>(`${url}/cart/edit/update-recipient-contact`, recipient)
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  cartApplyCouponCode({
    crId,
    isCallCenterQAManager,
    orderCount,
    couponCode,
    userSession,
    callCenterMode,
    region,
  }: CouponCode): Promise<CouponCodeState> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<CouponCodeState>(`${url}/cart/edit/apply-coupon`, {
          crId,
          isCallCenterQAManager,
          orderCount,
          couponCode,
          userSession,
          callCenterMode,
          region,
        })
        .then(({ data }) => {
          const newData = { ...data };
          newData.crId = crId;
          return newData;
        });
    throw new Error('Invalid URL for CartNG');
  }

  cartRemoveCouponCode({
    crId,
    couponCode,
    region,
  }: CouponCodeRemove): Promise<Cart> {
    const url = getCartNgUrl(region);
    if (url)
      return axios
        .post<Cart>(`${url}/cart/edit/remove-coupon`, {
          crId,
          couponCode,
        })
        .then(({ data }) => data);
    throw new Error('Invalid URL for CartNG');
  }

  async getCartCount(cartId: number, region: number): Promise<number> {
    const url = getCartNgUrl(region);
    if (url) {
      const cartCount = await axios.get(`${url}/cart/cartCount/${cartId}`);
      return cartCount.data as number;
    }
    throw new Error('Invalid URL for CartNG');
  }
}

const cartApiService = new CartApiService();

export { cartApiService };
