import { BvCallback } from 'lib/bazaarvoice/types/bv-callback.type';
import { EventName } from 'lib/bazaarvoice/enums/event-name.enum';
import { EventType } from 'lib/bazaarvoice/enums/event-type.enum';
import { Locale } from 'enums/locale';
import { getLocale } from 'lib/bazaarvoice/utils/get-locale';

interface Arguments {
  locale: Locale;
  productId: number;
  productName: string;
  productImageUrl?: string;
  productPageUrl: string;
  brandName: string;
}

/**
 * DCC (Dynamic Catalog Collection) widget
 *
 * @see {@link https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Collect/product_feed.html#step-2-add-dcc-javascript-to-product-display-pages}
 * @returns Bazaarvoice callback that should be called together with other callbacks
 */
export function initDynamicCatalogCollection({
  locale,
  productId,
  productName,
  productImageUrl,
  productPageUrl,
  brandName,
}: Arguments): BvCallback {
  window.bvDCC = {
    catalogData: {
      locale: getLocale(locale),
      catalogProducts: [
        {
          productId: String(productId),
          productName,
          productImageURL: productImageUrl,
          productPageURL: productPageUrl,
          brandName,
        },
      ],
    },
  };
  return (bv) =>
    bv.pixel.trackEvent(EventName.CATALOG_UPDATE, {
      type: EventType.PRODUCT,
      locale: window.bvDCC.catalogData.locale,
      catalogProducts: window.bvDCC.catalogData.catalogProducts,
    });
}
