export const ALERT_OWN_BOX_ADDONS = 'pdp.alert.own_box_addons';
export const ALERT_PRODUCT_OPTION = 'pdp.alert.product_option';
export const ALERT_AVAILABILITY_ZIP = 'pdp.alert.availability_zip';
export const ALERT_AVAILABILITY_DATE = 'pdp.alert.availability_date';
export const ALERT_AVAILABILITY_DELIVERY_METHOD =
  'pdp.alert.availability_delivery_method';
export const ALERT_AVAILABILITY_STORE = 'pdp.alert.availability_store';
export const ALERT_SUGGESTED_DATE = 'pdp.alert.suggested_date';
export const ALERT_SUGGESTED_PRODUCT = 'pdp.alert.suggested_product';
export const OWN_DESSERT_BOARD_BAKED = 'pdp.alert.dessert_board_baked';
export const OWN_DESSERT_BOARD_DIPPED_BERRIES =
  'pdp.alert.dessert_board_dipped_berries';
export const OWN_DESSERT_BOARD_DIPPED_FRUIT =
  'pdp.alert.dessert_board_dipped_fruit';
export const ALERT_DESSERT_OWN_BOX_ADDONS = 'pdp.alert.dessert_own_box_addons';
export const ALERT_6_COOKIE_BOX_ADDONS = 'pdp.alert.6_cookie_box_addons';
export const ALERT_12_COOKIE_BOX_ADDONS = 'pdp.alert.12_cookie_box_addons';
export const TITLE = 'pdp.alert.title';
