export const SA_INVALID_TITLE = 'sa.invalid.title';
export const SA_INVALID_CONTENT = 'sa.invalid.content';
export const SA_INVALID_CONFIRM = 'sa.invalid.confirm';
export const SA_INVALID_REJECT = 'sa.invalid.reject';
export const SA_UNKNOWN_CONFIRM = 'sa.unknown.confirm';
export const SA_UNKNOWN_CONTENT = 'sa.unknown.content';
export const SA_KEEP_ADDRESS = 'sa.unknown.keep_address';
export const SA_ORIGINAL_ADDRESS_DISCLAIMER = 'sa.unknown.address_disclaimer';
export const SA_UNKNOWN_TITLE = 'sa.unknown.title';
export const SA_SHIPMENT_CONFIRM = 'sa.shipment.confirm';
export const SA_SHIPMENT_REJECT = 'sa.shipment.reject';
export const SA_ORIGINAL_ADDRESS = 'sa.original_address';
export const SA_SUGGESTED_ADDRESS = 'sa.suggested_address';
export const SA_SHIPMENT_CONTENT = 'sa.shipment.content';
export const SA_SHIPMENT_TITLE = 'sa.shipment.title';
