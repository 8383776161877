import { ALL_ARRANGEMENTS_FILTER_VALUE } from 'api/arrangements/arrangement.api.service';
import { DEFAULT_FILTER } from 'api/filters-api.service';
import {
  FILTER_QUERY_KEY,
  FRUIT_QUERY_KEY,
} from 'containers/PLPContainer/query.constants';
import { Filter, FruitFilter } from 'api/filter-categories/types';
import { adaptSingleToCategoryFilterItem } from 'utils/adapters/single-to-category-filter-item.adapter';
import {
  getFilters,
  getFruitFilters,
} from 'components/PLP/FilterCategories/feature/selectors';
import { isEmpty } from 'utils/array/size';
import { selectFilter } from 'redux/PLP/filter/selectors';
import { useQueryParameters } from 'hooks/use-query-parameter';
import { useSelector } from 'react-redux';

interface Filters {
  filters: Filter[];
  fruitFilters: FruitFilter[];
}
export const usePlpFilters = (): Filters => {
  const parameters = useQueryParameters(FILTER_QUERY_KEY);
  const fruitParameters = useQueryParameters(FRUIT_QUERY_KEY);
  const pageFilter = useSelector(selectFilter);
  const fetchedFilters = useSelector(getFilters);
  const fetchedFruitFilters = useSelector(getFruitFilters);

  if (isEmpty(parameters) && isEmpty(fruitParameters)) {
    return {
      filters: pageFilter ? [adaptSingleToCategoryFilterItem(pageFilter)] : [],
      fruitFilters: [],
    };
  }

  const filters = fetchedFilters.filter(({ Value }) =>
    parameters.includes(Value),
  );
  const fruitFilters = fetchedFruitFilters.filter(({ ProductGroupID }) =>
    fruitParameters.includes(ProductGroupID.toString()),
  );

  if (
    parameters.length === 1 &&
    parameters[0] === ALL_ARRANGEMENTS_FILTER_VALUE
  ) {
    return { filters: [DEFAULT_FILTER], fruitFilters };
  }

  if (
    pageFilter?.value &&
    parameters.includes(pageFilter.value) &&
    !fetchedFilters.find(({ Value }) => Value === pageFilter.value)
  ) {
    return {
      filters: [...filters],
      fruitFilters,
    };
  }
  return { filters, fruitFilters };
};
