import { SEARCH_QUERY_KEY } from 'containers/PLPContainer/query.constants';
import { isHomePath } from 'containers/MainContainer/router.helper';

export function shouldUseReferrer(referrer: string, origin: string): boolean {
  return Boolean(referrer) && referrer.startsWith(origin);
}

export function getUrlAlias(path: string): string | null {
  const url = new URL(path);

  if (url.searchParams.get(SEARCH_QUERY_KEY) !== null) {
    return 'Search Results';
  }
  if (isHomePath(url.pathname)) {
    return 'Home';
  }

  return null;
}
