import * as styles from 'components/GiftFinder/Form/styles';
import { BaseExpandedSelector } from 'components/GiftFinder/Form/base-expanded-selector';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Filter, FilterCategory } from 'api/filter-categories/types';
import { ReasonChip } from 'components/GiftFinder/chips/reason-chip';
import React, { FC } from 'react';

interface ExpandedReasonSelectorProps {
  setFilterData: (filterData: Filter[]) => void;
  selectedFilterData: Filter[];
  category: FilterCategory[];
}

export const ExpandedReasonSelector: FC<ExpandedReasonSelectorProps> = (
  props,
) => {
  const occasionList = props?.category[0]?.Items ?? [];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSelectOccasion = (occasion: Filter) => {
    const newArray = props.selectedFilterData.includes(occasion)
      ? props.selectedFilterData.filter((item) => item.ID !== occasion.ID)
      : [...props.selectedFilterData, occasion];
    props.setFilterData(newArray);
  };
  return (
    <BaseExpandedSelector
      label="Reason"
      subHeading="For someone or an event."
      imageSrc={props?.category[0]?.FilterCategoryImageURL}
      isSelected={props.selectedFilterData.length > 0}
    >
      <Box
        display="flex"
        gap={1}
        flexWrap="wrap"
        sx={styles.expandedReasonSelectorContainer(isMobile)}
      >
        {occasionList.map((occasion) => (
          <ReasonChip
            key={occasion.ID}
            label={occasion.Name}
            isSelected={props.selectedFilterData.some(
              (item) => item.ID === occasion.ID,
            )}
            imageSrc={occasion.FilterImageURL ?? ''}
            onClick={() => onSelectOccasion(occasion)}
          />
        ))}
      </Box>
    </BaseExpandedSelector>
  );
};
