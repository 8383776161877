import { BreadcrumbBack } from 'components/BreadcrumbBack/BreadcrumbBack';
import { Collapse } from '@mui/material';
import { checkIsBlocked } from 'components/PDP/GoBack/feature/selectors';
import { useBreadcrumbProps } from 'components/BreadcrumbBack/hooks/use-breadcrumb-props';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const NavigateBack: FC = () => {
  const isBlocked = useSelector(checkIsBlocked);
  const { visible, linkProps, title } = useBreadcrumbProps(isBlocked);

  return (
    <Collapse in={visible}>
      {visible && (
        <BreadcrumbBack sx={{ mt: 2.5 }} {...linkProps}>
          {title}
        </BreadcrumbBack>
      )}
    </Collapse>
  );
};
