import * as styles from 'components/Header/HeaderNavigation/styles';
import { Box, Skeleton } from '@mui/material';
import { range } from 'utils/range';
import React, { FC } from 'react';

export const MenuSkeleton: FC = () => (
  <Box sx={styles.skeleton}>
    {range(8).map((item) => (
      <Box key={item} sx={styles.skeletonItem}>
        <Skeleton />
      </Box>
    ))}
  </Box>
);
