import { DateSelector } from 'components/PDP/Details/components/PasControl/components/DateSelection/enums/date-selector.enum';
import {
  TODAY,
  TOMORROW,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/constants';
import { isDefined } from 'utils/is-defined';

export const getDateSelector = (date: Date | null): DateSelector | null => {
  if (isDefined(date)) {
    const selectedDay = new Date(date).toDateString();
    if (selectedDay === TODAY.toDateString()) {
      return DateSelector.TODAY;
    }
    if (selectedDay === TOMORROW.toDateString()) {
      return DateSelector.TOMORROW;
    }
    return DateSelector.DATE_PICKER;
  }

  return null;
};
