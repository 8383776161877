import { SxProps } from '@mui/system';

export const authButtons: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
};

export const signOutButton: SxProps = {
  display: 'flex',
  alignItems: 'flex-end',
};

export const signOutIcon: SxProps = {
  mr: 1.2,
};

export const divider: SxProps = {
  '&.MuiDivider-root.MuiDivider-fullWidth': {
    my: 0,
  },
};

export const userName: SxProps = {
  fontWeight: 600,
  maxWidth: '105px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
