import { SxProps } from '@mui/material';
import { colors } from 'utils/theme/colors';
import { theme } from 'utils/theme/theme';

export const getImageSx = ({ disabled }: { disabled?: boolean }): SxProps => ({
  width: 64,
  height: 64,
  borderRadius: 8,
  mb: 0.5,
  mr: 1.5,
  [theme.breakpoints.up('sm')]: {
    mr: 0,
  },
  [theme.breakpoints.up('xs')]: {
    mr: 0,
  },
  ...(disabled && {
    opacity: 0.3,
  }),
});

export const limitedOnlySx = ({
  disabled,
}: {
  disabled?: boolean;
}): SxProps => ({
  background: colors.primaryRed,
  color: colors.white,
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '164%',
  display: 'flex',
  height: '18px',
  padding: '1px 6px',
  [theme.breakpoints.down('sm')]: {
    width: '105px',
  },
  ...(disabled && {
    background: '#0000001F',
    color: '#888888',
  }),
});
