import { Box } from '@mui/material';
import React, { FC, Suspense, lazy } from 'react';

const LazyChat = lazy(() =>
  import('components/common/Chat/ChatInBody').then((module) => ({
    default: module.ChatInBody,
  })),
);

export const FakeChat: FC = () => (
  <Suspense fallback={<Box position="absolute" top={0} left={0} />}>
    <LazyChat />
  </Suspense>
);
