import * as styles from 'components/About/styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { SubTitleItem } from 'api/about/type/about';
import React, { FC } from 'react';

interface Props {
  subTitleData: SubTitleItem[];
}

export const TermsAndConditionSubTitle: FC<Props> = ({ subTitleData }) => {
  return (
    <>
      <Box display="flex" sx={styles.subTitle}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="subtitle">
            <TableBody>
              {subTitleData.map((row) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row">
                    <Typography>{row.Name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.Value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
