import { AxiosError } from 'axios';
import { OrderType } from 'types/cart.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { recipientAvailabilityApiService } from 'api/availability/recipient-availability.api.service';

export const retrieveRecipientAvailability = createAsyncThunk(
  'recipient/availability',
  async (
    params: {
      ids: number[];
      storeId: number;
      areaId: number;
      orderType: OrderType;
      fulfillmentDate: Date;
      recipientId?: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const results = await recipientAvailabilityApiService.fetch(params);
      return [
        {
          isAvailable: results[0].isAvailable,
          recipientId: params.recipientId,
        },
      ];
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);
