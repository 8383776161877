import * as TK from 'containers/ContactUsContainer/translations/locale.constants';

export const contactUsCommonLocale = {
  [TK.CONTACT_US_TEXT]: 'contact us',
  [TK.CONTACT_US_SUCCESS_TEXT]: 'submission confirmed',
  [TK.CONTACT_US_ORDER_NUMBER_NOT_VALID_ERROR_TEXT]:
    'Enter a valid order number',
  [TK.CONTACT_US_SAVE_ERROR]:
    'Something went wrong while saving case. Please try again in few moments',
  [TK.CONTACT_US_FORM_LABEL_REASON]: 'What Can We Help You With?*',
  [TK.CONTACT_US_FORM_LABEL_REFUND_REASON]: 'Refund/Cancel request reason',
  [TK.CONTACT_US_FORM_LABEL_ORDER]: 'Order Number',
  [TK.CONTACT_US_FORM_LABEL_FIRST_NAME]: 'First Name*',
  [TK.CONTACT_US_FORM_LABEL_LAST_NAME]: 'Last Name*',
  [TK.CONTACT_US_FORM_LABEL_EMAIL]: 'Email Address*',
  [TK.CONTACT_US_FORM_LABEL_PHONE]: 'Phone Number*',
  [TK.CONTACT_US_FORM_LABEL_MESSAGE]: 'Message*',
  [TK.CONTACT_US_FORM_LABEL_BEST_TIME_TO_CALL]: 'Best Time To Call',
  [TK.CONTACT_US_FORM_LABEL_PROVIDE_INFORMATION]:
    'So we can best help you, type your message here and provide as much information as possible.',
  [TK.CONTACT_US_GO_TO_FAQ_PAGE]: 'Go To FAQs',
  [TK.CONTACT_US_SEND_REQUEST_BUTTON_TEXT]: 'Submit',
  [TK.CONTACT_US_EMAIL_NOT_VALID_ERROR_TEXT]: 'Enter a valid email address',
  [TK.CONTACT_US_PHONE_NUMBER_NOT_VALID_ERROR_TEXT]:
    'Enter a valid phone number',
  [TK.CONTACT_US_FORM_LABEL_HEADER_TEXT_LINE_01]:
    'We work hard to make WOW moments possible for every guest - that’s you!',
  [TK.CONTACT_US_FORM_LABEL_HEADER_TEXT_LINE_02]:
    'If there’s an issue with your order or you want to provide feedback, complete the form below and we will respond as soon as possible.',
  [TK.CONTACT_US_FORM_LABEL_HEADER_TEXT_REQUIRED]: '* This field is required',
  [TK.CONTACT_US_FORM_LABEL_USER_TYPE_TEXT]:
    'Are You The Customer Or Recipient?*',
  [TK.CONTACT_US_FIRST_NAME_SPL_CHARS_ERROR_TEXT]: 'Enter a valid first name',
  [TK.CONTACT_US_LAST_NAME_SPL_CHARS_ERROR_TEXT]: 'Enter a valid last name',
  [TK.CONTACT_US_MSG_SPL_CHARS_ERROR_TEXT]: 'Characters (<>) are not allowed',
  [TK.CONTACT_US_REMOVE_PREFIX]: 'Refund/Cancel- ',
  [TK.BACKTEXT]: 'Back',
  [TK.CONTINUESHOPPING]: 'Continue Shopping',
  [TK.CONTACT_US_MESSAGE_ERROR_TEXT]: 'A message is required',
  [TK.CHOOSE_OPTION]: 'Please Choose',
  [TK.CATEGORY_ERROR_TEXT]: 'Select Reason For Contacting Us',
  [TK.CATEGORY_TYPE_ERROR_TEXT]: 'Select Reason type',
  [TK.REQUEST_BY_ERROR_TEXT]: 'Select Requested by',
};
