import { Box, Skeleton } from '@mui/material';
import { skeletonImage } from 'components/PDP/Details/components/ProductOptions/components/ProductOption/product-option.styles';
import React, { FC } from 'react';

export const ProductOptionSkeleton: FC = () => (
  <Box display="flex" width={1}>
    <Skeleton sx={skeletonImage} />
    <Box width={1} my="auto">
      <Skeleton height={20} sx={{ mb: 0.5 }} />
      <Skeleton width={56} height={20} />
    </Box>
  </Box>
);
