import * as TK from 'components/Error/translations/constants';
import * as styles from 'components/Error/OrderConfirmationError/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { Links } from 'enums/common-links';
import { Shaky } from 'components/Error/OrderConfirmationError/styles';
import { appInsights } from 'utils/telemetry/app-insights';
import { confusedStrawberry } from 'utils/cloudinaryAssets';
import React, { useEffect } from 'react';
import i18next from 'i18next';

export function ProductDisplayPageError(props: FallbackProps): JSX.Element {
  useEffect(() => {
    appInsights.trackEvent({ name: 'Product Detail Page Error' }, props.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Shaky>
        <img
          src={confusedStrawberry}
          width={200}
          height={200}
          alt={i18next.t(TK.ERROR_STRAWBERRY_MASCOT)}
        />
      </Shaky>
      <Typography variant="h2">
        {i18next.t(TK.PRODUCT_DISPLAY_PAGE_ERROR_TITLE)}
      </Typography>
      <Typography variant="body2">
        {i18next.t(TK.PRODUCT_DISPLAY_PAGE_ERROR_SUMMARY)}
      </Typography>
      <Box display="flex" sx={{ gap: '30px' }}>
        <Link href={Links.FruitArrangements}>
          <Button variant="outlined">
            {i18next.t(TK.PRODUCT_DISPLAY_PAGE_ERROR_SHOP_ALL)}
          </Button>
        </Link>
        <Button variant="contained" onClick={props.resetErrorBoundary}>
          {i18next.t(TK.PRODUCT_DISPLAY_PAGE_ERROR_RELOAD)}
        </Button>
      </Box>
    </Box>
  );
}
