import { fetchProductsAvailability } from 'components/PDP/Details/components/ProductOptions/feature/actions';
import { isNull } from 'utils/null.utils';
import { selectProductsIds } from 'redux/PDP/arrangement/arrangement.selectors';
import { selectSessionDate } from 'redux/session/availability/availability.selectors';
import { selectShouldCheckAvailability } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useFetchProductsAvailability(): void {
  const dispatch = useDispatch();

  const optionsIds = useSelector(selectProductsIds);
  const shouldCheckAvailability = useSelector(selectShouldCheckAvailability);
  const sessionState = useSelector((state) => state.availabilitySession);
  const date = useSelector(selectSessionDate);

  useEffect(() => {
    if (!shouldCheckAvailability || isNull(date)) {
      return;
    }

    dispatch(
      fetchProductsAvailability({
        ids: optionsIds,
        areaId: sessionState.data.areaId,
        storeId: sessionState.data.storeId,
        fulfillmentDate: date,
        orderType: sessionState.data.serviceOption,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldCheckAvailability]);
}
