export const SUBSCRIPTION_TERMS_OF_USE = 'subscription.terms_of_use';
export const SUBSCRIPTION_FOOTER_DIALOG_TITLE =
  'subscription.footer_dialog.title';
export const SUBSCRIPTION_FOOTER_DIALOG_SUBTITLE =
  'subscription.footer_dialog.subtitle';
export const SUBSCRIPTION_FOOTER_DIALOG_INFORM_EMAIL =
  'subscription.footer_dialog.inform_email';
export const SUBSCRIPTION_FOOTER_DIALOG_INFORM_TEXT =
  'subscription.footer_dialog.inform_text';
export const SUBSCRIPTION_FOOTER_DIALOG_SOCIAL_LINKS_TITLE =
  'subscription.footer_dialog.social_links_title';
export const SUBSCRIPTION_SHOP_NOW = 'subscription.shop_now';
export const SUBSCRIPTION_EMAIL_DIALOG_FORM_STEP_BACKGROUND_ALT =
  'subscription.email_dialog.form_step.background_alt';
export const SUBSCRIPTION_EMAIL_DIALOG_SUBMIT_SUCCESS_STEP_BACKGROUND_ALT =
  'subscription.email_dialog.submit_success_step.background_alt';
export const SUBSCRIPTION_FACEBOOK = 'subscription.facebook';
export const SUBSCRIPTION_TWITTER = 'subscription.twitter';
export const SUBSCRIPTION_INSTAGRAM = 'subscription.instagram';
export const SUBSCRIPTION_LINKEDIN = 'subscription.linkedin';
export const SUBSCRIPTION_YOUTUBE = 'subscription.youtube';
export const SUBSCRIPTION_EMAIL_FORM_ERROR = 'subscription.email_form.error';
export const SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER =
  'subscription.email_form.placeholder';
export const SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL =
  'subscription.email_form.button_label';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE =
  'subscription.terms_and_conditions.before';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND =
  'subscription.terms_and_conditions.between_and';
export const SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS =
  'subscription.email_form.terms_and_conditions';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER =
  'subscription.terms_and_conditions.after';
export const NEW_SUBSCRIPTION_FIRST_POPUP_TITLE =
  'subscription.new_first_popup.title';
export const NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT =
  'subscription.new_first_popup.join_email.text';
export const NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT =
  'subscription.new_signup_first_popup.join_email_sms.text';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT =
  'subscription.new_first_popup.instant_savings.text';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT =
  'subscription.new_first_popup.instant.text';
export const NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT =
  'subscription.new_first_popup.savings.text';
export const NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT =
  'subscription.new_first_popup.pickup_order.text';

export const SUBSCRIPTION_EMAIL_FORM_LABEL = 'subscription.email_form.label';
export const SUBSCRIPTION_FIRST_POPUP_TITLE = 'subscription.first_popup.title';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_VAL =
  'subscription.first_popup.offer.val';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_PER =
  'subscription.first_popup.offer.per';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT =
  'subscription.first_popup.offer.text';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_STAR =
  'subscription.first_popup.offer.star';
export const SUBSCRIPTION_FIRST_POPUP_DESCRIPTION =
  'subscription.first_popup.description';
export const SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE =
  'subscription.success_popup.title_new';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST =
  'subscription.success_popup.description_first';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST =
  'subscription.success_popup.description_last';
export const SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE =
  'subscription.success_popup.social_link_title';
export const SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT =
  'subscription.success_popup.gift.text';
export const SUBSCRIPTION_IMAGE_TITLE = 'subscription.image.title';
export const ERROR_PHONE = 'subscription.phone.error';
export const REQUIRED_EMAIL = 'subscription.email.required.error';
export const ERROR_EMAIL = 'subscription.email.error';
export const ERROR_AGREE = 'subscription.agree.error';
