import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/Careers/styles';
import { Box } from '@mui/system';
import {
  CarouselContainer,
  CarouselPropertiesWrapper,
} from 'components/About/Careers/components/styles';
import { Image } from 'components/common/Image/Image';
import { Typography } from '@mui/material';
import {
  careerPropertiesPeople,
  careerPropertiesPlaces,
  careerPropertiesProducts,
  careerPropertiesPromise,
  careerPropertiesPurpose,
} from 'utils/cloudinaryAssets';
import { settings } from 'components/About/Careers/components/PropertiesSetting';
import React, { FC } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

export const Properties: FC = () => {
  return (
    <Box sx={styles.careerProperties}>
      <Box display="flex" flexDirection="row" textAlign="center" mb={2.5}>
        <Typography sx={styles.propertiesTextStyle} variant="h2">
          {i18next.t(TK.CAREER_PROPERTIES_TITLE)}
        </Typography>
        <Typography variant="body1" fontSize="16px" color="#E93B3B">
          {i18next.t(TK.CAREER_PROPERTIES_SUB_TITLE)}
        </Typography>
      </Box>
      <CarouselPropertiesWrapper data-test="carousel-detailed-container">
        <CarouselContainer data-test="carousel-detailed">
          <Box>
            <Slider {...settings}>
              <Box sx={styles.careerPropertiesItems}>
                <Box padding="0 30px" mb={[3, 0, 0]}>
                  <Box sx={styles.careerPropertiesItemsSerial}>
                    <Typography variant="h3" lineHeight="31px">
                      1
                    </Typography>
                  </Box>
                  <Typography
                    sx={styles.careerPropertiesItemsTitle}
                    variant="h2"
                  >
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_TITLE1)}
                  </Typography>
                  <Typography fontSize="16px" color="#111" mb={[1, 0, 0]}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_SLOGAN1)}
                  </Typography>
                  <Typography sx={styles.careerPropertiesItemsDesc}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_DESC1)}
                  </Typography>
                </Box>
                <Image
                  src={careerPropertiesPromise}
                  sx={styles.propertiesImage}
                />
              </Box>
              <Box sx={styles.careerPropertiesItems}>
                <Box padding="0 30px" mb={[3, 0, 0]}>
                  <Box sx={styles.careerPropertiesItemsSerial}>
                    <Typography variant="h3" lineHeight="31px">
                      2
                    </Typography>
                  </Box>
                  <Typography
                    sx={styles.careerPropertiesItemsTitle}
                    variant="h2"
                  >
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_TITLE2)}
                  </Typography>
                  <Typography fontSize="16px" color="#111" mb={[1, 0, 0]}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_SLOGAN2)}
                  </Typography>
                  <Typography sx={styles.careerPropertiesItemsDesc}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_DESC2)}
                  </Typography>
                </Box>
                <Image
                  src={careerPropertiesPeople}
                  sx={styles.propertiesImage}
                />
              </Box>
              <Box sx={styles.careerPropertiesItems}>
                <Box padding="0 30px" mb={[3, 0, 0]}>
                  <Box sx={styles.careerPropertiesItemsSerial}>
                    <Typography variant="h3" lineHeight="31px">
                      3
                    </Typography>
                  </Box>

                  <Typography
                    sx={styles.careerPropertiesItemsTitle}
                    variant="h2"
                  >
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_TITLE3)}
                  </Typography>
                  <Typography fontSize="16px" color="#111" mb={[1, 0, 0]}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_SLOGAN3)}
                  </Typography>
                  <Typography sx={styles.careerPropertiesItemsDesc}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_DESC3)}
                  </Typography>
                </Box>
                <Image
                  src={careerPropertiesProducts}
                  sx={styles.propertiesImage}
                />
              </Box>
              <Box sx={styles.careerPropertiesItems}>
                <Box padding="0 30px" mb={[3, 0, 0]}>
                  <Box sx={styles.careerPropertiesItemsSerial}>
                    <Typography variant="h3" lineHeight="31px">
                      4
                    </Typography>
                  </Box>

                  <Typography
                    sx={styles.careerPropertiesItemsTitle}
                    variant="h2"
                  >
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_TITLE4)}
                  </Typography>
                  <Typography fontSize="16px" color="#111" mb={[1, 0, 0]}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_SLOGAN4)}
                  </Typography>
                  <Typography sx={styles.careerPropertiesItemsDesc}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_DESC4)}
                  </Typography>
                </Box>
                <Image
                  src={careerPropertiesPlaces}
                  sx={styles.propertiesImage}
                />
              </Box>
              <Box sx={styles.careerPropertiesItems}>
                <Box padding="0 30px" mb={[3, 0, 0]}>
                  <Box sx={styles.careerPropertiesItemsSerial}>
                    <Typography variant="h3" lineHeight="31px">
                      5
                    </Typography>
                  </Box>

                  <Typography
                    sx={styles.careerPropertiesItemsTitle}
                    variant="h2"
                  >
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_TITLE5)}
                  </Typography>
                  <Typography fontSize="16px" color="#111" mb={[1, 0, 0]}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_SLOGAN5)}
                  </Typography>
                  <Typography sx={styles.careerPropertiesItemsDesc}>
                    {i18next.t(TK.CAREER_PROPERTIES_ITEM_DESC5)}
                  </Typography>
                </Box>
                <Image
                  src={careerPropertiesPurpose}
                  sx={styles.propertiesImage}
                />
              </Box>
            </Slider>
          </Box>
        </CarouselContainer>
      </CarouselPropertiesWrapper>
    </Box>
  );
};
