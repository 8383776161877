import * as TK from 'components/PDP/Details/components/ProductCode/translations/product-code.locale.constants';
import { Skeleton, Typography } from '@mui/material';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  code: number;
  loading?: boolean;
}

export const ProductCode: FC<Props> = ({ code, loading }) => {
  if (loading) {
    return <Skeleton width={151} height={20} />;
  }

  return (
    <Typography variant="body2" data-test="pdp-details-code">
      {i18next.t(TK.PRODUCT_CODE)}: {code}
    </Typography>
  );
};
