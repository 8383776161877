export const marks = [
  { value: 0, label: '0', price: 0 },
  { value: 1, label: '<$30', price: 29 },
  { value: 2, label: '$50', price: 49 },
  { value: 3, label: '$75', price: 74 },
  { value: 4, label: '$100', price: 99 },
  { value: 5, label: '$200', price: 199 },
  { value: 6, label: '$500+', price: 500 },
];

export const valuetext = (value: number): string => {
  return `$${value}`;
};
