import * as styles from 'components/Home/CarouselDetailed/components/Skeleton/styles';
import { CarouselItemImageSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton/CarouselItemImageSkeleton';
import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

export const CarouselItemSkeleton: FC = () => (
  <div>
    <CarouselItemImageSkeleton />
    <Skeleton sx={styles.name} variant="rectangular" />
    <Skeleton sx={styles.sizeCount} variant="rectangular" />
    <Skeleton sx={styles.price} variant="rectangular" />
    <Skeleton sx={styles.link} variant="rectangular" />
  </div>
);
