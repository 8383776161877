import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AvailabilitySessionParams } from 'redux/session/availability/availability.types';
import { DEFAULT_MOBILE_AVAILABILITY } from 'api/legacy-session/defaults';
import { LegacySessionService } from 'api/legacy-session/interfaces';
import {
  MobileUpdateAvailabilityParams,
  MobileUpdateAvailabilityPayload,
} from 'api/legacy-session/types';
import { formatToLegacyDateString } from 'utils/date';

class MobileLegacySessionApiService
  extends ApiLegacyBaseService
  implements LegacySessionService
{
  private buildUrl(path: string): string {
    return `/SmartDiv.aspx/${path}`;
  }

  private getUpdatePayload = ({
    areaId,
    areaName,
    selectedDate,
    serviceOption,
    storeId,
    productId,
    arrangementSizeID,
    arrangementGroupId,
    arrangementId,
    quantity,
    stateCode,
    currentRecipientId,
  }: AvailabilitySessionParams): MobileUpdateAvailabilityPayload => {
    const {
      SelectedDate: defaultSelectedDate,
      StoreID: defaultStoreId,
      RecipientProductDetails: defaultRecipientProductDetails,
      StateCode: defaultStateCode,
      CurrentRecipientID: defaultCurrentRecipientId,
    } = DEFAULT_MOBILE_AVAILABILITY;

    const body: MobileUpdateAvailabilityParams = {
      ...DEFAULT_MOBILE_AVAILABILITY,
      AreaID: areaId,
      AreaName: areaName,
      SelectedDate: selectedDate
        ? formatToLegacyDateString(selectedDate)
        : defaultSelectedDate,
      ServiceOption: serviceOption,
      StoreID: storeId || defaultStoreId,
      StateCode: stateCode || defaultStateCode,
      CurrentRecipientID: currentRecipientId || defaultCurrentRecipientId,
      RecipientProductDetails: {
        ...defaultRecipientProductDetails,
        ProductID: productId || defaultRecipientProductDetails.ProductID,
        ArrangementSizeID:
          arrangementSizeID || defaultRecipientProductDetails.ArrangementSizeID,
        ArrangementGroupID:
          arrangementGroupId ||
          defaultRecipientProductDetails.ArrangementGroupID,
        ArrangementID:
          arrangementId || defaultRecipientProductDetails.ArrangementID,
        Quantity: quantity || defaultRecipientProductDetails.Quantity,
      },
    };

    return { objASS: JSON.stringify(body) };
  };

  async reset(): Promise<void> {
    await this.apibase.post(this.buildUrl('clearSessionState'), null);
  }

  async update(params: AvailabilitySessionParams): Promise<void> {
    await this.apibase.post(
      this.buildUrl('setSessionState'),
      this.getUpdatePayload(params),
    );
  }
}

export { MobileLegacySessionApiService };
