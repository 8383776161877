import * as TK from 'translations/locales/translation-keys.constant';
import { Area } from 'api/area/types/area.interface';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Collapse, Grid } from '@mui/material';
import { Calendar } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/Calendar';
import {
  DAY_TOGGLE_FORMAT,
  PREVIEW_FORMAT,
  TODAY,
  TOMORROW,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/constants';
import { DateSelector } from 'components/PDP/Details/components/PasControl/components/DateSelection/enums/date-selector.enum';
import { NotAvailableAlert } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/NotAvailableAlert/NotAvailableAlert';
import { StepContent } from 'components/PDP/Details/components/PasControl/components/Stepper/styled';
import { StepSummary } from 'components/PDP/Details/components/PasControl/components/Stepper/StepSummary';
import { ToggleButton } from 'components/common/ToggleButton/ToggleButton';
import { format } from 'date-fns';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { getAvailabilityOnDate } from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/actions';
import { getDateSelector } from 'components/PDP/Details/components/PasControl/components/DateSelection/utils/get-date-selector';
import { isDefined } from 'utils/is-defined';
import { isNull } from 'utils/null.utils';
import { selectArrangementFloralPartyBoxBrand } from 'redux/PDP/arrangement/arrangement.selectors';
import {
  selectIsDateAvailabilityFulfilled,
  selectIsNotAvailable,
  selectIsPending,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import { selectIsStoreOrRecipientLock } from 'redux/session/availability/availability.selectors';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeIcon from '@mui/icons-material/DateRange';
import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  disabled?: boolean;
  defaultValue: Date | null;
  onSelect: (date: Date | null) => void;
  area: Area | null;
  arrangementId: number;
  setActiveStepperStep: (d: number) => void;
  isStepClickable: boolean;
  dateRef?: MutableRefObject<HTMLInputElement | null>;
}

export const DateSelection: FC<Props> = ({
  disabled,
  defaultValue,
  onSelect,
  arrangementId,
  area,
  setActiveStepperStep,
  isStepClickable,
  dateRef,
}) => {
  const dispatch = useDispatch();

  const isFulfilled = useSelector(selectIsDateAvailabilityFulfilled);
  const isLoading = useSelector(selectIsPending);
  const isNotAvailable = useSelector(selectIsNotAvailable);
  const isStoreOrRecipientLock = useSelector(selectIsStoreOrRecipientLock);
  const isFloralPartyBoxBrand = useSelector(
    selectArrangementFloralPartyBoxBrand,
  );

  const [date, setDate] = useState<Date | null>(defaultValue);
  const [defaultDateGuard, setDefaultDateGuard] = useState<string>('');
  const [selector, setSelector] = useState<DateSelector | null>(
    getDateSelector(defaultValue),
  );
  const [isDatePickerOpened, setDatePickerOpened] = useState(false);
  const dateGuard = date ? formatSQLDate(date) : '';
  const getDatePreview = (dateValue: Date | null): string | undefined =>
    dateValue ? format(dateValue, PREVIEW_FORMAT) : undefined;

  const resetSelectedDate = (): void => {
    setDate(null);
    setDefaultDateGuard('');
    setSelector(null);
  };

  useEffect(() => {
    if (defaultValue && defaultDateGuard !== formatSQLDate(defaultValue)) {
      setDefaultDateGuard(formatSQLDate(defaultValue));
    }
    if (isNull(defaultValue)) {
      setDefaultDateGuard('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (!defaultValue && date && defaultDateGuard) {
      resetSelectedDate();
    }
  }, [defaultValue, date, defaultDateGuard]);

  useEffect(() => {
    if (isNull(defaultValue) && isDefined(area?.name)) {
      resetSelectedDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area?.name]);

  useEffect(() => {
    setSelector(getDateSelector(defaultValue));
    setDate(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDateGuard]);

  useEffect(() => {
    if (selector === null) {
      setDate(null);
      setDatePickerOpened(false);
      return;
    }

    const toggledDate = {
      [DateSelector.TODAY]: TODAY,
      [DateSelector.TOMORROW]: TOMORROW,
      [DateSelector.DATE_PICKER]: null,
    }[selector];
    setDate(toggledDate ?? date);
    setDatePickerOpened(toggledDate === null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, setDate]);

  useEffect(() => {
    if (date && area && !isStoreOrRecipientLock) {
      dispatch(
        getAvailabilityOnDate({
          areaId: area.id,
          arrangementId,
          date,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateGuard, dispatch]);

  useEffect(() => {
    const shouldCallOnSelect = isFulfilled && !isNotAvailable && date;

    if (shouldCallOnSelect) {
      onSelect(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFulfilled, isNotAvailable]);

  return (
    <>
      <Backdrop opened={isLoading} />
      <StepSummary
        availabilityStep={AvailabilityStep.DATE_SELECTION}
        setActiveStep={setActiveStepperStep}
        clickable={isStepClickable}
        preview={getDatePreview(date)}
      >
        {i18next.t(TK.WHEN)}
      </StepSummary>
      <StepContent>
        <Grid
          data-test="pdp-pas-date-content"
          container
          role="radio"
          columnSpacing={1.5}
          rowSpacing={2}
          ref={dateRef}
        >
          {!isFloralPartyBoxBrand && (
            <>
              <Grid item>
                <ToggleButton
                  value={TK.TODAY}
                  selected={selector === DateSelector.TODAY}
                  onChange={() => setSelector(DateSelector.TODAY)}
                  disabled={disabled}
                  data-test="pdp-pas-date-today-button"
                >
                  {i18next.t(TK.TODAY, {
                    date: format(TODAY, DAY_TOGGLE_FORMAT),
                  })}
                </ToggleButton>
              </Grid>
              <Grid item>
                <ToggleButton
                  value={TK.TOMORROW}
                  selected={selector === DateSelector.TOMORROW}
                  onChange={() => setSelector(DateSelector.TOMORROW)}
                  disabled={disabled}
                  data-test="pdp-pas-date-tomorrow-button"
                >
                  {i18next.t(TK.TOMORROW, {
                    date: format(TOMORROW, DAY_TOGGLE_FORMAT),
                  })}
                </ToggleButton>
              </Grid>
            </>
          )}
          <Grid item>
            <ToggleButton
              value={TK.DATE_PICKER}
              selected={selector === DateSelector.DATE_PICKER}
              onChange={() => setSelector(DateSelector.DATE_PICKER)}
              LeftIcon={DateRangeIcon}
              data-test="pdp-pas-date-picker-button"
            >
              {i18next.t(TK.DATE_PICKER)}
            </ToggleButton>
          </Grid>
        </Grid>
        <Collapse in={isDatePickerOpened}>
          <div data-test="pdp-pas-date-picker">
            <Calendar date={date} setDate={setDate} />
          </div>
        </Collapse>
        {area && date && (
          <Collapse in={isFulfilled && isNotAvailable}>
            <Box mt={2}>
              <NotAvailableAlert area={area} date={date} />
            </Box>
          </Collapse>
        )}
      </StepContent>
    </>
  );
};
