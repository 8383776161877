import { BVSocialContent } from 'components/Home/BVSocial/types';
import { ComponentProps } from 'components/Home/types';
import { Gallery } from 'lib/curalate/components/Gallery';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { WidgetWrapper } from 'components/Home/common/styled';
import { getComponent } from 'components/Home/feature/selectors';
import { getId } from 'components/Home/utils/get-html-id';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const BVSocial: FC<ComponentProps> = ({ type, id, sequence }) => {
  const componentData = useSelector(getComponent(type, id));
  const content = useResponsiveContent<BVSocialContent>(componentData, 'md');

  if (!content.available) {
    return null;
  }

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <WidgetWrapper id={getId(type, id)} data-test="bv-social-container">
        <Gallery sx={{ pt: 5, pb: 10 }} />
      </WidgetWrapper>
    </RenderWhenInView>
  );
};
