import { checkIsInit } from 'components/PLP/ArrangementsList/feature/selectors';
import { fetchArrangements } from 'components/PLP/ArrangementsList/feature/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useFetchOrderConfirmationArrangements = (
  filterIds: string[] | number[],
): void => {
  const dispatch = useDispatch();
  const fetchArrangementStatus = useSelector(checkIsInit);

  useEffect(() => {
    if (fetchArrangementStatus) {
      dispatch(fetchArrangements({ filterIds }));
    }
  }, []);
};
