import { CartProductForAnalytics } from 'api/cart/types/cart-item.type';
import { CartStoreNumberSetting } from 'types/cart.types';
import { EventLocation } from 'service/adobe-analytics/enums';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { RootState } from 'redux/store';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { analytics } from 'service/adobe-analytics/analytics';
import { appInsights } from 'utils/telemetry/app-insights';
import {
  checkIsInitialized,
  getOccasions,
} from 'components/PDP/Order/RecipientForm/components/Occasion/feature/selectors';
import { dynamicEvents } from 'service/dynamic-yield/dynamic-events';
import { fetchOccasions } from 'components/PDP/Order/RecipientForm/components/Occasion/feature/actions';
import { getByType } from 'utils/getProductPostfixByType';
import { getCartDetail } from 'redux/cart/cart.selectors';
import { getOccasion } from 'utils/get-occasion-selected';
import { isUS } from 'utils/country';
import { nxtGenCartStoreNumberSetting } from 'redux/settings/settings.selectors';
import { segment } from 'service/segment/segment';
import { selectOccasionId } from 'redux/session/availability/availability.selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useProductAddedToCartRedirect = (
  quantity: number,
  product: Product | null,
  addonItems: UpsellItem[] = [],
  selectedStoreID: number,
): void => {
  const dispatch = useDispatch();
  const cartRecipientId = useSelector(
    ({ cart }: RootState) => cart.cartRecipientId,
  );
  const culture = useSelector(
    ({ arrangement: { data } }: RootState) => data.culture,
  );
  const occasions = useSelector(getOccasions);
  const occasionId = useSelector(selectOccasionId);
  const isInitialized = useSelector(checkIsInitialized);
  const cartDetail = useSelector(getCartDetail);
  const storeSettings = useSelector(nxtGenCartStoreNumberSetting);
  const callCenterMode = useSelector(({ callCenterMode: { data } }) => data);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const occasionSelected = getOccasion(occasions, occasionId);
  const currency = isUS ? 'USD' : 'CAD';
  useEffect(() => {
    if (!isInitialized) {
      dispatch(fetchOccasions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  useEffect(() => {
    if (cartRecipientId > -1) {
      if (product) {
        let productToAddToCart: CartProductForAnalytics = {
          id: `${product.id}${getByType(product.type)}`,
          price: product.price,
          units: quantity,
          cartAddLocation: EventLocation.PDP,
        };

        if (occasionSelected.length) {
          productToAddToCart = {
            ...productToAddToCart,
            occasionSelected,
          };
        }
        const addToCartAddons = addonItems.filter(
          ({ checked, quantity: quant }) => checked && quant,
        );
        analytics.addedToCart({
          product: productToAddToCart,
          addonItems: addToCartAddons,
          culture,
        });
        segment.productAdded({
          product: productToAddToCart,
          addonItems: addToCartAddons,
          user: userSession,
          accountInformation: userAccountInformation,
        });

        dynamicEvents.addToCart({
          value: product?.price ?? 0,
          currency,
          quantity,
          sku: product?.sku,
        });
      }
      appInsights.trackEvent({ name: 'Added to cart' });

      const cartStoreNumberSettingParsed: CartStoreNumberSetting = JSON.parse(
        storeSettings,
      ) as CartStoreNumberSetting;

      const newCartEnabledStoreIdArray =
        cartStoreNumberSettingParsed.EnabledStoreList?.split(',');
      let isNewCartEnabled =
        newCartEnabledStoreIdArray && selectedStoreID && selectedStoreID > 0
          ? newCartEnabledStoreIdArray.includes(`${selectedStoreID}`)
          : true;
      if (
        isNewCartEnabled &&
        newCartEnabledStoreIdArray &&
        cartDetail?.length > 0
      ) {
        isNewCartEnabled = !cartDetail.some(
          (item) => !newCartEnabledStoreIdArray.includes(`${item.storeID}`),
        );
      }
      if (callCenterMode && callCenterMode.id > 0) {
        isNewCartEnabled = false;
      } else {
        isNewCartEnabled =
          cartStoreNumberSettingParsed.AllStoreEnabled || isNewCartEnabled;
      }

      /* TODO: should be split to have analytics and navigation separately */
      window.location.href =
        window.NEW_CART_ENABLED && isNewCartEnabled
          ? `${window.location.origin}/fruit-cart?CRID=${cartRecipientId}`
          : `${window.location.origin}/fruit-cart.aspx?CRID=${cartRecipientId}`;
    }
    /* should react only on cartRecipientId change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRecipientId, occasionSelected]);
};
