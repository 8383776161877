import { availabilityOnDateService } from 'api/availability-on-date/availability-on-date.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const getAvailabilityOnDate = createAsyncThunk(
  'availability-on-date/fetch',
  async (
    {
      areaId,
      arrangementId,
      date,
    }: {
      areaId: number;
      arrangementId: number;
      date: Date;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await availabilityOnDateService.getDates(
        areaId,
        arrangementId,
        date,
      );

      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
