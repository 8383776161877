import { SxProps } from '@mui/system';

export const containerWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 1,
  maxWidth: '1060px',
  mx: 'auto',
  fontSize: '16px',
};
