import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5, 2.5),
  margin: theme.spacing(0, 0),
  [theme.breakpoints.up('sm')]: {
    maxWidth: '1060px',
    padding: theme.spacing(2.5, 5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5, 0),
  },
  '& h3': {
    fontSize: '14px',
    fontWeight: 600,
  },
  '& p': {
    fontSize: '14px',
  },
  '& a': {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  fontFamily: fonts.poppins,
}));

export const noSpace: ThemedSxProps = {
  padding: 0,
  margin: 0,
};

export const accordianText: ThemedSxProps = {
  padding: 0,
  margin: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
};

export const title: ThemedSxProps = (theme) => ({
  whiteSpace: 'wrap',
  fontWeight: '500',
  fontSize: '16px',
  letterSpacing: '0.15px',
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
});
