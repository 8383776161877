import React, { FC } from 'react';

interface Props {
  components: React.ComponentType[];
}

export const Composer: FC<Props> = ({ components, children }) => (
  <>
    {components.reduceRight(
      (composedChildren, Component) => (
        <Component>{composedChildren}</Component>
      ),
      children,
    )}
  </>
);
