import { AREA_NOT_FOUND } from 'components/AreaController/feature/area.constants';
import { AxiosError } from 'axios';
import { ResponseError } from 'types/error.types';
import { areaApiService } from 'api/area/area.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const fetchArea = createAsyncThunk(
  'area/fetch',
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await areaApiService.getAreas(name, 1);

      if (!data.length) {
        const error: ResponseError = {
          message: 'Area not found',
          data: { key: AREA_NOT_FOUND },
          status: 404,
        };
        return rejectWithValue(error);
      }

      return data[0];
    } catch (err) {
      return rejectWithValue(parseAxiosError(err as AxiosError));
    }
  },
);
