import { sale } from 'components/OrderConfirmation/translations/locale.constants';

export const orderConfirmDetailsCommonLocale = {
  [sale.TITLE]: 'Total Charged:',
  [sale.ORDERNUMBER]: 'Order Number:',
  [sale.ITEM_COUNT]: '{{Number}} Item',
  [sale.QTY]: '{{#}} x',
  [sale.ADDON_QTY]: 'x{{#}}',
  // eslint-disable-next-line no-template-curly-in-string
  [sale.PRICE]: '${{Price}}',
  [sale.SUMMARY]: 'Order Summary',
  [sale.DELIVERY_FEE]: 'Delivery Fees',
  [sale.SHIPMENT_CHARGE]: 'Shipment Charge',
  [sale.SERVICE_FEE]: 'Service Fee:',
  [sale.SUBTOTAL]: 'Subtotal',
  [sale.TOTAL]: 'Order Total',
  [sale.COUPON_CODE]: 'Coupon Code ({{CouponCode}})',
  [sale.RECIPIENT]: 'Recipient {{RecipientNumber}}',
  [sale.TRANSACTION_ID]: 'Transaction ID:',
};
