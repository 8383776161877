import { ArrangementSuggestionList } from 'components/PDP/Details/components/ProductAccordion/components/suggestions/ArrangementSuggestionList';
import { Faq } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/FAQ';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductAccordion } from 'components/PDP/Details/components/ProductAccordion/ProductAccordion';
import { Stack } from '@mui/material';
import {
  checkIsBlocked,
  selectArrangement,
  selectIsMarketplaceProduct,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

type Props = {
  reviewsOpen: boolean;
  setReviewsOpen: (open: boolean) => void;
  product: Product | null;
};

export const ArrangementPanel: FC<Props> = ({
  reviewsOpen,
  setReviewsOpen,
  product,
}) => {
  const arrangement = useSelector(selectArrangement);
  const arrangementPending = useSelector(checkIsBlocked);
  const isMarketplace = useSelector(selectIsMarketplaceProduct);

  return (
    <Stack gap={6}>
      {isMarketplace && (
        <ArrangementSuggestionList arrangementId={arrangement.id} />
      )}
      <ProductAccordion
        loading={arrangementPending}
        arrangement={arrangement}
        selectedProduct={product}
        reviewsOpen={reviewsOpen}
        setReviewsOpen={setReviewsOpen}
      />
      <Faq />
    </Stack>
  );
};
