import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist/es/constants';
import { PlpWidgetContentReducer } from 'components/PLP/feature/slice';
import { ShipmentStoreTaxHistoryReducer } from 'components/Cart/Details/components/OrderSummary/feature/slice';
import { addOnUpgradeCarouselReducer } from 'components/Cart/Details/components/AddOnUpgradeCarousel/feature/slice';
import { addressBookReducer } from 'containers/AddressBook/feature/slice';
import { areaControllerReducer } from 'components/AreaController/feature/area.slice';
import { areaReducer } from 'redux/PDP/area/area.slice';
import { arrangementGroupReducer } from 'redux/PDP/arrangement/arrangement-group.slice';
import { arrangementReducer } from 'redux/PDP/arrangement/arrangement.slice';
import { authPasswordResetReducer } from 'components/Authentication/Reset/feature/slice';
import { authReducer } from 'redux/auth/auth.slice';
import { authenticationReducer } from 'components/Authentication/LogIn/feature/slice';
import { availabilityOnDateReducer } from 'redux/PDP/availability/on-date/availability-on-date.slice';
import { availabilitySessionReducer } from 'redux/session/availability/availability.slice';
import { bannerReducer } from 'components/Header/Banner/feature/slice';
import { billingFormOptionsReducer } from 'components/Payment/BillingInformation/Options/feature/slice';
import { breadcrumbHistoryReducer } from 'components/BreadcrumbBack/feature/slice';
import { businessGiftingEventInfoReducer } from 'components/BusinessGifting/ContactDialog/feature/slice';
import { businessGiftingReducer } from 'containers/BusinessGifting/feature/slice';
import { callCenterLoginReducer } from 'components/PLP/CallCenter/components/Login/feature/slice';
import { callCenterModeReducer } from 'components/PLP/CallCenter/feature/slice';
import { cartOptionsReducer } from 'components/Cart/Options/feature/slice';
import { cartReducer } from 'redux/cart/cart.slice';
import { caseCategoryReducer } from 'redux/case/caseCategory/case.slice';
import { caseOrderReducer } from 'redux/case/caseOrder/case-order.slice';
import { checkAvailabilityReducer } from 'redux/PDP/availability/check-availability/check-availability.slice';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { dateAvailabilityReducer } from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/slice';
import { deliveryPickupInstructionsReducer } from 'components/PDP/Order/RecipientForm/components/DeliveryPickupInstructions/feature/delivery-pickup-instructions.slice';
import { deliveryReducer } from 'redux/PDP/availability/for-delivery/delivery.slice';
import { giftFinderFilterReducer } from 'components/GiftFinder/feature/gift-finder.slice';
import { greetingsCardsReducer } from 'components/PDP/Order/GreetingsCards/feature/greetings-cards.slice';
import { headerReducer } from 'redux/header/header.slice';
import { homePageContentReducer } from 'components/Home/feature/slice';
import { isInProduction } from 'utils/environment';
import { localSeoReducer } from 'containers/LocalSeoContainer/feature/slice';
import { localeReducer } from 'redux/locale/locale.slice';
import { mainAlertReducer } from 'components/common/MainAlert/feature/slice';
import { maintenanceReducer } from 'redux/maintenance/maintenance.slice';
import { nearestStoreReducer } from 'components/Home/NearestStore/feature/slice';
import { orderConfirmationReducer } from 'redux/order-confirmation/order-confirmation.slice';
import { orderExpressFulfillmentsReducer } from 'redux/order-confirmation/order-express-fulfillments.slice';
import { orderOccasionsReducer } from 'components/PDP/Order/RecipientForm/components/Occasion/feature/slice';
import { orderTrackingReducer } from 'components/CustomerService/OrderTracking/feature/slice';
import { pasReducer } from 'containers/PDPContainer/feature/pas/slice';
import { pdpBackFilterReducer } from 'components/PDP/GoBack/feature/slice';
import { pdpOrderAddressValidationReducer } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/slice';
import { pdpRelatedCategoryNameReducer } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/feature/slice';
import { pdpUpsellsReducer } from 'components/PDP/Upsells/feature/slice';
import { persistReducer } from 'redux-persist';
import { pickupReducer } from 'redux/PDP/availability/for-pickup/pickup.slice';
import { pickupStoreReducer } from 'components/PDP/Order/RecipientForm/PickupForm/feature/slice';
import { plpAllArrangementsReducer } from 'components/PLP/ArrangementsList/AllArrangements/feature/slice';
import { plpArrangementGroupReducer } from 'components/common/ArrangementGroup/feature/slice';
import { plpArrangementsReducer } from 'components/PLP/ArrangementsList/feature/slice';
import { plpFilterCategoriesReducer } from 'components/PLP/FilterCategories/feature/slice';
import { plpReducers } from 'redux/PLP/plp.slice';
import { processPaymentReducer } from 'components/Payment/feature/slice';
import { productOptionsAvailabilityReducer } from 'components/PDP/Details/components/ProductOptions/feature/slice';
import { recipientAvailabilityReducer } from 'components/Session/Alert/feature/slice';
import { recipientCartReducer } from 'components/Cart/feature/slice';
import { recipientsReducer } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/slice';
import { redirectReducer } from 'redux/PLP/redirect/redirect.slice-part';
import { reviewsReducer } from 'components/PDP/Details/components/Reviews/feature/reviews.slice';
import { searchCatalogReducer } from 'components/Header/feature/slice';
import { sessionStoreScheduleReducer } from 'components/Session/feature/slice';
import { settingsReducer } from 'redux/settings/settings.slice';
import { shipmentReducer } from 'redux/PDP/availability/for-shipment/store-available-for-shipment.slice';
import { signUpReducer } from 'components/SignUp/feature/slice';
import { storeComingSoonDetailsReducer } from 'components/StoreComingSoon/feature/slice';
import { storeDetailsReducer } from 'components/StoreDetails/feature/slice';
import { storeFinderReducer } from 'components/StoreFinder/feature/slice';
import { storeScheduleReducer } from 'redux/PDP/stores/schedule/stores-schedule.slice';
import { storesByStateReducer } from 'components/LocalSeoPage/State/feature/slice';
import { storesReducer } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.slice';
import { subscriptionReducer } from 'components/Subscription/feature/slice';
import { suggestedArrangementsReducerNew } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.slice';
import { userSessionReducer } from 'providers/Session/feature/user.slice';
import { zipAvailabilityReducer } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.slice';
import storage from 'redux-persist/lib/storage';

const localePersistConfig = {
  key: 'locale',
  storage,
  whitelist: ['data'],
};

const rootReducers = combineReducers({
  addOnUpgradeCarousel: addOnUpgradeCarouselReducer,
  addressBook: addressBookReducer,
  area: areaReducer,
  areaController: areaControllerReducer,
  arrangement: arrangementReducer,
  arrangementGroup: arrangementGroupReducer,
  auth: authReducer,
  authentication: authenticationReducer,
  authPasswordReset: authPasswordResetReducer,
  availabilityCheck: checkAvailabilityReducer,
  availabilityOnDate: availabilityOnDateReducer,
  availabilitySession: availabilitySessionReducer,
  banners: bannerReducer,
  breadcrumb: breadcrumbHistoryReducer,
  businessGifting: businessGiftingReducer,
  businessGiftingEventInfo: businessGiftingEventInfoReducer,
  callCenterLogin: callCenterLoginReducer,
  callCenterMode: callCenterModeReducer,
  cart: cartReducer,
  caseCategory: caseCategoryReducer,
  caseOrder: caseOrderReducer,
  cartOptions: cartOptionsReducer,
  billingOptions: billingFormOptionsReducer,
  dateAvailability: dateAvailabilityReducer,
  delivery: deliveryReducer,
  deliveryPickupInstructions: deliveryPickupInstructionsReducer,
  giftFinder: giftFinderFilterReducer,
  greetingsCards: greetingsCardsReducer,
  header: headerReducer,
  homePageContent: homePageContentReducer,
  plpWidgetContent: PlpWidgetContentReducer,
  locale: persistReducer(localePersistConfig, localeReducer),
  localSeo: localSeoReducer,
  mainAlert: mainAlertReducer,
  maintenance: maintenanceReducer,
  nearestStore: nearestStoreReducer,
  orderConfirmation: orderConfirmationReducer,
  orderExpressFulfillments: orderExpressFulfillmentsReducer,
  orderOccasions: orderOccasionsReducer,
  orderTracking: orderTrackingReducer,
  pas: pasReducer,
  paymentResponse: processPaymentReducer,
  pdpBackFilter: pdpBackFilterReducer,
  pdpOrderAddressValidation: pdpOrderAddressValidationReducer,
  pdpRelatedCategoryName: pdpRelatedCategoryNameReducer,
  pdpUpsells: pdpUpsellsReducer,
  pickup: pickupReducer,
  pickupStore: pickupStoreReducer,
  plp: plpReducers,
  plpAllArrangements: plpAllArrangementsReducer,
  plpArrangementGroup: plpArrangementGroupReducer,
  plpArrangements: plpArrangementsReducer,
  plpFilterCategories: plpFilterCategoriesReducer,
  productOptionsAvailability: productOptionsAvailabilityReducer,
  recipientAvailability: recipientAvailabilityReducer,
  recipientCart: recipientCartReducer,
  shipmentStoreTaxHistory: ShipmentStoreTaxHistoryReducer,
  recipients: recipientsReducer,
  redirects: redirectReducer,
  reviews: reviewsReducer,
  searchCatalog: searchCatalogReducer,
  sessionStoreSchedule: sessionStoreScheduleReducer,
  settings: settingsReducer,
  store: shipmentReducer,
  storeDetails: storeDetailsReducer,
  storeComingSoon: storeComingSoonDetailsReducer,
  storeSchedule: storeScheduleReducer,
  storesByState: storesByStateReducer,
  storeFinderState: storeFinderReducer,
  signUp: signUpReducer,
  stores: storesReducer,
  subscription: subscriptionReducer,
  suggestedArrangementsNew: suggestedArrangementsReducerNew,
  userSession: userSessionReducer,
  zipAvailability: zipAvailabilityReducer,
});

export const store = configureStore({
  reducer: rootReducers,
  devTools: !isInProduction(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
