import { ResponseError } from 'types/error.types';
import { SettingsState } from 'redux/settings/settings.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  getEnablePrintibleStrikeSetting,
  getNxtGenCartStoreNumberSetting,
  getPaypalEnableSetting,
  getPaypalVersionSetting,
} from 'redux/settings/settings.actions';

const initialState: SettingsState = {
  data: {
    enablePaymentMethodPayPal: false,
    usePayPalVersion: '',
    enableNxtGenCartStoreNumberSetting: '',
    enablePrintibleStrikeThrough: false,
  },
  status: Status.INIT,
  error: null,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaypalEnableSetting.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(getPaypalEnableSetting.fulfilled, (state, { payload }) => {
        state.data = { ...state.data };
        state.data.enablePaymentMethodPayPal = payload;
        state.status = Status.FULFILLED;
      })
      .addCase(getPaypalEnableSetting.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addCase(getPaypalVersionSetting.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(getPaypalVersionSetting.fulfilled, (state, { payload }) => {
        state.data = { ...state.data };
        state.data.usePayPalVersion = payload;
        state.status = Status.FULFILLED;
      })
      .addCase(getPaypalVersionSetting.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addCase(getNxtGenCartStoreNumberSetting.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(
        getNxtGenCartStoreNumberSetting.fulfilled,
        (state, { payload }) => {
          state.data = { ...state.data };
          if (payload) state.data.enableNxtGenCartStoreNumberSetting = payload;
          state.status = Status.FULFILLED;
        },
      )
      .addCase(
        getNxtGenCartStoreNumberSetting.rejected,
        (state, { payload }) => {
          state.status = Status.ERROR;
          state.error = payload as ResponseError;
        },
      )
      .addCase(getEnablePrintibleStrikeSetting.pending, (state) => {
        state.error = null;
        state.status = Status.PENDING;
      })
      .addCase(
        getEnablePrintibleStrikeSetting.fulfilled,
        (state, { payload }) => {
          state.data = { ...state.data };
          state.data.enablePrintibleStrikeThrough = payload;
          state.status = Status.FULFILLED;
        },
      )
      .addCase(
        getEnablePrintibleStrikeSetting.rejected,
        (state, { payload }) => {
          state.status = Status.ERROR;
          state.error = payload as ResponseError;
        },
      )
      .addDefaultCase((state) => state);
  },
});

export const { reducer: settingsReducer, actions: settingsActions } =
  settingsSlice;
