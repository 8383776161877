import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import { AddOn } from 'api/cart/types/cart-add-on.type';
import { Box, Typography } from '@mui/material';
import { ChildProducts } from 'api/order/types';
import { OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import { sale } from 'components/CustomerService/translations/locale.constants';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  freeProduct: OrderSubItem | ChildProducts | AddOn;
  title?: boolean;
  cartPage?: boolean;
}

export const FreeProduct: FC<Props> = ({ freeProduct, title, cartPage }) => (
  <Box sx={cartPage ? styles.freeCartProductStyle : styles.freeProductStyle}>
    <Box width={cartPage ? '100%' : '97%'} ml={cartPage ? 0 : 2}>
      <Box
        width="100%"
        sx={styles.freeProductLayout}
        display="flex"
        justifyContent="space-between"
      >
        <Box
          sx={
            title
              ? styles.freeProductTitleStyle
              : styles.freeProductTitleStyleConfirmation
          }
        >
          <PureHtmlTypography
            sx={styles.freeProductNameStyle}
            variant="subtitle2"
          >
            {cartPage
              ? `${i18next.t(sale.FREE_TEXT)} ${freeProduct.name}`
              : freeProduct.name}
          </PureHtmlTypography>
          {!cartPage && (
            <Typography sx={styles.freeProductSizeStyle} variant="subtitle2">
              {freeProduct?.sizeName}
            </Typography>
          )}
        </Box>

        {cartPage ? (
          <Box
            display="flex"
            flexDirection="row"
            sx={styles.freeProductMobileViewCart}
          >
            <Box display="flex" sx={styles.freeProductPriceCart}>
              <Typography variant="body2" sx={styles.freeProductColorTxt}>
                {i18next.t(sale.FREE_TEXT)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            sx={
              title
                ? styles.freeProductMobileView
                : styles.freeProductMobileViewConfirmation
            }
          >
            {!cartPage && (
              <Typography
                variant="body2"
                sx={
                  title ? styles.freeQtyStyle : styles.freeQtyStyleConfirmation
                }
              >
                {i18next.t(sale.QTY, {
                  '#': freeProduct.quantity,
                })}
              </Typography>
            )}
            <Box display="flex" sx={styles.freeProductPrice}>
              <Typography variant="body2" sx={styles.freeProductColorTxt}>
                {`$${roundNumberValue(
                  freeProduct.quantity * freeProduct.price,
                )}`}
              </Typography>
              <Typography variant="body2" sx={styles.productPriceStyle}>
                $
                {i18next.t(sale.PRICE, {
                  '#': freeProduct.quantity,
                  Price: roundNumberValue(freeProduct.price),
                })}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  </Box>
);
