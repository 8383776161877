import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/styles';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Link, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { TermsAndCOnditionsResponse } from 'api/about/type/about';
import { TermsAndConditionSubTitle } from 'components/About/TermsAndConditions/TermsAndConditionsSubTitle';
import { aboutApiService } from 'api/about/about.api.service';
import { isNotEmpty } from 'utils/is-not-empty';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const TermsAndConditions: FC = () => {
  const [termsAndConditionData, setTermsAndConditionData] = useState<
    TermsAndCOnditionsResponse[]
  >([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(true);
  const inactiveData = 'false';
  const fetchTermsAndConditionsInfo = async (inActive: string) => {
    const res = await aboutApiService.getTermsAndConditionsInfo(inActive);
    setTermsAndConditionData(res);
    setDataLoaded(false);
  };

  useEffect(() => {
    fetchTermsAndConditionsInfo(inactiveData);
  }, []);

  return (
    <>
      <Backdrop opened={dataLoaded} />
      <Box sx={styles.termAndConditionsWrapper}>
        <Box sx={styles.PageHeading}>#EdibleMoments</Box>
        <Box sx={styles.pageContainer}>
          {termsAndConditionData.map((item) => {
            return (
              <>
                <Box key={item.id}>
                  <PureHtmlTypography variant="body2" sx={styles.heading}>
                    {item.title}
                  </PureHtmlTypography>
                  <hr
                    style={{
                      borderTop: '1px solid #C9DCE0',
                      marginBottom: '10px',
                    }}
                    className="lineStyle"
                  />
                  {isNotEmpty(item.SubTitle) ? (
                    <Box>
                      <TermsAndConditionSubTitle subTitleData={item.SubTitle} />
                    </Box>
                  ) : null}
                  <PureHtmlTypography variant="body2" sx={styles.description}>
                    {item.description}
                  </PureHtmlTypography>
                </Box>
              </>
            );
          })}
        </Box>
        <Link
          sx={styles.termAndConditionsLink}
          href={HeaderLinks.PROMOTION_TERMS_CONDITIONS_EXPIRED}
        >
          <Typography style={{ fontWeight: '500' }}>
            {i18next.t(TK.TERMS_AND_CONDITIONS_EXPIRED_LINK)}
          </Typography>
        </Link>
      </Box>
    </>
  );
};
