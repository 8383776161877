import * as TK from 'components/Authentication/translations/locale.constants';
import { Slide } from '@mui/material';
import { selectIsResetMode } from 'components/Authentication/LogIn/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ResetPasswordTitle: FC = () => {
  const isReset = useSelector(selectIsResetMode);
  if (!isReset) {
    return null;
  }

  return (
    <Slide in={isReset} direction="right">
      <div>{i18next.t(TK.AUTH_DIALOG_RESET_HEADER)}</div>
    </Slide>
  );
};
