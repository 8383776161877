import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { storesApiService } from 'api/stores.api.service';

interface Params {
  id: number;
}

export const getStore = createAsyncThunk(
  'pickup-form/store',
  ({ id }: Params, { rejectWithValue }) =>
    storesApiService
      .getStore(id)
      .catch((err) => rejectWithValue(parseAxiosError(err))),
);
