import { Collapse } from '@mui/material';
import { MultipleRecipientLock } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/MultipleRecipientLock';
import { SingleRecipientLock } from 'components/Session/Alert/RecipientLockAlert/components/SingleRecipientLock';
import { selectIsNewRecipientSelected } from 'components/Session/feature/selectors';
import { selectIsRecipientLock } from 'redux/session/availability/availability.selectors';
import { useSelector } from 'react-redux';
import { useTitle } from 'components/Session/Alert/RecipientLockAlert/translations/use-title';
import React, { FC } from 'react';

interface Props {
  displayRecipientName?: boolean;
  multipleRecipients?: boolean;
  shortDate?: boolean;
  recipientLockTitle?: string;
  pdpRecipientLock?: boolean;
}

export const RecipientLockAlert: FC<Props> = ({
  displayRecipientName,
  multipleRecipients,
  shortDate,
  recipientLockTitle,
  pdpRecipientLock,
}) => {
  const isRecipientLock = useSelector(selectIsRecipientLock);
  const isNewRecipientSelected = useSelector(selectIsNewRecipientSelected);
  const message = useTitle({ displayRecipientName, shortDate });

  const shouldDisplayLock =
    isRecipientLock || (isNewRecipientSelected && multipleRecipients);

  if (!shouldDisplayLock) {
    return null;
  }

  return (
    <Collapse in={shouldDisplayLock}>
      {pdpRecipientLock && (
        <MultipleRecipientLock pdpRecipientLock={pdpRecipientLock}>
          {isNewRecipientSelected
            ? recipientLockTitle || `This treat is available for ${message}`
            : `This treat is available for ${message}`}
        </MultipleRecipientLock>
      )}
      {multipleRecipients && (
        <MultipleRecipientLock>
          {isNewRecipientSelected ? recipientLockTitle || message : message}
        </MultipleRecipientLock>
      )}
      {!multipleRecipients && !pdpRecipientLock && (
        <SingleRecipientLock>
          {recipientLockTitle || message}
        </SingleRecipientLock>
      )}
    </Collapse>
  );
};
