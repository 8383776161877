import { SxProps } from '@mui/material';

export const paper = (width?: number): SxProps => ({
  py: 1.5,
  px: 2,
  borderRadius: '16px',
  display: 'flex',
  width,
});

export const arrow: SxProps = {
  position: 'absolute',
  bottom: 0,
  right: 22,

  '&::before': {
    backgroundColor: 'background.paper',
    content: '""',
    display: 'block',
    position: 'relative',
    width: 12,
    height: 12,
    top: 5,
    transform: 'rotate(45deg)',
  },
};
