import * as StoreTK from 'components/Session/Alert/StoreLockAlert/translations/locale.constants';
import { AvailabilitySession } from 'redux/session/availability/availability.types';
import { Box } from '@mui/system';
import { Button, Collapse, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Phone } from '@mui/icons-material';
import { RootState } from 'redux/store';
import { SL_RESET } from 'components/Session/Alert/translations/locale.constants';
import { Schedule } from 'components/Session/Alert/StoreLockAlert/Schedule/Schedule';
import { checkStoreIdIsValid } from 'components/Session/feature/selectors';
import { lockIcon } from 'components/Session/Alert/styles';
import { useSelector } from 'react-redux';
import Lock from '@mui/icons-material/Lock';
import React, { memo, useState } from 'react';
import i18next from 'i18next';

type Props = {
  title: string;
  reset: () => void;
};

export const FullStoreLock = memo<Props>(({ title, reset }) => {
  const [isFullInfoShown, setIsFullInfoShown] = useState<boolean>(false);
  const isStoreValid = useSelector(checkStoreIdIsValid);
  const { storeName, storeAddress, storePhone }: AvailabilitySession =
    useSelector(({ availabilitySession: s }: RootState) => s.data);
  const schedule = useSelector(
    ({ availabilitySession: { data } }: RootState) => ({
      storeId: data.storeId,
      date: data.selectedDate,
    }),
  );

  return (
    <>
      <Typography variant="subtitle2" data-test="pdp-details-store-lock-title">
        <Lock sx={lockIcon} />
        {title}
      </Typography>
      {isStoreValid && (
        <Box>
          <Collapse in={isFullInfoShown}>
            <Typography
              mt={2}
              variant="subtitle2"
              data-test="pdp-details-store-lock-name"
            >
              {storeName}
            </Typography>
          </Collapse>
          <Typography
            mt={1}
            variant="body2"
            component="address"
            data-test="pdp-details-store-lock-address"
          >
            {storeAddress}
          </Typography>
          <Collapse in={isFullInfoShown}>
            <Typography variant="body2" mt={1}>
              {isFullInfoShown && schedule.date && (
                <Schedule
                  storeId={schedule.storeId}
                  date={new Date(schedule.date)}
                />
              )}
            </Typography>
          </Collapse>
          <Collapse in={isFullInfoShown}>
            <Button
              startIcon={<Phone />}
              href={`tel:${storePhone}`}
              sx={{ mt: 1.5 }}
              variant="text"
              size="small"
              color="secondary"
            >
              {storePhone}
            </Button>
          </Collapse>
        </Box>
      )}
      <Box mt={2} width={1} display="flex" justifyContent="space-between">
        <Button
          onClick={reset}
          variant="text"
          color="secondary"
          size="small"
          type="button"
          data-test="pdp-details-store-lock-reset-button"
        >
          {i18next.t(SL_RESET)}
        </Button>
        {isStoreValid && (
          <Button
            onClick={() => setIsFullInfoShown((prev) => !prev)}
            endIcon={
              isFullInfoShown ? <KeyboardArrowUp /> : <KeyboardArrowDown />
            }
            variant="text"
            color="secondary"
            size="small"
            type="button"
          >
            {i18next.t(
              isFullInfoShown ? StoreTK.SL_LESS_INFO : StoreTK.SL_MORE_INFO,
            )}
          </Button>
        )}
      </Box>
    </>
  );
});
