import { SxProps } from '@mui/system';
import { fonts } from 'utils/theme/fonts';

export const containerWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0 auto',
  fontFamily: fonts.geomanistregular,
  fontSize: '16px',
};

export const main: SxProps = {
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
};
