export const colors = {
  black: '#000',
  toolTip: '#F2F3F5',
  white: '#fff',
  primaryTextColor: '#333',
  primaryBlack: '#252525',
  primaryRed: '#E10600',
  freeDelivery: '#407FCB',
  primaryRedHover: '#900400',
  secondaryGray: '#555555',
  accentPrimary: '#cc0000',
  blue: '#2271b3',
  gray: '#ccc',
  red: '#c00',
  redLighten1: '#e21836',
  promoRed: '#FF0000',
  grayLighten1: '#ececec',
  hellGray: '#666',
  highGray: '#999',
  textDecent: '#676767',
  systemBackground: '#f8f8f8',
  systemBorder: '#d3d2d2',
  actionActive: '#797979',
  iconBlack: 'rgba(0, 0, 0, 0.54)',
  disabledBlack: 'rgba(0, 0, 0, 0.6)',
  activeBlack: 'rgba(0, 0, 0, 0.87)',
  borderBlack: 'rgba(0, 0, 0, 0.23)',
  carouselArrowBlack: 'rgba(0, 0, 0, 0.26)',
  dividerBlack: 'rgba(0, 0, 0, 0.12)',
  boxShadowBlacks: {
    firstBlack: 'rgba(0, 0, 0, 0.12)',
    secondBlack: 'rgba(0, 0, 0, 0.14)',
  },
  promoHover: 'rgba(37, 37, 37, 0.08)',
  lightGray: '#f9f9f9',
  mediumGray: '#ddd',
  redButtonHover: '#C8102E14',
  redButtonHover2: '#900400',
  redButton: '#C8102E',
  printibleThumbBackground: 'rgba(200, 16, 46, 0.08)',
  salePriceColor: '#FF3300',
  hyperlink: 'rgba(200, 16, 46, 0.7)',
  lightRed: '#F6685E',
  lightGreen: '#4CAF50',
  warningColor: '#FF9900',
  successLightColor: '#6FBF73',
  errorLightColor: '#F44336',
  lightRedBackground: '#FFFDE7',
  errorLight: '#E7655C',
  placeholderText: '#2E2E2E',
  helperText: '#5c5c5c',
  greyBorder: '#c9c9c9',
  grey: '#ABABAB',
  chipSelectedBackground: 'rgba(225, 6, 0, 0.08)',
};
