import { NearestStore } from 'components/Home/NearestStore/types';
import { NearestStoreState } from 'components/Home/NearestStore/feature/slice';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';

export const getContent = (state: RootState): NearestStoreState =>
  state.nearestStore;

export const getStatus = (state: RootState): Status => getContent(state).status;

export const getData = (state: RootState): Partial<NearestStore> =>
  getContent(state).data || {};

export const getSameDayDeliveryCutOff = (state: RootState): string | null =>
  getContent(state).data?.sameDayDeliveryCutOffTime || null;

export const isBlocked = (state: RootState): boolean =>
  getContent(state).status === 0 || getContent(state).status === 1;
