import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const carousel: ThemedSxProps = {
  '& .slick-slider': {
    '& button.slick-arrow': {
      display: 'none !important',
    },
    '& .slick-list': {
      margin: '0 -10px',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          width: { xs: '150px', md: '250px' },
          margin: '0px 10px',
        },
      },
    },
  },
};

export const imageSkeleton: ThemedSxProps = {
  width: '100%',
  paddingTop: '100%',
  borderRadius: '50%',
};

export const titleSkeleton: ThemedSxProps = {
  maxWidth: '80%',
  margin: '20px auto 0',
};

export const widgetTitleSkeleton: ThemedSxProps = {
  width: '50%',
  height: '32px',
  marginBottom: '20px',
};

export const item: ThemedSxProps = {
  width: { xs: '150px', md: '250px' },
  margin: '0px 10px',
  display: 'inline-block',
} as const;
