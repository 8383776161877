import * as TK from 'components/Authentication/translations/locale.constants';
import { Button, Slide, Typography } from '@mui/material';
import { LogInAlert } from 'components/Authentication/LogIn/LogInAlert';
import { LogInForm } from 'components/Authentication/LogIn/LogInForm';
import { checkIsAlreadyExist } from 'components/SignUp/feature/selectors';
import { selectIsLoginMode } from 'components/Authentication/LogIn/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const LogInContent: FC = () => {
  const isLogin = useSelector(selectIsLoginMode);
  const alreadyExist = useSelector(checkIsAlreadyExist);

  if (!isLogin) {
    return null;
  }

  return (
    <Slide in={isLogin} direction="left">
      <div>
        <LogInAlert />
        <LogInForm />
        {!alreadyExist && (
          <>
            <Typography
              variant="body1"
              sx={{
                width: 1,
                mt: 6,
                textAlign: 'center',
              }}
            >
              {i18next.t(TK.AUTH_DIALOG_MODAL_REGISTRATION_TIP)}
            </Typography>
            <Button
              href="/account/sign-up"
              variant="outlined"
              size="large"
              color="primary"
              sx={{ mt: 2 }}
              fullWidth
            >
              {i18next.t(TK.AUTH_DIALOG_MODAL_REGISTRATION)}
            </Button>
          </>
        )}
      </div>
    </Slide>
  );
};
