import { createAsyncThunk } from '@reduxjs/toolkit';
import { headerApiService } from 'api/header-api.service';
import { parseAxiosError } from 'utils/errors';

export const fetchHeader = createAsyncThunk(
  'header/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await headerApiService.all();
      return data;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
