import * as TK from 'components/PLP/CallCenter/components/Login/translations/constants';

export const callCenterLoginCommonLocale = {
  [TK.TITLE]: 'Login',
  [TK.SUBMIT]: 'Sign In',
  [TK.SUBMIT_LOADING]: 'Signing In...',
  [TK.USERNAME]: 'User Name',
  [TK.PASSWORD]: 'Password',
  [TK.ERROR_EMPTY_FIELDS]: 'Please complete all requires fields',
  [TK.ERROR_EMPTY_FIELD]: 'Please enter {{fieldName}}',
  [TK.ERROR_INVALID_FIELDS]: 'Invalid User Name or Password. Please try again',
};
