import * as TK from 'components/PLP/GI/FilterSortMenu/translations/constants';

export const sortSelectCommonLocale = {
  [TK.SORT_DRAWER_HEADER]: 'Sort',
  [TK.SORT_DESKTOP_PRICE_ASC_LABEL]: 'Price — Low to High',
  [TK.SORT_DESKTOP_PRICE_DESC_LABEL]: 'Price — High to Low',
  [TK.SORT_DESKTOP_RELEVANCE_LABEL]: 'Relevance',
  [TK.SORT_PRICE]: 'Price',
  [TK.SORT_BY]: 'Sort By',
};
