import * as TK from 'components/PDP/Upsells/translations/constant';
import { lettersAndNumbersCommonLocale } from 'components/PDP/Upsells/components/UpsellOption/LettersAndNumbers/translations/common.locale';

export const upsellsCommonLocale = {
  [TK.UPSELLS_DECREASE_QUANTITY]: 'Decrease quantity',
  [TK.UPSELLS_INCREASE_QUANTITY]: 'Increase quantity',
  [TK.UPSELLS_REMOVE]: 'Remove',
  [TK.UPSELLS_ADD]: 'Add',
  [TK.UPSELLS_CONTINUE]: 'Continue',
  [TK.UPSELLS_SAVE_SELECTION]: 'Save selections ({{count}})',
  [TK.UPSELLS_TITLE]: 'upgrade your gift',
  [TK.UPSELLS_OPTIONS_SINGLE]: 'Single',
  [TK.UPSELLS_OPTIONS_OPTION]: 'Option',
  [TK.UPSELL_OPTIONS_STARTING_AT]: 'Options Starting At',
  [TK.UPSELL_OPTIONS_SHOW_ALL]: 'Show all',
  [TK.UPSELL_OPTIONS_EXPANDED_SINGLE]: 'Single Option',
  [TK.UPSELL_OPTIONS_EXPANDED_SELECT_ONE]: 'Select Only One Option',
  [TK.UPSELL_OPTIONS_EXPANDED_SELECT_MULTIPLE]: 'Select 1–{{count}} Options',
  [TK.UPSELL_OPTIONS_EXPANDED_SHOW_LESS]: 'Show less',
  [TK.UPSELL_OPTIONS_WARNING]:
    'Warning: We cant fetch the upsells, just go to the recipient form',
  [TK.UPSELL_OPTIONS_WARNING_DESIGN_VARY]: 'Design may vary',
  [TK.UPSELL_OPTIONS_NOTICE]:
    'Notice: There is no upsells here. You should go to the recipient form',
  [TK.UPSELL_SHOW_MORE]: 'Show More',
  [TK.UPSELL_SHOWING]: 'Showing {{shown}} of {{count}}.',
  [TK.UPSELL_SHOW]: 'Show {{shownCount}} More',
  [TK.UPSELL_OPTIONS_PRODUCT_BADGE_LABEL]: 'Your gift',
  ...lettersAndNumbersCommonLocale,
};
