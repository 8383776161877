import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useDoubleSlashRedirector = (): void => {
  const location = useLocation();

  useEffect(() => {
    if (/\/\/+?/.test(location.pathname)) {
      window.location.pathname = location.pathname.replace(/\/\/+/g, '/');
    }
  }, [location.pathname]);
};
