import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';

export const blogCarouselClassNames = {
  title: {
    width: '220px',
    height: '32px',
    fontFamily: fonts.poppins,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    marginLeft: '1%',
    marginBottom: '10px',
    [theme.breakpoints.down(950)]: {
      marginLeft: '4%',
    },
    [theme.breakpoints.down(767)]: {
      marginLeft: '5%',
    },
    [theme.breakpoints.between(400, 415)]: {
      marginLeft: '9%',
    },
    [theme.breakpoints.down(361)]: {
      marginLeft: '3%',
    },
  },
  blogImage: {
    [theme.breakpoints.between(760, 769)]: {
      width: '360px',
    },
    [theme.breakpoints.between(769, 821)]: {
      width: '360px',
    },
  },
};
