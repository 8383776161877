import { Typography } from '@mui/material';
import { theme } from 'utils/theme/theme';
import React, { FC } from 'react';

interface Props {
  color?: string;
}

export const NonPromotionalMessage: FC<Props> = ({ children, color }) => (
  <Typography
    variant="subtitle1"
    color={color ?? theme.palette.primary.main}
    sx={{ mb: 1 }}
  >
    {children}
  </Typography>
);
