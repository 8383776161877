import * as styles from 'components/Session/Alert/styles';
import { Box, Collapse } from '@mui/material';
import { SlimLockAlert } from 'components/Session/Alert/components/SlimLockAlert';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { resetAvailabilitySession } from 'redux/session/availability/availability.action';
import { selectIsOrderTypeLock } from 'redux/session/availability/availability.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'components/Session/Alert/translations/use-title';
import { zipAvailability } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.slice';
import React, { FC, useCallback } from 'react';

interface Props {
  shortDate?: boolean;
  displayPickupStore?: boolean;
}

export const OrderTypeLockAlert: FC<Props> = ({
  shortDate,
  displayPickupStore,
}) => {
  const dispatch = useDispatch();
  const isOrderTypeLock = useSelector(selectIsOrderTypeLock);
  const reset = useCallback(() => {
    dispatch(resetAvailabilitySession());
    dispatch(pas.reset());
    dispatch(zipAvailability.reset());
  }, [dispatch]);
  const title = useTitle({
    shortDate,
    displayPickupStore,
  });

  if (!isOrderTypeLock) {
    return null;
  }

  return (
    <Collapse in={isOrderTypeLock}>
      <Box sx={styles.alert} role="alert">
        <SlimLockAlert
          dataTest="pdp-details-order-type-lock"
          title={title}
          reset={reset}
        />
      </Box>
    </Collapse>
  );
};
