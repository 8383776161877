export interface BrowserCoordinates {
  latitude?: number;
  longitude?: number;
  status: BrowserCoordinatesStatus;
}

export enum BrowserCoordinatesStatus {
  INIT,
  ALLOWED,
  NOT_ALLOWED,
}
