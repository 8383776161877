import { root } from 'utils/root';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTopOnMount = (): void => {
  const location = useLocation();
  useEffect(() => {
    if (root) {
      root.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
