import { SxProps } from '@mui/system';
import { theme } from 'utils/theme/theme';

export const headerWrapper: SxProps = {
  borderBottom: `1px solid ${theme.palette.divider}`,
};

export const mobileNavWrapper: SxProps = {
  top: 0,
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 999,
  width: '100%',
};
