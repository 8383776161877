import * as TK from 'components/PDP/Details/components/OwnBox/translations/own-box.locale.constants';
import { DessertBoardType } from 'api/products/types';
import {
  OwnBoxOptions,
  OwnBoxOptionsDessertBoard,
} from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import {
  cookieBox,
  dessertsBoard,
  dessertsBox,
  dippedFruitBox,
  fruitBox,
} from 'utils/cloudinaryAssets';
import i18next from 'i18next';

export const getOwnBoxOptions = (ownBoxType: OwnBoxTypes): OwnBoxOptions => {
  const options: Record<string, OwnBoxOptions> = {
    [OwnBoxTypes.DIPPED_FRUIT]: {
      cells: 12,
      rows: 3,
      optionsLimit: 4,
      image: dippedFruitBox,
      title: i18next.t(TK.OWN_BOX_DIPPED_FRUIT_TITLE),
    },
    [OwnBoxTypes.FRUIT]: {
      cells: 12,
      rows: 1,
      optionsLimit: 4,
      image: fruitBox,
      title: i18next.t(TK.OWN_BOX_FRUIT_TITLE),
    },
    [OwnBoxTypes.DESSERT]: {
      cells: 6,
      rows: 2,
      optionsLimit: 3,
      image: dessertsBox,
      title: i18next.t(TK.OWN_BOX_DESSERT_TITLE),
    },
    [OwnBoxTypes.COOKIE_BOX]: {
      cells: 6,
      rows: 2,
      optionsLimit: 3,
      image: cookieBox,
      title: i18next.t(TK.OWN_BOX_COOKIE_BOX_TITLE),
    },
    [OwnBoxTypes.NONE]: {
      cells: 0,
      rows: 0,
      optionsLimit: 0,
      image: '',
      title: '',
    },
  };

  return options[ownBoxType];
};
export const getOwnBoxBoardOptions = (
  ownBoxType: OwnBoxTypes,
): OwnBoxOptionsDessertBoard => {
  const options: Record<string, OwnBoxOptionsDessertBoard> = {
    [OwnBoxTypes.DESSERT_BOARD]: {
      [DessertBoardType.BakedGoods]: {
        cells: 6,
        rows: 6,
        optionsLimit: 3,
        image: dessertsBoard,
        type: DessertBoardType.BakedGoods,
        title: i18next.t(TK.OWN_BOX_DESSERT_BOARD_BAKESHOP_TITLE),
      },
      [DessertBoardType.DippedBerries]: {
        cells: 6,
        rows: 6,
        optionsLimit: 6,
        image: dessertsBoard,
        type: DessertBoardType.DippedBerries,
        title: i18next.t(TK.OWN_BOX_DESSERT_BOARD_BERRIES_TITLE),
      },
      [DessertBoardType.DippedFruits]: {
        cells: 2,
        rows: 2,
        optionsLimit: 2,
        image: dessertsBoard,
        type: DessertBoardType.DippedFruits,
        title: i18next.t(TK.OWN_BOX_DESSERT_BOARD_FRUIT_TITLE),
      },
    },
  };

  return options[ownBoxType];
};
