import { ApiBaseService } from 'api/apibase.service';
import { OrderType } from 'types/cart.types';
import { ProductOptionAvailability } from 'api/availability/types/product-option-availability.interface';
import { formatSQLDate } from 'utils/get-sql-date-format';

class ProductOptionsAvailabilityApiService extends ApiBaseService {
  fetch({
    ids,
    storeId,
    areaId,
    orderType,
    fulfillmentDate,
  }: {
    ids: number[];
    storeId: number;
    areaId: number;
    orderType: OrderType;
    fulfillmentDate: Date;
  }): Promise<ProductOptionAvailability[]> {
    const todaysDate = new Date();
    const futureFulfillmentDate = fulfillmentDate;

    if (futureFulfillmentDate < todaysDate) {
      futureFulfillmentDate.setDate(futureFulfillmentDate.getDate() + 1);
    }

    return this.apibase
      .get<ProductOptionAvailability[]>('availability/products', {
        params: {
          ids,
          storeId,
          areaId,
          orderType,
          date: formatSQLDate(futureFulfillmentDate),
        },
      })
      .then(({ data }) => data);
  }
}

export const productOptionsAvailabilityApiService =
  new ProductOptionsAvailabilityApiService();
