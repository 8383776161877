export enum BannerTextType {
  'left' = 'left',
  'top' = 'top',
}
export enum TextAndButtonAlignment {
  TopLeft = '1',
  TopMiddle = '2',
  TopRight = '3',
  MiddleLeft = '4',
  MiddleMiddle = '5',
  MiddleRight = '6',
  BottomLeft = '7',
  BottomMiddle = '8',
  BottomRight = '9',
}
