import { Locale as AppLocale } from 'enums/locale';
import { Locale as BvLocale } from 'lib/bazaarvoice/enums/locale.enum';

const locales = {
  [AppLocale.EN_US]: BvLocale.EN_US,
  [AppLocale.EN_CA]: BvLocale.EN_CA,
  [AppLocale.FR_CA]: BvLocale.FR_CA,
  /** Bazaarvoice doesn't support es_pr */
  [AppLocale.ES_PR]: BvLocale.EN_US,
};

export function getLocale(locale: AppLocale): BvLocale {
  return locales[locale];
}
