import { Link } from 'react-router-dom';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const StateLink = styled(Link)({
  fontSize: 16,
  margin: 0,
  fontWeight: 400,
  color: colors.activeBlack,
  cursor: 'pointer',
  fontFamily: fonts.poppins,
  textDecoration: 'none',
  lineHeight: '143%',
  '&:hover': {
    color: colors.primaryRed,
    textDecoration: 'none',
  },
});

export const stateTitle: ThemedSxProps = () => ({
  fontSize: 20,
  mt: 4,
  mb: 2,
  fontWeight: 600,
  letterSpacing: '0.15px',
});

export const stateDescription: ThemedSxProps = (theme) => ({
  fontSize: 12,
  mt: 0,
  mb: 4,
  fontWeight: 400,
  color: theme.palette.text.secondary,
});

export const stateStoreTitle: ThemedSxProps = () => ({
  fontSize: 16,
  mt: 0,
  mb: 4,
  fontWeight: 600,
  color: colors.activeBlack,
});

export const states: ThemedSxProps = (theme) => ({
  fontSize: 16,
  mt: 0,
  mb: 3,
  fontWeight: 400,
  color: colors.activeBlack,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
});

export const showMore: ThemedSxProps = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  mb: 5,
  color: colors.activeBlack,
  'span:first-of-type': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 600,
    mt: 3,
    mb: 2,
  },
});
