import { Area } from 'api/area/types/area.interface';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { OrderType, ValidOrderTypes } from 'types/cart.types';
import { dateSelection } from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/slice';
import {
  dateStep,
  orderTypeStep,
} from 'components/PDP/Details/components/PasControl/contants/steps';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { isLockExist } from 'components/PDP/Details/components/PasControl/utils/is-lock-exist';
import { isNull } from 'utils/null.utils';
import { isPasEmpty } from 'containers/PDPContainer/feature/pas/utils/is-pas-empty';
import { lockStepMapper } from 'components/PDP/Details/components/PasControl/utils/lock-step-mapper';
import { selectSessionLockType } from 'redux/session/availability/availability.selectors';
import { storeSelector } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useSkipMountEffect } from 'hooks/use-skip-mount-effect';

interface Params {
  area: Area | null;
  selectedDate: Date | null;
  setSelectedDate: (d: Date | null) => void;
  orderType: ValidOrderTypes;
  setOrderType: (d: ValidOrderTypes) => void;
  setAvailability: (d: AvailabilityOnDate | null) => void;
  setActiveStepperStep: (d: number) => void;
  suggestedDate: Date | null;
}

export const useResetPreviousSteps = ({
  area,
  selectedDate,
  setSelectedDate,
  orderType,
  setOrderType,
  setActiveStepperStep,
  setAvailability,
  suggestedDate,
}: Params): void => {
  const dispatch = useDispatch();
  const pasData = usePasData();
  const sessionLock = useSelector(selectSessionLockType);
  const [initializing, setInitializing] = useState<boolean>(
    !isPasEmpty(pasData),
  );
  const dateGuard = selectedDate ? formatSQLDate(selectedDate) : '';

  useEffect(() => {
    setActiveStepperStep(lockStepMapper[sessionLock]);
  }, [sessionLock, setActiveStepperStep]);

  useEffect(() => {
    if (initializing || isLockExist(sessionLock) || isNull(area)) {
      return;
    }
    setSelectedDate(null);
    setAvailability(null);
    dispatch(dateSelection.reset());
    setActiveStepperStep(dateStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, dispatch]);

  useEffect(() => {
    if (initializing || isLockExist(sessionLock)) {
      return;
    }
    if (isNull(suggestedDate)) {
      setOrderType(OrderType.NotSpecified);
    }

    dispatch(storeSelector.reset());
    setActiveStepperStep(orderTypeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateGuard, dispatch]);

  useSkipMountEffect(() => {
    if (
      orderType !== pasData.orderType ||
      selectedDate?.toJSON() !== pasData.date?.toJSON() ||
      area !== pasData.area
    )
      setInitializing(false);
  }, [orderType, selectedDate, area]);
};
