import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsUpMd = (): boolean =>
  useMediaQuery(useTheme().breakpoints.up('md'));

export const HideUpMd: FC = ({ children }) => {
  const isUpMd = useIsUpMd();
  return isUpMd ? null : <>{children}</>;
};
