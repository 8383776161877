import {
  Account,
  EditAccount,
  FullAccount,
} from 'components/SignUp/account.types';
import {
  AccountMemberType,
  AddressTypes,
  GenderType,
  RegistrationSource,
} from 'components/SignUp/signup.enum';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';

export function fillLegacyAccountDefaultParams(
  account: Account | EditAccount,
): FullAccount {
  return {
    ...(pickBy(
      {
        company: '',
        addressType: AddressTypes.NotSpecified,
        address1: '',
        address2: '',
        areaOther: '',
        cityOther: '',
        stateID: 0,
        stateOther: '',
        countryID: 0,
        homePhone: '',
        cellPhone: '',
        referredByEmail: '',
        gender: GenderType.NotSpecified,
        mailingListSubscriptionDate: '1/1/0001 12:00:00 AM',
        memberType: AccountMemberType.FullMember,
        subscribeToSMSList: false,
        registrationSource: RegistrationSource.Desktop,
      },
      isNotUndefined,
    ) as Required<FullAccount>),
    ...account,
  };
}
