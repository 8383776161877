import * as TK from 'containers/SignupContainer/translations/locale.constants';
import * as yup from 'yup';
import { SignupData } from 'types/forms/signup-data-new';
import i18next from 'i18next';

const nameRegExp = new RegExp(/^[a-zA-Z]*$/);
const phoneNumberRegExp = new RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
);
const birthMonthRegExp = new RegExp(/^(1[0-2]|[1-9])$/);
const passwordValidation = new RegExp(/^(?=.*[!@#$%^&*])(?=.{8,})/);
export const signupFormSchema: yup.SchemaOf<SignupData> = yup.object().shape({
  firstName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_FIRST_NAME_SPL_CHARS))
    .required(i18next.t(TK.ERROR_REQUIRED)),
  lastName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_LAST_NAME_SPL_CHARS))
    .required(i18next.t(TK.ERROR_REQUIRED)),
  email: yup
    .string()
    .email(i18next.t(TK.SIGNUP_EMAIL_NOT_VALID_ERROR_TEXT))
    .required(i18next.t(TK.ERROR_REQUIRED)),
  birthDay: yup.string().required(i18next.t(TK.ERROR_REQUIRED)),
  birthMonth: yup
    .string()
    .matches(birthMonthRegExp, i18next.t(TK.ERROR_REQUIRED))
    .required(i18next.t(TK.ERROR_REQUIRED)),
  birthYear: yup.string().notRequired(),
  phoneNumber: yup
    .string()
    .required(i18next.t(TK.ERROR_REQUIRED))
    .matches(phoneNumberRegExp, i18next.t(TK.ERROR_PHONE_NUMBER_INVALID)),
  password: yup
    .string()
    .min(8, i18next.t(TK.SIGNUP_PASSWORD_MINIMUM))
    .matches(passwordValidation, i18next.t(TK.SIGNUP_PASSWORD_INVALID))
    .required(i18next.t(TK.ERROR_REQUIRED)),
  verifyPassword: yup
    .string()
    .required(i18next.t(TK.ERROR_REQUIRED))
    .oneOf([yup.ref('password')], i18next.t(TK.SIGNUP_VERIFY_PASSWORD_MATCH)),
});
