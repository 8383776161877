import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const title: ThemedSxProps = {
  display: 'flex',
  width: '100%',
  maxWidth: '50%',
  height: '32px',
  marginBottom: '32px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const item: ThemedSxProps = {
  width: '100px',
  height: '32px',
};
