import { Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import React from 'react';

type RenderWhenInViewType = {
  children: React.ReactNode;
  rootMargin?: string;
  initialView?: boolean;
};

export const RenderWhenInView = ({
  children,
  rootMargin = '100px',
  initialView = false,
}: RenderWhenInViewType): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin,
    initialInView: initialView,
  });

  return <Box ref={ref}>{inView && children}</Box>;
};
