import {
  HEADER_LOGO,
  MOBILE_CLOSE_SEARCH_ARIA_LABEL,
  MOBILE_MENU_ARIA_LABEL,
  MOBILE_SEARCH_ARIA_LABEL,
} from 'components/Header/translations/locale.constants';
import { headerMenusCommonLocale } from 'components/Header/Menus/translations/header-menus.common.locale';
import { headerNavigationCommonLocale } from 'components/Header/HeaderNavigation/translations/header-navigation.common.locale';

export const headerCommonLocale = {
  [HEADER_LOGO]: 'Edible Arrangements logo',
  [MOBILE_MENU_ARIA_LABEL]: 'Open the main menu',
  [MOBILE_SEARCH_ARIA_LABEL]: 'Search for arrangement',
  [MOBILE_CLOSE_SEARCH_ARIA_LABEL]: 'Close search for arrangement task',
  ...headerMenusCommonLocale,
  ...headerNavigationCommonLocale,
};
