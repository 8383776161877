import { Grow, Rating } from '@mui/material';
import {
  checkIsBlocked,
  selectReviewsInfo,
} from 'components/PDP/Details/components/Reviews/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const ReviewsRating: FC = () => {
  const { rating } = useSelector(selectReviewsInfo);
  const loading = useSelector(checkIsBlocked);

  return (
    <Grow in={!loading}>
      <Rating value={rating} readOnly />
    </Grow>
  );
};
