import { Color } from 'components/common/Buttons/enums/color.enum';
import {
  FormControlLabelProps,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
} from '@mui/material';
import { Size } from 'components/common/Buttons/enums/size.enum';
import { StyledProps } from 'types/mui/styled-props.interface';
import CircleIcon from '@mui/icons-material/Circle';
import styled from '@emotion/styled/macro';

interface RadioIconProps {
  size?: Size;
}

export const RadioIcon = styled(CircleIcon)(
  ({ size = Size.MEDIUM }: RadioIconProps) => ({
    width: size === Size.MEDIUM ? 12 : 8,
  }),
);

export const Radio = styled(MuiRadio)({
  transition: '.15s',
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
  },
});

interface LabelProps extends StyledProps, FormControlLabelProps {
  checked?: boolean;
  disabled?: boolean;
  color?: Color;
  size?: Size;
}

export const FormControlLabel = styled(MuiFormControlLabel)(
  ({
    theme,
    checked,
    disabled,
    color = Color.PRIMARY,
    size = Size.MEDIUM,
  }: LabelProps) =>
    !theme
      ? {}
      : {
          ...theme.typography.body2,
          display: 'flex',
          padding: theme.spacing(size === Size.MEDIUM ? 2.5 : 2),
          cursor: 'pointer',
          position: 'relative',
          justifyContent: 'space-between',
          margin: 0,

          '&:before': {
            transition: '.15s',
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: `1px solid ${theme.palette.other.outlineBorder}`,

            ...(disabled && {
              borderColor: theme.palette.action.disabledBackground,
            }),
            ...(checked && {
              borderWidth: 2,
              borderColor:
                color === Color.PRIMARY
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
            }),
          },

          ...(disabled && {
            '& .MuiTypography-root': {
              color: `${theme.palette.text.disabled}`,
            },
          }),
          ...(!checked && {
            [Radio.toString()]: {
              opacity: 0,
              height: 0,
            },
            '&:hover, &:focus': {
              [Radio.toString()]: !disabled && {
                opacity: 1,
                color: theme.palette.action.disabled,
              },
            },
            '&:hover:before, &:focus:before': !disabled && {
              borderColor: theme.palette.action.active,
            },
          }),
        },
);
