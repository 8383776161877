import * as TK from 'components/Authentication/translations/locale.constants';
import { Alert, Collapse, Typography } from '@mui/material';
import { LegacyLogInValidationStatus } from 'api/auth-legacy/validate.misc';
import { checkIsAlreadyExist } from 'components/SignUp/feature/selectors';
import {
  checkIsLockedType,
  checkIsTruthyType,
  getValidationType,
} from 'components/Authentication/LogIn/feature/selectors';
import { getValidationMessage } from 'components/Authentication/translations/get-validation-message';
import { useSelector } from 'react-redux';
import Info from '@mui/icons-material/Info';
import Lock from '@mui/icons-material/Lock';
import React, { FC } from 'react';
import i18next from 'i18next';

export const RewardAlert: FC = () => {
  const status = useSelector(getValidationType);
  const truthy = useSelector(checkIsTruthyType);
  const locked = useSelector(checkIsLockedType);
  const alreadyExist = useSelector(checkIsAlreadyExist);

  return (
    <>
      {alreadyExist && (
        <Typography
          variant="subtitle2"
          sx={{
            width: 1,
            mt: 1,
            mb: 3,
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {i18next.t(TK.SIGNUP_EMAIL_ALREADY_EXIST)}
        </Typography>
      )}
      <Collapse in={!truthy && status !== LegacyLogInValidationStatus.NONE}>
        <Alert
          variant={locked ? 'filled' : 'standard'}
          icon={locked ? <Lock /> : <Info />}
          severity="error"
          sx={{ mb: 3, display: 'inline-flex' }}
        >
          {locked && (
            <Typography variant="subtitle2" mb={0.5}>
              {i18next.t(TK.AUTH_LOGIN_ERR_LOCK_HEADER)}
            </Typography>
          )}
          <Typography variant="body2">
            {i18next.t(getValidationMessage(status))}
          </Typography>
        </Alert>
      </Collapse>
    </>
  );
};
