import { AvailabilitySessionParams } from 'redux/session/availability/availability.types';
import { HttpStatusCode } from 'enums/http-status-code';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { legacySessionApiService } from 'api/legacy-session';
import { parseAxiosError } from 'utils/errors';
import { sessionApiService } from 'api/session/session.api.service';

export const getAvailabilitySession = createAsyncThunk(
  'session-availability/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return await sessionApiService.getAvailability();
    } catch (err) {
      const returnError = parseAxiosError(err);

      if (
        [
          HttpStatusCode.GRACEFULLY_FORBIDDEN,
          HttpStatusCode.FORBIDDEN,
        ].includes(returnError.status)
      ) {
        await legacySessionApiService.reset();
      }

      return rejectWithValue(returnError.message);
    }
  },
);

export const updateAvailabilitySession = createAsyncThunk(
  'session-availability/update',
  async (payload: AvailabilitySessionParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await legacySessionApiService.update(payload);
      await dispatch(getAvailabilitySession());
      return res;
    } catch (err) {
      const returnError = parseAxiosError(err);
      return rejectWithValue(returnError.message);
    }
  },
);

export const resetAvailabilitySession = createAsyncThunk(
  'session-availability/reset',
  async (_, { rejectWithValue }) => {
    try {
      return await legacySessionApiService.reset();
    } catch (err) {
      const returnError = parseAxiosError(err);
      return rejectWithValue(returnError.message);
    }
  },
);
