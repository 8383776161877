import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';

export const baseExpandedContainer = (isSelected: boolean): ThemedSxProps => ({
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  p: 2,
  border: 1,
  backgroundColor: colors.white,
  width: '100%',
  position: 'relative',
  mb: 2,
  borderColor: isSelected ? colors.primaryRed : colors.greyBorder,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

export const baseExpandedMainIcon: ThemedSxProps = {
  width: 56,
  height: 56,
  marginRight: 2,
};

export const checkIcon = (isMobile: boolean): ThemedSxProps => ({
  color: colors.primaryRed,
  position: 'absolute',
  top: isMobile ? '10px' : '50%',
  right: isMobile ? '10px' : '16px',
  transform: isMobile ? 'none' : 'translateY(-50%)',
});

export const mainContainer = (isMobile: boolean): ThemedSxProps => ({
  display: 'flex',
  gap: 1,
  flexWrap: 'wrap',
  flexGrow: 2,
  ml: isMobile ? 0 : 10,
  width: '100%',
  justifyContent: isMobile ? 'center' : 'flex-start',
});

export const expandedCostSelectorContainer: ThemedSxProps = {
  marginRight: '5%',
};

export const costSlider: ThemedSxProps = { width: '100%' };

export const expandedReasonSelectorContainer = (
  isMobile: boolean,
): ThemedSxProps => ({
  overflowY: 'hidden',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  justifyContent: isMobile ? 'center' : 'flex-start',
});

export const giftFinderSelectorContainer: ThemedSxProps = {
  backgroundColor: colors.white,
  '@media (min-width: 600px) and (max-width: 980px)': {
    minHeight: '106px',
  },
  cursor: 'pointer',
};

export const giftFinderSelectorMainImage: ThemedSxProps = {
  width: 56,
  height: 56,
  marginRight: 2,
};

export const giftFinderSelectorCheckIcon: ThemedSxProps = {
  color: colors.primaryRed,
  position: 'absolute',
  top: '50%',
  right: '16px',
  transform: 'translateY(-50%)',
};
