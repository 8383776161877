export const DELIVERY = 'pdp.delivery_method.hand_delivery';
export const PICK_UP = 'pdp.delivery_method.pickup';
export const DELIVERY_DESCRIPTION =
  'pdp.delivery_method.hand_delivery_description';
export const AVAILABLE_FOR_OTHER_DATES =
  'pdp.delivery_method.available_for_other_dates';
export const AVAILABLE_FOR_OTHER_PRODUCTS =
  'pdp.delivery_method.available_for_other_products';
export const DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES =
  'pdp.delivery_method.delivery_not_available_for_date_suggest_dates';
export const DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS =
  'pdp.delivery_method.delivery_not_available_for_date_suggest_products';
export const OTHER_PRODUCTS_AVAILABLE =
  'pdp.delivery_method.other_products_available';
export const PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES =
  'pdp.delivery_method.pickup_not_available_for_date_suggest_dates';
export const CHOOSE_ANOTHER_DATE =
  'pdp.delivery_method.choose_another_date_for_picup';
export const PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS =
  'pdp.delivery_method.pickup_not_available_for_date_suggest_products';
export const PICKUP_IN_STORE = 'pdp.delivery_method.pickup_in_store';
export const PICKUP_DESCRIPTION = 'pdp.delivery_method.hand_pickup_description';
export const FREE = 'pdp.delivery_method.free';
