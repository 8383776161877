import { emptyFunction } from 'utils/emptyFunction';
import { selectCartCount } from 'components/Cart/feature/selectors';
import { selectIsCartCountBlocked } from 'redux/cart/cart.selectors';
import { selectIsRecipientLock } from 'redux/session/availability/availability.selectors';
import { useSelector } from 'react-redux';
import React, { FC, createContext, useEffect, useState } from 'react';

export const LOCK_GUIDE_KEY = 'lockGuide';

interface StorageValue {
  isEmptyCart: boolean;
  isOpened: boolean;
}

function setStorageValue(value: StorageValue): void {
  localStorage.setItem(LOCK_GUIDE_KEY, JSON.stringify(value));
}

export const LockGuideContext = createContext<{
  isOpened: boolean;
  close: () => void;
}>({
  isOpened: false,
  close: emptyFunction,
});

export const LockGuideProvider: FC = ({ children }) => {
  const cartCount = useSelector(selectCartCount);
  const isCartCountBlocked = useSelector(selectIsCartCountBlocked);
  const isRecipientLock = useSelector(selectIsRecipientLock);

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isCartCountBlocked) {
      return;
    }

    const storageStringValue = localStorage.getItem(LOCK_GUIDE_KEY);

    if (cartCount === 0 || !storageStringValue) {
      setIsOpened(true);
      setStorageValue({
        isOpened: true,
        isEmptyCart: cartCount === 0,
      });
      return;
    }

    const previousState = JSON.parse(storageStringValue) as StorageValue;

    if (!previousState.isOpened) {
      setIsOpened(false);
      return;
    }
    if (previousState.isEmptyCart) {
      setIsOpened(true);
      setStorageValue({
        isOpened: true,
        isEmptyCart: false,
      });
      return;
    }

    setIsOpened(false);
    setStorageValue({
      isOpened: false,
      isEmptyCart: false,
    });
  }, [cartCount, isCartCountBlocked]);

  const close = () => {
    setIsOpened(false);
    setStorageValue({
      isOpened: false,
      isEmptyCart: false,
    });
  };

  return (
    <LockGuideContext.Provider
      value={{ isOpened: isOpened && isRecipientLock, close }}
    >
      {children}
    </LockGuideContext.Provider>
  );
};
