import * as styles from 'containers/SignupContainer/sign-up-container.styles';
import { AccountSignUp } from 'components/SignUp';
import { Grid } from '@mui/material';
import React, { FC } from 'react';

export const SignUpContainer: FC = () => (
  <Grid sx={styles.containerWrapper}>
    <Grid sx={styles.main}>
      <AccountSignUp />
    </Grid>
  </Grid>
);
