import { SessionLock } from 'enums/session-lock';

export function isLockExist<T extends SessionLock>(
  lock: SessionLock | T,
): lock is SessionLock {
  return [
    SessionLock.Recipient,
    SessionLock.Store,
    SessionLock.OrderType,
    SessionLock.ZipDate,
  ].includes(lock);
}
