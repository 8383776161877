import { Area } from 'api/area/types/area.interface';
import { LegacyAreaClassificationType } from 'api/area/enums/legacy-area-classification-type.enum';

export function getInvalidArea(areaName: string): Area {
  return {
    name: areaName,
    id: 0,
    state: '',
    stateCode: '',
    countryCode: '',
    culture: '',
    availability: {
      delivery: false,
      pickup: false,
      shipment: false,
    },
    cities: [],
    classification: LegacyAreaClassificationType.NotSpecified,
  };
}
