import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { deserialize } from 'containers/PDPContainer/feature/pas/utils/deserialize';
import { isPickup } from 'utils/is-order-type';

export const selectPasData = createSelector(
  (state: RootState) => state.pas,
  (pas) => deserialize(pas),
);

export const selectIsPickup = createSelector(
  ({ pas }: RootState) => pas.orderType,
  (orderType) => isPickup(orderType),
);
