import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const wrapper: SxProps = {
  marginTop: { md: '40px', sm: '-260px', xs: '0' },
  marginBottom: { md: '30px', sm: '0', xs: '0' },
  display: { md: 'flex', sm: 'block', xs: 'contents' },
  '& .giftImg': {
    border: `1px solid ${colors.borderBlack}`,
    width: { md: '120px', sm: '120px', xs: '80px' },
    height: { md: '186px', sm: '186px', xs: '124px' },
  },
};

export const orderDetails: SxProps = {
  width: { md: '57%', sm: '100%', xs: '90%' },
  marginRight: '30px',
  padding: { md: '0', sm: '0 40px', xs: '0' },
  marginLeft: { md: '0', sm: '0', xs: '20px' },
  display: 'inline-table',
  marginTop: { md: '0', sm: '320px', xs: '30px' },
};

export const orderNumCont: SxProps = {
  display: 'flex',
};

export const orderNumberTxtStyle: SxProps = {
  fontWeight: '600',
  fontSize: '16px',
  width: { md: '82%', sm: '84%', xs: '67%' },
  display: { md: 'flex', sm: 'flex', xs: 'block' },
};

export const itemTxt: SxProps = {
  textAlign: 'right',
  width: '45%',
  fontWeight: '600',
  fontSize: '14px',
  margin: '6px 0',
  float: 'right',
};

export const totalStyle: SxProps = {
  fontSize: '12px',
  textAlign: { sm: 'left', xs: 'right' },
  marginTop: { sm: '10px', xs: '-9%' },
};

export const orderNumberStyle: SxProps = {
  fontWeight: '600',
  margin: { md: '2px 10px', sm: '2px 10px', xs: '2px 0' },
};

export const trackOrderTxt: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  textDecoration: 'underline',
};

export const productSeparator: SxProps = {
  width: '100%',
  height: 0,
  border: `1px solid ${colors.dividerBlack}`,
  mt: 2,
  mb: 2,
};

export const product: SxProps = {
  display: 'flex',
  marginBottom: '20px',
  mt: 2,
  '& svg': {
    alignSelf: 'center',
    width: 'auto',
    height: '40px',
    color: colors.black,
  },
  '& img': {
    width: { md: '120px', sm: '120px', xs: '80px' },
    height: { md: '120px', sm: '120px', xs: '80px' },
    border: `1px solid ${colors.borderBlack}`,
  },
  '& .productDescription': {
    display: { md: 'flex', sm: 'flex', xs: 'block' },
  },
};
export const childProduct: SxProps = {
  display: 'flex',
  marginBottom: '20px',
  mt: 2,
  '& img': {
    width: { md: '80px', sm: '80px', xs: '48px' },
    height: { md: '80px', sm: '80px', xs: '48px' },
    border: `1px solid ${colors.borderBlack}`,
  },
  '& .productDescription': {
    display: { md: 'flex', sm: 'flex', xs: 'block' },
  },
  '& .fontStyle': {
    fontSize: '12px',
  },
  '& .arrowStyle': {
    width: '24px',
    height: '24px',
    border: 'none',
    margin: '25px 15px 0px 0',
  },
};
export const combinationProduct: SxProps = {
  display: 'flex',
  marginBottom: '20px',
  mt: 2,
  '& img': {
    width: { md: '60px', sm: '60px', xs: '48px' },
    height: { md: '60px', sm: '60px', xs: '48px' },
    border: `1px solid ${colors.borderBlack}`,
  },
  '& .productDescription': {
    display: { md: 'block', sm: 'flex', xs: 'block' },
    marginTop: { md: '16px', sm: '16px', xs: '0' },
  },
  '& .fontStyle': {
    fontSize: '12px',
  },
};
export const storeContact: SxProps = {
  color: colors.primaryRed,
  marginTop: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: ['start', 'center'],
  flexDirection: ['column', 'row'],
  '& svg': {
    width: '28px',
  },
  '& h6': {
    marginLeft: '10px',
    fontWeight: 600,
  },
  '& a': {
    color: colors.primaryRed,
    display: 'flex',
  },
};

export const nameSpace: SxProps = () => ({
  marginRight: { md: '0', sm: '10px', xs: '10px' },
  marginTop: { md: '8px', sm: '8px', xs: '0' },
  width: { md: '74%', sm: '65%', xs: '100%' },
  fontSize: { sm: '14px', xs: '12px' },
});

export const productNameStyle: SxProps = () => ({
  marginRight: { md: '0', sm: '10px', xs: '10px' },
  marginTop: { md: '8px', sm: '8px', xs: '0' },
  width: { md: '74%', sm: '65%', xs: '100%' },
});

export const nameStyle: SxProps = () => ({
  marginRight: '5px',
  marginTop: { md: '8px', sm: '8px', xs: '0' },
  width: '100%',
  fontSize: '12px',
});

export const giftCard: SxProps = {
  width: { md: '79%', sm: '82%', xs: '73%' },
};
export const giftCardWidth: SxProps = {
  width: '100%',
};
export const orderCont: SxProps = {
  float: { md: 'right', sm: 'left', xs: 'left' },
  width: { md: '100%', sm: '98%', xs: '98%' },
};

export const giftCardTxt: SxProps = {
  height: '100px',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '164%',
  color: colors.disabledBlack,
  width: { md: '85%', sm: '85%', xs: '100%' },
  display: 'contents',
  '& pre': {
    fontFamily: fonts.poppins,
    whiteSpace: 'pre-wrap',
  },
};
export const giftCardTitle: SxProps = {
  fontWeight: '600',
  fontSize: { md: '14px', sm: '14px', xs: '12px' },
  lineHeight: '143%',
};
export const giftCardMsg: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  color: colors.disabledBlack,
  marginBottom: '5px',
};

export const giftCardPrice: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  textAlign: 'right',
  marginTop: { md: '-15px', sm: '-15px', xs: '100px' },
};
export const giftCardFreeTxt: SxProps = {
  fontWeight: '600',
  fontSize: '12px',
  textAlign: 'right',
  marginTop: { md: '-15px', sm: '-15px', xs: '0' },
};
export const splText: SxProps = {
  fontWeight: '600',
  fontSize: '14px',
};
export const qtyStyle: SxProps = {
  fontWeight: '400',
  fontSize: '12px',
  color: colors.activeBlack,
  marginTop: { md: '40px', sm: '25px', xs: '40px' },
};

export const sizeStyle: SxProps = {
  fontWeight: '400',
  fontSize: '14px',
  color: colors.disabledBlack,
  marginTop: { md: '0', sm: '-15px', xs: '-50px' },
  position: { xs: 'absolute' },
  width: { md: '30%', sm: '60%', xs: '55%' },
};

export const addOnSizeStyle: SxProps = {
  color: colors.disabledBlack,
  marginTop: { md: '-10px', sm: '-15px', xs: '-50px' },
  position: { xs: 'absolute' },
  width: { md: '30%', sm: '60%', xs: '55%' },
};

export const accordianText: ThemedSxProps = {
  padding: 0,
  margin: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& svg': {
    color: colors.black,
  },
};

export const noSpace: ThemedSxProps = {
  padding: 0,
  margin: 0,
};

export const noSpaceCYO: ThemedSxProps = {
  padding: 0,
  marginBottom: '80px',
};

export const qtyCont: SxProps = {
  fontWeight: '400',
  fontSize: '12px',
  color: colors.activeBlack,
  textAlign: 'right',
  width: { sm: '100%', xs: '55%' },
  marginTop: { sm: '-15px', xs: '-17px' },
};

export const disclaimerTextContainer: SxProps = {
  width: { md: '610px', sm: '610px', xs: '320px' },
  height: '56px',
  padding: '0 0 24px 0',
  border: '0 0 1px 0',
  gap: '4px',
  marginTop: '24px',
};

export const disclaimerText: SxProps = {
  fontFamily: fonts.poppins,
  fontSize: '10px',
  fontWeight: '400',
  letterSpacing: '0',
  textAlign: 'left',
};

export const greetingStyle: SxProps = {
  '& .greeting_txt': {
    marginLeft: '5px',
    width: { md: '75%', sm: '75%', xs: '91%' },
  },
  '& .giftCardPrice': {
    marginTop: { md: '-55px', sm: '-55px', xs: '15px' },
  },
};

export const multiRecipientCount: SxProps = {
  textAlign: 'left',
  width: '45%',
  fontWeight: '600',
  fontSize: '14px',
  margin: '6px 0',
  float: 'left',
};

export const addonPosStyle: SxProps = {
  display: 'flex',
  marginBottom: '20px',
  marginTop: { md: '-60px', sm: '-70px', xs: '-10px' },
  marginLeft: { md: '137px', sm: '137px', xs: '95px' },
};

export const upgradePosStyle: SxProps = {
  marginLeft: { md: '137px', sm: '137px', xs: '95px' },
};
export const upgrade: SxProps = {
  display: 'flex',
  mt: 2,
  mb: 3,
  gap: '16px',
  width: '100%',
  '& img': {
    width: { md: '60px', sm: '60px', xs: '48px' },
    height: { md: '60px', sm: '60px', xs: '48px' },
    border: `1px solid var(--other-outline-border, ${colors.borderBlack})`,
  },
};
export const addonSizeTxt: SxProps = () => ({
  fontSize: '12px',
  color: colors.disabledBlack,
});
export const colorTxt: SxProps = () => ({
  color: colors.black,
});

export const freeProductStyle: SxProps = {
  marginLeft: { md: '120px', sm: '120px', xs: '72px' },
};

export const freeCartProductStyle: SxProps = {
  marginLeft: { md: 0, sm: 0, xs: 0 },
};

export const freeProductLayout: SxProps = {
  flexDirection: ['column', 'row'],
};

export const freeProductPrice: SxProps = {
  flexDirection: ['row', 'column'],
  justifyContent: ['space-between'],
  alignItems: 'end',
  paddingTop: '10px',
  width: { md: '70%', sm: '70%', xs: '55%' },
};

export const freeProductPriceCart: SxProps = {
  flexDirection: ['row', 'column'],
  justifyContent: { md: 'space-between', sm: 'flex-end', xs: 'flex-end' },
  alignItems: 'end',
  paddingTop: '10px',
  width: { md: '100%', sm: '70%', xs: '55%' },
};

export const freeProductMobileView: SxProps = {
  justifyContent: ['space-between'],
  width: { md: '20%', sm: '22%', xs: '98%' },
};

export const freeProductMobileViewConfirmation: SxProps = {
  justifyContent: ['space-between'],
  width: { md: '20%', sm: '17%', xs: '98%' },
};

export const freeProductMobileViewCart: SxProps = {
  justifyContent: ['flex-end'],
  width: { md: '20%', sm: '22%', xs: '98%' },
};

export const freeProductSizeStyle: SxProps = {
  fontWeight: '400',
  fontSize: '14px',
  color: colors.disabledBlack,
  marginTop: '10px',
  width: { md: '30%', sm: '60%', xs: '55%' },
};

export const freeQtyStyle: SxProps = {
  fontWeight: '400',
  fontSize: '12px',
  color: colors.activeBlack,
  marginLeft: '0',
  marginTop: { md: '48px', sm: '48px', xs: '40px' },
};

export const freeQtyStyleConfirmation: SxProps = {
  fontWeight: '400',
  fontSize: '12px',
  color: colors.activeBlack,
  margin: '0 !important',
  marginLeft: '6px !important',
  marginTop: {
    md: '48px !important',
    sm: '48px !important',
    xs: '40px !important',
  },
};

export const freeProductNameStyle: SxProps = () => ({
  marginRight: { md: '0', sm: '10px', xs: '10px' },
});

export const freeStyle: SxProps = () => ({
  float: 'left',
});

export const freeProductColorTxt: SxProps = () => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '600',
});

export const productPriceStyle: SxProps = () => ({
  marginTop: '10px',
});

export const freeProductTitleStyle: SxProps = () => ({
  color: colors.black,
  bgcolor: 'aliceblue',
  height: { md: 'auto', sm: '68px', xs: '88px' },
  paddingLeft: '15px',
  paddingTop: '10px',
  width: { md: '72%', sm: '78%', xs: '100%' },
});

export const freeProductTitleStyleConfirmation: SxProps = () => ({
  color: colors.black,
  bgcolor: 'aliceblue',
  height: { md: 'auto', sm: '68px', xs: '88px' },
  padding: '12px 16px 12px 16px',
  width: { md: '79%', sm: '76%', xs: '100%' },
});
