import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';

export const dialog: ThemedSxProps = {
  paddingLeft: '0px',
  borderRadius: '0px !important',
};
export const windowWrappercon: ThemedSxProps = {
  borderRadius: '0px !important',
  minWidth: { md: '700px', sm: '688px', xs: '360px' },
};

export const title: ThemedSxProps = {
  textAlign: 'right',
  padding: '0px !important',
};
export const closeBtn: ThemedSxProps = {
  position: 'absolute !important',
  marginLeft: '-50px',
  marginTop: { md: '20px', sm: '20px', xs: '15px' },
  height: { md: '24px', sm: '24px', xs: '18px' },
  width: { md: '24px', sm: '24px', xs: '18px' },
};

export const content: ThemedSxProps = (theme) => ({
  padding: theme.spacing(0, 0),
  textAlign: 'left',
  border: `${colors.primaryRed} 15px solid`,
});

export const drawContent: ThemedSxProps = {
  border: `${colors.primaryRed} 15px solid`,
};
