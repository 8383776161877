import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const CarouselWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  textAlign: 'center',
  boxSizing: 'content-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  padding: '0px',
  [breakpoints.down('md')]: {
    padding: '0px',
    maxWidth: '100%',
    marginLeft: '0px',
    marginTop: '0px',
  },
  [breakpoints.down('sm')]: {
    padding: '0px',
    maxWidth: '100%',
    marginLeft: '0px',
  },
}));

export const CarouselContainer = styled('div')(() => ({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 0',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
          paddingLeft: '15px',
          width: '100%',
        },
      },
    },
    '& .slick-arrow': {
      color: colors.activeBlack,
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
    },
    '& .slick-next': {
      right: '-10px',
    },
    '& .slick-prev': {
      left: '-10px',
    },
  },
}));

export const skinnyBanner: ThemedSxProps = {
  position: 'relative',
  width: '100%',
  textDecoration: 'none',
  fontFamily: fonts.poppins,
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  textAlign: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
};

export const skinnyBannerText: ThemedSxProps = (theme) => ({
  font: 'inherit',
  fontWeight: 400,
  color: theme.palette.common.black,
  marginLeft: '5px',
  marginRight: '5px',
  height: 'auto',
});

export const CarouselContainerSx: ThemedSxProps = (theme) => ({
  margin: theme.spacing(0, 'auto', 0, 'auto'),
  maxWidth: [350, 725, 1060],
  paddingLeft: '4px',
  paddingRight: '4px',
});
