import { CarouselContainer } from 'components/Home/BannerProductCarousel/styles';
import { styled } from '@mui/material';

export const MarketplaceSuggestionsCarouselContainer = styled(
  CarouselContainer,
)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginLeft: '1.25rem',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
  },
  '& .slick-slider': {
    '& .slick-list': {
      '& .slick-track': {
        [theme.breakpoints.up('xs')]: {
          margin: 0,
        },
        marginLeft: 0,
        '& .slick-slide': {
          marginLeft: 0,
          marginRight: '1rem',
          '& img': {
            [theme.breakpoints.up('xs')]: {
              width: '120px',
              height: '120px',
            },
            [theme.breakpoints.up('sm')]: {
              width: '138px',
              height: '138px',
            },
          },
        },
      },
    },
  },
}));
