/* eslint-disable @typescript-eslint/no-explicit-any */
import * as TK from 'containers/SignupContainer/translations/locale.constants';
import * as styles from 'components/SignUp/Styles/sign-up-form.styles';
import { SignupData as SignupFormData } from 'types/forms/signup-data-new';
import { TextField } from '@mui/material';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import React, { FC } from 'react';
import i18next from 'i18next';

interface OwnProps {
  register: UseFormRegister<SignupFormData>;
  watch: UseFormWatch<SignupFormData>;
  error: boolean;
  errorText: string | null;
}

type Props = OwnProps;

export const PasswordField: FC<Props> = ({
  register,
  watch,
  error,
  errorText,
}) => (
  <TextField
    {...register('password', { required: true })}
    sx={styles.passwordChecker}
    type="password"
    label={i18next.t(TK.SIGNUP_PASSWORD_TEXT)}
    placeholder="Password"
    error={error}
    helperText={errorText || null}
    data-test="sign-up-password"
    value={watch('password')}
  />
);
