import { HeaderState, Item, Status } from 'redux/header/header.types';
import { createSlice } from '@reduxjs/toolkit';
import { fetchHeader } from 'redux/header/header.actions';

const initialState: HeaderState = {
  data: [] as Item[],
  status: null,
};

const headerSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeader.pending, () => ({
        ...initialState,
        status: Status.FETCHING,
      }))
      .addCase(fetchHeader.rejected, () => ({
        ...initialState,
        status: Status.ERROR,
      }))
      .addCase(fetchHeader.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.SUCCESS;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: headerReducer } = headerSlice;

export const headerActions = {
  ...headerReducer,
  fetchHeader,
};
