import { Box, Button, Typography } from '@mui/material';
import {
  UNALERT_BUTTON,
  UNALERT_TITLE,
} from 'components/PDP/Arrangement/UnavailableBySchedule/translations/constants';
import { checkIsUnavailableBySchedule } from 'components/PDP/Arrangement/feature/selector';
import { useRedirectAfterInactiveDelay } from 'components/PDP/Arrangement/UnavailableBySchedule/use-redirect-after-inactive-delay';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const UnavailableBySchedule: FC = () => {
  useRedirectAfterInactiveDelay();
  const unavailable = useSelector(checkIsUnavailableBySchedule);

  if (!unavailable) {
    return null;
  }

  return (
    <Box
      bgcolor={({ palette }) => palette.custom.bodyBg}
      p={2}
      borderRadius={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      role="alert"
    >
      <Typography variant="subtitle2" mb={2}>
        {i18next.t(UNALERT_TITLE)}
      </Typography>
      <Button
        size="medium"
        variant="contained"
        color="secondary"
        href="/all-fruit-arrangements"
        fullWidth
      >
        {i18next.t(UNALERT_BUTTON)}
      </Button>
    </Box>
  );
};
