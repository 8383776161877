import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { getAvailabilityOnDate } from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/actions';

const orderTypeInitialState: OrderTypeAvailability = {
  available: false,
  dates: [],
  arrangementsAvailable: false,
  message: '',
};

const initialState = InitialStateFactory.create<AvailabilityOnDate>({
  pickup: orderTypeInitialState,
  shipment: orderTypeInitialState,
  delivery: {
    ...orderTypeInitialState,
    fee: 0,
    isFlex: false,
  },
  deliveryFee: 0,
});

const slice = createSlice({
  name: 'pdp/date-selection',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: createExtraReducers(initialState, getAvailabilityOnDate),
});

export const { reducer: dateAvailabilityReducer } = slice;
export const dateSelection = slice.actions;
