import { Avatar, Box } from '@mui/material';
import { ReasonStyledChip } from 'components/GiftFinder/chips/styles';
import React, { FC } from 'react';

interface Props {
  label: string;
  isSelected: boolean;
  imageSrc: string;
  onClick: () => void;
}

export const ReasonChip: FC<Props> = ({
  label,
  isSelected,
  imageSrc,
  onClick,
}) => (
  <ReasonStyledChip
    label={
      <Box display="flex" alignItems="center">
        <Avatar alt={label} src={imageSrc} />
        {label}
      </Box>
    }
    variant="outlined"
    isSelected={isSelected}
    onClick={onClick}
    data-test="plp-filter-chip"
  />
);
