import { AddressType } from 'types/cart.types';
import { AvailabilitySession } from 'redux/session/availability/availability.types';
import { Curbside } from 'enums/curbside';
import { Recipient } from 'redux/cart/cart.types';
import { formatToAmPmTime } from 'utils/date';

export function populateRecipientData(
  availabilitySession: AvailabilitySession,
): Recipient {
  const { cartRecipient } = availabilitySession;
  const [model, color] = cartRecipient?.isCurbside
    ? cartRecipient?.recipientNote.carInfo.split('|')
    : [];

  return {
    instructions: cartRecipient?.recipientNote.note || '',
    firstName: cartRecipient?.recipientContact?.firstName || '',
    lastName: cartRecipient?.recipientContact?.lastName || '',
    addressType:
      cartRecipient?.recipientContact?.addressType || AddressType.NotSpecified,
    address1: cartRecipient?.recipientContact?.address1 || '',
    address2: cartRecipient?.recipientContact?.address2 || '',
    company: cartRecipient?.recipientContact?.company || '',
    cityMlId: cartRecipient?.recipientContact?.cityMlId || 0,
    phone: cartRecipient?.recipientContact?.phone || '',
    cellPhone: cartRecipient?.recipientContact?.cellPhone || '',
    workPhone: cartRecipient?.recipientContact?.workPhone || '',
    occasionId: cartRecipient?.occasionId || 0,
    recipientEmail: cartRecipient?.recipientContact?.recipientEmail || '',
    upsellItems: [],
    cardMessage: cartRecipient?.cardMessage || '',
    fulfillmentDate: availabilitySession.fulfillmentDate || '',
    curbside: cartRecipient?.isCurbside ? Curbside.YES : Curbside.NO,
    carColor: color || '',
    carModel: model || '',
    pickupTime: availabilitySession.fulfillmentDate
      ? formatToAmPmTime(availabilitySession.fulfillmentDate)
      : '',
  };
}
