import {
  ITelemetryItem,
  RemoteDependencyData,
} from '@microsoft/applicationinsights-web';
import { isString } from 'utils/is-string';

interface DataType extends ITelemetryItem {
  baseType: typeof RemoteDependencyData.dataType;
  baseData: {
    target: string;
  };
}

const isDataType = (telemetry: ITelemetryItem): telemetry is DataType =>
  telemetry.baseType === RemoteDependencyData.dataType &&
  'baseData' in telemetry &&
  typeof telemetry?.baseData === 'object' &&
  'target' in telemetry.baseData &&
  isString(telemetry.baseData?.target);

const filterByOrigin =
  (origins: string[]) =>
  (telemetry: ITelemetryItem): boolean => {
    if (isDataType(telemetry)) {
      return !origins.some((origin) =>
        telemetry.baseData.target?.startsWith(origin),
      );
    }

    return true;
  };

const filterHostsSubdomains =
  (hosts: string[]) =>
  (telemetry: ITelemetryItem): boolean => {
    if (isDataType(telemetry)) {
      try {
        const targetHost = new URL(telemetry.baseData.target).host;
        return !hosts.some((host) => targetHost.endsWith(`.${host}`));
      } catch (e) {
        return true;
      }
    }
    return true;
  };

/**
 * @see {@link https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=snippet#telemetry-initializers}
 * If false is returned, the telemetry item isn't sent
 */
export const filterRedundantItems = (item: ITelemetryItem): boolean | void => {
  const filters: ((i: ITelemetryItem) => boolean)[] = [
    filterByOrigin([
      'https://get663.com/',
      'https://www.ediblearrangements.com/lFr51kNSbGyN/' /* Akamai */,
      'https://www.ediblearrangements.com/1VAOi',
      'https://www.ediblearrangements.com/lFr51kNSbGyN',
      'https://www.facebook.com/tr/',
      'https://d.p.ediblearrangements.com/track.php',
      'https://stats.g.doubleclick.net/',
      'https://www.google-analytics.com/',
      'https://pixel.mtrcs.samba.tv',
      'https://www.paypal.com/credit-presentment/log',
      'https://www.paypal.com/xoplatform/logger/',
      'https://dpm.demdex.net' /* Adobe */,
    ]),
    filterHostsSubdomains([
      'nanorep.co',
      'cdn77.org',
      'clarity.ms',
      'prmutv.co',
      'adnxs.com',
      'boldchat.com',
      'permutive.com',
      '2o7.net',
    ]),
  ];

  return !filters.some((filter) => !filter(item));
};
