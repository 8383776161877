import * as styles from 'components/common/Video/styles';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { ConstructedErrorImage } from 'components/common/Image/ConstructedErrorImage';
import { DefaultErrorImage } from 'components/common/Image/DefaultErrorImage';
import {
  VideoPlayer,
  iconContainer,
  root,
} from 'components/common/Image/styles';
import { useInView } from 'react-intersection-observer';
import { videoImg } from 'utils/regex';
import React, { FC, useEffect, useRef, useState } from 'react';

type Props = {
  src: string;
  lazy?: boolean;
  forcePause?: boolean;
  dataTest?: string;
  disableError?: boolean;
  observerMargins?: string;
  disableSpinner?: boolean;
  animationDuration?: number;
  disableTransition?: boolean;
  iconContainerStyles?: { [key: string]: string };
  errorFallback?: string | Omit<React.ReactNode, 'string'>;
  PlayIcon?: JSX.Element;
  autoPlay?: boolean;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'loading'>;

export const Video: FC<Props> = ({
  src,
  forcePause = false,
  lazy,
  dataTest,
  disableError,
  errorFallback,
  disableSpinner,
  observerMargins,
  disableTransition,
  animationDuration,
  iconContainerStyles,
  PlayIcon,
  autoPlay,
  ...imgProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [videoError, setVideoError] = useState<boolean>(false);
  const [brokenErrorVideo, setBrokenErrorVideo] = useState<boolean>(false);
  const [fallbackImageLoaded, setFallbackImageLoaded] =
    useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const play = async () => {
    if (videoRef.current && !forcePause) {
      await videoRef.current.play();
      setIsPlaying(true);
    }
  };
  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: observerMargins ?? '0px',
  });

  useEffect(() => {
    if (forcePause && isPlaying) {
      pause();
    }
  }, [forcePause, isPlaying]);

  const show = !lazy || inView;

  const handleVideoError = () => {
    if (src) {
      setVideoError(true);
    }
  };

  const fallbackImageError = () => {
    setBrokenErrorVideo(true);
    setFallbackImageLoaded(true);
  };

  useEffect(() => {
    if (autoPlay) {
      setIsPlaying(true);
    }
  }, [autoPlay]);

  const defaultErrorImage = (
    <DefaultErrorImage
      errorFallback={errorFallback}
      animationDuration={animationDuration}
      disableTransition={disableTransition}
      fallbackImageLoaded={fallbackImageLoaded}
      setFallbackImageLoaded={setFallbackImageLoaded}
    />
  );

  const constructedErrorImage = (
    <ConstructedErrorImage
      errorFallback={errorFallback}
      animationDuration={animationDuration}
      disableTransition={disableTransition}
      fallbackImageError={fallbackImageError}
      fallbackImageLoaded={fallbackImageLoaded}
      setFallbackImageLoaded={setFallbackImageLoaded}
    />
  );

  return (
    <Box ref={ref} sx={root}>
      {src && show && (
        <Box sx={{ position: 'relative' }}>
          <VideoPlayer
            controls={isPlaying}
            loop={!isMobile}
            muted={!isMobile}
            data-test={dataTest}
            autoPlay={autoPlay}
            poster={videoImg(src)}
            width={imgProps.width ?? '100%'}
            height={imgProps.height ?? '100%'}
            onError={() => handleVideoError()}
            controlsList="nofullscreen nodownload"
            ref={videoRef}
            onEnded={() => setIsPlaying(false)}
            onPause={() => setIsPlaying(false)}
            onClick={pause}
          >
            <source type="video/mp4" src={src} />
            <track kind="captions" />
          </VideoPlayer>
          {PlayIcon && !isPlaying ? (
            <Button sx={styles.iconWrapper} onClick={play}>
              {PlayIcon}
            </Button>
          ) : null}
        </Box>
      )}
      <Box sx={{ ...iconContainer, ...iconContainerStyles }}>
        {!disableError && videoError && brokenErrorVideo && defaultErrorImage}
        {!disableError &&
          videoError &&
          !brokenErrorVideo &&
          constructedErrorImage}
      </Box>
    </Box>
  );
};

Video.defaultProps = {
  lazy: false,
  forcePause: false,
  dataTest: '',
  disableError: false,
  disableSpinner: false,
  errorFallback: undefined,
  animationDuration: 250,
  disableTransition: false,
  observerMargins: undefined,
  iconContainerStyles: undefined,
  autoPlay: false,
};
