import { Store } from 'redux/types/store.types';

export const getReferredStoreList = (
  storeList: Store[],
  enteredZip: string,
  localStorageStoreNumber: number,
): Store[] => {
  const exactMatchAsEnteredZip = storeList.find(
    (store) => store.areaName === enteredZip,
  );

  const referredStoreWithinRange = storeList.filter(
    (store) => store.number === localStorageStoreNumber,
  );

  const allStoresExceptReferredStore = storeList.filter(
    (referredStore) => referredStore.number !== localStorageStoreNumber,
  );

  const storeListWithReferredAtTop = [
    ...referredStoreWithinRange,
    ...allStoresExceptReferredStore,
  ];

  const exactMatchFirstInStoreList = exactMatchAsEnteredZip
    ? storeListWithReferredAtTop.sort((store) => {
        if (store.areaName === enteredZip) {
          return -1;
        }
        return 0;
      })
    : null;

  const stores =
    exactMatchAsEnteredZip && exactMatchFirstInStoreList
      ? exactMatchFirstInStoreList
      : [...referredStoreWithinRange, ...allStoresExceptReferredStore];

  return stores;
};
