import {
  ARRANGEMENTS_NO_RESULTS_FOUND,
  ARRANGEMENTS_NO_RESULT_SUGGESTION,
} from 'components/PLP/ArrangementsList/translations/constants';

export const arrangementsListCommonLocale = {
  [ARRANGEMENTS_NO_RESULTS_FOUND]:
    'There are no available products for the selected filter. Please try other options.',
  [ARRANGEMENTS_NO_RESULT_SUGGESTION]: 'These products might interest you:',
};
