import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const AcconutSignupStyles = {
  strengthText: {
    color: colors.primaryBlack,
    float: 'right',
    fontFamily: fonts.poppins,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  strengthMeter: {
    float: 'right',
    display: 'flex',
    gap: '5px',
  },
  strengthBar: {
    top: 'calc(50% - 2px/2)',
    height: '2px',
    width: '126px',
    marginTop: '3%',
  },
};
