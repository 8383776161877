import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { GiftRemindersResponse } from 'api/rewards/types/reminders-response.type';
import React, { FC } from 'react';
import i18next from 'i18next';

export type Props = {
  open?: boolean;
  onReject: () => void;
  giftReminderDetails?: GiftRemindersResponse;
  deleteReminder: (reminderId: number) => void;
};

export const ConfirmationDialog: FC<Props> = ({
  onReject,
  open = false,
  giftReminderDetails,
  deleteReminder,
}) => {
  const onConfirm = () => {
    if (giftReminderDetails && giftReminderDetails.id) {
      deleteReminder(giftReminderDetails.id);
      onReject();
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: '770px !important',
          height: '550px',
        },
      }}
      sx={styles.reminderDialogContainer}
      onClose={onReject}
      open={open}
    >
      <DialogTitle>
        <Typography variant="h3" align="center">
          {i18next.t(TK.GIFT_REMINDER_DELETE)}
        </Typography>
        <IconButton
          onClick={onReject}
          sx={styles.closeBtnStyle}
          aria-label={i18next.t(TK.CLOSE)}
          size="large"
          color="secondary"
          data-test="reminder-dialog-close"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: 'auto', padding: 0 }}>
        <Box
          sx={{ margin: '0 5px', marginBottom: '40px' }}
          display="flex"
          justifyContent="center"
        >
          <Typography variant="body2" align="center">
            {i18next.t(TK.DELETE_CONFIRMATION_MSG)}
          </Typography>
        </Box>
        <Box sx={{ margin: '0 5px' }} display="flex" justifyContent="center" />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          onClick={onReject}
          sx={styles.shoppingBtnReject}
          variant="outlined"
          color="primary"
        >
          {i18next.t(TK.CANCEL_TEXT)}
        </Button>
        <Button
          onClick={onConfirm}
          sx={styles.shoppingBtnSelect}
          variant="contained"
          color="primary"
          type="submit"
        >
          {i18next.t(TK.REWARD_DIALOG_OK)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
