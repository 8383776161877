import * as styles from 'components/Home/ChipWidget/components/ChipItem/styles';
import { Chip, Grid } from '@mui/material';
import { ChipWidgetItem } from 'components/Home/ChipWidget/types';
import { checkForFrench } from 'components/Home/utils';
import React, { FC } from 'react';

interface Props {
  item: ChipWidgetItem;
  analyticsClickHandler?: () => void;
}

export const ChipItem: FC<Props> = ({ item, analyticsClickHandler }) => {
  const chipClickHandler = () => {
    if (analyticsClickHandler) analyticsClickHandler();
  };
  return (
    <Grid item>
      <Chip
        sx={styles.chip}
        label={checkForFrench(item.title, item.frTitle)}
        component="a"
        variant="outlined"
        href={item.url}
        clickable
        data-test="chip-widget-item"
        onClick={chipClickHandler}
      />
    </Grid>
  );
};
