import { ArrangementPreview } from 'api/arrangements/types';
import {
  Event,
  Interaction,
  PaymentMethod,
} from 'service/adobe-analytics/enums';
import {
  PageLoadCompleteEvent,
  SiteInteraction,
} from 'service/adobe-analytics/types';
import { PaymentModeEnum } from 'enums/payment-enum';
import { Sort } from 'components/PLP/sort';
import { SortType } from 'components/PLP/FilterSortMenu/enums/sort-type.enum';
import { analytics } from 'service/adobe-analytics/analytics';
import { isPositive } from 'utils/numbers';

export function getSortSiteInteraction(sort: Sort): Interaction {
  return {
    [Sort.RELEVANCE]: Interaction.SORT_BY_RELEVANCE,
    [Sort.PRICE_ASC]: Interaction.SORT_BY_PRICE_LOW_TO_HIGH,
    [Sort.PRICE_DESC]: Interaction.SORT_BY_PRICE_HIGH_TO_LOW,
  }[sort];
}

export function getSortTypeSiteInteraction(sort: SortType): SiteInteraction {
  return {
    [SortType.Relevance]: Interaction.SORT_BY_RELEVANCE,
    [SortType.PriceLow2High]: Interaction.SORT_BY_PRICE_LOW_TO_HIGH,
    [SortType.PriceHigh2Low]: Interaction.SORT_BY_PRICE_HIGH_TO_LOW,
  }[sort];
}

export const onArrangementClick = (arrangement: ArrangementPreview): void => {
  analytics.digitalDataPushList({
    event: Event.ArrangementClicked,
    arrangement: {
      id: arrangement.id,
      title: arrangement.name,
      sequence: arrangement.sequence,
      promo: isPositive(arrangement.promo.length),
      nonPromo: isPositive(arrangement.nonPromo.length),
    },
  });
};

export const onPlpInterrupterClick = (
  arrangement: ArrangementPreview,
): void => {
  const imageName = arrangement.image.split('/').pop() ?? '';
  analytics.plpInterrupterClick(imageName);
};

export const getPageLoadCompletedEvent = (): PageLoadCompleteEvent => ({
  event: Event.PageLoadComplete,
});

export const getPaymentMethodText = (paymentMode: PaymentModeEnum): string => {
  const paymentMethodMapping = {
    [PaymentModeEnum.NotSpecified]: PaymentMethod.NotSpecified,
    [PaymentModeEnum.Cash]: PaymentMethod.Cash,
    [PaymentModeEnum.CreditCard]: PaymentMethod.CreditCard,
    [PaymentModeEnum.HouseCharge]: PaymentMethod.HouseCharge,
    [PaymentModeEnum.GiftCertificate]: PaymentMethod.GiftCertificate,
    [PaymentModeEnum.DebitCard]: PaymentMethod.DebitCard,
    [PaymentModeEnum.EAConnect]: PaymentMethod.EAConnect,
    [PaymentModeEnum.EAEnterprise]: PaymentMethod.EAEnterprise,
    [PaymentModeEnum.Complementary]: PaymentMethod.Complementary,
    [PaymentModeEnum.Check]: PaymentMethod.Check,
    [PaymentModeEnum.PayPal]: PaymentMethod.PayPal,
    [PaymentModeEnum.BML]: PaymentMethod.BML,
    [PaymentModeEnum.EFT]: PaymentMethod.EFT,
    [PaymentModeEnum.BarterTrade]: PaymentMethod.BarterTrade,
    [PaymentModeEnum.AmazonPay]: PaymentMethod.AmazonPay,
    [PaymentModeEnum.AmazonMarketplace]: PaymentMethod.AmazonMarketplace,
    [PaymentModeEnum.ApplePay]: PaymentMethod.ApplePay,
    [PaymentModeEnum.Others]: PaymentMethod.Others,
    [PaymentModeEnum.Unpaid]: PaymentMethod.Unpaid,
    [PaymentModeEnum.Venmo]: PaymentMethod.Venmo,
    [PaymentModeEnum.GooglePay]: PaymentMethod.GooglePay,
    [PaymentModeEnum.Link]: PaymentMethod.Link,
  };

  return paymentMethodMapping[paymentMode];
};
