import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';
import { Box, Typography } from '@mui/material';
import { getSuggestedAddresses } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { isEmpty } from 'utils/array/size';
import { suggestedAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styles';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const SuggestedAddress: FC = () => {
  const addresses = useSelector(getSuggestedAddresses);

  if (isEmpty(addresses)) {
    return null;
  }

  return (
    <>
      {addresses.map((address) => (
        <Box key={address.street1} sx={suggestedAddress}>
          <Typography variant="subtitle2">
            {i18next.t(TK.SA_SUGGESTED_ADDRESS)}
          </Typography>
          <Typography variant="body2">{`${address.street1} ${address.city} ${
            address.state || ''
          },`}</Typography>
          <Typography variant="body2">{address.street2}</Typography>
          <Typography variant="body2">{address.zip}</Typography>
        </Box>
      ))}
    </>
  );
};
