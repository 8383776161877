import { Box, Skeleton } from '@mui/material';
import React, { FC } from 'react';

export const HeaderSkeleton: FC = () => (
  <>
    <Skeleton variant="rectangular" sx={{ height: [70, 200] }} />
    <Skeleton
      variant="text"
      height={50}
      sx={{ mt: 1, width: ['40%', '30%'] }}
    />
    <Box mt={2}>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </Box>
    <Box display={['inline', 'none']}>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" width="60%" />
    </Box>
  </>
);
