import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { StoresByStateData } from 'components/LocalSeoPage/State/types';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { getStoresByState } from 'components/LocalSeoPage/State/feature/actions';

export interface StoresByState {
  data: StoresByStateData;
  status: Status;
  error: ResponseError | null;
}

const initialState: StoresByState = {
  data: {
    storeData: [
      {
        Address: '',
        ApplicableDate: '',
        AvailableMerchandise: 0,
        Certifications: 0,
        DistanceInMiles: '',
        FormalName: '',
        ID: 0,
        IsCBDStore: false,
        IsLevel2Store: false,
        Latitude: 0,
        Longitude: 0,
        MapAddress: '',
        ModifiedName: '',
        Number: 0,
        PageFriendlyURL: '',
        PhoneNumber: '',
        ShowHours: '',
        TextShownOnWebsite: '',
        TimingsShort: [
          {
            Days: '',
            Timing: '',
          },
        ],
      },
    ],
  },
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'storesByState',
  initialState,
  reducers: {},
  extraReducers: createExtraReducers(initialState, getStoresByState),
});

export const storesByStateReducer = slice.reducer;
