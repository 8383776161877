import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const listItem: SxProps<Theme> = (theme) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 64,
  padding: theme.spacing(1.5, 1.5, 1.5, 2.5),
  '& a': {
    padding: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.MuiButtonBase-root.MuiListItemButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const divider: SxProps = {
  my: 4,
};

export const subTitle: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1, 2.5, 2),
});

export const languageItem: SxProps = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  px: 2.5,
  py: 1.5,
  '& img': {
    maxHeight: 40,
    mr: 2,
  },
};

export const languageSkeleton: SxProps = {
  display: 'flex',
  width: '100%',
  px: 2.5,
  py: 1.5,
};

export const language: SxProps = {
  flex: '1 0 auto',
};

export const titleWrapper: SxProps = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: 64,
  padding: 1.5,
};

export const title: SxProps = {
  maxWidth: '185px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const backButton: SxProps = {
  mr: 2,
};

export const closeButton: SxProps = {
  ml: 'auto',
};

export const menuList = (isVisible: boolean): SxProps => ({
  display: isVisible ? 'block' : 'none',
  padding: 0,
});

export const menuContent = (isVisible: boolean): SxProps => ({
  display: isVisible ? 'block' : 'none',
});
