import * as TK from 'components/Authentication/translations/locale.constants';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { checkIsPending } from 'components/Authentication/Reset/feature/selectors';
import { isNotUndefined } from 'utils/is-not-undefined';
import { resetPassword } from 'components/Authentication/Reset/feature/actions';
import { schema } from 'components/Authentication/Reset/validation.scheme';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import i18next from 'i18next';

type ResetValues = { email: string };

export const ResetForm: FC = () => {
  const dispatch = useDispatch();
  const disabled = useSelector(checkIsPending);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResetValues>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const error = errors?.email?.message;
  return (
    <form onSubmit={handleSubmit((data) => dispatch(resetPassword(data)))}>
      <Typography variant="body2">{i18next.t(TK.AUTH_RESET_TIP)}</Typography>
      <TextField
        sx={{ mt: 3, width: ['388px', '388px', '456px'] }}
        {...register('email')}
        error={isNotUndefined(error)}
        helperText={error ? i18next.t(error) : undefined}
        disabled={disabled}
        label={i18next.t(TK.AUTH_RESET_EMAIL_LABEL)}
        placeholder="email@email.com"
        type="email"
        fullWidth
      />
      <Button
        sx={{ mt: 6, width: ['388px', '388px', '456px'] }}
        disabled={disabled}
        startIcon={
          disabled ? <CircularProgress size={20} color="inherit" /> : undefined
        }
        variant="contained"
        size="large"
        type="submit"
        fullWidth
      >
        {i18next.t(disabled ? TK.AUTH_RESET_SUBMITTING : TK.AUTH_RESET_SUBMIT)}
      </Button>
    </form>
  );
};
