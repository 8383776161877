import { Box, SxProps } from '@mui/material';
import { Device } from 'components/SkinnyBannerV2/enum/device.enum';
import { HeadMeta } from 'components/HreflangTag/HeadMeta/HeadMeta';
import { HeaderNavigation } from 'components/Header/HeaderNavigation';
import { HideUpMd, useIsUpMd } from 'utils/hidden/hide-up-md';
import { Locale } from 'enums/locale';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { SkinnyBannerV2 } from 'components/SkinnyBannerV2';
import { TopHeaderPanel } from 'components/Header/TopHeaderPanel';
import { TopHeaderPanelMobile } from 'components/Header/TopHeaderPanelMobile';
import { fetchHeader } from 'redux/header/header.actions';
import { getCartCount } from 'components/Cart/feature/actions';
import { getNxtGenCartStoreNumberSetting } from 'redux/settings/settings.actions';
import { getStoreDetailsById } from 'redux/cart/cart.actions';
import {
  headerWrapper,
  mobileNavWrapper,
} from 'containers/HeaderContainer/styles';
import {
  saveStore,
  updateCartCount,
} from 'providers/Session/feature/user.action';
import {
  selectCartCount,
  selectIsCartLoading,
  selectRecipientCart,
} from 'components/Cart/feature/selectors';
import { useCreateCallCenterSession } from 'components/PLP/CallCenter/hooks/use-callcenter-mode';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, memo, useEffect, useRef, useState } from 'react';

export const HeaderContainer: FC = memo(() => {
  useCreateCallCenterSession();
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const isUpMd = useIsUpMd();
  const isScrolledPastBanner = scrollPosition >= height;
  const user = useSelector(({ userSession: { data } }) => data);
  const cartCount = useSelector(selectCartCount);
  const isCartLoading = useSelector(selectIsCartLoading);
  const recipientCart = useSelector(selectRecipientCart);
  const nxtGenStoreList = useSelector(
    ({ settings: { data } }) => data.enableNxtGenCartStoreNumberSetting,
  );

  useEffect(() => {
    if (!nxtGenStoreList) dispatch(getNxtGenCartStoreNumberSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (user.cartId > 0 && cartCount && cartCount > 0) {
      dispatch(getStoreDetailsById(user.cartId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.cartId, cartCount]);

  useEffect(() => {
    dispatch(updateCartCount(cartCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCount]);

  useEffect(() => {
    dispatch(fetchHeader());
  }, [dispatch]);

  useEffect(() => {
    if (user.cartId) {
      dispatch(
        getCartCount({
          cartId: user.cartId,
          region: user.cartHostedRegion,
        }),
      );
    }
  }, [isCartLoading, recipientCart, user.cartId]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref?.current?.clientHeight]);

  const params = new URLSearchParams(window.location.search);
  const storeNumber = params.get('store');

  useEffect(() => {
    if (storeNumber !== '' && storeNumber) {
      dispatch(saveStore(storeNumber));
    }
  }, [dispatch, storeNumber]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, handleScroll]);

  useEffect(() => {
    if (user && user.visitLogged === false) {
      const urlReferer = document.referrer;
      const imgLogVisit = new Image();
      if (urlReferer) {
        imgLogVisit.src = `/handlers/logvisit.ashx?R=${urlReferer}`;
      } else {
        imgLogVisit.src = `/handlers/logvisit.ashx`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skinnyBannerWrapper: SxProps = {
    height: isScrolledPastBanner && !isUpMd ? '0%' : 'auto',
    marginTop: isScrolledPastBanner && !isUpMd ? `${height}px` : '0',
  };

  return (
    <Box sx={headerWrapper}>
      <HeadMeta locale={Locale.EN_US} />
      <ShowUpMd>
        <Box ref={ref}>
          <SkinnyBannerV2 deviceName={Device.desktop} />
        </Box>
        <TopHeaderPanel />
        <HeaderNavigation />
      </ShowUpMd>
      <HideUpMd>
        <Box sx={skinnyBannerWrapper} ref={ref}>
          <SkinnyBannerV2 deviceName={Device.mobile} />
        </Box>
        <Box
          sx={mobileNavWrapper}
          style={{ position: isScrolledPastBanner ? 'fixed' : 'relative' }}
        >
          <TopHeaderPanelMobile />
        </Box>
      </HideUpMd>
    </Box>
  );
});
