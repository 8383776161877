import { LegacyLogInValidationStatus } from 'api/auth-legacy/validate.misc';
import { appInsights } from 'utils/telemetry/app-insights';
import { authLegacyApiService } from 'api/auth-legacy/auth.legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

const TRUTHY_STATUSES = [
  LegacyLogInValidationStatus.FULL,
  LegacyLogInValidationStatus.PARTIAL,
];

type ValidationParsedResponse = {
  _Status: LegacyLogInValidationStatus;
  _Message: string;
  _Data: string;
};

export const validate = createAsyncThunk<
  ValidationParsedResponse,
  { email: string; password: string },
  { rejectValue: ValidationParsedResponse }
>('auth/validate', async ({ email, password }, { rejectWithValue }) => {
  try {
    const { data } = await authLegacyApiService.validate(email, password);
    const res = JSON.parse(data.d) as ValidationParsedResponse;
    if (!res?._Status || !TRUTHY_STATUSES.includes(res._Status)) {
      return rejectWithValue(res);
    }
    return res;
  } catch (e) {
    appInsights.trackEvent({ name: 'Log in validation failed' });
    const error =
      e && typeof e === 'object'
        ? {
            _Status:
              (e as ValidationParsedResponse)?._Status ||
              LegacyLogInValidationStatus.EXCEPTION,
            _Message: (e as ValidationParsedResponse)?._Message || 'Unexpected',
            _Data: (e as ValidationParsedResponse)?._Data || 'Unexpected',
          }
        : {
            _Status: LegacyLogInValidationStatus.EXCEPTION,
            _Message: 'Unexpected',
            _Data: 'Unexpected',
          };

    return rejectWithValue(error);
  }
});
