import { OverrideTag } from 'api/skinny-banner.api.service';

export const addToLocalStorage = (search?: string): void => {
  if (search) {
    const STORE_ID = 'store';
    const params = new URLSearchParams(search);
    const storeId = params.get('store');

    if (storeId !== '' && storeId) {
      localStorage.setItem(STORE_ID, storeId);
    }
  }
};

export const setWithExpiry = (key: string, tag: string, ttl: number): void => {
  const now = new Date();

  const item = {
    value: tag,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string): string | null => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr) as OverrideTag;
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
