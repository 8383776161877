import { isNotNull } from 'utils/null.utils';
import { isSaturday } from 'date-fns';
import { isShipment } from 'utils/is-order-type';
import { selectIsResidential } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useSelector } from 'react-redux';

export function useIsSaturdayNonResidentialShipment(): boolean {
  const { orderType, date } = usePasData();
  const isResidential = useSelector(selectIsResidential);

  return (
    !isResidential &&
    isShipment(orderType) &&
    isNotNull(date) &&
    isSaturday(date)
  );
}
