import * as TK from 'translations/locales/translation-keys.constant';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { getName } from 'components/PDP/GoBack/feature/selectors';
import { isNotEmpty } from 'utils/array/size';
import { pop } from 'components/BreadcrumbBack/feature/slice';
import { selectLink } from 'components/BreadcrumbBack/feature/selectors';
import { shouldUseReferrer } from 'components/PDP/GoBack/utils';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

type Breadcrumb = {
  visible: boolean;
  title: string;
  linkProps: {
    component?: ForwardRefExoticComponent<
      LinkProps & RefAttributes<HTMLAnchorElement>
    >;
    to?: string;
    href?: string;
    onClick?: () => void;
  };
};

const { referrer } = document;
const isReferrerValid = shouldUseReferrer(referrer, window.origin);

export const useBreadcrumbProps = (isBlocked?: boolean): Breadcrumb => {
  const filter = useSelector(getName);
  const link = useSelector(selectLink);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(pop());
  };

  const linkProps = link
    ? { component: RouterLink, to: link.href, onClick }
    : { href: isReferrerValid ? referrer : undefined };
  const title = link?.title || (referrer && filter) || i18next.t(TK.BACK);

  const visible =
    !isBlocked && isNotEmpty(Object.values(linkProps).filter(Boolean));

  return {
    linkProps,
    title,
    visible,
  };
};
