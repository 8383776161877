import { commonSliderProps } from 'components/PDP/Details/components/Gallery/props';
import { styled } from '@mui/material';
import SlickSlider from 'react-slick';

export const Slider = styled(SlickSlider)(({ theme }) => ({
  '& .slick-dots': {
    bottom: -18,

    '& li': {
      width: 12,
      height: 6,
      margin: '0 8px',
      borderRadius: 4,
      background: theme.palette.other.outlineBorder,
      transition: `${commonSliderProps.speed ?? 0}ms`,

      '& button': {
        width: 12,
        height: 6,

        '&:before': {
          content: 'none',
        },
      },
      '&.slick-active': {
        background: theme.palette.primary.main,
        width: 48,

        '& button': {
          width: 48,
        },
      },
    },
  },
}));
