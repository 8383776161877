import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  bullet,
  icon,
} from 'components/PDP/Details/components/Ingredients/styles';
import CircleIcon from '@mui/icons-material/Circle';
import React, { FC } from 'react';

type Props = {
  ingredient: string;
};

export const Ingredient: FC<Props> = ({ ingredient = '' }) => (
  <ListItem sx={{ alignItems: 'flex-start' }} disablePadding dense>
    <ListItemIcon sx={icon}>
      <CircleIcon role="presentation" sx={bullet} />
    </ListItemIcon>
    <ListItemText>
      <Typography variant="body2">{ingredient}</Typography>
    </ListItemText>
  </ListItem>
);
