import { SpeedStyledChip } from 'components/GiftFinder/chips/styles';
import React, { FC } from 'react';

interface Props {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

export const SpeedChip: FC<Props> = ({ label, isSelected, onClick }) => (
  <SpeedStyledChip
    label={label}
    variant="outlined"
    isSelected={isSelected}
    onClick={onClick}
    data-test="plp-filter-chip"
  />
);
