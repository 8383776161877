import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const titleContainer: SxProps = {
  fontFamily: fonts.poppins,
  '& span': {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
};

export const formInputContainer: SxProps = {
  width: '100%',
  paddingTop: '20px',
};

export const formInput: SxProps = {
  width: '100%',
};
export const dividerLine: SxProps = {
  width: '100%',
  border: `1px solid ${colors.dividerBlack}`,
};

export const phoneField: SxProps = {
  width: '57%',
};

export const passwordChecker: SxProps = {
  width: '100%',
};

export const dobFieldDropdown: SxProps = {
  width: '100%',
};

export const createAccountButton: SxProps = {
  width: '100%',
};
export const termsAndConditions: SxProps = {
  backgroundColor: '#EFEFEF',
  paddingTop: ['15px', '18px', '18px'],
  paddingBottom: ['30px', '18px', '18px'],
  px: ['23px', '24px', '24px'],
  borderRadius: '16px',
  '& a': {
    color: '#0073A4',
    textDecoration: 'underline',
  },
};

export const tooltipInfo: SxProps = {
  backgroundColor: '#F2F3F5',
  borderRadius: '16px',
  fontFamily: fonts.poppins,
  fontSize: '14px',
  fontWeight: '400',
  color: colors.black,
};

export const labelText: SxProps = {
  fontFamily: fonts.poppins,
  fontSize: '14px',
  fontWeight: '500',
  color: colors.black,
};
