import * as TK from 'components/OrderConfirmation/translations/locale.constants';
import * as styles from 'components/OrderConfirmation/CreateAccount/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Image } from 'components/common/Image/Image';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { StrawberryImg, StrawberryImgMob } from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const AccountBannerLogin: FC = () => {
  return (
    <Box sx={styles.accountPromo}>
      <Box sx={styles.accountContainer}>
        <Typography sx={styles.accountHead}>{i18next.t(TK.ACC_TXT)}</Typography>
        <Typography sx={styles.accountText}>
          {i18next.t(TK.ENJOY_MSG)}
        </Typography>
        <ShowUpMd>
          <Image
            alt={i18next.t(TK.STRAWBERRY)}
            className="strawberryImg"
            src={StrawberryImg}
          />
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Image
              alt={i18next.t(TK.STRAWBERRY)}
              className="strawberryImg"
              src={StrawberryImg}
            />
          </ShowUpSm>
          <ShowUpXs>
            <Image
              alt={i18next.t(TK.STRAWBERRY)}
              className="strawberryImg"
              src={StrawberryImgMob}
            />
          </ShowUpXs>
        </HideUpMd>
        <Box sx={styles.listText}>
          <li>{i18next.t(TK.ACC_LIST_TEXT1)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT2)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT3)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT4)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT5)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT6)}</li>
          <li>{i18next.t(TK.ACC_LIST_TEXT7)}</li>
        </Box>
        <Typography sx={styles.rewardText}>
          Only
          <span className="fontWt"> {i18next.t(TK.ORDERS_MSG)}</span> until your
          <span className="freetxt"> {i18next.t(TK.FREE_TXT)}</span>
          <span> {i18next.t(TK.FRUIT_TXT)}</span>
        </Typography>
        <Button
          variant="text"
          type="button"
          size="small"
          data-test="create-account-button"
          aria-label="create-account"
          className="createAccountBtn"
          sx={styles.redBtnStyle}
          href="/account/sign-up"
        >
          {i18next.t(TK.CREATE_ACCOUNT)}
        </Button>
        <Link
          href="/legal/terms-of-use"
          sx={styles.linkStyle}
          className="termsTxt"
        >
          {i18next.t(TK.TERMS_MSG)}
        </Link>
      </Box>
    </Box>
  );
};
