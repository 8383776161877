import {
  getIniScript,
  getScript,
} from 'hooks/google/utils/ads-tag-manager.utils';
import { useEffect } from 'react';

export const useAdsTagManager = (): void => {
  useEffect(() => {
    const id = window.GOOGLE_ADS_TAG_MANAGER_ID;
    if (!id) {
      return;
    }
    const initScript = getIniScript(id);
    const script = getScript(id);

    document.head.insertBefore(initScript, document.head.childNodes[0]);
    document.head.insertBefore(script, document.head.childNodes[1]);
  });
};
