import { SxProps, styled } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';

export const CarouselContainer = styled('div')({
  '& .slick-slider': {
    '& button.slick-arrow': {
      display: 'none !important',
    },
    '& .slick-list': {
      '& .slick-track': {
        display: 'flex',
      },
    },
  },
});

export const title: ThemedSxProps = (theme) => ({
  height: 20,
  width: '50%',
  margin: '1px 0',
  [theme.breakpoints.up('md')]: {
    height: 25,
    margin: '5px 0',
  },
});

export const productImg: SxProps = {
  width: ['124px', '124px', '144px'],
  height: ['124px', '124px', '144px'],
  border: `1px solid var(--other-outline-border, ${colors.borderBlack})`,
};

export const addUpsellContainer: SxProps = {
  display: 'flex',
  padding: '5px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  color: 'var(--primary-main, #E10600)',
  '& button': {
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    minWidth: '28px',
    padding: '8px 0px',
  },
  '& svg': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: '18px',
    height: '18px',
  },
};

export const carouselItem: SxProps = {
  maxWidth: '120px',
};

export const productDetails: SxProps = {
  display: 'flex',
  padding: '0px 8px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  '& span': {
    fontWeight: 500,
  },
};

export const sliderContainer: SxProps = {
  maxWidth: '615px',
  padding: ['16px 8px', '16px 20px', '24px 24px'],
  background: '#f7f7f7',
  mt: 4,
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 0',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
          width: '100%',
          '& img': {
            border: `1px solid ${colors.dividerBlack}`,
            borderRadius: '0px',
          },
        },
      },
    },
    '& .slick-arrow': {
      border: `1px solid ${colors.dividerBlack}`,
      background: colors.white,
      color: colors.activeBlack,
      top: '90px',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
      height: '50px',
      '::before': {
        content: 'none',
      },
      zIndex: 1,
    },
    '& .slick-next': {
      right: '-25px',
    },
    '& .slick-prev': {
      left: '-25px',
    },
  },
};

export const addUpsell: SxProps = {
  mt: 2,
  border: `4px solid ${colors.primaryRed}`,
  borderRadius: 0,
  background: colors.white,
};

export const desktopTitle: SxProps = {
  display: ['none', 'flex', 'flex'],
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: 3,
};

export const mobileTitle: SxProps = {
  display: ['flex', 'none', 'none'],
  alignItems: 'center',
  justifyContent: 'center',
  mb: 3,
};
