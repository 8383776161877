import { analytics as AA } from 'service/adobe-analytics/analytics';
import { DeliveryService } from 'enums/delivery-service';
import { Filter, FilterCategory } from 'api/filter-categories/types';
import { FilterAction, UserAgent } from 'service/adobe-analytics/enums';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { OrderType } from 'types/cart.types';
import { Site } from 'service/adobe-analytics/types';
import { getCountryLanguage } from 'utils/country';
import { getCurrentEnvironment } from 'utils/environment';

const DEFAULT_LOGIN_PAGE_PREFIX = 'home';

export const filterDifferentEvent = (
  previous: Filter[],
  current: Filter[],
): Filter[] => previous.filter((prev) => !current.includes(prev));

const getEventTypeName = (item: Filter, filterCategories: FilterCategory[]) =>
  filterCategories.find(
    (filterCategory) => filterCategory.ID === item.FilterCategoryID,
  )?.Name || '';

export const getSortEventAndPushProductRefinement = (
  action: FilterAction,
  events: Filter[],
  filterCategories: FilterCategory[],
): void => {
  events.forEach((item) => {
    AA.productRefinement({
      action,
      type: getEventTypeName(item, filterCategories).toLowerCase(),
      value: item.Name.toLowerCase(),
    });
  });
};

export const getSelectedServiceType = (
  orderType: OrderType,
): DeliveryService => {
  if (orderType === OrderType.Delivery) {
    return DeliveryService.HAND_DELIVERED;
  }
  if (orderType === OrderType.Shipment) {
    return DeliveryService.SHIPMENT;
  }

  return DeliveryService.PICKUP;
};

export const getVersion = (): string =>
  document.querySelector('meta[name="app-version"]')?.getAttribute('content') ||
  'potato';

export const createLoginPagePrefix = (): string =>
  window.location.pathname.substring(1).replace(/\/$/, '') ||
  DEFAULT_LOGIN_PAGE_PREFIX;

export const getSiteInfo = (): Site => {
  const site: Site = {
    domain: window.location.host,
    environment: getCurrentEnvironment(),
    name: IS_MOBILE_HOST ? UserAgent.Mobile : UserAgent.Desktop,
    languageCountry: getCountryLanguage(),
    version: getVersion(),
  };
  return site;
};
