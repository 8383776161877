import { ComponentType } from 'components/Home/types';
import { getComponent } from 'components/Home/feature/selectors';
import {
  plpGetComponent,
  plpGetComponents,
} from 'components/PLP/feature/selectors';
import { useSelector } from 'react-redux';

export function useCmsWidgets(type: string, id: string): ComponentType {
  const isPlpPageActive = useSelector(plpGetComponents);
  const CmsWidgetData = useSelector(
    isPlpPageActive ? plpGetComponent(type, id) : getComponent(type, id),
  );
  return CmsWidgetData;
}
