import { Chip, styled } from '@mui/material';
import { colors } from 'utils/theme/colors';

export const ReasonStyledChip = styled(Chip)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    backgroundColor: isSelected
      ? `${colors.chipSelectedBackground} !important`
      : `${colors.white} !important`,
    borderColor: isSelected
      ? `${colors.primaryRed} !important`
      : `${colors.greyBorder} !important`,
    borderRadius: '20px 0 0 20px',
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiAvatar-root': {
      width: '32px',
      height: '32px',
      marginLeft: '-12px',
      marginRight: '6px',
      borderRadius: '16px',
      border: `1px solid ${colors.greyBorder}`,
    },
  }),
);

export const SpeedStyledChip = styled(Chip)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    backgroundColor: isSelected
      ? `${colors.chipSelectedBackground} !important`
      : `${colors.white} !important`,
    borderColor: isSelected
      ? `${colors.primaryRed} !important`
      : `${colors.greyBorder} !important`,
  }),
);
