/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { FilterItem } from 'api/filters-api.service';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { Status } from 'redux/types/state.interface';
import { fetchFiltersByFriendlyUrl } from 'redux/PLP/filter/filters.actions';

export type FilterState = {
  filter: {
    filterItem: FilterItem | null;
    status: Status;
  };
};

export const filtersInitialState: FilterState = {
  filter: {
    filterItem: null,
    status: Status.INIT,
  },
};

export const filtersExtraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<FilterState>>,
): void => {
  builder
    .addCase(fetchFiltersByFriendlyUrl.pending, (state) => {
      state.filter.status = Status.PENDING;
    })
    .addCase(fetchFiltersByFriendlyUrl.fulfilled, (state, { payload }) => {
      state.filter.filterItem = payload;
      state.filter.status = Status.FULFILLED;
    })
    .addCase(fetchFiltersByFriendlyUrl.rejected, (state) => {
      state.filter.status = Status.ERROR;
    });
};
