import { Components } from 'enums/home-components';
import { HomePageContent } from 'components/Home/types';
import { colors } from 'utils/theme/colors';

export const fallbackResponseLocal: HomePageContent = {
  activeFrom: '2022-07-19T00:00:00.000Z',
  activeTo: '2022-07-31T23:59:59.999Z',
  createdAt: '2022-06-20T00:00:00.000Z',
  source: 'organic',
  description:
    'Our one-of-a-kind fresh fruit baskets, bouquets, arrangements & gourmet chocolate covered fruit gifts can be conveniently delivered nationwide. Shop today!',
  eventName: 'Sub Test Page',
  id: 'b8b1a649-ce44-4677-a4c4-d50d6fe88ce3',
  isDraft: false,
  keywords:
    'Edible arrangements, fruit arrangements, fresh fruit arrangements, fruit bouquets, fruit baskets, fresh fruit, floral fruit, chocolate covered strawberries, dipped fruit, fruit delivery',
  title: 'Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery',
  type: Components.homepage,
  content: [
    {
      id: '1',
      sequence: 0,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      position: 1,
      type: Components.carouselBanners,
      content: {
        mobile: {
          available: true,
          autoSlideInterval: 4000,
          items: [
            {
              id: '31',
              sequence: 0,
              name: 'New Edible® Cakes. Just the right size for sharing. Shop Now.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Mobile-780x464-Cakes.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549437/Development/dev/carouselBanners/banner_01_sm.jpg',
              url: '/birthday-gifts',
              altText: 'New Edible® Cakes',
            },
            {
              id: '23',
              sequence: 1,
              name: 'Pause for Sweetness. Shop salted caramel sweetness today. Shop Now. ©2022 Mars or Affiliates.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Mobile-780x464-Twix.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549438/Development/dev/carouselBanners/banner_02_sm.jpg',
              url: '/caramel-desserts',
              altText: 'Shop salted caramel sweetness today',
            },
            {
              id: '32',
              sequence: 2,
              name: 'Summer Sweetness Under $50. Make this the sweetest summer yet. Shop Now.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Mobile-780x464-Summer.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549437/Development/dev/carouselBanners/banner_01_sm.jpg',
              url: '/under-50',
              altText: 'Summer Sweetness Under $50',
            },
          ],
        },
        desktop: {
          available: true,
          autoSlideInterval: 4000,
          items: [
            {
              id: '11',
              sequence: 0,
              name: 'New Edible® Cakes. Just the right size for sharing. Shop Now.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Desktop-1920px-H1.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549441/Development/dev/carouselBanners/banner_01.jpg',
              url: '/birthday-gifts',
              altText: 'New Edible® Cakes',
            },
            {
              id: '25',
              sequence: 1,
              name: 'Pause for Sweetness. Shop salted caramel sweetness today. Shop Now. ©2022 Mars or Affiliates.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Desktop-1920px-H2.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549446/Development/dev/carouselBanners/banner_02.jpg',
              url: '/caramel-desserts',
              altText: 'Shop salted caramel sweetness today',
            },
            {
              id: '38',
              sequence: 2,
              name: 'Summer Sweetness Under $50. Make this the sweetest summer yet. Shop Now.',
              src: 'https://rescloud.ediblearrangements.com/image/private/f_auto,q_auto/Creative-Marketing/site_support/banners/hero/Desktop-1920px-H3.jpg',
              fallbackSrc:
                'https://rescloud.ediblearrangements.com/image/upload/v1653549444/Development/dev/carouselBanners/banner_03.jpg',
              url: '/under-50',
              altText: 'Summer Sweetness Under $50',
            },
          ],
        },
      },
    },
    {
      id: '2',
      sequence: 1,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      position: 1,
      type: Components.carouselSimplified,
      content: {
        desktop: {
          available: true,
          bgColor: 'transparent',
          title:
            '<p>Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery</p>',
          items: [
            {
              id: 'birthday',
              name: 'Birthday',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/8091.jpg',
              url: '/birthday-gifts',
              sequence: 0,
              frName: 'Anniversaire',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/8091.jpg',
            },
            {
              id: 'sameDayDelivery',
              name: 'Same Day Delivery',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/summer-bouquet.jpg',
              url: '/same-day-delivery-gifts',
              sequence: 1,
              frName: 'Livraison le jour même',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/summer-bouquet.jpg',
            },
            {
              id: 'chocolageCoveredStrawberries',
              name: 'Chocolate Covered Strawberries',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/7928.jpg',
              url: '/chocolate-covered-strawberries',
              sequence: 2,
              frName: 'Fraises enrobées de chocolat',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/7928.jpg',
            },
            {
              id: 'giftsunder30',
              name: 'Gifts Under $50',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/8114.jpg',
              url: '/under-50',
              sequence: 3,
              frName: 'Cadeaux à moins de 50 $',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/8114.jpg',
            },
          ],
        },
        mobile: {
          available: true,
          bgColor: 'transparent',
          title:
            '<p>Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery</p>',
          items: [
            {
              id: 'birthday',
              name: 'Birthday',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/8091.jpg',
              url: '/birthday-gifts',
              frName: 'Anniversaire',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/8091.jpg',
              sequence: 0,
            },
            {
              id: 'sameDayDelivery',
              name: 'Same Day Delivery',
              frName: 'Livraison le jour même',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/summer-bouquet.jpg',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/summer-bouquet.jpg',
              url: '/same-day-delivery-gifts',
              sequence: 1,
            },
            {
              id: 'chocolageCoveredStrawberries',
              name: 'Chocolate Covered Strawberries',
              frName: 'Fraises enrobées de chocolat',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/7928.jpg',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/7928.jpg',
              url: '/chocolate-covered-strawberries',
              sequence: 2,
            },
            {
              id: 'giftsunder30',
              name: 'Gifts Under $50',
              frName: 'Cadeaux à moins de 50 $',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/8114.jpg',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/8114.jpg',
              url: '/under-50',
              sequence: 3,
            },
          ],
        },
      },
    },
    {
      id: '3',
      sequence: 2,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      position: 1,
      type: Components.carouselDetailed,
      content: {
        desktop: {
          available: true,
          title: 'Birthday - The Best Birthday Gifts Are Edible.',
          url: '/birthday-gifts',
          viewAllText:
            '<p>View All <span style="font-size: 14px;"></span><span>&#10132;</span></p>',
          viewAllButtonText: 'View All Arrangements',
          items: [
            {
              id: '7255',
              name: 'Edible® Smash Cake Bundle',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Edible_Smash_Cake_Bundle.jpg',
              url: 'edible-smash-cake-bundle-8187',
              price: 51.99,
              sizeCount: 1,
              alt: 'Edible Smash Cake Bundle',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7160',
              name: 'Sweet & Simple Birthday Party Pack',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Sweet_Simple_Birthday_Party_Pack.jpg',
              url: 'sweet-simple-birthday-party-pack-8092',
              price: 35.98,
              sizeCount: 1,
              alt: 'Sweet  Simple Birthday Party Pack',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7159',
              name: 'Mini Happy Birthday Dessert & Balloon Party Kit',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Mini_Happy_Birthday_Dessert_Balloon_Party_Kit.jpg',
              url: 'mini-happy-birthday-dessert-balloon-party-kit-8091',
              price: 29.99,
              sizeCount: 1,
              alt: 'Mini Happy Birthday Dessert  Balloon Party Kit',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7192',
              name: 'Chocolate Edible® Cake with Strawberries',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Chocolate_Edible_Cake_with_Strawberries.jpg',
              url: 'chocolate-edible-cake-with-strawberries-8124',
              price: 39.99,
              sizeCount: 1,
              alt: 'Chocolate Edible Cake with Strawberries',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
          ],
        },
        mobile: {
          available: true,
          title: 'Birthday - The Best Birthday Gifts Are Edible.',
          url: '/birthday-gifts',
          viewAllText:
            '<p>View All <span style="font-size: 14px;"></span><span>&#10132;</span></p>',
          viewAllButtonText: 'View All Arrangements',
          items: [
            {
              id: '7255',
              name: 'Edible® Smash Cake Bundle',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Edible_Smash_Cake_Bundle.jpg',
              url: 'edible-smash-cake-bundle-8187',
              price: 51.99,
              sizeCount: 1,
              alt: 'Edible Smash Cake Bundle',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7160',
              name: 'Sweet & Simple Birthday Party Pack',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Sweet_Simple_Birthday_Party_Pack.jpg',
              url: 'sweet-simple-birthday-party-pack-8092',
              price: 35.98,
              sizeCount: 1,
              alt: 'Sweet  Simple Birthday Party Pack',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7159',
              name: 'Mini Happy Birthday Dessert & Balloon Party Kit',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Mini_Happy_Birthday_Dessert_Balloon_Party_Kit.jpg',
              url: 'mini-happy-birthday-dessert-balloon-party-kit-8091',
              price: 29.99,
              sizeCount: 1,
              alt: 'Mini Happy Birthday Dessert  Balloon Party Kit',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7192',
              name: 'Chocolate Edible® Cake with Strawberries',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: '',
              promoColor: '',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Chocolate_Edible_Cake_with_Strawberries.jpg',
              url: 'chocolate-edible-cake-with-strawberries-8124',
              price: 39.99,
              sizeCount: 1,
              alt: 'Chocolate Edible Cake with Strawberries',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
          ],
        },
      },
    },
    {
      id: '4',
      sequence: 3,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      position: 1,
      type: Components.carouselDetailed,
      content: {
        desktop: {
          title: 'Special Offers - Extra Sweet Savings on Edible Gifts!',
          url: '/special-offers',
          viewAllText:
            '<p>View All <span style="font-size: 14px;"></span><span>&#10132;</span></p>',
          viewAllButtonText: 'View All Arrangements',
          available: true,
          items: [
            {
              id: '5130',
              name: 'Summer Fruits Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Summer_Fruits_Platter.jpg',
              url: 'summer-fruits-platter-6065',
              price: 49.99,
              sizeCount: 1,
              alt: 'Summer Fruits Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7025',
              name: 'Time After Time™ Roses',
              productImageTag: '',
              nonPromo: 'Limited Time Offer!',
              nonPromoColor: colors.promoRed,
              promo: '',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Time_After_Time_Roses.jpg',
              url: 'time-after-time-roses-7957',
              price: 99.99,
              sizeCount: 1,
              alt: 'Time After Time Roses',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '5201',
              name: 'Rainbow and Butterflies Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: colors.promoRed,
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_ButterfliesRainbowsPlatter.jpg',
              url: 'Rainbow-and-Butterflies-Platter-6136',
              price: 59.99,
              sizeCount: 1,
              alt: 'Rainbow and Butterflies Fruit Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '4902',
              name: 'Chocolate Dipped Indulgence Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_ChocoDippedIndulgentPlat.jpg',
              url: 'dipped-fruit-decadence-platter-5837',
              price: 54.99,
              sizeCount: 1,
              alt: 'Dipped Fruit™ Decadence Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7026',
              name: 'Time After Time™ Roses & Godiva® Chocolate Gift Bundle',
              productImageTag: '',
              nonPromo: 'Limited Time Offer!',
              nonPromoColor: colors.promoRed,
              promo: '',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Time_After_Time_Roses_Godiva_Chocolate_Gift_Bundle.jpg',
              url: 'time-after-time-roses-godiva-chocolate-gift-bundle-7958',
              price: 132.98,
              sizeCount: 1,
              alt: 'Time After Time Roses  Godiva Chocolate Gift Bundle',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
          ],
        },
        mobile: {
          title: 'Special Offers - Extra Sweet Savings on Edible Gifts!',
          url: '/special-offers',
          viewAllText:
            '<p>View All <span style="font-size: 14px;"></span><span>&#10132;</span></p>',
          viewAllButtonText: 'View All Arrangements',
          available: true,
          items: [
            {
              id: '5130',
              name: 'Summer Fruits Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Summer_Fruits_Platter.jpg',
              url: 'summer-fruits-platter-6065',
              price: 49.99,
              sizeCount: 1,
              alt: 'Summer Fruits Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7025',
              name: 'Time After Time™ Roses',
              productImageTag: '',
              nonPromo: 'Limited Time Offer!',
              nonPromoColor: colors.promoRed,
              promo: '',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Time_After_Time_Roses.jpg',
              url: 'time-after-time-roses-7957',
              price: 99.99,
              sizeCount: 1,
              alt: 'Time After Time Roses',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '5201',
              name: 'Rainbow and Butterflies Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: colors.promoRed,
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_ButterfliesRainbowsPlatter.jpg',
              url: 'Rainbow-and-Butterflies-Platter-6136',
              price: 59.99,
              sizeCount: 1,
              alt: 'Rainbow and Butterflies Fruit Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '4902',
              name: 'Chocolate Dipped Indulgence Platter',
              productImageTag: '',
              nonPromo: '',
              nonPromoColor: '',
              promo: 'Save 20% when you choose pick up! Use code PICKUP20',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_ChocoDippedIndulgentPlat.jpg',
              url: 'dipped-fruit-decadence-platter-5837',
              price: 54.99,
              sizeCount: 1,
              alt: 'Dipped Fruit™ Decadence Platter',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
            {
              id: '7026',
              name: 'Time After Time™ Roses & Godiva® Chocolate Gift Bundle',
              productImageTag: '',
              nonPromo: 'Limited Time Offer!',
              nonPromoColor: colors.promoRed,
              promo: '',
              promoColor: colors.promoRed,
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/a/t_o_Time_After_Time_Roses_Godiva_Chocolate_Gift_Bundle.jpg',
              url: 'time-after-time-roses-godiva-chocolate-gift-bundle-7958',
              price: 132.98,
              sizeCount: 1,
              alt: 'Time After Time Roses  Godiva Chocolate Gift Bundle',
              salePriceColor: '',
              minSizeProductPrice: 10,
              isMinSizeOnSale: false,
            },
          ],
        },
      },
    },
    {
      id: '5',
      sequence: 4,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      type: Components.gridWidget,
      content: {
        desktop: {
          available: true,
          title: 'Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery',
          viewAllText: '',
          viewAllButtonText: 'View All Categories',
          url: '/fruit-arrangements',
          items: [
            {
              id: '0',
              name: 'Birthday',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/7947.jpg',
              url: 'birthday-gifts',
              frName: 'Anniversaire',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/7947.jpg',
              frUrl: 'birthday-gifts',
            },
            {
              id: '1',
              name: 'Same Day Delivery',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/l_Rainbow_And_Butterflies_Bouquet_s.jpg',
              url: 'same-day-delivery-gifts',
              frName: 'Livraison le jour même',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/l_Rainbow_And_Butterflies_Bouquet_s.jpg',
              frUrl: 'same-day-delivery-gifts',
            },
            {
              id: '2',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/Strawberries.jpg',
              name: 'Chocolate Covered Strawberries',
              url: 'chocolate-covered-strawberries',
              frName: 'Fraises enrobées de chocolat',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/Strawberries.jpg',
              frUrl: 'chocolate-covered-strawberries',
            },
            {
              id: '3',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/simply-edible-block.jpg',
              name: 'Gifts Under $30',
              url: 'gifts-under-30',
              frName: 'Cadeaux à moins de 30 $',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/simply-edible-block.jpg',
              frUrl: 'gifts-under-30',
            },
          ],
        },
        mobile: {
          available: true,
          title: 'Edible Arrangements® Fruit Baskets, Bouquets & Gift Delivery',
          viewAllText: '',
          viewAllButtonText: 'View All Categories',
          url: '/fruit-arrangements',
          items: [
            {
              id: '0',
              name: 'Birthday',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/7947.jpg',
              url: 'birthday-gifts',
              frName: 'Anniversaire',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/7947.jpg',
              frUrl: 'birthday-gifts',
            },
            {
              id: '1',
              name: 'Same Day Delivery',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/l_Rainbow_And_Butterflies_Bouquet_s.jpg',
              url: 'same-day-delivery-gifts',
              frName: 'Livraison le jour même',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/l_Rainbow_And_Butterflies_Bouquet_s.jpg',
              frUrl: 'same-day-delivery-gifts',
            },
            {
              id: '2',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/Strawberries.jpg',
              name: 'Chocolate Covered Strawberries',
              url: 'chocolate-covered-strawberries',
              frName: 'Fraises enrobées de chocolat',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/Strawberries.jpg',
              frUrl: 'chocolate-covered-strawberries',
            },
            {
              id: '3',
              src: 'https://resources.ediblearrangements.com/resources/en-us/i/b/simply-edible-block.jpg',
              name: 'Gifts Under $30',
              url: 'gifts-under-30',
              frName: 'Cadeaux à moins de 30 $',
              frSrc:
                'https://resources.ediblearrangements.com/resources/en-us/i/b/simply-edible-block.jpg',
              frUrl: 'gifts-under-30',
            },
          ],
        },
      },
    },
    {
      id: '6',
      sequence: 5,
      activeFrom: '2022-07-19T00:00:00.000Z',
      activeTo: '2022-07-31T23:59:59.999Z',
      sources: ['organic'],
      type: Components.banner,
      content: {
        mobile: {
          available: true,
          items: [
            {
              body: '<p><strong>FREE SHIPPING</strong> 🎁 ON ORDERS OVER $25</p>',
              hasGiftFinder: true,
              src: 'https://rescloud.ediblearrangements.com/image/upload/v1653552056/Development/dev/promotionalBanner/free-shipping-banner.png',
              url: '/free-delivery',
              bannerBackgroundColor: '#00d084',
            },
          ],
        },
        desktop: {
          available: true,
          items: [
            {
              body: '<p><strong>FREE SHIPPING</strong> 🎁 ON ORDERS OVER $25</p>',
              hasGiftFinder: true,
              src: 'https://rescloud.ediblearrangements.com/image/upload/v1653552056/Development/dev/promotionalBanner/free-shipping-banner.png',
              url: '/free-delivery',
              bannerBackgroundColor: '#00d084',
            },
          ],
        },
      },
    },
  ],
};
