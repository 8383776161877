import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

export const phoneNumber: SxProps<Theme> = (themes) => ({
  marginLeft: '8px',
  [themes.breakpoints.down(520)]: {
    margin: '0px',
  },
  '.MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    letterSpacing: 0.4,
    lineHeight: 1.6,
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: `${colors.redButtonHover} 8%`,
  },
});
export const ContainerStyle: SxProps = {
  width: ['320px', '688px', '740px'],
  [theme.breakpoints.between(370, 395)]: {
    width: '340px',
  },
  [theme.breakpoints.between(400, 415)]: {
    width: '363px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '380px',
  },
  [theme.breakpoints.between(820, 1100)]: {
    width: 'auto',
    maxWidth: '900px',
  },
  marginBottom: '10px',
};
export const title: SxProps = {
  fontSize: ['14px', '16px', '16px'],
};
export const containerWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0 auto',
  fontFamily: fonts.geomanistregular,
  fontSize: '16px',
};

export const main: SxProps = {
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

export const tablinkStyle: SxProps = {
  fontWeight: 500,
  mb: 4,
  textAlign: 'left',
  display: 'block',
  textTransform: 'Uppercase',
  marginTop: '30px',
  '&.Mui-selected': {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: fonts.poppins,
    lineHeight: '143%',
    fontStyle: 'normal',
  },
};

export const tabListStyle: SxProps = {
  '.MuiTabs-flexContainer': {
    display: ['ruby', 'ruby', 'flex'],
  },
  '.MuiTabs-scroller': {
    overflowX: 'auto',
  },
  display: 'flex',
  minWidth: '270px',
  padding: '8px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const pressroomContainer: SxProps = {
  flexGrow: 1,
  [theme.breakpoints.between(400, 435)]: {
    width: '410px',
  },
  marginTop: '30px',
  marginBottom: '30px',
  marginLeft: '30px',
};

export const accountContainer: SxProps = {
  display: ['block', 'block', 'flex'],
  margin: ['0', '0', '0 auto'],
  width: ['360px', '688px', '1060px'],
  pl: [0, 5, 0],
  [theme.breakpoints.between(400, 435)]: {
    width: '100%',
    marginTop: '70px',
  },
  [theme.breakpoints.between(820, 1100)]: {
    width: '95%',
    marginTop: '70px',
  },
  '& .MuiTabPanel-root': {
    padding: [2.5, 0, 0],
  },
};

export const link: ThemedSxProps = () => ({
  '&:hover': {
    textDecoration: 'underline',
  },
  textDecoration: 'none',
  fontSize: '12px',
});

export const button: ThemedSxProps = () => ({
  '&:hover': {
    textDecoration: 'underline',
  },
  textDecoration: 'none',
  paddingLeft: 0,
  fontWeight: '400',
});

export const timeLineBox: ThemedSxProps = () => ({
  display: 'block',
  border: `solid 1px ${colors.grey}`,
  textAlign: 'justify',
  width: '310px',
});

export const altTimeLineBox: ThemedSxProps = () => ({
  display: 'block',
  border: `solid 1px ${colors.grey}`,
  textAlign: 'justify',
});

export const timeLineContent: ThemedSxProps = () => ({
  height: '198px',
  width: '100%',
  margin: '15px auto',
  objectFit: 'contain',
});

export const sortButton: ThemedSxProps = () => ({
  width: 'auto',
  height: '36px',
  margin: '0 auto',
});

export const connector: ThemedSxProps = () => ({
  width: '5px',
  color: colors.grey,
});

export const timelineItem: ThemedSxProps = () => ({
  [`& .${timelineItemClasses.root}:before`]: {
    display: 'none',
  },
});

export const termAndConditionsWrapper: ThemedSxProps = () => ({
  width: '100%',
  margin: '0 auto',
});

export const PageHeading: ThemedSxProps = () => ({
  textAlign: 'center',
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px',
});

export const pageContainer: ThemedSxProps = () => ({
  backgroundColor: '#EAEAEA',
  margin: '15px 25px',
  padding: '25px',
  border: '2px solid #EAEAEA',
});

export const heading: ThemedSxProps = () => ({
  textAlign: 'center',
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px',
});

export const subTitle: ThemedSxProps = () => ({
  marginBottom: '10px',
  paddingBottom: '10px',
  fontSize: '14px',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& table th': {
      width: ['50%', '50%', '25%'],
      border: '0px',
      paddingLeft: '0px',
      paddingBottom: '0px',
      verticalAlign: 'top',
    },
    '& table td': {
      border: '0px',
      paddingBottom: '0px',
      verticalAlign: 'top',
    },
    '& p': {
      fontSize: '12px',
      color: '#000',
    },
  },
});

export const description: ThemedSxProps = () => ({
  padding: '20px',
  backgroundColor: '#fff',
  fontSize: '14px',
});

export const termAndConditionsLink: ThemedSxProps = () => ({
  color: '#03789B',
  margin: '15px 25px',
  textDecoration: 'none',
  '&:hover': {
    color: '#03789B',
  },
});
