import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchFilterName } from 'components/PDP/GoBack/feature/action';

interface State {
  status: Status;
  filterName: string;
}

const initialState: State = {
  status: Status.INIT,
  filterName: '',
};

const slice = createSlice({
  name: 'pdp/back/filter',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterName.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchFilterName.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.filterName = payload || '';
      })
      .addCase(fetchFilterName.rejected, (state) => {
        state.status = Status.ERROR;
        state.filterName = '';
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    setFulfilled: (state) => {
      state.status = Status.FULFILLED;
    },
    setState: (state, { payload }: { payload: State }) => payload,
  },
});
export const pdpBackFilterReducer = slice.reducer;
export const { setFulfilled, setState } = slice.actions;
