export enum LoginStatus {
  OK = 'OK',
  Error = 'Error',
}

export enum ErrorMessage {
  INVALID_USERNAME_OR_PASSWORD = 'InvalidUserNamePassword',
}

export interface ParsedCallCenterLoginResponse {
  _Status: LoginStatus;
  _Data: string;
}

export type ParsedLoginResponse = {
  _Status: LoginStatus;
  _Data: string;
  _UserId: number;
  _RewardsCount: number;
};

export type ParsedErrorResponse = { _Status: LoginStatus.Error; _Data: string };
