import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';

export const wrapper: SxProps = {
  display: 'flex',
  flexDirection: ['column', 'row', 'row'],
  width: 1,
  justifyContent: 'space-between',
};

export const addressWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 1,
  justifyContent: 'space-between',
};

export const nameStyle: SxProps = {
  position: 'absolute',
  marginTop: '60px',
  fontSize: '12px',
  color: colors.helperText,
  ml: 1,
};
