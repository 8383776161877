import { ProductType, ProductTypes } from 'api/products/types';
import { ProductTypeAnalyticsPostfixes } from 'enums/product-types';

export const getByType = (type: ProductTypes): string =>
  ({
    [ProductType.NotSpecified]: ProductTypeAnalyticsPostfixes.NotSpecified,
    [ProductType.Arrangement]: ProductTypeAnalyticsPostfixes.Arrangement,
    [ProductType.Package]: ProductTypeAnalyticsPostfixes.Package,
    [ProductType.Upgrade]: ProductTypeAnalyticsPostfixes.Upgrade,
    [ProductType.AddOn]: ProductTypeAnalyticsPostfixes.AddOn,
    [ProductType.Container]: ProductTypeAnalyticsPostfixes.Container,
  }[type]);
