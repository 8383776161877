import * as actions from 'components/Payment/feature/actions';
import * as select from 'components/Payment/feature/selectors';
import { isUS } from 'utils/country';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const currentCountry = isUS ? 'US' : 'CA';

export const useFetchStates = (): void => {
  const dispatch = useDispatch();
  const countries = useSelector(select.selectCountries);
  const filterCountry = countries.filter((c) => c.Code === currentCountry);
  const countryId = filterCountry.length && filterCountry[0].ID;

  useEffect(() => {
    if (countryId) {
      dispatch(actions.getStates(countryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);
};
