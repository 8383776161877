import { Box, Skeleton } from '@mui/material';
import { CarouselDetailedSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton';
import React, { FC } from 'react';

export const OrderConfirmationSkeleton: FC = () => (
  <Box width="98%" sx={{ margin: '0 auto' }}>
    <Skeleton
      variant="text"
      sx={{
        width: ['25%'],
        height: ['42px'],
        float: ['left'],
        margin: ['5px 0px 15px 0px'],
      }}
    />
    <Box width="100%" sx={{ float: 'left' }}>
      <Box
        sx={{ float: ['left'], width: { md: '62%', sm: '100%', xs: '100%' } }}
      >
        <Skeleton
          variant="text"
          sx={{
            width: ['98%'],
            height: ['42px'],
            float: ['left'],
          }}
        />
        <Skeleton
          variant="text"
          sx={{
            width: ['98%'],
            height: ['48px'],
            float: ['left'],
          }}
        />
        <Box width="98%" sx={{ float: 'left' }}>
          <Skeleton
            variant="text"
            sx={{
              width: { md: '45%', sm: '45%', xs: '100%' },
              height: ['72px'],
              float: ['left'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: { md: '45%', sm: '45%', xs: '100%' },
              height: ['72px'],
              float: ['right'],
            }}
          />
        </Box>
        <Box width="98%" sx={{ float: 'left' }}>
          <Skeleton
            variant="text"
            sx={{
              width: ['68%'],
              height: ['32px'],
              float: ['left'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: ['28%'],
              height: ['24px'],
              float: ['right'],
              marginTop: ['8px'],
            }}
          />
        </Box>
        <Box width="98%" sx={{ float: 'left' }}>
          <Skeleton
            variant="text"
            sx={{
              width: ['68%'],
              height: ['23px'],
              float: ['left'],
            }}
          />
          <Box width="98%" sx={{ float: 'left' }}>
            <Skeleton
              variant="text"
              sx={{
                width: { md: '45%', sm: '45%', xs: '100%' },
                height: ['136px'],
                float: ['left'],
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: { md: '45%', sm: '45%', xs: '100%' },
                height: ['136px'],
                float: ['right'],
              }}
            />
          </Box>
          <Skeleton
            variant="text"
            sx={{
              width: ['38%'],
              height: ['64px'],
              float: ['right'],
            }}
          />
        </Box>
      </Box>
      <Box
        height={450}
        sx={{
          float: ['right'],
          width: { md: '38%', sm: '100%', xs: '100%' },
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            width: ['100%'],
            height: ['400px'],
            float: ['left'],
            transform: ['scale(1, 0.98)'],
            borderRadius: ['30px'],
          }}
        />
      </Box>
    </Box>

    <Box width="100%" sx={{ float: 'left' }}>
      <Box
        height={633}
        sx={{ float: ['left'], width: { md: '62%', sm: '100%', xs: '100%' } }}
      >
        <Box width="98%" height={24} sx={{ float: 'left' }}>
          <Skeleton
            variant="text"
            sx={{
              width: ['66%'],
              height: ['24px'],
              float: ['left'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: ['28%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
        </Box>
        <Box width="98%" height={24} sx={{ float: 'left' }}>
          <Skeleton
            variant="text"
            sx={{
              width: ['28%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
        </Box>
        <Skeleton
          variant="text"
          sx={{
            width: ['98%'],
            height: ['42x'],
            float: ['left'],
            margin: ['10px 0'],
          }}
        />
        <Box width="98%" sx={{ float: 'left' }}>
          <Box width="98%" height={120} sx={{ float: 'left' }}>
            <Box width="30%" height={120} sx={{ float: 'left' }}>
              <Skeleton
                variant="text"
                sx={{
                  width: ['95%'],
                  height: ['120px'],
                  float: ['left'],
                  transform: ['scale(1, 0.98)'],
                  borderRadius: ['10px'],
                }}
              />
            </Box>
            <Box width="70%" height={120} sx={{ float: 'left' }}>
              <Skeleton
                variant="text"
                sx={{
                  width: ['45%'],
                  height: ['60px'],
                  float: ['left'],
                  transform: ['scale(1, 0.98)'],
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  width: ['25%'],
                  height: ['60px'],
                  float: ['right'],
                  transform: ['scale(1, 0.98)'],
                }}
              />
            </Box>
          </Box>
          <Skeleton
            variant="text"
            sx={{
              width: ['45%'],
              height: ['22x'],
              float: ['left'],
              margin: ['10px 0'],
            }}
          />
          <Box width="98%" height={120} sx={{ float: 'left' }}>
            <Box width="30%" height={120} sx={{ float: 'left' }}>
              <Skeleton
                variant="text"
                sx={{
                  width: ['95%'],
                  height: ['120px'],
                  float: ['left'],
                  transform: ['scale(1, 0.98)'],
                  borderRadius: ['10px'],
                }}
              />
            </Box>
            <Box width="70%" height={120} sx={{ float: 'left' }}>
              <Skeleton
                variant="text"
                sx={{
                  width: ['45%'],
                  height: ['60px'],
                  float: ['left'],
                  transform: ['scale(1, 0.98)'],
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  width: ['25%'],
                  height: ['60px'],
                  float: ['right'],
                  transform: ['scale(1, 0.98)'],
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="98%" sx={{ float: 'left' }} mt={8}>
          <Skeleton
            variant="text"
            sx={{
              width: ['58%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: ['58%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: ['58%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              width: ['58%'],
              height: ['24px'],
              float: ['right'],
            }}
          />
        </Box>
        <Box width="98%" sx={{ float: 'left' }} mt={4}>
          <Skeleton
            variant="text"
            sx={{
              width: ['42%'],
              height: ['38px'],
              float: ['right'],
            }}
          />
        </Box>
      </Box>
      <Box
        height={410}
        sx={{
          float: ['right'],
          width: { md: '38%', sm: '100%', xs: '100%' },
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            width: ['100%'],
            height: ['400px'],
            float: ['left'],
            transform: ['scale(1, 0.98)'],
            borderRadius: ['30px'],
          }}
        />
      </Box>
    </Box>
    <CarouselDetailedSkeleton />
  </Box>
);
