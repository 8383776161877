export const TITLE = 'gift_finder.gift_finder_title';
export const BUTTON_NAME = 'gift_finder.gift_finder_button_name';
export const SELECT_FRUIT_TITLE = 'gift_finder.gift_finder_select_fruit_title';
export const GIFT_FINDER = 'gift_finder.gift_finder_title';
export const GIFT_FINDER_TEXT_ONE = 'gift_finder.gift_finder_text_one';
export const GIFT_FINDER_TEXT_TWO = 'gift_finder.gift_finder_text_two';
export const SELECTED_FRUIT_TITLE =
  'gift_finder.gift_finder_selected_fruit_title';
export const SELECT_PRICE_TITLE = 'gift_finder.gift_finder_select_price_title';
export const SELECTED_PRICE_TITLE =
  'gift_finder.gift_finder_seleced_price_title';
export const SELECT_OCCASION_TITLE =
  'gift_finder.gift_finder_select_occasion_title';
export const SELECTED_OCCASION_TITLE =
  'gift_finder.gift_finder_selected_occasion_title';
export const GIFT_FINDER_VALIDATION_ERROR =
  'gift_finder.gift_finder_validation_error';
export const GIFT_FINDER_SPEED_LABEL = 'gift_finder.gift_finder_speed_label';
export const GIFT_FINDER_REASON_LABEL = 'gift_finder.gift_finder_reason_label';
export const GIFT_FINDER_COST_LABEL = 'gift_finder.gift_finder_cost_label';
