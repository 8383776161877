import { Helmet } from 'react-helmet-async';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { getDesktopUrl, getMobileUrl } from 'utils/get-domain-url';
import React, { FC } from 'react';

export const CanonicalUrlProvider: FC = ({ children }) => {
  const canonical = IS_MOBILE_HOST ? (
    <Helmet>
      <link
        rel="canonical"
        href={getDesktopUrl()}
        data-test="header-canonical-mobile-link"
      />
    </Helmet>
  ) : (
    <Helmet>
      <link
        rel="canonical"
        href={getDesktopUrl()}
        data-test="header-canonical-desktop-link"
      />
      <link
        rel="alternate"
        href={getMobileUrl()}
        media="only screen and (max-width: 640px)"
        data-test="header-alternate-desktop-link"
      />
    </Helmet>
  );

  return (
    <>
      {canonical}
      {children}
    </>
  );
};
