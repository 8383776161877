import * as TK from 'translations/locales/translation-keys.constant';
import { Box } from '@mui/system';
import { Certification } from 'enums/store-params';
import { Store } from 'redux/types/store.types';
import { Tag } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/components/Tag';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  store: Store;
};

export const StoreTags: FC<Props> = ({ store }) => {
  const isKosherShown = Boolean(store?.certifications === Certification.Kosher);

  return (
    <Box display="flex" flexWrap="wrap" mt={1.5}>
      {store.smoothie && <Tag mr={1}>{i18next.t(TK.SMOOTHIE)}</Tag>}
      {isKosherShown && <Tag mr={1}>{i18next.t(TK.KOSHER)}</Tag>}
      {store.curbside && <Tag>{i18next.t(TK.CURBSIDE)}</Tag>}
    </Box>
  );
};
