import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsOnlySm = (): boolean =>
  useMediaQuery(useTheme().breakpoints.only('sm'));

export const HideOnSm: FC = ({ children }) => {
  return useIsOnlySm() ? null : <>{children}</>;
};
