import { LegacyLogInValidationStatus } from 'api/auth-legacy/validate.misc';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { logIn } from 'components/Authentication/LogIn/feature/log-in.action';
import { validate } from 'components/Authentication/LogIn/feature/validate.action';

export enum Mode {
  None,
  LogIn,
  Reset,
}

interface State {
  validationStatus: Status;
  validationType: LegacyLogInValidationStatus;
  logInStatus: Status;
  mode: Mode;
}

const initialState: State = {
  validationStatus: Status.INIT,
  validationType: LegacyLogInValidationStatus.NONE,
  logInStatus: Status.INIT,
  mode: Mode.None,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(validate.pending, (state) => {
        state.validationStatus = Status.PENDING;
        state.validationType = LegacyLogInValidationStatus.NONE;
      })
      .addCase(validate.rejected, (state, { payload }) => {
        state.validationStatus = Status.ERROR;
        state.validationType =
          payload?._Status || LegacyLogInValidationStatus.EXCEPTION;
      })
      .addCase(validate.fulfilled, (state, { payload }) => {
        state.validationStatus = Status.FULFILLED;
        state.validationType =
          payload?._Status || LegacyLogInValidationStatus.NONE;
      })
      .addCase(logIn.pending, (state) => {
        state.logInStatus = Status.PENDING;
      })
      .addCase(logIn.rejected, (state) => {
        state.logInStatus = Status.ERROR;
        state.validationType = LegacyLogInValidationStatus.EXCEPTION;
      })
      .addCase(logIn.fulfilled, (state) => {
        state.logInStatus = Status.FULFILLED;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {
    reset: () => initialState,
    setMode: (state, { payload: mode }: { payload: Mode }) => {
      state.mode = mode;
    },
  },
});

export const authenticationReducer = slice.reducer;
export const { reset, setMode } = slice.actions;
