import {
  AUTH_RESET_STATUS_ERROR,
  AUTH_RESET_STATUS_EXCEPTION,
  AUTH_RESET_STATUS_NOT_FOUND,
} from 'components/Authentication/translations/locale.constants';
import { ResetPasswordStatus } from 'api/auth-legacy/reset-password.misc';

export const getResetStatusMessage = (status: ResetPasswordStatus): string => {
  switch (status) {
    case ResetPasswordStatus.NotFound:
      return AUTH_RESET_STATUS_NOT_FOUND;
    case ResetPasswordStatus.Error:
      return AUTH_RESET_STATUS_ERROR;
    case ResetPasswordStatus.Unexpected:
    default:
      return AUTH_RESET_STATUS_EXCEPTION;
  }
};
