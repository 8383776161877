import { ApiBaseService } from 'api/apibase.service';
import { Country, State } from 'components/Payment/feature/types';
import { ProcessPaymentRequest } from 'api/payment/types/process-payment-request.type';
import { ProcessPaymentResponse } from 'api/payment/types/process-payment-response.type';
import { getCartNgUrl } from 'utils/cart-validation/cart-region-validation';
import axios from 'axios';

class PaymentApiService extends ApiBaseService {
  processPayment(
    request: ProcessPaymentRequest,
  ): Promise<ProcessPaymentResponse> {
    const url = getCartNgUrl(request.region);
    return axios
      .post<ProcessPaymentResponse>(`${url}/cart/pay/process-payment`, request)
      .then(({ data }) => data);
  }

  getCountries(): Promise<Country[]> {
    return this.apibase.get<Country[]>(`/country`).then(({ data }) => data);
  }

  getStates(countryId: number): Promise<State[]> {
    return this.apibase
      .get<State[]>(`/country/states/${countryId}`)
      .then(({ data }) => data);
  }

  isEmailAlreadyExist(email: string): Promise<boolean> {
    return this.apibase
      .get<boolean>(`/sessions/validate-user/${JSON.stringify(email)}`)

      .then(({ data }) => data);
  }
}

export const paymentApiService = new PaymentApiService();
