import { DependencyList, useEffect, useMemo, useState } from 'react';

interface PromiseState<T> {
  state: 'loading' | 'completed' | 'error';
  isLoading: boolean;
  data?: T;
  error?: Error;
  invalidate: () => void;
}
/**
 * Utility for handling promise state
 */
export const usePromiseState = <T>(
  fn: () => Promise<T>,
  deps: DependencyList = [],
): PromiseState<T> => {
  const [trigger, setTrigger] = useState(false);
  const [state, setState] = useState<PromiseState<T>['state']>('loading');
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const isLoading = useMemo(() => state === 'loading', [state]);

  useEffect(() => {
    setState('loading');
    fn()
      .then((res) => {
        setData(res);
        setState('completed');
      })
      .catch((err) => {
        setError(err);
        setState('error');
      });
  }, [trigger].concat(deps));

  return {
    isLoading,
    data,
    error,
    state,
    invalidate: () => setTrigger((prev) => !prev),
  };
};
