import { Alert, AlertTitle, Snackbar, Typography } from '@mui/material';
import { RootState } from 'redux/store';
import { handleAlertState } from 'components/common/MainAlert/feature/slice';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC } from 'react';

export const MainAlert: FC = () => {
  const dispatch = useDispatch();
  const { isOpen, text, title } = useSelector(
    (state: RootState) => state.mainAlert,
  );

  const onClose = () => {
    dispatch(handleAlertState({ isOpen: false }));
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ minWidth: [320, 320, '100%'] }}
    >
      <Alert variant="filled" severity="error" onClose={onClose}>
        <AlertTitle>
          <Typography variant="subtitle2">{title}</Typography>
        </AlertTitle>
        {text}
      </Alert>
    </Snackbar>
  );
};
