import * as TK from 'components/Rewards/translations/locale.constants';
import * as actions from 'components/Payment/feature/actions';
import * as select from 'components/Payment/feature/selectors';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { Alert, Box, Button, Typography } from '@mui/material';
import { ChangePasswordForm } from 'components/Rewards/RewardLanding/Account/ChangePasswordForm';
import { EditAccountForm } from 'components/Rewards/RewardLanding/Account/EditAccountForm';
import { UserAccountInfo } from 'api/account/type/user-account-info-response';
import { accountApiService } from 'api/account/account.api.service';
import { format } from 'date-fns';
import { getAddressType, getCountryFullName } from 'components/Header/utils';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCountries } from 'components/Payment/BillingInformation/hooks/use-fetch-countries';
import { useFetchStates } from 'components/Payment/BillingInformation/hooks/use-fetch-states';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const Account: FC = () => {
  useFetchCountries();
  useFetchStates();
  const dispatch = useDispatch();

  const userAccountInformation = useSelector(selectUserAccountInformation);
  const states = useSelector(select.selectStates);
  const cities = useSelector(select.selectCities);
  const account: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId = account.length && account[0].id ? account[0].id : 0;
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isEditAccount, setIsEditAccount] = useState(false);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [userAccountInfo, setUserAccountInfo] = useState<UserAccountInfo>();
  const [isAlertLoad, setIsAlertLoad] = useState<boolean>(false);
  const [alertTextMsg, setAlertTextMsg] = useState<string>();

  const handlePasswordChangeClick = () => {
    setIsChangingPassword((prevState) => !prevState);
  };
  const handleEditClick = () => {
    setIsEditAccount((prevState) => !prevState);
  };

  const fetchUSerInfoByAccountID = async () => {
    const res = await accountApiService.getUSerInfoByAccountID(accountId);
    setUserAccountInfo(res);
  };

  useEffect(() => {
    if (accountId) {
      fetchUSerInfoByAccountID();
    }
  }, [accountId, isEditAccount]);

  useEffect(() => {
    if (userAccountInfo && userAccountInfo.areaOther) {
      dispatch(actions.getArea(userAccountInfo.areaOther));
    }
  }, [userAccountInfo]);

  useEffect(() => {
    if (userAccountInfo && states) {
      const selectedState = states.filter(
        (c) => c.ID === userAccountInfo.stateId,
      );
      const stateVal = selectedState.length
        ? selectedState[0].Abbreviation
        : '';
      setStateName(stateVal);
    }
  }, [userAccountInfo, states]);
  useEffect(() => {
    if (userAccountInfo && cities) {
      const selectedCity = cities.filter(
        (c) => c.mlId === Number(userAccountInfo.cityMlId),
      );
      const cityVal = selectedCity.length ? selectedCity[0].name : '';
      setCityName(cityVal);
    }
  }, [userAccountInfo, cities]);
  return (
    <>
      <Box sx={styles.accountContainer}>
        <Box>
          <Box
            sx={{
              display: isEditAccount ? 'block' : ['block', 'flex', 'flex'],
            }}
          >
            <Typography
              variant="h4"
              sx={styles.accountTitle}
              width={['325px', '455px', 'auto']}
            >
              {i18next.t(TK.ACCOUNT_PASSWORD_TITLE)}
            </Typography>
            {userAccountInfo && (
              <Box sx={{ display: 'flex' }}>
                {isEditAccount ? (
                  <Box>
                    <EditAccountForm
                      userAccountInfo={userAccountInfo}
                      setIsEditAccount={setIsEditAccount}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Button sx={styles.editAccount} onClick={handleEditClick}>
                      {i18next.t(TK.ACTION_EDIT)}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {userAccountInfo && isEditAccount === false && (
            <>
              <Box sx={{ marginTop: '20px' }}>
                <Typography
                  variant="subtitle1"
                  mb={2}
                  sx={styles.accountSubTitle}
                >
                  {i18next.t(TK.ACCOUNT_PASSWORD_SUBTITLE)}
                </Typography>
                <Box sx={styles.accountGridList}>
                  {userAccountInfo.title && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_TITLE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {' '}
                        {userAccountInfo.title}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.firstName && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.SIGNUP_FIRST_NAME)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.firstName
                          ? userAccountInfo.firstName
                          : ''}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.lastName && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.SIGNUP_LAST_NAME)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.lastName
                          ? userAccountInfo.lastName
                          : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={styles.accountGridList}>
                  {(userAccountInfo.cellPhone ||
                    userAccountInfo.workPhone ||
                    userAccountInfo.homePhone) && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.PHONE_NUMBER)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.cellPhone ||
                          userAccountInfo.workPhone ||
                          userAccountInfo.homePhone}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.email && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.EMAIL_ADDRESS)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.email ? userAccountInfo.email : ''}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.birthday && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.SIGNUP_BIRTHDAY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.birthday
                          ? format(
                              new Date(
                                userAccountInfo.birthday
                                  .toString()
                                  .replace('Z', ''),
                              ),
                              'MM/dd',
                            )
                          : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.addressContainer}>
                {(userAccountInfo.addressType > 0 ||
                  userAccountInfo.address1 ||
                  userAccountInfo.address2 ||
                  userAccountInfo.countryId > 0 ||
                  userAccountInfo.cityMlId ||
                  userAccountInfo.stateId ||
                  userAccountInfo.areaOther) && (
                  <Typography
                    variant="subtitle1"
                    mb={2}
                    sx={styles.accountSubTitle}
                  >
                    {i18next.t(TK.ACCOUNT_MY_ADDRESS)}
                  </Typography>
                )}
                {userAccountInfo.addressType > 0 && (
                  <Box sx={styles.accountGridList}>
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_ADDRESS_TYPE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {getAddressType(
                          userAccountInfo.addressType
                            ? userAccountInfo.addressType
                            : 0,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box sx={styles.accountGridList}>
                  {userAccountInfo.company && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_BUSINESS_NAME)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.company ? userAccountInfo.company : ''}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.address1 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_STREET_ADDRESS)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.address1
                          ? userAccountInfo.address1
                          : ''}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.address2 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_APARTMENT)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.address2
                          ? userAccountInfo.address2
                          : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={styles.accountGridList}>
                  {userAccountInfo.countryId > 0 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_COUNTRY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {getCountryFullName(
                          userAccountInfo.countryId
                            ? userAccountInfo.countryId
                            : 0,
                        )}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.cityMlId > 0 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_CITY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {cityName}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.stateId > 0 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_STATE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {stateName}
                      </Typography>
                    </Box>
                  )}
                  {userAccountInfo.areaOther && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_ZIP)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {userAccountInfo.areaOther
                          ? userAccountInfo.areaOther
                          : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px' }} />
      <Typography variant="h4" mb={2} sx={styles.accountTitle}>
        {i18next.t(TK.PASSWORD_TITLE)}
      </Typography>
      {isAlertLoad && (
        <Alert
          sx={styles.alertContainer}
          severity={isChangingPassword ? 'error' : 'success'}
        >
          <Typography variant="body2" textAlign="center">
            {alertTextMsg}
          </Typography>
        </Alert>
      )}
      {account && (
        <Box>
          {isChangingPassword ? (
            <ChangePasswordForm
              setIsChangingPassword={setIsChangingPassword}
              setIsAlertLoad={setIsAlertLoad}
              setAlertTextMsg={setAlertTextMsg}
            />
          ) : (
            <Box>
              <Typography ml={2}>Password</Typography>
              <Typography ml={2}>•••••••••••</Typography>
              <Button
                sx={styles.changePassword}
                onClick={handlePasswordChangeClick}
              >
                {i18next.t(TK.ACCOUNT_CHANGE_PASSWORD)}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
