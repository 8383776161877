import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { MarketplaceBrandsEnum } from 'enums/marketplace-brands.enum';

export const getMarketplaceBrandName = (
  arrangement: Arrangement,
): MarketplaceBrandsEnum => {
  return arrangement?.brands?.find((brand) =>
    Object.values(MarketplaceBrandsEnum).includes(
      brand as MarketplaceBrandsEnum,
    ),
  ) as MarketplaceBrandsEnum;
};
