import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const wrapper: SxProps = {
  display: 'inline-block',
  '& .mr5': {
    marginRight: '5px',
  },
  '& .m5': {
    margin: '5px',
  },
  '& .ml5': {
    marginLeft: '5px',
  },
  '& .mt5': {
    marginTop: '5px',
  },
  '& .lineStyle': {
    width: { md: '57%', sm: '100%', xs: '100%' },
    marginTop: '10px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  marginTop: { md: '3%', sm: '-467px', xs: '-365px' },
  position: { md: 'inherit', sm: 'relative', xs: 'relative' },
  top: { md: '3%', sm: '-400px', xs: '-580px' },
};

export const orderWrapper: SxProps = {
  display: 'inline-block',
  '& .mr5': {
    marginRight: '5px',
  },
  '& .m5': {
    margin: '5px',
  },
  '& .ml5': {
    marginLeft: '5px',
  },
  '& .mt5': {
    marginTop: '5px',
  },
  '& .lineStyle': {
    width: { md: '57%', sm: '100%', xs: '100%' },
    marginTop: '10px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  position: { md: 'inherit', sm: 'relative', xs: 'relative' },
};

export const title: SxProps = {
  fontWeight: '600',
  fontSize: '20px',
};
export const payMethod: SxProps = {
  fontWeight: '600',
  fontSize: '16px',
  margin: '20px 0 10px 0',
};
export const cardStyle: SxProps = {
  fontWeight: '400',
  fontSize: '16px',
  display: 'inline-grid',
  fontFamily: fonts.poppins,
  width: { md: '35%', sm: '61%', xs: '70%' },
  height: { md: '100%', sm: '100%', xs: '130px' },
};
export const marginLeft: SxProps = {
  marginLeft: '10px',
};
export const cardContent: SxProps = {
  display: 'flex',
  height: '30px',
  '& .masterImg': {
    margin: '-5px 0px 0px 5px',
  },
  '& .visaImg': {
    margin: '3px 0px 0px 5px',
  },
  '& .americanImg': {
    margin: '-8px 0px 0px 5px',
  },
  '& .discoverImg': {
    margin: '-6px 0px 0px 5px',
  },
};
export const billStyle: SxProps = {
  width: { md: '30%', sm: '38%', xs: '101%' },
  display: 'inline-grid',
};
export const billInfo: SxProps = {
  fontSize: '16px',
  marginBottom: '5px',
  '& .billTxt': {
    marginTop: '5px',
  },
};
export const cardTitle: SxProps = {
  height: '110px',
  display: 'flex',
  '& .title': {
    fontWeight: '400',
    fontSize: '16px',
  },
};
export const cardNumber: SxProps = {
  position: 'absolute',
  marginTop: '35px',
  fontWeight: '400',
  fontSize: '16px',
};

export const orderDetailsTitle = {
  color: colors.activeBlack,
  textAlign: { md: 'right', sm: 'right', xs: 'left' },
  width: { md: '57%', sm: '90%', xs: '90%' },
  marginTop: '35px',
};

export const billWrapper = {
  height: '115px',
};

export const venmoPaymentWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  mb: '14px',
};

export const venmoPaymentContent = {
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
};

export const venmoPaymentText = {
  fontSize: '16px',
  lineHeight: '24px',
};
