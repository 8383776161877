import { ApiBaseService } from 'api/apibase.service';
import { ArrangementGroup } from 'redux/PDP/arrangement/types/arrangement-group.types';
import { AxiosPromise, AxiosResponse } from 'axios';
import { ArrangementGroupTargets as Targets } from 'enums/arrangement-group-targets';

export type ArrangementGroupOccasion = {
  id: number;
  name: string;
};

class ArrangementGroupApiService extends ApiBaseService {
  getOccasions(target: Targets): AxiosPromise<ArrangementGroupOccasion[]> {
    return this.apibase.get('/arrangement-group/occasions', {
      params: target ? { target } : {},
    });
  }

  findOne(id: number): Promise<AxiosResponse<ArrangementGroup>> {
    return this.apibase.get(`/arrangement-group/${id}`);
  }
}

const groupsApiService = new ArrangementGroupApiService();

export { groupsApiService };
