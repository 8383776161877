/**
 *  Extracts text from HTML string
 * @param htmlText
 * @returns string
 */
export const extractTextFromHTML = (htmlText: string): string => {
  const regex = /<\/?[^>]+>/gi;
  const extractedText = htmlText.replace(regex, '').trim();
  return extractedText;
};
