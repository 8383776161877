import { BvCallback } from 'lib/bazaarvoice/types/bv-callback.type';
import { Locale } from 'enums/locale';
import { getLocale } from 'lib/bazaarvoice/utils/get-locale';
import { useEffect } from 'react';

const callbacks: BvCallback[] = [];

export function useBazaarvoice(props?: {
  callback?: BvCallback;
  locale?: Locale;
}): void {
  useEffect(() => {
    if (props?.callback) {
      callbacks.push(props.callback);
      window.bvCallback = (bv) => {
        callbacks.forEach((callback) => callback(bv));
      };
    }
    return () => {
      callbacks.splice(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const getScript = () => {
  const bvLocale = getLocale(Locale.EN_US);
  const script = document.createElement('script');
  script.dataset.test = 'bazaarvoice-script';
  script.async = true;
  script.src = window.BAZAARVOICE_SCRIPT_URL.replace('{locale}', bvLocale);
  return script;
};

export const useBazaarvoiceScript = (): void => {
  useEffect(() => {
    document.head.insertBefore(getScript(), document.head.childNodes[1]);
  });
};
