import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const accordion: SxProps = {
  height: 80,
  display: 'flex',
  alignItems: 'center',
};

export const summary: SxProps<Theme> = (theme) => ({
  p: 0,
  justifyContent: 'space-between',

  '&.Mui-disabled': {
    opacity: 1,
  },
  '& *': {
    color: theme.palette.text.primary,
  },
});

export const preview: SxProps = {
  textOverflow: 'ellipsis',
  maxWidth: [200, 300, 260],
  width: 'auto',
  pl: 2,
  textAlign: 'right',
};
