export const SAVE = 'ln.save';
export const ADD = 'ln.add';
export const CANCEL = 'ln.cancel';
export const LETTERS_OPTION_TITLE = 'ln.letters_option_title';
export const LETTERS_OPTION_MODAL_TITLE = 'ln.letters_option_modal_title';
export const LETTERS_OPTION_EXAMPLE = 'ln.letters_option_example';
export const LETTERS_OPTION_WARN = 'ln.letters_option_warn';
export const LETTERS_INPUT_LABEL = 'ln.letters_input_label';
export const NUMBERS_OPTION_TITLE = 'ln.numbers_option_title';
export const NUMBERS_OPTION_MODAL_TITLE = 'ln.numbers_option_modal_title';
export const NUMBERS_OPTION_EXAMPLE = 'ln.numbers_option_example';
export const NUMBERS_OPTION_WARN = 'ln.numbers_option_warn';
export const NUMBERS_INPUT_LABEL = 'ln.numbers_input_label';
export const YEAR_OPTION_TITLE = 'ln.year_option_title';
export const YEAR_OPTION_MODAL_TITLE = 'ln.year_option_modal_title';
export const YEAR_OPTION_EXAMPLE = 'ln.year_option_example';
export const YEAR_OPTION_WARN = 'ln.year_option_warn';
export const YEAR_INPUT_LABEL = 'ln.year_input_label';
export const EMPTY_VALUE_VALIDATION_ERROR_MESSAGE =
  'ln.empty_value_validation_error_message';
export const CENSORED_WORD_VALIDATION_ERROR_MESSAGE =
  'ln.censored_word_validation_error_message';
export const SWITCH_TO_THIS_OPTION = 'ln.switch_to_this_option';
export const MAXIMUM_3_CHARACTERS = 'ln.maximum_3_characters';
export const MAXIMUM_5_CHARACTERS = 'ln.maximum_5_characters';
export const ONLY_NUMBERS_ALLOWED = 'ln.only_numbers_allowed';
export const MUST_BE_4_CHARACTERS_LONG = 'ln.must_be_4_characters_long';
export const ONLY_UPPERCASE_LETTERS_ALLOWED =
  'ln.only_uppercase_letters_allowed';
