import * as TK from 'components/PDP/Upsells/components/UpsellOption/LettersAndNumbers/translations/translation-keys.constant';

export const lettersAndNumbersCommonLocale = {
  [TK.SAVE]: 'Save',
  [TK.ADD]: 'Add',
  [TK.CANCEL]: 'Cancel',
  [TK.LETTERS_OPTION_TITLE]: 'Letter (1–5 chars)',
  [TK.LETTERS_OPTION_MODAL_TITLE]: 'Letter',
  [TK.LETTERS_OPTION_EXAMPLE]: 'Example: Birthday — add their name',
  [TK.LETTERS_OPTION_WARN]: 'Only upper case letters are allowed',
  [TK.LETTERS_INPUT_LABEL]: 'Enter Letters',
  [TK.NUMBERS_OPTION_TITLE]: 'Number (1–3 chars)',
  [TK.NUMBERS_OPTION_MODAL_TITLE]: 'Number',
  [TK.NUMBERS_OPTION_EXAMPLE]: "Example: Sweet Sixteen - add a '16'",
  [TK.NUMBERS_OPTION_WARN]: 'Only numbers are allowed',
  [TK.NUMBERS_INPUT_LABEL]: 'Enter Your Number',
  [TK.YEAR_OPTION_TITLE]: 'Year (4 chars)',
  [TK.YEAR_OPTION_MODAL_TITLE]: 'Year',
  [TK.YEAR_OPTION_EXAMPLE]:
    'Example: Class Reunion — type the graduation year (YYYY)',
  [TK.YEAR_OPTION_WARN]: 'Only numbers are allowed',
  [TK.YEAR_INPUT_LABEL]: 'Enter Year',
  [TK.EMPTY_VALUE_VALIDATION_ERROR_MESSAGE]:
    'Enter at least one character to continue',
  [TK.CENSORED_WORD_VALIDATION_ERROR_MESSAGE]:
    'This combination of letters is prohibited. Please try another combination',
  [TK.SWITCH_TO_THIS_OPTION]: 'Switch to This Option',
  [TK.MAXIMUM_3_CHARACTERS]: 'Maximum 3 characters.',
  [TK.MAXIMUM_5_CHARACTERS]: 'Maximum 5 characters.',
  [TK.ONLY_NUMBERS_ALLOWED]: 'Only numbers are allowed.',
  [TK.MUST_BE_4_CHARACTERS_LONG]: 'Must be 4 characters long.',
  [TK.ONLY_UPPERCASE_LETTERS_ALLOWED]: 'Only upper case letters are allowed.',
};
