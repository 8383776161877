import { Address, ValidatedAddress } from 'api/fulfillment/utils';
import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { LegacyAddedToCartResponse } from 'redux/cart/cart.types';

class FulfillmentApiService extends ApiBaseService {
  async validateAddress(address: Address): Promise<ValidatedAddress> {
    const { data } = await this.apibase.post<ValidatedAddress>(
      '/order/fulfillment/address-validation',
      address,
    );
    return data;
  }

  legacyAddToCart(data: string): AxiosPromise<LegacyAddedToCartResponse> {
    /** if mobile domain, then ignore ajax prefix */
    const prefix = IS_MOBILE_HOST ? '' : '/ajax';

    return this.apibase.post<LegacyAddedToCartResponse>(
      `${prefix}/OrderingForm.aspx/AddtoCart`,
      data,
      {
        headers: {
          'content-type': 'application/json; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest',
        },
        baseURL: '/',
      },
    );
  }

  legacyUpdatePickUpTimeToCart(
    data: string,
  ): AxiosPromise<LegacyAddedToCartResponse> {
    /** if mobile domain, then ignore ajax prefix */
    const prefix = IS_MOBILE_HOST ? '' : '/ajax';
    return this.apibase.post<LegacyAddedToCartResponse>(
      `${prefix}/OrderingForm.aspx/UpdateCartRecipientContact`,
      `${data}`,
      {
        headers: {
          'content-type': 'application/json; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest',
        },
        baseURL: '/',
      },
    );
  }
}

const fulfillmentApiService = new FulfillmentApiService();

export { fulfillmentApiService };
