import * as TK from 'components/Subscription/translations/locale.constants';

export const subscriptionCommonLocale = {
  [TK.SUBSCRIPTION_TERMS_OF_USE]: 'Terms of Use',
  [TK.SUBSCRIPTION_FOOTER_DIALOG_TITLE]: 'Sweet!',
  [TK.SUBSCRIPTION_FOOTER_DIALOG_SUBTITLE]: 'Thanks for signing up.',
  [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_EMAIL]:
    'Check your inbox for your first email from us soon!',
  [TK.SUBSCRIPTION_FOOTER_DIALOG_INFORM_TEXT]:
    'Check your messages for your first text from us soon!',
  [TK.SUBSCRIPTION_FOOTER_DIALOG_SOCIAL_LINKS_TITLE]:
    'Make sure you connect with us here, too:',
  [TK.SUBSCRIPTION_SHOP_NOW]: 'Shop Now',
  [TK.SUBSCRIPTION_EMAIL_DIALOG_FORM_STEP_BACKGROUND_ALT]:
    'Email subscription form step background',
  [TK.SUBSCRIPTION_EMAIL_DIALOG_SUBMIT_SUCCESS_STEP_BACKGROUND_ALT]:
    'Email subscription submit success step background',
  [TK.SUBSCRIPTION_FACEBOOK]: 'facebook',
  [TK.SUBSCRIPTION_TWITTER]: 'twitter',
  [TK.SUBSCRIPTION_INSTAGRAM]: 'instagram',
  [TK.SUBSCRIPTION_LINKEDIN]: 'linkedin',
  [TK.SUBSCRIPTION_YOUTUBE]: 'youtube',
  [TK.SUBSCRIPTION_EMAIL_FORM_ERROR]: 'Enter a valid email address',
  [TK.SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER]: 'yourname@email.com',
  [TK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL]: 'Sign Up',
  [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE]: 'See ',
  [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND]: 'and',
  [TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS]: 'Terms & Conditions',
  [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER]: ' for details.',
  [TK.SUBSCRIPTION_EMAIL_FORM_LABEL]: 'Email Address*',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE]: 'unlock your discount',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT]: 'Join our email list for ',
  [TK.NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT]:
    'Join our email or SMS list for ',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT]: 'instant savings',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT]: 'instant',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT]: 'savings',
  [TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT]:
    'on your next pickup order',
  [TK.SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Something You’ll Love!',
  [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_VAL]: '15',
  [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_PER]: '%',
  [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT]: 'off',
  [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_STAR]: '*',
  [TK.SUBSCRIPTION_FIRST_POPUP_DESCRIPTION]:
    'your next pickup order when you sign up to receive our emails today!',
  [TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE]: 'sweet!',
  [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST]:
    'Thanks for signing up! Check your email inbox',
  [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST]:
    'for 15% off your next pickup purchase.',
  [TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE]:
    'Connect with us on social media:',
  [TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT]:
    'Order a freshly-crafted gift for any occasion!',
  [TK.SUBSCRIPTION_IMAGE_TITLE]: 'Delicious Fruit design® Dipped Strawberries',
  [TK.ERROR_PHONE]: 'Invalid phone number',
  [TK.ERROR_EMAIL]: 'Invalid email',
  [TK.REQUIRED_EMAIL]: 'Email is required',
  [TK.ERROR_AGREE]: 'You must agree to the policy',
};
