import * as TK from 'components/Authentication/translations/locale.constants';
import { LegacyLogInValidationStatus } from 'api/auth-legacy/validate.misc';

export const getValidationMessage = (
  status: LegacyLogInValidationStatus,
): string => {
  switch (status) {
    case LegacyLogInValidationStatus.ERROR:
      return TK.AUTH_LOG_IN_ERR_TRY_AGAIN;
    case LegacyLogInValidationStatus.ACCOUNT_LOCK:
    case LegacyLogInValidationStatus.ACCOUNT_LOCK_DESKTOP:
      return TK.AUTH_LOG_IN_ERR_LOCKED;
    case LegacyLogInValidationStatus.INVALID_MAIL_PASSWORD:
      return TK.AUTH_LOG_IN_ERR_INVALID_CREDENTIALS;
    case LegacyLogInValidationStatus.ERROR_DESKTOP:
      return TK.AUTH_LOG_IN_ERR_NOT_EXIST;
    default:
      return TK.AUTH_LOG_IN_ERR_EXCEPTION;
  }
};
