import { SxProps, Theme } from '@mui/system';
import { TextAndButtonAlignment } from 'components/Home/SecondaryBanner/types';
import { fonts } from 'utils/theme/fonts';
import React from 'react';

export const backGroundImageWrapper: SxProps = {
  display: 'flex !important',
  minHeight: ['400px', '400px', '720px'],
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
};

export const backGroundImageWrapperSecondaryBanner: SxProps = {
  display: 'flex !important',
  minHeight: ['264px', '264px', '320px'],
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
};

export const mainContainerSecondaryBanner: SxProps = {
  display: 'flex',
  maxWidth: '1060px',
  width: ['auto', 'auto', 'auto'],
};

export const getAlignmentSecondaryBannerStyles = (
  alignment: TextAndButtonAlignment,
): React.CSSProperties => {
  switch (alignment) {
    case TextAndButtonAlignment.TopLeft:
      return { top: '32px', left: '32px' };
    case TextAndButtonAlignment.TopRight:
      return { top: '32px', right: '32px', textAlign: 'right' };
    case TextAndButtonAlignment.MiddleLeft:
      return { top: '52%', left: '32px', transform: 'translate(0%, -48%)' };
    case TextAndButtonAlignment.MiddleRight:
      return {
        top: '52%',
        right: '32px',
        textAlign: 'right',
        transform: 'translate(-2%, -48%)',
      };
    case TextAndButtonAlignment.BottomLeft:
      return { bottom: '32px', left: '32px' };
    case TextAndButtonAlignment.BottomRight:
      return { bottom: '32px', right: '32px', textAlign: 'right' };
    default:
      return {};
  }
};

export const getMobileAlignmentSecondaryBannerStyles = (
  alignment: TextAndButtonAlignment,
): React.CSSProperties => {
  switch (alignment) {
    case TextAndButtonAlignment.TopLeft:
      return { top: '24px', left: '24px' };
    case TextAndButtonAlignment.TopRight:
      return { top: '24px', right: '24px', textAlign: 'right' };
    case TextAndButtonAlignment.MiddleLeft:
      return { top: '45%', left: '24px', transform: 'translate(0%, -55%)' };
    case TextAndButtonAlignment.MiddleRight:
      return {
        top: '45%',
        right: '24px',
        textAlign: 'right',
        transform: 'translate(-2%, -55%)',
      };
    case TextAndButtonAlignment.BottomLeft:
      return { bottom: '24px', left: '24px' };
    case TextAndButtonAlignment.BottomRight:
      return { bottom: '24px', right: '24px', textAlign: 'right' };
    default:
      return {};
  }
};

export const getHeaderTextSecondaryBannerStyles = (
  bannerLength: number,
): React.CSSProperties => {
  switch (bannerLength) {
    case 1:
      return { width: '40%' };
    case 2:
      return { width: '40%' };
    default:
      return {};
  }
};

export const getMobileHeaderTextSecondaryBannerStyles = (
  bannerLength: number,
): React.CSSProperties => {
  switch (bannerLength) {
    case 1:
      return { width: '50%' };
    case 2:
      return { width: '40%' };
    default:
      return {};
  }
};

export const titleTextSecondaryBanner: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: ['20px', '20px', '24px'],
  alignSelf: 'stretch',
  fontWeight: 500,
  lineHeight: ['26.4px', '26.4px', '32.02px'],
};

export const textContainerSecondaryBanner: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start !important',
  alignSelf: 'stretch',
  position: 'absolute',
  width: ['auto', 'auto', '50%'],
  gap: '16px',
};

export const subTitleTextSecondaryBanner: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: ['14px', '14px', '16px'],
  alignSelf: 'stretch',
  fontWeight: ['500', '500', '500'],
  lineHeight: ['20.02px', '20.02px', '24px'],
};

export const backGroundImageWrapperTop: SxProps = {
  display: 'flex !important',
  minHeight: ['400px', '400px', '720px'],
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
};

export const mainContainer: SxProps = {
  display: 'flex',
  maxWidth: '1650px',
  width: ['auto', 'auto', '1650px'],
};

export const mainContainerTop: SxProps = {
  display: 'flex',
  maxWidth: '1060px',
  padding: ['24px', '24px', '64px 0px'],
  alignItems: ['center', 'center', 'center'],
  gap: ['24px', '24px', '32px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  margin: '0 auto',
  flexDirection: ['column', 'column', 'column'],
  justifyContent: ['center', 'center', 'center'],
};

export const mainFrameDesktop: SxProps = {
  display: 'flex',
};

export const mainFrameDesktopTop: SxProps = {
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  alignItems: ['center', 'center', 'center'],
  gap: ['16px', '16px', '8px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const mainFrameDesktopTopWithoutSmallText: SxProps = {
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  alignItems: ['center', 'center', 'center'],
  gap: '24px',
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const mainFrameMobile: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  justifyContent: 'center',
  gap: ['16px', '16px', '80px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('sm')]: {
    maxWidth: ['480px'],
    alignItems: 'center !important',
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: ['480px'],
    alignItems: 'center !important',
  },
});

export const mainFrameMobileTop: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: ['column', 'column', 'column'],
  justifyContent: 'center',
  gap: ['16px', '24px', '80px'],
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('sm')]: {
    maxWidth: ['480px'],
    alignItems: 'flex-start !important',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: ['480px'],
    alignItems: 'flex-start !important',
    flexDirection: 'column',
  },
});

export const spacer: SxProps<Theme> = (theme) => ({
  display: 'flex !important',
  flexDirection: 'column !important',
  alignItems: 'flex-start !important',
  gap: '10px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.up('md')]: {
    width: '514px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '300px',
  },
  [theme.breakpoints.up('xs')]: {
    width: '186px',
  },
});

export const buttonContainer: SxProps = {
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'absolute',
};

export const buttonStyle: SxProps = {
  display: 'flex',
  padding: ['9px 24px', '9px 24px', '15px 32px'],
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
};

export const buttonText: SxProps = {
  fontFamily: fonts.poppins,
  fontWeight: '600',
  fontSize: ['13px', '13px', '15px'],
  lineHeight: ['22px', '22px', '26px'],
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
};
