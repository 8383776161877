import { theme } from 'utils/theme/theme';
import styled from '@emotion/styled/macro';

interface InputWrapperProps {
  disabled?: 1 | 0;
}

export const InputWrapper = styled('div')(
  ({ disabled }: InputWrapperProps) => ({
    display: 'flex',
    padding: theme.spacing(1, 3),
    border: '1px solid',
    borderColor: disabled
      ? theme.palette.action.disabled
      : theme.palette.other.standardInputLine,
  }),
);
