import { Banner } from 'api/banner/types';
import { Box } from '@mui/material';
import React, { FC } from 'react';
import parse from 'html-react-parser';

type Props = { banner: Banner };

export const SkinnyMobile: FC<Props> = ({ banner }) => {
  if (!banner.mobileImage) {
    return null;
  }
  return (
    <Box
      display="flex"
      maxWidth={640}
      minHeight={[65, 110, 40]}
      my={0}
      mx="auto"
      data-test="header-banner"
    >
      <img
        src={banner.mobileImage}
        alt={banner.alt}
        style={{ width: '100%' }}
        useMap={`#SkinnyBannerMap${banner.id}`}
      />
      {parse(banner.mobileImageMap)}
    </Box>
  );
};
