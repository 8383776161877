import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/material';

export const CarouselWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  boxSizing: 'content-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  [breakpoints.down('md')]: {
    padding: '0px',
    maxWidth: '88%',
    marginLeft: '45px',
    marginTop: '30px',
  },
  [breakpoints.down('sm')]: {
    padding: '0px',
    maxWidth: '88%',
    marginLeft: '23px',
  },
  '& .lineStyle': {
    width: '128%',
    margin: '20px 0 50px -14.5%',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
}));

export const Headline = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
  },
}));

export const title: ThemedSxProps = {
  whiteSpace: 'wrap',
  fontWeight: '600',
  fontSize: '20px',
  flex: '1 1 auto',
  letterSpacing: '0.15px',
  lineHeight: '160%',
  pr: 1.2,
};

export const CarouselContainer = styled('div')({
  '& .slick-slider': {
    '& .slick-list': {
      margin: '0 -10px',
      '& .slick-track': {
        display: 'flex',
        '& .slick-slide': {
          margin: '0px 10px',
        },
      },
    },
  },
});

export const arrowControls: ThemedSxProps = (theme) => ({
  display: 'flex',
  position: 'relative',
  '& button:first-of-type': {
    mr: 1.2,
    [theme.breakpoints.up('sm')]: {
      ml: 2.5,
      mr: 2.5,
    },
  },
});
