import { BulkHours } from 'interfaces/store-details-page.interfaces';
import {
  StoreAvailability,
  StoreOccasionHours,
} from 'interfaces/store-occasion-hours-and-availabilities.interface';
import { zonedTime } from 'utils/date';

interface TimeRange {
  openingTime: string | null;
  closingTime: string | null;
}

export const setOpeningAndClosingTimes = (
  time: StoreOccasionHours | StoreAvailability | BulkHours | undefined,
): TimeRange => {
  return {
    openingTime: time?.openingTime
      ? zonedTime(time.openingTime.toString())
      : null,
    closingTime: time?.closingTime
      ? zonedTime(time.closingTime.toString())
      : null,
  };
};
