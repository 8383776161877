import * as TK from 'components/Subscription/translations/locale.constants';
import * as Yup from 'yup';
import i18next from 'i18next';

const PHONE_NUMBER_LENGTH = 10;

export const signUpSubscriptionFormSchema = Yup.object().shape({
  email: Yup.string().trim().email().required(),
  phone: Yup.string().matches(
    /^$|^\d{3}-\d{3}-\d{4}$/,
    i18next.t(TK.ERROR_PHONE),
  ),
  isAgreeWithPolicy: Yup.boolean()
    .required()
    .when('phone', (phone: string, schema: Yup.BooleanSchema) => {
      const clearPhone = phone.replace(/-/g, '');

      return clearPhone.length === PHONE_NUMBER_LENGTH
        ? schema.oneOf([true])
        : schema;
    }),
});
