import * as TK from 'components/GiftFinder/translations/locale.constants';
import * as styles from 'components/GiftFinder/styles';
import {
  Alert,
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ExpandedCostSelector } from 'components/GiftFinder/Form/expanded-cost-selector';
import { ExpandedReasonSelector } from 'components/GiftFinder/Form/expanded-reason-selector';
import { ExpandedSpeedSelector } from 'components/GiftFinder/Form/expanded-speed-selector';
import { Filter } from 'api/filter-categories/types';
import { GiftFinderFormData } from 'types/forms/gift-finder-data';
import { GiftFinderSelectorWrapper } from 'components/GiftFinder/Form/gift-finder-selector-wrapper';
import { Links } from 'enums/common-links';
import { StyledForm } from 'components/GiftFinder/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { analytics } from 'service/adobe-analytics/analytics';
import {
  checkIsInitFruitFiltersBlocked,
  getOccasions,
  getPriceRange,
  getSpeedCategories,
} from 'components/PLP/FilterCategories/feature/selectors';
import {
  clearGiftFinderFilters,
  setGiftFinderFilters,
} from 'components/GiftFinder/feature/gift-finder.slice';
import { fetchFilterCategories } from 'components/PLP/FilterCategories/feature/actions';
import { isEmpty } from 'utils/string/size';
import { useDispatch, useSelector } from 'react-redux';
import { useGiftFinderFormStart } from 'components/GiftFinder/hooks/use-gift-finder-form-start';
import { useNavigate } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  analyticsClickHandler?: () => void;
}

interface Errors {
  mobile: boolean;
  desktop: boolean;
}

const initialErrors: Errors = {
  mobile: false,
  desktop: false,
};

const defaultValues: GiftFinderFormData = {
  occasion: '',
  price: '',
  fruit: '',
};

enum SelectorType {
  SPEED = 'SPEED',
  COST = 'COST',
  REASON = 'REASON',
}

export const InlineGiftFinder: FC<Props> = ({ analyticsClickHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const occasionCategories = useSelector(getOccasions);
  const priceCategories = useSelector(getPriceRange);
  const speedCategories = useSelector(getSpeedCategories);
  const isInitFruitFiltersBlocked = useSelector(checkIsInitFruitFiltersBlocked);
  const [occasionFilter, setOccasionFilter] = useState<Filter[] | null>(null);
  const [priceFilter, setPriceFilter] = useState<Filter[] | null>(null);
  const [speedFilter, setSpeedFilter] = useState<Filter[] | null>(null);
  const [selectedCostRange, setSelectedCostRange] = useState<number[]>([0, 0]);
  const [expandedSelector, setExpandedSelector] = useState<SelectorType | null>(
    null,
  );

  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [errors] = useState<Errors>(initialErrors);
  const isError = isUpMd ? errors.desktop : errors.mobile;

  useGiftFinderFormStart();

  const { handleSubmit, formState } = useForm<GiftFinderFormData>({
    defaultValues,
  });
  const isAnyFieldValue =
    occasionFilter?.length || priceFilter?.length || speedFilter?.length;
  const isAnyFieldSelected = !!isAnyFieldValue;
  const findButtonDisable = !isEmpty(formState.errors) && !isAnyFieldSelected;
  const errorValidationCheck =
    !isEmpty(formState.errors) && !isAnyFieldSelected;
  useEffect(() => {
    if (occasionCategories.length < 1 && priceCategories.length < 1) {
      dispatch(fetchFilterCategories());
    }
  }, [occasionCategories.length, priceCategories.length]);
  if (occasionCategories.length < 1 && priceCategories.length < 1) {
    return null;
  }
  const onSubmit: SubmitHandler<GiftFinderFormData> = () => {
    if (analyticsClickHandler) analyticsClickHandler();

    dispatch(clearGiftFinderFilters());

    if (occasionFilter?.length) {
      dispatch(setGiftFinderFilters(occasionFilter));
      const occasionValue: string[] = occasionFilter.map((item) => item.Name);
      analytics.giftFinderInteraction({
        name: i18next.t(TK.GIFT_FINDER_REASON_LABEL),
        value: occasionValue,
      });
    }

    if (priceFilter?.length) {
      dispatch(setGiftFinderFilters(priceFilter));
      const priceValue: string[] = priceFilter.map((item) => item.Name);
      analytics.giftFinderInteraction({
        name: i18next.t(TK.GIFT_FINDER_COST_LABEL),
        value: priceValue,
      });
    }
    if (speedFilter?.length) {
      dispatch(setGiftFinderFilters(speedFilter));
      const speedValue: string[] = speedFilter.map((item) => item.Name);
      analytics.giftFinderInteraction({
        name: i18next.t(TK.GIFT_FINDER_SPEED_LABEL),
        value: speedValue,
      });
    }
    navigate(Links.FruitArrangements);
  };

  if (isError) {
    return null;
  }

  const getGridItemSize = (selectorType: SelectorType) => {
    return expandedSelector === null || expandedSelector === selectorType
      ? {
          maxWidth: { md: '32%', sm: '32%', xs: '100%' },
          xs: 12,
          sm: 4,
          md: 4,
        }
      : {
          maxWidth: { md: '49%', sm: '49%', xs: '100%' },
          xs: 12,
          sm: 6,
          md: 6,
        };
  };

  if (
    occasionCategories.length < 1 &&
    priceCategories.length < 1 &&
    speedCategories.length < 1 &&
    isInitFruitFiltersBlocked
  ) {
    return (
      <>
        <Grid item sx={styles.giftFinderskeleton}>
          <Skeleton sx={styles.skeleton} />
          <Skeleton sx={styles.skeleton} />
          <Skeleton sx={styles.skeleton} />
        </Grid>
      </>
    );
  }

  return (
    <>
      {occasionCategories.length >= 1 &&
        priceCategories.length >= 1 &&
        speedCategories.length >= 1 && (
          <Box sx={styles.giftFinderWrapper}>
            <Box sx={styles.giftFinderInline}>
              <Box mb={8}>
                <Typography sx={styles.giftFinderTitle}>
                  {i18next.t(TK.GIFT_FINDER)}
                </Typography>
                <Typography sx={styles.giftFinderInlneText}>
                  {i18next.t(TK.GIFT_FINDER_TEXT_ONE)}
                </Typography>
              </Box>

              {expandedSelector === SelectorType.SPEED && (
                <ExpandedSpeedSelector
                  setFilterData={(filterData) => {
                    setSpeedFilter(filterData);
                  }}
                  selectedFilterData={speedFilter ?? []}
                  category={speedCategories}
                />
              )}
              {expandedSelector === SelectorType.REASON && (
                <ExpandedReasonSelector
                  setFilterData={(filterData) => {
                    setOccasionFilter(filterData);
                  }}
                  selectedFilterData={occasionFilter ?? []}
                  category={occasionCategories}
                />
              )}
              {expandedSelector === SelectorType.COST && (
                <ExpandedCostSelector
                  setFilterData={(filterData) => {
                    setPriceFilter(filterData);
                  }}
                  selectedFilterData={priceFilter ?? []}
                  category={priceCategories}
                  selectedCostRange={selectedCostRange}
                  setSelectedCostRange={setSelectedCostRange}
                />
              )}

              <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <>
                  {errorValidationCheck && (
                    <Grid item sx={styles.itemContainer}>
                      <Alert id="errorWrapper" severity="error">
                        {i18next.t(TK.GIFT_FINDER_VALIDATION_ERROR)}
                      </Alert>
                    </Grid>
                  )}
                  <>
                    <Grid container sx={styles.giftFinderDropdownSec}>
                      {expandedSelector !== SelectorType.SPEED && (
                        <Grid item {...getGridItemSize(SelectorType.SPEED)}>
                          <GiftFinderSelectorWrapper
                            onClick={() =>
                              setExpandedSelector(SelectorType.SPEED)
                            }
                            isSelected={
                              !!speedFilter && speedFilter?.length > 0
                            }
                            label="Speed"
                            subHeading="I need this fast!"
                            category={speedCategories}
                            imageSrc={speedCategories[0].FilterCategoryImageURL}
                          />
                        </Grid>
                      )}
                      {expandedSelector !== SelectorType.COST && (
                        <Grid item {...getGridItemSize(SelectorType.COST)}>
                          <GiftFinderSelectorWrapper
                            onClick={() =>
                              setExpandedSelector(SelectorType.COST)
                            }
                            isSelected={
                              !!priceFilter && priceFilter?.length > 0
                            }
                            label="Cost"
                            subHeading={"I'm on a budget."}
                            category={priceCategories}
                            imageSrc={priceCategories[0].FilterCategoryImageURL}
                          />
                        </Grid>
                      )}

                      {expandedSelector !== SelectorType.REASON && (
                        <Grid item {...getGridItemSize(SelectorType.REASON)}>
                          <GiftFinderSelectorWrapper
                            onClick={() =>
                              setExpandedSelector(SelectorType.REASON)
                            }
                            isSelected={
                              !!occasionFilter && occasionFilter?.length > 0
                            }
                            label="Reason"
                            subHeading="For someone or an event."
                            category={occasionCategories}
                            imageSrc={
                              occasionCategories[0].FilterCategoryImageURL
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                </>
                <Grid item xs={11} md={4} sx={styles.findGiftBtn}>
                  <Button
                    data-test="gift-finder-submit"
                    type="submit"
                    disabled={findButtonDisable}
                  >
                    {i18next.t(TK.BUTTON_NAME)}
                  </Button>
                </Grid>
              </StyledForm>
            </Box>
          </Box>
        )}
    </>
  );
};
