import * as TK from 'components/OrderConfirmation/translations/locale.constants';
import * as styles from 'components/OrderConfirmation/PaymentInfo/styles';
import { Box, Typography } from '@mui/material';
import { selectSaleData } from 'redux/order-confirmation/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const Billing: FC = () => {
  const saleData = useSelector(selectSaleData);
  return (
    <>
      {saleData && (
        <Box
          display="contents"
          flexDirection="column"
          width={1}
          maxWidth={1060}
          my={4}
          mx="auto"
          px={[2.5, 5, 0]}
        >
          <Box sx={styles.billStyle} mt={[1.5, 0, 0]}>
            <Typography sx={styles.payMethod}>
              {i18next.t(TK.BILLING_INFO_TITLE)}
            </Typography>
            <Box sx={styles.billWrapper}>
              <Typography sx={styles.billInfo}>
                {saleData.customerName}
              </Typography>
              <Typography sx={styles.billInfo}>{saleData.address1}</Typography>
              <Typography sx={styles.billInfo}>
                {saleData.city}
                {saleData.city !== '' ? ',' : ''} {saleData.stateAbbreviation}{' '}
                {saleData.area}
              </Typography>
              <Typography sx={styles.billInfo}>
                {saleData.workPhone || saleData.homePhone || saleData.cellPhone}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
