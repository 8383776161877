/**
 * @notice duplicated from library
 * @microsoft/applicationinsights-common/src/Interfaces/Contracts/Generated/SeverityLevel.ts
 * to avoid usage of loaders
 * */
export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
