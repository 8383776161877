import * as TK from 'translations/locales/translation-keys.constant';
import { Add, Remove } from '@mui/icons-material';
import {
  FAQQuestion,
  FAQsTitle,
  ProductAccordionDetails,
  ProductAccordionItem,
  ProductAccordionSummary,
} from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { FaqSkeleton } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/FaqSkeleton';
import { Faq as ProductFaq, faqApiService } from 'api/faq-api.service';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RootState } from 'redux/store';
import { answer } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/styles';
import { checkIsBlocked as checkIsArrangementBlocked } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const Faq: FC = () => {
  const [faqs, setFaqs] = useState<ProductFaq[]>([]);
  const isArrangementBlocked = useSelector(checkIsArrangementBlocked);
  const arrangementId = useSelector(
    ({ arrangement: { data } }: RootState) => data.id,
  );

  useEffect(() => {
    const fetchFaqs = async () => {
      const res = await faqApiService.getFaq(arrangementId);
      setFaqs(res);
    };
    if (arrangementId && !faqs.length) {
      fetchFaqs();
    }
  }, [arrangementId]);

  const [expanded, setExpanded] = useState<string | false>(false);
  const toggleAccordion = (panel: string) =>
    setExpanded(expanded === panel ? false : panel);

  return (
    <div>
      <FAQsTitle variant="h6">{i18next.t(TK.PRODUCT_FAQ)}</FAQsTitle>
      <div>
        {isArrangementBlocked || !faqs ? (
          <FaqSkeleton />
        ) : (
          faqs.map((item, index) => (
            <ProductAccordionItem
              key={item.question}
              sx={{ width: 1 }}
              expanded={expanded === `question_${index}`}
              onClick={() => toggleAccordion(`question_${index}`)}
            >
              <ProductAccordionSummary
                expandIcon={
                  expanded === `question_${index}` ? (
                    <Remove color="error" />
                  ) : (
                    <Add />
                  )
                }
                aria-controls={`question_${index}_content`}
                id={`question_${index}_header`}
                data-test={`pdp-faq-item-${index + 1}-summary`}
              >
                <FAQQuestion
                  variant="subtitle2"
                  data-test={`pdp-faq-item-${index + 1}-question`}
                >
                  {item.question}
                </FAQQuestion>
              </ProductAccordionSummary>
              <ProductAccordionDetails>
                <PureHtmlTypography
                  data-test={`pdp-faq-item-${index + 1}-answer`}
                  component="div"
                  sx={answer}
                  variant="body2"
                >
                  {item.answer}
                </PureHtmlTypography>
              </ProductAccordionDetails>
            </ProductAccordionItem>
          ))
        )}
      </div>
    </div>
  );
};
