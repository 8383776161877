import * as TK from 'components/PDP/Order/RecipientForm/components/DeliveryPickupInstructions/translations/translation-keys.constant';

export const instructionsCommonLocale = {
  [TK.DELIVERY_INSTRUCTIONS]: 'Delivery Instructions',
  [TK.READ_OUR_DELIVERY_POLICIES]: 'Read our Delivery Policies',
  [TK.DELIVERY_INSTRUCTIONS_LABEL]:
    'Provide any details that can help us deliver your treats to the right place. Include crossroads, landmarks, info for gated communities, etc.',
  [TK.DELIVERY_INSTRUCTIONS_PLACEHOLDER]: 'Delivery Instructions',
  [TK.PICKUP_INSTRUCTIONS]: 'Pickup Instructions',
  [TK.PICKUP_INSTRUCTIONS_PLACEHOLDER]:
    'Provide any details that can help make your pickup as convenient as possible. Include pickup timeframe, accessibility requirements, etc.',
};
