import { ApiBaseService } from 'api/apibase.service';
import { OrderType } from 'types/cart.types';
import { formatSQLDate } from 'utils/get-sql-date-format';

export type RecipientAvailability = {
  isAvailable?: boolean;
  recipientId?: number;
};

class RecipientAvailabilityApiService extends ApiBaseService {
  fetch({
    ids,
    storeId,
    areaId,
    orderType,
    fulfillmentDate,
  }: {
    ids: number[];
    storeId: number;
    areaId: number;
    orderType: OrderType;
    fulfillmentDate: Date;
  }): Promise<RecipientAvailability[]> {
    const todaysDate = new Date();
    const futureFulfillmentDate = fulfillmentDate;

    if (futureFulfillmentDate < todaysDate) {
      futureFulfillmentDate.setDate(futureFulfillmentDate.getDate() + 1);
    }

    return this.apibase
      .get<RecipientAvailability[]>('availability/products', {
        params: {
          ids,
          storeId,
          areaId,
          orderType,
          date: formatSQLDate(futureFulfillmentDate),
        },
      })
      .then(({ data }) => data);
  }
}

export const recipientAvailabilityApiService =
  new RecipientAvailabilityApiService();
