import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { StoreData } from 'components/LocalSeoPage/State/types';
import { StoresByState } from 'components/LocalSeoPage/State/feature/slice';

export const getContent = (state: RootState): StoresByState =>
  state.storesByState;

export const getStatus = (state: RootState): Status => getContent(state).status;

export const isBlocked = (state: RootState): boolean =>
  getContent(state).status === 0 || getContent(state).status === 1;

export const getData = (state: RootState): StoreData[] =>
  getContent(state).data.storeData;

export const getLength = (state: RootState): number =>
  getContent(state).data.storeData.length || 0;

export const getCities = (state: RootState): string[] => {
  const cities = getContent(state).data.storeData.map((store) => {
    const modifiedAddress = store.Address && store.Address.split('<br>');
    const city = modifiedAddress && modifiedAddress[1].split(',');
    return city[0] && city[0].trimStart();
  });

  return [...new Set(cities)];
};

export const selectIsError = (state: RootState): boolean =>
  getContent(state).error?.status === 500 ||
  getContent(state).error?.status === 404;
