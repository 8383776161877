/* eslint-disable no-console */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as styles from 'components/PDP/Details/components/PasControl/components/CollectionPrompt/styles';
import { Area } from 'api/area/types/area.interface';
import { ArrangementItem } from 'components/common/ArrangementItem/ArrangementItem';
import { ArrangementPreview } from 'api/arrangements/types';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { CarouselContainer } from 'components/Cart/Details/components/AddOnUpgradeCarousel/styles';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ValidOrderTypes } from 'types/cart.types';
import { analytics } from 'service/adobe-analytics/analytics';
import { fetchArrangements } from 'components/PLP/ArrangementsList/feature/actions';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { navigate } from 'utils/navigate';
import { selectArrangements } from 'components/PLP/ArrangementsList/feature/selectors';
import { selectCatalogNumber } from 'redux/PDP/arrangement/arrangement.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useIsDownSm } from 'utils/hidden/use-is-down-sm';
import React, { FC, useEffect } from 'react';
import Slider from 'react-slick';

interface Props {
  orderType: ValidOrderTypes;
  date: Date | null;
  area: Area | null;
  product: Product | null;
}

export const CollectionPrompt: FC<Props> = ({
  orderType,
  date,
  area,
  product,
}) => {
  const dispatch = useDispatch();
  const arrangements = useSelector(selectArrangements);
  const catalogNumber = useSelector(selectCatalogNumber);
  const isMobile = useIsDownSm();

  useEffect(() => {
    if (!arrangements.length) {
      dispatch(
        fetchArrangements({
          filterIds: [window.PDP_COLLECTION_PROMPT_FILTER_ID],
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (date && area) {
      analytics.PdpAvailableModalLoad(orderType, date, area);
    }
  }, []);

  const filteredArrangements = arrangements.filter((a) => a.plpItemType === 1);

  const settings = {
    slidesToShow: 3.5,
    slidesToScroll: 2,
    focusOnSelect: true,
    variableWidth: true,
    initialSlide: 0,
    infinite: true,
    arrows: true,
    swipe: true,
    nextArrow: (
      <Box>
        <ArrowForwardIos fontSize="medium" />
      </Box>
    ),
    prevArrow: (
      <Box>
        <ArrowBackIosNew fontSize="medium" />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 850, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 4.1,
        },
      },
      {
        breakpoint: 430, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 2.5,
        },
      },
    ],
  };

  const widgetTitle = (
    <Box>
      <Typography sx={styles.subTitle} variant="subtitle2">
        Want it in time for Mother&apos;s Day?
      </Typography>
      <Typography sx={styles.bodyText} variant="body3">
        Shop available products and make your Mother&apos;s Day extra special.
      </Typography>
    </Box>
  );

  const handleShopAvailableProductsClick = () => {
    if (date && area && product) {
      const productSKU = `${catalogNumber}-${product.name}`;
      analytics.ShopAvailableProductsClickEvent(
        orderType,
        date,
        area,
        productSKU,
      );
    }
    navigate('/mothers-day-gifts');
  };

  const handleProductClick = (
    productPosition: number,
    arrangement: ArrangementPreview,
  ) => {
    if (date && area && product) {
      const productSKU = `${catalogNumber}-${product.name}`;
      analytics.ProductClickEvent(
        orderType,
        date,
        area,
        productSKU,
        productPosition,
        arrangement,
      );
    }
  };

  const shopAvailableProducts = (fWidth: boolean) => (
    <Button
      fullWidth={fWidth}
      variant="outlined"
      onClick={handleShopAvailableProductsClick}
      data-test="pdp-recipient-add-to-cart-button"
      sx={styles.shopButton}
    >
      Shop available products
    </Button>
  );

  return (
    <>
      <Box display="flex" sx={styles.boxStyle}>
        {widgetTitle}
        {!isMobile && shopAvailableProducts(false)}
      </Box>
      <Box sx={styles.sliderContainer}>
        <CarouselContainer data-test="carousel-Addon">
          <Slider {...settings}>
            {filteredArrangements &&
              filteredArrangements.map((arrangement, index) => (
                <Box
                  key={arrangement.id}
                  data-test={`pdp-pas-suggested-product-item-${index + 1}`}
                  sx={{ width: '200px' }}
                >
                  <a
                    href={getPdpPath(arrangement.url)}
                    onClick={() => handleProductClick(index + 1, arrangement)}
                    data-test={
                      arrangement.newPDP
                        ? 'suggested-product'
                        : 'legacy-suggested-product'
                    }
                  >
                    <ArrangementItem arrangement={arrangement} />
                  </a>
                </Box>
              ))}
          </Slider>
        </CarouselContainer>
        <Box display={['flex', 'none', 'none']}>
          {shopAvailableProducts(true)}
        </Box>
      </Box>
    </>
  );
};
