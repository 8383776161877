import * as styles from 'components/PDP/Order/RecipientForm/components/FullName/styles';
import { Box, TextField } from '@mui/material';
import { CommonData } from 'types/forms/common-data-new';
import {
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
} from 'components/PDP/Order/RecipientForm/translations/translation-keys.constant';
import { UseFormRegister, UseFormTrigger } from 'react-hook-form';
import { colors } from 'utils/theme/colors';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  firstName: string;
  lastName: string;
  register: UseFormRegister<Record<string, string>>;
  disabled?: boolean;
  firstNameError?: string;
  lastNameError?: string;
  trigger?: UseFormTrigger<CommonData>;
}

export const FullName: FC<Props> = ({
  firstName,
  lastName,
  register,
  disabled = false,
  firstNameError,
  lastNameError,
  trigger,
}) => (
  <Box maxWidth={['auto', 'auto', 610]} sx={styles.wrapper}>
    <TextField
      {...register('firstName')}
      label={i18next.t(FIRST_NAME_PLACEHOLDER)}
      disabled={disabled}
      onBlur={() => trigger && trigger('firstName')}
      inputProps={{
        'data-test': 'pdp-recipient-form-first-name',
      }}
      InputLabelProps={{
        style: {
          color: firstNameError ? colors.errorLight : colors.placeholderText,
        },
      }}
      sx={{
        mb: [5, 2, 2],
        width: 1,
        mr: [0, 1, 1],
      }}
      value={firstName}
      error={Boolean(firstNameError)}
      helperText={
        <span data-test="pdp-recipient-form-first-name-error">
          {firstNameError}
        </span>
      }
    />

    <TextField
      {...register('lastName', { required: true })}
      label={i18next.t(LAST_NAME_PLACEHOLDER)}
      disabled={disabled}
      value={lastName}
      onBlur={() => trigger && trigger('lastName')}
      inputProps={{
        'data-test': 'pdp-recipient-form-last-name',
      }}
      InputLabelProps={{
        style: {
          color: lastNameError ? colors.errorLight : colors.placeholderText,
        },
      }}
      sx={{
        width: 1,
        ml: [0, 1, 1],
      }}
      error={Boolean(lastNameError)}
      helperText={
        <span data-test="pdp-recipient-form-last-name-error">
          {lastNameError}
        </span>
      }
    />
  </Box>
);
