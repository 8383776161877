import { BreadcrumbBack } from 'components/BreadcrumbBack/BreadcrumbBack';
import { NavigateBack } from 'components/PDP/GoBack/NavigateBack';
import { Step, isDetails } from 'containers/PDPContainer/enums/step.enum';
import { appInsights } from 'utils/telemetry/app-insights';
import { checkRealUpsellsExists } from 'components/PDP/Upsells/feature/selectors';

import { useSelector } from 'react-redux';
import React, { FC } from 'react';

type Props = {
  step: Step;
  setStep: (step: Step) => void;
};

const getTitle = (step: Step) =>
  ({
    [Step.FORM]: 'form',
    [Step.UPSELLS]: 'upsells',
    [Step.DETAILS]: 'details',
  }[step]);

export const Back: FC<Props> = ({ setStep, step }) => {
  const isRealUpsellsExist = useSelector(checkRealUpsellsExists);

  if (isDetails(step)) {
    return <NavigateBack />;
  }

  const changeStep = () => {
    const newStep =
      step === Step.FORM && isRealUpsellsExist ? Step.UPSELLS : Step.DETAILS;
    appInsights.trackEvent({
      name: 'PDP navigation back clicked',
      properties: {
        current: getTitle(step),
        next: getTitle(newStep),
      },
    });
    setStep(newStep);
  };

  return <BreadcrumbBack sx={{ mt: 2.5 }} onClick={changeStep} />;
};
