import * as TK from 'components/StoreFinder/translations/locale.constants';

export const storeFinderCommonLocale = {
  [TK.STORES_NEAR_YOU]: '{{amountOfStores}} Stores Near You',
  [TK.STORE_NEAR_YOU]: '{{amountOfStores}} Store Near You',
  [TK.LOAD_MORE_STORES_BUTTON_TEXT]: 'Load More',
  [TK.PRIVACY_POLICY]: 'Privacy Policy',
  [TK.TERMS_OF_USE]: 'Terms of Use',
  [TK.CONCERNED_ABOUT_PRIVACY]:
    'Concerned about privacy? View our policies below.',
  [TK.LOCATION_SERVICES_OFF]: 'It looks like location services are turned off.',
  [TK.TURN_ON_LOCATION_SERVICES]:
    'To find an Edible store near you, use the search feature or the interactive map above. Or, turn on location services on your device.',
  [TK.STORE_SHOWING_AMOUNT_DISPLAY_TEXT]:
    'Showing {{amountOfStoresCurrentlyDisplayed}} of {{amountOfStoresTotal}}, Load 8 More',
  [TK.SMOOTHIE]: 'Smoothie',
  [TK.CURBSIDE]: 'Curbside',
  [TK.KOSHER]: 'Kosher',
  [TK.APPLY]: 'Apply',
  [TK.CLEAR_ALL_FILTERS]: 'Clear All Filters',
  [TK.ERROR_MESSAGE_OPEN_YOUR_OWN_STORE]: 'Open Your Own Store',
  [TK.ERROR_MESSAGE_WE_SHIP_TEXT]:
    'We’re sorry, we couldn’t find any stores nearby. But, we may be able to ship some of our gourmet treats to you or your loved one!',
  [TK.ERROR_MESSAGE_OH_NO]: 'Oh no!',
  [TK.ERROR_MESSAGE_DIFFERENT_SEARCH]:
    'Try a different city, state or zip to view results',
  [TK.ERROR_MESSAGE_DIFFERENT_SEARCH_AND_FILTER]:
    'Try a different city, state or zip, or change your filters to view results.',
  [TK.ERROR_MESSAGE_SEARCH_AND_FILTER_CRITERIA]:
    'We’re sorry, we couldn’t find any stores that match your criteria.',
  [TK.ERROR_MESSAGE_OPEN_STORE_TEXT]:
    'Want to be the first to open an Edible Arrangements store near you? You can own your own franchise location to deliver tasty treats to your neighborhood.',
  [TK.ERROR_MESSAGE_NEW_STORES_TEXT]:
    'New locations open all the time, so check back soon!',
  [TK.DISTANCE_FILTER_OPTION]: 'Within {{distance}} {{distanceMetric}}',
  [TK.DISTANCE_TEXT]: 'Distance',
  [TK.SERVICES_OFFERED_TEXT]: 'Services Offered',
  [TK.FILTERS_TEXT]: 'Filters',
  [TK.FIND_A_STORE]: 'Find a Store',
  [TK.MOBILE_FILTER_TEXT]: 'Filter Results',
  [TK.CUSTOM_GREETING_CARDS]: 'Custom Greeting Cards',
  [TK.STORE_FINDER_HOME]: 'Home',
  [TK.STORE_FINDER_TITLE]: 'Edible Store Locator',
  [TK.STORE_FINDER_DESCRIPTION]:
    'Visit one of over 900 locations worldwide for a free sample,exclusive offers & in-store specials!',
  [TK.STORE_FINDER_PLACEHOLDER]: 'Enter your City and State or Zip',
  [TK.STORE_FINDER_LOCATION]: 'Location',
  [TK.STORE_FINDER_CHOOSE_FROM_LIST]: 'Choose from the list below',
  [TK.STORE_FINDER_VALIDATION_ERROR]: 'Enter a valid city and state or zip',
  [TK.STORE_FINDER_BUTTON]: 'Find a Store',
  [TK.STORE_FINDER_BUTTON_LOADING_TEXT]: 'Retrieving Store(s)…',
  [TK.INPUT_ADORNMENT]: 'Enter City, State or Zip',
  [TK.STORE_FINDER_FRANCHISE_HEAD]: 'Live the Sweet Life!',
  [TK.STORE_FINDER_FRANCHISE_SUBTITLE]: 'Own a Franchise',
  [TK.STORE_FINDER_FRANCHISE_DESCRIPTION]:
    'Learn about opening your own Edible storefront and join our passionate community of franchise owners across the globe.',
  [TK.LEARN_MORE_STORES]: 'Learn More',
  [TK.LOAD_MORE_STORES_ARIA_TEXT]: 'Load More Stores',
  [TK.OPEN_FRANCHISE_LEARN_MORE_BUTTON]:
    'Open Your Own Franchise Learn More Button',
  [TK.OPEN_FRANCHISE_BANNER]: 'Open Your Own Franchise Banner',
  [TK.INVALID_SEARCH_TEXT]:
    'Enter a valid city and state or zip in the field above to see results',
  [TK.COMING_SOON_STORE_TEXT]: 'New Stores Coming Soon!',
  [TK.CALL_EDIBLE_STORE]: 'Call this Edible Store',
  [TK.CONTACT_US]: 'Contact Us',
  [TK.EMAIL_EDIBLE_STORE]: 'Email this Edible Store',
  [TK.GET_DIRECTIONS]: 'Get Edible Store Directions',
  [TK.STORE_FINDER_PAGE_META_TAG_TITLE]:
    'Edible Arrangements Locations & Stores Near You',
  [TK.STORE_FINDER_PAGE_META_TAG_DESCRIPTION]:
    'Find an Edible Arrangements location near you. Your one stop of gift delivery, pick-up, in-store exclusive offers and more. Explore the stores near you here!',
};
