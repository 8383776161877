import { Box } from '@mui/material';
import React, { memo } from 'react';

interface Props {
  productId: number;
}

/**
 * @see {@link https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Display/display_integration.html#reviews}
 */
export const Reviews = memo<Props>(({ productId }) => (
  <Box
    px={[2.5, 0]}
    data-bv-product-id={productId}
    data-bv-show="reviews"
    data-test="pdp-details-bazaarvoice-reviews"
  />
));
