import {
  BulkHours,
  SocialMedia,
  StoreDetails,
  StoreLocation,
} from 'interfaces/store-details-page.interfaces';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { StoreOccasionHours } from 'interfaces/store-occasion-hours-and-availabilities.interface';

export const storeDetails = (state: RootState): StoreDetails | undefined =>
  state.storeDetails.data.storeDetails;

export const storeNumber = (state: RootState): number | undefined =>
  state.storeDetails.data.storeDetails?.number;

export const storeServicedArea = (state: RootState): string[] | undefined =>
  state.storeDetails.data.deliveryAreasServiced;

export const storeHours = (
  state: RootState,
): { weekday: string; hours: string }[] | undefined =>
  state.storeDetails.data.storeSchedule;

export const storeLocation = (state: RootState): StoreLocation | undefined =>
  state.storeDetails.data.storeLocation;

export const bulkHours = (state: RootState): BulkHours[] | undefined =>
  state.storeDetails.data.bulkHours;

export const selectStoreRanking = (state: RootState): number =>
  state.storeDetails.storeRanking;

export const occasionHours = (
  state: RootState,
): StoreOccasionHours[] | undefined => state.storeDetails.data.occasionHours;

export const activeSocialMediaLinks = (state: RootState): SocialMedia[] => {
  const links = state.storeDetails.data.socialMedia?.filter(
    (media) => media.storeAddress !== null && !media.inactive,
  );

  if (!links) return [];

  return links;
};

export const hasSocialLinks = (state: RootState): boolean => {
  const links = state.storeDetails.data.socialMedia?.filter(
    (media) => media.storeAddress !== null && !media.inactive,
  );

  return !!links;
};

export const facebook = (state: RootState): string => {
  const media = state.storeDetails.data.socialMedia?.find(
    (link) => link.name === 'Facebook' && !link.inactive,
  );

  return media?.storeAddress || '';
};

export const instagram = (state: RootState): string => {
  const media = state.storeDetails.data.socialMedia?.find(
    (link) => link.name === 'Instagram' && !link.inactive,
  );

  return media?.storeAddress || '';
};

export const twitter = (state: RootState): string => {
  const media = state.storeDetails.data.socialMedia?.find(
    (link) => link.name === 'Twitter' && !link.inactive,
  );

  return media?.storeAddress || '';
};

export const pinterest = (state: RootState): string => {
  const media = state.storeDetails.data.socialMedia?.find(
    (link) => link.name === 'Pinterest' && !link.inactive,
  );

  return media?.storeAddress || '';
};

export const youtube = (state: RootState): string => {
  const media = state.storeDetails.data.socialMedia?.find(
    (link) => link.name === 'Youtube' && !link.inactive,
  );

  return media?.storeAddress || '';
};

export const storeDataPending = (state: RootState): boolean =>
  state.storeDetails.status === Status.INIT ||
  state.storeDetails.status === Status.PENDING;
