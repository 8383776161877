import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { checkAvailability } from 'redux/PDP/availability/check-availability/check-availability.actions';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';

const initialState = InitialStateFactory.createWithoutData();

const checkSlice = createSlice({
  name: 'check',
  initialState,
  reducers: {
    setAvailabilityCheckInitialState: () => initialState,
  },
  extraReducers: createExtraReducers(initialState, checkAvailability),
});

export const {
  reducer: checkAvailabilityReducer,
  actions: checkAvailabilityActions,
} = checkSlice;

export const { setAvailabilityCheckInitialState } = checkSlice.actions;
