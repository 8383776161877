import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeLegacyApiService } from 'api/store.legacy.api.service';

export const getStates = createAsyncThunk(
  'maintenance/get-states',
  async () => {
    const { data } = await storeLegacyApiService.getStateStores();
    const document = new DOMParser()
      .parseFromString(data, 'application/xml')
      .getElementsByTagName('ST');

    return [...document].map((node) => {
      const [state] = node.getElementsByTagName('S');
      const [count] = node.getElementsByTagName('C');
      return {
        state: state.textContent || '',
        count: Number(count.textContent),
      };
    });
  },
);
