import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/array/size';
import { isFulfilled, isPending } from 'utils/status.comparer';

export const selectDateAvailabilityState = (state: RootState) =>
  state.dateAvailability;

export const selectDateAvailabilityData = (state: RootState) =>
  state.dateAvailability.data;

export const selectIsDateAvailabilityFulfilled = createSelector(
  selectDateAvailabilityState,
  ({ status }) => isFulfilled(status),
);

export const selectIsPending = createSelector(
  selectDateAvailabilityState,
  ({ status }) => isPending(status),
);

export const selectIsNotAvailable = createSelector(
  selectDateAvailabilityData,
  ({ pickup, delivery, shipment }) =>
    [delivery, pickup, shipment].every(
      (type) =>
        !type.available && isEmpty(type.dates) && !type.arrangementsAvailable,
    ),
);

export const selectIsNotAvailableAndFulfilled = createSelector(
  selectIsNotAvailable,
  selectIsDateAvailabilityFulfilled,
  (isNotAvailable, isDateFulfilled) => isNotAvailable && isDateFulfilled,
);
