import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';

interface UpsellOptionBase {
  addonId: number;
  description: string;
  id: number;
  image: string;
  imageAltText: string;
  name: string;
  price: number;
  productId: number;
  sequence: number;
  uid: string;
}

export type AddonOption = UpsellOptionBase;

export interface UpgradeOption extends UpsellOptionBase {
  upgradeId: number;
}

export type UpsellOption = AddonOption | UpgradeOption | OwnBoxAddon;

export enum SelectionType {
  NotSpecified = 0,
  CheckBox = 1,
  Radio = 2,
}

export type SelectionTypes = typeof SelectionType[keyof typeof SelectionType];

export const productTypeMap = (step: number): string | undefined =>
  ({
    0: 'NotSpecified',
    1: 'Arrangement',
    2: 'Package',
    3: 'Upgrade',
    4: 'AddOn',
    5: 'Container',
  }[step]);

export enum ProductType {
  NotSpecified = 0,
  Arrangement = 1,
  Package = 2,
  Upgrade = 3,
  AddOn = 4,
  Container = 5,
}
export enum DessertBoardType {
  BakedGoods = 0,
  DippedBerries = 1,
  DippedFruits = 2,
}

export enum ProductTypeName {
  NotSpecified = 'NotSpecified',
  Arrangement = 'Arrangement',
  Package = 'Package',
  Upgrade = 'Upgrade',
  AddOn = 'AddOn',
  Container = 'Container',
}

export type ProductTypes = typeof ProductType[keyof typeof ProductType];

export interface Upsell {
  id: number;
  productId: number;
  name: string;
  productType: ProductType;
  description: string;
  selectionType: SelectionTypes;
  image: string;
  uid: string;
  sequence: number;
  productGroupId: number;
  productGroupName: string;
  imageAltText: string;
  personalizable: false;
  showQuantityField: boolean;
  altText: string;
  options: UpsellOption[];
}
