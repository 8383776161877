import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/common/StoreWidget/styles';
import { AltRoute, Phone } from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DirectionsAndPhoneNumber,
  SequenceAndDistance,
  SpecialStoreHoursAndTags,
  StoreHours,
  StoreInfo,
  StoreInfoAndSpecialStoreHoursAndTagIconWrapper,
  StoreWidgetWrapper,
  TagIcon,
  TagRow,
} from 'components/common/StoreWidget/styles';
import { Locale } from 'enums/locale';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { SpecialHours } from 'components/common/StoreWidget/components/SpecialHours';
import {
  StoreAvailability,
  StoreOccasionHours,
} from 'interfaces/store-occasion-hours-and-availabilities.interface';
import { getDirectionsUrl } from 'utils/get-directions-url';
import { isCanada } from 'utils/country';
import { setOpeningAndClosingTimes } from 'components/common/StoreWidget/utils';
import { useScrollToTopOnMount } from 'hooks/scroll/use-scroll-to-top-on-mount';
import React, { FC } from 'react';
import i18next from 'i18next';

interface StoreWidgetProps {
  storeName: string;
  storeAddress: string;
  storeHours: {
    Days?: string;
    Timing?: string;
    days?: string;
    timing?: string;
  }[];
  storePhoneNumber: string;
  pageFriendlyUrl: string;
  locale: string;
  isSmoothieStore: boolean;
  isCurbsidePickupAvailable?: boolean;
  isKosherCertified?: boolean;
  distance?: number;
  sequence?: string;
  storeBulkHours?: StoreAvailability;
  occasionHours?: StoreOccasionHours;
  directionsMapAddress?: string;
  storeClickedIsHighlighted?: boolean;
}

export const StoreWidget: FC<StoreWidgetProps> = ({
  storeName,
  storeAddress,
  storeHours,
  storePhoneNumber,
  pageFriendlyUrl,
  sequence,
  locale,
  isSmoothieStore,
  isCurbsidePickupAvailable,
  isKosherCertified,
  distance,
  storeBulkHours,
  occasionHours,
  directionsMapAddress,
  storeClickedIsHighlighted,
}) => {
  const {
    openingTime: bulkHoursOpeningTime,
    closingTime: bulkHoursClosingTime,
  } = setOpeningAndClosingTimes(storeBulkHours);

  const { openingTime: occasionOpeningTime, closingTime: occasionClosingTime } =
    setOpeningAndClosingTimes(occasionHours);

  const occasionHoursTitle =
    !!occasionOpeningTime &&
    !!occasionClosingTime &&
    occasionHours?.textShownOnWebsite
      ? occasionHours.textShownOnWebsite
      : null;

  const displaySpecialStoreHours =
    (!!storeBulkHours && !!bulkHoursOpeningTime && !!bulkHoursClosingTime) ||
    (!!occasionHours && !!occasionOpeningTime && !!occasionClosingTime);
  useScrollToTopOnMount();
  const modifiedAddress = storeAddress.split('<br>');

  const createTagIcon = (tagKey: string) => (
    <TagIcon key={tagKey}>
      <Typography variant="body3">{i18next.t(tagKey)}</Typography>
    </TagIcon>
  );

  const getTagIcons = (): JSX.Element | null => {
    const tagIconProps = [
      { key: TK.SMOOTHIE, condition: isSmoothieStore },
      { key: TK.CURBSIDE, condition: isCurbsidePickupAvailable },
      { key: TK.KOSHER, condition: isKosherCertified },
      // Add more tag props as needed
    ];

    const filteredTagIconProps = tagIconProps.filter(
      (tagProp) => tagProp.condition,
    );

    return filteredTagIconProps.length > 0 ? (
      <TagRow>
        {filteredTagIconProps.map((tagProp) => createTagIcon(tagProp.key))}
      </TagRow>
    ) : null;
  };
  const storeDistance = i18next.t(TK.DISTANCE_METRIC, {
    distance,
    distanceMetric:
      isCanada || locale === Locale.FR_CA || locale === Locale.EN_CA
        ? i18next.t(TK.KILOMETERS_TEXT)
        : 'Miles',
  });

  const directionsUrl = directionsMapAddress
    ? getDirectionsUrl(directionsMapAddress)
    : '';

  const switchToMobileView = useMediaQuery(useTheme().breakpoints.down(520));

  const DirectionsAndPhoneNumberContent = (
    <>
      <DirectionsAndPhoneNumber>
        {!!directionsMapAddress && !!directionsUrl && (
          <Button
            aria-label={i18next.t(TK.GET_DIRECTIONS)}
            sx={styles.getDirectionsButton}
            component="a"
            target="_blank"
            href={directionsUrl}
          >
            <AltRoute />
            <Typography>{i18next.t(TK.DIRECTIONS)}</Typography>
          </Button>
        )}
        <Button
          sx={styles.storePhoneNumber}
          aria-label={i18next.t(TK.CALL_EDIBLE_STORE)}
          component="a"
          href={`tel:${storePhoneNumber}`}
        >
          <Phone />
          <Typography>{storePhoneNumber}</Typography>
        </Button>
      </DirectionsAndPhoneNumber>
    </>
  );

  const SpecialStoreHoursAndTagsContent = (
    <SpecialStoreHoursAndTags>
      {!!displaySpecialStoreHours && (
        <SpecialHours
          bulkHoursClosingTime={bulkHoursClosingTime}
          bulkHoursOpeningTime={bulkHoursOpeningTime}
          occasionClosingTime={occasionClosingTime}
          occasionOpeningTime={occasionOpeningTime}
          occasionTitle={occasionHoursTitle}
          occasionHoursApplicableDate={occasionHours?.applicableDate}
        />
      )}
      {getTagIcons()}
    </SpecialStoreHoursAndTags>
  );

  return (
    <StoreWidgetWrapper
      href={`/stores/${pageFriendlyUrl}`}
      sx={storeClickedIsHighlighted ? styles.highlightedStore : {}}
    >
      <StoreInfoAndSpecialStoreHoursAndTagIconWrapper>
        <StoreInfo>
          {!!distance && !!sequence && (
            <SequenceAndDistance>
              <Box sx={styles.sequence}>
                <Typography>{sequence}</Typography>
              </Box>
              <Box sx={styles.distance}>
                <Typography>{storeDistance}</Typography>
              </Box>
            </SequenceAndDistance>
          )}
          {!!sequence && !distance && (
            <Box sx={{ ...styles.sequence, marginBottom: '12px' }}>
              <Typography>{sequence}</Typography>
            </Box>
          )}
          <PureHtmlTypography variant="subtitle2" sx={{ paddingBottom: '8px' }}>
            {`${storeName}, ${modifiedAddress[0]}`}
          </PureHtmlTypography>
          <PureHtmlTypography variant="body2" sx={{ marginBottom: '8px' }}>
            {modifiedAddress[1]}
          </PureHtmlTypography>
          {storeHours.map((hours) => {
            const days = hours.Days || hours.days || '';
            const timings = hours.Timing || hours.timing || '';
            return (
              <StoreHours key={`${days} - ${timings}`}>
                <PureHtmlTypography variant="body2">
                  {`${days}:`}
                </PureHtmlTypography>
                <PureHtmlTypography variant="body2">
                  {timings}
                </PureHtmlTypography>
              </StoreHours>
            );
          })}
        </StoreInfo>
        {switchToMobileView
          ? SpecialStoreHoursAndTagsContent
          : DirectionsAndPhoneNumberContent}
      </StoreInfoAndSpecialStoreHoursAndTagIconWrapper>
      {switchToMobileView
        ? DirectionsAndPhoneNumberContent
        : SpecialStoreHoursAndTagsContent}
    </StoreWidgetWrapper>
  );
};
