import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';

export const dataTestAvailabilityStepName = {
  [AvailabilityStep.NOT_SELECTED]: '',
  [AvailabilityStep.OWN_BOX_ADDONS]: 'own-box',
  [AvailabilityStep.PRODUCT_OPTIONS]: 'product-options',
  [AvailabilityStep.ZIP_AVAILABILITY]: 'zip',
  [AvailabilityStep.DATE_SELECTION]: 'date',
  [AvailabilityStep.DELIVERY_METHOD_SELECTION]: 'order-type',
  [AvailabilityStep.SUGGESTED_DATE_SELECTION]: 'suggested-date',
  [AvailabilityStep.SUGGESTED_PRODUCT_SELECTION]: 'suggested-product',
  [AvailabilityStep.STORE_LIST_SELECTION]: 'stores',
  [AvailabilityStep.CONTINUE]: 'continue',
  [AvailabilityStep.OWN_DESSERT_BOARD_BAKED]: 'baked-good',
  [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_BERRIES]: 'dipped-berries',
  [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_FRUIT]: 'dipped-fruit',
  [AvailabilityStep.DESSERT_OWN_BOX_ADDONS]: 'dessert-own-box',
  [AvailabilityStep.TITLE]: 'title',
  [AvailabilityStep.OWN_COOKIE_BOX_EVERYDAY]: 'everyday cookie',
  [AvailabilityStep.OWN_COOKIE_BOX_LIMITED]: 'limited cookie',
  [AvailabilityStep.OWN_6_COOKIE_BOX]: '6 cookie box',
  [AvailabilityStep.OWN_12_COOKIE_BOX]: '12 cookie box',
};
