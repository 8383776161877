import {
  ALL_ARRANGEMENTS_FILTER_VALUE,
  arrangementApiService,
} from 'api/arrangements/arrangement.api.service';
import { ArrangementPreview } from 'api/arrangements/types';
import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';
import { FulfillmentOptionType } from 'types/cart.types';
import { SortParams } from 'types/arrangement-service.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import axios from 'axios';

export type Params = {
  filterIds?: (string | number)[];
  orderType?: FulfillmentOptionType;
  search?: string;
  areaId?: number;
  storeId?: number;
  date?: Date;
  sort?: SortParams;
  limit?: number;
  fruitFilterValue?: string;
  isGiftFinder?: boolean;
  isOneHourDelivery?: boolean;
};

export enum AbortReason {
  NewSearch = 'new_search',
}

export const ABORTED = 'aborted';

export const createGetArrangementsAction = (
  typePrefix: string,
): AsyncThunk<ArrangementPreview[], Params, Record<string, never>> =>
  createAsyncThunk(typePrefix, async (params: Params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener('abort', () => {
      source.cancel(ABORTED);
    });

    try {
      const { data } = await arrangementApiService.find(
        {
          ...params,
          filterIds: params.filterIds
            ? params.filterIds.join(',')
            : ALL_ARRANGEMENTS_FILTER_VALUE,
        },
        { cancelToken: source.token },
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        Object.hasOwnProperty.call(e, 'message') &&
          (e as { message: unknown }).message === ABORTED
          ? { message: ABORTED }
          : parseAxiosError(e),
      );
    }
  });

export const fetchArrangements = createGetArrangementsAction(
  'plp/arrangements/fetch',
);
