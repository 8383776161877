import { useTagManager } from 'hooks/google/use-tag-manager';
import React, { FC } from 'react';

export const HeadMetaInfo: FC = () => {
  useTagManager();
  return (
    <>
      <script
        src="https://assets.adobedtm.com/9d1055827072/79fd33338bfe/launch-ENc22ac52da7e343f4ab77ae82343e6023.min.js"
        async
      />{' '}
    </>
  );
};
