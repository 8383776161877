import { SxProps, styled } from '@mui/material';

interface ImageBaseProps {
  'animation-duration': number;
  transition?: 1 | 0;
  'transition-in'?: 1 | 0;
  broken?: 1 | 0;
}

export const ImageBase = styled('img')<ImageBaseProps>((props) => ({
  display: 'block',
  transitionDuration: `${props['animation-duration'].toString()}ms`,

  ...(props.transition && {
    transition: 'opacity cubic-bezier(0.4, 0.0, 0.2, 1) 1000ms',
    opacity: 0,
  }),
  ...(props['transition-in'] && {
    opacity: 1,
  }),
  ...(props.broken && {
    /**
     * @see {@link https://dev.azure.com/Netsolace/Platform/_workitems/edit/8992}
     * @notice !important cannot be omitted since we are using beautiful slick slider
     * with a global style ".slick-slide img { display: block }".
     * If you don't see react-slick and slick-carousel in package.json
     * then remove this ugly !important, but until this day just leave it
     * */
    display: 'none !important',
  }),
}));

export const media = {
  top: 0,
  left: 0,
};

export const root: SxProps = {
  position: 'relative',
};

export const square = {
  aspectRatio: '1/1',
};

export const iconContainer: SxProps = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
};

export const VideoPlayer = styled('video')(() => ({
  width: '100%',
  top: 0,
  left: 0,
}));
