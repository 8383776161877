import { useEffect } from 'react';

const getScript = (): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'cordial-script';
  script.innerHTML = `(function(C,O,R,D,I,A,L){C.CordialObject=I,C[I]=C[I]||function(){(C[I].q=C[I].q||[]).push(arguments)};C[I].l=1*new Date,C[I].q=[],A=O.createElement(R);L=O.getElementsByTagName(R)[0],A.async=1,A.src=D,L.parentNode.insertBefore(A,L);})(window, document, 'script', '//d.p.ediblearrangements.com/track.v2.js','crdl');crdl('connect','ediblearrangements', {trackUrl: "//e.p.ediblearrangements.com" ,connectUrl: "//d.p.ediblearrangements.com" ,cookieDomain: "p.ediblearrangements.com",cookieLife: 365});`;
  return script;
};

export const useCordial = (): void => {
  useEffect(() => {
    document.body.insertBefore(getScript(), document.body.childNodes[0]);
  });
};
