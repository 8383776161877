import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsUpXs = (): boolean =>
  useMediaQuery(useTheme().breakpoints.up('xs'));

export const useOnlyXs = (): boolean =>
  useMediaQuery(useTheme().breakpoints.only('xs'));
// export const useOnlyXs = (): boolean =>
//   useMediaQuery(useTheme().breakpoints.down('xs'));
