import * as styles from 'components/Home/CarouselDetailed/components/CarouselItem/styles';
import { CarouselDetailedItem } from 'components/Home/CarouselDetailed/types';
import { CarouselItemImageSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton/CarouselItemImageSkeleton';
import { Image } from 'components/common/Image/Image';
import { Link, Typography } from '@mui/material';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { checkForFrench } from 'components/Home/utils';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { getSizeDescription } from 'utils/get-size-description';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { updateAssetUrls } from 'utils/assets-url-builder';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface CarouselItemProps {
  item: CarouselDetailedItem;
  sequence: number;
  analyticsClickHandler?: () => void;
}
export const CarouselItem: FC<CarouselItemProps> = ({
  item: {
    src,
    frSrc,
    url,
    frUrl,
    name,
    frName,
    sizeCount,
    price,
    alt,
    frAlt,
    productImageTag,
    promo,
    promoColor,
    nonPromo,
    nonPromoColor,
    salePriceColor,
    minSizeProductPrice,
    isMinSizeOnSale,
  },
  sequence,
  analyticsClickHandler,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const srcUrl = checkForFrench(src, frSrc);
  const urlBasedOnLanguage = checkForFrench(url, frUrl);

  return (
    <Link
      sx={styles.itemLink}
      href={getPdpPath(urlBasedOnLanguage)}
      data-test="carousel-detailed-item"
      color="secondary"
      underline="none"
      onClick={() => {
        segment.productClicked(
          name,
          urlBasedOnLanguage,
          sequence,
          userSession,
          userAccountInformation,
        );
        if (analyticsClickHandler) analyticsClickHandler();
      }}
    >
      <Image
        src={updateAssetUrls(srcUrl)}
        errorFallback={srcUrl}
        loading={<CarouselItemImageSkeleton />}
        observerMargins="0px 0px 400px"
        animationDuration={250}
        alt={checkForFrench(alt, frAlt) || checkForFrench(name, frName)}
      />
      {productImageTag && (
        <Typography
          sx={styles.productImageTagText}
          component="p"
          variant="subtitle3"
        >
          {productImageTag}
        </Typography>
      )}
      {nonPromo && (
        <PureHtmlTypography
          sx={styles.promo(nonPromoColor)}
          variant="subtitle3"
        >
          {nonPromo}
        </PureHtmlTypography>
      )}
      <PureHtmlTypography variant="subtitle2" component="p" sx={styles.title}>
        {checkForFrench(name, frName)}
      </PureHtmlTypography>
      <PureHtmlTypography variant="body3" sx={styles.size}>
        {getSizeDescription(sizeCount)}
      </PureHtmlTypography>
      <span style={{ display: 'flex' }}>
        {isMinSizeOnSale && minSizeProductPrice > 1 ? (
          <Typography
            variant="subtitle2"
            component="p"
            sx={styles.regularPrice}
          >
            ${minSizeProductPrice}
          </Typography>
        ) : null}
        {isMinSizeOnSale &&
        minSizeProductPrice > 1 &&
        salePriceColor.length < 4 ? (
          <Typography
            variant="subtitle2"
            component="p"
            sx={styles.salePrice(
              isMinSizeOnSale && minSizeProductPrice > 1 ? '#FF3300' : '',
            )}
          >
            ${price}
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            component="p"
            sx={styles.salePrice(
              isMinSizeOnSale && minSizeProductPrice > 1 ? salePriceColor : '',
            )}
          >
            ${price}
          </Typography>
        )}
      </span>
      {promo && (
        <PureHtmlTypography
          sx={styles.promo(promoColor)}
          variant="subtitle3"
          component="p"
        >
          {promo}
        </PureHtmlTypography>
      )}
    </Link>
  );
};
