import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import * as styles from 'components/LocalSeoPage/States/styles';
import { BlogCarousel } from 'components/LocalSeoPage/BlogCarousel/BlogCarousel';
import { Box, Typography } from '@mui/material';
import { CanonicalUrlProvider } from 'providers/CanonicalUrlProvider';
import { HeadMetaCity } from 'components/LocalSeoPage/HeadMeta/HeadMetaCity';
import { LoadingSpinner } from 'components/LocalSeoPage/LoadingSpinner/LoadingSpinner';
import { RedirectUrl } from 'components/LocalSeoPage/RedirectUrl/RedirectUrl';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { StoreWidget } from 'components/common/StoreWidget/StoreWidget';
import {
  getData,
  isBlocked,
  selectIsError,
} from 'components/LocalSeoPage/State/feature/selectors';
import { getSeoUrlParam } from 'utils/get-seo-url-param';
import { loadLocale } from 'redux/locale/utils';
import { seoActions } from 'containers/LocalSeoContainer/feature/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useScrollToTopOnMount } from 'hooks/scroll/use-scroll-to-top-on-mount';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

export const City: FC = () => {
  useScrollToTopOnMount();
  const dispatch = useDispatch();
  const isError = useSelector(selectIsError);
  const storeData = useSelector(getData);
  const dataFetching = useSelector(isBlocked);
  const { state: stateParam, city: cityParam } = useParams<{
    state: string;
    city: string;
  }>();
  const state = stateParam && getSeoUrlParam(stateParam);
  const city = cityParam && getSeoUrlParam(cityParam);
  const storesByCity = storeData.filter((store) =>
    store.Address.includes(city || ''),
  );
  const locale = loadLocale();

  useEffect(() => {
    dispatch(
      seoActions.clearAndSet({
        title: i18next.t(TK.CITY_META_TITLE_TAG, {
          replace: {
            State: state,
            City: city,
          },
        }),
        description: i18next.t(TK.CITY_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
            City: city,
          },
        }),
      }),
    );
  });

  if (dataFetching) {
    return <LoadingSpinner />;
  }

  if (isError) {
    /** @notice it's made to trigger ErrorBoundaries and show UnexpectedError Page */
    throw new Error();
  }

  return (
    <>
      <RedirectUrl />
      <CanonicalUrlProvider />
      {state && city && <HeadMetaCity state={state} city={city} />}
      <Typography variant="h1" sx={styles.stateTitle}>
        {i18next.t(TK.CITY_PAGE_TITLE, {
          replace: {
            State: state,
            City: city,
          },
        })}
      </Typography>
      <Typography sx={styles.stateDescription}>
        {i18next.t(TK.CITY_PAGE_DESCRIPTION, {
          replace: {
            State: state,
            City: city,
          },
        })}
      </Typography>
      <Typography variant="h2" sx={styles.stateStoreTitle}>
        {i18next.t(TK.CITY_STORE_COUNT, {
          replace: {
            StoreCount: storesByCity?.length || 0,
            City: city,
            State: state,
          },
        })}
      </Typography>
      <Box>
        {storesByCity?.map((store) => (
          <StoreWidget
            key={store.ID}
            storeName={store.ModifiedName}
            storeAddress={store.Address}
            storeHours={store.TimingsShort}
            storePhoneNumber={store.PhoneNumber}
            isSmoothieStore={store.IsLevel2Store}
            pageFriendlyUrl={store.PageFriendlyURL}
            locale={locale}
          />
        ))}
      </Box>
      <RenderWhenInView>
        <BlogCarousel />
      </RenderWhenInView>
    </>
  );
};
