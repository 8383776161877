import { LegacyAddToCartParams } from 'types/cart.types';
import { RootState } from 'redux/store';
import { fillCartDataPrintible } from 'containers/PDPContainer/utils/fill-cart-data-printible';
import { getPrintibleAddon } from 'components/PDP/Upsells/feature/selectors';
import { useSelector } from 'react-redux';

type CartFiller = (cart: LegacyAddToCartParams) => LegacyAddToCartParams;

export const useCartPrintibleFiller = (): CartFiller => {
  const printibleOrder = useSelector(
    ({ greetingsCards }: RootState) => greetingsCards.printible,
  );
  const printibleAddon = useSelector(getPrintibleAddon);

  return (cart: LegacyAddToCartParams) =>
    fillCartDataPrintible(cart, printibleOrder, printibleAddon);
};
