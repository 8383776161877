import { OneTrustConsentChangeEvent } from 'service/onetrust/types';
import { checkUserTargetingCookies } from 'service/onetrust/utils';
import {
  prepareDinamicYieldConsent,
  updateDynamicYieldConsent,
} from 'service/dynamic-yield/dy-utils';
import { useEffect } from 'react';

const getDynamicScript = (): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'dynamic-yield-dynamic-script';
  script.type = 'text/javascript';
  script.src = `//cdn.dynamicyield.com/api/${window.DYNAMIC_YIELD_SITE_ID}/api_dynamic.js`;
  return script;
};

const getStaticScript = (): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'dynamic-yield-static-script';
  script.type = 'text/javascript';
  script.src = `//cdn.dynamicyield.com/api/${window.DYNAMIC_YIELD_SITE_ID}/api_static.js`;
  return script;
};

const initializeDY = () => {
  document.head.appendChild(getDynamicScript());
  document.head.appendChild(getStaticScript());
};

export const useDynamicYield = (): void => {
  useEffect(() => {
    const handleOneTrustLoaded = () => {
      if (window.OneTrust && !window.DYO) {
        const hasAcceptedTargetingCookies = checkUserTargetingCookies(
          window.OnetrustActiveGroups,
        );

        prepareDinamicYieldConsent(hasAcceptedTargetingCookies);
        initializeDY();
      }
    };

    const handleOneTrustConsentChange = (
      consentData: OneTrustConsentChangeEvent,
    ) => {
      const hasAcceptedTargetingCookies = checkUserTargetingCookies(
        consentData.detail,
      );
      updateDynamicYieldConsent(hasAcceptedTargetingCookies);
    };

    window.addEventListener('oneTrustLoaded', handleOneTrustLoaded);
    window.addEventListener(
      'consent.onetrust',
      handleOneTrustConsentChange as EventListener,
    );

    return () => {
      window.removeEventListener('oneTrustLoaded', handleOneTrustLoaded);
      window.removeEventListener(
        'consent.onetrust',
        handleOneTrustConsentChange as EventListener,
      );
    };
  }, []);
};
