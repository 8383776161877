import { commonSliderProps } from 'components/PDP/Details/components/Gallery/props';
import React, { ComponentProps, forwardRef } from 'react';
import SlickSlider from 'react-slick';

export const Slider = forwardRef<
  SlickSlider,
  ComponentProps<typeof SlickSlider>
>(({ children, ...sliderProps }, ref) => (
  <SlickSlider
    {...commonSliderProps}
    {...sliderProps}
    ref={ref}
    slidesToShow={1}
  >
    {children}
  </SlickSlider>
));
