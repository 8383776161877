import * as TK from 'components/PDP/Details/components/ProductOptions/components/ViewAvailableProductsAlert/translations/locale.constants';
import { BaseAlert } from 'components/common/BaseAlert/styled';
import { Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ViewAvailableProductsAlert: FC = () => (
  <BaseAlert data-test="pdp-product-is-not-available-block">
    <Typography variant="subtitle2" mb={1.5}>
      {i18next.t(TK.INFO)}
    </Typography>
    <Button
      variant="contained"
      color="secondary"
      href="/fruit-arrangements"
      fullWidth
      data-test="pdp-product-is-not-available-block-button"
    >
      {i18next.t(TK.BUTTON)}
    </Button>
  </BaseAlert>
);
