import { Status } from 'redux/types/state.interface';

export type Language = {
  definition: string;
  acronym: string;
  icon: string;
};

export enum Languages {
  ENGLISH = 'en-us',
  FRENCH = 'fr-ca',
}

export type LocaleState = {
  data: string;
  status: Status | null;
};

export enum CultureChangeStatus {
  OK = 'OK',
  Error = 'Error',
}

export type CultureChangeResponse = {
  _Status?: CultureChangeStatus;
  _Data?: CultureChangeStatus;
};

export interface JSONObject {
  data: string;
}
