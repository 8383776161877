import * as TK from 'components/Subscription/translations/locale.constants';
import * as styles from 'components/Subscription/EmailSubscriptionDialog/FormStep/styles';
import { Box, Container, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { SubscriptionForm } from 'components/Subscription/EmailSubscriptionDialog/SubscriptionForm';
import { TERMS_OF_USE_PATH } from 'containers/MainContainer/constants/path';
import {
  TermsAndConditions,
  TermsOfUse,
} from 'components/Subscription/EmailSubscriptionDialog/FormStep/styles';
import { useIsUpSm } from 'utils/hidden/hide-up-sm';
import React, { FC } from 'react';
import i18next from 'i18next';

interface FormStepProps {
  onSuccess: () => void;
}

export const FormStep: FC<FormStepProps> = ({ onSuccess }) => {
  const isSm = useIsUpSm();
  return (
    <>
      {!isSm && (
        <Box display="flex" justifyContent="center">
          <Box sx={styles.drawerBar} />
        </Box>
      )}
      <Container sx={styles.containerWrapper}>
        <Box sx={styles.wrapper}>
          <Typography sx={styles.contentTitle}>
            {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE)}
          </Typography>
          <Box>
            <Typography sx={styles.inlineContent}>
              <span>
                {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT)}
              </span>
              &nbsp;
              {isSm && (
                <span>
                  <Typography sx={styles.contentInstantSavings}>
                    {i18next.t(
                      TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT,
                    )}
                  </Typography>
                </span>
              )}
              {!isSm && (
                <span>
                  <Typography sx={styles.contentInstantSavings}>
                    {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT)}
                  </Typography>
                </span>
              )}
            </Typography>
            <Typography sx={styles.pickupContent}>
              {!isSm && (
                <Typography
                  sx={styles.contentInstantSavings}
                  display="contents"
                >
                  {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT)}
                </Typography>
              )}
              &nbsp;
              {i18next.t(TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT)}
            </Typography>
          </Box>
          <Box sx={styles.unlockOffer} />
          <SubscriptionForm onSuccess={onSuccess} />

          <Box sx={styles.termsContainer}>
            <Box sx={styles.termConBottom}>
              {isSm && (
                <span>
                  {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE)}
                </span>
              )}
              <TermsOfUse
                sx={styles.termsofuse}
                href={TERMS_OF_USE_PATH}
                target="_blank"
              >
                {i18next.t(TK.SUBSCRIPTION_TERMS_OF_USE)}
              </TermsOfUse>
              &nbsp;
              <span>
                {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND)}
              </span>
              &nbsp;
              <TermsAndConditions
                href={HeaderLinks.PROMOTION_TERMS_CONDITIONS}
                target="_blank"
              >
                <span>
                  {i18next.t(TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS)}
                </span>
              </TermsAndConditions>
              {isSm && (
                <span>
                  {i18next.t(TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER)}
                </span>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
