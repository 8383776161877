import 'core-js/features/string/ends-with';
import 'core-js/features/string/match-all';
import 'core-js/features/string/repeat';
import 'es6-shim';
import 'intersection-observer';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'styles/App.css';
import 'translations/i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppContainer } from 'containers/AppContainer/AppContainer';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { persistStore } from 'redux-persist';
import { plugin } from 'utils/telemetry/app-insights';
import { root } from 'utils/root';
import { store } from 'redux/store';
import React from 'react';
import ReactDOM from 'react-dom';

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={plugin}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <HelmetProvider>
                <AppContainer />
              </HelmetProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </React.StrictMode>
      </PersistGate>
    </AppInsightsContext.Provider>
  </Provider>,
  root,
);
