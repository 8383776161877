import { ArrangementGroupOccasion } from 'api/arrangement-group.api.service';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchOccasions } from 'components/PDP/Order/RecipientForm/components/Occasion/feature/actions';

interface State {
  status: Status;
  occasions: ArrangementGroupOccasion[];
}

const initialState: State = {
  status: Status.INIT,
  occasions: [],
};

const slice = createSlice({
  name: 'occasion/selector',
  extraReducers: (builder) => {
    builder
      .addCase(fetchOccasions.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchOccasions.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.occasions = payload;
      })
      .addCase(fetchOccasions.rejected, (state) => {
        state.status = Status.ERROR;
        state.occasions = [];
      })
      .addDefaultCase((state) => state);
  },
  initialState,
  reducers: {},
});
export const orderOccasionsReducer = slice.reducer;
