import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';

export const isValidateCookieboxItems = (
  selectedOwnBoxAddons: UpsellItem[],
  onChangeStep: (step: AvailabilityStep) => void,
  pasData: Pas,
): boolean => {
  const cookieLimitedName = 'Cookie Box - LTO';
  const everydayCookies = selectedOwnBoxAddons.filter(
    (addon) => !addon.option.optionName?.includes(cookieLimitedName),
  );

  if (everydayCookies.length >= 1) {
    if (pasData?.area?.name) {
      onChangeStep(AvailabilityStep.DATE_SELECTION);
    } else {
      onChangeStep(AvailabilityStep.ZIP_AVAILABILITY);
    }
    return false;
  }
  return true;
};
