export const STATES_SEO_META_TITLE = 'local_seo_page.edible_states_directory';
export const STATES_SEO_PAGE_TITLE = 'local_seo_page.edible_states_title';
export const STATES_SEO_PAGE_DESCRIPTION =
  'local_seo_page.edible_states_description';
export const STATE_SEO_STATE_PAGE_DESCRIPTION =
  'local_seo_page.edible_store_state_page_description';
export const STORE_LISTING_PAGE_DESCRIPTION =
  'local_seo_page.edible_store_listing_page_description';
export const STATE_SEO_STATE_PAGE_TITLE =
  'local_seo_page.edible_store_state_page_title';
export const STORES_IN_CITIES_OF_STATE =
  'local_seo_page.edible_stores_in_city_of_state';
export const CITY_PAGE_TITLE = 'local_seo_page.edible_city_page_title';
export const CITY_PAGE_DESCRIPTION =
  'local_seo_page.edible_city_page_description';
export const STORE_COUNT = 'local_seo_page.edible_store_count';
export const STATE_STORE_COUNT = 'local_seo_page.edible_state_store_count';
export const CITY_STORE_COUNT = 'local_seo_page.edible_city_store_count';
export const STATES = 'local_seo_page.edible_states_page';
export const HOME = 'local_seo_page.edible_home_page';
export const SHOW_MORE_WIDGETS = 'local_seo_page.show_more_widgets';
export const TOTAL_WIDGETS_SHOWN = 'local_seo_page.total_widgets_shown';
export const STATE_META_TITLE_TAG =
  'local_seo_page.edible_state_meta_title_tag';
export const STATES_META_TITLE_TAG = 'local_seo_page.edible_states_meta_title';
export const STATES_META_DESCRIPTION_TAG =
  'local_seo_page.edible_state_meta_description_tag';
export const CITY_META_TITLE_TAG = 'local_seo_page.edible_city_meta_title_tag';
export const CITY_META_DESCRIPTION_TAG =
  'local_seo_page.edible_city_meta_description_tag';
export const STORE_LIST = 'local_seo_page.store_list';
