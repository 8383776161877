import * as TK from 'components/PDP/Details/components/Gallery/translations/constants';
import * as styles from 'components/PDP/Details/components/Gallery/components/OwnBoxImage/styles';
import { Avatar, Box, Button, Grow, Tooltip, Typography } from '@mui/material';
import { ImageButton } from 'components/PDP/Details/components/Gallery/components/OwnBoxImage/styled';
import { Media } from 'components/PDP/Details/components/Gallery/components/Media';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { extendAddonsByQuantity } from 'components/PDP/Details/components/Gallery/components/OwnBoxImage/extend-addons';
import {
  getOwnBoxBoardOptions,
  getOwnBoxOptions,
} from 'components/PDP/Details/components/OwnBox/utils/get-own-box-options';
import { isNotEmpty } from 'utils/is-not-empty';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  selectedAddons: UpsellItem[];
  isBottomCarousel?: boolean;
  type: OwnBoxTypes;
  onItemSelect: (id: number) => void;
  clear: () => void;
  clickOwnBoxAddon?: OwnBoxAddon[];
  addOnIds: number[];
  isCookie12?: boolean;
};

export const OwnBoxImage: FC<Props> = ({
  selectedAddons,
  isBottomCarousel,
  type,
  onItemSelect,
  clear,
  clickOwnBoxAddon,
  addOnIds,
  isCookie12,
}) => {
  const boardOption =
    type === OwnBoxTypes.DESSERT_BOARD ? getOwnBoxBoardOptions(type) : null;

  const options = boardOption ? boardOption[0] : getOwnBoxOptions(type);

  const getDessertType = () => {
    const indexType = selectedAddons.map((x) =>
      addOnIds.indexOf(x.option.addonId),
    );

    if (
      indexType.includes(1) &&
      indexType.includes(2) &&
      indexType.includes(0)
    ) {
      return OwnBoxTypes.DESSERT_BOARD;
    }
    if (indexType.includes(1) && indexType.includes(2)) {
      return OwnBoxTypes.DESSERT_BOARD_DIPPED_BERRIES;
    }
    if (indexType.includes(0) && indexType.includes(2)) {
      return OwnBoxTypes.DESSERT_BOARD_BACKEDGOODS_DIPPEDFRUIT;
    }

    if (indexType.includes(0)) {
      return OwnBoxTypes.DESSERT_BOARD;
    }
    if (indexType.includes(1)) {
      return OwnBoxTypes.DESSERT_BOARD_DIPPED_BERRIES;
    }
    if (indexType.includes(2)) {
      return OwnBoxTypes.DESSERT_BOARD_DIPPED_FRUIT;
    }

    return OwnBoxTypes.DESSERT_BOARD;
  };
  let ownBoxType = type;
  if (addOnIds.length === 3) {
    ownBoxType = getDessertType();
  }

  if (isBottomCarousel) {
    return (
      <Avatar variant="rounded" sx={styles.avatar}>
        <Typography variant="body3">
          {i18next.t(TK.GALLERY_OWN_BOX_TITLE)}
        </Typography>
      </Avatar>
    );
  }

  return (
    <Box position="relative">
      <Media src={options?.image} alt={i18next.t(TK.GALLERY_OWN_BOX_TITLE)} />
      {extendAddonsByQuantity(
        selectedAddons,
        options,
        clickOwnBoxAddon,
        addOnIds,
        isCookie12,
      ).map(({ option: { name, image, id: optionID } }, id) => (
        <Tooltip
          // eslint-disable-next-line react/no-array-index-key
          key={`${name}_${id}`}
          title={name}
          placement="top"
          arrow
          data-id={id}
        >
          <ImageButton
            boxtype={ownBoxType}
            onClick={() => onItemSelect(optionID)}
          >
            <img role="presentation" src={image} alt={name} />
          </ImageButton>
        </Tooltip>
      ))}
      <Grow in={isNotEmpty(selectedAddons)}>
        <Button
          size="small"
          color="secondary"
          sx={styles.clearButton}
          onClick={clear}
        >
          {i18next.t(TK.GALLERY_OWN_BOX_CLEAR_SELECTED)}
        </Button>
      </Grow>
    </Box>
  );
};
