import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ArrangementPreview } from 'api/arrangements/types';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { ResponseError } from 'types/error.types';
import { State } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.actions';

const orderTypeInitialState = InitialStateFactory.create<ArrangementPreview[]>(
  [],
);

const initialState: State = {
  [OrderType.Delivery]: orderTypeInitialState,
  [OrderType.Pickup]: orderTypeInitialState,
  [OrderType.Shipment]: orderTypeInitialState,
};

const slice = createSlice({
  name: 'suggestedArrangementsNew',
  initialState,
  reducers: { reset: () => initialState },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<State>>): void => {
    builder
      .addCase(
        getSuggestedArrangements.pending,
        (
          state,
          {
            meta: {
              arg: { orderType },
            },
          },
        ) => ({
          ...state,
          [orderType as FulfillmentOptionType]: {
            data: [],
            status: Status.PENDING,
            error: null,
          },
        }),
      )
      .addCase(
        getSuggestedArrangements.fulfilled,
        (
          state,
          {
            payload,
            meta: {
              arg: { orderType },
            },
          },
        ) => ({
          ...state,
          [orderType as FulfillmentOptionType]: {
            data: payload,
            status: Status.FULFILLED,
            error: null,
          },
        }),
      )
      .addCase(
        getSuggestedArrangements.rejected,
        (
          state,
          {
            payload,
            meta: {
              arg: { orderType },
            },
          },
        ) => ({
          ...state,
          [orderType as FulfillmentOptionType]: {
            data: [],
            status: Status.ERROR,
            error: payload as ResponseError,
          },
        }),
      );
  },
});

export const suggestedArrangementsReducerNew = slice.reducer;
export const { reset } = slice.actions;
