import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/system';

export const container: ThemedSxProps = {
  justifyContent: 'flex-start',
};

export const Headline = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(4),
  },
}));
