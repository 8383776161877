import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const gallery: ThemedSxProps = () => ({
  borderWidth: ['inherit', 1, 'inherit'],
  borderStyle: ['hidden', 'solid', 'hidden'],
  borderColor: ({ palette }) => palette.divider,
  display: 'flex',
  justifyContent: 'center',
  padding: [0, 4, 0],
});

export const galleryMpProduct: ThemedSxProps = () => ({
  display: 'flex',
  justifyContent: 'center',
  padding: [0, 4, 0],
});

export const slider =
  (showHorizontalBorders: boolean): ThemedSxProps =>
  (theme) => ({
    width: [1, 410, 400],
    textAlign: 'center',
    ...(showHorizontalBorders && {
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: theme.palette.divider,
      [theme.breakpoints.up('sm')]: {
        border: 'none',
      },
    }),
  });

export const skeleton: ThemedSxProps = () => ({
  width: [360, 'auto', 400],
  height: [360, 474, 400],
  mx: 'auto',
});
