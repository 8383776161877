export const WELCOME_TITLE = 'plp.call-center-welcome-modal.title';
export const WELCOME_MESSAGE = 'plp.call-center-welcome-modal.message';
export const WELCOME_LOGGED_MESSAGE =
  'plp.call-center-welcome-modal.logged-message';
export const WELCOME_CONTINUE = 'plp.call-center-welcome-modal.continue';
export const WELCOME_LOGIN = 'plp.call-center-welcome-modal.login';

export const ERROR_TITLE = 'plp.call-center-error-modal.title';
export const ERROR_MESSAGE = 'plp.call-center-error-modal.message';
export const ERROR_CLOSE = 'plp.call-center-error-modal.close';
