import { State, Status } from 'redux/types/state.interface';
import { Store } from 'redux/types/store.types';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { fetchStores } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.actions';

const initialState: State<Store[]> = {
  data: [],
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: createExtraReducers(initialState, fetchStores),
});

export const { reducer: storesReducer } = slice;
export const storeSelector = slice.actions;
