import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import { Helmet } from 'react-helmet-async';
import { ogImage } from 'utils/cloudinaryAssets';
import { useStructuredCityData } from 'hooks/meta/use-city-structured-data';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  state: string;
  city: string;
}
export const HeadMetaCity: FC<Props> = ({ state, city }) => {
  const structuredData = useStructuredCityData();

  return (
    <Helmet>
      <title>
        {i18next.t(TK.CITY_META_TITLE_TAG, {
          replace: {
            State: state,
            City: city,
          },
        })}
      </title>
      <meta name="keywords" content="" />
      <meta
        name="title"
        content={i18next.t(TK.CITY_META_TITLE_TAG, {
          replace: {
            State: state,
            City: city,
          },
        })}
      />
      <meta
        name="description"
        content={i18next.t(TK.CITY_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
            City: city,
          },
        })}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={i18next.t(TK.CITY_META_TITLE_TAG, {
          replace: {
            State: state,
            City: city,
          },
        })}
      />
      <meta
        property="og:description"
        content={i18next.t(TK.CITY_META_DESCRIPTION_TAG, {
          replace: {
            State: state,
            City: city,
          },
        })}
      />
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};
