import * as styles from 'components/PDP/Details/components/Marketplace/components/BrandValues/brandvalues.styles';
import { Avatar } from '@mui/material';
import React, { FC } from 'react';

interface BrandValueImgProps {
  url: string;
}

export const BrandValueImg: FC<BrandValueImgProps> = ({
  url,
}: BrandValueImgProps) => {
  return <Avatar sx={styles.imageContainer} src={url} />;
};
