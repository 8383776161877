import * as TK from 'containers/SignupContainer/translations/locale.constants';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { CLOSE } from 'translations/locales/translation-keys.constant';
import { Close } from '@mui/icons-material';
import { Links } from 'enums/common-links';
import { checkIsSignUpSuccess } from 'components/SignUp/feature/selectors';
import { closeBtn, container } from 'components/Authentication/styles';
import { colors } from 'utils/theme/colors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const SuccessDialog: FC = () => {
  const success = useSelector(checkIsSignUpSuccess);
  const [open, setOpen] = useState<boolean>(success);

  useEffect(() => {
    if (success) {
      setOpen(true);
    }
  }, [success]);

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          href={Links.FruitArrangements}
          sx={closeBtn}
          aria-label={i18next.t(CLOSE)}
          size="large"
          color="secondary"
        >
          <Close />
        </IconButton>
        {i18next.t(TK.SIGNUP_SUCCESS_TITLE)}
      </DialogTitle>
      <DialogContent sx={container}>
        <Typography
          variant="subtitle1"
          sx={{
            width: 1,
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {i18next.t(TK.SIGNUP_SUCCESS_CONTENT)}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          href="/"
          sx={{
            backgroundColor: colors.actionActive,
            color: colors.white,
          }}
        >
          {i18next.t(TK.SIGNUP_SHOP_NOW_BUTTON_TEXT)}
        </Button>
        <Button href="/rewards" variant="contained">
          {i18next.t(TK.SIGNUP_MY_ACCOUNT_BUTTON_TEXT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
