import * as TK from 'translations/locales/translation-keys.constant';
import { STORE_TIMINGS } from 'translations/locales/translation-keys.constant';
import { format, setHours, setMinutes } from 'date-fns';
import i18next from 'i18next';

type Props = {
  openingTime: string;
  closingTime: string;
  date: Date;
};

export const getTimingMessage = ({
  openingTime,
  closingTime,
  date,
}: Props): string => {
  const [openHours, openMinutes] = openingTime.split(':');
  const [closeHours, closeMinutes] = closingTime.split(':');

  return i18next.t(STORE_TIMINGS, {
    replace: {
      selectedDate: format(date, 'cccc, dd MMMM'),
      openingTime: format(
        setMinutes(setHours(new Date(), +openHours), +openMinutes),
        'p',
      ),
      closingTime: format(
        setMinutes(setHours(new Date(), +closeHours), +closeMinutes),
        'p',
      ),
    },
  });
};

const daysMap = {
  [i18next.t(TK.MONDAY_FULL)]: i18next.t(TK.MONDAY_SHORT),
  [i18next.t(TK.TUESDAY_FULL)]: i18next.t(TK.TUESDAY_SHORT),
  [i18next.t(TK.WEDNESDAY_FULL)]: i18next.t(TK.WEDNESDAY_SHORT),
  [i18next.t(TK.THURSDAY_FULL)]: i18next.t(TK.THURSDAY_SHORT),
  [i18next.t(TK.FRIDAY_FULL)]: i18next.t(TK.FRIDAY_SHORT),
  [i18next.t(TK.SATURDAY_FULL)]: i18next.t(TK.SATURDAY_SHORT),
  [i18next.t(TK.SUNDAY_FULL)]: i18next.t(TK.SUNDAY_SHORT),
};
const fullDayTitles = Object.keys(daysMap);

/**
 * Function that transform full-form day/days to the short form
 * - translations are implemented
 * - if wrong string is passed it won't be formatted
 *
 * @param {string} daysString - String with full-form day/days(separated with -)
 * @returns {string} String with short-form day/days(separated with -)
 *
 * @example
 *  transformDaysToShortForm('Monday')
 *  transformDaysToShortForm('Monday-Wednesday')
 */
export const transformDaysToShortForm = (daysString: string): string => {
  const days = daysString.split('-');
  const isRightDayTitles = days.every((day) =>
    fullDayTitles.some((title) => title === day),
  );

  if (!isRightDayTitles) {
    return daysString;
  }

  if (days.length === 1) {
    return daysMap[days[0]];
  }
  return `${daysMap[days[0]]}-${daysMap[days[1]]}`;
};
