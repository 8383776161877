import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';

export const checkArrangementGroupStatus = createSelector(
  ({ plpArrangementGroup: { status } }: RootState) => status,
  (status) => status,
);

export const checkIsBlocked = createSelector(
  ({ plpArrangementGroup: { status } }: RootState) => status,
  (status) => isBlocked(status),
);

export const selectInfo = createSelector(
  ({ plpArrangementGroup: { arrangementGroup: a } }: RootState) => a?.mls || [],
  ([info]) => info,
);

export const selectArrangementGroupId = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState) => arrangementGroup?.id || -1;

export const selectArrangementInactive = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState): boolean => Boolean(arrangementGroup?.inactive);

export const selectMarketplaceCategories = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState) => arrangementGroup?.marketplaceCategories || [];

export const getGroupImage = createSelector(
  ({ plpArrangementGroup: a }: RootState) => a.arrangementGroup,
  (group) =>
    (IS_MOBILE_HOST
      ? group?.giftGuideMobileBannerImage
      : group?.giftGuideBannerImage) || '',
);

export const getDesktopGroupImage = createSelector(
  ({ plpArrangementGroup: a }: RootState) => a.arrangementGroup,
  (group) => group?.giftGuideBannerImage || '',
);
export const getMobileGroupImage = createSelector(
  ({ plpArrangementGroup: a }: RootState) => a.arrangementGroup,
  (group) => group?.giftGuideMobileBannerImage || '',
);

export const selectArrangementGroupKeyWords = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState) =>
  arrangementGroup?.mls[0].metaTagKeyword ||
  arrangementGroup?.mls[0].pageTitle ||
  '';

export const selectArrangementGroupTitle = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState) => arrangementGroup?.mls[0].name || 'Occasion Page';

export const selectArrangementGroup = ({
  plpArrangementGroup: { arrangementGroup },
}: RootState) => arrangementGroup?.mls[0];
