import { Box, Rating, Typography } from '@mui/material';
import { Link } from 'react-scroll';
import { ROOT_CONTAINER_ID } from 'utils/root';
import { selectReviewsInfo } from 'components/PDP/Details/components/Reviews/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface Props {
  onLinkClick: () => void;
}

export const StarRating: FC<Props> = ({ onLinkClick }) => {
  const { rating, count } = useSelector(selectReviewsInfo);

  return (
    <Box display="flex" alignItems="center">
      <Rating value={rating} readOnly sx={{ mr: 0.5 }} />
      <Link
        containerId={ROOT_CONTAINER_ID}
        onClick={onLinkClick}
        to={count ? 'reviews' : ''}
        smooth
        style={{ color: 'text.primary' }}
        data-test="pdp-details-rating-count"
      >
        <Typography
          sx={{
            cursor: count ? 'pointer' : 'default',
            textDecoration: count ? 'underline' : 'none',
            pt: 0.5,
          }}
          variant="body2"
        >
          {`(${count})`}
        </Typography>
      </Link>
    </Box>
  );
};
