import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { styled } from '@mui/material';

export const Image = styled('img')({
  display: 'block',
  width: '100%',
  minHeight: '62px',
});

export const wrapper: ThemedSxProps = (theme) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const textWrapper: ThemedSxProps = (theme) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const banner: ThemedSxProps = {
  position: 'relative',
  cursor: 'pointer',
  width: '100%',
  marginBottom: { xs: '20px', md: '40px' },
  textDecoration: 'none',
};

export const bannerText: ThemedSxProps = {
  font: 'inherit',
  fontWeight: 400,
};

export const giftFinderText: ThemedSxProps = (theme) => ({
  width: '81px',
  height: '22px',
  borderRadius: '100px',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '22px',
  color: theme.palette.primary.contrastText,
  marginLeft: '14%',
  marginTop: '3%',
});

export const giftFinder: ThemedSxProps = {
  background: colors.primaryBlack,
  borderRadius: '100px',
  width: '107.28px',
  height: '26.26px',
  margin: '10px',
};

export const bgWrapper: ThemedSxProps = {
  width: '100%',
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: fonts.poppins,
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  textAlign: 'center',
  marginBottom: { xs: '20px', md: '40px' },
  '&:hover': {
    textDecoration: 'none',
  },
};

export const bgBanner: ThemedSxProps = (theme) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '24px', sm: '30px' },
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  color: theme.palette.common.white,
  paddingTop: '5px',
  paddingBottom: '5px',
});

export const bgBannerText: ThemedSxProps = (theme) => ({
  fontWeight: 400,
  color: theme.palette.common.black,
  marginLeft: '5px',
  marginRight: '5px',
  height: 'auto',
});
