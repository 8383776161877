import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { styled } from '@mui/system';

export const Item = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(0.75),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(5),
  },
}));

export const itemImage: ThemedSxProps = {
  paddingBottom: '100%',
  width: '100%',
};

export const itemTitle: ThemedSxProps = (theme) => ({
  height: { xs: 14, md: 20 },
  margin: {
    xs: theme.spacing(1.5, 0, 0.25),
    md: theme.spacing(2.625, 0, 0.125),
  },
});

export const widgetTitle: ThemedSxProps = (theme) => ({
  width: '50%',
  height: { xs: 20, md: 25 },
  margin: { xs: theme.spacing(0.125, 0), md: theme.spacing(0.625, 0) },
});

export const container: ThemedSxProps = (theme) => ({
  marginTop: { xs: theme.spacing(1.875), md: theme.spacing(3.125) },
  padding: theme.spacing(0, 2.5),
  justifyContent: 'center',
});
