import {
  Step as MuiStep,
  StepContent as MuiStepContent,
  StepLabel as MuiStepLabel,
  styled,
} from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';

export const StepContent = styled(MuiStepContent)(({ theme }) => ({
  border: 0,
  margin: 0,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    '& > .MuiCollapse-root': {
      marginBottom: theme.spacing(4),
    },
  },
}));

export const Step = styled(MuiStep)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 2.5),
    border: `1px solid ${theme.palette.other.outlineBorder}`,
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface StepLabelProps {
  returnable?: boolean;
}

export const StepLabel = styled(MuiStepLabel, {
  shouldForwardProp: isPropValid,
})<StepLabelProps>(({ theme, returnable }) => ({
  width: '100%',

  ...(returnable && {
    '& svg': {
      color: theme.palette.common.black,
    },
  }),
}));
