import * as TK from 'components/Home/translations/locale.constants';

export const homePageCommonLocale = {
  [TK.HOME_PAGE_VIEW_ALL]: 'View All',
  [TK.HOME_PAGE_CAROUSEL_FORWARD_CONTROL]:
    'Move carousel forward to revel more products',
  [TK.HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL]:
    'Move carousel backwards to previous products',
  [TK.NEAREST_STORE_WEEKLY_HOURS]: 'Display nearest store weekly hours',
  [TK.NEAREST_STORE_PHONE_CONTACT]:
    'Call the Edible Store closest to your location',
  [TK.NEAREST_STORE_DIRECTIONS]:
    'Get directions to the closest Edible Store to your location',
  [TK.HOME_PAGE_H1_HEADING]:
    'Edible Arrangements Fruit Bouquets, Chocolate Covered Strawberries & Gift Delivery',
  [TK.FRANCHISE_BANNER_TITLE]: 'the sweetest deal in franchising!',
  [TK.FRANCHISE_BANNER_BODY]:
    'Open your own Edible® storefront and join our passionate, global community of franchise owners',
  [TK.FRANCHISE_BANNER_BUTTON]: 'Learn More',
  [TK.POPULAR_CATEGORIES]: 'Popular Categories',
};
