import { Currency } from 'components/Paypal/enums/currency.enum';
import { KeyConst } from 'components/Paypal/enums/key.enum';
import {
  checkIsInit,
  payPalEnabled,
  payPalVersion,
} from 'redux/settings/settings.selectors';
import {
  getPaypalEnableSetting,
  getPaypalVersionSetting,
} from 'redux/settings/settings.actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';

export const PaypalProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const isInit = useSelector(checkIsInit);
  const isPayPalEnabled = useSelector(payPalEnabled);
  const isPayPalVersion = useSelector(payPalVersion);
  const pageName = window.location.pathname;

  useEffect(() => {
    if (isInit || isPayPalVersion === '' || isPayPalEnabled === false) {
      dispatch(getPaypalEnableSetting());
      dispatch(getPaypalVersionSetting());
    }
  }, []);

  useEffect(() => {
    if (
      isPayPalEnabled &&
      isPayPalVersion === KeyConst.PaypalVersion &&
      window.PAYPAL_CLIENT_ID
    ) {
      /* NOTE: We can't use Helmet here, because it causes multiple script execution */
      if (pageName !== '/payment') {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?components=messages&currency=${Currency.USD}&enable-funding=paylater&client-id=${window.PAYPAL_CLIENT_ID}`;
        script.setAttribute('data-test', 'paypal-script');
        document.head.appendChild(script);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPayPalEnabled, isPayPalVersion]);

  return <>{children}</>;
};
