import * as TK from 'components/CustomerService/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { FAQ_EDIBLE_REWARDS_ID } from 'api/api.constant';
import { FaqEntity, faqApiService } from 'api/faq-api.service';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { Source } from 'components/CustomerService/faq.enum';
import AddIcon from '@mui/icons-material/Add';
import React, { FC, useEffect, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import i18next from 'i18next';

export const Faq: FC = () => {
  const [faqs, setFaqs] = useState<FaqEntity[]>([]);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [opened, setOpened] = React.useState<number>(0);
  const rewardCategoryId = FAQ_EDIBLE_REWARDS_ID ?? -1;
  const [dataLoaded, setDataLoaded] = useState<boolean>(true);
  useEffect(() => {
    const fetchEdibleFaqs = async () => {
      const res = await faqApiService.getFaqs(
        Source.edibleArrangements,
        rewardCategoryId,
      );
      setFaqs(res.data);
      setDataLoaded(false);
    };
    fetchEdibleFaqs();
  }, []);

  const handleAccordionChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setOpened(panel);
    };

  return (
    <>
      <Backdrop opened={dataLoaded} />
      <Box sx={{ width: '100%' }}>
        <Typography data-test="faq-title" variant="h4" sx={styles.faqTitle}>
          {i18next.t(TK.CUSTOMER_SERVICE_FAQ_PAGE)}
        </Typography>
        <Box sx={{ marginTop: '5px' }}>
          {faqs.map((faq) => (
            <Accordion
              key={faq.id}
              expanded={expanded === faq.id}
              onChange={handleAccordionChange(faq.id)}
            >
              <AccordionSummary
                expandIcon={
                  expanded && opened === faq.id ? <RemoveIcon /> : <AddIcon />
                }
              >
                <PureHtmlTypography sx={styles.accordionTitle}>
                  {faq.question}
                </PureHtmlTypography>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionDetails}>
                <PureHtmlTypography>{faq.answer}</PureHtmlTypography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};
