import * as actions from 'components/Payment/feature/actions';
import { Forms } from 'service/adobe-analytics/enums';
import { analytics } from 'service/adobe-analytics/analytics';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useFetchCountries = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCountries());
    analytics.formStart(Forms.BillingInfoForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
