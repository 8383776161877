import { HttpStatusCode } from 'enums/http-status-code';
import { arrangementApiService } from 'api/arrangements/arrangement.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createProductFeedSku } from 'service/dynamic-yield';
import { isEmpty } from 'utils/array/size';
import { parseAxiosError } from 'utils/errors';

export const fetchArrangement = createAsyncThunk(
  'arrangement/get',
  async (pageFriendlyUrl: string, { rejectWithValue }) => {
    try {
      const arrangements = await arrangementApiService.getArrangements({
        pageFriendlyUrl,
        limit: 1,
      });

      if (isEmpty(arrangements)) {
        return rejectWithValue({ status: HttpStatusCode.NOT_FOUND });
      }
      const arrangement = arrangements[0];
      arrangement.products = arrangement.products.map((product) => ({
        ...product,
        sku: createProductFeedSku(arrangement.catalogNumber, product.name),
      }));

      return arrangement;
    } catch (e) {
      return rejectWithValue(parseAxiosError(e));
    }
  },
);
