import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const suggestedDate: SxProps = {
  mb: 1,
  '& .MuiFormControlLabel-label': {
    width: 1,
  },
  '& .MuiRadio-root': {
    py: 0,
  },
};

export const disclaimerMessage: SxProps = {
  fontFamily: fonts.poppins,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '8px',
  display: 'flex',
  lineHeight: '157%',
  alignItems: 'center',
  letterSpacing: '0.1px',
  color: colors.activeBlack,
};
