import { ApiBaseService } from 'api/apibase.service';
import { AxiosResponse } from 'axios';
import { RequestOptions } from 'redux/types/request-options.interface';

interface AreaML {
  id: number;
  name: string;
}

interface CityML {
  id: number;
  name: string;
}

interface StateML {
  id: number;
  code: string;
}

export type Contact = {
  id: number;
  accountId: number;
  title: string;
  firstName: string;
  lastName: string;
  company: string;
  addressType: number;
  address1: string;
  address2: string;
  areaML: AreaML | null;
  // TODO: Make non optional after R36
  areaOther?: string;
  cityId: number | null;
  cityML: CityML | null;
  stateML: StateML;
  workPhone: string;
  homePhone: string;
  cellPhone: string;
  email: string;
};

export interface GetContactsParams {
  name?: string;
  offset?: number;
  limit?: number;
}

interface ContactResponse {
  data: Contact[];
  count: number;
}

const DEFAULT_CONTACTS_LIMIT = 20;

class ContactsApiService extends ApiBaseService {
  get(
    { name, offset, limit }: GetContactsParams,
    { cancelToken }: RequestOptions,
  ): Promise<AxiosResponse<ContactResponse>> {
    return this.apibase.get('/contacts', {
      params: {
        name,
        offset,
        limit: limit || DEFAULT_CONTACTS_LIMIT,
      },
      cancelToken,
    });
  }
}

const contactsApiService = new ContactsApiService();

export { contactsApiService };
