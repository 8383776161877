import { ResetForm } from 'components/Authentication/Reset/ResetForm';
import { ResetSent } from 'components/Authentication/Reset/ResetSent';
import { checkIsSuccess } from 'components/Authentication/Reset/feature/selectors';
import { reset } from 'components/Authentication/Reset/feature/slice';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';

export const Reset: FC = () => {
  const dispatch = useDispatch();
  const success = useSelector(checkIsSuccess);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch],
  );

  return success ? <ResetSent /> : <ResetForm />;
};
