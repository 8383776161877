import * as TK from 'components/PDP/Details/components/ContinueButton/translations/continue-button.locale.constants';
import * as styles from 'components/PDP/Details/components/ContinueButton/continue-button.styles';
import { Box, Button } from '@mui/material';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { Quantity } from 'components/PDP/Details/components/ProductOptions/components/Quantity/Quantity';
import { appInsights } from 'utils/telemetry/app-insights';
import React, { forwardRef } from 'react';
import i18next from 'i18next';

interface Props {
  disabled?: boolean;
  onClick: (event?: React.MouseEvent) => void;
  dataTest: string;
  selectedProduct?: Product;
  quantity?: number;
  setQuantity?: (q: number) => void;
}

export const ContinueButton = forwardRef<HTMLButtonElement, Props>(
  ({ disabled, onClick, dataTest, ...props }, ref) => (
    <Box sx={styles.continueButtonWrapper}>
      <Box sx={styles.counterButton}>
        {props.selectedProduct && props.quantity && props.setQuantity && (
          <Quantity
            quantity={props.quantity}
            selectedProduct={props.selectedProduct}
            setQuantity={props.setQuantity}
          />
        )}
      </Box>
      <Button
        variant="contained"
        size="large"
        disabled={disabled}
        onClick={(e) => {
          appInsights.trackEvent({ name: 'PAS continue clicked' });
          onClick(e);
        }}
        ref={ref}
        fullWidth
        data-test={dataTest}
      >
        {i18next.t(TK.PRODUCT_CONTINUE_BUTTON)}
      </Button>
    </Box>
  ),
);
