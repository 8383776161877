import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';

export const getName = createSelector(
  ({ pdpBackFilter: { filterName } }: RootState) => filterName,
  (name) => name,
);

export const checkIsBlocked = createSelector(
  ({ pdpBackFilter: { status } }: RootState) => status,
  (status) => isBlocked(status),
);
