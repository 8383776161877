export const AREA = 'area.area';
export const POSTAL_CODE = 'area.postal_code';
export const CHANGE_POSTAL_CODE = 'area.change_postal_code';
export const STATE = 'area.state';
export const CITY = 'area.city';
export const CHANGE_AREA = 'area.change_area';
export const CHANGE_AREA_INFO = 'area.change_area_info';
export const CHANGE_POSTAL_CODE_INFO = 'area.change_postal_code_info';
export const CART_POSTAL_CODE_INFO = 'area.cart_postal_code_info';
export const CHANGE_ZIP_TITLE = 'area.change_zip_title';
export const CHANGE_ZIP_DESCRIPTION = 'area.change_zip_description';
export const CHANGE_BACK_BUTTON = 'area.change_back_button';
export const CHANGE_ZIP_BUTTON = 'area.change_zip_button';
