import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';
import * as styles from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styles';
import { Box, Typography } from '@mui/material';
import { getAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const OriginalAddress: FC = () => {
  const address = useSelector(getAddress);

  if (!address) {
    return null;
  }

  const addressLine1 = `${address.street1}${
    address.street2 ? `, ${address.street2}` : ''
  }`;
  const addressLine2 = `${address.city} ${address.state || ''}, ${
    address.zip || ''
  }`;

  return (
    <Box sx={styles.address}>
      <Typography variant="subtitle2">
        {i18next.t(TK.SA_ORIGINAL_ADDRESS)}
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography sx={styles.addressStreet} variant="body2">
          {addressLine1}
        </Typography>
        <Typography variant="body2">{addressLine2}</Typography>
      </Box>
    </Box>
  );
};
