import * as styles from 'components/GiftFinder/Form/styles';
import { Avatar, Box, Typography } from '@mui/material';
import { colors } from 'utils/theme/colors';
import CheckIcon from '@mui/icons-material/Check';
import React, { FC } from 'react';

interface Props {
  label: string;
  subHeading: string;
  imageSrc: string;
  isSelected?: boolean;
}

export const GiftFinderSelector: FC<Props> = ({
  label,
  subHeading,
  imageSrc,
  isSelected = false,
}) => (
  <Box
    display="flex"
    alignItems="center"
    p={2}
    border={1}
    borderColor={isSelected ? colors.primaryRed : colors.greyBorder}
    sx={styles.giftFinderSelectorContainer}
    width="100%"
    position="relative"
  >
    <Avatar
      alt={label}
      src={imageSrc}
      sx={styles.giftFinderSelectorMainImage}
    />
    <Box>
      <Typography color="black" variant="h6" component="div">
        {label}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subHeading}
      </Typography>
    </Box>
    {isSelected && <CheckIcon sx={styles.giftFinderSelectorCheckIcon} />}
  </Box>
);
