import { CallCenterSessionParams } from 'api/session/types/call-center-session.interface';
import { appInsights } from 'utils/telemetry/app-insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { sessionApiService } from 'api/session/session.api.service';

export const createCallCenterSession = createAsyncThunk(
  'call-center-mode/send',
  async (
    callCenterSessionParams: CallCenterSessionParams,
    { rejectWithValue },
  ) => {
    try {
      const result = await sessionApiService.createCallCenterSession(
        callCenterSessionParams,
      );
      appInsights.trackEvent({
        name: 'Call center session started',
      });
      return result;
    } catch (err) {
      const returnError = parseAxiosError(err);
      appInsights.trackEvent({
        name: 'Call center session error',
      });
      return rejectWithValue(returnError.message);
    }
  },
);
