import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Header/styles';
import { AccountMenu } from 'components/Header/Menus/AccountMenu';
import { Box, Button, Divider, Link } from '@mui/material';
import { DesktopCartLink } from 'components/Header/DesktopCartLink';
import { EdibleLogo } from 'components/Header/EdibleLogo';
import { HeaderLinks } from 'enums/header-links';
import { HelpMenu } from 'components/Header/Menus/HelpMenu';
import { LanguageMenu } from 'components/Header/Menus/LanguageMenu';
import { Search } from 'components/Header/Search';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { analytics } from 'service/adobe-analytics/analytics';
import { isCanada } from 'utils/country';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useFetchRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/hooks/use-fetch-recipients';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, memo } from 'react';
import i18next from 'i18next';

export const TopHeaderPanel: FC = memo(() => {
  useTranslation();
  useFetchRecipients();
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const handleClick = (menuItem: string, link: string) => {
    analytics.headerMenuItemClicked(menuItem);
    segment.menuItemClicked(
      menuItem,
      link,
      userSession,
      userAccountInformation,
    );
  };

  return (
    <Box sx={styles.upperHeaderPanel}>
      <ShowUpMd>
        <EdibleLogo />
        <Box sx={styles.webHeader}>
          <Box sx={styles.search}>
            <Search />
          </Box>
          <Box sx={styles.controls}>
            {isCanada && (
              <>
                <LanguageMenu />
                <Box sx={styles.divider}>
                  <Divider orientation="vertical" />
                </Box>
              </>
            )}
            <HelpMenu />
            <Button
              data-test="store-locator"
              href={HeaderLinks.STORE_FINDER}
              color="secondary"
              component={Link}
              sx={styles.buttonLink}
              onClick={() =>
                handleClick(
                  i18next.t(TK.HEADER_FIND_STORE),
                  HeaderLinks.STORE_FINDER,
                )
              }
            >
              {i18next.t(TK.HEADER_FIND_STORE)}
            </Button>
            <AccountMenu />
            <Box sx={styles.divider}>
              <Divider orientation="vertical" />
            </Box>
            <DesktopCartLink />
          </Box>
        </Box>
      </ShowUpMd>
    </Box>
  );
});
