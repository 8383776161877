import { IconButton, InputBase } from '@mui/material';
import { InputWrapper } from 'components/PDP/Details/components/ProductOptions/components/Quantity/quantity.styles';
import { MIN_PRODUCTS_AMOUNT } from 'components/PDP/Details/components/ProductOptions/components/Quantity/quantity.constants';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import {
  QUANTITY_ARIA,
  QUANTITY_DECREASE_ARIA,
  QUANTITY_INCREASE_ARIA,
} from 'components/PDP/Details/components/ProductOptions/translations/product-options.locale.constants';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import React, { FC } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import i18next from 'i18next';

interface Props {
  quantity: number;
  setQuantity(quantity: number): void;
  disabled?: boolean;
  selectedProduct: Product;
}

export const Quantity: FC<Props> = ({
  quantity,
  setQuantity,
  disabled,
  selectedProduct,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    segment.productQuantityChanged(
      selectedProduct,
      quantity,
      userSession,
      userAccountInformation,
    );
  };
  const handleDecrement = () => {
    segment.productQuantityChanged(
      selectedProduct,
      quantity,
      userSession,
      userAccountInformation,
    );
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <InputWrapper disabled={disabled ? 1 : 0}>
      <IconButton
        onClick={handleDecrement}
        color="secondary"
        disabled={quantity === MIN_PRODUCTS_AMOUNT || disabled}
        data-test="pdp-details-quantity-minus"
        aria-label={i18next.t(QUANTITY_DECREASE_ARIA)}
      >
        <RemoveIcon />
      </IconButton>

      <InputBase
        value={quantity}
        sx={{ flex: 1 }}
        inputProps={{
          style: { textAlign: 'center' },
          disabled: true,
          'data-test': 'pdp-details-quantity-value',
          'aria-label': i18next.t(QUANTITY_ARIA),
        }}
        disabled={disabled}
      />

      <IconButton
        color="primary"
        onClick={handleIncrement}
        disabled={disabled}
        data-test="pdp-details-quantity-plus"
        aria-label={i18next.t(QUANTITY_INCREASE_ARIA)}
      >
        <AddIcon />
      </IconButton>
    </InputWrapper>
  );
};
