/* eslint-disable no-bitwise */
import { removeUndefinedProperties } from 'utils/remove-undefined-properties';

export function hashString(str: string): number {
  return str
    .split('')
    .map((character) => character.charCodeAt(0))
    .reduce((hash, code) => {
      const newHash = (hash << 5) - hash + code;
      return newHash & newHash;
    }, 0);
}

export function hashObject(obj: Record<string, unknown>): number {
  return hashString(JSON.stringify(removeUndefinedProperties(obj)));
}
