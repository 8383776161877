import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

export const PaypalSkeleton: FC = () => (
  <Skeleton
    sx={{
      width: ['65%', '50%', '40%'],
      height: ['10px'],
      marginTop: ['25px'],
    }}
  />
);
