import { Status } from 'redux/types/state.interface';
import { Upsell } from 'api/products/types';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAddons,
  fetchUpgrades,
} from 'components/PDP/Upsells/feature/actions';

interface State {
  upgrades: Upsell[];
  addons: Upsell[];
  upgradesStatus: Status;
  addonsStatus: Status;
}

const initialState: State = {
  upgrades: [],
  addons: [],
  upgradesStatus: Status.INIT,
  addonsStatus: Status.INIT,
};
const slice = createSlice({
  name: 'pdp/upsells',
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpgrades.pending, (state) => {
        state.upgradesStatus = Status.PENDING;
      })
      .addCase(fetchAddons.pending, (state) => {
        state.addonsStatus = Status.PENDING;
      })
      .addCase(fetchUpgrades.fulfilled, (state, { payload }) => {
        state.upgrades = payload;
        state.upgradesStatus = Status.FULFILLED;
      })
      .addCase(fetchAddons.fulfilled, (state, { payload }) => {
        state.addons = payload;
        state.addonsStatus = Status.FULFILLED;
      })
      .addCase(fetchUpgrades.rejected, (state) => {
        state.upgradesStatus = Status.ERROR;
      })
      .addCase(fetchAddons.rejected, (state) => {
        state.addonsStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
  initialState,
  reducers: {},
});

export const pdpUpsellsReducer = slice.reducer;
