export const getIniScript = (id: string): HTMLScriptElement => {
  const script = document.createElement('script');

  script.dataset.test = 'google-ads-tag-init-manager-script';
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  return script;
};

export const getScript = (id: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.dataset.test = 'google-ads-tag-manager-script';
  script.innerHTML = `try { 
    window.dataLayer = window.dataLayer || []; 
    function gtag() { dataLayer.push(arguments); } 
    gtag('js', new Date()); 
    gtag('config', '${id}');     
  } catch (e) { }`;
  return script;
};
