import { BannerCarouselDetailedItem } from 'components/Home/BannerProductCarousel/components/BannerCarouselDetailedItem';
import {
  BannerCarouselDetailedItem as BannerCarouselDetailedItemType,
  BannerProductCarouselContent,
} from 'components/Home/BannerProductCarousel/types';
import { BannerProductCarouselSkeleton } from 'components/Home/BannerProductCarousel/components/Skeleton';
import { Box, Link, SxProps } from '@mui/material';
import { CarouselContainer } from 'components/Home/BannerProductCarousel/styles';
import { ComponentProps } from 'components/Home/types';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import { WidgetWrapper } from 'components/Home/common/styled';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { settings } from 'components/Home/BannerProductCarousel/settings';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useIsBetweenMdnLg } from 'utils/hidden/use-is-between-md-lg';
import { useIsUpLg } from 'utils/hidden/use-is-up-lg';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC, useMemo } from 'react';
import Slider from 'react-slick';

const useSlideToShow = () => {
  const isDesktop = useIsUpLg();
  const isTablet = useIsBetweenMdnLg();

  return useMemo(() => {
    if (isDesktop) return 5.5;
    if (isTablet) return 4.5;
    return 2.5;
  }, [isDesktop, isTablet]);
};

export const BannerProductCarousel: FC<ComponentProps> = ({
  type,
  id,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const bannerContent = useResponsiveContent<BannerProductCarouselContent>(
    componentData,
    'md',
  );
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const isPlpPageActive = useSelector(plpGetComponents);

  const slidesToShow = useSlideToShow();
  const sliderSettings = useMemo(
    () => ({ slidesToShow, ...settings }),
    [slidesToShow],
  );
  const initialSlides =
    !!bannerContent.items && bannerContent.items.length > 0
      ? bannerContent.items.slice(0, sliderSettings.slidesToShow)
      : [];
  const { imagesPreloaded } = useImagePreloader(initialSlides);

  if (!imagesPreloaded) {
    return <BannerProductCarouselSkeleton />;
  }

  if (
    !bannerContent.available ||
    !bannerContent.items ||
    bannerContent.items.length === 0
  ) {
    return null;
  }

  const handleClick = () => {
    segment.bannerClicked(
      checkForFrench(bannerContent.url, bannerContent.frUrl),
      'Banner With Product List',
      'Home Page Banner with Product List Carousel',
      userSession,
      userAccountInformation,
    );

    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: '0',
      module_type: 'Hybrid',
      module_name: name,
      link: name,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  const handleAnalyticsClick = (position: number, link: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: position.toString(),
      module_type: 'Hybrid',
      module_name: name,
      link,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  const imageContainer: SxProps = {
    width: '100%',
    height: ['264px', '264px', '320px'],
    maxWidth: ['100%', '768px', '100%'],
    backgroundImage: `url(${checkForFrench(
      bannerContent.src,
      bannerContent.frSrc,
    )})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: { xs: '20px', md: '40px', lg: '40px' },
  };

  return (
    <RenderWhenInView initialView={sequence <= 1}>
      <WidgetWrapper
        id={getId(type, id)}
        data-test="banner-product-carousel-detailed-container"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={imageContainer}
            component={Link}
            href={checkForFrench(bannerContent.url, bannerContent.frUrl)}
            onClick={handleClick}
            aria-label={checkForFrench(
              bannerContent.altText,
              bannerContent.frAltText,
            )}
            data-test="banner-widget-image-with-product-list"
          />
        </Box>

        <CarouselContainer data-test="banner-product-carousel-detailed">
          <Slider {...sliderSettings}>
            {bannerContent.items.map(
              (carouselItem: BannerCarouselDetailedItemType, index: number) => (
                <BannerCarouselDetailedItem
                  handleAnalyticsClick={handleAnalyticsClick}
                  key={carouselItem.id}
                  item={carouselItem}
                  data-test="banner-product-carousel-detailed-slide"
                  sequence={index + 1}
                />
              ),
            )}
          </Slider>
        </CarouselContainer>
      </WidgetWrapper>
    </RenderWhenInView>
  );
};
