import { DYContextType } from 'service/dynamic-yield/dy-types';
import { useEffect } from 'react';

export const setDYContext = (type: DYContextType, data?: string[]): void => {
  window.DY = window.DY || {};
  window.DY.recommendationContext = {
    type,
    data,
  };
};

export const useDYContext = (type: DYContextType, data?: string[]): void => {
  useEffect(() => {
    setDYContext(type, data);
  }, []);
};
