import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/translations/suggested-dates.locale.constants';

export const suggestedDatesCommonLocale = {
  [TK.NO_APPROPRIATE_DATE]: 'Not seeing the date you’d like?',
  [TK.EXPLORE_AVAILABLE_PRODUCTS]:
    'Let’s explore available products on your selected date.',
  [TK.SEE_AVAILABLE_PRODUCTS]: 'See Available Products',
  [TK.FLEX_FREE]: 'FREE*',
  [TK.FLEX_NEXT_DAY_PRICE]: '$5.99*',
  [TK.FLEX_DATES_AVAILABLE]: 'Flex Dates Available',
  [TK.FLEX_DELIVERY_DATES]: 'Flexible with Delivery Date?',
  [TK.FLEX_DELIVERY_DATES_SUBTITLE]:
    'We offer free delivery for most next day and beyond delivery orders.',
  [TK.FLEX_DATES_AVAILABLE_SUBTITLE]:
    'We’ve found additional delivery dates you might be interested in. Happy with your selected date? Just skip this selection.',
  [TK.CHOOSE_ANOTHER_DATE]: 'Select another date for delivery',
  [TK.DISCLAIMER_MESSAGE]:
    '*Additional surcharges may incur for Delivery that exceeds 15 miles and/or for Same Day or 1 Hour Delivery orders based on the time zone of the store receiving the order. See Terms of Use for more details',
};
