import * as TK from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/translations/constants';
import { AddressValidationDecision } from 'service/adobe-analytics/enums';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dialog } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styled';
import { OriginalAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/OriginalAddress';
import { Props } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/props';
import {
  actions,
  dialogWrapper,
  keepOriginalAddressButton,
  updateButton,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/Dialog/styles';
import { analytics } from 'service/adobe-analytics/analytics';
import { getAddress } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

export const UnknownDialogPart: FC<Props> = ({
  open = false,
  onReject,
  onSelectOriginalAddress,
  isShipmentOrder,
}) => {
  const address = useSelector(getAddress);

  useEffect(() => {
    if (address && open) {
      analytics.cartPageModalLoad('address validation');
    }
  }, [address, open]);

  if (!address) {
    return null;
  }

  const handleModifyAddress = () => {
    if (onReject) onReject();
    analytics.addressValidationSelection(
      AddressValidationDecision.ModifyOriginalAddress,
    );
  };

  const handleKeepOriginalAddress = () => {
    if (onSelectOriginalAddress) onSelectOriginalAddress();
    analytics.addressValidationSelection(
      AddressValidationDecision.ContinueWithOriginalAddress,
    );
  };

  return (
    <Dialog sx={dialogWrapper} onClose={handleModifyAddress} open={open}>
      <DialogTitle>{i18next.t(TK.SA_UNKNOWN_TITLE)}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2.5}>
          {i18next.t(TK.SA_UNKNOWN_CONTENT)}
        </Typography>
        <Box width={1} display="flex" justifyContent="center">
          <OriginalAddress />
        </Box>
      </DialogContent>
      <DialogActions sx={actions}>
        <Button
          onClick={handleModifyAddress}
          sx={updateButton}
          variant="outlined"
          size="large"
          color="primary"
          data-test="pdp-recipient-update-original-address-button"
        >
          {i18next.t(TK.SA_UNKNOWN_CONFIRM)}
        </Button>
        {!isShipmentOrder && (
          <Button
            onClick={handleKeepOriginalAddress}
            sx={keepOriginalAddressButton}
            variant="contained"
            size="large"
            color="primary"
            data-test="pdp-recipient-keep-original-address-button"
          >
            {i18next.t(TK.SA_KEEP_ADDRESS)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
