/*
Follow this https://gist.github.com/scokmen/f813c904ef79022e84ab2409574d1b45
while adding new HTTP status code to this enum
*/
export enum HttpStatusCode {
  OK = 200,
  GRACEFULLY_NOT_FOUND = 214,
  GRACEFULLY_FORBIDDEN = 213,
  GRACEFULLY_BAD_REQUEST = 210,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}
