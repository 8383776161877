import { OrderType } from 'types/cart.types';
import { Pas } from 'containers/PDPContainer/feature/pas/types/pas.interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SerializablePas } from 'containers/PDPContainer/feature/pas/types/serializable-pas.interface';
import { serialize } from 'containers/PDPContainer/feature/pas/utils/serialize';

const initialState: SerializablePas = {
  isOpened: false,
  area: null,
  date: null,
  orderType: OrderType.NotSpecified,
  store: null,
};

const slice = createSlice({
  name: 'pdp/pas',
  initialState,
  reducers: {
    /** @notice For legacy modal */
    openAndClear: () => ({
      ...initialState,
      isOpened: true,
    }),
    /** @notice For legacy modal */
    close: (state) => {
      state.isOpened = false;
    },
    set: (state, { payload }: PayloadAction<Partial<SerializablePas>>) => ({
      ...state,
      ...payload,
    }),
    clearAndSet: (
      state,
      { payload }: PayloadAction<Partial<SerializablePas>>,
    ) => ({
      ...initialState,
      isOpened: true,
      ...payload,
    }),
    reset: () => initialState,
  },
});

const { set, clearAndSet, reset } = slice.actions;

export const pas = {
  ...slice.actions,
  set: (payload: Partial<Pas>): ReturnType<typeof set> =>
    set(serialize(payload)),
  clearAndSet: (payload: Partial<Pas>): ReturnType<typeof set> =>
    clearAndSet(serialize(payload)),
  reset: (): ReturnType<typeof reset> => reset(),
};

export const pasReducer = slice.reducer;
