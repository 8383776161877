import {
  FPEB_DESCRIPTION,
  FPEB_HEADER,
  FPEB_REFRESH,
} from 'components/Error/FullPageErrorBoundaries/translations/constants';

export const fullPageErrorBoundariesCommonLocale = {
  [FPEB_HEADER]: 'An unexpected error occurred.',
  [FPEB_DESCRIPTION]: 'Please refresh the page and try again',
  [FPEB_REFRESH]: 'Refresh',
};
