import { Status } from 'enums/status';
import { State as StoreState } from 'redux/maintenance/maintenance.types';
import { createSlice } from '@reduxjs/toolkit';
import { getStates } from 'redux/maintenance/get-states.action';
import { logEmail } from 'redux/maintenance/log-email.action';

interface State {
  status: Status;
  stateStatus: Status;
  sent: boolean;
  states: StoreState[];
}

const initialState: State = {
  status: Status.IDLE,
  stateStatus: Status.IDLE,
  sent: false,
  states: [],
};

const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(logEmail.pending, (state) => {
        state.status = Status.IN_PROGRESS;
      })
      .addCase(logEmail.rejected, (state) => {
        state.status = Status.ERROR;
        state.sent = true;
      })
      .addCase(logEmail.fulfilled, (state, { payload }) => {
        state.sent = payload;
        state.status = Status.SUCCESS;
      })
      .addCase(getStates.pending, (state) => {
        state.stateStatus = Status.IN_PROGRESS;
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.states = payload;
        state.stateStatus = Status.SUCCESS;
      })
      .addCase(getStates.rejected, (state) => {
        state.stateStatus = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const maintenanceReducer = slice.reducer;
export const { reset } = slice.actions;
