import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { isNotEmpty } from 'utils/array/size';

const isAvailable = ({ available }: OrderTypeAvailability) => available;
const hasSuggestedDates = ({ dates }: OrderTypeAvailability) =>
  isNotEmpty(dates);
const hasSuggestedProducts = ({
  arrangementsAvailable,
}: OrderTypeAvailability) => arrangementsAvailable;

export const getAvailableMethods = ({
  pickup,
  delivery,
  shipment,
}: AvailabilityOnDate): FulfillmentOptionType[] => {
  const availabilityChecks = [
    isAvailable,
    hasSuggestedDates,
    hasSuggestedProducts,
  ];

  const pickupMethod =
    (pickup.dates.length > 0 && !pickup.available) || pickup.available
      ? OrderType.Pickup
      : null;

  const deliveryMethod = availabilityChecks.reduce(
    (orderType: FulfillmentOptionType | null, check) => {
      if (orderType) {
        return orderType;
      }

      if (check(delivery)) {
        return OrderType.Delivery;
      }

      if (check(shipment)) {
        return OrderType.Shipment;
      }

      return null;
    },
    null,
  );

  return [pickupMethod, deliveryMethod].filter(
    (m): m is FulfillmentOptionType => Boolean(m),
  );
};
