import { ArrowButtonType } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/components/ArrowButton/arrow-button-type.enum';
import { Button, IconButton, Typography } from '@mui/material';
import { ShowOnlyXs } from 'utils/hidden/show-only-xs';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { FC } from 'react';

/**
 * ArrowButton that is used as a custom component for CalendarPicker slot.
 * It is needed to add month names in left and right arrow buttons in CalendarPicker
 * @param type - LEFT or RIGHT arrow button type
 * @param text - month name
 * @param disabled - handled by CalendarPicker, disables arrow button
 * @param onClick - handled by CalendarPicker, switching month dates view internally
 * @param onClickAction - custom action to be performed along with default onClick
 */
interface ArrowButtonProps {
  type: ArrowButtonType;
  text?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAction: () => void;
}

export const ArrowButton: FC<ArrowButtonProps> = ({
  type,
  disabled,
  text,
  onClick,
  onClickAction,
}) => {
  const onClickCallback = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onClick?.(e);
    onClickAction();
  };

  return (
    <>
      <ShowOnlyXs>
        {type === ArrowButtonType.LEFT && (
          <IconButton
            color="secondary"
            disabled={disabled}
            onClick={onClickCallback}
            data-test="pdp-pas-date-picker-prev-month"
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        {type === ArrowButtonType.RIGHT && (
          <IconButton
            color="secondary"
            disabled={disabled}
            onClick={onClickCallback}
            data-test="pdp-pas-date-picker-next-month"
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </ShowOnlyXs>
      <ShowUpSm>
        <Button
          disabled={disabled}
          onClick={onClickCallback}
          variant="text"
          color="secondary"
          size="medium"
          data-test={`pdp-pas-date-picker-${
            type === ArrowButtonType.LEFT ? 'prev' : 'next'
          }-month`}
          startIcon={type === ArrowButtonType.LEFT && <ChevronLeftIcon />}
          endIcon={type === ArrowButtonType.RIGHT && <ChevronRightIcon />}
        >
          <Typography
            fontWeight={700}
            marginLeft={type === ArrowButtonType.LEFT ? 0 : 1}
            marginRight={type === ArrowButtonType.RIGHT ? 0 : 1}
          >
            {text}
          </Typography>
        </Button>
      </ShowUpSm>
    </>
  );
};
