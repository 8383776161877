import { selectIsCallCenterModeDisabled } from 'components/PLP/CallCenter/feature/selectors';
import { useSelector } from 'react-redux';
import React, { lazy, memo } from 'react';

const CallCenterDialog = lazy(
  () => import('components/PLP/CallCenter/CallCenterDialog'),
);

export const CallCenter = memo((): JSX.Element | null => {
  const isCallCenterModeDisabled = useSelector(selectIsCallCenterModeDisabled);
  const checkCallCenterDialog =
    sessionStorage.getItem('isCallCenterDialog') || '';

  if (isCallCenterModeDisabled) {
    return null;
  }

  return checkCallCenterDialog ? null : <CallCenterDialog />;
});
