import { isNotUndefined } from 'utils/is-not-undefined';

export enum Environment {
  PRODUCTION = 'production',
  LOCAL = 'local',
  QA = 'qa',
  DEVELOPMENT = 'development',
}

export const getEnvironments = (): Environment[] => [
  Environment.PRODUCTION,
  Environment.LOCAL,
  Environment.QA,
  Environment.DEVELOPMENT,
];

function isEnvironment<T extends Environment>(
  env: Environment | T | undefined,
): env is Environment {
  return isNotUndefined(env) && getEnvironments().includes(env);
}

export const getCurrentEnvironment = (): Environment =>
  isEnvironment(window.ENVIRONMENT)
    ? window.ENVIRONMENT
    : Environment.PRODUCTION;

const isProduction = (
  environment: Environment,
): environment is Environment.PRODUCTION =>
  environment === Environment.PRODUCTION;

const isLocal = (environment: Environment): environment is Environment.LOCAL =>
  environment === Environment.LOCAL;

export const isInProduction = (): boolean =>
  isProduction(getCurrentEnvironment());

export const isInLocal = (): boolean => isLocal(getCurrentEnvironment());
