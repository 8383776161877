import { Helmet } from 'react-helmet-async';
import { PDP_MAIN_META_KEYWORDS } from 'components/PDP/Arrangement/translations/constants';
import { TITLE } from 'components/common/ArrangementGroup/HeadMeta/translations/constants';
import { getData } from 'components/PDP/Arrangement/feature/selector';
import { sanitize } from 'utils/sanitize';
import { useArrangementStructuredData } from 'hooks/meta/use-arrangement-structured-data';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const HeadMeta: FC = () => {
  const arrangement = useSelector(getData);
  const structuredData = useArrangementStructuredData(
    arrangement.catalogNumber ? arrangement.catalogNumber.toString() : '',
  );

  return (
    <Helmet>
      <title>{sanitize(arrangement.pageTitle) || i18next.t(TITLE)}</title>
      <meta
        name="keywords"
        content={`${arrangement.name} ${i18next.t(PDP_MAIN_META_KEYWORDS)}`}
      />
      <meta name="title" content={arrangement.name} />
      <meta name="description" content={arrangement.metaTagDescription} />
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};
