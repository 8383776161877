import * as styles from 'components/Header/Menus/LanguageMenu/styles';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ButtonContent } from 'components/Header/Menus/LanguageMenu/ButtonContent';
import { changeLocale } from 'redux/locale/locale.actions';
import { languages } from 'redux/locale/locale.slice';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks/use-locale';
import { useMenu } from 'hooks/useMenu';
import Cookies from 'js-cookie';
import React, { FC } from 'react';

type Props = {
  isFull?: boolean;
};
function setLocaleCookie(locale: string) {
  Cookies.set('locale', locale);
}

export const LanguageMenu: FC<Props> = ({ isFull = false }) => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const dispatch = useDispatch();
  const { locale } = useLocale();
  const activeLanguage = languages[locale];

  const handleClickLanguage = (language: string) => {
    handleClose();
    dispatch(changeLocale({ locale: language }));
    setLocaleCookie(language);
  };

  return (
    <Box sx={styles.wrapper}>
      <Button
        color="secondary"
        id="language-menu-button"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={styles.button}
      >
        <ButtonContent
          value={isFull ? activeLanguage.definition : activeLanguage.acronym}
          icon={activeLanguage.icon}
        />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-menu-button',
        }}
        anchorOrigin={
          isFull
            ? {
                vertical: 'top',
                horizontal: 'left',
              }
            : {
                vertical: 'bottom',
                horizontal: 'left',
              }
        }
        transformOrigin={
          isFull
            ? {
                vertical: 'bottom',
                horizontal: 'left',
              }
            : {
                vertical: 'top',
                horizontal: 'left',
              }
        }
      >
        {Object.keys(languages).map((language) => {
          const languagesItem = languages[language];
          return (
            <MenuItem
              key={language}
              onClick={() => handleClickLanguage(language)}
              disabled={language === locale}
            >
              <ButtonContent value={languagesItem.definition} />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
