export type Item = {
  id: number;
  name: string;
  url: string;
  newTab: boolean;
  externalLink: boolean;
  hideOnMobile: boolean;
  megaMenuAssociation: number;
  sequence: number;
  mobileSequence: number | null;
  categories: Category[];
  forWeb: boolean;
  forMobile: boolean;
  color: string;
};

export type Category = {
  id: number;
  name: string;
  filters: Filter[];
  forWeb: boolean;
  forMobile: boolean;
};

export type Filter = {
  id: number;
  name: string;
  url: string;
};

/** @deprecated use 'redux/types/state.interface/status' */
export enum Status {
  FETCHING = 'fetching',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type HeaderState = {
  data: Item[];
  status: Status | null;
};
