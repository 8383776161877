import * as styles from 'components/Subscription/SubscriptionSection/SubscriptionSuccessDialog/styles';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SwipeableDrawer,
} from '@mui/material';
import { CLOSE } from 'translations/locales/translation-keys.constant';
import { SubmitSuccessStep } from 'components/Subscription/EmailSubscriptionDialog/SubmitSuccessStep';
import { useIsUpSm } from 'utils/hidden/hide-up-sm';
import Close from '@mui/icons-material/Close';
import React, { FC } from 'react';
import i18next from 'i18next';

interface SubscriptionSuccessDialogProps {
  isOpen: boolean;
  toggleOpenState: () => void;
}

const drawerBleeding = 56;
export const SubscriptionSuccessDialog: FC<SubscriptionSuccessDialogProps> = ({
  isOpen,
  toggleOpenState,
}) => {
  const content = () => <SubmitSuccessStep />;
  const isSm = useIsUpSm();

  return (
    <>
      {!isSm ? (
        <SwipeableDrawer
          anchor="bottom"
          open={isOpen}
          onClose={() => toggleOpenState()}
          onOpen={() => toggleOpenState()}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: styles.drawContent,
          }}
        >
          <Box>{content()}</Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={isOpen}
          sx={styles.dialog}
          PaperProps={{ sx: styles.windowWrappercon }}
        >
          <DialogTitle sx={styles.title}>
            <IconButton
              sx={styles.closeBtn}
              onClick={() => toggleOpenState()}
              color="secondary"
              size="small"
              aria-label={i18next.t(CLOSE)}
            >
              <Close fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={styles.content}>{content()}</DialogContent>
        </Dialog>
      )}
    </>
  );
};
