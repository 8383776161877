import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  text?: string;
  isOpen?: boolean;
  title?: string;
}

const initialState: State = { text: '', isOpen: false, title: '' };

const slice = createSlice({
  name: 'app/main-alert',
  initialState,
  reducers: {
    handleAlertState: (state, { payload }: PayloadAction<State>) => {
      state.text = payload.text;
      state.title = payload.title;
      state.isOpen = payload.isOpen;
    },
  },
});

export const mainAlertReducer = slice.reducer;
export const { handleAlertState } = slice.actions;
