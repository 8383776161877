import * as styles from 'components/Home/Banner/styles';
import { Box } from '@mui/material';
import { InlineGiftFinder } from 'components/GiftFinder';
import React, { FC } from 'react';

interface Props {
  analyticsClickHandler?: () => void;
}

export const BannerGiftFinder: FC<Props> = ({ analyticsClickHandler }) => (
  <Box sx={styles.wrapper}>
    <InlineGiftFinder analyticsClickHandler={analyticsClickHandler} />
  </Box>
);
