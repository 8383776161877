import * as TK from 'components/Cart/Details/translations/locale.constant';
import * as select from 'components/Cart/feature/selectors';
import * as styles from 'components/SkinnyBannerV2/styles';
import { Box } from '@mui/material';
import {
  CarouselContainer,
  CarouselWrapper,
} from 'components/SkinnyBannerV2/styles';
import { CarouselItem } from 'components/SkinnyBannerV2/components/CarouselItem/index';
import { Device } from 'components/SkinnyBannerV2/enum/device.enum';
import { Links } from 'enums/common-links';
import {
  SkinnyBannerDeviceContent,
  SkinnyBannerItem,
  skinnyBannerApiService,
} from 'api/skinny-banner.api.service';
import { SkinnyBannerSkeleton } from 'components/SkinnyBannerV2/components/Skeleton';
import { SkinnyBanners } from 'components/Header/Banner/SkinnyBanners';
import { analytics } from 'service/adobe-analytics/analytics';
import { colors } from 'utils/theme/colors';
import { settings } from 'components/SkinnyBannerV2/settings';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

interface Props {
  deviceName: string;
}

export const SkinnyBannerV2: FC<Props> = ({ deviceName }) => {
  const [skinnyBannerData, setSkinnyBannerData] =
    useState<SkinnyBannerDeviceContent>();
  const [isLoading, setIsLoading] = useState(true);

  const [isActiveNewBanner, setIsActiveNewBanner] = useState(false);
  const [skinnyBannerName, setSkinnyBannerName] = useState('');
  const { leftAmount, orderTotal } = useSelector(
    select.selectFreeDeliveryDetails,
  );
  const defaultSkinnyBannerDeviceContent: SkinnyBannerDeviceContent = {
    available: false,
    autoSlideInterval: 0,
    bannerBackgroundColor: colors.primaryRed,
    items: [],
  };
  const isAnyDeliveryAndNextDay = sessionStorage.getItem(
    'isAnyDeliveryAndNextDay',
  );
  const CartMerchandiseTotal = Number(
    sessionStorage.getItem('CartMerchandiseTotal'),
  );

  const isUnlockFree =
    CartMerchandiseTotal > 0 && isAnyDeliveryAndNextDay === 'true';
  // Override the default values
  const skinnyorderTotalBannerDeviceContent: SkinnyBannerDeviceContent = {
    ...defaultSkinnyBannerDeviceContent,
    available: true,
    autoSlideInterval: 5000,
    bannerBackgroundColor: colors.primaryRed,
    items: [
      {
        url: Links.FruitArrangements,
        editorContent: `<p style="color: ${colors.white};">${
          leftAmount > 0
            ? i18next.t(TK.FREE_DELIVERY_REMAINING, {
                amount: leftAmount.toFixed(2),
              })
            : i18next.t(TK.FREE_DELIVERY_COMPLETED)
        }</p>`,
        sequence: 1,
        bannerBackgroundColor: colors.white,
      },
    ],
  };
  useEffect(() => {
    const getSkinnyBannerContent = async () => {
      if (isUnlockFree) {
        setSkinnyBannerData(skinnyorderTotalBannerDeviceContent);
        setIsActiveNewBanner(true);
        setIsLoading(false);
        return;
      }
      const { data } = await skinnyBannerApiService.getSkinnyBanner(
        window.location.search,
      );
      if (data) {
        setSkinnyBannerName(data?.content[0].eventName);
        if (deviceName === Device.desktop) {
          setSkinnyBannerData(data.content[0].content.desktop);
          setIsActiveNewBanner(
            data.newSkinnyBanner && data.content[0].content.desktop.available,
          );
        }
        if (deviceName === Device.mobile) {
          setSkinnyBannerData(data.content[0].content.mobile);
          setIsActiveNewBanner(
            data.newSkinnyBanner && data.content[0].content.mobile.available,
          );
        }
      }
      setIsLoading(false);
    };

    getSkinnyBannerContent().catch(() => {
      setIsLoading(false);
    });
  }, [deviceName, isUnlockFree, orderTotal, CartMerchandiseTotal]);

  const handleClickAnalytics = (position: number, skinnyBannerText: string) => {
    analytics.skinnyBannerClick(skinnyBannerName, position, skinnyBannerText);
  };

  return (
    <>
      {isActiveNewBanner && skinnyBannerData && skinnyBannerData.available && (
        <CarouselWrapper
          data-test="carousel-detailed-container"
          style={{
            backgroundColor:
              skinnyBannerData?.bannerBackgroundColor ||
              skinnyBannerData?.items[0]?.bannerBackgroundColor,
          }}
        >
          <CarouselContainer data-test="carousel-detailed">
            <Box sx={styles.CarouselContainerSx}>
              <Slider
                {...settings}
                autoplaySpeed={skinnyBannerData.autoSlideInterval}
              >
                {skinnyBannerData.items.map(
                  (skinnyBannerItem: SkinnyBannerItem, index) => (
                    <CarouselItem
                      onClickAnalytics={() =>
                        handleClickAnalytics(
                          index + 1,
                          skinnyBannerItem.editorContent,
                        )
                      }
                      key={skinnyBannerItem.url}
                      item={skinnyBannerItem}
                      data-test="carousel-detailed-slide"
                    />
                  ),
                )}
              </Slider>
            </Box>
          </CarouselContainer>
        </CarouselWrapper>
      )}
      {!isLoading && !isActiveNewBanner && <SkinnyBanners />}
      {isLoading && <SkinnyBannerSkeleton />}
    </>
  );
};
