import * as TK from 'components/Session/Alert/translations/locale.constants';
import {
  DATE_FORMAT,
  SHORT_DATE_FORMAT,
} from 'components/Session/Alert/constants';
import { OrderType } from 'types/cart.types';
import { format } from 'date-fns';
import { isNull } from 'utils/null.utils';
import { isPickup } from 'utils/is-order-type';
import {
  selectSessionAreaName,
  selectSessionOrderType,
  selectSessionSelectedDate,
  selectSessionStoreName,
} from 'redux/session/availability/availability.selectors';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

const getKeyByOrderType = (
  orderType: OrderType,
  { displayPickupStore }: { displayPickupStore?: boolean },
) => {
  if (isPickup(orderType)) {
    return displayPickupStore
      ? TK.SL_PICKUP_STORE_TITLE
      : TK.SL_PICKUP_ZIP_TITLE;
  }
  return TK.SL_DELIVERY_TITLE;
};

export const useTitle = (options?: {
  shortDate?: boolean;
  displayPickupStore?: boolean;
}): string => {
  const orderType = useSelector(selectSessionOrderType);
  const selectedDate = useSelector(selectSessionSelectedDate);
  const areaName = useSelector(selectSessionAreaName);
  const storeName = useSelector(selectSessionStoreName);

  if (isNull(selectedDate)) {
    return '';
  }

  return i18next.t(
    getKeyByOrderType(orderType, {
      displayPickupStore: Boolean(options?.displayPickupStore && storeName),
    }),
    {
      date: format(
        selectedDate,
        options?.shortDate ? SHORT_DATE_FORMAT : DATE_FORMAT,
      ),
      zip: areaName,
      store: storeName,
    },
  );
};
