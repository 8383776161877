export enum LegacySignUpStatus {
  NONE = 'None',
  OK = 'OK',
  ERROR = 'ERROR',
  EMAIL_EXISTS = 'EMAILEXISTS',
  EXCEPTION = 'EXCEPTION',
}

export type ParsedSignUpResponse = {
  _Status: LegacySignUpStatus;
  _AccountID: string;
  _Message: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type ParsedErrorResponse = {
  _Status: LegacySignUpStatus.ERROR;
  _Message: string;
};

type SuccessStatus = LegacySignUpStatus.OK;

type AccountExistStatus = LegacySignUpStatus.EMAIL_EXISTS;

type ErrorStatus = LegacySignUpStatus.ERROR | LegacySignUpStatus.EMAIL_EXISTS;

export const isSuccess = (
  status: LegacySignUpStatus,
): status is SuccessStatus => [LegacySignUpStatus.OK].includes(status);

export const isError = (status: LegacySignUpStatus): status is ErrorStatus =>
  [LegacySignUpStatus.ERROR].includes(status);

export const isAlreadyExist = (
  status: LegacySignUpStatus,
): status is AccountExistStatus =>
  [LegacySignUpStatus.EMAIL_EXISTS].includes(status);
