import { Box, Slide, Typography } from '@mui/material';
import { ContinueButton } from 'components/PDP/Details/components/ContinueButton/ContinueButton';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { Quantity } from 'components/PDP/Details/components/ProductOptions/components/Quantity/Quantity';
import {
  bottomContinueBtn,
  bottomContinuePanel,
  bottomContinueWrapper,
} from 'components/PDP/Details/containers/BottomContinuePanel/bottom-continue-panel.styles';
import {
  selectArrangement,
  selectIsMarketplaceProduct,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface BottomContinuePanelProps {
  quantity: number;
  selectedVariantName: string;
  onContinue: (event?: React.MouseEvent) => void;
  isBlocked: boolean;
  reveal: boolean;
  selectedProduct?: Product;
  setQuantity?: (q: number) => void;
}

export const BottomContinuePanel: FC<BottomContinuePanelProps> = ({
  quantity,
  selectedVariantName,
  onContinue,
  isBlocked,
  reveal,
  ...props
}) => {
  const arrangement = useSelector(selectArrangement);
  const isMarketplace = useSelector(selectIsMarketplaceProduct);

  return (
    <Slide in={reveal} direction="up">
      <Box sx={bottomContinuePanel}>
        <Box
          sx={{
            ...bottomContinueWrapper,
            // when is marketplace change the panel layout
            justifyContent: isMarketplace ? 'center' : 'space-between',
            // also make the width 100% for all child elements
            '& > *': {
              width: isMarketplace ? '100% !important' : undefined,
            },
          }}
        >
          {isMarketplace &&
          props.selectedProduct &&
          quantity &&
          props.setQuantity ? (
            <Quantity
              quantity={quantity}
              selectedProduct={props.selectedProduct}
              setQuantity={props.setQuantity}
            />
          ) : (
            <div>
              <PureHtmlTypography variant="subtitle2" component="h3">
                {arrangement.name}
              </PureHtmlTypography>
              <Typography variant="body2">{`${quantity}x ${selectedVariantName}`}</Typography>
            </div>
          )}
          <Box sx={bottomContinueBtn}>
            <ContinueButton
              dataTest="pdp-details-bottom-panel-continue-button"
              onClick={onContinue}
              disabled={isBlocked}
            />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};
