import { InitialStateFactory } from 'redux/factories/initial-state.factory';
import { Recipient } from 'api/session/types/recipient.interface';
import { createExtraReducers } from 'redux/factories/create-extra-reducers';
import { createSlice } from '@reduxjs/toolkit';
import { fetchRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/actions';

const initialState = InitialStateFactory.create<Recipient[]>([]);

const slice = createSlice({
  initialState,
  name: 'session/recipients',
  reducers: {},
  extraReducers: createExtraReducers(initialState, fetchRecipients),
});

export const recipientsReducer = slice.reducer;
