import { SxProps } from '@mui/system';
import { Theme, alpha } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';

export const navContainer: SxProps = {
  position: 'relative',
  maxWidth: '1060px',
  mx: 'auto',
};

export const navItems: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  flexFlow: 'row wrap',
  rowGap: 1,
  columnGap: 3,
  justifyContent: 'center',
  maxWidth: '1060px',
  mx: 'auto',
  pb: 1,
};

export const navButton =
  (itemColor: string, isActive: boolean): SxProps<Theme> =>
  (theme) => ({
    color: itemColor,
    fontWeight: 600,
    background: isActive
      ? alpha(itemColor, theme.palette.action.hoverOpacity)
      : 'none',
    '&:hover': {
      background: alpha(itemColor, theme.palette.action.hoverOpacity),
    },
  });

export const skeleton: SxProps = {
  display: 'flex',
  width: '100%',
  maxWidth: '1060px',
  mx: 'auto',
  height: 'auto',
  pb: 1,
};

export const skeletonItem: SxProps = {
  width: '12.5%',
  px: 2,
  height: 30,
};

export const categories: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const categoriesContainer = (
  position: number,
  isMenuVisible: boolean | null,
): SxProps => ({
  display: isMenuVisible ? 'block' : 'none',
  position: 'static',
  '& .MuiBackdrop-root': {
    position: 'absolute',
    top: 'calc(100% + 1px)',
    bottom: '-100vh',
    left: '-50vw',
    right: '-50vw',
    zIndex: 12,
  },
  '.MuiPaper-root': {
    position: 'absolute',
    top: 'calc(100% + 1px)',
    boxShadow: 'none',
    pt: 1,
    maxHeight: `calc(100vh - ${position + 1}px)`,
    left: '-50vw',
    right: '-50vw',
  },
});

export const categoriesWrapper: SxProps = {
  display: 'flex',
  width: '1060px',
  justifyContent: 'center',
  my: 1,
  mx: 'auto',
};

export const categoriesTable: SxProps = {
  mt: 1,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
};

export const categoryWrapper: SxProps = {
  pl: 0,
  width: '25%',
  verticalAlign: 'initial',
  '& + $navItemCategory': {
    ml: 3,
  },
};

export const categoryLink: SxProps = {
  color: colors.black,
  textDecoration: 'none',
  cursor: 'pointer',
};

export const menuItemLink: SxProps = {
  mb: 1,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    color: colors.accentPrimary,
  },
};

export const viewAllButton: SxProps = {
  borderRadius: 0,
  py: 4,
  px: 2,
};

export const banner: SxProps = {
  ml: 5,
  mt: 2,
  mb: 5,
  display: 'flex',
  justifyContent: 'center',
};
