export enum ResetPasswordStatus {
  Ok = 'OK',
  NotFound = 'NotFound',
  Error = 'Error',
  Unexpected = 'Unexpected',
}

type ResetPasswordSuccess = { _Status: ResetPasswordStatus.Ok; _Data: boolean };

type ResetPasswordError = {
  _Status: ResetPasswordStatus.Error | ResetPasswordStatus.NotFound;
  _Data: string;
};

type ErrorStatus =
  | ResetPasswordStatus.Unexpected
  | ResetPasswordStatus.NotFound
  | ResetPasswordStatus.Error;

export type ResetPasswordParsedResponse =
  | ResetPasswordSuccess
  | ResetPasswordError;

export const isError = (status: ResetPasswordStatus): status is ErrorStatus =>
  [
    ResetPasswordStatus.Unexpected,
    ResetPasswordStatus.NotFound,
    ResetPasswordStatus.Error,
  ].includes(status);
