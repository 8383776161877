import { Contact } from 'api/contacts.api.service';
import { ResponseError } from 'types/error.types';
import { Status } from 'enums/status';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchContacts,
  fetchMoreContacts,
} from 'containers/AddressBook/feature/actions';

type ContactsState = {
  data: {
    contacts: Contact[];
    count: number;
    selectedContact: Contact | null;
  };
  fetchStatus: Status;
  fetchMoreStatus: Status;
  error: ResponseError | null;
};

const initialState: ContactsState = {
  data: {
    contacts: [],
    count: 0,
    selectedContact: null,
  },
  fetchStatus: Status.IDLE,
  fetchMoreStatus: Status.IDLE,
  error: null,
};

const slice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    resetSelectedContacts: (state) => {
      state.data.selectedContact = null;
    },
    resetContacts: (state) => {
      state.data.contacts = [];
    },
    selectContact: (state, { payload }) => {
      state.data.selectedContact =
        state.data.contacts.find((contact) => contact.id === payload) || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchContacts.fulfilled,
        (state, { payload: { data, count } }) => {
          state.data.contacts = data;
          state.data.count = count;
          state.fetchStatus = Status.SUCCESS;
          state.error = null;
        },
      )
      .addCase(fetchContacts.pending, (state) => {
        state.fetchStatus = Status.IN_PROGRESS;
        state.error = null;
      })
      .addCase(fetchContacts.rejected, (state, { payload }) => {
        state.fetchStatus = Status.ERROR;
        state.data.contacts = [];
        state.error = payload as ResponseError;
      })
      .addCase(
        fetchMoreContacts.fulfilled,
        (state, { payload: { data, count } }) => {
          state.data.contacts = [...state.data.contacts, ...data];
          state.data.count = count;
          state.fetchMoreStatus = Status.SUCCESS;
          state.error = null;
        },
      )
      .addCase(fetchMoreContacts.pending, (state) => {
        state.fetchMoreStatus = Status.IN_PROGRESS;
        state.error = null;
      })
      .addCase(fetchMoreContacts.rejected, (state, { payload }) => {
        state.fetchMoreStatus = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
});

export const addressBookReducer = slice.reducer;
export const { resetSelectedContacts } = slice.actions;
export const { resetContacts } = slice.actions;
export const { selectContact } = slice.actions;
