import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.locale.constants';
import { FulfillmentOptionType } from 'types/cart.types';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { Typography } from '@mui/material';
import { getRadioTitleLocaleKey } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/utils/get-radio-title-locale-key';
import { shouldShowSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-arrangements';
import { shouldShowSuggestedDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-dates';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  type: FulfillmentOptionType;
  availability: OrderTypeAvailability;
};

const getAdditionalTextTK = (availability: OrderTypeAvailability): string => {
  if (shouldShowSuggestedDates(availability)) {
    return TK.AVAILABLE_FOR_OTHER_DATES;
  }

  if (shouldShowSuggestedArrangements(availability)) {
    return TK.AVAILABLE_FOR_OTHER_PRODUCTS;
  }

  return '';
};

export const Title: FC<Props> = ({ type, availability }) => {
  const additionalTextTK = getAdditionalTextTK(availability);

  return (
    <Typography variant="body2" display="flex" flexDirection="row">
      {i18next.t(getRadioTitleLocaleKey(type))}
      {Boolean(additionalTextTK) && ` (${i18next.t(additionalTextTK)}) `}
    </Typography>
  );
};
