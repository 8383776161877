import * as styles from 'components/Cart/SuggestedProducts/components/CarouselItem/styles';
import { Box, Link, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { ImageSkeleton } from 'components/common/ArrangementItem/components/ImageSkeleton';
import { ItemImageWrapper } from 'components/common/ArrangementItem/styled';
import { Props } from 'components/Cart/SuggestedProducts/props/carousel-item';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addCurrency } from 'utils/add-currency';
import { analytics } from 'service/adobe-analytics/analytics';
import { colors } from 'utils/theme/colors';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { getSizeDescription } from 'utils/get-size-description';
import { updateAssetUrls } from 'utils/assets-url-builder';
import React, { FC } from 'react';

export const CarouselItem: FC<Props> = ({
  item: {
    alt,
    image,
    minPrice,
    name,
    productImageTag,
    nonPromo,
    nonPromoColor,
    promo,
    promoColor,
    sizeCount,
    url,
    isMinSizeOnSale,
    minsizeProductPrice,
  },
  position,
}) => (
  <Link
    underline="none"
    sx={styles.itemLink}
    href={getPdpPath(url)}
    data-test="carousel-detailed-item"
    color="secondary"
    onClick={() => analytics.productCarouselClicked(position)}
  >
    <ItemImageWrapper>
      <Image
        src={updateAssetUrls(image)}
        errorFallback={image}
        alt={alt}
        animationDuration={250}
        lazy
        observerMargins="0px 0px 400px"
        loading={<ImageSkeleton />}
        data-test="plp-arrangement-item-image"
      />
      {productImageTag && (
        <Typography
          sx={styles.productImageTagText}
          component="p"
          variant="subtitle3"
        >
          {productImageTag}
        </Typography>
      )}
    </ItemImageWrapper>
    {nonPromo && (
      <PureHtmlTypography sx={styles.promo(nonPromoColor)} variant="subtitle3">
        {nonPromo}
      </PureHtmlTypography>
    )}
    <PureHtmlTypography variant="subtitle2" sx={styles.title}>
      {name}
    </PureHtmlTypography>
    <PureHtmlTypography variant="body3" sx={styles.size}>
      {getSizeDescription(sizeCount)}
    </PureHtmlTypography>
    <Box style={{ display: 'flex' }}>
      {isMinSizeOnSale && minsizeProductPrice > 1 ? (
        <Typography
          variant="subtitle2"
          component="p"
          mt={0.5}
          data-test="plp-arrangement-item-price"
          sx={styles.regularPrice}
        >
          {addCurrency(minsizeProductPrice)}
        </Typography>
      ) : null}
      <Typography
        variant="subtitle2"
        mt={0.5}
        component="p"
        data-test="plp-arrangement-item-price"
        sx={styles.salePrice(
          isMinSizeOnSale && minsizeProductPrice > 1
            ? `${colors.salePriceColor}`
            : '',
        )}
      >
        {addCurrency(minPrice.toFixed(2))}
      </Typography>
    </Box>
    {promo && (
      <PureHtmlTypography sx={styles.promo(promoColor)} variant="subtitle3">
        {promo}
      </PureHtmlTypography>
    )}
  </Link>
);
