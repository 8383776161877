import { BannerContent } from 'components/Home/Banner/types';
import { BannerGiftFinder } from 'components/Home/Banner/components/banner-gift-finder';
import { BannerLink } from 'components/Home/Banner/components/banner-link';
import { BannerSkeleton } from 'components/Home/Banner/components/Skeleton';
import { ComponentProps } from 'components/Home/types';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import {
  addGiftFinderWidgetClickEventToCookies,
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Errors {
  mobile: boolean;
  desktop: boolean;
}

const initialErrors: Errors = {
  mobile: false,
  desktop: false,
};

export const Banner: FC<ComponentProps> = ({ type, id, sequence, name }) => {
  const [errors] = useState<Errors>(initialErrors);
  const componentData = useCmsWidgets(type, id);
  const bannerContent = useResponsiveContent<BannerContent>(
    componentData,
    'md',
  );
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isPlpPageActive = useSelector(plpGetComponents);

  const { imagesPreloaded } = useImagePreloader(bannerContent.items);

  const handleAnalyticsClick = (link?: string) => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: '0',
      module_type: type,
      module_name: name,
      link,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
    addGiftFinderWidgetClickEventToCookies(clickEvent);
  };

  if (!imagesPreloaded) {
    return <BannerSkeleton />;
  }

  const isError = isUpMd ? errors.desktop : errors.mobile;

  if (!bannerContent.available || isError) {
    return null;
  }

  return (
    <RenderWhenInView>
      <section id={getId(type, id)}>
        {bannerContent.items.map((bannerItem) => {
          if (bannerItem.hasGiftFinder) {
            return (
              <BannerGiftFinder
                key={bannerItem.src}
                analyticsClickHandler={() => handleAnalyticsClick('Find Gifts')}
              />
            );
          }

          return (
            <BannerLink
              key={bannerItem.src}
              bannerItemUrl={bannerItem.url}
              bannerItemSrc={bannerItem.src}
              bannerItemBody={checkForFrench(
                bannerItem.body,
                bannerItem.frBody,
              )}
              bannerItemAltText={bannerItem.altText}
              bannerBGColor={bannerItem.bannerBackgroundColor}
              analyticsClickHandler={handleAnalyticsClick}
            />
          );
        })}
      </section>
    </RenderWhenInView>
  );
};
