export type OrderConfirmation = {
  sale?: Sale;
  order?: Order[];
  orderItem?: OrderItem[];
  orderSubItem?: OrderSubItem[];
};

export type Sale = {
  SaleID: number;
  number: string;
  date: Date;
  total: number;
  customerName: string;
  address1: string;
  address2: string;
  workPhone: string;
  homePhone: string;
  cellPhone: string;
  area: string;
  email: string;
  city: string;
  state: string;
  stateAbbreviation: string;
  country: string;
  creditCardExpirationDate: Date;
  creditCardNumber: string;
  creditCardType: number;
  paymentMode: number;
  orderType: number;
  houseChargeAccountNumber: string;
  creditCardNameOn: string;
  referrerID: string;
  payPalOrderTransactionID: string;
  countryID: string;
  referenceID: string;
  paymentTransactionID: string;
};

export type Order = {
  saleID: number;
  orderID: number;
  number: string;
  fulfillmentDate: Date;
  addressType: string;
  cardMessage: string;
  orderType: number;
  CouponID: string;
  clearanceMode: number;
  recipientName: string;
  company: string;
  address1: string;
  address2: string;
  workPhone: string;
  homePhone: string;
  cellPhone: string;
  area: string;
  city: string;
  state: string;
  stateAbbreviation: string;
  email: string;
  storeNumber: number;
  storeType: number;
  formalName: string;
  storePhone: string;
  storePlaza: string;
  storeAddress1: string;
  storeAddress2: string;
  storeArea: string;
  storeCity: string;
  storeState: string;
  storeStateAbbreviation: string;
  deliveryOrShipmentCharge: number;
  orderDiscount: number;
  merchandiseTotal: number;
  merchandiseDiscount: number;
  deliveryOrShipmentChargeDiscount: number;
  fLEXDiscount: number;
  orderTotal: number;
  orderTax1: number;
  orderTax2: number;
  orderTax3: number;
  orderTax4: number;
  orderTax5: number;
  storeTaxRate1: number;
  storeTaxRate2: number;
  storeTaxRate3: number;
  storeTaxRate4: number;
  storeTaxRate5: number;
  storeTax1: string;
  storeTax2: string;
  storeTax3: string;
  storeTax4: string;
  storeTax5: string;
  flexOptionID: number;
  flexID: number;
  flexDecidedByStoreOnDate: Date;
  newFulfillmentFromDate: Date;
  newFulfillmentToDate: Date;
  couponCodeVisible: number;
  promoCodeVisible: number;
  couponCode: string;
  promotionCode: string;
  shippingMode: string;
  date: Date;
  pickup7Minute: boolean;
  deliveryInstruction: string;
  surcharge: number;
  surchargeID: number;
  surchargeDiscount: number;
  surchargeName: string;
  catering: boolean;
  isCurbSide: boolean;
  fulfillmentTimeFrom: string;
  fulfillmentTimeTo: string;
  storeConcept: number;
  showCardMessage: number;
  storeID: number;
  CurbSideInstruction: string;
  occasion: string;
};

export type OrderItem = {
  orderItemID: number;
  orderID: number;
  name: string;
  sizeName: string;
  productID: number;
  price: number;
  regularPrice?: number;
  quantity: number;
  discount: number;
  total: number;
  itemType: number;
  catalogCode: string;
  type: number;
  option: string;
  catalogNumber: number;
  image: string;
  productIDForCordial: string;
  imagePrefix: string;
  imageAvailable: boolean;
  sequence1: number;
  arrangementID: number;
  category: number;
  minServings: number;
  maxServings: number;
  buildOwnBox: boolean;
  CYOArrangement: boolean;
  categoryName: string;
  pageFriendlyURL: string;
  occassion: string;
};

export type OrderSubItem = {
  id: number;
  catalogNumber: number;
  orderID: number;
  parentOrderItemID: number;
  name: string;
  sizeName: string;
  price: number;
  quantity: number;
  discount: number;
  total: number;
  itemType: number;
  isContainer: number;
  catalogCode: string;
  type: number;
  image: string;
  productID: number;
  option: string;
  sleeve: boolean;
  addOnOptionImage: string;
  addOnOptionType: number;
  imagePrefix: string;
  addOnAltText: string;
  addOnOptionID: string;
  isCYOA: boolean;
};

export type OrderSummaryItem = {
  text: string;
  value: number;
  line: boolean;
  couponCodeStyle: boolean;
  orderType: number;
};

export enum ResendConfirmationStatus {
  OK = 'OK',
  Error = 'Error',
  NotFound = 'NotFound',
}

export type ResendConfirmationResponse = {
  _Status?: string;
  _Data?: string;
};
type ResendConfirmationSuccess = {
  _Status: ResendConfirmationStatus.OK;
  _Data: boolean;
};

type ResendConfirmationError = {
  _Status: ResendConfirmationStatus.Error;
  _Data: string;
};

export type ResendConfirmationParsedResponse =
  | ResendConfirmationSuccess
  | ResendConfirmationError;

export enum PaymentMode {
  NOTSPECIFIED = 0,
  CASH = 1,
  CREDITCARD = 2,
  HOUSECHARGE = 3,
  GIFTCERTIFICATE = 4,
  DEBITCARD = 5,
  EACONNECT = 6,
  EAENTERPRISE = 7,
  COMPLEMENTARY = 8,
  CHECK = 9,
  PAYPAL = 10,
  BML = 11,
  EFT = 12,
  BARTERTRADE = 13,
  AMAZONPAY = 14,
  AMAZONMARKETPLACE = 15,
  APPLEPAY = 16,
  OTHERS = 17,
  UNPAID = 18,
  VENMO = 19,
  GOOGLEPAY = 20,
  LINK = 21,
}

export enum CreditCardType {
  NOTSPECIFIED = 0,
  MASTER = 1,
  VISA = 2,
  AMEX = 3,
  DISCOVER = 4,
  MAESTRO = 5,
  SOLO = 6,
  SWITCH = 7,
  DELTA = 8,
  DINERSCLUB = 9,
  JCB = 10,
  PAYPAL = 11,
  BML = 12,
  AMAZONPAY = 13,
  APPLEPAY = 14,
  OTHERS = 15,
  VENMO = 16,
  GOOGLEPAY = 17,
  LINK = 18,
}

export enum OrderType {
  NOTSPECIFIED = 0,
  Delivery = 1,
  Pickup = 2,
  Shipment = 3,
  WalkIn = 4,
}

export type OrderExpressFulfillments = {
  id: number;

  cartRecipientId: number;

  orderId: number;

  orderNumber: string;

  type: number;

  expressFulfillmentType: ExpressFulFillmentsTypeEnum;

  expressFulFillmentSelected: boolean;

  expressFulfillmentFee: number;

  sbPushDate: Date;

  processed: number;

  createdOnDate: Date;

  updatedOnDate?: Date;
};

export enum ExpressFulFillmentsTypeEnum {
  Normal = 0,
  OneHourDelivery = 1,
  TwoHourDelivery = 2,
}
