import * as TK from 'components/Session/Alert/translations/locale.constants';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { lockIcon } from 'components/Session/Alert/styles';
import Lock from '@mui/icons-material/Lock';
import React, { memo } from 'react';
import i18next from 'i18next';

type Props = {
  dataTest: string;
  title: string;
  reset: () => void;
};

export const SlimLockAlert = memo<Props>(({ title, reset, dataTest }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography mr={2} variant="subtitle2" data-test={`${dataTest}-title`}>
        <Lock sx={lockIcon} />
        {title}
      </Typography>
      <Button
        data-test={`${dataTest}-reset-button`}
        onClick={reset}
        variant="text"
        color="secondary"
        size="small"
      >
        {i18next.t(TK.SL_RESET)}
      </Button>
    </Box>
  );
});
