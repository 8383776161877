export enum Source {
  edibleArrangements = 1,
  dippedFruit = 2,
  both = 3,
}

export enum CategoryType {
  edibleArrangements = 1,
  edibleForBusiness = 2,
}
