import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'translations/locales/translation-keys.constant';
import { Blog } from 'components/LocalSeoPage/BlogCarousel/types';
import { BlogWidget } from 'components/LocalSeoPage/BlogWidget/BlogWidget';
import { Box, Typography } from '@mui/material';
import { Slider } from 'components/LocalSeoPage/BlogWidget/styles';
import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { blogApiService } from 'api/blog.api.service';
import { blogCarouselClassNames } from 'components/LocalSeoPage/BlogCarousel/blogCarouselClassNames';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1225,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
  ],
};

const wrapper: ThemedSxProps = {
  '& .slick-initialized .slick-slide': {
    display: 'flex',
    justifyContent: 'center',
  },
};

type Props = {
  titleSx?: SxProps;
};

export const BlogCarousel: FC<Props> = ({ titleSx }) => {
  const [blogData, setBlogData] = useState<Blog[]>([]);
  const classes = blogCarouselClassNames;

  useEffect(() => {
    const fetchBlogData = async () => {
      const res = await blogApiService.getBlogPosts();
      setBlogData(res);
    };
    if (blogData.length <= 1) {
      fetchBlogData();
    }
  }, [blogData]);

  if (!blogData) return null;
  if (Object.keys(blogData).length === 0) return null;

  return (
    <Box mt={4} mb={8} sx={wrapper}>
      <Typography sx={titleSx} variant="h3" classes={classes.title}>
        {i18next.t(TK.BLOG_CAROUSEL_TITLE)}
      </Typography>
      <Slider {...settings}>
        {blogData.map(
          (blog) =>
            blog.blogHeadJson.og_image && (
              <BlogWidget
                key={blog.blogHeadJson.description}
                title={blog.blogHeadJson.title}
                link={blog.blogHeadJson.canonical}
                image={
                  blog.blogHeadJson.og_image[0].url ||
                  blog.blogHeadJson.twitter_image
                }
              />
            ),
        )}
      </Slider>
    </Box>
  );
};
