import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';

export const ourMissionContainer: SxProps = {
  margin: ['0', '0 auto;', '0 auto'],
  width: ['100%', '688px', '1060px'],
  paddingLeft: ['20px', 0, '30px'],
  paddingRight: ['20px', 0, '0'],
  paddingBottom: ['20px', 0, '0'],
  paddingTop: '30px',
};

export const ourMissionTitle: SxProps = {
  textTransform: ['uppercase', 'uppercase', 'capitalize'],
  fontSize: ['16px', '16px', '30px'],
  pt: 2.5,
  pb: [0, 0, 2.5],
  color: '#111',
  fontWeight: '500',
  textAlign: ['center', 'center', 'left'],
};

export const ourMissionSubTitleContainer: SxProps = {
  width: ['100%', '688px', '510px'],
  mr: [0, 0, 5],
  borderBottom: [
    `1px solid ${colors.gray}`,
    `1px solid ${colors.gray}`,
    'none',
  ],
};

export const ourMissionSubTitleStyle: SxProps = {
  fontSize: ['16px', '16px', '18px'],
  margin: '20px 0',
  textAlign: ['left', 'left', 'justify'],
};

export const doFruitContainer: SxProps = {
  border: 'solid 1px #9a9a9a',
  clear: 'both',
  margin: '0px 15px 100px 0px',
  padding: '10px',
};

export const doFruitButton: SxProps = {
  backgroundColor: '#f1f1f1',
  width: '145px',
  height: '48px',
  padding: '12px 0px',
  textAlign: 'center',
  mt: 8,
};

export const doFruitTextStyle: SxProps = {
  paddingRight: '10px',
  fontSize: '18px',
  color: '#333',
  fontWeight: '700',
  textShadow: '0px 1px 0px #FFF',
};

export const missionImage: SxProps = {
  width: '335px',
  height: '204px',
  marginLeft: '-2px',
  border: '1px solid #a9a9a9',
};

export const missionMobileImage: SxProps = {
  width: '100%',
  height: '250px',
  marginLeft: '-2px',
  border: '1px solid #a9a9a9',
};

export const missionTabletContainer: SxProps = {
  display: 'flex',
  width: '100%',
  pb: 4,
  borderBottom: `1px solid ${colors.gray}`,
};

export const missionMobileContainer: SxProps = {
  display: 'block',
  width: '100%',
  pb: 2.5,
  borderBottom: `1px solid ${colors.gray}`,
};
