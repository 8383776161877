import { Area } from 'api/area/types/area.interface';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { ErrorAlert } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/ErrorAlert';
import { FulfillmentOptionType } from 'types/cart.types';
import {
  SHOW_MORE,
  SHOW_MORE_DELIVERY_STORES,
} from 'translations/locales/translation-keys.constant';
import { Store } from 'components/PDP/Details/components/PasControl/components/StoreSelector/components/Store/Store';
import { Store as StoreType } from 'redux/types/store.types';
import { TransitionGroup } from 'react-transition-group';
import { appInsights } from 'utils/telemetry/app-insights';
import { getOrderTypeDataTestValue } from 'components/PDP/Details/components/PasControl/utils/get-order-type-data-test-value';
import { getOrderTypeKey } from 'components/PDP/Details/components/PasControl/utils/get-order-type-key';
import { getStoreRanking } from 'components/StoreDetails/feature/actions';
import { getTitle } from 'components/PDP/Details/components/PasControl/components/StoreSelector/translations/get-title';
import { isDelivery, isShipment } from 'utils/is-order-type';
import { isNegative } from 'utils/numbers';
import { isNull } from 'utils/null.utils';
import { segment } from 'service/segment/segment';
import {
  selectAreStoresUnavailable,
  selectIsFulfilled,
  selectIsPending,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import {
  useAutoSelect,
  useAutoSelectDelivery,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/hooks/use-autoselect';
import { useDispatch, useSelector } from 'react-redux';
import { useSkipMountEffect } from 'hooks/use-skip-mount-effect';
import { useStores } from 'components/PDP/Details/components/PasControl/components/StoreSelector/hooks/use-stores';
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18next from 'i18next';

interface Props {
  date: Date | null;
  orderType: FulfillmentOptionType;
  selectedStore: StoreType | null;
  productId: number | null;
  area: Area | null;
  arrangementId: number;
  disableAutoSelect: boolean;
  onSelect: (store: StoreType) => void;
  isNonFlexFeeDates: boolean;
  storeRef?: MutableRefObject<HTMLInputElement | null>;
  defaultFirstStoreAutoSelect: boolean;
}

export const StoreSelector: FC<Props> = ({
  area,
  date,
  orderType,
  selectedStore,
  productId,
  arrangementId,
  disableAutoSelect,
  isNonFlexFeeDates,
  onSelect,
  storeRef,
  defaultFirstStoreAutoSelect,
}) => {
  const DEFAULT_SHOW_COUNT = orderType === 1 ? 1 : 5;
  const SHOW_COUNT_INCREMENT = 5;

  const [showCount, setShowCount] = useState<number>(DEFAULT_SHOW_COUNT);
  const stores = useStores({
    orderType,
    date,
    productId,
    arrangementId,
    area,
  });
  const dispatch = useDispatch();
  const isFulfilled = useSelector(selectIsFulfilled);
  const isPending = useSelector(selectIsPending);
  const areUnavailable = useSelector(selectAreStoresUnavailable);
  const shouldAutoSelect = useAutoSelect(orderType);
  const shouldAutoSelectDelivery = useAutoSelectDelivery(orderType);
  const list = useMemo(() => stores.slice(0, showCount), [showCount, stores]);
  const storeIdsString = stores.map((lst) => lst.id).join(',');
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    if (isDelivery(orderType)) {
      if (storeIdsString.length > 0) {
        dispatch(getStoreRanking(storeIdsString));
      }
    }
  }, [orderType, storeIdsString]);

  const skipMountEffect =
    shouldAutoSelect &&
    !disableAutoSelect &&
    !isNonFlexFeeDates &&
    (isNull(selectedStore) || isNegative(selectedStore.id));

  const skipMountEffectDelivery =
    defaultFirstStoreAutoSelect && shouldAutoSelectDelivery;
  useSkipMountEffect(() => {
    if (skipMountEffect || skipMountEffectDelivery) {
      appInsights.trackEvent({
        name: 'PAS store autoselected',
        properties: {
          storeNumber: stores[0].number,
          orderType: getOrderTypeKey(orderType),
        },
      });
      onSelect(stores[0]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [skipMountEffect, skipMountEffectDelivery]);

  if (areUnavailable) {
    return <ErrorAlert />;
  }

  if (isFulfilled && isShipment(orderType)) {
    return null;
  }

  return (
    <>
      <Backdrop opened={isPending} displayLogo={false} />
      <Collapse in={isFulfilled}>
        <Typography mt={3} variant="subtitle1">
          {i18next.t(getTitle(orderType))}
        </Typography>
        {orderType === 1 ? (
          <Box
            mt={2}
            mb={1.5}
            data-test={getOrderTypeDataTestValue(orderType, 'stores-list')}
            ref={storeRef}
          >
            <TransitionGroup>
              {list.map((storeItem, index) => (
                <Collapse key={storeItem.id}>
                  <Store
                    selected={storeItem.id === selectedStore?.id}
                    store={storeItem}
                    setStore={() => {
                      appInsights.trackEvent({
                        name: 'PAS store selected',
                        properties: {
                          storeNumber: stores[0].number,
                          orderType: getOrderTypeKey(orderType),
                        },
                      });
                      onSelect(storeItem);
                      segment.fulfillmentStoreClicked(
                        storeItem,
                        index,
                        userSession,
                        userAccountInformation,
                      );
                    }}
                    date={date}
                    dataTest={getOrderTypeDataTestValue(
                      orderType,
                      `store-item-${index}`,
                    )}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            {showCount < stores.length && (
              <Button
                onClick={() => {
                  setShowCount((count) => count + SHOW_COUNT_INCREMENT);
                  segment.fulfillmentStoreExpanded(
                    userSession,
                    userAccountInformation,
                  );
                }}
                sx={{ mt: 2 }}
                variant="text"
                size="medium"
                color="primary"
                fullWidth
                data-test="pdp-pas-show-more-stores"
              >
                {i18next.t(SHOW_MORE_DELIVERY_STORES)}
              </Button>
            )}
          </Box>
        ) : (
          <Box
            mt={2}
            mb={1.5}
            data-test={getOrderTypeDataTestValue(orderType, 'stores-list')}
            ref={storeRef}
          >
            <TransitionGroup>
              {list.map((storeItem, index) => (
                <Collapse key={storeItem.id}>
                  <Store
                    selected={storeItem.id === selectedStore?.id}
                    store={storeItem}
                    setStore={() => {
                      appInsights.trackEvent({
                        name: 'PAS store selected',
                        properties: {
                          storeNumber: stores[0].number,
                          orderType: getOrderTypeKey(orderType),
                        },
                      });
                      onSelect(storeItem);
                    }}
                    date={date}
                    dataTest={getOrderTypeDataTestValue(
                      orderType,
                      `store-item-${index}`,
                    )}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            {showCount < stores.length && (
              <Button
                onClick={() =>
                  setShowCount((count) => count + SHOW_COUNT_INCREMENT)
                }
                sx={{ mt: 2 }}
                variant="text"
                size="medium"
                color="primary"
                fullWidth
                data-test="pdp-pas-show-more-stores"
              >
                {i18next.t(SHOW_MORE, {
                  count:
                    stores.length - showCount < SHOW_COUNT_INCREMENT
                      ? stores.length - showCount
                      : SHOW_COUNT_INCREMENT,
                })}
              </Button>
            )}
          </Box>
        )}
      </Collapse>
    </>
  );
};
