import * as TK from 'components/PDP/Details/components/Gallery/components/BigGallery/components/PreviewCarousel/translations/locale.constants';
import * as styles from 'components/PDP/Details/components/Gallery/components/BigGallery/components/PreviewCarousel/styles';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import { PREVIEW_SLIDERS_AMOUNT } from 'components/PDP/Details/components/Gallery/constants';
import { commonSliderProps } from 'components/PDP/Details/components/Gallery/props';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { ComponentProps, forwardRef } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

export const PreviewCarousel = forwardRef<
  Slider,
  ComponentProps<typeof Slider>
>(({ children, ...sliderProps }, ref) => (
  <Slider
    {...commonSliderProps}
    {...sliderProps}
    ref={ref}
    slidesToShow={PREVIEW_SLIDERS_AMOUNT}
    nextArrow={
      <Box>
        <IconButton
          sx={styles.galleryIconButton}
          aria-label={i18next.t(TK.NEXT)}
        >
          <KeyboardArrowRightIcon sx={styles.galleryIcon} fontSize="small" />
        </IconButton>
      </Box>
    }
    prevArrow={
      <Box>
        <IconButton
          sx={styles.galleryIconButton}
          aria-label={i18next.t(TK.PREVIOUS)}
        >
          <KeyboardArrowLeftIcon sx={styles.galleryIcon} fontSize="small" />
        </IconButton>
      </Box>
    }
    focusOnSelect
    centerMode
    infinite
    arrows
  >
    {children}
  </Slider>
));
