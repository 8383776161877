import * as TK from 'components/Rewards/translations/locale.constants';
import * as yup from 'yup';
import i18next from 'i18next';

const validChars = /^((?![<>]).)*$/g;

export const schema = yup.object({
  title: yup
    .string()
    .matches(validChars, i18next.t(TK.REMINDER_TITLE))
    .required(i18next.t(TK.REMINDER_TITLE)),
  occasionDate: yup
    .string()
    .required(i18next.t(TK.REMINDER_OCCASION_DATE_ERROR)),
  occasionId: yup
    .number()
    .min(1, i18next.t(TK.REMINDER_OCCASION_ERROR))
    .required(i18next.t(TK.REMINDER_OCCASION_ERROR)),
  advanceNotificationDays: yup
    .number()
    .required(i18next.t(TK.REMINDER_REMIND_ME_ERROR)),
});

export type ReminderFormValues = {
  id: number;
  accountId: number;
  title: string;
  occasionDate: Date;
  occasionId: number;
  advanceNotificationDays: number;
  recurring: boolean;
  note: string;
};

export type ReminderSearchFormValues = {
  searchText: string;
};

export type ReminderParams = {
  id?: number;
  accountId: number;
  title: string;
  occasionDate: Date;
  occasionId: number;
  advanceNotificationDays: number;
  recurring: boolean;
  note: string;
};
