import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { Review } from 'api/reviews/types/review.interface';
import { StructuredDataValues } from 'utils/seo/google/enums';
import { format } from 'date-fns';
import { getAverage } from 'utils/numbers';
import { isCanada } from 'utils/country';
import { isNotEmpty } from 'utils/array/size';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';
import { sanitize } from 'utils/sanitize';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type Location = { host: string; protocol: string; pathname: string };

const getStructuredReview = (review: Review) => ({
  '@type': 'Review',
  author: {
    '@type': 'Person',
    name: sanitize(review.authorName),
  },
  datePublished: review.createdAt,
  reviewBody: review.text,
  reviewRating: {
    '@type': 'Rating',
    bestRating: '5',
    ratingValue: review.rating.toString(),
    worstRating: '1',
  },
});

const generate = (
  { description, name, products, brands }: Arrangement,
  { host, protocol, pathname }: Location,
  reviews: Review[],
  catalogNumber: string,
): string => {
  const images = products.map(({ image }) => image);

  return JSON.stringify(
    pickBy(
      {
        '@context': StructuredDataValues.SCHEMA,
        '@type': 'ProductGroup',
        '@id': `${protocol}//${host}${pathname}`,
        name: sanitize(name),
        image: images.length
          ? images[0]
          : `https://resources.ediblearrangements.com/resources/en-us/i/revamp_p/edible_logo_new.png?v=${format(
              new Date(),
              'MMddyyy',
            )}`,
        url: `${protocol}//${host}${pathname}`,
        description: sanitize(description),
        brand: {
          '@type': 'Brand',
          name: brands,
        },
        aggregateRating: isNotEmpty(reviews)
          ? {
              '@type': 'AggregateRating',
              ratingValue: getAverage(reviews.map(({ rating }) => rating))
                .toFixed(1)
                .toString(),
              reviewCount: reviews.length.toString(),
            }
          : undefined,
        review: isNotEmpty(reviews)
          ? reviews.map(getStructuredReview)
          : undefined,
        productGroupID: catalogNumber,
        variesBy: 'https://schema.org/size',
        hasVariant: products.map((product) => ({
          '@type': 'Product',
          sku: `${catalogNumber}-${product.name}`,
          image: product.image,
          name: sanitize(`${name}-${product.name}`),
          size: product.name,
          offers: {
            '@type': 'Offer',
            url: `${protocol}//${host}${pathname}?size=${product.name}`,
            priceCurrency: isCanada ? 'CAD' : 'USD',
            price: `${product.price}`,
            itemCondition: 'http://schema.org/NewCondition',
            availability: 'http://schema.org/InStock',
          },
        })),
      },
      isNotUndefined,
    ),
    null,
    2,
  );
};

export function useArrangementStructuredData(catalogNumber: string): string {
  const arrangement = useSelector(({ arrangement: { data } }) => data);
  const reviews = useSelector((state) => state.reviews.data.items);
  const [structure, setStructure] = useState<string>(
    generate(arrangement, window.location, reviews, catalogNumber),
  );

  useEffect(() => {
    if (catalogNumber.length) {
      setStructure(
        generate(arrangement, window.location, reviews, catalogNumber),
      );
    }
  }, [arrangement, reviews, catalogNumber]);

  return structure;
}
