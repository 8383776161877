import { Button, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';

export const StoreFinderNoResults = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const FilterAndStoreWidgetSection = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '1060px',
  [theme.breakpoints.down(1150)]: {
    paddingRight: '5%',
    paddingLeft: '5%',
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '48px',
  },
  [theme.breakpoints.down(773)]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0%',
    marginRight: '0%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    flexDirection: 'column',
  },
}));

export const MobileFilterButton = styled(Button)({
  color: 'rgba(37, 37, 37, 1)',
  borderBottom: '1px solid rgb(0,0,0,0.12)',
  paddingBottom: '20px',
  paddingTop: '20px',
  marginBottom: '16px',
  borderRadius: '0px',
  '& .MuiTypography-root': {
    fontWeight: 600,
    paddingLeft: '8px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: colors.primaryRed,
  },
});

export const FilterChipSection = styled('div')({
  display: 'flex',
  overflowY: 'auto',
  paddingBottom: '16px',
  '.MuiTypography-root': {
    fontSize: '14px',
  },
});

export const FilterChipGroup = styled('div')({
  display: 'flex',
});

export const filterChip: SxProps<Theme> = (theme) => ({
  ':not(:last-child)': {
    marginRight: '8px',
  },
  ':nth-last-of-type(1)': {
    ':after': {
      position: 'absolute',
      content: '""',
      borderRight: `1px solid rgb(0,0,0,0.12)`,
      height: 24,
      right: -24,
    },
  },
  '& .MuiButton-root': {
    padding: '4px',
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiTouchRipple-root': {
    width: 'max-content',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgba(200, 16, 46, 0.7)',
    height: '20px',
    width: '20px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
});

export const ClearAllFiltersButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '165px',
  height: '32px',
  padding: '0px',
  '& .MuiTypography-root': {
    fontWeight: 600,
  },
  marginLeft: '24px',
});

export const StoreWidgetListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: '64px',
  [theme.breakpoints.down(855)]: {
    paddingLeft: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  [theme.breakpoints.down(440)]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

export const LoadMoreStoresButtonSections = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadMoreStoresSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadMoreStoresButton = styled(Button)(({ theme }) => ({
  width: '430px',
  height: '56px',
  margin: '20px 0px',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down(440)]: {
    width: '100%',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    textDecoration: 'none',
    boxShadow: 'none',
  },
  '.MuiTypography-root': {
    fontWeight: 600,
    fontSize: '14px',
    color: colors.white,
  },
}));

export const ShopByOccasionWrapper = styled('div')({
  '& #shopByOccasionWidget': {
    backgroundColor: 'transparent !important',
  },
});
