import { AvailabilitySession } from 'redux/session/availability/availability.types';
import { OrderType } from 'types/cart.types';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import {
  selectAvailability,
  selectSessionArea,
  selectSessionDate,
  selectSessionStore,
} from 'redux/session/availability/availability.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useUpdatePasWithAvailability = (): void => {
  const dispatch = useDispatch();
  const availabilitySession: AvailabilitySession =
    useSelector(selectAvailability);
  const sessionStore = useSelector(selectSessionStore);
  const sessionArea = useSelector(selectSessionArea);
  const date = useSelector(selectSessionDate);

  useEffect(() => {
    const { serviceOption } = availabilitySession;

    if (
      availabilitySession.isSet &&
      serviceOption &&
      serviceOption !== OrderType.WalkIn
    ) {
      dispatch(
        pas.set({
          date,
          area: sessionArea,
          orderType: serviceOption,
          store: sessionStore,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitySession]);
};
