import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'containers/CartContainer/translations/locale.constants';
import * as select from 'components/Cart/feature/selectors';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ButtonModulePosition } from 'service/adobe-analytics/enums';
import {
  CarouselContainer,
  CarouselRewardsContainer,
  CarouselWrapper,
  Headline,
  arrowControls,
  title,
} from 'components/Cart/SuggestedProducts/styles';
import { CarouselControl } from 'components/Home/common/CarouselControl';
import { CarouselDetailedItem } from 'components/Cart/SuggestedProducts/type';
import { CarouselDetailedSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton';
import { CarouselItem } from 'components/Cart/SuggestedProducts/components/CarouselItem/index';
import { CarouselSettings } from 'components/Cart/SuggestedProducts/carousel-settings';
import { PLPArrangementType } from 'components/common/ArrangementsGrid/components/plp-arrangement-type';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import {
  ViewAllButtonDesktop,
  ViewAllButtonMobile,
} from 'components/Home/common/styled';
import { analytics } from 'service/adobe-analytics/analytics';
import {
  checkIsBlocked,
  selectArrangements,
} from 'components/PLP/ArrangementsList/feature/selectors';
import { isDefined } from 'utils/is-defined';
import { selectArrangementInactive } from 'components/common/ArrangementGroup/feature/selectors';
import { settings } from 'components/Cart/SuggestedProducts/settings';
import { useFetchSuggestedProducts } from 'components/Cart/SuggestedProducts/hooks/use-fetch-suggested-products';
import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

export interface Props {
  contactUs?: boolean;
}

export const SuggestedProducts: FC<Props> = ({ contactUs }) => {
  useFetchSuggestedProducts();
  const pathUrl = window.location.pathname;
  const totalProducts = useSelector(select.selectCartCount);
  const isBlocked = useSelector(checkIsBlocked);
  const arrangements = useSelector(selectArrangements);
  const slicedArray = arrangements
    .filter((x) => x.plpItemType === PLPArrangementType.arrangement)
    .slice(0, 10);
  const isInactive = useSelector(selectArrangementInactive);
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(
    null,
  );

  if (isBlocked) {
    return <CarouselDetailedSkeleton />;
  }

  if (slicedArray.length === 0 || isInactive) {
    return null;
  }

  const onRef = (divElement: HTMLDivElement) => {
    if (isDefined(divElement)) {
      setPortalContainer(divElement);
    }
  };

  const handleViewAllButtonClickHandler = () => {
    if (totalProducts) {
      analytics.continueShopping(ButtonModulePosition.PRODUCT_CAROUSEL);
    } else {
      analytics.startShopping(ButtonModulePosition.PRODUCT_CAROUSEL);
    }
  };
  const totalProductLabel = totalProducts
    ? i18next.t(TK.SUGGESTED_PRODUCTS)
    : i18next.t(TK.SUGGESTED_PRODUCTS_EMPTY);
  const totalProductButton = totalProducts
    ? i18next.t(TK.CONTINUE_SHOPPING)
    : i18next.t(TK.START_SHOPPING);

  return (
    <CarouselWrapper data-test="carousel-detailed-container">
      <Headline>
        {!pathUrl.includes('/my-account') && (
          <PureHtmlTypography
            sx={title}
            variant="h1"
            data-test="carousel-detailed-title"
          >
            {contactUs ? TK.WANT_TO_SHOP_LABEL : totalProductLabel}
          </PureHtmlTypography>
        )}
        {pathUrl.includes('/my-account') && (
          <PureHtmlTypography
            sx={title}
            variant="h1"
            data-test="carousel-detailed-title"
          >
            {i18next.t(TK.SUGGESTED_PRODUCTS_REWARDS)}
          </PureHtmlTypography>
        )}
        {!pathUrl.includes('/my-account') && (
          <ViewAllButtonDesktop
            size="large"
            href={contactUs ? '/top-sellers' : '/'}
            variant="outlined"
            color="primary"
            data-test="view-all-button-big"
            onClick={handleViewAllButtonClickHandler}
          >
            {contactUs ? TK.SHOP_POPULAR_GIFTS : totalProductButton}
          </ViewAllButtonDesktop>
        )}
        {pathUrl.includes('/my-account') && (
          <ViewAllButtonDesktop
            size="large"
            href="/top-sellers"
            variant="outlined"
            color="primary"
            data-test="view-all-button-big"
            onClick={handleViewAllButtonClickHandler}
          >
            {i18next.t(TK.VIEW_ALL)}
          </ViewAllButtonDesktop>
        )}
        <Box ref={onRef} sx={arrowControls} />
      </Headline>
      {!pathUrl.includes('/my-account') && (
        <CarouselContainer data-test="carousel-detailed">
          <Slider
            {...settings}
            nextArrow={
              <CarouselControl portalContainerEl={portalContainer}>
                <ArrowForwardIos fontSize="small" />
              </CarouselControl>
            }
            prevArrow={
              <CarouselControl portalContainerEl={portalContainer}>
                <ArrowBackIosNew fontSize="small" />
              </CarouselControl>
            }
          >
            {slicedArray.map((carouselItem: CarouselDetailedItem, index) => (
              <CarouselItem
                key={carouselItem.id}
                item={carouselItem}
                position={index + 1}
                data-test="carousel-detailed-slide"
              />
            ))}
          </Slider>
        </CarouselContainer>
      )}
      {pathUrl.includes('/my-account') && (
        <CarouselRewardsContainer data-test="carousel-detailed">
          <Slider {...CarouselSettings}>
            {slicedArray.map((carouselItem: CarouselDetailedItem, index) => (
              <CarouselItem
                key={carouselItem.id}
                item={carouselItem}
                position={index + 1}
                data-test="carousel-detailed-slide"
              />
            ))}
          </Slider>
        </CarouselRewardsContainer>
      )}
      {!pathUrl.includes('/my-account') && (
        <ViewAllButtonMobile
          size="large"
          variant="outlined"
          href={contactUs ? '/top-sellers' : '/'}
          color="primary"
          data-test="view-all-button-big"
          style={{ marginBottom: '32px' }}
        >
          {contactUs ? TK.SHOP_POPULAR_GIFTS : totalProductButton}
        </ViewAllButtonMobile>
      )}
      {pathUrl.includes('/my-account') && (
        <ViewAllButtonMobile
          size="large"
          variant="outlined"
          href="/top-sellers"
          color="primary"
          data-test="view-all-button-big"
          style={{ marginBottom: '32px' }}
        >
          {i18next.t(TK.VIEW_ALL)}
        </ViewAllButtonMobile>
      )}
    </CarouselWrapper>
  );
};
