import { createAsyncThunk } from '@reduxjs/toolkit';
import { filterApiService } from 'api/filters-api.service';

export const fetchFilterName = createAsyncThunk(
  'pdp/back-filter-name/fetch',
  async (alias: string) => {
    const { data } = await filterApiService.find(alias);
    return data?.[0]?.mls?.[0]?.name ?? '';
  },
);
