import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import { Helmet } from 'react-helmet-async';
import { ogImage } from 'utils/cloudinaryAssets';
import { statesUS } from 'utils/country';
import { useStructuredStatesData } from 'hooks/meta/use-states-structured-data';
import React, { FC } from 'react';
import i18next from 'i18next';

export const HeadMetaStates: FC = () => {
  const states = statesUS;
  const structuredData = useStructuredStatesData(states);
  let statesKeywordsString = '';

  states.forEach((state) => {
    statesKeywordsString += `${state}, `;
  });

  return (
    <Helmet>
      <title>{i18next.t(TK.STATES_SEO_META_TITLE)}</title>
      <meta name="keywords" content={`${statesKeywordsString}`} />
      <meta name="title" content={i18next.t(TK.STATES_SEO_META_TITLE)} />
      <meta
        name="description"
        content={i18next.t(TK.STATES_META_DESCRIPTION_TAG)}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={i18next.t(TK.STATES_SEO_META_TITLE)} />
      <meta
        property="og:description"
        content={i18next.t(TK.STATES_META_DESCRIPTION_TAG)}
      />
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
};
