import { ARRANGEMENTS_NO_RESULTS_FOUND } from 'components/PLP/ArrangementsList/translations/constants';
import { categoryPagesApiService } from 'api/category-pages.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import i18next from 'i18next';

type Param = {
  url: string;
};

export enum AbortReason {
  NewSearch = 'new_search',
}

export const ABORTED = 'aborted';

export const getCategoryPage = createAsyncThunk(
  'category-pages/get',
  async (params: Param, thunkAPI) => {
    try {
      const { data } = await categoryPagesApiService.getCategoryPages(params);

      if (data.length) {
        return data[0];
      }

      return thunkAPI.rejectWithValue({
        data: { key: '' },
        message: i18next.t(ARRANGEMENTS_NO_RESULTS_FOUND),
        status: 404,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(parseAxiosError(e));
    }
  },
);
