import { isUndefined } from 'utils/is-undefined';

/**
 * @example
 * const obj = { a: 1, b: undefined };
 * const cleanObj = removeUndefinedProperties(obj);
 * console.log(cleanObj); // { a: 1 }
 */
export function removeUndefinedProperties<
  T extends Record<string, unknown | undefined>,
>(obj: T): Partial<T> {
  const copy = { ...obj };

  (Object.keys(copy) as (keyof T)[]).forEach((key) => {
    if (isUndefined(copy[key])) {
      delete copy[key];
    }
  });

  return copy;
}
