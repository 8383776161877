import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { fonts } from 'utils/theme/fonts';

export const skinnyBanner: ThemedSxProps = (theme) => ({
  width: '100%',
  textDecoration: 'none',
  fontFamily: fonts.poppins,
  margin: theme.spacing(0, 'auto', 0, 'auto'),
  textAlign: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const skinnyBannerLink: ThemedSxProps = {
  textDecoration: 'none',
  fontFamily: fonts.poppins,
  textAlign: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
};

export const skinnyBannerText: ThemedSxProps = (theme) => ({
  font: 'inherit',
  fontWeight: 400,
  color: theme.palette.common.black,
  height: 'auto',
});

export const wrapper: ThemedSxProps = (theme) => ({
  top: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { md: '24px', xs: '24px', sm: '24px' },
  paddingTop: { md: '6px', xs: '4px', sm: '4px' },
  color: theme.palette.common.white,
  paddingBottom: { md: '6px', xs: '4px', sm: '4px' },
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});
