import * as TK from 'components/Rewards/translations/locale.constants';
import { Button, InputAdornment, TextField } from '@mui/material';
import { ReminderSearchFormValues } from 'components/Rewards/RewardLanding/MyRewards/Reminder/types';
import { baseInput, searchBtnStyle } from 'components/Rewards/styles';
import { useForm } from 'react-hook-form';
import React, { FC, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import i18next from 'i18next';

type Props = {
  onSubmitAddressBook: (searchText: string) => void;
};

export const SearchBookAddress: FC<Props> = ({ onSubmitAddressBook }) => {
  const baseInputRef = useRef<HTMLInputElement>(null);

  const { register, handleSubmit } = useForm<ReminderSearchFormValues>({
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit((data) => {
    onSubmitAddressBook(data.searchText);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          inputRef={baseInputRef}
          sx={baseInput}
          variant="outlined"
          data-test="address-book-search"
          placeholder={i18next.t(TK.ADDRESS_SEARCH_TEXT)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon data-test="search-icon" />
              </InputAdornment>
            ),
          }}
          {...register('searchText')}
        />
        <Button variant="contained" type="submit" sx={searchBtnStyle}>
          {i18next.t(TK.SEARCH_TEXT)}
        </Button>
      </form>
    </>
  );
};
