import { ApiBaseService } from 'api/apibase.service';
import { Review } from 'api/reviews/types/review.interface';

class ReviewsApiService extends ApiBaseService {
  getReviews({
    productCode,
    offset,
    limit,
  }: {
    productCode: number;
    offset?: number;
    limit?: number;
  }): Promise<Review[]> {
    return this.apibase
      .get<Review[]>(`/arrangements/${productCode}/reviews`, {
        params: { offset, limit },
      })
      .then(({ data }) => data);
  }
}

export const reviewsApiService = new ReviewsApiService();
