import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { AddressBook } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/AddressBook';
import { Box, Typography } from '@mui/material';
import { HeadMetaInfo } from 'components/Cart/HeadMetaInfo/HeadMetaInfo';
import { MyLocations } from 'components/Rewards/RewardLanding/MyRewards/MyLocations';
import { Reminders } from 'components/Rewards/RewardLanding/MyRewards/Reminder/Reminders';
import { Rewards } from 'components/Rewards/RewardLanding/MyRewards/Rewards';
import { SuggestedProducts } from 'components/Cart/SuggestedProducts/SuggestedProducts';
import { accountApiService } from 'api/account/account.api.service';
import { getSearchParams } from 'utils/getSearchParams';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';

export const MyRewards: FC = () => {
  const giftReminderRef = useRef<HTMLDivElement>(null);
  const addressBookRef = useRef<HTMLDivElement>(null);
  const hightLightSection = getSearchParams('section');
  const [customRewardOrderMsg, setCustomRewardOrderMsg] = useState<string>();
  useEffect(() => {
    if (hightLightSection === 'gf') {
      giftReminderRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (hightLightSection === 'ab') {
      addressBookRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hightLightSection]);

  const userAccountInformation = useSelector(selectUserAccountInformation);
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId =
    accountInfo.length && accountInfo[0].id ? accountInfo[0].id : 0;

  const fetchRewardTierLevelMessageByAccountID = async () => {
    const res = await accountApiService.getRewardTierLevelMessageByAccountID(
      accountId,
    );
    setCustomRewardOrderMsg(res);
  };

  useEffect(() => {
    if (accountId) {
      fetchRewardTierLevelMessageByAccountID();
    }
  }, [accountId]);
  return (
    <>
      <HeadMetaInfo />
      <Box sx={styles.rewardContainerStyle}>
        <Typography variant="h4" fontWeight="500" mb={3}>
          {i18next.t(TK.EDIBLE_REWARDS)}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: ['14px', '16px', '16px'] }}>
          {i18next.t(TK.REWARD_DETAILED_MESSAGE)}
        </Typography>
        {customRewardOrderMsg && (
          <Box sx={styles.messageContainer}>
            <Typography variant="body2" textAlign="center" color="black">
              {customRewardOrderMsg}
            </Typography>
          </Box>
        )}
        <Box>
          <Rewards />
        </Box>
        <Box ref={giftReminderRef}>
          <Reminders />
          <hr />
        </Box>
        <Box ref={addressBookRef}>
          <AddressBook />
          <hr />
        </Box>
        <Box>
          <Typography variant="subtitle1" mt={4} mb={4}>
            {i18next.t(TK.REWARD_MY_LOCATIONS)}
          </Typography>
          <MyLocations />
          <hr />
        </Box>
        <SuggestedProducts />
      </Box>
    </>
  );
};
