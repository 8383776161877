import {
  LoginStatus,
  ParsedErrorResponse,
  ParsedLoginResponse,
} from 'api/auth-legacy/log-in.misc';
import { appInsights } from 'utils/telemetry/app-insights';
import { authLegacyApiService } from 'api/auth-legacy/auth.legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const logIn = createAsyncThunk<
  ParsedLoginResponse,
  { email: string; password: string },
  { rejectValue: ParsedErrorResponse }
>('auth/log-in', async ({ email, password }, { rejectWithValue }) => {
  try {
    const { data } = await authLegacyApiService.logInUser(email, password);
    const res = JSON.parse(data.d) as ParsedLoginResponse;
    if (res._Status === LoginStatus.Error) {
      return rejectWithValue(res as ParsedErrorResponse);
    }
    appInsights.trackEvent({ name: 'Logged in' });
    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});
