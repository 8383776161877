import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import {
  isAlreadyExist,
  isError,
  isSuccess,
} from 'api/account-legacy/sign-up.misc';
import { isPending } from 'utils/status.comparer';

export const checkIsProcessing = createSelector(
  ({ signUp: { signUpStatus } }: RootState) => signUpStatus,
  (status) => isPending(status),
);

export const checkIsSignUpSuccess = createSelector(
  ({ signUp: { responseStatus } }: RootState) => responseStatus,
  (status) => isSuccess(status),
);

export const checkIsSignUpError = createSelector(
  ({ signUp: { responseStatus } }: RootState) => responseStatus,
  (status) => isError(status),
);

export const checkIsAlreadyExist = createSelector(
  ({ signUp: { responseStatus } }: RootState) => responseStatus,
  (status) => isAlreadyExist(status),
);

export const getNewUserInfo = createSelector(
  ({ signUp: { accountInfo } }: RootState) => accountInfo,
  (accountInfo) => accountInfo,
);
