import { HEADER_LOGO } from 'components/Header/translations/locale.constants';
import { analytics } from 'service/adobe-analytics/analytics';
import { headerEdibleLogo } from 'utils/cloudinaryAssets';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const EdibleLogo: FC = () => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const handleClick = () => {
    analytics.headerMenuItemClicked(i18next.t(HEADER_LOGO));
    segment.menuItemClicked(
      i18next.t(HEADER_LOGO),
      '/',
      userSession,
      userAccountInformation,
    );
  };

  return (
    <a href="/" onClick={() => handleClick()}>
      <img
        src={headerEdibleLogo}
        width={136}
        height={40}
        alt={i18next.t(HEADER_LOGO)}
        data-test="edible-header-logo"
      />
    </a>
  );
};
