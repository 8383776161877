import { ApiLegacyBaseService } from 'api/apiLegacyBase.service';
import { AvailabilitySessionParams } from 'redux/session/availability/availability.types';
import { DEFAULT_DESKTOP_AVAILABILITY } from 'api/legacy-session/defaults';
import { DesktopUpdateAvailabilityParams } from 'api/legacy-session/types';
import { LegacySessionService } from 'api/legacy-session/interfaces';
import { formatToLegacyDateString } from 'utils/date';

class DesktopLegacySessionApiService
  extends ApiLegacyBaseService
  implements LegacySessionService
{
  private getUpdateAvailabilityPayload({
    areaId,
    areaName,
    selectedDate,
    serviceOption,
    storeId,
    currentRecipientId,
  }: AvailabilitySessionParams): DesktopUpdateAvailabilityParams {
    return {
      ...DEFAULT_DESKTOP_AVAILABILITY,
      AreaID: `${areaId}`,
      AreaName: `${areaName}`,
      SelectedDate: selectedDate
        ? formatToLegacyDateString(selectedDate)
        : DEFAULT_DESKTOP_AVAILABILITY.SelectedDate,
      ServiceOption: `${serviceOption}`,
      StoreID: storeId ? `${storeId}` : DEFAULT_DESKTOP_AVAILABILITY.StoreID,
      CurrentRecipientID: currentRecipientId
        ? `${currentRecipientId}`
        : DEFAULT_DESKTOP_AVAILABILITY.CurrentRecipientID,
    };
  }

  async reset(): Promise<void> {
    await this.apibase.post(
      '/AJAX/AvailabilityStatistics.aspx/ClearSession',
      null,
    );
  }

  async update(params: AvailabilitySessionParams): Promise<void> {
    await this.apibase.post(
      '/ajax/SmartDiv.aspx/CreateAvailabilitySessionStateAsJSON',
      this.getUpdateAvailabilityPayload(params),
    );
  }
}

export { DesktopLegacySessionApiService };
