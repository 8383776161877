import { ProductCatalogItem, SearchCatalogItem } from 'components/Header/types';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchProductsCatalog,
  fetchSearchCatalog,
} from 'components/Header/feature/actions';

export interface SearchCatalogState {
  searchItems: SearchCatalogItem[];
  searchProducts: ProductCatalogItem[];
  status: Status;
  error: ResponseError | null;
}

const initialState: SearchCatalogState = {
  searchItems: [],
  searchProducts: [],
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'searchModule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchCatalog.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchSearchCatalog.fulfilled, (state, { payload }) => {
        state.searchItems = payload._Data;
        state.status = Status.FULFILLED;
      })
      .addCase(fetchSearchCatalog.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addCase(fetchProductsCatalog.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchProductsCatalog.fulfilled, (state, { payload }) => {
        state.searchProducts = payload._Data;
        state.status = Status.FULFILLED;
      })
      .addCase(fetchProductsCatalog.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const searchCatalogReducer = slice.reducer;
