import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { Contact } from 'api/contacts.api.service';
import { NULL_RECIPIENT } from 'containers/PDPContainer/PDPContainer';
import { Recipient } from 'redux/cart/cart.types';

export function getPageFriendlyUrl(pathname: string): string {
  return pathname.slice(pathname.lastIndexOf('/') + 1);
}

export function isAvailabilityStepGreaterThan(
  step: AvailabilityStep,
  comparableStep: AvailabilityStep,
): boolean {
  return step > comparableStep;
}

export const areAllAvailabilityStepsAreFilled = (
  step: AvailabilityStep,
): step is AvailabilityStep.STORE_LIST_SELECTION =>
  step === AvailabilityStep.CONTINUE;

export const mapContactToRecipient = (
  contact: Contact,
): Partial<Recipient> => ({
  addressType: contact.addressType,
  address1: contact.address1,
  address2: contact.address2,
  company: contact.company,
  cellPhone: contact.cellPhone,
  phone: contact.homePhone,
  workPhone: contact.workPhone,
  cityMlId: contact.cityML?.id ?? NULL_RECIPIENT.cityMlId,
  firstName: contact.firstName,
  lastName: contact.lastName,
  recipientEmail: contact.email,
});
