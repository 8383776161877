import {
  ORDER_OCCASION_ALERT,
  ORDER_OCCASION_EVENT_SELECT_LABEL,
  ORDER_OCCASION_HEADER,
  ORDER_OCCASION_OPTION_TITLE,
  ORDER_OCCASION_SELECT_LABEL,
} from 'components/PDP/Order/RecipientForm/components/Occasion/translations/constants';

export const orderOccasionsCommonLocale = {
  [ORDER_OCCASION_SELECT_LABEL]: 'Occasion or Event (optional)',
  [ORDER_OCCASION_HEADER]: "What's the Occasion?",
  [ORDER_OCCASION_OPTION_TITLE]: 'Please Choose',
  [ORDER_OCCASION_ALERT]:
    'Help us improve our products and learn about trending occasions and events',
  [ORDER_OCCASION_EVENT_SELECT_LABEL]: 'Occasion/Event*',
};
