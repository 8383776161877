/*
 * Available Context types
 * according to DY [docs](https://dy.dev/docs/page-context#page-context-for-e-commerce)
 */
export type DYContextType =
  | 'HOMEPAGE'
  | 'CATEGORY'
  | 'PRODUCT'
  | 'CART'
  | 'OTHER';

export interface DYRecommendationContext {
  type: DYContextType;
  data?: string[];
  lng?: string;
}

export type DYActiveConsent = {
  accepted: boolean;
};
export interface DY {
  userActiveConsent?: DYActiveConsent;
  recommendationContext?: DYRecommendationContext;
  API: (
    event: CartEvent,
    properties: {
      [key: string]: unknown;
    },
  ) => void;
}

export interface DYO {
  userActiveConsent?: { accepted: boolean };
  ActiveConsent: {
    updateConsentAcceptedStatus(status: boolean): void;
    isUserOptOut(): boolean;
  };
  recommendations: {
    registerElements(el: Element): void;
  };
  dyhash: {
    sha256(value: string): string;
  };
}

export type CartProductEventProps = {
  value: number;
  currency: string;
  quantity: number;
  catalogNumber?: number;
  sizeName?: string;
  sku?: string;
};

export type CartItem = {
  productId: string;
  quantity: number;
  itemPrice: number;
};

export enum CartEvent {
  EVENT = 'event',
}
export type DynamicYieldEventType =
  | 'enter-promo-code-v1'
  | 'keyword-search-v1'
  | 'newsletter-subscription-v1'
  | 'add-to-cart-v1'
  | 'login-v1'
  | 'signup-v1'
  | 'purchase-v1'
  | 'sort-items-v1'
  | 'filter-items-v1'
  | 'identify-v1'
  | 'remove-from-cart-v1';

export interface DynamicYieldEventProperties {
  dyType: DynamicYieldEventType;
  [key: string]: unknown;
}

export interface DynamicYield {
  API: (event: CartEvent, properties: DynamicYieldEventProperties) => void;
}

export type DynamicYieldEventName =
  | 'Promo Code Entered'
  | 'Keyword Search'
  | 'Newsletter Subscription'
  | 'Login'
  | 'Signup'
  | 'Add to cart'
  | 'Purchase'
  | 'Remove from cart'
  | 'Sort Items'
  | 'Filter Items'
  | 'Identify User';

export enum DynamicYieldSortBy {
  PRICE = 'PRICE',
  RELEVANCE = 'RELEVANCE',
}

export enum DynamicYieldSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type DynamicYieldSortType = {
  sortBy: DynamicYieldSortBy;
  sortOrder: DynamicYieldSortOrder;
};
