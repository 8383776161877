import { FilterItem, filterApiService } from 'api/filters-api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNotEmpty } from 'utils/array/size';
import { parseAxiosError } from 'utils/errors';

export const fetchFiltersByFriendlyUrl = createAsyncThunk(
  'filter/fetch',
  async (url: string, { rejectWithValue }) => {
    try {
      const { data }: { data: FilterItem[] } = await filterApiService.find(url);
      if (isNotEmpty(data)) {
        return data[0];
      }
      return rejectWithValue('not_found');
    } catch (error) {
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);
