import { fetchPreviousGroupName } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/feature/actions';
import { setNoName } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/feature/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useQueryParameter } from 'hooks/use-query-parameter';

const categoryId = document.referrer
  ? new URLSearchParams(new URL(document.referrer).search).get('CategoryID')
  : '';

export const useRelatedCategoryProvider = (): void => {
  const dispatch = useDispatch();
  const groupId = useQueryParameter('ArrangementGroupID');
  const id = groupId || categoryId;

  useEffect(() => {
    dispatch(id ? fetchPreviousGroupName(+id) : setNoName());
  }, [dispatch, id]);
};
