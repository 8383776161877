import { DesktopLegacySessionApiService } from 'api/legacy-session/desktop-legacy-session.api.service';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { LegacySessionService } from 'api/legacy-session/interfaces';
import { MobileLegacySessionApiService } from 'api/legacy-session/mobile-legacy-session.api.service';

const LegacySessionApiService = IS_MOBILE_HOST
  ? MobileLegacySessionApiService
  : DesktopLegacySessionApiService;

const legacySessionApiService: LegacySessionService =
  new LegacySessionApiService();

export { legacySessionApiService };
