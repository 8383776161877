import { AvailabilityManagedTypes } from 'enums/availability-managed-types.enum';

export const isBulkHoursAvailable = (
  managedType: AvailabilityManagedTypes,
): boolean =>
  Boolean(
    managedType &&
      [
        AvailabilityManagedTypes.MANAGED_HOURS,
        AvailabilityManagedTypes.MANAGED_HOURS_AND_PROFILES,
      ].includes(managedType),
  );
