import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/OrderHistory/styles';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { ActionMenu } from 'components/Rewards/RewardLanding/OrderHistory/ActionMenu';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Links } from 'enums/common-links';
import {
  OrderHistoryDates,
  OrderHistoryResponse,
} from 'api/rewards/types/order-history-response.type';
import { OrderTypeName } from 'components/PDP/Details/components/PasControl/contants/data-test-order-type-name';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { format } from 'date-fns';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const OrderHistory: FC = () => {
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const [orderHistory, setOrderHistory] = useState<OrderHistoryResponse[]>([]);
  const [hasPreviousOrders, setHasPreviousOrders] = useState<boolean>(false);
  const [orderDates, setOrderDates] = useState<OrderHistoryDates[]>([]);
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [month, setMonth] = useState<string>(format(new Date(), 'MMM yyyy'));
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const [dataLoaded, setDataLoaded] = useState<boolean>(true);
  useEffect(() => {
    const fetchOrderDates = async () => {
      const res = await rewardsApiService.getOrderDateList(accountInfo[0].id);
      if (res.length > 0) {
        setHasPreviousOrders(true);
        setOrderDates(res);
        const selectedDate = new Date(res[0].Date);
        setMonth(format(selectedDate, 'MMM yyyy'));
        const firstDay = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          1,
        );
        const lastDay = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          0,
        );

        setFromDate(firstDay);
        setToDate(lastDay);
      } else {
        const currentDate = new Date();
        setOrderDates([{ Date: currentDate }]);
        setHasPreviousOrders(false);
        setDataLoaded(false);
      }
    };
    if (accountInfo) fetchOrderDates();
  }, [accountInfo]);
  useEffect(() => {
    const fetchOrderHistory = async () => {
      setDataLoaded(true);
      const res = await rewardsApiService.getOrderHistory(
        accountInfo[0].id,
        format(new Date(fromDate || ''), 'MM-dd-yyyy'),
        format(new Date(toDate || ''), 'MM-dd-yyyy'),
      );
      if (res.length > 0) {
        setDataLoaded(false);
        setOrderHistory(res);
        setHasPreviousOrders(true);
      } else {
        setHasPreviousOrders(false);
      }
    };
    if (accountInfo && fromDate && toDate) fetchOrderHistory();
  }, [accountInfo, toDate, fromDate]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (
    event: unknown,
    newPage: React.SetStateAction<number>,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setRowsPerPage(parseInt(target.value, 10));
    setPage(0);
  };

  const rowsToDisplay = orderHistory.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const handleChange = (event: SelectChangeEvent) => {
    const selectedDate = new Date(event.target.value);

    setMonth(format(selectedDate, 'MMM yyyy'));
    const firstDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1,
    );
    const lastDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0,
    );
    setFromDate(firstDay);
    setToDate(lastDay);
  };
  return (
    <>
      <Backdrop opened={dataLoaded} />
      <Box display="flex">
        <Typography variant="h4" sx={styles.orderTitleStyle}>
          {i18next.t(TK.ORDER_HISTORY)}
        </Typography>
        <HideOnXs>
          <Button
            variant="contained"
            type="submit"
            href={Links.FruitArrangements}
            sx={styles.placeBtnStyle}
          >
            {i18next.t(TK.ORDER_BUTTON_TEXT)}
          </Button>
        </HideOnXs>
      </Box>
      <PureHtmlTypography variant="body1" sx={styles.orderTextStyle}>
        {i18next.t(TK.ORDER_TEXT)}
      </PureHtmlTypography>
      <ShowUpXs>
        <Button
          variant="contained"
          type="submit"
          href={Links.FruitArrangements}
          sx={styles.placeBtnMobileStyle}
        >
          {i18next.t(TK.ORDER_BUTTON_TEXT)}
        </Button>
      </ShowUpXs>
      {orderDates && (
        <Box sx={styles.orderHeaderStyle}>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              Show Orders Placed In:
            </InputLabel>
            <Select
              value={month}
              label="Show Orders Placed In:"
              labelId="demo-simple-select-label"
              onChange={handleChange}
              sx={{
                ...styles.selectBoxStyle,
                marginTop: '-2px',
                marginLeft: '5px',
              }}
            >
              {orderDates.map((row) => (
                <MenuItem
                  key={row.Date.toString()}
                  value={format(new Date(row.Date), 'MMM yyyy')}
                >
                  {format(new Date(row.Date), 'MMM yyyy')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box display="flex" sx={styles.orderTableContainer}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <HideOnXs>
                <TableRow>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.ORDER_TYPE)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.ORDER_NUMBER)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.RECIPIENT_NAME)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.FULFILLMENT_DATE)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.ORDER_ACTIONS)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {i18next.t(TK.ORDER_ACTIONS)}
                  </TableCell>
                </TableRow>
              </HideOnXs>
            </TableHead>
            <TableBody>
              <HideOnXs>
                {rowsToDisplay.map((row) => (
                  <TableRow
                    key={row.number}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: '12px' }}
                    >
                      {OrderTypeName[row.type]} -{' '}
                      {format(new Date(row.date), 'MM/dd/yy')}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      {row.number}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      {row.recipient}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      {format(new Date(row.fulfillmentDate), 'MM/dd/yy')}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      ${row.total}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      <ActionMenu orderDetails={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </HideOnXs>
              <ShowUpXs>
                {rowsToDisplay.map((row) => (
                  <Box key={row.number} sx={styles.mobileContainer}>
                    <TableRow key={row.number} sx={styles.tableRowMobileStyle}>
                      <Box sx={styles.tableMobileStyle}>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {i18next.t(TK.ORDER_TYPE)}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {i18next.t(TK.ORDER_NUMBER)}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {i18next.t(TK.RECIPIENT_NAME)}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {i18next.t(TK.FULFILLMENT_DATE)}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {i18next.t(TK.ORDER_TOTAL)}
                        </TableCell>
                      </Box>
                      <Box sx={styles.tableMobileContentStyle}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontSize: '12px' }}
                        >
                          {OrderTypeName[row.type]} -{' '}
                          {format(new Date(row.date), 'MM/dd/yy')}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {row.number}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {row.recipient}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          {format(new Date(row.fulfillmentDate), 'MM/dd/yy')}
                        </TableCell>
                        <TableCell sx={{ fontSize: '12px' }}>
                          ${row.total}
                        </TableCell>
                      </Box>
                    </TableRow>
                    <Box>
                      <ActionMenu orderDetails={row} />
                    </Box>
                    <hr className="lineStyle" />
                  </Box>
                ))}
              </ShowUpXs>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={orderHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      {!hasPreviousOrders && (
        <Typography
          variant="subtitle2"
          textAlign="center"
          fontWeight="500"
          mb={2}
        >
          {i18next.t(TK.NO_ORDERS_FOUND)}
        </Typography>
      )}
    </>
  );
};
