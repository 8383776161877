import * as styles from 'components/Home/BannerProductCarousel/components/BannerCarouselDetailedItem/styles';
import { Image } from 'components/common/Image/Image';
import { Link, Typography } from '@mui/material';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addCurrency } from 'utils/add-currency';
import React, { FC } from 'react';

interface ArrangementSuggestionProps {
  imageUrl: string;
  path: string;
  title: string;
  price: number;
  brandName: string;
}

export const ArrangementSuggestionItem: FC<ArrangementSuggestionProps> = (
  props,
) => {
  return (
    <Link
      sx={styles.itemLink}
      underline="none"
      href={props.path}
      color="secondary"
    >
      <Image
        sx={{ display: 'inline-block' }}
        src={props.imageUrl}
        animationDuration={250}
        observerMargins="0px 0px 400px"
      />
      <PureHtmlTypography color="primary" variant="subtitle3">
        {props.brandName}
      </PureHtmlTypography>
      <PureHtmlTypography variant="subtitle2">{props.title}</PureHtmlTypography>
      <Typography variant="subtitle2" component="p" mt={0.5}>
        {addCurrency(props.price)}
      </Typography>
    </Link>
  );
};
