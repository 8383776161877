import { Mode } from 'components/Authentication/LogIn/feature/slice';
import { analytics } from 'service/adobe-analytics/analytics';
import { getValidationMessage } from 'components/Authentication/translations/get-validation-message';
import {
  getValidationType,
  selectLogInIsFulfilled,
  selectMode,
} from 'components/Authentication/LogIn/feature/selectors';
import { isError } from 'api/auth-legacy/validate.misc';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

export const useTrackLogInForm = (): void => {
  const success = useSelector(selectLogInIsFulfilled);
  useEffect(() => {
    if (success) {
      analytics.loginFormCompleted();
    }
  }, [success]);

  const status = useSelector(getValidationType);
  useEffect(() => {
    if (isError(status)) {
      analytics.pushErrors([i18next.t(getValidationMessage(status))]);
    }
  }, [status]);

  const mode = useSelector(selectMode);
  useEffect(() => {
    if (mode === Mode.LogIn) {
      analytics.loginDialog();
    }
  }, [mode]);
};
