import * as TK from 'components/OrderConfirmation/translations/locale.constants';
import * as select from 'redux/order-confirmation/selectors';
import * as styles from 'components/OrderConfirmation/styles';
import { Account } from 'components/OrderConfirmation/CreateAccount/Account';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box, Button, Typography } from '@mui/material';
import { BreadcrumbBack } from 'components/Rewards/OrderDetails/BreadcrumbBack';
import { CarouselDetailed } from 'components/OrderConfirmation/TopSeller/TopSeller';
import { Image } from 'components/common/Image/Image';
import { OrderConfirmationSkeleton } from 'components/OrderConfirmation/Skeleton';
import { OrderDetails } from 'components/OrderConfirmation/OrderDetails/OrderDetails';
import { Payment } from 'components/OrderConfirmation/PaymentInfo/payment';
import { PersonIcon } from 'utils/cloudinaryAssets';
import { TOP_SALES_ID } from 'components/OrderConfirmation/TopSeller/constants';
import { isEmpty } from 'utils/array/size';
import { sale } from 'components/OrderConfirmation/translations/locale.constants';
import { selectIsFinished } from 'providers/Session/feature/user.selectors';
import { useFetchOrderConfirmationArrangementGroup } from 'components/OrderConfirmation/hooks/use-fetch-order-confirmation-arrangement-group';
import { useFetchOrderConfirmationArrangements } from 'components/OrderConfirmation/hooks/use-fetch-order-confirmation-arrangements';
import { useFetchOrderDetails } from 'components/Rewards/hook/use-order-details';
import { usePlpFiltersState } from 'containers/PLPContainer/hooks/query/filters/use-plp-filters-state';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import React, { FC, useRef } from 'react';
import i18next from 'i18next';

export const OrderDetailsPage: FC = () => {
  useFetchOrderDetails();
  const loading = useSelector(select.checkIsOrderConfirmation);
  const orderData = useSelector(select.selectOrderData);
  const saleData = useSelector(select.selectSaleData);
  const recipientLength = orderData && orderData.length;
  const products = useSelector(select.selectOrderItemData);
  const childProducts = useSelector(select.selectOrderSubItemData);
  const isUserSessionAvailable = useSelector(selectIsFinished);
  const count = (products?.length || 0) + (childProducts?.length || 0);
  const componentRef = useRef(null);
  const [filters] = usePlpFiltersState();
  const totalProducts = useSelector(select.selectTotalProductCount);
  const pathUrl = window.location.pathname;
  const OrderDetailsUrl = pathUrl.includes('/order-details');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const filterIds = isEmpty(filters)
    ? [TOP_SALES_ID]
    : filters.map(({ Value }) => Value);

  useFetchOrderConfirmationArrangements(filterIds);
  useFetchOrderConfirmationArrangementGroup(TOP_SALES_ID);

  if (loading || !isUserSessionAvailable) {
    return (
      <>
        <Backdrop opened />
        <OrderConfirmationSkeleton />
      </>
    );
  }

  return (
    <>
      <Box sx={styles.breadcrumbWrapper}>
        <BreadcrumbBack orderHistory={i18next.t(TK.ORDER_HISTORY)} />
      </Box>
      <Box sx={styles.confirmWrapper}>
        {saleData && (
          <Box px={[2.5, 5, 0]} sx={styles.wrapper}>
            <Box className="title">{i18next.t(TK.ORDER_DETAILS_TITLE)}</Box>
            <Box className="confirmSection">
              <Button
                variant="text"
                type="button"
                data-test="print-confirmation-button"
                aria-label="print-confirmation"
                className="printConfirmBtn"
                sx={styles.redBtnStyle}
                onClick={handlePrint}
              >
                {i18next.t(TK.PRINT_ORDER_DETAILS)}
              </Button>
            </Box>
            <Box className="orderContainer">
              <Typography sx={styles.orderTxt}>
                {i18next.t(TK.ORDER_TXT)}:{' '}
                {i18next.t(sale.ITEM_COUNT, {
                  Number: totalProducts,
                })}
                {count !== 1 && 's'}
              </Typography>
              <Box display="flex">
                <Box sx={styles.imgStyle}>
                  <Image alt={i18next.t(TK.RECIPIENT_IMG)} src={PersonIcon} />
                </Box>
                <Typography sx={styles.recipientTxt}>
                  {recipientLength}{' '}
                  {recipientLength !== 1 ? 'Recipients' : 'Recipient'}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Account IsOrderDetails />
      <Box
        sx={
          OrderDetailsUrl ? styles.orderPrintContainer : styles.printContainer
        }
      >
        <Payment />
        <Box ref={componentRef}>
          {orderData?.map((order, i) => (
            <OrderDetails
              key={order.orderID}
              order={order}
              recipientNumber={i + 1}
            />
          ))}
        </Box>
      </Box>
      <CarouselDetailed />
    </>
  );
};
