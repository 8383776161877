import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { isIE } from 'utils/isIE';

export const avatar: ThemedSxProps = () => ({
  width: 56,
  height: 56,
  padding: 0.5,
  fontSize: 12,
  borderRadius: 2,
  backgroundColor: 'common.black',
  transition: 'all .2s',
});

export const clearButton: ThemedSxProps = (theme) => ({
  position: 'absolute',
  bottom: 24,
  left: 0,
  right: 0,
  width: isIE ? '135px' : 'max-content',
  height: '32px',
  margin: '0 auto',
  border: '4px solid',
  fontSize: '13.25px',
  borderColor: colors.primaryRed,
  backgroundColor: theme.palette.custom.whiteBg,
  backdropFilter: 'blur(20px)',
  color: colors.primaryRed,
});
