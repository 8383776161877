import { Links } from 'enums/common-links';
import { MenuItem, Typography } from '@mui/material';
import { SEARCH_QUERY_KEY } from 'containers/PLPContainer/query.constants';
import { getSearchCatalog } from 'components/Header/feature/selectors';
import { getSearchItemUrl } from 'components/Header/utils';
import { highlight, suggestedOption } from 'components/Header/Search/styles';
import { regexEscape } from 'utils/regex';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface Props {
  option: string;
  regex: RegExp;
  props: React.HTMLAttributes<HTMLLIElement>;
}

export const SuggestedItem: FC<Props> = ({ option, regex, props }) => {
  const suggestionElements = option.split(regex).filter(Boolean);
  const highlightElementIdx = suggestionElements.findIndex((el: string) =>
    regex.test(el),
  );
  const searchOptions = useSelector(getSearchCatalog);

  const handleItemClick = () => {
    const optionData = searchOptions.find((item) => item.Name === option);
    const baseUrl = window.location.origin;
    let url = `${baseUrl}${
      Links.FruitArrangements
    }?${SEARCH_QUERY_KEY}=${regexEscape(option.trim())}`;
    if (optionData && optionData.URL) {
      url = `${baseUrl}/${getSearchItemUrl(optionData.URL)}`;
    }
    window.location.href = url;
  };

  return (
    <MenuItem {...props} sx={suggestedOption} onClick={handleItemClick}>
      {suggestionElements.map((element, index) => (
        <Typography
          key={element.concat(index.toString())}
          variant="subtitle2"
          component="span"
          sx={index === highlightElementIdx ? highlight : undefined}
        >
          {element}
        </Typography>
      ))}
    </MenuItem>
  );
};
