/* eslint-disable no-param-reassign */
import { RedirectUrl } from 'redux/PLP/redirect/types/types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getRedirectUrl } from 'redux/PLP/redirect/redirect.action';

export type RedirectState = {
  redirect: {
    data: RedirectUrl | null;
    status: Status;
  };
};

export const initialState: RedirectState = {
  redirect: {
    data: null,
    status: Status.INIT,
  },
};

const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRedirectUrl.pending, (state) => {
        state.redirect.status = Status.PENDING;
      })
      .addCase(getRedirectUrl.rejected, (state) => {
        state.redirect.status = Status.ERROR;
      })
      .addCase(getRedirectUrl.fulfilled, (state, { payload }) => {
        state.redirect.data = payload;
        state.redirect.status = Status.FULFILLED;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: redirectReducer } = redirectSlice;
