import { Settings } from 'react-slick';

export const settings: Settings = {
  lazyLoad: 'progressive',
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: false,
  initialSlide: 0,
  infinite: true,
  arrows: true,
  swipe: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 550,
      settings: {
        variableWidth: false,
        slidesToShow: 2,
      },
    },
  ],
};
