import { FulfillmentOptionType, OrderType } from 'types/cart.types';

export function isNotSpecified<T extends OrderType>(
  type: OrderType | T,
): type is OrderType.NotSpecified {
  return type === OrderType.NotSpecified;
}

export function isDelivery<T extends OrderType>(
  type: OrderType | T,
): type is OrderType.Delivery {
  return type === OrderType.Delivery;
}

export function isShipment<T extends OrderType>(
  type: OrderType | T,
): type is OrderType.Shipment {
  return type === OrderType.Shipment;
}

export function isPickup<T extends OrderType>(
  type: OrderType | T,
): type is OrderType.Pickup {
  return type === OrderType.Pickup;
}

export function isFulfillType<T extends OrderType>(
  type: T | OrderType,
): type is FulfillmentOptionType {
  return [OrderType.Delivery, OrderType.Pickup, OrderType.Shipment].includes(
    type,
  );
}
