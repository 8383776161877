import * as TK from 'components/PDP/Details/components/OwnBox/translations/own-box.locale.constants';

export const ownBoxCommonLocale = {
  [TK.OWN_BOX_SUBTITLE]: 'Your selections will evenly fill all available spots',
  [TK.OWN_BOX_DIPPED_FRUIT_TITLE]: 'Select up to 4 fruit/topping combinations',
  [TK.OWN_BOX_FRUIT_TITLE]: 'Select up to 4 options',
  [TK.OWN_BOX_DESSERT_TITLE]: 'Select up to 3 desserts',
  [TK.OWN_BOX_LIMIT_ALERT_TITLE]: 'Selection Limit Reached',
  [TK.OWN_BOX_LIMIT_ALERT_CONTENT]: 'Please remove a selection to add another',
  [TK.OWN_BOX_DESSERT_BOARD_BAKESHOP_TITLE]:
    'Select up to 3 baked good options',
  [TK.OWN_BOX_DESSERT_BOARD_BERRIES_TITLE]:
    'Select up to 6 dipped berry options',
  [TK.OWN_BOX_DESSERT_BOARD_FRUIT_TITLE]: 'Select up to 2 dipped fruit options',
  [TK.OWN_BOX_COOKIE_BOX_TITLE]:
    'Choose up to 3 flavors. One selection is required.',
  [TK.OWN_BOX_COOKIE_BOX_LIMITED_TITLE]:
    'Choose up to 3 flavors of Seasonal cookies',
  [TK.OWN_BOX_COOKIE_BOX_SUBTITLE]:
    'You must select at least 1 Signature flavor',
  [TK.OWN_BOX_6_COOKIE_BOX_TITLE]:
    'Choose up to 3 flavors, maximum of 6 cookies.',
  [TK.OWN_BOX_6_COOKIE_BOX_SUBTITLE]:
    'Click on a cookie below to add it to your box',
  [TK.OWN_BOX_COOKIE_BOX_LIMITED_SUBTITLE]:
    'Our Seasonal cookie flavors are available for a limited time only',
  [TK.OWN_BOX_COOKIE_SIZE_12_COOKIES]: '12 Cookies',
  [TK.OWN_BOX_COOKIE_SIZE_6_COOKIES]: '6 Cookies',
};
