import { Radio, RadioProps } from '@mui/material';
import React, { FC } from 'react';

export const MenuRadio: FC<RadioProps> = (props) => (
  <Radio
    onTouchStart={(e) => e.stopPropagation()}
    onMouseDown={(e) => e.stopPropagation()}
    sx={{ mr: 3 }}
    {...props}
  />
);
