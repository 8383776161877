import { Slider } from 'components/common/DottedSlider/styles';
import { commonSliderProps } from 'components/PDP/Details/components/Gallery/props';
import React, { ComponentProps, FC } from 'react';

export const DottedSlider: FC<ComponentProps<typeof Slider>> = ({
  children,
  ...sliderProps
}) => (
  <Slider
    {...commonSliderProps}
    {...sliderProps}
    slidesToShow={1}
    dots
    arrows={false}
  >
    {children}
  </Slider>
);
