import {
  Arrangement,
  Product,
} from 'redux/PDP/arrangement/types/arrangement.types';
import { RootState } from 'redux/store';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { isUS } from 'utils/country';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useGTagProductAddedToCart = (
  arrangement: Arrangement,
  product: Product | null,
  category: string | null,
  quantity: number,
  addonItems: UpsellItem[] = [],
): void => {
  const currency = isUS ? 'USD' : 'CAD';
  const cartRecipientId = useSelector(
    ({ cart }: RootState) => cart.cartRecipientId,
  );

  useEffect(() => {
    if (cartRecipientId > -1 && product) {
      const addOnProducts = [];
      const addToCartProduct = {
        item_id: arrangement.catalogNumber,
        item_name: arrangement.name,
        index: 0,
        item_category: category,
        price: product.price,
        item_variant: `${arrangement.name} - ${product.name}`,
        quantity,
      };
      for (let i = 0; i < addonItems.length; i += 1) {
        addOnProducts.push({
          item_id: addonItems[i].option.id,
          item_name: addonItems[i].option.name,
          index: 0,
          item_category: '',
          price: addonItems[i].option.price,
          quantity: 1,
        });
      }
      const items = [addToCartProduct, addOnProducts].flat();
      gtag('event', 'add_to_cart', {
        currency,
        value: '',
        items,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRecipientId]);
};
