import * as TK from 'containers/RewardsContainer/translations/locale.constants';
import * as TRK from 'components/Rewards/translations/locale.constants';
import * as actions from 'components/Payment/feature/actions';
import * as select from 'components/Payment/feature/selectors';
import * as styles from 'components/Rewards/RewardLanding/Account/styles/edit-account-form.styles';
import { AccountInformationFormSchema } from 'components/Rewards/RewardLanding/Account/schema';
import { AddressType } from 'types/cart.types';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ContactInfoData } from 'components/Rewards/RewardLanding/Account/types';
import { Controller, useForm } from 'react-hook-form';
import { FullName } from 'components/PDP/Order/RecipientForm/components/FullName/FullName';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { Option } from 'components/common/Select/Option';
import { Select } from 'components/common/Select';
import { ShowOnlyXs } from 'utils/hidden/show-only-xs';
import { Status } from 'redux/types/state.interface';
import { UpdateAccountRequest } from 'api/account/type/account-request';
import { UserAccountInfo } from 'api/account/type/user-account-info-response';
import { ZIP_LENGTH } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.constants';
import { accountApiService } from 'api/account/account.api.service';
import { fetchUserAccountInformation } from 'providers/Session/feature/user.action';
import { format } from 'date-fns';
import {
  getDayDropdownSelectValues,
  getMonthDropdownSelectValues,
  getYearDropdownSelectValues,
} from 'utils/calculateBirthdayDropdownValues';
import {
  isFullZip,
  sanitizeZip,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.utils';
import { isUS } from 'utils/country';
import { phoneModifier } from 'utils/phone-modifier';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCountries } from 'components/Payment/BillingInformation/hooks/use-fetch-countries';
import { useFetchStates } from 'components/Payment/BillingInformation/hooks/use-fetch-states';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

const currentCountry = isUS ? 'US' : 'CA';
const defaultYear = '1904';

export type Props = {
  userAccountInfo: UserAccountInfo;
  setIsEditAccount: (value: boolean) => void;
};

export const EditAccountForm: FC<Props> = ({
  userAccountInfo,
  setIsEditAccount,
}) => {
  useFetchCountries();
  useFetchStates();
  const dispatch = useDispatch();

  const area = useSelector(select.selectArea);
  const areaStatus = useSelector(select.selectAreaStatus);
  const states = useSelector(select.selectStates);
  const cities = useSelector(select.selectCities);
  const countries = useSelector(select.selectCountries);
  const isLoading = useSelector(select.selectIsAreaPending);

  const [showStateOtherOption, setShowStateOtherOption] = useState(false);
  const [isCountrycheck, setIsCountrycheck] = useState(false);
  const [isCitiesLoad, setIsCitiesLoad] = useState(false);

  const stateOptions = states || [];

  const countryName = userAccountInfo.countryId === 1 ? 'US' : 'CA';
  const defaultValues = {
    title: userAccountInfo?.title,
    firstName: userAccountInfo?.firstName,
    lastName: userAccountInfo?.lastName,
    address1: userAccountInfo?.address1,
    address2: userAccountInfo?.address2,
    city: userAccountInfo?.cityMlId || 0,
    state: userAccountInfo?.stateId || 0,
    otherState: '',
    country: userAccountInfo?.countryId ? countryName : currentCountry,
    zipCode: userAccountInfo?.areaOther,
    phone:
      userAccountInfo?.cellPhone ||
      userAccountInfo?.homePhone ||
      userAccountInfo?.workPhone,
    email: userAccountInfo?.email,
    addressType: userAccountInfo?.addressType,
    birthDay: userAccountInfo?.birthday
      ? format(new Date(userAccountInfo?.birthday), 'd')
      : '',
    birthMonth: userAccountInfo?.birthday
      ? format(new Date(userAccountInfo?.birthday), 'M')
      : '',
    birthYear: userAccountInfo?.birthday
      ? format(new Date(userAccountInfo?.birthday), 'y')
      : '',
    company: userAccountInfo?.company,
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ContactInfoData>({
    mode: 'onChange',
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(AccountInformationFormSchema),
  });

  useEffect(() => {
    if (userAccountInfo && userAccountInfo.areaOther) {
      dispatch(actions.getArea(userAccountInfo.areaOther));
      setIsCitiesLoad(true);
    }
  }, [userAccountInfo]);

  const daysDropdownSelectValues = getDayDropdownSelectValues(
    Number(getValues().birthMonth),
    Number(getValues().birthYear),
  );
  const monthsDropdownSelectValues = getMonthDropdownSelectValues();
  const yearsDropdownSelectValues = getYearDropdownSelectValues();
  const SaveButtonDisableCheck = errors && Object.keys(errors).length === 0;
  const [isBusinessAddress, setIsBusinessAddress] = useState(false);
  const addressTypeValue = userAccountInfo.addressType;

  useEffect(() => {
    if (addressTypeValue === 2) {
      setIsBusinessAddress(true);
    }
    if (addressTypeValue === 1) {
      setIsBusinessAddress(false);
    }
  }, [addressTypeValue]);

  useEffect(() => {
    if (areaStatus === Status.ERROR) {
      setValue('zipCode', '', { shouldValidate: true });
      setValue('city', 0);
      setValue('state', 0);
      return;
    }
    const { state, country, cities: areaCities } = area;
    type FieldName = 'city' | 'state' | 'country';
    type FielsType = string | number | undefined;
    if (state && country && !!areaCities.length) {
      const selectedState = states.filter((c) => c.Code === state);
      const fieldsToUpdate: Record<FieldName, FielsType> = {
        country,
        state: selectedState.length ? selectedState[0].ID : 0,
        city: isCitiesLoad ? userAccountInfo?.cityMlId : areaCities[0].mlId,
      };
      (Object.entries(fieldsToUpdate) as [FieldName, string][]).forEach(
        ([key, value]) => {
          setValue(key, value, { shouldValidate: true });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, areaStatus]);

  const validateZip = (zipValue: string) => {
    if (!isCountrycheck) {
      const value = sanitizeZip(zipValue);

      setValue('zipCode', value, { shouldValidate: true });

      if (isFullZip(value)) {
        setIsCitiesLoad(false);
        dispatch(actions.getArea(value));
      }
    } else {
      setValue('zipCode', zipValue, { shouldValidate: true });
    }
  };

  const addressTypeOptions = Object.keys(AddressType)
    .filter((value) => Number.isNaN(Number(value)))
    .map((key, index) => ({
      text: index === 0 ? i18next.t(TK.CHOOSE_OPTION) : key,
      value: index,
    }));

  const handleChange = (value: number) => {
    if (value === 2) {
      setIsBusinessAddress(true);
    }
    if (value === 1 || value === 0) {
      setIsBusinessAddress(false);
    }
  };

  const getCountryId = (countryCode: string): number => {
    switch (countryCode) {
      case 'US':
        return 1;
      case 'CA':
        return 2;
      default:
        return 0;
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const year = data.birthYear || defaultYear;
    const dob = format(
      new Date(`${data.birthMonth}/${data.birthDay}/${year}`),
      'yyyy-MM-dd hh:mm:ss',
    );
    const countryId = getCountryId(data.country);
    const selectedCity = cities.filter((c) => c.mlId === Number(data.city));
    const cityId = selectedCity.length ? selectedCity[0].id : 0;
    const updateAccountRequest: UpdateAccountRequest = {
      id: Number(userAccountInfo.id),
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      homePhone: data.phone,
      email: data.email,
      birthday: dob,
      addressType: data.addressType,
      address1: data.address1,
      address2: data.address2,
      areaOther: data.zipCode,
      cityID: cityId,
      cityMLID: Number(data.city),
      cityOther: data.cityOther,
      stateID: Number(data.state),
      stateOther: data.stateOther,
      countryID: countryId,
      deleted: false,
      company: data.company,
    };
    await accountApiService.updateAccountInfo(updateAccountRequest);
    dispatch(fetchUserAccountInformation(userAccountInfo.id));
    setShowStateOtherOption(false);
    setIsEditAccount(false);
  });

  const onReject = () => {
    setShowStateOtherOption(false);
    setIsEditAccount(false);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <HideOnXs>
          <Box sx={styles.buttonContainer}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onReject}
              sx={styles.cancelButton}
            >
              {i18next.t(TRK.CANCEL_TEXT)}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!SaveButtonDisableCheck}
              sx={styles.saveEditAccount}
            >
              {i18next.t(TRK.ACCOUNT_SAVE)}
            </Button>
          </Box>
        </HideOnXs>
        <Box sx={styles.editAccountContainer}>
          <Typography variant="subtitle1" mb={2}>
            {i18next.t(TK.ACCOUNT_TITLE)}
          </Typography>
          <Box sx={styles.contactContainer} mb={1.5}>
            <Box>
              <FormControl sx={styles.titleBoxStyle} error={!!errors.title}>
                <InputLabel
                  id="contact-title-label"
                  error={Boolean(errors.title)}
                >
                  {i18next.t(TK.ADDRESS_TITLE)}
                </InputLabel>
                <Controller
                  control={control}
                  name="title"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      label={i18next.t(TK.ADDRESS_TITLE)}
                      {...register('title')}
                      labelId="account-title-label"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors.title)}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value="">
                        {i18next.t(TK.CHOOSE_OPTION)}
                      </MenuItem>
                      <MenuItem value="Dr.">Dr.</MenuItem>
                      <MenuItem value="Mr.">Mr.</MenuItem>
                      <MenuItem value="Mrs.">Mrs.</MenuItem>
                      <MenuItem value="Ms.">Ms.</MenuItem>
                      <MenuItem value="Prof.">Prof.</MenuItem>
                    </Select>
                  )}
                  rules={{ required: true }}
                />
                <FormHelperText>{errors.title?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box sx={styles.nameContainer}>
              <FullName
                firstName={watch('firstName')}
                lastName={watch('lastName')}
                register={register}
                firstNameError={errors.firstName?.message}
                lastNameError={errors.lastName?.message}
              />
            </Box>
          </Box>

          <Box sx={styles.contactContainer} mb={2.5}>
            <FormControl>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    {...register('phone')}
                    sx={styles.phoneBoxStyle}
                    onChange={(event) => {
                      onChange(phoneModifier(event.target.value));
                    }}
                    value={value || ''}
                    variant="outlined"
                    data-test="billing-info-phone"
                    label={i18next.t(TK.PHONE_NUMBER)}
                    error={Boolean(errors.phone)}
                    autoComplete="off"
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                control={control}
                name="email"
                render={({ field: { value } }) => (
                  <TextField
                    sx={styles.emailBoxStyle}
                    value={value || ''}
                    variant="outlined"
                    label={i18next.t(TK.EMAIL_ADDRESS)}
                    data-test="billing-info-email"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={styles.bithdayContainerStyle}>
            <Grid
              container
              flexDirection={['column', 'row', 'row']}
              flexWrap={['wrap', 'nowrap', 'nowrap']}
            >
              <Grid container direction="column" pt={2}>
                <Grid item sx={styles.labelText}>
                  {i18next.t(TK.REWARDS_BIRTHDAY)}
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  flexWrap={['wrap', 'nowrap', 'nowrap']}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    pr={[0, 2, 2]}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel error={!!errors.birthMonth?.message}>
                        {`${i18next.t(TK.REWARDS_BIRTH_MONTH)}*`}
                      </InputLabel>
                      <Select
                        {...register('birthMonth', { required: true })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="sign-up-birth-month"
                        placeholder={i18next.t(TK.REWARDS_BIRTH_MONTH)}
                        error={!!errors?.birthMonth?.message}
                        value={watch('birthMonth')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.REWARDS_BIRTH_MONTH)}
                        </Option>
                        {monthsDropdownSelectValues.map((month) => {
                          return (
                            <Option
                              onClick={() =>
                                setValue('birthMonth', month.key.toString())
                              }
                              key={month.key}
                              value={month.key}
                            >
                              {month.value}
                            </Option>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        data-test="signup-birth-month-helper"
                        error
                      >
                        {errors?.birthMonth?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                    pr={[0, 2, 2]}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel error={!!errors.birthDay?.message}>
                        {`${i18next.t(TK.REWARDS_BIRTH_DAY)}*`}
                      </InputLabel>
                      <Select
                        {...register('birthDay', { required: true })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="signup-birth-date"
                        placeholder={i18next.t(TK.REWARDS_BIRTH_DAY)}
                        error={!!errors?.birthDay?.message}
                        value={watch('birthDay')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.REWARDS_BIRTH_DATE)}
                        </Option>
                        {daysDropdownSelectValues.map((day) => {
                          return (
                            <Option
                              onClick={() => setValue('birthDay', day)}
                              selected={
                                Number(day) === Number(getValues().birthDay)
                              }
                              key={day}
                              value={day}
                            >
                              {day}
                            </Option>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        data-test="signup-birth-date-helper"
                        error
                      >
                        {errors?.birthDay?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={styles.formInputContainer}
                  >
                    <FormControl sx={styles.dobFieldDropdown}>
                      <InputLabel>
                        {i18next.t(TK.REWARDS_BIRTH_YEAR)}
                      </InputLabel>
                      <Select
                        {...register('birthYear', { required: false })}
                        sx={styles.formInput}
                        color="secondary"
                        data-test="sign-up-birth-year"
                        placeholder={i18next.t(
                          TK.REWARDS_BIRTH_YEAR_PLACEHOLDER,
                        )}
                        value={watch('birthYear')}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="0">
                          {i18next.t(TK.REWARDS_BIRTH_YEAR)}
                        </Option>
                        {yearsDropdownSelectValues.map((year) => {
                          return (
                            <Option
                              onClick={() => setValue('birthYear', year)}
                              key={year}
                              value={year}
                            >
                              {year}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="subtitle1" mb={2.5} mt={2.5}>
            {i18next.t(TK.ACCOUNT_MY_ADDRESS)}
          </Typography>
          <Box sx={styles.contactContainer} mb={2.5}>
            <Controller
              control={control}
              name="addressType"
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl>
                  <InputLabel
                    id="account-addresstype-label"
                    error={Boolean(error)}
                  >
                    {i18next.t(TK.ADDRESS_TYPE_LABEL)}
                  </InputLabel>
                  <Select
                    label={i18next.t(TK.ADDRESS_TYPE_LABEL)}
                    sx={styles.addressTypeContainer}
                    labelId="account-addresstype-label"
                    value={value}
                    onChange={(event) => {
                      onChange(event.target.value);
                      handleChange(event.target.value as number);
                    }}
                    MenuProps={{ disableScrollLock: true }}
                  >
                    {addressTypeOptions.map((c) => (
                      <Option key={c.value} value={c.value}>
                        {c.text}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
          {!isBusinessAddress && (
            <Box sx={styles.contactContainer} mb={2.5}>
              <TextField
                label={i18next.t(TK.STREET_ADDRESS)}
                {...register('address1', { required: true })}
                value={watch('address1')}
                InputLabelProps={{ shrink: !!watch('address1') }}
                variant="outlined"
                sx={styles.streetAddressContainer}
                error={Boolean(errors.address1)}
                helperText={errors.address1?.message}
              />
              <TextField
                label={i18next.t(TK.APT_FLOOR_SUITE_BUTTON)}
                {...register('address2')}
                InputLabelProps={{ shrink: !!watch('address2') }}
                value={watch('address2')}
                variant="outlined"
                sx={styles.apatBoxStyle}
                error={Boolean(errors.address2)}
              />
            </Box>
          )}
          {isBusinessAddress && (
            <Box sx={styles.contactContainer} pb={2.5}>
              <TextField
                label={i18next.t(TK.BUSINESS_NAME)}
                {...register('company', { required: true })}
                value={watch('company')}
                InputLabelProps={{ shrink: !!watch('company') }}
                variant="outlined"
                sx={styles.phoneBoxStyle}
                error={Boolean(errors.company)}
                helperText={errors.company?.message}
              />
              <TextField
                label={i18next.t(TK.STREET_ADDRESS)}
                {...register('address1', { required: true })}
                value={watch('address1')}
                InputLabelProps={{ shrink: !!watch('address1') }}
                variant="outlined"
                sx={styles.emailBoxStyle}
                error={Boolean(errors.address1)}
                helperText={errors.address1?.message}
              />
              <TextField
                label={i18next.t(TK.APT_FLOOR_SUITE_BUTTON)}
                {...register('address2')}
                InputLabelProps={{ shrink: !!watch('address2') }}
                value={watch('address2')}
                variant="outlined"
                sx={styles.apatBoxStyle}
                error={Boolean(errors.address2)}
              />
            </Box>
          )}

          <Box sx={styles.contactContainer} mb={2.5}>
            <FormControl sx={styles.countryComponent} error={!!errors.country}>
              <Controller
                control={control}
                name="country"
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel
                      id="billing-information-country-label"
                      error={Boolean(error)}
                    >
                      {i18next.t(TK.COUNTRY_LABEL)}
                    </InputLabel>
                    <Select
                      label={i18next.t(TK.COUNTRY_LABEL)}
                      labelId="billing-information-country-label"
                      value={value}
                      error={Boolean(errors.country)}
                      onChange={(event) => {
                        onChange(event.target.value);
                        if (event.target.value !== currentCountry) {
                          setIsCountrycheck(true);
                          setValue('zipCode', '');
                          setValue('city', 0);
                          setValue('state', 0);
                        } else {
                          setIsCountrycheck(false);
                          setValue('zipCode', '');
                          setValue('city', 0);
                          setValue('state', 0);
                        }
                      }}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <Option value="">{i18next.t(TK.CHOOSE_OPTION)}</Option>
                      {countries.map((data) => (
                        <Option key={data.ID} value={data.Code}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <FormHelperText>{errors.country?.message}</FormHelperText>
            </FormControl>

            <FormControl sx={styles.countryComponent} error={!!errors.city}>
              {!isCountrycheck && cities.length ? (
                <>
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FormControl>
                        <InputLabel
                          id="billing-information-city-label"
                          error={Boolean(error)}
                        >
                          {i18next.t(TK.CITY_LABEL)}
                        </InputLabel>
                        <Select
                          label={i18next.t(TK.CITY_LABEL)}
                          labelId="billing-information-city-label"
                          value={value}
                          onChange={(event) => onChange(event.target.value)}
                          error={Boolean(errors.city)}
                          MenuProps={{ disableScrollLock: true }}
                        >
                          <Option value="">
                            {i18next.t(TK.CHOOSE_OPTION)}
                          </Option>
                          {cities.map((c) => (
                            <Option key={c.mlId} value={c.mlId}>
                              {c.name}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <FormHelperText>{errors.city?.message}</FormHelperText>
                </>
              ) : (
                <TextField
                  {...register('cityOther')}
                  value={watch('cityOther')}
                  label={i18next.t(TK.CITY_LABEL)}
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                />
              )}
            </FormControl>
            <FormControl sx={styles.countryComponent} error={!!errors.state}>
              <Controller
                control={control}
                name="state"
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel
                      id="billing-information-state-label"
                      error={Boolean(error)}
                    >
                      {i18next.t(TK.STATE_LABEL)}
                    </InputLabel>
                    <Select
                      label={i18next.t(TK.STATE_LABEL)}
                      labelId="billing-information-state-label"
                      value={value}
                      error={Boolean(errors.state)}
                      onChange={(event) => {
                        onChange(event.target.value);
                        if (event.target.value === 'Others') {
                          setShowStateOtherOption(true);
                        }
                      }}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <Option value="0">{i18next.t(TK.CHOOSE_OPTION)}</Option>
                      {!!stateOptions.length &&
                        stateOptions.map((data) => (
                          <Option key={data.ID} value={data.ID}>
                            {data.Name}
                          </Option>
                        ))}
                      <Option value="Others">
                        {i18next.t(TRK.OTHERS_TEXT)}
                      </Option>
                    </Select>
                  </FormControl>
                )}
              />
              <FormHelperText>{errors.state?.message}</FormHelperText>
              {showStateOtherOption && (
                <TextField
                  label="State Name"
                  {...register('stateOther', { required: true })}
                  value={watch('stateOther')}
                  error={Boolean(errors.stateOther)}
                  helperText={errors.stateOther?.message}
                />
              )}
            </FormControl>
            <TextField
              sx={styles.apatBoxStyle}
              {...register('zipCode')}
              value={watch('zipCode')}
              onChange={(event) => validateZip(event.target.value)}
              label={i18next.t(TK.ZIP_LABEL)}
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode?.message}
              InputProps={{
                inputProps: {
                  maxLength: !isCountrycheck && isUS ? ZIP_LENGTH : '',
                  inputMode: !isCountrycheck && isUS ? 'numeric' : 'text',
                },
                endAdornment: isLoading && (
                  <InputAdornment position="end">
                    <CircularProgress color="secondary" size={24} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ShowOnlyXs>
            <Box mt={3}>
              <Button
                variant="contained"
                type="submit"
                sx={styles.bottomButtonStyle}
              >
                {i18next.t(TRK.ACCOUNT_SAVE)}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={onReject}
                sx={styles.bottomButtonStyle}
              >
                {i18next.t(TRK.CANCEL_TEXT)}
              </Button>
            </Box>
          </ShowOnlyXs>
        </Box>
      </form>
    </>
  );
};
