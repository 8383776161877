import {
  CultureChangeResponse,
  CultureChangeStatus,
} from 'redux/locale/locale.types';
import { appInsights } from 'utils/telemetry/app-insights';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { localeLegacyApiService } from 'api/locale.legacy.api.service';
import { parseAxiosError } from 'utils/errors';

export const changeLocale = createAsyncThunk(
  'locale/change',
  async ({ locale }: { locale: string }, { rejectWithValue }) => {
    try {
      const { data } = await localeLegacyApiService.changeLocale(locale);
      const parsedResponse = JSON.parse(data.d) as CultureChangeResponse;
      if (parsedResponse._Status === CultureChangeStatus.Error) {
        return rejectWithValue(parsedResponse);
      }
      appInsights.trackEvent({
        name: 'Locale changed',
        properties: { to: locale },
      });
      return locale;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);
