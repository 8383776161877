import { ResetPasswordStatus } from 'api/auth-legacy/reset-password.misc';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from 'components/Authentication/Reset/feature/actions';

interface State {
  status: Status;
  reset: ResetPasswordStatus;
}

const initialState: State = {
  status: Status.INIT,
  reset: ResetPasswordStatus.Ok,
};

const slice = createSlice({
  name: 'auth/password-reset',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.status = Status.PENDING;
        state.reset = ResetPasswordStatus.Ok;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.reset = payload._Status;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.reset = payload?._Status || ResetPasswordStatus.Unexpected;
      })
      .addDefaultCase((state) => state);
  },
  reducers: { reset: () => initialState },
});

export const authPasswordResetReducer = slice.reducer;
export const { reset } = slice.actions;
