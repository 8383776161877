import {
  HOME,
  LEGAL_META_TITLE_TAG,
  LEGAL_PAGE,
  californiaPolicy,
  deliveryPolicy,
  shipmentPolicy,
} from 'components/LegalPages/translations/locale.constants';

export const legalPageCommonLocale = {
  [HOME]: 'Home',
  [LEGAL_PAGE]: 'Legal',
  [LEGAL_META_TITLE_TAG]: 'Edible Arrangements - {{LegalPage}}',
};

export const legalDeliveryPolicyPage = {
  [deliveryPolicy.TITLE]: 'Delivery Policies',
  [deliveryPolicy.SUBTITLE]: 'Please Note The Following Delivery Policies:',
  [deliveryPolicy.ONE]:
    'We take pride in our customer service at Edible Arrangements and this includes not only the quality of our products, but also the speed of our service and delivery. Keeping this in mind, we require that any cancellation or adjustment to your order be made no later than 11:59pm EST on the day before the scheduled delivery.',
  [deliveryPolicy.TWO]: 'Delivery times cannot be guaranteed.',
  [deliveryPolicy.THREE]: 'We cannot deliver to P.O. Boxes.',
  [deliveryPolicy.FOUR]: 'The recipient may be called to schedule delivery.',
  [deliveryPolicy.FIVE]:
    'Requests for deliveries at specific times of day are not guaranteed. Please contact the shop that will be processing your order with any specific questions regarding your delivery.',
  [deliveryPolicy.SIX]:
    'Residential Deliveries: If the recipient is not available at the time of delivery, we can no longer leave the delivery with a neighbor. We can only arrange to leave a delivery at a neighbor’s upon specific request of the guest. If you want your order to be left with a neighbor if the recipient is not home, please make sure to advise the local store in your delivery instructions at the time you place the order. You will need to provide the name and address of the neighbor. During busy holiday times, you can expect that residential deliveries may be made as late as 9:00 PM.',
  [deliveryPolicy.SEVEN]:
    'For business deliveries: The delivery person may leave the gift with the front desk, security, or the receptionist with specific instructions to refrigerate immediately. Edible Arrangements is not responsible for any gift that is not properly handled. If the business is closed, delivery will be attempted the next business day. **During busy holiday times, business deliveries may be made as late as 5:00 PM. We do not advise same day business deliveries to be placed after 3:00 PM as many business close at 5:00 PM. Your local store may contact you if you place a same day order for delivery to reschedule your order for another business day.',
  [deliveryPolicy.EIGHT]:
    'For school deliveries: The delivery person may leave the gift with the front desk, security, or the receptionist with specific instructions to refrigerate immediately. Edible Arrangements is not responsible for any gift that is not properly handled. If the school is closed, delivery will be attempted the next school day. **During busy holiday times, school deliveries may be made as late as 3:00 PM. We do not advise same day school deliveries to be placed after 12:00 PM as many school close at 3:00 PM. Your local store may contact you if you place a same day order for delivery to reschedule your order for another business day.',
  [deliveryPolicy.NINE]:
    'FLEX Orders will be delivered during the time period specified at the time the order was placed.',
};

export const legalShipmentPolicyPage = {
  [shipmentPolicy.TITLE]: 'Shipment Policies',
  [shipmentPolicy.SUBTITLE]: 'Please Note The Following Shipment Policies:',
  [shipmentPolicy.ONE]:
    'We take pride in our customer service at Edible Arrangements and this includes not only the quality of our products, but also the speed of our service and shipping. Keeping this in mind, we require that any cancellation or change be made no later than 12.00 noon EST on the day before your scheduled delivery.',
  [shipmentPolicy.TWO]:
    'We cannot deliver to P.O. boxes or APO/ FPO addresses at this time.',
  [shipmentPolicy.THREE]:
    'You are responsible for entering correct and complete recipient address information. We will ship, and FedEx will attempt to deliver to the address exactly as you have provided it. Entry of incorrect, incomplete, or otherwise invalid addresses may result in delivery delay or failure, for which we cannot be responsible.',
  [shipmentPolicy.FOUR]:
    'To maintain the integrity of our fresh fruit products, we do not require signature proof for orders shipped via FedEx service. Due to the perishable nature of our products, we recommend that you ship your order to a location and for a delivery date when someone will be able to receive the order and refrigerate (or enjoy!) upon delivery.',
  [shipmentPolicy.FIVE]:
    'We cannot guarantee a delivery time for any order shipped via FedEx service. Any request for a specific delivery time cannot be honored.',
  [shipmentPolicy.SIX]:
    'For Residential deliveries, please be aware that in the event that someone is not available to receive a shipment sent via FedEx, your package will be left in a location deemed by the FedEx driver to be secure, at their discretion. FedEx may also choose to leave your package with a neighbor.',
  [shipmentPolicy.SEVEN]:
    'For Non-Residential (Business-Hospital-School, etc.) deliveries, packages typically arrive no later than the end of the business day. During busy holiday delivery times, packages to a non-residential address may be delivered as late as 5:00 PM. Please be aware that your package will likely be delivered in accordance with standard delivery policies of the business, hospital, school, etc., and may not be delivered directly to your recipient in such cases. FedEx deliveries to a non-residential address may be left in a receiving area of the business such as the front desk, security, reception or mail room etc.',
  [shipmentPolicy.EIGHT]:
    'In accordance with FedEx policy, in certain instances or in certain areas, FedEx may make the determination not to leave a package without a receiver signature, at their sole discretion. Please keep in mind that your recipient may also have previously opted to require signatures for all deliveries made by FedEx to their location. In these instances, a signature will be required for delivery. This may delay the delivery of your order, or compromise the freshness of our products.',
  [shipmentPolicy.NINE]:
    'FedEx deliveries are available Tuesday through Saturday, excluding certain holidays. Saturday Delivery may not be available in all areas.',
  [shipmentPolicy.TEN]:
    'We cannot be responsible for any delivery problems or delays resulting from of any of the shipping policies outlined above.',
};

export const legalCaliforniaPrivacyRightsPage = {
  [californiaPolicy.TITLE]: 'California Privacy Rights',
  [californiaPolicy.SUBTITLE]: 'California Privacy Rights',
  [californiaPolicy.ONE]:
    'Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request from a business, with whom the California resident has an established business relationship, certain information with respect to the types of personal information the business shares with third parties for direct marketing purposes by such third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year.',
  [californiaPolicy.TWO]:
    'To request a copy of the information disclosure provided by Edible Arrangements pursuant to Section 1798.83 of the California Civil Code, please contact ',
  [californiaPolicy.THREE]: 'privacypolicy@edible.com.',
  [californiaPolicy.FOUR]: 'To review our entire privacy policy, click here',
  [californiaPolicy.FIVE]: '',
  [californiaPolicy.SIX]: '',
  [californiaPolicy.SEVEN]: '',
  [californiaPolicy.EIGHT]: '',
  [californiaPolicy.NINE]: '',
};
