import * as TK from 'components/Session/Alert/RecipientLockAlert/translations/constants';

export const recipientLockCommonLocale = {
  [TK.DELIVERY_FROM]: 'Your order will be serviced from {{store}} on {{date}}',
  [TK.DELIVERY_FOR]: 'Delivery to {{name}} on {{date}}',
  [TK.PICKUP_FROM]:
    'Your order will be ready for pickup from {{store}} on {{date}} at {{time}}',
  [TK.PICKUP_FOR]: 'Pickup for {{name}} on {{date}}',
  [TK.SHIPMENT_SERVICED_ON]: 'Your order will be serviced on {{date}}',
  [TK.NEW_RECIPIENT]: 'New Recipient',
};
