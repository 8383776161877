import { Filter } from 'api/filter-categories/types';
import { FilterItem } from 'api/filters-api.service';

const cache: Record<number, Filter> = {};
export const adaptSingleToCategoryFilterItem = (filter: FilterItem): Filter => {
  if (!cache[filter.id]) {
    cache[filter.id] = {
      FilterCategoryID: filter.filterCategoryID,
      ID: filter.id,
      Name: filter.mls?.[0]?.name || '',
      PageFriendlyURL: filter.url,
      Sequence: filter.sequence,
      Type: filter.type,
      Value: filter.value,
    };
  }
  return cache[filter.id];
};
