import { Menu as MuiMenu, styled } from '@mui/material';

export const Menu = styled(MuiMenu)<{ width?: number }>(({ width, theme }) => ({
  '& .MuiPaper-root': {
    width,
    marginTop: theme.spacing(1.5),
  },
  '& .MuiMenuItem-root': {
    whiteSpace: 'normal',

    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
}));
