import { SxProps, Theme } from '@mui/system';

export const galleryOuterWrapper: SxProps<Theme> = (theme) => ({
  mb: [6, 3],
  mr: [0, 0, '50px'],
  mt: [2, 2, 0],
  maxWidth: [1, 1, 445],
  [theme.breakpoints.only('xs')]: {
    mb: 3,
  },
});

export const freshFruitWrapper: SxProps<Theme> = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
