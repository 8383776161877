import { Area } from 'api/area/types/area.interface';
import { LegacyAreaClassificationType } from 'api/area/enums/legacy-area-classification-type.enum';
import { ResponseError } from 'types/error.types';
import { State, Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getArea } from 'components/AreaController/feature/area.actions';

const initialState: State<Area> = {
  data: {
    cities: [],
    state: '',
    name: '',
    id: 0,
    culture: '',
    countryCode: '',
    stateCode: '',
    classification: LegacyAreaClassificationType.NotSpecified,
    availability: {
      shipment: false,
      delivery: false,
      pickup: false,
    },
  },
  status: Status.INIT,
  error: null,
};

const areaControllerSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArea.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(getArea.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addCase(getArea.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.data = payload;
      })
      .addDefaultCase((state) => state);
  },
});

export const { resetState } = areaControllerSlice.actions;

export const {
  reducer: areaControllerReducer,
  actions: areaControllerActions,
} = areaControllerSlice;
