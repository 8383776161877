import { ResponseError } from 'types/error.types';
import { RootState } from 'redux/store';
import { Selector } from '@reduxjs/toolkit';
import { State, StateWithoutData } from 'redux/types/state.interface';
import {
  isBlocked,
  isError,
  isFulfilled,
  isInit,
  isPending,
} from 'utils/status.comparer';

type RootSelector<T> = Selector<RootState, T>;

interface NoDataSelectors<Error = ResponseError> {
  selectError: RootSelector<Error | null>;
  selectIsInit: RootSelector<boolean>;
  selectIsPending: RootSelector<boolean>;
  selectIsError: RootSelector<boolean>;
  selectIsFulfilled: RootSelector<boolean>;
  /** isInit || isPending */
  selectIsBlocked: RootSelector<boolean>;
}

export const createNoDataSelectors = <Error = ResponseError>(
  selectState: RootSelector<StateWithoutData<Error>>,
): NoDataSelectors<Error> => ({
  selectError: (state) => selectState(state).error,
  selectIsInit: (state) => isInit(selectState(state).status),
  selectIsPending: (state) => isPending(selectState(state).status),
  selectIsError: (state) => isError(selectState(state).status),
  selectIsFulfilled: (state) => isFulfilled(selectState(state).status),
  selectIsBlocked: (state) => isBlocked(selectState(state).status),
});

interface Selectors<T, Error = ResponseError> extends NoDataSelectors<Error> {
  selectData: RootSelector<T>;
}

export const createSelectors = <T, Error = ResponseError>(
  selectState: RootSelector<State<T, Error>>,
): Selectors<T, Error> => ({
  ...createNoDataSelectors<Error>(selectState),
  selectData: (state) => selectState(state).data,
});
