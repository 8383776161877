import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { isNotEmpty } from 'utils/array/size';

export const shouldShowSuggestedDates = ({
  available,
  dates,
}: OrderTypeAvailability): boolean => {
  return !available && isNotEmpty(dates);
};

export const shouldShowFlexCalendar = (
  { available, dates }: OrderTypeAvailability,
  isDelivery?: boolean,
  isNonFlexFeeDates?: boolean,
): boolean => {
  const showFlexCalendar =
    !!isNonFlexFeeDates || (available && !!isDelivery && isNotEmpty(dates));
  return showFlexCalendar;
};

export const shouldShowCalendar = (
  availability: OrderTypeAvailability,
  isDelivery?: boolean,
  isNonFlexFeeDates?: boolean,
): boolean => {
  return (
    shouldShowSuggestedDates(availability) ||
    shouldShowFlexCalendar(availability, isDelivery, !!isNonFlexFeeDates)
  );
};
