import { useLocation, useNavigate } from 'react-router-dom';

type Setter = (parameter: string, value: string | string[]) => void;
export const useQueryParameterSetter = (): Setter => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (param: string, value: string | string[]): void => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete(param);
    if (typeof value === 'string') {
      urlSearchParams.set(param, value);
    } else if (Array.isArray(value)) {
      value.forEach((v) => {
        urlSearchParams.append(param, v);
      });
    }
    navigate({ search: urlSearchParams.toString() });
  };
};
