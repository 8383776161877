import * as TK from 'components/About/translations/locale.constants';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { icon } from 'components/PDP/GoBack/styles';
import { theme } from 'utils/theme/theme';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { FC } from 'react';
import i18next from 'i18next';

export const wrapper: ThemedSxProps = () => ({
  display: 'flex',
  height: '18px',
  '& a': { fontSize: 12 },
  paddingTop: '16px',
  width: ['320px', '688px', '1060px'],
  margin: '0 auto',
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
});
export const link: ThemedSxProps = () => ({
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const WhoWeAreBreadcrumb: FC = () => {
  return (
    <Box sx={wrapper}>
      <Link target="_parent" href="/">
        <ArrowBackIosNewIcon
          style={{
            width: '18px',
            height: '18px',
            marginRight: 0,
            color: '#000',
          }}
          sx={icon}
          role="presentation"
        />
      </Link>
      <Breadcrumbs>
        <Link
          className="link"
          sx={link}
          href="/"
          style={{
            fontSize: '14px',
            fontWeight: '500',
          }}
          data-test="back-breadcrumb"
        >
          {i18next.t(TK.BACK_LINK)}
        </Link>
        <Link
          className="link"
          sx={link}
          href="/"
          style={{
            fontSize: '14px',
            fontWeight: '500',
          }}
          data-test="back-breadcrumb"
        >
          {i18next.t(TK.HOME_LINK)}
        </Link>
        <Link
          className="link"
          sx={link}
          target="_parent"
          href="/about/who-we-are/"
          style={{ color: colors.primaryRed }}
          data-test="breadcrumb"
        >
          <Typography style={{ fontWeight: '500' }}>
            {i18next.t(TK.WHO_WE_ARE_LINK)}
          </Typography>
        </Link>
      </Breadcrumbs>
    </Box>
  );
};
