import * as TK from 'translations/locales/translation-keys.constant';
import * as alertStyles from 'components/Session/Alert/styles';
import {
  ButtonBase,
  FormControlLabel,
  IconButton,
  MenuItem,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import { FreeDeliveryMessage } from 'components/Cart/Details/free-delivery-message';
import { GuidePopover } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/components/GuidePopover/GuidePopover';
import { Menu } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/styled';
import { MenuRadio } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/components/MenuRadio';
import { Recipient } from 'api/session/types/recipient.interface';
import { SHORT_DATE_FORMAT } from 'components/Session/Alert/constants';
import { batch, useDispatch, useSelector } from 'react-redux';
import { checkIsEmptyResult } from 'components/PLP/ArrangementsList/feature/selectors';
import { format } from 'date-fns/esm';
import { getTitleKey } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/translations/get-title';
import { isPositive } from 'utils/numbers';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import {
  resetAvailabilitySession,
  updateAvailabilitySession,
} from 'redux/session/availability/availability.action';
import {
  selectIsSessionPending,
  selectIsStoreOrRecipientLock,
} from 'redux/session/availability/availability.selectors';
import { selectRecipientAvailability } from 'components/Session/Alert/feature/selectors';
import { selectRecipientId } from 'components/Session/feature/selectors';
import { selectRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import { useGuide } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/hooks/use-guide';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Lock from '@mui/icons-material/Lock';
import React, { FC, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';

const NEW_RECIPIENT_VALUE = 0;
const GUIDE_POPPER_TIMEOUT = 7000;

interface Props {
  pdpRecipientLock?: boolean;
}

export const MultipleRecipientLock: FC<Props> = ({
  children,
  pdpRecipientLock,
}) => {
  const dispatch = useDispatch();
  const isEmptyResult = useSelector(checkIsEmptyResult);

  const recipients = useSelector(selectRecipients);
  const recipientAvailability = useSelector(selectRecipientAvailability);
  const recipientId = useSelector(selectRecipientId);
  const isSessionPending = useSelector(selectIsSessionPending);
  const isStoreOrRecipientLock = useSelector(selectIsStoreOrRecipientLock);

  const currentRecipientValue = isPositive(recipientId)
    ? recipientId
    : NEW_RECIPIENT_VALUE;

  const buttonRef = useRef<HTMLDivElement>(null);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { close: closeGuide, isOpened: isGuideOpened } = useGuide({
    isMenuOpened,
  });

  const onClose = () => setIsMenuOpened(false);

  const onSelectNewRecipient = () => {
    batch(() => {
      dispatch(pas.reset());
      dispatch(resetAvailabilitySession());
    });
    onClose();
  };

  useEffect(() => {
    if (isEmptyResult && isStoreOrRecipientLock) {
      batch(() => {
        dispatch(pas.reset());
        dispatch(resetAvailabilitySession());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyResult]);

  const onSelectRecipient = ({ id, orderType, storeId, area }: Recipient) => {
    dispatch(
      updateAvailabilitySession({
        currentRecipientId: id,
        serviceOption: orderType,
        areaId: area.id,
        areaName: area.name,
        storeId,
      }),
    );
    onClose();
  };

  const isAnyDeliveryAndNextDay = sessionStorage.getItem(
    'isAnyDeliveryAndNextDay',
  );
  const CartMerchandiseTotal = Number(
    sessionStorage.getItem('CartMerchandiseTotal'),
  );

  const isUnlockFree =
    CartMerchandiseTotal > 0 && isAnyDeliveryAndNextDay === 'true';

  const isPdpUnlockMessage = isUnlockFree && pdpRecipientLock;

  return (
    <>
      <ButtonBase
        ref={buttonRef}
        component="div"
        role="button"
        id="recipients-menu"
        sx={alertStyles.alert}
        style={{ height: pdpRecipientLock ? '64px' : '54px' }}
        disabled={isSessionPending}
        onClick={() => setIsMenuOpened(true)}
      >
        {isSessionPending ? (
          <Skeleton variant="text" sx={{ width: [1, 200, 1] }} />
        ) : (
          <>
            {currentRecipientValue === NEW_RECIPIENT_VALUE ||
            pdpRecipientLock ? (
              <AccountCircleIcon sx={alertStyles.lockIcon} />
            ) : (
              <Lock sx={alertStyles.lockIcon} />
            )}
          </>
        )}
        <Typography variant="subtitle2" sx={alertStyles.lockDetails}>
          {children}
        </Typography>
        <Typography
          variant="subtitle2"
          display={pdpRecipientLock ? 'inline' : 'none'}
          sx={alertStyles.recipientChange}
        >
          Change Recipient
        </Typography>
        <IconButton
          aria-label={i18next.t(TK.SWITCH_RECIPIENT)}
          sx={{ p: 0, ml: 0 }}
          onTouchStart={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          disabled={isSessionPending}
        >
          <KeyboardArrowDownIcon
            sx={{
              transition: 'transform .15s',
              transform: isMenuOpened ? 'rotate(180deg)' : 'rotate(0)',
            }}
          />
        </IconButton>
      </ButtonBase>
      <FreeDeliveryMessage isUnlockPdpMessage={isPdpUnlockMessage} />
      <Menu
        id="recipients-dropdown"
        MenuListProps={{ 'aria-labelledby': 'recipients-menu' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={buttonRef.current}
        open={isMenuOpened}
        onClose={onClose}
        width={buttonRef.current?.offsetWidth}
      >
        <Typography variant="subtitle2" m={2}>
          {i18next.t(TK.MENU_TITLE)}
        </Typography>
        <RadioGroup
          sx={{ maxHeight: 375, overflowY: 'auto', flexWrap: 'nowrap' }}
          value={currentRecipientValue}
        >
          {recipients.map(
            (recipient) =>
              recipientAvailability?.includes(recipient.id) && (
                <MenuItem
                  key={recipient.id}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectRecipient(recipient);
                  }}
                >
                  <FormControlLabel
                    value={recipient.id}
                    control={<MenuRadio />}
                    label={i18next.t(getTitleKey(recipient.orderType), {
                      name: recipient.name,
                      date: format(
                        new Date(recipient.fulfillmentDate),
                        SHORT_DATE_FORMAT,
                      ),
                    })}
                  />
                </MenuItem>
              ),
          )}
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              onSelectNewRecipient();
            }}
          >
            <FormControlLabel
              value={NEW_RECIPIENT_VALUE}
              control={<MenuRadio />}
              label={
                <>
                  <Typography>{i18next.t(TK.NEW_RECIPIENT)}</Typography>
                  <Typography variant="body3" color="text.secondary" mt={0.5}>
                    {i18next.t(TK.NEW_RECIPIENT_SUBTITLE)}
                  </Typography>
                </>
              }
            />
          </MenuItem>
        </RadioGroup>
      </Menu>
      {buttonRef.current && (
        <GuidePopover
          anchorElement={buttonRef.current}
          open={isGuideOpened}
          onClose={closeGuide}
          timeout={GUIDE_POPPER_TIMEOUT}
        />
      )}
    </>
  );
};
