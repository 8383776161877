import { Typography } from '@mui/material';
import { colors } from 'utils/theme/colors';
import { theme } from 'utils/theme/theme';
import styled from '@emotion/styled/macro';

export const ItemImageWrapper = styled('div')({
  backgroundColor: colors.white,
  paddingTop: '100%',
  position: 'relative',
  '& > div': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export const ItemImageWrapperInterrupter = styled('div')({
  backgroundColor: colors.white,
  paddingTop: '100%',
  position: 'relative',
  '& > div': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export const ArrangementWrapper = styled('div')();

export const ProductTextWrapper = styled('div')({
  width: '100%',
  marginTop: theme.spacing(2),
  padding: theme.spacing(0, 1),
});

export const Name = styled(Typography)({
  marginTop: theme.spacing(1),
  wordWrap: 'break-word',
  paddingBottom: theme.spacing(0.5),

  '& sup': {
    verticalAlign: 'top',
    fontSize: '16px',
    lineHeight: '22px',
  },
});
