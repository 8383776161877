export enum NavigationTypes {
  FILTERS = 'filters',
  MENUS = 'menus',
  LINK = 'link',
}

export type Navigation = {
  name: string;
  url: string;
};

export type NavigationGroups = {
  name: string;
  navigations: Navigation[];
};

export type Menu = {
  name: string;
  type: NavigationTypes;
  url?: string;
  groups?: NavigationGroups[];
};
