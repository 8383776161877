import * as TK from 'components/PLP/CallCenter/translations/constants';

export const callCenterModalsCommonLocale = {
  [TK.WELCOME_TITLE]: 'Welcome {{clerkName}}',
  [TK.WELCOME_MESSAGE]:
    'If this is your name then please click Continue to take order otherwise click Login to recreate your session',
  [TK.WELCOME_LOGGED_MESSAGE]:
    'Thank you for creating your session. Please click Continue to take order.',
  [TK.WELCOME_CONTINUE]: 'Continue',
  [TK.WELCOME_LOGIN]: 'Login',
  [TK.ERROR_TITLE]: 'Sorry, something went wrong',
  [TK.ERROR_MESSAGE]: 'Connect with you manager or/and development team',
  [TK.ERROR_CLOSE]: 'Close',
};
