import * as styles from 'containers/RewardsContainer/rewards-sign-up-container.styles';
import { Grid } from '@mui/material';
import { RewardsSignUp } from 'components/Rewards/RewardSignup';
import React, { FC } from 'react';

export const RewardsSignUpContainer: FC = () => (
  <Grid sx={styles.containerWrapper}>
    <Grid sx={styles.main}>
      <RewardsSignUp />
    </Grid>
  </Grid>
);
