import {
  DynamicYieldSortBy,
  DynamicYieldSortOrder,
  DynamicYieldSortType,
} from 'service/dynamic-yield/dy-types';
import { SortType } from 'components/PLP/FilterSortMenu/enums/sort-type.enum';

export const createProductFeedSku = (
  id: number | string,
  sizeName: string,
): string => `${id}-${sizeName}`.trim().replaceAll(' ', '');

export const getDynamicYieldSortData = (
  selectedSortOption: SortType,
): DynamicYieldSortType => {
  const dynamicYieldSortOptions = {
    [SortType.Relevance]: {
      sortOrder: DynamicYieldSortOrder.DESC,
      sortBy: DynamicYieldSortBy.RELEVANCE,
    },
    [SortType.PriceHigh2Low]: {
      sortOrder: DynamicYieldSortOrder.DESC,
      sortBy: DynamicYieldSortBy.PRICE,
    },
    [SortType.PriceLow2High]: {
      sortOrder: DynamicYieldSortOrder.ASC,
      sortBy: DynamicYieldSortBy.PRICE,
    },
  };
  return dynamicYieldSortOptions[selectedSortOption];
};

export const prepareDinamicYieldConsent = (isAccepted: boolean): void => {
  window.DY.userActiveConsent = { accepted: isAccepted };
};

export const updateDynamicYieldConsent = (isAccepted: boolean): void => {
  prepareDinamicYieldConsent(isAccepted);
  window.DYO.ActiveConsent.updateConsentAcceptedStatus(isAccepted);
};
