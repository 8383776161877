import { BVSocial } from 'components/Home/BVSocial';
import { Banner } from 'components/Home/Banner';
import { BannerProductCarousel } from 'components/Home/BannerProductCarousel';
import { CarouselBanners } from 'components/Home/CarouselBanners';
import { CarouselDetailed } from 'components/Home/CarouselDetailed';
import { CarouselSimplified } from 'components/Home/CarouselSimplified';
import { ChipWidget } from 'components/Home/ChipWidget';
import { ComponentProps } from 'components/Home/types';
import { Components } from 'enums/home-components';
import { Fragment, FunctionComponent } from 'react';
import { GeneralText } from 'components/Home/GeneralText';
import { GridWidget } from 'components/Home/GridWidget';
import { Locale } from 'enums/locale';
import { NotificationBanner } from 'components/Home/NotificationBanner';
import { SecondaryBanner } from 'components/Home/SecondaryBanner';
import { VideoText } from 'components/Home/VideoText';
import { loadLocale } from 'redux/locale/utils';

const isFrench = loadLocale() === Locale.FR_CA;

export const getComponentByType = (
  type: keyof typeof Components,
): FunctionComponent<ComponentProps> => {
  const components = {
    [Components.banner]: Banner,
    [Components.secondaryBanner]: SecondaryBanner,
    [Components.bannerWithProductList]: BannerProductCarousel,
    [Components.carouselBanners]: CarouselBanners,
    [Components.carouselDetailed]: CarouselDetailed,
    [Components.carouselSimplified]: CarouselSimplified,
    [Components.gridWidget]: GridWidget,
    [Components.chipWidget]: ChipWidget,
    [Components.homepage]: Fragment,
    [Components.generalText]: GeneralText,
    [Components.notificationBanner]: NotificationBanner,
    [Components.bvsocial]: BVSocial,
    [Components.videoWithText]: VideoText,
  };

  return components[type] || Fragment;
};

export const checkForFrench = (
  englishText: string | undefined,
  frenchText: string | undefined,
): string => {
  if (isFrench && frenchText && frenchText.length > 0) {
    return frenchText;
  }
  if (englishText) {
    return englishText;
  }
  return '';
};
