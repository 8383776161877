export const SAME_DAY_DELIVERY_GET_IT_TODAY =
  'sameDayDeliveryCommonLocale.get_it_today';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS_AND_MINUTES =
  'sameDayDeliveryCommonLocale.order_within_hours_and_minutes';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS_AND_MINUTE =
  'sameDayDeliveryCommonLocale.order_within_hours_and_minute';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR_AND_MINUTES =
  'sameDayDeliveryCommonLocale.order_within_hour_and_minutes';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR_AND_MINUTE =
  'sameDayDeliveryCommonLocale.order_within_hour_and_minute';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOUR =
  'sameDayDeliveryCommonLocale.order_within_hour';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_MINUTES =
  'sameDayDeliveryCommonLocale.order_within_minutes';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_HOURS =
  'sameDayDeliveryCommonLocale.order_within_hours';
export const SAME_DAY_DELIVERY_ORDER_WITHIN_MINUTE =
  'sameDayDeliveryCommonLocale.order_within_minute';
