import * as LTK from 'translations/locales/translation-keys.constant';
import * as TK from 'components/Subscription/translations/locale.constants';
import * as styles from 'components/Subscription/EmailSubscriptionDialog/SubscriptionForm/styles';
import * as yup from 'yup';
import { Box, Button, TextField } from '@mui/material';
import {
  EmailSubscriptionFormData,
  SubscriptionFormData,
} from 'components/Subscription/types';
import { SectionType } from 'components/Subscription/EmailSubscriptionDialog/enums/email-subscription.enum';
import { analytics } from 'service/adobe-analytics/analytics';
import { dataLayer } from 'hooks/google/data-layer';
import { dynamicEvents } from 'service/dynamic-yield';
import { emailSubscriptionFormSchema } from 'components/Subscription/schemas/email-subscription-form.schema';
import { getEmailSubscriptionStatus } from 'components/Subscription/feature/selectors';
import { isFulfilled } from 'utils/status.comparer';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { subscribe } from 'components/Subscription/feature/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

interface SubscriptionFormProps {
  onSuccess: () => void;
}

const resolver = yupResolver<yup.SchemaOf<EmailSubscriptionFormData>>(
  emailSubscriptionFormSchema,
);

const defaultValues: EmailSubscriptionFormData = {
  email: '',
};

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ onSuccess }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
  } = useForm<EmailSubscriptionFormData>({
    reValidateMode: 'onSubmit',
    defaultValues,
    resolver,
  });
  const status = useSelector(getEmailSubscriptionStatus);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  useEffect(() => {
    if (isSubmitSuccessful && isFulfilled(status)) {
      dataLayer.push({
        event: 'newSubscriptionSignUp',
        newSubscriptionSignUp: {
          email: getValues('email'),
          path: pathname,
        },
      });
      reset(defaultValues);
      onSuccess();
      segment.marketingOptIn(
        getValues('email'),
        'Email',
        userSession,
        userAccountInformation,
      );
      segment.identify(getValues('email'));
      dynamicEvents.identifyUser(getValues('email'));
      analytics.promoSignUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isSubmitSuccessful, onSuccess, reset]);

  const onSubmit = (data: SubscriptionFormData) => {
    dispatch(subscribe({ ...data, section: SectionType.CouponSignUp }));
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue('email', newValue);
    clearErrors('email');
  };

  return (
    <Box
      component="form"
      id="email-subscription-form"
      display="flex"
      alignItems="center"
      sx={styles.emailBoxcon}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        sx={styles.textField}
        {...register('email', { required: true })}
        onChange={handleEmailChange}
        variant="outlined"
        color={!errors?.email?.message ? 'secondary' : 'error'}
        autoComplete="off"
        name="email"
        placeholder={i18next.t(TK.SUBSCRIPTION_EMAIL_FORM_PLACEHOLDER)}
        error={!!errors?.email?.message}
        helperText={errors?.email?.message ? 'Enter a valid email address' : ''}
        data-test="sign-up-email-address"
        value={watch('email')}
        label={i18next.t(TK.SUBSCRIPTION_EMAIL_FORM_LABEL)}
      />
      <Button
        sx={styles.button}
        disableRipple
        type="submit"
        variant="contained"
        size="small"
        color="primary"
        disabled={!!errors?.email?.message}
        data-test="subscription-form-submit"
      >
        {i18next.t(LTK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL)}
      </Button>
    </Box>
  );
};
