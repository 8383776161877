import {
  Select as MuiSelect,
  NativeSelect,
  NativeSelectProps,
  SelectProps,
} from '@mui/material';
import { isIE } from 'utils/isIE';
import React, { ChangeEventHandler, FC, forwardRef } from 'react';

export type SelectChangeEventHandler = ChangeEventHandler<HTMLSelectElement> &
  SelectProps['onChange'];

export const Select: FC<NativeSelectProps & SelectProps> = forwardRef(
  (props, ref) =>
    isIE ? (
      <NativeSelect {...props} ref={ref} />
    ) : (
      <MuiSelect {...props} ref={ref} />
    ),
);
