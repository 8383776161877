import { MenuItem, MenuItemProps } from '@mui/material';
import { isIE } from 'utils/isIE';
import React, { FC } from 'react';

export const Option: FC<MenuItemProps> = (props) => {
  const { value, children } = props;

  return isIE ? (
    <option value={value}>{children}</option>
  ) : (
    <MenuItem {...props} />
  );
};
