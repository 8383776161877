import { arrangementApiService } from 'api/arrangements/arrangement.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchArrangements = createAsyncThunk(
  'business-gifting/arrangements',
  (limit?: number) =>
    arrangementApiService
      .find({
        filterIds: window.BUSINESS_GIFTS_ARRANGEMENT_GROUP_ID.toString(),
        limit,
      })
      .then(({ data }) => data),
);
