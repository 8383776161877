import * as styles from 'components/SkinnyBannerV2/components/CarouselItem/style';
import { Box, Link } from '@mui/material';
import { SkinnyBannerItem } from 'api/skinny-banner.api.service';
import { SkinnyMessage } from 'components/SkinnyBannerV2/components/CarouselItem/skinnyMessage';
import { checkForFrench } from 'components/Home/utils';
import { isUrlToAnotherResource } from 'utils/router';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface CarouselItemProps {
  item: SkinnyBannerItem;
  onClickAnalytics: () => void;
}

export const CarouselItem: FC<CarouselItemProps> = ({
  item: { url, editorContent, frEditorContent },
  onClickAnalytics,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const bannerClickHandler = (urlValue: string): void => {
    segment.bannerClicked(
      urlValue,
      editorContent,
      'Skinny Banner',
      userSession,
      userAccountInformation,
    );
    if (isUrlToAnotherResource(url)) {
      window.open(urlValue, '_blank');
    }
    onClickAnalytics();
  };
  return (
    <Box sx={styles.skinnyBanner} data-test="Skinny-Banner-widget-box">
      {url ? (
        <Link
          sx={styles.skinnyBannerLink}
          href={url}
          onClick={() => bannerClickHandler(url)}
          data-test="Skinny-Banner-widget"
        >
          <SkinnyMessage
            editorContent={checkForFrench(editorContent, frEditorContent)}
          />
        </Link>
      ) : (
        <Box
          sx={styles.skinnyBannerLink}
          onClick={() => bannerClickHandler(url)}
          data-test="Skinny-Banner-widget"
        >
          <SkinnyMessage
            editorContent={checkForFrench(editorContent, frEditorContent)}
          />
        </Box>
      )}
    </Box>
  );
};
