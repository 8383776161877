import { ResponseError } from 'types/error.types';
import { State, Status } from 'redux/types/state.interface';
import { Store } from 'components/PDP/Order/RecipientForm/PickupForm/types/store.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getStore } from 'components/PDP/Order/RecipientForm/PickupForm/feature/actions';

const initialState: State<Store> = {
  data: {
    id: 0,
    number: 0,
    areaName: '',
    plaza: '',
    address: '',
    additionalAddress: '',
    phone: '',
    certifications: 0,
    curbside: false,
    city: '',
    smoothie: false,
    schedule: [
      {
        weekday: '',
        hours: '',
      },
    ],
    timeZoneOffset: 0,
    dayLightSavingOffset: 0,
  },
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'pickupStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStore.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(getStore.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.FULFILLED;
      })
      .addCase(getStore.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.status = Status.ERROR;
      })
      .addDefaultCase((state) => state);
  },
});

export const pickupStoreReducer = slice.reducer;
