import { checkIsUnavailableBySchedule } from 'components/PDP/Arrangement/feature/selector';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useRedirectAfterInactiveDelay = (): void => {
  const navigate = useNavigate();
  const unavailable = useSelector(checkIsUnavailableBySchedule);

  useEffect((): (() => void) => {
    if (!unavailable) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      navigate('/all-fruit-arrangements');
    }, 5000);

    const clearRedirect = () => {
      window.clearTimeout(timeout);
    };
    document.addEventListener('click', clearRedirect, { once: true });

    return () => {
      window.clearTimeout(timeout);
      document.removeEventListener('click', clearRedirect);
    };
  }, [unavailable]);
};
