export enum OwnBoxTypes {
  NONE,
  DIPPED_FRUIT,
  FRUIT,
  DESSERT,
  COOKIE_BOX,
  COOKIE_BOX_LIMITED,
  DESSERT_BOARD,
  DESSERT_BOARD_DIPPED_BERRIES,
  DESSERT_BOARD_DIPPED_FRUIT,
  DESSERT_BOARD_BACKEDGOODS_DIPPEDFRUIT,
}
