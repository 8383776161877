import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/Careers/styles';
import { Box } from '@mui/system';
import {
  CarouselContainer,
  CarouselWrapper,
} from 'components/About/Careers/components/styles';
import { Image } from 'components/common/Image/Image';
import { Typography } from '@mui/material';
import {
  careerJobReviewsFirst,
  careerJobReviewsSecond,
} from 'utils/cloudinaryAssets';
import { settings } from 'components/About/Careers/components/settings';
import React, { FC } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

export const Testimonials: FC = () => {
  return (
    <Box sx={styles.careerJobReviews}>
      <CarouselWrapper data-test="carousel-detailed-container">
        <CarouselContainer data-test="carousel-detailed">
          <Box>
            <Slider {...settings}>
              <Box sx={styles.careerJobReviewsItems}>
                <Image
                  src={careerJobReviewsFirst}
                  height="102px"
                  width="102px"
                />
                <Typography className="reviews">
                  {i18next.t(TK.CAREER_JOB_REVIEWS_FIRST)}
                </Typography>
                <Typography>
                  {i18next.t(TK.CAREER_JOB_REVIEWS_NAME_FIRST)}
                </Typography>
                <Typography>
                  {i18next.t(TK.CAREER_JOB_REVIEWS_DESIGNATION_FIRST)}
                </Typography>
              </Box>
              <Box sx={styles.careerJobReviewsItems}>
                <Image
                  src={careerJobReviewsSecond}
                  height="102px"
                  width="102px"
                />
                <Typography className="reviews">
                  {i18next.t(TK.CAREER_JOB_REVIEWS_SECOND)}
                </Typography>
                <Typography>
                  {i18next.t(TK.CAREER_JOB_REVIEWS_NAME_SECOND)}
                </Typography>
                <Typography>
                  {i18next.t(TK.CAREER_JOB_REVIEWS_DESIGNATION_SECOND)}
                </Typography>
              </Box>
            </Slider>
          </Box>
        </CarouselContainer>
      </CarouselWrapper>
    </Box>
  );
};
