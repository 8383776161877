import * as TK from 'translations/locales/translation-keys.constant';
import { Alert, InputAdornment, TextField, Typography } from '@mui/material';
import { MAX_SYMBOLS_ALLOWED } from 'components/PDP/Order/common/components/LimitedTextField/constants';
import {
  MessageCounter,
  getInputAdornmentSx,
  inputAdornmentAlert,
  textField,
  textFieldMessage,
} from 'components/PDP/Order/common/components/LimitedTextField/limited-text-field.styles';
import { breakLargeWords } from 'components/PDP/Order/common/components/LimitedTextField/utils/decorators/break-large-words.decorator';
import {
  checkForEmojis,
  checkForSpecialCharacters,
} from 'utils/string/special-characters';
import { colors } from 'utils/theme/colors';
import { combineDecorators } from 'utils/decorator';
import { cutLargeText } from 'components/PDP/Order/common/components/LimitedTextField/utils/decorators/cut-large-text.decorator';
import React, { ChangeEvent, FC, RefObject, useEffect, useState } from 'react';
import i18next from 'i18next';

interface LimitedTextFieldProps {
  value: string;
  label: string;
  placeholder?: string;
  chars: number;
  max_chars_allowed?: number;
  reaching_text_trigger?: number;
  rows: number;
  charsPerLine: number;
  isEmptyFieldError?: boolean;
  emptyFieldMessage?: string;
  isEmptyFieldMessageError?: boolean;
  setValue: (str: string) => void;
  onBlur: (str: string) => void;
  onLinesNumberChanged: (lines: number) => void;
  inputRef: RefObject<HTMLTextAreaElement>;
  autoFocus?: boolean;
  dataTest?: string;
  fieldName?: string;
  setSaveBtnDisable?: (val: boolean) => void;
}

const getTextLinesCount = (text: string): number => text.split('\n').length;

export const LimitedTextField: FC<LimitedTextFieldProps> = ({
  value,
  label,
  placeholder,
  chars,
  max_chars_allowed,
  reaching_text_trigger,
  rows,
  charsPerLine,
  isEmptyFieldError,
  isEmptyFieldMessageError,
  emptyFieldMessage,
  setValue,
  onBlur,
  onLinesNumberChanged,
  inputRef,
  autoFocus = false,
  dataTest,
  setSaveBtnDisable,
  fieldName,
}) => {
  const [linesNumber, setLinesNumber] = useState<number>(1);

  useEffect(() => {
    setLinesNumber(getTextLinesCount(value));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value]);

  useEffect(() => {
    if (value === '' && linesNumber !== 1) {
      onLinesNumberChanged(1);
      setLinesNumber(1);
    }
    onLinesNumberChanged(linesNumber);
  }, [value, linesNumber, onLinesNumberChanged]);

  const getHelperText = () => {
    if (linesNumber >= rows) {
      return i18next.t(TK.LIMITED_LINES_HELPER_TEXT);
    }
    if (isEmptyFieldError) {
      return i18next.t(TK.FIELD_REQUIRED_HELPER_TEXT);
    }
    if (isEmptyFieldMessageError) {
      return emptyFieldMessage;
    }
    if (checkForSpecialCharacters(value)) {
      return i18next.t(TK.SPL_CHARS_ERROR);
    }
    if (checkForEmojis(value)) {
      return i18next.t(TK.EMOJIS_ENTERED);
    }
    if (value.length > chars) {
      return i18next.t(TK.REDUCE_MESSAGE_LENGTH_HELPER_TEXT);
    }
    return null;
  };

  const isShowInputAlerts = reaching_text_trigger
    ? (value.length > chars - reaching_text_trigger && value.length < chars) ||
      linesNumber >= rows
    : linesNumber >= rows;

  const isError =
    isEmptyFieldError ||
    isEmptyFieldMessageError ||
    value.length > chars ||
    linesNumber > rows ||
    checkForSpecialCharacters(value) ||
    checkForEmojis(value);

  const forceLimits = combineDecorators(
    breakLargeWords(charsPerLine),
    cutLargeText(max_chars_allowed || MAX_SYMBOLS_ALLOWED),
  );

  return (
    <>
      <TextField
        autoFocus={autoFocus}
        inputRef={inputRef}
        label={label}
        placeholder={placeholder || label}
        helperText={getHelperText()}
        multiline
        rows={rows}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setValue(forceLimits(event.target.value));
          if (event.target.value.trim().length === 0 && setSaveBtnDisable)
            setSaveBtnDisable(true);
          if (
            (event.target.value.trim().length !== 0 ||
              event.target.value === '') &&
            setSaveBtnDisable
          )
            setSaveBtnDisable(false);
        }}
        onBlur={(event) => onBlur(event.target.value)}
        value={value}
        error={isError}
        sx={fieldName && fieldName === 'message' ? textFieldMessage : textField}
        InputLabelProps={{
          sx: {
            fontSize: 14,
            ml: -1,
          },
          style: {
            color: isError ? colors.errorLight : colors.placeholderText,
          },
        }}
        InputProps={{
          inputProps: {
            'data-test': dataTest,
          },
          endAdornment: (
            <InputAdornment
              position="end"
              component="div"
              sx={getInputAdornmentSx(isShowInputAlerts)}
            >
              {linesNumber > rows && (
                <Alert
                  severity="error"
                  variant="filled"
                  sx={inputAdornmentAlert}
                >
                  <Typography
                    variant="body3"
                    data-test="limited-text-field-lines-limit-exceeded"
                  >
                    {i18next.t(TK.LINES_LIMIT_EXCEEDED)}
                  </Typography>
                </Alert>
              )}
              {linesNumber === rows && (
                <Alert severity="warning" sx={inputAdornmentAlert}>
                  <Typography
                    variant="body3"
                    data-test="limited-text-field-last-line"
                  >
                    {i18next.t(TK.TYPING_LAST_LINE)}
                  </Typography>
                </Alert>
              )}
              {reaching_text_trigger &&
                value.length > chars - reaching_text_trigger &&
                value.length < chars && (
                  <Alert severity="warning" sx={inputAdornmentAlert}>
                    <Typography variant="body3" data-test="reaching-last-line">
                      {i18next.t(TK.REACHING_LAST_LINE)}
                    </Typography>
                  </Alert>
                )}
              <MessageCounter isError={value.length > chars}>
                {value.length}/{chars}
              </MessageCounter>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
