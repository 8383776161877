import * as TK from 'components/Authentication/translations/locale.constants';
import { Button, CircularProgress, TextField } from '@mui/material';
import { checkExistingEmailState } from 'components/Payment/BillingInformation/Options/feature/selectors';
import {
  checkIsLockedType,
  checkIsProcessing,
  checkIsTruthyType,
  selectLogInIsFulfilled,
} from 'components/Authentication/LogIn/feature/selectors';
import { dataLayer } from 'hooks/google/data-layer';
import { isNotUndefined } from 'utils/is-not-undefined';
import { logIn } from 'components/Authentication/LogIn/feature/log-in.action';
import { schema } from 'components/Authentication/LogIn/schema';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useOpenResetPasswordDialog } from 'components/Authentication/hooks/control';
import { validate } from 'components/Authentication/LogIn/feature/validate.action';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

type LogInFormValues = { email: string; password: string };

export const LogInForm: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const processing = useSelector(checkIsProcessing);
  const locked = useSelector(checkIsLockedType);
  const truthy = useSelector(checkIsTruthyType);
  const success = useSelector(selectLogInIsFulfilled);
  const checkExistingEmail = useSelector(checkExistingEmailState);
  const openResetPasswordDialog = useOpenResetPasswordDialog();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LogInFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: checkExistingEmail,
    },
  });

  useEffect(() => {
    if (truthy) {
      dispatch(logIn(getValues()));
    }
  }, [dispatch, getValues, truthy]);

  useEffect(() => {
    if (success) {
      dataLayer.push({
        event: 'userLogin',
        userLogin: {
          email: getValues('email'),
          path: pathname,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onSubmit = handleSubmit((data) => dispatch(validate(data)));

  return (
    <form onSubmit={onSubmit}>
      <TextField
        {...register('email')}
        error={isNotUndefined(errors?.email?.message)}
        helperText={errors?.email?.message && i18next.t(errors?.email?.message)}
        disabled={processing || locked}
        sx={{ mt: 1 }}
        label={i18next.t(TK.AUTH_LOG_IN_FORM_EMAIL_LABEL)}
        type="email"
        placeholder="email@email.com"
        fullWidth
        autoFocus
      />
      <TextField
        {...register('password')}
        error={isNotUndefined(errors?.password?.message)}
        helperText={
          errors?.password?.message && i18next.t(errors?.password?.message)
        }
        disabled={processing || locked}
        sx={{ mt: 3 }}
        label={i18next.t(TK.AUTH_LOG_IN_FORM_PASSWORD_LABEL)}
        type="password"
        placeholder={i18next.t(TK.AUTH_LOG_IN_FORM_PASSWORD_PLACEHOLDER)}
        fullWidth
      />
      <Button
        onClick={openResetPasswordDialog}
        sx={{ mt: 3, textDecoration: 'underline' }}
        variant="text"
        color="primary"
        fullWidth
      >
        {i18next.t(TK.AUTH_LOG_IN_FORM_RESET_LABEL)}
      </Button>
      <Button
        disabled={processing || locked}
        startIcon={
          processing ? (
            <CircularProgress size={20} color="inherit" />
          ) : undefined
        }
        sx={{ mt: 6 }}
        type="submit"
        variant="contained"
        size="large"
        fullWidth
        data-test="sign-in-btn"
      >
        {i18next.t(
          (processing && TK.AUTH_LOG_IN_FORM_SIGNING_IN) ||
            (success && TK.AUTH_LOG_IN_FORM_SUCCESS) ||
            TK.AUTH_LOG_IN_FORM_SIGN_IN,
        )}
      </Button>
    </form>
  );
};
