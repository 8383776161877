export const HOME = 'legal_page.edible_home_page';
export const LEGAL_PAGE = 'legal_page.edible_legal_page';
export const LEGAL_META_TITLE_TAG = 'legal_page.edible_legal_meta_title_tag';

export const shipmentPolicy = {
  TITLE: 'legal_page.title',
  SUBTITLE: 'legal_page.subtitle',
  ONE: 'legal_page.one',
  TWO: 'legal_page.two',
  THREE: 'legal_page.three',
  FOUR: 'legal_page.four',
  FIVE: 'legal_page.five',
  SIX: 'legal_page.six',
  SEVEN: 'legal_page.seven',
  EIGHT: 'legal_page.eight',
  NINE: 'legal_page.nine',
  TEN: 'legal_page.ten',
};

export const deliveryPolicy = {
  TITLE: 'legal_page.delivery_title',
  SUBTITLE: 'legal_page.delivery_subtitle',
  ONE: 'legal_page.delivery_one',
  TWO: 'legal_page.delivery_two',
  THREE: 'legal_page.delivery_three',
  FOUR: 'legal_page.delivery_four',
  FIVE: 'legal_page.delivery_five',
  SIX: 'legal_page.delivery_six',
  SEVEN: 'legal_page.delivery_seven',
  EIGHT: 'legal_page.delivery_eight',
  NINE: 'legal_page.delivery_nine',
};

export const californiaPolicy = {
  TITLE: 'legal_page.california_title',
  SUBTITLE: 'legal_page.california_subtitle',
  ONE: 'legal_page.california_one',
  TWO: 'legal_page.california_two',
  THREE: 'legal_page.california_three',
  FOUR: 'legal_page.california_four',
  FIVE: 'legal_page.california_five',
  SIX: 'legal_page.california_six',
  SEVEN: 'legal_page.california_seven',
  EIGHT: 'legal_page.california_eight',
  NINE: 'legal_page.california_nine',
};

export type PolicyContent = {
  TITLE: string;
  SUBTITLE: string;
  ONE: string;
  TWO: string;
  THREE: string;
  FOUR: string;
  FIVE: string;
  SIX: string;
  SEVEN: string;
  EIGHT: string;
  NINE: string;
  TEN?: string;
};
