import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/styles';
import { Box, RadioGroup, Typography } from '@mui/material';
import { NonFlexFee } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/Fees/NonFlexFee';
import { RadioButton } from 'components/common/Buttons/RadioButton/RadioButton';
import { addDays, format } from 'date-fns';
import { dateToSQLDateString } from 'utils/date';
import { isCanada } from 'utils/country';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, MutableRefObject } from 'react';
import i18next from 'i18next';

type Props = {
  todaysDate: Date;
  dateSelected: Date | null;
  daysToShow: number;
  sameDayDeliveryFee: number;
  setNewDate: (date: Date) => void;
  isFulfillmentAvailable: boolean;
  collectionPromptRef?: MutableRefObject<HTMLInputElement | null>;
  selectedProductPrice?: number;
};

export const NonFlexFeeDates: FC<Props> = ({
  todaysDate,
  dateSelected,
  daysToShow,
  sameDayDeliveryFee,
  setNewDate,
  isFulfillmentAvailable,
  collectionPromptRef,
  selectedProductPrice,
}) => {
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const dateStringArray: string[] = [];
  for (let i = 0; i < daysToShow; ) {
    dateStringArray.push(addDays(todaysDate, i).toString());
    i += 1;
  }

  return (
    <>
      <Box ref={collectionPromptRef}>
        {isFulfillmentAvailable ? (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              {i18next.t(TK.FLEX_DELIVERY_DATES)}
            </Typography>
            <Typography variant="body2">
              {i18next.t(TK.FLEX_DELIVERY_DATES_SUBTITLE)}
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ mt: 3, mb: 2 }} variant="subtitle1">
            {i18next.t(TK.CHOOSE_ANOTHER_DATE)}
          </Typography>
        )}
        <RadioGroup
          value={dateSelected && dateToSQLDateString(dateSelected)}
          onChange={(_, value) => {
            setNewDate(new Date(value));
            segment.deliveryTypeSelected(
              'Next day and beyond',
              dateSelected,
              userSession,
              userAccountInformation,
            );
          }}
        >
          {dateStringArray.map((mappedDate, index) => {
            return (
              <RadioButton
                key={mappedDate}
                labelPosition="start"
                sx={styles.suggestedDate}
                value={mappedDate}
                checked={
                  !!dateSelected &&
                  dateSelected.getDate() === new Date(mappedDate).getDate()
                }
              >
                <Box display="flex" justifyContent="space-between">
                  {format(new Date(mappedDate), 'eeee, d LLL')}
                  <NonFlexFee
                    fee={index === 0 ? sameDayDeliveryFee : 0}
                    selectedProductPrice={selectedProductPrice}
                  />
                </Box>
              </RadioButton>
            );
          })}
        </RadioGroup>
        <Box>
          <Typography sx={styles.disclaimerMessage}>
            {i18next.t(
              isCanada ? TK.DISCLAIMER_MESSAGE_KMS : TK.DISCLAIMER_MESSAGE,
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
