import { ArrangementSuggestionItem } from 'components/PDP/Details/components/ProductAccordion/components/suggestions/ArrangementSuggestionItem';
import { Box } from '@mui/material';
import { FAQsTitle } from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { MarketplaceSuggestionsCarouselContainer } from 'components/PDP/Details/components/ProductAccordion/components/suggestions/styles';
import { arrangementApiService } from 'api/arrangements/arrangement.api.service';
import { getPdpPath } from 'containers/MainContainer/router.helper';
import { settings } from 'components/Home/BannerProductCarousel/settings';
import { usePromiseState } from 'hooks/fetching/use-promise-state';
import React, { FC, useMemo } from 'react';
import Slider from 'react-slick';

interface ArrangementSuggestionProps {
  arrangementId: number;
}

export const ArrangementSuggestionList: FC<ArrangementSuggestionProps> = (
  props,
) => {
  const { isLoading, data } = usePromiseState(
    () => arrangementApiService.getSuggestions(props.arrangementId),
    [props.arrangementId],
  );

  const brandName = useMemo(() => data?.[0]?.brandName, [data]);

  if (isLoading || !data?.length) return <></>;

  return (
    <Box maxWidth={{ md: 610 }}>
      <FAQsTitle variant="h6" textAlign={{ xs: 'center', sm: 'left' }}>
        {brandName ? `More from ${brandName}` : 'More from the same brand'}
      </FAQsTitle>
      <MarketplaceSuggestionsCarouselContainer>
        <Slider {...settings} infinite={false}>
          {data.map((item) => (
            <ArrangementSuggestionItem
              key={item.arrangementId}
              imageUrl={item.imageUrl}
              path={getPdpPath(item.friendlyUrl)}
              brandName={item.brandName}
              title={item.arrangementName}
              price={item.price}
            />
          ))}
        </Slider>
      </MarketplaceSuggestionsCarouselContainer>
    </Box>
  );
};
