import { arrangementGroupsApiService } from 'api/arrangement-groups.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';

export const fetchArrangementGroup = createAsyncThunk(
  'arrangement-group-with-ml/fetch',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await arrangementGroupsApiService.findOne(id);

      return data;
    } catch (e) {
      return rejectWithValue(parseAxiosError(e));
    }
  },
);
