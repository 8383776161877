import {
  AccountInfo,
  Reward,
  UserAccountInformation,
  UserSession,
} from 'providers/Session/feature/user.types';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isFinished, isFulfilled } from 'utils/status.comparer';
import { isPositive } from 'utils/numbers';
import Cookies from 'js-cookie';

export const selectIsLoggedIn = createSelector(
  ({
    userSession: {
      data: { userId },
    },
  }: RootState) => userId,
  (userId) => isPositive(userId),
);

export const selectUserName = ({ userSession: { data } }: RootState): string =>
  data.userName;

export const selectUserId = ({ userSession: { data } }: RootState): number =>
  data.userId;

export const selectRegion = ({ userSession: { data } }: RootState): number =>
  data.cartHostedRegion;

export const selectUserLocale = (): string => Cookies.get('locale') || 'en-us';

export const selectUserRewards = ({ userSession }: RootState): Reward[] =>
  userSession.userAccountInformation?.rewards || [];

export const selectIsBlocked = createSelector(
  ({ userSession: { status } }: RootState) => status,
  (status) => isBlocked(status),
);

export const selectIsFinished = createSelector(
  ({ userSession: { status } }: RootState) => status,
  (status) => isFinished(status),
);

export const selectSaleIdUploadFulfilled = createSelector(
  ({ userSession: { updateSaleIdStatus } }: RootState) => updateSaleIdStatus,
  (status) => status === Status.FULFILLED,
);

export const selectSaleIdUploadInit = createSelector(
  ({ userSession: { updateSaleIdStatus } }: RootState) => updateSaleIdStatus,
  (status) => status === Status.INIT,
);

export const selectIsFulfilled = createSelector(
  ({ userSession: { status } }: RootState) => status,
  (status) => isFulfilled(status),
);

export const selectUserEmail = ({ userSession: { data } }: RootState): string =>
  data.userEmail || '';

export const selectUser = ({ userSession: { data } }: RootState): UserSession =>
  data;

export const selectUserAccountInformation = ({
  userSession: { userAccountInformation },
}: RootState): UserAccountInformation | undefined => userAccountInformation;

export const selectPhoneNumber = ({
  userSession: { userAccountInformation },
}: RootState): string | undefined => {
  const accountInfo = userAccountInformation?.accountInfo[0];
  const phone =
    accountInfo?.workPhone && accountInfo.workPhone !== ''
      ? accountInfo.workPhone
      : accountInfo?.cellPhone;
  return phone;
};

export const selectAddress = ({
  userSession: { userAccountInformation },
}: RootState): Partial<AccountInfo> | undefined => {
  const accountInfo = userAccountInformation?.accountInfo[0];
  return {
    address1: accountInfo?.address1,
    address2: accountInfo?.address2,
    city: accountInfo?.city,
    stateAbbreviation: accountInfo?.stateAbbreviation,
    area: accountInfo?.area,
  };
};

export const selectUserAccountFetchStatus = ({
  userSession: { userAccountFetchStatus },
}: RootState): Status => userAccountFetchStatus;
