import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { isAvailabilityStepGreaterThan } from 'containers/PDPContainer/pdp-container.utils';
import { isNotNull } from 'utils/null.utils';
import {
  selectIsDateAvailabilityFulfilled,
  selectIsNotAvailable,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import {
  selectIsSessionPending,
  selectSessionLockType,
} from 'redux/session/availability/availability.selectors';
import { shouldShowCalendar } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-dates';
import { shouldShowSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-arrangements';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSkipMountEffect } from 'hooks/use-skip-mount-effect';

interface Params {
  isProductOptionSelected: boolean;
  isZipEntered: boolean;
  isOrderTypeSelected: boolean;
  isStoreSelected: boolean;
  date: Date | null;
  selectedAvailability: OrderTypeAvailability | null;
}

export function useAvailabilityStep({
  isProductOptionSelected,
  isZipEntered,
  isOrderTypeSelected,
  isStoreSelected,
  date,
  selectedAvailability,
}: Params): AvailabilityStep {
  const [availabilityStep, setAvailabilityStep] = useState(
    isProductOptionSelected
      ? AvailabilityStep.ZIP_AVAILABILITY
      : AvailabilityStep.PRODUCT_OPTIONS,
  );
  const sessionLock = useSelector(selectSessionLockType);
  const isNotAvailable = useSelector(selectIsNotAvailable);
  const isFulfilled = useSelector(selectIsDateAvailabilityFulfilled);
  const isSessionPending = useSelector(selectIsSessionPending);

  const handleChangedStep = (
    isDataFilled: boolean,
    changedStep: AvailabilityStep,
    nextStep: AvailabilityStep,
  ) => {
    if (isSessionPending) {
      return;
    }
    if (isDataFilled) {
      setAvailabilityStep(nextStep);
      return;
    }
    if (isAvailabilityStepGreaterThan(availabilityStep, changedStep)) {
      setAvailabilityStep(changedStep);
    }
  };

  useEffect(() => {
    if (sessionLock) {
      return;
    }
    handleChangedStep(
      isProductOptionSelected,
      AvailabilityStep.PRODUCT_OPTIONS,
      AvailabilityStep.ZIP_AVAILABILITY,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductOptionSelected]);

  useEffect(() => {
    handleChangedStep(
      isZipEntered,
      AvailabilityStep.ZIP_AVAILABILITY,
      AvailabilityStep.DATE_SELECTION,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZipEntered]);

  useEffect(() => {
    handleChangedStep(
      isNotNull(date) && !isNotAvailable && isFulfilled,
      AvailabilityStep.DATE_SELECTION,
      AvailabilityStep.DELIVERY_METHOD_SELECTION,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (!selectedAvailability) {
      return;
    }

    let nextStep = AvailabilityStep.STORE_LIST_SELECTION;
    if (shouldShowCalendar(selectedAvailability)) {
      nextStep = AvailabilityStep.SUGGESTED_DATE_SELECTION;
    }

    if (shouldShowSuggestedArrangements(selectedAvailability)) {
      nextStep = AvailabilityStep.SUGGESTED_PRODUCT_SELECTION;
    }

    handleChangedStep(
      isOrderTypeSelected,
      AvailabilityStep.DELIVERY_METHOD_SELECTION,
      nextStep,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAvailability]);

  useEffect(() => {
    handleChangedStep(
      isStoreSelected,
      AvailabilityStep.STORE_LIST_SELECTION,
      AvailabilityStep.CONTINUE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStoreSelected]);

  useSkipMountEffect(() => {
    if (!sessionLock) {
      setAvailabilityStep(AvailabilityStep.ZIP_AVAILABILITY);
    }
  }, [sessionLock]);
  return availabilityStep;
}
