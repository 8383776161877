import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const carouselSkeleton: ThemedSxProps = (theme) => ({
  marginBottom: 5,
  height: 250,
  [theme.breakpoints.up('md')]: {
    height: 500,
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: 6,
  },
});
