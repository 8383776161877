import { Status } from 'redux/types/state.interface';
import { clearUserAccountInformation } from 'providers/Session/feature/user.slice';
import {
  fetchUserAccountInformation,
  fetchUserSession,
} from 'providers/Session/feature/user.action';
import { getAvailabilitySession } from 'redux/session/availability/availability.action';
import { resetLogOutStatus } from 'redux/auth/auth.slice';
import { selectIsStatusSuccess } from 'redux/auth/selectors';
import {
  selectUser,
  selectUserAccountFetchStatus,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackAuthUser } from 'providers/Session/use-track-auth-user.aa';
import React, { FC, useEffect } from 'react';

export const SessionProvider: FC = ({ children }) => {
  useTrackAuthUser();
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectIsStatusSuccess);
  const user = useSelector(selectUser);
  const userAccountFetchStatus = useSelector(selectUserAccountFetchStatus);
  const userAccountInfo = useSelector(selectUserAccountInformation);
  const isStoreNumberSaved = useSelector(
    ({ userSession }) => userSession.isCreditedStoreNumberSaved,
  );

  const params = new URLSearchParams(window.location.search);
  const storeNumber = params.get('store');

  const shouldFetchUserAccountInfo =
    user.userEmail && userAccountFetchStatus === Status.INIT;

  const shouldClearUserAccountInfo =
    user.userId <= 0 &&
    !user.userEmail &&
    userAccountFetchStatus !== Status.INIT &&
    userAccountInfo;

  useEffect(() => {
    if (storeNumber) {
      if (isStoreNumberSaved) {
        dispatch(fetchUserSession());
      }
    } else {
      dispatch(fetchUserSession());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeNumber, isStoreNumberSaved]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchUserSession());
      dispatch(resetLogOutStatus());
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (shouldFetchUserAccountInfo && !userAccountInfo) {
      dispatch(fetchUserAccountInformation(user.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (shouldClearUserAccountInfo) {
      dispatch(clearUserAccountInformation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(getAvailabilitySession());
  }, [dispatch]);

  return <>{children}</>;
};
