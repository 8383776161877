import { SxProps, styled } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import {
  giftFinderInlineDesktop,
  giftFinderInlineMobile,
  giftFinderInlineTablet,
} from 'utils/cloudinaryAssets';

export const giftFinderWrapper: ThemedSxProps = {
  width: '100%',
  height: '100%',
  backgroundImage: {
    md: `url(${giftFinderInlineDesktop})`,
    sm: `url(${giftFinderInlineTablet})`,
    xs: `url(${giftFinderInlineMobile})`,
  },
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
};

export const giftFinderInline: ThemedSxProps = {
  maxWidth: '1060px',
  margin: '0 auto',
  padding: ['40px 30px', '40px', '40px 0px'],
};

export const giftFinderTitle: ThemedSxProps = {
  fontSize: ['20px', '34px', '34px'],
  fontFamily: fonts.poppins,
  fontWeight: '500',
  lineHeight: ['26.4px', '41.99px', '41.99px'],
  letterSpacing: ['0.15px', '0.25px', '0.25px'],
  color: colors.activeBlack,
  textAlign: 'center',
  marginBottom: '16px',
};

export const giftFinderInlneText: ThemedSxProps = {
  fontSize: ['14px', '16px', '16px'],
  fontFamily: fonts.poppins,
  fontWeight: '400',
  lineHeight: ['24px', '20px', '20px'],
  color: colors.activeBlack,
  textAlign: 'center',
  marginBottom: '8px',
};

export const StyledForm = styled('form')({
  '& button': {
    background: colors.primaryRed,
    color: colors.white,
    height: '50px',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '0px',
    '&:hover': {
      background: colors.primaryRed,
    },
  },
});

export const findGiftBtn: ThemedSxProps = {
  textAlign: 'center',
  '& button': {
    width: { md: '32%', sm: '50%', xs: '100%' },
    marginLeft: { sm: '0px', xs: '0px' },
    '&.Mui-disabled': {
      background: '#ccc',
    },
  },
};

export const giftFinderDropdownSec: ThemedSxProps = {
  width: '100%',
  justifyContent: 'space-between',
};

export const giftFinderskeleton: ThemedSxProps = {
  paddingTop: '30px',
  width: '100%',
  textAlign: 'center',
  display: ['block', 'flex', 'flex'],
};

export const headerContainer: ThemedSxProps = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  '& svg': {
    width: '36px',
    height: '36px',
  },
};

export const skeleton: ThemedSxProps = {
  height: '54px',
  width: { md: '33.33%', sm: '50%', xs: '100%' },
  marginBottom: '18px',
  marginRight: '10px',
};

export const wrapper: ThemedSxProps = () => ({
  '& .MuiDialog-container': {
    top: {
      xs: '138px',
      sm: 'calc(38% - 484px/2 - 1px)',
      md: 'calc(38% - 484px/2 - 7px)',
    },
    '& .MuiPaper-root': {
      top: {
        xs: '138px',
        sm: 'calc(38% - 484px/2 - 1px)',
        md: 'calc(38% - 484px/2 - 7px)',
      },
      width: { md: '520px', sm: '452px', xs: '100%' },
    },
  },
});

export const formControl: SxProps = {
  width: '100%',
  textAlign: 'left',
  marginBottom: '20px',
  '& label': {
    color: colors.activeBlack,
  },
  '& .MuiInputBase-formControl': {
    backgroundColor: '#fff',
  },
  transition: 'transform 0.1s, box-shadow 0.1s',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.02)',
  },
};
export const itemContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px 10px',
  width: '100%',
  justifyContent: 'center',
  '& #errorWrapper': {
    padding: '0px',
    color: '#ef5350',
    marginTop: '-30px',
    backgroundColor: 'transparent',
  },
};

export const giftFinderText: ThemedSxProps = {
  width: '81px',
  height: '22px',
  borderRadius: '100px',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '22px',
  color: colors.white,
  marginLeft: '14%',
  marginTop: '3%',
};

export const giftFinder: ThemedSxProps = {
  background: colors.primaryBlack,
  borderRadius: '100px',
  width: '107.28px',
  height: '26.26px',
  margin: '10px',
};

export const bannerText: ThemedSxProps = {
  font: 'inherit',
  fontWeight: 400,
};

export const textWrapper: ThemedSxProps = (theme) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
});

export const imageWrapper: ThemedSxProps = (theme) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '14px', sm: '20px' },
  color: theme.palette.common.white,
  cursor: 'pointer',
  marginBottom: { xs: '20px', md: '40px' },
});

export const bgWrapper: ThemedSxProps = {
  width: '100%',
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: fonts.poppins,
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  textAlign: 'center',
  marginBottom: { xs: '20px', md: '40px' },
  '&:hover': {
    textDecoration: 'none',
  },
};

export const bgBanner: ThemedSxProps = (theme) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: { xs: '14px', sm: '20px' },
  lineHeight: { xs: '24px', sm: '30px' },
  minHeight: { md: '40px', sm: '67px', xs: '67px' },
  color: theme.palette.common.white,
  paddingTop: '5px',
  paddingBottom: '5px',
});

export const bgBannerText: ThemedSxProps = {
  fontWeight: 400,
  color: colors.black,
  marginLeft: '5px',
  marginRight: '5px',
  height: 'auto',
};
