export const NOTIFICATION_BANNER_WITHIN_YEAR =
  'notificationBannerCommonLocale.order_within_year';
export const NOTIFICATION_BANNER_WITHIN_YEARS =
  'notificationBannerCommonLocale.order_within_years';
export const NOTIFICATION_BANNER_WITHIN_MONTH =
  'notificationBannerCommonLocale.order_within_month';
export const NOTIFICATION_BANNER_WITHIN_MONTHS =
  'notificationBannerCommonLocale.order_within_months';
export const NOTIFICATION_BANNER_WITHIN_DAY =
  'notificationBannerCommonLocale.order_within_day';
export const NOTIFICATION_BANNER_WITHIN_DAYS =
  'notificationBannerCommonLocale.order_within_days';
export const NOTIFICATION_BANNER_WITHIN_HOUR =
  'notificationBannerCommonLocale.order_within_hour';
export const NOTIFICATION_BANNER_WITHIN_HOURS =
  'notificationBannerCommonLocale.order_within_hours';
export const NOTIFICATION_BANNER_WITHIN_MINUTE =
  'notificationBannerCommonLocale.order_within_minute';
export const NOTIFICATION_BANNER_WITHIN_MINUTES =
  'notificationBannerCommonLocale.order_within_minutes';
