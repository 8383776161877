import * as styles from 'components/OrderConfirmation/OrderDetails/styles';
import { Box, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { OrderSubItem } from 'redux/order-confirmation/types/types';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { addOnUrl } from 'components/CustomerService/OrderTracking/utils/utils';
import { addonArrow } from 'utils/cloudinaryAssets';
import { roundNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import { sale } from 'components/OrderConfirmation/translations/locale.constants';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  product: OrderSubItem;
}

export const ChildProduct: FC<Props> = ({ product }) => (
  <Box sx={styles.childProduct}>
    <Image className="arrowStyle" src={addonArrow} />
    <Image className="productImg" src={addOnUrl(product.addOnOptionImage)} />
    <Box width="100%" ml={2}>
      <Box className="productDescription" justifyContent="space-between">
        <PureHtmlTypography
          sx={styles.nameSpace}
          className="fontStyle"
          variant="subtitle2"
        >
          {product.name
            .replace(`- ${product.sizeName}`, '')
            .replace(`: ${product.sizeName}`, '')}
        </PureHtmlTypography>
        <Typography variant="body2" mt={1} sx={styles.qtyStyle}>
          {i18next.t(sale.QTY, {
            '#': product.quantity,
          })}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={styles.totalStyle}
        >{`$${roundNumberValue(product.quantity * product.price)}`}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography sx={styles.addOnSizeStyle} variant="body3">
          {product.sizeName}
        </Typography>
        <Typography variant="subtitle2" sx={styles.qtyCont}>
          {i18next.t(sale.PRICE, {
            Price: roundNumberValue(product.price),
          })}
        </Typography>
      </Box>
    </Box>
  </Box>
);
