import {
  ARRANGEMENT_TITLE_ELEMENT,
  DETAILS_EDIBLE_GALLERY_KEY,
  MARKETPLACE_STORE_ZIPCODES,
} from 'components/PDP/Details/constants';
import { ArrangementPanel } from 'components/PDP/Details/components/ProductAccordion/ArrangementPanel';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import {
  Box,
  Collapse,
  Divider,
  Link,
  Portal,
  Typography,
} from '@mui/material';
import { BrandValues } from 'components/PDP/Details/components/Marketplace/components/BrandValues/BrandValues';
import { StarRating as BvStarRating } from 'lib/bazaarvoice/components/StarRating/StarRating';
import { CHOOSE_RECIPIENT } from 'translations/locales/translation-keys.constant';
import { DccProvider } from 'lib/bazaarvoice/providers/DccProvider';
import { Element, scroller } from 'react-scroll';
import { EmailSubscriptionDialog } from 'components/Subscription/EmailSubscriptionDialog';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Gallery,
  GalleryImages,
} from 'components/PDP/Details/components/Gallery/Gallery';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Image } from 'components/common/Image/Image';
import { Links } from 'enums/common-links';
import { LockAlert } from 'components/Session/Alert/LockAlert';
import { MarginWrapper } from 'components/common/MarginWrapper/styles';
import { MarketplaceProductDetails } from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductDetails/MarketplaceProductDetails';
import { NonPromotionalMessage } from 'components/PDP/Details/components/NonPromotionalMessage/NonPromotionalMessage';
import { OrderType } from 'types/cart.types';
import { OwnBox } from 'components/PDP/Details/components/OwnBox/OwnBox';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxDessertBoard } from 'components/PDP/Details/components/OwnBox/OwnBoxDessertBoard';
import { OwnBoxImage } from 'components/PDP/Details/components/Gallery/components/OwnBoxImage';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import { PasControl } from 'components/PDP/Details/components/PasControl/PasControl';
import { PasControlSkeleton } from 'components/PDP/Details/components/PasControl/components/PasControlSkeleton';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductCode } from 'components/PDP/Details/components/ProductCode/ProductCode';
import { ProductDescription } from 'components/PDP/Details/components/Marketplace/components/ProductDescription/ProductDescription';
import { ProductOptions } from 'components/PDP/Details/components/ProductOptions/ProductOptions';
import { PromotionalMessage } from 'components/PDP/Details/components/PromotionalMessage/PromotionalMessage';
import { RecipientLockAlert } from 'components/Session/Alert/RecipientLockAlert/RecipientLockAlert';
import {
  SelectFreshFruitProduct,
  checkIsBlocked,
  selectArrangement,
  selectCatalogNumber,
  selectIsMarketplaceProduct,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { SessionLock } from 'enums/session-lock';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { StarRating } from 'components/PDP/Details/components/Reviews/StarRating';
import { StickyAlert } from 'components/common/StickyAlert/StickyAlert';
import { Title } from 'components/PDP/Details/components/Title/Title';
import { UnavailableBySchedule } from 'components/PDP/Arrangement/UnavailableBySchedule/UnavailableBySchedule';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { addCurrency } from 'utils/add-currency';
import { appInsights, trackComponent } from 'utils/telemetry/app-insights';
import { areAllAvailabilityStepsAreFilled } from 'containers/PDPContainer/pdp-container.utils';
import { availabilityStoreService } from 'api/availability-store.api.service';
import { checkIsUnavailableBySchedule } from 'components/PDP/Arrangement/feature/selector';
import {
  freshFruitBanner,
  freshFruitBannerMobile,
} from 'utils/cloudinaryAssets';
import {
  freshFruitWrapper,
  galleryOuterWrapper,
} from 'components/PDP/Details/details.styles';
import { getMarketplaceBrandName } from 'components/PDP/Details/components/Marketplace/components/utils/get-marketplace-brand-name';
import { getSearchParams } from 'utils/getSearchParams';
import { isEmpty } from 'utils/is-empty';
import { isNull } from 'utils/null.utils';
import { isPositive } from 'utils/numbers';
import { isValidateCookieboxItems } from 'components/PDP/Details/components/OwnBox/utils/is-validated-cookie-box';
import { isValidateDessertBoardItems } from 'components/PDP/Details/components/OwnBox/utils/is-validated';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { selectIsLoading } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import {
  selectIsStoreOrRecipientLock,
  selectSessionLockType,
} from 'redux/session/availability/availability.selectors';
import { selectReviewsInfo } from 'components/PDP/Details/components/Reviews/feature/selectors';
import { stickyAlertConfig } from 'components/common/StickyAlert/sticky-alert.constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchProductsAvailability } from 'components/PDP/Details/components/ProductOptions/hooks/use-fetch-products-availability';
import { useIsAnyOptionAvailable } from 'components/PDP/Details/hooks/use-is-any-option-available';
import { useIsBvEnabled } from 'lib/bazaarvoice/hooks/use-is-bv-and-c8-enabled';
import { useOnlyXs } from 'utils/hidden/use-only-xs';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useProductOptionsAvailability } from 'components/PDP/Details/components/ProductOptions/hooks/use-product-options-availability';
import { useUpdatePasWithAvailability } from 'components/PDP/Details/hooks/use-update-pas-with-availability';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import i18next from 'i18next';

interface Props {
  onSubmit: () => void;
  quantity: number;
  setQuantity: (d: number) => void;
  product: Product | null;
  setProduct: (d: Product) => void;
  setOwnBoxAddons: (addons: UpsellItem[]) => void;
  selectedOwnBoxAddons: UpsellItem[];
  bottomContainer: RefObject<HTMLDivElement>;
  setClickOwnBoxAddon: (addon: OwnBoxAddon[]) => void;
  clickOwnBoxAddon: OwnBoxAddon[];
  isCookie12?: boolean;
}

export const Details = trackComponent(
  ({
    quantity,
    setQuantity,
    onSubmit,
    product,
    setProduct,
    setOwnBoxAddons,
    selectedOwnBoxAddons,
    bottomContainer,
    setClickOwnBoxAddon,
    clickOwnBoxAddon,
    isCookie12,
  }: Props) => {
    useFetchProductsAvailability();
    useUpdatePasWithAvailability();

    const zipRef = useRef<HTMLInputElement | null>(null);
    const dateRef = useRef<HTMLInputElement | null>(null);
    const fulfillmentRef = useRef<HTMLInputElement | null>(null);
    const suggestedDatesRef = useRef<HTMLInputElement | null>(null);
    const storeRef = useRef<HTMLInputElement | null>(null);
    const isMobile = useOnlyXs();
    const optionsAvailability = useProductOptionsAvailability();
    const lockType = useSelector(selectSessionLockType);
    const isAnyProductOptionAvailable =
      useIsAnyOptionAvailable(optionsAvailability);
    const isBvEnabled = useIsBvEnabled();

    const arrangementPending = useSelector(checkIsBlocked);
    const isAvailabilityLoading = useSelector(selectIsLoading);
    const isloading = !arrangementPending || !isAvailabilityLoading;
    const arrangement = useSelector(selectArrangement);
    const catalogNumber = useSelector(selectCatalogNumber);
    const isFreshFruitProduct = useSelector(SelectFreshFruitProduct);
    const isUnavailableBySchedule = useSelector(checkIsUnavailableBySchedule);
    const isStoreOrRecipientLock = useSelector(selectIsStoreOrRecipientLock);
    const reviewsInfo = useSelector(selectReviewsInfo);
    const isMarketplaceProduct = useSelector(selectIsMarketplaceProduct);

    const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
    const [pdpSkipperLoader, setPdpSkipperLoader] = useState<boolean>(false);
    const [availabilityStep, setAvailabilityStep] = useState<AvailabilityStep>(
      AvailabilityStep.PRODUCT_OPTIONS,
    );
    const [reviewsOpen, setReviewsOpen] = useState(false);
    const [focusedOwnBoxAddonId, setFocusedOwnBoxAddonId] = useState(0);

    const addOnIds = [
      ...new Set(arrangement.ownBox.addons.map((addon) => addon.addonId)),
    ];
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const sizeName = getSearchParams('size');

    const displayedProduct: Product | undefined =
      product ||
      (sizeName?.length > 0
        ? arrangement.products.find((p) => p.name === sizeName)
        : arrangement.products[0]);
    const isUnavailable =
      isUnavailableBySchedule || !isAnyProductOptionAvailable;
    const isOwnBoxEmpty = arrangement.isOwnBox && isEmpty(selectedOwnBoxAddons);
    const [isAutoSelectStore, setIsAutoSelectStore] = useState<boolean>(false);
    const [isDeliveryOrderType, setIsDeliveryOrderType] =
      useState<boolean>(false);
    const pasData = usePasData();
    const dispatch = useDispatch();

    const showAlert = () => {
      setIsAlertShown(true);
    };

    const hideAlert = () => {
      setIsAlertShown(false);
    };

    useEffect(() => {
      const timeout = setTimeout(
        () => {
          setShouldRender(true);
        },
        isMobile ? 2000 : 7000,
      );

      return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
      if (availabilityStep === 4 && dateRef.current) {
        dateRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }

      if (suggestedDatesRef.current && availabilityStep === 6) {
        suggestedDatesRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }

      if (
        storeRef.current &&
        !suggestedDatesRef.current &&
        availabilityStep === 6
      ) {
        storeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }, [availabilityStep]);

    const scrollToStep = (step: AvailabilityStep): void => {
      if (zipRef.current && step === 3) {
        if (isMobile) {
          zipRef.current.focus();
        } else {
          zipRef.current.focus();
          zipRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }

      if (dateRef.current && step === 4) {
        dateRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }

      if (fulfillmentRef.current && step === 5) {
        fulfillmentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }

      if (storeRef.current && step === 6) {
        storeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
      scroller.scrollTo(AvailabilityStep[step], stickyAlertConfig);
      showAlert();
    };

    const onDeliveryCheck = (orderType: OrderType): void => {
      if (orderType === 1) {
        setIsDeliveryOrderType(true);
      } else {
        setIsDeliveryOrderType(false);
      }
    };

    const validateFlow = (step: AvailabilityStep): boolean => {
      if (areAllAvailabilityStepsAreFilled(step)) {
        return true;
      }

      scrollToStep(step);
      return false;
    };

    const handleMarketplaceOnContinue = async (): Promise<void> => {
      if (arrangement && displayedProduct) {
        setPdpSkipperLoader((prevVal) => !prevVal);
        const marketplaceBrandName = getMarketplaceBrandName(arrangement);
        const areaName = MARKETPLACE_STORE_ZIPCODES[marketplaceBrandName];
        const date = new Date();

        const marketplaceStoreData =
          await availabilityStoreService.getMarketplaceStore({
            arrangementId: arrangement.id,
            productId: displayedProduct.id,
            areaName,
            marketplaceBrandName,
            date,
          });
        if (marketplaceStoreData?.area && marketplaceStoreData?.store) {
          dispatch(
            pas.set({
              ...marketplaceStoreData,
              orderType: OrderType.Shipment,
              date,
            }),
          );
          setPdpSkipperLoader((prevVal) => !prevVal);
          onSubmit();
        }
      }
    };

    const onContinue = (event?: React.MouseEvent): void => {
      event?.stopPropagation();

      if (isMarketplaceProduct) {
        handleMarketplaceOnContinue();
        return;
      }

      if (
        addOnIds.length === 3 &&
        isValidateDessertBoardItems(
          selectedOwnBoxAddons,
          clickOwnBoxAddon,
          setAvailabilityStep,
          pasData,
        )
      ) {
        scrollToStep(AvailabilityStep.DESSERT_OWN_BOX_ADDONS);
        return;
      }
      if (
        addOnIds.length === 2 &&
        arrangement.ownBox.type === OwnBoxTypes.COOKIE_BOX &&
        isCookie12 &&
        isValidateCookieboxItems(
          selectedOwnBoxAddons,
          setAvailabilityStep,
          pasData,
        )
      ) {
        scrollToStep(AvailabilityStep.OWN_12_COOKIE_BOX);
        return;
      }
      if (isOwnBoxEmpty) {
        scrollToStep(AvailabilityStep.OWN_BOX_ADDONS);
        return;
      }

      const shouldSubmit =
        isStoreOrRecipientLock || validateFlow(availabilityStep);

      if (
        availabilityStep === AvailabilityStep.STORE_LIST_SELECTION &&
        isDeliveryOrderType
      ) {
        setIsAutoSelectStore(true);
      }

      if (shouldSubmit) {
        onSubmit();
      }
    };

    const handleReviewLinkClick = () => {
      if (reviewsInfo.count) {
        setReviewsOpen(true);
      }
    };

    const handleSubmitPasStep = () => {
      if (
        addOnIds.length === 3 &&
        isValidateDessertBoardItems(
          selectedOwnBoxAddons,
          clickOwnBoxAddon,
          setAvailabilityStep,
          pasData,
        )
      ) {
        scrollToStep(AvailabilityStep.DESSERT_OWN_BOX_ADDONS);
        return;
      }
      if (
        addOnIds.length === 2 &&
        arrangement.ownBox.type === OwnBoxTypes.COOKIE_BOX &&
        isCookie12 &&
        isValidateCookieboxItems(
          selectedOwnBoxAddons,
          setAvailabilityStep,
          pasData,
        )
      ) {
        scrollToStep(AvailabilityStep.OWN_12_COOKIE_BOX);
        return;
      }
      if (isOwnBoxEmpty) {
        scrollToStep(AvailabilityStep.OWN_BOX_ADDONS);
        return;
      }
      onSubmit();
    };

    const galleryImages: GalleryImages[] = useMemo(() => {
      if (isMarketplaceProduct) {
        const displayedProductImage =
          displayedProduct?.marketplaceProductAttributes?.productImageUrl ?? '';

        const primaryImages =
          arrangement?.marketplaceArrangementAttributes?.productImageUrls
            ?.split(',')
            .filter((img) => img !== displayedProductImage) as GalleryImages[];

        if (!displayedProductImage) {
          return [...primaryImages];
        }

        return [displayedProductImage, ...primaryImages];
      }

      const gallery: GalleryImages[] = [
        displayedProduct?.image ?? '',
        ...(displayedProduct?.carouselImages || []),
      ];

      if (arrangement.isOwnBox) {
        gallery.unshift((isBottomCarousel?: boolean) => (
          <OwnBoxImage
            selectedAddons={selectedOwnBoxAddons}
            type={
              addOnIds.length === 3
                ? OwnBoxTypes.DESSERT_BOARD
                : arrangement.ownBox.type
            }
            isBottomCarousel={isBottomCarousel}
            onItemSelect={(id) => setFocusedOwnBoxAddonId(id)}
            clear={() => {
              setOwnBoxAddons([]);
              setClickOwnBoxAddon([]);
            }}
            clickOwnBoxAddon={clickOwnBoxAddon}
            addOnIds={addOnIds}
            isCookie12={isCookie12}
          />
        ));
      }

      return gallery;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      arrangement.isOwnBox,
      arrangement.ownBox.type,
      displayedProduct?.carouselImages,
      displayedProduct?.image,
      displayedProduct?.marketplaceProductAttributes?.productImageUrl,
      selectedOwnBoxAddons,
    ]);

    const cookieLimitedName = 'Cookie Box - LTO';
    const everyDayCookie = selectedOwnBoxAddons.filter(
      (addon) => !addon.option.optionName?.includes(cookieLimitedName),
    );

    const galleryKey = isMarketplaceProduct
      ? displayedProduct?.id
      : DETAILS_EDIBLE_GALLERY_KEY;

    return (
      <>
        {isBvEnabled && isPositive(arrangement.id) && (
          <ErrorBoundary
            onError={(error) =>
              appInsights.trackEvent(
                { name: 'BazaarVoice Dynamic Catalog Collection Error' },
                error,
              )
            }
            fallback={<></>}
          >
            <DccProvider arrangement={arrangement} />
          </ErrorBoundary>
        )}
        <Box data-test="pdp-details-page">
          {addOnIds.length === 3 && isOwnBoxEmpty ? (
            <StickyAlert
              isShown={isAlertShown}
              hideAlert={hideAlert}
              selectedOwnBoxAddons={selectedOwnBoxAddons}
              clickOwnBoxAddon={clickOwnBoxAddon}
              availabilityStep={
                isOwnBoxEmpty
                  ? AvailabilityStep.DESSERT_OWN_BOX_ADDONS
                  : availabilityStep
              }
            />
          ) : (
            <StickyAlert
              isShown={isAlertShown}
              selectedOwnBoxAddons={selectedOwnBoxAddons}
              clickOwnBoxAddon={clickOwnBoxAddon}
              hideAlert={hideAlert}
              availabilityStep={
                isOwnBoxEmpty
                  ? AvailabilityStep.OWN_BOX_ADDONS
                  : availabilityStep
              }
            />
          )}
          {addOnIds.length === 2 &&
            arrangement.ownBox.type === OwnBoxTypes.COOKIE_BOX &&
            isCookie12 &&
            everyDayCookie.length < 1 && (
              <StickyAlert
                isShown={isAlertShown}
                hideAlert={hideAlert}
                selectedOwnBoxAddons={selectedOwnBoxAddons}
                clickOwnBoxAddon={clickOwnBoxAddon}
                availabilityStep={AvailabilityStep.OWN_12_COOKIE_BOX}
                isCookie12={isCookie12}
              />
            )}
          {addOnIds.length === 2 &&
            arrangement.ownBox.type === OwnBoxTypes.COOKIE_BOX &&
            !isCookie12 &&
            isOwnBoxEmpty && (
              <StickyAlert
                isShown={isAlertShown}
                hideAlert={hideAlert}
                selectedOwnBoxAddons={selectedOwnBoxAddons}
                clickOwnBoxAddon={clickOwnBoxAddon}
                availabilityStep={AvailabilityStep.OWN_6_COOKIE_BOX}
                isCookie12={isCookie12}
              />
            )}
          <Box display="flex">
            <ShowUpMd>
              <Box sx={galleryOuterWrapper}>
                <Box
                  position="sticky"
                  zIndex={9}
                  top={({ spacing }) => spacing(3)}
                >
                  <Gallery
                    key={galleryKey}
                    images={galleryImages}
                    imageAltTag={arrangement.imageAltTag}
                    loading={arrangementPending}
                    showHorizontalBorders={arrangement.isOwnBox}
                  />
                  {isFreshFruitProduct && (
                    <Link href={Links.FreshFruit}>
                      <Image alt="Fresh Fruit" src={freshFruitBanner} />
                    </Link>
                  )}
                </Box>
              </Box>
            </ShowUpMd>
            <Box width="100%">
              <MarginWrapper>
                {isMarketplaceProduct ? (
                  <MarketplaceProductDetails isLoading={pdpSkipperLoader} />
                ) : (
                  <>
                    <Box mb={1.5}>
                      {arrangement.nonPromotionalMessage && (
                        <NonPromotionalMessage
                          color={arrangement.nonPromotionalMessageFG}
                        >
                          {arrangement.nonPromotionalMessage}
                        </NonPromotionalMessage>
                      )}
                      <Element name={ARRANGEMENT_TITLE_ELEMENT}>
                        <Title
                          title={arrangement.name}
                          loading={arrangementPending}
                        />
                      </Element>
                    </Box>

                    <Box mb={1.5} display="flex" alignItems="flex-end">
                      <ProductCode
                        code={catalogNumber}
                        loading={arrangementPending}
                      />
                      {!arrangementPending && (
                        <>
                          <Divider
                            sx={{ mx: 1 }}
                            orientation="vertical"
                            flexItem
                          />
                          {isBvEnabled ? (
                            <BvStarRating
                              productId={catalogNumber}
                              onGoToReview={() => setReviewsOpen(true)}
                            />
                          ) : (
                            <StarRating onLinkClick={handleReviewLinkClick} />
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {arrangement.isOwnBox && addOnIds.length !== 2 && (
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {addCurrency(arrangement.products[0].price)}
                  </Typography>
                )}
                {addOnIds.length === 2 && isCookie12 && (
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {addCurrency(arrangement.products[0].price)}
                  </Typography>
                )}
                {addOnIds.length === 2 && !isCookie12 && (
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {addCurrency(arrangement.products[1].price)}
                  </Typography>
                )}
                {arrangement.promotionalMessage && (
                  <Box mb={2}>
                    <PromotionalMessage
                      color={arrangement.promotionalMessageFG}
                    >
                      {arrangement.promotionalMessage}
                    </PromotionalMessage>
                  </Box>
                )}
              </MarginWrapper>
              <HideUpMd>
                <Box sx={galleryOuterWrapper}>
                  <Gallery
                    key={galleryKey}
                    showHorizontalBorders={arrangement.isOwnBox}
                    images={galleryImages}
                    imageAltTag={arrangement.imageAltTag}
                    loading={arrangementPending}
                  />
                </Box>
                {isMarketplaceProduct && (
                  <ProductDescription description={arrangement.description} />
                )}
                {isMarketplaceProduct && (
                  <Box paddingX={isMobile ? '1.25rem' : '0'}>
                    <BrandValues />
                  </Box>
                )}
                {isFreshFruitProduct && (
                  <Box sx={freshFruitWrapper}>
                    <Link
                      href={Links.FreshFruit}
                      style={{ width: isMobile ? '100% ' : 'auto' }}
                    >
                      <Image alt="Fresh Fruit" src={freshFruitBannerMobile} />
                    </Link>
                  </Box>
                )}
              </HideUpMd>
              <Box mb={6}>
                <MarginWrapper>
                  {lockType !== SessionLock.Recipient &&
                    !arrangementPending &&
                    !arrangement.isOwnBox && (
                      <Box sx={{ mt: 2 }}>
                        <LockAlert />
                      </Box>
                    )}
                  {isUnavailableBySchedule && (
                    <Box px={[2, 0]}>
                      <UnavailableBySchedule />
                    </Box>
                  )}
                  <Box mt={2} mb={4}>
                    {arrangement.isOwnBox ? (
                      <Element
                        name={AvailabilityStep[AvailabilityStep.OWN_BOX_ADDONS]}
                      >
                        {addOnIds.length === 3 ? (
                          <OwnBoxDessertBoard
                            focusedAddonId={focusedOwnBoxAddonId}
                            setOwnBoxAddons={setOwnBoxAddons}
                            product={product}
                            selectedOwnBoxAddons={selectedOwnBoxAddons}
                            unavailable={isUnavailable}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            onContinue={onContinue}
                            onAnimationEnd={() => setFocusedOwnBoxAddonId(0)}
                            productUnavailable={
                              isNull(product) ||
                              !optionsAvailability[product.id]
                            }
                            hidePasAlert={hideAlert}
                            setClickOwnBoxAddon={setClickOwnBoxAddon}
                            clickOwnBoxAddon={clickOwnBoxAddon}
                            onChangeStep={setAvailabilityStep}
                          />
                        ) : (
                          <>
                            {isloading && arrangement.isOwnBox && (
                              <Box sx={{ mt: 2, mb: 2 }}>
                                <LockAlert />
                              </Box>
                            )}
                            <ProductOptions
                              selectedProduct={product}
                              setProduct={setProduct}
                              unavailable={isUnavailable}
                              quantity={quantity}
                              setQuantity={setQuantity}
                              onContinue={onContinue}
                              anyOptionAvailable={isAnyProductOptionAvailable}
                              optionsAvailability={optionsAvailability}
                              salePriceFG={arrangement.salePriceFG}
                              isCYOBox
                              setOwnBoxAddons={setOwnBoxAddons}
                            />
                            <OwnBox
                              focusedAddonId={focusedOwnBoxAddonId}
                              setOwnBoxAddons={setOwnBoxAddons}
                              product={product}
                              selectedOwnBoxAddons={selectedOwnBoxAddons}
                              unavailable={isUnavailable}
                              quantity={quantity}
                              setQuantity={setQuantity}
                              onContinue={onContinue}
                              onAnimationEnd={() => setFocusedOwnBoxAddonId(0)}
                              productUnavailable={
                                isNull(product) ||
                                !optionsAvailability[product.id]
                              }
                              hidePasAlert={hideAlert}
                              isCookie12={isCookie12}
                            />
                          </>
                        )}
                      </Element>
                    ) : (
                      <Element
                        name={
                          AvailabilityStep[AvailabilityStep.PRODUCT_OPTIONS]
                        }
                      >
                        <ProductOptions
                          selectedProduct={product}
                          setProduct={setProduct}
                          unavailable={isUnavailable}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          onContinue={onContinue}
                          anyOptionAvailable={isAnyProductOptionAvailable}
                          optionsAvailability={optionsAvailability}
                          salePriceFG={arrangement.salePriceFG}
                        />
                      </Element>
                    )}
                  </Box>
                </MarginWrapper>
                <Element
                  name={AvailabilityStep[AvailabilityStep.ZIP_AVAILABILITY]}
                >
                  <Collapse in={isStoreOrRecipientLock}>
                    <Box mb={6}>
                      <RecipientLockAlert
                        displayRecipientName
                        shortDate={false}
                        recipientLockTitle={i18next.t(CHOOSE_RECIPIENT)}
                        pdpRecipientLock
                      />
                    </Box>
                  </Collapse>
                  {!isMarketplaceProduct && (
                    <Collapse in={!isStoreOrRecipientLock}>
                      <>
                        {arrangementPending ? (
                          <PasControlSkeleton />
                        ) : (
                          <PasControl
                            hideAlert={hideAlert}
                            onChangeStep={setAvailabilityStep}
                            onSubmit={handleSubmitPasStep}
                            arrangementId={arrangement.id}
                            product={product}
                            disabled={isUnavailable || arrangementPending}
                            isOwnBoxEmpty={isOwnBoxEmpty}
                            scrollToStep={scrollToStep}
                            zipRef={zipRef}
                            fulfillmentRef={fulfillmentRef}
                            dateRef={dateRef}
                            storeRef={storeRef}
                            suggestedDatesRef={suggestedDatesRef}
                            firstStoreAutoSelect={isAutoSelectStore}
                            onDeliveryCheck={onDeliveryCheck}
                            addOnIds={addOnIds}
                            selectedOwnBoxAddons={selectedOwnBoxAddons}
                            clickOwnBoxAddon={clickOwnBoxAddon}
                          />
                        )}
                      </>
                    </Collapse>
                  )}
                  <ArrangementPanel
                    reviewsOpen={reviewsOpen}
                    setReviewsOpen={setReviewsOpen}
                    product={product}
                  />
                </Element>
              </Box>
            </Box>
          </Box>
          {!isUnavailable && (
            <ShowUpSm>
              <Portal container={bottomContainer.current}>
                <Box p={5} />
              </Portal>
            </ShowUpSm>
          )}
        </Box>
        <div id="DY-PDP-TAG" />
        {!arrangementPending && shouldRender && <EmailSubscriptionDialog />}
      </>
    );
  },
  'PDP details',
);
