import {
  OrderConfirmation,
  ResendConfirmationParsedResponse,
  ResendConfirmationStatus,
} from 'redux/order-confirmation/types/types';
import { appInsights } from 'utils/telemetry/app-insights';
import { confirmationLegacyApiService } from 'api/re-send-confirmation.legacy.api.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderConfirmationApiService } from 'api/order-confirmation.api.service';
import { parseAxiosError } from 'utils/errors';

export const getOrderConfirmation = createAsyncThunk(
  'order-confirmation/fetch',
  async (saleId: number, { rejectWithValue }) => {
    try {
      const { data }: { data: OrderConfirmation } =
        await orderConfirmationApiService.find(saleId);
      if (data) {
        return data;
      }
      return rejectWithValue('not_found');
    } catch (error) {
      appInsights.trackEvent({ name: 'Order Confirmation Error' });
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);

export const sendSpecialProductMail = createAsyncThunk(
  'send-specialproduct-mail/fetch',
  async (saleId: number, { rejectWithValue }) => {
    try {
      const { data } =
        await confirmationLegacyApiService.sendSpecialProductsMail(saleId);
      const parsedResponse = JSON.parse(
        data.d,
      ) as ResendConfirmationParsedResponse;
      if (parsedResponse._Status === ResendConfirmationStatus.Error) {
        return rejectWithValue(parsedResponse);
      }
      return parsedResponse;
      return rejectWithValue('not_found');
    } catch (error) {
      return rejectWithValue(parseAxiosError(error).message);
    }
  },
);
