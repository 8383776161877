import { SxProps } from '@mui/system';

export const infoLine: SxProps = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '.7rem',
};

export const infoIcon: SxProps = {
  width: 20,
  height: 20,
  mr: '4px',
};

export const dangerIcon: SxProps = {
  width: 20,
  height: 20,
  mr: '4px',
};
