import * as TK from 'containers/SignupContainer/translations/locale.constants';
import { AcconutSignupStyles } from 'components/SignUp/Styles/styles';
import { Box } from '@mui/material';
import { colors } from 'utils/theme/colors';
import React, { FC } from 'react';
import clsx from 'clsx';
import i18next from 'i18next';

interface OwnProps {
  passwordStrength: string;
  passwordError?: string;
}

type Props = OwnProps;

export const StrengthMeter: FC<Props> = ({
  passwordStrength,
  passwordError,
}) => {
  const classes = AcconutSignupStyles;
  let styleValue = 'gray';
  switch (passwordStrength) {
    case 'Poor':
      styleValue = colors.red;
      break;
    case 'Fair':
      styleValue = '#ff7f00';
      break;
    case 'Good':
      styleValue = '#6599CD';
      break;
    case 'Excellent':
      styleValue = '#009900';
      break;
    default:
      styleValue = 'gray';
  }
  const errorMessage = passwordError !== '' ? passwordError : 'Empty';

  return (
    <Box pt={2} id="pwindicator">
      <Box>
        <Box sx={classes.strengthMeter}>
          <p className={clsx(classes.strengthText)}>
            {i18next.t(TK.SIGNUP_PASSWORD_STRENGTH)}:
          </p>
          <p
            className={clsx(classes.strengthBar)}
            style={{
              backgroundColor: styleValue,
            }}
          />
          <p
            className={clsx(classes.strengthText)}
            style={{
              color: styleValue,
            }}
          >
            {errorMessage}
          </p>
        </Box>
      </Box>
    </Box>
  );
};
