import * as styles from 'components/Home/Banner/styles';
import { Box, Link, useMediaQuery, useTheme } from '@mui/material';
import { Image } from 'components/Home/Banner/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';

interface Props {
  bannerItemUrl: string;
  bannerItemSrc: string;
  bannerItemBody?: string;
  bannerItemAltText?: string;
  bannerBGColor?: string;
  analyticsClickHandler?: () => void;
}
interface Errors {
  mobile: boolean;
  desktop: boolean;
}

const initialErrors: Errors = {
  mobile: false,
  desktop: false,
};
export const BannerLink: FC<Props> = ({
  bannerItemUrl = '',
  bannerItemSrc = '',
  bannerItemBody = '',
  bannerItemAltText = '',
  bannerBGColor,
  analyticsClickHandler,
}) => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const [errors, setErrors] = useState<Errors>(initialErrors);
  const isError = isUpMd ? errors.desktop : errors.mobile;
  if (isError) {
    return null;
  }

  const handleClick = () => {
    segment.bannerClicked(
      bannerItemUrl,
      bannerItemBody,
      'Home Page Banner',
      userSession,
      userAccountInformation,
    );
    if (analyticsClickHandler) analyticsClickHandler();
  };

  const handleError = () => {
    setErrors((prevState) => ({
      mobile: isUpMd ? prevState.mobile : true,
      desktop: isUpMd ? true : prevState.desktop,
    }));
  };
  return bannerItemSrc ? (
    <Box sx={styles.wrapper}>
      <Link
        key={bannerItemUrl}
        sx={styles.banner}
        href={bannerItemUrl}
        data-test="banner-widget"
        onClick={() => handleClick()}
      >
        <Image
          src={bannerItemSrc}
          alt={bannerItemAltText}
          onError={handleError}
          data-test="banner-widget-image"
        />
        <Box sx={styles.textWrapper}>
          <PureHtmlTypography
            sx={styles.bannerText}
            data-test="banner-widget-body"
          >
            {bannerItemBody}
          </PureHtmlTypography>
        </Box>
      </Link>
    </Box>
  ) : (
    <Box sx={styles.wrapper}>
      <Link
        key={bannerItemUrl}
        sx={styles.bgWrapper}
        href={bannerItemUrl}
        data-test="banner-widget"
        onClick={() => handleClick()}
        style={{
          backgroundColor: bannerBGColor,
        }}
      >
        <Box sx={styles.bgBanner}>
          <PureHtmlTypography
            sx={styles.bgBanner}
            data-test="banner-widget-body"
          >
            {bannerItemBody}
          </PureHtmlTypography>
        </Box>
      </Link>
    </Box>
  );
};

BannerLink.defaultProps = {
  bannerBGColor: '',
};
