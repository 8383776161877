import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { StoreAvailableForShipmentState } from 'redux/PDP/availability/for-shipment/store-available-for-shipment.types';
import { createSlice } from '@reduxjs/toolkit';
import { getAvailableStoreForShipment } from 'redux/PDP/availability/for-shipment/store-available-for-shipment.actions';

const initialState: StoreAvailableForShipmentState = {
  storeId: null,
  storeNumber: null,
  loading: false,
  status: Status.INIT,
  error: null,
};

const shipmentSlice = createSlice({
  name: 'availability/stores/for-shipment',
  initialState,
  reducers: {
    setStoreAvailableForShipmentInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableStoreForShipment.pending, () => ({
        ...initialState,
        status: Status.PENDING,
        loading: true,
      }))
      .addCase(getAvailableStoreForShipment.rejected, (_, { payload }) => ({
        ...initialState,
        status: Status.ERROR,
        error: payload as ResponseError,
      }))
      .addCase(getAvailableStoreForShipment.fulfilled, (_, { payload }) => ({
        ...initialState,
        status: Status.FULFILLED,
        storeId: payload.storeId,
        storeNumber: payload.storeNumber,
      }))
      .addDefaultCase((state) => state);
  },
});

export const { reducer: shipmentReducer } = shipmentSlice;

export const { setStoreAvailableForShipmentInitialState } =
  shipmentSlice.actions;
