import * as selectors from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import { Area } from 'api/area/types/area.interface';
import { FulfillmentOptionType } from 'types/cart.types';
import { Store } from 'redux/types/store.types';
import { fetchStores } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.actions';
import { getReferredStoreList } from 'utils/getReferredStore';
import { isDelivery } from 'utils/is-order-type';
import { selectIsStoreOrRecipientLock } from 'redux/session/availability/availability.selectors';
import { selectStores } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useShouldShowTestStore } from 'components/PDP/Details/components/PasControl/components/StoreSelector/hooks/use-should-show-test-store';

export function useStores({
  arrangementId,
  area,
  orderType,
  date,
  productId,
}: {
  arrangementId: number;
  area: Area | null;
  orderType: FulfillmentOptionType;
  date: Date | null;
  productId: number | null;
}): Store[] {
  const dispatch = useDispatch();
  const retrievedStores = useSelector(selectStores);
  const isStoreOrRecipientLock = useSelector(selectIsStoreOrRecipientLock);
  const shouldShowTestStore = useShouldShowTestStore();
  const isOnDateFulfilled = useSelector(
    selectors.selectIsDateAvailabilityFulfilled,
  );

  useEffect(
    () => {
      if (
        area &&
        date &&
        isOnDateFulfilled &&
        !isStoreOrRecipientLock &&
        productId
      ) {
        dispatch(
          fetchStores({
            areaId: area.id,
            areaName: area.name,
            date,
            orderType,
            test: shouldShowTestStore,
            arrangementId,
            productId,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderType, isOnDateFulfilled],
  );
  const storeNumberFromLocalStorage = Number(localStorage.getItem('store'));

  const stores =
    storeNumberFromLocalStorage && area && isDelivery(orderType)
      ? getReferredStoreList(
          retrievedStores,
          area.name,
          storeNumberFromLocalStorage,
        )
      : [...retrievedStores];

  return stores;
}
