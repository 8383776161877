import { orderContactsCommonLocale } from 'components/PDP/Order/RecipientForm/components/Contacts/translations/order-contacts.common.locale';
import { orderHeaderCommonLocale } from 'components/PDP/Order/Header/translations/common.locale';
import { orderOccasionsCommonLocale } from 'components/PDP/Order/RecipientForm/components/Occasion/translations/common.locale';
import { orderPickupFormCommonLocale } from 'components/PDP/Order/RecipientForm/PickupForm/translations/common.locale';
import { recipientFormCommonLocale } from 'components/PDP/Order/RecipientForm/translations/common.locale';

export const pdpOrderCommonLocale = {
  ...orderHeaderCommonLocale,
  ...orderContactsCommonLocale,
  ...recipientFormCommonLocale,
  ...orderOccasionsCommonLocale,
  ...orderPickupFormCommonLocale,
};
