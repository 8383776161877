import { AxiosError } from 'axios';
import { StoreRankingParams } from 'components/Cart/Details/components/OrderSummary/feature/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseAxiosError } from 'utils/errors';
import { storesApiService } from 'api/stores.api.service';

export const retrieveStoreDetails = createAsyncThunk(
  'stores/details',
  async ({ friendlyUrl }: { friendlyUrl: string }, { rejectWithValue }) => {
    try {
      const storeDetails = await storesApiService.getStoreDetailByFriendlyUrl(
        friendlyUrl,
      );
      return storeDetails;
    } catch (err) {
      return rejectWithValue(parseAxiosError(<AxiosError>err));
    }
  },
);
export const getStoreRanking = createAsyncThunk(
  'store/ranking',
  async (storeList: string, { rejectWithValue }) => {
    try {
      const response = await storesApiService.getStoreRanking(storeList);
      return response;
    } catch (err) {
      return rejectWithValue(parseAxiosError(err));
    }
  },
);

export const saveRanking = createAsyncThunk(
  'save/ranking',
  async (dataParams: StoreRankingParams[], { rejectWithValue }) => {
    try {
      const response = await storesApiService.updateStoreRankingDetails(
        dataParams,
      );
      return response;
    } catch (ex) {
      const error = ex as AxiosError;

      return rejectWithValue(parseAxiosError(error));
    }
  },
);
