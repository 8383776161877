import { appInsights } from 'utils/telemetry/app-insights';
import { happyNotFoundResponseInterceptor } from 'api/interceptors/happy-not-found-response.interceptor';
import { htmlErrorInterceptor } from 'api/interceptors/html-error.interceptor';
import { rejectGracefullyBadRequestResponse } from 'api/interceptors/reject-gracefully-bad-request-response.interceptor';
import { rejectGracefullyForbiddenResponse } from 'api/interceptors/reject-gracefully-forbidden-response.interceptor';
import { rejectGracefullyNotFoundResponse } from 'api/interceptors/reject-gracefully-not-found-response.interceptor';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

export class ApiBaseService {
  apibase: AxiosInstance;

  constructor() {
    this.apibase = axios.create({
      baseURL: '/api',
    });

    axiosRetry(this.apibase, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 500;
      },
      retryCondition: (error) => {
        appInsights.trackEvent({ name: 'Axios Retry' });
        if (error.response === undefined) {
          // handle network errors
        }
        return true;
      },
    });

    this.apibase.interceptors.response.use(
      happyNotFoundResponseInterceptor,
      htmlErrorInterceptor,
    );
    this.apibase.interceptors.response.use(rejectGracefullyForbiddenResponse);
    this.apibase.interceptors.response.use(rejectGracefullyBadRequestResponse);
    this.apibase.interceptors.response.use(rejectGracefullyNotFoundResponse);
  }
}
