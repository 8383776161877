import { SxProps } from '@mui/system';

export const wrapper: SxProps = {
  mr: 2,
  minWidth: 90,
};

export const button: SxProps = {
  alignItems: 'flex-start',
  '& img': {
    height: 20,
    width: 20,
    mr: 1,
  },
};

export const menuItem: SxProps = {
  display: 'flex',
  alignItems: 'center',
  '& img': {
    height: 24,
    width: 24,
    mr: 1,
  },
};
