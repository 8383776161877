import { Box, Button } from '@mui/material';
import {
  BoxContainer,
  buttonWrapper,
} from 'components/Home/NotificationBanner/styles';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { fonts } from 'utils/theme/fonts';
import { isUrlToAnotherResource } from 'utils/router';
import { notificationBannerCTAClicked } from 'components/Home/NotificationBanner/utils/analytics';
import { useNavigate } from 'react-router-dom';
import React, { FC } from 'react';

type NotificationBannerProps = {
  sequence: number;
  bannerBGColor: string;
  bannerText: string;
  buttonText: string;
  buttonCTALink: string;
  buttonBGColor: string;
  message: string;
  isButtonText: boolean;
};

export const NotificationBannerItem: FC<NotificationBannerProps> = ({
  sequence,
  bannerBGColor,
  bannerText,
  buttonText,
  buttonCTALink,
  buttonBGColor,
  message,
  isButtonText,
}) => {
  const isExternalURL = isUrlToAnotherResource(buttonCTALink);
  const navigate = useNavigate();
  const handleCTAClick = () => {
    notificationBannerCTAClicked(buttonText, buttonCTALink);
    if (isExternalURL) {
      window.open(buttonCTALink, '_self');
    } else {
      navigate(buttonCTALink);
    }
  };

  const contentLeftPadding = isButtonText
    ? '20px !important'
    : '0px !important';

  const maxWidthValue = isButtonText ? '208px' : '295px';
  const MobileMaxWidthValue = isButtonText ? '239px' : '232px';
  const MobileMinWidthValue = isButtonText ? '232px' : null;

  return (
    <BoxContainer
      key={sequence}
      bannerbgcolor={bannerBGColor}
      isButtonText={isButtonText}
      onClick={handleCTAClick}
    >
      <PureHtmlTypography
        sx={{
          fontFamily: fonts.poppins,
          fontSize: '14px',
          fontWeight: '500',
          maxWidth: [MobileMaxWidthValue, maxWidthValue, maxWidthValue],
          paddingLeft: [contentLeftPadding, '0px !important', '0px !important'],
          minWidth: [MobileMinWidthValue, null, null],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: '10px',
          alignSelf: 'stretch',
        }}
      >
        {bannerText
          .replace('&lt;delivery_time&gt;', message)
          .replace('&lt;countdown_time&gt;', message)}
      </PureHtmlTypography>
      {isButtonText && (
        <Box sx={buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: buttonBGColor,
              padding: ['5px 16px', '9px 16px', '9px 16px'],
              borderRadius: '0px',
              width: '100%',
              '&.MuiButton-root:hover': {
                backgroundColor: buttonBGColor,
                opacity: 0.7,
              },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
            }}
          >
            <PureHtmlTypography
              sx={{
                fontFamily: fonts.poppins,
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              {buttonText}
            </PureHtmlTypography>
          </Button>
        </Box>
      )}
    </BoxContainer>
  );
};
