import { Filter, FruitFilter } from 'api/filter-categories/types';
import { useBadFiltersSanitizer } from 'containers/PLPContainer/hooks/query/filters/use-bad-filters-sanitizer';
import { usePlpFilters } from 'containers/PLPContainer/hooks/query/filters/use-plp-filters';
import { useSetQueryFilters } from 'containers/PLPContainer/hooks/query/filters/use-set-query-filters';

export const usePlpFiltersState = (): [
  Filter[],
  FruitFilter[],
  (filters: Filter[], fruitFilters?: FruitFilter[]) => void,
] => {
  useBadFiltersSanitizer();
  const setFilters = useSetQueryFilters();
  const { filters, fruitFilters } = usePlpFilters();
  return [filters, fruitFilters, setFilters];
};
