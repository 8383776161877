import {
  AddToCartProduct,
  DataItem,
  Filter,
  HomePageClickEvent,
  PlpPageClickEvent,
  ShipmentRecipientFormProps,
  SiteInteraction,
  Store,
} from 'service/adobe-analytics/types';
import {
  AddressValidationDecision,
  AuthStatus,
  ButtonModulePosition,
  CartRecipientAmountType,
  Event,
  EventLocation,
  Forms,
  ModalCtaLocation,
  OneHourDeliveryEvent,
  PDPAvailabilityModal,
  PageType,
  Platform,
  Promo,
  PromoModule,
  UserAgent,
} from 'service/adobe-analytics/enums';
import {
  AnalyticsCartItem,
  CartProductForAnalytics,
} from 'api/cart/types/cart-item.type';
import { Area } from 'api/area/types/area.interface';
import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { ArrangementPreview } from 'api/arrangements/types';
import { CurrencyProvider } from 'utils/CurrencyProvider';
import { HOME_PAGE_CLICK_COOKIE_KEY } from 'utils/cookies-storage';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import {
  Order,
  OrderItem,
  OrderSubItem,
  Sale,
} from 'redux/order-confirmation/types/types';
import { PaymentModeEnum } from 'enums/payment-enum';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { ValidOrderTypes } from 'types/cart.types';
import {
  createLoginPagePrefix,
  getSiteInfo,
  getVersion,
} from 'service/adobe-analytics/utils';
import { extractTextFromHTML } from 'utils/string/extract-text-from-html';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { getByType } from 'utils/getProductPostfixByType';
import { getCurrentCountry } from 'utils/country';
import { getCurrentEnvironment } from 'utils/environment';
import {
  getPageLoadCompletedEvent,
  getPaymentMethodText,
} from 'service/adobe-analytics/helpers';
import { isNotUndefined } from 'utils/is-not-undefined';
import { isZero } from 'utils/numbers';
import { priceRegex } from 'utils/regex';
import Cookies from 'js-cookie';

const getFriendlyUrl = () =>
  window.location.pathname.replace(/.*\/([^/]+)\/*$/, '$1');

export class AdobeAnalytics {
  private readonly environment = getCurrentEnvironment();

  private readonly userAgent = IS_MOBILE_HOST
    ? UserAgent.Mobile
    : UserAgent.Desktop;

  protected digitalData: DataItem[];

  private static instance: AdobeAnalytics;

  private readonly version = getVersion();

  constructor() {
    this.digitalData = window.digitalData || [];
  }

  private digitalDataPush(item: DataItem) {
    const pushItem = {
      ...item,
      site: getSiteInfo(),
      country: getCurrentCountry(),
    };
    this.digitalData.push(pushItem);
  }

  public digitalDataPushList(items: DataItem[] | DataItem): AdobeAnalytics {
    if (Array.isArray(items)) {
      items.forEach((item) => this.digitalDataPush(item));
    } else {
      this.digitalDataPush(items);
    }
    return this;
  }

  public pushPageData(
    type: PageType,
    platform = Platform.ReactNew,
  ): AdobeAnalytics {
    this.digitalDataPush(
      this.generatePushDataEvent({
        event: Event.PageData,
        platform,
        page: {
          name: `${getFriendlyUrl()}`,
          type,
        },
      }),
    );
    return this;
  }

  private generatePushDataEvent = (data: DataItem): DataItem => {
    if (data.event === Event.PageData) {
      let { click } = data;
      const homePageClickObj = Cookies.get(HOME_PAGE_CLICK_COOKIE_KEY);
      const homePageData = homePageClickObj
        ? (JSON.parse(homePageClickObj) as HomePageClickEvent['click'])
        : null;
      if (homePageData) {
        click = homePageData;
        Cookies.remove(HOME_PAGE_CLICK_COOKIE_KEY);
      }
      return { ...data, click };
    }
    return data;
  };

  private static getGlobalUserId() {
    return '';
  }

  public formStart = (formType: Forms): void => {
    this.digitalDataPush({
      event: Event.FormStart,
      form: {
        name: formType,
      },
    });
  };

  public formRewards = (formType: Forms): void => {
    this.digitalDataPush({
      event: Event.FormRewards,
      form: {
        name: formType,
      },
    });
  };

  public static getInstance(): AdobeAnalytics {
    if (!AdobeAnalytics.instance) {
      AdobeAnalytics.instance = new AdobeAnalytics();
    }
    return AdobeAnalytics.instance;
  }

  public pageCompleted = (): void => {
    this.digitalDataPush(getPageLoadCompletedEvent());
  };

  public cartPageInitialized = (
    cartRecipientAmountType: CartRecipientAmountType,
    cartItems: AnalyticsCartItem[],
    freeDeliveryLeftAmount?: number,
  ): void => {
    const productItem = cartItems.map((item) => ({
      productInfo: {
        id: item.productID,
        units: item.qty,
        price: item.itemPrice,
        cartAddLocation: EventLocation.AddOnModal,
        modalCta: ModalCtaLocation.AddOnModal,
        occasionSelected: '',
      },
    }));

    const freeDeliveryDetails = () => {
      return freeDeliveryLeftAmount
        ? {
            freeDeliveryLeft:
              freeDeliveryLeftAmount < 0
                ? 0
                : Number(freeDeliveryLeftAmount?.toFixed(2)),
            freeDeliveryThreshold: window.MIN_ORDER_TOTAL_THRESHOLD,
          }
        : '';
    };

    this.digitalDataPush({
      event: Event.ShoppingCart,
      products: [...productItem],
      cartType: cartRecipientAmountType,
      ...freeDeliveryDetails(),
    });
    this.pageCompleted();
  };

  public addressValidation = (doesSuggestionExist: boolean): void => {
    this.digitalDataPush({
      event: Event.AddressValidationModule,
      suggestedAddress: doesSuggestionExist,
    });
  };

  public addressValidationSelection = (
    addressValidationDecision: AddressValidationDecision,
  ): void => {
    this.digitalDataPush({
      event: Event.AddressValidationSelection,
      selection: addressValidationDecision,
    });
  };

  public pageInit = (platform = Platform.React): AdobeAnalytics => {
    this.digitalDataPush({
      event: Event.PageInit,
      platform,
    });
    return this;
  };

  public productsDetailPage = (
    arrangement: Arrangement,
    category = '',
    platform = Platform.React,
  ): void => {
    if (isNotUndefined(window.s)) {
      window.s.currencyCode = CurrencyProvider.getCurrency(arrangement.culture);
    }
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        platform,
        page: {
          name: window.location.pathname.substring(1),
          type: PageType.ProductDetailedPage,
        },
      }),
      {
        event: Event.ProductViewed,
        products: arrangement.products.map((product) => ({
          productInfo: {
            id: `${product.id}${getByType(product.type)}`,
            originalPrice: product.price.toString().replace(priceRegex, ''),
            discountedPrice: product.price.toString().replace(priceRegex, ''),
            category,
          },
        })),
      },
      getPageLoadCompletedEvent(),
    ]);
  };

  /** Showing PAS */
  public storeSelector = (platform = Platform.React): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        platform,
        page: {
          name: `fruit-gifts%2f${getFriendlyUrl()}/store-selector`,
          type: PageType.ProductDetailedPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  /**
   * Invoked on store selection without any submission or processing,
   * just on simple selection
   */
  public storeSelected = ({
    enteredZipCode,
    selectedServiceType,
    selectedStoreDistance,
    selectedStoreNumber,
    selectedZipCode,
    selectedDate,
  }: Store): void => {
    this.digitalDataPush({
      event: Event.StoreSelected,
      store: {
        enteredZipCode,
        selectedZipCode,
        selectedServiceType,
        selectedStoreDistance,
        selectedStoreNumber,
        selectedDate,
      },
    });
  };

  public upgradeYourGift = (platform = Platform.React): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        platform,
        page: {
          name: `fruit-gifts%2f${getFriendlyUrl()}/upgrade-your-gift`,
          type: PageType.ProductDetailedPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public headerMenuItemClicked = (linkClicked: string): void => {
    const id = 'global header click';
    this.digitalDataPush({
      event: Event.MenuNavClicked,
      id,
      linkClicked,
    });
  };

  public footerMenuItemClicked = (linkClicked: string): void => {
    const id = 'global footer click';
    this.digitalDataPush({
      event: Event.MenuNavClicked,
      id,
      linkClicked,
    });
  };

  public cartPageModalLoad = (name: string): void => {
    this.digitalDataPush({
      event: Event.CartPageModalLoad,
      modal: {
        name,
      },
    });
  };

  public addOnsModalLoad = (name: string, cta: string): void => {
    this.digitalDataPush({
      event: Event.CartPageModalLoad,
      modal: {
        name,
        cta,
      },
    });
  };

  public editProductModalLoad = (
    name: string,
    cta: string,
    productId: string,
  ): void => {
    this.digitalDataPush({
      event: Event.CartPageModalLoad,
      modal: {
        name,
        cta,
        product: productId,
      },
    });
  };

  public PdpAvailableModalLoad = (
    orderType: ValidOrderTypes,
    date: Date,
    area: Area,
  ): void => {
    let fulfillment = '';
    if (orderType === 1) fulfillment = 'delivery';
    if (orderType === 2) fulfillment = 'pickup';
    if (orderType === 3) fulfillment = 'shipment';
    this.digitalDataPush({
      event: Event.PDPAvailabilityHelperLoad,
      zipEntered: area.name,
      dateEntered: formatSQLDate(date),
      fulfillmentTime: `${
        date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`,
      fulfillmentType: fulfillment,
    });
  };

  public ShopAvailableProductsClickEvent = (
    orderType: ValidOrderTypes,
    date: Date,
    area: Area,
    productSKU: string,
  ): void => {
    let fulfillment = '';
    if (orderType === 1) fulfillment = 'delivery';
    if (orderType === 2) fulfillment = 'pickup';
    if (orderType === 3) fulfillment = 'shipment';
    this.digitalDataPush({
      event: Event.ShopAvailableProductsModalLoad,
      zipEntered: area.name,
      dateEntered: formatSQLDate(date),
      fulfillmentTime: `${
        date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`,
      fulfillmentType: fulfillment,
      moduleName: PDPAvailabilityModal.ShopAvailableProductsModal,
      originalProduct: productSKU,
    });
  };

  public ProductClickEvent = (
    orderType: ValidOrderTypes,
    date: Date,
    area: Area,
    productSKU: string,
    productPos: number,
    arrangement: ArrangementPreview,
  ): void => {
    let fulfillment = '';
    if (orderType === 1) fulfillment = 'delivery';
    if (orderType === 2) fulfillment = 'pickup';
    if (orderType === 3) fulfillment = 'shipment';
    this.digitalDataPush({
      event: Event.ShopAvailableProductsModalLoad,
      zipEntered: area.name,
      dateEntered: formatSQLDate(date),
      fulfillmentTime: `${
        date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`,
      fulfillmentType: fulfillment,
      moduleName: PDPAvailabilityModal.FulfillmentModal,
      originalProduct: productSKU,
      moduleType: PDPAvailabilityModal.ProductCarouselModuleType,
      productPosition: productPos,
      products: {
        productInfo: {
          id: arrangement.id,
          price: arrangement.minPrice,
          units: 1,
          productFindLocation: PDPAvailabilityModal.ShopAvailableProductsModal,
        },
      },
    });
  };

  public SuggestedDateClickEvent = (
    fulfillment: string,
    date: Date,
    area: Area,
    productSKU: string,
    suggestedDate: Date,
  ): void => {
    this.digitalDataPush({
      event: Event.SuggestedDateModalLoad,
      zipEntered: area.name,
      dateEntered: formatSQLDate(date),
      fulfillmentTime: `${
        date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
      }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`,
      fulfillmentType: fulfillment,
      moduleName: PDPAvailabilityModal.ShopAvailableProductsModal,
      originalProduct: productSKU,
      newDateSelected: formatSQLDate(suggestedDate),
      newFulfillmentTime: `${
        suggestedDate.getHours() > 12
          ? suggestedDate.getHours() - 12
          : suggestedDate.getHours()
      }:${suggestedDate.getMinutes()} ${
        suggestedDate.getHours() >= 12 ? 'PM' : 'AM'
      }`,
    });
  };

  public shipmentRecipientForm = ({
    product,
    upsellItems,
    addonButtonPosition,
  }: ShipmentRecipientFormProps): void => {
    const personalizedOptions = upsellItems.map((upsell: UpsellItem) => ({
      productInfo: {
        id: `${upsell.option.productId}${getByType(upsell.productType)}`,
      },
    }));
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: `fruit-gifts%2f${getFriendlyUrl()}/Recipient-Information`,
          type: PageType.ProductDetailedPage,
        },
      }),
      {
        event: Event.AddonUpgrades,
        products: [
          { productInfo: { id: `${product.id}${getByType(product.type)}` } },
        ].concat(personalizedOptions),
        button: addonButtonPosition ?? '',
      },
    ]);
    this.orderForm();
  };

  /** Showing recipient form */
  public orderForm = (): void => {
    this.digitalDataPushList([
      {
        event: Event.FormStart,
        form: { name: Forms.OrderForm },
      },
      getPageLoadCompletedEvent(),
    ]);
  };

  public pushErrors = (errors: string[]): void => {
    this.digitalDataPush({
      event: Event.Error,
      errors,
    });
  };

  public plpInitPage = (platform = Platform.React): void => {
    this.pageInit(platform);
    const plpInterrupterData = Cookies.get('PLPInterrupterData');
    const plpInteruppterClick = () => {
      let clickObject;
      if (plpInterrupterData && plpInterrupterData !== '') {
        const data = JSON.parse(plpInterrupterData) as ArrangementPreview;
        clickObject = {
          click: {
            elementType: 'plp interrupter',
            elementPosition: data.sequence.toString(),
            elementName: data.image?.split('/').pop() ?? '',
          },
        };
        Cookies.remove('PLPInterrupterData');
      }
      return clickObject;
    };

    const plpClickObj = Cookies.get('PLPClickObject');

    const plpWidgetClick = () => {
      let plpWdgetClickEvent;
      if (plpClickObj && plpClickObj !== '') {
        const data = JSON.parse(plpClickObj || '{}') as PlpPageClickEvent;
        plpWdgetClickEvent = {
          click: {
            zone: data.zone,
            moduleType: data.module_type,
            moduleName: data.module_name,
            position: data.position,
            link: data?.link || '',
          },
        };
        Cookies.remove('PLPClickObject');
      }
      return plpWdgetClickEvent;
    };

    const giftFinderClickObj = Cookies.get('GiftFinderClickObject');
    const giftFinderWidgetClick = () => {
      let giftFinderWdgetClickEvent;
      if (giftFinderClickObj && giftFinderClickObj !== '') {
        const data = JSON.parse(
          giftFinderClickObj || '{}',
        ) as PlpPageClickEvent;
        giftFinderWdgetClickEvent = {
          click: {
            zone: data.zone,
            moduleType: data.module_type,
            moduleName: data.module_name,
            position: data.position,
            link: data?.link || '',
          },
        };
        Cookies.remove('GiftFinderClickObject');
      }
      return giftFinderWdgetClickEvent;
    };

    this.digitalDataPush(
      this.generatePushDataEvent({
        event: Event.PageData,
        platform,
        page: {
          name: `${getFriendlyUrl()}`,
          type: PageType.ProductListPage,
        },
        ...plpInteruppterClick(),
        ...plpWidgetClick(),
        ...giftFinderWidgetClick(),
      }),
    );
  };

  public plpInterrupterLoad = (imageName: string): void => {
    this.digitalDataPush({
      event: Event.PlpInterrupterLoad,
      name: imageName,
    });
  };

  public plpInterrupterClick = (imageName: string): void => {
    this.digitalDataPush({
      event: Event.PlpInterrupterClicked,
      name: imageName,
    });
  };

  public localSeoInitPage = (): void => {
    this.pageInit();
    this.digitalDataPush(
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'Edible Store Directory',
          type: PageType.LocalSeoPage,
        },
      }),
    );
  };

  public shopAvailableProducts = (): void => {
    this.digitalDataPush({
      event: Event.ShopAvailableProducts,
    });
  };

  public localSeoPageData = (
    cityState: string,
    pageType: PageType.LocalSeoStatePage | PageType.LocalSeoCityPage,
  ): void => {
    this.digitalDataPush(
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: cityState,
          type: pageType,
        },
      }),
    );
  };

  public productRefinement = (filter: Filter): void => {
    this.digitalDataPush({
      event: Event.ProductRefinement,
      filter,
    });
  };

  public pushSiteInteraction = (name: SiteInteraction): void => {
    this.digitalDataPush({
      event: Event.SiteInteraction,
      interaction: { name },
    });
  };

  public addToCart = (
    product: CartProductForAnalytics[],
    freeDeliveryLeftAmount?: number,
  ): void => {
    const productItem = product.map((item) => ({
      productInfo: {
        id: item.id,
        units: item.units,
        price: item.price,
        cartAddLocation: item.cartAddLocation,
        modalCta: item.modalCta,
        occasionSelected: item.occasionSelected,
      },
    }));

    const freeDeliveryDetails = () => {
      return freeDeliveryLeftAmount
        ? {
            freeDeliveryLeft:
              freeDeliveryLeftAmount < 0
                ? 0
                : Number(freeDeliveryLeftAmount?.toFixed(2)),
            freeDeliveryThreshold: window.MIN_ORDER_TOTAL_THRESHOLD,
          }
        : '';
    };

    this.digitalDataPush({
      event: Event.AddToCart,
      products: [...productItem],
      ...freeDeliveryDetails(),
    });
  };

  public editProductInCart = (product: CartProductForAnalytics): void => {
    this.digitalDataPush({
      event: Event.AddToCart,
      products: {
        productInfo: product,
      },
    });
  };

  public removeFromCart = (
    product: CartProductForAnalytics,
    freeDeliveryLeftAmount?: number,
  ): void => {
    const freeDeliveryDetails = () => {
      return freeDeliveryLeftAmount
        ? {
            freeDeliveryLeft:
              freeDeliveryLeftAmount < 0
                ? 0
                : Number(freeDeliveryLeftAmount?.toFixed(2)),
            freeDeliveryThreshold: window.MIN_ORDER_TOTAL_THRESHOLD,
          }
        : '';
    };
    this.digitalDataPush({
      event: Event.RemoveFromCart,
      products: {
        productInfo: product,
      },
      ...freeDeliveryDetails(),
    });
  };

  public addedToCart({
    product,
    addonItems,
    culture,
  }: {
    product: AddToCartProduct;
    addonItems: UpsellItem[];
    culture: string;
  }): void {
    if (isNotUndefined(window.s)) {
      window.s.currencyCode = CurrencyProvider.getCurrency(culture);
    }

    const addons = addonItems.map((addon) => ({
      productInfo: {
        id: `${addon.option.productId}${getByType(addon.productType)}`,
        units: Number(addon.quantity),
        price: addon.option.price,
      },
    }));

    this.digitalDataPushList([
      {
        event: Event.FormComplete,
        form: { name: Forms.OrderForm },
      },
      {
        event: Event.AddToCart,
        products: [{ productInfo: product }, ...addons],
      },
    ]);
  }

  public editProductInteraction = (name: string): void => {
    this.digitalDataPush({
      event: Event.EditProductInteraction,
      name,
    });
  };

  public homePage = (isUserLoggedIn: boolean, userId: number): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'home',
          type: PageType.HomePage,
        },
        platform: Platform.React,
      }),
      {
        event: Event.UserData,
        user: {
          authStatus: isUserLoggedIn ? AuthStatus.Auth : AuthStatus.NotAuth,
          id: String(userId),
        },
      },
      getPageLoadCompletedEvent(),
    ]);
  };

  public homePageClick = (clickObj: HomePageClickEvent['click']): void => {
    this.digitalDataPush({
      event: Event.HomePageClick,
      click: clickObj,
    });
  };

  public skinnyBannerClick = (
    moduleName: string,
    position: number,
    modelText: string,
  ): void => {
    this.digitalDataPush({
      event: Event.SkinnyBannerClick,
      click: {
        position: position.toString(),
        module: moduleName,
        text: extractTextFromHTML(modelText),
      },
    });
  };

  public giftFinderInteraction = (field: {
    name: string;
    value: string[];
  }): void => {
    this.digitalDataPush({
      event: Event.GiftFinderInteraction,
      field,
    });
  };

  public rewardsRegistration = (): void => {
    this.digitalDataPush({
      event: Event.RewardsRegistration,
    });
  };

  public billingInformationCompleted = (): void => {
    this.digitalDataPush({
      event: Event.FormComplete,
      form: { name: Forms.BillingInfoForm },
    });
  };

  public promoSignUp = (
    promoType: Promo = Promo.Email,
    promoModule: PromoModule = PromoModule.Modal,
  ): void => {
    this.digitalDataPush({
      event: Event.PromoSignUp,
      promo: {
        type: promoType,
        module: promoModule,
      },
    });
  };

  public placeOrderPromoSignUp = (): void => {
    this.digitalDataPush({
      event: Event.SubscriptionSignup,
    });
  };

  public signUpStoreComingSoon = (promo: Promo = Promo.Email): void => {
    this.digitalDataPush({
      event: Event.SignUpStoreComingSoon,
      promo: {
        type: promo,
      },
    });
  };

  public loginDialog = (): void => {
    const loginPagePrefix = createLoginPagePrefix();

    this.digitalDataPushList([
      {
        event: Event.FormStart,
        form: {
          name: Forms.LoginForm,
        },
      },
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: `${loginPagePrefix}/login-to-your-account`,
          type: `${loginPagePrefix}/${PageType.Login}`,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public startDeliveryFormEdits = (): void => {
    this.digitalDataPush({
      event: Event.FormStart,
      form: {
        name: Forms.EditDeliveryInfoForm,
      },
    });
  };

  public saveDeliveryFormEdits = (): void => {
    this.digitalDataPush({
      event: Event.FormComplete,
      form: {
        name: Forms.EditDeliveryInfoForm,
      },
    });
  };

  public startPickupFormEdits = (): void => {
    this.digitalDataPush({
      event: Event.FormStart,
      form: {
        name: Forms.EditPickupInfoForm,
      },
    });
  };

  public savePickupFormEdits = (): void => {
    this.digitalDataPush({
      event: Event.FormComplete,
      form: {
        name: Forms.EditPickupInfoForm,
      },
    });
  };

  public loginFormCompleted = (): void => {
    this.digitalDataPushList([
      {
        event: Event.FormComplete,
        form: {
          name: Forms.LoginForm,
        },
      },
      {
        event: Event.Login,
      },
    ]);
  };

  public search = (term: string, results: number): void => {
    this.digitalDataPush({
      event: Event.ProductSearch,
      search: {
        type: 'product',
        term,
        results,
        nullResults: isZero(results),
      },
    });
  };

  public productCarouselClicked = (productCarouselIndex: number): void => {
    this.digitalDataPush({
      event: Event.ProductCarouselClicked,
      merchandising: {
        zone: 'product carousel',
        position: productCarouselIndex,
      },
    });
  };

  public oneHourDelivery = (event: OneHourDeliveryEvent): void => {
    this.digitalDataPush({
      event: event
        ? Event.OneHourDeliverySelected
        : Event.OneHourDeliveryRemoved,
    });
  };

  public continueShopping = (position: ButtonModulePosition): void => {
    this.digitalDataPush({
      event: Event.ContinueShopping,
      position,
    });
  };

  public startShopping = (position: ButtonModulePosition): void => {
    this.digitalDataPush({
      event: Event.StartShopping,
      position,
    });
  };

  public rewardApplied = (discountAmount: number): void => {
    this.digitalDataPush({
      event: Event.RewardApplied,
      amount: [discountAmount],
    });
  };

  public promoCodeApplied = (promo: { code: string; amount: number }): void => {
    this.digitalDataPush({
      event: Event.PromoCodeApplied,
      promo,
    });
  };

  public alternativePaymentMethod = (paymentMethod: PaymentModeEnum): void => {
    this.digitalDataPush({
      event: Event.AlternativePaymentMethodUsed,
      paymentMethodUsed: getPaymentMethodText(paymentMethod),
    });
  };

  public giftFinderClicked = (): void => {
    this.digitalDataPush({
      event: Event.GiftFinderClicked,
    });
  };

  public giftFinderOptionsSelected = (
    occasion: string[],
    priceRange: string[],
    favoriteFruit: string[],
  ): void => {
    this.digitalDataPush({
      event: Event.GiftFinderFindGifts,
      options: {
        occasion,
        priceRange,
        favoriteFruit,
      },
    });
  };

  public printablePopUpClicked = (): void => {
    this.digitalDataPush({
      event: Event.PrintablePopupClicked,
    });
  };

  public printablePopUpCardClicked = (): void => {
    this.digitalDataPush({
      event: Event.PrintablePopupCardClicked,
    });
  };

  public printablePopUpMessageClicked = (): void => {
    this.digitalDataPush({
      event: Event.PrintablePopupMessageClicked,
    });
  };

  public purchaseConfirmation = (
    sale: Sale,
    order: Order[],
    products: OrderItem[],
    addOns: OrderSubItem[],
    expressFulfillment?: string,
  ): void => {
    order.forEach((orderData) => {
      let discountType = '';
      if (orderData.couponCode) {
        discountType = 'coupon';
      }
      let fulfillment = '';
      const productsInfo: {
        productInfo: {
          id: string;
          price: string;
          units: string;
          discount: string;
          discount_type: string;
        };
      }[] = [];

      products
        ?.filter((product) => product.orderID === orderData.orderID)
        ?.map((product) => {
          const discount = product.regularPrice
            ? (product.regularPrice - product.price + product.discount).toFixed(
                2,
              )
            : product.discount;
          const price = product.regularPrice
            ? product.regularPrice
            : product.price;
          const discountTypePrice = product.regularPrice
            ? 'slash pricing'
            : discountType;
          const discountTypeValue =
            !!discountType && product.regularPrice
              ? `coupon | slash pricing`
              : discountTypePrice;
          const productsInfoValue = {
            id:
              product.type === 2
                ? `${product.productID}_package`
                : `${product.productID}_arr`,
            price: `${price}`,
            units: `${product.quantity}`,
            discount: `${discount}`,
            discount_type: discountTypeValue,
          };
          const subObject = { productInfo: productsInfoValue };
          productsInfo.push(subObject);
          return true;
        });

      const addOnInfo = addOns
        .filter(
          (a) =>
            a.orderID === orderData.orderID && a.type === 4 && a.itemType !== 2,
        )
        .map((addOn) => ({
          productInfo: {
            id: `${addOn.productID}_addon`,
            price: `${addOn.price}`,
            units: `${addOn.quantity}`,
            discount: `${addOn.discount}`,
            discount_type: `${discountType}`,
          },
        }));

      const upgradeInfo = addOns
        .filter(
          (a) =>
            a.orderID === orderData.orderID && a.type === 3 && a.itemType !== 2,
        )
        .map((addOn) => ({
          productInfo: {
            id: `${addOn.productID}_upgrade`,
            price: `${addOn.price}`,
            units: `${addOn.quantity}`,
            discount: `${addOn.discount}`,
            discount_type: `${discountType}`,
          },
        }));

      if (orderData.orderType === 1) fulfillment = 'hand delivery';
      if (orderData.orderType === 2) fulfillment = 'pickup';
      if (orderData.orderType === 3) fulfillment = 'shipment';

      const deliveryMethod = expressFulfillment || fulfillment;

      this.digitalDataPush({
        event: Event.PurchaseConfirmation,
        products: [...productsInfo, ...addOnInfo, ...upgradeInfo],
        transaction: {
          couponCode: orderData.couponCode,
          deliveryOrShippingAmount: `${orderData.deliveryOrShipmentCharge}`,
          deliveryOrShippingDiscount: `${orderData.deliveryOrShipmentChargeDiscount}`,
          fulfillingStoreNumber: `${orderData.storeNumber}`,
          fulfillmentMethod: fulfillment,
          orderDiscount: `${orderData.orderDiscount}`,
          orderID: `${orderData.number}`,
          orderOccasion: `${orderData.occasion}`,
          paymentMethod: PaymentModeEnum[sale.paymentMode].toString(),
          recipientZipCode: `${orderData.area}`,
          taxAmount: `${
            orderData.orderTax1 +
            orderData.orderTax2 +
            orderData.orderTax3 +
            orderData.orderTax4 +
            orderData.orderTax5
          }`,
          totalAmount: `${orderData.orderTotal}`,
          transactionID: `${orderData.number}`,
          deliveryMethod,
        },
      });
    });
  };

  public startNewOrderClicked = (visitorId: number): void => {
    this.digitalDataPush({
      event: Event.StartNewOrderClicked,
      visitorId,
    });
  };

  public wantToKeepShoppingClicked = (
    visitorId: number,
    id: number,
    alt: string,
    name: string,
    minPrice: number,
  ): void => {
    this.digitalDataPush({
      event: Event.WantToKeepShoppingClicked,
      visitorId,
      productInfo: {
        id,
        alt,
        name,
        minPrice,
      },
    });
  };

  public storeFinderInit = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'store finder',
          type: PageType.StoreFinderPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public storeFinderSearch = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'store finder results',
          type: PageType.StoreFinderResults,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public orderTrackingInit = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'track your order',
          type: PageType.OrderTrackingPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public orderTrackingSearch = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'track your order results',
          type: PageType.OrderTrackingResults,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public storeDetailPageInit = (
    isUserLoggedIn: boolean,
    userId: number,
  ): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: window.location.pathname,
          type: PageType.StoreDetailPage,
        },
      }),
      {
        event: Event.UserData,
        user: {
          authStatus: isUserLoggedIn ? AuthStatus.Auth : AuthStatus.NotAuth,
          id: String(userId),
        },
      },
      getPageLoadCompletedEvent(),
    ]);
  };

  public storeComingSoonInit = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'store coming soon page',
          type: PageType.StoreComingSoonPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public contactUsPageInit = (): void => {
    this.digitalDataPushList([
      this.generatePushDataEvent({
        event: Event.PageData,
        page: {
          name: 'contact us page',
          type: PageType.ContactUsPage,
        },
      }),
      getPageLoadCompletedEvent(),
    ]);
  };

  public zipTextFieldFocus = (): void => {
    this.digitalDataPush({
      event: Event.FormStart,
      form: {
        name: Forms.DeliveryInfoForm,
      },
    });
  };

  public orderConfirmationPageView = (): void => {
    this.digitalDataPush({
      event: Event.OrderConfirmationPageView,
    });
  };

  public complimentaryGiftMessageAIClicked = (): void => {
    this.digitalDataPush({
      event: Event.ComplimentaryGiftMessageAIClicked,
      name: 'ai gift message created',
    });
  };

  public cyoSelection = (
    type: string | undefined,
    name: string,
    position: number,
  ): void => {
    this.digitalDataPush({
      event: Event.CyoSelection,
      item: {
        type,
        name,
        position,
      },
    });
  };
}

export const analytics: AdobeAnalytics = AdobeAnalytics.getInstance();
