import { ProductCatalogItem, SearchCatalogItem } from 'components/Header/types';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';

export const getSearchCatalog = ({
  searchCatalog,
}: RootState): SearchCatalogItem[] => searchCatalog.searchItems;

export const getProductCatalog = ({
  searchCatalog,
}: RootState): ProductCatalogItem[] => searchCatalog.searchProducts;

const getStatus = ({ searchCatalog }: RootState): Status =>
  searchCatalog.status;

export const getIsPending = createSelector<RootState, Status | null, boolean>(
  getStatus,
  (status) => status === Status.PENDING,
);
