export const YOUTUBE_LINK = 'https://www.youtube.com/ediblearrangements';
export const PINTEREST_LINK = 'https://pinterest.com/dofruit/';
export const TWITTER_LINK = 'https://twitter.com/edible';
export const FACEBOOK_LINK =
  'https://www.facebook.com/ediblearrangements?mibextid=LQQJ4d';
export const INSTAGRAM_LINK =
  'https://instagram.com/accounts/login/?next=/ediblearrangements/';
export const LINKEDIN_LINK =
  'https://www.linkedin.com/company/edible-arrangements/';
export const INSTA_LINK = 'https://www.instagram.com/ediblearrangements/';
