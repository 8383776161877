import * as styles from 'containers/OrderConfirmationContainer/styles';
import { Box } from '@mui/material';
import { OrderDetailsPage } from 'components/Rewards/OrderDetails';
import React, { FC } from 'react';

export const OrderDetailsContainer: FC = () => {
  return (
    <Box sx={styles.containerWrapper}>
      <OrderDetailsPage />
    </Box>
  );
};
