const phoneMask = (phone: string): string =>
  phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const phoneModifier = (phone: string): string => {
  let digits = phone.replace(/\D/g, '');
  if (!digits) {
    return '';
  }
  digits = digits.length > 10 ? digits.slice(0, 10) : digits;

  return phoneMask(digits);
};
