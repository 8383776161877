import { SxProps } from '@mui/material';
import { theme } from 'utils/theme/theme';

export const getImageSx = ({ disabled }: { disabled?: boolean }): SxProps => ({
  width: 64,
  height: 64,
  borderRadius: 8,
  mb: 0.5,
  mr: 1.5,
  [theme.breakpoints.up('sm')]: {
    mr: 0,
  },
  [theme.breakpoints.up('xs')]: {
    mr: 0,
  },
  ...(disabled && {
    opacity: 0.3,
  }),
});
