import {
  BUTTON_NAME,
  GIFT_FINDER,
  GIFT_FINDER_COST_LABEL,
  GIFT_FINDER_REASON_LABEL,
  GIFT_FINDER_SPEED_LABEL,
  GIFT_FINDER_TEXT_ONE,
  GIFT_FINDER_TEXT_TWO,
  GIFT_FINDER_VALIDATION_ERROR,
  SELECTED_FRUIT_TITLE,
  SELECTED_OCCASION_TITLE,
  SELECTED_PRICE_TITLE,
  SELECT_FRUIT_TITLE,
  SELECT_OCCASION_TITLE,
  SELECT_PRICE_TITLE,
  TITLE,
} from 'components/GiftFinder/translations/locale.constants';

export const giftFinderCommonLocale = {
  [TITLE]: 'Gift Finder',
  [BUTTON_NAME]: 'Find Gifts',
  [SELECT_FRUIT_TITLE]: 'What’s your favorite fruit?',
  [SELECTED_FRUIT_TITLE]: 'Your Favorite Fruit',
  [SELECT_PRICE_TITLE]: 'What’s your budget?',
  [SELECTED_PRICE_TITLE]: 'Your Budget',
  [SELECT_OCCASION_TITLE]: 'What’s your occasion?',
  [SELECTED_OCCASION_TITLE]: 'Your Occasion',
  [GIFT_FINDER]: 'find the perfect gift',
  [GIFT_FINDER_TEXT_ONE]:
    'Looking for the best possible gift? Let us make your search quick and easy!',
  [GIFT_FINDER_TEXT_TWO]:
    'Answer as many questions below as you like, and we’ll find the perfect match for you.',
  [GIFT_FINDER_VALIDATION_ERROR]:
    'Please fill out at least one of the fields below',
  [GIFT_FINDER_SPEED_LABEL]: 'When do you need it?',
  [GIFT_FINDER_REASON_LABEL]: 'Reason',
  [GIFT_FINDER_COST_LABEL]: 'Price',
};
