import * as TK from 'components/About/translations/locale.constants';
import * as styles from 'components/About/styles';
import { Box, Link, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { Image } from 'components/common/Image/Image';

import { Links } from 'enums/common-links';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { WhoWeAreBreadcrumb } from 'components/About/WhoWeAre/WhoWeAreBreadcrumb';
import { useIsDownSm } from 'utils/hidden/use-is-down-sm';
import {
  whoWeAreArtOfWow,
  whoWeAreArtOfWowMobile,
  whoWeAreFreshDaily,
  whoWeAreFreshDailyMobile,
  whoWeAreGoLocal,
  whoWeAreGoLocalMobile,
  whoWeAreSpecialDelivery,
  whoWeAreSpecialDeliveryMobile,
} from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const WhoWeAre: FC = () => {
  const isMobile = useIsDownSm();
  return (
    <>
      <WhoWeAreBreadcrumb />
      <Box sx={styles.accountContainer}>
        <Box sx={styles.pressroomContainer}>
          <Box sx={styles.ContainerStyle}>
            <Typography
              variant="h4"
              fontWeight="500"
              style={{ marginBottom: '10px' }}
            >
              {i18next.t(TK.WHO_WE_ARE_TITLE)}
            </Typography>
            <Box style={{ display: 'block' }}>
              <PureHtmlTypography
                variant="body1"
                style={{ marginBottom: '10px' }}
              >
                {i18next.t(TK.WHO_WE_ARE_AT_EDIBLE)}
              </PureHtmlTypography>
              <PureHtmlTypography
                variant="body1"
                style={{ marginBottom: '10px' }}
              >
                {i18next.t(TK.WHO_WE_ARE_PRIDE)}
              </PureHtmlTypography>
              <PureHtmlTypography
                variant="body1"
                style={{ marginBottom: '10px' }}
              >
                {i18next.t(TK.NO_MATTER)}
              </PureHtmlTypography>
            </Box>
            <Box>
              <Link href={Links.FruitArrangements}>
                <Image
                  style={{ marginBottom: '10px' }}
                  alt={TK.MADE_FRESH_DAILY_ALT}
                  src={isMobile ? whoWeAreFreshDailyMobile : whoWeAreFreshDaily}
                />
              </Link>
              <Link href={Links.StoreLocator}>
                <Image
                  style={{ marginBottom: '10px' }}
                  alt={TK.GO_LOCAL_ALT}
                  src={isMobile ? whoWeAreGoLocalMobile : whoWeAreGoLocal}
                />
              </Link>
              <Box display={isMobile ? 'block' : 'flex'}>
                <Link href={HeaderLinks.OUR_MISSION}>
                  <Image
                    style={{ marginBottom: '10px' }}
                    alt={TK.ART_OF_WOW_ALT}
                    src={isMobile ? whoWeAreArtOfWowMobile : whoWeAreArtOfWow}
                  />
                </Link>
                <Link href={Links.FruitArrangements}>
                  <Image
                    sx={{
                      marginLeft: !isMobile ? '10px' : 0,
                    }}
                    alt={TK.EXTRA_SPECIAL_DELIVERY_ALT}
                    src={
                      isMobile
                        ? whoWeAreSpecialDeliveryMobile
                        : whoWeAreSpecialDelivery
                    }
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
