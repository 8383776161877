import * as TK from 'components/PDP/Details/components/ProductAccordion/translations/constants';

export const faqCommonLocale = {
  [TK.PRODUCT_DESCRIPTION]: 'About',
  [TK.PRODUCT_INGREDIENTS]: 'Product Description',
  [TK.QUESTIONS]: 'Questions? Ask Away!',
  [TK.COUPONS_APPLICABLE_TITLE]: 'Coupons Not Applicable',
  [TK.ALLERGY_WARNING_TITLE]: 'Allergy Warning: ',
  [TK.DISCLAIMER_TITLE]: 'Disclaimer: ',
};
