import { Typography } from '@mui/material';
import { theme } from 'utils/theme/theme';
import React, { FC } from 'react';

interface Props {
  color?: string;
}

export const PromotionalMessage: FC<Props> = ({ children, color }) => (
  <Typography variant="subtitle2" color={color ?? theme.palette.primary.main}>
    {children}
  </Typography>
);
