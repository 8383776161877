export const THANK_YOU_MSG = 'Thank You for Your Order!';
export const ORDER_DETAILS_TITLE = 'order details';
export const CONFIRM_MAIL = 'You should receive a confirmation email at';
export const CONFIRM_MSG =
  'shortly. If you don’t receive an email, re-send it using the button below.';
export const PRINT_CONFIRMATION = 'Print Confirmation';
export const PRINT_ORDER_DETAILS = 'Print Order Details';
export const RESEND_CONFIRMATION = 'Re-send Confirmation';
export const RECIPIENT_IMG = 'Recipient Image';
export const ORDER_TXT = 'In This Order';
export const CREDIT_CARD_IMAGE = 'Credit Card Image';
export const CREATE_ACCOUNT = 'Create Account';
export const VIEW_REWARDS = 'View Your Rewards';
export const TRACK_ORDER = 'Track Order';
export const TRACK_ORDER_IMG = 'Track Order Image';
export const HELP_GREY_ICON = 'Help_Grey_Icon';
export const DISCLAIMER_TEXT_LINE1 =
  'Unless specified, product containers may vary.';
export const DISCLAIMER_TEXT_LINE2 =
  'For flower arrangements, floral type and colors may vary.';
export const BILLING_INFO_TITLE = 'Billing Info';
export const PICKUP_INFO_TITLE = 'Pickup Information';
export const PICKUP_IN_STORE = 'Pickup In-Store';
export const CURB_PICKUP = 'Curbside Pickup';
export const PICKUP_INFO_METHOD = 'Pickup Method';
export const PICKUP_INFO_DATE = 'Pickup Date & Time';
export const PICKUP_INFO_INSTRUCTION = 'Pickup Instructions';
export const PICKUP_INFO_NAME = 'Name On Order';
export const PICKUP_INFO_FROM = 'Pickup From';

export const DELIVERY_TITLE = 'Delivery Information';
export const SHIPMENT_TITLE = 'Shipment Information';
export const DELIVERY_POLICY = 'View our Delivery Policies';
export const SHIPMENT_POLICY = 'View our Shipping Policies';
export const DELIVERY_METHOD_NAME = 'Delivery Method';
export const DELIVERY_METHOD = 'Hand Delivery';
export const ONE_HOUR_DELIVERY_METHOD = 'One-Hour Delivery';
export const SHIPMENT_METHOD = 'FedEx';
export const MORE_INFO = 'For more info';
export const MORE_INFORMATION = 'For more information';
export const SHIPMENT_INFO = 'contact Customer Service at';
export const CUSTOMER_CARE = '678-992-2390';
export const DELIVERY_INFO_ON = 'Delivering On';
export const DELIVERY_INFO_INSTRUCTION = 'Delivery Instructions';
export const DELIVERY_INFO_TO = 'Delivering To';
export const DELIVERY_INFO_FROM = 'Delivering From';
export const AMAZON_PAY_IMAGE = 'Amazon Pay Image';
export const APPLE_PAY_IMAGE = 'Apple Pay Image';
export const GOOGLE_PAY_IMAGE = 'Google Pay Image';
export const LINK_IMAGE = 'LINK Image';
export const PAID_BY = 'Paid By Apple pay';
export const PAID_BY_GPAY = 'Paid By Google pay';
export const PAID_BY_LINK = 'Paid By Link';
export const TRANSACTION_ID = 'Transaction ID: ';
export const NEED_MORE_SHOPPING = 'Need to Do More Shopping?';
export const PAYMENT_ID = 'PAYMENT ID: ';
export const VENMO_IMAGE = 'Venmo Image';
export const PAYPAL_IMAGE = 'Paypal Image';
export const PRODUCT_IMAGE = 'product image';
export const STRAWBERRY = 'STRAWBERRY';
export const CHECK_ICON = 'check icon';
export const NO_INSTRUCTIONS = 'NO INSTRUCTIONS';
export const TERMS_TEXT = 'Terms and Conditions';
export const LIST_TEXT1 =
  'With your account, you have access to exclusive rewards.';
export const LIST_TEXT2 = 'See what you’ll earn next!';
export const ACC_TXT = 'Why Not Create an Account?';
export const THANKS_MSG = 'Thank You for Being a Rewards Member!';
export const ENJOY_MSG = 'You’ll Enjoy';
export const TERMS_MSG = 'See Terms of Use';
export const ORDERS_MSG = '3 more orders';
export const FREE_TXT = 'FREE';
export const FRUIT_TXT = '12-count Chocolate Dipped Fruit Box';
export const GIFT_MSG = 'Complimentary Gift Message';
export const GIFT_TXT = 'Gift Message:';
export const GREETING_TXT = 'Premium Greeting Card';
export const EMAIL_TXT =
  'Your email confirmation was re-sent and should arrive in your inbox shortly. If you can’t find it, please check your spam folder.';
export const ACC_LIST_TEXT1 = '$5 off with sign up';
export const ACC_LIST_TEXT2 = 'Free gifts';
export const ACC_LIST_TEXT3 = 'Fast checkout';
export const ACC_LIST_TEXT4 = 'Gift reminders';
export const ACC_LIST_TEXT5 = 'In-store offers';
export const ACC_LIST_TEXT6 = 'Birthday rewards';
export const ACC_LIST_TEXT7 = 'and more';
export const SPL_TXT = 'You Made it Special With';
export const GREETING_MSG =
  'Your premium greeting card includes your chosen card front and personal gift message. It will be printed by the selected Edible store and placed in an envelope alongside your order.';
export const NEW_ORDER = 'Start New Order';
export const KEEP_SHOPPING_TITLE = 'Want to Keep Shopping?';
export const UPGRADE_TEXT = 'You Selected These Upgrades';
export const CYOA_TITLE1 = 'Box with';
export const CYOA_TITLE2 = 'fruit/topping combinations';
export const CYODB_TITLE = 'Board with baked goods and dipped fruit/berries';
export const CYODB_NAME = 'Create Your Own Desserts Board';
export const CYO_6COOKIEBOX_TITLE = 'Box with up to 3 cookie combinations';
export const CYO_12COOKIEBOX_TITLE = 'Box with up to 6 cookie combinations';
export const CYO_6COOKIEBOX_NAME = 'Create Your Own Cookie Box - 6 Cookies';
export const CYO_12COOKIEBOX_NAME = 'Create Your Own Cookie Box - 12 Cookies';
export const CYO_DESSERT_NAME = 'Create Your Own Desserts Box - One Size';
export const CYO_DIPPEDFRUIT_NAME = 'Create Your Own Dipped Fruit Box';
export const PACKAGE_TITLE1 = 'Bundle containing';
export const PACKAGE_TITLE2 = 'special treats';
export const ORDER_HISTORY = 'Order History';
export const sale = {
  TITLE: 'order_confirmation_page.sale_title',
  ORDERNUMBER: 'order_confirmation_order_number',
  ITEM_COUNT: 'order_confirmation_page.sale_item_count',
  QTY: 'order_confirmation_page.sale_quantity',
  ADDON_QTY: 'order_confirmation_page.sale_addon_quantity',
  PRICE: 'order_confirmation_page.sale_price',
  SUMMARY: 'order_confirmation_page.sale_summary',
  DELIVERY_FEE: 'order_confirmation_page.sale_delivery_fee',
  SHIPMENT_CHARGE: 'order_confirmation_page.sale_shipment_charge',
  SERVICE_FEE: 'order_confirmation_page.sale_service_fee',
  SUBTOTAL: 'order_confirmation_page.sale_subtotal',
  TOTAL: 'order_confirmation_page.sale_total',
  COUPON_CODE: 'order_confirmation_page.coupon_code',
  RECIPIENT: 'order_confirmation_page.recipient',
  TRANSACTION_ID: 'order_confirmation_page.venmo.transaction_id',
};
