import * as styles from 'components/Header/styles';
import { AccountMenu } from 'components/Header/Menus/AccountMenu';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material';
import { DesktopCartLink } from 'components/Header/DesktopCartLink';
import { EdibleLogo } from 'components/Header/EdibleLogo';
import { MOBILE_MENU_ARIA_LABEL } from 'components/Header/translations/locale.constants';
import { MainMenu } from 'components/Header/MobileMenus/MainMenu';
import { Menu } from '@mui/icons-material';
import { Search } from 'components/Header/Search';
import { SubMenu } from 'components/Header/MobileMenus/SubMenu';
import React, { FC, useState } from 'react';
import i18next from 'i18next';

export const TopHeaderPanelMobile: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenu, setSubMenu] = useState<string | null>(null);

  const toggleMenuVisibility = () => {
    setMenuOpen(!menuOpen);
    setSubMenu(null);
  };

  const toggleSubMenuVisibility = () => {
    setSubMenuOpen(!subMenuOpen);
    toggleMenuVisibility();
  };

  const onSubMenuBackBtnClick = () => {
    setSubMenuOpen(false);
    setSubMenu(null);
  };

  const handleOpenSubMenu = (menu: string) => {
    setSubMenu(menu);
  };

  return (
    <>
      <AppBar
        component="nav"
        position="static"
        color="transparent"
        elevation={0}
      >
        <Toolbar sx={styles.toolbar}>
          <EdibleLogo />
          <Box sx={styles.toolbarButtons}>
            <Search />
            <AccountMenu />
            <DesktopCartLink />
            <IconButton
              color="secondary"
              component="button"
              onClick={toggleMenuVisibility}
              aria-label={i18next.t(MOBILE_MENU_ARIA_LABEL)}
            >
              <Menu />
            </IconButton>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={menuOpen}
        onClose={toggleMenuVisibility}
        onOpen={toggleMenuVisibility}
        disablePortal
        disableScrollLock
        ModalProps={{
          keepMounted: true,
        }}
        sx={styles.drawer}
      >
        <MainMenu
          isVisible={!subMenu}
          toggleMenuVisibility={toggleMenuVisibility}
          handleSubMenu={handleOpenSubMenu}
        />
        <SwipeableDrawer
          anchor="right"
          open={Boolean(subMenu)}
          onClose={toggleSubMenuVisibility}
          onOpen={toggleSubMenuVisibility}
          disablePortal
          disableScrollLock
          ModalProps={{
            keepMounted: true,
          }}
          sx={styles.drawer}
        >
          <SubMenu
            isVisible={Boolean(subMenu)}
            menu={subMenu}
            toggleMenuVisibility={toggleSubMenuVisibility}
            onBackBtnClick={onSubMenuBackBtnClick}
          />
        </SwipeableDrawer>
      </SwipeableDrawer>
    </>
  );
};
