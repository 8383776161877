import { OwnBoxOptions } from 'redux/PDP/arrangement/types/own-box.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { isNotEmpty } from 'utils/is-not-empty';

export const getCookieQuantity = (
  selectedItem: UpsellItem,
  selected: UpsellItem[],
  options: OwnBoxOptions,
): number => {
  const cookieLimitedName = 'Cookie Box - LTO';
  const limitedCookies = selected.filter((addon) =>
    addon.option.optionName?.includes(cookieLimitedName),
  );
  const everyDayCookie = selected.filter(
    (addon) => !addon.option.optionName?.includes(cookieLimitedName),
  );
  if (selectedItem.option.optionName?.includes(cookieLimitedName)) {
    return options.cells / limitedCookies.length;
  }
  return options.cells / everyDayCookie.length;
};

export const updateQuantity = (
  selected: UpsellItem[],
  options: OwnBoxOptions,
  isCookie12?: boolean,
): UpsellItem[] =>
  selected.map((selectedItem) => {
    const updatedQuantity = isCookie12
      ? getCookieQuantity(selectedItem, selected, options)
      : options.cells / selected.length;
    return {
      ...selectedItem,
      quantity: isNotEmpty(selected) ? updatedQuantity : 0,
    };
  });
