import * as TK from 'components/Cart/Details/translations/locale.constant';
import { OrderType } from 'types/cart.types';
import i18next from 'i18next';

export type OrderInfo = {
  title: string;
  method: string;
  method2: string;
  method3: string;
  onDate: string;
  instructions: string;
  to: string;
  from: string;
  orderType: OrderType;
};

export const delivery = {
  title: i18next.t(TK.DELIVERY_TITLE),
  method: i18next.t(TK.DELIVERY_METHOD),
  method2: i18next.t(TK.HAND_DELIVERY),
  method3: '',
  onDate: i18next.t(TK.DELIVERY_DAY),
  instructions: i18next.t(TK.DELIVERY_INSTRUCTIONS),
  to: i18next.t(TK.DELIVERY_TO),
  from: i18next.t(TK.DELIVERY_FROM),
  orderType: 1,
};

export const pickup = {
  title: i18next.t(TK.PICKUP_TITLE),
  method: i18next.t(TK.PICKUP_METHOD),
  method2: i18next.t(TK.IN_STORE_PICKUP),
  method3: '',
  onDate: i18next.t(TK.PICKUP_DAY),
  instructions: i18next.t(TK.PICKUP_INSTRUCTIONS),
  to: i18next.t(TK.PICKUP_TO),
  from: i18next.t(TK.PICKUP_FROM),
  orderType: 2,
};

export const shipment = {
  title: i18next.t(TK.SHIPPING_INFORMATION),
  method: i18next.t(TK.DELIVERY_METHOD),
  method2: i18next.t(TK.SHIPPER),
  method3: i18next.t(TK.SHIPMENT_METHOD_NAME),
  onDate: i18next.t(TK.SHIPMENT_DAY),
  instructions: '',
  to: i18next.t(TK.SHIPMENT_TO),
  from: i18next.t(TK.SHIPMENT_TO),
  orderType: 3,
};
