import { Box, Skeleton, Slide, SxProps } from '@mui/material';
import { ContinueButtonSkeleton } from 'components/PDP/Details/components/ContinueButton/ContinueButtonSkeleton';
import {
  bottomContinuePanel,
  bottomContinueWrapper,
} from 'components/PDP/Details/containers/BottomContinuePanel/bottom-continue-panel.styles';
import React, { FC } from 'react';

export const BottomContinuePanelSkeleton: FC = () => {
  const productTextGroup: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    width: ['100%'],
    marginRight: ['15px'],
  };

  return (
    <Slide in direction="up">
      <Box sx={bottomContinuePanel}>
        <Box sx={bottomContinueWrapper}>
          <Box sx={productTextGroup}>
            <Skeleton
              sx={{
                width: ['0%', '80%'],
                marginBottom: ['10px'],
              }}
            />
            <Skeleton
              sx={{
                width: ['0%', '35%', '45%'],
              }}
            />
          </Box>
          <Box sx={{ width: ['100%'] }}>
            <ContinueButtonSkeleton />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};
