import { OrderType } from 'types/cart.types';

export const dataTestOrderTypeName = {
  [OrderType.Pickup]: 'pickup',
  [OrderType.Shipment]: 'shipment',
  [OrderType.Delivery]: 'delivery',
};

export const OrderTypeName = {
  [OrderType.Pickup]: 'Pickup',
  [OrderType.Shipment]: 'Shipment',
  [OrderType.Delivery]: 'Delivery',
};
