import { reset } from 'redux/PDP/arrangement/arrangement.slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useClearArrangementOnUnmount = (): void => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch],
  );
};
