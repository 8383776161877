import { TOP_SALES_ID } from 'containers/CartContainer/constants';
import { fetchArrangements } from 'components/PLP/ArrangementsList/feature/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function useFetchSuggestedProducts(): void {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchArrangements({
        filterIds: [TOP_SALES_ID],
        limit: 10,
      }),
    );
  }, [dispatch]);
}
