import {
  Arrangement,
  Product,
} from 'redux/PDP/arrangement/types/arrangement.types';
import { Box, SxProps } from '@mui/material';
import { useCuralate } from 'lib/curalate/hooks/use-curalate';
import React, { FC } from 'react';

interface Props {
  sx?: SxProps;
  arrangement?: Arrangement;
  product?: Product;
}

export const Gallery: FC<Props> = ({ sx, arrangement, product }) => {
  useCuralate();

  return (
    <>
      {window.BAZAARVOICE_PRODUCT_ENABLED && arrangement && product ? (
        <Box
          data-crl8-container-id="product"
          data-crl8-filter={`productId:${
            arrangement.catalogNumber
          }-${product.name.replaceAll(' ', '')}`}
        />
      ) : (
        <Box
          data-crl8-container-id="gallery-d9IYdfgW"
          data-test="curalate-gallery"
          position="relative"
          sx={sx}
        />
      )}
    </>
  );
};
