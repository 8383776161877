import { IS_DESKTOP_PROD_HOST, IS_MOBILE_PROD_HOST } from 'utils/is-prod-host';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';

export const getMobileUrl = (): string => {
  const urlData = new URL(window.location.href);
  const preparedUrl = (urlData.origin + urlData.pathname).replace('www.', '');
  if (IS_MOBILE_HOST) {
    return preparedUrl;
  }
  const preparedHostname = urlData.hostname.replace('www.', '');
  return preparedUrl.replace(
    preparedHostname,
    `${IS_DESKTOP_PROD_HOST ? 'm.' : 'm-'}${preparedHostname}`,
  );
};

export const getDesktopUrl = (): string => {
  const urlData = new URL(window.location.href);
  const preparedUrl = urlData.origin + urlData.pathname;
  if (!IS_MOBILE_HOST) {
    return preparedUrl;
  }
  return preparedUrl.replace(
    `${IS_MOBILE_PROD_HOST ? 'm.' : 'm-'}`,
    `${IS_MOBILE_PROD_HOST ? 'www.' : ''}`,
  );
};
