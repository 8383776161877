import {
  AddressBookResponse,
  ContactInfoResponse,
} from 'api/rewards/types/address-book-response.type';
import { ApiBaseService } from 'api/apibase.service';
import {
  CreateAddressBookInfoRequest,
  UpdateAddressBookInfoRequest,
} from 'api/rewards/types/address-book-request.type';
import {
  CreateReminderInfoRequest,
  UpdateReminderInfoRequest,
} from 'api/rewards/types/create-reminder-request.type';
import { GiftReminderOccasionResponse } from 'api/rewards/types/gift-reminder-occasion-response';
import { GiftRemindersResponse } from 'api/rewards/types/reminders-response.type';
import {
  OrderHistoryDates,
  OrderHistoryResponse,
} from 'api/rewards/types/order-history-response.type';
import { RewardsResponse } from 'api/rewards/types/rewards-response.type';
import {
  StoreFinderResponse,
  StoreFinderWidget,
} from 'components/StoreFinder/interfaces/store-finder-page.interfaces';

class RewardsApiService extends ApiBaseService {
  getReminders(
    accountId: number,
    searchText = '',
  ): Promise<GiftRemindersResponse[]> {
    return this.apibase
      .get<GiftRemindersResponse[]>(
        `/rewards/gift-reminder?accountId=${accountId}&searchText=${searchText}`,
      )
      .then(({ data }) => data);
  }

  getAddressBook(
    accountId: number,
    searchText = '',
  ): Promise<AddressBookResponse[]> {
    return this.apibase
      .get<AddressBookResponse[]>(
        `/rewards/address-book?accountId=${accountId}&searchText=${searchText}`,
      )
      .then(({ data }) => data);
  }

  getOrderHistory(
    accountId: number,
    fromDate: string,
    toDate: string,
  ): Promise<OrderHistoryResponse[]> {
    return this.apibase
      .get<OrderHistoryResponse[]>(
        `/account/${accountId}/${fromDate}/${toDate}`,
      )
      .then(({ data }) => data);
  }

  getOrderDateList(accountId: number): Promise<OrderHistoryDates[]> {
    return this.apibase
      .get<OrderHistoryDates[]>(`/account/dates/${accountId}`)
      .then(({ data }) => data);
  }

  getMyLocationList(
    distance: number,
    accountId: number,
  ): Promise<StoreFinderWidget[]> {
    return this.apibase
      .get<StoreFinderResponse>(`/rewards/my-location/${distance}/${accountId}`)
      .then(({ data }) => data.storeData);
  }

  createReminderInfo(
    createReminderRequest: CreateReminderInfoRequest,
  ): Promise<string> {
    return this.apibase
      .post<string>(`/rewards/gift-reminder/add`, createReminderRequest)
      .then(({ data }) => data);
  }

  updateReminderInfo(
    updateReminderRequest: UpdateReminderInfoRequest,
  ): Promise<string> {
    return this.apibase
      .post<string>(`/rewards/gift-reminder/update`, updateReminderRequest)
      .then(({ data }) => data);
  }

  deleteReminderInfo(id: number): Promise<string> {
    return this.apibase
      .delete<string>(`/rewards/gift-reminder/delete/${id}`)
      .then(({ data }) => data);
  }

  deleteAddressBookInfo(id: number): Promise<string> {
    return this.apibase
      .delete<string>(`/rewards/address-book/delete/${id}`)
      .then(({ data }) => data);
  }

  getGiftReminderOccasion(
    target: number,
  ): Promise<GiftReminderOccasionResponse[]> {
    return this.apibase
      .get<GiftReminderOccasionResponse[]>(
        `/rewards/gift-reminder/occasions?target=${target}`,
      )
      .then(({ data }) => data);
  }

  createAddressBookInfo(
    createAddressBookRequest: CreateAddressBookInfoRequest,
  ): Promise<string> {
    return this.apibase
      .post<string>(`/rewards/address-book/add`, createAddressBookRequest)
      .then(({ data }) => data);
  }

  updateAddressBookInfo(
    updateAddressBookRequest: UpdateAddressBookInfoRequest,
  ): Promise<string> {
    return this.apibase
      .post<string>(`/rewards/address-book/update`, updateAddressBookRequest)
      .then(({ data }) => data);
  }

  getAddressBookById(id: number): Promise<ContactInfoResponse> {
    return this.apibase
      .get<ContactInfoResponse>(`/rewards/address-book/${id}`)
      .then(({ data }) => data);
  }

  getRewardsByAccountID(accountId: number): Promise<RewardsResponse> {
    return this.apibase
      .get<RewardsResponse>(`/rewards/${accountId}`)
      .then(({ data }) => data);
  }
}

export const rewardsApiService = new RewardsApiService();
