import { SxProps, alpha } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const root: SxProps<Theme> = (theme) => ({
  background: alpha(theme.palette.primary.contrastText, 0.64),
  zIndex: 2147483647,
});

export const progress: SxProps = {
  position: 'fixed',
  top: 0,
  width: 1,
};
