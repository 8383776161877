import { Locale } from 'enums/locale';
import { isUndefined } from 'utils/is-undefined';
import Cookies from 'js-cookie';

export enum Country {
  USA = 'us',
  CANADA = 'ca',
}

export const isUS =
  isUndefined(window.COUNTRY) || window.COUNTRY === Country.USA;

export const isCanada = window.COUNTRY === Country.CANADA;

export const getCurrentCountry = (): Country =>
  window.COUNTRY && [Country.USA, Country.CANADA].includes(window.COUNTRY)
    ? window.COUNTRY
    : Country.USA;

export const getCountryLanguage = (): Locale => {
  const country = window.COUNTRY;
  if (country === Country.CANADA) {
    const locale = Cookies.get('locale') || 'en-us';
    if (locale === 'fr-ca') return Locale.FR_CA;
    return Locale.EN_CA;
  }
  return Locale.EN_US;
};

export interface Internationalizable {
  country?: Country;
}

export const internationalizablePredicate = ({
  country,
}: Internationalizable): boolean =>
  isUndefined(country) || country === getCurrentCountry();

export const statesUS = [
  'Alabama',
  'Arkansas',
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Iowa',
  'Idaho',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Massachusetts',
  'Maryland',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Mississippi',
  'North Carolina',
  'Nebraska',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'Nevada',
  'New York',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'Tennessee',
  'Texas',
  'Utah',
  'Virginia',
  'Vermont',
  'Washington',
  'West Virginia',
];
