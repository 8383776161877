import { BannerSkeleton } from 'components/Home/Banner/components/Skeleton';
import { Box } from '@mui/material';
import { CarouselBannerSkeleton } from 'components/Home/CarouselBanners/components/Skeleton';
import { CarouselDetailedSkeleton } from 'components/Home/CarouselDetailed/components/Skeleton';
import { CarouselSimplifiedSkeleton } from 'components/Home/CarouselSimplified/components/Skeleton';
import { CategoryWidgetSkeleton } from 'components/Home/GridWidget/components/Skeleton';
import { ChipWidgetSkeleton } from 'components/Home/ChipWidget/components/Skeleton';
import React, { FC } from 'react';

export const HomeSkeleton: FC = () => (
  <Box>
    <CarouselBannerSkeleton />
    <CarouselSimplifiedSkeleton />
    <BannerSkeleton />
    <CategoryWidgetSkeleton />
    <ChipWidgetSkeleton />
    <CarouselDetailedSkeleton />
  </Box>
);
