import * as TK from 'components/PDP/Order/GreetingsCards/translations/translation-keys.constants';

export const greetingCardsCommonLocale = {
  [TK.COMPLIMENTARY_GIFT_MESSAGE]: 'Add a Сomplimentary Gift Message',
  [TK.COMPLIMENTARY_MESSAGE_TITLE]: 'Сomplimentary Gift Message',
  [TK.OR]: 'Or',
  [TK.REMOVE_GIFT_MESSAGE]: 'Remove Gift Message',
  [TK.GIFT_MESSAGE_FREE_EXPLAIN]:
    'Write a gift message, and include your name so the recipient knows who sent their gift.',
  [TK.HEARTFELT_MESSAGE]: 'Leave them a heartfelt message',
  [TK.TOP_PICK]: 'TOP PICK',
  [TK.PREMIUM_CARD]: 'Add Card',
  [TK.OPTIONAL]: 'optional',
  [TK.SELECT_NEW_CARD]: 'Select New Card',
  [TK.REMOVE_CARD]: 'Remove Card',
  [TK.GIFT_MESSAGE_FREE_HINT]:
    'Our treats will make an impression, but your card can be a keepsake they cherish for years to come.',
  [TK.ENTER_CARD_MESSAGE]: 'Complimentary Gift Message',
  [TK.PRINTIBLE_CARD_PREVIEW]: 'Card preview',
  [TK.PRINTIBLE_APP]: 'Printible App',
  [TK.PRINTIBLE_IFRAME_ERROR]:
    'Browser Support Error: Browser Does Not Support iframe.',
  [TK.PRINTIBLE_SUBTITLE]:
    'Pick a card you love, or <strong>add your own photo</strong> to <strong>your heartfelt message</strong>',
  [TK.PRINTIBLE_OLD_PRICE_TEXT]: 'Was ',
  [TK.PRINTIBLE_PRICE_SUBTEXT]: 'Only ',
  // eslint-disable-next-line no-template-curly-in-string
  [TK.PRINTIBLE_PRICE_TEXT]: '${{price}}',
  [TK.COMPLIMENTARY_CARD]: 'Complimentary card',
  [TK.PRINTIBLE_CARDS]: 'Printible cards',
  [TK.GIFT_CARD_MODAL_TITLE]: 'Leave Them A Heartfelt Message',
  [TK.GIFT_CARD_MODAL_SUB_TITLE]:
    'Remember to include your name! Your message is the only way the recipient will know who sent their gift.',
  [TK.GIFT_CARD_MODAL_GIFT_MESSAGE]: 'Make it Memorable',
  [TK.GIFT_CARD_MODAL_KEEPSAKE_CHERISH]: 'send a personalized greeting card',
  [TK.GIFT_CARD_MODAL_YEAR_COME]: 'for Years to Come',
  [TK.WRITE_FOR_ME_NAME]: 'Your Name*',
  [TK.ANANYMOUSLY_SWITCH_LABEL]: 'Send anonymously',
  [TK.WRITE_FOR_ME_NAME_NOTE]: 'Let the recipient know who sent their gift',
  [TK.WRITE_FOR_ME_CREATE_BUTTON]: 'Create My Gift Message',
  [TK.WRITE_FOR_ME_START_NEW_BUTTON]: 'Start A New Gift Message',
  [TK.GIFT_MESSAGE_TABS_LABEL]: 'Card message input type tabs',
  [TK.GIFT_MESSAGE_FIRST_TAB_LABEL]: 'Write My Own',
  [TK.GIFT_MESSAGE_SECOND_TAB_LABEL]: 'Write For Me',
};
