type Filterable = Record<string, unknown>;
type ValueOf<T> = T[keyof T];
type Iteratee<T> = (item: ValueOf<T>) => boolean;

/**
 * Filter objects by callback
 * @param obj
 * @param iteratee
 */
export function pickBy<T extends Filterable>(
  obj: T,
  iteratee: Iteratee<T>,
): Partial<T> {
  if (!obj) {
    return {};
  }
  return Object.keys(obj).reduce((acc: Partial<T>, key: keyof T) => {
    if (iteratee(obj[key])) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
