import { Banner } from 'api/banner/types';
import { SkinnyDesktop } from 'components/Header/Banner/SkinnyDesktop';
import { SkinnyMobile } from 'components/Header/Banner/SkinnyMobile';
import { isSkinnyDesktop } from 'components/Header/Banner/is-skinny-desktop';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  banner: Banner;
};

export const Skinny: FC<Props> = ({ banner }) => {
  const [desktop, setDesktop] = useState<boolean>(isSkinnyDesktop());
  const determineLayout = () => setDesktop(isSkinnyDesktop());

  useEffect(() => {
    window.addEventListener('resize', determineLayout);
    return () => {
      window.removeEventListener('resize', determineLayout);
    };
  }, []);

  return desktop ? (
    <SkinnyDesktop banner={banner} />
  ) : (
    <SkinnyMobile banner={banner} />
  );
};
