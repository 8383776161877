import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFallbackResponse } from 'components/Home/feature/fallback';
import { homePageApiService } from 'api/home-page.api.service';

export const getHomePageContent = createAsyncThunk(
  'home/get',
  async ({
    previewDate,
    showDraft,
  }: {
    previewDate: string | null;
    showDraft: string | null;
  }) => {
    const { data } = await homePageApiService
      .getContent(previewDate, showDraft)
      .catch(() => ({
        data: getFallbackResponse(),
      }));

    return data;
  },
);
