import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { subscribe } from 'components/Subscription/feature/actions';

export interface SubscriptionState {
  emailStatus: Status;
  phoneStatus: Status;
}

const initialState: SubscriptionState = {
  emailStatus: Status.INIT,
  phoneStatus: Status.INIT,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.pending, (state, { meta: { arg } }) => {
        if (arg.email) {
          state.emailStatus = Status.PENDING;
          state.phoneStatus = Status.INIT;
        }
        if (arg.phone) {
          state.phoneStatus = Status.PENDING;
          state.emailStatus = Status.INIT;
        }
      })
      .addCase(subscribe.fulfilled, (state, { meta: { arg } }) => {
        if (arg.email) {
          state.emailStatus = Status.FULFILLED;
          state.phoneStatus = Status.INIT;
        }
        if (arg.phone) {
          state.phoneStatus = Status.FULFILLED;
          state.emailStatus = Status.INIT;
        }
      })
      .addCase(subscribe.rejected, (state, { meta: { arg } }) => {
        if (arg.email) {
          state.emailStatus = Status.ERROR;
        }
        if (arg.phone) {
          state.phoneStatus = Status.ERROR;
        }
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: subscriptionReducer } = subscriptionSlice;
