import * as TK from 'components/Home/NotificationBanner/translations/locale.constants';
import i18next from 'i18next';

export const getRemainingTime = (
  minRemaining: number,
  hrRemaining: number,
  dayRemaining?: number,
  monthRemaining?: number,
  yearRemaining?: number,
): string => {
  let bannerText = '';

  if (yearRemaining && yearRemaining === 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_YEAR, {
      replace: {
        years: yearRemaining,
      },
    });
  }
  if (yearRemaining && yearRemaining > 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_YEARS, {
      replace: {
        years: yearRemaining,
      },
    });
  }
  if (monthRemaining && monthRemaining === 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_MONTH, {
      replace: {
        months: monthRemaining,
      },
    });
  }
  if (monthRemaining && monthRemaining > 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_MONTHS, {
      replace: {
        months: monthRemaining,
      },
    });
  }
  if (dayRemaining && dayRemaining === 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_DAY, {
      replace: {
        days: dayRemaining,
      },
    });
  }
  if (dayRemaining && dayRemaining > 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_DAYS, {
      replace: {
        days: dayRemaining,
      },
    });
  }
  if (hrRemaining === 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_HOUR, {
      replace: {
        hours: hrRemaining,
      },
    });
  }
  if (hrRemaining > 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_HOURS, {
      replace: {
        hours: hrRemaining,
      },
    });
  }
  if (minRemaining === 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_MINUTE, {
      replace: {
        minutes: minRemaining,
      },
    });
  }
  if (minRemaining > 1) {
    bannerText += i18next.t(TK.NOTIFICATION_BANNER_WITHIN_MINUTES, {
      replace: {
        minutes: minRemaining,
      },
    });
  }
  return bannerText;
};
