import { LegacySignUpStatus } from 'api/account-legacy/sign-up.misc';
import { SignUp } from 'components/SignUp/feature/sign-up.action';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';

interface State {
  signUpStatus: Status;
  responseStatus: LegacySignUpStatus;
  accountInfo: { firstName?: string; lastName?: string; email?: string };
}

const initialState: State = {
  signUpStatus: Status.INIT,
  responseStatus: LegacySignUpStatus.NONE,
  accountInfo: {},
};

const slice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(SignUp.pending, (state) => {
        state.signUpStatus = Status.PENDING;
        state.responseStatus = LegacySignUpStatus.NONE;
      })
      .addCase(SignUp.rejected, (state) => {
        state.signUpStatus = Status.ERROR;
        state.responseStatus = LegacySignUpStatus.EXCEPTION;
      })
      .addCase(SignUp.fulfilled, (state, { payload }) => {
        state.signUpStatus = Status.FULFILLED;
        state.responseStatus = payload?._Status || LegacySignUpStatus.NONE;
        state.accountInfo.firstName = payload.firstName;
        state.accountInfo.lastName = payload.lastName;
        state.accountInfo.email = payload.email;
      })
      .addDefaultCase((state) => state);
  },
  reducers: {},
});

export const signUpReducer = slice.reducer;
