import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';

export const selectFilter = (state: RootState) => state.plp.filter.filterItem;

export const checkIsBlocked = createSelector(
  ({ plp: { filter } }: RootState) => filter.status,
  (status) => isBlocked(status),
);
