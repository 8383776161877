import {
  NullableState,
  Status as RequestStatus,
  State,
} from 'redux/types/state.interface';
import { Status } from 'enums/status';
import { isNotNull } from 'utils/null.utils';

/* @deprecated */
export const isIdle = (status: Status): boolean => status === Status.IDLE;
/* @deprecated */
export const isSuccess = (status: Status): boolean => status === Status.SUCCESS;
/* @deprecated */
export const isInProgress = (status: Status): boolean =>
  status === Status.IN_PROGRESS;
/* @deprecated */
export const isLegacyError = (status: Status): boolean =>
  status === Status.ERROR;

export function isError<T>(
  status: T | RequestStatus,
): status is RequestStatus.ERROR {
  return status === RequestStatus.ERROR;
}
export function isFulfilled<T>(
  status: T | RequestStatus,
): status is RequestStatus.FULFILLED {
  return status === RequestStatus.FULFILLED;
}
export function isFinished<T extends RequestStatus>(
  status: T | RequestStatus,
): status is RequestStatus.FULFILLED | RequestStatus.ERROR {
  return [RequestStatus.FULFILLED, RequestStatus.ERROR].includes(status);
}
export function isPending<T>(
  status: T | RequestStatus,
): status is RequestStatus.PENDING {
  return status === RequestStatus.PENDING;
}
export function isInit<T>(
  status: T | RequestStatus,
): status is RequestStatus.INIT {
  return status === RequestStatus.INIT;
}
export function isBlocked<T>(
  status: T | RequestStatus,
): status is RequestStatus.INIT | RequestStatus.PENDING {
  return [RequestStatus.INIT, RequestStatus.PENDING].includes(
    status as RequestStatus,
  );
}

interface FulfilledState<T> extends State<T> {
  status: RequestStatus.FULFILLED;
  error: null;
}
export function isStateFulfilled<T>(
  state: NullableState<T>,
): state is FulfilledState<T> {
  return isNotNull(state.data) && state.status === RequestStatus.FULFILLED;
}
