/**
 * Creates an array in specified range
 *
 * @example
 * range(3)     // [0, 1, 2]
 * range(2, 5)  // [2, 3, 4]
 */
export function range(start: number, end?: number): number[] {
  if (!end) {
    if (start < 0) {
      return [];
    }

    return [...Array(start).keys()];
  }

  if (start > end) {
    return [];
  }

  return [...Array(end - start).keys()].map((i) => i + start);
}
