import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const icon: SxProps = {
  width: 13,
  height: 13,
  mr: 0.5,
  alignSelf: 'center',
};
export const link: SxProps<Theme> = {
  display: 'inline-flex',
  alignItems: 'center',
};
