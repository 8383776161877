export const BUSINESS_GIFTING_FILTER_ALIAS = 'business-gifting';
export const BUSINESS_GIFTS_FILTER_ALIAS = 'business-gifts';
export const LOCAL_SEO_ALIAS = 'states';
export const ROOT_PATH_LIST = ['edible-gifts', 'pages', 'policy'];
export const LOCAL_LEGAL_ALIAS = 'legal';
export const LOCAL_LEGAL_TOU_ALIAS = 'terms-of-use';
export const LOCAL_LEGAL_PRIVACY_POLICY_ALIAS = 'privacy-policy';
export const CALIFORNIA_PRIVACY_RIGHTS_ALIAS = 'california-privacy-rights';
export const CUSTOMER_SERVICE_ALIAS = 'customer-service';
export const ALLERGY_SAFETY_INFORMATION_ALIAS = 'allergy-safety-information';
export const CERTIFIED_HAPPINESS_ALIAS = 'certified-happiness';
export const EDIBLE_GUARANTEE_ALIAS = 'edible-guarantee';
export const TRACK_YOUR_ORDER_ALIAS = 'track-your-order';
export const SHIPMENT_POLICY_PATH = 'shipment-policy';
export const DELIVERY_POLICY_PATH = 'delivery-policy';
export const LEGAL_PATH = '/legal';
export const TERMS_OF_USE_PATH = '/legal/terms-of-use';
export const PRIVACY_POLICY_PATH = '/legal/privacy-policy';
export const DELIVERY_POLICY = '/legal/delivery-policy';
export const FAQ_PAGE_ALIAS = 'faq';
export const STORE_FINDER_PATH = '/store-finder';
export const STORE_COMING_SOON_PATH = '/stores/opening-soon-store-detail';
export const PRESS = '/about/press-room';
export const WHO_WE_ARE = 'about/who-we-are';
export const OUR_MISSION = 'about/our-mission';
export const CAREERS = 'edible-careers/edible-arrangements-careers';
export const PROMOTION_TERMS_CONDITIONS = '/terms-and-conditions';
export const PROMOTION_TERMS_CONDITIONS_EXPIRED =
  '/terms-and-conditions/expired';
export const ACCESSIBILITY = '/accessibility/';
