import * as styles from 'components/OrderConfirmation/OrderSummary/styles';
import { Box, Typography } from '@mui/material';
import { colors } from 'utils/theme/colors';
import { roundOffNumberValue } from 'utils/number-calculations/roundTwoDecimals';
import React, { FC } from 'react';

interface Props {
  text: string;
  value: number | undefined;
  line?: boolean;
  couponCodeStyle?: boolean;
  orderType?: number;
}

export const LineItem: FC<Props> = ({
  text,
  value = 0,
  line,
  couponCodeStyle,
  orderType,
}) => {
  let priceLine = `$${roundOffNumberValue(value)}`;
  if (orderType === 1) {
    priceLine = value > 0 ? `$${roundOffNumberValue(value)}` : 'FREE';
  }
  return (
    <>
      {line && <Box sx={styles.productSeparator} />}
      {text !== '' && (
        <Box display="flex" justifyContent="end" mb={1.5}>
          <Typography
            variant="body2"
            sx={line ? styles.orderTotalLine : styles.title}
            color={couponCodeStyle ? colors.primaryRed : ''}
          >
            {text}:
          </Typography>
          <Typography
            variant="body2"
            sx={line ? styles.orderTotalLine : styles.price}
            color={couponCodeStyle ? colors.primaryRed : ''}
          >
            {couponCodeStyle && '-'}
            {`${priceLine}`}
          </Typography>
        </Box>
      )}
    </>
  );
};
