import * as TK from 'components/About/translations/locale.constants';

export const aboutCommonLocale = {
  [TK.ABOUT]: 'About',
  [TK.PRESS_ROOM]: 'Press Room',
  [TK.BACK_LINK]: 'Back',
  [TK.HOME_LINK]: 'Home',
  [TK.PRESS_ROOM_LINK]: 'Press Room',
  [TK.WHO_WE_ARE_LINK]: 'Who We Are',
  [TK.OUR_MISSION_LINK]: 'Our Mission',
  [TK.TIMELINE_LINK]: 'Our History',
  [TK.TERMS_AND_CONDITIONS_LINK]: 'Terms & Conditions',
  [TK.TERMS_AND_CONDITIONS_EXPIRED_LINK]: 'Expired Terms & Conditions',
  [TK.ACCESSIBILITY]: 'Accessibility',
  [TK.MEDIA_CONTACTS_TITLE]: 'Media Contacts',
  [TK.MEDIA_CONTACT_INFO]: 'For national and regional media inquiries, contact',
  [TK.PRINT_WEB_TITLE]: 'Print And Web',
  [TK.NEWS_TITLE]: 'Edible Arrangements® is blooming',
  [TK.AD_INQUIRY_TITLE]: 'For Local Advertising Inquiries',
  [TK.REG_INQ_INFO]: 'Coyne Public Relations',
  [TK.MEDIA_EMAIL]: 'media@edible.com',
  [TK.MEDIA_CONTACT_NUMBER]: '(212) 938-0166',
  [TK.LOCAL_INQUIRY]: 'For local media inquires,',
  [TK.CONTACT_STORE_LINK]: ' Contact Local Store',
  [TK.FAQ_TITLE]: 'Frequently asked questions',
  [TK.SPONSORSHIP]: 'Sponsorship and Donations',
  [TK.DOWNLOAD_PRESS_KIT]: 'Download 2024/2025 Press Kit',
  [TK.LINK_ALT]:
    'Ranked on Entrepreneur Franchise Magazine – Edible Arrangements Franchise Opportunities',
  [TK.TOP_ALT]: 'Edible Arrangements Ranked Top 100 Internet Retailer in 2013',

  [TK.WHO_WE_ARE_TITLE]: 'About Edible®',
  [TK.TIMELINE_TITLE]: 'Ripened Over Time',
  [TK.TIMELINE_SUBTEXT]:
    'The history of the World of Edible Arrangements® is the stuff of legend. We invite you to take a quick trip through time with us, to see some of our most memorable moments…',
  [TK.WHO_WE_ARE_AT_EDIBLE]:
    'At Edible®, our passion, heritage, and future are rooted in helping people all over the world celebrate life’s most special moments with a gift or treat that’s carefully crafted to WOW. Whether it’s a birthday, a work milestone, a get-together with friends and family, or even just because, our assortment of freshly made gifts is just what you need to create spontaneous moments of goodness filled with extra sweet memories and lots of smiles!',
  [TK.NO_MATTER]:
    'No matter the occasion, count on Edible® to always deliver unforgettable delights made with only the most delicious fruit and real, gourmet chocolate.',
  [TK.WHO_WE_ARE_PRIDE]:
    'We pride ourselves on our knack for innovation, our entrepreneurial spirit, and, above all, our shameless love for fruit. Each of our gifts and grab-and-go Edible® Treats is made fresh by a Fruit Expert® at one of our 1,200+ independently owned franchise locations right in your neighborhood. The best part? You can choose to stop by for pickup, or we’ll hand-deliver it right to your doorstep!',
  [TK.MADE_FRESH_DAILY_ALT]: 'Edible Fruit Bouquets Made Fresh Daily',
  [TK.GO_LOCAL_ALT]:
    'Go Local With Edible Arrangements – Your Neighborhood Gifting Experts',
  [TK.ART_OF_WOW_ALT]: 'Edible Arrangements – The Art of WOW',
  [TK.EXTRA_SPECIAL_DELIVERY_ALT]:
    'Edible Arrangements Fresh Fruit Bouquet Local Delivery',
  [TK.CAREER_BANNER_HEADER]: 'Live your most fruitful life.',
  [TK.CAREER_BANNER_HEADER1]: 'Live your most',
  [TK.CAREER_BANNER_HEADER2]: 'fruitful life.',
  [TK.CAREER_BANNER_SUB_HEADER]: 'Apply to work at EDIBLE® today.',
  [TK.CAREER_BANNER_BUTTON_TEXT]: 'See Open Positions',
  [TK.CAREER_ABOUT]: 'About Us',
  [TK.CAREER_ABOUT_TEXT]:
    'Edible was founded in 1999 with one deliciously different idea: a fruit bouquet. Since then, we’ve grown into America’s favorite gifting brand, with over 1,000 franchise locations, a world-class eCommerce function and millions of customers every year. Through all that growth, one thing hasn’t changed: our commitment to helping people celebrate what’s good in life!',
  [TK.CAREER_CORPORATE_TEXT]: 'Corporate',
  [TK.CAREER_OPENING_TEXT]: 'Openings',
  [TK.CAREER_STORE_TEXT]: 'Stores',
  [TK.CAREER_FRUITS_TEXT]: 'The fruits of your Labor',
  [TK.CAREER_FRUITS_SUB_TEXT]:
    'Of course, we’ve got you (chocolate) covered with all the usual benefits, but we also have a few perks that may not be so familiar:',
  [TK.CAREER_JOB_REVIEWS_FIRST]:
    '“Edible is such a great place to work. I get inspired from my colleagues since we all are a collective of creative, collaborative, and sometimes quirky individuals. Each of us adds a unique flavor to the work we do every day, echoing the diverse and fun workplace that we are all part of. We talk and communicate to each other in a way I had not experienced before coming to work on Edible. Come and try the sweetest workplace there is (I mean it, is not uncommon to try some baked goodies or dipped fruit when you work here!).”',
  [TK.CAREER_JOB_REVIEWS_NAME_FIRST]: 'Sara Ramones',
  [TK.CAREER_JOB_REVIEWS_DESIGNATION_FIRST]:
    'Graphic Designer - Edible Arrangements, LLC',
  [TK.CAREER_JOB_REVIEWS_SECOND]:
    '“At Edible every day brings a new challenge that motivates and inspires me to grow professionally. I love that I am always learning something new, whether from the people who I interact with in the company or the Franchisees. I feel the work I do makes a difference in everyone’s lives I touch.”',
  [TK.CAREER_JOB_REVIEWS_NAME_SECOND]: 'Isgrey Brandt',
  [TK.CAREER_JOB_REVIEWS_DESIGNATION_SECOND]:
    'Franchise Sales Associate - Edible Arrangements, LLC',
  [TK.LABOR_SUB_SECTION1_TITLE1]: 'Top Secret',
  [TK.LABOR_SUB_SECTION1_TITLE2]: 'Product Sampling',
  [TK.LABOR_SUB_SECTION2_TITLE]: 'Celebration of Life',
  [TK.LABOR_SUB_SECTION3_TITLE1]: 'Medical/Dental/Vision',
  [TK.LABOR_SUB_SECTION3_TITLE2]: 'Coverage',
  [TK.LABOR_SUB_SECTION4_TITLE]: 'We Celebrate You',
  [TK.LABOR_SUB_SECTION5_TITLE]: 'Daily Smoothies',
  [TK.LABOR_SUB_SECTION6_TITLE]: 'Surprise & Delight',
  [TK.LABOR_SUB_SECTION1_HOVER_TEXT]:
    'We work hard and snack harder. Enjoy the latest fruit innovations before they go global.',
  [TK.LABOR_SUB_SECTION2_HOVER_TEXT]:
    'Celebrating the birth of an immediate family member? Take three days off, on us!',
  [TK.LABOR_SUB_SECTION3_HOVER_TEXT]:
    'Our comprehensive health plans offer you the care you need when you need it most.',
  [TK.LABOR_SUB_SECTION4_HOVER_TEXT]:
    'We celebrate moments both big and small, from birthdays to milestone anniversaries.',
  [TK.LABOR_SUB_SECTION5_HOVER_TEXT]:
    'Fresh cut fruit will always be waiting for you, along with a few sweet toppings.',
  [TK.LABOR_SUB_SECTION6_HOVER_TEXT]:
    'Special events, lunches, and celebrations are hosted throughout the year.',
  [TK.FOLLOW_US_TEXT]: 'Follow us',
  [TK.CAREER_PROPERTIES_TITLE]: 'The seeds of our success',
  [TK.CAREER_PROPERTIES_SUB_TITLE]:
    "They're called 'The 5Ps' and they inform every decision we make, product we create, and customer we serve.",
  [TK.CAREER_PROPERTIES_ITEM_TITLE1]: 'Our Promise',
  [TK.CAREER_PROPERTIES_ITEM_SLOGAN1]: 'To WOW customers.',
  [TK.CAREER_PROPERTIES_ITEM_DESC1]:
    'When Tariq Farid first started Edible, he hoped to take each customer’s special moment and make it even better. Put simply, his goal was to WOW them. Today, everyone at Edible continues that tradition, providing innovative products and delightful experiences to each and every guest.',
  [TK.CAREER_PROPERTIES_ITEM_TITLE2]: 'Our People',
  [TK.CAREER_PROPERTIES_ITEM_SLOGAN2]: 'Caring, loving Edible Experts.',
  [TK.CAREER_PROPERTIES_ITEM_DESC2]:
    'Whether they’re in our stores, running our franchises, or working at our Support Center, our people live by one simple rule: treat everyone the way you want to be treated, only sweeter. Talented, diverse and driven, our employees are filling the world with goodness on a daily basis.',
  [TK.CAREER_PROPERTIES_ITEM_TITLE3]: 'Our Products',
  [TK.CAREER_PROPERTIES_ITEM_SLOGAN3]: 'Remarkably fresh.',
  [TK.CAREER_PROPERTIES_ITEM_DESC3]:
    'Edible started with a simple fresh fruit arrangement. Since then, we’ve created everything from gift boxes to party platters to gourmet baked goods. Through it all, we’ve never lost sight of what makes our products unique: real ingredients, inspired designs and, of course, premium chocolate.',
  [TK.CAREER_PROPERTIES_ITEM_TITLE4]: 'Our Places',
  [TK.CAREER_PROPERTIES_ITEM_SLOGAN4]: 'Playful, fun and healthy.',
  [TK.CAREER_PROPERTIES_ITEM_DESC4]:
    'We want guests who visit us anywhere to feel welcome and inspired. Whether you’re perusing edible.com, receiving a one-of-a-kind gift from our delivery vans, or visiting one of our 1000+ stores, you’ll see that we love what we do and, of course, have fun doing it.',
  [TK.CAREER_PROPERTIES_ITEM_TITLE5]: 'Our Purpose',
  [TK.CAREER_PROPERTIES_ITEM_SLOGAN5]: 'To celebrate what’s good in life.',
  [TK.CAREER_PROPERTIES_ITEM_DESC5]:
    'The world is more connected than ever before, but true, authentic connection is getting more and more difficult to come by. Sure, we sell gifts and treats, but our ultimate purpose is to help people celebrate what’s good in life. Because life’s sweetest moments deserve special attention.',
  [TK.OUR_MISSION_TITLE]: 'Three Little Words, One Great Big Mission',
  [TK.OUR_MISSION_SUB_TITLE]: 'Our mission is simply',
  [TK.OUR_MISSION_SUB_TITLE1]: 'To WOW You®',
  [TK.OUR_MISSION_SUB_TITLE2]:
    'When it comes to a WOW, we think you know one when you see it. We’d love to tell you more about what our mission means to us: how we spend years developing our unique and inventive fresh fruit concepts, how we require that every piece of produce we use meets the highest standards of quality, and how our in-store Fruit Experts® craft each arrangement with care, because they understand exactly what it takes to make your special occasion extra special. (And if you want to read more about everything we do ',
  [TK.OUR_MISSION_SUB_TITLE3]:
    ', please explore further! We’ve left lots of tasty tidbits for you throughout our website.)',
  [TK.OUR_MISSION_SUB_TITLE4]:
    'But, just like our fresh fruit products, we like to put our mission where our mouth is. If we WOW you, please tell us! (And if for any reason we miss the mark, well, we’re here to help then, too.)',
  [TK.DO_FRUIT_TEXT]: '#DOFRUIT',
  [TK.ACCESSIBILITY_TEXT1]:
    'We’re here to help, it is our goal to provide a great experience for our customers with disabilities.If you need assistance using our website, or would like to place an order by phone, please call us at',
  [TK.ACCESSIBILITY_PHONE]: '877-363-7848.',
  [TK.ACCESSIBILITY_TITLE]: 'Accessibility Statement',
  [TK.ACCESSIBILITY_STATEMENT]:
    'This Accessibility Statement applies to: edible',
  [TK.ACCESSIBILITY_TITLE1]: 'We Value Digital Inclusion',
  [TK.ACCESSIBILITY_STATEMENT1]:
    'In our effort to provide a fully accessible and optimized user experience for all site visitors, edible  has taken careful measure to ensure an excellent user experience, regardless of the assistive technology being used to access this site or the specific abilities of those individuals seeking access to this site.',
  [TK.ACCESSIBILITY_STATEMENT2]:
    'The edible website is monitored and tested regularly by internal resources and by AudioEye, a third-party provider of Web Accessibility testing and monitoring. As issues of accessibility are identified, results of automated and manual testing are managed through the AudioEye® Digital Accessibility Platform. As new solutions are discovered to improve the user experience, remediation is tracked through the AudioEye system and fixes are implemented to improve the website user experience.',
  [TK.ACCESSIBILITY_TITLE2]: 'AudioEye Accessibility Certification',
  [TK.ACCESSIBILITY_STATEMENT3]:
    'The AudioEye Certification seal represents a commitment to accessibility and digital inclusion. The AudioEye certification process involves automatic and manual testing with the goal of maximizing conformance with Web Content Accessibility Guidelines (WCAG) 2.0 Level AA Success Criteria.',
  [TK.ACCESSIBILITY_STATEMENT4]:
    'AudioEye certifies that the edible website is in the process of being enhanced to conform with WCAG 2.0 Level AA Success Criteria to the greatest extent possible.',
  [TK.ACCESSIBILITY_STATEMENT5]:
    'AudioEye and edible continue to collaborate in an ongoing effort to maintain conformance and provide an accessible user experience for all users.',
  [TK.ACCESSIBILITY_STATEMENT6]: 'Last Updated 08 21, 2019',
  [TK.ACCESSIBILITY_STATEMENT7]: 'Notice something wrong? Please Provide your',
  [TK.ACCESSIBILITY_LINK]: 'Feedback',
  [TK.ACCESSIBILITY_STATEMENT8]:
    'This website is regularly tested using a variety of assistive technologies. We recommend using the following web browser / screen reader combinations for an optimized experience:',
  [TK.ACCESSIBILITY_USER_TITLE1]: 'For Windows users: ',
  [TK.ACCESSIBILITY_USER_TITLE2]: 'For Mac users:  ',
  [TK.ACCESSIBILITY_USER_TITLE3]: 'For mobile users: ',
  [TK.ACCESSIBILITY_USER_TEXT1]:
    'JAWS and Internet Explorer or NVDA and Firefox',
  [TK.ACCESSIBILITY_USER_TEXT2]: 'VoiceOver and Safari or VoiceOver and Chrome',
  [TK.ACCESSIBILITY_USER_TEXT3]:
    'VoiceOver for the iPhone and TalkBack for Android devices',
  [TK.ACCESSIBILITY_TITLE3]: 'Web Accessibility Guidelines',
  [TK.ACCESSIBILITY_TITLE4]: 'Feedback',
  [TK.ACCESSIBILITY_STATEMENT9]: 'edible has leveraged',
  [TK.ACCESSIBILITY_WEB_LINK]:
    'Web Content Accessibility Guidelines (WCAG) 2.0',
  [TK.ACCESSIBILITY_STATEMENT10]:
    'as reference to ensure the web content made available from this site is more accessible for individuals with disabilities and user friendly for everyone.',
  [TK.ACCESSIBILITY_STATEMENT11]:
    'These globally recognized best practices (as recommended by the World Wide Web Consortium) consist of three levels of accessibility measurement (A, AA, and AAA). To the greatest extent feasible, <span>edible</span> has elected to conform to Level AA of these guidelines.',
  [TK.ACCESSIBILITY_USER_TITLE4]: 'Related Links: ',
  [TK.ACCESSIBILITY_LINK_TEXT1]: 'Web Accessibility Initiative (WAI) ',
  [TK.ACCESSIBILITY_GUIDELINE]:
    'Design guidelines for electronic and information technology',
  [TK.ACCESSIBILITY_STATEMENT12]:
    'If you encounter issues with any page on our site that presents a challenge for individuals with disabilities, ',
  [TK.ACCESSIBILITY_LINK_TEXT]: 'please submit your feedback',
  [TK.ACCESSIBILITY_TITLE5]:
    'Providing Users with a Free Customizable Assistive Utility',
  [TK.ACCESSIBILITY_STATEMENT13]:
    'The following tools may be available in the Ally Toolbar:',
  [TK.ACCESSIBILITY_STATEMENT14]:
    'In addition to the above-mentioned techniques and strategies – and since not all site visitors have access to assistive tools such as screen readers – we provide free Web Enhancement Tools that allow site visitors to customize their user experience. Activate the Ally Toolbar to access each tool listed below.',
  [TK.ACCESSIBILITY_STATEMENT15]: 'AudioEye Tools are off',
  [TK.ACCESSIBILITY_USER_TITLE5]: 'Experience the Ally Toolbar ',
  [TK.ACCESSIBILITY_STATEMENT16]:
    'To experience the AudioEye Ally Toolbar, activate the Ally Toolbar button in the bottom right hand corner.',
  [TK.ACCESSIBILITY_USER_TITLE6]:
    'The Ally Toolbar provides Tools Tailored to Needs ',
  [TK.ACCESSIBILITY_STATEMENT17]:
    'Learn more about the ways in which the Ally Toolbar allows site visitors with diverse abilities to customize their user experience:',
  [TK.ACCESSIBILITY_LINK_TEXT2]: 'Browse All Features',
  [TK.ACCESSIBILITY_STATEMENT18]:
    'The following tools may be available in the Ally Toolbar:',
  [TK.ACCESSIBILITY_EXP_TEXT1]: 'Player',
  [TK.ACCESSIBILITY_EXP_SUBTEXT1]:
    'Listen to the content of a web page read aloud',
  [TK.ACCESSIBILITY_EXP_TEXT2]: 'Reader',
  [TK.ACCESSIBILITY_EXP_SUBTEXT2]:
    'Customize the visual display of the website',
  [TK.ACCESSIBILITY_EXP_TEXT3]: 'Voice',
  [TK.ACCESSIBILITY_EXP_TEXT3_LINK]: '(if applicable)',
  [TK.ACCESSIBILITY_EXP_SUBTEXT3]: 'Command the browser using your voice',
  [TK.ACCESSIBILITY_EXP_TEXT4]: 'Site Menu',
  [TK.ACCESSIBILITY_EXP_SUBTEXT4]:
    'Navigate simplified menus using your keyboard or mouse',
  [TK.ACCESSIBILITY_EXP_TEXT5]: 'Page Elements Menu',
  [TK.ACCESSIBILITY_EXP_SUBTEXT5]:
    'Access page elements and regions with simple keystrokes',
  [TK.ACCESSIBILITY_EXP_TEXT6]: 'AudioEye Help Desk',
  [TK.ACCESSIBILITY_EXP_SUBTEXT6]: 'Report accessibility related issues',
  [TK.ACCESSIBILITY_USER_TITLE7]: 'Third-Party Sites',
  [TK.ACCESSIBILITY_STATEMENT19]:
    'Throughout this website, we make use of different third-party websites such as Instagram, YouTube, Twitter, and Facebook to spread news and information about edible  products and services.',
  [TK.ACCESSIBILITY_STATEMENT20]:
    'As made publicly available, here are the Accessibility Policies provided from these third-party sites:',
  [TK.ACCESSIBILITY_LINK_TEXT3]: 'Facebook Accessibility Policy',
  [TK.ACCESSIBILITY_LINK_TEXT4]: 'YouTube Accessibility Policy',
};
