import { initReactI18next } from 'react-i18next';
import { resources } from 'translations/locales';
import i18next from 'i18next';

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: 'en-US',
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});
